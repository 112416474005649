import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Tooltip} from 'antd';
import {faPen} from '@fortawesome/pro-solid-svg-icons';
import {Attribute, AttributeContainer, AttributeOverflow, PopupContainer} from '../styles';
import {humanize} from '@/utils/string';
import FreezeWrapper from '../../../freezTooltip';

const AttributeField = ({field, openModal, suggestion, isInKnowledgeGraph}) => {
  const socialMediaAttributes = ['attributes/url_instagram', 'attributes/url_youtube', 'attributes/url_linkedin', 'attributes/url_facebook', 'attributes/url_appointment', 'attributes/url_tiktok', 'attributes/url_twitter', 'attributes/url_pinterest'];
  const getAttributeTitle = (key:string) => {
    const str = key?.replaceAll('attributes/', '');
    return humanize(str);
  };

  const openLink = (attr: any) => {
    if (attr?.fieldName?.includes('url')) {
      window.open(attr?.value as string, '_blank');
    } else {
      const value = Object?.values(attr)[0];
      if (typeof value === 'string') {
        window.open(value, '_blank');
      }
    }
  };

  return (
    <>
      <PopupContainer style={{width: 'calc(100% - 60px)', cursor: field?.length === 0 && 'pointer', marginLeft: !isInKnowledgeGraph && 17}}>
        <AttributeOverflow>
          {field?.value && Object.entries(field?.value)?.filter(([key]) => !socialMediaAttributes?.includes(key))?.filter(([, value]) => value)?.length ? (Object.entries(field?.value)?.filter(([key]) => !socialMediaAttributes?.includes(key))?.filter(([, value]) => value)?.map(([key, value], index) => (
            <div key={index}>
              <span>{getAttributeTitle(key)}</span>
              <AttributeContainer style={{marginBottom: typeof value === 'object' && 7}}>
                {Array.isArray(value) && value?.map((item, i) => (
                  <Attribute isURL={key?.includes('url')} onClick={() => openLink({fieldName: key, value: item})} key={`${index}-${i}`}><span>{humanize(item)}</span></Attribute>
                ))}
                {typeof value === 'string' && (
                  <Attribute isURL={key?.includes('url')} onClick={() => openLink(value)}><span>{key?.includes('url') ? value : humanize(value)}</span></Attribute>
                )}
              </AttributeContainer>
            </div>
          ))) : (
            <>
              <FreezeWrapper>
                <Tooltip title={field?.value ? '' : 'No attributes available for this business'}>
                  <span style={{color: field?.value ? '#2D6CCA' : '#a3a4a4'}} onClick={() => field?.value && openModal(suggestion, field)}>
                    Select Attributes
                  </span>
                </Tooltip>
              </FreezeWrapper>
            </>
          )}
        </AttributeOverflow>
      </PopupContainer>
      <div className='edit-icon' style={{color: !field?.value && '#a3a4a4'}}>
        <FreezeWrapper>
          <Tooltip title={field?.value ?'Edit' : 'No Attributes Available for this business'}>
            <div onClick={() => field?.value && openModal(suggestion, field)}>
              <FontAwesomeIcon
                style={{marginRight: 6}}
                icon={faPen}
                color={field?.value ? '#2D6CCA' : '#a3a4a4'}
              />
          Edit
            </div>
          </Tooltip>
        </FreezeWrapper>
      </div>
    </>
  );
};

export default AttributeField;


