import React, {useState, useEffect} from 'react';
import styles from '@/components/dashboard/billing/unpaid-invoice/style.module.scss';
import {Modal, Button, SkeletonRingLoader} from '@/components/common-components/components';
import {Alert, Collapse, Form, Radio} from 'antd';
import {notification} from '@/utils/notifications';
import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react';
import classnames from 'classnames';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {BillingDetail} from '../payment-method-modal/billing-detail';
import {updateGTMDatalayer} from '@/utils/gtm';
import {useTranslation} from 'next-i18next';
import {NextImg} from '@/utils/nextImg';
import moment from 'moment';


interface props {
  isOpen: boolean;
  setIsOpen: (boolean) => void;
  loadUpdatedInvoice: () => void;
  invoice: any;
}

export interface CheckoutFormProp {
  setStripe: any;
  setElements: any;
}

export const UnpaidInvoiceModal: React.FC<props> = observer(({isOpen, setIsOpen, invoice, loadUpdatedInvoice}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [previousCard, setPreviousCard] = useState(-2);
  const [paymentMethodKey, setPaymentMethodKey] = useState('1');
  const [newCard, setNewCard] = useState(true);
  const [previousCredits, setPreviousCredits] = useState(-1);
  const [newCredits, setNewCredits] = useState(false);

  const {
    plans: {
      isLoading,
      stopLoading,
      invoicePaid,
      startLoading,
      getServerErrors,
      resetServerErrors,
    }, invoices: {
      getPaymentOptions,
      getPaymentMethod,
      getCurrentPaymentLoading,
      loadOrderInvoices,
    }, settings: {
      customer: {
        profile: {
          useTestStripeKey,
          firstName,
          lastName,
          currentCredits,
        },
        loadProfile,
      },
    },
  } = useStore('');

  const date = invoice?.dueDate == '-' ? '-' : moment(invoice?.dueDate).utc().format('DD-MM-YYYY');
  const [stripePromise] = useState(() => loadStripe(useTestStripeKey ? process.env.STRIPE_TEST_PUBLIC_KEY : process.env.STRIPE_LIVE_PUBLIC_KEY));
  const {t} = useTranslation('common');
  const {Panel} = Collapse;

  const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();

    const onChange = e => {
      setPreviousCard(e.target.value);
      e.target.value == -1 ? setNewCard(true) : setNewCard(false);
    };

    const createOptions = () => {
      return {
        base: {
          'fontSize': '14px',
          'color': '#121212',
          'fontFamily': 'Inter',
          'letterSpacing': '0.025em',
          '::placeholder': {
            'color': '#A3A4A4',
            'fontSize': '14px',
          },
        },
        invalid: {
          color: '#F44343',
        },
      };
    };


    const handleSubmit = async event => {
      // Block native form submission.

      if (newCard) {
        if (!stripe || !elements ) {
          setErrorMessage('Stripe Error');
          return;
        }
        const cardNumElement = elements.getElement(CardNumberElement);
        const result = await stripe.createPaymentMethod({
          type: 'card',
          card: cardNumElement,
          billing_details: {
            address: {
              country: null,
              line1: event.address,
              line2: null,
              postal_code: event.zipcode,
              state: null,
            },
            name: `${event.firstname} ${event.lastname}`,
          }});

        if (result.error) {
          setErrorMessage(result.error.message);
        } else {
          setErrorMessage('');
          let data = {};
          if (invoice.type == 'order') {
            data = {
              charge_type: invoice.type,
              invoice_id: invoice.slug,
              payment_method: {
                source: 'stripe',
                first_name: event.firstname,
                last_name: event.lastname,
                address: event.address,
                zipcode: event.zipcode,
                stripe_payment_method: result.paymentMethod,
              },
            };
          } else {
            data = {
              charge_type: invoice.type,
              invoice_id: invoice.slug,
              plan_id: invoice.plan_id,
              payment_method: {
                source: 'stripe',
                first_name: event.firstname,
                last_name: event.lastname,
                address: event.address,
                zipcode: event.zipcode,
                stripe_payment_method: result.paymentMethod,
              },
            };
          }
          await payInvoices(data);
        }
      } else {
        startLoading();
        let data = {};
        if (invoice.type == 'order') {
          data = {
            charge_type: invoice.type,
            invoice_id: invoice.slug,
            payment_method_id: previousCard,
          };
        } else {
          data = {
            charge_type: invoice?.type,
            invoice_id: invoice.slug,
            plan_id: invoice.plan_id,
            payment_method_id: previousCard,
          };
        }
        await payInvoices(data);
      }
    };

    const handleOnChangeKey = key => {
      if (Number(key) === 2) {
        setNewCredits(true);
        setNewCard(false);
      } else {
        if (previousCard == -1) {
          setNewCard(true);
        }
        setNewCredits(false);
      }
      setPaymentMethodKey(key);
    };

    const onChangeCredits = e => {
      setPreviousCredits(e.target.value);
      setNewCredits(true);
      // e.target.value == -1 ? setNewCredits(true) : setNewCredits(false);
    };

    const payWithCredits = async () =>{
      const invoiceData={
        amount: invoice.amount,
        invoice_id: invoice.slug,
        charge_via_credits: true,
        plan_id: invoice?.plan_id,
        charge_type: invoice?.type,
      };
      await invoicePaid(invoiceData);
      await loadProfile();
      setIsOpen(false);
      await loadOrderInvoices('');
    };

    const payInvoices = async data => {
      try {
        resetServerErrors();
        const invoicePaidResponse = await invoicePaid(data);
        if (invoicePaidResponse) {
          if (invoicePaidResponse?.success == false) {
            notification.error(invoicePaidResponse.details);
          } else if (getServerErrors.length > 0) {
            notification.error(getServerErrors[0]);
            setTimeout(() => {
              resetServerErrors();
            }, 5000);
          } else {
            setIsOpen(false);
            loadUpdatedInvoice();
            await loadProfile();
            updateGTMDatalayer({
              'event': 'invoice_payment',
              'charge_type': invoice?.type,
              'invoice_id': invoice?.slug,
              'plan': (invoice?.plan_id && invoice?.plan_id === 1) ? 'growth' : 'starter',
              'amount': invoice?.price ?? invoice?.amount,
            });
          }
        }
        stopLoading();
      } catch (error) {
        const statusCode = error?.response?.status;
        if (statusCode==500) {
          resetServerErrors();
          notification.error('Invoice Payment Failed');
        }
        stopLoading();
        return Promise.reject(error);
      }
    };

    useEffect(() => {
      if (previousCard == -2) {
        setPreviousCard(getPaymentMethod?.id);
        getPaymentMethod ? setNewCard(false) : setNewCard(true);
      }
    }, []);

    return (
      <Form onFinish={newCredits ? payWithCredits : handleSubmit}>
        <div className={styles.unpaidInvoice}>
          <div className={styles.content}>
            <h2 className={styles.heading}>{invoice?.type == 'customer_plan' ? `Subscription` : `Order`}</h2>
            <p className={styles.history}>
              <div className={styles.heading}>
                <span>CUSTOMER</span>
                <span>AMOUNT TO BE PAID</span>
              </div>
              <div className={styles.detail}>
                <p>
                  <span className={styles.name}> {invoice.name ?? `${firstName} ${lastName}`} </span>
                  <p> { invoice.address || '' } </p>
                </p>
                <p className={styles.rightSide}>
                  <span className={styles.price}> ${ invoice.amount ?? invoice.price ?? 0} </span>
                  <span>Due on: { date } </span>
                </p>
              </div>
            </p>

            {!!getPaymentOptions.length && <h2 className={styles.heading}>Payment Methods</h2>}

            <div className={styles.cardDetail}>
              { getCurrentPaymentLoading ? (
                <SkeletonRingLoader/>
              ) : (
                <Collapse accordion ghost={true} defaultActiveKey={paymentMethodKey} onChange={key => handleOnChangeKey(key)} expandIcon={({isActive}) =>
                  <Radio className={styles.radioButtonStyle} checked={isActive}></Radio>
                }>
                  <Panel header={t('credit-card')} key='1' className={styles.collapsePanel}>
                    {/* !!getPaymentOptions.length &&  */}
                    <Radio.Group onChange={onChange} value={previousCard}>
                      { getPaymentOptions.length > 0 && (
                        getPaymentOptions.map((item, index) =>{
                          return <div key={index} className={classnames(styles.paymentCard, previousCard== item.id ? styles.paymentCardActive : '' )}>
                            <Radio name='cardOpen' value={item.id}>
                              <div className={styles.paymentDetail}>
                                <div className={styles.paymentInfo}>
                                  <span>•••• •••• •••• {item.last4}</span>
                                  <p>
                                    <span>{ item.name && item.name.length > 10 ? item.name.substring(0, 10) + '...' : item.name }</span>
                                    <span>{item.expMonth}/{item.expYear}</span>
                                  </p>
                                </div>
                                <NextImg src={previousCard== item.id ? '/img/icon/master-card.svg' : '/img/icon/master-card-inactive.svg'}/>
                              </div>
                            </Radio>
                          </div>;
                        })
                      )}

                      { getPaymentOptions.length > 0 && (
                        <div className={classnames(styles.paymentCard, styles.newPaymentCard, previousCard== -1 ? styles.paymentCardActive : '' )}>
                          <Radio value={-1}>
                            <div className={styles.paymentDetail}>
                              <h2>Pay with another card</h2>
                            </div>
                          </Radio>
                        </div>
                      )}

                    </Radio.Group>
                  </Panel>
                  <Panel header={<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: 430}}><span>Pay with Credits</span><span>{`${currentCredits} available`}</span></div>} key='2' className={styles.collapsePanel}>
                    <Radio.Group onChange={onChangeCredits} value={previousCredits}>
                    </Radio.Group>
                  </Panel>
                </Collapse>
              )}

              { newCard && (
                <>
                  <label className={styles.cardNumber}>
                    Card number
                    <Form.Item name='cardNumber'>
                      <CardNumberElement options={
                        {style: createOptions(), showIcon: true}} className={styles.cardNumberStyle}/>
                    </Form.Item>
                  </label>
                  <div className={styles.cardInfo}>
                    <label>
                      Expiration date
                      <CardExpiryElement options={{style: createOptions()}} className={styles.cardInputStyle}/>
                    </label>
                    <label>
                      CVC
                      <CardCvcElement options={{style: createOptions()}} className={styles.cardInputStyle}/>
                    </label>
                  </div>
                  <div className={styles.billingDetail}>
                    <BillingDetail/>
                  </div>
                </>
              ) }

            </div>

            <div className={styles.lock}>
              <NextImg src='/img/icon/lock.svg'/>
              <label className={styles.labelBottom}>
                Transactions are secured with SSL encryption.</label>
            </div>
          </div>

          <div className={styles.footer}>
            <span
              className={styles.cancel}
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </span>
            <Button
              className={styles.payForInvoice}
              buttonType='purple'
              loading={isLoading}
              disabled={isLoading || (paymentMethodKey ? false : true)}
              type='submit'
            >
              <NextImg src='/img/icon/lock-white.svg'/>
              Pay for Invoice
            </Button>
          </div>
        </div>
      </Form>
    );
  };


  return (
    <div>
      <Modal
        onClose={() => setIsOpen(false)}
        visible={isOpen}
        className={styles.unpaidInvoice}
        wrapClassName={styles.modalWrapper}
      >
        <Elements stripe={stripePromise}>
          {errorMessage != '' ? <Alert message={`${errorMessage}`} type='error'/> : ''}
          <CheckoutForm/>
        </Elements>

      </Modal>
    </div>
  );
});
