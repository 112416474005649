import {getTokenFromCookies} from '@/api/common-utils';
import {getSaUrl} from '@/api/gsc/utils';
import {routes} from '@/utils/const';
import {linkgraphDomain} from '@/utils/const';
import {getDomain} from '@/utils/url';

export function getAbsoluteLegacyUrl(path: string, withToken: boolean = true) {
  const token = getTokenFromCookies();
  const domainName = getDomain(linkgraphDomain);
  if (!withToken) {
    return `https://legacy.${domainName}/${path}`;
  }

  return `https://legacy.${domainName}/${path}?legacy_token=${token}`;
}

export function getHubSpotLink() {
  return `${getSaUrl()}/consultation/`;
}
export function getRequestPricelink() {
  return `${getSaUrl()}/consultation/`;
}
export function getGrowBusinessLink() {
  return `${process.env.LINKGRAPH_COM}/learn-how-we-can-grow-your-business/`;
}
export function getKnowledgeCenterLink(lang:string) {
  return `https://help.linkgraph.com/${lang}/`;
}
export function getKnowledgeAcademyLink() {
  return `${getSaUrl()}/academy/`;
}
export function getSearchAtlasKnowledgeAcademyLink() {
  return `${getSaUrl()}/academy/`;
}

export function getAppleAppLink() {
  return ' https://apps.apple.com/us/app/searchatlas-seo/id1571340382';
}

export function getAndroidAppLink() {
  return 'https://play.google.com/store/apps/details?id=linkgraph.com';
}

export function getChromeAppLink() {
  return 'https://chrome.google.com/webstore/detail/linkgraphs-searchatlas/ahnhkdjjonmjlhbbgaaoehdnhlgcgbab';
}

export function getFullAccessLink() {
  return routes.pricing;
}
export function goToCheckout() {
  return routes.checkout;
}

