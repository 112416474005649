import {faXmark} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {observer} from 'mobx-react';
import React, {Dispatch, SetStateAction, useState} from 'react';
import styled from 'styled-components';
import {ViewButton} from '../style';
import {faCodePullRequest, faRotate} from '@fortawesome/pro-regular-svg-icons';
import {StyledSpin} from '../Utils/styledSpin';
import {faClockRotateLeft} from '@fortawesome/pro-light-svg-icons';
import {useStore} from '@/store/root-store';
import {getGeneratedTitle} from '../Constants/functions';
import {getSingleUrlParam} from '@/utils/url';
import {newNotification} from '@/utils/notification-v3';
import {Tooltip} from 'antd';
interface BulkActionBarProps {
  setShowBulkBanner: Dispatch<SetStateAction<boolean>>;
  count: number;
  selectedPages: number[];
  setSelectedPages: Dispatch<SetStateAction<number[]>>;
  selectAll: boolean;
  setSelectAll: Dispatch<SetStateAction<boolean>>;
  selectAllOnPage: any;
  issueType: string;
  selectPageText?: string;
  currentProject: any;
  setPageChanged: Dispatch<SetStateAction<boolean>>;
  isSelectAllDisable?: any;
  deployButtonText?: any;
  rollBackButtonText?: any;
  aiGenerateButtonText?: any;
  isSiteMapModal?: boolean;
  isSiteMap?: boolean;
  getSiteMapUrls?: (value?: any) => void;
}

const hideAIGenerationButtons = ['twitter_site_property', 'og_url', 'twitter_card'];

export const BulkActionBar: React.FC<BulkActionBarProps> = observer(({
  setShowBulkBanner,
  count, selectedPages,
  setSelectedPages,
  selectAll,
  setSelectAll,
  selectAllOnPage,
  issueType,
  selectPageText,
  setPageChanged,
  isSelectAllDisable = false,
  deployButtonText = 'Deploy',
  rollBackButtonText = 'Roll back',
  aiGenerateButtonText = 'AI Generate',
  isSiteMapModal=false,
  isSiteMap=false,
  getSiteMapUrls,
}) => {
  const {ottoV2Store: {
    deployOttoUrls,
    setIssueTypeSelected,
    setIsDeploying,
    loadOttoV2Project,
    bulkGenerateAiProposedFix,
    selectedIssue,
    setAiShouldRepoll,
    getOttoUrls,
    enableUrlIndexing,
    loadIssueTableData,
    getOttoV2Project,
  },
  settings: {customer: {getCustomerQuota}},
  } = useStore('');
  const [loading, setLoading] = useState({
    key: '',
    state: false,
  });

  const uuid = getSingleUrlParam('uuid');

  const defaultDeploySelectedPages = async (toDeploy: boolean, selectedPages: number[]) => {
    try {
      setLoading({
        key: toDeploy ? 'deploy' : 'rollback',
        state: true,
      });
      const data = {toDeploy, issueType: issueType, uuid};
      data['ottoUrls'] = selectedPages;
      await deployOttoUrls(data);
      setPageChanged(true);
      setIssueTypeSelected(issueType);
      setIsDeploying(true);
      setSelectedPages([]);
      setShowBulkBanner(false);
      if (uuid) {
        loadOttoV2Project(uuid, true);
      }
      if (toDeploy) {
        newNotification(`${selectedPages.length} ${getGeneratedTitle(issueType, selectedPages.length)} Deployed Successfully`, 2, 'deploy');
      } else {
        newNotification(`${selectedPages.length} ${getGeneratedTitle(issueType, selectedPages.length)} Rolled Back`, 2, 'rollback');
      }
      setLoading({
        key: '',
        state: false,
      });
    } catch (error) {
      setLoading({
        key: '',
        state: false,
      });
      // eslint-disable-next-line no-console
      console.error('Error: ', error);
    }
  };


  const handleIndex = async toDeploy => {
    setLoading({
      key: toDeploy ? 'deploy' : 'rollback',
      state: true,
    });
    const filter = getOttoUrls.filter(d => d.issueType === issueType);
    await enableUrlIndexing({otto_project_uuid: uuid, indexing_url_ids: [...selectedPages], is_selected: toDeploy});
    if (isSiteMapModal) {
      getSiteMapUrls(false);
    } else {
      await loadIssueTableData({uuid: getOttoV2Project?.uuid, page: filter?.length ? filter[0].page : 1, page_size: filter?.length ? filter[0].pageSize : 10, issue_type: issueType, otto_project: getOttoV2Project?.id});
    }
    setLoading({
      key: '',
      state: false,
    });
    setSelectedPages([]);
    setShowBulkBanner(false);
  };

  const generateBulkAiProposedFix = async () => {
    setAiShouldRepoll(true);
    try {
      setLoading({
        key: 'ai-generate',
        state: true,
      });
      await bulkGenerateAiProposedFix({issueType, uuid, selectedIds: selectedPages});
      getCustomerQuota();
      setPageChanged(true);
      newNotification(`${selectedPages.length} AI ${selectedPages.length > 1 ? 'Suggestions' : 'Suggestion'} Generated Successfully`, 2, 'deploy');
      setIssueTypeSelected(issueType);
      setSelectedPages([]);
      setShowBulkBanner(false);
      setLoading({
        key: '',
        state: false,
      });
    } catch (error) {
      setLoading({
        key: '',
        state: false,
      });
      return Promise.reject(error);
    }
  };

  const deselectAll = () => {
    setSelectAll(false);
    setSelectedPages([]);
  };

  return (
    <BarWrapper>
      <div className='left-section'>
        <div className='selected-wrapper'>Selected: <div>{selectAll ? count : selectedPages?.length} {selectPageText ? selectedPages?.length > 1 ? selectPageText + `'s` : selectPageText : 'pages'}</div></div>
        <div className='select-buttons'>
          {<Tooltip placement='bottom' title={isSelectAllDisable ? 'Empty fields cannot be selected.' : ''}> <div className='select-all-on-page' style={{cursor: isSelectAllDisable ? 'not-allowed' : 'pointer'}} onClick={() => (!isSelectAllDisable && selectAllOnPage())}>Select all on this page</div> </Tooltip>}
          {/* <div className='select-all' onClick={()=>setSelectAll(true)}>Select all {count ?? ''}</div> */}
          <div className='deselect-all' onClick={deselectAll}>Deselect All</div>
        </div>
      </div>
      <div className='right-section'>
        <div className='buttons'>
          {selectedIssue === 'technical_fixes' && !hideAIGenerationButtons.includes(issueType) ? <ViewButton
            style={{
              color: '#fff',
              backgroundColor: selectedPages.length ? '#2D6CCA' : '#2D6CCA96',
              cursor: !selectedPages.length ? 'not-allowed' : 'pointer',
              width: 'auto',
            }}
            onClick={()=> selectedPages?.length && generateBulkAiProposedFix()}>
            {(loading.key == 'ai-generate' && loading.state) ? <StyledSpin color='#FFFFFF' fontSize={14}/> : <FontAwesomeIcon icon={faRotate} color='#FFFFFF' fontSize={14}/>} {aiGenerateButtonText}
          </ViewButton> : <></>}
          <ViewButton
            style={{
              color: '#fff',
              backgroundColor: '#2AC155',
              cursor: !selectedPages.length ? 'not-allowed' : 'pointer',
              width: 'auto',
            }}
            onClick={()=> !selectedPages?.length ? '' : (isSiteMapModal || isSiteMap) ? handleIndex(true) : defaultDeploySelectedPages(true, selectedPages)}>
            {(loading.key == 'deploy' && loading.state) ? <StyledSpin color='#FFFFFF' fontSize={14}/> : <FontAwesomeIcon icon={faCodePullRequest} color='#FFFFFF' fontSize={14}/>} {deployButtonText}
          </ViewButton>
          <ViewButton
            style={{
              color: '#121212',
              backgroundColor: '#FFFFFF',
              width: 'auto',
              cursor: !selectedPages.length ? 'not-allowed' : 'pointer',
              border: '1px solid #D2D2D2',
            }}
            onClick={()=> !selectedPages?.length ? '' : (isSiteMapModal || isSiteMap) ? handleIndex(false) :defaultDeploySelectedPages(false, selectedPages)}>
            {(loading.key == 'rollback' && loading.state) ? <StyledSpin color='#4E5156' fontSize={14}/> : <FontAwesomeIcon icon={faClockRotateLeft} color='#4E5156' fontSize={14}/>} {rollBackButtonText}
          </ViewButton>
        </div>
        <div className='close-icon'>
          <FontAwesomeIcon onClick={() => {
            setShowBulkBanner(false);
            if (isSiteMapModal) {
              setSelectedPages([]);
            }
          }} icon={faXmark} fontSize={14} color='#4E5156'/>
        </div>
      </div>
    </BarWrapper>
  );
});
const BarWrapper = styled.div`
  margin-top: 15px;
  padding: 4px 16px;
  border-radius: 10px;
  background-color: #F2F2F5;
  border: 1px solid #A3A4A459;
  box-shadow: 0px 3px 6px 0px #0000001A;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left-section {
    display: flex;
    align-items: center;
    gap: 12px;
    .selected-wrapper {
      font-family: 'Inter', sans-serif;
      font-size: 13px;
      font-weight: 400;
      line-height: normal;
      color: #4E5156;
      border-right: 1px solid #D9D9D9;
      display: flex;
      align-items: center;
      gap: 2px;
      padding-right: 12px;
      div {
        font-family: 'Inter', sans-serif;
        font-size: 13px;
        font-weight: 500;
        line-height: normal;
        color: #121212;
      }
    }
    .select-buttons {
      display: flex;
      align-items: center;
      gap: 20px;
      .select-all-on-page, .select-all, .deselect-all {
        font-family: 'Inter', sans-serif;
        font-size: 13px;
        font-weight: 400;
        line-height: normal;
        color: #2D6CCA;
        cursor: pointer;
      }
      .deselect-all {
        color: #F44343 !important;
      }
    }
  }
  .right-section {
    display: flex;
    align-items: center;
    gap: 16px;
    .buttons {
      display: flex;
    align-items: center;
    gap: 6px;
    }
    .close-icon {
      .fa-xmark {
        cursor: pointer;
      }
    }
  }
  @media screen and (max-width: 1380px) {
    .left-section {
      .select-buttons {
        gap: 8px;
      }
    }
  }
`;
