import React from 'react';
import {observer} from 'mobx-react-lite';
import styled from 'styled-components';
import {Progress} from 'antd';
import {LineChart} from '../charts/lineChart';
import {formatLargeNumber} from '@/utils/number';
import {SkeletonHorizontalLoaderGray} from '@/components/common-components';
import {LineChartDouble} from '../charts/lineChartDouble';
import {useStore} from '@/store/root-store';
import moment from 'moment';

function convertDateFormat(inputDate) {
  const months = {
    Jan: '01', Feb: '02', Mar: '03', Apr: '04',
    May: '05', Jun: '06', Jul: '07', Aug: '08',
    Sep: '09', Oct: '10', Nov: '11', Dec: '12',
  };

  const parts = inputDate.split(' ');
  const month = months[parts[0]];
  const day = parts[1].slice(0, -1);
  const year = parts[2];

  return `${year}-${month}-${day}`;
}

const formatDate = (date: string) => {
  const options: Intl.DateTimeFormatOptions = {year: 'numeric', month: 'short', day: 'numeric'};
  return new Date(date).toLocaleDateString('en-US', options);
};

const getDateRange = dateRange => {
  const currentDate = new Date();
  let startDate = null;
  let endDate = null;

  switch (dateRange) {
    case 'last_1_month':
      startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate());
      endDate = currentDate;
      break;
    case 'last_3_months':
      startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 3, currentDate.getDate());
      endDate = currentDate;
      break;
    case 'last_6_months':
      startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 6, currentDate.getDate());
      endDate = currentDate;
      break;
    case 'last_year':
      startDate = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), currentDate.getDate());
      endDate = currentDate;
      break;
    default:
      startDate = null;
      endDate = null;
  }
  return {dateRangeStart: convertDateFormat(formatDate(startDate)), dateRangeEnd: convertDateFormat(formatDate(endDate))};
};

export const MatrixDR = observer(() => {
  const {competitorResearcherV2: {overview: {getCompetitorResearcherDetail, loadingDetail}}} = useStore('');

  return (
    <div>
      {loadingDetail ? <Wrapper><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></Wrapper> : <div>
        <BlockWrapper>
          <Title>Domain Rating </Title>
          <div>
            <Progress type='circle' trailColor='rgb(229 229 229)' percent={Math.round((getCompetitorResearcherDetail?.data?.domainRating/100)*100) || 0} strokeColor='#2D6CCA' width={28} strokeWidth={20}
              gapDegree={0}
              size='small'
              showInfo={false}
            />
            <h2 className='h2Style'><b>{getCompetitorResearcherDetail?.data?.domainRating ?? '-'}</b></h2>
          </div>
          <p>Authority: {getCompetitorResearcherDetail?.data?.competitorResearch?.authorityScore ?? '-'}</p>
        </BlockWrapper>
      </div>}
    </div>
  );
});
export const MatrixUR = observer(() => {
  const {competitorResearcherV2: {overview: {getCompetitorResearcherDetail, loadingDetail}}} = useStore('');

  return (
    <div>

      {loadingDetail ? <Wrapper><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></Wrapper> : <div>
        <BlockWrapper>
          <Title>URL Rating </Title>
          <div>
            <Progress type='circle' trailColor='rgb(229 229 229)' percent={Math.round((getCompetitorResearcherDetail?.data?.urlRating/100)*100) || 0} strokeColor='#2D6CCA' width={28} strokeWidth={20}
              gapDegree={0}
              size='small'
              showInfo={false}
            />
            <h2 className='h2Style'><b> {getCompetitorResearcherDetail?.data?.urlRating ?? '-'}</b></h2>
          </div>
        </BlockWrapper>
      </div> }

    </div>
  );
});
export const MatrixB = observer(() => {
  const {competitorResearcherV2: {overview: {getCompetitorResearcherDetail, loadingDetail}}} = useStore('');

  const {reportBuilder: {details: {singleProjectData}}} = useStore('');

  const dateProject = singleProjectData?.dateRangeEnd ? {dateRangeStart: convertDateFormat(moment(singleProjectData?.dateRangeStart).format('ll')), dateRangeEnd: convertDateFormat(moment(singleProjectData?.dateRangeEnd).format('ll'))} : getDateRange(singleProjectData?.dateRange);

  let filteredData = getCompetitorResearcherDetail?.data?.competitorResearch?.backlinksTrend?.filter(item => {
    const date = new Date(item.date);
    return date >= new Date(dateProject?.dateRangeStart) && date <= new Date(dateProject?.dateRangeEnd);
  });

  filteredData = filteredData?.length == 1 ? [...filteredData, ...filteredData] : filteredData;

  return (
    <div>
      {loadingDetail ? <Wrapper><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></Wrapper> : <div>
        <BlockWrapper>
          <Title>Backlinks </Title>
          <div>
            <h2 className='range'><b>{formatLargeNumber(getCompetitorResearcherDetail?.data?.competitorResearch?.backlinkCount, 1)?.toString()?.replace('.0', '') ?? '-'}</b></h2>
          </div>
          <p>Ref. domain: {formatLargeNumber(getCompetitorResearcherDetail?.data?.competitorResearch?.refferingDomains || 0, 1)?.toString()?.replace('.0', '') ?? '-'}</p>
          <LineChartDouble boundaryGap={false} gridBottom={0} chartHeight='20px' chartWidth='90px' seriesName='Backlinks' loading={false} xAxisData={filteredData?.map(item => ({value: item.totalLinks, date: item.date, ref: item.totalRefdomains}))}
            color='#2D6CCA' data={filteredData?.map(item => ({value: item.totalLinks, date: item.date, ref: item.totalRefdomains}))}
            tooltipPositionTop='180%' />
        </BlockWrapper>
      </div>}
    </div>
  );
});
export const MatrixTF = observer(() => {
  const {competitorResearcherV2: {overview: {getCompetitorResearcherDetail, loadingDetail}}} = useStore('');

  return (
    <div>
      {loadingDetail ? <Wrapper><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></Wrapper> : <div>
        <BlockWrapper>
          <Title>Trust Flow </Title>
          <div>
            <Progress type='circle' trailColor='rgb(229 229 229)'
              percent={getCompetitorResearcherDetail?.data?.competitorResearch?.trustFlow ? (typeof(getCompetitorResearcherDetail?.data?.competitorResearch?.trustFlow) == 'number' ? getCompetitorResearcherDetail?.data?.competitorResearch?.trustFlow : Number(getCompetitorResearcherDetail?.data?.competitorResearch?.trustFlow)) : 0}
              strokeColor='#2D6CCA' width={28} strokeWidth={20}
              gapDegree={0}
              size='small'
              showInfo={false}
            />
            <h2 className='h2Style'><b>{getCompetitorResearcherDetail?.data?.competitorResearch?.trustFlow ?? '-'}</b></h2>
          </div>
          <p style={{marginTop: '5px'}}>Citation flow: {getCompetitorResearcherDetail?.data?.competitorResearch?.citationFlow ?? '-'}</p>
          <p>Spam score: {getCompetitorResearcherDetail?.data?.spamScore ?? '-'}</p>
        </BlockWrapper>
      </div>}
    </div>
  );
});
export const MatrixK = observer(() => {
  const {competitorResearcherV2: {overview: {getCompetitorResearcherDetail, loadingDetail}}} = useStore('');

  const {reportBuilder: {details: {singleProjectData}}} = useStore('');

  const dateProject = singleProjectData?.dateRangeEnd ? {dateRangeStart: convertDateFormat(moment(singleProjectData?.dateRangeStart).format('ll')), dateRangeEnd: convertDateFormat(moment(singleProjectData?.dateRangeEnd).format('ll'))} : getDateRange(singleProjectData?.dateRange);

  let filteredData = getCompetitorResearcherDetail?.data?.competitorResearch?.organicTrend?.filter(item => {
    const date = new Date(item.date);
    return date >= new Date(dateProject?.dateRangeStart) && date <= new Date(dateProject?.dateRangeEnd);
  });

  filteredData = filteredData?.length == 1 ? [...filteredData, ...filteredData] : filteredData;

  return (
    <div>
      {loadingDetail ? <Wrapper><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></Wrapper> : <div>
        <BlockWrapper>
          <Title>Keywords </Title>
          <div>
            <h2 className='range'><b>{formatLargeNumber(getCompetitorResearcherDetail?.data?.competitorResearch?.organicKeywords || 0, 1)?.toString()?.replace('.0', '')??'-'}</b></h2>
          </div>
          <p>Top 3: {getCompetitorResearcherDetail?.data?.competitorResearch?.organicKeywordsTop3 ?? '-'}</p>
          <LineChart boundaryGap={false} gridBottom={0} chartHeight='20px' chartWidth='90px' seriesName='Keywords' loading={false} xAxisData={filteredData?.map(item => item.organicKeywords)} color='#7F4EAD' data={filteredData?.map(item => ({value: item.organicKeywords, date: item.date}))}
            tooltipPositionTop='180%' />
        </BlockWrapper>
      </div>}
    </div>
  );
});
export const MatrixT = observer(() => {
  const {competitorResearcherV2: {overview: {getCompetitorResearcherDetail, loadingDetail}}} = useStore('');

  const {reportBuilder: {details: {singleProjectData}}} = useStore('');

  const dateProject = singleProjectData?.dateRangeEnd ? {dateRangeStart: convertDateFormat(moment(singleProjectData?.dateRangeStart).format('ll')), dateRangeEnd: convertDateFormat(moment(singleProjectData?.dateRangeEnd).format('ll'))} : getDateRange(singleProjectData?.dateRange);

  let filteredData = getCompetitorResearcherDetail?.data?.competitorResearch?.organicTrend?.filter(item => {
    const date = new Date(item.date);
    return date >= new Date(dateProject?.dateRangeStart) && date <= new Date(dateProject?.dateRangeEnd);
  });

  filteredData = filteredData?.length == 1 ? [...filteredData, ...filteredData] : filteredData;

  return (
    <div>
      {loadingDetail ? <Wrapper><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></Wrapper> : <div>
        <BlockWrapper>
          <Title>Traffic </Title>
          <div>
            <h2 className='range'><b>{formatLargeNumber(getCompetitorResearcherDetail?.data?.competitorResearch?.organicTraffic || 0, 1)?.toString()?.replace('.0', '')??'-'}</b></h2>
          </div>
          <p>per month</p>
          <LineChart boundaryGap={false} gridBottom={0} chartHeight='20px' chartWidth='90px' seriesName='Traffic' loading={false} xAxisData={filteredData?.map(item => item.organicTraffic)} color='#B98CE4' data={filteredData?.map(item => ({value: item.organicTraffic, date: item.date}))}
            tooltipPositionTop='180%' />
        </BlockWrapper>
      </div>}
    </div>
  );
});
export const MatrixPK = observer(() => {
  const {competitorResearcherV2: {overview: {getCompetitorResearcherDetail, loadingDetail}}} = useStore('');

  const {reportBuilder: {details: {singleProjectData}}} = useStore('');

  const dateProject = singleProjectData?.dateRangeEnd ? {dateRangeStart: convertDateFormat(moment(singleProjectData?.dateRangeStart).format('ll')), dateRangeEnd: convertDateFormat(moment(singleProjectData?.dateRangeEnd).format('ll'))} : getDateRange(singleProjectData?.dateRange);

  let filteredData = getCompetitorResearcherDetail?.data?.competitorResearch?.organicTrend?.filter(item => {
    const date = new Date(item.date);
    return date >= new Date(dateProject?.dateRangeStart) && date <= new Date(dateProject?.dateRangeEnd);
  });

  filteredData = filteredData?.length == 1 ? [...filteredData, ...filteredData] : filteredData;

  return (
    <div>
      {loadingDetail ? <Wrapper><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></Wrapper> : <div>
        <BlockWrapper>
          <Title>Paid kwds. </Title>
          <div>
            <h2 className='range'><b>{formatLargeNumber(getCompetitorResearcherDetail?.data?.competitorResearch?.paidKeywords || 0, 1)?.toString()?.replace('.0', '')??'-'}</b></h2>
          </div>
          <p style={{height: '18px'}}></p>
          <LineChart boundaryGap={false} gridBottom={0} chartHeight='20px' chartWidth='90px' seriesName='Paid Keywords' loading={false} xAxisData={filteredData?.map(item => item.paidKeywords)} color='#FF8536' data={filteredData?.map(item => ({value: item.paidKeywords, date: item.date}))}
            tooltipPositionTop='180%' />
        </BlockWrapper>
      </div>}

    </div>
  );
});
export const MatrixPT = observer(() => {
  const {competitorResearcherV2: {overview: {getCompetitorResearcherDetail, loadingDetail}}} = useStore('');

  const {reportBuilder: {details: {singleProjectData}}} = useStore('');

  const dateProject = singleProjectData?.dateRangeEnd ? {dateRangeStart: convertDateFormat(moment(singleProjectData?.dateRangeStart).format('ll')), dateRangeEnd: convertDateFormat(moment(singleProjectData?.dateRangeEnd).format('ll'))} : getDateRange(singleProjectData?.dateRange);

  let filteredData = getCompetitorResearcherDetail?.data?.competitorResearch?.organicTrend?.filter(item => {
    const date = new Date(item.date);
    return date >= new Date(dateProject?.dateRangeStart) && date <= new Date(dateProject?.dateRangeEnd);
  });

  filteredData = filteredData?.length == 1 ? [...filteredData, ...filteredData] : filteredData;

  return (
    <div>
      {loadingDetail ? <Wrapper><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></Wrapper> : <div>
        <BlockWrapper>
          <Title>Paid Traffic </Title>
          <div>
            <h2 className='range'><b>{formatLargeNumber(getCompetitorResearcherDetail?.data?.competitorResearch?.paidTraffic || 0, 1)?.toString()?.replace('.0', '') ?? '-'}</b></h2>
          </div>
          <p>per month</p>
          <LineChart boundaryGap={false} gridBottom={0} chartHeight='20px' chartWidth='90px' seriesName='Paid Traffic' loading={false} xAxisData={filteredData?.map(item => item.paidTraffic)} color='#F1AA3E' data={filteredData?.map(item => ({value: item.paidTraffic, date: item.date}))}
            tooltipPositionTop='180%' />
        </BlockWrapper>
      </div>}

    </div>
  );
});

const Wrapper = styled.div`
margin-top: 5px
`;

const Border = styled.div`
padding: 10px 10px 0 10px;
// border: 3px dotted #d5d3d3;
border-radius: 11px;
`;

const Title = styled.p`
  color: #121212;
  font-size: 14px !important;
  font-weight: 500;
`;

const BlockWrapper = styled(Border)`
height: 142px;
width: 140px;
margin-right: 10px;
div{
  display: flex;
  align-items: center;
}
.range{
  margin: 0px !important;
  font-size: 24px;
  font-weight: 500 !important;
  margin-right: 0px  !important;
}
.h2Style{
  font-size: 24px;
  font-weight: 500;
  margin: 0 10px 0 10px;
}
p{
  font-size: 12px;
  margin: 0px 0 0 0;
}
.progress{
  color: #49b949;
}
`;
