import {faCaretUp, faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Tooltip} from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, {useState} from 'react';
import styled from 'styled-components';
import {StyledDivider} from '../styles';
import {useStore} from '@/store/root-store';
export const IncidentsModal = ({incidents}) => {
  const {settings: {customer: {profile: {whitelabelOtto}}}} = useStore('');

  const getCurrentDaySchedule = schedule => {
    const today = moment().format('dddd').toLowerCase();
    const todaySchedule = schedule[today]?.intervals;

    if (todaySchedule?.length) {
      const {open, close} = todaySchedule[0];
      return `${capitalize(today)}: ${formatTime(open)} - ${formatTime(close)}`;
    }
    return `${capitalize(today)}: Closed`;
  };

  const formatTime = time => moment(time, 'HH:mm').format('hh:mm A');

  const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);

  const isValidUrl = value => {
    const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/.*)?$/;
    return urlRegex.test(value);
  };

  const renderContent = data => {
    if (typeof data === 'string') {
      return data;
    } else if (Array.isArray(data)) {
      const hasLabels = data.every(item => typeof item === 'object' && item !== null && 'label' in item);
      const hasDisplayName = data.every(item => typeof item === 'object' && item !== null && 'displayName' in item);
      const hasServiceTyeId = data.every(item => typeof item === 'object' && item !== null && 'serviceTypeId' in item);
      if (data?.length === 0) {
        return '-';
      } else if (hasLabels) {
        return data.map(item => item?.label).join(', ');
      } else if (hasDisplayName) {
        return data.map(item => item?.displayName).join(', ');
      } else if (hasServiceTyeId) {
        return data.map(item => item?.serviceTypeId?.label).join(', ');
      } else if (data?.length && typeof data[0] === 'object' && (data[0]?.endDate || data[0]?.endDate || data[0]?.isClosed || data[0]?.startDate)) {
        const startDate = new Date(data[0]?.startDate?.year, data[0]?.startDate?.month, data[0]?.startDate?.day);
        const endDate = new Date(data[0]?.endDate?.year, data[0]?.endDate?.month, data[0]?.endDate?.day);
        const formattedStartDate = moment(startDate).format('MMM D, YYYY');
        const formattedEndDate = moment(endDate).format('MMM D, YYYY');
        return `${formattedStartDate} - ${formattedEndDate} ${data[0]?.isClosed ? 'Closed' : 'Opened'}`;
      }
      return data.join(', ');
    } else if (typeof data === 'object' && data !== null) {
      const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
      const isSchedule = daysOfWeek.some(day => data[day]?.intervals);
      if (isSchedule) {
        return getCurrentDaySchedule(data);
      }
      if (data?.status === 'OPEN' || data?.status === 'CLOSED_TEMPORARILY') {
        if (data?.openingDate) {
          const date = new Date(data?.openingDate?.year, data?.openingDate?.month, data?.openingDate?.day);
          const formattedDate = moment(date).format('MMM D, YYYY');
          return `${data?.status === 'OPEN' ? 'Open on' : 'Closed temporarily opening on'} ${formattedDate}`;
        } else {
          return `-`;
        }
      } else {
        return JSON.stringify(data);
      }
    } else if (typeof data == 'boolean') {
      return data;
    }
    return '-';
  };

  const renderTooltipContent = data => {
    if (typeof data === 'object' && data !== null && data.friday) {
      return Object.keys(data).map(day => {
        const intervals = data[day]?.intervals;
        if (intervals?.length) {
          const {open, close} = intervals[0];
          return `${capitalize(day)}: ${formatTime(open)} - ${formatTime(close)}`;
        }
        return `${capitalize(day)}: Closed`;
      }).join(', ');
    }
    return renderContent(data);
  };
  const renderImage = (image, idx) => (
    ((!image.format || image.format !== 'VIDEO') && image?.url!==null) && (
      <img
        style={{cursor: 'pointer'}}
        key={idx}
        width={200}
        src={image.url}
      />
    )
  );

  const dataCell = (data, width) => {
    if (data?.fieldType=='MEDIA') {
      const images = Array.isArray(data.value) ? data.value : [data.value];
      return (
        <StyledImages>
          {images.map((image, idx) => (
            image && renderImage(image, idx)
          ))}
        </StyledImages>
      );
    } else {
      return (
        <BodyCell style={{marginRight: 5, height: 38, maxWidth: '400px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}} width={width}>
          <Tooltip title={renderTooltipContent(data?.value)} placement='topRight'>
            {isValidUrl(renderContent(data?.value)) ? <a
              href={
                typeof renderContent(data?.value) === 'string' ?
                  (renderContent(data?.value) as string).includes('https') || (renderContent(data?.value) as string).includes('http') ?
              renderContent(data?.value) as string :
                    `https://${renderContent(data?.value) as string}` :
                  ''
              }
              style={{color: '#2D6CCA', maxWidth: '400px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}
              target='_blank'
              rel='noopener noreferrer'
            >{renderContent(data?.value)}</a> : <span style={{maxWidth: '400px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{renderContent(data?.value)}</span>}
          </Tooltip>
        </BodyCell>
      );
    }
  };

  const [filterOttoAi, setFilterOttoAi] = useState(null);

  const sortAscending = () => {
    setFilterOttoAi(prev => (prev === null ? true : prev === true ? null : true));
  };

  const sortDescending = () => {
    setFilterOttoAi(prev => (prev === null ? false : prev === false ? null : false));
  };

  const sortedIncidents = filterOttoAi === null ?
    incidents :
    [...incidents].sort((a, b) => {
      const isOttoAiA = a?.source === 'OTTO_AI' && a?.eventType === 'LOCK_FIXED';
      const isOttoAiB = b?.source === 'OTTO_AI' && b?.eventType === 'LOCK_FIXED';
      if (filterOttoAi) {
        return isOttoAiA === isOttoAiB ? 0 : isOttoAiA ? -1 : 1;
      } else {
        return isOttoAiA === isOttoAiB ? 0 : isOttoAiA ? 1 : -1;
      }
    });

  const getIconColor = (direction: 'up' | 'down') => {
    if (filterOttoAi === null) return '#A3A4A4';
    return (direction === 'up' && filterOttoAi === true) || (direction === 'down' && filterOttoAi === false) ?
      'rgb(45, 108, 202)' :
      '#A3A4A4';
  };

  const convertToHumanReadable = str => {
    const string = str === 'website_uri' ? 'website_url' : str;
    return _.startCase(
      string
        .toLowerCase()
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/_/g, ' '),
    );
  };

  return (
    <div>
      <StyledBodyContainer>
        <Header>
          <Title style={{fontWeight: 600}}>DATE AND TIME</Title>
          <Title style={{fontWeight: 600}}>ATTEMPTED TO CHANGE</Title>
          <Title style={{fontWeight: 600}} width={'400px'}>NEW VALUE</Title>
          <Title style={{fontWeight: 600, display: 'flex', alignItems: 'center'}}>STATUS<div style={{display: 'flex', flexDirection: 'column', marginLeft: '8px', lineHeight: 1, cursor: 'pointer'}}><FontAwesomeIcon icon={faCaretUp} style={{marginBottom: -6, color: getIconColor('up')}}onClick={sortAscending}/>
            <FontAwesomeIcon icon={faCaretDown} color={getIconColor('down')} onClick={sortDescending}/></div></Title>
        </Header>
        {sortedIncidents?.length ? sortedIncidents?.map((incident, index) => {
          const isOttoAi = incident?.source=='OTTO_AI' && incident?.eventType=='LOCK_FIXED';
          return (<><BodyRow fieldType={incident?.fieldType} key={index}>
            <BodyCell>{moment(incident?.createdAt).format('MMM D, YYYY [at] h:mm A')}</BodyCell>
            <BodyCell>{convertToHumanReadable(incident?.fieldName)}</BodyCell>
            {dataCell(incident, '400px')}
            <Status color={isOttoAi ? '#1FAC4712' : '#F4434312'}><Circle color={isOttoAi ? '#2AC155' : '#F44343'} />{isOttoAi ? `Prevented by ${whitelabelOtto}` : 'Attempt submitted to Google'}</Status>
          </BodyRow><StyledDivider style={{width: 1170}} /></>);
        }) : <NoDataDiv>No Incidents Available</NoDataDiv>}
      </StyledBodyContainer>
    </div>
  );
};

const Header = styled.div`
  background-color: #F2F2F5;
  height: 40px;
  width: 1170px;
  border-radius: 8px;
  display: flex;
  padding: 13px;
`;
const Title = styled.div<{width?: string}>`
  font-size: 12px;
  width: ${p => p?.width ? p?.width : '230px'};
`;
const BodyRow = styled.div<{fieldType?: string}>`
  max-height: ${p => p?.fieldType=='MEDIA' ? '120px' : '38px'};
  height: ${p => p?.fieldType!=='MEDIA' && '38px'};
  width: 1170px;
  display: flex;
  padding: 12px 13px;
  align-items: center;
`;
const BodyCell = styled.div<{width?: string}>`
  font-size: 14px;
  width: ${p => p?.width ? p?.width : '230px'};
  height: 38px;
  display: flex;
  align-items: center;
`;

const Status = styled.div<{color}>`
display: flex;
padding: 9px 12px;
gap: 6px;
border-radius: 6px;
height: 32px;
align-items: center;
  background-color: ${p => p?.color};
`;
const Circle = styled.div<{color}>`
  width: 8px;
  height: 8px;
  background-color: ${p => p?.color};
  border-radius: 50%;
`;

const StyledBodyContainer = styled.div`
  max-height: 600px;
  overflow: auto;
  overflow-y: auto;

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #4E515680;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #F9F9FB;
    border-radius: 10px;
  }
`;
const StyledImages = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 400px;
  padding-right: 15px;
  max-height: 120px;
  overflow: auto;
  overflow-y: auto;
  margin-right: 5px;

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #4E515680;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #F9F9FB;
    border-radius: 10px;
  }
img{
  border-radius: 8px;
  width: 60px;
  height: 50px;
}
`;
export const NoDataDiv = styled.div`
  width: 100%;
  text-align: center;
  height: 250px;
  font-size: x-large;
  color: gray;
  padding: 100px;
`;
