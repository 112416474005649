import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {CancelButton, ApplyButton, BothBtnWrapper, CustomRangeContainer} from '../styledComponents';
import {CustomTextarea} from '@/components/common-components/components/form/input/customTextarea';
import {CheckboxStyled} from '@/components/common-components/components/searchBarV2/style';

interface Props {
  type?: string;
  filterId?: number;
  filterLabel?: string;
  filterName?: string;
  onChange?: (filter:any)=>void;
  className?: string;
  filterField?: string;
  customFields?: any[];
  headerField?: any;
  isDisable?:boolean;
  isClear?: boolean;
  onClear?: (data: any)=>void;
  absoluteUi?: boolean;
  isPageExplorer?: boolean;
  queryVal?: string;
  popRef?: any;
  brandedKeywordsList: any;
  onApplyClick: any;
}

const FilterBrandedKeywordPopup: React.FC<Props> = ({type, queryVal, filterField, headerField, filterId, customFields, filterLabel, filterName, onChange, isPageExplorer, brandedKeywordsList, onApplyClick}) => {
  const [operator, setOperator] = useState(headerField?.operator ||'contains');
  const [query, setQuery] = useState('');
  const [text, setText] = useState('');
  const [isExcludeInclude, setIsExcludeInclude] = useState({isInclude: false, isExclude: false});

  useEffect(() => {
    if (type) {
      setOperator(type);
    }
  }, [type]);

  useEffect(() => {
    if (queryVal == 'branded_keyword_filter=false') {
      setIsExcludeInclude({isInclude: false, isExclude: true});
    } else if (queryVal == 'branded_keyword_filter=true') {
      setIsExcludeInclude({isInclude: true, isExclude: false});
    } else {
      setIsExcludeInclude({isInclude: false, isExclude: false});
    }
  }, [queryVal]);

  useEffect(() => {
    if (brandedKeywordsList?.length) {
      setText(brandedKeywordsList?.join('\n'));
    }
  }, [brandedKeywordsList]);

  useEffect(() => {
    if (isExcludeInclude.isExclude) {
      setQuery('branded_keyword_filter=false');
    } else if (isExcludeInclude.isInclude) {
      setQuery('branded_keyword_filter=true');
    } else {
      setQuery('');
    }
  }, [isExcludeInclude]);

  const createOrUpdateFilter = async e =>{
    e.preventDefault();
    await onApplyClick(text);
    emitOnChanges(operator, query);
  };

  const emitOnChanges = (operator: string, query: string) => {
    if (typeof onChange === 'function') {
      onChange({
        id: filterId,
        header: filterLabel,
        name: filterName,
        type: '',
        operator: operator,
        query: query,
        filterField: filterField,
        active: true,
        customFields: customFields,
        checked: false,
        isUrl: filterLabel == 'url' && isPageExplorer ? true : false,
      });
    }
  };

  const disablingButton = () =>{
    if (query=='') {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Wrapper>
      <CheckboxWrapper style={{display: 'flex', flexDirection: 'column'}}>
        <CheckboxStyled style={{fontSize: '14px', color: '#121212'}} checked={isExcludeInclude.isExclude} onChange={() => setIsExcludeInclude({isExclude: !isExcludeInclude.isExclude, isInclude: false})}>Exclude branded keywords</CheckboxStyled>
        <CheckboxStyled style={{fontSize: '14px', color: '#121212'}} checked={isExcludeInclude.isInclude} onChange={() => setIsExcludeInclude({isExclude: false, isInclude: !isExcludeInclude.isInclude})}>Show only branded keywords</CheckboxStyled>
      </CheckboxWrapper>
      <hr />
      <div>
        <h3>Enter your branded keywords here:</h3>
        <p>We recommend adding branded keywords that are unique to your brand, not generic terms. Most sites have a single branded keyword.</p>
        <CustomTextarea autoSize={true}
          rows={4}
          minHeight='90px'
          variant='light'
          autoSizeCheck={true}
          placeholder={'Enter a keywords like "Garden Tools"'}
          onChange={e=> setText(e.target.value)}
          value={text}
        ></CustomTextarea>
      </div>
      <BothBtnWrapper style={{marginTop: '20px'}}>
        <ApplyButton
          disabled={disablingButton()}
          onClick={createOrUpdateFilter}
        >
          Apply
        </ApplyButton>
        <CancelButton
          border
        >
          Cancel
        </CancelButton>
      </BothBtnWrapper>
    </Wrapper>
  );
};

export default FilterBrandedKeywordPopup;

const CheckboxWrapper = styled.div`
  .ant-checkbox-wrapper {
    margin-left: 0 !important;
    span {
      font-size: 13px;
    }
  }
`;

const Wrapper = styled(CustomRangeContainer)`
  hr {
    width: 100%;
    background: #ececec;
    border: 1px solid #ececec;
    margin: 0;
  }
  h3 {
    font-size: 14px;
    margin: 0;
  }
  p {
    font-size: 12px;
    color: gray;
  }
  button {
    margin-bottom: 0;
  }
`;
