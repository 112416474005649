import React, {useEffect, useState} from 'react';
import UserMenu from '@/components/layoutV2/user-menu';
import {observer} from 'mobx-react-lite';
import styles from './style.module.scss';
import classnames from 'classnames';
import {RootStoreType, useStore} from '@/store/root-store';
import {Popover, notification as antdNotification} from 'antd';
import {useRouter} from 'next/router';
import {PendingInvoiceModal} from '@/components/dashboard/billing/modals/pending-invoices';
import {ProjectActiveModal} from '@/components/common-components/components/project-active-modal/project-active';
import {ConfettiAnimation} from '@/components/common-components/components/confetti-animation';
import firebase from 'firebase/app';
import 'firebase/database';
import {getSingleUrlParam} from '@/utils/url';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {MenuItemsBar} from '../menu/menuItems';
import {useLocalStorage} from '@/utils/hooks/useLocalStorage';
import styled, {keyframes} from 'styled-components';
import {NoSsr} from '@/components/common-components';
import {getTokenFromCookies} from '@/api/common-utils';
import {faStarHalf, faStar} from '@fortawesome/pro-duotone-svg-icons';
import {ScaTopUpQuotaModal} from './headerComponents/scaTopUpQuotaModal';
import WhatsNewModal from './whats-new-modal';
import moment from 'moment';
import {faSortDown} from '@fortawesome/pro-solid-svg-icons';
import {OnBoardingPopup} from './onboarding-popup';
import {faBell} from '@fortawesome/pro-regular-svg-icons';
import NotificationsPopupV2 from './notification-popup-v2';
import {routes} from '@/utils/const';
import {NextImg} from '@/utils/nextImg';

type HeaderProps = {
  store: RootStoreType;
  handleSideBarItems?: (items) => void;
};

const config = {
  apiKey: 'AIzaSyAAnv2XuzvhHREv_x9tPOKgtEhCgCpkRzo',
  authDomain: 'organic-ruler-207123.firebaseapp.com',
  databaseURL: 'https://organic-ruler-207123-default-rtdb.firebaseio.com',
  projectId: 'organic-ruler-207123',
  storageBucket: 'organic-ruler-207123.appspot.com',
  messagingSenderId: '88261415951',
  appId: '1:88261415951:web:db784e7f23f543a1b3ff7a',
  measurementId: 'G-8CMC6QJNNS',
};

!firebase.apps.length ? firebase.initializeApp(config) : firebase.app();

const Header = observer(({store, handleSideBarItems}: HeaderProps) => {
  const router = useRouter();
  const checkPublicPages = router.pathname?.includes('public');
  const [showNotificationsModal, setShowNotificationsModal] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleOnboarding, setVisibleOnboarding] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(false);
  const [highlightNotification, setHighlightNotification] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState<
    number | null
  >(null);
  const [isFullStar, setIsFullStar] = useState(false);
  const [postOnBoardingNotifications, setPostOnBoardingNotifications] = useState([]);

  const {
    notifications: {setNotifications, triggerNotSeen},
    gsc: {setDrawerVisibility},
    settings: {
      customer: {
        setShowScaTopUpModal,
        showTopUpConfirmationModal,
        loadProfile,
        isGHLAccount,
        loadCustomerNotifications,
        notificationsNextPage,
        customerNotifications: {
          results: {mostRecentlyUpdatedAt, data: notificationsData},
        },
        profile: {isVerified, isViewer, isParentAccount, logo},
        setAuthModal,
        getPostOnboardingNotifications,
      },
    },
    contentOptimizer: {
      currentPage: {isEditorScrolled},
    },
    navBar: {
      setIsDockedSider,
      loadSiteProperties,
      getSiteProperties,
    },
  } = useStore('');

  const setNotificationModalVisibility = (visibility: boolean) => {
    if (visibility) {
      if (unreadNotifications) {
        setHighlightNotification(true);
      }
      setUnreadNotifications(false);
      if (mostRecentlyUpdatedAt) {
        localStorage.setItem('notificationsUpdatedAt', mostRecentlyUpdatedAt);
      }
    } else {
      setHighlightNotification(false);
      setSelectedNotification(null);
    }
    setShowNotificationsModal(visibility);
  };
  const [customerLogo, setCustomerLogo] = useState(logo);
  const token = getTokenFromCookies();

  useEffect(() => {
    try {
      const showModal = JSON.parse(localStorage.getItem('showUpdates'));
      const notificationsLastUpdatedAt = localStorage.getItem(
        'notificationsUpdatedAt',
      );
      let hasUnreadNotifications = false;
      if (!notificationsLastUpdatedAt) {
        hasUnreadNotifications = true;
      }
      if (
        notificationsLastUpdatedAt?.length > 0 &&
        mostRecentlyUpdatedAt?.length > 0
      ) {
        const newerDate = moment(mostRecentlyUpdatedAt);
        const olderDate = moment(notificationsLastUpdatedAt);
        if (newerDate?.isAfter(olderDate)) {
          hasUnreadNotifications = true;
        }
      }
      if (!notificationsData?.length) {
        hasUnreadNotifications = false;
      }
      setUnreadNotifications(hasUnreadNotifications);
      if (
        showModal &&
        hasUnreadNotifications &&
        notificationsData?.length &&
        !isWhitelabel
      ) {
        setHighlightNotification(true);
        setNotificationModalVisibility(true);
      }
    } catch (error) {
      setNotificationModalVisibility(false);
    }
  }, [mostRecentlyUpdatedAt]);


  useEffect(() => {
    setCustomerLogo(logo);
  }, [logo]);

  const allowedPagesForSitesAPI = ['gsc'];
  const currentPathname = window.location.pathname;
  const isAllowedPage = allowedPagesForSitesAPI.some(page =>
    currentPathname.includes(page),
  );

  useEffect(() => {
    notificationsNextPage === 1 && loadCustomerNotifications();
    if (token) {
      loadProfile();
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      if (!getSiteProperties?.length && isAllowedPage) {
        loadSiteProperties();
      }
    }
  }, [token, currentPathname]);

  // Kill all notifications when changing page
  useEffect(() => {
    antdNotification.destroy();
  }, [router]);
  const [isDocked] = useLocalStorage<boolean>('isDocked', true);

  useEffect(() => {
    setIsDockedSider(isDocked);
  }, [isDocked]);

  const {
    plans: {projectActivePopup},
    settings: {
      customer: {profile},
    },
  } = useStore('');
  const {
    settings: {
      customer: {
        profile: {
          isWhitelabel,
          hasCompletedRegistration,
          showConfettiAnimation,
          isVendastaClient,
        },
      },
    },
  } = useStore('');
  const {isSubscriber, id, firebaseKey} = profile;
  const isBacklinkAnalyzer = router.pathname.match(/\/backlink.*/g);
  const showProjectsPicker =
    !store.backLinkAnalyzer.projectStore.loading &&
    store.backLinkAnalyzer.projectStore.currentProject;
  const [screenWidth, setScreenWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 0,
  );

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const isSmallScreen = screenWidth <= 768;
  const isMediumScreen = screenWidth <= 980;

  useEffect(() => {
    if (firebaseKey) {
      const dbRef = firebase.database().ref(firebaseKey).limitToLast(30);
      dbRef.on('value', snapshot => {
        if (snapshot.exists()) {
          const notifications = snapshot.val();
          setNotifications(snapshot.val());
          if (
            !Object.keys(notifications).every(notificationKey =>
              notifications[notificationKey]['was_seen_by_users']?.includes(id),
            )
          ) {
            triggerNotSeen();
          }
        }
      });
    }
  }, [firebaseKey, id, setNotifications, triggerNotSeen]);

  const getProjectFromUrl = () => {
    const backlinkProject = getSingleUrlParam('sp');
    if (backlinkProject) {
      store.backLinkAnalyzer.projectStore.setCurrentProject(
        backlinkProject,
        isSubscriber,
      );
      store.backLinkAnalyzer.refdomainStore.selectCurrentProject(
        backlinkProject,
      );
    }
  };

  const pendingInvoice = store.invoices.getPendingInvoices?.filter(item => item?.type !== 'customer_plan').filter(
    item =>
      ['pending', 'failed'].includes(item.status?.toLowerCase()) &&
      !['order', 'ai_quota_top_up'].includes(item.type),
  );
  if (pendingInvoice.length > 0) {
    store.invoices.openModal();
  }
  useEffect(() => {
    if (pendingInvoice?.length == 0) {
      store.invoices.closeModal();
    }
  }, []);

  useEffect(() => {
    if (showProjectsPicker && isBacklinkAnalyzer) {
      getProjectFromUrl();
    }
  }, [showProjectsPicker, isBacklinkAnalyzer]);

  // close sca top up modal on dismount
  useEffect(() => {
    return () => {
      setShowScaTopUpModal(false);
    };
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setIsFullStar(prevState => !prevState);
    }, 500);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (getPostOnboardingNotifications?.length) {
      setPostOnBoardingNotifications(getPostOnboardingNotifications);
    }
  }, [JSON.stringify(getPostOnboardingNotifications)]);

  const shouldHideNotificationIcon = () => {
    const isWhitelabelChild = isWhitelabel && !isParentAccount;
    return isVendastaClient || isWhitelabelChild || isGHLAccount;
  };

  return (
    <NoSsr>
      <WrapperMain
        id='HEADER_MAIN'
        isEditorScrolled={isEditorScrolled}
        style={{
          padding: isEditorScrolled ? '0' : '15px 15px 0 15px',
          height: isEditorScrolled ? '0' : '70px',
          transition: 'all 0.2s ease',
          display: 'flex',
        }}
        className='bg-main-color'
      >
        <div >
          {isWhitelabel && (!customerLogo || customerLogo?.includes('/Logo_SVG.svg')) ? (
            <div
              onClick={() => setDrawerVisibility(true)}
              style={{
                fontSize: '20px',
                color: '#E8E8E8',
                marginLeft: '5px',
                alignItems: 'center',
                transition: 'all 0.2s ease',
                overflow: 'hidden',
              }}
            ></div>
          ) : (
            <div className={styles.logoWrapper} style={{minWidth: '273px'}}>
              <NextImg
                className={`${styles.hideimg} logo-responsive`}
                style={{
                  height: '100%',
                  padding: 5,
                  overflow: 'hidden',
                  marginLeft: '5px',
                  display: 'flex',
                  alignItems: 'center',
                  transition: 'all 0.2s ease',
                  width: isWhitelabel ? '88px' : '207px',
                  objectFit: 'contain',
                }}
                onClick={
                  checkPublicPages && !isSubscriber ?
                    () => setAuthModal(true, true) :
                    () => router.push(routes?.home)
                }
                src={
                  isWhitelabel && customerLogo ?
                    customerLogo :
                    '/img/logo/searchAtlas_new_login.svg'
                }
                alt='logo'
                // onError={e => addDefaultSrc(e)}
              />
            </div>
          )}
        </div>
        <div
          className={styles.header}
          style={{display: isEditorScrolled ? 'none' : 'flex', paddingLeft: '40px'}}
        >
          <PendingInvoiceModal
            visible={
              store.invoices.visibleModal &&
              pendingInvoice.length > 0 &&
              !isViewer && !isVendastaClient
            }
            closable={true}
            content={[
              ...pendingInvoice.map(invoice => {
                return {
                  order: invoice['order'] ?? '',
                  status: invoice.status,
                  slug: invoice.slug,
                  createdAt: invoice.createdAt,
                  amount: invoice.amount,
                  type: invoice.type,
                };
              }),
            ]}
            account={store.settings.customer.profile}
            onClose={store.invoices.closeModal}
          />
          {showTopUpConfirmationModal && <ScaTopUpQuotaModal />}
          {projectActivePopup && (
            <ProjectActiveModal visible={projectActivePopup} closable={true} />
          )}

          {hasCompletedRegistration && showConfettiAnimation && (
            <ConfettiAnimation />
          )}

          <MenuItemsBar
            isSmallScreen={isSmallScreen}
            isMediumScreen={isMediumScreen}
            handleSideBarItems={handleSideBarItems}
          />
          <UserMenuRight
            style={{
              display: 'flex',
              flexGrow: 1,
              position: 'sticky',
            }}
          >
            {
              !isGHLAccount && postOnBoardingNotifications.filter(notification => notification?.status === 'unread').length ?
                <Popover
                  overlayClassName={`${styles.onBoardingPopover}`}
                  content={
                    <OnBoardingPopup notifications={postOnBoardingNotifications} setVisibleOnboarding={setVisibleOnboarding}/>
                  }
                  showArrow={false}
                  trigger={'click'}
                  placement='bottomRight'
                  overlayInnerStyle={{
                    overflow: 'hidden',
                    minWidth: 400,
                    maxHeight: 862,
                    boxShadow: '1px 3px 3px 1px #0000002B',
                    borderRadius: '12px',
                    border: '1px solid #E8E8E8',
                  }}
                  visible={visibleOnboarding}
                  onOpenChange={e => setVisibleOnboarding(e)}
                  mouseEnterDelay={0}
                  mouseLeaveDelay={0}
                >
                  <GradientButton>
                    {isFullStar ?
                      <FontAwesomeIcon icon={faStar} color='#B98CE4' fontSize={14} />:
                      <FontAwesomeIcon icon={faStarHalf} color='#B98CE4' fontSize={14} />
                    }
                      Get Started {(postOnBoardingNotifications.filter(notification => notification?.status === 'read').length/postOnBoardingNotifications.length)*100}%
                    <FontAwesomeIcon icon={faSortDown} color='#ffffff' fontSize={14} style={{marginTop: -6}}/>
                  </GradientButton>
                </Popover> : <></>
            }
            {!checkPublicPages || isVerified ? (
              <>
                {!shouldHideNotificationIcon() && <div className={classnames(styles['header__buttons'])}>
                  <div className={styles.IconsHeader}>
                    <Popover
                      overlayClassName={`${styles.notificationPopover} hide-arrow ${styles.width100}`}
                      showArrow={false}
                      content={
                        <NotificationsPopupV2
                          setSelectedNotification={setSelectedNotification}
                          setNotificationModalVisibility={
                            setNotificationModalVisibility
                          }
                          visible={visible}
                          setVisible={setVisible}
                        />
                      }
                      visible={visible}
                      trigger={showNotificationsModal ? 'hover' : 'click'}
                      placement='bottomRight'
                      onOpenChange={e => setVisible(e)}
                      overlayInnerStyle={{
                        maxWidth: 420,
                        width: isSmallScreen ? 'auto' : 420,
                        maxHeight: 507,
                        padding: '0',
                        background: '#FFF',
                        boxShadow: '0px 3px 2px 0px rgba(0, 0, 0, 0.02)',
                        borderRadius: '12px',
                        border: '1px solid #E8E8E8',
                        overflow: 'hidden',
                      }}
                      mouseEnterDelay={0}
                      mouseLeaveDelay={0}
                    >
                      <div style={{position: 'relative', display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                        <StyledIcon style={{height: '12px'}} icon={faBell} color='white' /> <p style={{fontSize: '11px', margin: '0px', color: '#e8e8e8'}}>Notification</p>
                        {unreadNotifications && <YellowDot></YellowDot>}
                      </div>
                    </Popover>
                  </div>
                </div>}
                <div className={styles['header__user-menu']}>
                  <UserMenu
                    name={store.settings.customer.profile.fullName}
                    email={store.settings.customer.profile.emailAddress}
                  />
                </div>
                {showNotificationsModal && (
                  (isWhitelabel && !isParentAccount) ? null : (
                    <WhatsNewModal
                      selectedNotificationId={selectedNotification}
                      highlightLatest={highlightNotification}
                      visible={showNotificationsModal}
                      setVisible={setNotificationModalVisibility}
                    />
                  )
                )}
              </>
            ) : (
              ''
            )}
          </UserMenuRight>
        </div>
      </WrapperMain>
    </NoSsr>
  );
});

export default Header;

const WrapperMain = styled.div<{ isEditorScrolled?: boolean }>`
  padding: ${p => (p.isEditorScrolled ? '0 !important' : '15px 15px 0 15px')};
  @media screen and (max-width: 768px) {
    padding-left: 8px !important;
    padding-right: 8px !important;
    padding-top: 6px !important;
  }
`;


const StyledIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  width: 20px;
  @media screen and (max-width: 768px) {
    width: 30px !important;
  }
`;

const UserMenuRight = styled.div`
  display: flex;
  position: sticky;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  @media screen and (max-width: 560px) {
    position: static;
  }
`;

// Gradient border animation
const gradientBorder = keyframes`
  50% {
      background-position: 100% 30%;
    }
`;

const GradientButton = styled.div`
  --border-width: 2px;
  position: relative;
  display: flex;
  padding: 1px 6px;
  gap: 6px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: white;
  background: #222;
  border-radius: 4px;
  cursor: pointer;
  width: 174px;

  &::after {
    position: absolute;
    content: "";
    top: calc(-1 * var(--border-width));
    left: calc(-1 * var(--border-width));
    z-index: -1;
    width: calc(100% + var(--border-width) * 2);
    height: calc(100% + var(--border-width) * 2);
    background: linear-gradient(
      50deg,
      hsl(270, 53.6%, 50.98039215686274%),
      hsl(280, 55.46218487394956%, 53.333333333333336%),
      hsl(290, 60%, 64%),
      hsl(300, 55%, 60%),
      hsl(310, 50%, 56%),
      hsl(320, 55%, 62%),
      hsl(330, 86.27450980392159%, 80%)
    );
    background-size: 300% 300%;
    background-position: 0 50%;
    border-radius: 4px;
    animation: ${gradientBorder} 4s alternate infinite;
  }
`;
const YellowDot = styled.div`
  width: 14px;
  height: 14px;
  background-color: #f1aa3e;
  border-radius: 9999px;
  position: absolute;
top: -10px;
    right: 58px;
        font-size: 9px;
    color: white;
    align-items: center;
    text-align: center;
    font-weight: 600;
`;
