import {makeAutoObservable} from 'mobx';

class UIStore {
  activeComponent = null;

  constructor() {
    makeAutoObservable(this);
  }

  setComponent(component, isSearch, list) {
    this.activeComponent = {component: component, isSearch: isSearch, list: list};
  }
}

export const headerStore = new UIStore();
