import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleCheck, faCircleExclamation, faTriangleExclamation} from '@fortawesome/pro-duotone-svg-icons';
import {faPen} from '@fortawesome/pro-solid-svg-icons';
import {Tooltip} from 'antd';
import moment from 'moment';
import {observer} from 'mobx-react';
import FreezeWrapper from '../../../freezTooltip';
import {PopupContainer, IntervalLabel, DayLabel, IntervalsContainer} from '../styles';

const OpenHoursField = observer(({field, openModal, suggestion, isInKnowledgeGraph}) => {
  const [iconDetails, setIconDetails] = useState({
    color: null,
    icon: null,
  });
  useEffect(() => {
    if (suggestion?.value) {
      const isValueNull = field?.value && Object.values(field?.value)?.some((item: any) => item.intervals?.length);
      if (isValueNull) {
        if (suggestion) {
          setIconDetails({
            color: '#FF8536',
            icon: faTriangleExclamation,
          });
        } else {
          setIconDetails({
            color: '#2AC155',
            icon: faCircleCheck,
          });
        }
      } else {
        setIconDetails({
          color: '#F44343',
          icon: faCircleExclamation,
        });
      }
    }
  }, [field?.value]);

  const handlePopupClick = e => {
    e.preventDefault();
    e.stopPropagation();
    openModal(suggestion, field?.value);
  };


  const getInterval = (interval: any) => {
    if (interval?.close == '24') {
      return '24 hours';
    } else {
      return `${moment(interval?.open, 'HH:mm').format('hh:mm A')} - ${moment(interval?.close, 'HH:mm').format('hh:mm A')}`;
    }
  };

  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  return (
    <>
      <Tooltip title='' overlayInnerStyle={{backgroundColor: '#121212', borderRadius: '8px', border: '1px solid #121212'}}>
        {iconDetails?.icon ? <FontAwesomeIcon
          style={{marginRight: 6, marginTop: '2px'}}
          color={iconDetails.color}
          icon={iconDetails.icon}
        /> : !isInKnowledgeGraph && <div style={{width: 18}}></div>}
      </Tooltip>
      <PopupContainer style={{width: 'calc(100% - 60px)'}}>
        <div>
          {days.map(item => (
            <IntervalLabel key={item} style={{alignItems: field?.value?.[item.toLowerCase()]?.intervals?.length > 1 && 'flex-start'}}>
              <DayLabel>
                <span>{item}</span>
              </DayLabel>
              <IntervalsContainer>
                {field?.value?.[item.toLowerCase()]?.intervals?.length ? (
                  field?.value?.[item.toLowerCase()]?.intervals?.map((interval, idx) => (
                    <div key={item + '_' + idx}>{getInterval(interval)}</div>
                  ))
                ) : (
                  <div>Closed</div>
                )}
              </IntervalsContainer>
            </IntervalLabel>
          ))}        </div>
      </PopupContainer>
      <div className='edit-icon'>
        <FreezeWrapper>
          <Tooltip title='Edit'>
            <div onClick={e => handlePopupClick(e)}>
              <FontAwesomeIcon
                style={{marginRight: 6}}
                icon={faPen}
                color='#2D6CCA'
              />
            Edit
            </div>
          </Tooltip>
        </FreezeWrapper>
      </div>
    </>
  );
});

export default OpenHoursField;

