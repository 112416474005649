import styled from 'styled-components';
import {Button} from '@/components/common-components/components';

export const TextOverflow = styled.div<{isBoldTxt?: boolean}>`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${p => p?.isBoldTxt ? `
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    color: #121212;
  ` : ''}
`;

export const FlexWrapper = styled.div<{align?: string; justifyContent?: string}>`
  display: flex;
  padding: 0 10px 0 35px;
`;

export const HeaderCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #7f4ead;
    border-color: #7f4ead;
  }
`;

export const ImgUser = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 8px;
`;

export const BodyWrapper = styled.div<{isUnread?: boolean}>`
  width: 100%;
  font-size: 12px !important;
  line-height: 14px;
  color: #4E5156;
  white-space: pre-line;
  overflow-wrap: anywhere;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  strong {
    font-size: 0.8rem !important;
  }
  img {
    width: 30px !important;
  }
  ${p => p?.isUnread ? `
    * {
      color: #121212 !important;
    }
  ` : `
    * {
      color: #4E5156 !important;
    }
  `}
`;

export const HeadingWrapper = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #121212;
`;

export const WhiteCard = styled.div<{width?: string; margin?: string; position?: string}>`
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.02);
  border-radius: 12px;
  width: ${p => p.width ?? '50%'};
  ${p => p?.margin && `margin: ${p?.margin}`};
  ${p => p?.position && `position: ${p?.position}`};
`;

export const Wrapper = styled.div`
  margin-top: -270px;
`;

export const HeroSection = styled.div<{minHeight?: string}>`
  background-color: #0C0E12;
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.02);
  color: #fff;
  display: flex;
  flex-direction: column;
  min-height: ${p => p.minHeight ? p.minHeight : '363px'};
  padding: 40px 40px 0;
  width: 100%;
`;

export const PaginationWrapper = styled.div`
  padding: 20px 25px;
  display: flex;
  justify-content: flex-end;
`;

export const TableHeaderSeparated = styled.div`
  align-items: center;
  background: #fff;
  display: flex;
  line-height: 22px;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
  border-radius: 12px 12px 0px 0px;
  justify-content: space-between;
  b {
    max-width: 400px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const TableWrapper = styled.div`
  background: #fff;
  border-radius: 0 0 12px 12px;
  padding: 10px 10px 0 10px;
  margin-bottom: 20px;

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
    background-color: transparent !important;
  }
  table {
    .ant-table-column-sorter {
      display: flex;
      padding-left: 3px !important;
    }

    .ant-table-column-sorters {
      display: inline-flex !important;
      padding: 5px !important;
    }

    .ant-table-tbody {
      tr:nth-child(even) {
        td {
          background: #fff !important;
          border-bottom: 1px solid #f4f4f4 !important;
          box-shadow: none;
          font-weight: 400;
          white-space: nowrap;
        }

        &:hover {
          td {
            background: #eef4fe !important;
          }
        }
      }

      tr:nth-child(odd) {
        td {
          background: #fff !important;
          border-bottom: 1px solid #f4f4f4 !important;
          box-shadow: none;
          font-weight: 400;
          white-space: nowrap;
        }

        &:first-child {
          td {
            border-bottom: 0 solid transparent !important;
          }
        }

        &:hover {
          td {
            background: #eef4fe !important;
          }
        }
      }
      tr td {
        padding: 5px 10px;
      }

    }

    .ant-table-thead {

      .ant-table-column-has-sorters {
        /* text-align: center !important; */
  
        .ant-table-column-title {
          text-align: center;
          z-index: 0 !important;
        }
      }

      th {
        background: #f7f7f7 !important;
        color: #121212 !important;
        font-size: 12px;
        font-weight: 600;
        line-height: 14px;
        padding: 0 !important;
      }

      .ant-table-cell-fix-left {
        text-align: left !important;
      }
    }

    tr th {
      height: 60px !important;
      line-height: 22px;
      white-space: nowrap;
    }

    tr th:first-child,
    tr td:first-child {
      padding-left: 25px !important;
      padding-right: 25px !important;
    }

    tr th:last-child,
    tr td:last-child {
      padding-left: 25px !important;
      padding-right: 25px !important;
      text-align: center;
    }
  }
`;

export const PaginationSpan = styled.span`
  color: #a3a4a4;
  padding: 40px 40px 0;
  width: 100%;
`;

export const CampaignName = styled.div``;

export const DateWrapper = styled.div``;

export const FirstColumnWrapper = styled.div`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: normal;
  font-style: normal;
  margin-left: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${CampaignName} {
  width: 100%;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  span{
  color: #2d6cca;
  cursor: pointer;
  }
  svg {
    cursor: pointer;
  }
}

${DateWrapper} {
  font-size: 12px;
  color: #4e5156;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
`;

export const ViewButton = styled(Button)``;

export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  ${ViewButton} {
    width: 60px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f9f9fB;
    border-radius: 4px;
    border: 1px solid #e8e8e8;
    color: #121212;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
  }
  svg {
  width: 6px;
  height: 30px;
  color: #4E5156;
  cursor: pointer;
  }
`;

export const RepliedAt = styled.div<{isBoldTxt?: boolean}>`
  ${p => p?.isBoldTxt ? `
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    color: #121212;
  ` : ''}
`;
