import {Pagination} from '@/components/common-components/components/pagination';
import {Button} from '@/components/common-components/v2/Button';
import {Button as ButtonV2} from '@/components/common-components/components';
import {Input, Select} from 'antd';
import styled from 'styled-components';
import {TableStyled} from '../../home-page-main/components/table-styled';

export const MainContainer = styled.div`

`;
export const TitleWrapper = styled.div`
font-weight: 700;
font-size: 28px;
color: black;
margin-bottom: 13px;
@media (max-width: 435px) {
  font-size: 20px;
}
`;
export const HeadWrapper = styled.div`
  // width: 960px;
  color: #FFFFFF;
  margin: auto;
  height: 90vh;
`;
export const DescriptionWrapper = styled.div`
font-weight: 400;
line-height: 20px;
color: #4E5156;
margin-top: 6px;
display: flex;
align-items: center;
`;
export const LocalScanWrapper = styled.div`
width: 100%;
height: 100%;
background: #e8e8e8;
border-radius: 12px;
margin-top: 27px;
position: relative;
`;

export const StyledSelectV2 = styled(Select)<{isOnMyBusinessPage?: boolean}>`
  .ant-select-dropdown {
    width: 465px !important;
    border-radius: 12px !important;
    border: 1px solid #D9D9D9 !important;
    box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.02) !important;
    margin-top: 10px !important;
    padding: 15px !important;
  }
  .ant-select-item-option {
    border-radius: 10px !important;
  }
  .ant-select-selector {
    height: 44px !important;
    outline: none;
    color: #121212;
    border: 1px solid #E8E8E8 !important;
    box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.02) !important;
    background: #fff;
    border-radius: 6px !important;
    padding-left: 16px;
    input {
      height: 100% !important;
    }
    .ant-select-selection-placeholder {
      color: #A3A4A4 !important;
      margin: auto;
      margin-top: 7px;
    }
    .ant-select-selection-item {
      margin: auto;
    }
  }
  @media (max-width: 532px) {
  .ant-select-dropdown {
    width: 365px !important;
    min-width: 365px !important;
  }
  }
  @media (max-width: 440px) {
    .ant-select-dropdown {
    width: 295px !important;
    min-width: 295px !important;
  }
  }
  ${p => p.isOnMyBusinessPage ? `
    @media screen and (max-width: 830px) {
      flex-grow: 1;
      max-width: 100%;
    }
    @media screen and (max-width: 540px) {
      min-width: 100% !important;
    }
    @media screen and (max-width: 600px) {
      width: auto !important;
    }
    @media (max-width: 480px) {
      .ant-select-dropdown {
        width: 100% !important;
        min-width: 100% !important;
      }
    }
  ` : ''}
`;

export const StyledInput = styled(Input)`
  width: 360px;
  height: 44px;
  border-radius: 8px;
  border: 1px solid #D9D9D9;
  background: #FFFFFF;
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.02);
`;

export const InputWrapper = styled.div`
top: 0;
left: 0;
position: absolute;
height: 100%;
width: 100%;
padding: 20px;
background-image: linear-gradient(to top,#f2f2f52e,#f2f2f5b8,#f2f2f5c7,#f2f2f5);
`;
export const RunLocalSeoScanBtn = styled(Button)`
margin-top: 0px;
margin-left: 10px;
gap: 8px;

@media screen and (max-width: 820px) {
  margin: 0;
}
`;

export const MapContainer = styled.div<{height?: string}>`
  height: 140vh;
  width: 100%;
  contain: content;
  position: absolute;
  top: 0;
  border-top-left-radius: 12px;
  @media screen and (max-width: 840px) {
    height: ${p => p?.height};
    min-height: 160vh;
  }
`;

export const ResultsWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
  @media screen and (max-width: 800px) {
    top: 20px;
    padding: 0 15px !important;
  }
  .searchWrapper{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
  .btnWrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .quotaDetail{
      span{
        color: black
      }
    }
  }
  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 5px;
  }
  &:hover {
    ::-webkit-scrollbar-thumb {
      background: #4E5156;
    }
  }
`;

export const WhiteOverlay = styled.div<{height?: string}>`
  position: absolute;
  width: 100%;
  height: 140vh;
  background: linear-gradient(135deg, rgba(242, 242, 245, 0.95) 31.44%, rgba(255, 255, 255, 0.00) 50.7%);  top: 0;
  left: 0;
  padding-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #4E5156;
  gap: 5px;
  overflow: auto;

  border-top-left-radius: 12px;
  @media screen and (max-width: 450px) {
    background: linear-gradient(135deg, rgba(242, 242, 245, 0.95) 32.44%, rgba(255, 255, 255, 0.00) 55.7%);
  }
  @media screen and (max-width: 840px) {
    height: ${p => p?.height};
    min-height: 160vh;
  }

  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 5px;
  }
  &:hover {
    ::-webkit-scrollbar-thumb {
      background: #4E5156;
    }
  }
`;

export const PaginationStyled = styled(Pagination)`
  padding: 20px 25px;  
  text-align: right;
  margin-top: 10px;
  border-top: 1px solid #E8E8E8!important;


.ant-pagination-item{
  min-width: 25px !important;
 height:25px !important;
}
.ant-pagination-prev {
  width:25px !important;
 height:25px !important;
}
.ant-pagination-next{
  width:25px !important;
 height:25px !important;
}
.ant-pagination-disabled{
  min-width: 25px !important;
 height:25px !important;
}
.ant-pagination-item-link  span  svg {
  display:flex;
  margin-bottom:2px;
  align-items:center;
  justify-content:center;
}
.ant-pagination-prev{
  min-width:25px !important;
  max-height:25px !important;
}
.ant-pagination-next{
  min-width:25px !important;
  max-height:25px !important;
}
.ant-pagination-options{
  height:25px;
}
.ant-pagination{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top:10px;
  margin-right:15px;
  
}
`;
export const TextBlack = styled.div`
  color: #121212;
  font-size: 15px;
  font-weight: 500;
`;
export const GrayText = styled.div`
  color: #4E5156;
  font-size: 12px;
  font-weight: 400;
`;
export const StyledSelect = styled(Select)`
margin-top: -5px;
margin-bottom: 4px;
.ant-select-selector {
  border-radius: 5px !important;
  border: 1px solid #E8E8E8 !important;
  background: #F9F9FB !important;
}
.ant-select-arrow {
  margin-top: 3px !important;
}
`;
export const VideoContainer = styled.div`
  position: relative;
  text-align: center;
  width: 400px;

  @media (max-width: 600px) {
    width: 100% !important;
  }

  @media (max-width: 1180px) {
    text-align: left;
    margin-top: 10px;
  }
  @media (max-width: 435px) {
    width: 250px;
  }

  .videoWrapper {
  .crossBtn {
    display: none !important;

    &:hover {
      background: rgba(18, 18, 18, 1) !important;
    }
  }

  &:hover  .crossBtn {
    background: rgba(18, 18, 18, 0.5);
    border-radius: 6px 0 0 6px !important;
    display: block !important;
    padding: 4px 8px !important;
    position: absolute;
    right: 0px;
    top: 48%;
  }}

  .stillHaveQuestions {
    color: #a3a4a4;
    font-size: 14px;
    font-weight: 400;
  }
  .completeToolGuideButton {
  color: #2e6cca;
  font-size: 14px;
  font-weight: 400;

  &:hover {
    text-decoration: underline;
  }}
`;

export const TasksWrapper = styled.div<{index?: number}>`
 box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.02);
 .ant-pagination{
  margin-top: 0px !important;
  padding: 5px 25px !important;
 }
`;

export const TableStyle = styled(TableStyled)`
// padding: 0 100px !important;
@media screen and (max-width: 800px) {
  .ant-table-tbody > tr > td:first-child, table > thead > tr > th:first-child {
    padding-left: 10px !important;
  }
}
`;

export const TableWrapper = styled.div`
  background: #FFFFFF;
  max-width: 1040px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid #E8E8E8;
  margin-top: 60px;
  pointer-events: auto;

  .ant-table-content {
    height: 100% !important;
  }
  .ant-input-affix-wrapper{
    margin: 13px;
    width: 100%;
  }
  .searchWrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 550px) {
      .spanFull {
        width: 100% !important;
      }
    }
  }
 `;

export const Intro = styled.div`
  cursor: pointer;
  display: flex;
  padding-left: 30px;
  flex-direction: column;
  height: 60px;
  width: 100%;
  margin-top: -66px;
  background-image:  linear-gradient(to right,rgb(42 98 64 / 78%),rgb(31 172 71 / 91%));
  z-index: 5;
  position: relative;
  border-radius: 8px;
  text-align: justify;
  .stillHaveQuestions{
    color: #e3e3e3;
    font-size: 12px;
    margin-top: 10px;
  }
  .detail{
    color: white;
    font-size: 14px;
  }
  @media (max-width: 435px) {
    margin-top: -58px;
    height: 100%;
    padding-left: 8px;
    padding-right: 8px;
  }
`;

export const ContentWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export const ContentContainer = styled.div<{height: string}>`
  position: relative;
  height: ${p => p?.height};
  min-height: calc(100vh - 65px);
`;

export const ExportButtonStyled = styled(ButtonV2)`
  border-radius: 6px;
  border: 1px solid #CDCDCD;
  color: #121212;
  background: #FFFFFF;
  width: fit-content;
  display: flex;
  align-items: center;
`;

export const ViewButtton = styled(ButtonV2)`
  width: 60px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9f9fB;
  border-radius: 4px;
  border: 1px solid #e8e8e8;
  color: #121212;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin-left: 20px;
  margin-right: 0;
`;

export const MoreOutLinedStyled = styled.span`
font-size: 20px;
`;

