interface BaseProps {
  keywords: string[];
  pageGroups: number[];
  siteProperty: string;
  pages: string[];
}

export interface Watchlist extends Partial<BaseProps> {
  id: number;
  name: string;
}

export interface CreateProps extends Partial<BaseProps> {
  name: string;
}

export interface UpdateProps extends Partial<BaseProps> {
  id: number;
  name?: string;
}

export interface UpdatePropsV2 extends Partial<BaseProps> {
  action: string;
  countryCode?: string;
}

export const MAIN_WATCHLIST = 'main';
