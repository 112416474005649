import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {observer} from 'mobx-react';
import {Button, Checkbox, Input, Modal, Popover, Radio, Rate, Tooltip} from 'antd';
import {TableStyled} from '../../home-page-main/components/table-styled';
import {useStore} from '@/store/root-store';
import {faArrowUpRightFromSquare, faMagnifyingGlass, faPlus, faBan} from '@fortawesome/pro-regular-svg-icons';
import styled from 'styled-components';
import {integrationApi} from '@/api/gsc';
import {notification} from '@/utils/notification-v2';
import {apiError} from '@/utils/api';
import {faCaretDown, faTriangleExclamation} from '@fortawesome/pro-solid-svg-icons';
import _ from 'lodash';
import FreezeWrapper from '../Component/freezTooltip';
import {useRouter} from 'next/router';
import {FreezeBanner} from '../Component/ottoHeaderV2';
import {faBoxArchive} from '@fortawesome/pro-duotone-svg-icons';

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isOttoV2?: boolean;
  ottoProjectId?: string;
  ottoConnectedGBPId?: any;
  onOttoGBPConnect?: () => void | Promise<void>;
  isLocalSeo?: boolean;
  fromOtto?: string;
  isOttoListingPage?: boolean;
}

function CustomRow(props) {
  return (
    <Tooltip title={props.className.includes('disableRow') ? <div>Businesses without a physical address can not be imported from here. <br/>Instead, import them by pasting the Business URL to the search form on the My Businesses page.</div> : ''} overlayInnerStyle={{width: '350px'}}>
      <tr {...props} />
    </Tooltip>
  );
}


export const ImportBusinessModalV2 = observer(({visible, setVisible, isOttoV2, ottoProjectId, onOttoGBPConnect, ottoConnectedGBPId, fromOtto='', isOttoListingPage = false}: Props) => {
  const {
    gsc: {googleAnalytics: {getGMBData, gmbDataLoading, getGMBSetting}},
    ottoV2Store: {connectGBPOtto, getOttoV2Project, activeTab, setActiveTab, setSelectedLocation},
  } = useStore('');

  // const uuid = getSingleUrlParam('uuid');
  const [businesses, setBusinessData] = useState([]);
  const [searchVal, setSearchVal] = useState('');
  const [selectedBusinesses, setSelectedBusinesses] = useState([]);
  const [filteredBusinesses, setFilteredBusinesses] = useState([]);
  const [checkedUserId, setCheckedUserId] = useState('all');
  const [maxChar, setMaxChar] = useState(47);
  const [filterWithEmail, setFilterWithEmail] = useState('all');
  const [visibleAccount, setVisibleAccount] = useState(false);
  // const [isDisconnectLoader, setIsDisconnectLoader] = useState(false);
  const [isConnectLoader, setIsConnectLoader] = useState(false);
  const router = useRouter();

  const onWindowResize = () => {
    if (window.innerWidth <= 768) {
      setMaxChar(34);
    } else if (window.innerWidth <= 2560) {
      setMaxChar(47);
    }
  };

  useEffect(() => {
    if (visible) {
      getGMBSetting();
    }
  }, [visible]);

  useEffect(() => {
    onWindowResize();
    window.addEventListener('resize', onWindowResize);
    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, []);

  useEffect(() => {
    if (ottoConnectedGBPId?.length) {
      setSelectedBusinesses(ottoConnectedGBPId.map(d => d.locationId));
    }
  }, [ottoConnectedGBPId]);

  useEffect(() => {
    if (visible) {
      getGMBSetting();
    }
  }, [visible]);

  useEffect(() => {
    if (visible) {
      let locations = [];
      getGMBData?.forEach(business => {
        business.businessAccounts.forEach(account => {
          if (account?.locations?.length) {
            const updatedLocation = account.locations.map(location => ({...location, email: business.email}));
            locations = [...locations, ...updatedLocation];
          }
        });
      });
      const locationWithPlaceId = locations.filter(item => item.placeId);
      const locationWithoutPlaceId = locations.filter(item => !item.placeId);
      setBusinessData([...locationWithPlaceId, ...locationWithoutPlaceId]);
    } else {
      setBusinessData([]);
      setFilterWithEmail('all');
      setCheckedUserId('all');
    }
  }, [getGMBData, visible]);

  const getAddress = record => {
    try {
      const address = `${record?.address?.addressLines?.join(', ') || ''}, ${record?.address?.locality || ''}, ${record?.address?.regionCode || ''}`;
      if (address !== ', , ') {
        return <KeywordsTxt>{address.slice(0, maxChar + 10)}{record?.title?.length > maxChar + 10 ? '...' : ''}</KeywordsTxt>;
      }
    } catch (error) {
      return '';
    }
  };

  useEffect(() => {
    const selectedBusiness = [];
    const results = [];
    const updatedArr = (filterWithEmail === 'all' ? businesses :
      businesses.filter(business => business.email.toLowerCase().includes(filterWithEmail.toLowerCase()))).filter(business => business.title.toLowerCase().includes(searchVal.toLowerCase()) || business.email.toLowerCase().includes(searchVal.toLowerCase()));

    if (selectedBusinesses?.length) {
      for (let i = 0; i < selectedBusinesses.length; i++) {
        for (let j = 0; j < updatedArr.length; j++) {
          if (String(selectedBusinesses[i]) === String(updatedArr[j].locationId)) {
            selectedBusiness.push(updatedArr[j]);
          } else {
            results.push(updatedArr[j]);
          }
        }
      }
      const mergedArr = [...selectedBusiness, ...results];
      const finalResult = [];
      const duplicateItems = [];
      for (let index = 0; index < mergedArr.length; index++) {
        if (!duplicateItems.includes(mergedArr[index].locationId)) {
          duplicateItems.push(mergedArr[index].locationId);
          finalResult.push(mergedArr[index]);
        }
      }
      setFilteredBusinesses(finalResult);
    } else {
      setFilteredBusinesses(updatedArr);
    }
  }, [businesses, searchVal, filterWithEmail]);

  const columns = [
    {
      title: '',
      key: '',
      dataIndex: '',
      align: 'left' as 'left',
      width: 50,
      render: (_, record) => {
        if ((isOttoV2 && !record.locationId)) {
          return <Tooltip title={`${isOttoV2 ? 'Location' : 'Place'} ID is not available for this`} trigger='hover'>
            <FontAwesomeIcon style={{marginRight: '10px'}} icon={faBan} color='rgba(255, 0, 0)' />
          </Tooltip>;
        } else {
          return <StyledCheckbox
            isOttoV2={isOttoV2}
            disabled={(isOttoV2 && !record.locationId) || (!isOttoV2 && (!record.locationId || !record.address))}
            checked={selectedBusinesses?.includes(record.locationId)}
            onChange={e => {
              if (e.target.checked) {
                setSelectedBusinesses([...selectedBusinesses, String(record.locationId)]);
              } else {
                setSelectedBusinesses(selectedBusinesses.filter(business => String(business) !== String(record.locationId)));
              }
            }} />;
        }
      },
    },
    {
      title: 'BUSINESS',
      key: 'BUSINESS',
      dataIndex: 'BUSINESS',
      align: 'left' as 'left',
      sortFieldName: 'BUSINESS',
      ellipsis: true,
      width: 250,
      render: (_, record) => {
        return (
          <BusinessWrapper>
            <div className='business-title-container'>
              <Tooltip title={record?.title?.length > maxChar ? record?.title : ''} trigger='hover'>
                <BusinessTitle>{record?.title?.slice(0, maxChar)}{record?.title?.length > maxChar ? '...' : ''}</BusinessTitle>
              </Tooltip>
              {record?.googlemapsUrl && <a target='_blank' href={record?.googlemapsUrl || ''} rel='noreferrer'>
                <FontAwesomeIcon style={{marginRight: '8px'}} icon={faArrowUpRightFromSquare} color='rgba(163, 164, 164, 1)' />
              </a>}
              {(isOttoV2 && !record?.isVerified) && <Tooltip title='This business is not verified. You may experience issues with GBP Optimizations and Posting.' placement='right'><FontAwesomeIcon icon={faTriangleExclamation} color='#F1AA3E' /></Tooltip> }
            </div>
            {getAddress(record)}
          </BusinessWrapper>
        );
      },
    },
    {
      title: 'REVIEWS',
      key: 'REVIEWS',
      dataIndex: 'REVIEWS',
      align: 'left' as 'left',
      sortFieldName: 'REVIEWS',
      ellipsis: true,
      width: 150,
      sorter: (a: any, b: any) => a.totalReviews - b.totalReviews,
      render: (_, record) => {
        return (
          <>
            {record?.totalReviews ?
              <ReviewsContainer>
                {record?.totalReviews || ''}
                <Rate allowHalf disabled value={Number(record?.avgRating)} style={{color: '#F1AA3E', fontSize: '12px'}}/>
                <div style={{fontSize: '12px', color: '#F1AA3E'}}>{Number(record?.avgRating).toFixed(1) || ''}</div>
              </ReviewsContainer>:
              'No Reviews'
            }
          </>
        );
      },
    },
    {
      title: 'ACCOUNT',
      key: 'ACCOUNT',
      dataIndex: 'ACCOUNT',
      align: 'left' as 'left',
      sortFieldName: 'ACCOUNT',
      ellipsis: true,
      width: 110,
      render: (_, record) => {
        const email = record?.email || '';
        return (
          <Tooltip title={email?.length > 23 ? email : ''} trigger='hover'>
            <Mail>{email?.slice(0, 23)}{email?.length > 23 ? '...' : ''}</Mail>
          </Tooltip>
        );
      },
    },
  ];

  const onConnectGBP = () => {
    window.open(integrationApi.getGoogleMyBusinessConnectUrl(fromOtto ? fromOtto : '?is_open=true'), '_self');
    // const now = new Date().getTime();
    // localStorage.setItem('connectGBPRepollTime', now.toString());
    // getGMBSetting(true);
  };

  const handleCreateBusiness = async () => {
    setIsConnectLoader(true);
    try {
      await connectGBPOtto(ottoProjectId, {service: 'gbp', location_ids: selectedBusinesses});
      if (selectedBusinesses?.length === 0) {
        if (activeTab === 'gbp_audit') setActiveTab('All');
        setSelectedLocation({});
      }
      onOttoGBPConnect();
      setVisible(false);
      setSearchVal('');
      setSelectedBusinesses([]);
    } catch (error) {
      const errorMessage = apiError(error) as string;
      notification.error('', errorMessage, false, 'OK');
    }
    setIsConnectLoader(false);
  };

  // const handleDisconnect = async () => {
  //   setIsDisconnectLoader(true);
  //   try {
  //     if (isOttoV2) {
  //       await connectGBPOtto(ottoProjectId, {service: 'gbp', location_ids: []});
  //       onOttoGBPConnect();
  //       setVisible(false);
  //       setSelectedBusinesses([]);
  //     }
  //   } catch (error) {
  //     const errorMessage = apiError(error) as string;
  //     notification.error('', errorMessage, false, 'OK');
  //   }
  //   setIsDisconnectLoader(false);
  // };

  const onClose = () => {
    setSearchVal('');
    setVisible(false);
    if (ottoConnectedGBPId?.length && isOttoV2 && !isOttoListingPage) {
      // resetting default businesses on closing the modal
      setSelectedBusinesses(ottoConnectedGBPId?.map(d => d?.locationId));
    }
    if (isOttoListingPage) {
      setSelectedBusinesses([]);
    }
  };


  const applyFilterByUser = async () => {
    setVisibleAccount(false);
    setFilterWithEmail(checkedUserId);
  };

  const uniqEmails = _.uniq(businesses.map(item=> item.email));


  return (
    <StyledModal
      visible={visible}
      onCancel={onClose}
      footer={false}
      width={950}
    >
      {(getOttoV2Project?.isFrozen && isOttoV2) ? <FreezeBanner style={{marginInline: 'auto', width: '96%'}}>
        <FontAwesomeIcon icon={faBoxArchive} color='#F1AA3E' />
        <div className='txt' style={{color: 'rgba(0, 0, 0, 0.85)'}}>
             The changes on this site are frozen. You will be unable to make any modifications.
        </div>
      </FreezeBanner> : ''}
      <Title>{`${businesses.length} Google Business${businesses.length > 1 ? 'es' : ''} Found`}</Title>
      <TableSection>
        <FilterWrapper>
          <div style={{display: 'flex', width: '100%'}}>
            <StyledInput prefix={<FontAwesomeIcon icon={faMagnifyingGlass} color='rgba(163, 164, 164, 1)' />} placeholder='Search...' value={searchVal} onChange={e => setSearchVal(e.target.value)} />
            <Popover trigger={'click'} onVisibleChange={()=> setVisibleAccount(!visibleAccount)} visible={visibleAccount} overlayInnerStyle={{borderRadius: '10px', border: '1px solid rgb(232, 232, 232)', boxShadow: 'rgba(0, 0, 0, 0.02) 0px 3px 2px 2px'}} content={<FilterByUserEmailsWrapper>
              <div style={{fontSize: '12px', color: 'gray', margin: '0px 0 5px 0'}}>Show only projects created by:</div>
              <RadioGroupStyled onChange={e => {
                setCheckedUserId(e?.target?.value);
              }} value={checkedUserId == '' ? 'all' : checkedUserId}>
                {uniqEmails?.length > 0 && <Radio value={'all'}>Show all</Radio>}
                <br />
                {uniqEmails?.length && uniqEmails?.map((item, idx) => <Radio key={idx} value={item}>{item}</Radio>)}
              </RadioGroupStyled>
              <FilterByUserButtonStyled onClick={() => applyFilterByUser()}>Apply</FilterByUserButtonStyled>
            </FilterByUserEmailsWrapper>} placement='bottomRight'>
              <FilterByUserWrapper onClick={()=> setVisibleAccount(true)}>{filterWithEmail?.length > 3 ? filterWithEmail : 'Account'}&nbsp;&nbsp;&nbsp;<FontAwesomeIcon color='#000000' icon={faCaretDown} /></FilterByUserWrapper>
            </Popover>
          </div>
          {/* <div>
            <Button onClick={handleDisconnect} loading={isDisconnectLoader} disabled={!selectedBusinesses?.length} type='link' danger>Disconnect GBP</Button>
          </div> */}
        </FilterWrapper>
        <GPBTableStyled scroll={{y: 200, x: 'auto'}}
          columns={columns}
          dataSource={filteredBusinesses}
          pagination={false}
          rowClassName={record => record.address == null && !isOttoV2 ? 'disableRow' : ''}
          loading={gmbDataLoading}
          loadingRows={10}
          components={{
            body: {
              row: CustomRow,
            },
          }}/>
      </TableSection>
      <Footer>
        <FreezeWrapper removeTooltip={!router?.pathname?.includes('otto-page-v2') && !router?.pathname?.includes('home')}>
          <ImportButton isOttoV2={isOttoV2} loading={isConnectLoader} onClick={handleCreateBusiness}>
          Save
          </ImportButton>
        </FreezeWrapper>
        <FreezeWrapper removeTooltip={!router?.pathname?.includes('otto-page-v2')}>
          <ConnectGB onClick={onConnectGBP}>
            <FontAwesomeIcon icon={faPlus} color='rgba(45, 108, 202, 1)' style={{marginRight: '10px'}}/>
              Add another GBP Email
          </ConnectGB>
        </FreezeWrapper>
      </Footer>
    </StyledModal>
  );
});

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px;
    background: rgba(242, 242, 245, 1);
  }
  .ant-modal-body {
    @media screen and (max-width: 450px) {
      padding: 15px;
    }
  }
  .ant-table-body{
    max-height: 45vh !important;
  }
  .ant-table-wrapper{
    max-height: 80%;
  }
`;

const Title = styled.div`
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(18, 18, 18, 1);
  @media screen and (max-width: 450px) {
    margin-top: 16px;
    font-size: 22px;
  }
`;

const FilterWrapper = styled.div`
  display: flex;
  height: 28px;
  justify-content: space-between;
`;

const RadioGroupStyled = styled(Radio.Group)`
  max-height: 300px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: lightgray;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
 
  &::-webkit-scrollbar-thumb {
    background: #888; 
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

  .ant-radio-checked .ant-radio-inner{
    border-color: rgb(127,78,173) !important;
  }
  
  .ant-radio-checked .ant-radio-inner:after{
    background-color: rgb(127,78,173);
  }
  
  .ant-radio:hover .ant-radio-inner {
    border-color: rgb(127,78,173);
  }

  .ant-radio-checked .ant-radio-inner:focus{
    border-color: rgb(127,78,173);
  }
`;

const FilterByUserButtonStyled = styled(Button)`
  width: 100% !important;
  height: 28px !important;
  background: rgb(127, 78, 173) !important;
  border-radius: 5px !important;
  border: 1px solid transparent !important;
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin-top: 20px;
  &:hover {
    color: white;
  }
  &:active{
    color: white;
  }
`;

const TableSection = styled.div`
  background-color: rgba(255, 255, 255, 1);
  border-radius: 12px;
  margin: 20px 0px;
  padding: 15px 16px;
  height: 60vh;
  width: 100%;
  @media screen and (max-width: 450px) {
    margin: 10px 0;
    padding: 13px 8px;
  }
  .ant-table-tbody{
    .disableRow{
      cursor: not-allowed;
      .ant-table-cell{
        background: #d3d3d338 !important;
        color: darkgray !important;
        div{color: darkgray !important;}
        .business-title-container{
          div{color: darkgray !important;}
        }
      }
    }
  }
  .ant-table-tbody .disableRow:hover{
    background: #80808038 !important;
  }

`;

const FilterByUserWrapper = styled.div`
  margin-left: 10px;
  color: #4E5156;
  border: 1px solid #CDCDCD;
  background: #ffffff1a;
  width: fit-content;
  padding: 2px 10px;
  border-radius: 6px;
  cursor: pointer;
`;

const FilterByUserEmailsWrapper = styled.div`
  width: 265px;
  background: white;

  .ant-checkbox-checked .ant-checkbox-inner{
    border-color: #7F4EAD;
    background-color: #7F4EAD;
  }

  .ant-checkbox-inner {
    background: #F9F9FB;
    border: 1px solid #CDCDCD;
    border-radius: 3px;
  }

  .ant-divider-horizontal {
    margin: 8px 0 !important;
  }
`;

const StyledInput = styled(Input)`
  max-width: 200px;
  border-radius: 6px;
  border: 1px solid rgba(205, 205, 205, 1);
  color: rgba(163, 164, 164, 1);
  margin-left: 10px;
`;

const StyledCheckbox = styled(Checkbox)<{isOttoV2?: boolean}>`
  .ant-checkbox-inner, .ant-checkbox-checked, .ant-checkbox {
    ${p => p?.isOttoV2 ? 'border-radius: 10% !important; overflow: hidden !important;': ''}
  }
`;

const GPBTableStyled = styled(TableStyled)`
  margin-top: 13px;
  max-height: 300px;
  overflow: auto;
  @media screen and (max-width: 450px) {
    table, .ant-table {
      padding-left: 0 !important;
    }
  }
`;

const BusinessTitle = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(18, 18, 18, 1);
`;

const BusinessWrapper = styled.div`
  .business-title-container {
    display: flex;
    align-items: center;
    gap: 6px;
    word-wrap: break-word !important;
  }
`;

const KeywordsTxt = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(78, 81, 86, 1);
`;

const ReviewsContainer = styled.div`
  display: flex;
  gap: 9px;
`;

const Mail = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(18, 18, 18, 1);
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 540px) {
    flex-wrap: wrap;
    row-gap: 10px;
  }
`;

const ConnectGB = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(45, 108, 202, 1);
  cursor: pointer;
`;

const ImportButton = styled(Button)<{isOttoV2?: boolean}>`
  height: 44px;
  width: 197px;
  border-radius: 8px;
  background-color: rgba(45, 108, 202, 1);
  color: rgba(255, 255, 255, 1);
  ${p => p?.isOttoV2 ? 'min-width: 96px !important; width: auto !important;': ''}
  &:hover {
    background-color: rgba(45, 108, 202, 1);
    color: rgba(255, 255, 255, 1);
  }
  &:focus{
    background-color: rgba(45, 108, 202, 1);
    color: rgba(255, 255, 255, 1);
  }
`;
