import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlay} from '@fortawesome/pro-duotone-svg-icons';
import {Button} from '../../components';
import styled, {keyframes} from 'styled-components';
import {hexToRgba} from '@/utils/functions';
import {VideoPlayerModal} from '../videoPlayerModal/videoPlayerModal';
import {Tooltip} from 'antd';
import moment from 'moment';
import {observer} from 'mobx-react-lite';
import {useStore} from '@/store/root-store';

interface videoUrlsProps {
  url: string;
  title?: string;
  duration?: number;
}

interface Props {
  backgroundColor?: string;
  heading: string;
  videoUrls: videoUrlsProps[];
  tooltipTitle: string;
}
export const PlayButton = observer(({backgroundColor, heading, videoUrls, tooltipTitle}: Props) => {
  const [open, setOpen] = useState(false);
  const totalDuration = videoUrls?.reduce((acc, b)=> acc + b.duration, 0);
  const {settings: {customer: {profile: {isWhitelabel, isParentAccount}}}} = useStore('');

  const tooltipContent = (
    <p style={{fontSize: 14, lineHeight: '20.3px', margin: 0}}>{
      videoUrls?.length > 1 ? `Explore our playlist with ${videoUrls.length} videos, totalling ${moment.duration(totalDuration, 'seconds').minutes()} minutes, and become a pro in no time.` : tooltipTitle
    }</p>
  );

  return isWhitelabel && !isParentAccount ? <></> : (
    <div>
      <Tooltip trigger={'hover'} color={backgroundColor ?? '#549E79'} overlayInnerStyle={{padding: 16, borderRadius: 8, width: 330}} placement='right' title={tooltipContent}>
        <StyledButton onClick={()=> setOpen(true)} backgroundColor={backgroundColor ?? '#4fd268' }>
          <div className='inner'>
            <FontAwesomeIcon style={{width: 8.41, zIndex: 1}} icon={faPlay} />
          </div>
        </StyledButton>
      </Tooltip>

      {open && <VideoPlayerModal color={backgroundColor} heading={heading} videoUrls={videoUrls} open={open} onCancel={()=>setOpen(false)} /> }
    </div>
  );
});


const grow = keyframes`
    0% {
        transform: scale(1, 1);
        opacity: 1;
    }
    25% {
        transform: scale(1.4, 1.4);
        opacity: 1;
    }
    60% {
        transform: scale(1.7, 1.7);
        opacity: 1;
    }
    80% {
       visibility: hidden;
    }

    100%{
       visibility: hidden;
    }
  `;

const growSlow = keyframes`
    0% {
        transform: scale(1, 1);
    }
    25% {
        transform: scale(1, 1);
    }
    60% {
        transform: scale(1.4, 1.4);
    }
    
    65% {
        transform: scale(1.7, 1.7);
    }
    70% {
      visibility: hidden;
    }
  
    100%{
       visibility: hidden;
    }
`;


const StyledButton = styled(Button)<{backgroundColor?: string}>`
  width: fit-content !important;
  justify-content: center;
  width: 36px !important;
  height: 36px !important;
  z-index: 1;
  border: none !important;
  border-radius: 50%;
  background: ${e => `linear-gradient(197.61deg,  ${hexToRgba(e?.backgroundColor, 0.9)}18.7%, ${e?.backgroundColor} 87.95%)`};
  padding: 0;

  &::after,
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 36px;
    height: 36px;
    background: ${e => hexToRgba(e?.backgroundColor, 0.4)};
    border-radius: 50%;
    animation: none;
    transition: none;
    z-index: -1;
  }

  &::after {
    animation: ${grow} 1.5s ease-in-out infinite;
  }

  &::before {
    animation: ${growSlow} 1.5s ease-in-out infinite;
  }

  .inner {
    width: 36px !important;
    height: 36px !important;
    border-radius: 50%;
    background: ${e => `linear-gradient(197.61deg,  ${hexToRgba(e?.backgroundColor, 0.9)}18.7%, ${e?.backgroundColor} 87.95%)`};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    border: none;
  }

  &:focus {
    background: ${e => `linear-gradient(197.61deg,  ${hexToRgba(e?.backgroundColor, 0.9)}18.7%, ${e?.backgroundColor} 87.95%)`};
    color: #fff;
  }
`;
