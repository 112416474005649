// Marker.js
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLocationDot} from '@fortawesome/free-solid-svg-icons';

interface Props {
  lat: number;
  lng: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Marker = (props: Props) => {
  return (
    <div style={{fontSize: '25px', color: '#F44343'}}>
      <FontAwesomeIcon icon={faLocationDot} />
    </div>
  );
};

export default Marker;
