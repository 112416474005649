import {Tooltip, Spin, Dropdown, Checkbox, Menu, Collapse} from 'antd';
import {observer} from 'mobx-react';
import React, {useCallback, useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowsRotate, faCheck, faCircleCheck, faCircleInfo, faCopy, faWandMagicSparkles, faXmark} from '@fortawesome/pro-regular-svg-icons';
import {faSortDown, faTrashXmark} from '@fortawesome/pro-solid-svg-icons';
import {Modal} from '@/components/common-components/components';
import {LoadingOutlined, MoreOutlined} from '@ant-design/icons';
import {useStore} from '@/store/root-store';
import {getSingleUrlParam, addProtocolToDomainHttps, getDomain} from '@/utils/url';
import {notification} from '@/utils/notification-v2';
import {openUrl} from '@/utils/router';
import {CopyViewLinks, PaginationStyled, StyledIssuesCollapse, StyledIssuesTable, UrlWrapper} from '../../../style';
import {getDeployStatus, getFilteredDataForOtto} from '../../tableIssuesCollapse';
import {debounce} from 'lodash';
import {apiError} from '@/utils/api';
import {TableTopBar} from '../../tableTopBar';
import {DeleteColumn, StatusMenu, CloudStackBanner, CloudRefreshButton, StyledDropdownTable, CloudStackStatusButton, SitesWrapper, StyledCloudStackInput, BackButton, DeleteButton, ModalWrapper, Wrapper, StyledButton} from '../../../style';
import {timeNotification} from '@/utils/notification-timer';
import {getOttoTablePageSize} from '@/api/common-utils';
import {saveOttoTablePageSize} from '@/api/common-utils';
import FreezeWrapper from '../../freezTooltip';
import {trialExpiresAtDateCheck} from '@/utils/moment';
import moment from 'moment';
import {OTTO_V2_API} from '@/api/otto-v2';

interface Props {
  componentIssueType: string;
  setPageChanged: (value: boolean) => void;
  setIssueTable: (value: string) => void;
  setAddRecord: (value: any) => void;
  addRecord: any;
  issueTable: string;
  maxHeight?: string;
  removeTopBar?: boolean;
}

const antIcon = <LoadingOutlined style={{fontSize: 100, color: '#2D6CCA'}} spin />;
export const CloudStacks = observer(({componentIssueType, setPageChanged, setIssueTable, setAddRecord, addRecord, issueTable, maxHeight = null, removeTopBar = false}: Props) => {
  const {ottoV2Store: {
    getOttoUrls,
    setTopupHyperDrive,
    getOttoV2Project,
    buildOrDeployCloudStack,
    loadIssueTableData,
    refreshCloudDeployInfo,
    ottoUrlLoader,
    ottoIssueType,
    createABProject,
    cloudStackProviders,
    creatingCloudStackProject,
    setCloudStackCurrentPage,
    setSelectedIssue,
    deleteCloudStackContent,
    loadCloudStacksProviders,
    isActiveKeys,
    setSelectedCategory,
    setLoadingDetail,
    selectedCategory,
    setOttoSearchTerm,
    issueTypeArray,
    selectedIssue,
  }, settings: {customer: {getCustomerQuota, profile: {trialExpiresAt, subscription}}},
  } = useStore('');

  const uuid = getSingleUrlParam('uuid');
  const [ottoUrls, setOttoUrls] = useState([]);
  const [stackProvidersList, setStackProvidersList] = useState({});
  const [deleteItem, setDeleteItem] = useState<any>(false);
  const [publishingCloudContent, setPublishingCloudContent] = useState<number[]>([]);
  const [cloudTargetKeywords, setCloudTargetKeywords] = useState({});
  const [currentRecord, setCurrentRecord] = useState<any>({});
  const [openModal, setOpenModal] = useState(false);
  const [refreshingCloudId, setRefreshingCloudId] = useState<number | null>(null);
  const [addRecordError, setAddRecordError] = useState(false);
  const [creatingCloudContent, setCreatingCloudContent] = useState<number | null>(null);
  const [authorityBuildingPayload, setAuthorityBuildingPayload] = useState({
    target_url: '',
    keyword: '',
    otto_project: '',
  });
  const domainName = getOttoV2Project?.hostname || getSingleUrlParam('domain');
  const filteredOttoUrls = ottoUrls ? ottoUrls?.filter(url => issueTypeArray.includes(url?.issueType)) : [];
  const url = filteredOttoUrls.find(url => url?.issueType === componentIssueType);
  const data = url?.issueTable?.results ?? [];
  const filteredData = getFilteredDataForOtto(data, url?.issueType, addRecord);
  const [dropdownOpen, setDropdownOpen] = useState(true);
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isTableOpen, setIsTableOpen] = useState<any>('open');
  const [isActiveFilter, setIsActiveFilter] = useState('all');
  const [pageSize, setPageSize] = useState(() => getOttoTablePageSize(componentIssueType));
  const [isCopied, setIsCopied] = useState(false);

  const onTooltipVisibleChange = (visible: boolean) => {
    if (!visible) {
      setTimeout(() => {
        setIsCopied(false);
      }, 300);
    }
  };

  useEffect(() => {
    if (getOttoUrls) {
      setOttoUrls(getOttoUrls);
    }
  }, [getOttoUrls]);

  useEffect(() => {
    if (ottoUrls?.length) {
      const isAuthorityBuilding = ottoUrls?.find(i => i?.issueType === 'cloud_stacks') || ottoUrls?.find(i => i?.issueType === 'press_release_distribution');
      if (!cloudStackProviders?.length && isAuthorityBuilding) {
        loadCloudStacksProviders();
      }
      if (isAuthorityBuilding) {
        setDefaultStackProviders();
      }
    }
  }, [ottoUrls]);

  const searchDebounce = debounce(async (params, isSitewide) => {
    await loadIssueTableData(params, isSitewide);
  }, 1000);

  const loadIssueTables = useCallback(async (issueArray: string[], issue: string, category: string, page: number, pageSize: number, stopLoading?: boolean, search?: string, rating?: string, activeKey?: string) => {
    setSelectedIssue(issue);
    setSelectedCategory(category);
    if (!stopLoading) {
      setLoadingDetail(true);
    }
    for (let index = 0; index < issueArray.length; index++) {
      const issueType = issueArray[index];
      if (issueTypeArray.includes(issueType)) {
        const params = {
          uuid,
          otto_project: getOttoV2Project?.id,
          issue_type: issueType,
          page_size: pageSize,
          page,
          is_loading: false,
          ...((activeKey == 'deployed' || activeKey == 'not_deployed') && {deploy_status: getDeployStatus(activeKey)}),
        };
        if (search) {
          params['search'] = search;
          setOttoSearchTerm(search);
        } else {
          setOttoSearchTerm('');
        }
        if (search) {
          searchDebounce(params, false);
        } else {
          await loadIssueTableData(params, false);
        }
      }
    }
    setLoadingDetail(false);
  }, []);

  const publishCloudStack = async record => {
    const filter = ottoUrls.filter(d => d.issueType === 'cloud_stacks');
    try {
      let cloudProviders = stackProvidersList[record?.id];
      if (!cloudProviders) return;
      cloudProviders = Object.keys(cloudProviders);
      cloudProviders = cloudProviders?.map(id => Number(id));
      cloudProviders = cloudProviders?.filter(id => !record?.cloudStackProviders?.find(item => id == item?.cloudStackContentProvider)) || [];
      setPublishingCloudContent(prev => ([...prev, record?.id]));
      await buildOrDeployCloudStack(record?.id, false, {
        cloud_stack_providers: cloudProviders,
      });
      setLoadingDetail(true);
      await loadIssueTableData({uuid: getOttoV2Project?.uuid, page: filter?.length ? filter[0].page : 1, page_size: filter?.length ? filter[0].pageSize : 10, issue_type: 'cloud_stacks', otto_project: getOttoV2Project?.id});
      setLoadingDetail(false);
      setPublishingCloudContent(prev => prev?.filter(id => id != record?.id));
    } catch (error) {
      setPublishingCloudContent(prev => prev?.filter(id => id != record?.id));
    }
  };

  const deleteCloudStack = async record => {
    setDeleteItem(true);
    const onDelayEnd = async () => {
      await deleteCloudStackContent(record?.id);
      const filter = ottoUrls.filter(d => d.issueType === 'cloud_stacks');
      loadIssueTableData({
        uuid: getOttoV2Project?.uuid,
        page_size: filter?.length ? filter[0].pageSize : 10,
        page: filter?.length ? filter[0].page : 1,
        issue_type: 'cloud_stacks',
        otto_project: getOttoV2Project?.id,
      });
      setDeleteItem(false);
    };
    const handleUndo = () => {
      setDeleteItem(false);
    };
    timeNotification('#FF0000', faTrashXmark, 4, `Cloud stack deleted successfully.`, ()=>handleUndo(), '#FF0000', onDelayEnd);
    setDeleteItem(false);
    // notification.success('Success', 'Cloud stack deleted successfully.', 'Close');
  };

  const updateStackProviders = (recordId: number, providerId: number, checked: boolean, name?: string) => {
    setStackProvidersList(prev => {
      const record = prev[recordId];
      if (!record) return prev;
      if (checked) {
        return {
          ...prev,
          [recordId]: {
            ...record,
            [providerId]: {name},
          },
        };
      } else {
        delete record[providerId];
        !record.length && setDropdownOpen(true);
        return {
          ...prev,
          [recordId]: {...record},
        };
      }
    });
  };

  const updateCloudTargetKeywords = (recordId: number, keyword: string) => {
    setCloudTargetKeywords(prev => {
      return {
        ...prev,
        [recordId]: keyword,
      };
    });
  };

  const setDefaultStackProviders = (setKeywords = true) => {
    const providerList = ottoUrls.find(i => i?.issueType === 'cloud_stacks')?.issueTable?.results?.map(item => {
      const itemProviders = item?.cloudStackProviders?.map(el => ({id: el?.cloudStackContentProvider, status: el?.status, indexStatus: el?.indexStatus, publishedAt: el?.publishedAt, publishedUrl: el?.publishedUrl})) || [];
      const providersObj = itemProviders?.reduce((acc, current) => {
        acc[current?.id] = {status: current?.status, indexStatus: current?.indexStatus, publishedAt: current?.publishedAt, publishedUrl: current?.publishedUrl};
        return acc;
      }, {});
      return [item?.id, providersObj];
    });

    const updatedStackProvidersList = {...stackProvidersList};

    providerList?.forEach(([id, providerObj]) => {
      if (updatedStackProvidersList[id]) {
        updatedStackProvidersList[id] = {...updatedStackProvidersList[id], ...providerObj};
      } else {
        updatedStackProvidersList[id] = providerObj;
      }
    });

    setStackProvidersList(updatedStackProvidersList);

    if (setKeywords) {
      const targetKeywordsList = ottoUrls?.find(i => i?.issueType === 'cloud_stacks')?.issueTable?.results?.map(item => ([item?.id, item?.keywords?.join(', ') || '']));
      if (targetKeywordsList) {
        setCloudTargetKeywords(Object.fromEntries(targetKeywordsList));
      }
    }
  };

  const onVisibleChange = visiblility => {
    if (visiblility) {
      setDefaultStackProviders(false);

      const providerList = ottoUrls?.find(i => i?.issueType === 'cloud_stacks')?.issueTable?.results?.map(item => {
        const itemProviders = item?.cloudStackProviders?.map(el => ({id: el?.cloudStackContentProvider, status: el?.status, indexStatus: el?.indexStatus, publishedAt: el?.publishedAt, publishedUrl: el?.publishedUrl})) || [];
        const providersObj = itemProviders?.reduce((acc, current) => {
          acc[current?.id] = {status: current?.status, indexStatus: current?.indexStatus, publishedAt: current?.publishedAt, publishedUrl: current?.publishedUrl};
          return acc;
        }, {});
        return [item?.id, providersObj];
      });
      setDropdownOpen(false);
      setStackProvidersList(Object.fromEntries(providerList));
    }
  };

  const showConfirmationPopup = record => {
    setCurrentRecord(record);
    setOpenModal(true);
  };

  const showDeleteColumn = record => {
    const isDisabled = filteredData.some(data => data.status === 'Generating');
    if (record?.targetUrl) {
      return <DeleteColumn>
        <FreezeWrapper placement='topRight' removeTooltip={record?.status === 'Published'}>
          <Dropdown trigger={['click']} overlay={
            <Menu>
              <Menu.Item disabled={isDisabled} key={'2'} onClick={async ()=>{
                try {
                  await OTTO_V2_API.exportCloudStackData(`?cloudstack=${record?.id}`);
                  notification.success('Success', 'File Exported Successfully');
                } catch (error) {
                  const errorMessage = apiError(error) as string;
                  notification.error('Failed', errorMessage);
                }
              } }>{'Export'}</Menu.Item>
              <Menu.Item disabled={isDisabled} key={'3'} onClick={() => showConfirmationPopup(record)}>{'Delete'}</Menu.Item>
            </Menu>} placement='bottomLeft'
          >
            <span
              style={{fontSize: '16px', cursor: 'pointer', marginLeft: '10px'}}
              onClick={e => e.stopPropagation()}
            >
              <span className={''}>
                <MoreOutlined />
              </span>
            </span>
          </Dropdown>
        </FreezeWrapper>
      </DeleteColumn>;
    }
  };


  const refreshCloudInfo = async (id: number) => {
    const filter = ottoUrls.filter(d => d.issueType === 'cloud_stacks');
    try {
      setRefreshingCloudId(id);
      await refreshCloudDeployInfo({
        cloud_stack_contents: [id],
        otto_project: getOttoV2Project?.id,
      });
      loadIssueTableData({
        uuid: getOttoV2Project?.uuid,
        page_size: filter?.length ? filter[0].pageSize : 10,
        page: filter?.length ? filter[0].page : 1,
        issue_type: 'cloud_stacks',
        otto_project: getOttoV2Project?.id,
      });
      setRefreshingCloudId(null);
    } catch (error) {
      setRefreshingCloudId(null);
    }
  };

  const handleOnChange = e => {
    addRecordError && setAddRecordError(false);
    setAuthorityBuildingPayload(prev => ({...prev, target_url: e.target.value}));
  };

  const resetCloudStackInput = () => {
    setAuthorityBuildingPayload({
      target_url: '',
      keyword: '',
      otto_project: '',
    });
    setAddRecord(false);
    setAddRecordError(false);
  };

  const addAuthorityBuildingPage = async () => {
    if (getDomain(authorityBuildingPayload.target_url) === getDomain(domainName)) {
      const filter = ottoUrls.filter(d => d.issueType === ottoUrls[0]?.issueType);
      const payload = {target_url: addProtocolToDomainHttps(authorityBuildingPayload.target_url), keywords: authorityBuildingPayload.keyword?.split(',').map(value => value.trim()), otto_project: getOttoV2Project?.id};
      const res = await createABProject(ottoUrls[0]?.issueType === 'press_release_distribution' ? 'press-release' : 'cloud-stack', payload);
      if (res?.success) {
        setLoadingDetail(true);
        setAddRecord(false);
        await loadIssueTableData({uuid: getOttoV2Project?.uuid, page: filter?.length ? filter[0].page : 1, page_size: filter?.length ? filter[0].pageSize : 10, issue_type: ottoUrls[0]?.issueType, otto_project: getOttoV2Project?.id});
        await getCustomerQuota();
        setAuthorityBuildingPayload({
          target_url: '',
          keyword: '',
          otto_project: '',
        });
        setLoadingDetail(false);
      }
    } else setAddRecordError(true);
  };

  const sitesDropdown = (record, showPublishedDropdown, providerList, originalProviderList) => {
    const canPublish = providerList?.length !== originalProviderList?.length;
    const publishing = record?.cloudStackProviders?.filter(item => !item.publishedUrl);

    !dropdownOpen && cloudStackProviders?.forEach(site => {
      if (stackProvidersList[record?.id] && !Object?.keys(stackProvidersList[record?.id])?.length) {
        updateStackProviders(record?.id, site?.id, true, site?.name);
      }
    });
    const urlsToCopy = record?.cloudStackProviders?.filter(i => i?.publishedUrl)?.map(i => i?.publishedUrl);

    return (
      <StatusMenu style={{borderRadius: 16, minWidth: 400, paddingTop: 6, overflowY: 'hidden', paddingBottom: 0}}>
        {showPublishedDropdown ? (
          <>
            <CloudStackBanner inProgress={Boolean(publishing?.length)}>
              <div>
                <div>
                  {publishing?.length ? (
                    <div className='banner-heading'>
                      <div className='banner-title'>
                        <Spin indicator={<LoadingOutlined style={{fontSize: 14, color: '#4E5156', marginRight: 6}} spin />}/>
                        <div>{publishing?.length || 0} Cloud Sites Publishing</div>
                      </div>
                    </div>
                  ) : (
                    <div className='banner-heading'>
                      <div className='banner-title'>
                        <FontAwesomeIcon icon={faCircleCheck} fontSize={16} color='#2AC155'/>
                        <div>{cloudStackProviders?.length == originalProviderList?.length && !Object.values(stackProvidersList[record?.id] || {})?.filter((item:any) => item?.status != 'INDEXED').length ? (`${originalProviderList?.length || 0} Cloud Sites Published and Indexed.`):(`${originalProviderList?.length || 0} Cloud Sites Published.`)}</div>
                      </div>
                    </div>
                  )}
                </div>
                {cloudStackProviders?.length != originalProviderList?.length && <div style={{color: '#4E5156', lineHeight: '14.52px', fontSize: 12, marginLeft: 26, display: 'flex'}}>Indexation may take up to a few days.
                  <Tooltip title={publishing?.length ? 'Wait until sites publishing complete.' : ''}>
                    <span>
                      <CloudRefreshButton style={{marginLeft: 10}} disabled={publishing?.length || refreshingCloudId == record?.id} onClick={() => refreshCloudInfo(record?.id)}>
                        <FontAwesomeIcon icon={faArrowsRotate} spin={refreshingCloudId == record?.id} /> <span>{refreshingCloudId == record?.id ? 'Refreshing' : 'Refresh'}</span>
                      </CloudRefreshButton>
                    </span>
                  </Tooltip>
                </div>}
              </div>
            </CloudStackBanner>
            <StyledDropdownTable
              columns={cloudStackDropdownColumns(record, originalProviderList, stackProvidersList, updateStackProviders)}
              dataSource={cloudStackProviders}
              pagination={false}
              style={{whiteSpace: 'nowrap', marginBottom: 5}}
              paddingInCells
              scroll={{x: 'auto'}}
              loading={{
                spinning: refreshingCloudId == record?.id,
                indicator: <Spin indicator={antIcon} />,
              }}
            />
            {cloudStackProviders?.length != originalProviderList?.length && <div style={{color: '#4E5156', lineHeight: '14.52px', fontSize: 12, marginLeft: 26, marginBottom: 5}}>You can deploy remaining sites at no additional cost.</div>}
            {canPublish && (
              <FreezeWrapper>
                <div style={{display: 'flex', alignItems: 'center', gap: 8, margin: '10px 16px 16px'}}>
                  <CloudStackStatusButton textColor='#fff' disabled={!canPublish || publishingCloudContent?.includes(record?.id) || record?.status === 'Publishing'} onClick={() => publishCloudStack(record)} background='#2AC155'>
                    <img src='/img/icon/turned-arrows.svg' alt='' color='#fff' style={{cursor: 'pointer', width: 14, height: 14}}/>
                    Publish {Number(providerList?.length - originalProviderList?.length) || ''} {Number(providerList?.length - originalProviderList?.length) > 1 ? 'Sites' : 'Site'}
                    {publishingCloudContent?.includes(record?.id || record?.status === 'Publishing') && <Spin indicator={<LoadingOutlined style={{fontSize: 12, marginLeft: 5, color: '#fff'}} spin />} />}
                  </CloudStackStatusButton>
                </div>
              </FreezeWrapper>
            )}
            <CopyViewLinks>
              <Tooltip
                onVisibleChange={onTooltipVisibleChange}
                overlayInnerStyle={{backgroundColor: '#000'}}
                title={!isCopied ? <>Click to Copy URLs</> : <><FontAwesomeIcon icon={faCheck} style={{color: '#2AC155'}} />{' '}
                  {urlsToCopy?.length} URLs Copied to Clipboard</> }
              >
                <div
                  onClick={() => {
                    navigator.clipboard.writeText(urlsToCopy?.join('\n'));
                    setIsCopied(true);
                  }}
                >
                  <FontAwesomeIcon icon={faCopy} />{' '}
                    Copy all URLs
                </div>
              </Tooltip>
            </CopyViewLinks>
          </>
        ) : (
          <SitesWrapper>
            <div className='info-banner'>
              <FontAwesomeIcon icon={faCircleInfo} fontSize={16} color='#2D6CCA'/>
              <div>Content Successfully Generated.</div>
              {record?.viewableUrl ? (
                <a href={addProtocolToDomainHttps(record?.editableUrl)} target='_blank' rel='noreferrer'>View/Edit Content</a>
              ) : (
                <Tooltip title='Viewable URL is not available'>
                  <span className='link-placeholder'>
                    View Content
                  </span>
                </Tooltip>
              )}
            </div>
            <div className='checkboxes-heading'>Select Clouds Stack Sites to Publish:</div>
            <div className='sites-list'>
              {cloudStackProviders?.map(site => (
                <div className='site' key={site?.id}>
                  <Checkbox
                    style={{textTransform: 'capitalize'}}
                    onChange={e => updateStackProviders(record?.id, site?.id, e.target.checked, site?.name)}
                    checked={stackProvidersList[record?.id]?.[site?.id]}
                    disabled={publishingCloudContent?.includes(record?.id)}
                  />
                  <span style={{textTransform: 'capitalize'}}>
                    <img src={`/img/icon/${site?.name?.toLowerCase()}.svg`} alt='' style={{cursor: 'pointer', width: 24, height: 24, marginRight: 6, verticalAlign: 'bottom'}}/>
                    {site?.name}
                  </span></div>
              ))}
            </div>
            {canPublish && (
              <FreezeWrapper>
                <div style={{display: 'flex', alignItems: 'center', gap: 8, margin: '13px 10px 5px'}}>
                  <Tooltip title={trialExpiresAtDateCheck(trialExpiresAt, subscription?.lastPaidAt) ? '' : 'Access to this feature is restricted for free trial users.'}>
                    <div>
                      <CloudStackStatusButton textColor='#fff' disabled={!canPublish || publishingCloudContent?.includes(record?.id) || !trialExpiresAtDateCheck(trialExpiresAt, subscription?.lastPaidAt) || record.status==='Publishing'} onClick={() => publishCloudStack(record)} background='#2AC155'>
                        <img src='/img/icon/turned-arrows.svg' alt='' color='#fff' style={{cursor: 'pointer', width: 14, height: 14}}/>
                        Publish {providerList?.length || 0} {providerList?.length > 1 ? 'Sites' : 'Site'}
                        {(publishingCloudContent?.includes(record?.id) || record?.status === 'Publishing') && <Spin indicator={<LoadingOutlined style={{fontSize: 12, marginLeft: 5, color: '#fff'}} spin />} />}
                      </CloudStackStatusButton>
                    </div>
                  </Tooltip>
                </div>
              </FreezeWrapper>
            )}
          </SitesWrapper>
        )}
      </StatusMenu>
    );
  };

  const createCloudContent = async recordId => {
    const filter = ottoUrls.filter(d => d.issueType === ottoUrls[0]?.issueType);
    try {
      const keyword = cloudTargetKeywords[recordId]?.split(',')?.map(value => value?.trim());
      setCreatingCloudContent(recordId);
      await buildOrDeployCloudStack(recordId, true, keyword?.length ? {keywords: keyword} : {});
      setLoadingDetail(true);
      await loadIssueTableData({uuid: getOttoV2Project?.uuid, page: filter?.length ? filter[0].page : 1, page_size: filter?.length ? filter[0].pageSize : 10, issue_type: ottoUrls[0]?.issueType, otto_project: getOttoV2Project?.id}, false, false, getCustomerQuota);
      await getCustomerQuota();
      setLoadingDetail(false);
      setCreatingCloudContent(null);
    } catch (error) {
      const errorMessage = apiError(error) === 'Missing business_name on knowledge graph' ? 'Please update Business Name in knowledge graph modal' : apiError(error);
      if (errorMessage.includes(`You don't have enough Otto Hyperdrive credits`)) {
        setTopupHyperDrive(true);
      } else {
        notification.error('', errorMessage);
      }
      setCreatingCloudContent(null);
    }
  };

  const renderWithStatus = record => {
    let providerList = stackProvidersList[record?.id];
    if (providerList) {
      providerList = Object.keys(providerList);
    }
    const originalProvidersList = record?.cloudStackProviders?.map(el => el?.cloudStackContentProvider) || [];
    const status = record.status;

    if (status === 'Pending' || status === 'Unapproved') {
      return <FreezeWrapper>
        <Tooltip title={trialExpiresAtDateCheck(trialExpiresAt, subscription?.lastPaidAt) ? '' : 'Access to this feature is restricted for free trial users.'}>
          <div>
            <CloudStackStatusButton disabled={cloudTargetKeywords?.[record?.id]?.length < 0 || creatingCloudContent === record?.id || !trialExpiresAtDateCheck(trialExpiresAt, subscription?.lastPaidAt)} background='#2D6CCA' textColor='#fff' onClick={() => createCloudContent(record?.id)}>
              <FontAwesomeIcon icon={faWandMagicSparkles} fontSize={14} color='#fff'/>
              <div style={{fontFamily: 'Inter', fontSize: '13px', fontWeight: 400, color: '#fff'}}>
                AI Generate Content
                {creatingCloudContent === record?.id && <Spin indicator={<LoadingOutlined style={{fontSize: 14, color: '#4E5156', marginLeft: '10px'}} spin />}/>}
              </div>
            </CloudStackStatusButton>
          </div>
        </Tooltip>
      </FreezeWrapper>;
    }

    if (status === 'Published') {
      const withUrl = record?.cloudStackProviders?.filter(item => item.publishedUrl);
      return <div style={{display: 'flex', alignItems: 'center', gap: '6px'}}>
        <Dropdown
          onVisibleChange={onVisibleChange}
          overlay={() => sitesDropdown(record, record?.cloudStackProviders?.length > 0, providerList || [], originalProvidersList)}
          trigger={['click']}
        >
          <CloudStackStatusButton isWhite={true} background='#fff' textColor='#2AC155'>
            <FontAwesomeIcon icon={faCircleCheck} fontSize={14} color='#2AC155' style={{cursor: 'pointer'}}/>
                Published {withUrl?.length || 0} of {record?.cloudStackProviders?.length || 0} Sites
            <FontAwesomeIcon icon={faSortDown} fontSize={13} color='#2AC155' style={{marginTop: '-1px', cursor: 'pointer'}}/>
          </CloudStackStatusButton>
        </Dropdown>
      </div>;
    }

    if (status === 'Publishing' || publishingCloudContent?.includes(record?.id)) {
      const withoutUrl = record?.cloudStackProviders?.filter(item => !item.publishedUrl);
      return <div style={{display: 'flex', alignItems: 'center', gap: '6px'}}>
        <Dropdown
          onVisibleChange={onVisibleChange}
          overlay={() => sitesDropdown(record, record?.cloudStackProviders?.length > 0, providerList || [], originalProvidersList)}
          trigger={['click']}
        >
          <CloudStackStatusButton isWhite background='white' textColor='#4E5156'>
            <span style={{color: '#4E5156'}}>
              <Spin indicator={<LoadingOutlined style={{fontSize: 14, color: '#4E5156', marginRight: 6}} spin />}/>
              Publishing {withoutUrl?.length || 0} of {record?.cloudStackProviders?.length || 0} Sites
            </span>
            <FontAwesomeIcon icon={faSortDown} fontSize={13} color='#4E5156' style={{marginTop: '-1px', cursor: 'pointer'}}/>
          </CloudStackStatusButton>
        </Dropdown>
      </div>;
    }

    if (status === 'Generated') {
      return <div style={{display: 'flex', alignItems: 'center', gap: '6px'}}>
        <Dropdown onVisibleChange={onVisibleChange} overlay={() => sitesDropdown(record, record?.cloudStackProviders?.length > 0, providerList || [], originalProvidersList)} trigger={['click']}>
          <CloudStackStatusButton background='#2AC155' textColor='#fff'>
            <img src='/img/icon/turned-arrows.svg' alt='' color='#fff' style={{cursor: 'pointer', width: 14, height: 14}}/>
            Select Sites
            <FontAwesomeIcon icon={faSortDown} fontSize={13} color='#fff' style={{marginTop: '-1px', cursor: 'pointer'}}/>
          </CloudStackStatusButton>
        </Dropdown>
      </div>;
    }

    if (creatingCloudContent === record?.id || status === 'Generating') {
      return <div style={{color: '#4E5156', fontSize: 13, lineHeight: '15.73px'}}>
        <Spin indicator={<LoadingOutlined style={{fontSize: 14, color: '#4E5156', marginRight: 6}} spin />}/>
        Generating content ...
      </div>;
    }
  };

  const columns = [
    {
      title: <div className='column-title'>TARGET PAGE</div>,
      dataIndex: 'targetPage',
      key: 'targetPage',
      width: 670,
      render: (_, record) => {
        return (
          record?.isFirstObject ? (
            <span style={{display: 'block'}}>
              <StyledCloudStackInput style={{width: 350}} placeholder='Enter the Page URL here...' value={authorityBuildingPayload.target_url} onChange={handleOnChange} />
              {addRecordError && <div style={{color: 'red', fontSize: '11px'}}>Target page url must contain the selected domain{domainName ? `: ${getDomain(domainName)}` : ''}</div>}
            </span>
          ) : (
            <UrlWrapper style={{maxWidth: 600}} onClick={() => openUrl(`${addProtocolToDomainHttps(record?.targetUrl)}`, '_blank')}>{record?.targetUrl}</UrlWrapper>
          )
        );
      },
    },
    {
      title: <div className='column-title'>TARGET KEYWORD</div>,
      dataIndex: 'targetKeyword',
      key: 'targetKeyword',
      width: 300,
      render: (_, record) => {
        const isDisable = creatingCloudStackProject || creatingCloudContent === record?.id || record.status === 'Generating';
        return (
          <div style={{minWidth: 300, maxWidth: 300}}>
            {record?.isFirstObject ? (
              <StyledCloudStackInput disabled={isDisable} value={authorityBuildingPayload.keyword} onChange={e => setAuthorityBuildingPayload(prev => ({...prev, keyword: e.target.value}))} placeholder='Enter the Target Keyword here...' />
            ) : !record?.viewableUrl ? (
              <StyledCloudStackInput disabled={isDisable} onChange={e => updateCloudTargetKeywords(record?.id, e.target.value)} value={cloudTargetKeywords[record?.id]} placeholder='Enter the Target Keyword here...' />
            ) : (
              <div style={{fontSize: 13}}>{record?.keywords?.join(', ') || ''}</div>
            )}
          </div>
        );
      },
    },
    {
      title: <div className='column-title'>CLOUDS STACK STATUS</div>,
      dataIndex: 'status',
      key: 'status',
      width: 250,
      render: (_, record) => {
        return (
          record?.isFirstObject ? (
            <div style={{display: 'flex', alignItems: 'center', gap: '6px'}}>
              <Tooltip title={!(authorityBuildingPayload.target_url && authorityBuildingPayload.keyword) ? 'Please enter a Target Url and a Target Keyword' : ''}>
                <StyledButton disabled={!(authorityBuildingPayload.target_url && authorityBuildingPayload.keyword && !creatingCloudStackProject)} onClick={addAuthorityBuildingPage} color='green'>Add Page</StyledButton>
              </Tooltip>
              <div onClick={resetCloudStackInput} style={{padding: '5px 6px', backgroundColor: '#4E5156', borderRadius: '4px', width: '24px', height: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}}>
                <FontAwesomeIcon icon={faXmark} fontSize={14} color='#fff'/>
              </div>
              {creatingCloudStackProject && <Spin indicator={<LoadingOutlined style={{fontSize: 14, color: '#A3A4A4'}} spin />}/>}
            </div>
          ) : renderWithStatus(record)
        );
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 20,
      align: 'left' as 'left',
      render: (_, record)=> showDeleteColumn(record),
    },
  ];

  const indexationStatusMapping = {
    INDEXED: 'Indexed',
    INDEXING: 'Indexing',
    NOT_INDEXED: 'Not Indexed',
  };

  const cloudStackDropdownColumns = (targetObj, originalProviderList, stackProvidersList, updateStackProviders) => {
    return [
      {
        title: <div style={{fontSize: 12, lineHeight: '14px'}} className='column-title'>CLOUD SITES</div>,
        dataIndex: 'name',
        key: 'name',
        width: 130,
        render: (_, record) => {
          const isDisabled = originalProviderList?.includes(record?.id);
          return (
            <div className='site'>
              <Checkbox
                style={{textTransform: 'capitalize'}}
                disabled={isDisabled || publishingCloudContent?.includes(targetObj?.id)}
                onChange={e => updateStackProviders(targetObj?.id, record?.id, e.target.checked, record?.name)}
                checked={stackProvidersList[targetObj?.id]?.[record?.id]}
              />
              <span style={{textTransform: 'capitalize'}}>
                <img
                  src={`/img/icon/${record?.name?.toLowerCase()}.svg`}
                  alt=''
                  style={{cursor: 'pointer', width: 24, height: 24, marginRight: 6, verticalAlign: 'bottom'}}
                />
                {record?.name}
              </span>
            </div>
          );
        },
      },
      {
        title: (
          <div style={{fontSize: 12, lineHeight: '14px'}} className='column-title'>
            INDEXATION <br/> STATUS
          </div>
        ),
        dataIndex: 'indexation',
        key: 'indexation',
        width: 100,
        render: (_, record) => {
          const status = stackProvidersList[targetObj?.id]?.[record?.id]?.status;
          return (
            <div style={{display: 'flex', alignItems: 'center', gap: 7}}>
              <div>{status ? indexationStatusMapping[status] || 'Awaiting' : '-'}</div>
            </div>
          );
        },
      },
      {
        title: (
          <div style={{fontSize: 12, lineHeight: '14px'}} className='column-title'>
            PUBLISHED <br/> DATE
          </div>
        ),
        dataIndex: 'published_at',
        key: 'published_at',
        width: 80,
        render: (_, record) => {
          const urlExists = !!stackProvidersList[targetObj?.id]?.[record?.id]?.publishedUrl;
          const publishedAt = stackProvidersList[targetObj?.id]?.[record?.id]?.publishedAt;
          return (
            <div style={{display: 'flex', alignItems: 'center', gap: 7}}>
              <div>{(urlExists && publishedAt && moment(publishedAt)?.isValid()) ? moment?.parseZone(publishedAt)?.format('DD MMM, YYYY') : '-'}</div>
            </div>
          );
        },
      },
      {
        title: <div style={{fontSize: 12, lineHeight: '14px'}} className='column-title'>PUBLISHED<div>URL</div></div>,
        dataIndex: 'id',
        key: 'id',
        width: 90,
        render: (_, record) => {
          const url = targetObj?.cloudStackProviders?.find(item => item?.cloudStackContentProvider == record?.id)?.publishedUrl;
          return (
            <UrlWrapper style={!url?.length ? {opacity: '30%', cursor: 'default'} : {}} onClick={() => url?.length && openUrl(`${addProtocolToDomainHttps(url)}`, '_blank')}>View Site</UrlWrapper>
          );
        },
      },
    ];
  };

  const handlePaginationChange = (issueArray: string[], page, pageSize) => {
    setStackProvidersList({});
    const activeKey = issueArray?.length ? isActiveKeys?.find(item => item?.issue == issueArray[0])?.key : 'all';
    loadIssueTables(issueArray, selectedIssue, selectedCategory, page, pageSize, true, '', '', activeKey);
  };

  return (
    <>
      <StyledIssuesCollapse
        ghost
        expandIconPosition='right'
        defaultActiveKey={[`${isTableOpen}`]}
        activeKey={[`${isTableOpen}`]}
        onChange={() => {
          if (isTableOpen === 'close') {
            setIsTableOpen('open');
            setIsOpenSearch(false);
          } else {
            setIsTableOpen('close');
            setIsOpenSearch(false);
          }
        }}
      >
        {!removeTopBar && <TableTopBar
          componentIssueType={componentIssueType}
          setPageChanged={setPageChanged}
          setIssueTable={setIssueTable}
          issueTable={issueTable}
          setSearchText={setSearchText}
          searchText={searchText}
          setIsOpenSearch={setIsOpenSearch}
          isOpenSearch={isOpenSearch}
          setIsTableOpen={setIsTableOpen}
          isTableOpen={isTableOpen}
          setAddRecord={setAddRecord}
          setIsActiveFilter={setIsActiveFilter}
          isActiveFilter={isActiveFilter} />}
        <Collapse.Panel key='open' header={<></>}>
          <StyledIssuesTable
            loading={ottoUrlLoader && ottoIssueType === componentIssueType}
            columns={columns}
            dataSource={filteredData}
            pagination={false}
            scroll={removeTopBar ? {x: 'auto', y: 'auto'} : {x: 850}}
            maxHeight={maxHeight}
          />
          <PaginationStyled
            onChange={(page, pageSize) => {
              setPageChanged(true);
              setIssueTable(url?.issueType);
              handlePaginationChange([url?.issueType], page, pageSize);
              setCloudStackCurrentPage(page);
              saveOttoTablePageSize(componentIssueType, pageSize);
              setPageSize(pageSize);
            }}
            total={url?.issueTable?.count}
            pageSize={pageSize}
            current={url?.page ?? 1}
            showSizeChanger
            pageSizeOptions={['5', '10', '20', '50', '100']}
          />
        </Collapse.Panel>
      </StyledIssuesCollapse>
      {openModal && <Wrapper>
        <Modal
          onClose={() => setOpenModal(false)}
          visible={openModal}
          className={'customConfirmationModal'}
        >
          <ModalWrapper>
            <p>Are you sure you want to delete this record? </p>
            <p className={'warningText'}>This action cannot be undone.</p>

            <DeleteButton
              buttonType='transparent' size='lg'
              loading={deleteItem}
              onClick={async () => {
                deleteCloudStack(currentRecord);
                setOpenModal(false);
              }}>
            Delete
            </DeleteButton>
            <BackButton onClick={() => setOpenModal(false)} buttonType='transparent' size='lg'>
            Go Back
            </BackButton>

          </ModalWrapper>
        </Modal>
      </Wrapper>}
    </>
  );
});
