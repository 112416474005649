import React, {Fragment, useEffect, useState} from 'react';
import {Table, CardWrapper, FilterInput, Button, Modal, Typography, SkeletonHorizontalLoaderGray} from '@/components/common-components/components';
import {CommonInput} from '@/components/common-components/components/input';
import {Row, Col, Popover, Dropdown, Menu} from 'antd';
import styles from '@/components/dashboard/gsc/styles.module.scss';
import classnames from 'classnames';
import stylesLocal from './styles.module.scss';
import {MoreOutlined} from '@ant-design/icons';
import stylesGlobal from '@/components/dashboard/settings/styles.module.scss';
import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react-lite';
import {TeamMemberType} from '@/store/settings/team-members/index';
import {Tooltip} from 'antd';
import {profileApi} from '@/api/account';
import {useTranslation} from 'next-i18next';
import {notification} from '@/utils/notification-v2';
import {notification as notifications} from '@/utils/notifications';
import ButtonComponent from '@/components/common-components/components/button-component';
import {NextImg} from '@/utils/nextImg';

import {MixPanel} from '@/utils/mixpanel';
import {SETTINGS_ADDING_NEW_TEAM_MEMBER, SETTINGS_CHANGING_TEAM_MEMBER_ROLE,
  SETTINGS_ACTIVATING_USER, SETTINGS_DEACTIVATING_USER} from '@/constants/events';
import styled from 'styled-components';
import {Button as ButtonV2} from '../../../../common-components/v2/Button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown, faCircleNotch} from '@fortawesome/pro-solid-svg-icons';
import {getLocation} from '@/utils/string';
import {apiError} from '@/utils/api';
import {emailRegexWithoutCommma} from '@/constants/regex';

type AddMemberDTO = {
  email_address: string;
  role: string;
};

const getRole = memberRoleKey => {
  switch (memberRoleKey) {
    case 'customer_role_admin':
      return 'Admin';
    case 'customer_role_viewer':
      return 'Member';
  }
};

const TeamMembers = observer(() => {
  const [openModal, setOpenModal] = useState(false);
  const {settings: {customer: {profile: {isWhitelabel}}}}= useStore('');
  const {t} = useTranslation('common');
  const {settings: {teamMembers: {setFromResponse,
    loading, getTeamMember}}} = useStore('');
  const {settings: {customer: {loadProfile, getCustomerQuota, profile: {getRoles, getMaxNumberOfSeats, getMaxNumberOfOccupiedSeats, isViewer}}}} = useStore('');
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [invitationEmail, setInvitationEmail] = useState('');
  const [showEmailError, setShowEmailError] = useState(false);
  const [teamMembersData, setTeamMembersData] = useState([]);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [memberRole, setRole] = useState('customer_role_viewer');
  const [tableWrapperHeight, setTableWrapperHeight] = useState(null);
  // const [message, setMessage] = useState('');
  const [inviteLoading, setInviteLoading] = useState(false);
  const updateTeamMember = async ({id, newRole, newStatus}: {id: number; newRole?: string; newStatus?: string}) => {
    const success = await profileApi.updateMember(id, newRole, newStatus);
    if (success) {
      notifications.success(t('successfully-changed-team-member'));
    } else {
      notifications.error(t('error-changing-team-member'));
    }
    await setFromResponse();
    loadProfile();
  };

  useEffect(() => {
    const data = getTeamMember?.filter(item => item?.email?.includes(query) && item.role != null) || [];
    setTeamMembersData(data);
  }, [JSON.stringify(getTeamMember), query]);

  const updateWidths = () => {
    setTimeout(() => {
      const sectionElement = document.getElementById('table-section');
      if (sectionElement) {
        const sectionHeight = sectionElement.clientHeight;
        if (sectionHeight) {
          setTableWrapperHeight(sectionHeight - 145);
        }
      }
    }, 0);
  };
  window.addEventListener('resize', updateWidths);
  useEffect(() => {
    if (!loading && getTeamMember) {
      updateWidths();
    }
  }, [getTeamMember]);


  const inviteTeamMember = async (email: string) => {
    if (!emailRegexWithoutCommma.test(invitationEmail)) {
      setShowEmailError(true);
    } else {
      setInviteLoading(true);
      const payload:AddMemberDTO = {
        email_address: email,
        role: memberRole,
      };
      try {
        await profileApi.addTeamMember(payload);
        setOpenModal(false);
        setFromResponse();
        setErrorMsg('');
        setInvitationEmail('');
        setShowEmailError(false);
        setRole('customer_role_viewer');
        notification.success('Invitation sent', `An email with log in instructions has been sent to ${email}.`);
      } catch (e) {
        if (e.response?.status === 400 && e.response?.data?.emails && e.response?.data?.emails[0]?.includes('is already associated with')) {
          setErrorMsg(e.response?.data?.emails[0]);
        } else {
          setErrorMsg('');
          const errorMessage = apiError(e) as string;
          notification.error('Invitation Failed', errorMessage, false, 'Close notification');
        }
      } finally {
        await getCustomerQuota();
        setInviteLoading(false);
      }
    }
  };

  const openPopup = (id: number, lastLogin: string, role: string, isActive: boolean) => {
    return (
      <div className={stylesLocal.ActionButtonsContainer}>
        <div>
          <Button className={stylesLocal.RefetchBtn}>
            <span onClick={async ()=> {
              role != 'Admin' ? await updateTeamMember({id: id, newRole: 'customer_role_admin'}) : null;
              const details = {
                id: id,
                newRole: 'customer_role_admin',
              };

              MixPanel.track(SETTINGS_CHANGING_TEAM_MEMBER_ROLE, {
                'changingRole': `${details}`,
              });

              setPopoverOpen(false);
            }} className={stylesLocal.spanDisplay}>{t('admin')} {role == 'Admin' ? <p className={stylesLocal.check}>&#10004;</p> : null}</span>
          </Button>
          <Button className={stylesLocal.RefetchBtn}>
            <span onClick={async ()=> {
              const admins = getTeamMember?.filter(i => i?.role === 'Admin');
              if (admins?.length <= 1) {
                return notification.error('Role Change Not Allowed', 'You are the only admin; you cannot change your role to viewer.');
              }
              role == 'Admin' ?await updateTeamMember({id: id, newRole: 'customer_role_viewer'}) : null;
              const details = {
                id: id,
                newRole: 'customer_role_viewer',
              };

              MixPanel.track(SETTINGS_CHANGING_TEAM_MEMBER_ROLE, {
                'changingRole': `${details}`,
              });

              setPopoverOpen(false);
            }} className={stylesLocal.spanDisplay}>{t('member-no-billing')} {role != 'Admin' ? <p className={stylesLocal.check}>&#10004;</p> : null}</span>
          </Button>
          {isActive ? <Button style={{marginBottom: '5px'}} className={stylesLocal.DisconnectBtn}
            onClick={async ()=> {
              await updateTeamMember({id: id, newStatus: 'disabled'});
              const details = {
                id: id,
                newStatus: 'disabled',
              };

              MixPanel.track(SETTINGS_DEACTIVATING_USER, {
                'userDeactivated': `${details}`,
              });

              setPopoverOpen(false);
            }}>
            {t('deactivate-user')}
          </Button> :
            <Button style={{marginBottom: '5px'}} className={stylesLocal.DisconnectBtn}
              onClick={async ()=> {
                await updateTeamMember({id: id, newStatus: 'active'});
                const details = {
                  id: id,
                  newStatus: 'active',
                };

                MixPanel.track(SETTINGS_ACTIVATING_USER, {
                  'userActivated': `${details}`,
                });

                setPopoverOpen(false);
              }}>
              {t('activate-user')}
            </Button>}
          <div style={{borderTop: '1px solid #E8E8E8', paddingTop: '10px', fontSize: '12px', color: '#4E5156', marginLeft: '12px', marginBottom: '16px', marginRight: '12px'}}>
            <span>{t('last-login-at')}:</span>
            <span style={{display: 'block'}}>{lastLogin}</span>
          </div>
        </div>
      </div>
    );
  };


  const changeValue = value => {
    setShowEmailError(false);
    setInvitationEmail(value);
  };

  const setCloseModal = () => {
    setOpenModal(false);
    setErrorMsg('');
    setInvitationEmail('');
    setShowEmailError(false);
    setRole('customer_role_viewer');
    const {style} = document.body;
    style.cssText = `width: calc(100% - 0px); overflow: visible;`;
  };

  const items = [
    {
      key: 'customer_role_admin',
      label: <div>Admin</div>,
    },
    {
      key: 'customer_role_viewer',
      label: <div>Member</div>,
    },
  ];

  const openAddMemberModal = () => {
    return (
      <Modal
        visible={openModal}
        maskStyle={{zIndex: 9}}
        width={400}
        onClose={()=> setCloseModal()}
        closeIcon={<NextImg className={stylesLocal.closeIcon} src='/icons/close_icon.svg' onClick={()=>setOpenModal(false) } />}
        closable={true}
        className={stylesLocal.Modal}
      >
        <div style={{paddingTop: '25px'}}>
          <div style={{paddingBottom: '15px', paddingLeft: '30px'}}>
            <span style={{fontSize: '20px', fontWeight: 600}}>Add Team Member</span>
          </div>
          <div className={stylesLocal.errorBannerContainer}>
            {errorMsg && <div className={stylesLocal.errorBanner}>
              <span>{errorMsg}</span>
            </div>}
          </div>
          <div className={stylesLocal.ContentDiv}>
            <Typography className={stylesLocal.Title} tag='h5'>Email Address <span>{t('user-seats')} {getMaxNumberOfOccupiedSeats} / {getMaxNumberOfSeats}</span></Typography>
            <CommonInput className={stylesLocal.CommonInput} placeholder={'Email'} value={invitationEmail} onChange={e => changeValue(e.target.value)} iconClasses={stylesLocal.InputIcon}/>
            {showEmailError && <div style={{color: 'red', fontSize: 12}}>Please enter a valid email</div>}
          </div>
          <div className={stylesLocal.FooterDiv}>
            <Typography className={stylesLocal.Title} tag='h5'>{'Role'}</Typography>
            <div className={stylesLocal.FooterFlex}>
              <Dropdown trigger={['click']} overlay={
                <Menu>
                  {items.map(item => (
                    <Menu.Item style={{background: item.key == memberRole && 'rgba(127,78,173,0.2)'}} key={item.key} onClick={() => setRole(item.key)}>
                      {item.label}
                    </Menu.Item>
                  ))}
                </Menu>
              }>
                <DropdownChildWrapper>
                  <DropdownChildTxt>{getRole(memberRole)}</DropdownChildTxt>
                  <FontAwesomeIcon fontSize={12} icon={faCaretDown}/>
                </DropdownChildWrapper>
              </Dropdown>
            </div>
            <div style={{display: 'flex', justifyContent: 'flex-end', borderTop: '1px solid #E8E8E8', paddingTop: '20px'}}>
              <ButtonComponent
                style='light-transparent'
                onClick={() => {
                  setErrorMsg('');
                  setCloseModal();
                }} className={stylesLocal.BtnCancel} disabled={false} >
                <a style={{color: '#4E5156'}}>{t('cancel')}</a>
              </ButtonComponent>

              <ButtonComponent disabled = {inviteLoading || !invitationEmail || getMaxNumberOfOccupiedSeats >= getMaxNumberOfSeats} className={stylesLocal.Btn} style='solid' color='primary' onClick={() => {
                // invite member function
                if (getMaxNumberOfOccupiedSeats < getMaxNumberOfSeats) {
                  inviteTeamMember(invitationEmail);
                  MixPanel.track(SETTINGS_ADDING_NEW_TEAM_MEMBER, {
                    'addingNewTeamMember': `${invitationEmail}`,
                  });
                }
              }}>
                {inviteLoading ? <FontAwesomeIcon className={'fa-spin'} icon={faCircleNotch}/> : 'Add'}
              </ButtonComponent>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const statusOrder = {
    [t('active')]: 3,
    [t('accepted')]: 2,
    Invited: 1,
    [t('deactivated')]: 0,
  };

  const getStatusLabel = record => {
    let label = 'Invited';
    if (record?.accepted === true) {
      label = t('accepted');
    }
    if (record?.isActive === true) {
      label = t('active');
    }
    if (record?.isActive === false) {
      label = t('deactivated');
    }
    return label;
  };

  const columns = [
    {
      title: t('user').toUpperCase(),
      dataIndex: 'fullName',
      key: 'fullName',
      align: 'left' as 'left',
      sorter: false,
      width: 150,
      render: (_, record: TeamMemberType) => {
        if (loading) return <SkeletonHorizontalLoaderGray lightLoader width='45px' height='10px' align='center'/>;
        return (
          <>
            <div>
              <span>{record.fullName}</span>
              <span style={{display: 'block', color: '#4E5156'}}>{record.email}</span>
            </div>
          </>
        );
      },
    },
    {
      title: t('status'),
      dataIndex: 'isActive',
      key: 'isActive',
      sortField: 'isActive',
      align: 'center' as 'center',
      sorter: (a, b) => statusOrder[getStatusLabel(a)] - statusOrder[getStatusLabel(b)],
      width: 135,
      render: (_, record: TeamMemberType) => {
        if (loading) return <SkeletonHorizontalLoaderGray lightLoader width='45px' height='10px' align='center'/>;
        let dotColor = stylesLocal.DotGrey;
        let label = 'Invited';

        if (record?.accepted === true) {
          dotColor = stylesLocal.DotGreen,
          label = t('accepted');
        }

        if (record?.isActive === true) {
          dotColor = stylesLocal.DotGreen,
          label = t('active');
        }

        if (record?.isActive === false) {
          dotColor = stylesLocal.DotRed,
          label = t('deactivated');
        }


        return (
          <>
            <div className={stylesLocal.WrapperDiv}>
              <div className={stylesLocal.Wrapper}>
                <div className={dotColor}></div>
                {label}
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: t('role').toUpperCase(),
      dataIndex: 'role',
      key: 'role',
      align: 'center' as 'center',
      sorter: false,
      width: 25,
      render: (_, record: TeamMemberType) => {
        if (loading) return <SkeletonHorizontalLoaderGray lightLoader width='45px' height='10px' align='center'/>;
        if (isViewer) {
          return (
            <div>{record.role}</div>
          );
        }
        return (
          <>
            <Popover
              overlayClassName={stylesLocal.popoverContainer} onOpenChange={visible=> setPopoverOpen(visible)} content={openPopup(record.userId, record.lastLoginAt, record.role, record.isActive)} trigger='click' placement='bottomRight'>
              <span className={stylesGlobal.BigMoreOutline} style={{cursor: 'pointer'}}><MoreOutlined />
              </span>
            </Popover>
          </>
        );
      },
    },
  ];

  return (
    <div className='team-members'>
      {(!loading && !getTeamMember?.length) ? (
        <EmptyBanner>
          <div>
            <div style={{fontWeight: 500}}>Invite team members to access your account</div>
            <div style={{color: '#4E5156'}}>{`The owner or an administrator for a ${!isWhitelabel ? 'LinkGraph' : getLocation(location)} account can invite new team members to access the account from the dashboard.`}</div>
          </div>
          <ButtonV2 onClick={()=> setOpenModal(true)}>+ Add members</ButtonV2>
        </EmptyBanner>
      ) : ''}
      <CardWrapper
        theme='themeLight'
        className={classnames(styles.cardLight, styles.topPagesCard, styles.keywordSearchTable, 'style-from-new-settings')}
        style={{borderRadius: '8px', marginTop: 10}}
        id={'table-section'}
      >
        <Fragment>
          <div className={stylesLocal.tableHeader} style={{borderRadius: '8px'}}>
            <Row gutter={24}>
              <Col style={{display: 'flex', alignItems: 'baseline'}} xl={18} md={18} xs={24}>
                <FilterInput
                  searchPlaceHolder={t('search-by-email2')}
                  onKeywordChange={e => setQuery(e)}
                />
              </Col>
              {getRoles?.includes('Admin') ?
                <StyledCol xl={6} md={6} xs={24}> <a>
                  <ButtonComponent
                    style='light-transparent'
                    className={stylesGlobal.LinkBtn}
                    onClick={() => setOpenModal(true)}>
                    + {t('add-member')}
                  </ButtonComponent>
                </a> </StyledCol>:
                <StyledCol xl={6} md={6} xs={24}>
                  <Tooltip title='Only Admins are allowed to invite other users to your account' placement='top'>
                    <ButtonComponent
                      disabled={true}
                      style='solid'
                      color='gray'
                      className={stylesGlobal.disabled}>
                    + {t('add-member')}
                    </ButtonComponent>
                  </Tooltip>
                </StyledCol>
              }
            </Row>
          </div>
          <div className={classnames(stylesLocal.tableOuterPages, 'table-wrapper')}>
            <Table
              className={isPopoverOpen ? stylesLocal.noScroll : null}
              pagination={false}
              columns={columns}
              dataSource={teamMembersData}
              loading={loading}
              loadingRows={20}
              scroll={{y: tableWrapperHeight}}
            />
          </div>
          <div className={stylesLocal.bottomTable}></div>
        </Fragment>
      </CardWrapper>
      {openModal ? openAddMemberModal() : null}
    </div>
  );
});

export default TeamMembers;

const EmptyBanner = styled.div`
  display: flex;
  justify-content: space-between,;
  align-items: center;
  background: #7F4EAD26;
  border-radius: 5px;
  padding: 15px 20px;
`;
const StyledCol = styled(Col)`
display: flex;
justify-content: flex-end;
padding-right: 0 !important;
@media only screen and (min-width: 767px) {
  margin-top: 10px;
}
@media only screen and (max-width: 767px) {
  margin-bottom: 10px;
}
`;

const DropdownChildWrapper = styled.div`
  width: 100%;
  height: 40px;
  background: #f9f9fb;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
`;

const DropdownChildTxt = styled.div`
  font-size: 12px;
  font-weight: 500;
`;
