import {observer} from 'mobx-react';
import React from 'react';
import {COLORS} from '@/components/common-components/v2/colors';
import {formatNumber, getPercentage} from '@/utils/number';
import {faSquareQuestion} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Progress} from 'antd';
import {TooltipPlacement} from 'antd/lib/tooltip';
// import styles from './styles.module.scss';
import styled from 'styled-components';
import {ButtonColor} from '../../common-components/v2/Button';
import {getInversePercentageColor} from '@/utils/colors';
import {PurchasedQuota} from '../components/badge';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {openLiveChat} from '@/utils/intercom';
import {useStore} from '@/store/root-store';
import {QuotaTooltip} from './quotaTooltip';

interface Guide {
  title: string;
  value: number | string;
}

interface ForHeatmapGuide {
  title: string;
  value: number | string;
}

interface GridShape {
  circle: ForHeatmapGuide[];
  rectangle: ForHeatmapGuide[];
}


interface Props {
  consumedData: number;
  totalData: number;
  heading?: string;
  description?: string;
  isCenter?: boolean;
  showCta?: boolean;
  showGuide?: boolean;
  showHeatmapGuide?: boolean;
  customCta?: any;
  customTopUpOnClick?: any;
  ctaColor?: ButtonColor;
  ctaLabel?: string;
  guideData?: Guide[];
  heatmapGuideData?: GridShape;
  isConsemed?:string;
  isRemaining?:string;
  isTotal?:string;
  isNoProgressBar?:boolean;
  isKrPublic?:boolean;
  isCampaignEmailQuota?: boolean;
  hideTopup?: boolean;
  showPurchasedQuota?: any;
  forHeatmap?: boolean;
  fontSize?: number;
  ctaIcon?: any;
  varient?: string;
  purchasedQuotaTooltipTxt?: string;
  showTopup?: boolean;
  showTopupAfterDesc?: boolean;
  topupCallback?: any;
  tooltipOnVisibleChange?: any;
  visible?: any;
  infoDescriptionWidth?: number | string;
  infoDescriptionIcon?: IconProp;
  infoDescription?: string;
  tooltipWidth?: number | string;
  dontShowGuideData?: boolean;
  tooltipPlacement?: TooltipPlacement;
  disableCtaButton?: boolean;
}

export const QuotaInfoDetail: React.FC<Props> = observer(({
  consumedData,
  totalData,
  heading,
  isCenter,
  description,
  showGuide,
  showHeatmapGuide,
  isConsemed,
  isRemaining,
  isTotal,
  guideData,
  showCta,
  customCta,
  customTopUpOnClick,
  ctaColor,
  ctaLabel,
  isNoProgressBar,
  isKrPublic,
  heatmapGuideData,
  isCampaignEmailQuota,
  hideTopup = false,
  showPurchasedQuota,
  forHeatmap = false,
  fontSize = 14,
  ctaIcon,
  purchasedQuotaTooltipTxt,
  topupCallback,
  tooltipOnVisibleChange,
  visible,
  infoDescriptionWidth,
  infoDescriptionIcon,
  infoDescription,
  tooltipWidth,
  dontShowGuideData,
}) => {
  const {settings: {customer: {isGHLAccount, profile: {isVendastaClient}}}} = useStore(null);

  const quotaProps = {
    description: description,
    showGuide: showGuide,
    showHeatmapGuide: showHeatmapGuide,
    isConsemed: isConsemed,
    isRemaining: isRemaining,
    isTotal: isTotal,
    guideData: guideData,
    showCta: showCta,
    customCta: customCta,
    ctaColor: ctaColor,
    ctaLabel: ctaLabel,
    heatmapGuideData: heatmapGuideData,
    ctaIcon: ctaIcon,
    showTopup: false,
    showTopupAfterDesc: false,
    topupCallback: topupCallback,
    tooltipOnVisibleChange: tooltipOnVisibleChange,
    visible: visible,
    infoDescriptionWidth: infoDescriptionWidth,
    infoDescriptionIcon: infoDescriptionIcon,
    infoDescription: infoDescription,
    tooltipWidth: tooltipWidth,
    dontShowGuideData: dontShowGuideData,
    tooltipPlacement: 'bottom',
    disableCtaButton: false,
    varient: 'white',
    consumedData: consumedData,
    totalData: totalData,
    forHeatmap: false,
    component: <FontAwesomeIcon icon={faSquareQuestion} color='#A3A4A4' fontSize={14} style={{marginLeft: 6, marginTop: 4, cursor: 'pointer'}} />,
  };

  return isGHLAccount ? <></> : (
    <div>
      <QuotaContainer isCenter={isCenter}><QuotaDivSA fontSize={fontSize}>
        {isCampaignEmailQuota ? <>
          {/* <span style={{display: 'flex'}} >{heading}:<QuotaValues style={{marginRight: '5px'}}>{consumedData ? `${formatNumber(consumedData, 1)}`.replace('.0', '') : 0}</QuotaValues>of<QuotaSubValue style={{marginLeft: '5px'}}>{totalData ? `${formatNumber(totalData, 1)}`.replace('.0', '') : '-'}</QuotaSubValue></span> */}
          {!isNoProgressBar && <ProgressStyled
            trailColor={'#4E5156'}
            strokeColor={getInversePercentageColor(getPercentage(Number(consumedData), 0, Number(totalData)))}
            percent={getPercentage(Number(consumedData), 0, Number(totalData))}
            showInfo={false} /> }
        </> :
          isKrPublic ? <div>Quota: 10 requests per day</div> :<>
            <span style={{display: 'flex', color: forHeatmap ? '#121212' : ''}} >
              {heading}:<QuotaValues>{consumedData ? `${formatNumber(consumedData, 1)}`.replace('.0', '') : 0} </QuotaValues>
              <QuotaSubValue>/{totalData ? `${formatNumber(totalData, 1)}`.replace('.0', '') : 0}</QuotaSubValue>
              {showPurchasedQuota ? <PurchasedQuota total={`+${showPurchasedQuota}`} purchasedQuotaTooltipTxt={purchasedQuotaTooltipTxt} /> : ''}
              <QuotaTooltip {...quotaProps} />
              {isGHLAccount ? '' : (!hideTopup && consumedData >= totalData) && consumedData >= totalData && <TopupText style={{cursor: isVendastaClient || isGHLAccount ? 'not-allowed' : 'pointer'}} onClick={() => {
                if (customTopUpOnClick && typeof customTopUpOnClick === 'function') {
                  customTopUpOnClick();
                } else {
                  openLiveChat();
                }
              }}>Top Up</TopupText>}
            </span>
            {!isNoProgressBar && <ProgressStyled
              trailColor={forHeatmap ? '#bdbdbd' :'#4E5156'}
              strokeColor={getInversePercentageColor(getPercentage(Number(consumedData), 0, Number(totalData)))}
              percent={getPercentage(Number(consumedData), 0, Number(totalData))}
              showInfo={false} /> }
          </>}
      </QuotaDivSA> </QuotaContainer>
    </div>
  );
});


const QuotaValues = styled.div`
  margin-left:5px;
`;
const ProgressStyled = styled(Progress)`
margin-top:-7px;
  .ant-progress-inner {
    height: 3px;
  }
`;
const QuotaSubValue = styled.div`
  font-size:12px;
  display: flex;
  align-items: center;
`;
const QuotaDivSA = styled.div<{fontSize?: number}>`
  display:flex;
  flex-direction:column;
  font-size: 14px;
  font-weight:400;
  color: ${COLORS.gray};
  @media screen and (max-width: 425px){
    font-size: ${p => p.fontSize !== null ? 12 : 14}px;
  }
  `;
const QuotaContainer = styled.div<{isCenter}>`
display:flex;
gap: 25px;
justify-content:${p=>p.isCenter ? '' : 'center'};
`;


const TopupText = styled.p`
color: #2D6CCA;
margin-left: 10px;
margin-top: 0;
margin-bottom: 0;
cursor: pointer;
`;
