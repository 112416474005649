import {Select} from 'antd';
import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import {InputContainer, InputStyled,
  StyledSelect,
  ButtonStyled,
  OptionContent,
  StyledSelectDiv,
  Wrapper} from './style';
import {Form} from 'antd';
import {targetPageRegexV3, urlDomainRegex, urlSubDomainRegex, urlValidationRegex} from '@/constants/regex';
import {useStore} from '@/store/root-store';
import {useRouter} from 'next/router';
// import {formatNumber} from '@/utils/number';
import {CountryDropdown} from '../../v2';
import {countriesListForCr, countriesListForSE} from '@/components/dashboard/pages/site-explorer-page/main-page/header';
import {getSingleUrlParam} from '@/utils/url';
import {notification} from '@/utils/notification-v2';
import {faSearch} from '@fortawesome/pro-regular-svg-icons';
import {LoadingOutlined} from '@ant-design/icons';
interface Props {
  quotaConsumed?: any;
  totalQuota?: any;
  quota?: boolean;
  onSubmitHandler?: (...form: any) => any;
  isHideDomainSelecter?: boolean;
  smallCountryDropdown?: boolean;
  isSE?: boolean;
  height?: string;
  isHeader?: any;
}
interface RegistrationForm {
  keyword: string;
  url: string;
  selectedCountry: string;
  mode: string;
  URL: string;
  clearFields?: boolean;
}

const {Option} = Select;
export const SearchBarV2: React.FC<Props> = observer(({isHideDomainSelecter = false, smallCountryDropdown = false, isSE, height, isHeader=false}) => {
  const router = useRouter();
  const {
    competitorResearcherV2: {inputFieldValue, postNewCompetitor, overview: {siteExplorerCountry, getMainUrl}}} = useStore('');

  const {
    settings: {customer: {getCustomerQuota}},
  } = useStore('');
  const {competitorResearcherV2: {competitorResearcherUrl, setCompetitorResearcherUrl, setCompetitorResearcherId, overview: {loadCompetitorResearcherDetail, getCompetitorResearcherDetail}}} = useStore('');
  const [selectedCountry, setSelectedCountry] = useState(siteExplorerCountry ? siteExplorerCountry.toUpperCase() : '');
  const [form] = Form.useForm<RegistrationForm>();
  const selectedDomain = getSingleUrlParam('domain');
  const [urlFieldValue, setUrlFieldValue] = useState('');
  const [selectedModeOption, setSelectedModeOption] = useState('domain');
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    setSelectedCountry(siteExplorerCountry?.toUpperCase());
  }, [siteExplorerCountry]);
  const publicPage = router?.pathname?.includes('public');

  useEffect(() => {
    if (selectedDomain) {
      const checkDomain = selectedDomain?.match(urlDomainRegex);
      const checkSubDomain = selectedDomain?.match(urlSubDomainRegex);
      const checkTargetPage = selectedDomain?.match(targetPageRegexV3);
      if (getCompetitorResearcherDetail?.data?.mode) {
        setSelectedModeOption(getCompetitorResearcherDetail?.data?.mode);
      } else if (checkDomain) {
        setSelectedModeOption('domain');
      } else if (checkSubDomain) {
        setSelectedModeOption('subdomain');
      } else if (checkTargetPage) {
        setSelectedModeOption('target_page');
      } else {
        setSelectedModeOption('domain');
      }
      setUrlFieldValue(selectedDomain);
    }
  }, [selectedDomain, getCompetitorResearcherDetail?.data?.mode]);

  const handleSubmit = async values => {
    const getUrl = values.URL;
    const payload = {url: getUrl, mode: selectedModeOption, country_code: selectedCountry};
    const response = await postNewCompetitor(payload, true);
    if (response?.id) {
      setCompetitorResearcherUrl(getUrl);
      setCompetitorResearcherId(response?.id);
      await router?.push(`${router?.pathname?.replace('[...id]', response?.id)}?domain=${getUrl}${publicPage ? `&public_hash=${response?.publicShareHash || ''}` : ''}`);
      if (!publicPage) {
        getCustomerQuota();
      }
    }
  };
  const postAndGetData = async url => {
    setSelectedCountry(router?.query?.country_code?.toString()?.toUpperCase() || '');
    const payload = {
      URL: url,
      selectedCountry: getSingleUrlParam('country_code') ? getSingleUrlParam('country_code') : getCompetitorResearcherDetail?.data?.countryCode || '',
      mode: getSingleUrlParam('mode') || 'domain',
    };
    const getUrl = payload.URL?.replace(/\.$/, '')?.replace(/\/$/, '');
    const payloadFinal = {url: getUrl, mode: payload?.mode, country_code: payload?.selectedCountry};
    const response = await postNewCompetitor(payloadFinal);
    if (response?.id) {
      setCompetitorResearcherUrl(getUrl);
      await router?.push(`/site-explorer/detail${publicPage ? '-public' : ''}/${response?.id}?domain=${getUrl}${publicPage ? `&public_hash=${response?.publicShareHash || ''}` : ''}`, undefined, {shallow: true});
      if (!publicPage) {
        getCustomerQuota();
      }
    }
  };
  const id = `${router?.query?.id || ''}`;
  useEffect(() => {
    if (id) {
      if (id == 'new') {
        const url = getSingleUrlParam('url');
        postAndGetData(url);
      } else {
        try {
          loadCompetitorResearcherDetail(id);
        } catch (e) {
          notification.error('Failed', 'Failed to get competitor researcher detail');
        }
      }
    }
  }, [id]);
  useEffect(() => {
    setUrlFieldValue(inputFieldValue ? inputFieldValue : urlFieldValue ? urlFieldValue : selectedDomain || getMainUrl || competitorResearcherUrl);
    setCompetitorResearcherUrl(getMainUrl);
  }, [getMainUrl, competitorResearcherUrl, selectedDomain]);


  useEffect(() => {
    form.setFieldsValue({
      URL: urlFieldValue? urlFieldValue : '',
    });
  }, [urlFieldValue]);

  const onSubmit = async companyInfo=> {
    setLoader(true);
    await handleSubmit(companyInfo);
    form.setFieldsValue({
      url: '',
      keyword: '',
      selectedCountry: 'US',
    });
    // setUrlFieldValue('');
    // form.resetFields();
    setLoader(false);
  };

  const onCountryChange = value => {
    setSelectedCountry(value);
  };
  const modeOptions=[
    {name: 'Domain', value: 'domain'},
    {name: 'Sub Domain', value: 'subdomain'},
    {name: 'Target Page', value: 'target_page'},
    {name: 'Sub Folder', value: 'subfolder'},
  ];
  const getPlaceholder = ()=> {
    if (selectedModeOption == 'domain') {
      return 'Enter a URL or keyword';
    } else if (selectedModeOption == 'target_page') {
      return 'example.com/target-page';
    } else {
      return 'subdomain.example.com';
    }
  };

  const errorMessage = 'Please enter a valid url';

  return (
    <Form onFinish={onSubmit} form={form} style={{marginRight: isHeader ? '23px' : ''}}>
      <Wrapper>
        <Form.Item
          style={{marginBottom: '0px'}}
          name={'URL'}
          rules={[
            {
              required: true,
              message: selectedModeOption == 'domain' ? 'Please enter Page URL' : 'Please enter Keyword',
            },
            {
              pattern: urlValidationRegex,
              message: errorMessage,
            },
          ]}
        >
          <InputContainer >
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <InputStyled
                value={urlFieldValue}
                placeholder={getPlaceholder()}
                onChange={e => {
                  const value = e.target.value;
                  setUrlFieldValue(e.target.value);
                  const checkDomain = value?.match(urlDomainRegex);
                  const checkSubDomain = value?.match(urlSubDomainRegex);
                  const checkTargetPage = value?.match(targetPageRegexV3);
                  if (checkDomain) {
                    setSelectedModeOption('domain');
                  } else if (checkSubDomain) {
                    setSelectedModeOption('subdomain');
                  } else if (checkTargetPage) {
                    setSelectedModeOption('target_page');
                  } else {
                    setSelectedModeOption('domain');
                  }
                }}
              />
            </div>
          </InputContainer>
        </Form.Item>
        {!isHideDomainSelecter && !isHeader && <StyledSelect
          value={selectedModeOption}
          dropdownStyle={{backgroundColor: '#fff', color: '#2D2F34'}}
          suffixIcon={<FontAwesomeIcon icon={faCaretDown} color='#121212' style={{marginTop: '-15px'}}/>}
          style={{minWidth: 130}} onChange={setSelectedModeOption}>
          {modeOptions?.map((item, index) => {
            return <Option key={index} value={item?.value}> <OptionContent>{item?.name}</OptionContent></Option>;
          },
          )}
        </StyledSelect>}
        <StyledSelectDiv isHeader={isHeader}>
          <CountryDropdown countriesToInclude={isSE ? countriesListForSE : countriesListForCr} showWorldWide={true} dropdownStyle={{width: '200px'}} onChange={onCountryChange} showSearch value={selectedCountry} smallDropdown={smallCountryDropdown} height={height}/>
        </StyledSelectDiv>
        <div>
          <ButtonStyled
            variant='solid'
            color='purple'
            htmlType='submit'
            loading={isHeader? false : loader}
          >
            {isHeader ? loader ? <div className='searchicon' style={{height: '32px'}}><LoadingOutlined /></div> : <FontAwesomeIcon icon={faSearch} className='search'/> : 'Search'}
          </ButtonStyled>
        </div>
      </Wrapper>
    </Form>
  );
});

