import React, {useState, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import {faCircleCheck, faCircleExclamation, faTriangleExclamation} from '@fortawesome/pro-duotone-svg-icons';
import {Tooltip} from 'antd';
import {faPen} from '@fortawesome/pro-solid-svg-icons';
import {observer} from 'mobx-react';
import FreezeWrapper from '../../../freezTooltip';
import {PopupContainer} from '../styles';
// import {SuggestionButton} from '../styles';

const AddressField = observer(({openModal, value, suggestion, isInKnowledgeGraph}) => {
  const [valueArray, setValueArray] = useState([]);
  const [currentValue, setCurrentValue] = useState<any>( );

  useEffect(() => {
    if (value && Object.keys(value).length) {
      const formattedValues = Object.entries(value).map(([key, val]) => ({
        fieldName: key,
        value: val,
      }));
      setValueArray(formattedValues);
    }
  }, [value]);
  useEffect(() => {
    valueArray?.length && setValues();
  }, [valueArray]);

  const setValues = async () => {
    if (value && Object.keys(value).length) {
      const fieldValue = Object.entries(value).map(([key, val]) => ({
        fieldName: key,
        value: val,
      }));
      setCurrentValue(fieldValue);
    }
  };

  const extractValue = () => {
    const addressLines = value?.address_lines?.slice(0, 2)?.filter(item => item?.trim())?.join(', ');
    const dataArray = [
      addressLines,
      value?.address_locality,
      value?.addressAdministrativeArea,
      value?.address_postal_code,
    ];
    if (value?.businessAddress?.length) {
      const businessAddressParts = value.businessAddress.split(', ');
      dataArray.push(businessAddressParts[businessAddressParts?.length - 1]);
    }
    return (
      <div>
        {dataArray.filter(item => item?.trim()).join(', ')}
      </div>
    );
  };

  const isEmptyValue = value => {
    return value === null || value === '' || (Array.isArray(value) && value.length === 0) || value === undefined;
  };

  const checkAllValues = () => {
    if (!currentValue) return true;
    return currentValue?.filter(i => i?.fieldName !== 'addressRegionCode' && i?.fieldName !== 'latlng')?.every(item => isEmptyValue(item.value));
  };

  return (
    <>

      <PopupContainer style={{color: checkAllValues() && '#2D6CCA', width: 'calc(100% - 60px)', cursor: checkAllValues() && 'pointer', marginLeft: !isInKnowledgeGraph && 18}}>
        <div onClick={() => checkAllValues() && openModal(suggestion, currentValue)}>
          {checkAllValues() ? 'Add Address' : extractValue()}
        </div>
      </PopupContainer>
      <div className='edit-icon'>
        <FreezeWrapper>
          <Tooltip title='Edit'>
            <div onClick={() => openModal(suggestion, currentValue)}>
              <FontAwesomeIcon
                style={{marginRight: 6}}
                icon={faPen}
                color='#2D6CCA'
              />
          Edit
            </div>
          </Tooltip>
        </FreezeWrapper>
      </div>
    </>
  );
});

export default AddressField;
