import {
  faImageLandscape, faIdBadge, faPhonePlus, faPhoneXmark, faListOl, faAlignLeft, faCalendarPlus, faCode,
  faGrid2Plus, faClock, faSparkles, faTags, faListCheck, faListTree, faLinkSimple, faThumbsUp, faMessageLines, faQuestion, faStarHalf,
  faLocationPlus,
  faLocationCrosshairs,
} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export const iconsMap = {
  '_id': {
    icon: <FontAwesomeIcon icon={faGrid2Plus} />,
    title: 'Create a Local SEO Heatmap (Keyword Tracking)',
    buttonText: 'Manage Heatmap',
  },
  'media_profile': {
    icon: <FontAwesomeIcon icon={faIdBadge} />,
    title: 'Add your Business Logo',
    buttonText: 'logo',
  },
  'media_cover': {
    icon: <FontAwesomeIcon icon={faImageLandscape} />,
    title: 'Add a Cover Image',
    buttonText: 'photo',
  },
  'phone_numbers': {
    icon: <FontAwesomeIcon icon={faPhonePlus} />,
    title: 'Add Phone Number',
    buttonText: 'phone',
  },
  '_phone_number': {
    icon: <FontAwesomeIcon icon={faPhonePlus} />,
    title: 'Add Phone Number',
    buttonText: 'phone',
  },
  '_phone_number_doesnt_match': {
    icon: <FontAwesomeIcon icon={faPhoneXmark} />,
    title: 'Phone Number doesn’t match on Business Profile and your site',
    buttonText: 'phone',
    notIntegrated: true,
  },
  '_business_address': {
    icon: <FontAwesomeIcon icon={faLocationPlus} />,
    title: 'Add Business Address',
    buttonText: 'address',
  },
  'address_lines': {
    icon: <FontAwesomeIcon icon={faLocationPlus} />,
    title: 'Add Business Address',
    buttonText: 'address',
  },
  'businessAddressDoesntMatch': {
    icon: <FontAwesomeIcon icon={faLocationCrosshairs} />,
    title: 'Business Address doesn’t match on Business Profile and your site',
    buttonText: 'address',
  },
  'categories._primary_category': {
    icon: <FontAwesomeIcon icon={faListOl} />,
    title: 'Add Primary Category',
    buttonText: 'category',
  },
  'categories._secondary_category': {
    icon: <FontAwesomeIcon icon={faListOl} />,
    title: 'Add Additional Category',
    buttonText: 'category',
  },
  'business_description': {
    icon: <FontAwesomeIcon icon={faAlignLeft} />,
    title: 'Add a Business Description',
    buttonText: 'description',
  },
  'opening_date': {
    icon: <FontAwesomeIcon icon={faCalendarPlus} />,
    title: 'Add Opening Date',
    buttonText: 'date',
  },
  '_embed_map': {
    icon: <FontAwesomeIcon icon={faCode} />,
    title: 'Embed GBP Map on your site',
    buttonText: 'Get Embed Code',
  },
  'website_uri': {
    icon: <img src={'/icons/faGlobePointer.svg'} style={{width: 16, height: 16}} />,
    title: 'Add a Website URL',
    buttonText: 'URL',
  },
  'open_hours': {
    icon: <FontAwesomeIcon icon={faClock} />,
    title: 'Add Open Hours',
    buttonText: 'hours',
  },
  'special_hours': {
    icon: <FontAwesomeIcon icon={faSparkles} />,
    title: 'Add Special Hours',
    buttonText: 'hours',
  },
  'products': {
    icon: <FontAwesomeIcon icon={faTags} />,
    title: 'Add Products',
    buttonText: 'product',
    notIntegrated: true,
  },
  'services': {
    icon: <FontAwesomeIcon icon={faListCheck} />,
    title: 'Add Services',
    buttonText: 'service',
  },
  'services._description': {
    icon: <FontAwesomeIcon icon={faAlignLeft} />,
    title: 'Add Service Description',
    buttonText: 'description',
  },
  'serviceDescriptionImprove': {
    icon: <FontAwesomeIcon icon={faAlignLeft} />,
    title: 'Improve Service Description',
    buttonText: 'description',
  },
  'attributes._url_appointment': {
    icon: <FontAwesomeIcon icon={faLinkSimple} />,
    title: 'Add Booking URL',
    buttonText: 'URL',
  },
  'attributes._social_media_url': {
    icon: <FontAwesomeIcon icon={faThumbsUp} />,
    title: 'Add Missing Social Media Profile',
    buttonText: 'links',
  },
  'attributes': {
    icon: <FontAwesomeIcon icon={faListTree} />,
    title: 'Add Attributes',
    buttonText: 'attribute',
  },
  'POST': {
    icon: <FontAwesomeIcon icon={faMessageLines} />,
    title: 'Review AI Generated Post',
    buttonText: 'Review post',
  },
  'QUESTION': {
    icon: <FontAwesomeIcon icon={faQuestion} />,
    title: 'Review AI Generated Q&A',
    buttonText: 'Review Q&A',
  },
  'REVIEW': {
    icon: <FontAwesomeIcon icon={faStarHalf} />,
    title: 'Respond to Review from ',
    buttonText: 'Review reply',
  },
  '_embed_map=missing': {
    icon: faCode,
    title: 'Embed GBP Map on your site',
    buttonText: 'Get Embed Code',
  },
  '_business_address=missing': {
    icon: faLocationPlus,
    title: `Business Address doesn’t match on Business Profile and`,
  },
  '_phone_number=missing': {
    icon: faPhonePlus,
    title: `Phone Number doesn’t match on Business Profile and`,
  },
};
