import React from 'react';
import {observer} from 'mobx-react-lite';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowUpRightFromSquare} from '@fortawesome/pro-regular-svg-icons';
import styled from 'styled-components';
import {useStore} from '@/store/root-store';
import {addProtocolToDomainHttps} from '@/utils/url';
import {NextImg} from '@/utils/nextImg';
import Head from 'next/head';
import {getLocation} from '@/utils/string';
import {Tooltip} from 'antd';
import {SkeletonHorizontalLoaderGray} from '@/components/common-components';
import GrayRingLoader from '@/components/common-components/components/skeleton/gray-ring-loader';


export const SiteBannerSection = observer(() => {
  const {competitorResearcherV2: {overview: {getCompetitorResearcherDetail, loadingDetail}}} = useStore('');
  const {reportBuilder: {details: {singleProjectData}}} = useStore('');
  const getFormattedString = str => {
    const apiString = str || '';
    const step1 = apiString.replace(/\\r/g, '\r').replace(/\\n/g, '\n').replace(/\\t/g, '\t');
    const step2 = step1.trim();
    const step3 = step2.replace(/\s+/g, ' ');

    return step3;
  };

  const GetContext = ({summary}:any) => {
    return <div style={{display: 'flex', alignItems: 'flex-start'}}>
      <NextImg width={18} height={18} src='/img/sunIcon.svg' style={{marginTop: '6px'}} />
      <div style={{marginLeft: '10px'}}>
        <h2 style={{color: 'white', fontSize: '15px'}}>Site Explorer</h2>
        <p>{summary}</p>
      </div>
    </div>;
  };

  return (<>
    <BannerWrapper className='dragDropStyle'>
      <Head>
        {getCompetitorResearcherDetail?.data?.competitorResearch && <title>{getFormattedString(getCompetitorResearcherDetail?.data?.['title'])} SEO Report | {getLocation(location)}</title>}
      </Head>
      {loadingDetail ? <GrayRingLoader margin='30px 78px' /> : <NextImg height={100} width={165} style={{borderRadius: '5px'}} src={getCompetitorResearcherDetail?.data?.screenshotUrl} alt='' />}
      {loadingDetail ? <div style={{marginTop: '13px', marginLeft: '-25px'}}><SkeletonHorizontalLoaderGray skeletonMarginTop='50px' skeletonMarginBottom='40px' lightLoader={true} height='10px' width='600px' skeletonHeight='20px' />
        <SkeletonHorizontalLoaderGray skeletonMarginTop='60px' lightLoader={true} height='10px' width='800px' skeletonHeight='20px' /></div> : <div>
        <span style={{display: 'flex', alignItems: 'center'}}>
          {getCompetitorResearcherDetail?.data?.competitorResearch?.['redirectTo'] || getCompetitorResearcherDetail?.data?.url ?
            <a href={getCompetitorResearcherDetail?.data?.competitorResearch?.['redirectTo'] || addProtocolToDomainHttps(getCompetitorResearcherDetail?.data?.url)} target='_blank' rel='noreferrer'>
              {getCompetitorResearcherDetail?.data?.competitorResearch?.['redirectTo'] || addProtocolToDomainHttps(getCompetitorResearcherDetail?.data?.url)} <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a> : null}
          {singleProjectData?.aiSummary['seDataSummary'] && singleProjectData['isAiSummaryEnabled'] ? <h2 style={{fontSize: '18px', fontWeight: '500', padding: '10px', display: 'flex'}}>
            <Tooltip
              color='#0f7ee5'
              placement='rightTop'
              overlayInnerStyle={{width: '390px', padding: '20px', borderTopRightRadius: '50px', borderBottomLeftRadius: '50px', borderBottomRightRadius: '50px'}}
              title={<GetContext summary={singleProjectData ? singleProjectData?.aiSummary['seDataJsonSummary'] ? Object.values(singleProjectData?.aiSummary['seDataJsonSummary'])[0] : '' : ''} />}><div className='summary' style={{background: '#0f7ee5', marginLeft: '10px'}}>
                <NextImg width={12} height={12} src='/img/sunIcon.svg' alt='' style={{height: '15px', width: '15px'}}/></div> </Tooltip>
          </h2> : '' }
        </span>
        <h2>{getCompetitorResearcherDetail?.data?.competitorResearch && getCompetitorResearcherDetail?.data?.['title'] ? getFormattedString(getCompetitorResearcherDetail?.data?.competitorResearch['title']) : '-'}</h2>
        <p>{ getCompetitorResearcherDetail?.data?.competitorResearch && getCompetitorResearcherDetail?.data?.['metaDesc']}</p>
      </div>}
    </BannerWrapper>
  </>
  );
});


const BannerWrapper = styled.div`
display: flex;
div{
  margin-left: 20px;
}
img{
  height: 100px;
  width: 165px;
  border-radius: 5px;
  object-fit: cover;
  object-position: 0 0;
}
h2{
  color: white;
  margin: 0px;
  font-size: 24px;
  font-weight: 600;
  color: black;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
p{
  color: white;
  font-size: 14px;
  color: #4E5156;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
`;
