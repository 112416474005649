import React, {useEffect} from 'react';
import {Form, Select} from 'antd';
import styled from 'styled-components';
import {Button} from '@/components/common-components/v2/Button';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import {Modal} from '@/components/common-components/components/modal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {CountryDropdown} from '@/components/common-components/v2';
import {observer} from 'mobx-react-lite';
import styles from '../style.module.scss';
import {integrationApi} from '@/api/gsc';
import {useStore} from '@/store/root-store';
import {toJS} from 'mobx';
import {PROJECTS_GSC_AUTHORIZE_CLICKED} from '@/constants/events';
import {MixPanel} from '@/utils/mixpanel';
import {getSingleUrlParam} from '@/utils/url';
const {Option} = Select;

interface Props {
  gscConnectModal?: boolean;
  setGscConnectModal?: (value) => void;
  data?: any;
  country?: string;
  gscLoading?: boolean;
  siteProperty?: any;
  onCountryChange?: (value) => void;
  setSiteProperty?: (value) => void;
  ottoPage?: boolean;
}
export const NewGscModal: React.FC<Props> = observer(
  ({
    gscConnectModal,
    setGscConnectModal,
    data,
    country,
    gscLoading,
    onCountryChange,
    siteProperty,
    setSiteProperty,
    ottoPage,
  }) => {
    const {
      siteAuditorV2: {siteAuditConnectGsc},
    } = useStore('');
    const domain = getSingleUrlParam('domain');
    const {
      navBar,
      navBar: {propertiesData},
      gsc: {criteria},
    } = useStore('');
    const {
      ottoStore: {onConnectToGsc, loadOttoDomainDetail},
      settings: {
        customer: {
          profile: {address},
        },
      },
    } = useStore('');

    const setConnectToGsc = () => {
      if (domain) {
        loadOttoDomainDetail(domain);
      }
    };

    useEffect(() => {
      if (address?.defaultLocation) {
        onCountryChange(address?.defaultLocation);
      }
    }, [address?.defaultLocation]);

    const onGSCConnect = async () => {
      if (data?.id && !ottoPage) {
        await siteAuditConnectGsc(data?.id, {
          property_url: siteProperty,
          country_code: country,
        });
        setGscConnectModal(false);
      } else {
        await onConnectToGsc(data?.id, siteProperty, country);
        setGscConnectModal(false);
        setConnectToGsc();
      }
    };
    const onModalPropertyChange = value => {
      setSiteProperty(value);
    };
    const currentProperty = navBar.getSiteProperty(criteria.property);
    const propertiesDataForSA = toJS(propertiesData).filter(
      item => item?.activeForCurrentCustomer,
    );
    const availableCountryData = toJS(
      currentProperty?.availableCountryCodes,
    )?.filter(code => currentProperty?.activeCountryCodes?.includes(code));
    return (
      <Modal
        className={styles.modalIconStyle}
        visible={gscConnectModal}
        width={550}
        onClose={() => setGscConnectModal(false)}
      >
        <div>
          <div>
            <p style={{fontSize: 20, fontWeight: 600, marginBottom: 10}}>
              GSC Settings
            </p>
          </div>
          <div>
            <p style={{fontSize: 16, fontWeight: 500, marginBottom: 10}}>
              Google Search Console
            </p>
          </div>

          <Form onFinish={onGSCConnect}>
            <Form.Item
              name='property'
              style={{marginBottom: 0}}
              className={styles.selectStyle}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: '10px',
                }}
              >
                <p style={{fontSize: 14, margin: 0, color: '#4E5156'}}>
                  GSC Property
                </p>
                <Select
                  style={{width: 350}}
                  defaultValue={siteProperty}
                  suffixIcon={
                    <FontAwesomeIcon color='#121212' icon={faCaretDown} />
                  }
                  value={siteProperty}
                  showSearch
                  placeholder='Select Property'
                  optionFilterProp='children'
                  onChange={onModalPropertyChange}
                >
                  <Option key={'no_property'} value={''}>
                    {'None'}
                  </Option>
                  {propertiesDataForSA?.map((item, idx) => {
                    return (
                      <Option key={idx} value={item?.url}>
                        {item?.url}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </Form.Item>
            <Form.Item name='country' className={styles.selectStyle}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: '10px',
                }}
              >
                <p style={{fontSize: 14, color: '#4E5156', margin: '0'}}>
                  Location
                </p>
                <div>
                  <CountryDropdown
                    countriesToInclude={availableCountryData}
                    aligned={true}
                    defaultValue={'US'}
                    variant='grey'
                    isReport={true}
                    suffixIcon={
                      <FontAwesomeIcon color='#121212' icon={faCaretDown} />
                    }
                    width={'350px'}
                    className={'selectInput'}
                    value={country}
                    onChange={onCountryChange}
                  />
                </div>
              </div>
            </Form.Item>
            {propertiesDataForSA?.some(z => z?.url !== data?.domain) && (
              <ConnectToGSCWrapper
                onClick={() => {
                  MixPanel.track(PROJECTS_GSC_AUTHORIZE_CLICKED);
                  location.href = integrationApi.getGoogleConnectUrl();
                }}
              >
                + Connect new GSC account
              </ConnectToGSCWrapper>
            )}
            <ButtonWrapper>
              <Button
                style={{border: '1px solid #e8e8e8'}}
                onClick={() => setGscConnectModal(false)}
                color='white'
                textColor='#4E5156'
              >
                Cancel
              </Button>
              <Form.Item style={{marginBottom: 0}}>
                <Button
                  htmlType='submit'
                  disabled={!siteProperty}
                  loading={gscLoading}
                >
                  Update
                </Button>
              </Form.Item>
            </ButtonWrapper>
          </Form>
        </div>
      </Modal>
    );
  },
);

const ButtonWrapper = styled.div`
  border-top: 1px solid #e8e8e8;
  padding-top: 20px;
  display: flex;
  justify-content: right;
  align-items: center;
`;

const ConnectToGSCWrapper = styled.div`
  font-size: 12px;
  display: flex;
  margin-bottom: 10px;
  cursor: pointer;
  justify-content: end;
  color: #2d6cca;
  &:hover {
    text-decoration: underline;
  }
`;
