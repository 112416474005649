import {Button, Checkbox, Dropdown, Empty, Input, Rate, Tooltip, Menu, Divider} from 'antd';
import {Modal} from '@/components/common-components';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {faArrowLeft, faXmark, faCircleNotch, faMagnifyingGlass, faCheck, faSync, faCodePullRequest, faUndo, faEyeSlash, faEye, faSpinnerThird} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useState, useEffect} from 'react';
import {faCaretDown, faLocationDot, faPen} from '@fortawesome/pro-solid-svg-icons';
import {ApproveModal, buttonOptions} from './modals/ApproveModal';
import {EditPostModal} from './modals/EditPostModal';
import {useStore} from '@/store/root-store';
import {OTTO_V2_API} from '@/api/otto-v2';
import moment from 'moment';
import {notification} from '@/utils/notification-v2';
import {GBPMediaLibraryModal} from '../GbpPosts/GbpPosts/gbpMediaLibraryModal';
import {getStandAloneLocations} from '@/utils/functions';
import {toJS} from 'mobx';
import {humanize, truncate} from '@/utils/string';
import {StyledSpin} from '../../../Utils/styledSpin';
import {ModalForms} from '../GbpOptimization/ModalForms';
import {ModalStyled as ModalStyled2, NoDataDiv} from '../GbpOptimization/styles';
import FreezeWrapper from '../../freezTooltip';
import {camelCase, snakeCase} from 'lodash';
import {RingLoaderV2} from '@/components/common-components/components/skeleton/ring-loader-v2';
import {AddPhoneNumberModal} from './modals/addPhoneNumberModal';
import {SocialMediaUrlsModal} from './modals/socialMediaUrlsModal';
import {socialMediaMapping} from '../GbpOptimization';
import {BusinessDescriptionModal} from './modals/BusinessDescriptionModal';
import {iconsMap} from './utils';
import {apiError} from '@/utils/api';
import {EmbedMapModal} from './modals/embedMapModal';
import {useRouter} from 'next/router';

export const MyTasks = observer(({isInGBPStandAlone=false, showBusinessDropdown = false, maxHeight = null, isInGbpProjectOverview = false, tableHeight = '100px', statsLoader=false}) => {
  const {ottoV2Store: {getMyTasks, myTasksLocations, setMyTasksLocations, selectedCategory, getGBPLocations, gbpLocations, loadingGBPLocations, loadingTasks,
    selectedBusiness, handleNewChangesInGbp, newChanges, getOptimizationData, optimizationData,
  }} = useStore('');

  const router = useRouter();
  const [selectedTitle, setSelectedTitle] = useState<any>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [modalData, setModalData] = useState(null);
  const [refresh, setRefresh] = useState(null);
  const [loader, setLoader]= useState('loader');
  const [openGbpLibraryModal, setOpenGbpLibraryModal] = useState(false);
  const [showGbpDropdown, setShowGbpDropdown] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [edit, setEdit] = useState('');
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [isCopied, setIsCopied]=useState(false);
  const activeArray = getStandAloneLocations(toJS(gbpLocations), searchValue)?.filter(location => myTasksLocations?.map(taskLocation => taskLocation?.id).includes(location?.id)) ?? [];
  const [pageNumber, setPageNumber] = useState(null);
  const [selectedField, setSelectedField] = useState('');
  const [primaryCategory, setPrimaryCategory] = useState(null);
  const [services, setServices] = useState([]);
  const [categories, setCategories] = useState([]);
  const [modalLoader, setModalLoader] = useState(false);
  const [selectedLocationCountryCode, setSelectedLocationCountryCode] = useState('');
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [showImageType, setShowImageType] = useState();
  const [gbpData, setGBPData] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [key, setKey] = useState(Math.random().toFixed(3));
  const [refreshLoader, setRefreshLoader] = useState(false);
  const [pendingFields, setPendingFields] = useState([]);
  const [isDeploying, setIsDeploying] = useState(false);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const pendingFieldsCount = pendingFields?.reduce((sum, item) => sum + item.pendingTasks.length, 0);
  const socialAttributes = ['attributes/url_facebook', 'attributes/url_instagram', 'attributes/url_youtube', 'attributes/url_twitter', 'attributes/url_linkedin', 'attributes/url_pinterest', 'attributes/url_tiktok'];
  const socialMedia = ['attributesUrlInstagram', 'attributesUrlYoutube', 'attributesUrlLinkedin', 'attributesUrlFacebook', 'attributesUrlTwitter', 'attributesUrlTiktok', 'attributesUrlPinterest'];
  const [action, setAction] = useState(null);
  const [filterValue, setFilterValue] = useState(false);
  const [hoveredButtonId, setHoveredButtonId] = useState(null);
  const BlackList = gbpData[0]?.data?.attributes?.blacklistAttributes;
  const [openPhoneNumberModal, setOpenPhoneNumberModal] = useState({visible: false, type: null});
  const [openSocialMediaUrlModal, setOpenSocialMediaUrlModal] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [descriptionModalVisible, setDescriptionModalVisible] = useState(false);
  const [count, setCount] = useState(0);
  const [tasksResponse, setTaskResponse] = useState<any>({});
  const initialFields = [
    {
      label: 'Address',
      fieldName: 'addressData',
      icon: faLocationDot,
      fieldType: 'addressData',
      customFieldType: 'addressData',
      value: null,
    },
  ];
  const [bulkApproveLoader, setBulkApproveLoader] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [modalSuggestion, setModalSuggestion] = useState<any>();
  const selectedIds = isInGbpProjectOverview ? selectedBusiness[0]?.id : myTasksLocations?.map(item => item.id)?.join(',');
  const [embedModalvisible, setEmbedModalVisible] = useState(false);
  useEffect(() => {
    if (selectedIds) {
      getData(false, true);
    } else {
      setTableData([]);
      setTaskResponse({});
    }
  }, [selectedIds, newChanges]);

  useEffect(() => {
    if (refresh) {
      getData();
    }
  }, [refresh]);

  useEffect(() => {
    setSearchValue('');
  }, [selectedCategory]);

  useEffect(() => {
    if (!isInGbpProjectOverview) getGBPLocations(false, isInGBPStandAlone);
  }, []);

  useEffect(() => {
    if (modalData?.callToActionType == 'CALL') {
      setPhoneNumbers(gbpLocations?.find(location => location?.id == modalData?.location)?.phoneNumbers);
    }
  }, [modalData]);

  useEffect(() => {
    if (!isModalOpen && modalType===null) {
      setModalData(null);
    }
  }, [isModalOpen]);

  useEffect(() => {
    getData();
  }, [filterValue]);

  useEffect(() => {
    if (!getStandAloneLocations(toJS(gbpLocations), '')?.length && isInGbpProjectOverview) {
      setMyTasksLocations([]);
    }
  }, [getStandAloneLocations(toJS(gbpLocations), '')]);

  const getData = async (loadMore=false, storeLoader=false) => {
    try {
      if (selectedIds) {
        if (loadMore) {
          setLoader('data');
        } else {
          setLoader('loader');
        }
        const data = await getMyTasks(loadMore ? pageNumber : null, isInGBPStandAlone, storeLoader, filterValue, isInGbpProjectOverview);
        setTaskResponse(data);
        if (data?.isCancel || !data) return;
        if (data?.data) {
          data.data?.forEach(task => {
            if (task.attributes.taskType !== 'LOCATION') {
              const taskData = data.meta[task.attributes.taskType.toLowerCase()+ 's']?.find(item => item.id == task.attributes?.objectId);
              task['data'] = taskData;
            }
          });
          if (loadMore) {
            setTableData(prevState => ([...prevState, ...data.data.filter(item => item.attributes.fieldType !== 'pending_sync_fields')]));
          } else {
            setTableData(data.data.filter(item => item.attributes.fieldType !== 'pending_sync_fields'));
          }
          setPendingFields(data.meta.locations?.map(item => ({id: item.id, name: item.businessName, businessAddress: item.businessAddress, pendingTasks: item.pendingSyncFields?.filter(item => item !== 'attributes')})));
          setCount(data?.meta?.pagination?.count);
          if (data?.links?.next) {
            if (loadMore) {
              setPageNumber(prev => prev + 1);
            } else setPageNumber(2);
          } else {
            setPageNumber(null);
          }
        } else {
          setTableData([]);
        }
        setRefresh(false);
      } else {
        setPendingFields([]);
        setTableData([]);
        setPageNumber(null);
        setTaskResponse({});
      }
      setLoader(null);
    } catch {
      setLoader(null);
      notification.error('Failed', 'Failed to load tasks data');
    }
  };

  const handleSocialMediaUrls = optimizationV2Data => {
    const attributes = optimizationV2Data?.data?.attributes?.attributes;
    if (!attributes) return [];

    return Object.keys(socialMediaMapping)
      .filter(key => attributes[key])
      .map((key, index) => ({
        [`url_${index + 1}`]: attributes[key],
        [`social_media_${index + 1}`]: socialMediaMapping[key],
      }));
  };

  const getFieldSuggestion = (data, field) => {
    const suggestionItem = data?.meta?.suggestions?.find(suggestion => suggestion?.fieldName == field);
    if (field === 'categories' && suggestionItem?.value) {
      suggestionItem['value'] = suggestionItem?.value?.map(cat => data?.meta?.categories?.find(val => val.key === cat));
    }
    return suggestionItem;
  };

  const handleLocationTasks = async (task, type) => {
    setLoader(task.id);
    let gbpObj;
    if (isInGbpProjectOverview) {
      gbpObj = JSON.parse(JSON.stringify(optimizationData));
    } else {
      gbpData?.find(item => item.data.id == task.relationships.location.data?.id);
    }
    if (!gbpObj) {
      const response = await OTTO_V2_API.getOptimizationsV2(task.relationships.location.data?.id);
      setGBPData(prev => [...prev, response]);
      gbpObj = response;
    }
    if (type == 'HEATMAP') {
      const path = isInGbpProjectOverview ? 'local-seo-heatmaps' : isInGBPStandAlone ? '/research/seo-projects-list' : '/gbp-galactic/local-seo-heatmaps';
      router.push(path + '?cid=' + gbpObj?.data?.attributes?.mapsCid.toString());
      return;
    }
    const field = task.attributes.fieldType === 'address_lines' || task.attributes.fieldType === '_business_address' ? 'addressData' : task.attributes.fieldType;
    if (field === 'services' || field?.startsWith('categories')) {
      setModalSuggestion(getFieldSuggestion(gbpObj, field?.startsWith('categories') ? 'categories' : field));
    }
    if (field == 'services' || field == 'services._description') {
      const catId = gbpObj?.data?.attributes?.categories?.[0];
      const category = gbpObj?.meta?.categories?.find(item => item.key == catId);
      setPrimaryCategory(category ?? null);
      setSelectedCategories(gbpObj?.data?.attributes?.categories?.map(cat => typeof cat === 'string' ? gbpObj?.meta?.categories?.find(val => val.key === cat) : cat));
    }
    if (field?.includes('categories') || field?.includes('phone_number')) {
      setSelectedField(field?.startsWith('categories') ? 'categories' : 'phone_numbers');
      setOpenPhoneNumberModal({visible: true, type: field?.startsWith('categories') ? 'businessCategory' : 'phoneNumber'});
    } else if (field?.startsWith('business_description')) {
      setSelectedField('business_description');
      setDescriptionModalVisible(true);
    } else if (field == '_business_address') {
      setSelectedField('addressData');
    } else if (field == '_phone_number') {
      setSelectedField('phone_numbers');
    } else if (field == 'services._description') {
      setSelectedField('services');
    } else if (field?.startsWith('_embed_map')) {
      setSelectedField('_embed_map');
      setEmbedModalVisible(true);
    } else {
      setSelectedField(field?.includes('attributes.') ? field?.split('._')[1] : field.includes('media') ? 'images' : field);
    }
    if (field.includes('media')) {
      setShowImageType(field.split('_')[1]);
    }
    getSelectedValue(field, (field.includes('categories') || field.includes('services')), gbpObj);
    setModalType(type);

    if (field?.includes('_social_media_url')) {
      const dataObj = {...gbpObj};
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Object.entries(dataObj?.data?.attributes || {}).forEach(([key, _]: any) => {
        if (key === 'attributes') {
          dataObj.data.attributes[key] = Object.entries(dataObj.data.attributes.attributes)
            .reduce((acc, [key, item]) => {
              const formattedKey = snakeCase(key).replace('attributes_', 'attributes/');
              if (Array.isArray(item)) {
                acc[formattedKey] = item;
              } else {
                acc[formattedKey] = item;
              }
              return acc;
            }, {});
        }
      });
      setSelectedValue(handleSocialMediaUrls(dataObj));
      setSelectedData(dataObj);
      setOpenSocialMediaUrlModal(true);
    }

    if (!field?.includes('categories') && !field?.includes('phone_number') && !field?.startsWith('business_description') && !field?.includes('_social_media_url') && !field?.includes('_embed_map')) {
      setIsModalOpen(true);
    }
    if (!field?.startsWith('_embed_map')) {
      setModalLoader(false);
    }
    setLoader(null);
    const location = gbpLocations?.find(location => location?.id == task.relationships.location.data?.id);
    setSelectedLocationCountryCode(location?.addressRegionCode);

    setSelectedLocation(task.relationships.location.data.id);
    setKey(Math.random().toFixed(3));
  };

  const getSelectedValue = (fieldName, fromMeta, gbpObj) => {
    let value = null;
    if (fieldName === 'addressData') {
      let arr = initialFields;

      Object?.keys(gbpObj?.data?.attributes)?.forEach(attribute => {
        arr = arr?.map(item => {
          if (item?.fieldName === 'addressData') {
            if (attribute?.includes('address') || attribute?.includes('latlng') || attribute?.includes('businessAddress')) {
              item.value = item?.value || {};

              const attributeMapping = {
                addressAdministrativeArea: 'addressAdministrativeArea',
                addressLines: 'address_lines',
                addressLocality: 'address_locality',
                addressRegionCode: 'addressRegionCode',
                addressPostalCode: 'address_postal_code',
                businessAddress: 'businessAddress',
                latlng: 'latlng',
              };

              const mappedAttribute = attributeMapping[attribute] || (attribute.includes('businessAddress') ? 'businessAddress' : null);

              if (mappedAttribute) {
                item.value[mappedAttribute] = gbpObj?.data?.attributes[attribute];
              }
            }
          } else if (item?.fieldName === snakeCase(attribute)) {
            item['value'] = gbpObj?.data?.attributes[attribute];
          }

          return item;
        });
      });

      const addressData = arr.find(i => i?.fieldName === 'addressData');
      let fieldValue;
      if (addressData?.value && Object.keys(addressData?.value).length) {
        fieldValue = Object.entries(addressData?.value).map(([key, val]) => ({
          fieldName: key,
          value: val,
        }));
      }
      value = fieldValue ? JSON.parse(JSON.stringify(fieldValue)) : [];
    } else if (fromMeta) {
      if (fieldName.includes('services')) {
        const services = gbpObj?.data.attributes.services;
        value = services.map(item => {
          let updatedValue = item;
          if (item.serviceTypeId) {
            updatedValue = gbpObj?.meta?.services?.find(ser => ser.key == item.serviceTypeId);
          }
          return updatedValue;
        });
      } else if (fieldName.includes('categories')) {
        const catIds = gbpObj?.data?.attributes?.categories;
        value = catIds?.map(id => gbpObj?.meta?.categories?.find(item => item.key == id)) ?? [];
      }
    } else {
      if (fieldName.includes('social')) {
        value = JSON.parse(JSON.stringify(Object.entries(gbpObj.data.attributes.attributes)?.map(item => ({key: item[0], value: item[1]}))?.filter(item => socialMedia.includes(item.key))));
      } else if (fieldName.includes('appointment')) {
        value = gbpObj.data.attributes?.attributes?.attributesUrlAppointment;
      } else if (fieldName == 'attributes') {
        value = JSON.parse(JSON.stringify(gbpObj.data.attributes?.attributes));
        [...socialMedia, 'attributesUrlAppointment']?.forEach(item => {
          delete value[item];
        });
        value = {value: value};
      } else if (fieldName == '_embed_map') {
        value = gbpObj?.data?.attributes?.mapsUri;
      } else if (fieldName == 'opening_date') {
        value = gbpObj.data.attributes?.openInfo;
      } else if (fieldName == '_business_address') {
        value = gbpObj.data.attributes.addressLines;
      } else if (fieldName == '_phone_number') {
        value = gbpObj.data.attributes.phoneNumbers;
      } else {
        value = gbpObj.data.attributes?.[camelCase(fieldName)];
      }
    }
    setSelectedValue(value);
  };

  const openModal = async (task, type) => {
    try {
      if (type == 'LOCATION' || type == 'WEBSITE' || type == 'HEATMAP') {
        handleLocationTasks(task, type);
      } else if (type !== 'edit') {
        setLoader(task.id);
        const response = await OTTO_V2_API.getModalDataByTypeAndId((type?.toLowerCase() + 's'), task?.attributes?.objectId ?? task?.id, isInGBPStandAlone);
        setModalData({...response, businessName: isInGbpProjectOverview ? selectedBusiness[0]?.businessName : myTasksLocations?.find(item => item.id == response?.location)?.businessName ?? ''});
        setLoader(null);
        const title = type == 'POST' ? 'Approve Post' : type == 'QUESTION' ? 'Approve Q&A' : 'Approve Review Reply';
        if (type != 'edit') {
          const modalTitle = (
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginLeft: 15}}>
              <div>
                <div style={{fontSize: 24}}>{title}</div>
                {response?.schedulePublishingAt && <div style={{fontSize: 14, fontWeight: 400, marginTop: 8}}>Scheduled for {moment(response?.schedulePublishingAt).format('MMM DD, YYYY')}</div>}
              </div>
              {type == 'POST' && <div style={{fontSize: 12, color: '#2D6CCA', fontWeight: 400, cursor: 'pointer'}} onClick={() => openModal(task, 'edit')}>
                <FontAwesomeIcon icon={faPen}/> Edit post
              </div>}
            </div>
          );
          setSelectedTitle(modalTitle);
        }
        setModalType(type);
        setIsModalOpen(true);
      } else if (type == 'edit') {
        const modalTitle = (
          <div style={{marginLeft: 15}}>
            <div style={{fontSize: 14, fontWeight: 400, color: '#4E5156', cursor: 'pointer'}} onClick={() => openModal(task, 'POST')}><FontAwesomeIcon icon={faArrowLeft}/> Back to Post Preview</div>
            <div style={{fontSize: 24, marginTop: 10, marginRight: 5}} onClick={() => openModal(task, 'edit')}>Edit Post</div>
          </div>
        );
        setSelectedTitle(modalTitle);
        setModalType(type);
        setIsModalOpen(true);
      }
    } catch {
      setLoader(null);
      notification.error('Failed', 'Failed to Fetch Data');
    }
  };

  const checkPendingStatus = task => {
    const fieldName = task.attributes.fieldType;

    if (fieldName == 'status') {
      return false;
    }
    const pendingFieldsArray = pendingFields?.find(item => item.id == task.relationships.location.data.id)?.pendingTasks;

    if (!pendingFieldsArray?.length) {
      return false;
    } else {
      if (fieldName.includes('._')) {
        if (fieldName == 'attributes._social_media_url') {
          return ['facebook', 'instagram', 'youtube', 'twitter', 'linkedin', 'pinterest', 'tiktok']?.some(item => pendingFieldsArray.includes('attributes/url_' + item));
        } else if (fieldName == 'attributes._url_appointment') {
          return pendingFieldsArray.includes('attributes/url_appointment');
        } else if (fieldName.includes('attributes')) {
          return pendingFieldsArray.some(field => (field.includes('attributes') && ![...socialAttributes, 'attributes/url_appointment'].includes(field)));
        } else if (fieldName.includes('services')) {
          return pendingFieldsArray.includes('services');
        } else if (fieldName.includes('categories')) {
          return pendingFieldsArray.includes('categories');
        }
      } else if (fieldName == 'opening_date') {
        return pendingFieldsArray.includes('open_info');
      } else if (fieldName?.includes('business_address')) {
        const addressFields = ['latlng', 'address_lines', 'address_locality', 'address_postal_code'];
        return pendingFieldsArray?.some(field => addressFields?.includes(field));
      } else if (fieldName?.includes('phone_number')) {
        return pendingFieldsArray.includes('phone_numbers');
      } else {
        return pendingFieldsArray.includes(fieldName);
      }
    }
  };

  const selectImageFromMediaLibrary = image => {
    setLoader('image-file');
    if (image?.url) {
      try {
        setModalData(prevState => {
          return ({...prevState, imageUrl: image?.url});
        });
        setOpenGbpLibraryModal(false);
        setIsModalOpen(true);
        setLoader(null);
      } catch (e) {
        setLoader(null);
        return Promise.reject(e);
      }
    } else {
      setLoader(null);
    }
  };

  const myTaskLocationHandler = (location, event) => {
    let updatedLocations = JSON.parse(JSON.stringify(myTasksLocations));
    if (location == 'all') {
      if (updatedLocations?.length == getStandAloneLocations(toJS(gbpLocations), searchValue)?.length) {
        updatedLocations = [];
      } else {
        updatedLocations = JSON.parse(JSON.stringify(getStandAloneLocations(toJS(gbpLocations), searchValue)));
      }
    } else {
      if (event.target.checked) {
        updatedLocations?.push(toJS(location));
      } else {
        updatedLocations = updatedLocations?.filter(item => item.id != location?.id);
      }
    }
    const locationIds = updatedLocations?.map(location => location?.id);
    localStorage.setItem('standalone_tasks_selected_gbps', JSON.stringify(locationIds));
    setMyTasksLocations(updatedLocations);
  };

  const myTasksLocationList = (
    <DropdownContainer>
      {loadingGBPLocations? <RingLoaderV2 height='225px' fontSize='40px' /> :
        <>
          <GbpSearchInput onChange={e => setSearchValue(e.target.value)} value={searchValue} placeholder='Search...'
            prefix={<FontAwesomeIcon icon={faMagnifyingGlass} fontSize={'12px'} color='#A3A4A4' />}
          />
          <DropdownListContainer>
            {getStandAloneLocations(toJS(gbpLocations), searchValue)?.length > 1 && <Checkbox key='all' disabled={loadingTasks} checked={activeArray?.length == getStandAloneLocations(toJS(gbpLocations), searchValue)?.length} onClick={event => myTaskLocationHandler('all', event)}>All</Checkbox>}
            {getStandAloneLocations(toJS(gbpLocations), searchValue)?.length ? getStandAloneLocations(toJS(gbpLocations), searchValue)?.map(business => (
              <Checkbox disabled={loadingTasks} key = {business?.id} style={{width: '100%'}} checked={myTasksLocations?.some(item => item.id == business?.id)} value={business} onClick={event => myTaskLocationHandler(business, event)}>
                <div>
                  <div style={{fontWeight: 'bold', fontSize: '12px'}}>{business?.businessName}</div>
                  <p style={{color: '#4E5156', fontSize: '12px', fontFamily: 'Inter', fontWeight: '400'}}>{business?.businessAddress ? business?.businessAddress : business?.storeCode ? `Store code: ${business?.storeCode}` : ''}</p>
                </div>
              </Checkbox>
            )) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
          </DropdownListContainer>
        </>
      }
    </DropdownContainer>
  );

  const getLocationData = async id => {
    const response = await OTTO_V2_API.getOptimizationsV2(id);
    setGBPData(prevData => {
      const existing = prevData.some(item => item.data.id === response.data.id);
      if (existing) {
        return prevData.map(item =>
          item.data.id === response.data.id ? response : item);
      } else {
        return [...prevData, response];
      }
    });
    setGBPData(prev => [...prev, response]);
  };

  const updateData = async payload => {
    try {
      const updatePayload = [];
      payload.forEach(dataItem => {
        let value = dataItem.value;
        if (dataItem.fieldName == 'categories') {
          value = value.map(item => item.key);
        } else if (dataItem.fieldName == 'services') {
          value = value.map(item => {
            if (Object.keys(item).includes('categoryName')) {
              return {
                display_name: item.displayName,
                category_name: item.categoryName,
                description: item?.description,
                price: item?.price,
              };
            } else if (Object.keys(item).includes('serviceTypeId')) {
              return {service_type_id: item.serviceTypeId.key || item.serviceTypeId, description: item?.description, price: item?.price};
            }
          }).filter(item => item);
        } else if (dataItem.fieldName == 'attributes/url_appointment') {
          while (Array.isArray(value) && Array.isArray(value[0])) {
            value = value[0];
          }
          if (Array.isArray(value)) {
            value = value[0]?.startsWith('http') ? value : [`http://${value[0]}`];
          } else {
            value = value.startsWith('http') ? value : `http://${value}`;
          }
        }
        updatePayload.push({
          field_name: dataItem.fieldName,
          field_type: dataItem.fieldType,
          value: value,
        });
      });
      const v2Payload = {};
      const gbpObj = isInGbpProjectOverview ? JSON.parse(JSON.stringify(optimizationData)) : gbpData?.find(item => item.data.id == selectedLocation);
      if (updatePayload?.some(item => item.field_type == 'ATTRIBUTE')) {
        const previousAttributes = gbpObj?.data?.attributes?.attributes && Object.entries(gbpObj?.data?.attributes?.attributes)
          .reduce((acc, [key, item]) => {
            const formattedKey = snakeCase(key).replace('attributes_', 'attributes/');
            if (Array.isArray(item)) {
              acc[formattedKey] = item;
            } else {
              acc[formattedKey] = item;
            }
            return acc;
          }, {});
        v2Payload['attributes'] = previousAttributes;
      }

      let attributesCopy = {};
      updatePayload?.forEach(item => {
        if (item.field_type == 'MEDIA') {
          v2Payload['media_' + (item.field_name == 'ADDITIONAL' ? 'items' : item.field_name.toLowerCase())] = item.value;
        } else if (item.field_type == 'ATTRIBUTE') {
          if (!v2Payload['attributes']) v2Payload['attributes'] = {};
          if (item.value) {
            v2Payload['attributes'][item.field_name] = item.value;
          } else {
            v2Payload['attributes'] = Object.fromEntries(
              Object.entries(v2Payload['attributes']).filter(attribute => attribute[0]!=item.field_name));
          }
        } else if (item.field_type == 'SOCIAL_PROFILES') {
          if (!attributesCopy) attributesCopy = {};
          attributesCopy[item.field_name] = item.value;
          v2Payload['attributes'] = attributesCopy;
        } else {
          v2Payload[item.field_name] = item.value;
        }
      });
      const data = {
        data: {
          type: 'locations',
          id: selectedLocation,
          attributes: v2Payload,
        },
      };
      await OTTO_V2_API.updateOptimizationsV2(selectedLocation, data);
      await getData();
      if (isInGbpProjectOverview) {
        await getOptimizationData(selectedIds, true);
      } else {
        await getLocationData(selectedLocation);
      }
      setIsModalOpen(false);
      setOpenSocialMediaUrlModal(false);
      setOpenPhoneNumberModal({visible: false, type: null});
      setDescriptionModalVisible(false);
      setEmbedModalVisible(false);
      notification.success('Update Successful', 'The field has been updated');
      handleNewChangesInGbp(!newChanges);
    } catch (error) {
      setLoader(null);
      const response = error?.response?.data?.errors;
      if (response) {
        const messagesArray = response?.map(item => item?.detail);
        if (messagesArray?.length) {
          messagesArray.forEach(item => {
            notification.error('Update Failed', item);
          });
        }
      } else {
        notification.error('Update Failed', 'Failed to Update the Current Field');
      }
      return null;
    } finally {
      setModalLoader(false);
    }
  };

  const getTooltipData = () => {
    const tooltipData = pendingFields.map(item => {
      const data = {
        name: item.name,
        tasks: item.pendingTasks?.map(task => humanize(task.replace('attributes/', '')).replace('Url', 'URL').replace('Uri', 'URL')),
      };
      return data;
    }).filter(item => item.tasks?.length);
    return <TooltipContainer>{tooltipData?.map(item => (<div key={item.name}>
      <div>{item.name}</div>
      <ul style={{paddingLeft: 30}}>
        {item.tasks.map(task => (<li key={task}>{task}</li>))}
      </ul>
    </div>))}</TooltipContainer>;
  };

  const updateTaskStatus = async task => {
    setLoader(task.id);
    setAction('ignore');
    try {
      const data = {
        data: {
          type: 'location_tasks',
          id: task?.id,
          attributes: {
            is_deleted: !task?.attributes?.isDeleted,
          },
        },
      };
      await OTTO_V2_API.updateMyTaskV2(task?.id, data, isInGBPStandAlone);
      await getData();
      if (isInGbpProjectOverview) {
        await getOptimizationData(selectedIds, true);
      }
      notification.success(`Task ${task?.attributes?.isDeleted ? 'Restored' : 'Ignored'} Successfully`, `The task has been ${task?.attributes?.isDeleted ? 'restored' : 'ignored'} successfully.`);
    } catch (error) {
      setLoader(null);
      const response = error?.response?.data?.errors;
      if (response) {
        const messagesArray = response?.map(item => item?.detail);
        if (messagesArray?.length) {
          messagesArray.forEach(item => {
            notification.error('Update Failed', item);
          });
        }
      } else {
        notification.error('Something went wrong!', `Failed to ${task?.attributes?.isDeleted ? 'restore' : 'ignore'} task.`);
      }
      return null;
    } finally {
      setLoader(null);
      setAction(null);
    }
  };

  const refreshTasks = async () => {
    setRefreshLoader(true);
    const payload = {
      data: isInGbpProjectOverview ? selectedBusiness?.map(item => ({type: 'locations', id: item.id})) :
        myTasksLocations?.map(item => ({type: 'locations', id: item.id}))};
    const response = await OTTO_V2_API.bulkRefreshTasks(payload, isInGBPStandAlone);
    localStorage.setItem('bulkRefreshTaskId', response.data.id);
    getRepollingStatus(response.data.id, 'refresh');
  };

  const getRepollingStatus = async (id, type) => {
    try {
      const response = await OTTO_V2_API.getTaskResult(id);
      if (response?.status == 'PENDING') {
        setTimeout(() => {
          getRepollingStatus(id, type);
        }, 5000);
      } else {
        setTimeout(async () => {
          if (response.status == 'SUCCESS') {
            notification.success(repollingMapper[type].successMessageHeading, repollingMapper[type].successMessageDescription);
          } else {
            notification.error(repollingMapper[type].errorMessageHeading, response?.errorMessage);
          }
          localStorage.removeItem(repollingMapper[type].localStorageKey);
          getData();
          if (isInGbpProjectOverview) await getOptimizationData(selectedIds, true);
          if (type == 'deploy') {
            setIsDeploying(false);
          } else if (type == 'refresh') {
            setRefreshLoader(false);
          } else if (type == 'bulkApprove') {
            setBulkApproveLoader(false);
          }
        }, 5000);
      }
    } catch (e) {
      if (e?.response?.status == 520) {
        setTimeout(() => {
          getRepollingStatus(id, type);
        }, 5000);
      } else {
        if (type == 'deploy') {
          setIsDeploying(false);
        } else if (type == 'refresh') {
          setRefreshLoader(false);
        } else if (type == 'bulkApprove') {
          setBulkApproveLoader(false);
        }
        const message = apiError(e);
        notification.error('', message);
      }
    }
  };

  const repollingMapper = {
    deploy: {
      successMessageHeading: 'Deployment Successful',
      successMessageDescription: 'Deployment was successful',
      errorMessageHeading: 'Deployment Failed',
      localStorageKey: 'deployStatusTaskId',
    },
    refresh: {
      successMessageHeading: 'Refresh Successful',
      successMessageDescription: 'Successfully refreshed the tasks.',
      errorMessageHeading: 'Refresh Failed',
      localStorageKey: 'bulkRefreshTaskId',
    },
    bulkApprove: {
      successMessageHeading: 'Approved Successfully',
      successMessageDescription: 'Bulk Approved Tasks Successful',
      errorMessageHeading: 'Failed to Approved Tasks',
      localStorageKey: 'bulkApproveId',
    },
  };

  useEffect(() => {
    const refreshId = localStorage.getItem('bulkRefreshTaskId');
    const deployId = localStorage.getItem('deployStatusTaskId');
    const approveId = localStorage.getItem('bulkApproveId');
    if (refreshId) {
      getRepollingStatus(refreshId, 'refresh');
      setRefreshLoader(true);
    }
    if (deployId && deployId !== 'null') {
      getRepollingStatus(deployId, 'deploy');
      setIsDeploying(true);
    }
    if (approveId) {
      getRepollingStatus(approveId, 'bulkApprove');
      setBulkApproveLoader(true);
    }
  }, []);

  const bulkDeploy = async () => {
    try {
      const Ids = pendingFields?.filter(item => item.pendingTasks?.length)?.map(item => item.id);
      if (Ids?.length) {
        setIsDeploying(true);
        const data = [];
        Ids?.map(id => data?.push({
          'type': 'locations',
          'id': id,
        }));
        const payload = {
          'data': data,
          'meta': {
            'mode': 'PARTIAL',
          },
        };
        const response = await OTTO_V2_API.bulkDeployOptimizationsV2(payload, isInGBPStandAlone);
        notification.success('Deployment Triggered', 'It will take few minutes to deploy your changes');
        localStorage.setItem('deployStatusTaskId', response.data.id);
        await getRepollingStatus(response.data.id, 'deploy');
      } else {
        notification.info('No Pending Fields', 'No Pending Data found for deployment');
      }
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const transformedData = {};
  selectedValue?.value && Object.keys(selectedValue.value)?.forEach(key => {
    const value = selectedValue?.value[key];
    const formattedKey = `attributes/${key.slice(10).replace(/([A-Z])/g, '_$1').toLowerCase().replace(/^_/, '')}`;
    transformedData[formattedKey] = value;
  });

  const approveBulkTasks = async type => {
    try {
      setBulkApproveLoader(true);
      const payload = {
        data: {
          type: 'location_task_bulk_approve',
          attributes: {
            location_ids: selectedBusiness?.map(item => item.id),
            entity_type: type,
          },
        },
      };
      const response = await OTTO_V2_API.bulkApproveTasks(payload, (isInGBPStandAlone || isInGbpProjectOverview));
      const taskId = response?.data?.id;
      localStorage.setItem('bulkApproveId', response.data.id);
      getRepollingStatus(taskId, 'bulkApprove');
    } catch (e) {
      setBulkApproveLoader(false);
      notification.error('', apiError(e));
    }
  };

  const menu = (
    <Menu>
      <Menu.Item key='1' disabled={!tasksResponse?.meta?.postsCount} onClick={() => approveBulkTasks('posts')}>
        <Tooltip placement='right' title='All posts will be approved, and scheduled to publish on their designated dates'>
          Approve all AI Generated Posts
        </Tooltip>
      </Menu.Item>
      <Divider style={{margin: 0}}/>
      <Menu.Item key='2' disabled={!tasksResponse?.meta?.reviewsCount} onClick={() => approveBulkTasks('review_reply')}>
        <Tooltip placement='right' title='All replies will be approved, and scheduled to publish on their designated dates'>
          Approve all AI Generated Replies
        </Tooltip>
      </Menu.Item>
      <Divider style={{margin: 0}}/>
      <Menu.Item key='3' disabled={!tasksResponse?.meta?.questionsCount} onClick={() => approveBulkTasks('question_answers')}>
        <Tooltip placement='right' title='All Q&As will be approved, and scheduled to publish on their designated dates'>
          Approve all AI Generated Q&As
        </Tooltip>
      </Menu.Item>
    </Menu>
  );


  return (
    <>
      <div>
        <div style={{display: 'flex', justifyContent: isInGBPStandAlone ? 'space-between' : 'flex-end', alignItems: 'center', margin: isInGBPStandAlone ? '10px 0' : '0 0 10px 0'}}>
          {isInGBPStandAlone && <Tooltip title={!gbpLocations?.length ? 'No businesses available' : ''}><Dropdown disabled={loader == 'loader' || gbpLocations?.length == 0} trigger={['click']} overlay={myTasksLocationList} open={showGbpDropdown} onOpenChange={e => setShowGbpDropdown(e)}>
            <DropdownWrapper locationsLength={gbpLocations?.length} style={showBusinessDropdown ? {marginBottom: '10px'} : {}}>
              <FontAwesomeIcon icon={faLocationDot} fontSize={14}/>
                  Business: &nbsp;{activeArray?.length ? activeArray?.length == 1 ? truncate(activeArray?.[0]?.businessName, 30) : (+ activeArray?.length + ' selected'): 'Select'}
              <FontAwesomeIcon icon={faCaretDown} fontSize={14}/>
            </DropdownWrapper>
          </Dropdown></Tooltip>}
          {isInGbpProjectOverview &&
            <div style={{display: 'flex', width: '100%', justifyContent: 'space-between', marginRight: 31, alignItems: 'center'}}>
              <div>
                <div style={{fontWeight: 500, fontSize: 16, color: '#FFFFFF'}}>Business Assistant</div>
                <div style={{color: '#E8E8E8', fontSize: 12}}>You have {count} pending tasks</div>
              </div>
            </div>
          }
          <div style={{display: 'flex', alignItems: 'center', gap: 15}}>
            <FreezeWrapper>
              <Tooltip title={!((isInGbpProjectOverview && selectedBusiness?.length) || myTasksLocations?.length) ? 'No business selected' : !filterValue ? 'Show Ignored Tasks' : 'Show Active Tasks'}>
                <div>
                  <FontAwesomeIcon icon={!filterValue ? faEyeSlash : faEye} onClick={() => !refreshLoader && setFilterValue(prev => !prev)}
                    style={{color: `${isInGbpProjectOverview ? '#fff' : '#000'}`, cursor: refreshLoader ? 'not-allowed' : 'pointer', pointerEvents: `${!((isInGbpProjectOverview && selectedBusiness?.length) || myTasksLocations?.length) ? 'none' : 'auto'}`}}/>
                </div>
              </Tooltip>
              {!isInGbpProjectOverview && <><Tooltip title={!((isInGbpProjectOverview && selectedBusiness?.length) || myTasksLocations?.length) ? 'No business selected' : !isDeploying ? (pendingFieldsCount ? getTooltipData() : 'No Pending Tasks found for deployment') : 'Tasks are recently deployed. Wait for few minutes for next deployment'}>
                <ButtonStyled style={{cursor: (isDeploying || !pendingFieldsCount) ? 'not-allowed' : 'pointer'}}
                  onClick={() => ((!isDeploying && pendingFieldsCount) && bulkDeploy())} loading={isDeploying}>
                  <FontAwesomeIcon icon={faCodePullRequest} color={'#fff'} style={{marginRight: 10}}/>{'Deploy all changes ' + `${pendingFieldsCount ? '(' + pendingFieldsCount + ')' : ''}`}
                </ButtonStyled>
              </Tooltip></>}
              <Tooltip title={!((isInGbpProjectOverview && selectedBusiness?.length) || myTasksLocations?.length) ? 'No business selected' : ''}>
                {<div style={{color: isInGbpProjectOverview ? 'white' : '#2D6CCA', width: isInGbpProjectOverview && 113, cursor: (loader || isDeploying || refreshLoader ||bulkApproveLoader || !((isInGbpProjectOverview && selectedBusiness?.length) || myTasksLocations?.length)) ?
                  'not-allowed' : 'pointer', display: 'flex', alignItems: 'center', gap: '5px'}} onClick={() => !loader && !refreshLoader && !isDeploying &&!bulkApproveLoader&& ((isInGbpProjectOverview && selectedBusiness?.length) || myTasksLocations?.length) && refreshTasks()}>
                  <FontAwesomeIcon icon={faSync} spin={refreshLoader}/> Refresh {isInGbpProjectOverview && ' Tasks'}
                </div>}
              </Tooltip>
              {isInGbpProjectOverview && <Dropdown overlay={menu} trigger={['click']} disabled={isDeploying || refreshLoader || bulkApproveLoader || !tableData?.length} dropdownRender={menu => (
                <StyledDropdown>
                  {menu}
                </StyledDropdown>
              )}>
                <div
                  style={{
                    background: '#4E5156B2',
                    height: 28,
                    borderRadius: 6,
                    color: '#FFFFFF',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '10px 7px',
                    cursor: (isDeploying || refreshLoader || bulkApproveLoader || !tableData?.length) ? 'not-allowed' : 'pointer',
                    width: 'max-content',
                  }}
                >
                  {bulkApproveLoader && <FontAwesomeIcon style={{marginRight: 3}} icon={faSpinnerThird} spin />}
                Bulk actions <FontAwesomeIcon style={{marginLeft: 8}} icon={faCaretDown} />
                </div>
              </Dropdown>}
            </FreezeWrapper>
          </div>
        </div>
        <div style={{backgroundColor: '#FFFFFF', borderRadius: (isInGbpProjectOverview && pageNumber) ? '12px 12px 0 0' : 12, maxHeight: maxHeight, minHeight: tableHeight, height: isInGbpProjectOverview && maxHeight}}>
          <TableContainer isInGbpProjectOverview={isInGbpProjectOverview} maxHeight={maxHeight} height={tableHeight}>
            {(loader == 'loader' || statsLoader) ? <FontAwesomeIcon icon={faSpinnerThird} style={{marginLeft: '48%', marginTop: isInGbpProjectOverview ? '20%':'20px'}} color='#2d6cca'spin fontSize={54} className='initial-loader'/> :
              tableData?.length ? tableData?.map((task, index) => {
                return <div style={{display: 'flex', padding: '8px 14px', backgroundColor: hoveredRowIndex==index ? '#2D6CCA1F' : '', alignItems: ['LOCATION', 'HEATMAP', 'WEBSITE'].includes(task.attributes.taskType) && 'center'}} key={index} onMouseEnter={() => setHoveredRowIndex(index)}
                  onMouseLeave={() => setHoveredRowIndex(null)}>
                  <div style={{height: 32, borderRadius: 8, backgroundColor: '#FFFFFF', border: '1px solid #E8E8E8', boxShadow: '1px 2px 8px 0px #0000001C', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: 12, minWidth: '32px', width: '32px'}}>
                    {iconsMap[['POST', 'REVIEW', 'QUESTION']?.includes(task?.attributes?.taskType) ? task.attributes?.taskType : task?.attributes?.fieldType]?.icon}
                  </div>
                  { !isInGbpProjectOverview && gbpLocations?.length > 0 &&
                    <div style={{marginRight: 30, width: '75%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                      <Tooltip title={
                        pendingFields?.find(location => location?.id == task?.relationships?.location?.data?.id)?.name
                      }>
                        <div>
                          {
                            pendingFields?.find(location => location?.id == task?.relationships?.location?.data?.id)?.name
                          }
                        </div>

                      </Tooltip>
                      <Tooltip title={
                        pendingFields?.find(location => location?.id == task?.relationships?.location?.data?.id)?.businessAddress
                      }>

                        <div style={{fontSize: 12, color: '#666', marginTop: 4}}>
                          {
                            pendingFields?.find(location => location?.id == task?.relationships?.location?.data?.id)?.businessAddress
                          }
                        </div>
                      </Tooltip>


                    </div>
                  }
                  <div style={{marginRight: 70, width: '75%'}}>
                    {task?.data ? <>
                      {task.attributes.taskType == 'POST' && <><div style={{color: '#121212'}}>{iconsMap?.POST?.title}</div><PreviewContainer>
                        <div className='image-container'>
                          {task?.data?.medias?.length ?
                            <> {
                              task?.data?.medias[0]?.format == 'VIDEO' ?
                                <video style={{width: '-webkit-fill-available', height: 'auto'}}>
                                  <source src={task?.relationships?.location?.data?.id?.medias[0]?.url} />
                                </video> :
                                <img referrerPolicy='no-referrer' key={task?.data?.medias[0]?.url} src={task?.data?.medias[0]?.url} className='image' style={{objectFit: 'cover', width: '100%', height: '100%', borderRadius: '8px'}}/>

                            }
                            </> :
                            <Tooltip title={'Image not available'}>
                              <img src='/img/icon/broken-image.svg' className='image' style={{width: 50, objectFit: 'cover'}}/>
                            </Tooltip>}
                        </div>
                        <div className='post'>
                          {(task?.data?.topicType !== 'STANDARD' && task?.data?.title) && <div className='content-title'>{task?.data?.title}</div>}
                          {task?.data?.content}
                          {((task?.data?.callToActionType === 'CALL') || (task?.data?.callToActionType !== 'CALL' && task?.data?.callToActionUrl)) && (
                            <Tooltip
                              title={task?.data?.callToActionType == 'CALL' && (gbpLocations?.find(location => location?.id == task?.data?.location)?.phoneNumbers?.length ? isCopied ? <span><FontAwesomeIcon icon={faCheck} style={{color: '#2AC155'}}/>{` Phone number copied`}</span> : 'Copy phone number' : 'No phone number available')}
                              placement='bottom'
                              overlayInnerStyle={{backgroundColor: 'black'}}
                              onOpenChange={visible => {
                                if (!visible) {
                                  setTimeout(() => {
                                    setIsCopied(false);
                                  }, 300);
                                }
                              }}><div className='learn-more' style={{width: 'fit-content'}}>
                                <a
                                  style={task?.data?.callToActionType === 'CALL' && !gbpLocations?.find(location => location?.id == task?.data?.location)?.phoneNumbers?.length ? {cursor: 'not-allowed'} : {}}
                                  href={task?.data?.callToActionType !== 'CALL' && task?.data?.callToActionUrl}
                                  onClick={() => {
                                    if (task?.data?.callToActionType === 'CALL') {
                                      navigator.clipboard.writeText(gbpLocations?.find(location => location?.id == task?.data?.location)?.phoneNumbers[0]);
                                      setIsCopied(true);
                                    }
                                  }}
                                  target={task?.data?.callToActionType === 'CALL' ? '_self' : '_blank'}
                                  rel='noreferrer'
                                >
                                  {buttonOptions?.find(item => item.value === task?.data?.callToActionType)?.label}
                                </a>
                              </div></Tooltip>
                          )}
                        </div>
                      </PreviewContainer></>}
                      {task.attributes.taskType == 'QUESTION' && <><div style={{color: '#121212'}}>{iconsMap?.QUESTION?.title}</div><PreviewContainer style={{flexDirection: 'column'}}>
                        <div className='question'>
                          <div className='label'>Question:&nbsp;</div>
                          <div className='question-content'>
                            {task?.data?.content}
                          </div>
                        </div>
                        <div className='qa-answer'>
                          <div className='label'>Answer:&nbsp;&nbsp;&nbsp;</div>
                          <div className='answer-content'>
                            {task?.data?.ownersAnswer?.content}
                          </div>
                        </div>
                      </PreviewContainer></>}
                      {task.attributes.taskType == 'REVIEW' && <><div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}><div style={{color: '#121212'}}>{iconsMap?.REVIEW?.title + task?.data?.reviewerName}
                        <Rate style={{fontSize: 12, marginRight: 'unset', marginLeft: 10, marginBottom: 3}} disabled value={task?.data?.starRating} /></div></div><PreviewContainer>
                        <div>
                          <div className='review' style={{fontSize: 12, color: '#121212'}}>Review: <span style={{color: '#4E5156'}}>{task?.data?.content}</span></div>
                          <div className='answer' style={{fontSize: 12, color: '#121212'}}>Reply: <span style={{color: '#4E5156'}}>{task?.data?.reply?.content}</span></div>
                        </div>
                      </PreviewContainer></>}
                    </> : task.attributes.taskType == 'WEBSITE' ? <div>
                      {iconsMap[task.attributes.issueType]?.title} {!iconsMap[task.attributes.issueType]?.buttonText && optimizationData?.data?.attributes?.websiteUri &&
                      <span style={{cursor: 'pointer', color: '#2D6CCA'}} onClick={() => window.open(optimizationData?.data?.attributes?.websiteUri)}>{optimizationData?.data?.attributes?.websiteUri?.replace(/^https?:\/\//, '').replace(/\/$/, '')}</span>}
                    </div> : <div>
                      {iconsMap[task.attributes.fieldType]?.title}
                    </div>}
                  </div>


                  <div style={{display: 'flex', alignItems: 'center', gap: 5, justifyContent: iconsMap[task.attributes.fieldType]?.notIntegrated ? 'flex-end' : 'space-between', width: '15%', minWidth: '150px'}}>
                    {!(task?.attributes?.taskType=='WEBSITE' && !iconsMap[task.attributes.issueType]?.buttonText) && !iconsMap[task.attributes.fieldType]?.notIntegrated &&
                    <Tooltip title={task?.attributes?.isDeleted ? 'This task is ignored.' : ''}>
                      <div
                        style={{
                          cursor: task?.attributes?.isDeleted ? 'not-allowed' : 'pointer',
                        }}
                      >
                        <div
                          onClick={() => (!refreshLoader && loader !== task.id) && openModal(task, task?.attributes?.taskType)}
                          onMouseEnter={() => setHoveredButtonId(task.id)}
                          onMouseLeave={() => setHoveredButtonId(null)}
                          style={{
                            background: hoveredButtonId === task.id ? '#2D6CCA' : '#2D6CCA26',
                            width: 'fit-content',
                            padding: '7px 12px',
                            color: hoveredButtonId === task.id ? '#FFFFFF' : '#2D6CCA',
                            borderRadius: 8,
                            cursor: (refreshLoader || loader === task.id) ? 'not-allowed' : 'pointer',
                            pointerEvents: task?.attributes?.isDeleted ? 'none' : 'auto',
                            opacity: task?.attributes?.isDeleted ? 0.5 : 1,
                            transition: 'background-color 0.3s, color 0.3s',
                          }}
                        >
                          {action !== 'ignore' && (
                            <FontAwesomeIcon
                              style={{
                                marginRight: 2,
                                display: loader !== task.id ? 'none' : 'inline-block',
                              }}
                              spin={loader === task.id}
                              icon={faCircleNotch}
                            />
                          )}
                          {
                            ['POST', 'REVIEW', 'QUESTION'].includes(task?.attributes?.taskType) ?
                              iconsMap[task.attributes.taskType]?.buttonText :
                              task?.attributes?.fieldType === '_embed_map' ?
                                iconsMap['_embed_map']?.buttonText :
                                task?.attributes?.fieldType=='_id' ?
                                  iconsMap['_id']?.buttonText :
                                  (checkPendingStatus(task) ? 'Update ' : 'Add ') + (iconsMap[task.attributes.fieldType]?.buttonText || '')
                          }
                        </div>
                      </div>
                    </Tooltip>
                    }
                    <div style={{marginLeft: 'auto'}}>
                      <Tooltip title={`${task?.attributes?.isDeleted ? 'Restore Task' : 'Ignore Task'}`}>
                        <div
                          onClick={() => {
                            !refreshLoader && updateTaskStatus(task);
                          }}
                          style={{marginRight: 8}}
                        >
                          <FontAwesomeIcon
                            style={{marginRight: 2, color: '#A3A4A4', cursor: `${(refreshLoader || loader === task.id) ? 'not-allowed' : 'pointer'}`}}
                            spin={loader === task.id && action === 'ignore'}
                            icon={(loader === task.id && action === 'ignore') ? faCircleNotch : task?.attributes?.isDeleted ? faUndo : faEyeSlash}
                          />
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                </div>;
              }) : <NoDataDiv style={{minHeight: tableHeight ? tableHeight : 'auto', height: isInGbpProjectOverview && 250, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>No Pending Tasks Available</NoDataDiv> }
          </TableContainer>
        </div>
      </div>
      { loader === 'data' ?
        <div style={{textAlign: 'center', marginTop: 12, cursor: 'not-allowed', color: '#2d6cca', display: 'flex', justifyContent: 'center', gap: '10px'}}><StyledSpin fontSize={16} color='#2d6cca'/> Loading...</div> :
        loader === 'loader' ? null : pageNumber && <div onClick={() => getData(true)}
          style={{textAlign: 'center', paddingTop: 12, paddingBottom: '3px', cursor: 'pointer', color: '#2d6cca', borderRadius: isInGbpProjectOverview && '0 0 12px 12px', background: isInGbpProjectOverview && 'white', borderTop: isInGbpProjectOverview && '1px solid #cdcdcd'}}>Load More</div>
      }


      {isModalOpen && modalType !== 'LOCATION' && modalType !== 'WEBSITE' && <ModalStyled
        width={620}
        visible={isModalOpen}
        closable={true}
        onClose={() => {
          setIsModalOpen(false);
          setModalData(null);
          setModalType(null);
        }}
        closeIcon={faXmark && <FontAwesomeIcon icon={faXmark} />}
        mask={true}
        title={selectedTitle}
      >
        {modalType != 'edit' && <ApproveModal refreshTasks={refreshTasks} modalType={modalType} modalData={modalData} setIsModalOpen={setIsModalOpen} setRefresh={setRefresh} edit={edit} setEdit={setEdit} phoneNumbers={phoneNumbers} isInGBPStandAlone={isInGBPStandAlone} isV2={isInGbpProjectOverview}/>}
        {modalType == 'edit' && <EditPostModal refreshTasks={refreshTasks} postData={modalData} setPostData={setModalData} setRefresh={setRefresh} setIsModalOpen={setIsModalOpen} setOpenGbpLibraryModal={setOpenGbpLibraryModal} openModal={openModal} phoneNumbers={phoneNumbers} isInGBPStandAlone={isInGBPStandAlone} />}
      </ModalStyled>}
      {openGbpLibraryModal && <GBPMediaLibraryModal
        openGbpLibraryModal={openGbpLibraryModal}
        setOpenGbpLibraryModal={setOpenGbpLibraryModal}
        setOpenGBPAutomationModal={setIsModalOpen}
        isLocalSEOMyTask={true}
        selectImageFromMediaLibrary={selectImageFromMediaLibrary}
        modalData={modalData}
        isInGBPStandAlone={isInGBPStandAlone}
      />
      }
      {
        (modalType == 'LOCATION' || modalType == 'WEBSITE') && <ModalStyled2
          key={key}
          width={selectedField == 'open_hours' ? 800 : selectedField == 'images' ? 1280 : selectedField == 'services' ? 680 : ['url_appointment', 'business_name', 'website_uri']?.includes(selectedField) ? 460 : selectedField === 'opening_date' ? 460 : selectedField === 'addressData' ? 900 : 560}
          closable={true}
          closeIcon={faXmark && <FontAwesomeIcon icon={faXmark} />}
          mask={true}
          headerPaddings={selectedField === 'opening_date' ? '30px 30px 0' : selectedField === 'services' ? '30px 28px 0' : ['url_appointment', 'business_name', 'website_uri']?.includes(selectedField) ? '30px 30px 0' : ''}
          bodyPaddings={selectedField === 'opening_date' ? '5px 30px 30px' : selectedField === 'services' ? '0' : ['url_appointment', 'business_name', 'website_uri']?.includes(selectedField) ? '5px 30px 30px' : ''}
          title={selectedField == 'categories' ? 'Business Category' : selectedField == 'images' ? 'Media' : selectedField == 'addressData' ?
            <TitleContainer>
              <div className='title-txt'>Business location</div>
              <div className='desc-txt'>If customers visit your business, add an address and adjust the pin on the map to its location.</div>
            </TitleContainer> : (selectedField == 'website_uri' ? 'Website' : selectedField == 'url_appointment' ? 'Booking URL' : humanize(selectedField).replace('Url', 'URL'))}
          visible={isModalOpen} footer={false} onClose={() => setIsModalOpen(false)} style={{height: 100}}>
          {isModalOpen && <ModalForms
            updateData={payload => updateData(payload)}
            attributes={null}
            selectedAttributes={transformedData}
            services={services}
            BlackList={BlackList}
            selectedField={selectedField}
            categories={categories}
            setIsModalOpen={setIsModalOpen}
            modalSuggestion={selectedField === 'services' ? modalSuggestion : null}
            selectedValue={selectedValue}
            setModalLoader={setModalLoader}
            modalLoader={modalLoader}
            incidents={null}
            primaryCategory={primaryCategory}
            setCategories={setCategories}
            setServices={setServices}
            selectedLocation={selectedLocation}
            selectedLocationCountryCode={selectedLocationCountryCode}
            showImageType={showImageType}
            isInLocalSeo={isInGBPStandAlone}
            subGroup={'my_tasks'}
            servicesList={(isInGbpProjectOverview ? optimizationData : gbpData?.find(data => data?.data?.id == selectedLocation))?.data?.attributes?.services}
            selectedCategories={selectedCategories}
          />}
        </ModalStyled2>
      }
      {openPhoneNumberModal?.visible && <AddPhoneNumberModal
        modalConfig={openPhoneNumberModal}
        setModalConfig={setOpenPhoneNumberModal}
        updateData={updateData}
        setModalLoader={setModalLoader}
        selectedValue={selectedValue}
        isInLocalSeo={isInGBPStandAlone}
        categories={categories}
        setCategories={setCategories}
        selectedLocation={selectedLocation}
        modalLoader={modalLoader}
        modalSuggestion={modalSuggestion}
      />}
      {openSocialMediaUrlModal && <SocialMediaUrlsModal
        visible={openSocialMediaUrlModal}
        setVisible={setOpenSocialMediaUrlModal}
        selectedValue={selectedValue}
        modalLoader={modalLoader}
        updateData={updateData}
        setModalLoader={setModalLoader}
        currentData={selectedData?.['data']?.attributes?.attributes}
      />}
      {descriptionModalVisible && (
        <BusinessDescriptionModal
          visible={descriptionModalVisible}
          setVisible={setDescriptionModalVisible}
          descriptionValue={selectedValue}
          onUpdate={payload => updateData(payload)}
          modalLoader={modalLoader}
          setModalLoader={setModalLoader}
          suggestionObj={gbpData?.find(data => data?.data?.id == selectedLocation)?.meta?.suggestions?.find(suggestion => suggestion?.fieldName === 'business_description')}
        />
      )}
      {embedModalvisible && (
        <EmbedMapModal
          visible={embedModalvisible}
          setVisible={setEmbedModalVisible}
          descriptionValue={selectedValue}
          setModalLoader={setModalLoader}
          modalLoader={modalLoader}
        />

      )}
    </>
  );
});

export const StyledDropdown= styled.div`
  .ant-dropdown-menu {
    border-radius: 8px;
  }
`;


export const ButtonStyled = styled(Button)`
  background: #2ac155;
  color: white;
  border: unset;
  box-shadow: unset;
  border-radius: 5px;
  height: 40px;
  &:hover{
    background: #2ac155;
    color: white;
    border: unset;
    box-shadow: unset;
  }
  &:focus{
    background: #2ac155;
    color: white;
    border: unset;
    box-shadow: unset;
  }
  &:active{
    background: #2ac155;
    color: white;
    border: unset;
    box-shadow: unset;
  }

`;

const ModalStyled = styled(Modal)`
  .rc-dialog-content {
    padding: 10px;
  }
  .rc-dialog-title {
    font-size: 24px !important;
    font-weight: 600 !important;
  }
  .rc-dialog-wrap {
    align-items: center !important;
  }
  .ant-divider-horizontal {
    margin: 0 !important;
  }
  .rc-dialog-body {
    padding: 20px 30px 20px 30px !important;
    border-radius: 12px !important;
  }
  .rc-dialog-close {
    font-size: 28px !important;
    color: white !important;
    left: 102% !important;
    top: -3px !important;
    opacity: 1 !important;
  }
  .ant-btn-submit {
    background-color:  #2D6CCA !important;
    color: white;
    padding: 13px 25px 14px 25px;
    border-radius: 8px;
    height: 44px;
    border: none;
    margin-top: 15px;
    &:hover {
      background: linear-gradient(0deg,rgba(0,0,0,0.18),rgba(0,0,0,0.18)),#2D6CCA !important;
      background-color:  '#7f4ead2E';
      border: 1px solid transparent !important;
      color: #fff;
    }
  }

  &::-webkit-scrollbar-thumb {
    background: #4E515680;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #F9F9FB;
    border-radius: 10px;
  }

  .rc-dialog-header {
  background: var(--Table-row-stripe, #F9F9FB);
  padding: 19px 19px 2px 19px;
  }
  .rc-dialog-content {
  background: var(--Table-row-stripe, #F9F9FB);
  }
`;
const DropdownWrapper = styled.div<{locationsLength?:any}>`
  padding: 0 8px;
  font-size: 14px;
  border: 1px solid #E8E8E8;
  border-radius: 8px;
  color: #4E5156;
  display: flex;
  align-items: center;  
  gap: 9px;
  cursor: ${p => p.locationsLength ? 'pointer' : 'not-allowed'};
  background: #fff;
  width: fit-content;
  max-width: 500px;
  height: 40px;
  // margin-top: 10px;
`;
const DropdownContainer = styled.div`
  width: 400px;
  border-radius: 12px;
  background: white;
  padding: 14px 12px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;
const GbpSearchInput = styled(Input)`
  width: 380px;
  height: 32px;
  margin-top: 9px;
  margin-bottom: 10px;
  border-radius: 8px;
  border: 1px solid #CDCDCD;
`;
const DropdownListContainer = styled.div`
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #4E515680;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #F9F9FB;
    border-radius: 10px;
  }
  .ant-checkbox-wrapper {
    margin-left: unset;
  }
`;

const PreviewContainer = styled.div`
  display: flex;
  width: 100%;
  .image-container {
    margin-right: 10px;
    border-radius: 8px;
    width: 60px;
    height: 40px;
  }

  .question,
  .qa-answer {
    display: flex;
    font-size: 12px;

    .label {
      color: #121212;
      margin-right: 5px;
    }

    .question-content,
    .answer-content {
      color: #4e5156;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

    .review,
    .answer {
      color: #4e5156;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }

  .post {
    width: 100%;
    font-size: 12px;
    color: #4e5156;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }

  .learn-more > a {
    cursor: pointer;
    font-style: italic;
    color: #2d6cca;
  }
`;

const TableContainer = styled.div<{maxHeight?:any; height?:any; isInGbpProjectOverview?:boolean}>`
  overflow-y: ${p => (p.maxHeight ? 'auto' : 'visible')};
  overflow-x: hidden;
  max-height: ${p => (p?.maxHeight ? `calc(${p.maxHeight} - 15px)` : 'auto')};
  min-height: ${p => p?.height};
  height: ${p => p?.isInGbpProjectOverview && p.height};
  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: lightgray;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
 
  &::-webkit-scrollbar-thumb {
    background: #888; 
  }

  &::-webkit-scrollbar-thumb:hover {
    border-radius: 10px;
    background: #7a7a7a; 
  }
`;

export const TooltipContainer = styled.div`
  max-height: 500px;
  overflow-y: auto;
   &::-webkit-scrollbar {
    width: 4px;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: lightgray;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
 
  &::-webkit-scrollbar-thumb {
    background: #888; 
  }

  &::-webkit-scrollbar-thumb:hover {
    border-radius: 10px;
    background: #7a7a7a; 
  }
`;

const TitleContainer = styled.div`
  .title-txt {
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 29.05px;
    text-align: left;
    color: #121212;
  }
  .desc-txt {
    margin-top: 8px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #4E5156;
  }
`;
