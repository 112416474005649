import React, {useEffect, useState} from 'react';
import {faArrowLeft, faCircleCheck} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styled, {keyframes} from 'styled-components';
import {observer} from 'mobx-react-lite';
import {Collapse, Popover, Tooltip, Row, Col} from 'antd';
import moment from 'moment';
import {NextImg} from '@/utils/nextImg';
import {useStore} from '@/store/root-store';
import {notification as notificationV2} from '@/utils/notification-v2';
import {faRotate} from '@fortawesome/pro-solid-svg-icons';

interface Props {
  setIsGmbDetail?: (value) => void;
  gmbDetailData?: any;
  onDeleteAccount?: (email) => void;
  setShowConnectAccountHeader?: React.Dispatch<React.SetStateAction<boolean>>;
}
export const GmbSettingsData: React.FC<Props> = observer(
  ({
    setIsGmbDetail,
    gmbDetailData,
    onDeleteAccount,
    setShowConnectAccountHeader,
  }) => {
    const {
      gsc: {
        googleAnalytics: {
          refreshGBPAccounts,
        },
      },
    } = useStore('');
    const {Panel} = Collapse;
    const [popover, setPopover] = useState([]);
    const [emailIndex, setEmailIndex] = useState(-1);

    useEffect(() => {
      gmbDetailData?.length &&
        setPopover(
          gmbDetailData.map(() => {
            return {
              des: false,
              category: false,
              time: false,
              website: false,
              location: false,
            };
          }),
        );
    }, [gmbDetailData]);

    const HandleGoBack = () => {
      setIsGmbDetail(false);
      setShowConnectAccountHeader && setShowConnectAccountHeader(true);
    };

    const onRefreshHandler = async accountId => {
      if (accountId) {
        try {
          await refreshGBPAccounts(accountId);
          notificationV2.success('Account has been refreshed.', '');
        } catch (error) {
          notificationV2.error('Something went wrong.', '');
        }
        setEmailIndex(-1);
      }
    };

    return (
      <MainWrapper>
        <Row gutter={24} style={{display: 'flex', flexWrap: 'wrap'}}>
          <Col xl={18} md={16} xs={24}>
            <div
              onClick={HandleGoBack}
              style={{display: 'flex', gap: '18px', cursor: 'pointer', width: 'fit-content'}}
            >
              <FontAwesomeIcon
                icon={faArrowLeft}
                style={{cursor: 'pointer'}}
              />
              <Heading>Back to list of GBP Connected Accounts</Heading>
            </div>
            <Email>{gmbDetailData?.email}</Email>
          </Col>
          <Col xl={6} md={8} xs={24}>
            <DisconnectEmail style={{textAlign: 'end'}} onClick={() => onDeleteAccount(gmbDetailData)}>
              Disconnect this Email
            </DisconnectEmail>
          </Col>
        </Row>
        <TableHeaderContainer>
          {gmbDetailData?.businessAccounts?.map((val, index) => {
            return (
              <CollapseStyled
                ghost
                key={index}
                expandIconPosition='right'
                bordered={false}
              >
                <Panel
                  key={1}
                  header={
                    <PanelWrapper>
                      <div>
                        <h4 className='headerTitle'>{val.name}</h4>
                        <p className='headerId' style={{marginBottom: '0px'}}>ID: {val.accountId}</p>
                      </div>
                      {val.locations?.length ? (
                        <div>
                          {emailIndex === index ? (
                            <RotatingIcon
                              icon={faRotate}
                              style={{marginRight: '8px'}}
                              color={'#A3A4A4'}
                            />
                          ) : (
                            <FontAwesomeIcon
                              style={{cursor: 'pointer', marginRight: '8px'}}
                              icon={faRotate}
                              title='Refresh'
                              color={'#A3A4A4'}
                              onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                setEmailIndex(index);
                                onRefreshHandler(gmbDetailData?.id);
                              }}
                            />
                          )}
                          <p style={{fontSize: '14px', marginBottom: '0px'}}>
                            {val.locations?.length} location
                            {val.locations?.length > 1 ? 's' : ''}
                          </p>
                        </div>
                      ) : (
                        <p style={{fontSize: '14px', color: '#A3A4A4'}}>
                          No locations found
                        </p>
                      )}
                    </PanelWrapper>
                  }
                >
                  {val.locations?.map((value, index) => {
                    return (
                      <div className='innerData' key={index}>
                        <div>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '7px',
                            }}
                          >
                            <h3>{value?.title || ''}</h3>
                            {value?.isVerified && (
                              <Tooltip
                                className='gmbtooltip'
                                overlayInnerStyle={{borderRadius: '10px'}}
                                title={'Verified Business'}
                              >
                                <FontAwesomeIcon
                                  icon={faCircleCheck}
                                  fontSize={12}
                                  color='#34AEF3'
                                />
                              </Tooltip>
                            )}
                          </div>
                          {value?.address &&
                          Object.values(value?.address)?.length ? (
                              <p>{`${
                                value?.address?.addressLines?.join(', ') || ''
                              }, ${value?.address?.locality || ''}, ${
                                value?.address?.regionCode || ''
                              }`}</p>
                            ) : (
                              <p>Missing address</p>
                            )}
                        </div>
                        <div className='icons'>
                          <Popover
                            style={{maxWidth: '434px'}}
                            content={value?.description}
                            trigger='click'
                            visible={popover[index]?.des}
                            placement='bottom'
                            onVisibleChange={(e: any) => {
                              if (popover[index]?.des) popover[index].des = e;
                              setPopover([...popover]);
                            }}
                          >
                            <Tooltip
                              className='gmbtooltip'
                              overlayInnerStyle={{borderRadius: '10px'}}
                              title={
                                value?.description &&
                                'Click to View Description'
                              }
                            >
                              <NextImg
                                onClick={() =>
                                  value?.description &&
                                  setPopover([
                                    ...popover,
                                    {...popover[index], des: true},
                                  ])
                                }
                                src='/img/descIcon.svg'
                                alt=''
                                style={
                                  value?.description ?
                                    {cursor: 'pointer'} :
                                    {filter: 'opacity(0.5)'}
                                }
                              />
                            </Tooltip>
                          </Popover>
                          <Popover
                            style={{maxWidth: '434px'}}
                            content={
                              value?.categories?.primaryCategory?.displayName
                            }
                            trigger='click'
                            visible={popover[index]?.category}
                            placement='bottom'
                            onVisibleChange={(e: any) => {
                              if (popover[index]?.category) {
                                popover[index].category = e;
                              }
                              setPopover([...popover]);
                            }}
                          >
                            <Tooltip
                              className='gmbtooltip'
                              overlayInnerStyle={{borderRadius: '10px'}}
                              title={
                                value?.categories?.primaryCategory
                                  ?.displayName && 'Click to View Categories'
                              }
                            >
                              <NextImg
                                onClick={() =>
                                  value?.categories?.primaryCategory
                                    ?.displayName &&
                                  setPopover([
                                    ...popover,
                                    {...popover[index], category: true},
                                  ])
                                }
                                src='/img/listIcon.svg'
                                alt=''
                                style={
                                  value?.categories?.primaryCategory
                                    ?.displayName ?
                                    {cursor: 'pointer'} :
                                    {filter: 'opacity(0.5)'}
                                }
                              />
                            </Tooltip>
                          </Popover>

                          <Popover
                            style={{maxWidth: '434px'}}
                            content={value?.workingHours?.periods?.map(
                              (val, index) => {
                                return (
                                  <p
                                    style={{margin: '5px 0 1px 0'}}
                                    key={index}
                                  >
                                    <b>{val.openDay}</b>:{' '}
                                    {val.openTime?.hours ?
                                      moment(
                                        val.openTime?.hours,
                                        'HH',
                                      ).format('h:mm A') + ' - ' :
                                      ''}{' '}
                                    {moment(val.closeTime?.hours, 'HH').format(
                                      'h:mm A',
                                    )}
                                  </p>
                                );
                              },
                            )}
                            trigger='click'
                            visible={popover[index]?.time}
                            placement='bottom'
                            onVisibleChange={(e: any) => {
                              if (popover[index]?.time) popover[index].time = e;
                              setPopover([...popover]);
                            }}
                          >
                            <Tooltip
                              className='gmbtooltip'
                              overlayInnerStyle={{borderRadius: '10px'}}
                              title={
                                value?.workingHours &&
                                'Click to View Working Hours'
                              }
                            >
                              <NextImg
                                onClick={() =>
                                  value?.workingHours &&
                                  setPopover([
                                    ...popover,
                                    {...popover[index], time: true},
                                  ])
                                }
                                src='/img/timeIcon.svg'
                                alt=''
                                style={
                                  value?.workingHours ?
                                    {cursor: 'pointer'} :
                                    {filter: 'opacity(0.5)'}
                                }
                              />
                            </Tooltip>
                          </Popover>
                          <Popover
                            style={{maxWidth: '434px'}}
                            content={
                              value?.websiteUrl && (
                                <Atag
                                  href={value?.websiteUrl}
                                  target='_blank'
                                  rel='noreferrer'
                                >
                                  {value?.websiteUrl}
                                </Atag>
                              )
                            }
                            trigger='click'
                            visible={popover[index]?.website}
                            placement='left'
                            onVisibleChange={(e: any) => {
                              if (popover[index]?.website) {
                                popover[index].website = e;
                              }
                              setPopover([...popover]);
                            }}
                          >
                            <Tooltip
                              className='gmbtooltip'
                              overlayInnerStyle={{borderRadius: '10px'}}
                              title={
                                value?.websiteUrl && 'Click to Open Website'
                              }
                            >
                              <NextImg
                                onClick={() =>
                                  value?.websiteUrl &&
                                  setPopover([
                                    ...popover,
                                    {...popover[index], website: true},
                                  ])
                                }
                                src='/img/worldIcon.svg'
                                alt=''
                                style={
                                  value?.websiteUrl ?
                                    {cursor: 'pointer'} :
                                    {filter: 'opacity(0.5)'}
                                }
                              />
                            </Tooltip>
                          </Popover>
                          <Popover
                            style={{maxWidth: '434px'}}
                            content={
                              value?.googlemapsUrl && (
                                <Atag
                                  href={value?.googlemapsUrl}
                                  target='_blank'
                                  rel='noreferrer'
                                >
                                  {value?.googlemapsUrl}
                                </Atag>
                              )
                            }
                            trigger='click'
                            visible={popover[index]?.location}
                            placement='left'
                            onVisibleChange={(e: any) => {
                              if (popover[index]?.location) {
                                popover[index].location = e;
                              }
                              setPopover([...popover]);
                            }}
                          >
                            <Tooltip
                              className='gmbtooltip'
                              overlayInnerStyle={{borderRadius: '10px'}}
                              title={
                                value?.googlemapsUrl &&
                                'Click to Open in Google Maps'
                              }
                            >
                              <NextImg
                                onClick={() =>
                                  value?.googlemapsUrl &&
                                  setPopover([
                                    ...popover,
                                    {...popover[index], location: true},
                                  ])
                                }
                                src='/img/mapIcon.svg'
                                alt=''
                                style={
                                  value?.googlemapsUrl ?
                                    {cursor: 'pointer'} :
                                    {filter: 'opacity(0.5)'}
                                }
                              />
                            </Tooltip>
                          </Popover>
                        </div>
                      </div>
                    );
                  })}
                </Panel>
              </CollapseStyled>
            );
          })}
        </TableHeaderContainer>
      </MainWrapper>
    );
  },
);

const DisconnectEmail = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  cursor: pointer;
  color: #f44343;
  @media only screen and (max-width: 767px) {
    padding-left: 30px;
  }
`;

const PanelWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
`;

const Atag = styled.a`
  max-width: 500px;
  display: flex;
  /* overflow: auto; */
  word-break: break-all;
`;

const CollapseStyled = styled(Collapse)`
  width: 100%;
  .headerTitle {
    font-size: 16px;
    color: #121212;
    font-weight: 500;
    margin: 0;
  }
  .headerId {
    color: #4e5156 !important;
    font-size: 12px !important;
    margin-left: 10px;
    @media only screen and (max-width: 487px) {
      margin-left: 0px;
    }
  }
  .ant-collapse-item {
    background: #e8e8e8 !important;
    border: 1px solid #e8e8e8 !important;
    margin: 0 !important;
    border-radius: 10px !important;
  }
  .ant-collapse-header {
    border-radius: 8px;
  }
  .ant-collapse-content-box {
    padding-top: 0 !important;
  }
  .innerData {
    padding: 10px 15px 10px 20px;
    background-color: white;
    // border-radius: 8px;
    overflow: auto;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:first-child {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }
    &:last-child {
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    .icons {
      // cursor: pointer;
      width: 33%;
      justify-content: space-between;
      display: flex;
      /* overflow: auto; */
    }
    h3 {
      font-size: 14px;
      color: #121212;
      margin: 0;
    }
    p {
      color: #4e5156;
      font-size: 12px;
    }
  }

  .childCollapseStyle {
    border: none;
    background: transparent;
    margin: 20px 0 0 0 !important;
    .ant-collapse-header {
      padding: 0 !important;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: flex-end;
      div {
        padding-top: 0 !important;
        .ant-collapse-arrow {
          position: unset !important;
          margin-left: 10px;
        }
      }
    }
    .ant-collapse-content-box {
      padding: 0 !important;
      background-color: #e8e8e8;
      margin: 4px 20px 4px 20px;
      border-radius: 8px;
    }
  }
`;

const Email = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #4e5156;
  padding-left: 30px;
`;
const MainWrapper = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  max-height: 100%;
`;
const Heading = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #121212;
`;

const TableHeaderContainer = styled.div`
  border-radius: 10px;
  margin-top: 25px;
  overflow-y: auto;
  padding-right: 6px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  scrollbar-face-color: rgba(138,138,138,0.62);
  scrollbar-track-color: rgba(138,138,138,0.12);

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(138,138,138,0.62);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(138,138,138,0.12);
  }

  .ant-table-column-sorters-with-tooltip:not(:first-child) {
    text-align: center;
  }
  .ant-table-content {
    overflow: hidden;
  }
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const RotatingIcon = styled(FontAwesomeIcon)`
  animation: ${spin} 2s linear infinite;
`;
