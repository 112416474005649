import React, {ReactNode, useState, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '@/store/root-store';
import {useRouter} from 'next/router';
import styled from 'styled-components';
import {routes} from '@/utils/const';
import {faQuestion} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPenNib, faClipboardList, faHighlighter, faBoltAuto, faMagnifyingGlass, faListUl, faMapLocationDot, faChartSimpleHorizontal, faMessageLines} from '@fortawesome/pro-regular-svg-icons';
import {faQuoteLeft, faStarHalfStroke} from '@fortawesome/pro-solid-svg-icons';
import {headerStore} from '@/store/storeCompTest';

type BaseMenuItem = {
  path?: string;
  key: string;
};

type PlainMenuItem = BaseMenuItem & {
  title: string;
  disabled?: boolean;
  hidden?: boolean;
  icon?: ReactNode;
  external?: boolean;
  newTab?: boolean;
  url?: string;
  path?: string;
  isAlpha?:boolean;
  isBeta?: boolean;
  color?: string;
  commingSoon?: boolean;
};

export type MenuItem = PlainMenuItem & {
  items?: PlainMenuItem[];
};

export type ItemsGroup = {
  title: string;
  key: string;
  items?: MenuItem[];
  hidden?: boolean;
  isShow?: boolean;
  isBeta?: boolean;
  isNew?: boolean;
  isAlpha?: boolean;
  disabled?: boolean;
  color?: string;
  path?: string;
  icon?: ReactNode;
  unreadEmails?: boolean | null;
};

type MenuProps = {
  groups: ItemsGroup[];
  reportBuilderId?: any;
  handleSideBarItems?: (items) => void;
  isSmallScreen?: boolean;
};

const Menu: React.FC<MenuProps> = observer(({handleSideBarItems, groups, ...props}: MenuProps) => {
  const router = useRouter();
  const id = router?.query?.id?.length ? Number(router?.query?.id[0]) : null;
  const {
    reportBuilder: {getReportsProjectsList},
    competitorResearcherV2: {getCompetitorResearcherId},
    ottoV2Store: {businessesList, getAllBusinesses},
    settings: {customer: {isGHLAccount}},
  } = useStore('');

  useEffect(() => {
    if (router?.pathname.includes('/gbp-galactic')) {
      const val = localSeoItemsV2?.map(item => {
        if (['Overview', 'Posts', 'Reviews', 'Q&As']?.includes(item?.title)) {
          item['commingSoon'] = !(businessesList?.data?.length || (router?.query?.id && router?.pathname?.includes('gbp-galactic') && !router?.pathname?.includes('local-seo-heatmaps')));
        }
        item['hidden'] = false;
        return item;
      });
      setLocalSeoItemsV2(val);
      if (['local-seo-heatmaps', 'local-citations', 'local-search-results']?.some(item => router?.pathname?.includes(item)) && businessesList == undefined) {
        getAllBusinesses(true);
      }
    }
  }, [router?.pathname, businessesList]);

  const localSeoItems = [
    {
      title: 'My businesses',
      key: `${routes.localSeoProjectsList}`,
      path: `${routes.localSeoProjectsList}`,
      color: '#2D6CCA',
      icon: <Icon onClick={() => {}} icon={faListUl} color='#2D6CCA'/>,
    },
    {
      title: 'Local SEO Heatmap',
      key: `${routes.localSeo}`,
      path: `${routes.localSeo}`,
      color: '#2D6CCA',
      icon: <Icon onClick={() => {}} icon={faMapLocationDot} color='#2D6CCA'/>,
    },
    {
      title: 'Local Listings',
      key: `''`,
      path: `''`,
      commingSoon: true,
      color: '#2D6CCA',
      icon: <Icon onClick={() => {}} icon={faClipboardList} color='#2D6CCA'/>,
    },
    {
      title: 'Local Citations',
      key: `${routes.localCitations}`,
      path: `${routes.localCitations}`,
      color: '#2D6CCA',
      icon: <Icon onClick={() => {}} icon={faHighlighter} color='#2D6CCA'/>,
      isBeta: false,
      hidden: isGHLAccount,
    },
    {
      title: 'GBP Automations',
      key: `${routes.gbpAutomations}`,
      path: `${routes.gbpAutomations}`,
      color: '#2D6CCA',
      isBeta: true,
      hidden: isGHLAccount,
      icon: <Icon onClick={() => {}} icon={faBoltAuto} color='#2D6CCA'/>,
      childPages: [
        {
          title: 'My Tasks',
          key: `${routes.myTasks}`,
          path: `${routes.myTasks}`,
          color: '#2D6CCA',
          isBeta: true,
          hidden: isGHLAccount,
        },
        {
          title: 'Posts',
          key: `${routes.gbpAutomationsPosts}`,
          path: `${routes.gbpAutomationsPosts}`,
          color: '#2D6CCA',
          isBeta: true,
          hidden: isGHLAccount,
        },
        {
          title: 'Reviews',
          key: `${routes.gbpAutomationsReviews}`,
          path: `${routes.gbpAutomationsReviews}`,
          color: '#2D6CCA',
          isBeta: true,
          hidden: isGHLAccount,
        },
        {
          title: 'Questions & Answers',
          key: `${routes.gbpAutomationsQuestions}`,
          path: `${routes.gbpAutomationsQuestions}`,
          color: '#2D6CCA',
          isBeta: true,
          size: 'small',
          hidden: isGHLAccount,
        },
        {
          title: 'GBP Optimization',
          key: `${routes.gbpAutomationsOptimization}`,
          path: `${routes.gbpAutomationsOptimization}`,
          color: '#2D6CCA',
          isNew: true,
          size: 'small',
          hidden: isGHLAccount,
        },
      ],
    },
    {
      title: 'Local SERPs',
      key: '/local-search-results',
      path: '/local-search-results',
      color: '#2D6CCA',
      icon: <Icon onClick={() => {}} icon={faMagnifyingGlass} color='#2D6CCA' />,
    },
    {
      title: 'AI GBP Writer',
      key: `${routes.aiGmbWriter}`,
      path: `${routes.aiGmbWriter}`,
      color: '#2D6CCA',
      isAlpha: true,
      hidden: isGHLAccount,
      icon: <Icon onClick={() => {}} icon={faPenNib} color='#2D6CCA'/>,
    },
  ];
  const [localSeoItemsV2, setLocalSeoItemsV2] = useState([
    {title: 'Local SEO V2', pages: [
      {
        title: 'GBP Projects',
        key: routes.gbpProjectsV2,
        path: routes.gbpProjectsV2,
        color: '#2D6CCA',
        icon: <Icon onClick={() => {}} icon={faListUl} color='#A3A4A4'/>,
      },
      {
        title: 'Overview',
        key: routes.gbpProjectOverview,
        path: routes.gbpProjectOverview,
        color: '#2D6CCA',
        commingSoon: !(businessesList?.data?.length || (router?.query?.id && router?.pathname?.includes('research-v2'))),
        disabledMsg: 'You need to connect a project.',
        icon: <Icon onClick={() => {}} icon={faChartSimpleHorizontal} color='#A3A4A4'/>,
      },
      {
        title: 'AI AUTOMATIONS',
        key: 'ai-automations',
        type: 'group',
        shortTitle: 'AI',
      },
      {
        title: 'Posts',
        key: routes.gbpPosts,
        path: routes.gbpPosts,
        color: '#2D6CCA',
        commingSoon: !(businessesList?.data?.length || (router?.query?.id && router?.pathname?.includes('research-v2'))),
        disabledMsg: 'You need to connect a project.',
        icon: <Icon onClick={() => {}} icon={faMessageLines} color='#A3A4A4'/>,
      },
      {
        title: 'Reviews',
        key: routes.gbpReviews,
        path: routes.gbpReviews,
        color: '#2D6CCA',
        commingSoon: !(businessesList?.data?.length || (router?.query?.id && router?.pathname?.includes('research-v2'))),
        disabledMsg: 'You need to connect a project.',
        icon: <Icon onClick={() => {}} icon={faStarHalfStroke} color='#A3A4A4'/>,
        isBeta: false,
      },
      {
        title: 'Q&As',
        key: routes.gbpQAs,
        path: routes.gbpQAs,
        color: '#2D6CCA',
        commingSoon: !(businessesList?.data?.length || (router?.query?.id && router?.pathname?.includes('research-v2'))),
        disabledMsg: 'You need to connect a project.',
        icon: <Icon onClick={() => {}} icon={faQuestion} color='#A3A4A4'/>,
      },
      {
        title: 'LOCAL SEO TOOLS',
        key: 'local-seo-tools',
        type: 'group',
        shortTitle: 'SEO',
      },
      {
        title: 'Local SEO Heatmaps',
        key: routes.localSeoHeatmaps,
        path: routes.localSeoHeatmaps,
        color: '#2D6CCA',
        icon: <Icon onClick={() => {}} icon={faMapLocationDot} color='#A3A4A4'/>,
      },
      {
        title: 'Local Citations',
        key: routes.localCitationsV2,
        path: routes.localCitationsV2,
        color: '#2D6CCA',
        icon: <Icon onClick={() => {}} icon={faQuoteLeft} color='#A3A4A4'/>,
        isBeta: false,
      },
      {
        title: 'Local SERPS',
        key: routes.localSerpsV2,
        path: routes.localSerpsV2,
        color: '#2D6CCA',
        icon: <Icon onClick={() => {}} icon={faMagnifyingGlass} color='#A3A4A4' />,
      },
    ]},
  ]);
  const localSeoTools = [
    `${routes.localSeoProjectsList}`,
    `${routes.localSeo}`,
    `${routes.localSeo}/${id}`,
    `${routes.aiGmbWriter}`,
    `${routes.localCitations}`,
    `${routes.myTasks}`,
    `${routes.gbpAutomations}`,
    `${routes.gbpAutomationsPosts}`,
    `${routes.gbpAutomationsReviews}`,
    `${routes.gbpAutomationsQuestions}`,
    `${routes.gbpAutomationsOptimization}`,
    `/local-search-results`,
  ];
  const localSeoToolsV2 = [
    routes.gbpProjectsV2,
    routes.gbpProjectOverview,
    routes.gbpPosts,
    routes.gbpReviews,
    routes.gbpQAs,
    routes.localSeoHeatmaps,
    routes.localSeoHeatmapsDetails,
    `${routes.localSeoHeatmapsDetails}/${id}`,
    routes.localCitationsV2,
    routes.localSerpsV2,
  ];

  useEffect(() => {
    const activeKey = router.asPath?.split('?')[0];
    const mainTool = groups?.find(item => item?.path == activeKey || (router?.pathname?.includes(item?.path) && router?.pathname?.includes('site-explorer')));
    const checkItemPath = groups?.find(item => item?.items?.find(z => z?.path == activeKey || router?.pathname?.includes(z?.path) || (router?.asPath?.includes(z?.path?.split('?')[0]) && (router?.pathname?.includes('report-builder') || router?.pathname?.includes('site-explorer')))));
    handleSideBarItems(mainTool?.key ? mainTool?.items : checkItemPath?.key ? checkItemPath?.items : (localSeoTools.includes(activeKey) ? localSeoItems : (localSeoToolsV2).includes(activeKey) ? localSeoItemsV2 : []));
  }, [router, getCompetitorResearcherId, props?.reportBuilderId, getReportsProjectsList?.length, groups]);

  const ActiveComponent = headerStore.activeComponent?.component;

  return ActiveComponent ? <ActiveComponent isSearch={headerStore.activeComponent?.isSearch} quotaList={headerStore.activeComponent?.list} /> : <></>;
});

export default Menu;


const Icon = styled(FontAwesomeIcon)`
  height: 16px !important;
  width: 18px !important; 
`;
