import React, {useEffect, useRef} from 'react';
import {colSpan, rowgutter, searchIntents} from './commonFolderSettingsUtils';
import {Container, Input, Label, SectionTitle, Select, StyledFormItem, Textarea} from './styles';
import {Col, Row, Tooltip} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleInfo} from '@fortawesome/pro-regular-svg-icons';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import useIntersectionObserver from './useIntersectionObserver';
import {observer} from 'mobx-react';

const GeneralSection = ({form, selectedSection, setActiveOption, currentProjectData, isEdit}) => {
  const blockref = useRef(null);
  const blockEntry = useIntersectionObserver(blockref, {
    threshold: [0.3, 0.5, 0.7, 0.9, 1.0],
  });
  const isVisible = !!blockEntry?.isIntersecting;


  useEffect(() => {
    if (isVisible) {
      setActiveOption('general');
    }
  }, [isVisible]);

  const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/;
  const validateUrl = (_, value) => {
    const trimmedValue = value?.trim();
    if (trimmedValue.includes(' ')) {
      return Promise.reject(new Error('URL cannot contain spaces'));
    }


    if ( !value || urlPattern.test(trimmedValue)) {
      return Promise.resolve();
    }

    return Promise.reject(new Error('URL must be valid'));
  };

  return (
    <Container id='general' ref={blockref}>
      <div className={selectedSection === 'general' ? 'selected-bg' : ''} id='container'>
        <SectionTitle>GENERAL</SectionTitle>
        <Label>Folder name</Label>
        <StyledFormItem
          name='name'
          rules={[
            {required: true, message: 'This field is required'},
            {max: 100, message: 'Folder name cannot exceed 100 characters'},
          ]}
        >
          <Input
            placeholder='Folder name'
            disabled={currentProjectData?.id === null ? true : false}
            className={`${
              isEdit && (form.getFieldValue('name') === null || form.getFieldValue('name') === '') ?
                'error-border' :
                ''
            }`}
          />
        </StyledFormItem>
        <Row gutter={rowgutter}>
          <Col span={colSpan}>
            <Label>Website URL</Label>
            <StyledFormItem
              name='domain'
              rules={[
                {validator: validateUrl},
              ]}

            >
              <Input placeholder='Website URL'
                className={`${
                  isEdit && (form.getFieldValue('domain') === null || form.getFieldValue('domain') === '') ?
                    'error-border' :
                    ''
                }`} />
            </StyledFormItem>
          </Col>
          <Col span={colSpan}>
            <Label>Website purpose <Tooltip title='Website Purpose is looking for the context of your entire website. Do you offer a service or a product? For affiliates, you would just say “a website providing informational guides and education.'><FontAwesomeIcon icon={faCircleInfo} color='#A3A4A4' /></Tooltip></Label>
            <StyledFormItem
              name='website_purpose'
            >
              <Input placeholder='Website purpose'
                className={`${isEdit && (form.getFieldValue('website_purpose') === null || form.getFieldValue('website_purpose') === '' )? 'error-border' : ''}`} />
            </StyledFormItem>
          </Col>
        </Row>
        <Row gutter={rowgutter}>
          <Col span={colSpan}>
            <Label>Niche <Tooltip title='This is used when generating your AI content and it should be broad (Roofing Company or Commercial Roofing).'><FontAwesomeIcon icon={faCircleInfo} color='#A3A4A4' /></Tooltip></Label>
            <StyledFormItem
              name='niche'
            >
              <Input placeholder='Niche'
                className={`${ isEdit && (form.getFieldValue('niche') === null || form.getFieldValue('niche') === '') ? 'error-border' : ''}`}
              />
            </StyledFormItem>
          </Col>
          <Col span={colSpan}>
            <Label>Default audience <Tooltip title='By default, the AI writes for a target audience associated with your niche, but you can also specify. Remote workers and freelancers aged 25-40.'><FontAwesomeIcon icon={faCircleInfo} color='#A3A4A4' /></Tooltip></Label>
            <StyledFormItem
              name='default_audience'
            >
              <Input
                placeholder='Default audience'
                className={`${isEdit && (form.getFieldValue('default_audience') === null || form.getFieldValue('default_audience') === '') ? 'error-border' : ''}`}
              />
            </StyledFormItem>
          </Col>
        </Row>
        <Row gutter={rowgutter}>
          <Col span={colSpan}>
            <Label>Search intent <Tooltip title='Search intent for blogs are always ‘educational’ and you should also specify what the searcher wants to learn.'><FontAwesomeIcon icon={faCircleInfo} color='#A3A4A4' /></Tooltip></Label>
            <StyledFormItem
              name='search_intent'
            >
              <Select
                mode='multiple'
                maxTagCount='responsive'
                onChange={(value: any) => {
                  const obj = {};
                  obj['search_intent'] = value;
                  form.setFieldsValue(obj);
                }}

                placeholder='Search intent'
                maxTagPlaceholder={values => {
                  return (
                    <Tooltip title={values?.map((item, index) => <div key={index}>{item?.label}{index < values?.length - 1 ? ', ' : ''}</div>)}>
                      <div>+{values.length}</div>
                    </Tooltip>
                  );
                }}
                suffixIcon={<FontAwesomeIcon icon={faCaretDown} color='#4E5156' />}
                defaultValue={form.getFieldValue('search_intent')}
                className={`${isEdit && (form.getFieldValue('search_intent')?.length === 0) ? 'error-border' : ''}`}
              >
                {searchIntents.map(item => (
                  <Select.Option value={item.value} key={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
              <FontAwesomeIcon icon={faCaretDown} color='#4E5156' style={{position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)'}} />
            </StyledFormItem>
          </Col>
          <Col span={colSpan}>
            <Label>Business intent <Tooltip title='This tells the AI what you’re trying to accomplish, which modifies content output'><FontAwesomeIcon icon={faCircleInfo} color='#A3A4A4' /></Tooltip></Label>
            <StyledFormItem
              name='intent'
            >
              <Input placeholder='Business intent'
                className={`${isEdit && (form.getFieldValue('intent') === null || form.getFieldValue('intent') === '' )? 'error-border' : ''}`} />
            </StyledFormItem>
          </Col>
        </Row>
        <Label>Company background (and/or additional instructions for the AI Writer)</Label>
        <StyledFormItem
          name='global_background'
        >
          <Textarea placeholder='Company background' className={`${isEdit && (form.getFieldValue('global_background') === null || form.getFieldValue('global_background') === '' )? 'error-border' : ''}`}/>
        </StyledFormItem>
      </div>
    </Container>
  );
};

export default observer(GeneralSection);
