import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRotateLeft, faMagnifyingGlassPlus, faMagnifyingGlassMinus, faCircleInfo, faFileLines, faListTree} from '@fortawesome/pro-regular-svg-icons';
import {faCirclePlus, faWandMagicSparkles, faArrowUpFromLine, faArrowUpRightFromSquare, faCircleCheck} from '@fortawesome/pro-solid-svg-icons';
import {Col, Form, Row, Checkbox, Popover, Dropdown, Menu, Tooltip, Spin} from 'antd';
import {useStore} from '@/store/root-store';
import * as echarts from 'echarts';
import {LoadingChart} from '@/components/dashboard/pages/site-explorer-page/detail-page/components/loadingChart';
import {Informational, Transitional, Commercial, Navigational} from '../../../Constants';
import {GenerateArticleMenu, StyledEmpty} from '../../../style';
import {getLeafNodes, spaceBetweenNodes} from '../../../Utils/helper-func';
import {OTTOTopicalModalTableFilterEnum, OTTOTopicalModalTableFilterList} from '@/constants';
import {truncate} from '@/utils/string';
import {Button} from '@/components/common-components';
import {LoadingOutlined} from '@ant-design/icons';
import {OneClickPublisher} from '@/components/dashboard/pages/landing-page-optimizer/page-editor/LeftSide/TopSection/title-editor/OneClickPublisher/OneClickPublisher';
import {openUrl} from '@/utils/router';
import {faCircleCheck as faCircleCheckDuotone} from '@fortawesome/pro-duotone-svg-icons';
let checkIsNode = false;

interface TopicalMapModalGraphContentProps {
  isNodeSelected: any;
  func: any;
  dependency: any;
  topicalMap: any;
  dataLoader: any;
  topicalMapDataState: any;
  zoom: any;
  setZoom: any;
  setActivatedFilters: any;
  activeCategories: any[];
  setActiveCategories: Dispatch<SetStateAction<any[]>>;
  categoriesOptions: any[];
  isInContentTopicMap?: boolean;
  setAddingIdeaInTopicMap: any;
  selectedArticlesToGenerate: any;
  setSelectedArticlesToGenerate: any;
  onGenerateArticle: any;
  onOutline: any;
  setErrorId: any;
  errorId: any;
  topicalMapArticle: any;
  generatingBulkArticles: any;
  setDataLoader: any;
  wpContainerRef: any;
  topicMapDataList: any;
  triggerZoom: boolean;
  onFiltersChange: any;
  activatedFilters: any;
  setTriggerZoom: any;
  tableData: any;
  topicalMapDataList: any;
}

let myChart;

export const TopicalMapModalGraphContent: React.FC<TopicalMapModalGraphContentProps> = (
  {
    isNodeSelected,
    func,
    dependency,
    topicalMap,
    dataLoader,
    topicalMapDataState,
    zoom,
    setZoom,
    setActivatedFilters,
    activeCategories,
    setActiveCategories,
    categoriesOptions,
    isInContentTopicMap,
    setAddingIdeaInTopicMap,
    selectedArticlesToGenerate,
    setSelectedArticlesToGenerate,
    onGenerateArticle,
    onOutline,
    setErrorId,
    errorId,
    topicalMapArticle,
    generatingBulkArticles,
    setDataLoader,
    wpContainerRef,
    topicMapDataList,
    triggerZoom,
    onFiltersChange,
    activatedFilters,
    setTriggerZoom,
    tableData,
    topicalMapDataList,
  }) => {
  const {settings: {customer: {profile: {whitelabelOtto}}}, contentOptimizer: {
    wpPublish: {wpArray, setShowInstructionsModal},
  }} = useStore('');

  const [isNodeClicked, setIsNodeClicked] = useState<boolean>(false);
  const [clickedValue, setClickedValue] = useState<any>(0);
  const [value, setValue] = useState<any>({});
  const [nodeId, setNodeId] = useState<any>(-1);
  const [positions, setPositions] = useState({
    top: 0,
    left: 0,
  });
  const graphHeight = document?.documentElement?.clientHeight < 830 ? 470 : document?.documentElement?.clientHeight - 500;

  useEffect(() => {
    const id = document.getElementById('linkgraph-chart');
    if (id && (dependency)) {
      myChart = echarts.init(id);
      const option = {
        tooltip: {
          formatter: function(...props: any) {
            const {name} = props[0].data;
            return name;
          },
          trigger: 'item',
          triggerOn: 'mousemove',
        },
        series: [
          {
            type: getLeafNodes(topicalMapDataState).length < 1 ? 'hidden' : 'tree',
            zoom: zoom,
            data: [topicalMapDataState],
            roam: 'move',
            top: '1%',
            left: '1%',
            bottom: getLeafNodes(topicalMapDataState)?.length > 3 ? spaceBetweenNodes(topicalMapDataState) : '1%',
            right: '45%',
            symbolSize: 10,
            symbolOffset: [6, 0],
            status: 'unselect',
            label: {
              position: 'top',
              verticalAlign: 'middle',
              color: '#333333',
              fontSize: 12,
              formatter: function(d) {
                let icon = '';
                let space = '';
                if (d.data?.searchIntent?.length > 0) {
                  space = '     ';
                  space = space.repeat(d.data?.searchIntent.length);
                  icon = d.data?.searchIntent?.map(intent => `{${intent?.toLowerCase()}|} `)?.join('');
                }
                if (d.data.titleName) {
                  return `${icon}{title|${d.data.titleName}}\n${space}{keyword|${d.data.name}}`;
                }
                return `{keyword|${d.data.name}}`;
              },
              rich: {
                title: {
                  color: '#333333',
                  fontWeight: 400,
                  fontSize: '12px',
                },
                keyword: {
                  color: '#4E5156',
                  fontWeight: 400,
                  fontSize: '10px',
                },
                transactional: {
                  align: 'center',
                  backgroundColor: {
                    image: encodeSvgToDataURI(Transitional),
                  },
                },
                informational: {
                  align: 'center',
                  backgroundColor: {
                    image: encodeSvgToDataURI(Informational),
                  },
                },
                commercial: {
                  align: 'center',
                  backgroundColor: {
                    image: encodeSvgToDataURI(Commercial),
                  },
                },
                navigational: {
                  align: 'center',
                  backgroundColor: {
                    image: encodeSvgToDataURI(Navigational),
                  },
                },
              },
            },
            leaves: {
              label: {
                position: 'right',
                verticalAlign: 'middle',
                align: 'left',
              },
            },
            emphasis: {
              focus: 'descendant',
            },
            selectedMode: true,
            expandAndCollapse: false,
            animationDuration: 550,
            animationDurationUpdate: 750,
          },
        ],
        notMerge: true,
        lazyUpdate: true,
      };
      myChart.setOption(option);
    }
  }, [zoom]);

  useEffect(() => {
    const id = document.getElementById('linkgraph-chart');
    if (id && topicalMapDataState?.children?.length) {
      myChart = echarts.init(id);
      const option = {
        tooltip: {
          show: false,
          formatter: function(...props: any) {
            const {name, isSelected, value} = props[0].data;
            return `${name}
            ${(isInContentTopicMap || topicalMapDataState?.name === name) ? '' :
    `<hr/>Click to ${isSelected ? 'remove' : 'add'} this article idea to your topical map.
    ${typeof value === 'number' ? `<br/>You can create articles in the Table view or on the main ${whitelabelOtto} page.` : ''}`}`;
          },
          trigger: 'item',
          triggerOn: 'mousemove',
        },
        series: [
          {
            type: getLeafNodes(topicalMapDataState).length < 1 ? 'hidden' : 'tree',
            data: [topicalMapDataState],
            roam: 'move',
            top: '1%',
            left: '1%',
            bottom: getLeafNodes(topicalMapDataState)?.length > 3 ? spaceBetweenNodes(topicalMapDataState) : '1%',
            right: '45%',
            symbolSize: 13,
            symbolOffset: [6, 0],
            status: 'unselect',
            label: {
              position: 'top',
              verticalAlign: 'middle',
              color: '#333333',
              fontSize: 12,
              formatter: function(d) {
                let icon = '';
                let space = '';
                if (d.data?.searchIntent?.length > 0) {
                  space = '     ';
                  space = space.repeat(d.data?.searchIntent.length);
                  icon = d.data?.searchIntent?.map(intent => `{${intent?.toLowerCase()}|} `)?.join('');
                }
                if (d.data.titleName) {
                  return `${icon}{title|${d.data.titleName}}\n${space}{keyword|${d.data.name}}`;
                }
                return `{keyword|${truncate(d.data.name, 35)}}`;
              },
              rich: {
                title: {
                  color: '#333333',
                  fontWeight: 400,
                  fontSize: '12px',
                },
                keyword: {
                  color: '#4E5156',
                  fontWeight: 400,
                  fontSize: '10px',
                },
                transactional: {
                  align: 'center',
                  backgroundColor: {
                    image: encodeSvgToDataURI(Transitional),
                  },
                },
                informational: {
                  align: 'center',
                  backgroundColor: {
                    image: encodeSvgToDataURI(Informational),
                  },
                },
                commercial: {
                  align: 'center',
                  backgroundColor: {
                    image: encodeSvgToDataURI(Commercial),
                  },
                },
                navigational: {
                  align: 'center',
                  backgroundColor: {
                    image: encodeSvgToDataURI(Navigational),
                  },
                },
              },
            },
            leaves: {
              label: {
                position: 'right',
                verticalAlign: 'middle',
                align: 'left',
              },
            },
            emphasis: {
              focus: 'descendant',
            },
            cursor: 'pointer',
            selectedMode: true,
            expandAndCollapse: false,
            animationDuration: 550,
            animationDurationUpdate: 750,
          },
        ],
        notMerge: true,
        lazyUpdate: true,
      };
      myChart.setOption(option);
    }
  }, [topicalMapDataState]);

  useEffect(() => {
    if (isInContentTopicMap && clickedValue?.value && myChart) {
      const updatedData = isNodeSelected(clickedValue?.value, {...topicalMapDataList}, isInContentTopicMap);
      func(updatedData);
      const option = {
        series: [
          {
            type: getLeafNodes(topicalMapDataState).length < 1 ? 'hidden' : 'tree',
            data: [topicalMapDataState],
            roam: 'move',
            top: '1%',
            left: '1%',
            bottom: getLeafNodes(topicalMapDataState)?.length > 3 ? spaceBetweenNodes(topicalMapDataState) : '1%',
            right: '45%',
            symbolSize: 13,
            status: 'unselect',
            label: {
              position: 'top',
              verticalAlign: 'middle',
              fontSize: 9,
            },
            leaves: {
              label: {
                position: 'right',
                verticalAlign: 'middle',
                align: 'left',
              },
            },
            emphasis: {
              focus: 'descendant',
            },
            expandAndCollapse: false,
            animationDuration: 550,
            animationDurationUpdate: 750,
          },
        ],
      };
      myChart.setOption(option);
      if (isInContentTopicMap) {
        setAddingIdeaInTopicMap(true);
      }
      setValue(clickedValue);
    }
  }, [isNodeClicked]);

  useEffect(() => {
    if (!isInContentTopicMap && clickedValue?.value && myChart) {
      const updatedData = isNodeSelected(clickedValue?.value, {...topicalMapDataList}, isInContentTopicMap);
      func(updatedData);
      const option = {
        series: [
          {
            type: getLeafNodes(topicalMapDataState).length < 1 ? 'hidden' : 'tree',
            data: [topicalMapDataState],
            roam: 'move',
            top: '1%',
            left: '1%',
            bottom: getLeafNodes(topicalMapDataState)?.length > 3 ? spaceBetweenNodes(topicalMapDataState) : '1%',
            right: '45%',
            symbolSize: 13,
            status: 'unselect',
            label: {
              position: 'top',
              verticalAlign: 'middle',
              fontSize: 9,
            },
            leaves: {
              label: {
                position: 'right',
                verticalAlign: 'middle',
                align: 'left',
              },
            },
            emphasis: {
              focus: 'descendant',
            },
            expandAndCollapse: false,
            animationDuration: 550,
            animationDurationUpdate: 750,
          },
        ],
      };
      myChart.setOption(option);
      if (isInContentTopicMap) {
        setAddingIdeaInTopicMap(true);
      }
    }
  }, [clickedValue]);

  const updateObject = children => {
    children.map(child => {
      if (String(child.value) === String(value?.data?.value)) {
        setValue({data: child});
        return child;
      } else {
        if (child?.children?.length) {
          return updateObject(child.children);
        }
      }
    });
  };

  useEffect(() => {
    if (value?.data) {
      topicMapDataList?.map(d => updateObject(d.children));
    }
  }, [topicMapDataList]);

  useEffect(() => {
    if (topicalMapDataState?.children?.length && myChart) {
      myChart?.on('click', params => {
        setClickedValue(params);
        if (isInContentTopicMap) {
          if (params?.data?.value && Number(params.data.value)) {
            setValue(params);
            checkIsNode = true;
            setPositions({
              left: params.event.offsetX,
              top: params.event.offsetY,
            });
            setNodeId(`${params.data.value}-open`);
            setTimeout(() => {
              checkIsNode = false;
            }, 2000);
          }
        }
      });
      if (typeof window !== 'undefined') {
        window.addEventListener('click', function(event: any) {
          const graphOverId = document.getElementById('graph-popover')?.contains(event.target);
          const dropdownId = document.getElementById('popover-dropdown')?.contains(event.target);
          const wpPublishModal = document.getElementById('wp-publish=modal')?.contains(event.target);
          const wpWebsite = document.getElementById('wpWebsite')?.contains(event.target);
          const rcVirtualList = document.querySelector('.rc-virtual-list');
          if (!checkIsNode && !graphOverId && !dropdownId && !wpPublishModal && !wpWebsite && !rcVirtualList) {
            setNodeId(-1);
          }
        });
      }
    }
  }, [topicalMapDataState]);

  useEffect(() => {
    if (triggerZoom) {
      handleResetZoom();
      setTriggerZoom(false);
    }
  }, [triggerZoom]);

  const encodeSvgToDataURI = svg => {
    return (
      'data:image/svg+xml;charset=utf8,' +
      encodeURIComponent(
        svg.replace(
          '<svg',
          svg.indexOf('xmlns') > -1 ? '<svg' : '<svg xmlns="http://www.w3.org/2000/svg"',
        ),
      )
    );
  };

  const handleCategories = (checked, value, selectAll, deSelectAll) => {
    let filters = [];
    if (selectAll || deSelectAll) {
      if (selectAll) {
        filters = activatedFilters.map(filter => filter?.header === OTTOTopicalModalTableFilterEnum.categories ? {...filter, category: value, active: true} : filter);
      } else {
        filters = activatedFilters.map(filter => filter?.header === OTTOTopicalModalTableFilterEnum.categories ? {...filter, category: [], active: false} : filter);
      }
    } else {
      if (activatedFilters.find(filter => filter?.header === OTTOTopicalModalTableFilterEnum.categories)?.active) {
        if (checked) {
          filters = activatedFilters.map(filter => filter?.header === OTTOTopicalModalTableFilterEnum.categories ? {...filter, category: [...filter.category, value]} : filter);
        } else {
          filters = activatedFilters.map(filter => filter?.header === OTTOTopicalModalTableFilterEnum.categories ? {...filter, category: filter.category.filter(ct => ct !== value)?.length ? filter?.category?.filter(ct => ct !== value) : undefined, active: filter?.category?.filter(ct => ct !== value)?.length ? true: false} : filter);
        }
      } else {
        filters = activatedFilters.map(filter => filter?.header === OTTOTopicalModalTableFilterEnum.categories ? {...filter, category: [value], active: true} : filter);
      }
    }
    onFiltersChange(filters, tableData);
  };

  const onChange = (event: any, value: any) => {
    setActivatedFilters(OTTOTopicalModalTableFilterList);
    setActiveCategories(prev =>event.target.checked ? [...prev, value] : prev?.filter(item => item !== value));
    handleCategories(event.target.checked, value, false, false);
  };

  const handleResetZoom = () => {
    if (myChart && (zoom !== 1)) {
      myChart.dispatchAction({
        type: 'restore',
      });
    }
    setZoom(1);
  };

  const hasLength = !!topicalMapArticle?.filter(i => i?.titleUuid === value?.data?.titleUuid)?.length;
  const isLoading = topicalMapArticle?.find(i => i?.titleUuid === value?.data?.titleUuid)?.isLoading;
  const articleGenerating = (hasLength && isLoading) || (!value?.data?.pageUuid && value?.data?.aiGenerationStatus === 'PENDING') || (selectedArticlesToGenerate?.filter(article => article?.titleUuid === value?.data?.titleUuid)?.length && generatingBulkArticles);

  return (
    <>
      <Row gutter={16}>
        <Col span={5}>
          <Row justify='space-between' style={{maxWidth: 250, marginTop: 10}}>
            <Col>
              <CategoryHeading>Categories</CategoryHeading>
            </Col>
            <Col>
              <Row gutter={14}>
                <Col>
                  <SelectAll onClick={() => {
                    setActiveCategories(categoriesOptions?.map(item => item?.value));
                    handleCategories(false, categoriesOptions?.map(item => item?.value), true, false);
                  }}>
                    Select all
                  </SelectAll>
                </Col>
                <Col>
                  <SelectAll onClick={() => {
                    setActiveCategories([]);
                    handleCategories(false, [], false, true);
                  }}>
                    Deselect all
                  </SelectAll>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={19}>
          <div style={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: isInContentTopicMap ? 'flex-end' : 'space-between'}}>
            {!isInContentTopicMap && (<div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
            </div>)}
            <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
              <ZoomNote>
                <FontAwesomeIcon icon={faCircleInfo} fontSize={16} color='#A3A4A4'/>
                <div className='text-note'>Click, hold and drag around to explore the diagram.<br/>Select ideas to add them to the topic map.</div>
              </ZoomNote>
              <ZoomButtons>Zoom:
                <FontAwesomeIcon icon={faMagnifyingGlassPlus} fontSize={16} color='#4E5156' style={{cursor: 'pointer'}} onClick={() => {
                  setZoom(zoom + 0.1);
                }}/>
                <FontAwesomeIcon icon={faMagnifyingGlassMinus} fontSize={16} color={zoom <= 1 ? '#989898' : '#4E5156'} style={{cursor: zoom <= 1 ? 'not-allowed' : 'pointer'}} onClick={() => {
                  if (zoom > 1) {
                    setZoom(zoom - 0.1);
                  }
                }}/>
                <FontAwesomeIcon icon={faArrowRotateLeft} fontSize={16} color='#4E5156' style={{cursor: 'pointer'}} onClick={()=>{
                  handleResetZoom();
                }}/>
              </ZoomButtons>
            </div>
          </div>
        </Col>
      </Row>
      <Row style={{height: '96%'}}>
        <Col span={5} style={isInContentTopicMap ? {height: '100%'} : {}}>
          <CategoryFormItem className='category-column' name='categories'>
            <StyledCheckboxWrapper isInContentTopicMap={isInContentTopicMap}>
              {categoriesOptions.map(categories => <div key={categories?.value} className={activeCategories.includes(categories?.value) ? 'checked-checkbox-wrapper' : 'checkbox-wrapper'}>
                <Checkbox onChange={e => onChange(e, categories?.value)} checked={activeCategories.includes(categories?.value)}>
                  <span className='label'>{categories?.label}</span>
                </Checkbox>
              </div>)}
            </StyledCheckboxWrapper>
          </CategoryFormItem>
        </Col>
        <Col span={18}>
          {!topicalMapDataState?.children?.length && <div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><StyledEmpty /></div>}
          {dataLoader ? <LoadingChart /> :
            (!dataLoader && (typeof topicalMapDataState === 'object' && 'children' in topicalMapDataState)) ? (
              <div style={{position: 'relative', width: isInContentTopicMap ? '100%' : '950px', height: '100%'}}>
                <Popover
                  trigger='click'
                  visible={nodeId !== -1}
                  key={nodeId}
                  // onVisibleChange={visible => !visible && setNodeId(-1)}
                  autoAdjustOverflow={false}
                  // placement={'bottomLeft'}
                  align={{offset: [positions.left, positions.top]}}
                  overlayInnerStyle={{
                    width: '285px',
                    borderRadius: '12px',
                    border: '1px solid #A3A4A499',
                    boxShadow: '0px 4px 24px 0px #00000040',
                    backgroundColor: '#FFFFFF',
                  }}
                  content={<StyledPopover id='graph-popover'>
                    <div className='heading'>Like this idea? Save it to your Topic Map.</div>
                    <div className='step'>
                      <div className={`step-label color-black ${value?.data?.isSelected ? 'selected' : ''}`}>Step 1:</div>
                      <StyledDynamicButton
                        onClick={() => !articleGenerating && setIsNodeClicked(!isNodeClicked)}
                        className={`${value?.data?.isSelected ? 'selected-topic-map' : 'unselected-topic-map'}`}
                        style={{cursor: articleGenerating ? 'not-allowed' : ''}}
                      >
                        <FontAwesomeIcon icon={value?.data?.isSelected ? faCircleCheckDuotone : faCirclePlus} fontSize={16} color={value?.data?.isSelected ? articleGenerating ? '#A3A4A4' : '#1FAC47' : '#ffffff'}/>{`${value?.data?.isSelected ? 'Saved' : 'Add'}`} to Topic Map
                      </StyledDynamicButton>
                    </div>
                    <div className='note'>Generate and publish entire articles directly<br/>from your Topic Map.</div>
                    <div className='step'>
                      <div className={`step-label ${value?.data?.pageUuid ? value?.data?.isSelected ? 'selected color-black' : 'selected' : ''}`}>Step 2:</div>
                      {articleGenerating ?
                        <StyledDynamicButton id='step-button-loader' disabled buttonType='purple' className='step-button'>
                          <Spin indicator={<LoadingOutlined style={{fontSize: 14, color: '#fff'}} spin />} />Generating...
                        </StyledDynamicButton> : value?.data?.pageUuid ?
                          <div style={{display: 'flex', alignItems: 'center', gap: '5px', cursor: (isInContentTopicMap && !value?.data?.isSelected) ? 'not-allowed' : ''}}>
                            <StyledDynamicButton
                              buttonType='purple'
                              className={`${isInContentTopicMap && !value?.data?.isSelected ? 'disable-purple' : value?.data?.pageUuid ? 'view-content' : 'enable-purple'}`}
                              disabled={isInContentTopicMap && !value?.data?.isSelected}
                              onClick={() => openUrl(`/content/seo-content-assistant/${value?.data?.pageUuid}`, '_blank')}>
                              <FontAwesomeIcon icon={faCircleCheck} color={isInContentTopicMap && !value?.data?.isSelected ? '#A3A4A4' : '#7F4EAD'} fontSize={14} />View content
                            </StyledDynamicButton>
                          </div> :
                          <Dropdown trigger={['click']}
                            className='popover-dropdown'
                            disabled={!(topicalMap?.project === null || topicalMap?.project === 0 || topicalMap?.project) || (isInContentTopicMap && !value?.data?.isSelected)}
                            overlayStyle={{top: '974px', left: '972px'}}
                            overlay={<GenerateArticleMenu>
                              <Menu.Item className='menu-item'
                                onClick={() => {
                                  setSelectedArticlesToGenerate([{id: value?.data?.id, titleUuid: value?.data?.titleUuid}]);
                                  onGenerateArticle([{id: value?.data?.id, titleUuid: value?.data?.titleUuid}], true);
                                  checkIsNode = true;
                                  setTimeout(() => {
                                    checkIsNode = false;
                                  }, 2000);
                                }}>
                                <FontAwesomeIcon icon={faFileLines} color='#121212' fontSize={14}/>Full Article with AI
                              </Menu.Item>
                              <Menu.Item className='menu-item'
                                onClick={() => {
                                  setSelectedArticlesToGenerate([{id: value?.data?.id, titleUuid: value?.data?.titleUuid}]);
                                  onOutline(value?.data);
                                  checkIsNode = true;
                                  setTimeout(() => {
                                    checkIsNode = false;
                                  }, 2000);
                                }}><FontAwesomeIcon icon={faListTree} color='#121212' fontSize={14}/>Headings Outline with AI</Menu.Item>
                            </GenerateArticleMenu>}>
                            <Tooltip title={!(topicalMap?.project === null || topicalMap?.project === 0 || topicalMap?.project) && 'No project folder attached to this topical map'} overlayInnerStyle={{borderRadius: '10px'}}>
                              <StyledDynamicButton
                                disabled={!(topicalMap?.project === null || topicalMap?.project === 0 || topicalMap?.project) || (isInContentTopicMap && !value?.data?.isSelected)}
                                buttonType='purple'
                                className={`step-button ${!(topicalMap?.project === null || topicalMap?.project === 0 || topicalMap?.project) || (isInContentTopicMap && !value?.data?.isSelected) ? 'disable-purple' : 'enable-purple'}`}
                              >
                                <FontAwesomeIcon
                                  icon={faWandMagicSparkles}
                                  fontSize={16}
                                  color={!(topicalMap?.project === null || topicalMap?.project === 0 || topicalMap?.project) || (isInContentTopicMap && !value?.data?.isSelected) ? '#A3A4A4' : '#ffffff'}
                                />Create content
                              </StyledDynamicButton>
                            </Tooltip>
                          </Dropdown>
                      }
                    </div>
                    <div className='step'>
                      <div className={`step-label ${value?.data?.wpPublishedAtUrl ? value?.data?.isSelected ? 'selected color-black' : 'selected' : ''}`}>Step 3:</div>
                      {!isLoading && value?.data?.pageUuid && value?.data?.wpPublishedAtUrl ? (!isInContentTopicMap || value?.data?.isSelected) ? (
                        <OneClickPublisher getPopupContainer={() => wpContainerRef?.current || document.body} errorId={errorId} setErrorId={setErrorId} topicalMapName={value?.data?.wpPublishedAtUrl} titleUuid={value?.data?.titleUuid} uuid={value?.data?.pageUuid} isInContentTopicMap={isInContentTopicMap} setDataLoader={setDataLoader}/>
                      ) :
                        (
                          <StyledDynamicButton disabled buttonType='purple' className='disable-purple'>
                            <FontAwesomeIcon icon={faCircleCheck} fontSize={16} color='#A3A4A4'/>Published
                          </StyledDynamicButton>
                        ): (
                        <>
                          {
                            !isLoading && value?.data?.pageUuid && (!isInContentTopicMap || value?.data?.isSelected) ?
                              !wpArray?.length ? (
                                <StyledDynamicButton onClick={() => setShowInstructionsModal(true)} buttonType='purple' className={`step-button ${(!value?.data?.isSelected || !value?.data?.pageUuid) ? 'disable-purple' : 'enable-purple'}`}>
                                  <FontAwesomeIcon icon={faArrowUpFromLine} fontSize={16} color='#ffffff'/>Publish
                                </StyledDynamicButton>
                              ) : (
                                <OneClickPublisher getPopupContainer={() => wpContainerRef?.current || document.body} errorId={errorId} setErrorId={setErrorId} topicalMapName={value?.data?.wpPublishedAtUrl} titleUuid={value?.data?.titleUuid} uuid={value?.data?.pageUuid} isInContentTopicMap={isInContentTopicMap} setDataLoader={setDataLoader}/>
                              ) : (
                                <StyledDynamicButton disabled buttonType='purple' className={`step-button ${!(topicalMap?.project === null || topicalMap?.project === 0 || topicalMap?.project) || (isInContentTopicMap && !value?.data?.isSelected || !value?.data?.pageUuid) ? 'disable-purple' : 'enable-purple'}`}>
                                  <FontAwesomeIcon icon={faArrowUpFromLine} fontSize={16} color={!(topicalMap?.project === null || topicalMap?.project === 0 || topicalMap?.project) || (isInContentTopicMap && !value?.data?.isSelected || !value?.data?.pageUuid) ? '#A3A4A4' : '#ffffff'}/>Publish
                                </StyledDynamicButton>
                              )
                          }
                        </>
                      )}
                    </div>
                    <div className='step'>
                      <div className={`step-label ${value?.data?.wpPublishedAtUrl ? value?.data?.isSelected ? 'selected color-black' : 'selected' : ''}`}>Step 4:</div>
                      <StyledDynamicButton buttonType='purple' onClick={() => {
                        if (!isLoading && value?.data?.pageUuid && value?.data?.wpPublishedAtUrl) {
                          openUrl(value?.data?.wpPublishedAtUrl, '_blank');
                        } else {
                          return;
                        }
                      }}
                      disabled={!(!isLoading && value?.data?.pageUuid && value?.data?.wpPublishedAtUrl && (!isInContentTopicMap || value?.data.isSelected))}
                      className={`step-button ${!(!isLoading && value?.data?.pageUuid && value?.data?.wpPublishedAtUrl && (!isInContentTopicMap || value?.data.isSelected)) ? 'disable-purple' : 'view-content-wp'}`}
                      >
                        {!isLoading && value?.data?.pageUuid && value?.data?.wpPublishedAtUrl ? (<>
                          <FontAwesomeIcon icon={faArrowUpRightFromSquare} color={!(!isLoading && value?.data?.pageUuid && value?.data?.wpPublishedAtUrl && (!isInContentTopicMap || value?.data.isSelected)) ? '#A3A4A4' : '#fffff'} fontSize={14} />View in WP
                        </>) : (<>
                          <FontAwesomeIcon icon={faArrowUpRightFromSquare} color={!(!isLoading && value?.data?.pageUuid && value?.data?.wpPublishedAtUrl && (!isInContentTopicMap || value?.data.isSelected)) ? '#A3A4A4' : '#fff'} fontSize={14} />View in WP
                        </>)}
                      </StyledDynamicButton>
                    </div>
                  </StyledPopover>}
                ></Popover>
                <div style={{position: 'relative', width: isInContentTopicMap ? '100%' : '960px', height: isInContentTopicMap ? `${graphHeight}px` : '100%'}} id='linkgraph-chart'></div>
              </div>
            ) : <div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><StyledEmpty /></div>}
        </Col>
      </Row>
    </>
  );
};

const StyledCheckboxWrapper = styled.div<{checked?: boolean; isInContentTopicMap?: boolean}>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-right: 4px;
  height: ${p=> p.isInContentTopicMap ? '100%' : '100%'};
  margin-bottom: 20px !important;
  overflow: hidden;
  overflow-y: auto;

  .checkbox-wrapper, .checked-checkbox-wrapper {
    width: ${p=> p.isInContentTopicMap ? '97%' : '245px'} !important;
    font-size: 12px;
    padding: 8px 10px !important;
    height: auto !important;
    border-radius: 8px;
    background-color: #F9F9FB;
    border: 1px solid #E8E8E8;
    line-height: normal;
    .ant-checkbox:not(.ant-checkbox-checked) {
      display: none;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: transparent !important;
        border-color: transparent !important;
        border-radius: 0px !important;
        overflow: hidden;
      }
    .ant-checkbox-checked .ant-checkbox-inner::after {
      border-color: #121212;
    }
    ${p => p?.checked ? `
      border-color: #2E343F !important;
      background-color: #2E343F !important;
      border: 1px solid #2E343F;
    ` : ''}
    .ant-checkbox-inner {
      border-radius: 5px;
    }
  }
  .checked-checkbox-wrapper {
    background-color: #7F4EAD33;
    .label {
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      font-size: 12px;
      color: #121212;
    }
  }
  .ant-checkbox-checked::after {
    border-color: #fff;
    border-radius: 5px;
    display: none;
  }

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(138,138,138,0.32);
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(138,138,138,0.12);
    border-radius: 6px;
  }
  
`;

const CategoryFormItem = styled(Form.Item)`
  padding-bottom: 0px; 
  margin-bottom: 0px;
  height: 100%; 
  overflow: auto;

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(138,138,138,0.32);
  }

  &::-webkit-scrollbar-track {
    background: rgba(138,138,138,0.12);
  }
`;

const CategoryHeading = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #4E5156;
`;

const SelectAll = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  color: #2D6CCA;
  cursor: pointer;
`;

const ZoomButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  border: 1px solid #CDCDCD;
  padding: 5px 10px;
  border-radius: 8px;
`;

const ZoomNote = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  .text-note {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #121212;
    line-height: normal;
  }
`;
const StyledPopover = styled.div`
  .heading {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 8px;
    color: #121212;
  }
  .step {
    display: flex;
    align-items: center;
    gap: 13px;
    margin-top: 8px;

    .step-label {
      font-family: 'Inter', sans-serif;
      font-size: 12px;
      font-weight: 400;
      color: #A3A4A4;
      &.selected {
        text-decoration: line-through;
      }
      &.color-black {
        color: #121212 !important;
      }
    }
  }
  .note {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #121212;
    margin-top: 22px;
  }
`;

const StyledDynamicButton = styled(Button)`
  padding: 6px 10px !important;
  height: 30px;
  opacity: 1;
  &.disabled {
    pointer-events: all;
    cursor: not-allowed !important;
  }
  span {
    display: flex;
    align-items: center;
    gap: 6px;
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: normal !important;
  }
  &.unselected-topic-map {
    background-color: #2FAA52;
    &:hover {
      background-color: #155828;
    }
  }
  &.selected-topic-map {
    background-color: #ffffff;
    border: 1px solid #CDCDCD;
    border-radius: 8px;
    :hover {
      background-color: #e3e3e3;
    }
    span {
      display: flex;
      align-items: center;
      gap: 6px;
      font-family: 'Inter', sans-serif;
      font-size: 13px;
      font-weight: 400;
      color: #121212;
      line-height: normal !important;
    }
  }
  &.enable-purple {
    background-color: #7F4EAD;
    &:hover {
      opacity: 0.8;
      background: #7F4EAD !important;
      border: 1px solid #7f4ead !important;
    }
  }
  &.disable-purple {
    background-color: #F9F9FB;
    border: 1px solid #CDCDCD80;
    span {
      color: #A3A4A4;
    }
  }
  &.view-content {
    background-color: #FFFFFF;
    border: 1px solid #CDCDCD;
    span {
      color: #121212;
    }
  }
  &.view-content-wp {
    background-color: #7F4EAD;
    span {
      color: #FFFFFF;
    }
  }

`;
