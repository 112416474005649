import React from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '@/store/root-store';
// import {useRouter} from 'next/router';
import styled from 'styled-components';
import {toJS} from 'mobx';
import {Tooltip, Progress} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
interface Props {
  isDockedSider?: boolean;
}
export const SiteHealthWithProgress: React.FC<Props> = observer(({isDockedSider}) => {
  // const router = useRouter();

  const {
    siteAuditorV2: {loadingSiteHealthData, siteHealthIncrease, getSiteAuditSpacificData, healthState},
  } = useStore('');

  // const projectId = siteAuditSelectedProperty ? siteAuditSelectedProperty : (localStorage.getItem('lastPropertySiteAudit') || router.query?.id);
  const siteHealthData =getSiteAuditSpacificData?.siteHealthValues;

  const actual = siteHealthData?.actual ? siteHealthData?.actual : toJS(healthState)?.actual || 0;
  const total = siteHealthData?.total ? siteHealthData?.total : toJS(healthState)?.total || 0;
  const color = siteHealthData?.color ? siteHealthData?.color : toJS(healthState)?.color || '';
  const antIcon = <LoadingOutlined style={{fontSize: 16, color: '#7f4ead'}} spin />;

  return (
    <>
      <HealthProgress>
        <Tooltip color='#121212'
          title={'Health score is a way of measuring how well a website is doing in terms of SEO. A higher score means the website is doing better.'}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <div >
              <Progress type='circle'
                width={22}
                showInfo={false}
                strokeWidth={15}
                percent={actual/total*100 ?? 0}
                success={{percent: (actual + siteHealthIncrease)/total*100 - (actual)/total*100, strokeColor: '#F1AA3E'}}
                trailColor='#4E5156'
                strokeColor={color}
              />
            </div>
            {isDockedSider?
              <BorderLine>
                <div>
                  <SiteHealthDiv>Site Health</SiteHealthDiv>
                  <div style={{fontWeight: '500', color: '#FFFFFF', fontSize: 10}}> {actual} <span style={{color: '#E8E8E8', fontSize: 12, fontWeight: '400'}}>/ {total} </span>
                    <span style={{marginLeft: 5, marginTop: 3}}> {siteHealthData || toJS(healthState) || !loadingSiteHealthData ? '' : antIcon }</span>
                  </div>
                </div>
              </BorderLine>: ''}
          </div>
        </Tooltip>
      </HealthProgress>

    </>
  );
});

const HealthProgress = styled.div`
  padding-left:14px;
  display: flex;
  align-items: center;
  height: 50px;
  color: #A3A4A4;
  border: 1px solid transparent;

  &:hover {
    /* background: rgba(84, 86, 90, 0.17);
    border: 1px solid #4E5156 !important;
    border-radius: 5px; */
  }
`;

const SiteHealthDiv = styled.div`
font-size: 12px;
`;

const BorderLine = styled.div`
  padding: 0px 0px 0px 12px;
  white-space: nowrap;
  /* border-left: 1px solid #4e5156; */
 `;
