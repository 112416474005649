import {observer} from 'mobx-react';
import {AddKeywordsWrapper, StyledCloudStackInput} from '../../../../style';
import {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark} from '@fortawesome/pro-solid-svg-icons';
import {Button} from '@/components/common-components';

const AddKeywords = observer(({deployingProposedFix, onAddKeyword, record, setEditDescription}) => {
  const [keywords, setKeywords] = useState([]);
  const [proposedFix, setProposedFix] = useState('');
  const [keywordError, setKeywordError] = useState(false);
  const inputKeywordsCount = proposedFix.split(',').map(keyword => keyword.trim()).filter(keyword => keyword)?.length;

  const handleKeyPress = e => {
    if (e.key === 'Enter' && proposedFix.trim()) {
      const newKeywords = proposedFix.split(',').map(keyword => keyword.trim()).filter(keyword => keyword);
      setKeywords(prevKeywords => [...prevKeywords, ...newKeywords]);
      setProposedFix('');
      setKeywordError(false);
    }
  };

  const handleInputChange = e => {
    e.stopPropagation();
    e.preventDefault();
    setProposedFix(e?.target?.value);
    setKeywordError(false);
  };

  const handleRemoveKeyword = keywordToRemove => {
    setKeywords(prevKeywords => prevKeywords?.filter(keyword => keyword !== keywordToRemove));
  };

  const handleAddKeywords = async () => {
    if (!keywords.length && !proposedFix?.length) {
      setKeywordError(true);
      return;
    } else {
      setKeywordError(false);
    }
    await onAddKeyword(record, proposedFix, keywords);
    setProposedFix('');
    setKeywords([]);
  };

  return (
    <AddKeywordsWrapper style={{minWidth: 'auto'}}>
      <div className='inner-wrapper'>
        <div className='title'>Enter one or multiple keywords, separating them with commas</div>
        <div className='input-wrapper'>
          {keywords.length ? <div className='keywords-wrapper'>
            {keywords?.map(keyword => <div className='keyword' key={keyword}>
              {keyword}
              <FontAwesomeIcon icon={faXmark} color='#4E5156' fontSize={14} style={{cursor: 'pointer'}} onClick={() => handleRemoveKeyword(keyword)}/>
            </div>)}
          </div> : null}
          <StyledCloudStackInput
            className='input'
            autoFocus
            disabled={deployingProposedFix}
            style={{width: '100%'}}
            value={proposedFix}
            onChange={handleInputChange}
            onKeyDown={handleKeyPress}
            placeholder='Type here...'
          />
        </div>
        {keywordError ? <div className='keyword-error'>{proposedFix?.length ? 'Please press enter to add keywords' : 'Enter at least 1 keyword'}</div> : ''}
        <div className='buttons-wrapper'>
          <Button
            buttonType={'blue-button'}
            loading={deployingProposedFix}
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '32px',
              fontSize: '14px',
              padding: '0px 10px',
            }}
            onClick={() => handleAddKeywords()}
          >
            {!deployingProposedFix ? `Add ${keywords?.length+inputKeywordsCount > 0 ? keywords?.length+inputKeywordsCount : ''} keyword${keywords?.length+inputKeywordsCount > 1 ? 's' : ''}` : ''}
          </Button>
          <div className='cancel-button' onClick={() => {
            setEditDescription(-1);
            setProposedFix('');
            setKeywords([]);
            setKeywordError(false);
          }}>
          Cancel
          </div>
        </div>
      </div>
    </AddKeywordsWrapper>
  );
});

export default AddKeywords;
