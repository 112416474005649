import styled from 'styled-components';

export const WildfireWrapper = styled.div<{loading: boolean}>`
  text-align: ${p => p.loading ? 'center' : 'unset'};
  margin-top: ${p => p.loading ? '200px' : 'unset'};
  .table-header-wrapper {
    display: flex;
    margin-left: 5px;
    margin-bottom: 15px;
    gap: 8px;
    align-items: center;
    span {
      color: #2D6CCA;
      cursor: pointer;
    }
    .search {
      color: #A3A4A4;
      cursor: pointer;
    }
    .icon {
      cursor: pointer;
    }
    .loader {
      color: #2D6CCA;
    }
    .ant-input {
      width: 35%;
      border-radius: 8px;
    }
    .table-heading {
      font-weight: 500;
    }
  }
  .page-cell {
    color: #2D6CCA;
    cursor: pointer;
  }
  .ant-table-thead th {
    font-weight: 600;
    font-size: 12px;
  }
  .ant-table-tbody td {
    font-size: 13px;
  }
  .ant-table {
    margin-bottom: 5px;
  }

  .ant-table-content::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  .ant-table-content::-webkit-scrollbar-thumb {
    background: rgba(138,138,138,0.32);
  }

  .ant-table-content::-webkit-scrollbar-track {
    background: rgba(138,138,138,0.12);
  }
  .section {
    margin-bottom: 15px;
    border: 1px solid #E8E8E8;
    border-radius: 8px;
    padding: 15px;
    background: white;
  }
  .ant-table-header{
    overflow: visible !important;
  }

  .not-available {
    display: flex;
    gap: 5px;
    align-items: center;
    .not-available-text {
      width: 310px;
    }
    .ratio {
      width: 56px;
      background: #fffbeb;
      color: #c9854f;
      font-size: 12px;
    }
  }
  .add-more {
    margin-left: auto;
  }
  .ant-pagination-total-text {
    color: #a3a4a4;
  }
  .ant-pagination-item,
  .ant-pagination-prev,
  .ant-pagination-next {
    height: 25px;
    min-width: 25px !important;
    display: inline-flex;
    font-size: 12px;
    font-weight: 500;
    justify-content: center;
    line-height: 27px;
    color: black;
    border: 1px solid #e8e8e8;
    border-radius: 3px;
    background: #f7f7f8;
  }
  .ant-pagination-item:hover,
  .ant-pagination-prev:hover,
  .ant-pagination-next:hover { 
    color: black;
    border: 1px solid #e8e8e8;
    border-radius: 3px;
  }
  .ant-pagination-item:hover a {
    color: black;
  }
  .ant-pagination-item-active a,
  .ant-pagination-item-active,
  .ant-pagination-item-active::selection {
    background: black !important;
    color: white !important;
    border-radius: 3px;
  }
`;
