import {getApiUrl, getAuthorizationHeader} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';

class AuthApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.LINKGRAPH_ENDPOINT, '/api/token/');
  private static readonly baseUrlRegister: string = getApiUrl(BaseApi.LINKGRAPH_ENDPOINT, '/api/customer/account/register/v2/');
  private static readonly baseUrlRegisterEmail: string = getApiUrl(BaseApi.LINKGRAPH_ENDPOINT, '/api/customer/account/team/invitations/');
  private static readonly baseUrlGoogleOAuthRegistration: string = getApiUrl(BaseApi.LINKGRAPH_ENDPOINT, '/api/customer/account/register/google/oauth2/');

  public async login(email: string, password: string) {
    const response = await this.axiosWithoutAuth.post(AuthApi.baseUrl, {
      username: email,
      password: password,
    });
    return response.data;
  }

  public getGoogleOAuthUrl(successfulUrl=null) {
    const path = '/account/google/oauth/flow/start';
    if (successfulUrl) {
      return getApiUrl(BaseApi.LINKGRAPH_ENDPOINT, `${path}?completedUrl=/${successfulUrl}/`);
    }
    return getApiUrl(BaseApi.LINKGRAPH_ENDPOINT, path);
  }

  public async register(payload: any) {
    const urlParams = new URLSearchParams(window.location.search);
    const gclid = urlParams.get('gclid');
    const params = gclid ? {gclid} : {};
    const inviteCode = urlParams.get('inviteCode');
    if (inviteCode) {
      payload['invite_code'] = inviteCode;
    }
    try {
      const response = await this.axiosWithoutAuth.post(AuthApi.baseUrlRegister, payload, {params});
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async loadRegisterEmail(code: any) {
    const response = await this.axiosWithoutAuth.get(`${AuthApi.baseUrlRegisterEmail}${code}/`);
    return response;
  }

  public async updateRegister(payload: any) {
    try {
      const response = await this.axiosWithoutAuth.put(AuthApi.baseUrlRegister,
        payload,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        },
      );
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async validateGoogleOAuthResponseAndLogin(payload: any) {
    try {
      const response = await this.axiosWithoutAuth.post(
        AuthApi.baseUrlGoogleOAuthRegistration,
        payload,
      );
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async updateRegisterData(payload: any, jwtToken) {
    try {
      const response = await this.axios.put(AuthApi.baseUrlRegister, payload, {
        headers: {
          'Authorization': `Bearer ${jwtToken}`,
        },
      });
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

export default AuthApi;
