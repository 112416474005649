import React from 'react';
import COUNTRY_CODES from '@/constants/countryCodes';
import {Globe} from 'react-feather';
import {Select as AntdSelect, SelectProps, Tooltip} from 'antd';
import styled from 'styled-components';
import {COLORS} from './colors';
import {useTranslation} from 'next-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import {countryFlag} from '@/utils/countries';
import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react';

const {Option} = AntdSelect;

interface Props extends SelectProps<any>{
  variant?: 'light' | 'dark' | 'default' | 'grey' | 'lightGray' | 'disabled';
  countriesToInclude?: string[];
  notAvailableCountries?: string[];
  notAvailableCountryTooltipText?: string;
  countriesToExclude?: string[];
  isReport?: boolean;
  width?: string;
  showWorldWide?:boolean;
  showCountryCodes?:boolean;
  isDocked?:boolean;
  aligned?:boolean;
  isNewEmailSettings?: boolean;
  isProjectCreation?: boolean;
  showSearch?: boolean;
  isReportBuilder?: boolean;
  isReportBuilderV2?: boolean;
  smallDropdown?: boolean;
  customIcon?: boolean;
  isSE?: boolean;
  worldwideTooltipTxt?: string;
  gscConnectModal?: boolean;
  height?: string;
  }

export const CountryDropdown = observer(({
  countriesToInclude,
  notAvailableCountries,
  notAvailableCountryTooltipText,
  showSearch,
  aligned,
  isProjectCreation,
  isDocked,
  showWorldWide=true,
  countriesToExclude,
  isReport = false,
  variant = 'light',
  showCountryCodes = false,
  smallDropdown = false,
  customIcon = false,
  worldwideTooltipTxt,
  gscConnectModal=false,
  ...props}: Props) => {
  const {countries: {filteredCountries, countriesLoading},
    settings: {customer: {profile: {address}}}} = useStore('');
  const {t} = useTranslation('common');
  const getCountriesList = () => {
    if (countriesToInclude?.length) {
      return countriesToInclude?.slice();
    } else if (countriesToExclude?.length) {
      return COUNTRY_CODES.filter(i => !countriesToExclude.includes(i));
    } else {
      return COUNTRY_CODES;
    }
  };

  const countriesList = filteredCountries(getCountriesList()).sort((a, b) => a.name?.toLowerCase().localeCompare(b.name?.toLowerCase()));
  return (!countriesLoading ?
    <StyledSelect
      {...props}
      defaultValue={props?.isNewEmailSettings ? '' : (address?.defaultLocation ?? 'US')}
      variant={variant}
      value={props?.value?.toLowerCase() === 'uk' ? 'GB' : props.value}
      showSearch={isDocked || showSearch}
      suffixIcon={customIcon ? <FontAwesomeIcon icon={faCaretDown} style={{paddingLeft: 10}}/>:
        smallDropdown ? <FontAwesomeIcon icon={faCaretDown} style={{paddingLeft: 10}}/> : isDocked || props?.isNewEmailSettings || isProjectCreation && <FontAwesomeIcon color={`${variant=='lightGray' ? '#2D2F34': props?.isNewEmailSettings || isProjectCreation ? '#121212' : '#fff'}`} icon={faCaretDown}/>}
      dropdownStyle={{backgroundColor: variant === 'dark' ? COLORS.grayDark : variant === 'default' ? COLORS.grayDark: ''}}
      filterOption={(input, option) =>
        option?.country?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      isDocked={isDocked}
      aligned={aligned}
      smallDropdown={smallDropdown}
      customIcon={customIcon}
    >
      {(!isReport && showWorldWide) || gscConnectModal ? <Option style={{backgroundColor: variant === 'dark' ? COLORS.grayDark : variant === 'default' ? COLORS.grayDark : COLORS.white}} value={''} country={'WorldWide'}>
        <Tooltip title={worldwideTooltipTxt ? worldwideTooltipTxt : ''} placement='bottom' overlayInnerStyle={{backgroundColor: '#000000', borderRadius: '12px'}}><GlobeContainer variant={variant} ><StyledGlobe />{smallDropdown ? 'WW' : t('worldwide')}</GlobeContainer></Tooltip></Option> : ''}
      {!isReport ? <Option style={{backgroundColor: variant === 'dark' ? COLORS.grayDark : variant === 'default' ? COLORS.grayDark: COLORS.white}} key={address?.defaultLocation ?? 'US'} country={address?.countryName ?? 'United States'} value={address?.defaultLocation ?? 'US'}>
        <Tooltip title={showCountryCodes ? address?.defaultLocation ?? 'US' : address?.countryName ?? 'United States'} mouseEnterDelay={.6} placement='right'>
          <OptionContent smallDropdown={smallDropdown} isNewEmailSettings={props?.isNewEmailSettings} variant={variant}>
            {countryFlag(address?.defaultLocation ?? 'US')}&nbsp; {smallDropdown ? address?.defaultLocation ?? 'US' : showCountryCodes ? address?.defaultLocation ?? 'US' : address?.countryName ?? 'United States'}
          </OptionContent>
        </Tooltip>
      </Option> : ''}
      {!smallDropdown && !isReport ? <Option style={{backgroundColor: variant === 'dark' ? COLORS.grayDark : variant === 'default' ? COLORS.grayDark: COLORS.white}} value={null} disabled><OptionContent smallDropdown={smallDropdown} variant={variant}><hr/></OptionContent></Option> : ''}
      {
        countriesList.map( item => {
          return (item.code !== (address?.defaultLocation ?? 'US') || isReport) ? (<Option
            disabled={notAvailableCountries?.includes(item?.code)} style={{backgroundColor: variant === 'dark' ? COLORS.grayDark : variant === 'default' ? COLORS.grayDark: COLORS.white}} key={item.code} country={showCountryCodes ? item.code : item.name} value={item?.code}>
            <Tooltip title={notAvailableCountries?.includes(item?.code) ? notAvailableCountryTooltipText : (isDocked && (showCountryCodes ? item?.code : item?.name))} mouseEnterDelay={.6} placement='right'>
              {item?.code ? <OptionContent notAvailable={notAvailableCountries?.includes(item?.code)} smallDropdown={smallDropdown} isReportBuilder={props?.isReportBuilder} variant={variant}>
                {countryFlag(item?.code, '24px')}&nbsp;
                <span>{smallDropdown ? item?.code : showCountryCodes ? item?.code : item?.name}</span>&nbsp;
              </OptionContent> :
                <OptionContent smallDropdown={smallDropdown} isDocked={isDocked} style={{display: 'flex', alignItems: 'center'}} variant={variant}><Globe style={{width: '24px', marginRight: 5}}/> <span>{'Worldwide'}</span></OptionContent>}
            </Tooltip>
          </Option>) : '';
        })
      }
    </StyledSelect> :
    null);
});
const variantColors = {
  light: {
    color: COLORS.black,
    backgroundColor: COLORS.white,
    borderColor: COLORS.grayDark,

  },

  default: {
    color: COLORS.white,
    backgroundColor: 'transparent',
    borderColor: 'transparent',

  },
  grey: {
    color: COLORS.black,
    backgroundColor: COLORS.white,
    borderColor: COLORS.textGrayLight,
  },
  lightGray: {
    color: COLORS.black,
    backgroundColor: COLORS.tableGray,
    borderColor: COLORS.textGrayLight,
  },
  dark: {
    color: COLORS.white,
    backgroundColor: COLORS.grayDark,
    borderColor: COLORS.grayDark,
  },
  disabled: {
    color: '#40404040',
    backgroundColor: '#f2f2f5',
    borderColor: '#f2f2f5',
  },
};

const StyledSelect = styled(AntdSelect)<Props>`
  color:${ p => variantColors[p.variant].color};
  .ant-select-selector {
    max-width: 100% !important;
    min-width: 76px !important;
    width: ${p => p?.smallDropdown ? '82px' : p.width ? p.width : '168px'} !important;
    margin-left:${p => p?.isReportBuilder ? '0px' : p.isDocked ? '6px' : p?.isSE ? '0' : p.aligned ? '5px' :'-12px'};
    margin-top:${p => p.isDocked ? '0px' :p.aligned ? '0px' : p?.isNewEmailSettings ? '0px' : '10px'};
    border-radius: ${p => p?.isReportBuilderV2 ? '10px' : '5px'} !important;
    background-color: ${ p => variantColors[p.variant].backgroundColor} !important;
    border-color: ${p => p.customIcon ? 'rgba(232, 232, 232, 1)' : p?.smallDropdown ? '#CDCDCD' : (p?.isNewEmailSettings || p?.isReportBuilder) ? '1px solid #E8E8E8' : variantColors[p.variant].borderColor} !important;
    padding: ${p=> p?.smallDropdown ? '' : p?.isNewEmailSettings ? '0px 10px' : '6px 15px'} !important;
    /* padding: 6px 15px !important; */
    height:${p=> p?.height ? p?.height : p?.smallDropdown ? '28px' : p?.isReportBuilder ? '38px' : p?.isNewEmailSettings ? '28px' : '44px'} !important;
    /* height: 44px !important; */
    cursor: pointer !important;
    line-height: unset !important;
  }
  .ant-select-selection-search {
    padding: 6px 0;
  }

  @media only screen and (max-width: 558px) {
    .ant-select-selector {
      max-width: 100% !important;
      width: 100% !important;
      margin-left: 0px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  };

 .ant-select-arrow {
  color:${ p => variantColors[p.variant].color};
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    background-color: ${ p => variantColors[p.variant].backgroundColor} !important;
    border-color: ${ p => variantColors[p.variant].borderColor} !important
  }
`;

const GlobeContainer=styled.div<{variant: string}>`
  color:${ p => variantColors[p.variant].color};
  background-color: ${ p => variantColors[p.variant].backgroundColor} !important;
  padding:0px;
  align-items: center;
  display: flex;
`;
const StyledGlobe=styled(Globe)`
  padding-right: 3px;
  width: 20px;
`;

const OptionContent = styled.div<{variant: string;isDocked?:boolean; isNewEmailSettings?: boolean; isReportBuilder?: boolean; smallDropdown?: boolean; notAvailable?: boolean}>`
  background-color: ${ p => variantColors[p.variant].backgroundColor} !important;
  border-color: ${ p => variantColors[p.variant].borderColor} !important;
  color:${ p => p?.notAvailable ? 'grey' : variantColors[p.variant].color} !important;
  max-width: ${p=> p?.isReportBuilder ? '300px' : '140px'};
  height: 100%;
  ${p=> p?.isNewEmailSettings ? ` display: flex;
  align-items: center;` : ''}
  /* width: ${p => p.isDocked ? '40px' : 'unset'} !important; */
  line-height: ${p => p?.smallDropdown ? '20px' : ''};
  font-size: ${p => p?.smallDropdown ? '14px' : ''};
  ${p => p?.smallDropdown ? 'display: flex; align-items: center;' : ''};

  overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &:active,focus{
      background-color: ${ p => variantColors[p.variant].backgroundColor} !important;
      border-color: ${ p => variantColors[p.variant].borderColor} !important;
      color:${ p => variantColors[p.variant].color} !important;
    }
`;
