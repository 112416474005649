import React, {useState} from 'react';
import styled from 'styled-components';
import {observer} from 'mobx-react-lite';
import {Progress} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCodeCompare, faFileChartColumn, faGridRound2Plus, faHeartCirclePlus, faMapLocationDot, faPenToSquare, faUserGroup} from '@fortawesome/pro-light-svg-icons';
import {faCircleCheck} from '@fortawesome/pro-solid-svg-icons';
import {useStore} from '@/store/root-store';
import {NextImg} from '@/utils/nextImg';
import {faChevronRight} from '@fortawesome/pro-regular-svg-icons';
import {openUrl} from '@/utils/router';
import {routes} from '@/utils/const';
import {useRouter} from 'next/router';
import {notification} from '@/utils/notification-v2';
import {apiError} from '@/utils/api';
import {StyledSpin} from '@/components/dashboard/pages/otto-page-detail/Utils/styledSpin';

const iconMapping = {
  'Create a Heatmap': faMapLocationDot,
  'Create Content in Content Genius': faPenToSquare,
  'Add a team member': faUserGroup,
  'Install OTTO SEO Pixel': faCodeCompare,
  'Install OTTO': faCodeCompare,
  'Connect Your Accounts': faGridRound2Plus,
  'Create a Project': faFileChartColumn,
  'Join the Daily Onboarding Call': faHeartCirclePlus,
};

const videosList = [
  {titleBackground: '', titleText: 'New', section: 'new', title: 'Introducing Search Atlas and OTTO: The AI-Powered SEO Revolution', imgPath: 'introducing-search-atlas-and-otto-the-ai-powered-seo-revolution.jpg', src: `https://www.youtube.com/embed/pq2rIJ9cjnY?si=1rnZ4b24amoKqZTG&autoplay=1&mute=1`, link: ``},
  {titleBackground: '', titleText: 'New', section: 'new', title: 'How to Use OTTO\'s AI-Driven SEO Automation', imgPath: 'how-to-use-ottos-ai-driven-seo-automation.jpg', src: `https://www.youtube.com/embed/sU_9CBBnZ9c?si=-DMlxfPrPy3f2rEV&autoplay=1&mute=1`, link: ``},
  {titleBackground: '', titleText: 'New', section: 'new', title: 'How to Use White Label Solutions in Search Atlas', imgPath: 'how-to-use-white-label-solutions-in-search-atlas.jpg', src: `https://www.youtube.com/embed/5QlYTNanJlk?si=9WbwwJ3OUCFHZmzz&autoplay=1&mute=1`, link: ``},
  {titleBackground: '', titleText: 'New', section: 'new', title: 'How to Setup the Holy Trinity: Website, Local SEO, and OTTO Projects', imgPath: 'how-to-setup-the-holy-trinity-website-local-seo-and-otto-projects.jpg', src: `https://www.youtube.com/embed/O7Mhhcyrbwc?si=7eSQlgfg8dPjWBnK&autoplay=1&mute=1`, link: ``},
];

interface Props {
  notifications: any[];
  setVisibleOnboarding: React.Dispatch<React.SetStateAction<boolean>>;
}

export const OnBoardingPopup = observer(({notifications, setVisibleOnboarding}: Props) => {
  const {
    settings: {customer: {loadUserOnboardingData, updateOnBoardingNotificationData},
    }} = useStore('');
  const router = useRouter();

  const [loading, setLoading] = useState(-1);

  const updateStatus = async id => {
    setLoading(id);
    try {
      await updateOnBoardingNotificationData(id, {
        status: 'read',
      });
      await loadUserOnboardingData({
        channels: 'onboarding',
      });
      setLoading(-1);
    } catch (e) {
      setLoading(-1);
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage);
    }
  };

  return (
    <Wrapper>
      <Header>
        <Status>
          <CompletedCount>{notifications.filter(notification => notification?.status === 'read').length}</CompletedCount>
          <div>of {notifications?.length} tasks completed</div>
        </Status>
        <Heading>Your Setup Checklist: Let’s Get You Ranking</Heading>
        <ProgressWrapper>
          <Progress percent={(notifications.filter(notification => notification?.status === 'read').length/notifications?.length)*100} showInfo={false} strokeColor={'#7F4EAD'} trailColor={'rgba(232, 232, 232, 1)'}/>
        </ProgressWrapper>
      </Header>
      <BodyWrapper>
        {notifications.map((item, idx) => (
          <Item key={idx} className={item?.status === 'read' ? 'completed' : 'in-completed'}>
            <ItemLeft>
              <FontAwesomeIcon
                className={item?.status === 'read' ? 'completed-icon' : 'in-completed-icon'}
                icon={iconMapping[item.title]}
                color='#121212'
                fontSize={16}
              />
              <ItemTitle
                className={`${item?.status === 'read' ? 'completed-title' : 'not-completed-title'}`}
                onClick={() => {
                  if (item?.status !== 'read') {
                    setVisibleOnboarding(false);
                    const actionUrl = item?.action?.url + `${item?.action?.url?.includes('?') ? '&' : '?'}ref=onboarding_tasklist`;
                    if (item.title == 'Join the onboarding call') {
                      window.open(actionUrl, '_blank');
                    } else {
                      router.push(actionUrl);
                    }
                  }
                }}
              >
                {item.title}
              </ItemTitle>
            </ItemLeft>
            <ItemButton className={`${item.status === 'read' && 'done-button'}`} onClick={()=>loading === -1 && item.status !== 'read' && updateStatus(item.id)}>
              {
                loading === item.id ? <div style={{width: 100, display: 'flex', justifyContent: 'center'}}><StyledSpin color='#7f4ead'/></div> :
                  <>
                    <FontAwesomeIcon
                      className={`${item.status === 'unread' && 'unread-hover-icon'}`}
                      icon={faCircleCheck}
                      fontSize={14}
                      color={`${item.status === 'read' ? '#FFFFFF' : '#4E5156'}`}
                    />
                    {item.status === 'read' ? 'Done' : 'Mark as Done'}
                  </>
              }
            </ItemButton>
          </Item>
        ))}
      </BodyWrapper>
      <FooterWrapper>
        <div className='footer-heading'>Check out our Training Course!</div>
        <Status style={{gap: 8}}>
          {videosList.map((item, index) => {
            return <NextImg key={index} width={65} height={40} src={`/img/${item?.imgPath}`}
              onClick={()=>openUrl(item.src, '_blank')}
              style={{borderRadius: '5px', cursor: 'pointer'}} />;
          })}
          <div style={{width: 65, textAlign: 'center'}}>
            <FontAwesomeIcon
              icon={faChevronRight}
              fontSize={14}
              color={'rgba(163, 164, 164, 1)'}
              onClick={()=> {
                setVisibleOnboarding(false);
                router.push(routes?.tutorials);
              }}
              style={{cursor: 'pointer'}}
            />
          </div>
        </Status>
      </FooterWrapper>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  padding-top: 22px;
`;
const Header = styled.div`
  margin-bottom: 8px;
  padding: 0px 25px;
`;

const Status = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 12px;
`;

const CompletedCount = styled.div`
  color: #7f4ead;
  font-weight: 600;
`;

const Heading = styled.h2`
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: #121212;
  margin-bottom: 10px;
  width: 214px;
  line-height: 24.2px;
`;

const ProgressWrapper = styled.div``;

const BodyWrapper = styled.div`
  width: 100%;
  padding: 0px 10px;
  margin-bottom: 14px;
  max-height: 334px;
  overflow: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #4E515680;
    border-radius: 5px;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px;
  border-radius: 10px;
  width: 380px;
  border: 1px solid #F9F9FB;
  margin-bottom: 4px;
  &.in-completed {
    cursor: pointer;
    &:hover {
      box-shadow: 1px 2px 2px 1px #0000000d;
      border-color:#d2d2d2;
      background-color: #ffffff;
    }
  }
`;

const ItemLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  .completed-icon {
    color: #A3A4A4;
  }
  &:hover {
    .in-completed-icon {
      color: #7f4ead;
    }
    .not-completed-title {
      font-family: 500;
      color: #7f4ead;
      text-decoration: underline;
    }
  }
`;

const ItemTitle = styled.div`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #4e5156;
  max-width: 200px;
  &.completed-title {
    color: #A3A4A4;
    text-decoration: line-through;
    cursor: not-allowed;
  }
`;

const ItemButton = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 6px;
  padding: 4px 5px;
  box-shadow: 0px 3px 2px 0px #00000005;
  font-family: 'Inter';
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  background: #4e51561a;
  color: #4e5156;
  
  &:hover {
    background: linear-gradient(85.22deg, rgba(123, 66, 202, 0.2) 0%, rgba(162, 90, 209, 0.2) 100%);
    color: #7f4ead;

    .unread-hover-icon {
      color: #7f4ead;
    }
  }

  &.done-button {
    background: linear-gradient(85.22deg, #7b42ca 0%, #a25ad1 100%);
    color: #ffffff;
    cursor: not-allowed;
  }
`;
const FooterWrapper = styled.div`
  padding: 16px 24px;
  background-color: #F2F2F5;
  border: 1px solid #E8E8E8;
  .footer-heading {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 14.52px;
    margin-bottom: 10px;
    color: #4E5156;
  }
`;
