import React, {useState} from 'react';
import Dragger from 'antd/lib/upload/Dragger';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import {faXmark, faTrashCan} from '@fortawesome/pro-regular-svg-icons';
import {Button, Divider, Modal} from 'antd';
import {notification} from '@/utils/notifications';
import {faCloudArrowUp} from '@fortawesome/pro-solid-svg-icons';
import {Form} from 'antd';
// import {faTrash} from '@fortawesome/pro-regular-svg-icons';
// import UrlField from './videoUrlField';
interface Props {
  setImages: any;
  images: any;
  hideExtra?: boolean;
  width?: string;
  types?: any;
  showImageType?: any;
  // youtubeUrlError?: any;
  // setYoutubeUrlError?: (value: any) => void;
}

const ImagePicker = ({setImages, images, hideExtra, width, types, showImageType,
  // youtubeUrlError,
  // setYoutubeUrlError
}: Props) => {
  const [modalConfig, setModalConfig] = useState({
    visible: false,
    imageSource: '',
  });

  const [selectedImgIndex, setSelectedImgIndex] = useState(null);
  const getImageSrc = imageUrl => {
    let src;
    if (typeof imageUrl === 'string') {
      src = imageUrl;
    } else if (imageUrl instanceof Blob || imageUrl instanceof File) {
      src = URL?.createObjectURL(imageUrl);
    } else {
      src = '';
    }
    return src;
  };

  const handleImageDelete = (type, url='') => {
    setImages(prev => {
      const updatedImages = {...prev};
      type=='PROFILE' ? updatedImages.PROFILE=null : type=='COVER' ? updatedImages.COVER=null : updatedImages.ADDITIONAL=updatedImages?.ADDITIONAL?.filter(item => item?.url != url);
      return updatedImages;
    });
  };

  // const handleYoutubeUrlDeletion = i => {
  //   setImages(prev => {
  //     const updatedImages = {...prev};
  //     updatedImages.ADDITIONAL = updatedImages?.ADDITIONAL?.filter((image, index) => index!==i);
  //     setYoutubeUrlError(prevErrors => prevErrors?.filter(index => i !== index));
  //     return updatedImages;
  //   });
  // };

  const [dropTriggered, setDropTriggered] = useState(false);

  const createDraggerProps = type => ({
    name: 'file',
    multiple: type === 'ADDITIONAL',
    accept: 'image/png, image/jpg, image/jpeg',
    showUploadList: false,
    customRequest: async data => {
      if (!dropTriggered) {
        const {file} = data;
        if (file) {
          if (
            file.type === 'image/png' ||
          file.type === 'image/jpg' ||
          file.type === 'image/jpeg'
          ) {
            handleImageUpload(file, type);
          } else {
            notification.error('Acceptable file formats: JPEG, PNG, JPG, MP4, MKV. Please try again.', 'topRight');
          }
        }
      }
    },
    onDrop: async e => {
      setDropTriggered(true);
      e.preventDefault();
      const files = e.dataTransfer.files;
      if (files.length) {
        for (const file of files) {
          if (
            file.type === 'image/png' ||
          file.type === 'image/jpg' ||
          file.type === 'image/jpeg'
          ) {
            await handleImageUpload(file, type);
          } else {
            notification.error('Acceptable file formats: JPEG, PNG, JPG. Please try again.', 'topRight');
          }
        }
      }
    },
  });

  const handleImageUpload = (file, type) => {
    const MAX_SIZE_BYTES = 5000000;
    if (file.size > MAX_SIZE_BYTES) {
      return notification.error('Image(s) size must be upto 5MB', 'topRight');
    } else if (file.size < 10241) {
      return notification.error('Image(s) size must be greater than 10.5kB', 'topRight');
    }
    setImages(prev => {
      const newItem = {url: file};
      const updatedImages = {...prev};
      type == 'PROFILE' ? updatedImages.PROFILE = newItem : type == 'COVER' ? updatedImages.COVER = newItem : !(updatedImages.ADDITIONAL?.find((img, index) => index==selectedImgIndex)) ? updatedImages.ADDITIONAL = (prev?.ADDITIONAL ? [...prev.ADDITIONAL, newItem] : [newItem]) : updatedImages.ADDITIONAL[selectedImgIndex] = newItem;
      return updatedImages;
    });
  };

  // const urlRegex = /^(https?:\/\/)?((www\.)?(youtube\.com|youtu\.be)(\/.*)?|[A-Za-z0-9.-]+\.[A-Za-z]{2,6}\/.+)$/;

  // const [showError, setShowError] = useState(false);
  // const handleChange = (value, index) => {
  //   const urlExists = images.ADDITIONAL.some((item, idx) => item.url === value && idx !== index);
  //   if (urlExists) {
  //     setYoutubeUrlError(prevErrors => prevErrors ? [...prevErrors, index] : [index]);
  //     setShowError(true);
  //     return false;
  //   }
  //   if (urlRegex.test(value)) {
  //     setShowError(false);
  //     youtubeUrlError?.includes(index) && setYoutubeUrlError(prevErrors => prevErrors?.filter(i => i !== index));
  //     setImages(prev => {
  //       const item = {
  //         url: value,
  //         format: 'VIDEO',
  //       };
  //       const updatedImages = {...prev};
  //       updatedImages.ADDITIONAL[index] = item;
  //       return updatedImages;
  //     });
  //   } else {
  //     setShowError(false);
  //     setYoutubeUrlError(prevErrors => prevErrors ? [...prevErrors, index] : [index]);
  //   }
  // };

  // const handleAddField = () => {
  //   setImages(prev => {
  //     const item = {
  //       url: '',
  //       format: 'VIDEO',
  //     };
  //     const updatedImages = {...prev};
  //     updatedImages.ADDITIONAL = (prev?.ADDITIONAL ? [...prev.ADDITIONAL, item] : [item]);
  //     return updatedImages;
  //   });
  // };

  return (
    <div>
      <InputContainer>
        {types?.map((type, index) => {
          if (type === 'ADDITIONAL' && !showImageType) {
            return <>
              {images?.ADDITIONAL && images?.ADDITIONAL.map((item, itemIndex) => (
                (!item.format || item.format!='VIDEO') && <div
                  key={itemIndex}
                  style={{width: width || '280px', position: 'relative'}}
                >
                  <ImageContainer style={{height: 280, cursor: 'pointer'}} width={width ?? '280px'}>
                    <img
                      id='selected-image'
                      alt='not found'
                      src={getImageSrc(item?.url)}
                      style={{width: '100%', height: '100%', objectFit: 'cover'}}
                      onClick={e => {
                        e.preventDefault();
                        const src = getImageSrc(item?.url);
                        setModalConfig({visible: true, imageSource: src});
                      }}
                    />
                    <Button
                      style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        backgroundColor: '#12121299',
                        border: 'none',
                        borderRadius: '50%',
                        padding: '8px',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 46,
                        height: 46,
                      }}
                      onClick={() => {
                        handleImageDelete(type, item?.url);
                      }}
                    >
                      <FontAwesomeIcon fontSize='20px' color={'white'} icon={faTrashCan} />
                    </Button>
                  </ImageContainer>
                </div>
              ))}
              <StyledDragger key={'ADD-PHOTO'} className='dragger-container' {...createDraggerProps(type)} style={{width: width || '280px', height: 280}}>
                <div onClick={() => {
                  setDropTriggered(false);
                  setSelectedImgIndex(null);
                }} style={{display: 'flex'}}>
                  <DragRightContainer>
                    <DragimageContainer style={{height: 280}} width={width ?? '280px'}>
                      <div className='upload-icon'>
                        <img
                          src={
                            type === 'PROFILE' ?
                              '/img/logoDefaultImg.png' :
                              type === 'COVER' ?
                                '/img/coverDefaultImg.png' :
                                '/img/photosDefaultImg.png'
                          }
                          alt='default'
                        />
                      </div>
                      <Divider style={{backgroundColor: '#D8D8D8', height: 1}}/>
                      <DragText className='drag-text'>
                        <div style={{color: '#121212', fontWeight: 500}}>
                          {type === 'PROFILE' ?
                            'Add a Logo' :
                            type === 'COVER' ?
                              'Add a Cover Photo' :
                              'Add a Photo'}
                        </div>
                        <div style={{color: '#4E5156', fontSize: 12}}>
                          {type === 'PROFILE' ?
                            'Drop your logo here, or:' :
                            type === 'COVER' ?
                              'Drop your cover photo here, or:' :
                              'Drag & drop your photo(s) here to upload them, or'}
                        </div>
                        <div style={{color: '#2D6CCA', fontSize: 12, display: 'inline-block'}}>
                          <FontAwesomeIcon color='#2D6CCA' style={{marginRight: 6}} fontSize={16} icon={faCloudArrowUp} />
                          {type === 'PROFILE' ?
                            'Select logo' :
                            type === 'COVER' ?
                              'Select cover photo' :
                              'Select file(s)'}
                        </div>
                      </DragText>
                    </DragimageContainer>
                  </DragRightContainer>
                </div>
                <div className='overlay' />
              </StyledDragger>
            </>;
          }
          if (!hideExtra && ['PROFILE', 'COVER'].includes(type)) {
            const imageUrl = type === 'PROFILE' ? images?.PROFILE?.url : images?.COVER?.url;
            if (imageUrl) {
              return (
                <div
                  key={index}
                  style={{width: width || '280px', position: 'relative'}}
                >
                  <ImageContainer style={{height: '280px'}} width={width ?? '280px'}>
                    <img
                      id='selected-image'
                      alt='not found'
                      src={getImageSrc(imageUrl)}
                      style={{width: '100%', height: '100%', objectFit: 'cover'}}
                      onClick={e => {
                        e.preventDefault();
                        const src = getImageSrc(imageUrl);
                        setModalConfig({visible: true, imageSource: src});
                      }}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        backgroundColor: '#12121299',
                        border: 'none',
                        borderRadius: '50%',
                        padding: '8px',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 46,
                        height: 46,
                      }}
                      onClick={() => handleImageDelete(type)}
                    >
                      <FontAwesomeIcon fontSize='20px' color={'white'} icon={faTrashCan} />
                    </div>
                    <Button
                      style={{
                        position: 'absolute',
                        bottom: '10px',
                        left: '10px',
                        backgroundColor: '#121212B2',
                        color: 'white',
                        border: 'none',
                        borderRadius: '90px',
                        padding: '10px 20px',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Dragger {...createDraggerProps(type)}>
                        <span style={{color: 'white'}}>
                          {type === 'PROFILE' ? 'Change logo' : 'Change cover'}
                        </span>
                      </Dragger>
                    </Button>
                  </ImageContainer>
                </div>
              );
            } else {
              return (
                (showImageType == type.toLowerCase() || !showImageType) &&<StyledDragger className='dragger-container' key={index} {...createDraggerProps(type)} style={{width: width || '280px', height: 280}}>
                  <div onClick={() => {
                    setSelectedImgIndex(null);
                  }} style={{display: 'flex'}}>
                    <DragRightContainer>
                      <DragimageContainer style={{height: 280}} width={width ?? '280px'}>
                        <div className='upload-icon'>
                          <img
                            src={
                              type === 'PROFILE' ?
                                '/img/logoDefaultImg.png' :
                                type === 'COVER' ?
                                  '/img/coverDefaultImg.png' :
                                  '/img/photosDefaultImg.png'
                            }
                            alt='default'
                          />
                        </div>
                        <Divider style={{backgroundColor: '#D8D8D8', height: 1}}/>
                        <DragText className='drag-text'>
                          <div style={{color: '#121212', fontWeight: 500}}>
                            {type === 'PROFILE' ?
                              'Add a Logo' :
                              type === 'COVER' ?
                                'Add a Cover Photo' :
                                'Add a Photo'}
                          </div>
                          <div style={{color: '#4E5156', fontSize: 12}}>
                            {type === 'PROFILE' ?
                              'Drop your logo here, or:' :
                              type === 'COVER' ?
                                'Drop your cover photo here, or:' :
                                'Drag & drop your photo(s) here to upload them, or'}
                          </div>
                          <div style={{color: '#2D6CCA', fontSize: 12, display: 'inline-block'}}>
                            <FontAwesomeIcon color='#2D6CCA' style={{marginRight: 6}} fontSize={16} icon={faCloudArrowUp} />
                            {type === 'PROFILE' ?
                              'Select logo' :
                              type === 'COVER' ?
                                'Select cover photo' :
                                'Select file(s)'}
                          </div>
                        </DragText>
                      </DragimageContainer>
                    </DragRightContainer>
                  </div>
                  <div className='overlay' />
                </StyledDragger>
              );
            }
          }
          return null;
        })}      </InputContainer>
      <StyledModal
        zIndex={1060}
        visible={modalConfig.visible}
        onCancel={() => setModalConfig({visible: false, imageSource: ''})}
        footer={false}
        width={1240}
        closeIcon={<FontAwesomeIcon color={'#fff'} fontSize={22} icon={faXmark}/>}
      >
        <img style={{maxHeight: '80vh', maxWidth: '1240px'}} src={modalConfig.imageSource} />
      </StyledModal>
      {/* {<StyledUrlContainer className='youtube-input-container'>
        <div className='logo-text'>Video URL(s)</div>
        <div className='input-cont' style={{maxHeight: '240px', overflow: 'auto'}}>
          {images?.ADDITIONAL?.map((item, index) => (
            item?.format == 'VIDEO' && (<div onMouseEnter={() => setSelectedImgIndex(index)} onMouseLeave={() => setSelectedImgIndex(null)}>
              <StyledFormItem key={index} name={item?.name} className='youtube-input-delete-icon' rules={[{pattern: urlRegex, message: 'Invalid URL'}]}>
                <UrlField value={item.url} index={index} handleChange={handleChange} setYoutubeUrlError={setYoutubeUrlError} />
                <FontAwesomeIcon fontSize={14} icon={faTrash} color='rgb(163, 164, 164)' onClick={() => handleYoutubeUrlDeletion(index)} />
              </StyledFormItem>
              {youtubeUrlError?.includes(index) && <p style={{color: '#ff4d4f'}} className='error'>{showError ? 'This URL already exists. Please enter a different URL.' : 'Invalid URL'}</p>}
            </div>)
          ))}
        </div>
        <span className='add-field' onClick={handleAddField}>+Add URL</span>
      </StyledUrlContainer>} */}
    </div>
  );
};

export default ImagePicker;

const InputContainer = styled.div`
display: flex;
gap: 20px;
flex-wrap: wrap;
  margin-top: 5px;
  flex-grow: 1;
  &:hover {
    .dragImageHover {
      border-color: #2D6CCA;
      color: #2D6CCA;
    }
    .dragSubtextHover {
      color: #2D6CCA;
    }
  }
  .ant-upload-btn {
    padding: 0 !important;
  }
  .ant-upload-drag {
    background: transparent !important;
    border: 0 !important;
  }
  .image-container {
  }
`;

const ImageContainer = styled.div<{width?: string}>`
  width: ${p => p?.width || '220px'};
  height: 125px;
  border: 1px solid #D8D8D8;
  border-radius: 12px;

  img {
    border-radius: 12px;
    height: 100% !important;
    width: 100% !important;
    object-fit: contain !important;
  }
`;

const DragRightContainer = styled.div`
  flex-grow: 1;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const DragText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #121212;
  text-align: left;
  padding: 17px;
`;

const DragimageContainer = styled.div<{width?: string}>`
  text-align: center;
  width: ${p => p?.width || '280px'};
  height: 125px;
  border: 1px solid #D8D8D8;
  border-radius: 12px;
  .upload-icon {
  height: 130px;
  background-color: #F1F3F4;
  width: 278px;
  border-radius: 12px 12px 0px 0px;
  }
`;

const StyledModal = styled(Modal)`
  width: fit-content !important;
  .ant-modal-close-x {
    position: absolute;
    top: -19px;
    left: 0px;
    right: -70px;
  }
  .ant-modal-body {
    padding: 0px;
  }
  .ant-modal-content {
    border-radius: 8px;
  }
`;

// const StyledUrlContainer = styled.div`
//       margin-top: 29px;
//       padding-right: 15px;
//     .logo-text {
//       font-family: Inter;
//       font-size: 12px;
//       font-weight: 400;
//       line-height: 22px;
//       text-align: left;
//       color: #4E5156;
//     }
//     .photos-container {
//       margin-top: 19px;
//       .photos {
//         display: flex;
//         align-items: center;
//         gap: 15px;
//       }
//     }
//     .youtube-url, .error-youtube-url {
//       border-radius: 8px;
//       margin-top: 5px;
//       height: 38px;
//     }
//     .error-youtube-url {
//       border-color: #ff4d4f;
//     }
//     .youtube-input-container {
//       margin-top: 29px;
//       .error {
//         color: #ff4d4f;
//         font-size: 12px;
//         margin-bottom: 0px !important;
//       }
//       .input-cont {
//         padding: 0px 5px 10px 0px;
//         ::-webkit-scrollbar {
//           width: 5px;
//           height: 5px;
//         }
//         ::-webkit-scrollbar-track {
//         background: #F9F9FB;
//         }
//         ::-webkit-scrollbar-thumb {
//           background: #4E515680;
//           border-radius: 5px;
//         }
//       }
//     }
//     .youtube-input-delete-icon {
//       display: flex;
//       align-items: center;
//       gap: 5px;
//       svg {
//         cursor: pointer;
//         width: 12.25px;
//         height: 14px;
//       }
//     }
//     .add-field {
//       margin-top: 9px;
//       font-family: Inter;
//       font-size: 14px;
//       font-weight: 400;
//       line-height: 16px;
//       text-align: left;
//       color: #2D6CCA;
//       cursor: pointer;
//     }
//     ::-webkit-scrollbar {
//      width: 5px;
//      height: 5px;
//     }
//     ::-webkit-scrollbar-track {
//       background: #F9F9FB;
//     }
//     ::-webkit-scrollbar-thumb {
//       background: #4E515680;
//       border-radius: 5px;
//     }
//   `;


export const StyledFormItem = styled(Form.Item)`
    margin-bottom: 0px !important;
    .ant-form-item-control-input-content {
      display: flex;
      align-items: center;
      gap: 0px;
    }
    .ant-form-item-explain-error {
      font-size: 12px;
    }
    .ant-form-item {
      margin-bottom: 0px !important;
    }
  `;

const StyledDragger = styled(Dragger)`
  position: relative;

  &:hover {
    background-color: rgba(0, 0, 255, 0.1); 
    transition: background-color 0.3s ease;
  }

  &:hover .overlay {
    opacity: 1;
    transition: opacity 0.3s ease;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(45, 108, 202, 0.1);
    opacity: 0;
    pointer-events: none;
  }
`;
