import React from 'react';
import {faArrowLeft} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import {observer} from 'mobx-react-lite';
import {ColumnsType} from 'antd/lib/table';
import {Table} from '@/components/common-components/components';
import {toJS} from 'mobx';
import {Row, Col} from 'antd';
import {useStore} from '@/store/root-store';
import {LoadingOutlined} from '@ant-design/icons';

interface Props {
  setIsFBAdsDetail: (value) => void;
  onDisconnect: any;
  fbAdsData: any;
  setShowConnectAccountHeader?: React.Dispatch<React.SetStateAction<boolean>>;
}
export const FBSettingsData: React.FC<Props> = observer(
  ({
    setIsFBAdsDetail,
    fbAdsData,
    onDisconnect,
    setShowConnectAccountHeader,
  }) => {
    const {
      reportBuilder: {
        details: {fbLoader},
      },
    } = useStore('');
    const columns: ColumnsType = [
      {
        title: () => <span>Business</span>,
        key: 'url',
        dataIndex: 'url',
        align: 'left',
        width: 10,
        render: (_, data: any) => (
          <ProjectName>{data?.business ? data.business : '-'}</ProjectName>
        ),
      },
      {
        title: () => <span style={{marginLeft: '-22px'}}>Name</span>,
        key: 'url',
        dataIndex: 'url',
        align: 'left',
        width: 400,
        render: (_, data: any) => (
          <ProjectName>{data?.name ? data.name : '-'}</ProjectName>
        ),
      },
    ];
    const HandleGoBack = () => {
      setIsFBAdsDetail(false);
      setShowConnectAccountHeader && setShowConnectAccountHeader(true);
    };
    return (
      <MainWrapper>
        <Row gutter={24} style={{display: 'flex', flexWrap: 'wrap'}}>
          <Col xl={18} md={16} xs={24}>
            <div
              onClick={HandleGoBack}
              style={{display: 'flex', gap: '18px', cursor: 'pointer', width: 'fit-content'}}
            >
              <FontAwesomeIcon
                icon={faArrowLeft}
                style={{cursor: 'pointer'}}
              />
              <Heading>Back to list of FB Connected Accounts</Heading>
            </div>
            <Email>{fbAdsData?.email}</Email>
          </Col>
          <Col xl={6} md={8} xs={24}>
            <DisconnectEmail
              style={{textAlign: 'end'}}
              onClick={() => (fbLoader ? {} : onDisconnect(fbAdsData?.email))}
            >
              Disconnect this Email{' '}
              {fbLoader && (
                <LoadingOutlined
                  style={{color: '#4e5156', fontSize: '15px'}}
                  spin
                />
              )}
            </DisconnectEmail>
          </Col>
        </Row>
        <TableContainer>
          <Table
            dataSource={toJS(fbAdsData)?.facebookAdAccounts || []}
            columns={columns}
            pagination={false}
          />
        </TableContainer>
      </MainWrapper>
    );
  },
);

const ProjectName = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #121212;
`;

const DisconnectEmail = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #f44343;
  cursor: pointer;
  @media only screen and (max-width: 767px) {
    padding-left: 30px;
  }
`;
const Email = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #4e5156;
  padding-left: 30px;
`;

const MainWrapper = styled.div`
  padding-top: 15px;
`;
const Heading = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #121212;
`;

const TableContainer = styled.div`
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  margin-top: 10px;
  padding: 0 5px 5px 5px;

  .ant-table-body {
    &::-webkit-scrollbar {
      height: 5px;
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(138, 138, 138, 0.32);
    }

    &::-webkit-scrollbar-track {
      background: rgba(138, 138, 138, 0.12);
    }
  }

  table tr th {
    background-color: #fff;
  }

  .ant-table-content {
    border-radius: 0 !important;
  }

  .ant-table-column-sorters-with-tooltip:not(:first-child) {
    text-align: center;
  }

  table {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;

    th::before {
      width: 0 !important;
    }

    .ant-table-column-sorter {
      display: flex;
      padding-left: 3px !important;
    }

    .ant-table-column-sorters {
      display: inline-flex !important;
      padding: 5px !important;
    }

    tr td {
      &:first-child {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
      }
    }

    .ant-table-tbody {
      tr:nth-child(even) {
        td {
          background: #fff !important;
          border-bottom: 1px solid #f4f4f4 !important;
          box-shadow: none;
          font-weight: 400;
          white-space: nowrap;
        }

        &:hover {
          td {
            background: #eef4fe !important;
          }

          button {
            background: #eef4fe !important;
            border: 0 !important;
            color: #2d6cca;
            font-weight: 500;
          }
        }
      }

      tr:nth-child(odd) {
        td {
          background: #fff;
          border-bottom: 1px solid #f4f4f4 !important;
          box-shadow: none;
          font-weight: 400;
          white-space: nowrap;
        }

        &:hover {
          td {
            background: #eef4fe !important;
          }

          button {
            background: #eef4fe !important;
            border: 0 !important;
            color: #2d6cca;
            font-weight: 500;
          }
        }
      }
    }

    .ant-table-thead {
      background: #f7f7f7 !important;

      th {
        background: #f7f7f7 !important;
        color: #121212 !important;
        font-size: 12px;
        font-weight: 600;
        line-height: 14px;
        padding: 0 !important;
      }

      .ant-table-cell-fix-left {
        text-align: left !important;

        &:first-child {
          border-bottom-left-radius: 8px !important;
          border-top-left-radius: 8px !important;
        }
      }

      th:first-child {
        background: #f7f7f7 !important;
        border-bottom-left-radius: 8px !important;
        border-top-left-radius: 8px !important;
        color: #121212;
        font-size: 12px;
        font-weight: 600;
      }

      th:nth-child(2) {
        background: #f7f7f7 !important;
        color: #121212;
        font-size: 12px;
        font-weight: 600;
      }

      th:last-child {
        background-color: #f7f7f7 !important;
        border-bottom-right-radius: 8px !important;
        border-top-right-radius: 8px !important;
        padding-left: 25px !important;
      }
    }

    .ant-table-cell-fix-left-last {
      text-align: left !important;
    }

    tr:first-child td {
      background: #fff !important;
    }

    tr:nth-child(even) td {
      background: #f9f9fb !important;
    }

    tr:nth-child(odd) td {
      background-color: #fff !important;
    }

    tr th {
      height: 50px !important;
      line-height: 22px;
      white-space: nowrap;
    }

    .ant-column-sorters {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }

    tr td {
      padding-bottom: 15px;
      padding-top: 15px;
      text-align: center;
    }

    tr th:first-child,
    tr td:first-child {
      padding-left: 25px !important;
      padding-right: 25px !important;
    }
  }
`;
