import React, {useState, Fragment} from 'react';
import {observer} from 'mobx-react';
import styles from '@/components/dashboard/settings/styles.module.scss';
import {
  Typography,
  Button,
  Modal,
} from '@/components/common-components/components/index';
import {AccountsFailedCredBanner} from '@/components/dashboard/gsc/gsc/BannerPages/AccountsFailedCredBanner';
import {AccountsHaveNoGscConnectedBanner} from '@/components/dashboard/gsc/gsc/BannerPages/AccountsHaveNoGscConnectedBanner';
import {Button as V2Button} from '@/components/common-components/v2/';
import {useStore} from '@/store/root-store';
import {useTranslation} from 'next-i18next';
import {UserOutlined} from '@ant-design/icons';
import {Empty, notification, Tooltip} from 'antd';
import {
  SETTINGS_GSC_AUTHORIZE_CLICKED,
  SETTINGS_DISCONNECT_ACCOUNT,
} from '@/constants/events';
import {integrationApi} from '@/api/gsc';
import {AuthorizedModal} from '@/components/common-components/components/authorized-modal';
import {MixPanel} from '@/utils/mixpanel';
import {getHubSpotLink} from '@/utils/legacy';
import styled, {keyframes} from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight, faRotate} from '@fortawesome/pro-solid-svg-icons';
import {toJS} from 'mobx';
import {NextImg} from '@/utils/nextImg';
import {faTrash} from '@fortawesome/pro-regular-svg-icons';
import {GSCSettingsDetailV2} from './gscSettingsDetailV2';
import {notification as notificationV2} from '@/utils/notification-v2';
import {COMMUNITY_EDITION_ID} from '@/constants';
interface ConnectAccountHeaderProps {
  setShowConnectAccountHeader?: (value: boolean) => void;
  showConnectAccountHeader?: boolean;
}
export const GSCSettingsV3: React.FC<ConnectAccountHeaderProps> = observer(
  ({setShowConnectAccountHeader, showConnectAccountHeader}) => {
    const [deleteEmail, setDeleteEmail] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);
    const [isGscDetail, setIsGscDetail] = useState(false);
    const [gscDetailData, setGscDetailData] = useState(null);
    const {t} = useTranslation('common');
    const {
      settings: {
        integration: {
          getIsAnyGscNotConnectedAccounts,
          gscIsAnyAccHaveNoSitesConnected,
          gscNoSitesConnectedAccList,
          getNotConnectedGscAccounts,
          refreshEmail,
        },
        integration,
        customer: {
          profile: {isWhitelabel, plan},
        },
      },
    } = useStore('');
    const {
      gsc: {setDrawerVisibility},
    } = useStore('');

    const googleProfilesList = integration.googleProfiles;
    const [confirmModal, setConfirmModal] = useState(false);
    const [emailIndex, setEmailIndex] = useState(-1);
    const onClose = () => {
      setConfirmModal(false);
    };

    const deleteAccount = () => {
      setDeleteModal(false);
      notification.success({
        placement: 'bottomRight',
        bottom: 70,
        duration: 3,
        style: {
          zIndex: 2,
        },
        message: t('account-disconnected-successfully'),
      });
      setDrawerVisibility(false);
    };

    const openDeleteModal = (profile: string) => {
      setDeleteModal(true);
      setDeleteEmail(profile);
    };

    const handleAuthorizeConnection = () => {
      MixPanel.track(SETTINGS_GSC_AUTHORIZE_CLICKED);

      window.open(integrationApi.getGoogleConnectUrl());
    };

    const onRefreshHandler = async email => {
      if (email) {
        try {
          await refreshEmail(email);
          notificationV2.success('Account has been refreshed.', '');
        } catch (error) {
          notificationV2.error('Something went wrong.', '');
        }
        setEmailIndex(-1);
      }
    };
    const hideGscBanner = localStorage.getItem('hideGscBanner');
    return (
      <Fragment>
        {showConnectAccountHeader && (
          <>
            {' '}
            <div className='nested-Heading'>Google Search Console</div>
            <div className='description'>
              Gain insights into your website’s search performance, keyword
              rankings, and visibility.
            </div>
          </>
        )}
        <div
          className={styles.TabPanContainer}
          style={{
            background: 'none',
            height: '100%',
            overflowY: 'scroll',
          }}
        >
          {googleProfilesList?.length < 1 ? (
            <div style={{marginTop: '21px'}}>
              <div className={styles.gscIntegration}>
                <div>
                  <NextImg src='/img/Groupgsc.svg' />
                </div>
                <div
                  style={{
                    marginTop: '25px',
                    marginLeft: '46px',
                    marginRight: '46px',
                  }}
                >
                  <h3>
                    <span
                      onClick={() => setConfirmModal(true)}
                      className={styles.spanStyle}
                      style={{cursor: 'pointer'}}
                    >
                      Connect
                    </span>{' '}
                    {!isWhitelabel && (
                      <>
                        or{' '}
                        <span className={styles.spanStyle}>
                          <a
                            href={`${process.env.LINKGRAPH_COM}/blog/how-to-use-google-search-console/`}
                            target={'_blank'}
                            style={{color: 'unset'}}
                            rel='noreferrer'
                          >
                            Learn how to set up
                          </a>
                        </span>{' '}
                      </>
                    )}{' '}
                    Google Search Console
                  </h3>
                </div>
                <div className={styles.paragraph}>
                  <p>
                    Follow our detailed guide to set up Google Search Console{' '}
                    <br></br> and view detailed data for your website!
                  </p>
                </div>
                <div className={styles.btnSetup}>
                  <Button className={styles.btnFirst} onClick={() => {}}>
                    <a
                      onClick={() => setConfirmModal(true)}
                      className={styles.btnFirstLink}
                    >
                      Connect Search Console
                    </a>
                  </Button>
                  {isWhitelabel ? (
                    ''
                  ) : (
                    <Button className={styles.btnSecond}>
                      <a
                        href={`${process.env.LINKGRAPH_COM}/how-to-use-google-search-console/`}
                        target='_blank'
                        rel='noreferrer'
                      >
                        View Detailed Guide
                      </a>
                    </Button>
                  )}
                </div>
              </div>
              <div className={styles.setupAccount}>
                {!isWhitelabel && (
                  <a href={getHubSpotLink()} target='_blank' rel='noreferrer'>
                    Ask us to Set Up your Google Account
                  </a>
                )}
              </div>
            </div>
          ) : (
            <>
              {getIsAnyGscNotConnectedAccounts ? (
                <AccountsFailedCredBanner
                  showButton={false}
                  emailList={getNotConnectedGscAccounts}
                  className={styles.container}
                />
              ) : null}
              {hideGscBanner !== 'hide' && gscIsAnyAccHaveNoSitesConnected ? (
                <AccountsHaveNoGscConnectedBanner
                  title={`No properties found for ${
                    toJS(integration.googleProfiles)?.length
                  } account${
                    toJS(integration.googleProfiles)?.length > 1 ? 's' : ''
                  }`}
                  showCancelButton
                  showButton={false}
                  emailList={gscNoSitesConnectedAccList}
                  className={styles.container}
                />
              ) : null}

              <Account
                className={!isGscDetail && styles.WrapperDiv}
                style={{
                  border: !isGscDetail && '1px solid #E8E8E8',
                  boxShadow: !isGscDetail && '0px 3px 2px rgba(0, 0, 0, 0.02)',
                  overflow: isGscDetail && 'hidden',
                }}
              >
                <div>
                  {googleProfilesList?.length > 0 ? (
                    isGscDetail ? (
                      <GSCSettingsDetailV2
                        setShowConnectAccountHeader={
                          setShowConnectAccountHeader
                        }
                        setGaDetail={setIsGscDetail}
                        onDeleteAccount={email => openDeleteModal(email)}
                        gscDetailData={gscDetailData}
                      />
                    ) : (
                      googleProfilesList.map((profile, index) => {
                        return (
                          profile.isAuthorizedForGsc && (
                            <AccountRow
                              key={index}
                              style={{borderBottom: '1px solid #D2D2D2'}}
                            >
                              <div key={index}>
                                <span style={{paddingRight: '5px'}}>
                                  <UserOutlined />
                                </span>
                                <span>{profile.email}</span>
                              </div>
                              <StyledButtonsWrapper>
                                <div className='view-properties'>
                                  {profile?.siteproperties?.length === 0 ? (
                                    <span
                                      className='properties-button'
                                      style={{color: '#A3A4A4'}}
                                    >
                                      No accounts found
                                    </span>
                                  ) : (
                                    <span
                                      className='properties-button'
                                      onClick={() => {
                                        setIsGscDetail(true);
                                        setShowConnectAccountHeader(false);
                                        setGscDetailData(profile);
                                      }}
                                      style={{
                                        color: '#2D6CCA',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '10px',
                                      }}
                                    >
                                      {`View ${profile.siteproperties.length} ${
                                        profile.siteproperties.length === 1 ?
                                          t('property') :
                                          t('properties')
                                      }`}
                                      <FontAwesomeIcon
                                        icon={faArrowRight}
                                        color='#2D6CCA'
                                        fontSize={14}
                                      />
                                    </span>
                                  )}
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '20px',
                                  }}
                                >
                                  {emailIndex === index ? (
                                    <RotatingIcon
                                      icon={faRotate}
                                      color={'#A3A4A4'}
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      style={{cursor: 'pointer'}}
                                      icon={faRotate}
                                      title='Refresh'
                                      color={'#A3A4A4'}
                                      onClick={() => {
                                        setEmailIndex(index);
                                        onRefreshHandler(profile.email);
                                      }}
                                    />
                                  )}
                                  <FontAwesomeIcon
                                    style={{cursor: 'pointer'}}
                                    title='Delete'
                                    icon={faTrash}
                                    color='#A3A4A4'
                                    onClick={() =>
                                      openDeleteModal(profile?.email)
                                    }
                                  />
                                </div>
                              </StyledButtonsWrapper>
                            </AccountRow>
                          )
                        );
                      })
                    )
                  ) : (
                    <Empty />
                  )}
                </div>
              </Account>
            </>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            paddingTop: '15px',
            paddingBottom: '25px',
          }}
        >
          <Tooltip title={COMMUNITY_EDITION_ID == plan && toJS(googleProfilesList)?.filter(acc => acc?.isAuthorizedForGsc)?.length >= 1 ? 'You can only add one GSC account with the Community Edition plan.' : ''}>
            <span>
              <V2Button
                variant='ghost'
                color='blue'
                onClick={handleAuthorizeConnection}
                disabled={COMMUNITY_EDITION_ID == plan && toJS(googleProfilesList)?.filter(acc => acc?.isAuthorizedForGsc)?.length >= 1}
              >
                {'+ Connect new GSC account'}
              </V2Button>
            </span>
          </Tooltip>
        </div>
        {confirmModal && (
          <AuthorizedModal
            trackingId={SETTINGS_GSC_AUTHORIZE_CLICKED}
            isOpen={confirmModal}
            onClose={onClose}
          />
        )}
        <Modal
          className={styles.Modal}
          isDark
          visible={deleteModal}
          onClose={() => setDeleteModal(false)}
        >
          <div className={styles.ConfirmationModal}>
            <Typography tag='h2'>{t('disconnect-account-question')}</Typography>
            <span>
              {t('all-gsc-projects-imported')}{' '}
              <span style={{fontWeight: 600}}>{deleteEmail}</span>{' '}
              {t('will-be-deactived')}
            </span>
          </div>
          <div className={styles.ButtonDisplay}>
            <Button
              className={styles.Back}
              onClick={() => setDeleteModal(false)}
            >
              {t('cancel')}
            </Button>
            <Button
              className={styles.Danger}
              onClick={async () => {
                try {
                  await integration.deleteGoogleProfile(deleteEmail);
                  deleteAccount();
                  const detail = {
                    profile: deleteEmail,
                  };

                  MixPanel.track(SETTINGS_DISCONNECT_ACCOUNT, {
                    disconnectingAccount: `${detail}`,
                  });
                } catch (e) {
                  return Promise.reject(e);
                }
              }}
            >
              {t('disconnect-account')}
            </Button>
          </div>
        </Modal>
      </Fragment>
    );
  },
);
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
const AccountRow = styled.div`
  display: flex;
  justify-content: space-between;
  height: 42px;
  align-items: center;
  @media only screen and (max-width: 760px) {
    width: 580px !important;
    overflow: auto;
    padding-right: 13px;
  }
`;
const Account = styled.div`
  overflow: auto;
  margin-top: 0px;
  &::-webkit-scrollbar {
      height: 5px;
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: #4E515680;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #F9F9FB;
      border-radius: 10px;
    }
`;
const RotatingIcon = styled(FontAwesomeIcon)`
  animation: ${spin} 2s linear infinite;
`;
const StyledButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 42px;
  .view-properties {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #2d6cca;
    .properties-button {
      color: #2d6cca;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 10px;
      position: relative;
      &::after {
        content: "";
        width: 2px;
        height: auto;
        position: absolute;
        top: 0px;
        bottom: 0px;
        background-color: #d9d9d9;
        right: -20px;
      }
    }
  }
`;
