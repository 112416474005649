import React, {useState} from 'react';
import Dragger from 'antd/lib/upload/Dragger';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFileArrowUp} from '@fortawesome/pro-duotone-svg-icons';
import styled from 'styled-components';
import {faArrowUpRightFromSquare, faTrash, faTrashCan, faXmark} from '@fortawesome/pro-regular-svg-icons';
import {Modal, Tooltip} from 'antd';
import {faCloudArrowUp} from '@fortawesome/pro-solid-svg-icons';
import {RingLoaderV2} from '@/components/common-components/components/skeleton/ring-loader-v2';

interface Props {
  setImages: any;
  type: string;
  images: any;
  props: any;
  hideExtra?: boolean;
  width?: string;
  placeholderText?: string;
  hideDragger?: boolean;
  variant?: 'default' | 'gbp-post';
  uploadingImagesNames?: string[];
  wrapperStyle?: React.CSSProperties;
  isLocalSEOMyTask?: boolean;
  selectImageFromMediaLibrary?: (e: any) => void;
  maxLimit?: number;
}

const ImagePicker = ({setImages, type, images, props, hideExtra, width, placeholderText, hideDragger=false, variant='default', uploadingImagesNames=[], wrapperStyle, isLocalSEOMyTask, selectImageFromMediaLibrary, maxLimit}: Props) => {
  const [modalConfig, setModalConfig] = useState({
    visible: false,
    imageSource: '',
  });

  const getImageSrc = imageUrl => {
    let src;
    if (typeof imageUrl === 'string') {
      src = imageUrl;
    } else if (imageUrl instanceof Blob || imageUrl instanceof File) {
      src = URL?.createObjectURL(imageUrl);
    } else {
      src = '';
    }
    return src;
  };

  const DefaultImageCard = ({item, index}) => (
    <StyledWrapper
      key={index}
      style={{width: width || '220px', position: 'relative'}}
    >
      {<ImageActions width={width} className='image-actions'>
        <ActionButton
          onClick={e => {
            e.preventDefault();
            const src = getImageSrc(index === -1 ? item : item?.url);
            setModalConfig({visible: true, imageSource: src});
          }}
        >
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            View
        </ActionButton>
        <ActionButton
          onClick={e => {
            e.preventDefault();
            setImages(index === -1 ? null: images.filter(i => i.id !== item?.id));
          }}
        >
          <FontAwesomeIcon icon={faTrash} fontSize={14} />
            Remove
        </ActionButton>
      </ImageActions>}
      <ImageContainer width={width}>
        <img
          id='selected-image'
          alt='not found'
          src={getImageSrc(index === -1 ? item : item?.url)}
        />
      </ImageContainer>
    </StyledWrapper>
  );

  const GBPImageCard = ({item, index}) => {
    const isNameExistInUpload = (typeof item === 'object' && uploadingImagesNames?.length) ? uploadingImagesNames?.some(name => name === item?.url?.name) : '';
    return (<div
      key={index}
      style={{width: width || '220px', position: 'relative', cursor: (isLocalSEOMyTask && selectImageFromMediaLibrary) ? 'pointer': ''}}
      onClick={() => (isLocalSEOMyTask && selectImageFromMediaLibrary) && selectImageFromMediaLibrary(item)}
    >
      {isNameExistInUpload || (props?.gbpGalleryDeletingImageId && props?.gbpGalleryDeletingImageId === item?.id) ? <StyledLoader>
        <RingLoaderV2 color='#D8D8D8' fontSize={100}/>
      </StyledLoader> : (uploadingImagesNames?.length || props?.gbpGalleryDeletingImageId !== -1) ? <></> :
        <ImageActionsGbp width={width}>
          <Tooltip title='Remove'>
            <button
              className='delete'
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                props?.onDeleteGbpGalleryImage && props?.onDeleteGbpGalleryImage(item?.id);
              }}
            >
              <FontAwesomeIcon icon={faTrashCan} fontSize={14} />
            </button>
          </Tooltip>
          <Tooltip title='View'>
            <button
              className='view'
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                const src = getImageSrc(index === -1 ? item : item?.url);
                setModalConfig({visible: true, imageSource: src});
              }}
            >
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </button>
          </Tooltip>
        </ImageActionsGbp>}
      <ImageContainerGbpPost width={width}>
        <img
          id='selected-image'
          alt='not found'
          src={getImageSrc(index === -1 ? item : item?.url)}
        />
      </ImageContainerGbpPost>
    </div>);
  };

  const defaultDragDropCard = (
    <DragimageContainer width={width}>
      <div className='upload-icon'>
        <FontAwesomeIcon icon={faFileArrowUp} />
      </div>
      <DragText className='drag-text'>
        {type == 'logo' ? 'Drop your logo here, or' : 'Drop your image(s) here, or'}
        <div style={{color: '#2D6CCA', textDecoration: 'underline', display: 'inline-block', marginLeft: 4}}>
        click to browse
        </div>
      </DragText>
      <ImgSize className='placeholder-txt'>
        {placeholderText || 'Up to 2MB'}
      </ImgSize>
    </DragimageContainer>
  );

  const gbpPostDragDropCard = (
    <DragimageContainerGbpPost width={width}>
      <div className='upload-icon-container'>
        <img className='upload-icon' src={'/img/icon/gbp-upload-image.svg'}/>
      </div>
      <div className='drag-text-container'>
        <div className='heading'>
          Add a Photo
        </div>
        <div className='sub-heading'>
          Drag & drop your photo(s) here to upload them, or
        </div>
        <div className='link'>
          <FontAwesomeIcon icon={faCloudArrowUp} /> Select file(s)
        </div>
      </div>
    </DragimageContainerGbpPost>
  );

  const dragDropCard = {
    'default': defaultDragDropCard,
    'gbp-post': gbpPostDragDropCard,
  };

  const imageCard = {
    'default': DefaultImageCard,
    'gbp-post': GBPImageCard,
  };

  const ImageCardComponent = imageCard[variant];

  return (
    <div>
      <InputContainer style={wrapperStyle}>
        {type !== 'logo' && images?.map((item, index) => (
          <ImageCardComponent key={index} item={item} index={index}/>
        ))}
        {hideExtra ? null : type == 'logo' && images ?
          <ImageCardComponent item={images} index={-1}/>:
          !hideDragger && <>
            <Tooltip overlayInnerStyle={{minWidth: 275, backgroundColor: '#121212', borderRadius: '12px'}} title={(maxLimit && images?.length >= maxLimit) ? `You cannot add more than ${maxLimit} images.` : ''}>
              <div>
                <Dragger {...props} style={{width: width || '220px'}} disabled={maxLimit && images?.length >= maxLimit}>
                  <div style={{display: 'flex'}}>
                    <DragRightContainer>
                      {dragDropCard[variant]}
                    </DragRightContainer>
                  </div>
                </Dragger>
              </div>
            </Tooltip>
          </>
        }
      </InputContainer>
      <StyledModal
        zIndex={1060}
        visible={modalConfig.visible}
        onCancel={() => setModalConfig({visible: false, imageSource: ''})}
        footer={false}
        closeIcon={<FontAwesomeIcon color={'#fff'} fontSize={22} icon={faXmark}/>}
      >
        <img style={{maxHeight: '80vh', maxWidth: '100%'}} src={modalConfig.imageSource} />
      </StyledModal>
    </div>
  );
};

export default ImagePicker;

const InputContainer = styled.div`
display: flex;
gap: 15px;
flex-wrap: wrap;
  margin-top: 5px;
  flex-grow: 1;
  &:hover {
    .dragImageHover {
      border-color: #2D6CCA;
      color: #2D6CCA;
    }
    .dragSubtextHover {
      color: #2D6CCA;
    }
  }
  .ant-upload-btn {
    padding: 0 !important;
  }
  .ant-upload-drag {
    background: transparent !important;
    border: 0 !important;
  }
  .image-container {
  }
  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(138, 138, 138, 0.32);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #F9F9FB;
    border-radius: 10px;
  }
`;

const ImageContainer = styled.div<{width?: string}>`
  width: ${p => p?.width || '220px'};
  height: 125px;
  border: 1px solid #D9D9D9;
  border-radius: 12px;

  img {
    border-radius: 12px;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;
const ImageContainerGbpPost = styled.div<{width?: string}>`
  width: ${p => p?.width || '220px'};
  height: ${p => p?.width || '220px'};
  border: 1px solid #D9D9D9;
  border-radius: 12px;

  img {
    border-radius: 12px;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

const DragRightContainer = styled.div`
  flex-grow: 1;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;


const ImgSize = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #121212;
`;

const DragText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #121212;
`;

const DragimageContainer = styled.div<{width?: string}>`
  text-align: center;
  width: ${p => p?.width || '220px'};
  height: 125px;
  padding: 18px;
  border: 1px solid #B6B6B6;
  border-style: dashed;
  border-radius: 12px;
  .upload-icon {
    svg {
    width: 22.5px;
    height: 30px;
    }
  }
`;

const DragimageContainerGbpPost = styled.div<{width?: string}>`
  text-align: center;
  width: ${p => p?.width || '220px'};
  height: ${p => p?.width || '220px'};
  border: 1px solid #D8D8D8;
  border-radius: 12px;
  .upload-icon-container {
    display: flex;
    align-items: end;
    justify-content: center;
    width: 100%;
    background-color: #F1F3F4;
    height: 130px;
    border-radius: 12px 12px 0px 0px;
    .upload-icon {
      margin-bottom: 10px;
    }
  }
  .drag-text-container {
    padding: 20px 15px;
    text-align: left;
    background-color: #FFFFFF;
    height: 148px;
    border-top: 1px solid #D8D8D8;
    border-radius: 0px 0px 12px 12px;
    .heading {
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 16.94px;
      color: #121212;
      margin-bottom: 5px;
    }
    .sub-heading {
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: #4E5156;
      margin-bottom: 5px;
    }
    .link {
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      color: #2D6CCA;
      margin-bottom: 10px;
    }
  }
`;

const ImageActions = styled.div<{width?: string}>`
  position: absolute;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  width: ${p => p?.width || '220px'};
  height: 125px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  opacity: 0;
`;

const ImageActionsGbp = styled.div<{width?: string}>`
  position: absolute;
  width: ${p => p?.width || '220px'};
  height: ${p => p?.width || '220px'};
  border-radius: 12px;
  .view {
    margin: 10px;
    width: 46px;
    height: 46px;
    border-radius: 25px;
    border: none;
    position: absolute;
    right: 55px;
    background: #12121299;
    color: #ffffff;
    cursor: pointer;
    svg {
      width: 17.5px;
      height: 20px;
    }
  }
  .delete {
    margin: 10px;
    width: 46px;
    height: 46px;
    border-radius: 25px;
    border: none;
    position: absolute;
    right: 0;
    background: #12121299;
    color: #ffffff;
    cursor: pointer;
    svg {
      width: 17.5px;
      height: 20px;
    }
  }
`;

const ActionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 100px;
  height: 28px;
  border-radius: 4px;
  border: 0px;
  background-color: rgba(255, 255, 255, 0.3);
  cursor: pointer;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
`;

const StyledModal = styled(Modal)`
  width: fit-content !important;
  .ant-modal-close-x {
    position: absolute;
    top: -19px;
    left: 0px;
    right: -70px;
  }
  .ant-modal-body {
    padding: 0px;
  }
  .ant-modal-content {
    border-radius: 8px;
  }
`;
const StyledLoader = styled.div`
  position: absolute;
  top: 34%;
  right: 32%;
`;

const StyledWrapper = styled.div`
  &:hover {
    .image-actions {
      opacity: 1 !important;
    }
  }
`;
