import {observer} from 'mobx-react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useStore} from '@/store/root-store';
import {useEffect, useState} from 'react';
import {Divider} from 'antd';
import {faArrowLeft, faXmark} from '@fortawesome/pro-regular-svg-icons';
import {EmailSendModal} from './viewEmail';
import {EditorState} from 'draft-js';
import {AddContactSection} from './sections/addContactSection';
import {ListContactsHeader} from './sections/listContactsHeader';
import {ContactsTableSection} from './sections/contactsTableSection';
import {Footer} from './sections/footer';
import {Modal} from './styledComponents';
import {toJS} from 'mobx';
import {notification} from '@/utils/notification-v2';

export const ListContactsModal = observer(({rowSelect, setRowSelect, result, campaignsId, isScheduled}) => {
  const {campaigns: {
    getCampaignsContact,
    setCurrentEditorState,
    visible,
    setVisible,
    setShowDragger,
    setAddContactStatesToDefault,
    chartData}} = useStore('');

  const [email, showEmail] = useState(false);
  const [sendSingleMail, setSendSingleMail] = useState(false);
  const [editorStateV2, setEditorStateV2] = useState('');
  const [index, setIndex] = useState(0);
  const [bulkDelete, setBulkDelete] = useState([]);
  const [handleBulkDelete, setHandleBulkDelete] = useState<() => void>(() => () => {});
  const [showBulkDeleteButton, setShowBulkDeleteButton] = useState(false);
  const [data, setDataMail] = useState({linked_email_id: null, subject: '', body: ''});

  useEffect(() => {
    setDataMail({...data, subject: toJS(chartData)?.template?.subject, body: toJS(chartData)?.template?.body});
    setEditorStateV2(toJS(chartData)?.template?.body);
  }, [visible, chartData]);


  useEffect(()=>{
    async function loadOpportunity() {
      await getCampaignsContact(rowSelect?.id);
    }
    rowSelect && loadOpportunity();
  }, [rowSelect]);

  useEffect(() => {
    const currentIndex = toJS(result)?.findIndex(i => i?.id === rowSelect?.id);
    setIndex(currentIndex);
  }, [toJS(result)]);

  useEffect(() => {
    setIndex(toJS(result)?.findIndex(i => i?.id === rowSelect?.id));
  }, [visible]);

  const handlePrevClick = () => {
    const currentIndex = toJS(result)?.findIndex(i => i?.id === rowSelect?.id) - 1;
    setIndex(currentIndex);
    setRowSelect(result[currentIndex]);
  };

  const handleNextClick = () => {
    const currentIndex = toJS(result)?.findIndex(i => i?.id === rowSelect?.id) + 1;
    setIndex(currentIndex);
    if (currentIndex <= result?.length - 1) {
      setRowSelect(result[currentIndex]);
    }
  };

  const handleClose = () => {
    setVisible(false);
    notification.success('Success', 'Added to "Ready for Outreach" List');
  };

  return (
    <Modal
      width={750}
      closable={true}
      closeIcon={<FontAwesomeIcon icon={faXmark} />}
      mask={true}
      visible={visible}
      onClose={() => {
        setVisible(false);
        showEmail(false);
        setAddContactStatesToDefault();
        setShowDragger(false);
        setCurrentEditorState(EditorState.createEmpty());
        setSendSingleMail(false);
        setEditorStateV2('');
        setIndex(toJS(result)?.findIndex(i => i?.id === rowSelect?.id));
        setShowBulkDeleteButton(false);
        setBulkDelete([]);
      }}
      zIndex={20}
    >
      <div style={{paddingBottom: '25px'}}>
        { email ? <>
          <div style={{cursor: 'pointer', width: '100px'}} onClick={()=> {
            showEmail(false);
            setSendSingleMail(false);
            setCurrentEditorState(EditorState.createEmpty());
            setEditorStateV2('');
            setDataMail({...data, subject: toJS(chartData)?.template?.subject, body: toJS(chartData)?.template?.body});
            setEditorStateV2(toJS(chartData)?.template?.body);
          }} ><FontAwesomeIcon icon={faArrowLeft} style={{marginRight: '10px'}}/>Back</div>
          <EmailSendModal sendSingleMail={sendSingleMail} setVisible={setVisible} showEmail={showEmail} editorStateV2={editorStateV2} setEditorStateV2={setEditorStateV2} data={data} setDataMail={setDataMail} campaignsId={campaignsId} isScheduled={isScheduled} /></> : <div>
          <ListContactsHeader showBulkDeleteButton={showBulkDeleteButton} handleBulkDelete={handleBulkDelete} rowSelect={rowSelect} handleNextClick={handleNextClick} handlePrevClick={handlePrevClick} result={result} index={index} />
          <ContactsTableSection bulkDelete={bulkDelete} setBulkDelete={setBulkDelete} setShowBulkDeleteButton={setShowBulkDeleteButton} setHandleBulkDelete={setHandleBulkDelete} setSendSingleMail={setSendSingleMail} showEmail={showEmail} rowSelect={rowSelect} campaignsId={campaignsId}/>
          <AddContactSection rowSelect={rowSelect}/>
          <Divider />
          <Footer onStatusUpdate={handleClose} showEmail={showEmail} rowSelect={rowSelect} setIndex={setIndex} result={result} setRowSelect={setRowSelect} campaignsId={campaignsId} isScheduled={isScheduled} />
        </div> }
      </div>
    </Modal>
  );
});
