import {Collapse} from 'antd';
import {observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import {StyledIssuesCollapse} from '../../../../style';
import {gbpPostInitialDateFilter} from '../../../../Constants';
import {GBPPostTableHeader} from './gbpPostTableHeader';
import {GbpPostsTable} from './gbpPostsTable';
import {useStore} from '@/store/root-store';

interface Props {
  componentIssueType: string;
  setPageChanged: (value: boolean) => void;
  setIssueTable: (value: string) => void;
  showConfirmationPopup: (value: any) => void;
  setRecordEditing: (value: Record<string, any>) => void;
  setEditingPost: (value: boolean) => void;
  issueTable: string;
  deleteItem: any;
  setOpenGenerateGbpPostsModal: (value: boolean) => void;
  maxHeight?: string;
  removeTopBar?: boolean;
}

export const GbpPosts = observer(({componentIssueType, setPageChanged, setIssueTable, issueTable, showConfirmationPopup, setRecordEditing, setEditingPost, setOpenGenerateGbpPostsModal, deleteItem, maxHeight = null, removeTopBar = false}: Props) => {
  const {ottoV2Store: {
    getGbpAutomatedPostSettings,
    getGbpMediaLibraryImages,
  }} = useStore('');
  const [searchText, setSearchText] = useState('');
  const [isTableOpen, setIsTableOpen] = useState<any>('open');
  const [isActiveFilter, setIsActiveFilter] = useState('all');
  const [postCreatedAt, setPostCreatedAt] = useState(gbpPostInitialDateFilter);
  const [selectedPostType, setSelectedPostType] = useState('ALL');

  useEffect(() => {
    getGbpAutomatedPostSettings();
    getGbpMediaLibraryImages({page: 1, pageSize: 7}, false);
  }, []);

  return (
    <StyledIssuesCollapse
      ghost
      expandIconPosition='right'
      defaultActiveKey={[`${isTableOpen}`]}
      activeKey={[`${isTableOpen}`]}
      onChange={() => {
        if (isTableOpen === 'close') {
          setIsTableOpen('open');
        } else {
          setIsTableOpen('close');
        }
      }}
    >
      {!removeTopBar && <GBPPostTableHeader
        componentIssueType={componentIssueType}
        setPageChanged={setPageChanged}
        setIssueTable={setIssueTable}
        issueTable={issueTable}
        setSearchText={setSearchText}
        searchText={searchText}
        setIsActiveFilter={setIsActiveFilter}
        isActiveFilter={isActiveFilter}
        postCreatedAt={postCreatedAt}
        setPostCreatedAt={setPostCreatedAt}
        selectedPostType={selectedPostType}
        setSelectedPostType={setSelectedPostType}
        isResponsiveHeader
      />}
      <Collapse.Panel key='open' header={<></>}>
        <GbpPostsTable
          showConfirmationPopup={showConfirmationPopup}
          setRecordEditing={setRecordEditing}
          setEditingPost={setEditingPost}
          deleteItem={deleteItem}
          setOpenGenerateGbpPostsModal={setOpenGenerateGbpPostsModal}
          issueTable={issueTable}
          componentIssueType={componentIssueType}
          setPageChanged={setPageChanged}
          setIssueTable={setIssueTable}
          searchText={searchText}
          isActiveFilter={isActiveFilter}
          postCreatedAt={postCreatedAt}
          selectedPostType={selectedPostType}
          removeTopBar={removeTopBar}
          maxHeight={maxHeight}
        />
      </Collapse.Panel>
    </StyledIssuesCollapse>
  );
});
