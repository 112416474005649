import {types, flow, Instance} from 'mobx-state-tree';
import {BULK_URL_ANALYZER_API} from '@/api/bulk-url-analyzer/analyzer-api';
import {notification} from '@/utils/notifications';
import {CONSTANT_BULK_URL_ANALYZER_LOCAL_STORE} from '@/api/common-utils';
import moment from 'moment';
import {notification as notify} from '@/utils/notification-v2';

type BulkUrlPayload = {
  country_code?: string;
  urls: Array<string>;
  export?: boolean;
};

const BulkUrlAnalyzerResultModel = types.model({
  url: types.maybeNull(types.string),
  domainRating: types.maybeNull(types.number),
  urlRating: types.maybeNull(types.number),
  traffic: types.maybeNull(types.number),
  keywordsCount: types.maybeNull(types.number),
  authorityScore: types.maybeNull(types.number),
  backlinksCount: types.maybeNull(types.number),
  domainsCount: types.maybeNull(types.number),
  citationFlow: types.maybeNull(types.number),
  trustFlow: types.maybeNull(types.number),
  trafficCost: types.maybeNull(types.number),
  paidKeywordsCount: types.maybeNull(types.number),
  paidTraffic: types.maybeNull(types.number),
  paidTrafficCost: types.maybeNull(types.number),
  dofollowPages: types.maybeNull(types.number),
  nofollowPages: types.maybeNull(types.number),
  domainPower: types.maybeNull(types.number),
  domainAuthority: types.maybeNull(types.number),
});

export const BulkUrlAnalyzerStore = types
  .model({
    requesting: types.maybeNull(types.boolean),
    results: types.array(BulkUrlAnalyzerResultModel),
  }).views(self => ({
    get getResults() {
      if (self.results.length) {
        return self.results;
      }
      let persistedData: any = localStorage.getItem(CONSTANT_BULK_URL_ANALYZER_LOCAL_STORE);
      if (persistedData) {
        persistedData = [...self.results, ...JSON.parse(persistedData)];
      } else {
        persistedData = self.results;
      }
      return persistedData;
    },
  }))
  .actions(self => {
    const setResults = results => {
      self.results = results;
    };

    const convertToCSV = data => {
      try {
        const date = moment().format('MMM-D');
        const filename = `Bulk-url-analyzer-${date}.csv`;

        const blob = new File([data], filename);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
      } catch (error) {
        notification.error('Failed to export data, something went wrong.', 'topRight');
      }
    };

    const normalizeUrl = url => {
      return url.replace(/^https?:\/\//, '');
    };

    const requestBulkAnalyzer = flow(function* (payload: BulkUrlPayload, isExport?: any) {
      try {
        if (!payload?.export) self.requesting = true;
        const response = yield BULK_URL_ANALYZER_API.getBulkUrlMetrics(payload);
        if (response.length) {
          let persistedData: any = localStorage.getItem(CONSTANT_BULK_URL_ANALYZER_LOCAL_STORE);
          if (persistedData) {
            const existingUrls = JSON.parse(persistedData).filter(item => {
              return payload?.urls?.some(url => url == item?.url || normalizeUrl(url)== item?.url);
            });
            if (existingUrls?.length > 0 && !isExport) {
              const existingUrlsList = existingUrls.map(item => item.url).join(', ');
              notify.info('Bulk URL Analyzer', `${existingUrlsList} already ${existingUrlsList.length>1 ? 'exist' : 'exists'}`);
            }
            const removedDuplication = JSON.parse(persistedData).filter(item => !payload?.urls?.some(url => url == item?.url || normalizeUrl(url) == item?.url));
            persistedData = [...response, ...removedDuplication];
          } else {
            persistedData = response;
          }
          if (!payload?.export) {
            localStorage.setItem(CONSTANT_BULK_URL_ANALYZER_LOCAL_STORE, JSON.stringify(persistedData));
            setResults(persistedData);
          } else convertToCSV(response);
        } else {
          setResults([]);
          notification.error(`No data found for given url${payload.urls.length > 1 ? 's' : ''}`, 'topRight');
        }
      } catch (error) {
        notification.error('Failed to get data, something went wrong.', 'topRight');
      } finally {
        self.requesting = false;
      }
    });

    const deleteUrl = url => {
      const persistedData: any = localStorage.getItem(CONSTANT_BULK_URL_ANALYZER_LOCAL_STORE);
      if (persistedData) {
        const foundUrl = JSON.parse(persistedData).find(item => url == item?.url);
        if (foundUrl) {
          const updatedUrls = JSON.parse(persistedData).filter(item => url != item?.url);
          localStorage.setItem(CONSTANT_BULK_URL_ANALYZER_LOCAL_STORE, JSON.stringify(updatedUrls));
          setResults(updatedUrls);
          notify.success('Deleted Successfully', `${url} deleted successfully.`);
        }
      }
    };

    return {requestBulkAnalyzer, deleteUrl};
  });


export const initBulkUrlAnalyzerStore = () => {
  return BulkUrlAnalyzerStore.create({
    requesting: false,
    results: [],
  });
};

export type BulkUrlAnalyzerStoreInstance = Instance<typeof BulkUrlAnalyzerStore>;
