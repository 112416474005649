import {observer} from 'mobx-react-lite';
import {useStore} from '@/store/root-store';
import {notification, Row, Col} from 'antd';
import styles from './styles.module.scss';
import {Input, Button} from '@/components/common-components/v2';
import {getLocation} from '@/utils/string';
import {NextImg} from '@/utils/nextImg';
import styled from 'styled-components';
import {useEffect, useState} from 'react';
import {notification as notify} from '@/utils/notification-v2';
import {LargeBadge} from '@/components/common-components/components/badge';


export const ApiKey= observer(({inputStyles = {}}: {inputStyles?: any}) => {
  const {settings: {customer: {profile}}} = useStore('');
  const {contentOptimizer: {pages: {getLlmApiKeys, updateLlmApiKeys, llmApiKeys, loadingSettingsUpdate}}} = useStore('');

  const [keys, setKeys] = useState<{openAIKey: string; anthropicKey: string}>({
    openAIKey: '',
    anthropicKey: '',
  });

  const handleInputChange = e => {
    const {name, value} = e.target;
    setKeys({
      ...keys,
      [name]: value,
    });
  };

  useEffect(()=> {
    getLlmApiKeys();
  }, []);

  useEffect(()=> {
    if (llmApiKeys) {
      setKeys(() => ({
        openAIKey: llmApiKeys?.openaiSecretKey?.apiKey || '',
        anthropicKey: llmApiKeys?.anthropicSecretKey?.apiKey || '',
      }));
    }
  }, [llmApiKeys]);

  const handleUpdateKeys = async () => {
    try {
      await updateLlmApiKeys({openai_secret_key: keys?.openAIKey, anthropic_secret_key: keys?.anthropicKey});
      notify.success('Success', 'Updated Successfully');
    } catch (error) {
      const errorObj = error?.response?.data;
      if ('anthropicSecretKey' in errorObj && 'openaiSecretKey' in errorObj) {
        notify.error(
          'Bring your own keys',
          `${errorObj.openaiSecretKey.join(' ')}\n${errorObj.anthropicSecretKey.join(' ')}`,
        );
      } else if ('anthropicSecretKey' in errorObj) {
        notify.error('Anthropic secret key', errorObj?.anthropicSecretKey.join(' '));
      } else if ('openaiSecretKey' in errorObj) {
        notify.error('OpenAI secret key', errorObj?.openaiSecretKey.join(''));
      }
      setKeys(() => ({
        openAIKey: llmApiKeys?.openaiSecretKey?.apiKey || '',
        anthropicKey: llmApiKeys?.anthropicSecretKey?.apiKey || '',
      }));
    }
  };

  const handleCopyTxt = entryText => {
    navigator.clipboard.writeText(entryText);
    notification.success({
      message: 'API Key is copied to clipboard',
      placement: 'bottomRight',
    });
  };
  const title = 'Search Atlas API Key';
  return (
    <div className={styles.topHeading}>
      {!profile.isWhitelabel ? title : title?.replace('Search Atlas', getLocation(location))}
      <Row gutter={24}>
        <Col xl={18} md={18} xs={24} className={styles.container}> <StyledInput inputStyle={inputStyles} className={styles.keyContainer} value={profile?.searchatlasApiKey}/></Col>
        <Col xl={6} md={6} xs={24} style= {{marginTop: '24px'}}><div className={styles.footerRight} onClick={() => handleCopyTxt(profile?.searchatlasApiKey)}><NextImg src='/img/copy-white.svg' width={14} height={14} />Copy API Key</div></Col>
      </Row>
      {profile?.plan==8 && <StyleDive>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <div className={styles.topHeading}>Bring your own keys</div>
          <LargeBadge beta/>
        </div>
        <div>
          <Stylespan>OpenAI secret key</Stylespan>
          <><StyledInput inputStyle={inputStyles} disabled={loadingSettingsUpdate} name={'openAIKey'} value={keys.openAIKey} onChange={handleInputChange}/><KeyExpireWraper>{llmApiKeys?.openaiSecretKey?.message}</KeyExpireWraper></>
        </div>
        <div>
          <Stylespan>Anthropic secret key</Stylespan>
          <><StyledInput inputStyle={inputStyles} disabled={loadingSettingsUpdate} name={'anthropicKey'} value={keys.anthropicKey} onChange={handleInputChange}/><KeyExpireWraper>{llmApiKeys?.anthropicSecretKey?.message}</KeyExpireWraper></>
          <ButtonStyle>
            <Button loading={loadingSettingsUpdate} onClick={handleUpdateKeys}>Update keys</Button>
          </ButtonStyle>
        </div>
      </StyleDive>}
    </div>
  );
});

const StyleDive = styled.div`
border-top: 1px solid #dfdfdf;
margin-top: 35px;
`;

const Stylespan = styled.div`
  font-size: 15px;
  color: #696969;
  margin-top: 21px;
  margin-bottom: 4px;
`;

const StyledInput = styled(Input)<{inputStyle?: any}>`
  border-radius: 8px;
  ${p => p.inputStyle && {...p.inputStyle}};
`;

const ButtonStyle = styled.div`
Button{
    margin: 0px;
    padding: 9px 25px;
    margin-top: 21px;

}
  //   @media (max-width: 767px) {
  //   Button {
  //     width: 100%;
  //     text-align: center;
  //   }
  // }

`;

const KeyExpireWraper = styled.span`
  font-size: 14px;
  color: red;
  margin: 5px;
`;
