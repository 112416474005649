import {observer} from 'mobx-react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowUpRightFromSquare, faArrowLeft, faArrowRight} from '@fortawesome/pro-regular-svg-icons';
import ReactEcharts from 'echarts-for-react';
import {formatLargeNumber} from '@/utils/number';
import {
  HeadingTitle,
  ModalConfig,
  UrlDescription,
  PrevNextContainer,
  PrevNextButton,
} from '../styledComponents';
import {useStore} from '@/store/root-store';
import {Tooltip} from 'antd';

export const ListContactsHeader = observer(({result, index, handlePrevClick, handleNextClick, rowSelect, handleBulkDelete, showBulkDeleteButton}) => {
  const {
    campaigns: {opportunityMetrics, contactsCount, campaignsContactLoading},
  } = useStore('');

  const chartOption={
    series: [{

      type: 'gauge',
      startAngle: 190,
      endAngle: 350,
      itemStyle: {
        color: '#2D6CCA',
      },
      progress: {
        show: true,
        width: 5,
      },
      axisLine: {
        lineStyle: {
          width: 5,

        },
      },
      pointer: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        distance: -13,
        show: false,
        length: 10,
        lineStyle: {
          color: '#2D2F34',
          width: 0,
        },
      },
      splitNumber: 0,
      axisLabel: {
        show: false,
        color: '#2D6CCA',
        distance: 40,
        fontSize: 20,
      },
      detail: {
        show: true,
        offsetCenter: [0, '15%'],
        formatter: value => value || value==0 ? `${value}` : '',
        color: 'white',
        fontSize: 11,
      },
      data: [{
        value: `${opportunityMetrics?.ahrefsDr}`,
      }],
    }],
  };

  return <>
    <PrevNextContainer>
      <Tooltip title='Show Previous Opportunity'>
        <PrevNextButton
          onClick={handlePrevClick}
          disabled={index === 0 || campaignsContactLoading}
        >
          <FontAwesomeIcon icon={faArrowLeft} /> Prev
        </PrevNextButton>
      </Tooltip>
      <Tooltip title='Show Next Opportunity'>
        <PrevNextButton
          onClick={handleNextClick}
          disabled={index === result?.length - 1 || campaignsContactLoading}
        >
        Next <FontAwesomeIcon icon={faArrowRight} />
        </PrevNextButton>
      </Tooltip>
    </PrevNextContainer>
    <HeadingTitle>{opportunityMetrics?.title}<span></span></HeadingTitle>
    <UrlDescription><a target='_blank' style={{color: '#2D6CCA'}} href={opportunityMetrics?.url} rel='noreferrer'>{opportunityMetrics?.url}<FontAwesomeIcon style={{cursor: 'pointer'}} icon={faArrowUpRightFromSquare} color='#2D6CCA'/></a></UrlDescription>
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
      <div style={{padding: '6px 0 0 0'}}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <ModalConfig><span>Domain Rating <ReactEcharts style={{width: 24, height: 24}} option={chartOption} /><b>{opportunityMetrics?.ahrefsDr}</b></span></ModalConfig>
          <ModalConfig>Org. Keywords: <b>{opportunityMetrics?.ahrefsOk ? formatLargeNumber(opportunityMetrics?.ahrefsOk) : 0}</b></ModalConfig>
          <ModalConfig>Org. Traffic: <b>{opportunityMetrics?.ahrefsOt ? formatLargeNumber(opportunityMetrics?.ahrefsOt) : 0}</b></ModalConfig>
          <ModalConfig>Ref. Domains: <b>{opportunityMetrics?.ahrefsRefdomains ? formatLargeNumber(opportunityMetrics?.ahrefsRefdomains) : 0}</b></ModalConfig>
        </div>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <ModalConfig>Contacts: <b>{contactsCount}</b></ModalConfig>
          <ModalConfig>Opportunity status:&nbsp;
            <b>{rowSelect?.isScheduled ? 'Outreach' : 'Discovered'}</b>
          </ModalConfig>
        </div>
      </div>
      {showBulkDeleteButton && <div style={{color: 'red', cursor: 'pointer'}} onClick={handleBulkDelete}>
        Delete
      </div>}
    </div>
    <br/>
  </>;
});
