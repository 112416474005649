import React, {useEffect, useState, useRef} from 'react';
import {SearchV2} from '@/components/common-components/v2/searchV2';
import {
  // TableStyle,
  TasksWrapper,
} from '../seo-projects-list-v2/styled';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useStore} from '@/store/root-store';
import {faArrowUpRightFromSquare} from '@fortawesome/pro-light-svg-icons';
import {faCircle, faEllipsisVertical, faSortDown, faArrowUpRightFromSquare as arrowRightSolid, faPause, faSearch, faXmark} from '@fortawesome/pro-solid-svg-icons';
import {PopoverWrapper, SpanWrapper} from '../local-seo-list/styled';
import {DeleteOutlined, EditOutlined} from '@ant-design/icons';
import styled from 'styled-components';
import moment from 'moment';
import {observer} from 'mobx-react';
import {SkeletonHorizontalLoaderGray} from '@/components/common-components';
import {keysMapping} from './index';
import {TableStyled} from '../../home-page-main/components/table-styled';
import {DeleteConfirmModal} from '@/components/dashboard/gsc/gsc/PageGroupings/blocks/Groups/DeleteConfirm';
import {Modal} from '@/components/common-components/components';
import {openUrl} from '@/utils/router';
import {Button, Dropdown, Menu, Progress, Tooltip} from 'antd';
import {StyledSpin} from '../../otto-page-detail/Utils/styledSpin';
import {faFileArrowDown, faArrowUp, faCheck, faListCheck, faMagnifyingGlass} from '@fortawesome/pro-regular-svg-icons';
import {useRouter} from 'next/router';
interface Props {
  setVisible?: (boolean) => void;
  setSelectedId?: (number) => void;
  selectedId?: number;
  setIsEdit?: (boolean) => void;
  setSelectedBusiness?: (value) => void;
}

export const CitationBuilderTable = observer(({setVisible, selectedId, setSelectedId, setIsEdit, setSelectedBusiness}: Props) => {
  const {localSeoScan: {loadAggregatorNetwork, loadingAggregatorNetwork, aggregatorNetwork, deleteAggregatorNetwork, deletingAggregatorNetwork, loadCitationBusinessDetail, citationBusinessDetail, gettingCitationBusinessDetail, exportCitationTableDetail, loadingExportCitation, setCID, loadSeoProjectsListV3},
    ottoV2Store: {selectedBusiness}} = useStore('');
  const [inputValue, setInputValue] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [openCitationModal, setOpenCitationModal] = useState(false);
  const [screenWidth, setScreenWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);
  const [citationsData, setCitationsData] = useState([]);
  const [openedBusinessInModal, setOpenedBusinessInModal] = useState(null);
  const [filteredData, setFilteredData] = useState([]);

  const router = useRouter();
  const onDelete = async () => {
    const res = await deleteAggregatorNetwork(selectedId);
    if (res?.success) {
      setSelectedId(null);
      setIsModalVisible(false);
    }
  };
  const onDeleteCancel = () => {
    setIsModalVisible(false);
    setSelectedId(null);
  };

  useEffect(() => {
    const data = JSON.parse(JSON.stringify(aggregatorNetwork));
    setFilteredData(inputValue ? data?.filter(item => item.businessName?.toLowerCase()?.includes(inputValue?.toLowerCase())) : data);
  }, [JSON.stringify(aggregatorNetwork), inputValue]);

  const onCitationView = async record => {
    setOpenedBusinessInModal(record);
    setOpenCitationModal(true);
    await loadCitationBusinessDetail(record?.id);
  };

  const DownloadLink = useRef<HTMLAnchorElement>(null);

  useEffect(()=> {
    if (router?.query?.cid && router?.pathname?.includes('gbp-galactic/')) {
      setCID(router?.query?.cid ?? null);
      setInputValue(selectedBusiness?.[0]?.businessName);
    }
    loadAggregatorNetwork();
    return () => {
      setCID(null);
      setInputValue('');
    };
  }, []);

  useEffect(() => {
    loadAggregatorNetwork();
  }, []);

  useEffect(() => {
    if (!gettingCitationBusinessDetail && citationBusinessDetail?.length) {
      setCitationsData(citationBusinessDetail || []);
    }
  }, [JSON.stringify(citationBusinessDetail)]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onChangeSearch = query => {
    if (router?.query?.cid) {
      router.replace(router.pathname, undefined, {shallow: true});
      setCID(null);
      loadSeoProjectsListV3();
    }
    setInputValue(query?.toLowerCase());
  };

  const content = (record: any) => {
    return (
      <><div>
        <button type='button' style={{outline: 'none', border: 'none', background: 'white'}} onClick={() => {
          setIsEdit(true);
          setSelectedId(record?.id);
          setVisible(true);
          setSelectedBusiness(record);
        } }>
          <p style={{cursor: 'pointer', marginBottom: 0, whiteSpace: 'nowrap'}}><EditOutlined /> Edit & Submit</p>
        </button>
      </div>
      <div>
        <button disabled={deletingAggregatorNetwork} type='button' style={{outline: 'none', border: 'none', background: 'white'}} onClick={() => {
          setIsModalVisible(true);
          setSelectedId(record?.id);
        }}>
          <p style={{cursor: 'pointer', marginBottom: 0, whiteSpace: 'nowrap'}}><DeleteOutlined /> Delete</p>
        </button>
      </div></>
    );
  };

  const getStatusConf = (status: string) => {
    switch (status) {
      case 'Saved':
      case 'Submitting To Sites':
        return {
          label: 'Submitting',
          icon: faArrowUp,
          strokeColor: '#F1AA3E',
          percentage: 30,
        };
      case 'Paid':
        return {
          label: 'Quality assurance',
          icon: faMagnifyingGlass,
          strokeColor: '#2AC155',
          percentage: 50,
        };
      case 'On Hold':
        return {
          label: 'On hold',
          icon: faPause,
          strokeColor: '#F44343',
          percentage: 100,
        };
      case 'Live Listing Checks':
      case 'Completed':
      case 'Complete':
        return {
          label: 'Live checks',
          icon: faListCheck,
          strokeColor: '#2AC155',
          percentage: 75,
        };
      case 'Finished':
        return {
          label: 'Completed',
          icon: faCheck,
          strokeColor: '#2AC155',
          percentage: 100,
        };
      default:
        return {
          label: 'Submitting',
          icon: faArrowUp,
          strokeColor: '#F1AA3E',
          percentage: 30,
        };
    }
  };

  const getTooltipTitle = (status: string, createdAt?: string) => {
    switch (status) {
      case 'Saved':
      case 'Submitting To Sites':
        return `Order has been paid and submitted for verification ${moment(createdAt).isValid ? 'on ' + moment(createdAt).format('MMM DD, YYYY') : ''}.`;
      case 'Paid':
        return 'Your submission has been verified successfully. We are now initiating the Quality Assurance process and performing a Live listing verification.';
      case 'On Hold':
        return `We've noticed some issues with the campaign data and will contact you soon if we need any further clarification. Thank you for your patience`;
      case 'Live Listing Checks':
      case 'Completed':
      case 'Complete':
        return 'Your submission has successfully passed the Quality Assurance check. We are now focusing on the final phase of live listing verification.';
      case 'Finished':
        return 'The process is now complete. We guarantee that 70% of your listings are live. If any listings are missing, they should go live within the next few days or weeks.';
      default:
        return `Order has been paid and submitted for verification ${moment(createdAt).isValid ? 'on ' + moment(createdAt).format('MMM DD, YYYY') : ''}.`;
    }
  };

  const columnsData = [
    {
      title: <div>BUSINESS</div>,
      dataIndex: 'business_name',
      key: 'businessName',
      width: screenWidth > 650 ? 250 : 100,
      color: '#17181A',
      ellipsis: true,
      align: 'left' as 'left',
      render: (_, data) => {
        if (loadingAggregatorNetwork) {
          return <SkeletonHorizontalLoaderGray lightLoader width='30px' height='10px' align='left' />;
        }
        return <div style={{margin: '5px 0'}}>
          <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
            <div style={{color: '#2D6CCA', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis'}}>{data?.businessName ?? '-'}</div>
            &nbsp;&nbsp;<a href={data?.websiteAddress} target='_blank' rel='noreferrer'><FontAwesomeIcon icon={faArrowUpRightFromSquare} color='#2D6CCA' /></a>
          </div>
          <p style={{fontSize: '12px', lineHeight: '10px', color: '#4E5156', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis'}}>{data.address1}</p>
        </div>;
      },
    },
    {
      title: <div>STATUS</div>,
      dataIndex: 'status',
      key: 'status',
      width: 120,
      align: 'left' as 'left',
      render: (_, data) => {
        const statusData = getStatusConf(data?.campaignStatus);
        if (loadingAggregatorNetwork) {
          return <SkeletonHorizontalLoaderGray lightLoader width='30px' height='10px' align='left' />;
        }
        return (
          <Tooltip overlayStyle={{maxWidth: 370}} overlayInnerStyle={{backgroundColor: '#000', borderRadius: 6}} placement={'left'} title={getTooltipTitle(data?.campaignStatus, data?.createdAt)}>
            <StatusDiv>
              <div className='label-icon'>
                <FontAwesomeIcon icon={statusData?.icon} color={statusData?.strokeColor} />
                <div>{statusData?.label}</div>
              </div>
              <StyledProgress percent={statusData?.percentage} size='small' strokeColor={statusData?.strokeColor} />
            </StatusDiv>
          </Tooltip>
        );
      },
    },
    {
      title: <div>DATA<br />AGGREGATOR</div>,
      dataIndex: 'aggregators',
      key: 'aggregators',
      width: screenWidth > 650 ? 150 : 100,
      color: '#17181A',
      sortFieldName: 'aggregators',
      align: 'left' as 'left',
      render: (_, data) => {
        return <div style={{display: 'flex', alignItems: 'center', gap: '8px', paddingLeft: '5px'}}>
          <div style={{fontSize: '12px'}}>{data?.aggregators?.length ? data?.aggregators?.map(aggre => keysMapping[aggre]).join(', ') : '-'}</div>
        </div>;
      },
    },
    {
      title: <div>AMOUNT</div>,
      dataIndex: 'amountPaid',
      key: 'amountPaid',
      width: 50,
      color: '#17181A',
      sorter: (a: any, b: any) => Number(a.amountPaid) - Number(b.amountPaid),
      align: 'right' as 'right',
      render: (_, data) => {
        return <div style={{marginRight: 10}}>
          <div style={{textAlign: 'end'}}>{`${data?.amountPaid || 'NA'} Credits`} </div>
        </div>;
      },
    },
    {
      title: <div>SUBMITTED ON</div>,
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 100,
      color: '#17181A',
      align: 'right' as 'right',
      render: (_, data) => {
        return <div>
          <div>{moment(data?.createdAt).format('MMM DD, YYYY')}</div>
        </div>;
      },
    },
    {
      title: '',
      dataIndex: 'citations',
      key: 'citations',
      width: 100,
      color: '#17181A',
      render: (_, record) => {
        return <ViewCitationButton onClick={() => onCitationView(record)}>View citations</ViewCitationButton>;
      },
    },
    {
      title: '',
      dataIndex: 'view',
      key: 'view',
      width: 30,
      color: '#17181A',
      render: (_, record) => {
        return <div>
          <PopoverWrapper overlayClassName={'customPopover'} placement='bottomLeft'
            content={content(record)} trigger='hover'>
            <SpanWrapper>
              <FontAwesomeIcon icon={faEllipsisVertical} color='#4E5156' />
            </SpanWrapper>
          </PopoverWrapper>
        </div>;
      },
    },
  ];

  const citationColumns = [
    {
      title: 'AGGREGATOR / NETWORK',
      dataIndex: 'aggregator',
      key: 'aggregator',
      width: '200px',
      color: '#17181A',
      render: (_, record) => {
        return <div className='website'>{record?.citation}</div>;
      },
    },
    {
      title: 'WEBSITE',
      dataIndex: 'website',
      key: 'website',
      width: '130px',
      color: '#17181A',
      render: (_, record) => {
        return <div className='domain-authority'>{record?.website}</div>;
      },
    },
    {
      title: 'DATE',
      dataIndex: 'date',
      key: 'date',
      color: '#17181A',
      render: (_, record) => {
        const date = moment(record?.date)?.isValid() ? moment(record?.date)?.format('MMM DD, YYYY') : '-';
        return <div className='domain-authority'>{date}</div>;
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      width: '120px',
      color: '#17181A',
      render: (_, record) => {
        return <div className={`status-tag ${record?.status !== 'Live' ? 'gray' : 'green'}`}>
          <FontAwesomeIcon icon={faCircle} color={record?.status === 'Live' ? '#1FAC47' : '#A3A4A4'} fontSize={8} /> {record?.status}
        </div>;
      },
    },
    {
      title: 'NOTES',
      dataIndex: 'notes',
      key: 'notes',
      color: '#17181A',
      render: (_, record) => {
        return (record?.notes || record?.note) ? <div className='notes'>Show
          <Dropdown
            trigger={['click']}
            placement='bottomRight'
            overlay={
              <Menu style={{width: '400px', padding: '10px', borderRadius: '6px', overflow: 'hidden'}}
              >{record?.notes || record?.note}</Menu>
            }>
            <FontAwesomeIcon style={{cursor: 'pointer'}} icon={faSortDown} color='#121212' fontSize={12} />
          </Dropdown>
        </div> : <div style={{textAlign: 'left'}}>-</div>;
      },
    },
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'citation_url',
      color: '#17181A',
      render: (_, record) => {
        return record?.url ? <div className='citation-url' onClick={() => openUrl(record?.url, '_blank')}><FontAwesomeIcon icon={arrowRightSolid} color='#2D6CCA' fontSize={16} />View</div> : <div style={{textAlign: 'left'}}>-</div>;
      },
    },
  ];

  const handleExport = async () => {
    const data = await exportCitationTableDetail(openedBusinessInModal?.id);
    DownloadLink.current!.href = URL.createObjectURL(new Blob([data], {type: 'text/csv'}));
    let fileName = 'file';
    fileName = fileName.replace(/\.(.*)$/, '');
    DownloadLink.current.download = fileName + '_top_ pages';
    DownloadLink.current.click();
  };
  const extraStatusProgressColors = {
    'Submitting To Sites': '#F1AA3E',
    'Saved': '#F1AA3E',
    'On Hold': '#F44343',
  };

  const timeLineArr = [
    {
      status: 'Submitted',
      days: 'Days 1-7',
      icon: faArrowUp,
      completelyFilled: ['Completed', 'Complete', 'Live Listing Checks', 'Paid', 'On Hold', 'Finished'].includes(openedBusinessInModal?.campaignStatus),
      partiallyFilled: ['Submitting To Sites', 'Saved'].includes(openedBusinessInModal?.campaignStatus),
      tooltipText: getTooltipTitle('Saved', openedBusinessInModal?.createdAt),
      progressColor: extraStatusProgressColors[openedBusinessInModal?.campaignStatus] || '#2AC155',
    },
    {
      status: 'Quality Assurance',
      days: 'Days 8-14',
      icon: faSearch,
      completelyFilled: ['Completed', 'Complete', 'Live Listing Checks', 'On Hold', 'Finished'].includes(openedBusinessInModal?.campaignStatus),
      partiallyFilled: ['Paid'].includes(openedBusinessInModal?.campaignStatus),
      tooltipText: getTooltipTitle('Paid', openedBusinessInModal?.createdAt),
      progressColor: extraStatusProgressColors[openedBusinessInModal?.campaignStatus] || '#2AC155',
    },
    {
      status: 'Live Checks',
      days: 'Days 22-37',
      icon: faListCheck,
      completelyFilled: ['On Hold', 'Finished'].includes(openedBusinessInModal?.campaignStatus),
      partiallyFilled: ['Live Listing Checks', 'Completed', 'Complete'].includes(openedBusinessInModal?.campaignStatus),
      tooltipText: getTooltipTitle('Live Listing Checks', openedBusinessInModal?.createdAt),
      progressColor: extraStatusProgressColors[openedBusinessInModal?.campaignStatus] || '#2AC155',
    },
    {
      status: ['On Hold'].includes(openedBusinessInModal?.campaignStatus) ? 'On Hold' : 'Completed',
      days: 'Day 42',
      icon: ['On Hold'].includes(openedBusinessInModal?.campaignStatus) ? faPause : faCheck,
      completelyFilled: ['Finished', 'On Hold'].includes(openedBusinessInModal?.campaignStatus),
      partiallyFilled: ['Finished', 'On Hold'].includes(openedBusinessInModal?.campaignStatus),
      tooltipText: getTooltipTitle(['On Hold'].includes(openedBusinessInModal?.campaignStatus) ? 'On Hold' : 'Finished', openedBusinessInModal?.createdAt),
      progressColor: extraStatusProgressColors[openedBusinessInModal?.campaignStatus] || '#2AC155',
    },
  ];

  return (
    <>
      <Wrapper style={{display: 'flex', justifyContent: 'center', marginTop: '-81px'}}>
        <TableWrapper>
          <div className='search-wrapper'>
            <SearchV2 value={inputValue} onSearchChange={onChangeSearch} Placeholder='Search' allowClear={true}/>
            <span className='spanFull' style={{textAlign: 'left', marginLeft: 23}}>Successfully submitted: <span className='count'>{filteredData?.length || 0}</span></span>
          </div>
          <TableStyle
            className='table'
            dataSource={filteredData || []}
            columns={columnsData}
            loading={loadingAggregatorNetwork}
            loadingRows={10}
          />
          <StyledPaginationWrapper>
          </StyledPaginationWrapper>
        </TableWrapper>
        <DeleteConfirmModal
          isDeleting={deletingAggregatorNetwork}
          isVisible={isModalVisible}
          warningText='Are you sure you want to delete this business?'
          onCancel={onDeleteCancel}
          onConfirm={onDelete}
        />
        <StyledModal
          width={'850px'}
          height={'91vh'}
          visible={openCitationModal}
          closeIcon={<div><FontAwesomeIcon color={'#fff'} fontSize={22} icon={faXmark} /></div>}
          onClose={() => setOpenCitationModal(false)}>
          <div className='wrapper'>
            <div className='header'>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div className='heading'>Data Aggregator Submissions</div>
                <div>
                  {!gettingCitationBusinessDetail && <Button
                    disabled={loadingExportCitation}
                    color='white'
                    onClick={() => handleExport()}
                  ><FontAwesomeIcon style={{marginRight: 7}} icon={faFileArrowDown} className={''} />
                  Export</Button>}
                  <a ref={DownloadLink}></a>
                </div>
              </div>
              <div style={{display: 'flex', alignItems: 'center', gap: 6, marginBottom: 62}}>
                {timeLineArr?.map((item, idx) => (
                  <TimelineBlock key={item?.status} isLast={idx !== timeLineArr?.length -1}>
                    <div style={{borderRadius: 9999, background: item?.partiallyFilled || item?.completelyFilled ? item?.progressColor : '#A3A4A4', display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: 28, minHeight: 28, position: 'relative'}}>
                      <FontAwesomeIcon icon={item?.icon} color='#fff' fontSize={14} />
                      <div style={{position: 'absolute', left: 0, bottom: openedBusinessInModal?.campaignStatus === 'On Hold' ? -30 : -50, whiteSpace: 'nowrap'}}>
                        <Tooltip placement='bottom' overlayStyle={{maxWidth: 350}} overlayInnerStyle={{backgroundColor: '#000', borderRadius: 10}} title={item?.partiallyFilled || item?.completelyFilled ? item?.tooltipText : ''}>
                          <div>
                            <div style={{color: '#000', lineHeight: '20px'}}>{item?.status}</div>
                            {openedBusinessInModal?.campaignStatus === 'On Hold' ? '' : <div style={{color: '#4E5156', fontSize: 12}}>{item?.days}</div>}
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                    <Progress percent={item?.completelyFilled ? 100 : item?.partiallyFilled ? 50 : 0} strokeColor={item?.progressColor} trailColor='rgba(163, 164, 164, 0.2)' type='line' />
                  </TimelineBlock>
                ))}
              </div>
              <div className='description'>
                {gettingCitationBusinessDetail ?
                  <StyledSpin fontSize={14} color={'#2D6CCA'} /> :
                  citationsData?.filter(citation => citation?.status !== 'Omitted')?.length} of {gettingCitationBusinessDetail ?
                  <StyledSpin fontSize={14} color={'#2D6CCA'} /> :
                  citationsData?.length} citations found for <Domain onClick={() => openUrl(openedBusinessInModal?.websiteAddress, '_blank')}>{openedBusinessInModal?.businessName}</Domain>
                <span className={'campaign-id'}>(campaign id-{openedBusinessInModal?.id})</span>
              </div>
            </div>
            <div className='body-content'>
              <CitationTable
                dataSource={citationsData}
                columns={citationColumns}
                loading={gettingCitationBusinessDetail}
                loadingRows={10}
                pagination={false}
                scroll={{y: '63vh'}}
              />
            </div>
          </div>
        </StyledModal>
      </Wrapper>
    </>
  );
});

const TimelineBlock = styled.div<{isLast?: boolean}>`
  display: flex;
  gap: 6px;
  align-items: center;
  ${p => p?.isLast ? 'flex-grow: 1;' : 'min-width: 60px;'}
  .ant-progress-text {
    display: none;
  }
  .ant-progress-outer {
    margin-right: 0 !important;
    padding-right: 0 !important;
  }
  .ant-progress-inner {
    border-radius: 0px !important;
    ${p => p?.isLast ? '' : `
      border-top-right-radius: 100px !important;
      border-bottom-right-radius: 100px !important;
    `}
  }
  .ant-progress-bg {
    height: 10px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
`;

const StatusDiv = styled.div`
  .label-icon {
    display: flex;
    align-items: center;
    gap: 5px;
  }
`;

const StyledPaginationWrapper = styled(TasksWrapper)`
  @media screen and (max-width: 800px) {
    padding-bottom: 8px;
    .ant-pagination {
      width: fit-content !important;
      padding-right: 0 !important;
      padding-left: 0 !important;
      margin-right: auto !important;
      margin-left: auto !important;
      text-align: center;
    }
  }
  @media screen and (max-width:540px) {
    .ant-pagination-options {
      margin: 0 0 0 4px !important;
      display: inline-block !important;
    }
  }
  @media screen and (max-width:450px) {
    .ant-pagination-total-text {
      margin: 0 !important;
    }
    .ant-pagination-item, .ant-pagination-item-active {
      margin-right: 4px !important;
    }
    .ant-pagination-prev {
      margin-right: 4px !important;
    }
  }
`;

export const Wrapper = styled.div`
  padding: 0 85px;
  .ant-pagination {
    padding-right: 10px;
  }
  @media screen and (max-width: 1440px) {
    padding: 0 40px;
  }
  @media screen and (max-width: 800px) {
    padding: 0 15px;
  }
`;

export const TableWrapper = styled.div`
  background: #FFFFFF;
  width: 100%;
  margin-bottom: 30px;
  border-radius: 12px;
  border: 1px solid #E8E8E8;
  pointer-events: auto;
  padding-bottom: 10px;

  table .ant-table-tbody tr:last-child td {
    border-bottom: none !important;
  }

  .ant-table-content {
    height: 100% !important;
    margin: 0px 10px !important;
  }
  .ant-table-content > table {
    table-layout: auto !important;
    tr > th{
      padding-right: 15px !important;
    }
  }
  .ant-table-tbody {
    tr > td {
      padding-right: 15px !important;
      background-color: red;
    }
  }
  .ant-input-affix-wrapper{
    margin: 13px;
    width: 100%;
    height: 32px;
  }
  .search-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #4E5156;
    font-family: Inter;
    .count {
      font-weight: 600;
    }

    @media screen and (max-width: 550px) {
      display: flex;
      flex-direction: column;
      .spanFull {
        width: 100% !important;
    }
  }
}
 `;

const TableStyle = styled(TableStyled)`
  table-layout: none !important;

  @media screen and (max-width: 800px) {
  .ant-table-tbody > tr > td:first-child, table > thead > tr > th:first-child {
    padding: 10px !important;
  }
}
`;
export const CitationTable = styled(TableStyled)`
  table {
    padding: 0px !important;
  }
  .ant-table {
    padding: 6px !important;
    border-radius: 16px !important;
    border: 1px solid #D2D2D2 !important;
    box-shadow: 0px 3px 2px 0px #00000005;
    overflow: hidden;
  }
  .ant-table-thead {
    border-radius: 16px;
    tr {
      th:first-child {
        border-top-left-radius: 16px !important;
        border-bottom-left-radius: 16px !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
      }
      th:last-child {
        border-top-right-radius: 16px !important;
        border-bottom-right-radius: 16px !important;
      }
      th {
        &.ant-table-cell-scrollbar {
          display: none !important;
        }
        &:nth-last-child(2) {
          border-top-right-radius: 16px !important;
          border-bottom-right-radius: 16px !important;
        }
      }
    }
  }
  .ant-table-tbody {
    tr {
      td:first-child {
        padding: 0px 10px !important;
      }
      .ant-table-cell {
        .citation-value, .type, .notes, .citation-url, .website, .domain-authority {
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          font-weight: 400;
          text-align: left;
          color: #121212;
        }
        .status-tag {
          max-width: 110px;
          padding: 6px 10px;
          border-radius: 6px;
          gap: 5px;
          width: fit-content;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          font-weight: 400;

          &.green {
            background-color: rgba(31, 172, 71, 0.1);
          }
          &.gray {
            background-color: rgba(242, 242, 245, 1);
          }
        }
        .notes {
          display: flex;
          align-items: center;
          gap: 5px;
          svg {
            margin-top: -4px;
          }
        }
        .citation-url {
          max-width: 73px;
          border-radius: 6px;
          padding: 6px 10px;
          background-color: rgba(45, 108, 202, 0.13);
          color: #2D6CCA;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
        }
      }
    }
  }
`;
const StyledModal = styled(Modal)`
   .rc-dialog-content {
    .rc-dialog-body {
      background-color: #F9F9FB;
      padding: 25px !important;
      border-radius: 10px;
      .wrapper {
        .header {
          margin-bottom: 20px;
          .heading {
            font-family: 'Inter', sans-serif;
            font-size: 24px;
            font-weight: 600;
            color: #121212;
          }
          .description {
            display: flex;
            gap: 4px;
            font-family: 'Inter', sans-serif;
            font-size: 14px;
            font-weight: 500;
            color: #4E5156;
            .campaign-id {
              font-family: 'Inter', sans-serif;
              font-size: 14px;
              font-weight: 400;
              color: #000000;
              line-height: 20px;
            }
          }
        }
      }
    }
    .rc-dialog-close {
    font-size: 21px;
    right: -34px;
    top: 0px;
    font-weight: 700;
    color: #fff !important;
    opacity: 0.70 !important;
    &:hover {
      opacity: 1 !important;
    }
    }
  }
`;
const ViewCitationButton = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #2D6CCA;
  padding: 7px 10px;
  border-radius: 6px;
  background-color: rgba(45, 108, 202, 0.12);
  cursor: pointer;
`;
const Domain = styled.span`
  color: #2D6CCA;
  cursor: pointer;
`;

const StyledProgress = styled(Progress)`
width: 130px;
.ant-progress-text {
  display: none !important;
}
.ant-progress-inner {
  background-color: #E8E8E8 !important;
}
.ant-progress-outer {
  padding-right: 0px !important;
   }
`;
