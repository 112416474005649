import {useState} from 'react';

const useGeocodeConverter = () => {
  const [address, setAddress] = useState({
    street: '',
    street2: '',
    postalCode: '',
    city: '',
  });

  const getLatLngFromAddress = async address2 => {
    const addressObj = address2 ? address2 : address;
    const fullAddress = `${addressObj?.street} ${addressObj?.street2} ${addressObj?.city} ${addressObj?.postalCode}`;

    try {
      const latLng = await getCoordinatesFromAddress(fullAddress);
      return latLng;
    } catch (error) {
      console.error('Error retrieving coordinates:', error);
    }
  };

  const getCoordinatesFromAddress = async addressString => {
    // @ts-ignore
    const geocoder = new window.google.maps.Geocoder();

    return new Promise((resolve, reject) => {
      geocoder.geocode({address: addressString}, (results, status) => {
        if (status === 'OK' && results[0]) {
          const latLng = results[0].geometry.location;
          const lat = latLng.lat();
          const lng = latLng.lng();
          resolve({lat, lng});
        } else {
          reject(new Error('Geocode failed: Unable to retrieve coordinates.'));
        }
      });
    });
  };

  const addressFromCoordinates = async (lat, lng) => {
    // @ts-ignore
    const geocoder = new window.google.maps.Geocoder();
    // @ts-ignore
    const latLng = new window.google.maps.LatLng(lat, lng);

    return new Promise((resolve, reject) => {
      geocoder.geocode({location: latLng}, (results, status) => {
        if (status === 'OK' && results[0]) {
          const address = results[0];
          const addressObj = {
            address_lines_1:
              (address.address_components?.[0]?.long_name ?? '') + ', ' +
              (address.address_components?.[1]?.long_name ?? ''),
            address_lines_2: '',
            address_postal_code: address.address_components?.find(comp => comp.types.includes('postal_code'))?.long_name ?? null,
            address_locality: address.address_components?.find(comp => comp.types.includes('locality'))?.long_name ?? null,
            country: address.address_components?.find(comp => comp.types.includes('country'))?.long_name ?? null,
          };
          resolve(addressObj);
        } else {
          reject(new Error('Geocode failed: Unable to retrieve address.'));
        }
      });
    });
  };


  return {
    address,
    setAddress,
    getLatLngFromAddress,
    addressFromCoordinates,
  };
};

export default useGeocodeConverter;
