import {Form, Input} from 'antd';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {ButtonStyled, LoaderOverlay, ModalStyled} from '../../GbpOptimization/styles';
import {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark} from '@fortawesome/pro-solid-svg-icons';
import {StyledSpin} from '../../../../Utils/styledSpin';

interface Props {
  descriptionValue?: string;
  onUpdate?: any;
  visible: boolean;
  setVisible: any;
  suggestionObj: any;
  modalLoader: boolean;
  setModalLoader: any;
}

export const BusinessDescriptionModal = observer(({descriptionValue, onUpdate, visible, setVisible, suggestionObj, modalLoader, setModalLoader}: Props) => {
  const [form] = Form.useForm();
  const [suggestionAccepted, setSuggestionAccepted] = useState(false);

  const onSubmit = async values => {
    setModalLoader(true);
    try {
      await onUpdate(
        [
          {
            value: values?.['businessDescription'],
            fieldName: 'business_description',
            fieldType: 'FIELD',
          },
        ],
      );
    } finally {
      setModalLoader(false);
    }
  };

  return (
    <StyledModal
      visible={visible}
      onClose={() => setVisible(false)}
      footer={null}
      title='Business Description'
      closeIcon={faXmark && <FontAwesomeIcon icon={faXmark} />}
      width={680}
    >
      {modalLoader && (
        <StyledLoaderOverlay>
          <StyledSpin fontSize={50} color='#2D6CCA' />
        </StyledLoaderOverlay>
      )}
      <StyledForm
        form={form}
        initialValues={{businessDescription: descriptionValue}}
        onFinish={onSubmit}
      >
        <div>
          <div className='description'>Describe your business to customers on Google.</div>
        </div>
        <div>
          <Form.Item name={['businessDescription']} rules={[{required: true, message: 'Please enter business description'}, {whitespace: true, message: `Can't be just whitespaces`}]}>
            <Textarea value={descriptionValue}></Textarea>
          </Form.Item>
          {suggestionObj?.value && !suggestionAccepted ? <button type='button' className='unstyledButton' style={{color: '#2D6CCA'}} onClick={() => {
            setSuggestionAccepted(true);
            form.setFieldValue('businessDescription', suggestionObj?.value);
          }}>Suggest description</button> : null}
        </div>
        <div style={{display: 'flex', alignItems: 'center', gap: 3, marginTop: 30}}>
          <ButtonStyled type='primary' htmlType='submit' style={{lineHeight: '14px'}}>Save Changes</ButtonStyled>
          <button type='button' className='unstyledButton' style={{color: '#4E5156', paddingInline: '10px'}} onClick={() => setVisible(false)}>Cancel</button>
        </div>
      </StyledForm>
    </StyledModal>
  );
});

const StyledModal = styled(ModalStyled)`
  .rc-dialog-content {
    position: relative;
    padding: 30px !important;
  }

  .rc-dialog-body, .rc-dialog-header {
    padding: 0 !important;
  }

  .rc-dialog-title {
    font-size: 24px !important;
    font-weight: 600 !important;
    line-height: 29.05px !important;
  }
`;

const StyledLoaderOverlay = styled(LoaderOverlay)`
  width: 100% !important;
  height: 100% !important;
  top: 0;
  left: 0;
  border-radius: 10px;
`;

const StyledForm = styled(Form)`
  .title {
    font-size: 24px;
    font-weight: 600;
    line-height: 29.05px;
    color: #121212;
  }
  .description {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    color: #4E5156;
    margin-top: 5px;
    margin-bottom: 20px;
  }
  .unstyledButton {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    border: none;
  }
  .ant-form-item {
    margin-bottom: 12px;
  }
`;

const Textarea = styled(Input.TextArea)`
  border-radius: 8px;
  border: 1px solid #D8D8D8;
  resize: none;
  height: 190px !important;
`;
