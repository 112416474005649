import {observer} from 'mobx-react-lite';
import styles from './style.module.scss';
import classnames from 'classnames';
import {RootStoreType, useStore} from '@/store/root-store';
import {useRouter} from 'next/router';
import {ConfettiAnimation} from '@/components/common-components/components/confetti-animation';
import styled from 'styled-components';
import {routes} from '@/utils/const';
import {NextImg} from '@/utils/nextImg';

type HeaderProps = {
  store?: RootStoreType;
  isOrderBuilder?: boolean;
};

const CheckoutHeader = observer(({isOrderBuilder}: HeaderProps) => {
  const router = useRouter();
  const {settings: {customer: {profile: {logo, isWhitelabel, hasCompletedRegistration, showConfettiAnimation}}}} = useStore('');
  // const {gsc: {setDrawerVisibility}} = useStore('');

  return (
    <WrapperMain style={{padding: '15px 15px 0 15px', background: '#24262a'}}>
      <div className={router?.pathname == '/order-builder' ?
        classnames(styles.orderHeader, styles.header, styles.checkoutLogo) :
        classnames(styles.header, styles.checkoutLogo)
      }>
        <div className={isOrderBuilder ? styles.orderBuilderHeaderStyle : ''}>
          {isWhitelabel && !logo ? <LogoText>Your logo here</LogoText> :
            <NextImg style={{height: 35, width: 126, marginLeft: '5px', display: 'flex', alignItems: 'center', transition: 'all 0.2s ease'}}
              onClick={()=> router.push(routes?.home)} src={logo && isWhitelabel ? logo : '/img/searchatlasLogo.svg'} alt='linkgraph' />}
          {/* <NextImg onClick={() => router.push({pathname: `/${routes.gsc}`})} src='/img/logo/searchatlaslogo.png' /> */}
        </div>

        {/* {!isOrderBuilder && !store.settings.customer.profile.hasCompletedRegistration &&
        <RegistrationModal />
      } */}
        {hasCompletedRegistration && showConfettiAnimation &&
        <ConfettiAnimation />
        }
      </div>
    </WrapperMain>
  );
});

export default CheckoutHeader;

const WrapperMain = styled.div`
  padding: 15px 15px 0 15px !important;
  background: #24262a !important;
`;

const LogoText = styled.div`
  font-size: 20px;
  color: #E8E8E8;
  margin-left: 5px;
  align-items: center;
  transition: all 0.2s ease;
`;
