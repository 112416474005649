import {opaqueColor} from '@/utils/colors';
import {faCalendar} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Divider, Popover, Tooltip} from 'antd';
import {observer} from 'mobx-react';
import moment from 'moment';
import {useEffect, useRef, useState} from 'react';
import DatePicker from 'react-datepicker';
import {Button, GrayButton} from '@/components/common-components/v2/Button';
import classNames from 'classnames';
import {faCaretDown, faChevronLeft, faChevronRight} from '@fortawesome/pro-solid-svg-icons';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import styles from './styles.module.scss';
import {DatePicker as AndtDatePicker} from 'antd';

export const CustomDateRangeList = observer(({
  loading = false,
  setEndDate,
  setStartDate,
  endDate,
  startDate,
  defaultRanges,
  open,
  setOpen,
  status = 'always',
  defaultRangeClickHandler,
  onDateSubmitHandler,
  onDateDismissHandler,
  varient = 'purple',
  customClassStart,
  customClassEnd,
  callback,
  label,
  labelWidth,
  showCustom = false,
  customRanges = null,
  type = null,
}: any) => {
  const varientStyle = {
    purple: '#7F4EAD',
    blue: '#2D6CCA',
  };

  const [endDateOpened, setEnedDateOpened] = useState(false);
  const [startDateOpened, setStartDateOpened] = useState(false);

  const [maxDate] = useState(new Date(moment().subtract(0, 'days').format()));

  const popupRef = useRef<any>();
  const [showRangePicker, setShowRangePicker] = useState(false);
  const today = moment().endOf('day');
  const maxDateEnabled = moment().subtract(27, 'days').startOf('day');
  const maxMonthEnabled = moment().subtract(2, 'years').startOf('day');
  const [range, setRange] = useState<any>();

  // sets up an event listener that should clsoe the modal if anywhere outside the screen is clicked
  const {RangePicker} = AndtDatePicker;
  useEffect(() => {
    function handleClickOutside(event) {
      const x = event.clientX; const y = event.clientY;
      const elementMouseIsOver = document.elementFromPoint(x, y);

      if (popupRef.current &&
            Boolean(elementMouseIsOver?.closest('.datePickerPopoverTriggerClass')) ||
            Boolean(elementMouseIsOver?.closest('.DatePickerCalendarClass')) ||
            Boolean(elementMouseIsOver?.closest('.DatePickerClass')) ||
            Boolean(elementMouseIsOver?.closest('.ant-picker-panel-container')) ||
            Boolean(elementMouseIsOver?.closest('.datePickerPopoverClass'))) {
        event.preventDefault();
        event.stopPropagation();
      } else {
        setOpen((prevOpen:boolean) => {
          prevOpen && onDateDismissHandler();
          return false;
        });
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRef]);


  const disabledDate = current => {
    return current && (current < (type == 'daily' ? maxDateEnabled : maxMonthEnabled) || current > today);
  };

  const datePickerHandler = (val:any) => {
    if (type == 'daily') {
      setStartDate(moment(val?.[0]).format('MMM DD, YYYY'));
      setEndDate(moment(val?.[1]).format('MMM DD, YYYY'));
    } else {
      setStartDate(moment(val[0]).date(15).format('MMM DD, YYYY'));
      setEndDate(moment(val[1]).date(15).format('MMM DD, YYYY'));
    }
  };

  const openMenuHandler = () => {
    if (status == 'custom') {
      setRange([moment(startDate, 'MMM DD, YYYY'), moment(endDate, 'MMM DD, YYYY')]);
    }
    setOpen(!open);
  };

  useEffect(() => {
    if (status == 'custom') {
      setShowRangePicker(false);
      setTimeout(() => {
        setShowRangePicker(true);
      });
      setRange(null);
    }
  }, [type]);

  useEffect(() => {
    if (open && status == 'custom') setShowRangePicker(true);
  }, [open]);

  const isStartDateGreaterThanEndDate = (startDateStr, endDateStr) => {
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);

    return startDate > endDate;
  };

  const datePickerRender = (<>
    <div style={{'display': 'flex', 'justifyContent': (showCustom && status =='custom') && 'space-between', 'background': showCustom && 'white'}}>
      {(showCustom && status =='custom') && <div style={{'width': '71%', 'borderRight': '1px solid #D9D9D9'}}>
        <RangePickerWrapper cellWidth={type == 'monthly' ? '' : '28px'} isMonthly={type == 'monthly'}>
          {showRangePicker && <>
            <RangePicker
              picker={type == 'monthly' ? 'month' : 'date'}
              disabledDate={disabledDate}
              open={showRangePicker}
              style={{width: 0, visibility: 'hidden'}}
              disabled={!(status == 'custom' && showRangePicker)}
              onChange={val => datePickerHandler(val)}
              getPopupContainer={() => document.getElementById('range-picker-wrapper')}
              value={range}
              className='position-change-range-picker'
              dropdownClassName='position-change-range-picker'
              nextIcon={<FontAwesomeIcon icon={faChevronRight} color='#121212' />}
              prevIcon={<FontAwesomeIcon icon={faChevronLeft} color='#121212' />}
              superNextIcon={<FontAwesomeIcon icon={faChevronRight} color='#121212' />}
              superPrevIcon={<FontAwesomeIcon icon={faChevronLeft} color='#121212' />}
            />
            <div id='range-picker-wrapper'></div>
          </>}
        </RangePickerWrapper>
      </div>}
      <div style={{background: showCustom && '#F9F9FB', flexGrow: 1}}>
        <PopupTop className='datepickerpopuptop'>
          {showCustom && (<>
            <p style={{'margin': 'unset', 'fontWeight': '600'}}>Recent Data</p>
            <p style={{'fontSize': '12px', 'color': '#4E5156', 'fontWeight': '400'}}>Data will be shown daily</p>
          </> )}
          {showCustom && customRanges?.map((range, idx) => {
            return <SingleCustomRangeBtn
              key={idx}
              active={range.selected}
              showCustom={showCustom}
              color={varientStyle[varient]}
              onClick={() => {
                defaultRangeClickHandler(range);
                range.status == 'custom' && setShowRangePicker(true);
              }}>
              {range.label}
            </SingleCustomRangeBtn>;
          })}
        </PopupTop>
        <PopupTop className='datepickerpopuptop'>
          {showCustom && (<>
            <p style={{'margin': 'unset', 'fontWeight': '600'}}>Historical Data</p>
            <p style={{'fontSize': '12px', 'color': '#4E5156', 'fontWeight': '400'}}>Data will be shown over full months</p>
          </>)}
          {defaultRanges.map((range, idx) => {
            return <SingleCustomRangeBtn
              key={idx}
              active={range.selected}
              color={varientStyle[varient]}
              showCustom={showCustom}
              onClick={() => {
                defaultRangeClickHandler(range);
                (range.status == 'custom' && showCustom) && setShowRangePicker(true);
              }}>
              {range.label}
            </SingleCustomRangeBtn>;
          })}
        </PopupTop>
        {showCustom && <PopupBottom>
          <div style={{'display': 'flex', 'justifyContent': 'center'}}>
            <StyledButton color={varientStyle[varient]} onClick={() => onDateSubmitHandler()}>Apply</StyledButton>
            <GrayButton style={{display: 'flex', justifyContent: 'center', marginLeft: 0, marginRight: 0, border: 'none', backgroundColor: 'transparent', boxShadow: 'none'}} onClick={() => onDateDismissHandler()}>Cancel</GrayButton>
          </div>
        </PopupBottom>
        }
      </div>
    </div>
    {!showCustom && (status == 'custom' || status === 'always') && <div>
      <Divider style={{margin: '0', backgroundColor: '#E8E8E8'}}/>
      <PopupBottom>
        <SingleDatePickerWrapper isGrayedOut={endDateOpened}>
          <DatePickerLabel isGrayedOut={endDateOpened}>Start date</DatePickerLabel>
          <div style={{width: 140}}>
            <DatePicker
              selected={startDate}
              dateFormat='MMM dd, yyyy'
              onChange={(date: Date) => {
                setStartDate(date);
                () => callback && callback(date, moment(endDate).format());
              }}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              popperPlacement={'right-start'}
              onCalendarOpen={() => setStartDateOpened(true)}
              onCalendarClose={() => setStartDateOpened(false)}
              popperClassName={customClassStart || classNames(styles.DatePickerCalendarClass, styles.DatePickerCalendarClassStart)}
              minDate={new Date(moment(maxDate).subtract(1, 'years').format())}
              maxDate={maxDate}
              showMonthYearDropdown
              popperModifiers={[
                {
                  name: 'offset',
                  options: {
                    offset: [40, 10],
                  },
                  fn: state => {
                    return {data: state};
                  },
                },
              ]}
              className={'DatePickerClass'}
            />
          </div>
        </SingleDatePickerWrapper>
        <SingleDatePickerWrapper isGrayedOut={startDateOpened}>
          <DatePickerLabel isGrayedOut={startDateOpened}>End date</DatePickerLabel>
          <div style={{width: 140}}>
            <DatePicker
              selected={endDate}
              dateFormat='MMM dd, yyyy'
              onChange={(date: Date) => {
                setEndDate(date);
                () => callback && callback(moment(startDate).format(), date);
              }}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              maxDate={maxDate}
              popperPlacement={'right-start'}
              onCalendarOpen={() => setEnedDateOpened(true)}
              onCalendarClose={() => setEnedDateOpened(false)}
              showMonthYearDropdown
              popperClassName={customClassEnd || classNames(styles.DatePickerCalendarClass, styles.DatePickerCalendarClassEnd)}
              popperModifiers={[
                {
                  name: 'offset',
                  options: {
                    offset: [40, 10],
                  },
                  fn: state => {
                    return {data: state};
                  },
                },
              ]}
              className={'DatePickerClass'}
            />
          </div>
        </SingleDatePickerWrapper>
        <Tooltip title={isStartDateGreaterThanEndDate(startDate, endDate) ? 'Start Date should not be greater than End Date' : ''}>
          <span>
            <StyledButton color={varientStyle[varient]} style={{width: '-webkit-fill-available', display: 'flex', justifyContent: 'center', marginTop: 15, marginBottom: 5, marginLeft: 0, marginRight: 0}}
              loading={loading}
              disabled={(status == 'always' ? false : status != 'custom') || isStartDateGreaterThanEndDate(startDate, endDate) ||
            (moment(startDate).format('YYYY-MM-DD') == moment(endDate).format('YYYY-MM-DD') && status == 'custom')}onClick={() => onDateSubmitHandler()}>Apply</StyledButton>
          </span>
        </Tooltip>
        <GrayButton style={{width: '-webkit-fill-available', display: 'flex', justifyContent: 'center', marginLeft: 0, marginRight: 0, border: 'none', backgroundColor: 'transparent', boxShadow: 'none'}} onClick={() => onDateDismissHandler()}>Cancel</GrayButton>
      </PopupBottom>
    </div>}
  </>);
  return (
    <Popover
      ref={popupRef}
      destroyTooltipOnHide={true}
      overlayClassName={classNames(styles.DatePickerPopover, 'datePickerPopoverClass', showCustom && 'custom-popover')}
      content={datePickerRender}
      placement={'bottomLeft'}
      trigger='click'
      visible={open}
      overlayInnerStyle={{width: (showCustom && status == 'custom') ? 796 : 250, borderRadius: 12}}>
      <SelectedDateWrapper varient={varient} showCustom={showCustom} onClick={() => openMenuHandler()} className='datePickerPopoverTriggerClass'>
        <FontAwesomeIcon icon={faCalendar} style={{marginRight: 6, marginBottom: 2, fontSize: 16}}/>
        <span style={{width: labelWidth || '200px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}> {status == 'last_24_hours' ? 'Last 24 hours' : label}</span>
        <FontAwesomeIcon icon={faCaretDown} style={{marginLeft: 5}}/>
      </SelectedDateWrapper>
    </Popover>
  );
});

const RangePickerWrapper = styled.div<{isMonthly?: boolean; cellWidth?: string}>`
  width: 100% !important;
  border-left: 1px solid #D9D9D9 !important;
  .ant-picker-dropdown {
    position: static !important;
  }
  .ant-picker-range-arrow {
    display: none !important;
  }
  .ant-picker-panel-container {
    box-shadow: none !important;
  }
  .ant-picker-header, .ant-picker-panel {
    border-bottom: none !important;
  }
  .ant-picker-header-super-next-btn, .ant-picker-header-super-prev-btn {
    display: ${p => p?.isMonthly ? '' : 'none'} !important;
  }
  .ant-picker-header-view {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 22px !important;
    color: #121212 !important;
  }
  .ant-picker-content {
    table-layout: auto !important;
    .ant-picker-cell {
      font-weight: 400 !important;
      font-size: 12px !important;
      &::before {
        height: 28px;
        border: none !important;
      }
    }
    .ant-picker-cell-in-range {
      &::before {
        border-right: 1px solid #fff !important;
        border-left: 1px solid #fff !important;
        ${p => p?.isMonthly ? 'border: none !important;' : ''}
      }
    }
    .ant-picker-cell-selected {
    }
    .ant-picker-cell-inner {
      width: ${p => p?.cellWidth};
      height: 28px;
      &::before {
        border: none !important;
      }
    }
    .ant-picker-cell-selected {
      border-right: 1px solid #fff !important;
      border-left: 1px solid #fff !important;
      ${p => p?.isMonthly ? 'border: none !important;' : ''}
    }
    .ant-picker-cell-range-start {
      .ant-picker-cell-inner {
        border-bottom-left-radius: 8px !important;
        border-top-left-radius: 8px !important;
        ${p => p?.isMonthly ? 'border-radius: 8px !important;' : ''}
      }
      &::before {
        border-bottom-left-radius: 8px !important;
        border-top-left-radius: 8px !important;
        ${p => p?.isMonthly ? `
          border-radius: 8px !important;
        ` : `
          border-bottom-left-radius: 8px !important;
          border-top-left-radius: 8px !important;
        `}
        left: 0 !important;
        right: 0 !important;
      }
    }
    .ant-picker-cell-range-end {
      &::before {
        ${p => p?.isMonthly ? `
          border-radius: 8px !important;
        ` : `
          border-bottom-right-radius: 8px !important;
          border-top-right-radius: 8px !important;
        `}
        left: 0 !important;
        right: 0 !important;
      }
      .ant-picker-cell-inner {
        border-bottom-right-radius: 8px !important;
        border-top-right-radius: 8px !important;
        ${p => p?.isMonthly ? 'border-radius: 8px !important;' : ''}
      }
    }
  }
`;


const SelectedDateWrapper = styled.div<{varient?: string; showCustom?: boolean}>`
  display: flex;
  width: fit-content;
  padding: 5px 10px 6px;
  color: ${p => p.varient === 'blue' || p.showCustom ? '' : '#fff'};
  border-radius: 6px;
  cursor: pointer;
  align-items: center;
  transition: background-color .2s ease;
  ${p => p.varient === 'blue' || !p.showCustom ? `
  height: 28px;
  border: 1px solid rgba(205, 205, 205, 1)` : ''}
  ${p => p.showCustom ?
    `background-color: rgba(127, 78, 173, 0.17);
    border: 1px solid #B498D0;
    height: 27px` : ''}
`;

const PopupTop = styled.div`
  padding: 10px 10px 10px 10px;
`;
const PopupBottom = styled.div`
  padding: 10px;
  background-color: #F9F9FB;
  border-radius: 0 0 12px 12px;
`;
const SingleDatePickerWrapper = styled.div<{isGrayedOut?: boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;

  .DatePickerClass {
    padding: 5px 9px 6px;
    background-color: #fff;
    border-radius: 10px;
    border: ${p => p.isGrayedOut ? '1px solid #CDCDCD' : '1px solid #7F4EAD'};
    width: 140px;
    cursor: pointer;
    opacity: ${p => p.isGrayedOut ? .5 : 1};
  }
`;
const DatePickerLabel = styled.div<{isGrayedOut?: boolean}>`
  font-size: 12px;
  color: #4E5156;
  margin-left: 10px;
  opacity: ${p => p.isGrayedOut ? .5 : 1};
`;

const SingleCustomRangeBtn = styled.div<{active?: boolean; color?: string; showCustom?: boolean}>`
  padding: 5px 15px 5px 10px;
  background-color: ${p => p.active ? opaqueColor(p.color, 20) : p.showCustom ? '##F9F9FB' : '#fff'};
  color: ${p => p.active ? p.color : ''};
  border-radius: 6px;
  transition: .2s ease;
  cursor: pointer;
  margin-bottom: 5px;

  &:hover {
    background-color: ${p => opaqueColor(p.color, 20)};
    color: ${p => p.color};
  }
`;

const StyledButton= styled(Button)<{color?: string}>`
  background-color: ${p => p?.color};
  &:hover {
    background: linear-gradient(0deg,rgba(0,0,0,0.18),rgba(0,0,0,0.18)), ${p => p.color} !important;
  }
  &:disabled {
    background: linear-gradient(0deg,rgba(0,0,0,0.17),rgba(0,0,0,0.17)), ${p => p.color} !important;
  }
`;
