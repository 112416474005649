import {Menu} from 'antd';
import styled from 'styled-components';

export const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const SchemaWrapper = styled.div`
  border: 1px solid #DCDCDC;
  border-radius: 12px;
  overflow: hidden;
  .schemaTextarea {
    textarea {
      padding-bottom: 9px;
      font-size: 12px;
      line-height: 18px;
    }

    & > textarea {
      color: #A3A4A4 !important;
      background: rgba(42, 193, 85, 0.1) !important;
    }
  }
  textarea {
    caret-color: transparent;
  }
  position: relative;
  .loaderWrapper {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.65);
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const DropdownContainer = styled.div<{isDisabled?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 310px;
  height: 34px;
  border-radius: 6px;
  padding: 10px;
  border: 1px solid #D8D8D8;
  background: #FFFFFF;
  cursor: pointer;
  ${p => p?.isDisabled ? `
    cursor: not-allowed;
    opacity: 60%;
  ` : ''}
`;

export const DeployStatusButton = styled.div`
.button-container {
    padding: 5px 12px;
    border-radius: 8px;
    position: relative;
    cursor: pointer;
  }
  .ant-switch {
    margin-right: 5px;
    width: 37px;
    min-width: 37px;
    height: 24px;
  }
  .ant-switch-checked {
    background: #18923B !important;
  }
  .ant-switch-unchecked {
    background: #4E515626;
  }
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  .label {
    font-size: 12px;
    font-weight: 600;
    line-height: 14.52px;
    color: #121212;
  }
`;

export const MenuStyled = styled(Menu)`
  border-radius: 8px;
  overflow: hidden;
  padding: 18px 10px 14px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  .optionsContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .ant-dropdown-menu-item  {
      background-color: #fff;
      border-radius: 6px;
      min-height: 26px;
      height: 26px;
      &:hover {
        background-color: rgba(103, 103, 103, 0.1);
      }
      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }
    .searchInput {
      background-color: #F9F9FB;
      border: 1px solid #E8E8E8;
      border-radius: 7px;
      height: 32px;
      input {
        background-color: #F9F9FB;
      }
    }
    .options {
      max-height: 200px;
      overflow-y: auto;
      padding-right: 6px;
      .optionItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &::-webkit-scrollbar {
        height: 5px;
        width: 5px;
      }
          
      &::-webkit-scrollbar-thumb {
        background: rgba(138,138,138,0.5);
        border-radius: 6px;
      }
          
  &::-webkit-scrollbar-track {
    background: transparent;
  }
    }
  }
  .title {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    color: #121212;
  }
  .recommended {
    color: #4E5156;
    font-size: 12px;
    line-height: 14.52px;
    margin-bottom: 3px;

    .recommendedOption {
      padding: 0;
      margin: 0;
      display: inline-block;
      background-color: #fff;
      font-size: 12px;
    line-height: 14.52px;
    }
  }
`;
