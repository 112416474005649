import {Tooltip, Spin, Collapse} from 'antd';
import {observer} from 'mobx-react';
import React, {useCallback, useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCirclePlus, faTrash, faXmark} from '@fortawesome/pro-regular-svg-icons';
import {faCircleCheck} from '@fortawesome/pro-solid-svg-icons';
import {LoadingOutlined} from '@ant-design/icons';
import {useStore} from '@/store/root-store';
import {getSingleUrlParam, addProtocolToDomain} from '@/utils/url';
import {openUrl} from '@/utils/router';
import {FirstDeployTooltipContent, KeywordWrapper, LinkDeployBtn, PaginationStyled, PurpleCheckbox, StyledIssuesCollapse, StyledIssuesTable, UrlWrapper} from '../../../../style';
import {getDeployStatus, getFilteredDataForOtto} from '../../../tableIssuesCollapse';
import {debounce} from 'lodash';
import {TableTopBar} from '../../../tableTopBar';
import useWindowSize from '@/utils/hooks/useWindowSize';
import {newNotification} from '@/utils/notification-v3';
import {BulkActionBar} from '../../../bulkActionBar';
import {getOttoTablePageSize} from '@/api/common-utils';
import {saveOttoTablePageSize} from '@/api/common-utils';

import FreezeWrapper from '../../../freezTooltip';
import AddKeywords from './AddKeywordSection';
import {CustomTooltip} from '@/components/common-components/components/tooltip';
import {issuesTypes} from '@/utils/string';

interface Props {
  componentIssueType: string;
  setPageChanged: (value: boolean) => void;
  setIssueTable: (value: string) => void;
  showConfirmationPopup: any;
  deleteItem: any;
  issueTable: string;
  deleteIssueIds: any;
  maxHeight?: string;
  removeTopBar?: boolean;
  setDeleteIssueIds?: React.Dispatch<React.SetStateAction<any>>;
}


export const MissingKeywords = observer(({componentIssueType, setPageChanged, setIssueTable, showConfirmationPopup, deleteItem, issueTable, deleteIssueIds, maxHeight = null, removeTopBar = false, setDeleteIssueIds}: Props) => {
  const {ottoV2Store: {
    getOttoUrls,
    getOttoV2Project,
    loadIssueTableData,
    issueTypeArray,
    ottoUrlLoader,
    ottoIssueType,
    addSourcePage,
    deployingProposedFix,
    isActiveKeys,
    setSelectedIssue,
    setSelectedCategory,
    setLoadingDetail,
    selectedCategory,
    setOttoSearchTerm,
    selectedIssue,
    deployOttoUrls,
    setIssueTypeSelected,
    setIsDeploying,
    loadOttoV2Project,
    deploySubOttoUrls,
    setIsKnowledgeModalVisible,
  }} = useStore('');

  const uuid = getSingleUrlParam('uuid');
  const domainName = getOttoV2Project?.hostname || getSingleUrlParam('domain');
  const {width} = useWindowSize();

  const [ottoUrls, setOttoUrls] = useState([]);
  const [urlId, setUrlId] = useState<any>(-1);
  const [editDescription, setEditDescription] = useState<any>(-1);
  const [currentProject, setCurrentProject] = useState(null);
  const [subUrlId, setSubUrlId] = useState<any>(-1);
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [tableData, setTableData] = useState([]);
  const [isTableOpen, setIsTableOpen] = useState<any>('open');
  const [isActiveFilter, setIsActiveFilter] = useState('all');
  const [showBulkBanner, setShowBulkBanner] = useState<boolean>(false);
  const [selectedPages, setSelectedPages] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [pageSize, setPageSize] = useState(() => getOttoTablePageSize(componentIssueType));
  const [visibleFirstDeployTooltip, setVisibleFirstDeployTooltip] = useState('');
  const [approvedSum, setApprovedSum] = useState(0);

  useEffect(() => {
    const groups = getOttoV2Project?.issuesCountBreakdown?.['issues'] || {};
    let updatedSum = 0;
    const groupKeys = Object.keys(groups);
    for (const key of groupKeys) {
      if (issuesTypes.includes(key) && 'approved' in groups[key]) {
        updatedSum += groups[key].approved || 0;
      }
    }
    setApprovedSum(updatedSum);
  }, [JSON.stringify(getOttoV2Project?.issuesCountBreakdown)]);
  useEffect(() => {
    if (getOttoUrls) {
      setOttoUrls(getOttoUrls);
    }
  }, [getOttoUrls]);

  useEffect(() => {
    if (tableData?.length && selectAll) {
      const pageArray = tableData.map(data => data?.id);
      setSelectedPages(pageArray);
    }
  }, [ottoUrls, selectAll]);

  useEffect(() => {
    const filteredOttoUrls = ottoUrls ? ottoUrls?.filter(url => issueTypeArray.includes(url?.issueType)) : [];
    const url = filteredOttoUrls.find(url => url?.issueType === componentIssueType);
    const data = url?.issueTable?.results ?? [];
    const filteredData = getFilteredDataForOtto(data, url?.issueType, false);
    setTableData(filteredData);
  }, [ottoUrls]);

  useEffect(() => {
    setCurrentProject(getOttoV2Project?.uuid == uuid && getOttoV2Project);
  }, [getOttoV2Project]);

  const filteredOttoUrls = ottoUrls ? ottoUrls?.filter(url => issueTypeArray.includes(url?.issueType)) : [];
  const url = filteredOttoUrls.find(url => url?.issueType === componentIssueType);
  const data = url?.issueTable?.results ?? [];
  const filteredData = getFilteredDataForOtto(data, url?.issueType, false);


  useEffect(() => {
    if (filteredData && deleteItem == -1) {
      setTableData(filteredData);
    } else {
      const newData = filteredData.filter(val=> val.itemIndex !== deleteItem);
      setTableData(newData);
    }
  }, [deleteItem]);

  const deployOttoUrl = async (id, toDeploy, issueType, indexesArray = []) => {
    if (toDeploy) {
      setUrlId(id);
    } else {
      setUrlId(`${id}unDeploy`);
    }
    try {
      const data = {toDeploy, issueType, uuid};
      if (issueType === 'images') {
        data['itemIndex'] = id;
      } else {
        data['ottoUrls'] = [id];
      }
      data['itemIndexes'] = indexesArray;
      await deployOttoUrls(data);
      setPageChanged(true);
      setIssueTypeSelected(issueType);
      setIsDeploying(true);
      if (uuid) {
        loadOttoV2Project(uuid, true);
      }
      if (toDeploy) {
        newNotification(`${indexesArray.length} Change deployed`, 2, 'deploy');
      } else {
        newNotification(`${indexesArray.length} Change Rolled Back`, 2, 'rollback');
      }
      setUrlId(-1);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error: ', error);
      setUrlId(-1);
    }
  };

  const deploySubOttoUrl = async (id, index, toDeploy, issueType ) => {
    setSubUrlId(`${index}${id}`);
    try {
      const data = {toDeploy, issueType, uuid};
      data['itemIndex'] = index;
      data['ottoUrls'] = [id];
      await deploySubOttoUrls(data);
      setPageChanged(true);
      setIssueTypeSelected(issueType);
      setIsDeploying(true);
      if (uuid) {
        loadOttoV2Project(uuid, true);
      }
      if (toDeploy) {
        newNotification(issueType == 'page_title' ? (currentProject?.isActive ? '1 Change deployed' : '1 Change enabled') : '1 Change deployed', 2, 'deploy');
      } else {
        newNotification('1 Change rolled back', 2, 'rollback');
      }
      setSubUrlId(-1);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error: ', error);
      setSubUrlId(-1);
    }
  };

  const descriptionContainer = (record, index) => {
    return (
      <FreezeWrapper>
        <div style={{display: 'flex', alignItems: 'center', gap: '6px', cursor: (urlId === -1 && subUrlId === -1 && deleteItem === -1) ? 'pointer' : 'not-allowed', width: 'fit-content'}}
          onClick={() => (urlId === -1 && subUrlId === -1 && deleteItem === -1) && setEditDescription(index)}
        >
          <FontAwesomeIcon icon={faCirclePlus} color='#2D6CCA' fontSize={14}/>
          <div style={{color: '#2D6CCA'}}>Add keyword</div>
        </div>
      </FreezeWrapper>
    );
  };

  const onAddKeyword = async (record, proposedFix, keywords) => {
    const filter = ottoUrls.filter(d => d.issueType === ottoUrls?.find(i => i?.issueType === componentIssueType)?.issueType);
    const enteredArray = proposedFix.split(',').map(keyword => keyword.trim()).filter(keyword => keyword);
    const trimmedArray = keywords?.map(item => item?.trim());
    const resultString = trimmedArray?.join(',');
    const enteredString = enteredArray?.join(',');
    const finalString = resultString.concat(`${resultString?.length ? `,${enteredString}` : enteredString}`);
    const payload = {
      issue_type: record?.issueType,
      otto_url_id: record?.id,
      object_key: finalString,
    };
    try {
      await addSourcePage(uuid, payload);
      await loadIssueTableData({
        uuid: getOttoV2Project?.uuid,
        page_size: filter?.length ? filter[0].pageSize : 10,
        page: filter?.length ? filter[0].page : 1,
        issue_type: ottoUrls?.find(i => i?.issueType === componentIssueType)?.issueType,
        otto_project: getOttoV2Project?.id,
      });
      setEditDescription(-1);
      setDeleteIssueIds({parent: null, child: null});
    } catch (error) {
      setEditDescription(-1);
    }
  };

  const searchDebounce = debounce(async (params, isSitewide) => {
    await loadIssueTableData(params, isSitewide);
  }, 1000);

  const loadIssueTables = useCallback(async (issueArray: string[], issue: string, category: string, page: number, pageSize: number, stopLoading?: boolean, search?: string, rating?: string, activeKey?: string) => {
    setSelectedIssue(issue);
    setSelectedCategory(category);
    if (!stopLoading) {
      setLoadingDetail(true);
    }
    for (let index = 0; index < issueArray.length; index++) {
      const issueType = issueArray[index];
      if (issueTypeArray.includes(issueType)) {
        const params = {
          uuid,
          otto_project: getOttoV2Project?.id,
          issue_type: issueType,
          page_size: pageSize,
          page,
          is_loading: false,
          ...((activeKey == 'deployed' || activeKey == 'not_deployed') && {deploy_status: getDeployStatus(activeKey)}),
        };
        if (searchText) {
          params['search'] = searchText;
          setOttoSearchTerm(searchText);
        } else {
          setOttoSearchTerm('');
        }
        if (searchText) {
          searchDebounce(params, false);
        } else {
          await loadIssueTableData(params, false);
        }
      }
    }
    setLoadingDetail(false);
  }, []);

  const handlePaginationChange = (issueArray: string[], page, pageSize) => {
    const activeKey = issueArray?.length ? isActiveKeys?.find(item => item?.issue == issueArray[0])?.key : 'all';
    loadIssueTables(issueArray, selectedIssue, selectedCategory, page, pageSize, true, searchText, '', activeKey);
  };

  const selectUnselectPages = page => {
    if (selectedPages.includes(page)) {
      setSelectedPages(data => data.filter(item => item !== page));
      setSelectAll(false);
    } else {
      setSelectedPages(data => [...data, page]);
    }
  };
  const firstDeployTooltip = (record, keyword) => {
    return (<FirstDeployTooltipContent>
      <div className='title'>Have you completed the Knowledge Graph?</div>
      <div className='description'>Your Knowledge Graph must be 100% completed to get the most out of OTTO optimizations</div>
      <div className='buttons'>
        <div className='blue-button' onClick={() => {
          setVisibleFirstDeployTooltip('');
          setIsKnowledgeModalVisible(true);
        }}>Open Knowledge Graph</div>
        <div
          className='cancel-button'
          onClick={() => {
            setVisibleFirstDeployTooltip('');
            if (keyword === 'using-from-all') {
              deployOttoUrl(record?.id, true, record?.issueType, record?.values?.map(value => value?.itemIndex));
            } else {
              deploySubOttoUrl(record?.id, keyword?.itemIndex, keyword?.isApproved ? false : true, record?.issueType);
            }
          }}
        >{`I’ll do it later`}</div>
      </div>
      <FontAwesomeIcon icon={faXmark} fontSize={14} className='close-icon' onClick={() => setVisibleFirstDeployTooltip('')}/>
    </FirstDeployTooltipContent>);
  };
  const columns = [
    {
      title: <div className='column-title'>PAGE URL</div>,
      dataIndex: 'url',
      key: 'url',
      width: '280px',
      render: (_, record) => {
        const path = (record?.path?.length && record?.path[0] === '/') ? record?.path.slice(1) : record?.path;
        return (<div style={{display: 'flex', alignItems: 'center', gap: 10}}>
          {showBulkBanner && <PurpleCheckbox checked={selectedPages.includes(record?.id)} onClick={()=>selectUnselectPages(record?.id)}/>}
          <UrlWrapper onClick={() => openUrl(`${addProtocolToDomain(domainName)}/${path}`, '_blank')}>{record?.path}</UrlWrapper>
        </div>);
      },
    },
    {
      title: <div className='column-title'>KEYWORDS TO ADD</div>,
      dataIndex: 'keywordsToAdd',
      key: 'keywordsToAdd',
      className: 'greenish-column',
      render: (_, record, index) => {
        const deployedValues = record?.values?.filter(value => value?.isApproved === true);
        return (
          <div style={{maxHeight: '300px', overflow: 'auto'}}>
            {!showBulkBanner ? <div style={{display: 'flex', alignItems: 'center', gap: '14px'}}>Deployed: {deployedValues?.length} of {record?.values?.length}
              <FreezeWrapper>
                <CustomTooltip
                  title={((approvedSum === 0) && currentProject?.isEngaged) && firstDeployTooltip(record, 'using-from-all')}
                  {...(approvedSum === 0 && {visible: visibleFirstDeployTooltip === `${record?.id}-all`})}
                  {...(approvedSum === 0 && {shouldOpenWithState: true})}
                  {...(approvedSum === 0 && {overlayWidth: '377px'})}
                  {...(approvedSum === 0 && {placement: 'bottomLeft'})}
                  {...(approvedSum === 0 && {setVisible: setVisibleFirstDeployTooltip})}
                  {...(approvedSum === 0 && {backgroundColor: '#000000'})}
                  {...(approvedSum === 0 && {arrowColor: '#000000'})}
                  {...(approvedSum === 0 && {padding: '18px 19px 20px 19px'})}>
                  <LinkDeployBtn
                    color='#219843'
                    onClick={() => {
                      if (approvedSum === 0 && currentProject?.isEngaged) {
                        setVisibleFirstDeployTooltip(`${record?.id}-all`);
                        return;
                      }
                      (urlId === -1 && subUrlId === -1 && deleteItem === -1) && deployOttoUrl(record?.id, true, record?.issueType, record?.values?.map(value => value?.itemIndex));
                    }}
                    style={{cursor: (urlId === -1 && subUrlId === -1 && deleteItem === -1) ? 'pointer' : 'not-allowed'}}
                  >
                    {currentProject?.isEngaged ? 'Deploy' : 'Enable'} all {urlId === record?.id ? <Spin indicator={<LoadingOutlined style={{fontSize: 12, color: '#219843'}} spin />} /> : ''}
                  </LinkDeployBtn>
                </CustomTooltip>
              </FreezeWrapper>
              <FreezeWrapper>
                <LinkDeployBtn
                  color='#F44343'
                  onClick={() => (urlId === -1 && subUrlId === -1 && deleteItem === -1) && deployOttoUrl(record?.id, false, record?.issueType, record?.values?.map(value => value?.itemIndex))}
                  style={{cursor: (urlId === -1 && subUrlId === -1 && deleteItem === -1) ? 'pointer' : 'not-allowed'}}
                >
                  {currentProject?.isEngaged ? 'Undeploy' : 'Disable'} all {urlId === `${record?.id}unDeploy` ? <Spin indicator={<LoadingOutlined style={{fontSize: 12, color: '#F44343'}} spin />} /> : ''}
                </LinkDeployBtn>
              </FreezeWrapper>
            </div> : <></>}
            <div>
              {record?.values?.map((keyword, idx) => (
                <KeywordWrapper key={idx} style={{display: record?.id === deleteIssueIds.parent && keyword.itemIndex === deleteIssueIds.child ? 'none' : 'flex', alignItems: 'center', gap: '6px'}}>
                  {(subUrlId === `${keyword?.itemIndex}${record?.id}`) ? <Spin indicator={<LoadingOutlined style={{fontSize: 10, color: '#219843'}} spin />} /> :
                    !showBulkBanner ?
                      <FreezeWrapper removeTooltip={keyword?.isApproved}>
                        <CustomTooltip
                          title={(approvedSum === 0) && firstDeployTooltip(record, keyword)}
                          {...(approvedSum === 0 && {visible: visibleFirstDeployTooltip === record?.id})}
                          {...(approvedSum === 0 && {shouldOpenWithState: true})}
                          {...(approvedSum === 0 && {overlayWidth: '377px'})}
                          {...(approvedSum === 0 && {placement: 'bottomLeft'})}
                          {...(approvedSum === 0 && {setVisible: setVisibleFirstDeployTooltip})}
                          {...(approvedSum === 0 && {backgroundColor: '#000000'})}
                          {...(approvedSum === 0 && {arrowColor: '#000000'})}
                          {...(approvedSum === 0 && {padding: '18px 19px 20px 19px'})}>
                          <FontAwesomeIcon
                            style={{cursor: (urlId === -1 && subUrlId === -1 && deleteItem === -1) ? 'pointer' : 'not-allowed'}}
                            onClick={() => {
                              if ((approvedSum === 0) && (urlId === -1 && subUrlId === -1 && deleteItem === -1)) {
                                setVisibleFirstDeployTooltip(record?.id);
                                return;
                              }
                              (urlId === -1 && subUrlId === -1 && deleteItem === -1) && deploySubOttoUrl(record?.id, keyword?.itemIndex, keyword?.isApproved ? false : true, record?.issueType);
                            }}
                            icon={faCircleCheck}
                            color={keyword?.isApproved ? '#2AC155' : '#A3A4A4'}
                            fontSize={14}
                          />
                        </CustomTooltip>
                      </FreezeWrapper> : <></>}
                  <div style={{color: '#000'}}>{keyword?.recommendedValue || keyword?.keyword}</div>
                  {!showBulkBanner && (
                    <FreezeWrapper removeTooltip={keyword?.isApproved}>
                      <Tooltip title={deleteItem === keyword?.itemIndex ? 'Deleting':'Delete'}>{
                        deleteItem === `${keyword?.itemIndex}${record?.id}` ?
                          <Spin indicator={<LoadingOutlined style={{fontSize: 14, color: '#A3A4A4'}} spin />}/>:
                          <FontAwesomeIcon
                            style={{cursor: (urlId === -1 && subUrlId === -1 && deleteItem === -1) ? 'pointer' : 'not-allowed'}}
                            onClick={() => (urlId === -1 && subUrlId === -1 && deleteItem === -1) && showConfirmationPopup(record, keyword)}
                            className={'keyword-delete-icon'}
                            icon={faTrash}
                            color='#4E5156'
                            fontSize={14}
                          />}
                      </Tooltip>
                    </FreezeWrapper>
                  )}
                </KeywordWrapper>
              ))}
              {!showBulkBanner ? (editDescription !== index ? descriptionContainer(record, index) : <AddKeywords deployingProposedFix={deployingProposedFix} onAddKeyword={onAddKeyword} record={record} setEditDescription={setEditDescription} />) : <></>}
            </div>
          </div>
        );
      },
    },
  ];
  const selectAllOnPage = () => {
    setSelectedPages(filteredData.map(data => data?.id));
  };


  return (
    <StyledIssuesCollapse
      ghost
      expandIconPosition='right'
      defaultActiveKey={[`${isTableOpen}`]}
      activeKey={[`${isTableOpen}`]}
      onChange={() => {
        if (isTableOpen === 'close') {
          setIsTableOpen('open');
          setIsOpenSearch(false);
        } else {
          setIsTableOpen('close');
          setIsOpenSearch(false);
        }
      }}
    >
      {!removeTopBar && <TableTopBar
        componentIssueType={componentIssueType}
        setPageChanged={setPageChanged}
        setIssueTable={setIssueTable}
        issueTable={issueTable}
        setSearchText={setSearchText}
        searchText={searchText}
        setIsOpenSearch={setIsOpenSearch}
        isOpenSearch={isOpenSearch}
        setIsTableOpen={setIsTableOpen}
        isTableOpen={isTableOpen}
        setIsActiveFilter={setIsActiveFilter}
        isActiveFilter={isActiveFilter}
        setShowBulkBanner={setShowBulkBanner}
      />}
      {showBulkBanner ? <BulkActionBar
        count={url?.issueTable?.count}
        setShowBulkBanner={setShowBulkBanner}
        selectedPages={selectedPages}
        setSelectedPages={setSelectedPages}
        selectAll={selectAll}
        setSelectAll={setSelectAll}
        selectAllOnPage={selectAllOnPage}
        issueType={url?.issueType}
        currentProject={currentProject}
        setPageChanged={setPageChanged}
      /> : <></>}
      <Collapse.Panel key='open' header={<></>}>
        <StyledIssuesTable
          loading={ottoUrlLoader && ottoIssueType === componentIssueType}
          columns={columns}
          dataSource={tableData}
          pagination={false}
          scroll={width < 1100 ? {x: 'auto', y: 'auto'} : {y: 'auto'}}
          maxHeight={maxHeight}
        />
        <PaginationStyled
          onChange={(page, pageSize) => {
            setPageChanged(true);
            setIssueTable(url?.issueType);
            handlePaginationChange([url?.issueType], page, pageSize);
            saveOttoTablePageSize(componentIssueType, pageSize);
            setPageSize(pageSize);
          }}
          total={url?.issueTable?.count}
          pageSize={pageSize}
          current={url?.page ?? 1}
          showSizeChanger
          pageSizeOptions={['5', '10', '20', '50', '100']}
        />
      </Collapse.Panel>
    </StyledIssuesCollapse>
  );
});
