import React, {useEffect, useState} from 'react';
import {Input, Popover} from 'antd';
import {observer} from 'mobx-react-lite';
import styled from 'styled-components';
import {Modal} from 'antd';
import {premiumAiQuota} from '@/utils/icons';
import {faXmark, faLockKeyhole} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from '@/components/common-components/v2';
import {useStore} from '@/store/root-store';
import {notification} from '@/utils/notification-v2';

interface Props {
  articlesLength?: number;
  consumedQuota?: number;
  totalQuota?: number;
}

export const BulkTopup = observer( ({articlesLength, totalQuota, consumedQuota}: Props) => {
  const {
    contentOptimizer: {
      currentPage: {
        setArticlesInProgress,
      },
      aiOutline: {
        addTopUpQuote,
        loadingAiQuota,
        setTopUpBulkVisibility,
        topUpBulkVisibility,
        showDesc,
        bulkAiTopup,
        setBulkAiTopup,
      },
    },
  } = useStore('');
  const {settings: {customer: {getCustomerQuota, profile: {isViewer}}}} = useStore('');
  const [isTooltip, setIsToolTip] = useState(false);
  const [addQuota, setAddQuota] = useState(1);
  const [requireQuota, setRequireQuota] = useState(0);

  useEffect(()=>{
    if (articlesLength) {
      setRequireQuota(Math.abs((totalQuota - consumedQuota) - (bulkAiTopup ? articlesLength * 3 : articlesLength)));
    }
  }, [totalQuota, consumedQuota, articlesLength, topUpBulkVisibility]);

  const onClose = () => {
    setAddQuota(1);
    setRequireQuota(0);
    setArticlesInProgress(0);
    setTopUpBulkVisibility(false);
    setBulkAiTopup(false);
  };

  return (
    <>
      <ModalStyle
        width={780}
        centered
        closable={false}
        footer={null}
        mask={true}
        visible={topUpBulkVisibility}
        onCancel={onClose}
      >
        <div className='container' style={{position: 'relative', padding: '0 15px 0 15px'}}>
          <IconWrapper>{premiumAiQuota()}</IconWrapper>
          <PopoverTitle>Top-up AI Premium Quota {showDesc && ' to Continue'}</PopoverTitle>
          {showDesc ? <>
            <PopoverDesc>
          Required for {articlesLength} AI articles: <b>{bulkAiTopup ? articlesLength * 3 : articlesLength} points</b>
            </PopoverDesc>
            <PopoverDesc>
          Available quota: <b>{totalQuota - consumedQuota} points</b>
            </PopoverDesc>
            <PopoverDesc>
          Required quota to continue: <b>{requireQuota == 1 ? `${requireQuota} Point` : `${requireQuota} Points`}</b>
            </PopoverDesc>
          </> : <PopoverDesc>Keep AI generating undetectable, SEO optimized articles.</PopoverDesc>}
          <InputWrapper>
            <Popover
              content={'Specify the amount of AI Premium Quota points you wish to add.'}
              trigger='click'
              visible={isTooltip}
              onVisibleChange={e=> isTooltip && setIsToolTip(e)}
            >
              <InputQuota
                type='number'
                minLength={0}
                placeholder='10 Points'
                value={addQuota}
                addonBefore='Points'
                onChange={(e:any) => {
                  const inputValue = Number(e.target.value);
                  if (inputValue < 0 || inputValue % 1 !== 0) {
                    return false;
                  }
                  setIsToolTip(false);
                  setAddQuota(Number(e.target.value));
                }}
                onMouseDown={(e:any) => e.stopPropagation()}
              />
            </Popover>
            <p style={{whiteSpace: 'nowrap'}}> = {addQuota} USD</p>
          </InputWrapper>
          <PopoverFooterContainer>
            <ButtonWrapper
              style={{whiteSpace: 'normal'}}
              color='green'
              loading={loadingAiQuota}
              onClick={async () => {
                if (isViewer) {
                  notification.error('', 'Only Admins can Top up quota ');
                  return;
                }
                if (addQuota <= 0) {
                  setIsToolTip(true);
                  return false;
                }
                await addTopUpQuote({units: addQuota});
                await getCustomerQuota();
                onClose();
              }}
            >
              {' '}
              <FontAwesomeIcon
                icon={faLockKeyhole}
                style={{
                  height: '16px',
                  color: 'white',
                  cursor: 'pointer',
                }}
              />{' '}
              <ButtonText> Get {addQuota} point{addQuota > 1 && 's'} for ${addQuota}{' '}</ButtonText>
            </ButtonWrapper>
            <ButtonWrapperCancel
              color='white'
              onClick={async () => {
                onClose();
              }}
            >
              Cancel
            </ButtonWrapperCancel>
          </PopoverFooterContainer>
          <div style={{position: 'absolute', top: -40, right: -47}}>
            <FontAwesomeIcon
              icon={faXmark}
              style={{height: '20px', color: '#FFFFFF', cursor: 'pointer'}}
              onClick={() => {
                onClose();
              }}
            />
          </div>
        </div>
      </ModalStyle>
    </>
  );
});

const ModalStyle = styled(Modal)`
width: 538px !important;
.ant-modal-content {
  border-radius: 8px !important;
}
@media screen and (max-width: 768px) {
  width: 300px !important;
  max-width: 300px !important;
  .container {
    padding: 0 !important;
  }
}
`;

const ButtonText = styled.span`
    margin-left: 7px;
    margin-top: 2px;
`;

const IconWrapper = styled.div`
width: 56px;
    height: 42px;
    border-radius: 100%;
    margin-right: auto;
    margin-top: 10px;
`;
const PopoverTitle = styled.div`
 color: black;
 font-weight: 600;
 font-size: 21px;
 margin-top: 8px;
 margin-bottom: 6px;
//  max-width: 280px;
`;
const PopoverDesc = styled.div`
 color: black;
 font-weight: 400;
 font-size: 13px;
 margin: 10px 0 0 0;
//  max-width: 280px;
`;
const PopoverFooterContainer = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;
  @media screen and (max-width: 860px) {
    row-gap: 10px;
  }
`;

const InputQuota = styled(Input)`
height: 38px;
width: 41%;
margin: 13px 10px 10px 0;
border: none;
.ant-input{
  padding: 8px 11px !important;
}
@media screen and (max-width: 860px) {
  max-width: 55%;
  width: auto;
}
`;

const InputWrapper = styled.div`
display:flex;
flex-wrap: wrap;
p{
  margin-top: 6px;
  margin-bottom: 0;
  color: black;
  font-size: 16px;
}
align-items: center;
.ant-input{
  border-radius: 8px;
}
`;

const ButtonWrapper = styled(Button)`
    background-color: #1FAC47;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    margin: 0px 30px 0px 0px !important;
    border: 1px solid transparent;
    padding: 7px 0px;
    color: white;
    font-size: 14px;
    min-width: 250px;
    height: auto;
    font-weight: 600;
    border:none;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    &:hover{
      background-color: #1FAC47;
      color: white;
      border:none;
    }
    .ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active{
      background-color: #1fac47b0 !important;
      color: white  !important;
      border:none !important;
    }
`;

const ButtonWrapperCancel = styled.button`
background-color: white;
    border-radius: 5px;
    border: 1px solid transparent;
    padding: 7px 20px;
    color: black;
    height: auto;
    border:none;
    cursor: pointer;
    &:hover{
      background-color: white;
      color: black;
      border:none;
    }
    &:focus {
      color: black;
    }
    @media screen and (max-width: 860px) {
      width: 100%;
      border: 1px solid #d1c5c5 !important;
    }
`;
