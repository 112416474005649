import {observer} from 'mobx-react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTwitter, faFacebook, faGithub, faLinkedin} from '@fortawesome/free-brands-svg-icons';
import {useStore} from '@/store/root-store';
import {useState} from 'react';
import {SkeletonHorizontalLoaderGray} from '@/components/common-components';
import {faTrash} from '@fortawesome/pro-regular-svg-icons';
import {LoadingOutlined} from '@ant-design/icons';
import {Spin} from 'antd';
import {
  ContactWrapper,
  IconPack,
  TableStyle} from '../styledComponents';
import {useRouter} from 'next/router';
import {Checkbox} from '@/components/common-components/v2';
import {useEffect} from 'react';
interface Props {
  rowSelect?: any;
  showEmail?: (value: boolean) => void;
  setSendSingleMail?: (value: boolean) => void;
  setHandleBulkDelete?: (value: any) => void;
  setShowBulkDeleteButton?: (value: boolean) => void;
  setBulkDelete?: (value: any) => void;
  bulkDelete?: any;
  campaignsId?: any;
}

export const ContactsTableSection = observer(({rowSelect, showEmail, setSendSingleMail, setHandleBulkDelete, setShowBulkDeleteButton, setBulkDelete, bulkDelete, campaignsId}: Props) => {
  const {campaigns: {
    getCampaignsContact,
    getOpportunityContact,
    getCampaignsContactsDelete,
    campaignsContactLoading,
    contactsDeleteLoading,
    getCampaignsOpportunity,
    campaignsContactBulkDelete,
    showDragger}} = useStore('');
  const router = useRouter();

  const {inbox: {setConversationModalVisible, viewConversationOpportunity, setContactId, getUnreadEmailCount}} = useStore('');

  const [deleteId, setDeleteId] = useState(0);

  useEffect(() => {
    bulkDelete?.length ? setShowBulkDeleteButton(true) : setShowBulkDeleteButton(false);
    setHandleBulkDelete(() => bulkDeleteFunction);
  }, [bulkDelete]);

  setHandleBulkDelete(() => bulkDeleteFunction);

  const deleteContact = async (row:any) => {
    await getCampaignsContactsDelete(rowSelect?.id, row.id);
    await getCampaignsContact(rowSelect?.id);
  };

  const setDeleteAll = () => {
    if (getOpportunityContact?.length == bulkDelete?.length) {
      setBulkDelete([]);
    } else {
      const deleteContacts = [...bulkDelete];
      getOpportunityContact?.map(contact => {
        if (!bulkDelete?.includes(contact?.id)) {
          deleteContacts.push(contact?.id);
        }
      });
      setBulkDelete(deleteContacts);
    }
  };

  const addToBulkDelete = id => {
    if (!bulkDelete?.includes(id)) {
      setBulkDelete([...bulkDelete, id]);
    } else {
      setBulkDelete(bulkDelete?.filter(contactId => contactId!=id));
    }
  };

  const bulkDeleteFunction = async () => {
    await campaignsContactBulkDelete(bulkDelete, rowSelect?.id);
    await getCampaignsContact(rowSelect?.id);
    setBulkDelete([]);
    setShowBulkDeleteButton(false);
    getCampaignsOpportunity(Number(router?.query?.id?.[0] ?? campaignsId));
  };

  const handleIconClick = url => {
    if (url) {
      const newUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `http://${url}`;
      window.open(newUrl, '_blank');
    }
  };

  const getCampaignColumns = (loading: boolean) => {
    return (
      [
        {
          title: <Checkbox customBackground='#2D6CCA' onClick={setDeleteAll} checked={getOpportunityContact?.length == bulkDelete?.length} style={{lineHeight: 'normal', marginRight: '12px'}} />,
          key: 'title',
          align: 'left' as 'left',
          fixed: 'left' as 'left',
          width: 40,
          render: (_, record) => {
            return (
              <Checkbox customBackground='#2D6CCA' checked={bulkDelete?.includes(record?.id)} onChange={() => addToBulkDelete(record?.id)} />
            );
          },
        },
        {
          title: <div style={{fontSize: '12px'}}><b>CONTACTS LIST</b></div>,
          dataIndex: 'emailAddress',
          key: 'emailAddress',
          sortFieldName: 'emailAddress',
          align: 'left' as 'left',
          sorter: false,
          width: 180,
          render: (_, record) => {
            if (loading) return <SkeletonHorizontalLoaderGray lightLoader width='116px' height= '15px' skeletonHeight='10px' align='left' margin= '0 10px'/>;
            if (record.emailAddress) {
              return (
                <ContactWrapper>
                  <span style={{marginLeft: 5}}>
                    <p className='detail'>{record.emailAddress}</p>
                    <p className='detail' style={{color: 'gray'}}>{record.firstName || record.lastName || record.title ? (
                      `${record.firstName || ''} ${record.lastName || ''} - ${record.title || ''}`
                    ) : (
                      <div><strong>-</strong></div>
                    )}</p>
                  </span>
                </ContactWrapper>
              );
            }
          },
        },
        {
          title: <div style={{fontSize: '12px'}}><b>STATUS</b></div>,
          dataIndex: 'wasContacted',
          key: 'wasContacted',
          sortFieldName: 'wasContacted',
          align: 'left' as 'left',
          sorter: false,
          width: 90,
          render: (_, record) => {
            if (loading) return <SkeletonHorizontalLoaderGray lightLoader width='116px' height= '15px' skeletonHeight='10px' align='left' margin= '0 10px'/>;
            if (record.emailAddress) {
              return (
                record?.wasContacted ? <>
                  <div>Contacted</div>
                  <div
                    onClick={() => {
                      viewConversationOpportunity(record?.id, rowSelect?.id);
                      getUnreadEmailCount();
                      setConversationModalVisible(true);
                      setContactId(record?.id);
                    }}
                    style={{color: '#2D6CCA', fontSize: '12px', cursor: 'pointer'}}>View conversation</div>
                </> :
                  <>
                    <div>Not contacted</div>
                    <div
                      onClick={() => {
                        setSendSingleMail(true);
                        setContactId(record?.id);
                        showEmail(true);
                      }}
                      style={{color: '#2D6CCA', fontSize: '12px', cursor: 'pointer'}}>Send email</div>
                  </>
              );
            }
          },
        },
        {
          title: <div style={{fontSize: '12px'}}><b>SOCIAL MEDIA</b></div>,
          dataIndex: 'twitterUrl',
          key: 'twitterUrl',
          sortFieldName: 'twitterUrl',
          align: 'left' as 'left',
          sorter: false,
          width: 100,
          render: (_, record) => {
            if (loading) return <SkeletonHorizontalLoaderGray lightLoader width='116px' height= '15px' skeletonHeight='10px' align='left' margin= '0 10px'/>;
            return (
              <IconPack>
                <FontAwesomeIcon onClick={() => handleIconClick(record?.facebookUrl)} style={{cursor: record.facebookUrl ? 'pointer' : ''}} color={record.facebookUrl ? '#2D6CCA' : '#DBDBDB'} icon={faFacebook} fontSize={18}/>
                <FontAwesomeIcon onClick={() => handleIconClick(record?.twitterUrl)} style={{cursor: record.twitterUrl ? 'pointer' : ''}} color={record.twitterUrl ? '#34AEF3' : '#DBDBDB'} icon={faTwitter} fontSize={18}/>
                <FontAwesomeIcon onClick={() => handleIconClick(record?.githubUrl)} style={{cursor: record.githubUrl ? 'pointer' : ''}} color={record.githubUrl ? 'black' : '#DBDBDB'} icon={faGithub} fontSize={18}/>
                <FontAwesomeIcon onClick={() => handleIconClick(record?.linkedinUrl)} style={{cursor: record.linkedinUrl ? 'pointer' : ''}} color={record.linkedinUrl ? '#2D6CCA' : '#DBDBDB'} icon={faLinkedin} fontSize={18}/>
              </IconPack>
            );
          },
        },
        {
          title: <div style={{fontSize: '12px'}}><b>DELETE</b></div>,
          dataIndex: 'title',
          key: 'title',
          sortFieldName: 'title',
          align: 'center' as 'center',
          sorter: false,
          width: 60,
          render: (_, record) => {
            if (loading) return <SkeletonHorizontalLoaderGray lightLoader width='116px' height= '15px' skeletonHeight='10px' align='left' margin= '0 10px'/>;
            return (
              contactsDeleteLoading && deleteId == record?.id ? <Spin indicator={<LoadingOutlined style={{fontSize: 14}} spin />} /> : <FontAwesomeIcon style={{cursor: 'pointer'}} onClick={async ()=> {
                setDeleteId(record?.id);
                await deleteContact(record);
                getCampaignsOpportunity(Number(router?.query?.id?.[0] ?? campaignsId));
              }} icon={faTrash} color='#DBDBDB' fontSize={18} />
            );
          },
        },

      ]
    );
  };

  return <>
    <TableStyle
      pagination={false}
      columns={getCampaignColumns(campaignsContactLoading)}
      scroll={{x: 600, y: 600}}
      dataSource={getOpportunityContact}
      onChange={() => {}}
      loading={campaignsContactLoading}
      background={showDragger ? 'white' : ''}
    />
  </>;
});
