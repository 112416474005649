import {Input, Form} from 'antd';
import {useState, useEffect} from 'react';
import GoogleMapReact from 'google-map-react';
import styled from 'styled-components';
import {ButtonStyled, ButtonsWrapper, CloseButton} from '../styles';
import Marker from '@/components/dashboard/pages/home-page-main/cards/projects-section/LocalSeo/marker';
import button from 'antd/lib/button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLocation} from '@fortawesome/free-solid-svg-icons';
import useGeocodeConverter from './geoCodeConverter';

interface addressTypes {
  address_lines_1?: string;
  address_lines_2?: string;
  address_postal_code?: string;
  address_locality?: string;
  country?: string;
}

export const LocationModal = ({selectedValue, setIsModalOpen, updateData, setModalLoader}) => {
  const [form] = Form.useForm();
  const [longitude, setLongitude] = useState(-73.935242);
  const [latitude, setLatitude] = useState(40.73061);
  const [zoomValue] = useState(11);
  const [markerMoved, setMarkerMoved] = useState(false);
  const [resetCord, setResetCord] = useState(false);
  const {address, setAddress, getLatLngFromAddress, addressFromCoordinates} = useGeocodeConverter();
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [country, setCountry] = useState<string | null>(null);

  const handleGoogleApiLoaded = () => {
    if (!isMapLoaded) {
      getLatLng();
      setIsMapLoaded(true);
    }
  };


  const createMapOptions = () => {
    return {
      panControl: false,
      mapTypeControl: false,
      scrollwheel: true,
      fullscreenControl: false,
    };
  };

  const getLatLng = async (address = null) => {
    const res: {lat?: number; lng?: number} = await getLatLngFromAddress(address);
    if (res?.lat && res?.lat !== undefined) {
      setLatitude(res?.lat);
    }
    if (res?.lng && res?.lng !== undefined) {
      setLongitude(res?.lng);
    }
  };

  const setInitialValues = async () => {
    const countryCode = selectedValue
      ?.find(item => item?.fieldName == 'businessAddress')
      ?.value?.trim()
      ?.split(',')
      ?.pop();

    const addressLines1 = selectedValue?.find(item => item?.fieldName == 'address_lines')?.value?.[0] ?? null;
    const addressLines2 = selectedValue?.find(item => item?.fieldName == 'address_lines')?.value?.[1] ?? '';
    const addressPostalCode = selectedValue?.find(item => item?.fieldName == 'address_postal_code')?.value ?? null;
    const addressLocality = selectedValue?.find(item => item?.fieldName == 'address_locality')?.value ?? null;

    form.setFieldsValue({
      'address_lines_1': addressLines1,
      'address_lines_2': addressLines2,
      'address_postal_code': addressPostalCode,
      'address_locality': addressLocality,
      'latlng': selectedValue?.find(item => item?.fieldName == 'latlng')?.value ?? null,
    });

    setCountry(countryCode?.length ? countryCode : selectedValue?.find(item => item?.fieldName == 'addressRegionCode')?.value ?? null);

    const address = {
      street: selectedValue?.find(item => item?.fieldName == 'address_lines')?.value?.[0] ?? null,
      street2: selectedValue?.find(item => item?.fieldName == 'address_lines')?.value?.[1] ?? '',
      postalCode: selectedValue?.find(item => item?.fieldName == 'address_postal_code')?.value ?? null,
      city: selectedValue?.find(item => item?.fieldName == 'address_locality')?.value ?? null,
    };

    setAddress(address);
    await getLatLng(address);
  };

  useEffect(() => {
    setInitialValues();
  }, [selectedValue, resetCord]);

  const getAddress = async ({lat, lng}) => {
    if (lat && lng) {
      try {
        const address: addressTypes = await addressFromCoordinates(lat, lng);

        form.setFieldsValue({
          'address_lines_1': address?.address_lines_1 ?? null,
          'address_lines_2': address?.address_lines_2 ?? '',
          'address_postal_code': address?.address_postal_code ?? null,
          'address_locality': address?.address_locality ?? null,
          'latlng': `${lat}, ${lng}`,
        });
        setCountry(address?.country ?? null);

        setAddress({
          street: address?.address_lines_1 ?? null,
          street2: address?.address_lines_2 ?? '',
          postalCode: address?.address_postal_code ?? null,
          city: address?.address_locality ?? null,
        });
      } catch (error) {
        console.error('Failed to fetch address:', error);
      }
    }
  };


  const submitHandler = async value => {
    setModalLoader(true);
    const payload = [

      {
        fieldName: 'address_lines',
        value: [value.address_lines_1, value.address_lines_2],
      },
      {
        fieldName: 'address_postal_code',
        value: value.address_postal_code,
      },
      {
        fieldName: 'address_locality',
        value: value.address_locality,
      },

      {
        fieldName: 'latlng',
        value: {
          'latitude': latitude,
          'longitude': longitude,
        },
      },

    ];
    payload?.forEach(item => {
      item['fieldType'] = 'FIELD';
    });
    await updateData(payload);
    setModalLoader(false);
  };


  const handleMarkerDrag = obj => {
    setMarkerMoved(true);
    if (markerMoved) {
      setLatitude(obj?.lat);
      setLongitude(obj?.lng);
      getAddress(obj);
    }
  };

  const handeleAdjust=()=>{
    setMarkerMoved(true);
    handleMarkerDrag({lat: latitude, lng: longitude});
  };

  const resetMarker = () => {
    setResetCord(prev => !prev);
    setMarkerMoved(false);
  };

  const finalizeMarker = () => {
    setMarkerMoved(false);
  };

  const DraggableMarker = ({lat, lng}) => {
    return (
      <div style={{fontSize: '25px', color: '#F44343', cursor: 'grab'}}>
        <Marker lat={lat} lng={lng} />
      </div>
    );
  };

  return (
    <div>
      <Form
        form={form}
        layout='vertical'
        requiredMark={false}
        onFinish={submitHandler}
        initialValues={{
          'address_lines_1': selectedValue?.find(item => item?.fieldName == 'address_lines')?.value?.[0] ?? null,
          'address_lines_2': selectedValue?.find(item => item?.fieldName == 'address_lines')?.value?.[1] ?? '',
          'address_postal_code': selectedValue?.find(item => item?.fieldName == 'address_postal_code')?.value ?? null,
          'address_locality': selectedValue?.find(item => item?.fieldName == 'address_locality')?.value ?? null,
          'country': selectedValue
            ?.find(item => item?.fieldName == 'businessAddress')
            ?.value?.trim()
            ?.split(',')
            ?.pop()
            ?.trim() ?? selectedValue?.find(item => item?.fieldName == 'addressRegionCode')?.value ?? null,
          'latlng': selectedValue?.find(item => item?.fieldName == 'latlng')?.value ?? null,
        }}
      >
        <div style={{display: 'flex', width: '100%'}}>
          <FormContainer style={{width: '90%', padding: '5px 20px 5px 0'}}>
            <CountryField>
              <div className='label'>Country / Region</div>
              <div>{country || '-'}</div>
            </CountryField>
            <Form.Item
              name='address_lines_1'
              label='Street address line 1'
              rules={[
                {
                  required: true,
                  message: 'Address Line is required',
                },
                {
                  whitespace: true,
                  message: 'Input cannot be only spaces.',
                },
              ]}
            >
              <Input value={address?.street} onChange={e => {
                setAddress(prev => ({...prev, street: e.target.value}));
                getLatLng();
              }} />
            </Form.Item>
            <Form.Item
              name='address_lines_2'
              label='Street address line 2 (optional)'
              rules={[
                {
                  whitespace: true,
                  message: 'Input cannot be only spaces.',
                },
              ]}
            >
              <Input value={address?.street2} onChange={e => {
                setAddress(prev => ({...prev, street2: e.target.value}));
                getLatLng();
              }} />
            </Form.Item>
            <Form.Item
              name='address_postal_code'
              label='Postcode'
              rules={[
                {
                  required: true,
                  message: 'Postcode is required',
                },
                {
                  whitespace: true,
                  message: 'Input cannot be only spaces.',
                },
                {
                  pattern: /^[A-Za-z0-9][A-Za-z0-9\- ]{0,14}[A-Za-z0-9]$/,
                  message: 'Enter a valid postcode.',
                },
              ]}
            >
              <Input value={address?.postalCode} onChange={e => {
                setAddress(prev => ({...prev, postalCode: e.target.value}));
                getLatLng();
              }} />
            </Form.Item>
            <Form.Item
              name='address_locality'
              label='Town / City'
              rules={[
                {
                  required: true,
                  message: 'Town / city is required',
                },
                {
                  whitespace: true,
                  message: 'Input cannot be only spaces.',
                },
              ]}
            >
              <Input value={address?.city} onChange={e => {
                setAddress(prev => ({...prev, city: e.target.value}));
                getLatLng();
              }} />
            </Form.Item>

          </FormContainer>
          <div style={{
            position: 'absolute',
            top: 77,
            right: 50,
            zIndex: 10,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}>
            {!markerMoved ? (
              <MapButton onClick={handeleAdjust} >
                <FontAwesomeIcon icon={faLocation} style={{marginRight: 5, width: 14, height: 14}}/>
              Adjust</MapButton>
            ) : (
              <>
                <MapButton onClick={resetMarker} style={{marginRight: 8}}>
        Reset
                </MapButton>
                <MapButton type='primary' onClick={finalizeMarker}>
        Done
                </MapButton>
              </>
            )}
          </div>

          <div style={{width: '960px', padding: 2, borderRadius: '10px', border: '1px solid #A3A4A4'}}>
            <GoogleMapReact
              bootstrapURLKeys={{key: process.env.GOOGLE_MAP_KEY}}
              center={{lat: latitude, lng: longitude}}
              zoom={zoomValue}
              options={createMapOptions}
              onClick={obj => {
                if (markerMoved) handleMarkerDrag(obj);
              }}
              onGoogleApiLoaded={handleGoogleApiLoaded}
            >
              <DraggableMarker lat={latitude} lng={longitude} />
            </GoogleMapReact>

          </div>
        </div>
        <Form.Item style={{marginBottom: '0'}}>
          <ButtonsWrapper>
            <ButtonStyled type='primary' htmlType='submit'>
              Save Changes
            </ButtonStyled>
            <CloseButton onClick={() => {
              setIsModalOpen(false);
              form.resetFields();
            }}>
              Cancel
            </CloseButton>
          </ButtonsWrapper>
        </Form.Item>
      </Form>

    </div>
  );
};


const FormContainer = styled.div`
  .ant-form-item-label {
    padding: 0 0 4px;
  }
  .ant-form-item-label label{
    font-size: 12px !important;
    color: #4E5156 !important;
  }
  .ant-form-vertical {
    padding: unset !important;
  }
  .ant-input {
    height: 38px !important;
    border-radius: 8px !important;
  }
  .ant-form-item {
  margin-top: 10px !important;
  margin-bottom: unset !important;
  }
  .ant-switch-checked {
    background: #2D6CCA;
  }

  .ant-switch-handle {
    width: 18px;
    height: 18px;
  }

  .ant-switch-handle::before {
    top: 1px;
    content: '';
    background-image: url('/icons/checkmark.svg');
    background-size: inherit;
    background-repeat: no-repeat;
    background-position: center;
  }
  .ant-switch {
    width: 42px;
    height: 24px;
  }
`;

const MapButton = styled(button)`
  height: 27px !important;
  border-radius: 8px !important;
  width: 100% !important;
  margin-top: 38px !important;
  border-radius: 8px !important;
  background-color: #FFFFFF !important;
  color: #2D6CCA !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  padding: 1px 15px;
  `;


const CountryField = styled.div`
margin-bottom: 10px;
.label{
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
}
.value {
  margin-top: 4px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: left;
  color: #121212;
}
`;
