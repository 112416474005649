import styled from 'styled-components';
import {faCaretDown, faCaretUp} from '@fortawesome/pro-solid-svg-icons';
import {Input} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';

interface Props {
    value?: number;
    setValue?: React.Dispatch<React.SetStateAction<number | string>>;
    step?: number;
    min?: number;
    max?: number;
    hasError?: boolean;
    rootClassName?: string;
    inputClassName?: string;
    width?: number;
}

export default function CustomNumberInput({value, setValue, step = 1, min = 1, max, hasError, rootClassName, inputClassName, width}: Props) {
  const getUpdatedValue = newValue => {
    let final = Number(newValue);
    if (final % 1 != 0 || final < 0) {
      return value;
    } else if (newValue < min) {
      final = Number(min);
    } else if (max && newValue > max) {
      final = Number(max);
    }
    return final;
  };
  const onKeyDown = e => {
    if (e.keyCode === 38) {
      setValue(prev => getUpdatedValue(Number(prev) + step));
    } else if (e.keyCode === 40 && value > step) {
      setValue(prev => getUpdatedValue(Number(prev) - step));
    }
  };
  return (
    <StyledInputContainer hasError={hasError} className={rootClassName} width={width}>
      <Input
        type='text'
        value={value}
        className={inputClassName}
        onChange={e => {
          const inputValue = e?.target?.value?.replace(/[^0-9]/g, '');
          if (!inputValue) {
            setValue(inputValue);
            return;
          }
          setValue(getUpdatedValue(inputValue));
        }}
        onKeyDown={onKeyDown}
        step={step}
        min={min}
        max={max}
      />
      <div className='input-handle-container'>
        <FontAwesomeIcon onClick={() => {
          setValue(prev => getUpdatedValue(Number(prev) + step));
        }} className='input-handle' icon={faCaretUp} />
        <FontAwesomeIcon onClick={() => {
          value > step && setValue(prev => getUpdatedValue(Number(prev) - step));
        }} className='input-handle' icon={faCaretDown} />
      </div>
    </StyledInputContainer>
  );
}

const StyledInputContainer = styled.div<{hasError?: boolean; width?: number}>`
  max-width: ${p => p?.width ? `${p?.width}px` : '62px'};
  position: relative;
  .input-handle-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 5px;
    right: 8px;
    pointer-events: none;
    opacity: 0;
    transition: all 0.3s ease-in;
    .input-handle {
        cursor: pointer;
      &:last-child {
        margin-top: -5px;
      }
    }
  }
  input {
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
    font-family: Inter, sans-serif;
    ${p => p?.hasError ? 'border-color: #ff4d4f !important;' : ''}
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  &:hover {
      .input-handle-container {
        opacity: 100% !important;
        pointer-events: all !important;
      }
    }
`;
