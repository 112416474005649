import {getGoogleConnectUrl, getGoogleAnalyticsConnectUrl} from '@/api/gsc/utils';
import {getApiUrl, getAuthorizationHeader, getTokenFromCookies} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';
import {MixPanel} from '@/utils/mixpanel';
import {SETTINGS_GA_AUTHORIZE_CLICKED, SETTINGS_GBP_AUTHORIZE_CLICKED} from '@/constants/events';

class IntegrationApi extends BaseApi {
  private static readonly googleConnectUrl: string = getGoogleConnectUrl();
  private static readonly googleAnalyticsConnectUrl: string = getGoogleAnalyticsConnectUrl();
  private static readonly baseUrl: string = getApiUrl(BaseApi.GSC_ENDPOINT, '/search-console/api/v2');
  private static readonly gscBaseUrl: string = getApiUrl(BaseApi.GSC_ENDPOINT, '');


  public async getConnectedGoogleProfiles() {
    const response = await this.axios.get(`${IntegrationApi.baseUrl}/google-user-profiles/`, {
      headers: {Authorization: getAuthorizationHeader()},
      cancelToken: this.cancelToken,
    });
    return response.data;
  }

  public getGoogleConnectUrl(param='') {
    const token = getTokenFromCookies();
    if (typeof window !== 'undefined') {
      return `${IntegrationApi.googleConnectUrl}?jwt=${token}&redirect_path=${window.location.pathname}${param}`;
    } else {
      return `${IntegrationApi.googleConnectUrl}?jwt=${token}`;
    }
  }

  public getFacebookConnectUrl(param='') {
    const token = getTokenFromCookies();
    if (typeof window !== 'undefined') {
      return `${IntegrationApi.gscBaseUrl}/oauth/start_facebook_ads/?jwt=${token}&redirect_path=${window.location.pathname}${param}`;
    } else {
      return `${IntegrationApi.gscBaseUrl}/oauth/start_facebook_ads/?jwt=${token}`;
    }
  }

  public getGoogleAdsUrl(param='') {
    const token = getTokenFromCookies();
    if (typeof window !== 'undefined') {
      return `${IntegrationApi.gscBaseUrl}/oauth/start_google_ads/?jwt=${token}&redirect_path=${window.location.pathname}${param}`;
    } else {
      return `${IntegrationApi.gscBaseUrl}/oauth/start_google_ads/?jwt=${token}`;
    }
  }

  public getGoogleAnalyticsConnectUrl(param='') {
    MixPanel.track(SETTINGS_GA_AUTHORIZE_CLICKED);
    const token = getTokenFromCookies();
    if (typeof window !== 'undefined') {
      const redirectPath = param ? encodeURIComponent(`${window.location.pathname}${param}`) : window.location.pathname;
      return `${IntegrationApi.googleAnalyticsConnectUrl}?jwt=${token}&redirect_path=${redirectPath}`;
    } else {
      return `${IntegrationApi.googleAnalyticsConnectUrl}?jwt=${token}`;
    }
  }

  public getGoogleMyBusinessConnectUrl(param='') {
    MixPanel.track(SETTINGS_GBP_AUTHORIZE_CLICKED);
    const token = getTokenFromCookies();
    if (typeof window !== 'undefined') {
      return `${IntegrationApi.gscBaseUrl}/oauth/start_business/?jwt=${token}&redirect_path=${window.location.pathname}${param}`;
    } else {
      return `${IntegrationApi.gscBaseUrl}/oauth/start_business/?jwt=${token}`;
    }
  }


  // To be used when adding disconnecting google accounts functionality
  public async removeConnectedGoogleAccount(email: string) {
    try {
      const response = await this.axios.delete(`${IntegrationApi.baseUrl}/google-user-profiles/${email}/`, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async refreshEmail(email: string) {
    try {
      const response = await this.axios.post(`${IntegrationApi.baseUrl}/google-user-profiles/${email}/refresh-gsc-account/`, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async removeConnectedGAAccount(email: string) {
    try {
      const response = await this.axios.patch(`${IntegrationApi.baseUrl}/google-user-profiles/${email}/disconnect-ga/`, {}, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async getGscConnectUrl() {
    try {
      const response = await this.axios.get(`${IntegrationApi.gscBaseUrl}/oauth/start/v2/`, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response?.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async getGaConnectUrl() {
    try {
      const response = await this.axios.get(`${IntegrationApi.gscBaseUrl}/oauth/start_analytics/v2/`, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response?.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async getGmbConnectUrl() {
    try {
      const response = await this.axios.get(`${IntegrationApi.gscBaseUrl}/oauth/start_business/v2/`, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response?.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async getGoogleAdsConnectUrl() {
    try {
      const response = await this.axios.get(`${IntegrationApi.gscBaseUrl}/oauth/start_google_ads/v2/`, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response?.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async getFacebookAdsConnectUrl() {
    try {
      const response = await this.axios.get(`${IntegrationApi.gscBaseUrl}/oauth/start_facebook_ads/v2/ `, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response?.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default IntegrationApi;
