import {observer} from 'mobx-react-lite';
import {Button} from '@/components/common-components/components';
import {CountdownCircleTimer} from 'react-countdown-circle-timer';
import {MoreOutlined} from '@ant-design/icons';
import {useState} from 'react';
import styles from './styles.module.scss';
import {useTranslation} from 'next-i18next';
import {Dropdown, Menu} from 'antd';

interface Props {
    editFn: any;
    deleteFn: any;
    objectId: number;
    editableObject: any;
}

export const MoreOptionsMenu = observer(({editFn, deleteFn, objectId, editableObject}: Props) => {
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const [deleteTimout, setDeleteTimout] = useState<any>('');
  const {t} = useTranslation('common');

  const deleteEvent = () => {
    setIsDeleteMode(true);
    const timeout = setTimeout(() => {
      deleteFn(objectId);
      setIsDeleteMode(false);
    }, 3000);
    setDeleteTimout(timeout);
  };

  const stopDeleteEvent = () => {
    clearTimeout(deleteTimout);
    setIsDeleteMode(false);
  };

  const optionsOverlay = () => (
    <Menu className={styles.moreOptionsMenu}>
      <Menu.Item style={{maxHeight: 40}}><Button buttonType='transparent' style={{textAlign: 'left', color: '#000'}} onClick={() => editFn(editableObject)}>{t('more-options-menu-edit')}</Button></Menu.Item>
      <Menu.Item style={{maxHeight: 40}}><Button buttonType='transparent' style={{textAlign: 'left', color: '#F44343'}} onClick={() => deleteEvent()}>{t('more-options-menu-delete')}</Button></Menu.Item>
    </Menu>
  );

  return (
    <div>
      <Button
        buttonType='transparent' style={{color: '#4E5156', padding: 0}}>
        {isDeleteMode == false && <Dropdown overlay={optionsOverlay()} trigger={['click']}>
          <MoreOutlined />
        </Dropdown> }
        {isDeleteMode && <div className={styles.deleteModeOn}>
          <Button buttonType={'transparent'} className={styles.undoButton} onClick={() => stopDeleteEvent()}>{t('more-options-menu-undo')}</Button>
          <CountdownCircleTimer
            isPlaying
            duration={3}
            size={20}
            trailStrokeWidth={2}
            strokeWidth={2}
            colors={['#2D6CCA', '#2D6CCA']}
            colorsTime={[10, 10]}
          />
        </div>
        }

      </Button>
    </div>
  );
});

export default MoreOptionsMenu;
