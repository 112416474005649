import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {COLORS} from '@/components/common-components/v2/colors';
import {formatNumber, getPercentage, numberWithCommas} from '@/utils/number';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Divider, Popover, Progress, Button} from 'antd';
import styles from './styles.module.scss';
import {faSquareQuestion} from '@fortawesome/pro-duotone-svg-icons';
import {getInversePercentageColor} from '@/utils/colors';
import {faMoneySimpleFromBracket} from '@fortawesome/pro-solid-svg-icons';

interface Props {
  variant?: 'light';
  style?: any;
  topupCallback?: any;
  isHeader?: any;
  component?: any;
}

export const KeywordsQuotaV2 = observer(({variant, style = {}, topupCallback, isHeader=false, component=null}: Props) => {
  const {
    ottoV2Store: {quotaAllocations, getOttoQuotaAllocations},
    settings: {customer: {isGHLAccount, profile: {quotaUtilization, isVendastaClient}}},
  } = useStore('');

  const total = quotaUtilization?.ke?.allowedTrackedKeywords?.total || 100;
  const consumed = quotaUtilization?.ke?.allowedTrackedKeywords?.consumed || 0;
  const keywordsQuotaDiff=Number(total)-Number(consumed);
  const percentage = getPercentage(Number(consumed), 0, Number(total));

  const handlePopoverVisibleChange = (visible: boolean) => {
    visible && !quotaAllocations && getOttoQuotaAllocations();
  };

  const popOverComp = () => {
    return <Popover
      onVisibleChange={handlePopoverVisibleChange}
      trigger={'hover'}
      overlayInnerStyle={{
        background: '#121212',
        boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.08)',
        borderRadius: 12,
      }}
      zIndex={9999}
      overlayClassName={styles.overlay}
      placement='bottom'
      content={<>
        <Title>Quota Utilization</Title>
        <KeywordTooltipTitle>Keyword Rank Tracker</KeywordTooltipTitle>
        <Data>
          <div>
            <Heading>Used:</Heading>
            <Heading>Remaining:</Heading>
            <Heading>Total:</Heading>
          </div>
          <div>
            <Value>{numberWithCommas(quotaUtilization?.ke?.allowedTrackedKeywords?.consumed)} keywords</Value>
            <Value remaing>{numberWithCommas(keywordsQuotaDiff)} keywords</Value>
            <Value>{quotaUtilization?.ke?.allowedTrackedKeywords?.total ? numberWithCommas(quotaUtilization?.ke?.allowedTrackedKeywords?.total) : 100} keywords</Value>
          </div>
        </Data>
        {isGHLAccount || isVendastaClient ? <></> : <TopupButton onClick={e => !isVendastaClient && topupCallback(e)} disabled={isVendastaClient}>
          <FontAwesomeIcon icon={faMoneySimpleFromBracket} color='#fff' /> Top Up
        </TopupButton>}
      </>} >
      {isHeader ? component : <FontAwesomeIcon icon={faSquareQuestion} color='#A3A4A4' fontSize={14} style={{marginLeft: 6, marginTop: 5, cursor: 'pointer'}} />}
    </Popover>;
  };

  return isHeader ? popOverComp() : <QuotaContainer style={style}>
    <QuotaDivSA variant={variant}>
      <span style={{display: 'flex'}} >Keywords:<QuotaValues>{quotaUtilization?.ke?.allowedTrackedKeywords?.consumed ? `${formatNumber(quotaUtilization?.ke?.allowedTrackedKeywords?.consumed, 1)}`?.replace('.0', '') : 0} </QuotaValues>
        <QuotaSubValue>/{quotaUtilization?.ke?.allowedTrackedKeywords?.total ? `${formatNumber(quotaUtilization?.ke?.allowedTrackedKeywords?.total, 1)}`?.replace('.0', '') : 100}</QuotaSubValue>
        {popOverComp()}
      </span>
      <ProgressStyled
        variant={variant}
        trailColor={'#4E5156'}
        strokeColor={getInversePercentageColor(isNaN(percentage) ? 100 : percentage)}
        percent={isNaN(percentage) ? 100 : percentage}
        showInfo={false} />
    </QuotaDivSA>
  </QuotaContainer>;
});

export const PagesQuota = observer(() => {
  const {
    settings: {customer: {profile: {quotaUtilization}}},
  } = useStore('');

  const consumed = quotaUtilization?.ke?.allowedTrackedProjects?.consumed || 0;
  const total = quotaUtilization?.ke?.allowedTrackedProjects?.total || 100;
  const pagesQuotaDiff=Number(total)-Number(consumed);
  const percentage = getPercentage(Number(consumed), 0, Number(total));

  return <QuotaContainer>
    <QuotaDivSA>
      <span style={{display: 'flex'}} >Projects:<QuotaValues>{formatNumber(quotaUtilization?.ke?.allowedTrackedProjects?.consumed) || 0} </QuotaValues> <QuotaSubValue>/{formatNumber(quotaUtilization?.ke?.allowedTrackedProjects?.total) || 100}</QuotaSubValue>
        <Popover
          trigger={'hover'}
          overlayInnerStyle={{
            width: 157,
            // height: 160,
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), #2D2F34',
            boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.08)',
            borderRadius: 8,
          }}
          overlayClassName={styles.overlay}
          placement='bottom'
          content={<>
            <TooltipTitle>Total number of Rank Tracking Sites</TooltipTitle>
            <Divider style={{background: '#4E5156', margin: '10px 0px'}}/>
            <div style={{display: 'flex', justifyContent: 'space-between', color: '#fff', fontSize: 12}}>
              <> <span>Used:</span><span>{numberWithCommas(quotaUtilization?.ke?.allowedTrackedProjects?.consumed)}</span></>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', color: '#fff', fontSize: 12}}>
              <> <span>Remaining:</span><span style={{color: pagesQuotaDiff > 0 ? '#15ac47' : '#f44343'}}>{numberWithCommas(pagesQuotaDiff)}</span></>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', color: '#fff', fontSize: 12}}>
              <> <span>Total:</span><span>{numberWithCommas(quotaUtilization?.ke?.allowedTrackedProjects?.total)}</span></>
            </div>

          </>} >
          <FontAwesomeIcon icon={faSquareQuestion} color='#A3A4A4' fontSize={14} style={{marginLeft: 6, marginTop: 5, cursor: 'pointer'}} />
        </Popover>
      </span>
      <ProgressStyled
        trailColor={'#4E5156'}
        strokeColor={getInversePercentageColor(isNaN(percentage) ? 100 : percentage)}
        percent={isNaN(percentage) ? 100 : percentage}
        showInfo={false} />
    </QuotaDivSA>
  </QuotaContainer>;
});

const QuotaContainer = styled.div`
display:flex;
gap: 25px;
margin-right: 20px;
`;
const ProgressStyled = styled(Progress)<{variant?: 'light'}>`
margin-top:-7px;
  .ant-progress-inner {
    height: 2px;
    background-color: ${p => p?.variant == 'light' ? '#f5f5f5 !important' : 'rgb(78, 81, 86)'};
  }
`;
const TooltipTitle = styled.div`
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 15px;
color: #FFFFFF;
`;

const QuotaDivSA = styled.div<{variant?: 'light'}>`
  display:flex;
  flex-direction:column;
  font-size:14px;
  font-weight:400;
  color: ${p => p?.variant == 'light' ? COLORS.black : COLORS.gray};
  margin-right: 30px;
  `;
const QuotaValues = styled.div`
  margin-left:5px;
`;
const QuotaSubValue = styled.div`
  font-size:12px;
  display: flex;
  align-items: flex-end;
`;

const TopupButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: #2AC155 !important;
  width: 96px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 5px;
  border: none;
  margin-top: 12px;
  cursor: pointer;
  color: #fff !important;
  &:hover, &:focus{
    color: #fff;
  }
`;

const Title = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #E8E8E8;
`;

const KeywordTooltipTitle = styled.div`
  margin-bottom: 11px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #FFFFFF;
`;

const Data = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 19px;
  margin-bottom: 15px;
`;

const Heading = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #FFFFFF;
`;

const Value = styled.div<{remaing?: boolean}>`
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: ${p => p?.remaing ? '#2AC155' : '#FFFFFF'};
`;
