import {Collapse, Input, Spin, Tooltip} from 'antd';
import {observer} from 'mobx-react';
import React, {useCallback, useEffect, useState} from 'react';
import {LoadingOutlined} from '@ant-design/icons';
import {useStore} from '@/store/root-store';
import {PaginationStyled, Pill, PurpleCheckbox, StyledButton, StyledIssuesCollapse, StyledIssuesTable, StyledTrashIcon, UrlWrapper} from '../../../../style';
import {TableTopBar} from '../../../tableTopBar';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import moment from 'moment';
import {openUrl} from '@/utils/router';
import {debounce} from 'lodash';
import {addProtocolToDomainHttps, getSingleUrlParam} from '@/utils/url';
import {faTrash, faXmark} from '@fortawesome/pro-regular-svg-icons';
import {getOttoTablePageSize} from '@/api/common-utils';
import {saveOttoTablePageSize} from '@/api/common-utils';
import AutoIndexingSwitch from '../siteMapIndexing/autoIndexingSwitch';
import {urlWithProtocolRegex} from '@/constants/regex';
import styled from 'styled-components';
import {gscInstantIndexingStatus, statusColorMapping} from '../../../../Constants';
import {BulkActionBar} from '../../../bulkActionBar';
import FreezeWrapper from '../../../freezTooltip';
import {useRouter} from 'next/router';

interface Props {
  componentIssueType: string;
  setPageChanged: (value: boolean) => void;
  setIssueTable: (value: string) => void;
  showConfirmationPopup: (value: any) => void;
  issueTable: string;
  maxHeight?: string;
  removeTopBar?: boolean;
  deleteItem?: any;
}

export const CustomUrlBasedIndexing = observer(({componentIssueType, setPageChanged, setIssueTable, showConfirmationPopup, issueTable, maxHeight = null, removeTopBar = false, deleteItem}: Props) => {
  const {ottoV2Store: {
    ottoUrls,
    getOttoUrls,
    getOttoV2Project,
    loadIssueTableData,
    ottoUrlLoader,
    ottoIssueType,
    setSelectedIssue,
    setSelectedCategory,
    setLoadingDetail,
    selectedCategory,
    setOttoSearchTerm,
    issueTypeArray,
    selectedIssue,
    createIndexingUrl,
    hasAccess,
    deletingCustomUrl,
  }} = useStore('');

  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isTableOpen, setIsTableOpen] = useState<any>('open');
  const [isActiveFilter, setIsActiveFilter] = useState('all');
  const [addRecord, setAddRecord] = useState(false);
  const data = getOttoUrls?.find(i => i?.issueType === componentIssueType);
  const uuid = getSingleUrlParam('uuid');
  const [pageUrl, setPageUrl] = useState('');
  const [addRecordError, setAddRecordError] = useState(false);
  const [addingPage, setAddingPage] = useState(false);
  const [pageSize, setPageSize] = useState(() => getOttoTablePageSize(componentIssueType));
  const [showBulkBanner, setShowBulkBanner] = useState<boolean>(false);
  const [selectedPages, setSelectedPages] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const domainName = getOttoV2Project?.hostname || getSingleUrlParam('domain');
  const issueTableResults = data?.issueTable?.results || [];
  const router = useRouter();
  const [tableData, setTableData] = useState([]);
  const filteredData = addRecord ? [{id: -1, isFirstObject: true}, ...issueTableResults] : issueTableResults;

  useEffect(() => {
    if (filteredData?.length && selectAll) {
      const pageArray = filteredData.map(data => data?.id);
      setSelectedPages(pageArray);
    }
  }, [ottoUrls, selectAll]);

  useEffect(() => {
    if (filteredData && deleteItem == -1) {
      setTableData(filteredData);
    } else {
      const newData = filteredData?.filter(val=> val?.id !== deleteItem);
      setTableData(newData);
    }
  }, [deleteItem, addRecord, data]);

  const isValidUrl = (url: string) => urlWithProtocolRegex.test(url);

  const onAddPage = async () => {
    let fullUrl = '';
    if (pageUrl.startsWith('/')) {
      fullUrl = addProtocolToDomainHttps(`${domainName+pageUrl}`);
    } else {
      fullUrl = addProtocolToDomainHttps(`${domainName+'/'+pageUrl}`);
    }
    if (!isValidUrl(fullUrl)) {
      setAddRecordError(true);
      return;
    }
    setAddingPage(true);
    const filter = getOttoUrls.filter(d => d.issueType === componentIssueType);
    await createIndexingUrl({otto_project_uuid: uuid, indexing_urls: [fullUrl]});
    await loadIssueTableData({uuid: getOttoV2Project?.uuid, page: filter?.length ? filter[0].page : 1, page_size: filter?.length ? filter[0].pageSize : 10, issue_type: componentIssueType, otto_project: getOttoV2Project?.id});
    setPageUrl('');
    setAddRecord(false);
    setAddingPage(false);
  };

  const onChangePageUrl = (value: string) => {
    setPageUrl(value);
    const fullUrl = addProtocolToDomainHttps(`${domainName+'/'+value}`);
    if (!isValidUrl(fullUrl)) {
      setAddRecordError(true);
    } else {
      setAddRecordError(false);
    }
  };

  const selectUnselectPages = page => {
    if (selectedPages.includes(page)) {
      setSelectedPages(data => data.filter(item => item !== page));
      setSelectAll(false);
    } else {
      setSelectedPages(data => [...data, page]);
    }
  };

  const selectAllOnPage = () => {
    setSelectedPages(filteredData.map(data => data?.id));
  };

  const columns = [
    {
      title: <div className='column-title'>PAGE URL</div>,
      dataIndex: 'url',
      key: 'url',
      onCell: record => record?.isFirstObject ? {colSpan: 1} : {},
      width: removeTopBar ? 300 : 650,
      className: 'no-padding-right',
      render: (_, record) => {
        if (record?.isFirstObject) {
          return <>
            <SlugInputWrapper style={{borderColor: addRecordError && '#ff4d4f'}}>
              <div className='domain'>{domainName}/</div><Input autoFocus className='slug' value={pageUrl} onChange={e => onChangePageUrl(e.target.value)} bordered={false}></Input>
            </SlugInputWrapper>
            {addRecordError && <div style={{color: 'red', fontSize: '11px'}}>Please enter a valid slug.</div>}
          </>;
        } else {
          return <div style={{display: 'flex', alignItems: 'center', gap: 20}}>
            {showBulkBanner ? <PurpleCheckbox checked={selectedPages.includes(record?.id)} onClick={()=>selectUnselectPages(record?.id)}/> :
              <Tooltip placement={'topLeft'} title={!record?.isSelected && gscInstantIndexingStatus.stopChecking.includes(record?.status) ? 'Please fix the issue before enabling for indexation' : ''}>
                <div>
                  <AutoIndexingSwitch key={record?.id} record={record} componentIssueType={componentIssueType} hasAccess={hasAccess} indexing={true}/>
                </div>
              </Tooltip>
            }
            <UrlWrapper style={{maxWidth: '100%'}} onClick={() => openUrl(record?.url, '_blank')}>{record?.url}</UrlWrapper>
          </div>;
        }
      },
    },
    {
      title: <div className='column-title'>STATUS</div>,
      dataIndex: 'targetPage',
      key: 'targetPage',
      width: 125,
      render: (_, record) => {
        if (record?.status) {
          return <Pill style={{background: statusColorMapping[record?.status] ? `${statusColorMapping[record.status]}12` : '#A3A4A412'}}>
            <div
              className='dot'
              style={{background: statusColorMapping[record?.status] ? `${statusColorMapping[record.status]}` : '#A3A4A4'}}
            />{record.status}</Pill>;
        } else {
          return <></>;
        }
      },
    },
    {
      title: <div className='column-title' style={{whiteSpace: 'nowrap'}}>SUBMITTED ON</div>,
      dataIndex: 'status',
      key: 'status',
      width: 150,
      render: (_, record) => {
        if (record?.isFirstObject) {
          return <div style={{display: 'flex', alignItems: 'center', gap: '6px'}}>
            <Tooltip title={!pageUrl?.trim()?.length ? 'Please enter a Page Url.' : ''}>
              <span>
                <StyledButton disabled={!pageUrl?.trim()?.length || addingPage} onClick={onAddPage} color='green'>Add Page</StyledButton>
              </span>
            </Tooltip>
            <div onClick={() => {
              setAddRecord(false);
              setPageUrl('');
            }} style={{padding: '5px 6px', backgroundColor: '#4E5156', borderRadius: '4px', width: '24px', height: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}}>
              <FontAwesomeIcon icon={faXmark} fontSize={14} color='#fff'/>
            </div>
            {addingPage && <Spin indicator={<LoadingOutlined style={{fontSize: 14, color: '#A3A4A4'}} spin />}/>}
          </div>;
        } else {
          return <div>{record?.lastSentToIndexerAt ? moment(record?.lastSentToIndexerAt)?.format('MMM D, YYYY') : '-'}</div>;
        }
      },
    },
    {
      title: '',
      dataIndex: 'delete',
      key: 'trashIcon',
      className: 'text-align-middle',
      width: 40,
      render: (_, record) => {
        if (record?.isFirstObject) {
          return <>-</>;
        } else {
          return (
            <FreezeWrapper removeTooltip={!router?.pathname?.includes('otto-page-v2')}>
              <Tooltip title={'Delete'}>
                {(deleteItem === record?.id && deletingCustomUrl) ?
                  <Spin indicator={<LoadingOutlined style={{fontSize: 14, color: '#A3A4A4'}} spin />}/>:
                  <StyledTrashIcon
                    icon={faTrash}
                    fontSize={14}
                    color='#A3A4A4'
                    onClick={() => {
                      showConfirmationPopup({...record, issueType: 'custom_url_based_indexing'});
                    }}
                  />}
              </Tooltip>
            </FreezeWrapper>
          );
        }
      },
    },
  ];

  const searchDebounce = debounce(async (params, isSitewide) => {
    await loadIssueTableData(params, isSitewide);
  }, 1000);


  const loadIssueTables = useCallback(async (issueArray: string[], issue: string, category: string, page: number, pageSize: number, stopLoading?: boolean, search?: string) => {
    setSelectedIssue(issue);
    setSelectedCategory(category);
    if (!stopLoading) {
      setLoadingDetail(true);
    }
    for (let index = 0; index < issueArray.length; index++) {
      const issueType = issueArray[index];
      if (issueTypeArray.includes(issueType)) {
        const params = {
          uuid,
          otto_project: getOttoV2Project?.id,
          issue_type: issueType,
          page_size: pageSize,
          page,
          is_loading: false,
        };
        if (search) {
          params['search'] = search;
          setOttoSearchTerm(search);
        } else {
          setOttoSearchTerm('');
        }
        if (search) {
          searchDebounce(params, false);
        } else {
          await loadIssueTableData(params, false);
        }
      }
    }
    setLoadingDetail(false);
  }, []);

  const handlePaginationChange = (issueArray: string[], page, pageSize) => {
    loadIssueTables(issueArray, selectedIssue, selectedCategory, page, pageSize, true, '');
  };

  return (
    <>
      <StyledIssuesCollapse
        ghost
        expandIconPosition='right'
        defaultActiveKey={[`${isTableOpen}`]}
        activeKey={[`${isTableOpen}`]}
        onChange={() => {
          if (isTableOpen === 'close') {
            setIsTableOpen('open');
            setIsOpenSearch(false);
          } else {
            setIsTableOpen('close');
            setIsOpenSearch(false);
          }
        }}
      >
        {!removeTopBar && <TableTopBar
          componentIssueType={componentIssueType}
          setPageChanged={setPageChanged}
          setIssueTable={setIssueTable}
          issueTable={issueTable}
          setSearchText={setSearchText}
          searchText={searchText}
          setIsOpenSearch={setIsOpenSearch}
          isOpenSearch={isOpenSearch}
          setIsTableOpen={setIsTableOpen}
          isTableOpen={isTableOpen}
          setAddRecord={setAddRecord}
          setIsActiveFilter={setIsActiveFilter}
          isActiveFilter={isActiveFilter}
          hasAccess={hasAccess}
          setShowBulkBanner={setShowBulkBanner}
        />}
        {showBulkBanner ? <BulkActionBar
          count={data?.issueTable?.count}
          setShowBulkBanner={setShowBulkBanner}
          selectedPages={selectedPages}
          setSelectedPages={setSelectedPages}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
          selectAllOnPage={selectAllOnPage}
          issueType={data?.issueType}
          currentProject={''}
          setPageChanged={setPageChanged}
          deployButtonText={'Enable Auto Indexing'}
          rollBackButtonText={'Disable Auto Indexing'}
          isSiteMap
        /> : <></>}
        <Collapse.Panel key='open' header={<></>}>
          <StyledIssuesTable
            loading={ottoUrlLoader && ottoIssueType === componentIssueType}
            columns={columns}
            dataSource={tableData}
            scroll={removeTopBar ? {x: 850, y: 'auto'} : {x: 850}}
            pagination={false}
            maxHeight={maxHeight}
          />
          <PaginationStyled
            onChange={(page, pageSize) => {
              setPageChanged(true);
              handlePaginationChange([data?.issueType], page, pageSize);
              saveOttoTablePageSize(componentIssueType, pageSize);
              setPageSize(pageSize);
            }}
            total={data?.issueTable?.count}
            pageSize={pageSize}
            current={data?.page ?? 1}
            showSizeChanger
            pageSizeOptions={['5', '10', '20', '50', '100']}
          />
        </Collapse.Panel>
      </StyledIssuesCollapse>
    </>
  );
});
export const SlugInputWrapper = styled.div`
  height: 26px;
  border-radius: 6px;
  border: 1px solid #CDCDCD;
  display: flex;
  align-items: center;
  padding: 4px 11px;
  .domain {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    color: #A3A4A4;
  }
  .slug {
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    padding: 0px !important;
  }
`;
