import {observer} from 'mobx-react';
import React from 'react';
import {useStore} from '@/store/root-store';
import {snakeToCamel} from '@/utils/string';
import styled from 'styled-components';
import {deployStatusFilterLabelMapping} from '../Constants';

interface Props {
  data: any;
  isActiveFilter?: string;
  onClick: any;
  isSitewide?: boolean;
  selectedRatings: any;
  newFilters?: boolean;
  isV2?: boolean;
}

export const DeployStatusFilter = observer(({data, onClick, isSitewide, isActiveFilter, selectedRatings, newFilters, isV2}: Props) => {
  const {ottoV2Store: {getOttoV2Project, ottoUrlLoader}} = useStore('');
  const issues = getOttoV2Project?.issuesCountBreakdown['issues'] || {};
  const ratingString = selectedRatings?.join(',');
  return (
    <DeployStatusWrapper>
      <DeployStatus
        disabled={ottoUrlLoader}
        onClick={() => ottoUrlLoader ? {} : onClick('all', data?.issueType, data.page, data?.pageSize, isSitewide, ratingString)}
        isActive={isActiveFilter == 'all'}
        newFilters={newFilters}
        borderRadius='6px 0px 0px 6px'
        borderRight='none'
      >All</DeployStatus>
      <DeployStatus
        disabled={ottoUrlLoader}
        onClick={() => ottoUrlLoader ? {} : onClick('deployed', data?.issueType, data.page, data?.pageSize, isSitewide, ratingString)}
        isActive={isActiveFilter == 'deployed'}
        newFilters={newFilters}
        borderRadius='0'
        borderRight='none'
      >{
          deployStatusFilterLabelMapping(isV2)?.deployed[data?.issueType] ?
            deployStatusFilterLabelMapping(isV2)?.deployed[data?.issueType] :
            `Deployed (${issues[snakeToCamel(data?.issueType)]?.approved > 0 ? issues[snakeToCamel(data?.issueType)]?.approved : 0})`
        }
      </DeployStatus>
      <DeployStatus
        disabled={ottoUrlLoader}
        onClick={() => ottoUrlLoader ? {} : onClick('not_deployed', data?.issueType, data.page, data?.pageSize, isSitewide, ratingString)}
        isActive={isActiveFilter == 'not_deployed'}
        newFilters={newFilters}
        borderRadius='0px 6px 6px 0px'
        borderRight=''
      >{

          deployStatusFilterLabelMapping(isV2)?.notDeployed[data?.issueType] ?
            deployStatusFilterLabelMapping(isV2)?.notDeployed[data?.issueType] :
            `Not Deployed (${(issues[snakeToCamel(data?.issueType)]?.total ?? 0) - (issues[snakeToCamel(data?.issueType)]?.approved > 0 ? issues[snakeToCamel(data?.issueType)]?.approved : 0)})`
        }
      </DeployStatus>
    </DeployStatusWrapper>
  );
});

const DeployStatusWrapper = styled.div`
  display: flex;
`;

const DeployStatus = styled.div<{borderRadius: string; isActive: boolean; newFilters: boolean; borderRight: string; disabled: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  //height: 28px;
  padding: 5px 10px 5px 10px;
  border-radius: ${p => p?.borderRadius};
  border: 1px solid ${p => (p?.isActive ? p?.newFilters ? '#B498D0' : '#2D6CCA' : '#CDCDCD')};
  background: ${p => (p?.isActive ? p?.newFilters ? 'rgba(127, 78, 173, 0.15)' : 'rgba(45, 108, 202, 0.15)' : '')};
  cursor: ${p => p.disabled ? 'not-allowed' : 'pointer'};
`;
