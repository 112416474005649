import {getApiUrl, getAuthHeader, getAuthorizationHeader} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';
import {CriteriaType} from '@/store/gsc-store/criteria';
import {getSingleUrlParam} from '@/utils/url';


class ReportsApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.GSC_ENDPOINT, '/search-console/api/v2');
  private static readonly baseUrlV3: string = getApiUrl(BaseApi.GSC_ENDPOINT, '/search-console/api/v3');

  // private coreReportsCancel;

  public async getCoreReports({property, previousPeriodStart, previousPeriodEnd, currentPeriodStart, currentPeriodEnd, countryCode}: CriteriaType, publicHash?: string) {
    const response = await this.axios.get(`${ReportsApi.baseUrl}/core-reports/`, {
      params: {
        selected_property: property,
        period1_start: previousPeriodStart,
        period1_end: previousPeriodEnd,
        period2_start: currentPeriodStart,
        period2_end: currentPeriodEnd,
        country_code: countryCode,
        ...(publicHash && {site_share_hash: publicHash}),
      },
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }

  public async getPaidMetrices({property, previousPeriodStart, previousPeriodEnd, currentPeriodStart, currentPeriodEnd, countryCode}: CriteriaType) {
    const publicHash = getSingleUrlParam('public_hash');
    const response = await this.axios.get(`${ReportsApi.baseUrl}/paid-metrics/`, {
      params: {
        selected_property: property,
        period1_start: previousPeriodStart,
        period1_end: previousPeriodEnd,
        period2_start: currentPeriodStart,
        period2_end: currentPeriodEnd,
        country_code: countryCode,
        ...(publicHash && {site_share_hash: publicHash}),
      },
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }

  public async getPublicHashToken(criteria) {
    const response = await this.axios.get(`${ReportsApi.baseUrlV3}/sites/share-hash-key/`, {
      params: {
        selected_property: criteria?.property,
        country_code: criteria?.countryCode,
      },
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }


  public async getEmojis(domain, countryCode) {
    try {
      const publicHash = getSingleUrlParam('public_hash');
      const url = publicHash ? `?selected_property=${domain}&country_code=${countryCode}&site_share_hash=${publicHash}` : '';
      const response = await this.axios.get(`${ReportsApi.baseUrl}/google-algo-updates/${url}`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }


  // TO DO: Remove after Edit Page Groups is 100% complete (with backend pagination implemented to front)
  // public async getDataTableReports({property, previousPeriodStart, previousPeriodEnd, currentPeriodStart, currentPeriodEnd}) {
  //   const response = await this.axios.get(`${ReportsApi.baseUrl}/data-table-reports/`, {
  //     params: {
  //       selected_property: property,
  //       period1_start: previousPeriodStart,
  //       period1_end: previousPeriodEnd,
  //       period2_start: currentPeriodStart,
  //       period2_end: currentPeriodEnd,
  //     },
  //     headers: {
  //       Accept: 'application/json',
  //       Authorization: getAuthorizationHeader(),
  //     },
  //     cancelToken: this.cancelToken,
  //   });
  //   return response.data;
  // };

  public async getKeywordDataTableReports(params) {
    const response = await this.axios.get(`${ReportsApi.baseUrl}/keywords/`, {
      params: params,
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }

  public async updateBrandedKeywords(payload) {
    const response = await this.axios.put(`${ReportsApi.baseUrl}/branded-keywords/`, payload, {
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }


  public async getMorePagesForKeyword(property: string, countryCode: string, keyword: string, periodStart: string, periodEnd: string) {
    const response = await this.axios.get(`${ReportsApi.baseUrl}/keyword-rankings/`, {
      params: {
        property,
        'country_code': countryCode,
        keyword,
        'period_start': periodStart,
        'period_end': periodEnd,
      },
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });

    return response.data;
  }

  public async searchIntent(
    data?: any,
    publicHash='',
  ): Promise<{success: boolean; response: any}> {
    const response = await this.axios.post(`${ReportsApi.baseUrl}/keyword-search-intent/`,
      {
        keywords: data,
      },
      {
        ...(publicHash && {params: {
          site_share_hash: publicHash,
        }}),
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      },
    );
    return response?.data;
  }

  public async getPagesDataTableReports(params) {
    const response = await this.axios.get(`${ReportsApi.baseUrl}/pages/`, {
      params: params,
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }

  public async getPaKeywordMovementsReports(params) {
    const response = await this.axios.get(`${ReportsApi.baseUrl}/daily-movements/`, {
      params: params,
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }

  public async getKeywordCannibalizationReports(params) {
    const response = await this.axios.get(`${ReportsApi.baseUrl}/keyword-cannibalization/`, {
      params: params,
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }
  public async getKeywordMovementsReportNew(params) {
    const response = await this.axios.get(`${ReportsApi.baseUrl}/daily-movements-chart/`, {
      params: params,
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }
  public async getKeywordCannibalizationChartReport(params) {
    const response = await this.axios.get(`${ReportsApi.baseUrl}/keyword-cannibalization-chart/`, {
      params: params,
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }

  public async getSpPerformanceData(property:string, limit:number, countryCode: string, publicHash?: string) {
    const url = `${ReportsApi.baseUrl}/site-property-performance/`;
    const response = await this.axios.get(url, {
      params: {
        selected_property: property,
        limit: limit,
        country_code: countryCode || '',
        ...(publicHash && {site_share_hash: publicHash}),
      },
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }

  public async getKeywordHistoricalPerformance({property, countryCode, period1Start, period1End, periodStart, periodEnd, keyword}) {
    const url = `${ReportsApi.baseUrl}/keyword-historical-performance/`;
    const response = await this.axios.get(url, {
      params: {
        property: property,
        period_start: periodStart,
        period_end: periodEnd,
        period1_start: period1Start,
        period1_end: period1End,
        period2_start: periodStart,
        period2_end: periodEnd,
        country_code: countryCode,
        keyword: keyword,
      },
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      // cancelToken: this.cancelToken,
    });
    return response.data;
  }

  public async getPageHistoricalPerformance({property, countryCode, period1Start, period1End, periodStart, periodEnd, page}) {
    const url = `${ReportsApi.baseUrl}/page-historical-performance/`;
    const publicHash = getSingleUrlParam('hash');
    const response = await this.axios.get(url, {
      params: {
        property: property,
        period_start: periodStart,
        period_end: periodEnd,
        period1_start: period1Start,
        period1_end: period1End,
        period2_start: periodStart,
        period2_end: periodEnd,
        country_code: countryCode,
        page: page,
        ...(publicHash && {site_share_hash: publicHash}),
      },
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }

  public async getPageGroupCustomerPerformance({property, currentPeriodStart, currentPeriodEnd, countryCode}: CriteriaType, publicHash?: string) {
    const url = `${ReportsApi.baseUrl}/page-group-customer-performance/`;
    const response = await this.axios.get(url, {
      params: {
        property: property,
        period_start: currentPeriodStart,
        period_end: currentPeriodEnd,
        country_code: countryCode,
        ...(publicHash && {site_share_hash: publicHash}),
      },
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }


  public async getKeywordSearchTableCsv(params) {
    const url = `${ReportsApi.baseUrl}/export-to-csv/`;
    const {data} = await this.axios.get(url, {
      params: params,
      headers: {
        Authorization: await getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return data;
  }

  public async exportGscTables(params) {
    const url = `${ReportsApi.baseUrl}/export-table/`;
    const {data} = await this.axios.get(url, {
      params: params,
      headers: {
        Authorization: await getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return data;
  }

  public async getKeywordSearchTableExportXlsLink(params) {
    const url = `${ReportsApi.baseUrl}/export-table/`;
    const output = this.axios.getUri({url, params});
    return output;
  }

  public async reprocessGscData(property) {
    const url = `${ReportsApi.baseUrl}/sa-recentdata-task/trigger/`;
    const response = await this.axios.get(url, {
      params: {
        property: property,
      },
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }
}

export default ReportsApi;
