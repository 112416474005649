import {notification} from '@/utils/notification-v2';
import {flow, types, cast} from 'mobx-state-tree';
import {apiError, customapiError, handleNetworkError} from '@/utils/api';
import {OTTO_V2_API} from '@/api/otto-v2';
import {toJS} from 'mobx';
import moment from 'moment';
import {openUrl} from '@/utils/router';
import {PAGES_API} from '@/api/content-optimizer';
import {getSingleUrlParam} from '@/utils/url';
import {getOttoTablePageSize} from '@/api/common-utils';
import {convertKeysToSnakeCase} from '@/utils/string';
import Router from 'next/router';
import {gbpPostInitialDateFilter} from '@/components/dashboard/pages/otto-page-detail/Constants';

interface deployOttoUrlsDTO {
  toDeploy: boolean;
  issueType?: string;
  issueGroup?: string;
  ottoUrls?: string[];
  uuid: string;
  itemIndex?: number;
  itemIndexes?: number[];
  feelingLuckyBanner?: boolean;
}
interface deployProposedFixDTO {
  uuid: string;
  id: number;
  issueType: string;
  proposedFix: any;
  toDelete?: boolean;
}

interface storeSelectedTitlesDTO {
  id: number | string;
  uuids: string[];
}
interface generateAiProposedFixDTO {
  uuid: string;
  id: number;
  issueType: string;
  itemIndex?: number;
  generateAll?: boolean;
  isNoLoading?: boolean;
}
interface bulkGenerateAiProposedFixDTO {
  uuid: string;
  issueType: string;
  selectedIds: number[];
}
interface createOttoProjectDttO {
  id: number;
  readyForProcessing?: boolean;
}
interface topicalMapUpdateArticleDTO {
  id: number;
  titleUuid: string;
  pageUuid?: string;
  outlineUuid?: string;
  shouldDelete?: boolean;
  publicShareHash?: string;
}
interface deployOttoGBPPostsDTO {
  id: number;
  content: string;
  imageUrl: string;
  schedulePublishingAt: string;
  location?: string;
}
interface autoRespondDTO {
  location?: number | null;
  toneOfVoice: string;
  length: string;
  language: string;
  isV2?: boolean;
  configByStars: {
    star_rating: number;
    mode: string;
    prompt: string;
  }[];
}

const pressReleaseDistributionFilters = () => ([
  {id: 1, name: 'exclude', header: 'Target page or keyword', text: '', type: undefined, active: false, isSearch: true, customFields: [{label: 'Contains', operator: 'contains'}]},
  {id: 2, name: 'radioButtonsFilter', header: 'Status', customCategoryType: 'status', filterTypes: [
    {label: 'Pending', value: 'Pending'},
    {label: 'Generating', value: 'Generating'},
    {label: 'Generated', value: 'Generated'},
    {label: 'Publishing', value: 'Publishing'},
    {label: 'Publish Stuck', value: 'Publish stuck'},
    {label: 'Publish Failed', value: 'Publish failed'},
    {label: 'Published', value: 'Published'},
  ], type: undefined, active: false},
]);

export const technicalFixesModel = types.model({
  metaDescription: types.maybeNull(types.array(types.string)),
  metaKeywords: types.maybeNull(types.array(types.string)),
  pageTitle: types.maybeNull(types.array(types.string)),
  canonicalLink: types.maybeNull(types.array(types.string)),
  ogMeta: types.maybeNull(types.array(types.string)),
  twitterMeta: types.maybeNull(types.array(types.string)),
  headingsLength: types.maybeNull(types.array(types.string)),
  images: types.maybeNull(types.array(types.string)),
  links: types.maybeNull(types.array(types.string)),
  misc: types.maybeNull(types.array(types.string)),
});

export const contentOptimizationsModel = types.model({
  missingKeywords: types.maybeNull(types.array(types.string)),
});

export const issueTypesFrontendMappingModel = types.model({
  technicalFixes: types.maybeNull(technicalFixesModel),
  contentOptimizations: types.maybeNull(contentOptimizationsModel),
});

export const countBreakdownModel = types.model({
  total: types.number,
  approved: types.number,
});

export const groupsModel = types.model({
  misc: types.maybeNull(countBreakdownModel),
  links: types.maybeNull(countBreakdownModel),
  images: types.maybeNull(countBreakdownModel),
  ogMeta: types.maybeNull(countBreakdownModel),
  pageTitle: types.maybeNull(countBreakdownModel),
  twitterMeta: types.maybeNull(countBreakdownModel),
  metaKeywords: types.maybeNull(countBreakdownModel),
  canonicalLink: types.maybeNull(countBreakdownModel),
  headingsLength: types.maybeNull(countBreakdownModel),
  metaDescription: types.maybeNull(countBreakdownModel),
  missingKeywords: types.maybeNull(countBreakdownModel),
});

export const issuesModel = types.model({
  links: types.maybeNull(countBreakdownModel),
  images: types.maybeNull(countBreakdownModel),
  ogUrl: types.maybeNull(countBreakdownModel),
  ogTitle: types.maybeNull(countBreakdownModel),
  pageTitle: types.maybeNull(countBreakdownModel),
  uniqueness: types.maybeNull(countBreakdownModel),
  twitterCard: types.maybeNull(countBreakdownModel),
  metaKeywords: types.maybeNull(countBreakdownModel),
  twitterTitle: types.maybeNull(countBreakdownModel),
  canonicalLink: types.maybeNull(countBreakdownModel),
  ogDescription: types.maybeNull(countBreakdownModel),
  metaDescription: types.maybeNull(countBreakdownModel),
  missingKeywords: types.maybeNull(countBreakdownModel),
  pageHasBgsound: types.maybeNull(countBreakdownModel),
  htmlLangMissing: types.maybeNull(countBreakdownModel),
  h1Under20Over70: types.maybeNull(countBreakdownModel),
  h2Under20Over70: types.maybeNull(countBreakdownModel),
  twitterDescription: types.maybeNull(countBreakdownModel),
  hasMetaRefreshTag: types.maybeNull(countBreakdownModel),
});

export const issuesCountBreakdownModel = types.model({
  groups: types.maybeNull(groupsModel),
  issues: types.maybeNull(issuesModel),
});

export const summaryModel = types.model({
  totalPages: types.maybeNull(types.number),
  foundIssues: types.maybeNull(types.number),
  healthyPages: types.maybeNull(types.number),
  seoOptimizationScore: types.maybeNull(types.number),
  deployedFixes: types.maybeNull(types.number),
});

const subGroupModel = types.model({
  icon: types.maybeNull(types.string),
  group: types.maybeNull(types.string),
  isComingSoon: types.maybeNull(types.boolean),
  label: types.maybeNull(types.string),
  requiredIntegration: types.maybeNull(types.string),
  enforceBranding: types.maybeNull(types.boolean),
  subGroups: types.maybeNull(types.array(types.frozen({}))),
});

const issueTypeModel = types.model({
  group: types.maybeNull(types.string),
  icon: types.maybeNull(types.string),
  isComingSoon: types.maybeNull(types.boolean),
  label: types.maybeNull(types.string),
  requiredIntegration: types.maybeNull(types.string),
  subGroups: types.maybeNull(types.array(subGroupModel)),
});

const connectedData = types.model({
  gbpDetails: types.maybeNull(types.model({
    locationId: types.maybeNull(types.string),
    id: types.maybeNull(types.number),
    businessName: types.maybeNull(types.string),
    businessAddress: types.maybeNull(types.string),
  })),
  gbpDetailsV2: types.maybeNull(types.array(types.model({
    locationId: types.maybeNull(types.string),
    id: types.maybeNull(types.number),
    businessName: types.maybeNull(types.string),
    businessAddress: types.maybeNull(types.string),
  }))),
  gscDetails: types.maybeNull(types.model({
    countryCode: types.maybeNull(types.string),
    gaPropertyId: types.maybeNull(types.string),
    propertyUrl: types.maybeNull(types.string),
  })),
  isGbpConnected: types.maybeNull(types.boolean),
  isGscConnected: types.maybeNull(types.boolean),
});

const dynamicIndexingModel = types.model({
  isAuto: types.maybeNull(types.boolean),
  limit: types.maybeNull(types.number),
});

const experimentalFeatures = types.model({
  wildFire: types.maybeNull(types.boolean),
  domainPower: types.maybeNull(types.boolean),
});

const CrawlRules = types.model({
  text: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  filter: types.maybeNull(types.string),
});

export const OttoV2ProjectDetail = types.model({
  uuid: types.maybeNull(types.string),
  activatedAt: types.maybeNull(types.string),
  afterSummary: types.maybeNull(types.union(summaryModel, types.frozen({}))),
  backlinks: types.maybeNull(types.number),
  beforeSummary: types.maybeNull(types.union(summaryModel, types.frozen({}))),
  connectedData: types.maybeNull(connectedData),
  createdAt: types.maybeNull(types.string),
  dr: types.maybeNull(types.number),
  hostname: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  isActive: types.maybeNull(types.boolean),
  isEngaged: types.maybeNull(types.boolean),
  isGbpConnected: types.maybeNull(types.boolean),
  isGscConnected: types.maybeNull(types.boolean),
  isWidgetEnabled: types.maybeNull(types.boolean),
  siteAuditId: types.maybeNull(types.number),
  issuesCountBreakdown: types.maybeNull(types.frozen({})),
  issueTypesFrontendMapping: types.maybeNull(types.frozen({})),
  enforceBrandingSettings: types.maybeNull(types.frozen({})),
  issueTypesFrontendMappingV2: types.maybeNull(types.array(issueTypeModel)),
  pixelTagState: types.maybeNull(types.string),
  topPages: types.maybeNull(types.array(types.model({
    url: types.maybeNull(types.string),
    isSelected: types.maybeNull(types.boolean),
    keywords: types.maybeNull(types.array(types.string)),
    source: types.maybeNull(types.string),
  }))),
  pixelHtml: types.maybeNull(types.string),
  pixelHtmlGtm: types.maybeNull(types.string),
  llmProvider: types.maybeNull(types.string),
  autopilotFrequencyDays: types.maybeNull(types.number),
  autopilotIsActive: types.maybeNull(types.boolean),
  autopilotOtherSettings: types.maybeNull(types.model({
    dynamicIndexing: types.maybeNull(dynamicIndexingModel),
  })),
  autopilotAiSettings: types.maybeNull(types.frozen({})),
  siteAuditCrawlSettings: types.maybeNull(types.model({
    crawlBudget: types.maybeNull(types.number),
    crawlConcurrency: types.maybeNull(types.number),
    nextProcessingDeltaDays: types.maybeNull(types.union(types.number, types.string)),
    selectedUserAgent: types.maybeNull(types.string),
    shouldRespectRobotsTxt: types.maybeNull(types.boolean),
    crawlRules: types.maybeNull(types.string),
    crawlRulesJson: types.maybeNull(types.array(CrawlRules)),
    javascriptRenderingEnabled: types.maybeNull(types.boolean),
  })),
  backgroundInfo: types.maybeNull(types.string),
  knowledgeGraphId: types.maybeNull(types.number),
  timeSavedTotal: types.maybeNull(types.string),
  siteAudit: types.maybeNull(types.number),
  timeSavedBreakdownStrings: types.maybeNull(types.frozen({})),
  languageCode: types.maybeNull(types.string),
  aggregatorNetworkAutofillData: types.maybeNull(types.frozen({})),
  customReportId: types.maybeNull(types.union(types.string, types.number)),
  isFrozen: types.maybeNull(types.boolean),
  lastCrawl: types.maybeNull(types.string),
  taskStatusUpdatedAt: types.maybeNull(types.string),
  frozenAt: types.maybeNull(types.string),
  publicShareHash: types.maybeNull(types.string),
  nextAnalysisAt: types.maybeNull(types.string),
  deepFreezeTask: types.maybeNull(types.model({
    displayableStatus: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    taskId: types.maybeNull(types.string),
  })),
  myTasksOrdering: types.maybeNull(types.array(types.string)),
  experimentalFeaturesToggles: types.maybeNull(experimentalFeatures),
  organizationSchemaType: types.maybeNull(types.string),
  organizationSchema: types.maybeNull(types.frozen()),
  organizationSchemaIsApproved: types.maybeNull(types.boolean),
});

export const connectedDataModel = types.model({
  isGscConnected: types.boolean,
  isGbpConnected: types.boolean,
  gbpDetailsV2: types.maybeNull(types.array(types.frozen({}))),
  gscDetails: types.maybeNull(types.frozen({})),
});

export const afterSummaryModel = types.model({
  deployedFixes: types.maybeNull(types.number),
  foundIssues: types.maybeNull(types.number),
  healthyPages: types.maybeNull(types.number),
  seoOptimizationScore: types.maybeNull(types.number),
  totalPages: types.maybeNull(types.number),
});


const OttoV2Project = types.model({
  uuid: types.maybeNull(types.string),
  hostname: types.maybeNull(types.string),
  isActive: types.maybeNull(types.boolean),
  isEngaged: types.maybeNull(types.boolean),
  isFrozen: types.maybeNull(types.boolean),
  isWidgetEnabled: types.maybeNull(types.boolean),
  pixelTagState: types.maybeNull(types.string),
  dr: types.maybeNull(types.number),
  ot: types.maybeNull(types.number),
  trafficValue: types.maybeNull(types.number),
  refDomains: types.maybeNull(types.number),
  backlinks: types.maybeNull(types.number),
  siteAudit: types.maybeNull(types.number),
  siteAuditId: types.maybeNull(types.number),
  createdAt: types.maybeNull(types.string),
  lastCrawl: types.maybeNull(types.string),
  pagesWithIssues: types.maybeNull(types.number),
  pixelHtml: types.maybeNull(types.string),
  connectedData: types.maybeNull(connectedDataModel),
  afterSummary: types.maybeNull(afterSummaryModel),
  timeSavedTotal: types.maybeNull(types.union(types.string, types.number)),
  taskStatus: types.maybeNull(types.string),
  readyForProcessing: types.maybeNull(types.boolean),
  processingStatus: types.maybeNull(types.string),
});

const OttoV2ProjectsList = types.model({
  count: types.maybeNull(types.number),
  next: types.maybeNull(types.string),
  pageSize: types.maybeNull(types.number),
  page: types.maybeNull(types.number),
  previous: types.maybeNull(types.string),
  results: types.maybeNull(types.array(OttoV2Project)),
  totalPages: types.maybeNull(types.number),
});

const DistributionChannelsModel = types.model({
  count: types.maybeNull(types.number),
  next: types.maybeNull(types.string),
  previous: types.maybeNull(types.string),
  results: types.maybeNull(types.array(types.model({
    id: types.maybeNull(types.number),
    displayName: types.maybeNull(types.string),
    provider: types.maybeNull(types.string),
    icon: types.maybeNull(types.string),
    isComingSoon: types.maybeNull(types.boolean),
    creditsCost: types.maybeNull(types.number),
    createdAt: types.maybeNull(types.string),
    linksApprox: types.maybeNull(types.string),
  }))),
});

const topicalIssueModel = types.model({
  id: types.maybeNull(types.union(types.string, types.number)),
  keywords: types.maybeNull(types.array(types.string)),
  path: types.maybeNull(types.string),
  isActive: types.maybeNull(types.boolean),
  isGenerating: types.maybeNull(types.boolean),
  values: types.maybeNull(types.array(types.frozen({}))),
  currentValue: types.maybeNull(types.string),
  recommendedValue: types.maybeNull(types.optional(types.union(types.string, types.array(types.string), types.frozen({})), '')),
  existingSchema: types.maybeNull(types.optional(types.union(types.string, types.array(types.string), types.frozen({})), '')),
  recommendedSchema: types.maybeNull(types.optional(types.union(types.string, types.array(types.string), types.frozen({})), '')),
  imageUrl: types.maybeNull(types.string),
  absoluteUrl: types.maybeNull(types.string),
  foundIn: types.maybeNull(types.array(types.maybeNull(types.string))),
  isApproved: types.maybeNull(types.boolean),
  contentTopic: types.maybeNull(types.string),
  formattedData: types.maybeNull(types.union(types.array(types.frozen({})), types.frozen({}))),
  googleSpreadsheetUrl: types.maybeNull(types.string),
  taskStatus: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  createdBy: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  publishedAt: types.maybeNull(types.string),
  searchUrl: types.maybeNull(types.string),
  unpublishedAt: types.maybeNull(types.string),
  topicType: types.maybeNull(types.string),
  location: types.maybeNull(types.number),
  content: types.maybeNull(types.string),
  nlpStatus: types.maybeNull(types.string),
  reply: types.maybeNull(types.model({
    content: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    createdBy: types.maybeNull(types.string),
    publishedAt: types.maybeNull(types.string),
    review: types.maybeNull(types.number),
    status: types.maybeNull(types.string),
    unpublishedAt: types.maybeNull(types.string),
  })),
  starRating: types.maybeNull(types.number),
  schedulePublishingAt: types.maybeNull(types.string),
  ownersAnswer: types.maybeNull(types.model({
    status: types.maybeNull(types.string),
    createdBy: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    publishedAt: types.maybeNull(types.string),
    unpublishedAt: types.maybeNull(types.string),
    location: types.maybeNull(types.number),
    content: types.maybeNull(types.string),
    questionId: types.maybeNull(types.number),
  })),
  cloudStackProviders: types.maybeNull(types.array(types.model({
    status: types.maybeNull(types.string),
    cloudStackContentProvider: types.maybeNull(types.number),
    indexStatusCheckedAt: types.maybeNull(types.string),
    indexStatus: types.maybeNull(types.string),
    publishedUrl: types.maybeNull(types.string),
    publishedAt: types.maybeNull(types.string),
  }))),
  name: types.maybeNull(types.string),
  ottoProject: types.maybeNull(types.union(types.number, types.string)),
  targetUrl: types.maybeNull(types.string),
  targetKeyword: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
  viewableUrl: types.maybeNull(types.string),
  editableUrl: types.maybeNull(types.string),
  contentPage: types.maybeNull(types.string),
  itemIndex: types.maybeNull(types.number),
  project: types.maybeNull(types.number),
  customHtmlHead: types.maybeNull(types.string),
  anchorText: types.maybeNull(types.string),
  customHtmlBody: types.maybeNull(types.string),
  wpPublishedAtUrl: types.maybeNull(types.string),
  reviewerName: types.maybeNull(types.string),
  reviewerPhotoUrl: types.maybeNull(types.string),
  callToActionType: types.maybeNull(types.string),
  callToActionUrl: types.maybeNull(types.string),
  inputPrompt: types.maybeNull(types.string),
  signalGenesys: types.maybeNull(types.model({
    distributionChannels: types.maybeNull(types.array(types.union(types.string, types.number))),
    distributions: types.maybeNull(types.array(types.frozen({}))),
    genesysCategoryId: types.maybeNull(types.string),
    genesysClientId: types.maybeNull(types.string),
    genesysId: types.maybeNull(types.string),
    pressRelease: types.maybeNull(types.string),
    genesysStatusMessage: types.maybeNull(types.string),
    genesysStatusDescription: types.maybeNull(types.string),
  })),
  targetKeywords: types.maybeNull(types.array(types.string)),
  title: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  redeemUrl: types.maybeNull(types.string),
  couponCode: types.maybeNull(types.string),
  endDateAt: types.maybeNull(types.string),
  endTimeAt: types.maybeNull(types.string),
  startDateAt: types.maybeNull(types.string),
  startTimeAt: types.maybeNull(types.string),
  termsAndConditions: types.maybeNull(types.string),
  channelsCount: types.maybeNull(types.number),
  address1: types.maybeNull(types.string),
  address2: types.maybeNull(types.string),
  aggregators: types.maybeNull(types.array(types.string)),
  amountPaid: types.maybeNull(types.number),
  briefDescription: types.maybeNull(types.string),
  businessCategoryId: types.maybeNull(types.number),
  businessName: types.maybeNull(types.string),
  campaignCity: types.maybeNull(types.string),
  campaignCountry: types.maybeNull(types.string),
  campaignName: types.maybeNull(types.string),
  campaignState: types.maybeNull(types.string),
  contactEmail: types.maybeNull(types.string),
  contactFirstname: types.maybeNull(types.string),
  contactName: types.maybeNull(types.string),
  contactTelephone: types.maybeNull(types.string),
  formationDate: types.maybeNull(types.string),
  gbLocationId: types.maybeNull(types.string),
  isSuccessfullySubmitted: types.maybeNull(types.boolean),
  postcode: types.maybeNull(types.string),
  websiteAddress: types.maybeNull(types.string),
  isSelected: types.maybeNull(types.boolean),
  lastSentToIndexerAt: types.maybeNull(types.string),
  urlCount: types.maybeNull(types.number),
  urlUnindexedCount: types.maybeNull(types.number),
  aiGenerationStatus: types.maybeNull(types.boolean),
  channelsCountByStatus: types.maybeNull(types.model({
    generated: types.maybeNull(types.number),
    generating: types.maybeNull(types.number),
    pending: types.maybeNull(types.number),
    publishing: types.maybeNull(types.number),
    published: types.maybeNull(types.number),
  })),
  uuid: types.maybeNull(types.string),
  city: types.maybeNull(types.string),
  wordpressUrl: types.maybeNull(types.string),
  cleanedHtml: types.maybeNull(types.string),
  pageName: types.maybeNull(types.string),
  additionalInfo: types.maybeNull(types.string),
  isAiGeneratedImages: types.maybeNull(types.boolean),
  color: types.maybeNull(types.string),
  exampleSite: types.maybeNull(types.string),
  services: types.maybeNull(types.union(types.array(types.string), types.string)),
  wpPostId: types.maybeNull(types.number),
  wpPublishedAtWebsite: types.maybeNull(types.number),
  wpUrlSlug: types.maybeNull(types.string),
  wpPostStatus: types.maybeNull(types.string),
  wpPostCategories: types.maybeNull(types.array(types.string)),
  excludeHeader: types.maybeNull(types.boolean),
  excludeFooter: types.maybeNull(types.boolean),
  campaignStatus: types.maybeNull(types.string),
  isImagesGenerated: types.maybeNull(types.boolean),
  isRebuild: types.maybeNull(types.boolean),
  extras: types.maybeNull(types.frozen()),
  indexedPages: types.maybeNull(types.number),
});

export const issueTableModel = types.model({
  count: types.maybeNull(types.number),
  next: types.maybeNull(types.string),
  previous: types.maybeNull(types.string),
  results: types.maybeNull(types.array(topicalIssueModel)),
});

export const ottoUrlModal = types.model({
  issueType: types.maybeNull(types.string),
  issueTable: types.maybeNull(issueTableModel),
  page: types.optional(types.number, 1),
  pageSize: types.optional(types.number, 10),
});

const TopicalMapsListModel = types.model({
  sv: types.maybeNull(types.number),
  cpc: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
  clicks: types.maybeNull(types.number),
  keyword: types.maybeNull(types.string),
  position: types.maybeNull(types.number),
  impressions: types.maybeNull(types.number),
  searchIntent: types.maybeNull(types.string),
  trafficValue: types.maybeNull(types.number),
  opportunityScore: types.maybeNull(types.number),
  topicalMapId: types.maybeNull(types.union(types.string, types.number)),
});

export const StackProviderModel = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
});

export const GSCSiteVerificationScript = types.model({
  isVerified: types.maybeNull(types.boolean),
  metaTag: types.maybeNull(types.string),
  verifiedAt: types.maybeNull(types.string),
});

export const LocationDetailModal = types.model({
  id: types.maybeNull(types.number),
  customerId: types.maybeNull(types.number),
  businessName: types.maybeNull(types.string),
  businessDescription: types.maybeNull(types.string),
  mapsUri: types.maybeNull(types.string),
  statusProcessingQnas: types.maybeNull(types.string),
  statusProcessingReviews: types.maybeNull(types.string),
  lastQuestionFetchAt: types.maybeNull(types.string),
  lastReviewFetchAt: types.maybeNull(types.string),
  lastPostFetchAt: types.maybeNull(types.string),
  locationId: types.maybeNull(types.string),
});

export const SuggestedKeywordsModel = types.model({
  id: types.maybeNull(types.number),
  keyword: types.maybeNull(types.string),
  rankingPotential: types.maybeNull(types.string),
});

const FieldModel = types.model({
  name: types.maybeNull(types.string),
  label: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  options: types.maybeNull(types.array(types.model({
    label: types.maybeNull(types.string),
    value: types.maybeNull(types.string),
  }))),
  nameMap: types.maybeNull(types.model({
    serpLocationId: types.maybeNull(types.string),
    serpLocationText: types.maybeNull(types.string),
  })),
  value: types.maybeNull(types.array(types.model({
    name: types.maybeNull(types.string),
    label: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
  }))),
});

const SectionFieldModel = types.compose(
  FieldModel,
  types.model({
    value: types.array(types.late(() => FieldModel)),
  }),
);

const OttoKnowledgeGraphFieldsMetaModel = types.compose(
  FieldModel,
  types.model({
    value: types.maybeNull(types.array(SectionFieldModel)),
  }),
);
const RatingModel = types.model({
  mode: types.maybeNull(types.string),
  prompt: types.maybeNull(types.string),
  starRating: types.maybeNull(types.number),
});
const ReviewSettingsModel = types.model({
  configByStars: types.maybeNull(types.array(RatingModel)),
  language: types.maybeNull(types.string),
  length: types.maybeNull(types.string),
  location: types.maybeNull(types.number),
  toneOfVoice: types.maybeNull(types.string),
});

const quotaAllocationsModel = types.model({
  quotaKey: types.maybeNull(types.string),
  pricePerUnit: types.maybeNull(types.number),
  quotaPoints: types.maybeNull(types.number),
  topOptions: types.maybeNull(types.array(types.string)),
  extra: types.maybeNull(types.frozen({})),
  range: types.maybeNull(types.string),
});

const ReviewRatingsModel = types.model({
  count: types.maybeNull(types.number),
  starRating: types.maybeNull(types.number),
});
const GeneratedBulkQuestionsModel = types.model({
  question: types.maybeNull(types.string),
  answer: types.maybeNull(types.string),
  date: types.maybeNull(types.string),
});
const GeneratedBulkPostModel = types.model({
  id: types.maybeNull(types.number),
  createdBy: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  location: types.maybeNull(types.number),
  content: types.maybeNull(types.string),
  imageUrl: types.maybeNull(types.string),
  schedulePublishingAt: types.maybeNull(types.string),
  topicType: types.maybeNull(types.string),
  callToActionType: types.maybeNull(types.string),
  callToActionUrl: types.maybeNull(types.string),
  couponCode: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  endDateAt: types.maybeNull(types.string),
  endTimeAt: types.maybeNull(types.string),
  publishedAt: types.maybeNull(types.string),
  redeemUrl: types.maybeNull(types.string),
  searchUrl: types.maybeNull(types.string),
  startDateAt: types.maybeNull(types.string),
  startTimeAt: types.maybeNull(types.string),
  termsAndConditions: types.maybeNull(types.string),
  unpublishedAt: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
});
const bulkPostSettingsModel = types.model({
  image: types.maybeNull(types.model({
    prompt: types.maybeNull(types.string),
  })),
  content: types.maybeNull(types.model({
    override: types.maybeNull(types.boolean),
    prompt: types.maybeNull(types.string),
  })),
});
const ReviewExampleModel = types.model({
  starRating: types.maybeNull(types.number),
  review: types.maybeNull(types.string),
  reply: types.maybeNull(types.string),
});

const categoriessModel = types.model({
  value: types.maybeNull(types.number),
  text: types.maybeNull(types.string),
});

const DistributionAgainstUrlModel = types.model({
  count: types.maybeNull(types.number),
  next: types.maybeNull(types.string),
  previous: types.maybeNull(types.string),
  results: types.maybeNull(types.array(types.model({
    distribution: types.maybeNull(types.number),
    mediaUrls: types.maybeNull(types.array(types.model({
      url: types.maybeNull(types.string),
      hits: types.maybeNull(types.number),
    }))),
    variation: types.maybeNull(types.model({
      createdAt: types.maybeNull(types.string),
      editableUrl: types.maybeNull(types.string),
      id: types.maybeNull(types.string),
      publishedAt: types.maybeNull(types.string),
      status: types.maybeNull(types.string),
      title: types.maybeNull(types.string),
      updatedAt: types.maybeNull(types.string),
      viewableUrl: types.maybeNull(types.string),
      signalGenesys: types.maybeNull(types.model({
        genesysCategoryId: types.maybeNull(types.string),
        genesysId: types.maybeNull(types.string),
      })),
    })),
  }))),
});
const BulkPagesModel = types.model({
  url: types.maybeNull(types.string),
  keywords: types.maybeNull(types.array(types.string)),
  isSelected: types.maybeNull(types.boolean),
  addedToTable: types.maybeNull(types.boolean),
});

const GBPLocationsModel = types.model({
  id: types.maybeNull(types.number),
  locationId: types.maybeNull(types.string),
  businessName: types.maybeNull(types.string),
  businessAddress: types.maybeNull(types.string),
  businessDescription: types.maybeNull(types.string),
  customerId: types.maybeNull(types.number),
  knowledgeGraph: types.maybeNull(types.number),
  mapsUri: types.maybeNull(types.string),
  usedInStandalone: types.maybeNull(types.boolean),
  phoneNumbers: types.maybeNull(types.array(types.string)),
  addressRegionCode: types.maybeNull(types.string),
  timezone: types.maybeNull(types.string),
  storeCode: types.maybeNull(types.string),
  knowledgeGraphObj: types.frozen(),
});

const AllGBPLocationsModel = types.model({
  locationId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  businessAddress: types.maybeNull(types.string),
  knowledgeGraphId: types.maybeNull(types.number),
  usedInStandalone: types.maybeNull(types.boolean),
  websiteUrl: types.maybeNull(types.string),
  isVerified: types.maybeNull(types.boolean),
  placeId: types.maybeNull(types.string),
  mapsUri: types.maybeNull(types.string),
  storeCode: types.maybeNull(types.string),
});

const QAndASettingsModel = types.model({
  location: types.maybeNull(types.number),
  lastRunAt: types.maybeNull(types.string),
  nextRunAt: types.maybeNull(types.string),
  isActive: types.maybeNull(types.boolean),
  frequencyType: types.maybeNull(types.string),
  frequency: types.maybeNull(types.number),
  count: types.maybeNull(types.number),
  helpPrompt: types.maybeNull(types.string),
  approvalType: types.maybeNull(types.string),
  startAt: types.maybeNull(types.string),
});

const ReviewFilterModel = types.model({
  isEnabled: types.maybeNull(types.boolean),
  contentIcontains: types.maybeNull(types.array((types.string))),
});

const GbpAutomatedPostSettingsModel = types.model({
  location: types.maybeNull(types.number),
  lastRunAt: types.maybeNull(types.string),
  nextRunAt: types.maybeNull(types.string),
  isActive: types.maybeNull(types.boolean),
  frequencyType: types.maybeNull(types.string),
  frequency: types.maybeNull(types.number),
  count: types.maybeNull(types.number),
  helpPrompt: types.maybeNull(types.string),
  approvalType: types.maybeNull(types.string),
  imageSourceType: types.maybeNull(types.string),
  startAt: types.maybeNull(types.string),
  contentType: types.maybeNull(types.string),
  reviewFilter: types.maybeNull(ReviewFilterModel),
  imagePrompt: types.maybeNull(types.string),
});

const GBPMediaLibraryModel = types.model({
  count: types.maybeNull(types.number),
  next: types.maybeNull(types.string),
  pageSize: types.maybeNull(types.number),
  previous: types.maybeNull(types.string),
  results: types.maybeNull(types.array(types.model({
    aiPrompt: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    formatType: types.maybeNull(types.string),
    gbpName: types.maybeNull(types.string),
    gbpUrl: types.maybeNull(types.string),
    id: types.maybeNull(types.number),
    location: types.maybeNull(types.number),
    sourceType: types.maybeNull(types.string),
    url: types.maybeNull(types.string),
  }))),
  totalPages: types.maybeNull(types.number),
});

const PRDistributionFilters = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  header: types.maybeNull(types.string),
  customFilterValue: types.optional(types.string, ''),
  type: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  isDecimals: types.maybeNull(types.boolean),
  maxLimit: types.maybeNull(types.number),
  filterField: types.maybeNull(types.string),
  query: types.maybeNull(types.string),
  from: types.maybeNull(types.string),
  to: types.maybeNull(types.string),
  equal: types.maybeNull(types.union(types.string, types.number)),
  customCategoryType: types.maybeNull(types.string),
  isSearch: types.maybeNull(types.boolean),
  active: types.boolean,
  category: types.maybeNull(types.array(types.string)),
  filterTypes: types.maybeNull(types.array(types.model({
    label: types.maybeNull(types.string),
    value: types.maybeNull(types.string),
  }))),
  customOptions: types.maybeNull(types.array(types.model({
    name: types.maybeNull(types.string),
    info: types.optional(types.string, ''),
    showPercent: types.optional(types.boolean, false),
    min: types.maybeNull(types.union(types.string, types.number)),
    max: types.maybeNull(types.union(types.string, types.number)),
    equal: types.maybeNull(types.union(types.string, types.number)),
  }))),
  customOptionsTop: types.maybeNull(types.array(types.model({
    name: types.maybeNull(types.string),
    info: types.optional(types.string, ''),
    showPercent: types.optional(types.boolean, false),
    min: types.maybeNull(types.union(types.string, types.number)),
    max: types.maybeNull(types.union(types.string, types.number)),
  }))),
  customFields: types.maybeNull(types.array(types.model({
    label: types.maybeNull(types.string),
    operator: types.maybeNull(types.string),
  }))),
});

const LocationModel = types.model({
  locationId: types.maybeNull(types.string),
  id: types.maybeNull(types.union(types.number, types.string)),
  businessName: types.maybeNull(types.string),
  businessAddress: types.maybeNull(types.string),
  businessDescription: types.maybeNull(types.string),
  timezone: types.maybeNull(types.string),
  storeCode: types.maybeNull(types.string),
  customerId: types.maybeNull(types.number),
  knowledgeGraph: types.maybeNull(types.number),
  mapsUri: types.maybeNull(types.string),
  usedInStandalone: types.maybeNull(types.boolean),
  phoneNumbers: types.maybeNull(types.array(types.string)),
  addressRegionCode: types.maybeNull(types.string),
});

const BlockedQuotasModel = types.model({
  quotaReleaseDate: types.maybeNull(types.string),
  projectsCount: types.maybeNull(types.number),
});

const PaymentTopupsModel = types.model({
  quotaKey: types.maybeNull(types.string),
  totalTopUpQuotaPoints: types.maybeNull(types.number),
  quotaToBeRemovedOnNextBilling: types.maybeNull(types.number),
  quotaToBeAddedOnNextBilling: types.maybeNull(types.number),
});

export const OttoV2Store = types.model({
  ottoV2Projects: types.maybeNull(OttoV2ProjectsList),
  ottoV2Project: types.maybeNull(OttoV2ProjectDetail),
  loadingProject: types.boolean,
  loadingProjects: types.boolean,
  ottoUrls: types.maybeNull(types.array(ottoUrlModal)),
  ottoUrlLoader: types.maybeNull(types.boolean),
  ottoIssueType: types.maybeNull(types.string),
  cloudStackProviders: types.maybeNull(types.array(StackProviderModel)),
  selectedCategory: types.string,
  loadingDetail: types.boolean,
  deployingOttoUrls: types.boolean,
  aIGenerateState: types.boolean,
  isDeployStatus: types.union(types.boolean, types.string),
  deployStatusKey: types.maybeNull(types.string),
  deployReplyKey: types.maybeNull(types.string),
  deployIssueType: types.string,
  searchTextValue: types.string,
  isDeploying: types.boolean,
  deployRollBackAll: types.boolean,
  gbpTableParams: types.frozen(),
  issueTypeArray: types.array(types.string),
  selectedIssue: types.string,
  selectedReviewFilters: types.frozen(),
  deployingProposedFix: types.boolean,
  loadGBPPostsLoading: types.boolean,
  generatingAiProposedFix: types.boolean,
  creatingOttoProject: types.boolean,
  creatingCloudStackProject: types.boolean,
  gscSiteVerificationScript: types.maybeNull(GSCSiteVerificationScript),
  scriptStatusLoading: types.boolean,
  gscScriptStatusLoading: types.boolean,
  projectListShouldRepoll: types.boolean,
  storingSelectedTitles: types.boolean,
  gbpLocationDetail: types.maybeNull(LocationDetailModal),
  topicalMapsList: types.maybeNull(types.array(TopicalMapsListModel)),
  creatingTopicalMap: types.boolean,
  deletingTopicalMap: types.boolean,
  loadingTopicalMapsList: types.boolean,
  suggestedTargetKeywords: types.maybeNull(types.array(SuggestedKeywordsModel)),
  connectingGbp: types.boolean,
  cloudStackProjectShouldRepoll: types.boolean,
  landingPageDetailShouldRepoll: types.boolean,
  rebuildingLandingPage: types.boolean,
  topicalMapsShouldRepoll: types.boolean,
  gbpShouldRepoll: types.boolean,
  aiShouldRepoll: types.boolean,
  nlpFaqShouldRepoll: types.boolean,
  nlpTermsShouldRepoll: types.boolean,
  issueTypeSelected: types.string,
  cloudStackCurrentPage: types.number,
  ottoSearchTerm: types.string,
  ottoKnowledgeGraphFieldsMeta: types.maybeNull(OttoKnowledgeGraphFieldsMetaModel),
  refreshingBulkGBPData: types.boolean,
  refreshingBulkGBPOptimization: types.boolean,
  gbpLocationRepoll: types.boolean,
  knowledgeGraph: types.maybeNull(types.frozen({})),
  loadingAutoPilot: types.maybeNull(types.boolean),
  editingCustomHtml: types.boolean,
  loadingKnowledgeGraph: types.boolean,
  defaultParams: types.maybeNull(types.model({
    pageSize: types.number,
    page: types.number,
    search: types.string,
  })),
  reviewSettings: types.maybeNull(ReviewSettingsModel),
  loadingOttoQuotaTopup: types.boolean,
  ottoQuotaAllocationsLoading: types.boolean,
  quotaAllocations: types.maybeNull(types.array(quotaAllocationsModel)),
  ottoQuotaAllocations: types.maybeNull(types.array(quotaAllocationsModel)),
  deploying: types.boolean,
  allDeployed: types.boolean,
  reviewRatings: types.maybeNull(types.array(ReviewRatingsModel)),
  loadingEditTargetKeyword: types.boolean,
  generatedBulkQA: types.maybeNull(types.array(GeneratedBulkQuestionsModel)),
  generatedBulkPost: types.maybeNull(types.array(GeneratedBulkPostModel)),
  bulkPostSettings: types.maybeNull(types.array(bulkPostSettingsModel)),
  loadingGenerateBulkQA: types.boolean,
  addingQA: types.boolean,
  editingQA: types.boolean,
  isKnowledgeModalVisible: types.boolean,
  reviewExample: types.maybeNull(ReviewExampleModel),
  loadingReviewExample: types.boolean,
  selectedIssueTypeObj: types.maybeNull(types.frozen({})),
  selectedLocation: types.maybeNull(LocationModel),
  isActiveKeys: types.maybeNull(types.array(types.model({
    key: types.string,
    issue: types.string,
  }))),
  deletingPost: types.maybeNull(types.number),
  deletingSiteMap: types.maybeNull(types.boolean),
  searchText: types.maybeNull(types.string),
  quotaAllocationsLoading: types.boolean,
  loadingPublishOrDeplayPress: types.maybeNull(types.string),
  pressReleaseCurrentPage: types.number,
  creatingPressReleaseProject: types.boolean,
  prevInProgressLength: types.maybeNull(types.number),
  loadmoreLoading: types.boolean,
  distributionChannels: types.maybeNull(DistributionChannelsModel),
  categories: types.maybeNull(types.array(categoriessModel)),
  loadingWorkSummary: types.boolean,
  loadingDistribution: types.boolean,
  distributionAgainstUrl: types.maybeNull(DistributionAgainstUrlModel),
  deployableStatus: types.maybeNull(types.string),
  bulkPages: types.maybeNull(types.array(BulkPagesModel)),
  creatingBulkPages: types.boolean,
  creatingPRContent: types.boolean,
  hasAccess: types.maybeNull(types.boolean),
  openGenerateQAModal: types.boolean,
  updatingOTTOSettings: types.boolean,
  activeReviewsTab: types.string,
  activeReviewsKey: types.maybeNull(types.number),
  gbpLocations: types.maybeNull(types.array(GBPLocationsModel)),
  allGBPLocations: types.maybeNull(types.array(AllGBPLocationsModel)),
  loadingGBPLocations: types.boolean,
  managingGBPLocations: types.boolean,
  unfreezeLoading: types.boolean,
  nlpPage: types.maybeNull(types.number),
  nlpPageSize: types.maybeNull(types.number),
  isTopUpHyperDrive: types.boolean,
  landingPageDetail: types.maybeNull(types.union(topicalIssueModel, types.frozen())),
  pollingLandingPageId: types.maybeNull(types.string),
  navigatedFromGbpOptimizations: types.boolean,
  ottoIdForGscSettings: types.maybeNull(types.string),
  activeQuestionsTab: types.string,
  activeQuestionsKey: types.maybeNull(types.number),
  syncLoad: types.maybeNull(types.boolean),
  ottoProjectNotFoundInRb: types.maybeNull(types.boolean),
  syncLoader: types.maybeNull(types.boolean),
  qAndASettings: types.maybeNull(QAndASettingsModel),
  prDistributionFilters: types.array(PRDistributionFilters),
  isAddPrModalVisible: types.boolean,
  gbpAutomatedPostSettings: types.maybeNull(GbpAutomatedPostSettingsModel),
  qAndASettingsLoading: types.boolean,
  gbpMediaLibrary: types.maybeNull(GBPMediaLibraryModel),
  loadingGBPMedia: types.boolean,
  myTasksLocations: types.maybeNull(types.array(LocationModel)),
  loadingAllGBPLocations: types.boolean,
  gettingPressRelease: types.boolean,
  editableUrlRepolling: types.boolean,
  editableUrl: types.maybeNull(types.string),
  pressReleaseCurrentPageSize: types.number,
  genesysApiKey: types.maybeNull(types.string),
  categoriesLength: types.maybeNull(types.number),
  blockedQuotas: types.maybeNull(types.array(BlockedQuotasModel)),
  paymentTopups: types.maybeNull(types.array(PaymentTopupsModel)),
  loadingTasks: types.boolean,
  selectedGbpProject: types.maybeNull(types.frozen()),
  selectedBusiness: types.maybeNull(types.frozen()),
  businessesList: types.maybeNull(types.frozen()),
  deletingCustomUrl: types.boolean,
  aggregatedStats: types.maybeNull(types.frozen()),
  isCompliantChecked: types.maybeNull(types.boolean),
  activeTab: types.maybeNull(types.string),
  postsCount: types.maybeNull(types.union(types.string, types.number)),
  newChanges: types.boolean,
  optimizationData: types.maybeNull(types.frozen()),
  selectedStatusList: types.maybeNull(types.frozen()),
  calendarPostDateFilter: types.maybeNull(types.frozen()),
  optimizationDataLoader: types.maybeNull(types.boolean),
})
  .views(self => ({
    get getOttoV2Project() {
      return toJS(self.ottoV2Project);
    },
    get getOttoV2ProjectsList() {
      return toJS(self.ottoV2Projects);
    },
    get getOttoUrls() {
      return toJS(self.ottoUrls);
    },
    get getReviewSettings() {
      return toJS(self.reviewSettings);
    },
    get getGeneratedBulkPost() {
      return toJS(self.generatedBulkPost);
    },
    get getBulkPostSettings() {
      return toJS(self.bulkPostSettings);
    },
    get getOttoKnowledgeGraphFieldsMeta() {
      return toJS(self.ottoKnowledgeGraphFieldsMeta);
    },
    get getBulkPages() {
      return toJS(self.bulkPages);
    },
    get getGBPStandAlone() {
      return toJS(self.gbpLocations);
    },
    get getPressReleaseDistributionFilters() {
      return toJS(self.prDistributionFilters);
    },
    get getIsAddPrModalVisible() {
      return toJS(self.isAddPrModalVisible);
    },
    get getQAndASettings() {
      return toJS(self.qAndASettings);
    },
    get getGbpMediaLibrary() {
      return toJS(self.gbpMediaLibrary);
    },
  }))
  .actions(self => {
    const setIsAddPrModalVisible = (value: boolean) => self.isAddPrModalVisible = value;
    const setProjectListShouldRepoll = value => self.projectListShouldRepoll = value;
    const setGbpLocationRepoll = value => self.gbpLocationRepoll = value;
    const setLandingPageDetail = value => self.landingPageDetail = value;
    const setOttoIdForGscSettings = value => self.ottoIdForGscSettings = value;
    const resetGBPStandAlone = value => self.ottoUrls = value;
    const resetKnowledgeGraph = () => self.knowledgeGraph = {};
    const resetAggregatedStats = () => self.aggregatedStats = null;

    const time = new Date().getTime();
    const setTopupHyperDrive = value => self.isTopUpHyperDrive = value;
    const setCloudStackProjectShouldRepoll = value => self.cloudStackProjectShouldRepoll = value;
    const setLandingPageDetailShouldRepoll = value => self.landingPageDetailShouldRepoll = value;
    const setTopicalMapsShouldRepoll = value => self.topicalMapsShouldRepoll = value;
    const setCloudStackCurrentPage = value => self.cloudStackCurrentPage = value;
    const setOttoSearchTerm = value => self.ottoSearchTerm = value;
    const setGbpShouldRepoll = value => self.gbpShouldRepoll = value;
    const setAiShouldRepoll = (value: boolean) => self.aiShouldRepoll = value;
    const setNlpFaqShouldRepoll = value => self.nlpFaqShouldRepoll = value;
    const setNlpTermsShouldRepoll = value => self.nlpTermsShouldRepoll = value;
    const setPressReleaseCurrentPage = value => self.pressReleaseCurrentPage = value;
    const setPressReleaseCurrentPageSize = value => self.pressReleaseCurrentPageSize = value;
    const setCreatingPRContent = value => self.creatingPRContent = value;
    const resetOptimizationData = () => self.optimizationData = null;
    const setIsCompliantChecked = value => self.isCompliantChecked = value;

    const setIsKnowledgeModalVisible = value => self.isKnowledgeModalVisible = value;
    const setSearchText = value => self.searchText = value;
    const setGeneratedBulkPost = value => self.generatedBulkPost = value;
    const setBulkPostSettings = value => self.bulkPostSettings = value;
    const resetDistributionAgainstUrl = () => self.distributionAgainstUrl = null;
    const setOttoProjectNotFoundInRb = value => self.ottoProjectNotFoundInRb = value;
    const setGenesysApiKey = value => self.genesysApiKey = value;
    const setActiveTab = value => self.activeTab = value;
    const setOptimizationDataLoader = value => self.optimizationDataLoader = value;
    const addBulkPage = value => {
      const bulkPagesClone = [...self.bulkPages];
      bulkPagesClone.unshift(value);
      self.bulkPages = cast(bulkPagesClone);
    };
    const addRemoveAllPages = (value: any[], method='addAll') => {
      if (method === 'removeAll') {
        self.bulkPages = cast([]);
      } else {
        self.bulkPages = cast(value);
      }
    };

    const publicHash =() => {
      return getSingleUrlParam('public_hash');
    };

    const findPriceByRange = (target, key) => {
      const arr = toJS(self?.ottoQuotaAllocations)?.filter(item => item?.quotaKey?.includes(key))?.map(i => {
        if (i?.range === '50') {
          return {...i, range: '50+'};
        }
        return i;
      }) || [];

      for (const item of arr) {
        const range = item?.range;
        if (range?.includes('-')) {
          const [start, end] = range.split('-').map(Number);
          if (target >= start && target <= end) {
            return item;
          }
        } else if (range?.includes('+')) {
          const start = parseInt(range);
          if (target >= start) {
            return item;
          }
        } else {
          const singleNumber = parseInt(range);
          if (target === singleNumber) {
            return item;
          }
        }
      }
      return null;
    };

    const removeBulkPage = value => {
      const bulkPagesClone = [...self.bulkPages];
      const index = toJS(bulkPagesClone).findIndex(item => (item?.url === value?.url));
      if (index !== -1) {
        bulkPagesClone?.splice(index, 1);
      }
      self.bulkPages = cast(bulkPagesClone);
    };
    const editBulkPage = (existingUrl, newItem) => {
      const bulkPagesClone = [...self.bulkPages];
      const index = toJS(bulkPagesClone).findIndex(item => (item?.url === existingUrl));
      if (index !== -1) {
        bulkPagesClone[index] = newItem;
      }
      self.bulkPages = cast(bulkPagesClone);
    };
    const loadOttoV2Projects = flow(function* (stopLoading?: boolean, isLoadmore = false, isRB=false) {
      if (!stopLoading && !isLoadmore) {
        self.loadingProjects = true;
      }
      if (!stopLoading && isLoadmore) {
        self.loadmoreLoading = true;
      }
      const isPublicHash = publicHash() ? publicHash() : '';
      try {
        if (isPublicHash) return;
        const params = {
          page_size: isRB ? 100 : self.defaultParams.pageSize || 20,
          page: self.defaultParams.page || 1,
          search: self.defaultParams.search || '',
          is_frozen: false,
        };
        const loadingArray = [null, 'PENDING', 'STARTED'];
        const response = yield OTTO_V2_API.getOttoV2Projects(params);
        if (response?.isCancel) return;
        if (isLoadmore) {
          self.ottoV2Projects = cast({...response, results: [...self.ottoV2Projects.results, ...response.results]});
        } else {
          self.ottoV2Projects = cast(response);
        }
        const loadingRecords = response?.results?.filter(record => loadingArray.includes(record?.taskStatus) && record?.readyForProcessing);
        if (loadingRecords.length && self.projectListShouldRepoll) {
          self.loadingProjects = false;
          const now = new Date().getTime();
          if ((now - time) < (30 * 60 * 1000)) {
            yield new Promise(r => setTimeout(r, 5000));
            return loadOttoV2Projects(true, isLoadmore);
          }
        }
        self.loadingProjects = false;
        self.loadmoreLoading = false;
        return self.ottoV2Projects.results || [];
      } catch (err) {
        handleNetworkError(err);
        self.loadingProjects = false;
        self.loadmoreLoading = false;
        const whiteLabel = localStorage.getItem('whitelabelOtto' ) || 'OTTO';
        const errorMessage = apiError(err, `Failed to fetch ${whiteLabel} projects list`) as string;
        notification.error('', errorMessage);
      }
    });

    const setLoadingProject = value => {
      self.loadingProject = value;
    };

    const resetOtto = () => self.ottoV2Project = null;
    const loadOttoV2Project = flow(function* (uuid: string, stopLoading?: boolean, stopGBPLocation?: boolean, isRb=false) {
      if (!stopLoading) {
        self.loadingProject = true;
      }
      try {
        const publicApi = publicHash() ? publicHash() : '';
        const response = yield OTTO_V2_API.getOttoV2Project(uuid, publicApi);
        if (response?.isCancel) return;
        // if (!Array.isArray(response?.siteAuditCrawlSettings?.crawlRulesJson)) delete response?.siteAuditCrawlSettings?.crawlRulesJson;
        self.ottoV2Project = cast(response);
        if (self.selectedLocation.id && !stopGBPLocation && !publicApi) {
          loadGBPLocationDetail(+self.selectedLocation.id);
        }
        isRb && setOttoProjectNotFoundInRb(false);
        if (response?.deepFreezeTask?.status === 'PENDING') {
          self.loadingProject = false;
          yield new Promise(r => setTimeout(r, 5000));
          return loadOttoV2Project(uuid, stopLoading, stopGBPLocation, isRb);
        }
        return response;
      } catch (err) {
        handleNetworkError(err);
        if (isRb) {
          err?.response?.status == 404 ? setOttoProjectNotFoundInRb(true) : setOttoProjectNotFoundInRb(false);
        }
        const whiteLabel = localStorage.getItem('whitelabelOtto') || 'OTTO';
        const errorMessage = apiError(err, `Failed to fetch ${whiteLabel} project details`) as string;
        if (isRb && self.ottoProjectNotFoundInRb) {
          return;
        }
        notification.error('', errorMessage);
      } finally {
        self.loadingProject = false;
      }
    });

    const getGBPLocations = flow(function* (stopLoading?: boolean, isStandAlone?: boolean) {
      if (!stopLoading) {
        self.loadingGBPLocations = true;
      }
      try {
        const response = yield OTTO_V2_API.getGBPLocations(isStandAlone);
        if (response.isCancel) return;
        self.gbpLocations = response?.results;
        return response?.results;
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to fetch GBP Locations') as string;
        notification.error('', errorMessage);
      } finally {
        self.loadingGBPLocations = false;
      }
    });

    const getAllGBPLocations = flow(function* (stopLoading?: boolean, isStandAlone?: boolean) {
      if (!stopLoading) {
        self.loadingAllGBPLocations = true;
      }
      try {
        const response = yield OTTO_V2_API.getAllGBPLocations(isStandAlone);
        if (response.isCancel) return;
        self.allGBPLocations = response;
        return response;
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to fetch GBP Locations') as string;
        notification.error('', errorMessage);
      } finally {
        self.loadingAllGBPLocations = false;
      }
    });

    const manageGBPLocations = flow(function* (payload, isStandAlone = false, noLoading?: boolean, isV2=false, isActive?: any, isbulk=false) {
      if (!noLoading) {
        self.managingGBPLocations = true;
      }
      try {
        const response = yield OTTO_V2_API.manageGBPLocations(payload, isStandAlone);
        if (response.isCancel) return;
        if (payload?.mode == 'OVERRIDE' || isV2) {
          notification.success('Success', `${isActive === true ? `GBP Location${isbulk && 's'} Deactivated Successfully` : isActive === false ? `GBP Location${isbulk && 's'} Activated Successfully` : 'GBP Locations Updated Successfully'}`);
        } else if (payload?.mode == 'SET_STANDALONE') {
          if (Array.isArray(payload?.location_ids) && !payload?.location_ids?.length) {
            notification.success('Success', `GBP Location Disconnected Successfully`);
          } else {
            notification.success('Success', `GBP Location${payload?.location_ids?.length > 0 && 's'} Connected Successfully`);
          }
        } else {
          notification.success('Success', `GBP Location${payload?.location_ids?.length > 0 && 's'} Disconnected Successfully`);
        }
      } catch (err) {
        const errorMessage = apiError(err, `Failed to ${payload?.mode} GBP Locations`) as string;
        notification.error('', errorMessage);
      } finally {
        self.managingGBPLocations = false;
      }
    });

    const loadGBPLocationDetail = flow(function* (id: number, isStandAlone=false) {
      try {
        const response = yield OTTO_V2_API.getLocationDetail(id, isStandAlone);
        if (response.isCancel) return;
        self.gbpLocationDetail = cast(response);
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to Load GBP location detail') as string;
        notification.error('', errorMessage);
      }
    });
    const setReviewSettings = flow(function* ({toneOfVoice, length, language, configByStars, location}: autoRespondDTO, isStandAlone= false) {
      const payload = {
        location: location || self.selectedLocation.id,
        tone_of_voice: toneOfVoice,
        length: length,
        language: language,
        config_by_stars: configByStars,
      };
      try {
        const response = yield OTTO_V2_API.setReviewSettings(location || self.selectedLocation.id, payload, isStandAlone);
        if (response.isCancel) return;
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to update auto respond settings.') as string;
        notification.error('', errorMessage);
      }
    });
    const updateOTTOSettings = flow(function* (uuid: string, payload) {
      self.updatingOTTOSettings = true;
      try {
        const response = yield OTTO_V2_API.updateOTTOSettings(uuid, payload);
        if (response.isCancel) return;
        notification.success('Success', 'Crawl Settings Updated Successfully');
      } catch (err) {
        const whiteLabel = localStorage.getItem('whitelabelOtto') || 'OTTO';
        const errorMessage = apiError(err, `Failed to update ${whiteLabel} settings.`) as string;
        notification.error('', errorMessage);
      } finally {
        self.updatingOTTOSettings = false;
      }
    });
    const loadReviewSettings = flow(function* (location?: number | string, isStandAlone=false, isV2=false) {
      try {
        const response = yield OTTO_V2_API.loadReviewSettings(location || (isV2 ? self.selectedBusiness.id : self.selectedLocation.id), isStandAlone);
        if (response.isCancel) return;
        self.reviewSettings = cast(response);
        return response;
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to Load auto respond settings.') as string;
        notification.error('', errorMessage);
      }
    });

    const setAutoPilot = flow(function* (uuid: string, isEngaged: boolean) {
      self.loadingAutoPilot = true;
      try {
        const response = yield OTTO_V2_API.setAutoPilot(uuid, isEngaged);
        if (response.isCancel) return;
        if (response.status === 200) {
          yield loadOttoV2Project(uuid, true, true);
        }
        self.loadingAutoPilot = false;
      } catch (err) {
        self.loadingAutoPilot = false;
        const whiteLabel = localStorage.getItem('whitelabelOtto') || 'OTTO';
        const errorMessage = apiError(err, `Failed to fetch ${whiteLabel} project details`) as string;
        notification.error('', errorMessage);
      }
    });

    const connectGBPOtto = flow(function* (uuid: string, payload, showNotification:boolean=true, stopLoadProjectApi = false) {
      self.connectingGbp = true;
      try {
        const response = yield OTTO_V2_API.connectGBP(uuid, payload);
        if (response.isCancel) return;
        if (stopLoadProjectApi) {
          loadOttoV2Project(uuid);
        }
        if (showNotification) {
          if (payload?.location_ids?.length ?? payload?.location_id) {
            notification.success('Success', 'GBP connected successfully.', 'Close');
          } else {
            notification.success('', 'GBP disconnected successfully.', 'Close');
          }
        }
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to connect GBP. Please try again later') as string;
        notification.error('', errorMessage, false, 'Close');
      } finally {
        self.connectingGbp = false;
      }
    });

    const editCustomHtml = flow(function* (id: number, uuid: string, payload) {
      self.editingCustomHtml = true;
      try {
        const response = yield OTTO_V2_API.editCustomHtml(id, uuid, payload);
        if (response.isCancel) return;
      } catch (err) {
        const errorMessage = apiError(err) as string;
        notification.error('', errorMessage);
      } finally {
        self.editingCustomHtml = false;
      }
    });

    const setSelectedIssueTypeObj = val => {
      self.selectedIssueTypeObj = val;
    };

    const updateSearchParams = params => {
      const clone = {...params};
      if (params.search) {
        if (self.ottoSearchTerm == '') {
          delete clone['search'];
          // clone['search'] = '';
        }
        self.searchTextValue = params.search;
      } else {
        if (self.deployIssueType === clone.issue_type) {
          if (clone.search === '') {
            self.searchTextValue = '';
            delete params['search'];
          }
          if (clone.search === undefined) {
            clone['search'] = self.searchTextValue;
          }
        } else {
          self.deployIssueType = clone.issue_type;
          self.searchTextValue = '';
          delete clone['search'];
        }
      }
      if (clone.issue_type === 'gbp_posts') {
        if (self.deployStatusKey === 'All' || self.deployStatusKey === 'all') {
          delete clone['status__in'];
          self.deployStatusKey = null;
          self.deployIssueType = clone.issue_type;
        } else if (self.deployStatusKey === 'Draft') {
          clone['status__in'] = 'Pending Review, Unpublished';
          self.isDeployStatus = 'Pending Review, Unpublished';
        } else {
          clone['status__in'] = self.deployStatusKey;
          self.isDeployStatus = String(self.deployStatusKey);
        }
        if (clone?.topic_type__in === '') {
          delete clone['topic_type__in'];
        }
      }
      if (self.deployStatusKey === 'deployed') {
        if (['qanda_suggestions'].includes(clone.issue_type)) {
          clone['status__in'] = 'Published';
          self.isDeployStatus = 'Published';
        } else if (clone.issue_type === 'unanswered_reviews') {
          clone['is_replied'] = 'true';
          self.isDeployStatus = 'true';
        } else {
          clone['deploy_status'] = true;
          self.isDeployStatus = true;
        }
        self.deployIssueType = clone.issue_type;
      } else if (self.deployStatusKey === 'not_deployed') {
        if (['qanda_suggestions'].includes(clone.issue_type)) {
          clone['status__in'] = 'Pending Review, Scheduled, Publishing, Rejected, Unpublished';
          self.isDeployStatus = 'Pending Review, Scheduled, Publishing, Rejected, Unpublished';
        } else if (clone.issue_type === 'unanswered_reviews') {
          clone['is_replied'] = 'false';
          self.isDeployStatus = 'false';
        } else {
          clone['deploy_status'] = false;
          self.isDeployStatus = false;
        }
        self.deployIssueType = clone.issue_type;
      } else if (self.deployStatusKey === 'all') {
        delete clone['deploy_status'];
        delete clone['status__in'];
        delete clone['is_replied'];
        self.deployStatusKey = null;
        self.deployIssueType = clone.issue_type;
      } else {
        if (self.deployStatusKey === 'all') {
          delete clone['deploy_status'];
          delete clone['status__in'];
          delete clone['is_replied'];
          self.deployStatusKey = null;
        } else {
          if (self.deployStatusKey !== null) {
            if (['gbp_posts', 'qanda_suggestions'].includes(clone.issue_type)) {
              clone['status__in'] = self.isDeployStatus;
            } else if (clone.issue_type === 'unanswered_reviews') {
              clone['is_replied'] = self.isDeployStatus;
            } else {
              clone['deploy_status'] = self.isDeployStatus;
            }
          } else {
            delete clone['deploy_status'];
            delete clone['status__in'];
            if (!self.deployReplyKey) {
              delete clone['is_replied'];
            }
          }
        }
      }
      return clone;
    };

    const setNlpPagination = (page: number, pageSize: number) => {
      self.nlpPage = page;
      self.nlpPageSize = pageSize;
    };

    const loadIssueTableData = flow(function* (params:{uuid?: string; issue_type?: string; page_size: number; page: number; otto_project?: number; search?: string; star_rating__in?: string; status__in?: string; isStandAlone?: boolean; location__in?: string; meta_include?: string},
      isSitewide?: boolean, isNoLoading = false, getCustomerQuota?: any, isV2 = false) {
      const isPublicHash = publicHash() ? publicHash() : '';
      self.aggregatedStats = null;
      try {
        if (!isNoLoading) {
          self.ottoUrlLoader = true;
          self.ottoIssueType = params.issue_type;
        }
        let response;
        if (params.issue_type !== 'cloud_stacks') setCloudStackCurrentPage(1);
        if (self.isCompliantChecked && (params.issue_type === 'page_title' || params.issue_type === 'meta_description')) params['compliant_only'] = false;
        if (params.issue_type === 'topical_maps_and_supplemental_content') {
          const results = yield OTTO_V2_API.getTopicalMapsTableData(params);
          if (results?.isCancel) return;
          response = {results: results};
          const inProgress = !!results?.filter(item => item?.taskStatus === 'PENDING' || item?.taskStatus === 'STARTED')?.length;
          const aiGenerationStatusIsPending = !inProgress ? Array.isArray(results) ? results?.some(topicMap =>
            Array.isArray(topicMap?.formattedData) && topicMap?.formattedData?.some(data =>
              data?.keywords?.some(keyword =>
                keyword?.titles?.some(title => (!title?.pageUuid && title?.aiGenerationStatus === 'PENDING')),
              ),
            ),
          ): false : false;
          if ((inProgress || aiGenerationStatusIsPending) && self.topicalMapsShouldRepoll) {
            yield new Promise(r => setTimeout(r, 5000));
            if (self.ottoSearchTerm && (params?.search !== self.ottoSearchTerm)) return;
            loadIssueTableData(params, isSitewide);
          }
        } else if (params.issue_type === 'sitemap_indexing') {
          const results = yield OTTO_V2_API.getSitemapsIndexingList(params.uuid);
          response = {results: results.results, count: results.count};
        } else if (params.issue_type === 'custom_url_based_indexing') {
          const results = yield OTTO_V2_API.getIndexingUrls(params.uuid, params);
          response = {results: results.results, count: results.count};
        } else if (params.issue_type === 'cloud_stacks') {
          const results = yield OTTO_V2_API.getCloudStacksTableData(params);
          if (results?.isCancel) return;
          const inProgress = results?.results?.filter(item => item?.status === 'Generating' || item?.status === 'Publishing')?.length;

          const tablePendingLength = self.ottoUrls?.find(i => i?.issueType === params?.issue_type)?.issueTable?.results?.filter(item => item?.status === 'Generating' || item?.status === 'Publishing')?.length;

          if (inProgress < tablePendingLength && getCustomerQuota) {
            getCustomerQuota();
          }

          if (!!inProgress && self.cloudStackProjectShouldRepoll) {
            yield new Promise(r => setTimeout(r, 5000));
            if (params?.page !== self.cloudStackCurrentPage || self.ottoSearchTerm && (params?.search !== self.ottoSearchTerm)) return;
            loadIssueTableData(params, isSitewide, isNoLoading, getCustomerQuota);
          }

          response = results;
        } else if (params.issue_type === 'press_release_distribution') {
          const results = yield OTTO_V2_API.getPressReleaseTableData(params);
          if (results?.isCancel) return;
          const inProgress = results?.results?.filter(item => item?.status === 'Generating' || item?.status === 'Publishing')?.length;

          const tablePendingLength = self.ottoUrls?.find(i => i?.issueType === params?.issue_type)?.issueTable?.results?.filter(item => item?.status === 'Generating' || item?.status === 'Publishing')?.length;

          if (inProgress < tablePendingLength && getCustomerQuota) {
            getCustomerQuota();
          }
          if (!!inProgress && self.cloudStackProjectShouldRepoll) {
            yield new Promise(r => setTimeout(r, 5000));
            if (self.ottoV2Project?.uuid !== params?.uuid || params?.page !== self.pressReleaseCurrentPage || self.ottoSearchTerm && (params?.search !== self.ottoSearchTerm)) return;
            self.selectedCategory == 'press_release_distribution' && loadIssueTableData(params, isSitewide, true, getCustomerQuota);
          }
          const isContentGenerated = results?.results?.filter(item => item?.status === 'Generating')?.length;
          if (isContentGenerated > 0) {
            yield new Promise(r => setTimeout(r, 5000));
            if (self.ottoV2Project?.uuid !== params?.uuid || params?.page !== self.pressReleaseCurrentPage || self.ottoSearchTerm && (params?.search !== self.ottoSearchTerm)) return;
            loadIssueTableData(params, isSitewide, true, getCustomerQuota);
          }
          response = {count: results?.count, next: results?.next, previous: results?.previous, results: results?.results};
        } else if (params.issue_type === 'gbp_posts') {
          const payload = {
            ...params,
            location__in: isV2 ? self.selectedBusiness?.map(item => item.id).join(',') : self.selectedLocation.id,
            meta_include: isV2 ? 'aggregated_stats' : '',
          };
          const updatedParams = updateSearchParams(payload);
          setSelectedIssueTypeObj(updatedParams);
          const results = yield OTTO_V2_API.getOttoGBPPosts(payload);
          if (results?.isCancel) return;
          const inProgress = !!results?.results?.filter(item => item?.status === 'Publishing')?.length;
          if (inProgress && self.gbpShouldRepoll) {
            yield new Promise(r => setTimeout(r, 5000));
            loadIssueTableData({...params, page: self.gbpTableParams.page, page_size: self.gbpTableParams.pageSize || getOttoTablePageSize('gbp_posts')}, isSitewide);
          }
          response = {results: results.results, count: results.count};
          if (isV2) {
            self.aggregatedStats = {...results?.meta?.aggregatedStats, total: results.count};
          }
        } else if (params.issue_type === 'ai_landing_page_builder') {
          const results = yield OTTO_V2_API.getLandingPageBuilderList(params?.uuid);
          if (results?.isCancel) return;
          response = {results: results};
        } else if (params.issue_type === 'qanda_suggestions') {
          const payload = {
            ...params,
            location__in: isV2 ? self.selectedBusiness?.map(item => item.id).join(',') : self.selectedLocation.id,
            meta_include: isV2 ? 'aggregated_stats' : '',
          };
          const updatedParams = updateSearchParams(payload);
          setSelectedIssueTypeObj(updatedParams);
          const results = yield OTTO_V2_API.getOttoGBPQuestionAnswers(updatedParams);
          if (results?.isCancel) return;
          response = {results: results.results, count: results.count};
          if (isV2) {
            self.aggregatedStats = {...results?.meta?.aggregatedStats, total: results.count};
          }
        } else if (params.issue_type === 'aggregator_network') {
          const payload = {
            ...params,
            location: self.selectedLocation.id,
          };
          const updatedParams = updateSearchParams(payload);
          setSelectedIssueTypeObj(updatedParams);
          const results = yield OTTO_V2_API.getAggregatorNetwork(self.selectedLocation.locationId);
          if (results?.isCancel) return;
          response = {results: results};
        } else if (params.issue_type === 'unanswered_reviews') {
          const payload = {
            ...params,
            location__in: isV2 ? self.selectedBusiness?.map(item => item.id).join(',') : self.selectedLocation.id,
            meta_include: isV2 ? 'aggregated_stats' : '',
          };
          const updatedParams = updateSearchParams(payload);
          setSelectedIssueTypeObj(updatedParams);
          const results = yield OTTO_V2_API.getOttoGBPReviews(updatedParams);

          if (results?.isCancel) return;
          response = {results: results.results, count: results.count};
          if (isV2) {
            self.aggregatedStats = {...results?.meta?.aggregatedStats, total: results.count};
          }
          if (!params.star_rating__in) {
            getStarRatingCount(updatedParams?.isStandAlone);
          }
        } else if (params.issue_type === 'profile_optimizations') {
          // For profile optimizations need to call here
        } else if (isSitewide) {
          const updatedParams = updateSearchParams(params);
          if (params.issue_type === 'organization_schema') {
            const results = yield OTTO_V2_API.getSitewideIssueTableData({issue_type: params?.issue_type, uuid: params?.uuid, search: params?.search, schema: true}, isPublicHash);
            response = {results: Array.isArray(results) ? results : [results]};
          } else {
            const results = yield OTTO_V2_API.getSitewideIssueTableData(updatedParams, isPublicHash);
            if (results?.isCancel) return;
            if (params.issue_type === 'images') {
              response = {results: results?.results, count: results?.count};
            } else if (params?.issue_type === 'dynamic_indexing') {
              response = {results: results?.values};
            }
          }
        } else {
          const updatedParams = updateSearchParams(params);
          const results = yield OTTO_V2_API.getIssueTableData(updatedParams, isPublicHash);
          if (results?.isCancel) return;
          const isGenerating = results?.results?.some(item => item?.isGenerating);
          const inProgressLength = results?.results?.filter(item => (item?.nlpStatus === 'PENDING' || item?.nlpStatus === 'STARTED'))?.length;
          const inProgress = !!inProgressLength;
          const prevInProgress = self.ottoUrls?.find(item => item?.issueType == params.issue_type)?.issueTable?.results?.filter(item => (item?.nlpStatus === 'PENDING' || item?.nlpStatus === 'STARTED'))?.length;
          if ((params.issue_type == 'nlp_terms' || params.issue_type == 'nlp_faq') && params?.uuid && prevInProgress !== inProgressLength) {
            self.isDeploying = true;
            loadOttoV2Project(params?.uuid, true);
          }
          if (self.selectedCategory == 'knowledge_based_trust' && params?.uuid && inProgressLength && self.nlpFaqShouldRepoll) {
            yield new Promise(r => setTimeout(r, 3000));
            if (self.nlpFaqShouldRepoll) {
              loadIssueTableData({
                ...updatedParams,
                page: self.nlpPage,
                page_size: getOttoTablePageSize(params.issue_type),
                search: params?.search,
              }, false, true);
            }
          } else if (self.selectedCategory == 'semantic_analysis' && params?.uuid && inProgressLength && self.nlpTermsShouldRepoll) {
            yield new Promise(r => setTimeout(r, 3000));
            if (self.nlpTermsShouldRepoll) {
              loadIssueTableData({
                ...updatedParams,
                page: self.nlpPage,
                page_size: getOttoTablePageSize(params.issue_type),
                search: params?.search,
              }, false, true);
            }
          }
          if (isGenerating && self.aiShouldRepoll) {
            yield new Promise(r => setTimeout(r, 3000));
            loadIssueTableData(updatedParams, isSitewide, true);
          } else if (inProgress && (self.nlpFaqShouldRepoll || self.nlpTermsShouldRepoll)) {
            if (params?.search == self.ottoSearchTerm) {
              yield new Promise(r => setTimeout(r, 5000));
              loadIssueTableData(updatedParams, isSitewide);
            }
          }
          if (!isGenerating) {
            self.aiShouldRepoll = false;
          }
          response = results;
        }
        setSearchText(params.search);
        const array = [...self.ottoUrls];
        if (array.length > 0) {
          const index = array.findIndex(obj => obj.issueType === params.issue_type);
          if (index !== -1) {
            array[index].issueTable = cast(response);
            array[index].page = params.page;
            array[index].pageSize = params.page_size;
          } else {
            array.push({
              issueType: params.issue_type,
              issueTable: cast(response),
              page: params.page,
              pageSize: params.page_size,
            });
          }
        } else {
          array.push({
            issueType: params.issue_type,
            issueTable: cast(response),
            page: params.page,
            pageSize: params.page_size,
          });
        }
        self.ottoUrls = cast(array);
        self.ottoUrlLoader = false;
        self.ottoIssueType = '';
        return response;
      } catch (err) {
        handleNetworkError(err);
        const whiteLabel = localStorage.getItem('whitelabelOtto') || 'OTTO';
        const errorMessage = apiError(err, `Failed to fetch ${whiteLabel} issues`) as string;
        params.issue_type != 'organization_schema' && notification.error('', errorMessage);
        if (params.issue_type == 'organization_schema') {
          self.ottoUrls = cast([{
            issueType: params.issue_type,
            issueTable: cast(null),
          }]);
        }
        self.ottoUrlLoader = false;
        self.ottoIssueType = '';
      }
    });


    const deployOttoUrls = flow(function* ({toDeploy, issueType, ottoUrls, uuid, issueGroup, itemIndex, itemIndexes, feelingLuckyBanner=false}: deployOttoUrlsDTO) {
      if (feelingLuckyBanner) {
        self.deploying = true;
      }
      let data = {};
      if (issueGroup) {
        data = {
          to_deploy: toDeploy,
          issue_group: issueGroup,
        };
      } else if (issueType === 'images' || issueType === 'dynamic_indexing') {
        data = {
          to_deploy: toDeploy,
          issue_type: issueType,
          item_index: itemIndex,
        };
      } else if (['internal_link_suggestions', 'missing_keywords', 'link'].includes(issueType)) {
        data = {
          to_deploy: toDeploy,
          issue_type: issueType,
          otto_urls: ottoUrls,
          item_indexes: itemIndexes,
        };
      } else if (['links'].includes(issueType)) {
        data = {
          to_deploy: toDeploy,
          issue_type: issueType,
          otto_urls: ottoUrls,
          item_index: itemIndex,
        };
      } else if (issueType == 'deploy-all' || issueType == 'undeploy-all') {
        data = {
          to_deploy: toDeploy,
        };
      } else {
        data = {
          to_deploy: toDeploy,
          issue_type: issueType,
          otto_urls: ottoUrls,
        };
      }
      try {
        const publicShareHash = publicHash() ? publicHash() : '';
        const response = yield OTTO_V2_API.deployOttoUrls(data, uuid, publicShareHash);
        if (response.isCancel) return;
        self.deployingOttoUrls = true;
        if (response) {
          // self.ottoV2Project = cast(response);
        }
        return true;
      } catch (err) {
        const whiteLabel = localStorage.getItem('whitelabelOtto') || 'OTTO';
        const errorMessage = apiError(err, `Failed to deploy ${whiteLabel} urls`) as string;
        notification.error('', errorMessage);
        return Promise.reject(err);
      } finally {
        self.deploying = false;
      }
    });

    const deployOttoGBPPosts = flow(function* ({id, imageUrl, schedulePublishingAt, content, location=null}: deployOttoGBPPostsDTO, isStandAlone=false, showErrorMessage = true) {
      const data = {
        location: location ? location : self.selectedLocation.id,
        image_url: imageUrl,
        schedule_publishing_at: schedulePublishingAt,
        content,
      };
      try {
        const response = yield OTTO_V2_API.approveOttoGBPPosts(data, id, isStandAlone);
        if (response.isCancel) return;
        self.isDeploying = true;
        return response;
      } catch (err) {
        if (showErrorMessage) {
          const errorMessage = apiError(err, 'Failed to deploy approve GBP post') as string;
          notification.error('', errorMessage);
        }
        return Promise.reject(err);
      }
    });

    const unApproveOttoGBPPosts = flow(function* ({id, imageUrl, schedulePublishingAt, content}: deployOttoGBPPostsDTO, isStandAlone=false) {
      const data = {
        location: self.selectedLocation.id,
        image_url: imageUrl,
        schedule_publishing_at: schedulePublishingAt,
        content,
      };
      try {
        const response = yield OTTO_V2_API.unApproveOttoGBPPosts(data, id, isStandAlone);
        if (response.isCancel) return;
        self.isDeploying = true;
        return response;
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to un approve GBP post') as string;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const publishOttoGBPPost = flow(function* ({id, imageUrl, schedulePublishingAt, content, location=null}: deployOttoGBPPostsDTO, isStandAlone=false, showErrorMessages = true) {
      const data = {
        location: location ?? self.selectedLocation.id,
        image_url: imageUrl,
        schedule_publishing_at: schedulePublishingAt,
        content,
      };
      try {
        const response = yield OTTO_V2_API.publishOttoGBPPosts(data, id, isStandAlone);
        if (response.isCancel) return;
        return response;
      } catch (err) {
        if (showErrorMessages) {
          const errorMessage = apiError(err, 'Failed to publish GBP post') as string;
          notification.error('', errorMessage);
        }
        return Promise.reject(err);
      }
    });

    const unPublishOttoGBPPost = flow(function* ({id, imageUrl, schedulePublishingAt, content}: deployOttoGBPPostsDTO, isStandAlone=false) {
      const data = {
        location: self.selectedLocation.id,
        image_url: imageUrl,
        schedule_publishing_at: schedulePublishingAt,
        content,
      };
      try {
        const response = yield OTTO_V2_API.unPublishOttoGBPPosts(data, id, isStandAlone);
        if (response.isCancel) return;
        return response;
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to publish GBP post') as string;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const publishOttoGBPPosts = flow(function* ({id, imageUrl, schedulePublishingAt, content}: deployOttoGBPPostsDTO, isStandAlone=false) {
      const data = {
        location: self.selectedLocation.id,
        image_url: imageUrl,
        schedule_publishing_at: schedulePublishingAt,
        content,
      };
      try {
        const response = yield OTTO_V2_API.publishGBPQuestion(data, id, isStandAlone);
        if (response.isCancel) return;
        // self.deployingOttoUrls = true;
        self.isDeploying = true;
        return response;
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to publish GBP post') as string;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const unPublishOttoGBPPosts = flow(function* ({id, imageUrl, schedulePublishingAt, content}: deployOttoGBPPostsDTO, isStandAlone=false) {
      const data = {
        location: self.selectedLocation.id,
        image_url: imageUrl,
        schedule_publishing_at: schedulePublishingAt,
        content,
      };
      try {
        const response = yield OTTO_V2_API.unPublishGBPQuestion(data, id, isStandAlone);
        if (response.isCancel) return;
        // self.deployingOttoUrls = true;
        self.isDeploying = true;
        return response;
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to un publish GBP post') as string;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const publishGPBQuestion = flow(function* (id: number, payload: deployOttoUrlsDTO, isStandAlone=false) {
      try {
        const response = yield OTTO_V2_API.publishGBPQuestion(id, payload, isStandAlone);
        if (response.isCancel) return;
        self.deployingOttoUrls = true;
      } catch (err) {
        const whiteLabel = localStorage.getItem('whitelabelOtto') || 'OTTO';
        const errorMessage = apiError(err, `Failed to deploy ${whiteLabel} urls`) as string;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const aiEnhanceGPBQuestion = flow(function* (id, payload, generatingPublished=false, isStandAlone=false) {
      self.generatingAiProposedFix = true;
      try {
        const response = yield OTTO_V2_API.aiEnhanceGBPOQuestion(id, payload, isStandAlone);
        if (response.isCancel) return;
        self.deployingOttoUrls = generatingPublished ? false : true;
        self.generatingAiProposedFix = generatingPublished ? generatingPublished : false;
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to generate') as string;
        self.generatingAiProposedFix = false;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const aiGenerateGPBOwnersAnswer = flow(function* (id, payload, generatingPublished=false, isStandAlone=false) {
      self.generatingAiProposedFix = true;
      try {
        const response = yield OTTO_V2_API.aiEnhanceGBPOwnersAnswer(id, payload, isStandAlone);
        if (response.isCancel) return;
        self.deployingOttoUrls = generatingPublished ? false : true;
        self.generatingAiProposedFix = generatingPublished ? generatingPublished : false;
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to generate') as string;
        self.generatingAiProposedFix = false;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const unPublishGPBQuestion = flow(function* (id: number, payload: deployOttoUrlsDTO, isStandAlone=false) {
      try {
        const response = yield OTTO_V2_API.unPublishGBPQuestion(id, payload, isStandAlone);
        if (response.isCancel) return;
        self.deployingOttoUrls = true;
      } catch (err) {
        const whiteLabel = localStorage.getItem('whitelabelOtto') || 'OTTO';
        const errorMessage = apiError(err, `Failed to deploy ${whiteLabel} urls`) as string;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const deploySubOttoUrls = flow(function* ({toDeploy, issueType, ottoUrls, uuid, itemIndex}: deployOttoUrlsDTO) {
      const data = {
        to_deploy: toDeploy,
        issue_type: issueType,
        otto_urls: ottoUrls,
        item_index: itemIndex,
      };
      try {
        const publicShareHash = publicHash() ? publicHash() : '';
        const response = yield OTTO_V2_API.deployOttoUrls(data, uuid, publicShareHash);
        if (response.isCancel) return;
        self.deployingOttoUrls = true;
      } catch (err) {
        const whiteLabel = localStorage.getItem('whitelabelOtto') || 'OTTO';
        const errorMessage = apiError(err, `Failed to deploy ${whiteLabel} urls`) as string;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const updateProposeFix = flow(function* (payload) {
      self.deployingProposedFix = true;
      const data = {
        item_index: payload.itemIndex,
        recommended_value: payload.recommendedValue,
        anchor_text: payload.anchorText,
        issue_type: payload.issueType,
        object_value: payload.objectValue,
        object_key: payload.objectKey,
      };
      try {
        const publicShareHash = publicHash() ? publicHash() : '';
        const response = yield OTTO_V2_API.deployProposedFix(data, payload.id, payload.uuid, publicShareHash);
        if (response.isCancel) return;
        self.deployingOttoUrls = true;
        self.deployingProposedFix = false;
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to update the proposed fix') as string;
        self.deployingProposedFix = false;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const deployProposedFix = flow(function* ({id, issueType, proposedFix, uuid}: deployProposedFixDTO, itemIndex?: any) {
      self.deployingProposedFix = true;
      const index = itemIndex >= 0 ? {item_index: itemIndex} : {};
      const data = {
        issue_type: issueType,
        recommended_value: proposedFix,
        ...index,
      };
      try {
        const publicShareHash = publicHash() ? publicHash() : '';
        const response = yield OTTO_V2_API.deployProposedFix(data, id, uuid, publicShareHash);
        if (response.isCancel) return;
        self.deployingOttoUrls = true;
        self.deployingProposedFix = false;
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to add the proposed fix') as string;
        self.deployingProposedFix = false;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const loadCloudStacksProviders = flow(function* () {
      try {
        const response = yield OTTO_V2_API.getCloudStacksProviders();
        if (response.isCancel) return;
        self.cloudStackProviders = cast(response);
      } catch (err) {
        self.cloudStackProviders = cast([]);
        notification.error('', 'Failed to load cloud stack providers.');
      }
    });

    const deleteCloudStackContent = flow(function* (id) {
      try {
        yield OTTO_V2_API.deleteCloudStackContent(id);
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to delete cloud stack') as string;
        notification.error('', errorMessage);
      }
    });

    const deleteQASuggestion = flow(function* (id, isStandAlone=false) {
      try {
        yield OTTO_V2_API.deleteQASuggestion(id, isStandAlone);
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to Q&A suggestion') as string;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const deployImageAltText = flow(function* ({id, issueType, proposedFix, uuid, toDelete}: deployProposedFixDTO, reloadData = true) {
      self.deployingProposedFix = true;
      const data = {
        issue_type: issueType,
        item_index: id,
      };
      if (toDelete) {
        data['to_delete'] = true;
      } else {
        data['recommended_value'] = proposedFix;
      }
      try {
        const publicShareHash = publicHash() ? publicHash() : '';
        const response = yield OTTO_V2_API.deployImageAltText(data, id, uuid, publicShareHash);
        if (response.isCancel) return;
        if (reloadData) self.deployingOttoUrls = true;
        self.deployingProposedFix = false;
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to add the proposed fix') as string;
        self.deployingProposedFix = false;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const generateAiProposedFix = flow(function* ({id, issueType, uuid, itemIndex, generateAll}: generateAiProposedFixDTO) {
      self.generatingAiProposedFix = true;
      const index = itemIndex >= 0 ? {item_index: itemIndex} : {};
      const payload = {
        issue_type: issueType,
        ...index,
      };
      if (issueType === 'nlp_faq' && !generateAll) {
        payload['item_index'] = itemIndex;
      }
      try {
        const response = yield OTTO_V2_API.generateAiProposedFix(payload, id, uuid);
        if (response.isCancel) return;
        self.deployingOttoUrls = true;
        self.generatingAiProposedFix = false;
      } catch (err) {
        if (err?.response?.status === 422) {
          notification.error('', err?.response?.data[0]?.message);
        } else {
          const errorMessage = apiError(err, 'Failed to generate') as string;
          notification.error('', errorMessage);
        }
        self.generatingAiProposedFix = false;
        return Promise.reject(err);
      }
    });


    const bulkGenerateAiProposedFix = flow(function* ({issueType, uuid, selectedIds}: bulkGenerateAiProposedFixDTO) {
      const payload = {
        otto_urls: selectedIds,
        issue_types: [issueType],
        missing_only: false,
      };
      try {
        const response = yield OTTO_V2_API.ottoBulkAiGenerate(payload, uuid);
        if (response.isCancel) return;
        if (response) {
          self.deployingOttoUrls = true;
        }
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to generate') as string;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const createOttoProject = flow(function* ({id, readyForProcessing}: createOttoProjectDttO) {
      self.creatingOttoProject = true;
      try {
        const response = yield OTTO_V2_API.createOttoProject({siteaudit: id, ready_for_processing: readyForProcessing});
        if (response.isCancel) return;
        self.deployingOttoUrls = true;
        self.creatingOttoProject = false;
        return response;
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to generate') as string;
        self.creatingOttoProject = false;
        notification.error('', errorMessage);
        return Promise.reject(err);
      } finally {
        self.creatingOttoProject = false;
      }
    });

    const createABProject = flow(function* (type, payload) {
      self.creatingCloudStackProject = true;
      try {
        const response = yield OTTO_V2_API.createCloudStackOrPressRelease(type, payload);
        if (response.isCancel) return;
        notification.success('Success', 'Cloud Stack added successfully');
        return {success: true};
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to create cloud stack') as string;
        self.creatingOttoProject = false;
        notification.error('', errorMessage);
      } finally {
        self.creatingCloudStackProject = false;
      }
    });

    const updateABProject = flow(function* (id, payload, showNotification = true) {
      self.creatingCloudStackProject = true;
      try {
        const response = yield OTTO_V2_API.updateCloudStacksTableData(id, payload);
        if (response.isCancel) return;
        if (showNotification) notification.success('Success', 'Cloud Content published successfully');
        return {success: true};
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to Update') as string;
        self.creatingOttoProject = false;
        notification.error('', errorMessage);
        return Promise.reject(err);
      } finally {
        self.creatingCloudStackProject = false;
      }
    });

    const buildOrDeployCloudStack = flow(function* (id, build, payload = {}) {
      try {
        let response;
        if (payload['keywords']) {
          yield updateABProject(id, payload, false);
        }
        if (build) {
          response = yield OTTO_V2_API.buildCloudContent(id);
        } else {
          response = yield OTTO_V2_API.publishCloudContent(id, payload);
        }
        if (response.isCancel) return;
        if (!build && response?.taskId) {
          yield getTaskResult('cloud_stacks', response?.taskId, false);
        }
        notification.success('Success', `Cloud Stack Content ${build ? 'Created' : 'Published'} successfully`);
        return {success: true};
      } catch (err) {
        self.creatingOttoProject = false;
        return Promise.reject(err);
      }
    });

    const refreshCloudDeployInfo = flow(function* (payload) {
      try {
        const response = yield OTTO_V2_API.refreshCloudStackDeployInfo(payload);
        if (response.isCancel) return;
        notification.success('Success', 'Cloud Stack Info refreshed successfully');
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to Refresh Deployment Info') as string;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const generateLandingPage = flow(function* (payload) {
      try {
        const response = yield OTTO_V2_API.generateLandingPage(payload);
        if (response.isCancel) return;
        return response;
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to generate Landing page') as string;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const deleteOttoProject = flow(function* (id: string) {
      try {
        yield OTTO_V2_API.deleteOttoProject(id);
        notification.success('Success', 'Project deleted successfully');
        loadOttoV2Projects();
      } catch (err) {
        const whiteLabel = localStorage.getItem('whitelabelOtto') || 'OTTO';
        const errorMessage = apiError(err, `Failed to delete ${whiteLabel} project`) as string;
        notification.error('', errorMessage);
      }
    });

    const editLandingPage = flow(function* (uuid: string, payload: any) {
      try {
        yield OTTO_V2_API.editLandingPage(uuid, payload);
        notification.success('Success', 'Landing page edited successfully');
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to edit Landing page') as string;
        notification.error('', errorMessage);
      }
    });

    const deleteLandingPage = flow(function* (uuid: string) {
      try {
        yield OTTO_V2_API.deleteLandingPage(uuid);
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to delete Landing page') as string;
        notification.error('', errorMessage);
      }
    });

    const publishLandingPageToWp = flow(function* (uuid: string, payload: any) {
      try {
        yield OTTO_V2_API.publishLandingPageToWp(uuid, payload);
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to publish landing page') as string;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const rebuildLandingPage = flow(function* (uuid: string) {
      self.rebuildingLandingPage = true;
      try {
        const res = yield OTTO_V2_API.rebuildLandingPage(uuid);
        self.rebuildingLandingPage = false;
        if (res?.isCancel) return;
        return res;
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to regenerate landing page') as string;
        self.rebuildingLandingPage = false;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const getLandingPageDetail = flow(function* (uuid: string, pollingCall?: boolean) {
      try {
        const res = yield OTTO_V2_API.getLandingPageDetail(uuid);
        if (!pollingCall) {
          self.pollingLandingPageId = uuid;
          self.landingPageDetail = res;
        }
        if ((res?.status === 'Generating' || res?.status === 'Pending') && self.landingPageDetailShouldRepoll) {
          yield new Promise(r => setTimeout(r, 5000));
          if (uuid === self.pollingLandingPageId) return getLandingPageDetail(uuid, true);
        }
        self.pollingLandingPageId = '';
        return res;
      } catch (err) {
        const errorMessage = apiError(err, 'Something went wrong') as string;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const getCitySuggestions = flow(function* (payload) {
      try {
        const res = yield OTTO_V2_API.getCitySuggestions(payload);
        if (res?.isCancel) return;
        return res || [];
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to get city suggestions') as string;
        notification.error('', errorMessage);
      }
    });

    const checkScriptFromUrl = flow(function* (url: string) {
      self.scriptStatusLoading = true;
      try {
        const response = yield OTTO_V2_API.checkScriptFromUrl({url});
        if (response.isCancel) return;
        self.scriptStatusLoading = false;
        return response;
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to generate') as string;
        self.scriptStatusLoading = false;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const checkScriptFromInstance = flow(function* (uuid: string) {
      self.scriptStatusLoading = true;
      try {
        const response = yield OTTO_V2_API.checkScriptFromInstance(uuid);
        if (response.isCancel) return;
        self.scriptStatusLoading = false;
        return response;
      } catch (err) {
        handleNetworkError(err);
        const errorMessage = apiError(err, 'Failed to generate') as string;
        self.scriptStatusLoading = false;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const getGSCVerificationScript = flow(function* (uuid: string) {
      self.gscScriptStatusLoading = true;
      try {
        const response = yield OTTO_V2_API.getGSCSiteVerification(uuid);
        self.gscSiteVerificationScript = cast(response);
        if (response.isCancel) return;
        self.gscScriptStatusLoading = false;
        return response;
      } catch (err) {
        self.gscScriptStatusLoading = false;
        return Promise.reject(err);
      }
    });

    const gscSiteVerificationScriptVerification = flow(function* (uuid: string) {
      self.gscScriptStatusLoading = true;
      try {
        const response = yield OTTO_V2_API.gscSiteVerificationScriptVerification(uuid);
        if (response.isCancel) return;
        self.gscScriptStatusLoading = false;
        return response;
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to generate') as string;
        self.gscScriptStatusLoading = false;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const refreshGSCSiteVerificationScript = flow(function* (uuid: string) {
      self.gscScriptStatusLoading = true;
      try {
        const response = yield OTTO_V2_API.refreshGSCSiteVerificationScript(uuid);
        if (response.isCancel) return;
        self.gscScriptStatusLoading = false;
        return response;
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to generate') as string;
        self.gscScriptStatusLoading = false;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const topicalMapUpdateArticle = flow(function* ({id, titleUuid, pageUuid, outlineUuid, shouldDelete, publicShareHash}: topicalMapUpdateArticleDTO) {
      self.scriptStatusLoading = true;
      try {
        const payload = {
          title_uuid: titleUuid,
        };
        if (pageUuid) {
          payload['page_uuid'] = pageUuid;
        }
        if (outlineUuid) {
          payload['outline_uuid'] = outlineUuid;
        }
        if (shouldDelete) {
          payload['should_delete'] = shouldDelete;
        }
        if (publicShareHash) {
          payload['publicShareHash'] = publicShareHash;
        }
        const response = yield OTTO_V2_API.topicalMapUpdateArticle(payload, id);
        if (response.isCancel) return;
        self.scriptStatusLoading = false;
        return response;
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to generate') as string;
        self.scriptStatusLoading = false;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const createOttoTopicalMap = flow(function* (payload, updateAiSettings=false, defaultAiSettingsPayload=null) {
      self.creatingTopicalMap = true;
      try {
        const response = yield OTTO_V2_API.createOttoTopicalMap(payload);
        if (response?.isCancel) return;
        if (defaultAiSettingsPayload) {
          defaultAiSettingsPayload['domain'] = payload.content_page;
        }
        if (updateAiSettings && defaultAiSettingsPayload !== null && response?.project) {
          yield PAGES_API.updateContentProjectAiSettings({payload: defaultAiSettingsPayload, projectId: response.project});
          yield PAGES_API.getProjects();
        }
        self.creatingTopicalMap = false;
        return response;
      } catch (err) {
        self.creatingTopicalMap = false;
        const errorMessage = apiError(err, 'Failed to create topical maps') as string;
        notification.error('', errorMessage);
      }
    });

    const updateOttoTopicalMap = flow(function* (id, project) {
      try {
        const response = yield OTTO_V2_API.updateOttoTopicalMap(id, project);
        if (response?.isCancel) return;
        return response;
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to create topical maps') as string;
        notification.error('', errorMessage);
      }
    });

    const deleteOttoTopicalMap = flow(function* (id) {
      self.deletingTopicalMap = true;
      try {
        const response = yield OTTO_V2_API.deleteOttoTopicalMap(id);
        if (response?.isCancel) return;
        self.deletingTopicalMap = false;
      } catch (err) {
        self.deletingTopicalMap = false;
        const errorMessage = apiError(err, 'Failed to delete topical maps') as string;
        notification.error('', errorMessage);
      }
    });

    const updateTopicalMapKeyword = flow(function* (uuid, payload) {
      try {
        const response = yield OTTO_V2_API.updateTopicalMapKeyword(uuid, payload);
        if (response?.isCancel) return;
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to update keyword') as string;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const getOttoTopicalMapsData = flow(function* (uuid: string) {
      self.loadingTopicalMapsList = true;
      try {
        const response = yield OTTO_V2_API.getOttoTopicalMapsData(uuid);
        if (response.isCancel) return;
        self.topicalMapsList = cast(response);
        self.loadingTopicalMapsList = false;
      } catch (err) {
        self.loadingTopicalMapsList = false;
        const errorMessage = apiError(err, 'Failed to fetch topical maps') as string;
        notification.error('', errorMessage);
      }
    });

    const editPostContent = flow(function* ({id, imageUrl, schedulePublishingAt, content}: deployOttoGBPPostsDTO, isStandAlone=false) {
      self.deployingProposedFix = true;
      const data = {
        location: self.selectedLocation.id,
        image_url: imageUrl,
        schedule_publishing_at: schedulePublishingAt,
        content,
      };
      try {
        const response = yield OTTO_V2_API.editOttoGBPPost(data, id, isStandAlone);
        if (response.isCancel) return;
        self.deployingOttoUrls = true;
        self.deployingProposedFix = false;
        return response;
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to update the post', true) as string;
        self.deployingProposedFix = false;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const storeSelectedTitles = flow(function* ({id, uuids}: storeSelectedTitlesDTO) {
      self.storingSelectedTitles = true;
      try {
        const response = yield OTTO_V2_API.storeSelectedTitles({uuids}, id);
        if (response.isCancel) return;
        self.storingSelectedTitles = false;
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to Store') as string;
        self.storingSelectedTitles = false;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const updateGBPQuestion = flow(function* (id, payload, updatingPublished=false, isStandAlone=false) {
      self.deployingProposedFix = true;
      try {
        const response = yield OTTO_V2_API.updateGBPOQuestion(id, payload, isStandAlone);
        if (response.isCancel) return;
        self.deployingOttoUrls = updatingPublished ? false : true;
        self.deployingProposedFix = updatingPublished ? true : false;
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to update the question') as string;
        self.deployingProposedFix = false;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const updateGBPOwnersAnswer = flow(function* (id, payload, updatingPublished=false, isStandAlone=false) {
      self.deployingProposedFix = true;
      try {
        const response = yield OTTO_V2_API.updateGBPOwnersAnswer(id, payload, isStandAlone);
        if (response.isCancel) return;
        self.deployingOttoUrls = updatingPublished ? false : true;
        self.deployingProposedFix = updatingPublished ? true : false;
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to update the answer') as string;
        self.deployingProposedFix = false;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const createKnowledgeGraph = flow(function* (payload: any) {
      try {
        const response = yield OTTO_V2_API.createKnowledgeGraph(payload);
        if (response.isCancel) return;
        return response;
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const updateKnowledgeGraph = flow(function* (id: number, payload: any) {
      try {
        const response = yield OTTO_V2_API.updateKnowledgeGraph(id, payload);
        self.deployingOttoUrls = true;
        if (response.isCancel) return;
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const updateOttoV2Project = flow(function* (uuid: string, payload: any) {
      try {
        const response = yield OTTO_V2_API.updateOttoV2Project(uuid, payload);
        if (response.isCancel) return;
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const getOttoGBPAiGenerateContent = flow(function* (payload, id=null, isStandAlone=false, key?:string) {
      try {
        const response = yield OTTO_V2_API.getOttoGBPAiGenerateContent((id ? id : self.selectedLocation.id), payload, isStandAlone);
        if (response.isCancel) return;
        return key ? response[key] : response?.content;
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to generate the AI content') as string;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const getOttoGBPAiGenerateImage = flow(function* (payload, id=null, isStandAlone=false) {
      try {
        const response = yield OTTO_V2_API.getOttoGBPAiGenerateImage((id ? id : self.selectedLocation.id), payload, isStandAlone);
        if (response.isCancel) return;
        const data = yield getSummaryData({taskId: response.taskId, generatingBulkImages: true});
        return data?.result;
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to generate the AI image') as string;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const generateOttoGBPAiBulkImage = flow(function* (payload, location, isStandAlone = false) {
      try {
        const response = yield OTTO_V2_API.generateOttoGBPAiBulkImage(location, payload, isStandAlone);
        if (response.isCancel) return;
        const data = yield getSummaryData({taskId: response.taskId, generatingBulkImages: true});
        return data?.result;
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to generate the AI images') as string;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const createOttoGBPPost = flow(function* (payload, isStandAlone = false) {
      try {
        const response = yield OTTO_V2_API.createOttoGBPPost({...payload, location: self.selectedLocation.id}, isStandAlone);
        if (response.isCancel) return;
        return response;
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to create post') as string;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const ottoGBPUploadImage = flow(function* (payload, isCancelMultiple=false) {
      try {
        const response = yield OTTO_V2_API.ottoGBPUploadImage(payload, isCancelMultiple);
        if (response.isCancel && isCancelMultiple) return;
        return response?.image;
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to upload image') as string;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const publishReview = flow(function* (id, isStandAlone = false) {
      self.deployingProposedFix = true;
      try {
        const response = yield OTTO_V2_API.publishReview(id, isStandAlone);
        if (response.isCancel) return;
        self.deployingOttoUrls = true;
        self.deployingProposedFix = false;
      } catch (err) {
        self.deployingProposedFix = false;
        const errorMessage = apiError(err, 'Failed to publish GBP review') as string;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const unPublishReview = flow(function* (id, isStandAlone = false) {
      self.deployingProposedFix = true;
      try {
        const response = yield OTTO_V2_API.unPublishReview(id, isStandAlone);
        if (response.isCancel) return;
        self.deployingOttoUrls = true;
        self.deployingProposedFix = false;
      } catch (err) {
        self.deployingProposedFix = false;
        const errorMessage = apiError(err, 'Failed to unpublish GBP review') as string;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });
    const editOttoGBPReview = flow(function* (id, payload, isStandAlone = false) {
      self.deployingProposedFix = true;
      try {
        const response = yield OTTO_V2_API.editOttoGBPReview(id, payload, isStandAlone);
        if (response.isCancel) return;
        self.deployingOttoUrls = true;
        self.deployingProposedFix = false;
        return response;
      } catch (err) {
        self.deployingProposedFix = false;
        const errorMessage = apiError(err, 'Failed to edit the review') as string;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });
    const aiGenerateGBPReview = flow(function* (id, isStandAlone = false) {
      self.generatingAiProposedFix = true;
      try {
        const response = yield OTTO_V2_API.aiGenerateGBPReview(id, isStandAlone);
        if (response.isCancel) return;
        self.deployingOttoUrls = true;
        self.generatingAiProposedFix = false;
        return response;
      } catch (err) {
        self.generatingAiProposedFix = false;
        const errorMessage = apiError(err, 'Failed to generate the AI reply') as string;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const editOttoGBPPost = flow(function* (id, payload, isStandAlone = false, showErrorMessages = true) {
      try {
        const response = yield OTTO_V2_API.editFullOttoGBPPost(id, {...payload, location: payload?.location ? payload?.location : self.selectedLocation.id}, isStandAlone);
        if (response.isCancel) return;
      } catch (err) {
        if (showErrorMessages) {
          const errorMessage = apiError(err, 'Failed to edit the post', true) as string;
          notification.error('', errorMessage);
        }
        return Promise.reject(err);
      }
    });

    const addQuestionAnswer = flow(function* (payload, locationId?: number | null, isStandAlone = false) {
      self.addingQA = true;
      try {
        const response = yield OTTO_V2_API.addQuestionAnswer({...payload, location: locationId || self.selectedLocation.id}, isStandAlone);
        if (response.isCancel) return;
        self.addingQA = false;
        return response;
      } catch (err) {
        self.addingQA = false;
        const errorMessage = apiError(err, 'Failed to add question and answer') as string;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const ottoQAAiGeneratedAnswer = flow(function* (payload, id=null, isStandAlone = false) {
      try {
        const response = yield OTTO_V2_API.ottoQAAiGeneratedAnswer((id ? id : self.selectedLocation.id), payload, isStandAlone);
        if (response.isCancel) return;
        return response?.content;
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to generate AI answer') as string;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const addPage = flow(function* (uuid, payload) {
      try {
        const response = yield OTTO_V2_API.addPage(uuid, payload);
        if (response.isCancel) return;
        return response?.content;
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to add page') as string;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const addSourcePage = flow(function* (uuid, payload, showNotification = true) {
      self.deployingProposedFix = true;
      try {
        const response = yield OTTO_V2_API.addSourcePage(uuid, payload);
        if (response.isCancel) return;
        if (!['internal_link_suggestions', 'dynamic_indexing'].includes(payload?.issue_type) && showNotification) notification.success('Success', 'Keyword added successfully');
        self.deployingProposedFix = false;
        return response?.content;
      } catch (err) {
        self.deployingProposedFix = false;
        const errorMessage = apiError(err, 'Failed to add source page') as string;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });
    const deleteUrlIssue = flow(function* (id, uuid, params) {
      self.deployingProposedFix = true;
      try {
        const publicShareHash = publicHash() ? publicHash() : '';
        const response = yield OTTO_V2_API.deleteUrlIssue(id, uuid, params, publicShareHash);
        if (response.isCancel) return;
        self.deployingOttoUrls = true;
        self.deployingProposedFix = false;
        return response?.content;
      } catch (err) {
        self.deployingProposedFix = false;
        const errorMessage = apiError(err, 'Failed to delete issue') as string;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });
    const editKBTAnswer = flow(function* (id, uuid, payload) {
      self.deployingProposedFix = true;
      try {
        const publicShareHash = publicHash() ? publicHash() : '';
        const response = yield OTTO_V2_API.editKBTAnswer(id, uuid, payload, publicShareHash);
        if (response.isCancel) return;
        self.deployingOttoUrls = true;
        self.deployingProposedFix = false;
        return response?.content;
      } catch (err) {
        self.deployingProposedFix = false;
        const errorMessage = apiError(err, 'Failed to delete issue') as string;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const getKnowledgeGraphData = flow(function* (id) {
      self.loadingKnowledgeGraph = true;
      try {
        const response = yield OTTO_V2_API.getKnowledgeGraphData(id);
        if (response?.isCancel) return response;
        self.knowledgeGraph = response;
        return response?.content;
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to delete issue') as string;
        notification.error('', errorMessage);
      } finally {
        self.loadingKnowledgeGraph = false;
      }
    });

    const getKnowledgeGraphFieldsMeta = flow(function* () {
      try {
        const response = yield OTTO_V2_API.getKnowledgeGraphFields();
        if (response?.isCancel) return;
        self.ottoKnowledgeGraphFieldsMeta = response;
      } catch (err) {
        const errorMessage = apiError(err, '') as string;
        notification.error('', errorMessage);
      }
    });

    const postBulkAiGenerate = flow(function* (payload, isStandAlone = false) {
      try {
        const response = yield OTTO_V2_API.postBulkAiGenerate(payload, isStandAlone);
        if (response.isCancel) return;
        if (response && response?.taskId) {
          const state = yield getSummaryData({taskId: response.taskId, generatingPost: true});
          return state;
        }
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to generate bulk ai question') as string;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const postBulkCreate = flow(function* (payload, isStandAlone = false) {
      try {
        const response = yield OTTO_V2_API.postBulkCreate(payload, isStandAlone);
        if (response?.isCancel) return;
      } catch (err) {
        // const errorMessage = apiError(err, 'Failed to create bulk posts.') as string;
        // eslint-disable-next-line no-console
        console.log('err', err);
        notification.error('', 'Failed to create bulk posts.' );
        return Promise.reject(err);
      }
    });

    const createBulkPages = flow(function* (payload) {
      self.creatingBulkPages = true;
      try {
        const response = yield OTTO_V2_API.createBulkPages(self.ottoV2Project.uuid, payload);
        if (response?.isCancel) return;
        self.creatingBulkPages = false;
      } catch (err) {
        self.creatingBulkPages = false;
        const errorMessage = apiError(err, 'Failed to create bulk pages.') as string;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const questionBulkAiGenerate = flow(function* (payload, date, locationId?: number | null, isStandAlone = false) {
      self.loadingGenerateBulkQA = true;
      try {
        if ((typeof payload === 'object') && Object.prototype.hasOwnProperty.call(payload, 'question_prompt')) {
          const response = yield OTTO_V2_API.questionBulkAiGenerate(locationId || self.selectedLocation.id, payload, isStandAlone);
          if (response.isCancel) return;
          const responseWithDate = response?.map(item => ({...item, date: date}));
          self.generatedBulkQA = cast(responseWithDate);
        } else {
          const emptyQuestionsArray = [];
          for (let index = 0; index < payload?.count; index++) {
            emptyQuestionsArray.push({
              question: '',
              answer: payload?.answer_prompt ?? '',
              date: date,
            });
          }
          self.generatedBulkQA = cast(emptyQuestionsArray);
        }
        self.loadingGenerateBulkQA = false;
      } catch (err) {
        self.loadingGenerateBulkQA = false;
        const errorMessage = apiError(err, 'Failed to generate bulk ai question') as string;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const generateQAContent = flow(function* (payload, generate: 'question' | 'answer', isStandAlone = false, locationId = null) {
      try {
        const array = [...self.generatedBulkQA];
        const isQuestion = generate === 'question';
        const findKey = isQuestion ? 'question' : 'answer';
        let response;
        if (isQuestion) {
          response = yield OTTO_V2_API.generateQuestionContent(locationId ?? self.selectedLocation.id, payload, isStandAlone);
          if (response.isCancel) return;
        } else {
          response = yield OTTO_V2_API.generateAnswerContent(locationId ?? self.selectedLocation.id, payload, isStandAlone);
          if (response.isCancel) return;
        }
        const index = array?.findIndex(obj => obj?.question === payload.question);
        if (index !== -1) {
          array[index][findKey] = response?.content;
        }
        self.generatedBulkQA = cast(array);
      } catch (err) {
        const errorMessage = apiError(err, `Failed to generate bulk AI ${generate}`);
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const ottoQuotaTopup = flow(function* (payload) {
      self.loadingOttoQuotaTopup = true;
      try {
        const res = yield OTTO_V2_API.ottoQuotaTopup(payload);
        if (payload?.skip_payment && res?.success) {
          notification.success('', res?.details || 'Quota top up set for next billing date.');
        } else if (res?.success) {
          notification.success('', 'Quota purchased successfully.');
        } else if (!res?.success) {
          notification.error('', res?.details || 'Failed to purchase quota.');
        }
      } catch (err) {
        const errorMessage = customapiError(err, '') as string;
        notification.error('', errorMessage);
      } finally {
        self.loadingOttoQuotaTopup = false;
      }
    });

    const getPaymentTopups = flow(function* () {
      try {
        const res = yield OTTO_V2_API.getPaymentTopups();
        self.paymentTopups = res;
      } catch (err) {
        const errorMessage = customapiError(err, '') as string;
        notification.error('', errorMessage);
      }
    });

    const getOttoQuotaAllocations = flow(function* () {
      self.ottoQuotaAllocationsLoading = true;
      try {
        const response = yield OTTO_V2_API.getOttoQuotaAllocations();
        if (response.isCancel) return;
        self.quotaAllocations = response;
      } catch (err) {
        handleNetworkError(err);
        const errorMessage = apiError(err, '') as string;
        notification.error('', errorMessage);
      } finally {
        self.ottoQuotaAllocationsLoading = false;
      }
    });

    const getOttoPlanQuotaAllocations = flow(function* () {
      self.quotaAllocationsLoading = true;
      try {
        const response = yield OTTO_V2_API.getOttoQuotaAllocations({is_otto_plan: true});
        if (response.isCancel) return;
        self.ottoQuotaAllocations = response;
      } catch (err) {
        handleNetworkError(err);
        const errorMessage = apiError(err, '') as string;
        notification.error('', errorMessage);
      } finally {
        self.quotaAllocationsLoading = false;
      }
    });

    const getTaskResult = flow(function* (key, taskId, showNotification=true) {
      try {
        self.refreshingBulkGBPData = true;
        const response = yield OTTO_V2_API.getTaskResult(taskId);
        if (response.isCancel) return;
        if (self.gbpLocationRepoll) {
          if (key === 'press_release') {
            self.deployableStatus = response?.displayableStatus;
          }
          if (response?.displayableStatus === 'PROCESSING') {
            const now = new Date().getTime();
            if ((now - time) < (30 * 60 * 1000)) {
              yield new Promise(r => setTimeout(r, 5000));
              return getTaskResult(key, taskId, showNotification);
            }
          } else {
            const KeyMapping = {
              'reviews': 'Unanswered Reviews refreshed successfully',
              'questions': 'Q&A refreshed successfully',
              'posts': 'Posts refreshed successfully',
              'press_release': 'Press release Content Published successfully',
              'profile_optimizations': 'Data refreshed successfully',
            };
            if (response?.displayableStatus === 'SUCCESS' && showNotification) {
              notification.success('Success', `${KeyMapping[key]}`);
            } else if (response?.displayableStatus === 'FAILED' && response?.errorMessage) {
              const errorMessage = response?.errorMessage;
              notification.error('', `${errorMessage}`);
            }
            self.refreshingBulkGBPData = false;
            localStorage.removeItem('taskId');
          }
        }
      } catch (err) {
        self.refreshingBulkGBPData = false;
        const errorMessage = apiError(err, '') as string;
        notification.error('', errorMessage);
      }
    });

    const ottoBulkImportPostOrQuestion = flow(function* (key, showRefreshNotification=true, standaloneTool=false, isV2=false) {
      try {
        self.refreshingBulkGBPData = true;
        const response = yield OTTO_V2_API.ottoBulkImportPostOrQuestion(isV2 ? self.selectedBusiness[0]?.id :self.selectedLocation.id, key, standaloneTool);
        if (response.isCancel) return;
        let taskIdArray = [];
        if (Array.isArray(response)) {
          taskIdArray = response;
        } else {
          taskIdArray = [response];
        }
        for (let index = 0; index < taskIdArray.length; index++) {
          const taskId = taskIdArray[index];
          yield getTaskResult(key, taskId, showRefreshNotification);
        }
        if (self.selectedLocation.id) {
          loadGBPLocationDetail(+self.selectedLocation.id, standaloneTool);
        }
        self.deployingOttoUrls = true;
        self.refreshingBulkGBPData = false;
      } catch (err) {
        self.refreshingBulkGBPData = false;
        const errorMessage = apiError(err, 'Failed to generate AI answer') as string;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const ottoBulkImportOptimization = flow(function* (showRefreshNotification=true, standaloneTool=false, locationId=null) {
      try {
        self.refreshingBulkGBPOptimization = true;
        const response = yield OTTO_V2_API.ottoBulkImportOptimizationV2(locationId ?? self.selectedLocation.id, standaloneTool);
        if (response.isCancel) return;
        const taskId = response.data.id;
        yield getTaskResult('profile_optimizations', taskId, showRefreshNotification);
        if (self.selectedLocation.id) {
          loadGBPLocationDetail(+self.selectedLocation.id, standaloneTool);
        }
        self.refreshingBulkGBPOptimization = false;
      } catch (err) {
        self.refreshingBulkGBPOptimization = false;
        return Promise.reject(err);
      }
    });

    const getStarRatingCount = flow(function* (isStandAlone = false) {
      const params = {
        location: self.selectedLocation.id,
      };
      try {
        const response = yield OTTO_V2_API.getStarRatingCount(params, isStandAlone);
        if (response.isCancel) return;
        self.reviewRatings = response;
        return response;
      } catch (err) {
        const errorMessage = apiError(err, 'Failed to load Reviews ratings') as string;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const updateTargetKeywords = flow(function* (id, uuid, payload) {
      self.loadingEditTargetKeyword = true;
      try {
        yield OTTO_V2_API.updateTargetKeyword(id, uuid, payload);
        notification.success('Success', 'Target Keywords updated successfully');
      } catch (err) {
        const errorMessage = apiError(err);
        notification.error('', errorMessage);
      } finally {
        self.loadingEditTargetKeyword = false;
      }
    });

    const aiGenerateReviewExample = flow(function* (payload, isStandAlone=false) {
      self.loadingReviewExample = true;
      try {
        const response = yield OTTO_V2_API.aiGenerateReviewExample(self.selectedLocation.id, payload, isStandAlone);
        if (response.isCancel) return;
        self.reviewExample = cast(response);
        self.loadingReviewExample = false;
      } catch (err) {
        self.loadingReviewExample = false;
        const errorMessage = apiError(err);
        notification.error('', errorMessage);
      }
    });

    const deleteGbpPost = flow(function* (id, isStandAlone=false) {
      self.deletingPost = id;
      try {
        yield OTTO_V2_API.deletePost(id, isStandAlone);
        // notification.success('', 'Post deleted successfully');
      } catch (error) {
        const errorMessage = apiError(error);
        notification.error('', errorMessage);
      } finally {
        self.deletingPost = null;
      }
    });
    const deleteIndexingSitemap = flow(function* (siteMapIds: number[]) {
      self.deletingSiteMap = true;
      try {
        const response = yield OTTO_V2_API.deleteIndexingSitemap(self.ottoV2Project.uuid, siteMapIds);
        if (response.isCancel) return;
        self.deletingSiteMap = false;
        notification.success('', 'Indexing Sitemap deleted successfully');
        return response;
      } catch (error) {
        self.deletingSiteMap = false;
        const errorMessage = apiError(error);
        notification.error('', errorMessage);
        return Promise.reject(error);
      }
    });
    const deleteCustomUrl = flow(function* (siteMapIds: number) {
      self.deletingCustomUrl = true;
      try {
        const response = yield OTTO_V2_API.deleteCustomUrl(self.ottoV2Project.uuid, siteMapIds);
        if (response.isCancel) return;
        self.deletingCustomUrl = false;
        notification.success('', 'Custom URL deleted successfully');
        return response;
      } catch (error) {
        self.deletingCustomUrl = false;
        const errorMessage = apiError(error);
        notification.error('', errorMessage);
        return Promise.reject(error);
      }
    });

    const ownersAnswerAiGenerate = flow(function* (id, reloadTable=false, standaloneTool=false) {
      self.generatingAiProposedFix = true;
      try {
        const response = yield OTTO_V2_API.ownersAnswerAiGenerate(id, standaloneTool);
        if (response.isCancel) return;
        self.deployingOttoUrls = reloadTable;
        self.generatingAiProposedFix = false;
        return response;
      } catch (error) {
        self.generatingAiProposedFix = false;
        const errorMessage = apiError(error);
        notification.error('', errorMessage);
      }
    });

    const buildOrDeployPressRelease = flow(function* (id, build, payload = {}, showNotification = true) {
      self.loadingPublishOrDeplayPress = id;
      try {
        let response;
        if (build) {
          response = yield OTTO_V2_API.buildPressReleaseContent(id);
        } else {
          response = yield OTTO_V2_API.publishPressReleaseContent(id, payload);
          localStorage.setItem('taskId', response?.taskId);
        }
        if (response.isCancel) return;
        if (build && showNotification) {
          notification.success('Success', `Press release Content Created successfully`);
        }
        return {success: true, response};
      } catch (err) {
        return Promise.reject(err);
      } finally {
        self.loadingPublishOrDeplayPress = null;
      }
    });

    const createPressRelease = flow(function* (type, payload, showNotification = true) {
      self.creatingPressReleaseProject = true;
      try {
        const response = yield OTTO_V2_API.createPressRelease(type, payload);
        if (showNotification) {
          notification.success('Success', 'Press release added successfully');
        }
        return {success: true, response};
      } catch (err) {
        self.creatingOttoProject = false;
        return Promise.reject(err);
      } finally {
        self.creatingPressReleaseProject = false;
      }
    });

    const getPressRelease = flow(function* (id) {
      self.gettingPressRelease = true;
      self.editableUrlRepolling = true;
      try {
        const response = yield OTTO_V2_API.getPressRelease(id);
        if (response.isCancel) return;
        self.editableUrl = response?.editableUrl;
        if ((response?.status === 'Generating' || response?.status === 'Generating') && !response?.editableUrl && self.isAddPrModalVisible) {
          yield new Promise(r => setTimeout(r, 5000));
          return getPressRelease(id);
        } else {
          self.editableUrlRepolling = false;
        }
        return response;
      } catch (err) {
        return Promise.reject(err);
      } finally {
        self.gettingPressRelease = false;
        self.editableUrlRepolling = false;
      }
    });

    const getDistributionChannels = flow(function* () {
      self.loadingDistribution = true;
      try {
        const response = yield OTTO_V2_API.getDistributionChannels();
        if (response.isCancel) return;
        self.distributionChannels = response;
      } catch (err) {
        const errorMessage = apiError(err) as string;
        notification.error('', errorMessage);
      } finally {
        self.loadingDistribution = false;
      }
    });

    const getCategories = flow(function* () {
      try {
        const response = yield OTTO_V2_API.getCategories();
        if (response.isCancel) return;
        self.categories = response;
      } catch (err) {
        const errorMessage = apiError(err) as string;
        notification.error('', errorMessage);
      }
    });

    const getDistributionAgainstUrl = flow(function* (id) {
      try {
        const response = yield OTTO_V2_API.getDistributionAgainstUrl(id);
        if (response.isCancel) return;
        self.distributionAgainstUrl = response;
      } catch (err) {
        const errorMessage = apiError(err) as string;
        notification.error('', errorMessage);
      }
    });

    const getSummaryData = flow(function* ({taskId, generatingPost, generatingBulkImages}) {
      try {
        const response = yield OTTO_V2_API.getSummaryData(taskId);
        if (response.isCancel) return;
        if (response?.displayableStatus === 'PROCESSING') {
          self.loadingWorkSummary = true;
          yield new Promise(r => setTimeout(r, 5000));
          return getSummaryData({taskId, generatingPost, generatingBulkImages});
        } else if (response?.displayableStatus === 'SUCCESS') {
          self.loadingWorkSummary = false;
          if (generatingPost) {
            self.generatedBulkPost = cast(response?.result?.successes);
            self.bulkPostSettings = cast(response?.result?.settings?.settings);
            return true;
          } else if (generatingBulkImages) {
            return response;
          } else {
            notification.success('Success', 'Work summary exported successfully!');
            yield new Promise(r => setTimeout(r, 1200));
            openUrl(response?.result, '_blank');
          }
        } else if (response?.displayableStatus === 'FAILED' && response?.errorMessage) {
          self.loadingWorkSummary = false;
          const errorMessage = response?.errorMessage;
          notification.error('', `${errorMessage}`);
          return false;
        }
      } catch (err) {
        self.loadingWorkSummary = false;
        const errorMessage = apiError(err, '') as string;
        notification.error('', errorMessage);
        return Promise.reject(err);
      }
    });

    const getExportTaskId = flow(function* (uuid) {
      self.loadingWorkSummary = true;
      try {
        const response = yield OTTO_V2_API.getExportTaskId(uuid);
        if (response.isCancel) return;
        if (response && response?.taskId) {
          getSummaryData({taskId: response.taskId});
        } else {
          self.loadingWorkSummary = false;
        }
      } catch (err) {
        self.loadingWorkSummary = false;
        const errorMessage = apiError(err) as string;
        notification.error('', errorMessage);
      }
    });

    const enableSitemap = flow(function* (uuid, payload) {
      try {
        yield OTTO_V2_API.enableSitemap(uuid, payload);
      } catch (err) {
        const errorMessage = apiError(err) as string;
        notification.error('', errorMessage);
      }
    });

    const enableUrlIndexing = flow(function* (payload) {
      try {
        yield OTTO_V2_API.enableUrlIndexing(payload);
      } catch (err) {
        const errorMessage = apiError(err) as string;
        notification.error('', errorMessage);
      }
    });

    const createIndexingUrl = flow(function* (payload) {
      try {
        yield OTTO_V2_API.createIndexingUrl(payload);
      } catch (err) {
        const errorMessage = apiError(err) as string;
        notification.error('', errorMessage);
      }
    });

    const createIndexSitemap = flow(function* (payload) {
      try {
        yield OTTO_V2_API.createIndexSitemap(self.ottoV2Project.uuid, payload);
        notification.success('Success', `Sitemap added successfully`);
      } catch (err) {
        const errorMessage = apiError(err) as string;
        notification.error('', errorMessage);
      }
    });

    const activateIndexing = flow(function* (id) {
      try {
        const res = yield OTTO_V2_API.activateIndexing(id);
        return res;
      } catch (err) {
        const errorMessage = apiError(err) as string;
        notification.error('', errorMessage);
      }
    });

    const getGscEmailStatus = flow(function* (uuid) {
      try {
        const res = yield OTTO_V2_API.getGscEmailStatus(uuid);
        self.hasAccess = res?.hasAccess || false;
        return res;
      } catch (err) {
        const errorMessage = apiError(err) as string;
        if (err?.response?.status != 404) notification.error('', errorMessage);
      }
    });


    const freezeOttoProject = flow(function* (uuid) {
      try {
        yield OTTO_V2_API.freezeOttoProject(uuid);
        const response = yield loadOttoV2Project(uuid);
        if (response?.deepFreezeTask?.status == 'FAILURE') {
          const errorMessage = response?.deepFreezeTask?.errorMessage?.split('details: ')[1] ?? response?.deepFreezeTask?.errorMessage;
          notification.error('Failure', errorMessage);
          return;
        }
        notification.success('Success', 'The website has been successfully frozen');
      } catch (err) {
        const errorMessage = apiError(err) as string;
        notification.error('', errorMessage);
      }
    });

    const getIndexingGraphKeys = flow(function* (uuid, url) {
      try {
        const response = yield OTTO_V2_API.getIndexingGraphKeys({
          otto_project_uuid: uuid,
          sitemap_url: url,
        });
        if (response.isCancel) return;
        return response;
      } catch (err) {
        const errorMessage = apiError(err) as string;
        notification.error('', errorMessage);
      }
    });

    const unfreezeOttoProject = flow(function* (uuid) {
      self.unfreezeLoading = true;
      try {
        yield OTTO_V2_API.unfreezeOttoProject(uuid);
        getBlockedQuotas();
        notification.success('Success', 'The website has been successfully unfreezed');
        yield loadOttoV2Project(uuid);
      } catch (err) {
        const errorMessage = apiError(err) as string;
        notification.error('', errorMessage);
      } finally {
        self.unfreezeLoading = false;
      }
    });

    const getSelectedSiteMapUrls = flow(function* (uuid, url, page, pageSize, statusFilter='') {
      try {
        const response = yield OTTO_V2_API.getSelectedSiteMapUrls({
          otto_project_uuid: uuid,
          sitemap_url: url,
          ordering: '-last_sent_to_indexer_at',
          page: page,
          page_size: pageSize,
          ...(statusFilter && {status: statusFilter}),
        });
        if (response.isCancel) return;
        return response;
      } catch (err) {
        const errorMessage = apiError(err) as string;
        notification.error('', errorMessage);
      }
    });

    const getPressReleaseData = flow(function* (params) {
      try {
        const response = yield OTTO_V2_API.getPressReleaseTableData(params);
        return response;
      } catch (err) {
        const errorMessage = apiError(err) as string;
        notification.error('', errorMessage);
      }
    });

    const restOttoUrls = () => {
      self.ottoUrls = cast([]);
    };

    const setSelectedCategory = (category: string) => {
      if (self.selectedCategory !== 'gbp_optimizations' && category !== self.selectedCategory) {
        if (category == 'unanswered_reviews') {
          setActiveReviewsTab('all');
        } else if (category == 'qanda_suggestions') {
          setActiveQuestionsTab('all');
        }
      }
      self.selectedCategory = category;
    };

    const getBlockedQuotas = flow(function* () {
      try {
        const response = yield OTTO_V2_API.getBlockedQuotas();
        self.blockedQuotas = response;
        return response;
      } catch (err) {
        const errorMessage = apiError(err) as string;
        notification.error('', errorMessage);
      }
    });

    const setSelectedIssue = (issue: string) => {
      self.selectedIssue = issue;
    };

    const setSelectedReviewFilters = (objectVal: any) => {
      self.selectedReviewFilters = objectVal;
    };

    const setLoadingDetail = (loading: boolean) => {
      self.loadingDetail = loading;
    };

    const setDeployingOttoUrls = (loading: boolean) => {
      self.deployingOttoUrls = loading;
    };

    const setAIGenerateBtnClick = (value: boolean) => {
      self.aIGenerateState = value;
    };

    const setIssueTypeArray = (array: string[]) => {
      self.issueTypeArray = cast(array);
    };


    const setIsDeploying = (loading: boolean) => {
      self.isDeploying = loading;
    };

    const setDeployRollBackAll = (loading: boolean) => {
      self.deployRollBackAll = loading;
    };

    const setGbpTableParams = (value: any) => {
      self.gbpTableParams = value;
    };

    const resetOttoV2Project = value => self.ottoV2Project = value;

    const setIssueTypeSelected = (issueType: string) => {
      self.issueTypeSelected = issueType;
    };

    const setAllDeployed = (value: boolean) => {
      self.allDeployed = value;
    };
    const setGbpLocationDetail = (value: any) => {
      self.gbpLocationDetail = value;
    };

    const setDefaultParams = (obj, noAPICall?: boolean, stopLoading?: boolean, isLoadmore = false) => {
      self.defaultParams.page = obj.page;
      self.defaultParams.pageSize = obj.pageSize;
      self.defaultParams.search = obj.search;
      if (!noAPICall) {
        loadOttoV2Projects(stopLoading, isLoadmore);
      }
    };
    const onEditQA = (itemIndex: number, previousDate?: string, newValue?: string, change?: 'question-edit' | 'answer-edit' | 'date-edit' | 'delete' | 'add') => {
      self.editingQA = true;
      try {
        const array = [...self.generatedBulkQA];
        if (change === 'add' && itemIndex === 0) {
          array.push({
            question: newValue,
            answer: '',
            date: moment().format('YYYY-MM-DD'),
          });
        } else if (change === 'date-edit' && previousDate) {
          const index = array.findIndex((obj, index) => (obj?.date === previousDate) && (index === itemIndex));
          if (index !== -1) {
            array[index]['date'] = newValue;
          }
        } else if (change === 'question-edit') {
          const index = array.findIndex((obj, index) => index === itemIndex);
          if (index !== -1) {
            array[index]['question'] = newValue;
          }
        } else if (change === 'answer-edit') {
          const index = array.findIndex((obj, index) => index === itemIndex);
          if (index !== -1) {
            array[index]['answer'] = newValue;
          }
        } else if (change === 'delete' && itemIndex >= 0 && itemIndex < array?.length) {
          array.splice(itemIndex, 1);
        }
        self.generatedBulkQA = cast(array);
        self.editingQA = false;
      } catch (error) {
        self.editingQA = false;
        notification.error('Error', 'Something went wrong please try again.');
      }
    };

    const createTopicalMapBulkArticles = flow(function* (key, payload) {
      try {
        const response = yield OTTO_V2_API.createTopicalMapBulkArticles(key, payload);
        if (response?.isCancel) return;
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const createTopicalMapBulkOutlines = flow(function* (key, payload) {
      try {
        const response = yield OTTO_V2_API.createTopicalMapBulkOutlines(key, payload);
        if (response?.isCancel) return;
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const synchronizeReviews = flow(function* () {
      setSyncLoader(true);
      try {
        if (self.selectedLocation?.id) {
          const response = yield OTTO_V2_API.synchronizeReviews(self.selectedLocation?.id);
          const taskId = response.taskId;
          if (taskId) {
            getTaskResults(taskId);
            localStorage.setItem('reviewSyncTaskId', taskId);
          }
        }
      } catch (err) {
        notification.error('Synchronization Failed', 'Reviews were synchronization failed');
        setSyncLoader(false);
        return Promise.reject(err);
      }
    });

    const getTaskResults = flow(function* (taskId) {
      try {
        const status = yield OTTO_V2_API.taskResults(taskId);
        if (status && status.status == 'SUCCESS') {
          localStorage.removeItem('reviewSyncTaskId');
          notification.success('Synchronization Successful', 'Reviews were synchronized successfully');
          setLoadIssueTableData(true);
        } else if (status && status.status == 'PENDING') {
          yield new Promise(resolve => setTimeout(resolve, 3000));
          yield getTaskResults(taskId);
        } else {
          notification.error('Synchronization Failed', 'Reviews were synchronization failed');
          localStorage.removeItem('reviewSyncTaskId');
        }
      } catch {
        notification.error('Synchronization Failed', 'Reviews were synchronization failed');
      } finally {
        setSyncLoader(false);
      }
    });


    const updateQandALocationSettings = flow(function* (locationId, payload, isStandalone = false) {
      try {
        yield OTTO_V2_API.updateQandALocationSettings(locationId, payload, isStandalone);
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const getQandALocationSettings = flow(function* (locationId, showLoading = true, isStandalone = false) {
      if (showLoading) {
        self.qAndASettingsLoading = true;
      }
      try {
        const response = yield OTTO_V2_API.getQandALocationSettings(locationId, isStandalone);
        self.qAndASettings = response;
        return response;
      } catch (err) {
        const errorMessage = apiError(err);
        notification.error('', errorMessage);
      } finally {
        self.qAndASettingsLoading = false;
      }
    });

    const getGbpAutomatedPostSettings = flow(function* (isStandAlone=false, locationId=null) {
      try {
        const response = yield OTTO_V2_API.getGbpAutomatedPostSettings(locationId ?? self.selectedLocation?.id, isStandAlone);
        if (response.isCancel) return;
        self.gbpAutomatedPostSettings = cast(response);
        return response;
      } catch (err) {
        const errorMessage = apiError(err);
        notification.error(errorMessage, '');
      }
    });

    const setGbpAutomationValue = value => {
      self.gbpAutomatedPostSettings = toJS(value);
    };

    const getGbpMediaLibraryImages = flow(function* (params: {page: number; pageSize: number}, loading?: boolean, businessLocation?: string, isStandAlone?: boolean) {
      if (loading) {
        self.loadingGBPMedia = true;
      }
      const convertedParams = convertKeysToSnakeCase(params);
      let location = null;
      if (businessLocation) {
        location = Number(businessLocation);
      } else {
        location = self.selectedLocation?.id;
      }
      try {
        const response = yield OTTO_V2_API.getGbpMediaLibraryImages(location, convertedParams, isStandAlone);
        if (response.isCancel) return;
        self.gbpMediaLibrary = cast(response);
        self.loadingGBPMedia = false;
        return response;
      } catch (err) {
        self.loadingGBPMedia = false;
        const errorMessage = apiError(err);
        notification.error(errorMessage, '');
        return Promise.reject(err);
      }
    });

    const uploadImageToGbpMediaLibrary = flow(function* (payload, isStandAlone = false, locationId=null, isCancelMultiple=false) {
      payload['location'] = locationId ?? self.selectedLocation?.id;
      try {
        const response = yield OTTO_V2_API.uploadImageToGbpMediaLibrary(payload, isStandAlone, isCancelMultiple);
        if (response.isCancel && isCancelMultiple) return;
        return response;
      } catch (err) {
        const errorMessage = apiError(err);
        notification.error(errorMessage, '');
        return Promise.reject(err);
      }
    });

    const deleteImageToGbpMediaLibrary = flow(function* (id, isStandAlone = false) {
      try {
        const response = yield OTTO_V2_API.deleteImageToGbpMediaLibrary(id, isStandAlone);
        return response;
      } catch (err) {
        const errorMessage = apiError(err);
        notification.error(errorMessage, '');
        return Promise.reject(err);
      }
    });

    const updatedGbpAutomatedPostSettings = flow(function* (payload, isStandAlone = false, locationId=null) {
      try {
        const response = yield OTTO_V2_API.updatedGbpAutomatedPostSettings(locationId ?? self.selectedLocation?.id, payload, isStandAlone);
        self.gbpAutomatedPostSettings = cast(response);
        return response;
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const getGenesysApiKey = flow(function* () {
      try {
        const response = yield OTTO_V2_API.getGenesysApiKey(self.ottoV2Project.uuid);
        self.genesysApiKey = cast(response?.apiKey);
      } catch (err) {
        Promise.resolve(err);
      }
    });

    const postGenesysApiKey = flow(function* (payload) {
      if (self.ottoV2Project.uuid) {
        payload['otto_project'] = self.ottoV2Project.uuid;
      }

      try {
        const response = yield OTTO_V2_API.postGenesysApiKey(payload);
        self.genesysApiKey = cast(response?.apiKey);
        notification.success('SUCCESS', 'API key added successfully');
      } catch (err) {
        const errorMessage = apiError(err);
        notification.error(errorMessage, '');
      }
    });


    const updateGenesysApiKey = flow(function* (payload) {
      try {
        const response = yield OTTO_V2_API.updateGenesysApiKey(self.ottoV2Project.uuid, payload);
        self.genesysApiKey = cast(response?.apiKey);
        notification.success('SUCCESS', 'API key updated successfully');
      } catch (err) {
        const errorMessage = apiError(err);
        notification.error(errorMessage, '');
      }
    });

    const resetReviewState = () => self.reviewExample = null;
    const setDeployingProposedFix = (value: boolean) => self.deployingProposedFix = value;
    const setGeneratingAiProposedFix = (value: boolean) => self.generatingAiProposedFix = value;

    const setActiveKeys = (value: any) => {
      self.isActiveKeys = value;
    };

    const getStatusKeyValue = () => {
      return self.deployStatusKey;
    };

    const setStatusKeyValue = (value: string) => {
      self.deployStatusKey = value;
    };

    const setIsDeployStatus = (value: string) => {
      self.isDeployStatus = value;
    };

    const setReplyKeyValue = (value: string) => {
      self.deployReplyKey = value;
    };


    const setSelectedLocation = (val: any, setPageToOne?: boolean, isV2 = false) => {
      self.selectedLocation = val;
      if (!isV2 && self.selectedIssueTypeObj['issue_type']) {
        const updatedIssueTypeObj = {...self.selectedIssueTypeObj, page: 1};
        loadIssueTableData(((setPageToOne ? updatedIssueTypeObj : self.selectedIssueTypeObj) as any), false, false, null, isV2);
      }
    };

    const getSelectedLocation = () => {
      return self.selectedLocation;
    };

    const setOpenGenerateQAModal = value => {
      self.openGenerateQAModal = value;
    };

    const setActiveReviewsTab = (activeTab: string) => {
      self.activeReviewsTab = activeTab;
    };

    const setActiveReviewsKey = (activeKey: number) => {
      self.activeReviewsKey = activeKey;
    };

    const setNavigatedFromGbpOptimizations = value => {
      self.navigatedFromGbpOptimizations = value;
    };

    const setActiveQuestionsTab = (activeTab: string) => {
      self.activeQuestionsTab = activeTab;
    };

    const setActiveQuestionsKey = (activeKey: number) => {
      self.activeQuestionsKey = activeKey;
    };

    const setLoadIssueTableData = (state: boolean) => {
      self.syncLoad = state;
    };

    const setSyncLoader = (state: boolean) => {
      self.syncLoader = state;
    };

    const updatePRDistributionFilters = filters => {
      self.prDistributionFilters = cast(filters);
    };

    const resetPRDistributionFilters = () => {
      self.prDistributionFilters = cast(pressReleaseDistributionFilters());
    };

    const setMyTasksLocations = locations => {
      if (locations) self.myTasksLocations = locations;
    };

    const setCategoriesLength = value => {
      self.categoriesLength = value;
    };

    const getMyTasks = flow(function* (pageNumber = null, isStandAlone = false, taskLoader = false, filterType, isV2) {
      self.loadingTasks = taskLoader;
      const params = {
        'location__in': isV2 ? self.selectedBusiness?.[0]?.id : self.myTasksLocations?.map(item => item.id)?.join(','),
        'meta_include': 'posts,questions,reviews,locations',
        'page_number': pageNumber,
      };
      if (!filterType) {
        params['is_deleted']= false;
      }
      const response = yield OTTO_V2_API.getMyTasksV2(params, isStandAlone);
      if (response?.isCancel) return;
      self.loadingTasks = false;
      return response;
    });

    const setBusinessesList = value => {
      self.businessesList = value;
    };

    const setSelectedStatus = value =>{
      self.selectedStatusList= value;
    };
    const setSelectedBusiness = value => {
      const uniqueBusinesses = Array.from(new Set(value?.map(business => business?.id)))
        .map(id => value?.find(business => business?.id === id));
      self.selectedBusiness = uniqueBusinesses;
    };

    const setSelectedGbpProject = value => self.selectedGbpProject = value;

    const getBusiness = flow(function* (id) {
      try {
        const params = {
          location__in: id,
          used_in_standalone: true,
          include: 'stats',
        };
        const response = yield OTTO_V2_API.getProjectListing(params, true, false);
        if (response?.isCancel) return;
        if (!response?.data?.filter(item => !item.attributes.isDeleted)?.length) {
          self.selectedBusiness = [];
          notification.error('The Selected Business has been Deactivated', '');
          setTimeout(() => {
            Router.push(`/gbp-galactic/gbp-projects`);
          });
        } else {
          self.selectedBusiness = response?.data?.map(item => ({...item.attributes, id: item.id}));
        }
      } catch (e) {
        return Promise.reject(e);
      }
    });

    const getAllBusinesses = flow(function* (setBusiness = false, loadMore = false, search=null) {
      try {
        const params = {
          page_number: loadMore ? (self.businessesList?.meta?.pagination?.page + 1) : 1,
          page_length: 25,
          used_in_standalone: true,
          is_deleted: false,
          include: 'stats',
        };
        if (search) {
          params['search'] = search;
        }
        const response = yield OTTO_V2_API.getProjectListing(params, true);
        if (response?.isCancel) return;
        self.businessesList = loadMore ? {...response, data: [...self.businessesList.data, ...response.data]} : response;
        if (setBusiness) {
          self.selectedBusiness = [{...response?.data?.[0]?.attributes, id: response.data?.[0]?.id}];
        }
      } catch (e) {
        return Promise.reject(e);
      }
    });

    const getCalenderData = flow(function* (locationId, startDate, endDate) {
      try {
        const params = {
          start_date: startDate,
          end_date: endDate,
        };
        const response = yield OTTO_V2_API.getCalenderData(locationId, params);
        return response;
      } catch (e) {
        return Promise.reject(e);
      }
    });

    const handleNewChangesInGbp = value => {
      self.newChanges = value;
    };

    const getOptimizationData = flow(function* (location, isStandAlone) {
      try {
        const response = yield OTTO_V2_API.getOptimizationsV2(location, isStandAlone);
        if (response?.isCancel) return;
        self.optimizationData = response;
      } catch (e) {
        return Promise.reject(e);
      }
    });

    const setCalendarPostDateFilter = (value: any) => {
      self.calendarPostDateFilter = value;
    };

    return {
      setOptimizationDataLoader,
      resetAggregatedStats,
      getOptimizationData,
      getAllBusinesses,
      getBusiness,
      setBusinessesList,
      setSelectedStatus,
      setSelectedBusiness,
      setSelectedLocation,
      getSelectedLocation,
      resetOtto,
      setGbpLocationDetail,
      resetPRDistributionFilters,
      setActiveKeys,
      setReplyKeyValue,
      loadOttoV2Projects,
      loadGBPLocationDetail,
      setSelectedReviewFilters,
      loadOttoV2Project,
      loadIssueTableData,
      restOttoUrls,
      setSelectedCategory,
      setLoadingDetail,
      setDeployingOttoUrls,
      setAIGenerateBtnClick,
      deployOttoUrls,
      setIssueTypeArray,
      setSelectedIssue,
      deployProposedFix,
      updateProposeFix,
      generateAiProposedFix,
      createOttoProject,
      deleteOttoProject,
      setAutoPilot,
      checkScriptFromUrl,
      setIsDeploying,
      setDeployRollBackAll,
      setGbpTableParams,
      checkScriptFromInstance,
      getGSCVerificationScript,
      deployImageAltText,
      deploySubOttoUrls,
      topicalMapUpdateArticle,
      deployOttoGBPPosts,
      publishGPBQuestion,
      unPublishGPBQuestion,
      createOttoTopicalMap,
      updateOttoTopicalMap,
      deleteOttoTopicalMap,
      updateTopicalMapKeyword,
      getOttoTopicalMapsData,
      editPostContent,
      updateGBPQuestion,
      updateGBPOwnersAnswer,
      updateOttoV2Project,
      setProjectListShouldRepoll,
      aiEnhanceGPBQuestion,
      aiGenerateGPBOwnersAnswer,
      storeSelectedTitles,
      unApproveOttoGBPPosts,
      publishOttoGBPPosts,
      unPublishOttoGBPPosts,
      publishOttoGBPPost,
      unPublishOttoGBPPost,
      getOttoGBPAiGenerateContent,
      getOttoGBPAiGenerateImage,
      createOttoGBPPost,
      ottoGBPUploadImage,
      publishReview,
      unPublishReview,
      editOttoGBPReview,
      aiGenerateGBPReview,
      editOttoGBPPost,
      connectGBPOtto,
      addQuestionAnswer,
      ottoQAAiGeneratedAnswer,
      loadCloudStacksProviders,
      createABProject,
      updateABProject,
      buildOrDeployCloudStack,
      resetOttoV2Project,
      setCloudStackProjectShouldRepoll,
      setTopupHyperDrive,
      setLandingPageDetailShouldRepoll,
      setCloudStackCurrentPage,
      addPage,
      setIssueTypeSelected,
      addSourcePage,
      deleteUrlIssue,
      refreshCloudDeployInfo,
      generateLandingPage,
      setTopicalMapsShouldRepoll,
      setGbpShouldRepoll,
      setAiShouldRepoll,
      setNlpFaqShouldRepoll,
      setNlpTermsShouldRepoll,
      setOttoSearchTerm,
      editKBTAnswer,
      gscSiteVerificationScriptVerification,
      refreshGSCSiteVerificationScript,
      getKnowledgeGraphFieldsMeta,
      questionBulkAiGenerate,
      generateQAContent,
      onEditQA,
      ottoBulkImportPostOrQuestion,
      setGbpLocationRepoll,
      editCustomHtml,
      getKnowledgeGraphData,
      setDefaultParams,
      deleteCloudStackContent,
      createKnowledgeGraph,
      updateKnowledgeGraph,
      ottoQuotaTopup,
      getOttoQuotaAllocations,
      getOttoPlanQuotaAllocations,
      getTaskResult,
      setAllDeployed,
      setReviewSettings,
      loadReviewSettings,
      getStarRatingCount,
      updateTargetKeywords,
      setDeployingProposedFix,
      setGeneratingAiProposedFix,
      deleteQASuggestion,
      setIsKnowledgeModalVisible,
      aiGenerateReviewExample,
      resetReviewState,
      deleteGbpPost,
      setSearchText,
      ownersAnswerAiGenerate,
      buildOrDeployPressRelease,
      setPressReleaseCurrentPage,
      createPressRelease,
      getDistributionChannels,
      getCategories,
      getStatusKeyValue,
      setStatusKeyValue,
      getExportTaskId,
      postBulkAiGenerate,
      setGeneratedBulkPost,
      postBulkCreate,
      getDistributionAgainstUrl,
      addBulkPage,
      addRemoveAllPages,
      removeBulkPage,
      editBulkPage,
      createBulkPages,
      setCreatingPRContent,
      enableSitemap,
      enableUrlIndexing,
      createIndexingUrl,
      activateIndexing,
      getGscEmailStatus,
      createTopicalMapBulkArticles,
      createTopicalMapBulkOutlines,
      updateOTTOSettings,
      deleteLandingPage,
      editLandingPage,
      getCitySuggestions,
      getLandingPageDetail,
      setOpenGenerateQAModal,
      bulkGenerateAiProposedFix,
      setBulkPostSettings,
      generateOttoGBPAiBulkImage,
      setActiveReviewsTab,
      setActiveReviewsKey,
      publishLandingPageToWp,
      freezeOttoProject,
      getGBPLocations,
      manageGBPLocations,
      unfreezeOttoProject,
      getIndexingGraphKeys,
      getSelectedSiteMapUrls,
      setNlpPagination,
      rebuildLandingPage,
      setLandingPageDetail,
      setNavigatedFromGbpOptimizations,
      setOttoIdForGscSettings,
      resetGBPStandAlone,
      resetDistributionAgainstUrl,
      setActiveQuestionsTab,
      setActiveQuestionsKey,
      setLoadIssueTableData,
      synchronizeReviews,
      getTaskResults,
      setOttoProjectNotFoundInRb,
      setLoadingProject,
      setSyncLoader,
      updateQandALocationSettings,
      getQandALocationSettings,
      deleteIndexingSitemap,
      createIndexSitemap,
      getPressReleaseData,
      updatePRDistributionFilters,
      setIsAddPrModalVisible,
      getGbpAutomatedPostSettings,
      updatedGbpAutomatedPostSettings,
      getGbpMediaLibraryImages,
      uploadImageToGbpMediaLibrary,
      deleteImageToGbpMediaLibrary,
      setMyTasksLocations,
      getMyTasks,
      getAllGBPLocations,
      findPriceByRange,
      getPressRelease,
      setPressReleaseCurrentPageSize,
      resetKnowledgeGraph,
      getGenesysApiKey,
      postGenesysApiKey,
      updateGenesysApiKey,
      setGenesysApiKey,
      ottoBulkImportOptimization,
      setCategoriesLength,
      getBlockedQuotas,
      getPaymentTopups,
      setSelectedGbpProject,
      deleteCustomUrl,
      setIsCompliantChecked,
      setActiveTab,
      handleNewChangesInGbp,
      setGbpAutomationValue,
      getCalenderData,
      resetOptimizationData,
      setCalendarPostDateFilter,
      setIsDeployStatus,
    };
  });

export const initOttoV2Store = () => {
  return OttoV2Store.create({
    selectedLocation: {},
    activeTab: 'All',
    loadingProject: false,
    loadingProjects: false,
    loadingDetail: false,
    deployingOttoUrls: false,
    aIGenerateState: false,
    isDeployStatus: false,
    deployStatusKey: null,
    deployReplyKey: null,
    deployIssueType: '',
    searchTextValue: '',
    isDeploying: false,
    deployRollBackAll: false,
    gbpTableParams: {
      page: 1,
      pageSize: null,
    },
    ottoV2Projects: {
      count: null,
      next: '',
      pageSize: null,
      previous: null,
      results: [],
    },
    ottoUrls: [],
    ottoUrlLoader: false,
    selectedCategory: '',
    issueTypeArray: [],
    selectedIssue: '',
    selectedReviewFilters: {
      star_rating__in: null,
      is_replied: null,
      reply__status__in: null,
    },
    issueTypeSelected: '',
    deployingProposedFix: false,
    generatingAiProposedFix: false,
    creatingOttoProject: false,
    creatingCloudStackProject: false,
    scriptStatusLoading: false,
    gscScriptStatusLoading: false,
    loadGBPPostsLoading: false,
    projectListShouldRepoll: false,
    storingSelectedTitles: false,
    creatingTopicalMap: false,
    deletingTopicalMap: false,
    loadingTopicalMapsList: false,
    connectingGbp: false,
    cloudStackProjectShouldRepoll: false,
    landingPageDetailShouldRepoll: false,
    rebuildingLandingPage: false,
    topicalMapsShouldRepoll: false,
    gbpShouldRepoll: false,
    aiShouldRepoll: false,
    nlpFaqShouldRepoll: false,
    nlpTermsShouldRepoll: false,
    cloudStackCurrentPage: 1,
    ottoSearchTerm: '',
    refreshingBulkGBPData: false,
    gbpLocationRepoll: false,
    loadingAutoPilot: false,
    editingCustomHtml: false,
    loadingKnowledgeGraph: true,
    deploying: false,
    defaultParams: {
      pageSize: 20,
      page: 1,
      search: '',
    },
    loadingOttoQuotaTopup: false,
    ottoQuotaAllocationsLoading: false,
    allDeployed: false,
    loadingEditTargetKeyword: false,
    generatedBulkQA: [],
    loadingGenerateBulkQA: false,
    addingQA: false,
    editingQA: false,
    isKnowledgeModalVisible: false,
    loadingReviewExample: false,
    deletingPost: null,
    searchText: '',
    quotaAllocationsLoading: false,
    loadingPublishOrDeplayPress: null,
    pressReleaseCurrentPage: 1,
    creatingPressReleaseProject: false,
    loadmoreLoading: false,
    loadingWorkSummary: false,
    loadingDistribution: false,
    bulkPages: [],
    creatingBulkPages: false,
    creatingPRContent: false,
    openGenerateQAModal: false,
    updatingOTTOSettings: false,
    activeReviewsTab: '',
    activeReviewsKey: null,
    loadingGBPLocations: false,
    managingGBPLocations: false,
    unfreezeLoading: false,
    nlpPage: 1,
    nlpPageSize: 10,
    isTopUpHyperDrive: false,
    navigatedFromGbpOptimizations: false,
    ottoIdForGscSettings: '',
    activeQuestionsTab: '',
    activeQuestionsKey: null,
    syncLoad: false,
    ottoProjectNotFoundInRb: false,
    syncLoader: false,
    prDistributionFilters: pressReleaseDistributionFilters(),
    isAddPrModalVisible: false,
    gbpLocationDetail: null,
    qAndASettingsLoading: false,
    loadingGBPMedia: false,
    myTasksLocations: [],
    loadingAllGBPLocations: false,
    gettingPressRelease: false,
    editableUrlRepolling: false,
    editableUrl: null,
    pressReleaseCurrentPageSize: 10,
    refreshingBulkGBPOptimization: false,
    loadingTasks: false,
    selectedGbpProject: {},
    deletingCustomUrl: false,
    selectedBusiness: [],
    isCompliantChecked: false,
    newChanges: false,
    optimizationData: null,
    selectedStatusList: null,
    calendarPostDateFilter: {dateFilters: gbpPostInitialDateFilter},
  });
};
