import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Dropdown, Input, Menu, Spin, Tooltip} from 'antd';
import {useStore} from '@/store/root-store';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash} from '@fortawesome/pro-regular-svg-icons';
import {faCircle, faStore} from '@fortawesome/pro-solid-svg-icons';
import {observer} from 'mobx-react';
import {faBoxArchive} from '@fortawesome/pro-duotone-svg-icons';
import {faMagnifyingGlass, faCircleDot} from '@fortawesome/pro-light-svg-icons';
import {Button as ViewBtton, Table, SkeletonHorizontalLoaderGray} from '@/components/common-components/components';
import {PaginationStyled} from '../otto-page-detail/style';
import {DeleteConfirmModal} from '../../gsc/gsc/PageGroupings/blocks/Groups/DeleteConfirm';
import {ArchivedSitesModal} from './archivedSitesModal';
import {OTTO_V2_API} from '@/api/otto-v2';
import {notification} from '@/utils/notification-v2';
import {apiError} from '@/utils/api';
import useWindowSize from '@/utils/hooks/useWindowSize';
import {addProtocolToDomain, findValidDomain, isValidDomain} from '@/utils/url';
import {useRouter} from 'next/router';
import {convertMinutesIntoReadAbleTime} from '@/utils/moment';
import moment from 'moment';
import {LoadingOutlined, MoreOutlined} from '@ant-design/icons';
import styles from './styles.module.scss';
import {debounce} from 'lodash';

interface Props {
  setUuid: (val: string) => void;
  setActiveTab: (val: number) => void;
  setIsWidgetModalVisible: (val: boolean) => void;
  setInstructionsData: any;
  setIsInstructionModalVisible: (val: boolean) => void;
  setConnectGSCLoader: (val: number) => void;
  connectGSCLoader: number;
  setSelectedConnectingProject: any;
  setConnectGMBLoader: (val: number) => void;
  setShouldOpenGBP: (val: boolean) => void;
  connectGMBLoader: number;
  isWidgetModalVisible: boolean;
  isInstructionModalVisible: boolean;
  gbpModalVisible: boolean;
  setIsSearch?: React.Dispatch<React.SetStateAction<boolean>>;
  isInHome?: boolean;
}

export const getPixelStateAsBoolean = pixelState => {
  switch (pixelState) {
    case 'installed':
      return true;
    case 'wp_plugin':
      return true;
    default:
      return false;
  }
};

export const getPixelErrorMessage = pixelState => {
  switch (pixelState) {
    case 'wrong_uuid':
      return 'Wrong UUID';
    case 'not_installed':
      return 'Not installed';
    case 'wp_plugin_disabled':
      return 'Disabled (WP)';
    default:
      return 'Not installed';
  }
};

export const ProjectsTable = observer(({
  setUuid,
  setActiveTab,
  setIsWidgetModalVisible,
  setInstructionsData,
  setIsInstructionModalVisible,
  setConnectGSCLoader,
  connectGSCLoader,
  setSelectedConnectingProject,
  setConnectGMBLoader,
  setShouldOpenGBP,
  connectGMBLoader,
  isWidgetModalVisible,
  isInstructionModalVisible,
  gbpModalVisible,
  setIsSearch,
  isInHome,
}: Props) => {
  const {
    ottoStore: {
      setDomainLoading,
    },
    ottoV2Store: {
      getOttoV2ProjectsList,
      loadingProjects,
      setDefaultParams,
      defaultParams,
      deleteOttoProject,
      resetOttoV2Project,
    },
    siteAuditorV2: {
      updateSelectedSiteSA,
      resetAuditedSiteDetail,
      resetDetectedCms,
    },
    settings: {customer: {getCustomerQuota, profile: {whitelabelOtto}}},
  } = useStore('');
  const router = useRouter();

  const [archivedOttoProjects, setArchivedOttoProjects] = useState({
    results: [],
  });
  const [sitesList, setSitesList] = useState([]);
  const [archiveVisible, setArchiveVisible] = useState(false);
  const [loadingArchivedOttoProjects, setLoadingArchivedOttoProjects] = useState(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeletePageModalVisible, setIsDeletePageModalVisible] = useState(false);

  const loadingArray = [null, 'PENDING', 'STARTED'];
  const {width} = useWindowSize();

  // useEffect(() => {
  //   getArchivedOttoProjects();
  // }, []);

  useEffect(() => {
    if (!loadingProjects && getOttoV2ProjectsList?.results?.length) {
      setSitesList(getOttoV2ProjectsList?.results);
    } else if (!getOttoV2ProjectsList?.results?.length) {
      setSitesList([]);
    }
  }, [JSON.stringify(getOttoV2ProjectsList?.results)]);

  const getArchivedOttoProjects = async () => {
    setLoadingArchivedOttoProjects(true);
    try {
      const params = {
        page_size: 100,
        page: 1,
        is_frozen: true,
      };
      const res = await OTTO_V2_API.getOttoV2Projects(params);
      setArchivedOttoProjects(res);
    } catch (error) {
      const errorMessage = apiError(error);
      notification.error('', errorMessage);
    } finally {
      setLoadingArchivedOttoProjects(false);
    }
  };

  const handleSeeTask = items => {
    setDomainLoading();
    updateSelectedSiteSA(items?.siteAudit);
    resetAuditedSiteDetail();
    resetDetectedCms();
    // router?.push(`/otto-page-v2${router?.pathname?.includes('public') ? '-public' : ''}/tasks?domain=${items?.domain}`);
    router?.push(`/otto-page-v2/tasks?uuid=${items?.uuid}`);
  };

  const addDefaultSrc = e => {
    e.target.src = '/img/project-no-screenshot.png';
  };

  const openOnStepTwo = (projectUuid: string) => {
    setUuid(projectUuid);
    setActiveTab(1);
    setIsWidgetModalVisible(true);
  };

  const deleteProject = async id => {
    await deleteOttoProject(id);
    // await getArchivedOttoProjects();
    getCustomerQuota();
  };

  const instructionHandler = record => {
    if (record?.hostname) {
      setInstructionsData({domainToOpen: record.hostname, uuid: record?.uuid});
      setIsInstructionModalVisible(true);
    }
  };

  const getDivColor = (value: number) => {
    if (value <= 30 || !value) return 'rgba(244,67,67,0.07)';
    if (value >= 31 && value <= 80) return 'rgba(241,170,62,10%)';
    return 'rgba(42,193,85,0.07)';
  };

  const getDotColor = (value: number) => {
    if (value <= 30 || !value) return '#F44343';
    if (value >= 31 && value <= 80) return '#F1AA3E';
    return '#2ac155';
  };

  const tableFilterHandler = debounce(async e => {
    if (e?.target?.value) {
      const passedValue = e.target.value;
      setIsSearch && setIsSearch(true);
      setDefaultParams({...defaultParams, pageSize: 20, page: 1, search: passedValue.toLowerCase()}, false, true);
    } else {
      setIsSearch && setIsSearch(false);
      setDefaultParams({search: '', pageSize: 20, page: 1});
    }
  }, 500);

  const tableColumn = (removeDeleteOption?: boolean) => ([
    {
      title: <Title className='column-title'>WEBSITE</Title>,
      dataIndex: 'domain',
      key: 'domain',
      sortFieldName: 'domain',
      width: 250,
      align: 'left' as 'left',
      fixed: width > 800 ? 'left' as 'left' : false,
      render: (_, record) => {
        const url = `https://www.google.com/s2/favicons?sz=64&domain_url=${addProtocolToDomain(record?.hostname)}`;
        return <div onClick={() => handleSeeTask(record)}>
          <FlexWrapper style={{cursor: 'pointer'}}>
            <Img onError={e => addDefaultSrc(e)} src={isValidDomain(record?.hostname) ? url : `https://www.google.com/s2/favicons?sz=64&domain_url=${findValidDomain(record?.hostname)?.includes('.com') ? findValidDomain(record?.hostname) : `${findValidDomain(record?.hostname)}.com`}`} alt={record?.hostname}/>
            <div className='site-name' style={{display: 'flex', gap: 15, width: '93%'}}>
              <Tooltip title={record?.hostname || '-'} overlayInnerStyle={{whiteSpace: 'nowrap', width: 'fit-content', background: '#000'}}>
                <BoldText>{record?.hostname || '-'}</BoldText>
              </Tooltip>
              {
                record?.processingStatus === 'FAILURE' && <FailedBanner>Failed</FailedBanner>
              }
              {/* <GrayText>{record?.location} ‧  {titleCase(record?.dateRange)}</GrayText> */}
            </div>
          </FlexWrapper>
        </div>;
      },
    },
    {
      title: <Title className='column-title'>{`${whitelabelOtto} PIXEL`}</Title>,
      dataIndex: 'isWidgetEnabled',
      key: 'isWidgetEnabled',
      width: 180,
      align: 'left' as 'left',
      onCell: record => ({
        colSpan: !record?.readyForProcessing ? 10 : 1,
      }),
      render: (_, record) => {
        return (
          !record?.readyForProcessing ? (
            <WidgetDiv
              width={'446px'}
              backgroundColor={'rgba(244, 67, 67, 0.07)'}
            >
              <FontAwesomeIcon icon={faCircle} fontSize={8} color='#F44343' />
              Installation process was not completed.
              <RetryBtn onClick={() => openOnStepTwo(record?.uuid)}>Continue installation.</RetryBtn>
            </WidgetDiv>
          ) : (
            <>
              {loadingArray.includes(record?.taskStatus) ?
                <SkeletonHorizontalLoaderGray lightLoader skeletonHeight='10px' height = { '25px' } width = { '100px' }/> :
                <WidgetDiv
                  width={getPixelStateAsBoolean(record?.pixelTagState) ? '130px' : '160px'}
                  backgroundColor={getPixelStateAsBoolean(record?.pixelTagState) ? 'rgba(42, 193, 85, 0.07)' : 'rgba(244, 67, 67, 0.07)'}
                >{getPixelStateAsBoolean(record?.pixelTagState) ?
                    <><FontAwesomeIcon icon={faCircle} fontSize={8} color='#2AC155' />{record?.pixelTagState === 'wp_plugin' ? 'Installed (WP)' : 'Installed'}</> :
                    <><FontAwesomeIcon icon={faCircle} fontSize={8} color='#F44343' />{getPixelErrorMessage(record?.pixelTagState)} <RetryBtn onClick={() => instructionHandler(record)}>Retry</RetryBtn></>
                  }
                </WidgetDiv>}
            </>
          )
        );
      },
    },
    {
      title: <Title className='column-title'>{`${whitelabelOtto} SEO`}</Title>,
      dataIndex: 'isEngaged',
      key: 'isEngaged',
      width: 140,
      align: 'left' as 'left',
      onCell: record => ({
        colSpan: !record?.readyForProcessing ? 0 : 1,
      }),
      render: (_, record) => {
        return (
          <>
            {
              loadingArray.includes(record?.taskStatus) ?
                <SkeletonHorizontalLoaderGray lightLoader skeletonHeight='10px' height = { '25px' } width = { '70px' }/> :
                <>
                  {
                    <WidgetDiv
                      width={!removeDeleteOption ? (record?.isFrozen || record?.isEngaged) ? '80px' : '110px' : record?.isEngaged ? '80px' : '110px'}
                      backgroundColor={!removeDeleteOption ? record?.isFrozen ? 'rgba(45, 108, 202, 0.07)' : record?.isEngaged ? 'rgba(42, 193, 85, 0.07)' : 'rgba(241, 170, 62, 10%)' : record?.isEngaged ? 'rgba(42, 193, 85, 0.07)' : 'rgba(241, 170, 62, 10%)'}
                    >{!removeDeleteOption ?
                        record?.isFrozen ? (
                          <><FontAwesomeIcon icon={faCircle} fontSize={8} color='#2D6CCA' />Frozen</>
                        ) : record?.isEngaged ? (
                          <><FontAwesomeIcon icon={faCircle} fontSize={8} color='#2AC155' />Engaged</>
                        ) : (
                          <><FontAwesomeIcon icon={faCircle} fontSize={8} color='#F1AA3E' />Not Engaged</>
                        ) : record?.isEngaged ? (
                          <><FontAwesomeIcon icon={faCircle} fontSize={8} color='#2AC155' />Engaged</>
                        ) : (
                          <><FontAwesomeIcon icon={faCircle} fontSize={8} color='#F1AA3E' />Not Engaged</>
                        )
                      }</WidgetDiv>
                  }
                </>
            }
          </>
        );
      },
    },
    {
      title: <Title className='column-title'>{`${whitelabelOtto} SCORE`}</Title>,
      dataIndex: 'isEngaged',
      key: 'isEngaged',
      width: 120,
      align: 'left' as 'left',
      onCell: record => ({
        colSpan: !record?.readyForProcessing ? 0 : 1,
      }),
      render: (_, record) => {
        return (
          <>
            {
              loadingArray.includes(record?.taskStatus) ?
                <SkeletonHorizontalLoaderGray lightLoader skeletonHeight='10px' height = { '25px' } width = { '50px' }/> :
                <>
                  {
                    <WidgetDiv
                      className='otto-score'
                      width={'60px'}
                      backgroundColor={getDivColor(record?.afterSummary?.seoOptimizationScore)}
                    >
                      <>
                        <FontAwesomeIcon icon={faCircle} fontSize={8} color={getDotColor(record?.afterSummary?.seoOptimizationScore)} />{record?.afterSummary?.seoOptimizationScore}
                      </>
                    </WidgetDiv>
                  }
                </>
            }
          </>
        );
      },
    },
    {
      title: <Title className='column-title'>FIXED ISSUES</Title>,
      dataIndex: 'isEngaged',
      key: 'isEngaged',
      width: 120,
      align: 'left' as 'left',
      onCell: record => ({
        colSpan: !record?.readyForProcessing ? 0 : 1,
      }),
      render: (_, record) => {
        return (
          <>
            {
              loadingArray.includes(record?.taskStatus) ?
                <SkeletonHorizontalLoaderGray lightLoader skeletonHeight='10px' height = { '25px' } width = { '50px' }/> :
                <>
                  {
                    [null, undefined, 'null', 'undefined'].includes(record?.afterSummary?.deployedFixes) ? (<TextDiv style={{marginLeft: '22px'}}>-</TextDiv>) : (<TextDiv style={{}}>{record?.afterSummary?.deployedFixes}</TextDiv>)
                  }
                </>
            }
          </>
        );
      },
    },
    {
      title: <Title className='column-title'>HEALTHY PAGES</Title>,
      dataIndex: 'isEngaged',
      key: 'isEngaged',
      width: 140,
      align: 'left' as 'left',
      onCell: record => ({
        colSpan: !record?.readyForProcessing ? 0 : 1,
      }),
      render: (_, record) => {
        return (
          <>
            {
              loadingArray.includes(record?.taskStatus) ?
                <SkeletonHorizontalLoaderGray lightLoader skeletonHeight='10px' height = { '25px' } width = { '50px' }/> :
                <>
                  {
                    [null, undefined, 'null', 'undefined'].includes(record?.afterSummary?.healthyPages) && [null, undefined, 'null', 'undefined'].includes(record?.afterSummary?.totalPages) ?
                      (<TextDiv style={{marginLeft: '22px'}}>-</TextDiv>) : (<TextDiv style={{}}>{`${record?.afterSummary?.healthyPages}/${record?.afterSummary?.totalPages}`}</TextDiv>)
                  }
                </>
            }
          </>
        );
      },
    },
    {
      title: <Title className='column-title'>TIME SAVED</Title>,
      dataIndex: 'isEngaged',
      key: 'isEngaged',
      width: 140,
      align: 'left' as 'left',
      onCell: record => ({
        colSpan: !record?.readyForProcessing ? 0 : 1,
      }),
      render: (_, record) => {
        return (
          <>
            {
              loadingArray.includes(record?.taskStatus) ?
                <SkeletonHorizontalLoaderGray lightLoader skeletonHeight='10px' height = { '25px' } width = { '50px' }/> :
                <>
                  {
                    [null, undefined, 'null', 'undefined'].includes(record?.timeSavedTotal) ? (
                      <TextDiv style={{marginLeft: '22px'}}>-</TextDiv>
                    ) : (
                      <Tooltip title={convertMinutesIntoReadAbleTime(record?.timeSavedTotal)} overlayInnerStyle={{whiteSpace: 'nowrap', width: 'fit-content', background: '#000'}}><SaveTimeText className='time-saved'>{convertMinutesIntoReadAbleTime(record?.timeSavedTotal)}</SaveTimeText></Tooltip>
                    )
                  }
                </>
            }
          </>
        );
      },
    },
    {
      title: <Title className='column-title'>PAGES WITH ISSUES</Title>,
      dataIndex: 'pagesWithIssues',
      key: 'pagesWithIssues',
      width: 140,
      align: 'left' as 'left',
      onCell: record => ({
        colSpan: !record?.readyForProcessing ? 0 : 1,
      }),
      render: (_, record) => {
        return (
          <>
            {
              loadingArray.includes(record?.taskStatus) ?
                <SkeletonHorizontalLoaderGray lightLoader skeletonHeight='10px' height = { '25px' } width = { '70px' }/> :
                <>
                  {
                    [null, undefined, 'null', 'undefined'].includes(record?.pagesWithIssues) ? (<TextDiv style={{marginLeft: '22px'}}>-</TextDiv>) : (<TextDiv style={{marginLeft: '22px'}}>{record?.pagesWithIssues}</TextDiv>)
                  }
                </>
            }
          </>
        );
      },
    },
    {
      title: <Title className='column-title'>LAST ANALYSIS</Title>,
      dataIndex: 'lastCrawl',
      key: 'lastCrawl',
      width: 140,
      align: 'left' as 'left',
      onCell: record => ({
        colSpan: !record?.readyForProcessing ? 0 : 1,
      }),
      render: (_, record) => {
        return (
          <>
            {
              loadingArray.includes(record?.taskStatus) ?
                <SkeletonHorizontalLoaderGray lightLoader skeletonHeight='10px' height = { '25px' } width = { '90px' }/> :
                <>
                  {
                    <Tooltip title={record?.lastCrawl ? moment(record?.lastCrawl)?.format('MMM DD, YYYY') : '-'} overlayInnerStyle={{whiteSpace: 'nowrap', width: 'fit-content', background: '#000'}}><SaveTimeText className='time-saved'>{record?.lastCrawl ? moment(record?.lastCrawl)?.format('MMM DD, YYYY') : '-'}</SaveTimeText></Tooltip>
                  }
                </>
            }
          </>
        );
      },
    },
    {
      title: <Title className='column-title'>CONNECTED DATA</Title>,
      dataIndex: 'reportsData',
      key: 'reportsData',
      width: 120,
      align: 'left' as 'left',
      onCell: record => ({
        colSpan: !record?.readyForProcessing ? 0 : 1,
      }),
      render: (_, record, idx) => {
        const gscTooltip = <div>
          <div>Url: {record?.connectedData?.gscDetails?.propertyUrl ?? '-'}</div>
          <div>Country code: {record?.connectedData?.gscDetails?.countryCode ?? '-'}</div>
        </div>;

        const gbpTooltip = <div style={{display: 'flex', flexDirection: 'column'}}>
          Connected Business{record?.connectedData?.gbpDetailsV2?.length > 1 ? 'es': ''}:
          {
            record?.connectedData?.gbpDetailsV2?.map(url => <div key={url?.businessName}><FontAwesomeIcon icon={faCircleDot} fontSize={10}/> {url?.businessName}</div>)
          }
        </div>;
        return (
          <>
            {
              loadingArray.includes(record?.taskStatus) ?
                <SkeletonHorizontalLoaderGray lightLoader skeletonHeight='10px' height = { '25px' } width = { '70px' }/> :
                <FlexWrapper>
                  {<>
                    <Tooltip title={record?.connectedData?.isGscConnected ?
                      gscTooltip :
                      'GSC not connected'}>
                      <StyledIConWrapper style={{backgroundColor: record?.connectedData?.isGscConnected ? '#144198' : '#A3A4A466', cursor: 'pointer'}} onClick={() => {
                        setConnectGSCLoader(idx);
                        resetOttoV2Project(null);
                        setSelectedConnectingProject({...record, is_gbp: false});
                      }}>
                        {connectGSCLoader === idx ? <Spin indicator={<LoadingOutlined style={{fontSize: 12, display: 'flex', alignItems: 'center'}} spin />} /> : <img src='/img/icon/GSC_Icon.png' width={20} style={{opacity: record?.connectedData?.isGscConnected ? '' : '50%'}}/>}
                      </StyledIConWrapper>
                    </Tooltip>
                    <Tooltip title={record?.connectedData?.isGbpConnected ?
                      gbpTooltip :
                      'GBP not connected'}>
                      <StyledIConWrapper style={{backgroundColor: record?.connectedData?.isGbpConnected ? '#2D6CCA' : '#A3A4A466', cursor: 'pointer'}} onClick={async () => {
                        setConnectGMBLoader(idx);
                        resetOttoV2Project(null);
                        setShouldOpenGBP(true);
                        setSelectedConnectingProject({...record, is_gbp: true});
                      }}>
                        {connectGMBLoader === idx ? <Spin indicator={<LoadingOutlined style={{fontSize: 12, display: 'flex', alignItems: 'center'}} spin />} /> : <FontAwesomeIcon icon={faStore} color={'#fff'} fontSize='16px' style={{opacity: record?.connectedData?.isGbpConnected ? '' : '50%'}}/>}
                      </StyledIConWrapper>
                    </Tooltip>
                  </>}
                </FlexWrapper>
            }
          </>
        );
      },
    },
    {
      title: (''),
      dataIndex: 'searchedOn',
      key: 'searchedOn',
      align: 'right' as 'right',
      width: 80,
      onCell: record => ({
        colSpan: !record?.readyForProcessing ? 0 : 1,
      }),
      render: (_, record) => {
        return (
          <>
            {
              loadingArray.includes(record?.taskStatus) ?
                <></> :
                <div style={{position: 'relative'}}>
                  <ButtonStyled
                    buttonType='transparent'
                    onClick={() => handleSeeTask(record)}
                  >
                      View
                  </ButtonStyled>
                </div>
            }
          </>);
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: '30px',
      align: 'left' as 'left',
      render: (_, record)=> {
        return <div className={styles.dropdownStyles}>
          {!record?.readyForProcessing ? (
            <FontAwesomeIcon icon={faTrash} color='#4E5156' fontSize={16} onClick={() => {
              setSelectedDeleteId(record?.uuid); setIsDeletePageModalVisible(true);
            }} style={{cursor: 'pointer'}}/>
          ) :
            (<Dropdown trigger={['click']} overlay={
              <Menu>
                {!removeDeleteOption && <Menu.Item key={'2'} onClick={() => {
                  setSelectedDeleteId(record?.uuid); setIsDeletePageModalVisible(true);
                }}>{'Remove'}</Menu.Item>}
                {
                  record?.siteAudit && <Menu.Item key={'3'} onClick={() => router.push(`/site-audit/overview/?id=${record?.siteAudit}`)}>{'Open Site Audit'}</Menu.Item>
                }
              </Menu>} placement={isInHome ? 'bottomRight' : 'bottomLeft'}
            ><span style={{fontSize: '16px', cursor: 'pointer'}} onClick={e => {
                e.stopPropagation();
              }}><span className={`${styles.removeStyle} option-btn`}>
                  <MoreOutlined />
                </span>
              </span>
            </Dropdown>)}
        </div>;
      },
    },
  ]);
  const openArchivedModal = async () => {
    await getArchivedOttoProjects();
    setArchiveVisible(true);
  };
  return (
    <>
      <Container isinhome={isInHome}>
        <TableContainer isinhome={isInHome} className='top-section'>
          <TableWrapper isinhome={isInHome}>
            <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '10px', alignItems: 'center'}}>
              <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
                <Input onChange={e => tableFilterHandler(e)}
                  size='small'
                  placeholder='Search'
                  prefix={<FontAwesomeIcon icon={faMagnifyingGlass} fontSize={'12px'} color='#A3A4A4'/>}
                  style={{width: '200px', borderRadius: '6px', border: '1px solid #CDCDCD', minHeight: isInHome && 32}}
                />
                <ReportCount>Websites: <span>{getOttoV2ProjectsList?.count ?? 0}</span></ReportCount>
              </div>
              <ArchiveButton onClick={openArchivedModal}>
                <FontAwesomeIcon icon={faBoxArchive} color='#4E5156' /> Deep Frozen
              </ArchiveButton>
            </div>
            <AntdTableStyled
              loading={loadingProjects}
              loadingRows={10}
              columns={tableColumn()}
              dataSource={sitesList}
              scroll={{x: width > 1100 ? undefined : '100%', y: 'none'}}
              pagination={false}
            />
            <PaginationStyled
              onChange={async (page, pageSize) => setDefaultParams({...defaultParams, pageSize: pageSize, page: page})}
              showSizeChanger
              defaultPageSize={50}
              total={getOttoV2ProjectsList.count}
              pageSize={defaultParams.pageSize ?? 20}
              current={defaultParams.page ?? 1}
              pageSizeOptions={['5', '10', '20', '50', '100']}
            />
          </TableWrapper>
        </TableContainer>
      </Container>
      <DeleteConfirmModal
        isDeleting={isLoading}
        isVisible={isDeletePageModalVisible}
        warningText={`Are you sure you want to delete?`}
        confirmButtonColor={'#ff6262'}
        onCancel={ () => {
          setIsDeletePageModalVisible(false); setSelectedDeleteId(null);
        }}
        onConfirm={ async () => {
          try {
            setIsLoading(true);
            await deleteProject(selectedDeleteId);
            setIsDeletePageModalVisible(false);
            setSelectedDeleteId(null);
            setIsLoading(false);
          } catch {
            setSelectedDeleteId(null);
            setIsDeletePageModalVisible(false);
            setIsLoading(false);
          }
        }}
      />
      <ArchivedSitesModal
        visible={archiveVisible}
        setVisible={setArchiveVisible}
        isWidgetModalVisible={isWidgetModalVisible}
        isInstructionModalVisible={isInstructionModalVisible}
        archivedOttoProjects={archivedOttoProjects}
        loadingArchivedOttoProjects={loadingArchivedOttoProjects}
        tableColumn={tableColumn}
        gbpModalVisible={gbpModalVisible}
      />
    </>
  );
});

const Container = styled.div<{isinhome?: boolean}>`
  background: linear-gradient(#0C0E12 20%, rgb(242, 242, 245) 20%);
  display: flex;
  justify-content: center;
  width: 100%;
  border-radius: ${p => p?.isinhome ? '8px' : ''}
`;
const TableWrapper = styled.div<{isinhome?: boolean}>`
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  margin: 0 auto;
  margin-bottom: ${p => p?.isinhome ? '' : '40px'};
  width: 100%;
`;
const TableContainer = styled.div<{isinhome?: boolean}>`
  ${p => p?.isinhome ? `
  margin-top: 20px;
  width: 100%;
  background-color: rgba(127, 78, 173, 0.1);
  border: 1px solid #7F4EAD;
  border-radius: 8px;
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 30px;
  .top-section {
    @media screen and (max-width: 900px) {
      flex-direction: column;
      gap: 10px !important;
    }
  }
  ` : `
  width: 1200px;
  @media screen and (max-width: 1550px) {
    max-width: 1200px;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }
  @media screen and (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  `};
`;

const ReportCount = styled.div`
font-family: 'Inter', sans-serif;
font-weight: 400;
font-size: 14px;
color: rgb(18, 18, 18);
span {
    font-weight: 600;
  }
`;
const AntdTableStyled = styled(Table)`
  .ant-table-body{
    ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    ::-webkit-scrollbar-track {
      background: #F9F9FB;
    }

    ::-webkit-scrollbar-thumb {
      background: #4E515680;
      border-radius: 5px;
    }
  }
  .ant-table-content{
    overflow: auto !important;
  }
  .ant-table-thead {
    background: #f7f7f7 !important;
    border-radius: 8px;
    .ant-table-cell-fix-left {
      z-index: 2 !important;
    }
    .ant-table-cell-fix-left:first-child  {
      border-bottom-left-radius: 8px !important;
      border-top-left-radius: 8px !important;
      font-size: 12px;
      font-weight: 600;
      background: #f7f7f7 !important;
    }
    th {
      white-space: normal !important;
      word-break: break-word !important;
      height: auto !important;
      min-height: 60px !important;
      padding: 8px 15px !important;
  }
    th:first-child {
      padding: 0px 15px 0px 25px !important;
    }
  }
  .ant-table-thead th:nth-child(2) {
    background: #f7f7f7 !important;
    font-size: 12px;
    font-weight: 600;
  }
  .ant-table-thead th {
    color: #121212 !important;
    border-bottom: unset !important;
    height: 60px !important;
    line-height: 14px;
    padding: 0 !important;
    
    &::before {
      display: none;
    }
}
.ant-table-thead th:last-child {
    background-color: #f7f7f7 !important;
    border-bottom-right-radius: 8px !important;
    border-top-right-radius: 8px !important;
    padding-left: 25px !important;
}
  .ant-table-tbody tr:nth-child(odd) td, .ant-table-tbody tr:nth-child(even) td {
    border-bottom: 1px solid #f4f4f4 !important;
    box-shadow: none;
    font-weight: 400;
    white-space: nowrap;
  }
  .ant-table-tbody > tr:first-child td {
    border-top: transparent !important;
  }
  @media screen and (max-width: 1200px) {
    .ant-table-thead th:not(:last-child), .ant-table-tbody tr td:not(:last-child) {
      padding-right: 16px !important;
      padding-left: 16px !important;
    }
    .ant-table-thead th {
      white-space: nowrap;
    }
  }
  .ant-table-tbody {
    .ant-table-row {
      height: 38px !important;
      td {
       height: 38px;
       margin: 0 25px !important;
      }
    }
  }
  .ant-pagination {
      align-items: center;
      .ant-pagination-prev, .ant-pagination-next {
        display: flex;
        align-items: center;
        .ant-pagination-item-link {
          display: flex;
          align-items: center;
          justify-content: center;
          border: none !important;
          background: #f7f7f8 !important;
          width: 25px;
          height: 25px;
          border-radius: 4px !important;
        }
      }
      .ant-pagination-options {
        height: 25px !important;
        .ant-select {
          background: #f7f7f8 !important;
          border: 1px solid #e8e8e8 !important;
          border-radius: 4px !important;
          height: 28px;
          justify-content: center;
          margin-bottom: 0;
          padding: 0 5px;
          z-index: 1 !important;
          .ant-select-selector {
            background-color: transparent !important;
            border: 0 !important;
            height: 1.75em !important;
            align-items: center;
          }
        }
      }
      .ant-pagination-item {
        min-width: 25px !important;
        height: 25px !important;
        background:#f7f7f8 !important;
        border: 1px solid #e8e8e8 !important;
        border-radius: 4px !important;
        box-sizing: border-box !important;
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: center;

        &.ant-pagination-item-active {
          background: #2d2f34 !important;
          a {
            color: #fff !important;
          }
        }
        a {
          color: #121212 !important;
          text-decoration: none !important;
        }
      }
    }
`;

const ArchiveButton = styled.button`
  width: 122px;
  height: 32px;
  border-radius: 6px;
  background: #F7F7F8;
  border: 1px solid #E8E8E8;
  cursor: pointer;
  color: rgb(18, 18, 18);
`;
const Title = styled.div`
font-family: 'Inter', sans-serif;
font-weight: 700;
font-size: 12px;
color: #121212;
`;
const Img = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 5px;
  object-fit: contain;
`;
const BoldText = styled.div`
  color: #2D6CCA;
  font-weight: 500;
  font-size: 14px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const SaveTimeText = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 14px;
  color: #121212;
  text-transform: capitalize;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  cursor: pointer;
`;
const FailedBanner = styled.div`
  padding: 3px 10px;
  border-radius: 35px;
  background-color: #F443431A;
  color: #F44343;

`;
const ButtonStyled = styled(ViewBtton)`
  background-color: #f7f7f8;
  border: 1px solid #E8E8E8 !important;
  padding: 4px 15px;
  color: #4e5156 !important;
  font-size: 14px;
  font-weight: 400 !important;
  outline: none !important;
`;
const StyledIConWrapper = styled.div`
width: 24px;
height: 24px;
border-radius: 6px;
display: flex;
align-items: center;
justify-content: center;
`;
const TextDiv = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #121212;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
`;
export const WidgetDiv = styled.div<{width: string; backgroundColor: string}>`
  font-weight: 400;
  font-size: 14px;
  color: #121212;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  border-radius: 6px;
  background-color: ${p => p.backgroundColor};
  height: 32px;
  width: ${p => p.width};
`;
const FlexWrapper= styled.div`
  display: flex;
  align-items: center;
  // justify-content: center;
  gap: 12px;
`;
const RetryBtn = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #2D6CCA;
  cursor: pointer;
  text-decoration: underline;
`;
