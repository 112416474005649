import React, {useState, useEffect} from 'react';
import {useTranslation} from 'next-i18next';
import {DividerStyled, TextBlack, RangeInputContainer, CustomRangeContainer, ApplyButton, PopupItemStyled, TextGray, ApplyBtnWrapper} from '../styledComponents';
import {Tooltip} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSquareQuestion} from '@fortawesome/pro-duotone-svg-icons';

interface Props {
  type?: string;
  filterId?: number;
  filterLabel?: string;
  filterName?: string;
  removeItem?: ()=>void;
  onChange?: (filter:any)=>void;
  className?: string;
  initialFrom?: string;
  initialTo?: string;
  isClear?: boolean;
  onClear?: ()=>void;
  absoluteUi?: boolean;
  isPageExplorer?: boolean;
  initialEqual?: string;
  isIssuePage?: boolean;
  customOptionsTop?: any[];
  customOptions?: any[];
  from?: any;
  setFrom?: any;
  to?: any;
  setTo?: any;
  isDecimals?: boolean;
  allowOneField?: boolean;
  maxLimit?: number;
  singleInputNumber?: boolean;
}

const FilterPopUp: React.FC<Props> = ({customOptions, maxLimit, allowOneField, customOptionsTop, type, filterId, filterLabel, filterName, onChange, initialFrom, initialTo, isPageExplorer, initialEqual, isDecimals, singleInputNumber}) => {
  const [value, setValue] = useState(type || 'between');
  const [eq, setEq] = useState('');
  const [from, setFrom] = useState('');
  const [isError, setIsError] = useState(false);
  const [to, setTo] = useState('');
  const {t} = useTranslation('common');
  useEffect(() => {
    if (initialFrom) {
      setFrom(initialFrom);
    } else {
      setFrom('');
    }
    if (initialTo || initialTo == '') {
      setTo(initialTo);
    } else {
      setTo('');
    }
    if (type) {
      setValue(type);
    }
    if (initialEqual || (filterLabel== 'depth' && isPageExplorer)) {
      setFrom('');
      setEq(initialEqual);
    } else {
      setEq('');
    }
  }, [initialFrom, initialTo]);

  const createOrUpdateFilter = () =>{
    emitOnChanges(from, to, eq);
  };
  const emitOnChanges = (from: string, to: string, eq: string) => {
    if (typeof onChange === 'function') {
      const makeObj = {
        id: filterId,
        header: filterLabel,
        name: filterName,
        type: value,
        from: !from ? '0' : from,
        to: to || '',
        equal: '',
        active: true,
        customOptions: customOptions,
        customOptionsTop: customOptionsTop,
        isDecimals: isDecimals,
        maxLimit: maxLimit,
      };
      if (eq) {
        makeObj['equal'] = value== 'between' || value== 'lower' || value == 'higher' ? '' : eq;
      }
      onChange(makeObj);
    }
  };

  const disablingButton = () =>{
    if (allowOneField) {
      if (maxLimit && isError) return true;
      if (!+from && !+to) return true;
      if (value === 'equal'&&filterLabel=='depth'&& !eq) return true;
      if ((initialFrom || initialTo) && (to ===initialTo && from ===initialFrom)) return true;
      return false;
    } else {
      if (maxLimit && isError) return true;
      if (value === 'between' && (+from > +to) ) return true;
      if (value === 'between' && (!from || !to) ) return true;
      if (value ==='higher' && !from) return true;
      if (value === 'lower' && !to) return true;
      if (value === 'equal'&&filterLabel=='depth'&& !eq) return true;
      if ((initialFrom || initialTo) && (to ===initialTo && from ===initialFrom)) return true;
      return false;
    }
  };
  const handleCustomFilters = data => {
    if (typeof onChange === 'function') {
      const makeObj = {
        id: filterId,
        header: filterLabel,
        name: filterName,
        type: value,
        from: !data?.min ? '0' : data?.min,
        to: data?.max || '',
        equal: data?.equal || '',
        active: true,
        customOptions: customOptions,
        customOptionsTop: customOptionsTop,
      };
      onChange(makeObj);
    }
  };

  return (
    <div>
      {customOptionsTop?.length ? <>
        {customOptionsTop?.map(item => {
          const isActive = item?.min == initialFrom && item?.max == initialTo;
          return <ApplyBtnWrapper key={`Custom_Filter_option_${item?.name}`}>
            <PopupItemStyled selected={isActive} onClick={() => handleCustomFilters(item)}>
              <TextBlack>{item?.name}<span>&nbsp;{item?.info ? <Tooltip title={item?.info} color='#121212' overlayInnerStyle={{borderRadius: '8px'}}>
                <FontAwesomeIcon icon={faSquareQuestion} style={{color: '#A3A4A4'}}/>
              </Tooltip> : ''}</span></TextBlack>
              {item?.showPercent ? <TextGray>{item?.min}-{item?.max}%</TextGray> : ''}
            </PopupItemStyled>
          </ApplyBtnWrapper>;
        })}
        <DividerStyled />
      </> : ''}
      {customOptions?.length ? <>
        {customOptions?.map(item => {
          const isActive = item?.equal ? (item?.equal == initialEqual) : (item?.min == initialFrom && item?.max == initialTo);
          return <ApplyBtnWrapper key={`Custom_Filter_option_${item?.name}`}>
            <PopupItemStyled selected={isActive} onClick={() => handleCustomFilters(item)}>
              <TextBlack>{item?.name}<span>&nbsp;{item?.info ? <Tooltip title={item?.info} color='#121212' overlayInnerStyle={{borderRadius: '8px'}}>
                <FontAwesomeIcon icon={faSquareQuestion} style={{color: '#A3A4A4'}}/>
              </Tooltip> : ''}</span></TextBlack>
              {item?.showPercent ? <TextGray>{item?.min}-{item?.max}%</TextGray> : ''}
            </PopupItemStyled>
          </ApplyBtnWrapper>;
        })}
        <DividerStyled />
      </> : ''}
      <CustomRangeContainer>
        {!singleInputNumber && <TextBlack>Custom range</TextBlack>}
        <RangeInputContainer singleInputNumber={singleInputNumber} isError={isError}>
          {!singleInputNumber && <input
            value={from}
            placeholder='From'
            type={'number'}
            onChange={e=> setFrom(isDecimals ? e.target.value : e.target.value.replace(/\D/g, ''))}
          />}
          <input
            value={to}
            type={'number'}
            placeholder= {singleInputNumber ? '' : (maxLimit ? `Upto ${maxLimit}` : 'To')}
            onChange={e=> {
              if (!from) {
                setFrom('0');
              }
              if (maxLimit && Number(e?.target?.value) > maxLimit) {
                setIsError(true);
              } else {
                setIsError(false);
              }
              setTo(isDecimals ? e.target.value : e.target.value.replace(/\D/g, ''));
            }}
          />
        </RangeInputContainer>
        <ApplyBtnWrapper>
          <ApplyButton
            disabled={disablingButton()}
            onClick={createOrUpdateFilter}
          >
            {t('apply-filter')}
          </ApplyButton>
        </ApplyBtnWrapper>
      </CustomRangeContainer>
    </div>
  );
};

export default FilterPopUp;
