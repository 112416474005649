import React from 'react';
import {AddShopifySiteModalStyled} from './styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faShopify} from '@fortawesome/free-brands-svg-icons';
import {faUpRightFromSquare} from '@fortawesome/pro-regular-svg-icons';
import {openLiveChat} from '@/utils/intercom';

const AddShopifySiteModal = ({visible, setVisible}) => {
  return (
    <AddShopifySiteModalStyled
      open={visible}
      onCancel={() => setVisible(false)}
      closable={false}
      footer={null}
      width={530}
    >
      <div className='container'>
        <div className='logo'>
          <FontAwesomeIcon icon={faShopify} color='#FFFFFF' />
        </div>
        <div className='title'>1-Click Publish to Shopify</div>
        <div className='desc'>Install the SearchAtlas app to your Shopify store and Start Publishing content directly from the SEO Content Assistant editor.</div>
        <div className='desc1'>Click the button below to install the Shopify App:</div>
        <button className='install-button' onClick={e => {
          e.preventDefault();
          window.open('https://apps.shopify.com/search-atlas', '_blank');
        }}
        >
          Install the App <FontAwesomeIcon style={{marginLeft: '8px'}} icon={faUpRightFromSquare} />
        </button>
        <div className='desc1' style={{marginTop: '10px'}}>Need help installing the app?</div>
        <div className='contact-support-button' onClick={openLiveChat}>Contact Support</div>
      </div>
    </AddShopifySiteModalStyled>
  );
};

export default AddShopifySiteModal;
