import {useStore} from '@/store/root-store';
import {faCaretDown} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Divider, Dropdown, Menu} from 'antd';
import GoogleMapReact from 'google-map-react';
import {observer} from 'mobx-react-lite';
import {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {MapTooltip} from '@/components/dashboard/pages/local-seo-scan/local-seo-detail-v2/components/map-tooltip';
import {notification} from '@/utils/notification-v2';
import {faTrash} from '@fortawesome/pro-regular-svg-icons';
import {LocationAnalysisModal} from '@/components/dashboard/pages/local-seo-scan/local-seo-detail-v2/components/locationAnalysisModal';
import {getSingleUrlParam} from '@/utils/url';
import {handleTilesLoaded} from '@/utils/maps';
import {faUpRightAndDownLeftFromCenter} from '@fortawesome/pro-regular-svg-icons';

export const Heatmap = observer(({setIsUpdateKeyword, isUpdateKeyword, seoId, updateDate, isDelete, mapData, handleMapDelete, keywordBreakdown}: any) => {
  const {reportBuilder: {details: {layoutType, isConfig, defaultMap, loadLocalSeoDetailsDataList, getLocalSeoDetailsData}}} = useStore('');
  const [maps, setMap] = useState([]);
  const [newDate, setNewDate] = useState('');
  const [locationReport, setLocationReport] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [comparison, setComparison] = useState(null);
  const [showComparison, setShowComparison] = useState(false);
  const getPublicHash = getSingleUrlParam('public_hash');
  const dates = JSON.parse(JSON.stringify(keywordBreakdown?.find(item => (mapData?.kId ? item.id == mapData?.kId : item?.keyword == mapData?.keyword))?.availableDates ?? []));
  const availableDates = dates?.sort().reverse();
  const [noDateScan, setNoDateScan] = useState(false);
  const [pinWidth, setPinWidth] = useState(50);
  const [fontSize, setFontSize] = useState(16);
  const [zoomValue, setZoomValue] = useState(null);
  const allScans = ['Latest Scan', 'Previous Scan', '2nd Previous Scan', '3rd Previous Scan', 'First Scan'];
  const [isFullscreen, setIsFullScreen] = useState(true);
  const mapPreviewRef = useRef(null);

  useEffect(() => {
    if (isFullscreen) {
      try {
        const headerMain = document.getElementById('HEADER_MAIN');
        const sidebarMain = document.getElementById('SIDEBAR_MAIN');
        const wrapperMain = document.getElementById(seoId)?.parentElement;
        const mapPreview = mapPreviewRef.current;

        if (headerMain) {
          headerMain.style.display = null;
        }
        if (sidebarMain) {
          sidebarMain.style.display = null;
        }
        if (wrapperMain) wrapperMain.style.zIndex = null;
        document.body.style.overflow = null;
        if (mapPreview) {
          mapPreview.style.position = null;
          mapPreview.style.width = null;
          mapPreview.style.height = null;
          mapPreview.style.left = null;
          mapPreview.style.top = null;
          mapPreview.style.zIndex = null;
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error: ', error);
      }
    } else {
      try {
        const headerMain = document.getElementById('HEADER_MAIN');
        const sidebarMain = document.getElementById('SIDEBAR_MAIN');
        const wrapperMain = document.getElementById(seoId)?.parentElement;
        const mapPreview = mapPreviewRef.current;
        if (headerMain) {
          headerMain.style.display = 'none';
        }
        if (sidebarMain) {
          sidebarMain.style.display = 'none';
        }
        wrapperMain.style.zIndex = '999';
        document.body.style.overflow = 'hidden';

        const bodyWidth = document.body.clientWidth;
        const bodyHeight = document.body.clientHeight;

        mapPreview.style.position = 'fixed';
        mapPreview.style.width = `${bodyWidth}px`;
        mapPreview.style.height = `${bodyHeight}px`;
        mapPreview.style.zIndex = '999';

        setTimeout(() => {
          const rect = mapPreview.getBoundingClientRect();
          const topDiff = mapPreview.offsetTop - mapPreview.scrollTop + mapPreview.clientTop;
          const divTop = rect.y - topDiff < -1 ? `${topDiff - rect.y}px` : `-${rect.y - topDiff}px`;
          const divLeft = rect.x - mapPreview.offsetLeft;

          mapPreview.style.left = `-${divLeft}px`;
          mapPreview.style.top = divTop;
        }, 0);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error: ', error);
      }
    }
  }, [isFullscreen]);

  useEffect(() => {
    if (maps?.length) {
      maps?.map(item => {
        if (item?.map) {
          // @ts-ignore
          window.google.maps.event.addListenerOnce(item?.map, 'tilesloaded', handleTilesLoaded(item?.map, locationReport.location?.data));
        }
      });
    }
  }, [maps, maps?.length, isLoading]);

  useEffect(() => {
    setNewDate(mapData.isLatestScan ? mapData.isLatestScan : mapData.selectedDate || availableDates[0] || defaultMap?.availableDates[0]);
  }, [mapData]);

  const getData = (isDate='') => {
    seoId && loadLocalSeoDetailsDataList(Number(mapData?.kId), Number(mapData.dataCid), {keyword: mapData?.keyword, date: isDate ? isDate : allScans.includes(newDate) ? newDate == 'First Scan' ? [...availableDates][availableDates?.length-1] : [...availableDates][allScans.indexOf(newDate)] : newDate})
      .then(data => {
        setIsLoading(false);
        maps[0]?.map.setZoom(11);
        try {
          if (Object.values(data).length) {
            setLocationReport({center: Object.values(data)[0]['snapshotData'].center.coordinates, radius: Object.values(data)[0]['snapshotData'].radiusMiles, location: Object.values(data)[0]['locationReport'][0]});
          }
        } catch (error) {
          notification.error('Error', 'Erorr on parsing data');
        }
      }).catch(()=> {
        setIsLoading(true);
      });
  };

  const renderDateData = (isDate='') => {
    if (isDate || newDate) {
      setIsLoading(true);
      if (allScans.includes(isDate || newDate)) {
        if ([...availableDates][(isDate || newDate) == 'First Scan' ? availableDates?.length-1 : allScans.indexOf(isDate || newDate)]) {
          getData(isDate);
          setNoDateScan(false);
        } else {
          setNoDateScan(true);
          setLocationReport({});
          setIsLoading(false);
        }
      } else {
        getData(isDate);
        setNoDateScan(false);
      }
    }
  };

  useEffect(()=> {
    isUpdateKeyword && renderDateData(mapData?.selectedDate);
  }, [mapData?.keyword, mapData?.kId]);

  useEffect(() => {
    !isUpdateKeyword && renderDateData();
  }, [newDate, seoId]);

  const createMapOptions = () => {
    return {
      panControl: false,
      mapTypeControl: false,
      scrollwheel: true,
      zoomControl: false,
      fullscreenControl: false,
    };
  };

  const placesLoading = () => {
    const markers = [];
    const center = {lat: getLocalSeoDetailsData?.find(item=> item.id == seoId)?.rbSeoData?.snapshotData?.center?.coordinates[0], lng: getLocalSeoDetailsData?.find(item=> item.id == seoId)?.rbSeoData?.snapshotData?.center?.coordinates[1]};
    const numMarkers = 3*3;
    const radius = (3*3)/2;
    const markerDistance = radius * 1609.34 * 2 / Math.sqrt(numMarkers);
    const latIncrement = markerDistance / 111319;
    const lngIncrement = markerDistance / (111319 * Math.cos(center.lat * Math.PI / 180));
    for (let i = 0; i < numMarkers; i++) {
      const x = i % Math.sqrt(numMarkers) - Math.floor(Math.sqrt(numMarkers) / 2);
      const y = Math.floor(i / Math.sqrt(numMarkers)) - Math.floor(Math.sqrt(numMarkers) / 2);
      const lat = center.lat + y * latIncrement;
      const lng = center.lng + x * lngIncrement;
      markers.push({id: i, lat, lng});
    }
    // Filter out any markers with invalid latitude/longitude values
    const validMarkers = markers.filter(marker => {
      return isFinite(marker.lat) && isFinite(marker.lng);
    });
    return validMarkers?.map((place, idx) => {
      return (
        <MapTooltip
          key={idx}
          lat={place?.lat}
          lng={place?.lng}
          data={place}
          zoom={11}
          isLoader={true}
        />
      );
    });
  };

  const onMapZoomChange = ({zoom}) => {
    const defaultZoom = 50;
    const maxZoom = 50;
    const minZoom = 20;
    const defaultZoomLevel = 13;
    const defaultFontSize = 16;

    setZoomValue(zoom);

    let newZoomValue;
    if (zoom <= defaultZoomLevel) {
      newZoomValue = defaultZoom - 14 * (defaultZoomLevel - zoom);
    } else {
      newZoomValue = defaultZoom + 14 * (zoom - defaultZoomLevel);
    }

    if (newZoomValue > maxZoom) {
      newZoomValue = maxZoom;
    } else if (newZoomValue < minZoom) {
      newZoomValue = minZoom;
    }

    const calculatedFontSize = defaultFontSize - ((defaultZoom - newZoomValue) / 4.5);
    setPinWidth(newZoomValue);
    setFontSize(calculatedFontSize);
  };

  const places = data => {
    if (data) {
      return data?.location?.data?.map((place, idx) => {
        const findBusinessIndex = place?.serps?.findIndex(z => z?.position == place?.gmbPosition);
        const topBusinessList = place?.serps?.slice(0, 3);
        if (findBusinessIndex !== -1 && ![0, 1, 2].includes(findBusinessIndex)) {
          topBusinessList?.push(place?.serps[findBusinessIndex]);
        }
        return (
          <MapTooltip
            key={idx}
            lat={place?.location?.lat}
            lng={place?.location?.lon}
            data={place}
            isReportBuilder={false}
            zoom={zoomValue}
            radiusMiles={data.radius}
            businessList={topBusinessList || []}
            open={false}
            pinWidth={pinWidth}
            fontSize={fontSize}
            centerPoint={place?.location?.lat == data.center[0] && place?.location?.lon == data.center[1]}
            setIsOpen={() => setComparison(place)}
            showPinDetailModal={showComparison}
            setShowPinDetailModal={setShowComparison}
            isConfig={isConfig}
            businessIcon={place?.location?.lat == data.center[0] && place?.location?.lon == data.center[1]}
            layoutType={layoutType == 'default'}
          />
        );
      });
    }
  };

  useEffect(() => {
    if (!availableDates?.some(item => item == (mapData.isLatestScan ? availableDates[0] : mapData?.selectedDate))) {
      handleMapDelete(mapData.id);
    }
  }, [mapData]);

  const defaultProps = {
    center: {
      lat: getLocalSeoDetailsData?.length && getLocalSeoDetailsData?.find(item=> item.id == seoId)?.rbSeoData?.snapshotData?.center?.coordinates[0] || 40.73061,
      lng: getLocalSeoDetailsData?.length && getLocalSeoDetailsData?.find(item=> item.id == seoId)?.rbSeoData?.snapshotData?.center?.coordinates[1] || -73.935242,
    },
  };

  return (availableDates?.some(item => item == (mapData.isLatestScan ? availableDates[0] : mapData?.selectedDate)) && <ContentWrapper>
    <MapContainer ref={mapPreviewRef}>
      <GoogleMapReact
        bootstrapURLKeys={{key: process.env.GOOGLE_MAP_KEY}}
        center={defaultProps.center}
        defaultZoom={zoomValue || 11}
        onGoogleApiLoaded={({map}) => setMap([...maps, {map: map}])}
        zoom={zoomValue}
        onChange={onMapZoomChange}
        options={createMapOptions}
        yesIWantToUseGoogleMapApiInternals
      // options={{fullscreenControl: false, zoomControl: false}}
      >
        {isLoading ? placesLoading() : places(locationReport)}
      </GoogleMapReact>
      <MapControl>
        <MapControlIcon onClick={() => setIsFullScreen(!isFullscreen)}>
          <FontAwesomeIconStyled hoverColor='#000000' color='rgba(0, 0, 0, 0.7)' icon={faUpRightAndDownLeftFromCenter} fontSize={19} />
        </MapControlIcon>
        {isFullscreen &&
          <MapControlIcon onClick={ async () => {
            if (!getPublicHash && isDelete) {
              setIsLoading(true);
              await handleMapDelete(mapData.id);
              setIsLoading(false);
            }
          }}>
            <FontAwesomeIconStyled hoverColor='red' pointerEvents={(!isDelete || isConfig) ? 'none' : 'auto'} color= {(!isDelete || isConfig) ? 'lightgray' : 'rgba(0, 0, 0, 0.7)'} icon={faTrash} fontSize={19} />
          </MapControlIcon>
        }

      </MapControl>
    </MapContainer>
    {(!locationReport['location'] || noDateScan) && !isLoading && <WhiteOverlay isScan={allScans.includes(newDate)}>{allScans.includes(newDate) ? `No data found for ${newDate}` : 'No location found'}</WhiteOverlay>}
    <ResultsWrapper>
      <div style={{display: 'flex', gap: '10px', alignItems: 'center', width: 'fit-content'}}>
        <Dropdown
          overlayStyle={{background: 'white'}}
          overlay={<MenuStyled defaultValue={newDate}>
            <div className='cover'>
              {[...allScans, ...availableDates]?.map((item, ind) => <><Menu.Item key={ind} onClick={() => {
                setIsUpdateKeyword(false);
                setNewDate(item);
                updateDate(mapData, item);
              }} className={item == newDate ? 'isSelected' : 'notSelected'} style={{position: 'relative'}}>
                <p style={{margin: '0'}}>{item ? item : '-'}</p>
              </Menu.Item>
              {ind == 4 && <Divider type='horizontal' style={{background: '#E8E8E8', margin: '9px 0'}} />}
              </>)}
            </div>
          </MenuStyled>
          }
          trigger={['click']}
          placement='bottomLeft'>
          <DropdownDiv>{newDate || ''}&nbsp;&nbsp;<FontAwesomeIcon icon={faCaretDown} /></DropdownDiv>
        </Dropdown>

      </div>
    </ResultsWrapper>
    <LocationAnalysisModal
      showPinDetailModal={showComparison}
      setShowComparison={setShowComparison}
      setShowPinDetailModal={()=>{}}
      selectedDate={comparison?.date}
      resultsData={comparison}
      gridDimensions={9}
      gridShape={comparison?.gridShape}
      isLoading={false}
      radiusMiles={2}
      showRBComparison={true}
      latitude={comparison?.location?.lat}
      longitude={comparison?.location?.lon}
      currentBusinessCid={`${getLocalSeoDetailsData?.find(item=> item.id == mapData?.dataCid)?.rbSeoData.dataCid}`} />
  </ContentWrapper>);
});


const ContentWrapper = styled.div`
  position: relative;
  margin-right: 12px;
  border-top-left-radius: 10px;
  margin-bottom: 10px;
`;

const MapControlIcon = styled.div`
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
`;

const MapControl = styled.div`
  display: flex;
  position: absolute;
  top: 10px;
  right: 14px;
  padding: 5px 2px 2px 2px;
  background-color: white;
  border-radius: 5px
`;

const FontAwesomeIconStyled = styled(FontAwesomeIcon)<{hoverColor: string}>`
  &:hover {
    color: ${prop => prop.hoverColor};
  }
`;

const MapContainer = styled.div`
  width: 320px;
  height: 287px;
  border-radius: 12px;
  div div {
    border-radius: 12px;
  }
  .gm-fullscreen-control {
    width: 65px !important;
    height: 28px !important;
    margin-top: 13px !important;
    border-radius: 6px !important;
    border: 1px solid #CDCDCD !important;
    img {
      left: 28% !important;
    }
  }
`;

const ResultsWrapper = styled.div`
  position: absolute;
  // width: 100%;
  // width: fit-content;
  border-radius: 12px;
  top: 0;
  left: 0;
  padding: 14px 0 0 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 5px;
  }
  &:hover {
    ::-webkit-scrollbar-thumb {
      background: #4E5156;
    }
  }
`;

const MenuStyled = styled(Menu)`
  border-radius: 7px;
  .cover{
height: fit-content;
    overflow-y: auto;
    max-height: 260px;
  }
  &:hover {
    ::-webkit-scrollbar-thumb {
      background: rgba(78, 81, 86, 0.6);
    }
  }
  .ant-dropdown-menu-item{
    border-radius: 10px;
    margin: 0 10px;
    width: 165px;
    padding: 5px 5px !important;
    width: auto;
  }
    .isSelected{
        background: #ebe9e9 !important;
    }
        .notSelected{
        background: transparent !important;

        }
    .ant-dropdown-menu-item:hover{
    background: #f5f5f5 !important;
    }
`;

const DropdownDiv = styled.div`
  width: fit-content;
  max-width: 100%;
  height: 28px;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 10px 6px 10px;
  border-radius: 6px;
  border: 1px solid #CDCDCD;
  background: #FFFFFF;
`;


const WhiteOverlay = styled.div<{isScan?: boolean}>`
  position: absolute;
  width: auto;
  height: 25px;
  background: #eecaca;
  border-radius: 10px;
  top: calc(50% - (25px / 2));
  left: ${p=> p.isScan ? 'calc(50% - (274px / 2))' : 'calc(50% - (154px / 2))' };
  padding: 15px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 5px;
  }
  &:hover {
    ::-webkit-scrollbar-thumb {
      background: #4E5156;
    }
  }
`;
