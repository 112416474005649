import {Tooltip, Spin, Dropdown, Collapse} from 'antd';
import {observer} from 'mobx-react';
import React, {Dispatch, SetStateAction, useCallback, useEffect, useRef, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash, faXmark} from '@fortawesome/pro-regular-svg-icons';
import {faSortDown, faCheckCircle, faPen, faTrashXmark} from '@fortawesome/pro-solid-svg-icons';
import {LoadingOutlined} from '@ant-design/icons';
import {useStore} from '@/store/root-store';
import {getSingleUrlParam, addProtocolToDomain} from '@/utils/url';
import {notification} from '@/utils/notifications';
import {openUrl} from '@/utils/router';
import {getFilteredDataForOtto} from '../../../tableIssuesCollapse';
import {BackButton, DeleteButton, DescriptionWrapper, MiddleInputWrapper, ModalWrapper, NlpTableWrapper, OnlyHeaderTAble, PaginationStyled, PopUpWrapper, StatusContainer, StatusMenu, StatusWrapper, StyledIssuesCollapse, StyledIssuesTable, StyledTrashIcon, UrlWrapper, Wrapper, PurpleCheckbox, AddKeywordsWrapper, StyledCloudStackInput, FirstDeployTooltipContent} from '../../../../style';
import {StyledMenu} from '../../../../Utils/styledMenu';
import {canDeploy, disableCheck, getDeployStatus, getStatusText} from '../../../../Constants/functions';
import useWindowSize from '@/utils/hooks/useWindowSize';
import {debounce} from 'lodash';
import EditTargetKeywordsModal from '../../../../Modals/editTargetKeywordsModal';
import {termQAError} from '../../../../Constants';
import {TableTopBar} from '../../../tableTopBar';
import {Button, Modal} from '@/components/common-components/components';
import {StyledSpin} from '../../../../Utils/styledSpin';
import {newNotification} from '@/utils/notification-v3';
import {timeNotification} from '@/utils/notification-timer';
import {BulkActionBar} from '../../../bulkActionBar';
import {getOttoTablePageSize} from '@/api/common-utils';
import {saveOttoTablePageSize} from '@/api/common-utils';
import FreezeWrapper from '../../../freezTooltip';
import {capitalizeFirstLetter, issuesTypes} from '@/utils/string';
import {CustomTooltip} from '@/components/common-components/components/tooltip';

let ReactJson = null;
if (typeof document !== 'undefined') {
  import('react-json-view').then(module => {
    ReactJson = module.default;
  });
}
interface Props {
  componentIssueType: string;
  setPageChanged: (value: boolean) => void;
  setIssueTable: (value: string) => void;
  setOttoProject?: (value: number) => void;
  issueTable: string;
  setCurrentIssueType?: (value: string) => void;
  setOpenBulkPagesModal?: Dispatch<SetStateAction<boolean>>;
  setAddBulkPagesFrom?: Dispatch<SetStateAction<string>>;
  maxHeight?: string;
  removeTopBar?: boolean;
}

export const NlpTerms = observer(({componentIssueType, setPageChanged, setIssueTable, issueTable, setOttoProject, setCurrentIssueType, setOpenBulkPagesModal, setAddBulkPagesFrom, maxHeight = null, removeTopBar = false}: Props) => {
  const {ottoV2Store: {
    getOttoUrls,
    getOttoV2Project,
    deployOttoUrls,
    setIssueTypeSelected,
    setIsDeploying,
    loadOttoV2Project,
    issueTypeArray,
    ottoUrlLoader,
    ottoIssueType,
    loadIssueTableData,
    setSelectedIssue,
    setSelectedCategory,
    setLoadingDetail,
    setOttoSearchTerm,
    isActiveKeys,
    selectedIssue,
    selectedCategory,
    addSourcePage,
    deploySubOttoUrls,
    deleteUrlIssue,
    setNlpPagination,
    setIsKnowledgeModalVisible,
  },
  } = useStore('');
  const uuid = getSingleUrlParam('uuid');
  const domainName = getOttoV2Project?.hostname || getSingleUrlParam('domain');
  const [currentProject, setCurrentProject] = useState(null);
  const [urlId, setUrlId] = useState<any>(-1);
  const [ottoUrls, setOttoUrls] = useState([]);
  const [qaDeleteVisible, setQADeleteVisible] = useState(false);
  const [currentDelId, setCurrentDelId] = useState(-1);
  const [termQAErrorState, setTermQAErrorState] = useState(termQAError);
  const [addNLPTerms, setAddNLPTerms] = useState(false);
  const [deleteItem, setDeleteItem] = useState<any>(-1);
  const [currentRecord, setCurrentRecord] = useState<any>({});
  const [addingNLPTerms, setAddingNLPTerms] = useState(false);
  const [subUrlId, setSubUrlId] = useState<any>(-1);
  const [deleteIssueIds, setDeleteIssueIds] = useState<any>({parent: null, child: null});
  const [openModal, setOpenModal] = useState(false);
  const [modalConf, setModalConf] = useState({
    visible: false,
    keywords: [],
    path: '',
    id: null,
    issueType: '',
  });
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isTableOpen, setIsTableOpen] = useState<any>('open');
  const [isActiveFilter, setIsActiveFilter] = useState('all');
  const [deployLoading, setDeployLoading] = useState('');
  const {width} = useWindowSize();
  const jsonViewRef = useRef(null);
  const [openedIndex, setOpenedIndex] = useState<any>(null);
  const termInputRef = useRef(null);
  const [showBulkBanner, setShowBulkBanner] = useState<boolean>(false);
  const [selectedPages, setSelectedPages] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const issueTypesFrontendMappingV2 = getOttoV2Project?.issueTypesFrontendMappingV2;
  const antUrlIcon = <LoadingOutlined style={{fontSize: 16, color: '#2AC155'}} spin />;
  const antBlueIcon = <LoadingOutlined style={{fontSize: 16, color: '#2D6CCA'}} spin />;
  const [pageSize, setPageSize] = useState(() => getOttoTablePageSize(componentIssueType));
  const [keywords, setKeywords] = useState([]);
  const [keywordString, setKeywordString] = useState('');
  const [keywordError, setKeywordError] = useState(false);
  const [page, setPage] = useState(1);
  const [visibleFirstDeployTooltip, setVisibleFirstDeployTooltip] = useState('');
  const [approvedSum, setApprovedSum] = useState(0);

  useEffect(() => {
    const groups = getOttoV2Project?.issuesCountBreakdown?.['issues'] || {};
    let updatedSum = 0;
    const groupKeys = Object.keys(groups);
    for (const key of groupKeys) {
      if (issuesTypes.includes(key) && 'approved' in groups[key]) {
        updatedSum += groups[key].approved || 0;
      }
    }
    setApprovedSum(updatedSum);
  }, [JSON.stringify(getOttoV2Project?.issuesCountBreakdown)]);
  const getOttoUrlAgainstIssueType = urls => {
    const filteredOttoUrls = urls ? urls?.filter(url => issueTypeArray.includes(url?.issueType)) : [];
    const url = filteredOttoUrls.find(url => url?.issueType === componentIssueType);

    return url;
  };

  const url = getOttoUrlAgainstIssueType(ottoUrls);
  const data = url?.issueTable?.results ?? [];
  const filteredData = getFilteredDataForOtto(data, url?.issueType, false);

  useEffect(() => {
    if (getOttoUrls) {
      const url = getOttoUrlAgainstIssueType(getOttoUrls);
      if (url?.page === page) setOttoUrls(getOttoUrls);
    }
  }, [getOttoUrls]);

  useEffect(() => {
    if (filteredData.length && selectAll) {
      const pageArray = filteredData.map(data => data?.id);
      setSelectedPages(pageArray);
    }
  }, [ottoUrls, selectAll]);

  useEffect(() => {
    setCurrentProject(getOttoV2Project?.uuid == uuid && getOttoV2Project);
  }, [getOttoV2Project]);

  const deployOttoUrl = async (id, toDeploy, issueType) => {
    setUrlId(`${id}nlp_terms`);
    try {
      const data = {toDeploy, issueType, uuid, ottoUrls: [id]};
      await deployOttoUrls(data);
      setPageChanged(true);
      setIssueTypeSelected(issueType);
      setIsDeploying(true);
      if (uuid) {
        loadOttoV2Project(uuid, true);
      }
      if (toDeploy) {
        newNotification('1 Change deployed', 2, 'deploy');
      } else {
        newNotification('1 Change Rolled Back', 2, 'rollback');
      }
      setUrlId(-1);
    } catch (error) {
      setUrlId(-1);
      return Promise.reject(error);
    } finally {
      setDeployLoading('');
    }
  };
  const deployOttoSection = async (toDeploy: boolean) => {
    try {
      await deployOttoUrls({toDeploy, issueType: url?.issueType, uuid});
      setPageChanged(true);
      setIssueTypeSelected(url?.issueType);
      setIsDeploying(true);
      if (uuid) {
        loadOttoV2Project(uuid, true);
      }
      if (toDeploy) {
        newNotification('Changes Deployed Successfully', 2, 'deploy');
      } else {
        newNotification('Changes Rolled Back', 2, 'rollback');
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };
  const showConfirmationPopup = record => {
    if (deployLoading !== 'Undeploy all' && deployLoading !== 'Deploy all') {
      setCurrentRecord(record);
      setIsDeploying(false);
      setOpenModal(true);
    }
  };
  const deploySubOttoUrl = async (id, index, toDeploy, issueType ) => {
    setSubUrlId(index);
    try {
      const data = {toDeploy, issueType, uuid};
      data['itemIndex'] = index;
      data['ottoUrls'] = [id];
      await deploySubOttoUrls(data);
      setPageChanged(true);
      setIssueTypeSelected(issueType);
      setIsDeploying(true);
      if (uuid) {
        loadOttoV2Project(uuid, true);
      }
      if (toDeploy) {
        newNotification(issueType == 'page_title' ? (currentProject?.isActive ? '1 Change deployed' : '1 Change enabled') : '1 Change deployed', 2, 'deploy');
      } else {
        newNotification('1 Change rolled back', 2, 'rollback');
      }
      setSubUrlId(-1);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error: ', error);
      setSubUrlId(-1);
    }
  };
  const onAddNLPTerm = async record => {
    if (!keywords.length && !keywordString?.length) {
      setKeywordError(true);
      return;
    } else {
      setKeywordError(false);
    }
    setAddingNLPTerms(true);
    const filter = ottoUrls.filter(d => d.issueType === ottoUrls?.find(i => i?.issueType === componentIssueType)?.issueType);
    const enteredArray = keywordString.split(',').map(keyword => keyword.trim()).filter(keyword => keyword);
    const trimmedArray = keywords?.map(item => item?.trim());
    const resultString = trimmedArray?.join(',');
    const enteredString = enteredArray?.join(',');
    const finalString = resultString.concat(`${resultString?.length ? `,${enteredString}` : enteredString}`);
    const payload = {
      issue_type: record?.issueType,
      otto_url_id: record?.id,
      object_key: finalString,
    };
    try {
      await addSourcePage(uuid, payload);
      await loadIssueTableData({
        uuid: getOttoV2Project?.uuid,
        page_size: filter?.length ? filter[0].pageSize : 10,
        page: filter?.length ? filter[0].page : 1,
        issue_type: 'nlp_terms',
        otto_project: getOttoV2Project?.id,
      });
      notification.success('Keyword added successfully.', '', 2);
      setAddNLPTerms(false);
      setKeywordString('');
      setAddingNLPTerms(false);
      setKeywords([]);
    } catch (error) {
      setAddNLPTerms(false);
      setAddingNLPTerms(false);
    }
  };
  const onDropdownVisibleChange = (visible, id, recordIndex) => {
    setCurrentDelId(id);
    setOpenedIndex(visible ? recordIndex : null);
    if (!visible) {
      setAddNLPTerms(visible);
      setCurrentDelId(-1);
      setQADeleteVisible(false);
      setTermQAErrorState({...termQAErrorState, question: {...termQAErrorState['question'], state: false}, answer: {...termQAErrorState['answer'], state: false}});
    } else {
      setQADeleteVisible(true);
    }
  };
  const deleteIssue = async (id, itemIndex) => {
    if (deleteItem === -1) {
      setDeleteItem(`${itemIndex}${id}`);
      setDeleteIssueIds({parent: id, child: itemIndex});
      try {
        const onDelayEnd = async () => {
          const payload = {
            'uuid': uuid,
            'issue_type': componentIssueType,
            'item_index': itemIndex,
            'to_delete': true,
          };
          await deleteUrlIssue(id, uuid, payload);
          // notification.success('Issue deleted successfully', '', 2);
          setPageChanged(true);
          setIssueTypeSelected(componentIssueType);
          setIsDeploying(true);
          setDeleteItem(-1);
          setDeleteIssueIds({parent: null, child: null});
          if (uuid) {
            loadOttoV2Project(uuid, true);
          }
        };
        const handleUndo = () => {
          setDeleteItem(-1);
          setDeleteIssueIds({parent: null, child: null});
        };
        timeNotification('#FF0000', faTrashXmark, 4, `Issue deleted successfully`, ()=>handleUndo(), '#FF0000', onDelayEnd);
      } catch (error) {
        setDeleteIssueIds({parent: null, child: null});
        setUrlId(-1);
        setDeleteItem(-1);
      }
    }
  };


  const onDeploy = (record, state) => {
    if (canDeploy(record, record?.issueType)) {
      deployOttoUrl(record?.id, !state, record?.issueType);
      if (state) {
        setDeployLoading('Undeploy all');
      } else {
        setDeployLoading('Deploy all');
      }
    }
  };


  const nlpMenu = (data, index) => {
    const antUrlIcon = <LoadingOutlined style={{fontSize: 16, color: '#2AC155'}} spin />;
    const updatedData = data?.values?.map(item => ({
      ...item,
      issueType: data?.issueType,
      id: data?.id,
    }));
    const nlpTermColumns = [
      {
        title: (<div className='column-title'>
          STATUS
        </div>),
        dataIndex: 'status',
        key: 'status',
        width: 110,
        render: (_, record, index) => {
          return (<>
            <FreezeWrapper removeTooltip={(currentProject?.pixelTagState == 'installed' || currentProject?.pixelTagState == 'wp_plugin') && currentProject?.isEngaged ? record?.isApproved : false}>
              <StatusWrapper
                style={data?.id == deleteIssueIds?.parent && record?.itemIndex == deleteIssueIds?.child ? {display: 'none'} : {}}
                status={(currentProject?.pixelTagState == 'installed' || currentProject?.pixelTagState == 'wp_plugin') && currentProject?.isEngaged ? record?.isApproved : false}
                isDisabled={subUrlId !== -1}
                onClick={() => {
                  if (subUrlId !== -1) return;
                  deploySubOttoUrl(record?.id, index, !record?.isApproved, record?.issueType);
                }}
              >
                {subUrlId === index ? <Spin indicator={antUrlIcon} /> :
                  <FontAwesomeIcon icon={faCheckCircle} fontSize={20} color={(currentProject?.pixelTagState == 'installed' || currentProject?.pixelTagState == 'wp_plugin') && currentProject?.isEngaged ? record?.isApproved ? `#2AC155` : '#A3A4A4' : '#A3A4A4'} />
                }
                <span>{getStatusText(record?.isApproved, currentProject)}</span>
              </StatusWrapper>
            </FreezeWrapper>
          </>
          );
        },
      },
      {
        title: <div className='column-title'>NLP TERM</div>,
        dataIndex: 'nlp_terms',
        key: 'nlp_terms',
        width: '280px',
        render: (_, record) => {
          return <div className='nlp-term-name' style={data?.id == deleteIssueIds?.parent && record?.itemIndex == deleteIssueIds?.child ? {display: 'none'}: {}}>{record?.name}</div>;
        },
      },
      {
        title: <></>,
        dataIndex: 'trashIcon',
        key: 'trashIcon',
        render: (_, record) => {
          return (
            <div style={{display: data?.id == deleteIssueIds?.parent && record?.itemIndex == deleteIssueIds?.child ? 'none' : 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
              <FreezeWrapper placement='topRight' removeTooltip={(currentProject?.pixelTagState == 'installed' || currentProject?.pixelTagState == 'wp_plugin') && currentProject?.isEngaged ? record?.isApproved : false}>
                <Tooltip title={deleteItem === record?.itemIndex ? 'Deleting':'Delete'}>
                  {
                    deleteItem === `${record?.itemIndex}${record?.id}` ?
                      <Spin indicator={<LoadingOutlined style={{fontSize: 14, color: '#A3A4A4'}} spin />}/>:
                      <StyledTrashIcon icon={faTrash} fontSize={14} color='#A3A4A4' onClick={() => !ottoUrlLoader && showConfirmationPopup(record)}/>
                  }
                </Tooltip>
              </FreezeWrapper>
            </div>
          );
        },
      },
    ];

    const handleKeyPress = e => {
      if (e.key === 'Enter' && keywordString.trim()) {
        const newKeywords = keywordString.split(',').map(keyword => keyword.trim()).filter(keyword => keyword);
        setKeywords(prevKeywords => [...prevKeywords, ...newKeywords]);
        setKeywordString('');
        setKeywordError(false);
      }
    };
    const handleInputChange = e => {
      setKeywordString(e?.target?.value);
      setKeywordError(false);
    };
    const handleRemoveKeyword = keywordToRemove => {
      setKeywords(prevKeywords => prevKeywords.filter(keyword => keyword !== keywordToRemove));
    };

    const inputKeywordsCount = keywordString.split(',').map(keyword => keyword.trim()).filter(keyword => keyword)?.length;
    return (
      <StatusContainer style={{padding: 0, width: '520px', borderRadius: '1rem', border: '1px solid #ddd'}}>
        <div style={{padding: '6px 6px 10px 6px'}}>
          <div className='header'>
            <div className='status'>
              <div className='heading'>
                Deployed: {updatedData.filter(data => data?.isApproved)?.length} of {updatedData?.length}
              </div>
              <div className='button-wrap'>
                <FreezeWrapper>
                  <Tooltip title={!canDeploy(data, data?.issueType) && !data?.isActive ? 'Empty fields cannot be deployed.': ''}>
                    <div
                      className='button green'
                      style={{cursor: (!canDeploy(data, data?.issueType) && !data?.isActive) ? 'not-allowed':''}}
                      onClick={() => onDeploy(data, false)}>
                      Deploy all {deployLoading === 'Deploy all' && <StyledSpin />}
                    </div>
                  </Tooltip>
                </FreezeWrapper>
                <FreezeWrapper>
                  <Tooltip title={!canDeploy(data, data?.issueType) && !data?.isActive ? 'Empty fields cannot be deployed.': ''}>
                    <div
                      className='button red'
                      style={{cursor: (!canDeploy(data, data?.issueType) && !data?.isActive) ? 'not-allowed':''}}
                      onClick={() => onDeploy(data, true)}>
                      Undeploy all {deployLoading === 'Undeploy all' && <StyledSpin color={'red'} />}
                    </div>
                  </Tooltip>
                </FreezeWrapper>
              </div>
            </div>
            <div className='button-wrapper'>
              <FreezeWrapper placement='topRight'>
                <div onClick={() => {
                  if (deployLoading !== 'Deploy all' && deployLoading !== 'Undeploy all') {
                    setAddNLPTerms(true);
                    setKeywordError(false);
                    setKeywordString('');
                    if (termInputRef?.current) {
                      termInputRef.current.scrollTop = 0;
                    }
                  }
                }} style={{cursor: deployLoading !== 'Deploy all' && deployLoading !== 'Undeploy all' ? 'pointer' : 'not-allowed', color: '#2D6CCA'}}>+Add Term</div>
              </FreezeWrapper>
            </div>
          </div>
          <OnlyHeaderTAble
            columns={nlpTermColumns}
            loading={ottoUrlLoader}
            dataSource={[{}]}
            pagination={false}
            style={{width: '510px', margin: 0}}
            isInPopOver={true}
            scroll={{y: 300, x: 'auto'}}
          />
          <NlpTableWrapper style={{scrollBehavior: 'smooth'}} ref={index == openedIndex ? termInputRef : null}>
            <MiddleInputWrapper>
              {
                addNLPTerms ? (
                  <AddKeywordsWrapper style={{minWidth: 'auto'}}>
                    <div className='inner-wrapper'>
                      <div className='title'>Enter one or multiple keywords, separating them with commas</div>
                      <div className='input-wrapper'>
                        {keywords.length ? <div className='keywords-wrapper'>
                          {keywords?.map(keyword => <div className='keyword' key={keyword}>
                            {keyword}
                            <FontAwesomeIcon icon={faXmark} color='#4E5156' fontSize={14} style={{cursor: 'pointer'}} onClick={() => handleRemoveKeyword(keyword)}/>
                          </div>)}
                        </div> : null}
                        <StyledCloudStackInput
                          className='input'
                          autoFocus
                          disabled={addingNLPTerms}
                          style={{width: '100%'}}
                          value={keywordString}
                          onChange={handleInputChange}
                          onKeyDown={handleKeyPress}
                          placeholder='Type here...'
                        />
                      </div>
                      {keywordError ? <div className='keyword-error'>{keywordString?.length ? 'Please press enter to add keywords' : 'Enter at least 1 keyword'}</div> : ''}
                      <div className='buttons-wrapper'>
                        <Button
                          buttonType={'blue-button'}
                          disabled={addingNLPTerms || deployLoading === 'Deploy all' || deployLoading === 'Undeploy all'}
                          loading={addingNLPTerms}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '32px',
                            fontSize: '14px',
                            padding: '0px 10px',
                          }}
                          onClick={() => onAddNLPTerm(data)}
                        >
                          {!addingNLPTerms ? `Add ${keywords?.length+inputKeywordsCount > 0 ? keywords?.length+inputKeywordsCount : ''} keyword${keywords?.length+inputKeywordsCount > 1 ? 's' : ''}` :
                            `Adding ${keywords?.length+inputKeywordsCount > 0 ? keywords?.length+inputKeywordsCount : ''} keyword${keywords?.length+inputKeywordsCount > 1 ? 's' : ''}`}
                        </Button>
                        <div className='cancel-button' onClick={() => {
                          setAddNLPTerms(false);
                          setKeywordString('');
                          setKeywords([]);
                          setKeywordError(false);
                        }}>
                              Cancel
                        </div>
                      </div>
                    </div>
                  </AddKeywordsWrapper>
                ) : <></>
              }
            </MiddleInputWrapper>
            <StyledIssuesTable
              columns={nlpTermColumns}
              showHeader={false}
              dataSource={updatedData}
              pagination={false}
              style={{width: '510px', margin: 0}}
              isInPopOver={true}
              scroll={{y: 'none', x: 'none'}}
            />
          </NlpTableWrapper>
        </div>
      </StatusContainer>
    );
  };

  const nlpSchemaMenu = recommendedSchema => {
    return (
      <StatusMenu>
        <PopUpWrapper style={{width: '510px'}}>
          <div ref={jsonViewRef} className='container'>
            {typeof document !== 'undefined' &&
              Object.keys(recommendedSchema).length ?
              <ReactJson collapseStringsAfterLength={250} src={recommendedSchema} displayDataTypes={false} /> :
              <></>
            }
          </div>
        </PopUpWrapper>
      </StatusMenu>
    );
  };

  const selectUnselectPages = page => {
    if (selectedPages.includes(page)) {
      setSelectedPages(data => data.filter(item => item !== page));
      setSelectAll(false);
    } else {
      setSelectedPages(data => [...data, page]);
    }
  };
  const firstDeployTooltip = record => {
    return (<FirstDeployTooltipContent>
      <div className='title'>Have you completed the Knowledge Graph?</div>
      <div className='description'>Your Knowledge Graph must be 100% completed to get the most out of OTTO optimizations</div>
      <div className='buttons'>
        <div className='blue-button' onClick={() => {
          setVisibleFirstDeployTooltip('');
          setIsKnowledgeModalVisible(true);
        }}>Open Knowledge Graph</div>
        <div
          className='cancel-button'
          onClick={() => {
            setVisibleFirstDeployTooltip('');
            deployOttoUrl(record?.id, !record?.isActive, record?.issueType);
          }}
        >{`I’ll do it later`}</div>
      </div>
      <FontAwesomeIcon icon={faXmark} fontSize={14} className='close-icon' onClick={() => setVisibleFirstDeployTooltip('')}/>
    </FirstDeployTooltipContent>);
  };
  const columns = [
    {
      title: (removeTopBar ? <div style={{cursor: 'default'}} className='column-title'>
        <div>
          <span>STATUS</span>
        </div>
      </div> : <div className='column-title'>
        {<Dropdown overlay={
          <StyledMenu disabled={filteredData?.length == 0} selectedIssueType={url?.issueType} deployOttoSection={deployOttoSection} currentProject={currentProject}/>
        } trigger={['click']} placement='bottomLeft' overlayStyle={{top: '798px', boxShadow: '0px 1px 0px 0px #F4F4F4'}}>
          <div>
            <span>STATUS</span>
            <FontAwesomeIcon icon={faSortDown} color='#121212' fontSize={12}/>
          </div>
        </Dropdown>
        }
      </div>),
      dataIndex: 'status',
      key: 'status',
      width: '125px',
      render: (_, record) => {
        return (
          ['PENDING', 'STARTED'].includes(record?.nlpStatus) ? <Tooltip title={`Status: ${capitalizeFirstLetter(record?.nlpStatus)}`}><Spin indicator={<LoadingOutlined style={{fontSize: 16, color: '#219843'}} spin />} /></Tooltip> :
            <FreezeWrapper removeTooltip={record?.isActive}>
              <CustomTooltip title={(approvedSum === 0 && canDeploy(record, record?.issueType)) ?
                firstDeployTooltip(record) :
                canDeploy(record, record?.issueType) || record?.isActive ? '' : 'Empty fields cannot be deployed.'}
              {...(approvedSum === 0 && {visible: visibleFirstDeployTooltip === record?.id})}
              {...(approvedSum === 0 && {shouldOpenWithState: true})}
              {...(approvedSum === 0 && {overlayWidth: '377px'})}
              {...(approvedSum === 0 && {placement: 'bottomLeft'})}
              {...(approvedSum === 0 && {setVisible: setVisibleFirstDeployTooltip})}
              {...(approvedSum === 0 && {backgroundColor: '#000000'})}
              {...(approvedSum === 0 && {arrowColor: '#000000'})}
              {...(approvedSum === 0 && {padding: '18px 19px 20px 19px'})}>
                <StatusWrapper
                  status={record?.isActive}
                  onClick={() => {
                    if (approvedSum === 0 && canDeploy(record, record?.issueType)) {
                      setVisibleFirstDeployTooltip(record?.id);
                      return;
                    }
                    (canDeploy(record, record?.issueType) || record?.isActive) && deployOttoUrl(record?.id, !record?.isActive, record?.issueType);
                  }}
                  style={{position: record?.issueType === 'internal_link_suggestions' ? 'absolute': 'relative', top: record?.issueType === 'internal_link_suggestions' && '16px'}}
                  isDisabled={(!canDeploy(record, record?.issueType) && !record?.isActive)}
                >
                  {(record?.issueType === 'nlp_terms' && urlId === `${record?.id}nlp_terms`) ?
                    <div style={{fontWeight: 400, fontSize: '13px', color: '#2D6CCA'}}>
                      <Spin indicator={antBlueIcon} /> Updating
                    </div> : urlId === record?.id ? <Spin indicator={antUrlIcon} /> :
                      <>
                        <FontAwesomeIcon icon={faCheckCircle} fontSize={20} color={record?.isActive ? `#2AC155` : '#A3A4A4'} />
                        <span>{getStatusText(record?.isActive, currentProject)}</span>
                      </>}
                </StatusWrapper>
              </CustomTooltip>
            </FreezeWrapper>
        );
      },
    },
    {
      title: <div className='column-title'>PAGE URL</div>,
      dataIndex: 'url',
      key: 'url',
      width: '280px',
      render: (_, record) => {
        const path = (record?.path?.length && record?.path?.includes(domainName) && record?.path[0] === '/') ? record?.path.slice(1) : record?.path;
        return (<div style={{display: 'flex', alignItems: 'center', gap: 10}}>
          {showBulkBanner && <PurpleCheckbox checked={selectedPages.includes(record?.id)} onClick={()=>selectUnselectPages(record?.id)}/>}
          <UrlWrapper onClick={() => path?.includes(domainName) ? openUrl(path?.startsWith('http://') || path?.startsWith('https://') ? path : `https://${path}`, '_blank') : openUrl(`${addProtocolToDomain(domainName)}/${path?.startsWith('/') ? path.slice(1) : path}`, '_blank')}>
            {record?.path?.includes(domainName) && record?.path[0] === '/' ? record?.path.slice(1) : record?.path}</UrlWrapper>
        </div>);
      },
    },
    {
      title: <div className='column-title'>TARGET KEYWORDS</div>,
      dataIndex: 'targetKeywords',
      key: 'targetKeywords',
      width: '300px',
      render: (_, record) => {
        return (
          <DescriptionWrapper>
            {record?.keywords?.length ? record?.keywords?.map((keyword, idx) => (
              keyword + (idx !== record.keywords.length - 1 ? ', ' : '')
            )) : '-' }
            {['PENDING', 'STARTED'].includes(record?.nlpStatus) ? <></> : <FreezeWrapper><FontAwesomeIcon
              style={{cursor: 'pointer', marginLeft: '5px'}}
              icon={faPen}
              fontSize={12}
              color='#4E5156'
              onClick={() => {
                setModalConf({visible: true, keywords: record?.keywords, path: `${domainName}${record?.path}`, id: record?.id, issueType: record?.issueType});
              }} /></FreezeWrapper>}
          </DescriptionWrapper>
        );
      },
    },
    {
      title: <div className='column-title'>TERMS</div>,
      dataIndex: 'terms',
      key: 'terms',
      className: 'greenish-column',
      render: (id, record, index) => {
        const deployedValues = record?.values?.filter(value => value?.isApproved === true);
        return (
          ['PENDING', 'STARTED'].includes(record?.nlpStatus) ? <Tooltip title={`Status: ${capitalizeFirstLetter(record?.nlpStatus)}`}><Spin indicator={<LoadingOutlined style={{fontSize: 16, color: '#219843'}} spin />} /></Tooltip> : <div style={{width: '100%', maxWidth: '700px', cursor: 'pointer', opacity: disableCheck(record, urlId)}}>
            <Dropdown visible={qaDeleteVisible && currentDelId == record.id} onVisibleChange={e=> onDropdownVisibleChange(e, record.id, index)} overlay={()=> nlpMenu(record, index)} trigger={['click']} placement='bottomLeft' overlayStyle={{top: '798px', boxShadow: '0px 1px 0px 0px #F4F4F4', minWidth: 500, zIndex: 9}}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <span>Deployed:&nbsp;{deployedValues?.length}&nbsp;of&nbsp;{record?.values?.length}&nbsp;{['nlp_terms'].includes(record.issueType) ? 'terms' : ''}</span>
                <FontAwesomeIcon icon={faSortDown} color='#272222' fontSize={12} style={{marginLeft: 10}} />
              </div>
            </Dropdown>
          </div>
        );
      },
    },
    {
      title: <div className='column-title'>SCHEMA</div>,
      dataIndex: 'schema',
      key: 'schema',
      className: 'greenish-column',
      render: (_, record) => {
        return ['PENDING', 'STARTED'].includes(record?.nlpStatus) ? <Tooltip title={`Status: ${capitalizeFirstLetter(record?.nlpStatus)}`}><Spin indicator={<LoadingOutlined style={{fontSize: 16, color: '#219843'}} spin />} /></Tooltip> :
          <div style={{width: '100%', maxWidth: '700px', cursor: 'pointer', opacity: (urlId === `${record?.id}nlp_terms`) && '20%'}}>
            <Dropdown overlay={() => nlpSchemaMenu(record?.recommendedSchema)}
              trigger={['click']}
              placement='bottomLeft'
              overlayStyle={{top: '798px',
                boxShadow: '0px 1px 0px 0px #F4F4F4'}}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <span>View Schema</span>
                <FontAwesomeIcon icon={faSortDown} color='#272222' fontSize={12} style={{marginLeft: 10}} />
              </div>
            </Dropdown>
          </div>;
      },
    },
  ];

  const selectAllOnPage = () => {
    setSelectedPages(filteredData.map(data => data?.id));
  };

  const searchDebounce = debounce(async (params, isSitewide) => {
    await loadIssueTableData(params, isSitewide);
  }, 1000);

  const loadIssueTables = useCallback(async (issueArray: string[], issue: string, category: string, page: number, pageSize: number, stopLoading?: boolean, search?: string, rating?: string, activeKey?: string) => {
    setSelectedIssue(issue);
    setSelectedCategory(category);
    if (!stopLoading) {
      setLoadingDetail(true);
    }
    const categories = issue == 'All' ? issueTypesFrontendMappingV2.find(group=> group.subGroups.filter(g=> g.group == selectedCategory).length).group : issue;
    const issueObject = issueTypesFrontendMappingV2?.find(issueObj => issueObj.group === categories || issueObj.label === categories)?.subGroups?.find(categoryObj => categoryObj?.group === category);
    for (let index = 0; index < issueArray.length; index++) {
      const issueType = issueArray[index];
      const itemObject: any = issueObject?.subGroups?.find((item: any) => item?.group === issueType);
      if (issueTypeArray.includes(issueType)) {
        const params = {
          uuid,
          otto_project: getOttoV2Project?.id,
          issue_type: issueType,
          page_size: pageSize,
          page,
          is_loading: false,
          ...((activeKey == 'deployed' || activeKey == 'not_deployed') && {deploy_status: getDeployStatus(activeKey)}),
        };
        if (searchText) {
          params['search'] = searchText;
          setOttoSearchTerm(searchText);
        } else {
          setOttoSearchTerm('');
        }
        if (searchText) {
          searchDebounce(params, itemObject?.isSitewide);
        } else {
          await loadIssueTableData(params, itemObject?.isSitewide);
        }
      }
    }
    setLoadingDetail(false);
  }, []);
  const handlePaginationChange = (issueArray: string[], page, pageSize) => {
    const activeKey = issueArray?.length ? isActiveKeys?.find(item => item?.issue == issueArray[0])?.key : 'all';
    setNlpPagination(page, pageSize);
    loadIssueTables(issueArray, selectedIssue, selectedCategory, page, pageSize, true, searchText, '', activeKey);
  };

  return (
    <>
      <StyledIssuesCollapse
        ghost
        expandIconPosition='right'
        defaultActiveKey={[`${isTableOpen}`]}
        activeKey={[`${isTableOpen}`]}
        onChange={() => {
          if (isTableOpen === 'close') {
            setIsTableOpen('open');
            setIsOpenSearch(false);
          } else {
            setIsTableOpen('close');
            setIsOpenSearch(false);
          }
        }}
      >
        {!removeTopBar && <TableTopBar
          componentIssueType={componentIssueType}
          setPageChanged={setPageChanged}
          setIssueTable={setIssueTable}
          issueTable={issueTable}
          setSearchText={setSearchText}
          searchText={searchText}
          setIsOpenSearch={setIsOpenSearch}
          isOpenSearch={isOpenSearch}
          setIsTableOpen={setIsTableOpen}
          isTableOpen={isTableOpen}
          setOttoProject={setOttoProject}
          setIsActiveFilter={setIsActiveFilter}
          isActiveFilter={isActiveFilter}
          setCurrentIssueType={setCurrentIssueType}
          setOpenBulkPagesModal={setOpenBulkPagesModal}
          setAddBulkPagesFrom={setAddBulkPagesFrom}
          isResponsiveHeader
          setShowBulkBanner={setShowBulkBanner}
        />}
        {showBulkBanner ? <BulkActionBar
          count={url?.issueTable?.count}
          setShowBulkBanner={setShowBulkBanner}
          selectedPages={selectedPages}
          setSelectedPages={setSelectedPages}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
          selectAllOnPage={selectAllOnPage}
          issueType={url?.issueType}
          currentProject={currentProject}
          setPageChanged={setPageChanged}
        /> : <></>}
        <Collapse.Panel key='open' header={<></>}>
          <StyledIssuesTable
            loading={ottoUrlLoader && ottoIssueType === componentIssueType}
            columns={columns.filter(column => showBulkBanner ? column.key !== 'status' : true)}
            dataSource={filteredData}
            pagination={false}
            scroll={removeTopBar ? {x: 'auto', y: 'auto'} : width < 1100 ? {x: 'auto', y: 'auto'} : {}}
            maxHeight={maxHeight}
          />
          <PaginationStyled
            onChange={(page, pageSize) => {
              setPageChanged(true);
              setIssueTable(url?.issueType);
              handlePaginationChange([url?.issueType], page, pageSize);
              saveOttoTablePageSize(componentIssueType, pageSize);
              setPage(page);
              setPageSize(pageSize);
            }}
            total={url?.issueTable?.count}
            pageSize={pageSize}
            current={page}
            showSizeChanger
            pageSizeOptions={['5', '10', '20', '50', '100']}
          />
        </Collapse.Panel>
      </StyledIssuesCollapse>
      <EditTargetKeywordsModal modalConf={modalConf} setModalConf={setModalConf} uuid={uuid} />
      {<Wrapper>
        <Modal
          onClose={() => setOpenModal(false)}
          visible={openModal}
          className={'customConfirmationModal'}
        >
          <ModalWrapper>
            <p>Are you sure you want to delete this record? </p>
            <p className={'warningText'}>This action cannot be undone.</p>

            <DeleteButton
              buttonType='transparent' size='lg'
              loading={deleteItem !== -1}
              onClick={async () => {
                await deleteIssue(currentRecord?.id, currentRecord?.itemIndex);
                setOpenModal(false);
              }}>
            Delete
            </DeleteButton>
            <BackButton onClick={() => setOpenModal(false)} buttonType='transparent' size='lg'>
            Go Back
            </BackButton>

          </ModalWrapper>
        </Modal>
      </Wrapper>}
    </>
  );
});
