import React, {useEffect, useState} from 'react';
import {Globe} from 'react-feather';
import {Select as AntdSelect, SelectProps, Tooltip} from 'antd';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import {countryFlag} from '@/utils/countries';
import {COLORS} from '@/components/common-components/v2/colors';
import {debounce} from 'lodash';
import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react';
import styles from '../style.module.scss';
import {toJS} from 'mobx';
import {useRouter} from 'next/router';
import GrayRingLoader from '@/components/common-components/components/skeleton/gray-ring-loader';

const {Option} = AntdSelect;

interface Props extends SelectProps<any>{
  variant?: 'light' | 'dark' | 'default' | 'grey' | 'lightGray';
  width?: string;
  isKeywordv2?: boolean;
  contentBrief?: boolean;
  useLocationAsValue?: boolean;
  useCustomOnChange?: boolean;
  }

export const ScaNewArticleCountryDropdown = observer(({variant = 'light', useLocationAsValue=false, useCustomOnChange=false, ...props}: Props) => {
  const [searchInputQuery, setSearchInputQuery] = useState('');
  // const [locationData, setLocationData] = useState();
  const router = useRouter();
  const countryCode = router?.query?.countryCode?.toString()?.toUpperCase();
  const [key, setKey] = useState(Math.floor(Math.random() * 1000));
  const initialDefaultLocations = [
    {
      countryCode: 'US',
      location: 'United States',
      locationId: 2840,
    },
    {
      countryCode: 'CA',
      location: 'Canada',
      locationId: 2124,
    },
    {
      countryCode: 'AU',
      location: 'Australia',
      locationId: 2036,
    },
    {
      countryCode: 'GB',
      location: 'United Kingdom',
      locationId: 2826,
    },
    {
      countryCode: 'DE',
      location: 'Germany',
      locationId: 2276,
    },
  ];
  const [defaultLocations, setDefaultLocations] = useState(initialDefaultLocations);
  const country = defaultLocations?.find(item => item?.countryCode == countryCode);
  // NEW FLOW START

  const {
    countries: {countriesLoading},
    contentOptimizer: {currentPage, currentPage: {loadingCountriesList}},
    settings: {customer: {profile: {address}}},
  } = useStore('');

  const [value, setValue] = useState({
    label: <OptionContent variant={variant}>{countryFlag((countryCode ? countryCode : address?.defaultLocation ?? 'US'))} <span className={styles.locationName}>{country ? country?.location : address?.countryName ?? 'United States'}</span></OptionContent>,
    value: address?.locationId ?? 2840,
  });

  useEffect(() => {
    const checkDefultValue = initialDefaultLocations.some(val=> val.countryCode == address?.defaultLocation );
    if (address?.defaultLocation && !checkDefultValue) {
      setDefaultLocations([...initialDefaultLocations, {
        countryCode: address?.defaultLocation,
        location: address?.countryName,
        locationId: address?.locationId,
      }]);
    }
  }, [address?.defaultLocation]);

  const locationDebouncerChange = debounce( async (country: string) => {
    try {
      await currentPage.updateCountriesList(country);
    } catch (err) {
      return Promise.reject(err);
    }
  }, 500);

  const searchForLocation = (location?: string) => {
    if (location && location !== '') {
      locationDebouncerChange(location);
    }
  };

  const addEllipsis = (str, n)=>{
    return (str?.length > n) ? str?.slice(0, n-1) + '....' : str;
  };

  const resetFilter = value => {
    if (value) {
      setValue({
        label: value.label,
        value: value?.value,
      });
    }
    setSearchInputQuery('');
    setKey(Math.floor(Math.random() * 1000));
    currentPage.clearCountries();
  };

  const handleChange = (value, option) => {
    props?.onChange({
      label: value.label,
      value: value?.value,
      item: option.item || {
        countryCode: option.key.toLowerCase(),
        location: option.country,
        locationId: option.value,
      },
    }, option);
  };
  // NEW FLOW END
  return ( !countriesLoading ?
    <StyledSelect
      key={key}
      isKeywordv2={props?.isKeywordv2}
      {...props}
      value={value}
      variant={variant}
      className={styles.selectedItemContainer}
      suffixIcon={<FontAwesomeIcon color= {`${variant==('lightGray' || 'light') ? '#2D2F34': '#fff'}`} icon={faCaretDown}/>}
      dropdownStyle={{backgroundColor: variant === 'dark' ? COLORS.grayDark : variant === 'default' ? COLORS.grayDark: ''}}
      dropdownClassName={styles.dropdownContainer}
      filterOption={false}
      labelInValue={true}
      listHeight={328}
      {...(useCustomOnChange && {onChange: handleChange})}
      onSelect={value => resetFilter(value)}
      onBlur={() => resetFilter(null)}
      // onSelect={value => locationSelectedHandler(value)}
      onSearch={value => {
        setSearchInputQuery(value);
        if (value) {
          searchForLocation(value);
        } else {
          currentPage.clearCountries();
        }
      }}
    >
      {(loadingCountriesList && searchInputQuery) ? <div style={{height: '90px', display: 'flex', alignItems: 'center'}}><GrayRingLoader/></div> : ''}
      {!searchInputQuery ? <Option style={{backgroundColor: variant === 'dark' ? COLORS.grayDark : variant === 'default' ? COLORS.grayDark: COLORS.white}} country={'Worldwide'} value={''} key={''}>
        <OptionContent style={{display: 'flex', alignItems: 'center'}} variant={variant}><Globe style={{width: '16px', marginRight: 5}}/> <span className={styles.locationName}>{'Worldwide'}</span></OptionContent>
      </Option> : null}

      {!searchInputQuery ? defaultLocations.map(item => {
        return <Option style={{backgroundColor: variant === 'dark' ? COLORS.grayDark : variant === 'default' ? COLORS.grayDark: COLORS.white}} key={item?.countryCode} country={item.location} value={useLocationAsValue ? item.location : item.locationId} >
          {item?.location ? <OptionContent variant={variant}>{countryFlag((item.countryCode).toUpperCase())}<Tooltip title={item.location} mouseEnterDelay={.6} placement='right'> <span className={styles.locationName}>{item.location}</span></Tooltip></OptionContent> : null}
        </Option>;
      }) : null}

      {!searchInputQuery ? <div style={{fontSize: 12, backgroundColor: `${variant==('lightGray' || 'light') ? '#fff': 'rgb(45 47 52)'}`, color: `${variant==('lightGray' || 'light') ? '#2D2F34': '#fff'}`, pointerEvents: 'none'}}>Start typing to see more countries and precise locations...</div> : null}


      {(searchInputQuery && currentPage?.countries?.length && !loadingCountriesList) ? toJS(currentPage.countries).map((item, index) => {
        return <Option item={item} style={{backgroundColor: variant === 'dark' ? COLORS.grayDark : variant === 'default' ? COLORS.grayDark: COLORS.white}} key={index} country={item.location} value={useLocationAsValue ? item.location : item.locationId}>
          {item?.location ? <OptionContent variant={variant}>{countryFlag((item?.countryCode || '').toUpperCase())}<Tooltip title={item.location} mouseEnterDelay={.6} placement='right'><span className={styles.locationName}>{addEllipsis(item.location || '', 15)}</span></Tooltip></OptionContent> :
            null}
        </Option>;
      }) : null}

    </StyledSelect> :
    null);
});
const variantColors = {
  default: {
    color: COLORS.white,
    backgroundColor: 'transparent',
    borderColor: 'transparent',

  },
  light: {
    color: COLORS.black,
    backgroundColor: COLORS.white,
    borderColor: COLORS.grayDark,

  },
  grey: {
    color: COLORS.black,
    backgroundColor: COLORS.white,
    borderColor: COLORS.textGrayLight,
  },
  lightGray: {
    color: COLORS.black,
    backgroundColor: COLORS.tableGray,
    borderColor: COLORS.textGrayLight,
  },
  dark: {
    color: COLORS.white,
    backgroundColor: COLORS.grayDark,
    borderColor: COLORS.grayDark,
  }};


const StyledSelect = styled(AntdSelect)<Props>`
  color:${ p => variantColors[p.variant].color};
  .ant-select-selector {
    max-width:180px !important;
    min-width: ${p => p.width ? p.width : '168px'} !important;
    width: ${p => p.width ? p.width : '168px'} !important;
    margin-left:6px;
    border-radius: 5px !important;
    background-color: ${ p => variantColors[p.variant].backgroundColor} !important;
    border-color: ${ p => variantColors[p.variant].borderColor} !important;
    padding: 6px 15px !important;
    @media screen and (max-width: 420px) {
      width: 150px !important;
      min-width: auto !important;
      padding-right: 0 !important;
      padding-left: 0 !important;
      margin-left: 0 !important;
    }
    
    height:${p=>p.isKeywordv2 ? '30px !important' : '44px !important' };

    cursor: pointer;
    line-height: unset !important;

    &:focus-visible {
      outline: none !important;
    }
  }
  .ant-select-selection-search {
    padding: ${p=>p.isKeywordv2 ? '0px 0px' : '6px 0'};
  }

 .ant-select-arrow {
  color:${ p => variantColors[p.variant].color};
  right: 45px !important;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    background-color: ${ p => variantColors[p.variant].backgroundColor} !important;
    border-color: ${ p => variantColors[p.variant].borderColor} !important
  }
  .ant-select-focused .ant-select-selector {
    box-shadow: none !important;
  }
`;


const OptionContent = styled.div<{variant: string}>`
  background-color: ${ p => variantColors[p.variant].backgroundColor} !important;
  border-color: ${ p => variantColors[p.variant].borderColor} !important;
  color:${ p => variantColors[p.variant].color} !important;
  max-width: 500px;
  min-width: 300px;
  height: 100%;
  overflow: hidden;
  white-space: pre-wrap;
  display: flex;
  align-items: ${p=>p.variant == 'lightGray' ? 'center' : 'flex-start' };
  line-height: 15px;
  font-weight: 400;
  display: flex;

  img {
    margin-top: 0px;
    margin-right: 5px;
  }
    &:active,focus{
      background-color: ${ p => variantColors[p.variant].backgroundColor} !important;
      border-color: ${ p => variantColors[p.variant].borderColor} !important;
      color:${ p => variantColors[p.variant].color} !important;
    }
`;
