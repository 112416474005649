import {useStore} from '@/store/root-store';
import {DownOutlined} from '@ant-design/icons';
import {faCircleInfo, faMagnifyingGlass} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Dropdown,
  Input,
  Menu, Switch,
  Tooltip} from 'antd';
import Table, {ColumnsType} from 'antd/lib/table';
import {useEffect, useState} from 'react';
import styled from 'styled-components';
import {StyledSpin} from '@/components/dashboard/pages/otto-page-detail/Utils/styledSpin';
import {getEmailCategoryIcons} from '@/utils/icons';
import {observer} from 'mobx-react-lite';
import {CommonDropdown} from '../dropdown';
import ButtonComponent from '../button-component';
import useWindowSize from '@/utils/hooks/useWindowSize';
import {REPORT_BUILDER_API} from '@/api/report-builder';
import {notification} from '@/utils/notification-v2';
import {apiError} from '@/utils/api';
import {SITES_API} from '@/api/gsc/sites/sites-api';
import {camelToSnakeCase} from '@/utils/string';
import {AUDITED_SITES_API_V2} from '@/api/SiteAuditorV2';


const toolTipsInfo = {
  'se_weekly_update': "Compare your site's performance to the previous week and track improvements!",
  'local_seo_weekly_update': 'Discover the keywords with the biggest improvement compared to last week!',
  'local_seo_event_based_update': 'Get notified when a keyword sees an improvement of over 30%!',
  'otto_postprocessing_email': 'See your optimization score, detected issues, affected pages, and time saved with OTTO fixes.',
  'otto_weekly_email': 'Get a weekly recap of performance, deployments, and completed OTTO SEO tasks.',
  'rb_ai_summary': "Receive AI-generated reports to track your SEO strategy's results.",
  'gsc_weekly_update': 'View impressions, clicks, and ranking changes over the past 7 days.',
  'gsc_monthly_update': 'Track impressions, clicks, and ranking changes over the past 30 days.',
};

const prefixes = [
  {
    label: 'General Emails',
    value: 'ge',
    hide: true,
    setting: {
      ge_trial_started: 'Trial started',
      ge_trial_abandoned: 'Trial abandoned',
      ge_project_created: 'Project created',
    },
  },
  {
    label: 'OTTO',
    value: 'otto',
    hide: false,
    setting: {
      otto_postprocessing_email: 'Post processing',
      otto_weekly_email: 'Weekly update',
    },
  },
  {
    label: 'Site Explorer',
    value: 'se',
    hide: false,
    setting: {
      se_weekly_update: 'Weekly update',
    },
  },
  {
    label: 'Report Builder',
    value: 'rb',
    hide: false,
    setting: {
      rb_ai_summary: 'AI Summary',
    },
  },
  {
    label: 'Google Search Console',
    value: 'gsc',
    hide: false,
    setting: {
      gsc_weekly_update: 'Weekly update',
      gsc_monthly_update: 'Monthly update',
    },
  },
  {
    label: 'Local SEO',
    value: 'local_seo',
    hide: false,
    setting: {
      local_seo_weekly_update: 'Weekly update',
      local_seo_event_based_update: 'Event based update',
      local_seo_gmb_post_processing: 'GBP Post processing',
    },
  },
  {
    label: 'Site Audit',
    value: 'sa',
    hide: true,
    setting: {
      sa_issue_detected: 'Issue detected',
    },
  },
  {
    label: 'Aggregator Network',
    value: 'an',
    hide: true,
    setting: {
      an_weekly_update: 'Weekly update',
    },
  },
  {
    label: 'Outreach Campaigns',
    value: 'or',
    hide: true,
    setting: {
      or_post_processing: 'Post processing',
      or_weekly_update: 'Weekly update',
    },
  },
];

type Props = {
  onClose: any;
};

export const EmailSetting = observer(({onClose}: Props) => {
  const {
    reportBuilder: {loadReportBuilderProjectsList},
    navBar: {customerEmailSettingLoading, getSearchConsoleList},
    settings: {customer: {
      profile: {
        customerId,
        isLinkgraph,
      },
    },
    teamMembers: {
      getTeamMember,
    }},
  } = useStore('');

  const {height} = useWindowSize();
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [tableHeight, setTableHeight] = useState(630);
  const [teamMembersData, setTeamMembersData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [emailSettingObj, setEmailSettingObj] = useState({
    csEmailSetting: {},
    keEmailSetting: {},
    saEmailSetting: {},
    ottoEmailSetting: {},
  });
  const [projectList, setProjectList] = useState({
    'ge': [],
    'otto': [],
    'se': [],
    'rb': [],
    'gsc': [],
    'local_seo': [],
    'sa': [],
    'an': [],
    'or': [],
    'geLoader': false,
    'ottoLoader': true,
    'seLoader': true,
    'rbLoader': true,
    'gscLoader': true,
    'local_seoLoader': true,
    'saLoader': false,
    'anLoader': true,
    'orLoader': true,
    'geHide': true,
    'ottoHide': false,
    'seHide': false,
    'rbHide': false,
    'gscHide': false,
    'local_seoHide': false,
    'saHide': false,
    'anHide': false,
    'orHide': false,
  });

  const labels = {
    'local_seo': 'keEmailSetting',
    'se': 'keEmailSetting',
    'an': 'keEmailSetting',
    'or': 'keEmailSetting',
    'rb': 'csEmailSetting',
    'otto': 'ottoEmailSetting',
    'ge': 'csEmailSetting',
    'sa': 'csEmailSetting',
    'gsc': 'saEmailSetting',
  };

  const variablesList = {
    'local_seo': emailSettingObj['keEmailSetting'],
    'se': emailSettingObj['keEmailSetting'],
    'an': emailSettingObj['keEmailSetting'],
    'or': emailSettingObj['keEmailSetting'],
    'rb': emailSettingObj['csEmailSetting'],
    'otto': emailSettingObj['ottoEmailSetting'],
    'ge': emailSettingObj['csEmailSetting'],
    'sa': emailSettingObj['csEmailSetting'],
    'gsc': emailSettingObj['saEmailSetting'],
  };


  const updateEmailSettingObj = values => {
    setEmailSettingObj(prev => ({
      ...prev,
      ...values,
    }));
  };

  const updateState = values => {
    setProjectList(prev => ({
      ...prev,
      ...values,
    }));
  };

  const loadList = async () => {
    // Helper function to handle mapping and state update
    setInitialLoading(true);
    const mapData = (dataList, loaderKey, stateKey, logoKey = 'logo') => {
      if (dataList && Array.isArray(dataList)) {
        const mappedData = dataList.map(data => ({
          id: data.id || data.uuid || data.metrics?.url || data.businessName,
          value: data.domain || data.metrics?.url || data.businessName || data.hostname || data.url || data.name || data.propertyUrl,
          logo: data[logoKey] || '',
        }));
        updateState({[`${loaderKey}`]: false, [stateKey]: mappedData});
      }
    };

    try {
      const [
        reportBuilderList,
        competitorResearchList,
        localSeoProjectsList,
        ottoProjectsList,
        searchConsoleList,
        campaignsList,
        aggregatorNetwork,
        auditedSitesList,
      ] = await Promise.all([
        loadReportBuilderProjectsList(),
        SITES_API.getCompetitorResearchList(),
        SITES_API.getGoogleBusinessList(),
        REPORT_BUILDER_API.loadOttoProjects(),
        getSearchConsoleList(),
        SITES_API.getOutreachCampaignsList(),
        SITES_API.getAggregatorNetworkList(),
        AUDITED_SITES_API_V2.getAuditedSitesList({}),
      ]);
      // Mapping and state update for each list
      mapData(reportBuilderList, 'rbLoader', 'rb', 'domain');
      mapData(competitorResearchList, 'seLoader', 'se', 'url');
      mapData(localSeoProjectsList, 'local_seoLoader', 'local_seo', 'websiteUrl');
      mapData(ottoProjectsList, 'ottoLoader', 'otto', 'hostname');
      mapData(searchConsoleList, 'gscLoader', 'gsc', 'url');
      mapData(campaignsList, 'orLoader', 'or');
      mapData(aggregatorNetwork, 'anLoader', 'an');
      mapData(auditedSitesList?.results || [], 'saLoader', 'sa', 'propertyUrl');
      setInitialLoading(false);
    } catch (error) {
      setInitialLoading(false);
    }
  };


  useEffect(() => {
    loadList();
    getEmailSettingsList();
  }, []);

  const restructuredKeys = obj => {
    let data = {};
    Object.entries(obj).map(([key, val]) => {
      data = {...data, [camelToSnakeCase(key)]: val};
      return;
    });
    return data;
  };

  const getEmailSettingsList = async () => {
    const [getKEmailSetting, getEmailSettingData, getSearchConsoleSettings, getOTTOEmailSetting] = await Promise.all([
      SITES_API.getKECustomerEmailSetting(),
      SITES_API.getCustomerEmailSetting(),
      SITES_API.getSearchConsoleEmailSettingList(),
      SITES_API.getOTTOCustomerEmailSetting(),
    ]);
    const gscList = restructuredKeys(getSearchConsoleSettings) as any;
    const updatedObj = {...gscList, gsc_recipients: gscList.recipients, gsc_project_ids: gscList.property_ids, gsc_weekly_update: gscList.weekly_update, gsc_monthly_update: gscList.monthly_update};
    updateEmailSettingObj({'csEmailSetting': restructuredKeys(getEmailSettingData),
      'keEmailSetting': restructuredKeys(getKEmailSetting),
      'saEmailSetting': updatedObj,
      'ottoEmailSetting': restructuredKeys(getOTTOEmailSetting),
    });
  };

  useEffect(() => {
    if (height) {
      setTableHeight(height-320);
    }
  }, [height]);

  useEffect(() => {
    if (getTeamMember?.length) {
      setTeamMembersData(getTeamMember?.filter(item => item?.isActive !== false).map(member => ({id: member?.userId, value: member?.email})));
    } else {
      setTeamMembersData([]);
    }
  }, [JSON.stringify(getTeamMember)]);

  let oldVal;
  const updateRecipientsData = async (objKey, key, value, state) => {
    if (oldVal == value) return;
    oldVal = value;
    let data = {};
    if (!state) {
      data = emailSettingObj[objKey];
      const emailList = data[key];
      const updatedList = emailList.filter(item => item !== value);
      data[key] = updatedList;
    } else {
      data = emailSettingObj[objKey];
      const emailList = data[key];
      emailList.push(value);
      data[key] = emailList;
    }
    updateEmailSettingObj({[objKey]: data});
  };

  const updateEmailActivate = (objKey, key, value) => {
    if (oldVal == value) return;
    oldVal = value;
    let data = {};
    data = emailSettingObj[objKey];
    data[key] = value;
    updateEmailSettingObj({[objKey]: data});
  };

  const selectDeselectAllRecipients = (objKey, state, key, List) => {
    let data = {};
    if (!state) {
      data = emailSettingObj[objKey];
      data[key] = [];
    } else {
      data = emailSettingObj[objKey];
      data[key] = List;
    }
    updateEmailSettingObj({[objKey]: data});
  };

  const bulkEmailSetting = async state => {
    let data = {};
    prefixes.forEach(item => {
      let val;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const [key, _] of Object.entries(item.setting)) {
        val = labels[item.value];
        data = variablesList[item.value];
        data[key] = state;
      }
      updateEmailSettingObj({[val]: data});
    });
    try {
      await Promise.all([
        SITES_API.updateKECustomerEmailSetting(emailSettingObj['keEmailSetting']['id'], emailSettingObj['keEmailSetting']),
        SITES_API.updateCustomerEmailSetting(customerId, emailSettingObj['csEmailSetting']),
        SITES_API.updateSearchConsoleCustomerEmailSetting(emailSettingObj['saEmailSetting']['id'], {...emailSettingObj['saEmailSetting'], property_ids: emailSettingObj['saEmailSetting']['gsc_project_ids'], recipients: emailSettingObj['saEmailSetting']['gsc_recipients'],
          weekly_update: emailSettingObj['saEmailSetting']['gsc_weekly_update'], monthly_update: emailSettingObj['saEmailSetting']['gsc_monthly_update']}),
        SITES_API.updateOTTOCustomerEmailSetting(emailSettingObj['ottoEmailSetting']['id'], {
          customer: emailSettingObj['ottoEmailSetting']['customer'],
          project_ids: emailSettingObj['ottoEmailSetting']['otto_project_ids'],
          email_types: {
            'weekly_email': emailSettingObj['ottoEmailSetting']['otto_weekly_email'],
            'postprocessing_email': emailSettingObj['ottoEmailSetting']['otto_postprocessing_email'],
          },
          recipients: {
            'weekly_email': emailSettingObj['ottoEmailSetting']['otto_recipients'],
            'postprocessing_email': emailSettingObj['ottoEmailSetting']['otto_recipients'],
          },
        }),
      ]);
      notification.success('Email Settings Updated', '');
    } catch (error) {
      const errorMessage = apiError(error) as string;
      notification.error('', errorMessage);
    }
  };

  const updateSettings = async () => {
    try {
      setLoading(true);
      await Promise.all([
        SITES_API.updateKECustomerEmailSetting(emailSettingObj['keEmailSetting']['id'], emailSettingObj['keEmailSetting']),
        SITES_API.updateCustomerEmailSetting(customerId, emailSettingObj['csEmailSetting']),
        SITES_API.updateSearchConsoleCustomerEmailSetting(emailSettingObj['saEmailSetting']['id'], {...emailSettingObj['saEmailSetting'], property_ids: emailSettingObj['saEmailSetting']['gsc_project_ids'], recipients: emailSettingObj['saEmailSetting']['gsc_recipients'],
          weekly_update: emailSettingObj['saEmailSetting']['gsc_weekly_update'], monthly_update: emailSettingObj['saEmailSetting']['gsc_monthly_update']}),
        SITES_API.updateOTTOCustomerEmailSetting(emailSettingObj['ottoEmailSetting']['id'], {
          customer: emailSettingObj['ottoEmailSetting']['customer'],
          project_ids: emailSettingObj['ottoEmailSetting']['otto_project_ids'],
          email_types: {
            'weekly_email': emailSettingObj['ottoEmailSetting']['otto_weekly_email'],
            'postprocessing_email': emailSettingObj['ottoEmailSetting']['otto_postprocessing_email'],
          },
          recipients: {
            'weekly_email': emailSettingObj['ottoEmailSetting']['otto_recipients'],
            'postprocessing_email': emailSettingObj['ottoEmailSetting']['otto_recipients'],
          },
        }),
      ]);
      notification.success('Email Settings Updated', '');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const errorMessage = apiError(error) as string;
      notification.error('', errorMessage);
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Category',
      dataIndex: 'label',
      key: 'label',
      className: 'category-col',
      render: (_, record) => {
        /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
        const activeSettings = Object.entries(record?.setting)?.filter(([key, _]) => variablesList[record?.value][`${key}`])?.length;
        return (
          <div style={{display: 'flex', gap: '12px'}}>
            <div style={{width: '34px', height: '34px', borderRadius: '12px', backgroundColor: 'rgba(45, 108, 202, 20%)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              {<FontAwesomeIcon icon={getEmailCategoryIcons[record?.value]} color='#2D6CCA' fontSize={16}/>}
            </div>
            <div>
              <div>{record?.label}</div>
              {
                activeSettings ?
                  <>
                    {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
                    <div>Active: {Object.entries(record?.setting)?.filter(([key, _]) => variablesList[record?.value][`${key}`])?.length} of {Object.entries(record?.setting)?.length}</div>
                  </> :
                  <div>No active email{Object.entries(record?.setting)?.length > 1 ? 's' : ''}</div>
              }
            </div>
          </div>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'emailSettings',
      key: 'emailSettings',
      render: (_, record) => {
        return (<div>
          {
            Object.entries(record?.setting).filter(obj => (record?.value === 'local_seo' && !isLinkgraph) ? obj[0] !== 'local_seo_gmb_post_processing' : true ).map(([key, val], index) => {
              return (
                <div key={index} style={{display: 'flex', alignItems: 'center', gap: '10px', marginBottom: Object.entries(record?.setting)?.length === index+1 ? '0px' :'16px'}}>
                  <Switch checked={variablesList[record?.value] && variablesList[record?.value][`${key}`]} onChange={value => updateEmailActivate(labels[record?.value], key, value)} />
                  <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                    {val as any}

                    {Object.keys(toolTipsInfo).includes(key) && (
                      <Tooltip title={toolTipsInfo[key]}>
                        <FontAwesomeIcon icon={faCircleInfo} color='#A3A4A4' fontSize={16}/>
                      </Tooltip>
                    )}

                  </div>
                </div>
              );
            })
          }
        </div>);
      },
    },
    {
      title: 'Recipients',
      dataIndex: 'recipients',
      key: 'recipients',
      className: 'recipients-col',
      render: (_, record) => {
        const selected = variablesList[record?.value] && variablesList[record?.value][`${record?.value}_recipients`];
        return <CommonDropdown
          list={teamMembersData}
          selectedList={selected}
          onSelectAll={list => selectDeselectAllRecipients(labels[record?.value], true, `${record?.value}_recipients`, list)}
          onDeselectAll={list => selectDeselectAllRecipients(labels[record?.value], false, `${record?.value}_recipients`, list)}
          onChange={(value, state) => updateRecipientsData(labels[record?.value], `${record?.value}_recipients`, value, state)}
          title={`Only selected recipients will receive ${record?.label} email updates.`}
          key={record?.value}
        />;
      },
    },
    {
      title: 'Project / Domain',
      dataIndex: 'projectDomain',
      key: 'projectDomain',
      className: 'project-domain-col',
      render: (_, record) => {
        const selected = variablesList[record?.value] && variablesList[record?.value][`${record?.value}_project_ids`];
        if (!projectList[`${record?.value}Hide`]) {
          return <CommonDropdown list={projectList[record?.value]} selectedList={selected}
            loading={projectList[`${record?.value}Loader`]}
            onSelectAll={list =>selectDeselectAllRecipients(labels[record?.value], true, `${record?.value}_project_ids`, list)}
            onDeselectAll={list =>selectDeselectAllRecipients(labels[record?.value], false, `${record?.value}_project_ids`, list)}
            onChange={(value, state) => updateRecipientsData(labels[record?.value], `${record?.value}_project_ids`, value, state)}
            title={`You'll receive ${record?.label} email updates for the chosen domains.`}
            keyValue={record?.value}
            key={record?.value}
            useId={true}
          />;
        }
      },
    },
  ];


  const menu = (
    <EmailMenu>
      <MenuItem style={{padding: 0}} key='1' onClick={() => bulkEmailSetting(true)}>Enable all emails</MenuItem>
      <MenuItem style={{padding: 0}} key='2' onClick={() => bulkEmailSetting(false)}>Disable all emails</MenuItem>
    </EmailMenu>
  );

  const getPopupContainer = triggerNode => triggerNode;
  return (
    <>
      <EmailContainer>
        <div>
          {
            customerEmailSettingLoading || initialLoading ?
              <StyledSpin color='#2D6CCA' fontSize={70} justifyContent='center' height='300px'/> :
              <>
                {/* <div className='email-heading'>Email Settings</div> */}
                <div className='email-searchbar'>
                  <Input
                    size='small'
                    placeholder='Search category'
                    onChange={e => setSearchValue(e.target.value)}
                    prefix={
                      <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        fontSize={'12px'}
                        color='#A3A4A4'
                      />
                    }
                    style={{
                      width: '200px',
                      borderRadius: '6px',
                      border: '1px solid #CDCDCD',
                    }}
                  />
                  <div></div>
                  <Dropdown overlay={menu} trigger={['click']} placement='bottomCenter' getPopupContainer={getPopupContainer} overlayStyle={{width: 180}}>
                    <h1 className='dropdown-heading'>
                    Bulk actions <DownOutlined size={20} />
                    </h1>
                  </Dropdown>
                </div>
                <StyledEmailSettingTable columns={columns} dataSource={prefixes.filter(d => !isLinkgraph ? !d.hide : true).filter(d => searchValue ? d.label.toLowerCase().includes(searchValue.toLowerCase()) : true)} pagination={false} scroll={{y: tableHeight}}/>
              </>
          }
        </div>
        <StyledFooter>
          <div className='cancel-btn' style={{cursor: 'pointer'}} onClick={onClose}>Cancel</div>
          <ButtonComponent color='blue' onClick={updateSettings} disabled={loading}>
            <div style={{display: 'flex', gap: 10}}>
              {
                loading ?
                  <StyledSpin color='white'/> : <></>
              }
              Save changes
            </div>
          </ButtonComponent>
        </StyledFooter>
      </EmailContainer>
    </>
  );
});

const StyledEmailSettingTable = styled(Table)`
  .ant-table-thead > tr {
    th {
      background-color: #F2F2F5;
      padding: 9px 16px;
      font-family: 'Inter', sans-serif;
      font-size: 12px;
      font-weight: 600;
      line-height: 14.52px;
      &:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        display: none !important;
      }
      &.category-col {
        border-top-left-radius: 6px !important;
        border-bottom-left-radius: 6px;
      }
      &.project-domain-col {
        border-top-right-radius: 6px !important;
        border-bottom-right-radius: 6px;
      }
    }
  }
  .ant-table-body {
    tr {
      vertical-align: baseline;
    }
    &::-webkit-scrollbar {
      height: 5px;
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(138, 138, 138, 0.32);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-track {
      background: rgba(138, 138, 138, 0.12);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
  }
`;
const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 30px;
  margin-top: 14px;
  padding-bottom: 15px;
`;
const EmailContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex
;
  flex-direction: column;
  justify-content: space-between;

  .email-heading {
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    padding: 10px;
    font-weight: 600;
    line-height: normal;
    color: #121212;
  }
  .email-searchbar {
    display: flex;
    padding: 10px 2px;
    justify-content: space-between;
    align-items: center;
  }
  .dropdown-heading {
    cursor: pointer;
    font-family: "Inter" sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    color: #2d6cca;
  }
`;
const EmailMenu = styled(Menu)`
  /* background-color: red !important; */
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: #f9f9fb;

  .ant-dropdown-menu-title-content {
    padding: 10px 30px;
  }
`;

const MenuItem = styled(Menu.Item)`
  .ant-dropdown-menu-title-content {
    padding: 8px 20px;
  }
`;
