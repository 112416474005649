import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Modal, Button, Select, Input, Tooltip} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinnerThird, faXmark} from '@fortawesome/pro-regular-svg-icons';
import {observer} from 'mobx-react';

interface Props {
  descriptionValue?: any;
  visible: boolean;
  setVisible: any;
  setModalLoader: any;
  modalLoader: boolean;
}

export const EmbedMapModal = observer(({descriptionValue, visible, setVisible, setModalLoader, modalLoader}: Props) => {
  const sizeOptions = [
    {value: '500px|300px', label: 'Small'},
    {value: '600px|450px', label: 'Medium'},
    {value: '800px|600px', label: 'Large'},
    {value: '550px|200px', label: 'Custom'},
  ];
  const [modalSize, setModalSize] = useState({width: '600px', height: '450px'});
  const [customWidth, setCustomWidth] = useState('550px');
  const [customHeight, setCustomHeight] = useState('200px');
  const [isCustom, setIsCustom] = useState(false);
  const [copied, setCopied] = useState(false);
  const embedUrl = `${descriptionValue}&output=embed`;
  const iframeCode = `<iframe
  src="${embedUrl}"
  loading="lazy"
  width="${ isCustom? customWidth : modalSize.width}"
  height="${isCustom? customHeight : modalSize.height}"
  referrerPolicy="no-referrer-when-downgrade"
  title="Google Map"
  allowFullScreen
  style={{ border: '0' }}
/>`;

  const handleCopyClick = () => {
    navigator.clipboard?.writeText(iframeCode).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 1500);
    });
  };
  const handleSizeChange = (value: string) => {
    const [width, height] = value.split('|');
    if (width === '550px') {
      setIsCustom(true);
      setModalSize({
        width,
        height,
      });
    } else {
      setIsCustom(false);
      setModalSize({
        width,
        height,
      });
    }
  };
  const handleIframeLoad = () => {
    setModalLoader(false);
  };

  useEffect(() => {
    if (visible) {
      setModalLoader(true);
    }
  }, [visible]);
  const handleOpenCustomWindow = () => {
    const customIframeUrl = `${descriptionValue}&output=embed`;
    window.open(customIframeUrl, '_blank', `width=${customWidth}, height=${customHeight}`);
  };
  return (
    <StyledModal
      open={visible}
      onCancel={() => setVisible(false)}
      width={modalSize?.width}
      closable={true}
      bodyStyle={{height: modalSize?.height}}
      closeIcon={<FontAwesomeIcon icon={faXmark} />}
      footer={false}
      title='Embed Map'
    >
      <>
        {modalLoader && <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: 422, width: 549, position: 'absolute', zIndex: 1}}><FontAwesomeIcon icon={faSpinnerThird} spin fontSize={44}/></div>}
        <StyleContainer isCustom={isCustom}>
          <StyledSelect isCustom={isCustom}>
            <Select
              options={sizeOptions}
              defaultValue={sizeOptions[1]?.value}
              onChange={handleSizeChange}

            />
          </StyledSelect>
          {isCustom && (
            <>
              <StyledInput
                type='number'
                placeholder='Width (px)'
                value={parseInt(customWidth) || ''}
                onChange={e => setCustomWidth(e.target.value)}
                style={{marginBottom: '10px'}}
              />
              <span style={{marginTop: '5px'}}>X</span>
              <StyledInput
                type='number'
                placeholder='Height (px)'
                value={parseInt(customHeight) || ''}
                style={{marginBottom: '10px'}}
                onChange={e => setCustomHeight(e.target.value)}
              />
              <Button onClick={handleOpenCustomWindow} type='link' style={{color: '#2D6CCA'}} >
              Preview actual size
              </Button>
            </>

          )}
          <InputContainer isCustom={isCustom} >{iframeCode}</InputContainer>
          <Tooltip title={copied ? 'Copied Successfully!' : ''} visible={copied}>
            <ResponsiveButton onClick={handleCopyClick} type='link' style={{marginBottom: '10px', fontSize: '12px', color: '#2D6CCA'}}>
            Copy HTML
            </ResponsiveButton>
          </Tooltip>

        </StyleContainer>

        {!isCustom && (
          <IframeContainer >
            <iframe
              src={embedUrl}
              className='absolute inset-0 w-full h-full border-0'
              loading='lazy'
              referrerPolicy='no-referrer-when-downgrade'
              title='Google Map'
              allowFullScreen
              style={{border: '0', height: '100%', width: '100%'}}
              onLoad={handleIframeLoad}
            />
          </IframeContainer>
        )}

      </>
    </StyledModal>
  );
});


const IframeContainer = styled.div`
  position: relative;
  height: calc(100% - 101px);
flex-grow: 1;
  border: 1px solid #c7c7c7;
`;

const StyleContainer = styled.div<{isCustom: boolean}>`
  color: #1f1f1f;
  max-height: calc(100vh - 140px);
  overflow: auto;
  width: 100%;
  display: flex;
  gap: 10px;
  padding: 10px;
  justify-content: space-between;
flex-wrap: ${({isCustom}) => (isCustom ? 'wrap' : 'nowrap')};
  
  @media screen and (max-width: 768px) {
    flex-direction: row;
  }
`;

const StyledSelect = styled.div <{isCustom: boolean}>`
  flex-grow: ${({isCustom}) => (isCustom ? '0' : '1')};  
  max-width: ${({isCustom}) => (isCustom ? '100%' : '250px')};  
  margin-right: 10px;
  font-size: 13px;
  margin-top: ${({isCustom}) => (isCustom ? '0' : '5px')};

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    background-color: transparent;
  }

  .ant-select-arrow {
    color: #000;
    font-size: 12px;
  }

  @media screen and (max-width: 768px) {
    width: 100%; 
    margin-right: 0; 
  }
`;

const InputContainer = styled.div <{isCustom: boolean}>`
  font-size: 13px;
  color: #1f1f1f;
  border-bottom: 1px solid #c7c7c7;
  padding: 8px 0;
  margin-right: 24px;
  overflow-x: auto;
  overflow-y: hidden;
    max-width: ${({isCustom}) => (isCustom ? '331px':'540px')};
  white-space: nowrap;
  height: 40px;
  scrollbar-width: none;
  
  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 768px) {
    width: 100%; 
    margin-right: 0;
  }
`;

const ResponsiveButton = styled(Button)`
  font-size: 12px;
  margin-bottom: 10px;
  white-space: nowrap;

  @media screen and (max-width: 768px) {
    width: 100%; 
`;
const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px;
    background: #f9f9fb;
    border: 1px solid #e8e8e8;
  }
  .ant-modal-header {
    background: #f9f9fb;
  }
  .ant-modal-body {
    padding-top: unset;
  }
  .title {
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 29.05px;
    text-align: left;
    color: #121212;
  }
  .desc {
    margin-top: 5px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #4e5156;
  }
  .fields-container {
    margin-top: 20px;
  }
  .label {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    margin-bottom: 5px;
  }
  .ant-form-item {
    margin-bottom: 10px;
  }
  .add-phone-num-button {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #2d6cca;
    background-color: transparent;
    border: 0px;
    cursor: pointer;
  }
  .input-field-container {
    position: relative;
  }
  .deleteIcon {
    position: absolute;
    right: 3%;
    top: 52%;
    width: 12.25px;
    height: 14px;
    color: #a3a4a4;
    cursor: pointer;
  }
  .footer {
    margin-top: 28px;
    display: flex;
    align-items: center;
    gap: 30px;
    .save-button {
      width: 145px;
      height: 44px;
      border-radius: 8px;
      background-color: #2d6cca;
      color: #ffffff;
    }
    .save-button:hover,
    .save-button:focus {
      background-color: #2d6cca;
      color: #ffffff;
    }
    .cancel-button {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.94px;
      text-align: left;
      color: #4e5156;
      background-color: transparent;
      border: 0px;
      cursor: pointer;
    }
  }
  .fields-heading {
    margin-top: 20px;
    margin-bottom: 5px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    color: #4e5156;
  }
  .social-media-fields-container {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 10px;
  }
  .delete-icon {
    margin-top: 10px;
    cursor: pointer;
    width: 15.75px;
    height: 18px;
  }
  .ant-modal-close {
    .ant-modal-close-x {
      font-size: 28px;
      position: absolute;
      top: -17px;
      right: -52px;
    }
    color: #fff;
  }
`;

const StyledInput = styled(Input)`
    font-size: 13px;
    color: #1f1f1f;
    width: 75px;
    cursor: text;
    border: 1px solid #c7c7c7;
    padding: 4px;
`;
