import {observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Modal} from '@/components/common-components/components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faListCheck, faXmark} from '@fortawesome/pro-regular-svg-icons';
import ReactEcharts from 'echarts-for-react';
import {useStore} from '@/store/root-store';
import {Checkbox, Skeleton, Tooltip} from 'antd';
import {addProtocolToDomainHttps, getSingleUrlParam} from '@/utils/url';
import {PaginationStyled, Pill, PurpleCheckbox, StyledIssuesTable, UrlWrapper, ViewButton} from '../style';
import {gscInstantIndexingStatus, statusColorMapping} from '../Constants';
import {openUrl} from '@/utils/router';
import moment from 'moment';
import {formatNumber} from '@/utils/number';
import AutoIndexingSwitch from '../Component/Indexing/gscIndexing/siteMapIndexing/autoIndexingSwitch';
import FreezeTooltip from '../Component/freezTooltip';
import {BulkActionBar} from '../Component/bulkActionBar';

interface SiteMapIndexationModalProps {
  openSiteMapIndexationModal: boolean;
  setOpenSiteMapIndexationModal: React.Dispatch<React.SetStateAction<boolean>>;
  sitemapUrl: string;
}

export const SiteMapIndexationModal: React.FC<SiteMapIndexationModalProps> = observer(({openSiteMapIndexationModal, setOpenSiteMapIndexationModal, sitemapUrl}) => {
  const {ottoV2Store: {
    getIndexingGraphKeys,
    getSelectedSiteMapUrls,
  }} = useStore('');
  const uuid = getSingleUrlParam('uuid');
  const [graphData, setGraphData] = useState([]);
  const [siteMapUrls, setSiteMapUrls] = useState([]);
  const [loadingSiteMapUrls, setLoadingSiteMapUrls] = useState(false);
  const [loadingIndexingGraphKeys, setLoadingIndexingGraphKeys] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [count, setCount] = useState<number>();
  const [showBulkBanner, setShowBulkBanner] = useState<boolean>(false);
  const [selectedPages, setSelectedPages] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const generateColor = status => {
    if (gscInstantIndexingStatus.finalStatus.includes(status)) {
      return '#1FAC47';
    } else if (status === 'Server error (5xx)') {
      return '#F44343';
    } else {
      return '#A3A4A4';
    }
  };
  useEffect(() => {
    getGraphkeys();
    getSiteMapUrls();
  }, []);

  useEffect(() => {
    const filters = graphData?.filter(obj => obj?.checked)?.map(obj => obj?.status?.trim())?.join(',');
    getSiteMapUrls(true, filters);
  }, [page, pageSize]);

  useEffect(() => {
    const filters = graphData?.filter(obj => obj?.checked)?.map(obj => obj?.status?.trim())?.join(',');
    if (page === 1) {
      getSiteMapUrls(false, filters);
    }
  }, [graphData, page]);

  const getGraphkeys = async () => {
    try {
      setLoadingIndexingGraphKeys(true);
      const response = await getIndexingGraphKeys(uuid, sitemapUrl);
      if (response) {
        const structuredData = response?.map(item => ({
          ...item,
          checked: true,
        }));
        setGraphData(structuredData);
        setLoadingIndexingGraphKeys(false);
      }
    } catch (error) {
      setLoadingIndexingGraphKeys(false);
    }
  };

  const getSiteMapUrls = async (shouldLoadTable=true, status='') => {
    try {
      if (shouldLoadTable) {
        setLoadingSiteMapUrls(true);
      }
      const response = await getSelectedSiteMapUrls(uuid, sitemapUrl, page, pageSize, status);
      setLoadingSiteMapUrls(false);
      setCount(response?.count);
      if (response?.results?.length) {
        setSiteMapUrls(response?.results);
      }
    } catch (error) {
      setLoadingSiteMapUrls(false);
    }
  };
  const selectUnselectPages = page => {
    if (selectedPages.includes(page)) {
      setSelectedPages(data => data.filter(item => item !== page));
      setSelectAll(false);
    } else {
      setSelectedPages(data => [...data, page]);
    }
  };

  const columns = [
    {
      title: <div className='column-title'>AUTO INDEXING</div>,
      dataIndex: 'targetPage',
      key: 'targetPage',
      width: showBulkBanner ? 70 : 125,
      className: 'p-vertical-12',
      render: (_, record) => {
        return (
          showBulkBanner ? <PurpleCheckbox checked={selectedPages.includes(record?.id)} onClick={()=>selectUnselectPages(record?.id)}/> :
            <Tooltip placement={'topLeft'} title={!record?.isSelected && gscInstantIndexingStatus.stopChecking.includes(record?.status) ? 'Please fix the issue before enabling for indexation' : ''}>
              <div>
                <AutoIndexingSwitch record={record} componentIssueType={'sitemap_indexing'} hasAccess={true} indexing={true} isSiteMapModal={true} getSiteMapUrls={getSiteMapUrls}/>
              </div>
            </Tooltip>
        );
      },
    },
    {
      title: <div className='column-title'>PAGE URL</div>,
      dataIndex: 'url',
      key: 'url',
      onCell: record => record?.isFirstObject ? {colSpan: 1} : {},
      width: 350,
      className: 'p-vertical-12',
      render: (_, record) => {
        return <div style={{display: 'flex', alignItems: 'center', gap: 20}}>
          <UrlWrapper style={{maxWidth: '100%'}} onClick={() => openUrl(record?.url, '_blank')}>{record?.url}</UrlWrapper>
        </div>;
      },
    },
    {
      title: <div className='column-title'>SUBMITTED ON</div>,
      dataIndex: 'submittedOn',
      key: 'submittedOn',
      width: 85,
      className: 'p-vertical-12',
      render: (_, record) => {
        return <div>{record?.lastSentToIndexerAt ? moment(record?.lastSentToIndexerAt)?.format('MMM D, YYYY') : '-'}</div>;
      },
    },
    {
      title: <div className='column-title'>STATUS</div>,
      dataIndex: 'targetPage',
      key: 'targetPage',
      width: 125,
      className: 'p-vertical-12',
      render: (_, record) => {
        if (record?.status) {
          return <Pill style={{background: statusColorMapping[record?.status] ? `${statusColorMapping[record.status]}12` : '#A3A4A412'}}>
            <div
              className='dot'
              style={{background: statusColorMapping[record?.status] ? `${statusColorMapping[record.status]}` : '#A3A4A4'}}
            />{record.status}</Pill>;
        } else {
          return <></>;
        }
      },
    },
  ];

  const onClickCheckbox = (e, item) => {
    setGraphData(prevState => {
      const updatedState = prevState.map(obj => {
        if (obj?.status === item?.status) {
          return {
            ...obj,
            checked: e.target.checked,
          };
        }
        return obj;
      });
      return updatedState;
    });
    setPage(1);
    setSelectedPages([]);
  };

  const renderGraph = () => {
    const isAllUnchecked = graphData?.every(obj => !obj?.checked);
    const processedData = isAllUnchecked ? [] : graphData?.map(obj => obj?.checked && {
      name: obj?.status,
      value: obj?.count,
      percentage: obj?.percentage,
      itemStyle: {color: generateColor(obj?.status)},
    }).filter(Boolean);

    return (
      <ReactEcharts
        style={{width: '112px', height: '112px'}}
        option={{
          tooltip: {
            trigger: 'item',
            position: 'right',
            formatter: params => {
              return `<p style='color:#121212; font-size: 15px'>${params?.data?.name}<br/>
                    ${params?.data?.value}
                    <span style='color:#4E5156; font-size: 13px; display: inline-block;'>(${formatNumber(params?.data?.percentage, 2)}%)</span>
                    </p>`;
            },
          },
          series: [
            {
              name: 'Access From',
              type: 'pie',
              radius: ['50%', '85%'],
              itemStyle: {
                borderColor: '#F9F9FB',
                borderWidth: 5,
              },
              label: {
                show: false,
                position: 'center',
              },
              emphasis: {
                label: {
                  show: false,
                  fontSize: 20,
                  fontWeight: 'bold',
                },
              },
              labelLine: {
                show: false,
              },
              data: processedData,
            },
          ],
        }}
      />
    );
  };

  const selectAllOnPage = () => {
    setSelectedPages(siteMapUrls.map(data => data?.id));
  };

  return (
    <StyledModal
      width={'1200px'}
      height={'90vh'}
      zIndex={900}
      style={{top: '-15px'}}
      visible={openSiteMapIndexationModal}
      onClose={() => setOpenSiteMapIndexationModal(false)}
      closable={true}
      closeIcon={<div><FontAwesomeIcon color={'#fff'} fontSize={22} icon={faXmark} /></div>}
    >
      <StyledModalHeader>
        <div className='modal-heading'>Sitemap Indexation Status</div>
        <a href={addProtocolToDomainHttps(sitemapUrl)} target='_blank' rel='noreferrer' className='domain-wrapper'>{sitemapUrl}</a>
      </StyledModalHeader>
      {loadingIndexingGraphKeys ? (<>
        <Skeleton paragraph={{rows: 2}}/>
      </>) : !loadingIndexingGraphKeys && <ChartWrapper>
        {renderGraph()}
        <div style={{display: 'flex', gap: '10px', flexWrap: 'wrap'}}>
          {graphData?.map((obj, index) => {
            const checkboxClass = obj?.status === 'Submitted and indexed' ?
              'green-checkbox' : obj?.status === 'Server error (5xx)' ?
                'red-checkbox' : 'gray-checkbox';
            return (
              <div className='detail-data-wrapper' key={index}>
                <div className='flex'>
                  <div className='data'>
                    <Checkbox className={checkboxClass} checked={obj?.checked} onChange={e => onClickCheckbox(e, obj)}/>
                    <div className='data-wrapper'>
                      <div className='title'>{obj?.status}</div>
                      <div className='count'>{obj?.count ?? 0}<span>({formatNumber(obj?.percentage, 2) ?? 0}%)</span></div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </ChartWrapper>}
      {showBulkBanner ? <BulkActionBar
        count={count}
        setShowBulkBanner={setShowBulkBanner}
        selectedPages={selectedPages}
        setSelectedPages={setSelectedPages}
        selectAll={selectAll}
        setSelectAll={setSelectAll}
        selectAllOnPage={selectAllOnPage}
        issueType={'sitemap_indexing'}
        selectPageText={`page url`}
        currentProject={''}
        setPageChanged={() => {}}
        deployButtonText={'Enable Auto Indexing'}
        rollBackButtonText={'Disable Auto Indexing'}
        isSiteMapModal={true}
        getSiteMapUrls={getSiteMapUrls}
      /> : <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
        <FreezeTooltip
          placement='topRight'
        >
          <ViewButton
            style={{color: '#2D6CCA', backgroundColor: 'rgba(45, 108, 202, 0.15)', width: '125px', cursor: 'pointer'}}
            onClick={()=> {
              setShowBulkBanner(true);
            }}
          >
            <FontAwesomeIcon icon={faListCheck} color='#2D6CCA' fontSize={14}/>Bulk actions
          </ViewButton>
        </FreezeTooltip>
      </div>}
      <StyledIssuesTable
        loading={loadingSiteMapUrls}
        columns={columns}
        dataSource={siteMapUrls}
        scroll={{y: 550}}
        pagination={false}
      />
      <PaginationStyled
        onChange={(page, pageSize) => {
          setPageSize(pageSize);
          setPage(page);
        }}
        total={count}
        pageSize={pageSize}
        current={page ?? 1}
        showSizeChanger
        pageSizeOptions={['5', '10', '20', '50', '100']}
      />
    </StyledModal>
  );
});
const StyledModal = styled(Modal)`
   .rc-dialog-content {
    .rc-dialog-body {
      background-color: #F9F9FB;
      padding: 25px !important;
      border-radius: 10px;
    }
    .rc-dialog-close {
    font-size: 21px;
    right: -34px;
    top: 0px;
    font-weight: 700;
    color: #fff !important;
    opacity: 0.8 !important;
    &:hover {
      opacity: 1 !important;
    }
    }
  }
`;
const StyledModalHeader = styled.div`
  padding: 0px !important;
  margin-bottom: 13px;
  .modal-heading {
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #121212;
    padding-bottom: 6;
  }
  .domain-wrapper {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #2D6CCA;
  }
`;
const ChartWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 34px;
  .detail-data-wrapper {
    white-space: nowrap;
    .flex {
      display: flex;
      align-items: center;
      gap: 44px;
      .data {
        display: flex;
        align-items: flex-start;
        gap: 5px;
        .ant-checkbox-wrapper {
          .ant-checkbox-checked {
            .ant-checkbox-inner {
              border-radius: 4px;
              background-color: #1FAC47;
              border-color: #1FAC47;
            }
          }
          .ant-checkbox {
            .ant-checkbox-inner {
              border-radius: 4px;
            }
          }
          &.green-checkbox {
            .ant-checkbox-checked {
              .ant-checkbox-inner {
                background-color: #1FAC47;
                border-color: #1FAC47;
              }
          }
          .ant-checkbox-input:focus + .ant-checkbox-inner {
            border-color: #1FAC47;
          }
          &:hover {
            .ant-checkbox-inner {
              border-color: #1FAC47;
            }
            .ant-checkbox::after {
              visibility: hidden;
            }
          }
          }
          &.red-checkbox {
            .ant-checkbox-checked {
              .ant-checkbox-inner {
                background-color: #F44343;
                border-color: #F44343;
              }
          }
          .ant-checkbox-input:focus + .ant-checkbox-inner {
            border-color: #F44343;
          }
          &:hover {
            .ant-checkbox-inner {
              border-color: #F44343;
            }
            .ant-checkbox::after {
              visibility: hidden;
            }
          }
          }
          &.gray-checkbox {
            .ant-checkbox-checked {
              .ant-checkbox-inner {
                background-color: #A3A4A4;
                border-color: #A3A4A4;
              }
          }
          .ant-checkbox-input:focus + .ant-checkbox-inner {
            border-color: #A3A4A4;
          }
          &:hover {
            .ant-checkbox-inner {
              border-color: #A3A4A4;
            }
            .ant-checkbox::after {
              visibility: hidden;
            }
          }
          }
        }
        .data-wrapper {
          .title {
            font-family: 'Inter', sans-serif;
            font-size: 13px;
            font-weight: 400;
            color: #18191B;
            line-height: normal;
          }
          .count, .count > span {
            font-family: 'Inter', sans-serif;
            font-size: 15px;
            font-weight: 500;
            color: #121212;
            line-height: normal;
            display: flex;
            align-items: center;
            gap: 4px;
            span {
              font-size: 13px;
              color: #4E5156;
            }
          }
        }
      }
    }
  }
`;
