import {types, Instance, flow, cast} from 'mobx-state-tree';
import {profileApi} from '@/api/account';
import {filterDataInMemory} from '@/utils/filter-data';
import {LINKGRAPH_API} from '@/api/Linkgraph';
import {toJS} from 'mobx';
import {notification} from '@/utils/notifications';


export const TeamInvitation = types.model({
  id: types.optional(types.number, 0),
  email: types.optional(types.string, ''),
  accepted: types.optional(types.boolean, false),
  created: types.optional(types.string, ''),
  sent: types.optional(types.string, ''),
});

export type TeamInvitationType = Instance<typeof TeamInvitation>;

export const TeamMember = types.model({
  id: types.maybeNull(types.number),
  fullName: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  role: types.maybeNull(types.string),
  isActive: types.maybeNull(types.boolean),
  accepted: types.maybeNull(types.boolean),
  lastLoginAt: types.maybeNull(types.string),
  created: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  sent: types.maybeNull(types.string),
  userId: types.maybeNull(types.number),
});

export const TeamMemberFiltered = types.model({
  id: types.optional(types.number, 0),
  fullName: types.optional(types.string, ''),
  email: types.optional(types.string, ''),
  role: types.optional(types.string, ''),
  isActive: types.maybeNull(types.boolean),
  lastLoginAt: types.optional(types.string, ''),
  createdAt: types.optional(types.string, ''),
  sent: types.optional(types.string, ''),
});

export type TeamMemberType = Instance<typeof TeamMember>;

const FiltersModel = types.model({
  from: types.maybeNull(types.string),
  header: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  to: types.maybeNull(types.string),
});

export const TeamMembers = types.model({
  teamInvitation: types.optional(types.array(TeamInvitation), []),
  teamMember: types.optional(types.array(TeamMember), []),

  loading: types.optional(types.boolean, false),
  filterKeywordTerm: types.optional(types.string, ''),
  pageNumber: types.optional(types.number, 0),
  pageSize: types.optional(types.number, 0),
  sortField: types.optional(types.string, ''),
  sortDirection: types.optional(types.string, ''),
  filterTableDataSize: types.maybeNull(types.number),
  filterList: types.optional(types.array(FiltersModel), []),
  filterKeywordColumns: types.optional(types.array(types.string), []),
  teamMemberFiltered: types.optional(types.array(TeamMemberFiltered), []),


}).views(self => ({
  get getTeamInvitations() {
    return self.teamInvitation;
  },
  get getTeamMember() {
    return toJS(self.teamMember);
  },
  get getFilteredData() {
    return toJS(self.teamMemberFiltered);
  },
})).actions(self => {
  const doLoadTeamInvitation = flow(function* () {
    let data = null;
    try {
      data = yield profileApi.getInvitations();
      self.teamInvitation = cast(data);
    } catch (e) {
      return Promise.reject(e);
    }
  });
  const doLoadTeamMember = flow(function* () {
    self.loading = true;
    self.teamMember = [] as any;
    try {
      const {data} = yield profileApi.getAllTeamMembers();
      self.teamMember = cast(data) ?? [];
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.loading = false;
    }
  });
  const setFromResponse = () => {
    // doLoadTeamInvitation();
    return doLoadTeamMember();
  };
  const filterTeamMemberData = (filterKeywordTerm, filters, pageNumber: number, pageSize: number, sortField, sortDirection) => {
    // self.loading = true;
    const {dataFiltered, dataFilteredSize} = filterDataInMemory(self.teamMember, self.filterKeywordTerm, self.filterKeywordColumns, filters, pageNumber, pageSize, sortField, sortDirection);
    self.filterTableDataSize = dataFilteredSize;
    self.teamMemberFiltered.length = 0;
    const newArray = dataFiltered.map(item => {
      return {
        id: item.id,
        fullName: item.fullName,
        email: item.email,
        role: item.role,
        isActive: item?.isActive,
        lastLoginAt: item.lastLoginAt,
        createdAt: item.createdAt,
        sent: item?.sent,
      };
    });
    self.teamMemberFiltered = cast(newArray);
    // self.loading = false;
  };
  const onKeywordChange = (searchTerm: string) => {
    if (self.filterKeywordTerm !== searchTerm) {
      self.filterKeywordTerm = searchTerm;
      self.pageNumber = 1;
      filterTeamMemberData(self.filterKeywordTerm, self.filterList, 1, self.pageSize, self.sortField, self.sortDirection);
    }
  };

  const revokeInvitation = flow(function* (id: number) {
    try {
      yield LINKGRAPH_API.revokeInvitation(id);
      setFromResponse();
    } catch (error) {
      return Promise.reject(error);
    }
  });

  const revokeTeamInvitation = flow(function* (email: string) {
    try {
      yield profileApi.removeTeamInvitation(email);
      notification.success('Invitation revoked successfully');
      setFromResponse();
    } catch (error) {
      return Promise.reject(error);
    }
  });

  return {
    doLoadTeamInvitation,
    doLoadTeamMember,
    setFromResponse,
    onKeywordChange,
    filterTeamMemberData,
    revokeInvitation,
    revokeTeamInvitation,
  };
});

export function initTeamMembersStore() {
  return TeamMembers.create({
    loading: true,
    filterKeywordColumns: ['fullName'],
    teamMemberFiltered: [],
    pageNumber: 1,
    pageSize: 1000,
    filterList: [],
    sortDirection: '',
    sortField: '',
    filterTableDataSize: 0,
    teamMember: [],
    teamInvitation: [],
  });
}
