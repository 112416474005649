import {Row} from 'antd';
import styled from 'styled-components';
import {Modal} from '@/components/common-components';

export const WhiteCard = styled.div<{width?: string; margin?: string; position?: string}>`
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.02);
  border-radius: 12px;
  width: ${p => p.width ?? '50%'};
  ${p => p?.margin && `margin: ${p?.margin}`};
  ${p => p?.position && `position: ${p?.position}`};
`;

export const Wrapper = styled.div`
  margin-top: -86px;
`;

export const HeroSection = styled.div<{minHeight?: string}>`
  background-color: #0c0e12;
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.02);
  color: #fff;
  display: flex;
  flex-direction: column;
  min-height: ${p => p.minHeight ? p.minHeight : '363px'};
  padding: 40px 40px 0;
  width: 100%;
`;

export const SettingsText = styled.span`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: left;
  color: #34AEF3;
`;

export const CampaignSettings = styled.div`
  color:#34AEF3;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  & > svg {
    width: 14px;
    height: 14px;
  }
${SettingsText} {
  color:#34AEF3;
  font-size: 14px !important;
}
  &.otto {
    color: #4E5156;
    padding: 8px 12px;
    border-radius: 6px;
    background-color: rgba(78, 81, 86, 0.1);
    ${SettingsText} {
      color: #4E5156;
    }
  }
`;

export const Box = styled.div``;

export const OverviewWrapper = styled.div`
  display: flex;
  gap: 30px;
  margin-top: 15px;
  ${Box} {
    div {
      color: #A3A4A4;
      font-family: 'Inter', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      svg {
        margin-left: 6px;
      }
    }
    h3 {
      color: #fff;
      font-family: 'Inter', sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
    }
  }
  &.otto {
    ${Box} {
      div {
        color: #4E5156;
      }
      h3 {
        color: #121212;
      }
    }
  }
`;

export const HistoryRow = styled(Row)`
`;

export const HistoryCardWrapper = styled.div`
  padding: 23px 25px;
  margin-top: 15px;
  background-color: #282b2f;
  border-radius: 12px;
  filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.02));
  &.otto {
    border: 1px solid #E8E8E8;
    background-color: #fff;
  }
`;

export const Text = styled.span``;
export const Value = styled.span``;
export const Paused = styled.span``;
export const ConfigureOutreach = styled.div``;
export const OutletTextWrapper = styled.div`
  position: relative;
  div {
    margin-bottom: 2px;
    ${Paused}, ${Text} {
      color: #E8E8E8;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-weight: 400;
    }
    ${Value} {
      color:  #A3A4A4;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-weight: 400;
    }
    &.otto {
      ${Paused} {
        color: #121212;
      }
    }
  }
  ${ConfigureOutreach} {
    color: #34AEF3;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
  }
  button {
    margin-top: 6px;
  }
  &::after {
    content: '';
    position: absolute;
    width: 1px;
    height: 150px;
    top: -23px;
    right: 60px;
    background-color: #4e5156;
  }
`;

export const OpportunitiesWrapper = styled.div`
  color: #F2F2F5;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 35px 0 20px 0;
`;

export const ColHeading = styled.div`
  color: #fff;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
  &.otto {
    color: #121212;
    font-size: 15px;
  }
`;

export const CheckboxGroupWrapper = styled.div`
  .ant-checkbox-group {
    .ant-checkbox-wrapper {
      .ant-checkbox {
        .ant-checkbox-inner {
          background-color: #4E5156;
          border: #4E5156;
          border-radius: 4px;
          width: 16px;
          height: 16px;
          &::after {
            position: absolute;
            display: table;
            border: 2px solid #fff;
            border-top: 0;
            border-left: 0;
            transform: rotate(45deg) scale(1) translate(-50%, -50%);
            opacity: 1;
            transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
            content: ' ';
          }
        }
        &:hover {
          .ant-checkbox-inner {
            border-color: transparent;
          }
        }
        .ant-checkbox-input {
          &:focus + .ant-checkbox-inner {
            border-color: transparent;
          }
        }
      }
      .ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: #1890ff;
          border: #1890ff;
        }
        &::after {
          visibility: hidden;
        }
      }
      span {
        color: #E8E8E8;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
`;

export const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 16px !important;
    background: #FBFBFB !important;
    padding: 22px 5px;
  }
  .ant-divider-horizontal {
    margin: 0 !important;
  }
  .rc-dialog-body {
    padding: 30px 30px 0 30px !important;
  }
  .rc-dialog-close {
    font-size: 28px !important;
    color: white !important;
    left: 102% !important;
    top: -3px !important;
    opacity: 1 !important;
  }
`;

export const ContentSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0 20px 0;
`;

export const StyledInputNumber = styled.input<{isError?: boolean}>`
  width: 68px;
  height: 32px;
  border: 1px solid ${p => p?.isError ? 'red' : '#E8E8E8'};
  border-radius: 8px;
  padding: 0 5px 2px 5px;

  &:focus {
    border: 1px solid ${p => p?.isError ? 'red' : '#E8E8E8'};
    border-radius: 8px;
  }
  &:focus-visible{
    outline: none;
    }
`;

export const TimeDropdown = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 32px;
  border-radius: 8px;
  border: 1px solid #E8E8E8;
  background: #ffff;
  cursor: pointer;
`;
