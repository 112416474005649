import React, {useEffect, useRef, useState} from 'react';
import {BlueTextButton, Container, Input, Label, SectionTitle, Select, StyledFormItem, StyledRadio, SwitchStyled} from './styles';
import useIntersectionObserver from './useIntersectionObserver';
import {Col, Radio, Row, Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import {getDomain} from '@/utils/url';
import {observer} from 'mobx-react';
import {useStore} from '@/store/root-store';
import {faShopify} from '@fortawesome/free-brands-svg-icons';

const ShopifySection = observer(({setActiveOption, selectedSection, form, setShowAddShopifySiteModal}) => {
  const {contentOptimizer: {
    wpPublish: {
      vendorAccounts,
      blogs,
      loadingBlogs,
    },
  }, settings: {customer: {profile}}} = useStore('');
  const [postType, setPostType] = useState(form.getFieldValue('shopify_post_type') || 'page');

  const blockref = useRef(null);
  const blockEntry = useIntersectionObserver(blockref, {
    threshold: [0.3, 0.5, 0.7, 0.9, 1.0],
  });

  const isVisible = !!blockEntry?.isIntersecting;
  useEffect(() => {
    if (isVisible) {
      setActiveOption('shopify');
    }
  }, [isVisible]);

  return (
    <Container id='shopify' ref={blockref}>
      <div className={selectedSection === 'shopify' ? 'selected-bg' : ''} id='container'>
        <SectionTitle style={vendorAccounts?.length ? {marginBottom: '0px'} : {}}>SHOPIFY INTEGRATION</SectionTitle>
        {(vendorAccounts?.length || profile?.isShopifyCustomer) ?
          <>
            <Row style={{alignItems: 'center', flexWrap: 'nowrap'}}>
              <Col style={{marginRight: '42px'}}>
                <Label>Website</Label>
              </Col>
              <Col span={16} style={{marginRight: '11px', marginTop: '9px'}}>
                <StyledFormItem
                  name='shopify_account_id'
                >
                  <Select
                    onChange={value => {
                      const obj = {};
                      obj['account_id'] = value;
                      form.setFieldsValue(obj);
                    }}
                    placeholder='Website'
                    disabled={!vendorAccounts?.length}
                    suffixIcon={!vendorAccounts?.length ? <Spin indicator={<LoadingOutlined />} /> : <FontAwesomeIcon icon={faCaretDown} color='#4E5156' />}
                  >
                    {vendorAccounts?.map((shopifySite, idx) => {
                      return <Select.Option on value={shopifySite.accountId} key={idx}>{getDomain(shopifySite.shopDomain)}</Select.Option>;
                    })}
                  </Select>
                </StyledFormItem>
              </Col>
              <Col>
                <BlueTextButton onClick={() => setShowAddShopifySiteModal(true)}>+Add site</BlueTextButton>
              </Col>
            </Row>
            <Row style={{alignItems: 'center', flexWrap: 'nowrap'}}>
              <Col style={{marginRight: '49px'}}>
                <Label>Post Type</Label>
              </Col>
              <Col>
                <StyledFormItem name='shopify_post_type' initialValue={'page'}>
                  <Radio.Group onChange={e => setPostType(e?.target?.value)}>
                    <StyledRadio value='blog'>Blog Post</StyledRadio>
                    <StyledRadio style={{marginLeft: '30px'}} value='page'>Page</StyledRadio>
                  </Radio.Group>
                </StyledFormItem>
              </Col>
            </Row>
            {postType === 'blog' && <Row style={{alignItems: 'center', flexWrap: 'nowrap'}}>
              <Col style={{marginRight: '64px'}}>
                <Label>Blog</Label>
              </Col>
              <Col span={16} style={{marginRight: '11px', marginTop: '9px'}}>
                <StyledFormItem
                  name='shopify_blog_title'
                >
                  <Select
                    onChange={value => {
                      const obj = {};
                      obj['shopify_blog_title'] = value;
                      form.setFieldsValue(obj);
                    }}
                    placeholder='Blog'
                    disabled={loadingBlogs}
                    suffixIcon={loadingBlogs ? <Spin indicator={<LoadingOutlined />} /> : <FontAwesomeIcon icon={faCaretDown} color='#4E5156' />}
                  >
                    {blogs?.map((blog, i) => <Select.Option on value={blog.id} key={i}>{blog.title}</Select.Option>)}
                  </Select>
                </StyledFormItem>
              </Col>
            </Row>}
            <Row style={{alignItems: 'center', flexWrap: 'nowrap'}}>
              <Col style={{marginRight: '49px'}}>
                <Label>Author</Label>
              </Col>
              <Col span={16} style={{marginRight: '11px', marginTop: '9px'}}>
                <StyledFormItem
                  name='shopify_author'
                >
                  <Input placeholder='Author' />
                </StyledFormItem>
              </Col>
            </Row>
            <Row style={{alignItems: 'center', flexWrap: 'nowrap'}}>
              <Col style={{marginRight: '60px'}}>
                <Label>Tags</Label>
              </Col>
              <Col span={16} style={{marginRight: '11px', marginTop: '9px'}}>
                <StyledFormItem
                  name='shopify_tags'
                >
                  <Input placeholder='Tags' />
                </StyledFormItem>
              </Col>
            </Row>
            <StyledFormItem name='shopify_auto_upload'>
              <Row style={{flexWrap: 'nowrap', marginTop: '4px'}}>
                <SwitchStyled
                  onChange={value => {
                    const obj = {};
                    obj['shopify_auto_upload'] = value;
                    form.setFieldsValue(obj);
                  }}
                  defaultChecked={form.getFieldValue('shopify_auto_upload')}
                />
                <Label>Auto-upload to Shopify the articles created with “Write with AI in Bulk” </Label>
              </Row>
            </StyledFormItem>
          </>: <div className='install-shopify-banner'>
            <div className='shopify-logo'>
              <FontAwesomeIcon icon={faShopify} color='#FFFFFF' />
            </div>
            <div className='right-section'>
              <div className='banner-title'>Publish Content to Shopify</div>
              <div className='banner-desc'>Install the SearchAtlas app to your Shopify store and Start Publishing content directly from the SEO Content Assistant</div>
              <button onClick={e => {
                e.preventDefault();
                setShowAddShopifySiteModal(true);
              }} className='view-instructions-button'>View Instructions</button>
            </div>
          </div>}
      </div>
    </Container>
  );
});

export default ShopifySection;
