import {observer} from 'mobx-react-lite';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft, faLink, faFilePdf, faCheck, faPlus, faMinus} from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';
import ReactEcharts from 'echarts-for-react';
import {
  Description,
  DescriptionWrapper} from '../styledComponents';
import {Button, Col, Rate, Spin, Tooltip} from 'antd';
import {positionSVG} from '@/utils/icons';
import GoogleMapReact from 'google-map-react';
import {useEffect, useRef, useState} from 'react';
import {getSingleUrlParam, removeSpaces} from '@/utils/url';
import {MapTooltip} from './map-tooltip';
import {useStore} from '@/store/root-store';
import {useRouter} from 'next/router';
import {SkeletonHorizontalLoaderGray} from '@/components/common-components';
import {LoadingOutlined} from '@ant-design/icons';
import {findDiagonalCoordinates, handleTilesLoaded, midpoint} from '@/utils/maps';
import useWindowSize from '@/utils/hooks/useWindowSize';
import {UniversalLoader} from '@/components/common-components/v2/maps/universalLoader';

const ZOOM_IN_OUT = 1;

export const ComparisonModal = observer(({showPinDetailModal, currentBusinessCid, selectedDate, name, setCompare, dataCId, newCenter}: any) => {
  const {localSeoScan: {localSeoScanDetail: {
    compareCompetitorData,
    getGMBDetail,
    isExportingCompare,
    exportSingleNode,
    setisExportingCompare,
    loadingCompareData,
    loadCompareData}},
  settings: {customer: {isGHLAccount}},
  } = useStore('');
  const mapContainerRef = useRef(null);
  const router = useRouter();
  const isPublic = router?.pathname?.includes('public');
  const [longitude, setLon] = useState(Number(getSingleUrlParam('lng')) || -73.935242);
  const [initialLoad, setInitialLoad] = useState(true);
  const [latitude, setLat] = useState(Number(getSingleUrlParam('lat')) || 40.730610);
  const gridId = getSingleUrlParam('id');
  const dataCID = getSingleUrlParam('data_cid');
  const compBusinessDate = getSingleUrlParam('competitor_business_date');
  const businessId = getSingleUrlParam('businessId');
  const [zoomValue, setZoomValue] = useState(12);
  const [zoomValueComp, setZoomValueComp] = useState(12);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [map, setMap] = useState(null);
  const [mapCompt, setMapCompt] = useState(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [resultsData, setResultsData] = useState({
    date: '',
    gmbPosition: null,
    keyword: '',
    location: {lat: null, lon: null},
    serps: [],
  });
  const [hoverKey, setHoverKey] = useState('');
  const [hoverKeyCompt, setHoverKeyCompt] = useState('');
  const [copySuccess, setCopySuccess] = useState(false);
  const mainId = getSingleUrlParam('id');
  const id = router.query.id?.length ? router.query.id[0] : null;
  const {width: screenWidth} = useWindowSize();
  const [pinWidth, setPinWidth] = useState(50);
  const [fontSize, setFontSize] = useState(16);
  const [pinWidthComp, setPinWidthComp] = useState(50);
  const [fontSizeComp, setFontSizeComp] = useState(16);

  const [diagonalCoordinates, setDiagonalCoordinates] = useState([]);
  const [diagonalCoordinatesCompt, setDiagonalCoordinatesCompt] = useState([]);

  useEffect(() => {
    const coords = compareCompetitorData?.businessReport?.map(item => item?.location);

    const diagonalCoords = coords?.length ? findDiagonalCoordinates(coords) : [];

    setDiagonalCoordinates(diagonalCoords);

    const coordsCompt = compareCompetitorData?.competitorBusinessReport?.map(item => item?.location);

    const diagonalCoordsCompt = coordsCompt?.length ? findDiagonalCoordinates(coordsCompt) : [];

    setDiagonalCoordinatesCompt(diagonalCoordsCompt);
  }, [compareCompetitorData]);

  const lat1 = diagonalCoordinates?.length ? diagonalCoordinates[0]?.lat : 40.7128;
  const lon1 = diagonalCoordinates?.length ? diagonalCoordinates[0]?.lon : -74.0060;
  const lat2 = diagonalCoordinates?.length ? diagonalCoordinates[1]?.lat : 34.0522;
  const lon2 = diagonalCoordinates?.length ? diagonalCoordinates[1]?.lon : -118.2437;
  // Calculate midpoint
  const midpointCoordinates = midpoint(lat1, lon1, lat2, lon2);

  const compLat1 = diagonalCoordinatesCompt?.length ? diagonalCoordinatesCompt[0]?.lat : 40.7128;
  const compLon1 = diagonalCoordinatesCompt?.length ? diagonalCoordinatesCompt[0]?.lon : -74.0060;
  const compLat2 = diagonalCoordinatesCompt?.length ? diagonalCoordinatesCompt[1]?.lat : 34.0522;
  const compLon2 = diagonalCoordinatesCompt?.length ? diagonalCoordinatesCompt[1]?.lon : -118.2437;
  // Calculate midpoint
  const midpointCoordinatesCompt = midpoint(compLat1, compLon1, compLat2, compLon2);

  useEffect(()=> {
    async function load() {
      await loadCompareData(businessId, gridId, dataCId || dataCID, selectedDate || compBusinessDate);
      setInitialLoad(false);
    }
    load();
  }, []);

  useEffect(() => {
    if (showPinDetailModal) {
      if (!loadingCompareData) {
        if (map) {
          // @ts-ignore
          window.google.maps.event.addListenerOnce(map, 'tilesloaded', handleTilesLoaded(map, compareCompetitorData?.businessReport));
        }
      }
    }
  }, [map, compareCompetitorData?.businessReport, longitude, showPinDetailModal, loadingCompareData, isExportingCompare]);

  useEffect(() => {
    if (showPinDetailModal) {
      if (!loadingCompareData) {
        if (mapCompt) {
          // @ts-ignore
          window.google.maps.event.addListenerOnce(mapCompt, 'tilesloaded', handleTilesLoaded(mapCompt, compareCompetitorData?.competitorBusinessReport));
        }
        const deltaX = 0;
        const deltaY = -100;
        if (map) {
          map.panBy(deltaX, deltaY);
        }
        if (mapCompt) {
          mapCompt.panBy(deltaX, deltaY);
        }
      } else if (loadingCompareData) {
        const deltaX = 0;
        const deltaY = -10;
        if (map) {
          map.panBy(deltaX, deltaY);
        }
        if (mapCompt) {
          mapCompt.panBy(deltaX, deltaY);
        }
      }
    }
  }, [mapCompt, compareCompetitorData?.competitorBusinessReport, showPinDetailModal, loadingCompareData, isExportingCompare]);

  const places = () => {
    return compareCompetitorData?.businessReport?.map((place, idx) => {
      return (
        <MapTooltip
          key={idx}
          lat={place?.location?.lat}
          lng={place?.location?.lon}
          data={place}
          zoom={(!zoomValue || isNaN(zoomValue)) ? 12 : zoomValue}
          radiusMiles={compareCompetitorData?.radiusMiles}
          gridShape={compareCompetitorData?.gridShape}
          businessIcon={place?.location?.lat?.toFixed(6) == (newCenter?.lat ? newCenter : compareCompetitorData?.center)?.lat.toFixed(6) && place?.location?.lon?.toFixed(6) == (newCenter?.lon ? newCenter : compareCompetitorData?.center)?.lon.toFixed(6)}
          centerPoint={compareCompetitorData?.gridShape == 'square' && compareCompetitorData?.gridDimensions == 4 ?
            false : place?.location?.lat?.toFixed(6) == (newCenter?.lat ? newCenter : compareCompetitorData?.center)?.lat.toFixed(6) && place?.location?.lon?.toFixed(6) == (newCenter?.lon ? newCenter : compareCompetitorData?.center)?.lon.toFixed(6)}
          businessList={[]}
          thumbnail={place?.thumbnail}
          hover={hoverKey == `${idx}`}
          pinWidth={pinWidth}
          fontSize={fontSize}
        />
      );
    });
  };

  const placesCompt = () => {
    return compareCompetitorData?.competitorBusinessReport?.map((place, idx) => {
      return (
        <MapTooltip
          key={idx}
          lat={place?.location?.lat}
          lng={place?.location?.lon}
          data={place}
          zoom={(!zoomValueComp || isNaN(zoomValueComp)) ? 12 : zoomValueComp}
          radiusMiles={compareCompetitorData?.radiusMiles}
          gridShape={compareCompetitorData?.gridShape}
          businessIcon={place?.location?.lat?.toFixed(6) == compareCompetitorData?.competitorBusiness?.location?.lat.toFixed(6) && place?.location?.lon?.toFixed(6) == compareCompetitorData?.competitorBusiness?.location?.lon.toFixed(6)}
          centerPoint={compareCompetitorData?.gridShape == 'square' && Number(compareCompetitorData?.gridDimensions) == 4 ?
            false : place?.location?.lat?.toFixed(6) == (newCenter?.lat ? newCenter : compareCompetitorData?.center)?.lat.toFixed(6) && place?.location?.lon?.toFixed(6) == (newCenter?.lon ? newCenter : compareCompetitorData?.center)?.lon.toFixed(6)}
          businessList={[]}
          thumbnail={place?.thumbnail}
          hover={hoverKeyCompt == `${idx}`}
          pinWidth={pinWidthComp}
          fontSize={fontSizeComp}
        />
      );
    });
  };

  const getBusinessTotalValue = () => {
    const totalValue = compareCompetitorData?.businessPositionLegends['13'] + compareCompetitorData?.businessPositionLegends['47'] + compareCompetitorData?.businessPositionLegends['820'] + compareCompetitorData?.businessPositionLegends['20'];
    return totalValue;
  };

  const getCompetitorTotalValue = () => {
    const totalValue = compareCompetitorData?.competitorBusinessPositionLegends['13'] + compareCompetitorData?.competitorBusinessPositionLegends['47'] + compareCompetitorData?.competitorBusinessPositionLegends['820'] + compareCompetitorData?.competitorBusinessPositionLegends['20'];
    return totalValue;
  };

  const getBusinessPercentage = value => {
    return (value / getBusinessTotalValue() * 100).toFixed(0);
  };

  const getCompetitorPercentage = value => {
    return (value / getCompetitorTotalValue() * 100).toFixed(0);
  };

  const chartData = () => {
    const data = [];

    if (compareCompetitorData?.businessPositionLegends['47'] > 0) {
      data.push({
        name: 'Medium',
        value: getBusinessPercentage(compareCompetitorData?.businessPositionLegends['47']),
        itemStyle: {
          color: '#FF8536',
        },
      });
    }

    if (compareCompetitorData?.businessPositionLegends['820'] > 0) {
      data.push({
        name: 'Bad',
        value: getBusinessPercentage(compareCompetitorData?.businessPositionLegends['820']),
        itemStyle: {
          color: '#F44343',
        },
      });
    }

    if (compareCompetitorData?.businessPositionLegends['20'] > 0) {
      data.push({
        name: 'Not Ranking',
        value: getBusinessPercentage(compareCompetitorData?.businessPositionLegends['20']),
        itemStyle: {
          color: '#B62222',
        },
      });
    }

    if (compareCompetitorData?.businessPositionLegends['13'] > 0) {
      data.push({
        name: 'Good',
        value: getBusinessPercentage(compareCompetitorData?.businessPositionLegends['13']),
        itemStyle: {
          color: '#2AC155',
        },
      });
    }

    return data;
  };

  const chartDataCompt = () => {
    const data = [];

    if (compareCompetitorData?.competitorBusinessPositionLegends['47'] > 0) {
      data.push({
        name: 'Medium',
        value: getCompetitorPercentage(compareCompetitorData?.competitorBusinessPositionLegends['47']),
        itemStyle: {
          color: '#FF8536',
        },
      });
    }

    if (compareCompetitorData?.competitorBusinessPositionLegends['820'] > 0) {
      data.push({
        name: 'Bad',
        value: getCompetitorPercentage(compareCompetitorData?.competitorBusinessPositionLegends['820']),
        itemStyle: {
          color: '#F44343',
        },
      });
    }

    if (compareCompetitorData?.competitorBusinessPositionLegends['20'] > 0) {
      data.push({
        name: 'Not Ranking',
        value: getCompetitorPercentage(compareCompetitorData?.competitorBusinessPositionLegends['20']),
        itemStyle: {
          color: '#B62222',
        },
      });
    }

    if (compareCompetitorData?.competitorBusinessPositionLegends['13'] > 0) {
      data.push({
        name: 'Good',
        value: getCompetitorPercentage(compareCompetitorData?.competitorBusinessPositionLegends['13']),
        itemStyle: {
          color: '#2AC155',
        },
      });
    }

    return data;
  };

  const pieChartOptions = {
    tooltip: {
      trigger: 'item',
      formatter: params => {
        const roundedPercentage = Math.round(params.percent);
        return `${params.name}: ${roundedPercentage}%`;
      },
    },
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: ['35%', '93%'],
        center: ['50%', '50%'],
        avoidLabelOverlap: false,
        label: {
          show: true,
          color: 'white',
          position: 'inside',
          formatter: params => {
            const roundedPercentage = params.percent.toFixed(0);
            return `${roundedPercentage}%`;
          },
          rich: {
            '#FF8536': {color: '#FF8536'},
            '#F44343': {color: '#F44343'},
            '#B62222': {color: '#B62222'},
            '#2AC155': {color: '#2AC155'},
          },
        },
        emphasis: {
          show: false,
          label: {
            show: false,
          },
        },
        labelLine: {
          show: false,
        },
        data: chartData().map(item => ({
          value: item.value,
          name: item.name,
          itemStyle: item.itemStyle,
        })),
      },
    ],
  };

  const pieChartOptionsCompt = {
    tooltip: {
      trigger: 'item',
      formatter: params => {
        const roundedPercentage = Math.round(params.percent);
        return `${params.name}: ${roundedPercentage}%`;
      },
    },
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: ['35%', '93%'],
        center: ['50%', '50%'],
        avoidLabelOverlap: false,
        label: {
          show: true,
          color: 'white',
          position: 'inside',
          formatter: params => {
            const roundedPercentage = params.percent.toFixed(0);
            return `${roundedPercentage}%`;
          },
          rich: {
            '#FF8536': {color: '#FF8536'},
            '#F44343': {color: '#F44343'},
            '#B62222': {color: '#B62222'},
            '#2AC155': {color: '#2AC155'},
          },
        },
        emphasis: {
          show: false,
          label: {
            show: false,
          },
        },
        labelLine: {
          show: false,
        },
        data: chartDataCompt().map(item => ({
          value: item.value,
          name: item.name,
          itemStyle: item.itemStyle,
        })),
      },
    ],
  };

  const onChildClick = (key, record) => {
    setResultsData(record?.data);
  };

  const createMapOptions = () => {
    return {
      panControl: false,
      mapTypeControl: false,
      scrollwheel: true,
      fullscreenControl: false,
      zoomControl: false,
    };
  };

  const onChildMouseEnter = key => {
    setHoverKey(key);
  };

  const onChildMouseLeave = () => {
    setHoverKey(null);
  };

  const onChildMouseEnterCompt = key => {
    setHoverKeyCompt(key);
  };

  const onChildMouseLeaveCompt = () => {
    setHoverKeyCompt(null);
  };

  const handleExportPdf = () => {
    setisExportingCompare(true);
    setTimeout(() => {
      exportSingleNode(mapContainerRef.current);
    }, 5000);
  };

  const onMapZoomChange = ({zoom}, isComp) => {
    const defaultZoom = 50;
    const maxZoom = 50;
    const minZoom = 20;
    const defaultZoomLevel = 12;
    const defaultFontSize = 16;

    if (isComp) {
      setZoomValueComp(zoom);
    } else {
      setZoomValue(zoom);
    }

    let newZoomValue;
    if (zoom <= defaultZoomLevel) {
      newZoomValue = defaultZoom - 20 * (defaultZoomLevel - zoom);
    } else {
      newZoomValue = defaultZoom + 20 * (zoom - defaultZoomLevel);
    }

    if (newZoomValue > maxZoom) {
      newZoomValue = maxZoom;
    } else if (newZoomValue < minZoom) {
      newZoomValue = minZoom;
    }

    const calculatedFontSize = defaultFontSize - ((defaultZoom - newZoomValue) / 4.5);

    if (isComp) {
      setPinWidthComp(newZoomValue);
      setFontSizeComp(calculatedFontSize);
    } else {
      setPinWidth(newZoomValue);
      setFontSize(calculatedFontSize);
    }
  };

  const handleZoomIn = isCompetitor => {
    if (map && !isCompetitor) {
      const currentZoom = map.getZoom();
      map.setZoom(currentZoom + ZOOM_IN_OUT);
      setZoomValue(currentZoom + ZOOM_IN_OUT);
    } else if (mapCompt && isCompetitor) {
      const currentZoom = mapCompt.getZoom();
      mapCompt.setZoom(currentZoom + ZOOM_IN_OUT);
      setZoomValueComp(currentZoom + ZOOM_IN_OUT);
    }
  };

  const handleZoomOut = isCompetitor => {
    if (map && !isCompetitor) {
      const currentZoom = map.getZoom();
      map.setZoom(currentZoom - ZOOM_IN_OUT);
      setZoomValue(currentZoom - ZOOM_IN_OUT);
    } else if (mapCompt && isCompetitor) {
      const currentZoom = mapCompt.getZoom();
      mapCompt.setZoom(currentZoom - ZOOM_IN_OUT);
      setZoomValueComp(currentZoom - ZOOM_IN_OUT);
    }
  };
  const defaultProps = {
    center: {
      lat: compareCompetitorData?.center?.lat || latitude || 40.73061,
      lng: compareCompetitorData?.center?.lon || longitude || -73.935242,
    },
  };

  return <Wrapper ref={mapContainerRef}>
    {!isPublic && <span className='backArrow' onClick={()=> setCompare(false)}><FontAwesomeIcon icon={faArrowLeft} /> <p>Back to competitors</p></span>}
    <DescriptionWrapper>
      <DescWrapper>
        <Heading>Local SEO Showdown for {`"${name}"`}</Heading>
        <ButtonsWrapper>
          {isPublic ? null : <Tooltip
            title={copySuccess ? <span><FontAwesomeIcon icon={faCheck} style={{color: '#2AC155'}} />{` URL copied`}</span> : 'Copy URL'}
            trigger='hover'
            placement='bottom'
            onVisibleChange={visible => {
              if (!visible) {
                setTimeout(() => {
                  setCopySuccess(false);
                }, 300);
              }
            }}>
            <ButtonStyled
              loading={!getGMBDetail?.publicShareHash}
              onClick={() => {
                navigator.clipboard.writeText(removeSpaces(
                  `${location.origin}/research/local-seo-public/${id || ''}?id=${mainId}&businessId=${businessId}
                        &business_date=${selectedDate}
                        &competitor_business_date=${selectedDate}
                        &data_cid=${dataCId}
                        &showCompare=${true}
                        &lat=${latitude || ''}&lng=${longitude || ''}&dataCid=${currentBusinessCid}
                        &public_hash=${getGMBDetail?.publicShareHash || ''}${isGHLAccount ? '&disable_logo=1' : ''}`));
                setCopySuccess(true);
              }}><FontAwesomeIcon icon={faLink}/>&nbsp;&nbsp;Share Report</ButtonStyled>
          </Tooltip>}
          <ButtonStyled
            loading={isExportingCompare}
            disabled={isExportingCompare}
            onClick={handleExportPdf}
            background='#F2F2F5'
            textColor='#121212'><FontAwesomeIcon icon={faFilePdf}/>&nbsp;&nbsp;Get PDF</ButtonStyled>
        </ButtonsWrapper>
      </DescWrapper>
      <Description>
      Direct comparison between you and the selected competitor, in terms of Google search placement {screenWidth > 800 && <br/>} within the selected geographic area, using the specified search query.
      </Description>
    </DescriptionWrapper>
    <ContentContainer>
      <ContentWrapper>
        <MapContainer>
          {(loadingCompareData || initialLoad) && (<UniversalLoader width='350px' height='350px' positionTop='60%'/>)}
          <GoogleMapReact
            ref={mapContainerRef}
            bootstrapURLKeys={{key: process.env.GOOGLE_MAP_KEY}}
            center={defaultProps.center}
            onGoogleApiLoaded={({map}) => {
              setMap(map);
              setLat(map.center.lat());
              setLon(map.center.lng());
            }}
            defaultZoom={(!zoomValue || isNaN(zoomValue)) ? 12 : zoomValue}
            onChange={e => onMapZoomChange(e, false)}
            onChildClick={onChildClick}
            options={createMapOptions}
            onChildMouseEnter={onChildMouseEnter}
            onChildMouseLeave={onChildMouseLeave}
            yesIWantToUseGoogleMapApiInternals
          >
            {!loadingCompareData && !initialLoad && ((compareCompetitorData?.gridShape == 'square' && Number(compareCompetitorData?.gridDimensions) == 4) && <MapTooltip
              lat={midpointCoordinates.lat}
              lng={midpointCoordinates.lon}
              zoom={(!zoomValue || isNaN(zoomValue)) ? 12 : zoomValue}
              squareFour={true}
              hidePin={true}
              radiusMiles={compareCompetitorData?.radiusMiles}
              gridShape={compareCompetitorData?.gridShape}
              centerPoint={true}
              businessList={[]}/>)
            }
            {(!loadingCompareData && !initialLoad) && places()}
          </GoogleMapReact>
        </MapContainer>
        <WhiteOverlay></WhiteOverlay>
        <ResultsWrapper>
          <TitleWrapper>
            <Title href={`https://www.google.com/maps/search/${compareCompetitorData?.business?.title}/@${compareCompetitorData?.business?.gpsCoordinates?.latitude},${compareCompetitorData?.business?.gpsCoordinates?.longitude},15z#rlfi=hd:;si:${compareCompetitorData?.business?.dataCid}`} target='_blank'>
              {loadingCompareData ? <SkeletonHorizontalLoaderGray height={'20px'} skeletonHeight='10px' lightLoader width='150px'/> : compareCompetitorData?.business?.title}
            </Title>
            <DataPill>You</DataPill>
          </TitleWrapper>
          <Address>{loadingCompareData ? <SkeletonHorizontalLoaderGray height={'20px'} skeletonHeight='10px' lightLoader width='150px'/> : compareCompetitorData?.business?.address}</Address>
          {loadingCompareData ? <SkeletonHorizontalLoaderGray height={'20px'} skeletonHeight='10px' lightLoader width='150px'/> : <RatingWrapper>
            <RatingText>{compareCompetitorData?.business?.rating}</RatingText>
            <Rate disabled allowHalf value={Number(compareCompetitorData?.business?.rating)} style={{color: '#F1AA3E', fontSize: '14px'}} />
            <ReviewsText>({compareCompetitorData?.business?.reviews})</ReviewsText>
          </RatingWrapper> }
          {loadingCompareData ? <p>Loading competitor heatmap <Spin indicator={<LoadingOutlined style={{fontSize: '12px', color: 'gray', marginLeft: '10px'}} spin />} /></p> :
            <MetricsContainer>
              <StyledCol span={9} style={{marginTop: '10px'}}>
                <MetricsWrapper>
                  {positionSVG('#2AC155')}
                  <div style={{fontSize: '12px', color: '#4E5156'}}>Good - {compareCompetitorData?.businessPositionLegends['13']} pins ({getBusinessPercentage(compareCompetitorData?.businessPositionLegends['13'])}%)</div>
                </MetricsWrapper>
                <MetricsWrapper>
                  {positionSVG('#FF8536')}
                  <div style={{fontSize: '12px', color: '#4E5156'}}>Medium - {compareCompetitorData?.businessPositionLegends['47']} pins ({getBusinessPercentage(compareCompetitorData?.businessPositionLegends['47'])}%)</div>
                </MetricsWrapper>
                <MetricsWrapper>
                  {positionSVG('#F44343')}
                  <div style={{fontSize: '12px', color: '#4E5156'}}>Bad - {compareCompetitorData?.businessPositionLegends['820']} pins ({getBusinessPercentage(compareCompetitorData?.businessPositionLegends['820'])}%)</div>
                </MetricsWrapper>
                <MetricsWrapper>
                  {positionSVG('#B62222')}
                  <div style={{fontSize: '12px', color: '#4E5156'}}>Not ranking - {compareCompetitorData?.businessPositionLegends['20']} pins ({getBusinessPercentage(compareCompetitorData?.businessPositionLegends['20'])}%)</div>
                </MetricsWrapper>
              </StyledCol>
              <StyledCol span={6}>
                <ReactEcharts style={{height: '110px'}} option={pieChartOptions} />
              </StyledCol>
            </MetricsContainer> }
        </ResultsWrapper>
        <ZoomContainer>
          <div onClick={() => handleZoomIn(false)}>
            <FontAwesomeIcon icon={faPlus} fontSize={18} color='rgba(0, 0, 0, 0.85)' />
          </div>
          <Divider></Divider>
          <div onClick={() => handleZoomOut(false)}>
            <FontAwesomeIcon style={{marginBottom: '-4px'}} icon={faMinus} fontSize={18} color='rgba(0, 0, 0, 0.85)' />
          </div>
        </ZoomContainer>
      </ContentWrapper>
      <ContentWrapper>
        <MapContainer>
          {(loadingCompareData || initialLoad) && (<UniversalLoader width='350px' height='350px' positionTop='60%'/>)}
          <GoogleMapReact
            ref={mapContainerRef}
            bootstrapURLKeys={{key: process.env.GOOGLE_MAP_KEY}}
            center={defaultProps.center}
            onGoogleApiLoaded={({map}) => {
              setMapCompt(map);
            }}
            defaultZoom={(!zoomValueComp || isNaN(zoomValueComp)) ? 12 : zoomValueComp}
            onChange={e => onMapZoomChange(e, true)}
            onChildClick={onChildClick}
            options={createMapOptions}
            onChildMouseEnter={onChildMouseEnterCompt}
            onChildMouseLeave={onChildMouseLeaveCompt}
            yesIWantToUseGoogleMapApiInternals
          >
            {!loadingCompareData && !initialLoad && ((compareCompetitorData?.gridShape == 'square' && compareCompetitorData?.gridDimensions == 4) && <MapTooltip
              lat={midpointCoordinatesCompt.lat}
              lng={midpointCoordinatesCompt.lon}
              zoom={(!zoomValueComp || isNaN(zoomValueComp)) ? 12 : zoomValueComp}
              squareFour={true}
              hidePin={true}
              radiusMiles={compareCompetitorData?.radiusMiles}
              gridShape={compareCompetitorData?.gridShape}
              centerPoint={true}
              businessList={[]}/>)
            }
            {(!loadingCompareData && !initialLoad) && placesCompt()}
          </GoogleMapReact>
        </MapContainer>
        <WhiteOverlay></WhiteOverlay>
        <ResultsWrapper>
          <TitleWrapper>
            <Title href={`https://www.google.com/maps/search/${compareCompetitorData?.competitorBusiness?.title}/@${compareCompetitorData?.competitorBusiness?.gpsCoordinates?.latitude},${compareCompetitorData?.competitorBusiness?.gpsCoordinates?.longitude},15z#rlfi=hd:;si:${compareCompetitorData?.competitorBusiness?.dataCid}`} target='_blank'>
              { loadingCompareData ? <SkeletonHorizontalLoaderGray height={'20px'} skeletonHeight='10px' lightLoader width='150px'/> : compareCompetitorData?.competitorBusiness?.title}
            </Title>
            <DataPill>Competitor</DataPill>
          </TitleWrapper>
          <Address>{ loadingCompareData ? <SkeletonHorizontalLoaderGray height={'20px'} skeletonHeight='10px' lightLoader width='150px'/> : compareCompetitorData?.competitorBusiness?.address}</Address>
          {loadingCompareData ? <SkeletonHorizontalLoaderGray height={'20px'} skeletonHeight='10px' lightLoader width='150px'/> : <RatingWrapper>
            <RatingText>{compareCompetitorData?.competitorBusiness?.rating}</RatingText>
            <Rate disabled allowHalf value={Number(compareCompetitorData?.competitorBusiness?.rating)} style={{color: '#F1AA3E', fontSize: '14px'}} />
            <ReviewsText>({compareCompetitorData?.competitorBusiness?.reviews})</ReviewsText>
          </RatingWrapper> }
          {loadingCompareData ? <p>Loading competitor heatmap <Spin indicator={<LoadingOutlined style={{fontSize: '12px', color: 'gray', marginLeft: '10px'}} spin />} /></p> :
            <MetricsContainer>
              <StyledCol span={9} style={{marginTop: '10px'}}>
                <MetricsWrapper>
                  {positionSVG('#2AC155')}
                  <div style={{fontSize: '12px', color: '#4E5156'}}>Good - {compareCompetitorData?.competitorBusinessPositionLegends['13']} pins ({getCompetitorPercentage(compareCompetitorData?.competitorBusinessPositionLegends['13'])}%)</div>
                </MetricsWrapper>
                <MetricsWrapper>
                  {positionSVG('#FF8536')}
                  <div style={{fontSize: '12px', color: '#4E5156'}}>Medium - {compareCompetitorData?.competitorBusinessPositionLegends['47']} pins ({getCompetitorPercentage(compareCompetitorData?.competitorBusinessPositionLegends['47'])}%)</div>
                </MetricsWrapper>
                <MetricsWrapper>
                  {positionSVG('#F44343')}
                  <div style={{fontSize: '12px', color: '#4E5156'}}>Bad - {compareCompetitorData?.competitorBusinessPositionLegends['820']} pins ({getCompetitorPercentage(compareCompetitorData?.competitorBusinessPositionLegends['820'])}%)</div>
                </MetricsWrapper>
                <MetricsWrapper>
                  {positionSVG('#B62222')}
                  <div style={{fontSize: '12px', color: '#4E5156'}}>Not ranking - {compareCompetitorData?.competitorBusinessPositionLegends['20']} pins ({getCompetitorPercentage(compareCompetitorData?.competitorBusinessPositionLegends['20'])}%)</div>
                </MetricsWrapper>
              </StyledCol>
              <StyledCol span={6}>
                <ReactEcharts style={{height: '110px'}} option={pieChartOptionsCompt} />
              </StyledCol>
            </MetricsContainer> }
        </ResultsWrapper>
        <ZoomContainer>
          <div onClick={() => handleZoomIn(true)}>
            <FontAwesomeIcon icon={faPlus} fontSize={18} color='rgba(0, 0, 0, 0.85)' />
          </div>
          <Divider></Divider>
          <div onClick={() => handleZoomOut(true)}>
            <FontAwesomeIcon style={{marginBottom: '-4px'}} icon={faMinus} fontSize={18} color='rgba(0, 0, 0, 0.85)' />
          </div>
        </ZoomContainer>
      </ContentWrapper>
    </ContentContainer>
  </Wrapper>;
});

const Wrapper = styled.div`
padding: 20px 20px 0 20px;
@media screen and (max-width: 700px) {
  padding: 10px !important;
}
.backArrow{
  cursor: pointer;
  width: fit-content;
  display: flex;
  align-items: center;
  p{
    margin-left: 10px;
    margin-bottom: 0px;
  }
}
.rating{
  display: flex;
  align-items: baseline;
  margin-top: 5px;
  p{
    margin-left: 10px;
  }
  ul{
    margin-left: 10px;
  }
  li{
    height: 35px;
    z-index: 1;
    margin-top: 7px;
    background-color: transparent;
    div{
      padding: 0;
    }
  }
}
.businessWrapper{
  display: flex;
  justify-content: space-between;
  .childOne{
    padding: 20px;
    height: 684px;
    width: 48%;
    background-color: lightgray;
    border-radius: 8px;
    margin-bottom: 20px;
    h3{
      font-size: 18px;
      margin: 0;
    }
    p{
      font-size: 12px;
      margin-bottom: 0;
    }
  }
}
`;

const Heading = styled.h1`
  @media screen and (max-width: 800px) {
    font-size: 20px;
    line-height: 25px;
  }
  @media screen and (max-width: 600px) {
    font-size: 18px !important;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  gap: 25px;
  @media screen and (max-width: 1200px) {
    display: block;
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  border-top-left-radius: 10px;
  @media screen and (max-width: 1200px) {
    margin-bottom: 10px;
  }
  @media screen and (max-width: 830px) {
    display: flex;
    flex-direction: column;
  }
`;

const MapContainer = styled.div`
  width: 557px;
  height: 68vh;
  // margin-bottom: 30px;
  border-radius: 12px;
  border: 1px solid #D4D4D4;
  div div {
    border-radius: 12px;
  }
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
  @media screen and (max-width: 830px) {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-top: none;
  }
`;

const WhiteOverlay = styled.div`
  position: absolute;
  width: calc(100% - 3px);
  height: 100%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: linear-gradient(159deg, rgba(255, 255, 255, 0.90) 32.02%, rgba(255, 255, 255, 0.00) 47.37%);
  top: 1px;
  left: 1px;
  padding-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #4E5156;
  gap: 5px;
  overflow: auto;
  pointer-events: none;

  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 5px;
  }
  &:hover {
    ::-webkit-scrollbar-thumb {
      background: #4E5156;
    }
  }
  @media screen and (max-width: 830px) {
    background: linear-gradient(159deg, rgba(255, 255, 255, 0.90) 39.02%, rgba(255, 255, 255, 0.00) 47.37%);
  }
  @media screen and (max-width: 700px) {
    display: none;
  }
`;

const ResultsWrapper = styled.div`
  position: absolute;
  width: 100%;
  border-radius: 12px;
  top: 0;
  left: 0;
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 5px;
  }
  &:hover {
    ::-webkit-scrollbar-thumb {
      background: #4E5156;
    }
  }
  @media screen and (max-width: 700px) {
    position: static;
    order: -4;
    padding: 15px;
    border: 1px solid #D4D4D4;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Title = styled.a`
  color: #121212;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  line-height: 24px;
  &:hover {
    color: #2D6CCA;
    text-decoration: none;
    border-bottom: 1px solid #2D6CCA;
  }
`;

const DataPill = styled.div`
  width: fit-content;
  height: 18px;
  padding: 2px 10px 2px 10px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 54px;
  background: #4E5156;
  color: white;
`;

const Address = styled.div`
  color: #4E5156;
  font-size: 12px;
`;

const RatingWrapper = styled.div`
  display: flex;
  gap: 7px;
  align-items: baseline;
`;

const RatingText = styled.div`
  color: #F1AA3E;
  font-size: 12px;
  font-weight: 500;
`;

const ReviewsText = styled.div`
  color: #121212;
  font-size: 12px;
`;

const MetricsContainer = styled.div`
  display: flex;
  margin-top: 10px;
  @media screen and (max-width: 830px) {
    flex-direction: column;
    row-gap: 8px;
    align-items: center;
  }
`;

const StyledCol = styled(Col)`
  @media screen and (max-width: 700px) {
    width: 100% !important;
    max-width: none !important;
  }
`;

const MetricsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
`;

const ButtonStyled = styled(Button)<{background?: string; textColor?: string; loading?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
  width: fit-content;
  height: 38px;
  padding: ${p => p?.loading ? '7px 10px' : '7px 20px'};
  border-radius: 8px;
  background: ${p => p?.background ? p?.background : 'rgba(45, 108, 202, 0.25)'};
  color: ${p => p?.textColor ? p?.textColor : '#2D6CCA'};

  &:hover {
    background: linear-gradient(0deg,rgba(0,0,0,0.18),rgba(0,0,0,0.18)),${p => p?.background ? p?.background : 'rgba(45, 108, 202, 0.25)'} !important;
    color: ${p => p?.textColor ? p?.textColor : '#2D6CCA'};
  }
  &:focus {
    background: linear-gradient(0deg,rgba(0,0,0,0.18),rgba(0,0,0,0.18)),${p => p?.background ? p?.background : 'rgba(45, 108, 202, 0.25)'} !important;
    color: ${p => p?.textColor ? p?.textColor : '#2D6CCA'};
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 13px;
  margin: 18px 0 14px 0;
`;

const DescWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 816px) {
    display: block;
  }
`;

const ZoomContainer = styled.div`
  position: absolute;
  left: 93%;
  top: 86%;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  width: 32px;
  height: 65px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    cursor: pointer;
  }
`;

const Divider = styled.div`
  height: 1px;
  background-color: rgb(230, 230, 230);
  width: 25px;
  margin: 3px 0px;
`;
