import styles from '../style.module.scss';
import {Input, Select, Divider, Tooltip} from 'antd';
import classNames from 'classnames';
import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import {Search} from 'react-feather';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import {useStore} from '@/store/root-store';

interface Props {
  projectId: string;
  disabled?: boolean;
  whiteLarge?: boolean;
  graySmall?: boolean;
  isFolderUpdate?: boolean;
  campaignId?: number;
  modalSelectedItemV2?: number;
  setModalSelectedItemV2?: (value: number) => void;
  isInOTTO?: boolean;
}

export const ProjectsSelector = observer(({
  projectId,
  disabled,
  whiteLarge,
  graySmall,
  isFolderUpdate,
  campaignId,
  modalSelectedItemV2,
  setModalSelectedItemV2,
  isInOTTO,
}: Props) => {
  const {
    campaigns: {foldersData, updateCampaign, getFolders},
    ottoV2Store: {ottoV2Project},
  } = useStore('');

  const projectsData = foldersData?.results;
  const [filteredProjectsData, setFilteredProjectsData] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (!foldersData?.results?.length) {
      getFolders();
    }
  }, []);

  useEffect(() => {
    setFilteredProjectsData(projectsData);
  }, [projectsData]);

  const {t} = useTranslation('common');
  const {Option}= Select;
  const [modalSelectedItem, setModalSelectedItem] = useState(projectId);

  const onOptionChange = option => {
    if (isFolderUpdate) {
      setModalSelectedItem(option);
      const payload = {
        project_id: `${option}`,
      };
      if (isInOTTO) {
        updateCampaign(payload, campaignId, ottoV2Project?.id);
      } else {
        updateCampaign(payload, campaignId);
      }
    } else {
      setModalSelectedItemV2(option);
    }
    setSearchValue('');
    setFilteredProjectsData(projectsData);
  };

  const onChangeSearch = value => {
    setSearchValue(value);
    const filteredData = projectsData?.filter(item => item?.name?.toLowerCase()?.includes(value?.toLowerCase()));
    setFilteredProjectsData(filteredData);
  };

  const selectRef = useRef(null);
  return (<MainDiv graySmall={graySmall} whiteLarge={whiteLarge} className='projectSelectorWrapper'>
    <Tooltip title={modalSelectedItem ? modalSelectedItem : 'Select Project'} placement='top' overlayStyle={{display: 'none'}}>
      <Select
        disabled={disabled}
        style={{opacity: disabled ? .5 : 1}}
        placement={'bottomRight'}
        dropdownClassName={styles.projectsDropdown}
        placeholder={'Select Project'}
        value={isFolderUpdate ? modalSelectedItem || null : modalSelectedItemV2}
        ref={selectRef}
        suffixIcon={<FontAwesomeIcon icon={faCaretDown} color={'#4E5156'}/>}
        onChange={option => onOptionChange(option)}
        dropdownRender={menu => (
          <Fragment>
            <div onClick={e=> e.stopPropagation()}
              className={styles.topDiv}>
              <Input className={styles.SearchField}
                placeholder={t('Search...')}
                prefix={<Search width={15}/>}
                bordered={false}
                value={searchValue}
                onChange={e => onChangeSearch(e?.target?.value)}
              />
            </div>
            <Divider className={styles.divider} />
            {menu}
          </Fragment>
        )}>
        {
          filteredProjectsData?.map((item, idx) => {
            if (item.id !== null) {
              return (
                <Option
                  className={classNames(styles.Option, item.id == (isFolderUpdate ? modalSelectedItem : modalSelectedItemV2) ? styles.activeOption : null)}
                  key={idx}
                  value={item.id}
                  onClick={e => e.stopPropagation()}
                  disabled={item.id == (isFolderUpdate ? modalSelectedItem : modalSelectedItemV2)}>
                  <div style={{display: 'flex'}}>
                    {item.name}
                  </div>

                </Option>
              );
            }
          })
        }
      </Select>
    </Tooltip>
  </MainDiv>);
});

const MainDiv= styled.div<{graySmall?: boolean; whiteLarge?: boolean}>`
  margin-right: 5px;
  border-radius: 4px;
  padding: 3px 5px;
  position: relative;
  display: flex;
  flex-direction: column;

  .ant-select-selector {
    ${p => p?.graySmall && 'width: fit-content !important;'}    
    ${p => p?.whiteLarge && 'width: 180px !important;'}
  }
  .ant-select {
    ${p => p?.graySmall && 'min-width: fit-content !important; width: fit-content !important;'}    
    ${p => p?.whiteLarge && 'min-width: 180px !important; width: 180px !important; height: 38px;'}
    .ant-select-selector {
      ${p => p?.graySmall && 'border: 1px solid transparent; background:  #F7F7F8 !important; border-radius: 5px !important; height: 30px !important;'}    
      ${p => p?.whiteLarge && 'display: flex; align-items: center; min-width: 180px !important; width: 180px !important; height: 38px; background: white; border: 1px solid #E8E8E8; border-radius: 8px;'}

      .ant-select-selection-item {
        display: flex;
        align-items: center;
      }
    }

    .ant-select-selection-placeholder {
      color: #4E5156 !important;
    }
  }

`;
