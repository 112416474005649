import React from 'react';
import {faArrowLeft} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import {observer} from 'mobx-react-lite';
import {ColumnsType} from 'antd/lib/table';
import {Table} from '@/components/common-components/components';
import {faCheck} from '@fortawesome/pro-regular-svg-icons';
import {faXmark} from '@fortawesome/pro-duotone-svg-icons';
import {useRouter} from 'next/router';
// import {routes} from '@/utils/const';
import {Collapse, Dropdown, Row, Col} from 'antd';
import {routes} from '@/utils/const';
import {useStore} from '@/store/root-store';
import {setCountryCodeToLocalStorage} from '@/api/common-utils';

interface Props{
  setGaDetail?: (value)=>void;
  gaDetailData?: any;
  onDeleteAccount?: (email) => void;
  setShowConnectAccountHeader?: React.Dispatch<React.SetStateAction<boolean>>;
}
export const GaSettingsData: React.FC<Props> = observer(({setGaDetail, gaDetailData, onDeleteAccount, setShowConnectAccountHeader}) => {
  const {gsc: {criteria, setDrawerVisibility}} = useStore('');
  const router = useRouter();
  const onClickProject = url =>{
    criteria.updateCriteria({
      ...criteria,
      property: url,
    });
    setCountryCodeToLocalStorage('');
    setDrawerVisibility(false);
  };
  const overLayStyle = {background: '#fff', padding: '10px', border: '1px solid #E8E8E8', borderRadius: '8px'};
  const columns: ColumnsType = [{
    title: () => <span>GA PROPERTIES & APPS</span>,
    key: 'selectedGaPropertyName',
    dataIndex: 'selectedGaPropertyName',
    width: 300,
    align: 'left',
    ellipsis: true,
    render: (_, data: any) => {
      return <div style={{padding: '3px 0'}}>
        <EllipsisText>{data?.selectedGaPropertyName ?? '-'}</EllipsisText>
        <SearchAtlasId style={{overflow: 'hidden', textOverflow: 'ellipsis', display: 'block'}}>{data?.selectedGaPropertyId ?? '-'}</SearchAtlasId>
      </div>;
    },
  },
  {
    title: () => <span>GSC PROJECT</span>,
    key: 'connectedGscProperties',
    dataIndex: 'connectedGscProperties',
    width: 85,
    render: (_, data: any) => {
      return <div>
        {data?.connectedGscProperties?.length ?
          <Dropdown overlayStyle={overLayStyle} overlay={<DropdownInnerDiv>
            {data?.connectedGscProperties?.map(item => {
              return <GscProject key={item?.id} style={{cursor: 'pointer'}} onClick={()=>{
                if (item?.url) {
                  onClickProject(item?.url);
                  router.push(`/${routes.gsc}/overview?&sp=${item?.url}&country_code=`);
                }
              }}>
                <FontAwesomeIcon icon={faCheck} color={'#1FAC47'}/>
                <ViewProject>{item?.url || ''}</ViewProject>
              </GscProject>;
            })}
          </DropdownInnerDiv>}>
            <GscProject style={{cursor: 'pointer'}}>
              <FontAwesomeIcon icon={faCheck} color={'#1FAC47'}/>
              <ViewProject>View {data?.connectedGscProperties?.length > 1 ? data?.connectedGscProperties?.length : ''}</ViewProject>
            </GscProject>
          </Dropdown> : <GscProject>
            <FontAwesomeIcon icon={faXmark} color={'#A3A4A4'}/>
            <MissingWrapper>No Project</MissingWrapper>
          </GscProject>}
      </div>;
    },
  },
  {
    title: () => <span>SITE AUDIT <br />PROJECT</span>,
    key: 'connectedSiteAudits',
    dataIndex: 'connectedSiteAudits',
    width: 103,
    render: (_, data: any) => <div>
      {data?.connectedSiteAudits?.length ?
        <Dropdown overlayStyle={overLayStyle} overlay={<DropdownInnerDiv>
          {data?.connectedSiteAudits?.map(item => {
            return <GscProject key={item?.id} style={{cursor: 'pointer'}} onClick={()=>{
              setDrawerVisibility(false);
              if (item?.propertyUrl) router.push(`/site-audit/overview?id=${item?.id || ''}`);
            }}>
              <FontAwesomeIcon icon={faCheck} color={'#1FAC47'}/>
              <ViewProject>{item?.propertyUrl || ''}</ViewProject>
            </GscProject>;
          })}
        </DropdownInnerDiv>}>
          <GscProject style={{cursor: 'pointer', paddingLeft: '25px'}}>
            <FontAwesomeIcon icon={faCheck} color={'#1FAC47'}/>
            <ViewProject>View {data?.connectedSiteAudits?.length > 1 ? data?.connectedSiteAudits?.length : ''}</ViewProject>
          </GscProject>
        </Dropdown> : <GscProject style={{paddingLeft: '25px'}}>
          <FontAwesomeIcon icon={faXmark} color={'#A3A4A4'}/>
          <MissingWrapper>No Project</MissingWrapper>
        </GscProject>}
    </div>,
  },
  ];

  const HandleGoBack = ()=>{
    setGaDetail(false);
    setShowConnectAccountHeader && setShowConnectAccountHeader(true);
  };

  return (
    <MainWrapper>
      <Row gutter={24} style={{display: 'flex', flexWrap: 'wrap'}}>
        <Col xl={18} md={16} xs={24}>
          <div onClick={HandleGoBack} style={{display: 'flex', gap: '18px', cursor: 'pointer', width: 'fit-content'}}>
            <FontAwesomeIcon icon={faArrowLeft} style={{cursor: 'pointer'}}/>
            <Heading>Back to list of GA Connected Accounts</Heading>
          </div>
          <Email>{gaDetailData?.email}</Email>
        </Col>
        <Col xl={6} md={8} xs={24}>
          <DisconnectEmail style={{textAlign: 'end'}} onClick={() => onDeleteAccount(gaDetailData?.email)}>Disconnect this Email</DisconnectEmail>
        </Col>
      </Row>
      <TableHeaderContainer>
        <Table
          dataSource={[]}
          columns={columns}
          pagination={false}
        />
      </TableHeaderContainer>
      <Collapse ghost defaultActiveKey={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}>
        {gaDetailData?.availableAccounts?.map((item, index) => {
          if (item?.siteproperties?.length) {
            return <Collapse.Panel key={`${index}`} className='childCollapseStyle' header={
              <SearchAtlasWrapper><SearchAtlas>{item?.name}</SearchAtlas><SearchAtlasId>ID: {item?.accountId}</SearchAtlasId></SearchAtlasWrapper>
            }>
              <TableContainer>
                <Table
                  showHeader={false}
                  dataSource={item?.siteproperties || []}
                  columns={columns}
                  pagination={false}
                />
              </TableContainer>
            </Collapse.Panel>;
          } else {
            return <FlexWrapper style={{display: 'flex', flexWrap: 'wrap'}} key={index} flexDirection={item?.siteproperties?.length}>
              <SearchAtlasWrapper><SearchAtlas>{item?.name}</SearchAtlas><SearchAtlasId>ID: {item?.accountId}</SearchAtlasId></SearchAtlasWrapper>
              <div style={{marginTop: '20px'}}>&nbsp; - &nbsp; No data available</div>
            </FlexWrapper>;
          }
        })}
      </Collapse>
    </MainWrapper>
  );
});
const GscProject = styled.div`
  display: flex;
  gap: 6px;
`;
const MissingWrapper = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #A3A4A4;
`;
const SearchAtlasId = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #4E5156;
`;
const FlexWrapper = styled.div<{flexDirection}>`
  font-size: 12px;
  line-height: 15px;
  flex-direction: ${p=> p?.flexDirection ? 'column' : 'row'};
  color: #4E5156;
  display: flex;
`;
const ViewProject = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #2D6CCA;
`;
const SearchAtlas = styled.span`
font-weight: 500;
font-size: 14px;
line-height: 17px;
color: #121212;
`;
const SearchAtlasWrapper = styled.div`
  display: flex;
  padding-top: 3px;
  padding-left: 5px;
  gap: 10px;
  flex-wrap: wrap;
`;
const DisconnectEmail = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  cursor: pointer;
  color: #F44343;
  @media only screen and (max-width: 767px){
    padding-left: 30px;
  }
`;

const DropdownInnerDiv = styled.div`
  display: grid;
  gap: 5px;
  justify-items: flex-start;
`;
const Email = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #4E5156;
  padding-left: 30px;
`;
const EllipsisText = styled.div`
text-overflow: ellipsis;
overflow: hidden;
`;
const MainWrapper = styled.div`
  padding-top: 15px;
`;
const Heading = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #121212;
`;
const TableContainer = styled.div`
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  margin-top: 10px;
  padding: 0 5px 5px 5px;

 .ant-table-body {
  &::-webkit-scrollbar {
      height: 5px;
      width: 5px;
    }
  
    &::-webkit-scrollbar-thumb {
      background: rgba(138,138,138,0.32);
    }
  
    &::-webkit-scrollbar-track {
      background: rgba(138,138,138,0.12);
    }
}

table tr th {
  background-color: #fff;
}

.ant-table-content {
  border-radius: 0 !important;
}

.ant-table-column-sorters-with-tooltip:not(:first-child) {
  text-align: center;
}

table {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;

  th::before {
    width: 0 !important;
  }

  .ant-table-column-sorter {
    display: flex;
    padding-left: 3px !important;
  }

  .ant-table-column-sorters {
    display: inline-flex !important;
    padding: 5px !important;
  }

  tr td {
    &:first-child {
      padding-bottom: 0 !important;
      padding-top: 0 !important;
    }
  }

  .ant-table-tbody {
    tr:nth-child(even) {
      td {
        background: #fff !important;
        border-bottom: 1px solid #f4f4f4 !important;
        box-shadow: none;
        font-weight: 400;
        white-space: nowrap;
      }

      &:hover {
        td {
          background: #eef4fe !important;
        }

        button {
          background: #eef4fe !important;
          border: 0 !important;
          color: #2d6cca;
          font-weight: 500;
        }
      }
    }

    tr:nth-child(odd) {
      td {
        background: #fff;
        border-bottom: 1px solid #f4f4f4 !important;
        box-shadow: none;
        font-weight: 400;
        white-space: nowrap;
      }

      &:hover {
        td {
          background: #eef4fe !important;
        }

        button {
          background: #eef4fe !important;
          border: 0 !important;
          color: #2d6cca;
          font-weight: 500;
        }
      }
    }
  }

  .ant-table-thead {
    background: #f7f7f7 !important;

    th {
      background: #f7f7f7 !important;
      color: #121212 !important;
      font-size: 12px;
      font-weight: 600;
      line-height: 14px;
      padding: 0 !important;
    }

    .ant-table-cell-fix-left {
      text-align: left !important;

      &:first-child {
        border-bottom-left-radius: 8px !important;
        border-top-left-radius: 8px !important;
      }
    }

    th:first-child {
      background: #f7f7f7 !important;
      border-bottom-left-radius: 8px !important;
      border-top-left-radius: 8px !important;
      color: #121212;
      font-size: 12px;
      font-weight: 600;
    }

    th:nth-child(2) {
      background: #f7f7f7 !important;
      color: #121212;
      font-size: 12px;
      font-weight: 600;
    }

    th:last-child {
      background-color: #f7f7f7 !important;
      border-bottom-right-radius: 8px !important;
      border-top-right-radius: 8px !important;
      padding-left: 25px !important;
    }
  }

  .ant-table-cell-fix-left-last {
    text-align: left !important;
  }

  tr:first-child td {
    background: #fff !important;
  }

  tr:nth-child(even) td {
    background: #f9f9fb !important;
  }

  tr:nth-child(odd) td {
    background-color: #fff !important;
  }

  tr th {
    height: 50px !important;
    line-height: 22px;
    white-space: nowrap;
  }

  .ant-column-sorters {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  tr td {
    padding-bottom: 15px;
    padding-top: 15px;
    text-align: center;
  }

  tr th:first-child,
  tr td:first-child {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
}
`;


const TableHeaderContainer = styled.div`
  border-radius: 10px;
  margin-top: 10px;

.ant-table-column-sorters-with-tooltip:not(:first-child) {
  text-align: center;
}
.ant-table-content {
  overflow: hidden;
  @media only screen and (max-width: 830px) {
    display: flex;
    overflow: auto;
    }
}

table {
  padding-left: 10px;
  padding-right: 10px;

  th::before {
    width: 0 !important;
  }

  .ant-table-column-sorter {
    display: flex;
    padding-left: 3px !important;
  }

  .ant-table-tbody {
    display: none !important;
  }

  .ant-table-thead {
    background: #E8E8E8 !important;

    th {
      background: #E8E8E8 !important;
      color: #121212 !important;
      font-size: 12px;
      font-weight: 600;
      line-height: 14px;
      padding: 0 !important;
    }

    .ant-table-cell-fix-left {
      text-align: left !important;

      &:first-child {
        border-bottom-left-radius: 8px !important;
        border-top-left-radius: 8px !important;
      }
    }

    th:first-child {
      background: #E8E8E8 !important;
      border-bottom-left-radius: 8px !important;
      border-top-left-radius: 8px !important;
      color: #121212;
      font-size: 12px;
      font-weight: 600;
    }

    th:nth-child(2) {
      background: #E8E8E8 !important;
      color: #121212;
      font-size: 12px;
      font-weight: 600;
    }

    th:last-child {
      background-color: #E8E8E8 !important;
      border-bottom-right-radius: 8px !important;
      border-top-right-radius: 8px !important;
      padding-left: 25px !important;
    }
  }

  .ant-table-cell-fix-left-last {
    text-align: left !important;
  }

  tr th {
    height: 50px !important;
    line-height: 22px;
    white-space: nowrap;
  }

  .ant-column-sorters {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  tr th:first-child,
  tr td:first-child {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
}
`;
