import React from 'react';
import {AIContentStatus} from '../ai-content-status';
import styled from 'styled-components';
import {Tabs, TabPane} from '@/components/common-components/components';
import {useEffect, useState, useRef} from 'react';
import {Checkbox} from 'antd';
import moment from 'moment';
import {useStore} from '@/store/root-store';
import {LoadingOutlined} from '@ant-design/icons';
import {Spin, Empty} from 'antd';
import {observer} from 'mobx-react-lite';
// import {GeneralTabContent} from './general';


interface Props {
  visible: boolean;
  setSelectedNotification: React.Dispatch<React.SetStateAction<number | null>>;
  setNotificationModalVisibility: (visibility: boolean) => void;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const NotificationsPopupV2 = observer(({visible, setNotificationModalVisibility, setSelectedNotification}: Props) => {
  const {
    settings: {customer: {profile: {whitelabelOtto, isWhitelabel},
      loadCustomerNotifications,
      // getGeneralNotifications,
      customerNotifications: {results: {data: notificationsData}, next},
      notificationsLoading},
    }} = useStore('');

  const [activeTab, setActiveTab] = useState<string>(isWhitelabel ? 'ai' : 'new');
  const [showUpdates, setShowUpdates] = useState(true);
  const modalRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!visible && !isWhitelabel) {
      modalRef.current.scrollTop = 0;
    }
  }, [visible]);

  const handleScroll = e => {
    const atBottom = Math.round(e?.target?.scrollHeight - e?.target?.scrollTop) === e?.target?.clientHeight;
    if (atBottom && next && !notificationsLoading) {
      loadCustomerNotifications();
    }
  };

  const antIcon = <LoadingOutlined style={{fontSize: 36, color: '#7f4ead', marginRight: '10px'}} spin />;

  useEffect(() => {
    try {
      const shouldShowUpdates = JSON.parse(localStorage.getItem('showUpdates'));
      if (typeof shouldShowUpdates === 'boolean') {
        setShowUpdates(shouldShowUpdates);
      }
    } catch (error) {
      setShowUpdates(false);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('showUpdates', JSON.stringify(showUpdates));
  }, [showUpdates]);

  const removeImagesFromHTML = (html: any) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    const images = doc.querySelectorAll('img');
    images.forEach(image => {
      image.parentNode.removeChild(image);
    });
    return doc.body.innerHTML;
  };

  const tabPanes = [
    // {
    //   key: 'general',
    //   title: <span className={'tab-title'}>General <span className='count'>{getGeneralNotifications?.filter(item => item?.status === 'unread')?.length}</span></span>,
    //   element: <GeneralTabContent setVisible={setVisible}/>,
    // },
    {
      key: 'new',
      title: <span style={{display: 'flex'}}>{`What's New`}</span>,
      element: (
        <WhatsNew ref={modalRef} style={(notificationsLoading && !notificationsData?.length) ? {display: 'flex'} : {}} onScroll={handleScroll}>
          {(notificationsLoading && !notificationsData?.length) ? (
            <div style={{alignSelf: 'center', textAlign: 'center', width: '100%'}}>
              <Spin indicator={antIcon} />
            </div>
          ) : (
            <>
              <div className='checkbox-wrapper'>
                <StyledCheckbox checked={showUpdates} onChange={e => setShowUpdates(e.target.checked)}>Notify me with a prominent modal about updates.</StyledCheckbox>
              </div>
              <NotificationList>
                {notificationsData?.length > 0 ? (
                  <>
                    {
                      notificationsData?.map(item => (
                        <div onClick={() => {
                          setSelectedNotification(item?.id);
                          setNotificationModalVisibility(true);
                        }} className='notification-item' key={item?.id}>
                          <p className='notification-text' style={{fontSize: 11}}>{item?.releaseDate ? moment(item?.releaseDate).format('MMMM D, YYYY') : '-'}</p>
                          <li>
                            <div style={{maxWidth: '95%'}}>
                              <h4 className='notification-title'>
                                {item?.title.includes('OTTO') ? item?.title.replace('OTTO', whitelabelOtto) : item?.title}
                              </h4>
                              <ContentWrapper addEllipsis1Line={item?.title?.length > 54} addEllipsis2Line={item?.title?.length < 54} style={{marginLeft: 0, lineHeight: 'normal'}} className='notification-text' dangerouslySetInnerHTML={{__html: removeImagesFromHTML(item?.wysiwygContent)}} />
                            </div>
                          </li>
                        </div>
                      ))
                    }
                    {notificationsLoading && (
                      <div style={{textAlign: 'center'}}>
                        <Spin indicator={antIcon} />
                      </div>
                    )}
                  </>
                ): (
                  <EmptyStyled description='No New Notifications' />
                )}
              </NotificationList>
            </>
          )}
        </WhatsNew>
      ),
    },
    {
      key: 'ai',
      title: 'AI Writing',
      element: <div style={{paddingBottom: 17, paddingRight: 20, paddingLeft: 20}}><AIContentStatus visible={visible} /></div>,
    },
  ];

  return (
    <Wrapper>
      <HeaderWrapper>
        <Title>Notifications</Title>
        <div className='tabs-container'>
          <StyledTabs
            tabBarGutter={30}
            tabBarStyle={{'padding': '0 !important', 'backgroundColor': '#FFF'}}
            moreIcon={false}
            defaultActiveKey={isWhitelabel ? 'ai' : 'new'}
            onChange={key => setActiveTab(key)}
            activeKey={activeTab}
          >
            {tabPanes.map(item => (
              <TabPane tab={(
                <TabDiv isActive={activeTab === item.key}>
                  <span>{item.title}</span>
                </TabDiv>
              )} key={item.key}>
                {item?.element}
              </TabPane>
            ))}
          </StyledTabs>
        </div>
      </HeaderWrapper>
    </Wrapper>
  );
});

export default NotificationsPopupV2;

const Wrapper = styled.div`
  .tabs-container {
    margin-left: -8px;
    .rc-tabs-nav {
      height: 32px !important;
      padding-top: 0 !important;
      padding: 0px 20px;
      .rc-tabs-nav-wrap {
        overflow: visible;
      }
      .rc-tabs-tab {
      width: auto !important;
      &:not(:first-child) {
        margin-left: 20px !important;
      }
      div {
        padding-right: 0 !important;
        padding-left: 0 !important;
        span {
          .tab-title {
            display: flex;
            position: relative;
            .count {
              position: absolute;
              top: -10px;
              right: -12px;
              padding: 1px 4px;
              border-radius: 50%;
              background-color: #ED991A;
              font-size: 12px;
              font-weight: 600;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #FFFFFF;
              line-height: normal;
            }
          }
        }
      }
    }
    }
  }
`;

const WhatsNew = styled.div`
  padding-bottom: 16px;
  .checkbox-wrapper {
    margin-top: 17px;
    margin-bottom: 13px;
  }
  max-height: 424px;
  min-height: 424px;
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 5px; /* Width of the scrollbar */
    height: 50px;
  }

/* Handle portion of the scrollbar */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(78, 81, 86, 0.4); /* Color of the scrollbar handle */
    border-radius: 10px; /* Rounded corners */
  }
`;

const Title = styled.h3`
  font-family: 'Inter', sans-serif;
  color: #121212;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.78px;
  text-align: left;
  padding: 20px 20px 10px 20px;
  margin-bottom: 0px;
`;

const TabDiv = styled.div<{isActive?: boolean}>`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  color: ${p => p?.isActive ? '#121212' : '#4E5156'};
  font-weight: ${p => p?.isActive ? '500' : '400'};
`;

const HeaderWrapper = styled.div`
  @media screen and (max-width: 500px) {
    max-width: 95vw !important;
    min-width: 90vw !important;
  }
`;

const NotificationList = styled.ul`
  padding-left: 0;
  padding: 0;
  margin-bottom: 0;
  .notification-text {
    margin-bottom: 0 !important;
    margin-left: 16px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #4E5156;
    p {
      margin-bottom: 0 !important;
    }
  }
  .notification-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 15px;
    color: #121212;
    margin-bottom: 2px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .notification-item {
    cursor: pointer;
    padding: 6px 20px 9px;
    min-height: 80px !important;
    &:hover {
      background-color: rgba(127, 78, 173, 0.1);
    }
    li {
      display: flex;
      align-items: baseline;
      &::before {
        content: '•';
        width: 6px;
        height: 6px;
        font-size: 18px;
        margin-right: 10px;
      }
    }
  }
`;

const ContentWrapper = styled.div<{addEllipsis1Line?: boolean; addEllipsis2Line?: boolean}>`
  ${p => p?.addEllipsis1Line ? `
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 17px;
  ` : ''}
    ${p => p?.addEllipsis2Line ? `
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 32px;
  ` : ''}
  p, div {
    ${p => p?.addEllipsis1Line ? `
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  ` : ''}
    ${p => p?.addEllipsis2Line ? `
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  ` : ''}
  }
`;

const StyledCheckbox = styled(Checkbox)`
  color: #121212;
  font-size: 14px;
  line-height: 16.94px;
  font-weight: 400;
  padding-left: 20px;
  padding-right: 20px;
  .ant-checkbox-inner {
    border-radius: 5px;
    width: 16px;
    height: 16px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #7F4EAD;
    border-color: #7F4EAD;
  }
  .ant-checkbox-checked::after {
    border: none !important;
  }
  .ant-checkbox:not(.ant-checkbox-checked):hover .ant-checkbox-inner,
  .ant-checkbox:not(.ant-checkbox-checked):hover .ant-checkbox-inner,
  .ant-checkbox-input:not(:checked) + .ant-checkbox-inner {
    border-color: #d9d9d9 !important;
  }
`;

const StyledTabs = styled(Tabs)`
margin-left: 8px !important;
.rc-tabs-nav {
  height: 30px !important;
  padding-top: 0 !important;
  border-bottom: 1px solid #D9D9D9 !important;
}
.rc-tabs-nav-operations{
  display: none !important;
}
.rc-tabs-ink-bar {
  background-color: #ED991A !important;
  height: 5px !important;
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
}

.rc-tabs-nav{
  height: 50px !important;
  margin-left: 0px !important;
  .rc-tabs-tab {
    
  margin-right: 0px !important;
  width: 91px;
  display: flex;
  align-items: center;
  justify-content: center;

  .rc-tabs-tab-btn{
    padding: 5px 18px !important;
  }
}
}
`;

const EmptyStyled = styled(Empty)`
  color: rgba(0, 0, 0, 0.25);
  height: 200px;
  padding-top: 30px;
`;
