import {
  types,
  flow,
  Instance,
  cast,
} from 'mobx-state-tree';
import {KEYWORD_RESEARCH_API_V2} from '@/api/KeywordResearcherV2';
import {formatSorters} from '@/utils/filters';
import {toJS} from 'mobx';
import {useErrorNotification} from '@/utils/notification-v2';
import {getSingleUrlParam, isRanckTrackerPublic} from '@/utils/url';
import moment from 'moment/moment';
import {apiError} from '@/utils/api';
import {notification} from '@/utils/notification-v2';
// import {KrtCriteriaType} from '../krt-criteria';

const KRT_FILTER_LIST = [
  {id: 1, name: 'exclude', header: 'Keyword', filterField: 'keyword', text: '', type: undefined, active: false, isSearch: true, customFields: [
    {label: 'Contains', operator: 'contains'},
    {label: 'Exclude', operator: 'exclude'},
  ]},
  {id: 7, name: 'location_filter', header: 'Location', filterField: 'location', query: '', type: undefined, active: false, isSearch: false},
  {id: 2, name: 'exclude', header: 'Position', filterField: 'keyword', text: '', type: undefined, active: false, isSearch: true, customFields: [
    {label: 'Greater than', operator: 'greater_than', inputType: 'number', min: 0},
    {label: 'Less than', operator: 'less_than', inputType: 'number', min: 0},
    {label: 'Equals', operator: 'equals', inputType: 'number', min: 0},
    {label: 'Not equals', operator: 'not_equals', inputType: 'number'},
  ]},
  {id: 3, name: 'exclude', header: 'Search Volume', filterField: 'keyword', text: '', type: undefined, active: false, isSearch: true, customFields: [
    {label: 'Greater than', operator: 'greater_than', inputType: 'number', min: 0},
    {label: 'Less than', operator: 'less_than', inputType: 'number', min: 0},
  ]},
  {id: 4, name: 'radioButtonsFilter', header: 'Keyword movement', filterField: 'keyword_movement', text: '', type: undefined, active: false, isSearch: true, filterTypes: [
    {label: 'Up', value: 'up'},
    {label: 'Down', value: 'down'},
    {label: 'Unchanged', value: 'unchanged'},
  ]},
  {id: 5, name: 'radioButtonsFilter', header: 'SERP Features', filterField: 'serp_feature', text: '', type: undefined, active: false, isSearch: true, filterTypes: [
    {label: 'People also ask', value: 'people_also_ask'},
    {label: 'Related searches', value: 'related_searches'},
    {label: 'Video', value: 'video'},
    {label: 'Images', value: 'images'},
    {label: 'Organic', value: 'organic'},
    {label: 'Paid', value: 'paid'},
  ]},
  {
    id: 6,
    name: 'radioButtonsFilter',
    isSearch: null,
    text: null,
    customFilterValue: '',
    header: 'Watchlisted',
    type: null,
    from: null,
    query: null,
    operator: null,
    to: null,
    active: false,
    filterField: null,
    customFields: null,
    customOptions: null,
    customOptionsTop: null,
    filterTypes: [
      {
        label: 'Yes',
        value: 'yes',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
];

const KrtFilters = [
  {id: 2, name: 'exclude', header: 'Avg Position', filterField: 'position_operator', text: '', type: undefined, active: false, isSearch: false, customFields: [
    {label: 'Greater than', operator: 'greater_than', inputType: 'number'},
    {label: 'Less than', operator: 'less_than', inputType: 'number'},
    {label: 'Equals', operator: 'equals', inputType: 'number'},
    {label: 'Not equals', operator: 'not_equals', inputType: 'number'},
  ]},
  {id: 3, name: 'exclude', header: 'Traffic', filterField: 'traffic_operator', text: '', type: undefined, active: false, isSearch: false, customFields: [
    {label: 'Greater than', operator: 'greater_than', inputType: 'number'},
    {label: 'Less than', operator: 'less_than', inputType: 'number'},
    {label: 'Equals', operator: 'equals', inputType: 'number'},
    {label: 'Not equals', operator: 'not_equals', inputType: 'number'},
  ]},
];

export const krtFilterListModel = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  header: types.maybeNull(types.string),
  customFilterValue: types.optional(types.string, ''),
  type: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  query: types.maybeNull(types.string),
  operator: types.maybeNull(types.string),
  from: types.maybeNull(types.string),
  to: types.maybeNull(types.string),
  isSearch: types.maybeNull(types.boolean),
  active: types.boolean,
  filterField: types.maybeNull(types.string),
  filterTypes: types.maybeNull(types.array(types.model({
    label: types.maybeNull(types.string),
    value: types.maybeNull(types.string),
  }))),
  customFields: types.maybeNull(types.array(types.model({
    label: types.maybeNull(types.string),
    operator: types.maybeNull(types.string),
    inputType: types.maybeNull(types.string),
    min: types.maybeNull(types.number),
    max: types.maybeNull(types.number),
  }))),
});
const SerpsOverviewModel = types.model({
  date: types.maybeNull(types.string),
  serp1: types.maybeNull(types.number),
  serp23: types.maybeNull(types.number),
  serp410: types.maybeNull(types.number),
  serp1120: types.maybeNull(types.number),
  serp2150: types.maybeNull(types.number),
  serp51100: types.maybeNull(types.number),
});
const SearchVisibilityModel = types.model({
  date: types.maybeNull(types.string),
  sv: types.maybeNull(types.number),
});
const TrafficReportModel = types.model({
  date: types.maybeNull(types.string),
  traffic: types.maybeNull(types.number),
});
const KeywordsUpDownHistoricalDataModel = types.model({
  date: types.maybeNull(types.string),
  keywordsUp: types.maybeNull(types.number),
  keywordsDown: types.maybeNull(types.number),
});
const KeywordsUpDownModel = types.model({
  keywordsUp: types.maybeNull(types.number),
  keywordsDown: types.maybeNull(types.number),
  keywordsUnchanged: types.maybeNull(types.number),
  historicalData: types.maybeNull(types.array(KeywordsUpDownHistoricalDataModel)),
});
const PositionLegendsModel = types.model({
  currentAvgPosition: types.maybeNull(types.number),
  positionDelta: types.maybeNull(types.number),
  previousAvgPosition: types.maybeNull(types.number),
});


const RanckTrackerItemModel = types.model({
  id: types.maybeNull(types.number),
  crawlConcurrency: types.maybeNull(types.number),
  refreshInterval: types.maybeNull(types.number),
  targetedKeywordsTaskStatus: types.maybeNull(types.string),
  created: types.maybeNull(types.string),
  firstKeywordAddedAt: types.maybeNull(types.string),
  targetedKeywordsUpdatedAt: types.maybeNull(types.string),
  targetedKeywordsCount: types.maybeNull(types.number),
  serpsOverview: types.maybeNull(types.array(SerpsOverviewModel)),
  searchVisibilityReport: types.maybeNull(types.array(SearchVisibilityModel)),
  estimatedTrafficReport: types.maybeNull(types.array(TrafficReportModel)),
  publicShareHash: types.maybeNull(types.string),
  hostname: types.maybeNull(types.string),
  countryCode: types.maybeNull(types.string),
  gscSiteProperty: types.maybeNull(types.string),
  shouldRepoll: types.maybeNull(types.boolean),
  language: types.maybeNull(types.string),
  preciseLocation: types.maybeNull(types.string),
  competitors: types.maybeNull(types.array(types.string)),
  postProcessingTaskStatus: types.maybeNull(types.string),
  postProcessingUpdatedAt: types.maybeNull(types.string),
  trackedKeywords: types.maybeNull(types.array(types.maybeNull(types.string))),
  trackedKeywordsCount: types.maybeNull(types.number),
  serpsTaskStatus: types.maybeNull(types.string),
  serpsUpdatedAt: types.maybeNull(types.string),
  keywordsUpDownReport: types.maybeNull(KeywordsUpDownModel),
  positionLegends: types.maybeNull(PositionLegendsModel),
  autoCreated: types.maybeNull(types.boolean),
  description: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
});

export type RankTrackerListItemType = Instance<typeof RanckTrackerItemModel>;

const RankTrackerListModel = types.model({
  count: types.maybeNull(types.number),
  next: types.maybeNull(types.string),
  previous: types.maybeNull(types.string),
  totalPages: types.maybeNull(types.number),
  pageSize: types.maybeNull(types.number),
  results: types.maybeNull(types.array(RanckTrackerItemModel)),
});


const TargetKeywordsArrayModel = types.model({
  keyword: types.maybeNull(types.string),
  position: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
});

const TargetKeywordsModel = types.model({
  targetedKeywordsTaskStatus: types.maybeNull(types.string),
  keywords: types.maybeNull(types.array(TargetKeywordsArrayModel)),
});
const TrackedKeywordsResultsModel = types.model({
  id: types.maybeNull(types.number),
  project: types.maybeNull(types.number),
  position: types.maybeNull(types.number),
  searchVolume: types.maybeNull(types.number),
  created: types.maybeNull(types.string),
  modified: types.maybeNull(types.string),
  topUrl: types.maybeNull(types.string),
  isTracked: types.maybeNull(types.boolean),
  keyword: types.maybeNull(types.string),
  serpFeatures: types.maybeNull(types.array(types.string)),
  device: types.maybeNull(types.string),
  location: types.maybeNull(types.string),
});

const TrackedKeywordsModel = types.model({
  count: types.maybeNull(types.number),
  next: types.maybeNull(types.string),
  previous: types.maybeNull(types.number),
  totalPages: types.maybeNull(types.number),
  pageSize: types.maybeNull(types.number),
  results: types.maybeNull(types.array(TrackedKeywordsResultsModel)),
});

const ProjectModel = types.model({
  competitors: types.maybeNull(types.array(types.string)),
  countryCode: types.maybeNull(types.string),
  crawlConcurrency: types.maybeNull(types.number),
  created: types.maybeNull(types.string),
  gscSiteProperty: types.maybeNull(types.string),
  hostname: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  language: types.maybeNull(types.string),
  postProcessingTaskStatus: types.maybeNull(types.string),
  postProcessingUpdatedAt: types.maybeNull(types.string),
  preciseLocation: types.maybeNull(types.string),
  publicShareHash: types.maybeNull(types.string),
  serpsOverview: types.maybeNull(types.array(SerpsOverviewModel)),
  targetedKeywordsCount: types.maybeNull(types.number),
  targetedKeywordsTaskStatus: types.maybeNull(types.string),
  targetedKeywordsUpdatedAt: types.maybeNull(types.string),
  trackedKeywordsCount: types.maybeNull(types.number),
});

const ProjectDetailsModel = types.model({
  autoCreated: types.maybeNull(types.boolean),
  competitors: types.maybeNull(types.array(types.string)),
  countryCode: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  crawlConcurrency: types.maybeNull(types.number),
  refreshInterval: types.maybeNull(types.number),
  created: types.maybeNull(types.string),
  firstKeywordAddedAt: types.maybeNull(types.string),
  gscSiteProperty: types.maybeNull(types.string),
  hostname: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  language: types.maybeNull(types.string),
  postProcessingTaskStatus: types.maybeNull(types.string),
  postProcessingUpdatedAt: types.maybeNull(types.string),
  preciseLocation: types.maybeNull(types.string),
  publicShareHash: types.maybeNull(types.string),
  serpsOverview: types.maybeNull(types.array(SerpsOverviewModel)),
  targetedKeywordsCount: types.maybeNull(types.number),
  targetedKeywordsTaskStatus: types.maybeNull(types.string),
  targetedKeywordsUpdatedAt: types.maybeNull(types.string),
  trackedKeywordsCount: types.maybeNull(types.number),
  shouldRepoll: types.maybeNull(types.boolean),
  name: types.maybeNull(types.string),
});
const SerpsOverviewDetailsModel = types.model({
  date: types.maybeNull(types.string),
  serp1: types.maybeNull(types.number),
  serp23: types.maybeNull(types.number),
  serp410: types.maybeNull(types.number),
  serp1120: types.maybeNull(types.number),
  serp2150: types.maybeNull(types.number),
  serp51100: types.maybeNull(types.number),
});
export type SerpsOverviewDetailsType = Instance<typeof SerpsOverviewDetailsModel>;
const SerpsOverviewLegendModel = types.model({
  currentSerp1: types.maybeNull(types.number),
  currentSerp23: types.maybeNull(types.number),
  currentSerp410: types.maybeNull(types.number),
  currentSerp110: types.maybeNull(types.number),
  currentSerp1120: types.maybeNull(types.number),
  currentSerp2150: types.maybeNull(types.number),
  currentSerp51100: types.maybeNull(types.number),
  previousSerp1: types.maybeNull(types.number),
  previousSerp23: types.maybeNull(types.number),
  previousSerp410: types.maybeNull(types.number),
  previousSerp110: types.maybeNull(types.number),
  previousSerp1120: types.maybeNull(types.number),
  previousSerp2150: types.maybeNull(types.number),
  previousSerp51100: types.maybeNull(types.number),
});
const AvgPositionHistModel = types.model({
  avgPosition: types.maybeNull(types.number),
  date: types.maybeNull(types.string),
});
const Top20PositionHistModel = types.model({
  avgPosition: types.maybeNull(types.number),
  date: types.maybeNull(types.string),
});
const Top10PositionHistModel = types.model({
  avgPosition: types.maybeNull(types.number),
  kwCount: types.maybeNull(types.number),
  date: types.maybeNull(types.string),
});
const AvgSvHistModel = types.model({
  sv: types.maybeNull(types.number),
  date: types.maybeNull(types.string),
});
const KeywordsChangeLegendsModel = types.model({
  top1Up: types.maybeNull(types.number),
  top23Up: types.maybeNull(types.number),
  top410Up: types.maybeNull(types.number),
  top1120Up: types.maybeNull(types.number),
  top2150Up: types.maybeNull(types.number),
  top51100Up: types.maybeNull(types.number),
  top1Down: types.maybeNull(types.number),
  top23Down: types.maybeNull(types.number),
  top410Down: types.maybeNull(types.number),
  top1120Down: types.maybeNull(types.number),
  top2150Down: types.maybeNull(types.number),
  top51100Down: types.maybeNull(types.number),
  top1Unchanged: types.maybeNull(types.number),
  top23Unchanged: types.maybeNull(types.number),
  top410Unchanged: types.maybeNull(types.number),
  top1120Unchanged: types.maybeNull(types.number),
  top2150Unchanged: types.maybeNull(types.number),
  top51100Unchanged: types.maybeNull(types.number),
  top1UpPct: types.maybeNull(types.number),
  top23UpPct: types.maybeNull(types.number),
  top410UpPct: types.maybeNull(types.number),
  top1120UpPct: types.maybeNull(types.number),
  top2150UpPct: types.maybeNull(types.number),
  top51100UpPct: types.maybeNull(types.number),
  top1DownPct: types.maybeNull(types.number),
  top23DownPct: types.maybeNull(types.number),
  top410DownPct: types.maybeNull(types.number),
  top1120DownPct: types.maybeNull(types.number),
  top2150DownPct: types.maybeNull(types.number),
  top51100DownPct: types.maybeNull(types.number),
  top1UnchangedPct: types.maybeNull(types.number),
  top23UnchangedPct: types.maybeNull(types.number),
  top410UnchangedPct: types.maybeNull(types.number),
  top1120UnchangedPct: types.maybeNull(types.number),
  top2150UnchangedPct: types.maybeNull(types.number),
  top51100UnchangedPct: types.maybeNull(types.number),
});
const KeywordsChangesModel = types.model({
  keywordsUp: types.maybeNull(types.number),
  keywordsDown: types.maybeNull(types.number),
  keywordsUnchanged: types.maybeNull(types.number),
  notRanking: types.maybeNull(types.number),
  legends: types.maybeNull(KeywordsChangeLegendsModel),
});
const AvgPositionHistDeltaModel = types.model({
  avgPositionDelta: types.maybeNull(types.number),
  currentAvgPosition: types.maybeNull(types.number),
  previousAvgPosition: types.maybeNull(types.number),
});
const AvgPositionSvDeltaModel = types.model({
  svDelta: types.maybeNull(types.number),
  currentSv: types.maybeNull(types.number),
  previousSv: types.maybeNull(types.number),
});
const top10PositionHistDeltaModel = types.model({
  kwCountDelta: types.maybeNull(types.number),
  currentKwCount: types.maybeNull(types.number),
  previousKwCount: types.maybeNull(types.number),
});
const TrackedKeywordHistoryModel = types.model({
  date: types.maybeNull(types.string),
  keywordsAdded: types.maybeNull(types.array(types.string)),
  keywordsAddedCount: types.maybeNull(types.number),
  keywordsRemoved: types.maybeNull(types.array(types.string)),
  keywordsRemovedCount: types.maybeNull(types.number),
});
const CoreReportsModel = types.model({
  serpsOverview: types.maybeNull(types.array(SerpsOverviewDetailsModel)),
  serpsOverviewLegend: types.maybeNull(SerpsOverviewLegendModel),
  avgPositionHist: types.maybeNull(types.array(AvgPositionHistModel)),
  avgPositionHistDelta: types.maybeNull(AvgPositionHistDeltaModel),
  avgSvHistDelta: types.maybeNull(AvgPositionSvDeltaModel),
  top10PositionHistDelta: types.maybeNull(top10PositionHistDeltaModel),
  avgSvHist: types.maybeNull(types.array(AvgSvHistModel)),
  top20PositionHist: types.maybeNull(types.array(Top20PositionHistModel)),
  top10PositionHist: types.maybeNull(types.array(Top10PositionHistModel)),
  trackedKeywordsCount: types.maybeNull(types.number),
  keywordsChanges: types.maybeNull(KeywordsChangesModel),
  trackedKeywordsHistory: types.maybeNull(types.array(TrackedKeywordHistoryModel)),
});
const PositionHistModel = types.model({
  position: types.maybeNull(types.number),
  date: types.maybeNull(types.string),
});

const KeywordsDetailsResultsModel = types.model({
  keyword: types.maybeNull(types.string),
  avgPositionDelta: types.maybeNull(types.number),
  currentAvgPosition: types.maybeNull(types.number),
  previousAvgPosition: types.maybeNull(types.number),
  updatedAt: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
  dailyDateCompareTo: types.maybeNull(types.string),
  sf: types.maybeNull(types.array(types.string)),
  lastDayAvgPosition: types.maybeNull(types.number),
  lastWeekAvgPosition: types.maybeNull(types.number),
  lastMonthAvgPosition: types.maybeNull(types.number),
  lastDayAvgPositionDelta: types.maybeNull(types.number),
  lastWeekAvgPositionDelta: types.maybeNull(types.number),
  lastMonthAvgPositionDelta: types.maybeNull(types.number),
  positionHist: types.maybeNull(types.array(PositionHistModel)),
  searchVolume: types.maybeNull(types.number),
  searchIntent: types.maybeNull(types.string),
  location: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  locationId: types.maybeNull(types.string),
  isRestricted: types.maybeNull(types.boolean),
  isStarred: types.maybeNull(types.boolean),
});

const KeywordChangesModel = types.model({
  keywordsUp: types.maybeNull(types.number),
  keywordsDown: types.maybeNull(types.number),
  keywordsUnchanged: types.maybeNull(types.number),
  notRanking: types.maybeNull(types.number),
});

export type KeywordDetailsResultType = Instance<typeof KeywordsDetailsResultsModel>;
const KeywordsDetailsModel = types.model({
  count: types.maybeNull(types.number),
  next: types.maybeNull(types.string),
  prev: types.maybeNull(types.number),
  totalPages: types.maybeNull(types.number),
  pageSize: types.maybeNull(types.number),
  keywordChanges: types.maybeNull(KeywordChangesModel),
  results: types.maybeNull(types.array(KeywordsDetailsResultsModel)),
});

const KeywordDetailsDrawerResultsModel = types.model({
  position: types.maybeNull(types.number),
  estimatedTraffic: types.maybeNull(types.number),
  searchVisibility: types.maybeNull(types.number),
  searchVolume: types.maybeNull(types.number),
  date: types.maybeNull(types.string),
  serpFeatures: types.maybeNull(types.array(types.string)),
  topUrl: types.maybeNull(types.string),
});

const KeywordDetailsDrawerDataModel = types.model({
  results: types.maybeNull(types.array(KeywordDetailsDrawerResultsModel)),
});

const ProjectCompetitorsResultsType = types.model({
  url: types.maybeNull(types.string),
  keywordsInTop10: types.maybeNull(types.number),
  avgPosition: types.maybeNull(types.number),
  previousSearchVisibility: types.maybeNull(types.number),
  currentSearchVisibility: types.maybeNull(types.number),
  color: types.maybeNull(types.string),
});

const ProjectCompetitorsSharedKeywordsType = types.model({
  sets: types.maybeNull(types.array(types.string)),
  size: types.maybeNull(types.number),
  color: types.maybeNull(types.string),
});

const ProjectCompetitorsType = types.model({
  count: types.maybeNull(types.number),
  next: types.maybeNull(types.string),
  prev: types.maybeNull(types.number),
  totalPages: types.maybeNull(types.number),
  pageSize: types.maybeNull(types.number),
  results: types.maybeNull(types.array(ProjectCompetitorsResultsType)),
  sharedKeywords: types.maybeNull(types.array(ProjectCompetitorsSharedKeywordsType)),
});

const ProjectCompetitorsTypeV2 = types.model({
  id: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  keywordPositionChange: types.maybeNull(types.array(types.model({
    keyword: types.maybeNull(types.string),
    location: types.maybeNull(types.string),
    currentAvgPosition: types.maybeNull(types.number),
    previousAvgPosition: types.maybeNull(types.number),
    change: types.maybeNull(types.number),
  }))),
});

export const KeywordRankTracker = types.model({
  loadingList: types.boolean,
  creatingProject: types.boolean,
  updatingTrackedKeywords: types.boolean,
  loadingTrackedKeywords: types.boolean,
  loadingTargetKeywords: types.boolean,
  loadingDetails: types.boolean,
  detailsRepolling: types.boolean,
  loadingDetailsCoreReports: types.boolean,
  loadingKeywordsDetails: types.boolean,
  loadingInitKeywordsDetails: types.boolean,
  rankTrackerList: types.maybeNull(RankTrackerListModel),
  rankTrackerCount: types.maybeNull(types.number),
  targetKeywords: types.maybeNull(TargetKeywordsModel),
  shouldStopTargetKeywordsRepolling: types.boolean,
  trackedKeywords: types.maybeNull(TrackedKeywordsModel),
  project: types.maybeNull(ProjectModel),
  details: types.maybeNull(ProjectDetailsModel),
  detailsCoreReports: types.maybeNull(CoreReportsModel),
  keywordsDetails: types.maybeNull(KeywordsDetailsModel),
  keywordsDetailsChartMinDate: types.maybeNull(types.string),
  keywordDetailsSearchTerm: types.maybeNull(types.string),
  keywordsDetailsSortField: types.maybeNull(types.string),
  keywordsDetailsSortDirection: types.maybeNull(types.string),
  keywordDetailsPageNumber: types.maybeNull(types.number),
  keywordDetailsPageSize: types.maybeNull(types.number),
  isFirstDetailsRepoll: types.boolean,
  isFirstProjectListRepoll: types.boolean,
  projectListRepollCount: types.number,
  shouldStopDetailsRepolling: types.boolean,
  shouldStopRepolling: types.boolean,
  projectEditModalCurStep: types.number,
  isEditKeywords: types.boolean,
  isEditSettings: types.boolean,
  refreshProjectLoading: types.boolean,
  currentListPage: types.number,
  keywordDetailsSortField: types.maybeNull(types.string),
  keywordDetailsSortDirection: types.maybeNull(types.string),
  keywordDetailsDrawerData: types.maybeNull(KeywordDetailsDrawerDataModel),
  showDetailsDrawer: types.boolean,
  loadingKeywordDetails: types.boolean,
  loadingInitKeywordDetails: types.boolean,
  isDrawerCloseAction: types.boolean,
  showProcessingKeywordsBanner: types.boolean,
  projectCompetitors: types.maybeNull(ProjectCompetitorsType),
  projectCompetitorsV2: types.maybeNull(types.model({competitors: types.maybeNull(types.array(ProjectCompetitorsTypeV2))})),
  loadingProjectCompetitors: types.boolean,
  loadingInitProjectCompetitors: types.boolean,
  projectCompetitorsSortField: types.maybeNull(types.string),
  projectCompetitorsSortDirection: types.maybeNull(types.string),
  projectCompetitorsPageNumber: types.maybeNull(types.number),
  projectCompetitorsPageSize: types.maybeNull(types.number),
  forceShowDetailsPage: types.maybeNull(types.boolean),
  krtFiltersList: types.array(krtFilterListModel),
  trackedKeywordsSortingKey: types.maybeNull(types.string),
  competitorsSortingKey: types.maybeNull(types.string),
  krtFilters: types.array(krtFilterListModel),
  krtSortField: types.maybeNull(types.string),
  krtSortDirection: types.maybeNull(types.string),
  krtSearchTerm: types.maybeNull(types.string),
  repollingDetailId: types.number,
  startDate: types.Date,
  endDate: types.Date,
  competitorsLoading: types.boolean,
  addingCompetitors: types.boolean,
  editingCompetitors: types.boolean,
  deletingCompetitor: types.boolean,
  addMore: types.boolean,
  projectList: types.maybeNull(types.frozen()),
}).views(self => ({
  get getKeywordDrawerDetails() {
    return self.keywordDetailsDrawerData?.results ?? [];
  },
  get getRankTrackerList() {
    return toJS(self.rankTrackerList);
  },
  get getRankTrackerCounts() {
    return toJS(self.rankTrackerCount);
  },
  get getKrtFiltersList() {
    return toJS(self.krtFiltersList);
  },
  get getKrtFilters() {
    return toJS(self.krtFilters);
  },
  get getKeywordsDetailsChartMinDate() {
    return toJS(self.keywordsDetailsChartMinDate);
  },
})).actions(self => {
  const getPublicHash = () => getSingleUrlParam('public_hash');

  const getList = flow(function* (isLoadMore = false, isSimple = false, repollCount = 0, noLoading = false, pageSize = 10) {
    const payLoad = {
      position: self.krtFilters?.find(item=> item?.id == 2)?.query,
      position_operator: self.krtFilters?.find(item=> item?.id == 2)?.type,
      traffic: self.krtFilters?.find(item=> item?.id == 3)?.query,
      traffic_operator: self.krtFilters?.find(item=> item?.id == 3)?.type,
    };

    const sorters = {
      currentAvgPosition: 'average_position',
      estimatedTraffic: 'estimated_traffic',
    };

    const formattedSorters = formatSorters(sorters, self.krtSortField, self.krtSortDirection);
    try {
      if (!noLoading) self.loadingList = true;
      const data = yield KEYWORD_RESEARCH_API_V2.getKwRankTrackerList(!isSimple ? self.currentListPage : null, isSimple, isLoadMore, getPublicHash(), formattedSorters, self.krtSearchTerm, payLoad, pageSize);
      if (data?.isCancel) return;

      const existingResults = isLoadMore ? toJS(self.rankTrackerList)?.results ?? [] : self.rankTrackerList?.next ? toJS(self.rankTrackerList)?.results : [];
      const updatedData = {
        ...data,
        results: self.currentListPage == 1 ? data?.results ?? [] : [...existingResults, ...(data?.results ?? [])],
      };

      if (!isSimple || isLoadMore) {
        self.rankTrackerList = updatedData;
      } else {
        const currentListData = toJS(self.rankTrackerList);
        const currentList = [];
        const changedList = [];


        if (currentListData?.results && updatedData?.results) {
          updatedData?.results?.forEach(result => {
            const itemToCheck = currentListData?.results?.find(item => item.id == result.id);
            if (itemToCheck) {
              if (itemToCheck.shouldRepoll !== result.shouldRepoll) {
                changedList.push(result);
              }
            }
          });

          if (changedList.length > 0 || data?.results?.length !== self.rankTrackerList?.results?.length) {
            currentListData.results.forEach(item => {
              currentList.push(
                {
                  ...item,
                  postProcessingTaskStatus: updatedData.results.find(result => result.id == item.id)?.postProcessingTaskStatus,
                  postProcessingUpdatedAt: updatedData.results.find(result => result.id == item.id)?.postProcessingUpdatedAt,
                  serpsTaskStatus: updatedData.results.find(result => result.id == item.id)?.serpsTaskStatus,
                  serpsUpdatedAt: updatedData.results.find(result => result.id == item.id)?.serpsUpdatedAt,
                  targetKeywordTaskStatus: updatedData.results.find(result => result.id == item.id)?.targetKeywordTaskStatus,
                  targetKeywordsUpdatedAt: updatedData.results.find(result => result.id == item.id)?.targetKeywordsUpdatedAt,
                  shouldRepoll: updatedData.results.find(result => result.id == item.id)?.shouldRepoll,
                },
              );
            });
            self.rankTrackerList.results = cast(currentList);
          }
        }
      }


      self.loadingList = false;
      if (!self.shouldStopRepolling && (updatedData?.results?.filter(item => item.shouldRepoll)?.length > 0) ) {
        // increment repoll count
        const newCount = repollCount + 1;
        yield new Promise(r => setTimeout(r, 3000));
        return getList(false, true, newCount, false, pageSize);
      } else {
        // if we did at least 1 repoll, after repoll ends get full list details once more.
        if (repollCount > 0) {
          getList(false, false, 0, false, pageSize);
        }
        self.loadingList = false;
      }
    } catch (e) {
      self.loadingList = false;
      const errorMessage = apiError(e);
      if (errorMessage === 'A valid integer is required.') {
        notification.error('', errorMessage);
      } else {
        useErrorNotification({
          e,
          msg: 'KRT Projects could not be loaded',
          desc: 'Please try again later.',
        });
      }
    } finally {
      self.loadingList = false;
    }
  });

  const updateKrtFilters = filters => {
    self.krtFilters = cast(filters);
    getList();
  };

  const getListWithoutRepoll = flow(function* (pageSize = 10) {
    try {
      const data = yield KEYWORD_RESEARCH_API_V2.getKwRankTrackerListWithoutRepoll(self.currentListPage, pageSize, getPublicHash(), (self.krtSearchTerm ? self.krtSearchTerm : ''));

      const existingResults = toJS(self.rankTrackerList)?.results ?? [];
      const updatedData = {
        ...data,
        results: self.currentListPage == 1 ? data?.results ?? [] : [...existingResults, ...(data?.results ?? [])],
      };
      self.rankTrackerCount = data.count;
      self.rankTrackerList = updatedData;
    } catch (e) {
      useErrorNotification({
        e,
        msg: 'KRT Projects could not be loaded',
        desc: 'Please try again later.'});
      return Promise.reject(e);
    }
  });

  const addCompetitors = flow(function* (id, payload) {
    self.addingCompetitors = true;
    try {
      const response = yield KEYWORD_RESEARCH_API_V2.addCompetitorsAPI(id, payload);
      if (response?.isCancel) return;
      return response;
    } catch (e) {
      const errorMessage = apiError(e);
      useErrorNotification({
        e,
        msg: errorMessage,
        permanent: false,
        handleStatuses: [
          {
            statuses: [400],
            msg: apiError(e) as string,
            ...(e.response?.data?.nonFieldErrors && {desc: e.response?.data?.nonFieldErrors.join('\n\n')}),
            showDetails: false,
          },
        ],
      });
      return Promise.reject(e);
    } finally {
      self.addingCompetitors = false;
    }
  });

  const editCompetitors = flow(function* (id, payload) {
    self.editingCompetitors = true;
    try {
      const response = yield KEYWORD_RESEARCH_API_V2.editCompetitorsAPI(id, payload);
      if (response?.isCancel) return;
      return response;
    } catch (e) {
      const errorMessage = apiError(e);
      useErrorNotification({
        e,
        msg: errorMessage,
        permanent: false,
        handleStatuses: [
          {
            statuses: [400],
            msg: apiError(e) as string,
            ...(e.response?.data?.nonFieldErrors && {desc: e.response?.data?.nonFieldErrors.join('\n\n')}),
            showDetails: false,
          },
        ],
      });
      return Promise.reject(e);
    } finally {
      self.editingCompetitors = false;
    }
  });

  const deleteCompetitor = flow(function* (id, competitorId) {
    self.deletingCompetitor = true;
    try {
      const response = yield KEYWORD_RESEARCH_API_V2.deleteCompetitorAPI(id, competitorId);
      if (response?.isCancel) return;
      return response;
    } catch (e) {
      const errorMessage = apiError(e);
      notification?.error('', errorMessage);
    } finally {
      self.deletingCompetitor = false;
    }
  });

  const getTargetKeywords = flow(function* (projectId: number) {
    self.loadingTargetKeywords = true;
    self.targetKeywords = null;
    try {
      const data = yield KEYWORD_RESEARCH_API_V2.getKwRankTargetKw(projectId, getPublicHash());
      if (data.isCancel) return;

      self.targetKeywords = data;
      if (!self.shouldStopTargetKeywordsRepolling &&
        (data?.targetedKeywordsTaskStatus !== 'SUCCESS' && data?.targetedKeywordsTaskStatus !== 'FAILURE')) {
        yield new Promise(r => setTimeout(r, 3000));
        return getTargetKeywords(projectId);
      } else {
        self.loadingTargetKeywords = false;
      }
      return data;
    } catch (e) {
      useErrorNotification({
        e,
        msg: 'Failed to get keyword data',
        desc: 'Please try again later.'});
      self.loadingTargetKeywords = false;
      return Promise.reject(e);
    }
  });
  const getTrackedKeywords = flow(function* (projectId: number, pageSize?: number) {
    self.loadingTrackedKeywords = true;
    try {
      const data = yield KEYWORD_RESEARCH_API_V2.getKwRankTrackedKw(projectId, pageSize, getPublicHash());
      if (data.isCancel) return;

      self.trackedKeywords = data;

      return data;
    } catch (e) {
      useErrorNotification({
        e,
        msg: 'Failed to get keyword data',
        desc: 'Please try again later.'});
      return Promise.reject(e);
    } finally {
      self.loadingTrackedKeywords = false;
    }
  });

  const createProject = flow(function* (payload) {
    if (getPublicHash()) return;
    self.creatingProject = true;
    try {
      const data = yield KEYWORD_RESEARCH_API_V2.createTrackedProject(payload);
      if (data.isCancel) return;

      self.project = data;

      return data;
    } catch (e) {
      // useErrorNotification({
      //   e,
      //   msg: 'Failed to Create KRT Site',
      //   desc: 'Please try again later.'});
      return Promise.reject(e);
    } finally {
      self.creatingProject = false;
    }
  });

  const deleteProject = flow(function* (projectId) {
    if (getPublicHash()) return;
    self.loadingList = true;
    self.shouldStopRepolling = true;
    try {
      yield KEYWORD_RESEARCH_API_V2.deleteTrackedProject(projectId);
    } catch (e) {
      useErrorNotification({
        e,
        msg: 'Failed to delete project',
        desc: 'Please try again later.'});
      return Promise.reject(e);
    } finally {
      self.loadingList = false;
      self.shouldStopRepolling = false;
    }
  });

  const updateProject = flow(function* (projectId, payload) {
    if (getPublicHash()) return;
    self.loadingList = true;
    try {
      const data = yield KEYWORD_RESEARCH_API_V2.updateTrackedProject(projectId, payload);
      if (data.isCancel) return;

      self.project = data;

      return data;
    } catch (e) {
      useErrorNotification({
        e,
        msg: 'Failed to update project',
        desc: 'Please try again later.'});
      return Promise.reject(e);
    } finally {
      self.loadingList = false;
    }
  });

  const addTrackedKeywords = flow(function* (payload: string[], projectId: number) {
    if (getPublicHash()) return;
    self.loadingList = true;
    self.updatingTrackedKeywords = true;

    // cleaning up keywords array from empty strings
    const cleanedKwPayload = [];
    for (let i = 0; i < payload.length; i++) {
      if (payload[i].trim() !== '') cleanedKwPayload.push(payload[i].trim());
    }

    try {
      const data = yield KEYWORD_RESEARCH_API_V2.addTrackedKeywords(cleanedKwPayload, projectId);
      if (data.isCancel) return;

      const foundProject = self.rankTrackerList?.results?.length ? toJS(self.rankTrackerList.results).find(project => project.id == projectId) : null;
      if (foundProject) {
        // updating project with added tracked keywords count
        // very important as it will allow us to avoid showing no kw view
        const formatData = {
          ...self.rankTrackerList,
          results: toJS(self.rankTrackerList).results.map(project => {
            return {
              ...project,
              ...(project.id == projectId && {trackedKeywordsCount: data.count}),
            };
          }),
        };

        if (formatData) self.rankTrackerList = cast(formatData);
      }

      setShowProcessingKwBanner(true);


      return data;
    } catch (e) {
      useErrorNotification({
        e,
        msg: 'Failed to submit tracked keywords',
        desc: 'Please try again later.'});

      return Promise.reject(e);
    } finally {
      self.loadingList = false;
      self.updatingTrackedKeywords = false;
    }
  });

  const getDetailsWithoutRepoll = flow(function* (id: number, criteria: any, isLinkgraph?: boolean) {
    self.loadingDetails = true;
    try {
      const data = yield KEYWORD_RESEARCH_API_V2.getDetailsWithoutRepoll(id, getPublicHash());
      self.details = cast(data);
      if (isRanckTrackerPublic()) criteria['currentPeriodEnd'] = moment(data?.postProcessingUpdatedAt).format('YYYY-MM-DD');
      yield Promise.all([
        getDetailsCoreReports(id, criteria),
        getKeywordsDetails(id, criteria),
        !isLinkgraph && getProjectCompetitors(id, criteria),
      ]);
      return data;
    } catch (e) {
      useErrorNotification({
        e,
        msg: 'Failed to get data for project',
        desc: 'Please try again later.'});

      return Promise.reject(e);
    } finally {
      self.loadingDetails = false;
    }
  });

  const getDetails = flow(function* (id: number, criteria: any, isSimple?: boolean, repollCount = 0) {
    if (self.isFirstDetailsRepoll) self.loadingDetails = true;
    try {
      const data = yield KEYWORD_RESEARCH_API_V2.getDetails(id, isSimple, getPublicHash());
      self.isFirstDetailsRepoll = false;
      if (data.isCancel) return;


      if (!isSimple) {
        if (self.detailsRepolling) self.detailsRepolling = false;
        self.details = cast(data);
        yield Promise.all([
          getDetailsCoreReports(id, criteria),
          getKeywordsDetails(id, criteria),
          getProjectCompetitors(id, criteria),
        ]);
      } else {
        const currentProjectData = toJS(self.details);

        if (data?.shouldRepoll !== currentProjectData?.shouldRepoll) {
          const updatedProjectData = {
            ...currentProjectData,
            postProcessingTaskStatus: data?.postProcessingTaskStatus,
            postProcessingUpdatedAt: data?.postProcessingUpdatedAt,
            serpsTaskStatus: data?.serpsTaskStatus,
            serpsUpdatedAt: data?.serpsUpdatedAt,
            targetKeywordTaskStatus: data?.targetKeywordTaskStatus,
            targetKeywordsUpdatedAt: data?.targetKeywordsUpdatedAt,
            shouldRepoll: data?.shouldRepoll,
          };

          self.details = updatedProjectData;
        }
      }

      self.loadingDetails = false;

      if ((!self.shouldStopDetailsRepolling && data.shouldRepoll) ) {
        if (!self.detailsRepolling) self.detailsRepolling = true;
        // increment repoll count
        self.repollingDetailId = id;
        const oldCount = repollCount;
        const newCount = oldCount + 1;
        yield new Promise(r => setTimeout(r, 3000));
        if (!self.shouldStopDetailsRepolling) return getDetails(id, criteria, true, newCount);
      } else {
        // if we did at least 1 repoll, after repoll ends get full list details once more.
        if (repollCount > 0) {
          self.repollingDetailId = -1;
          return getDetails(id, criteria, false, 0);
        }
        self.loadingDetails = false;

        if (self.showProcessingKeywordsBanner) setShowProcessingKwBanner(false);
      }
    } catch (e) {
      useErrorNotification({
        e,
        msg: 'Failed to get data for project',
        desc: 'Please try again later.'});

      return Promise.reject(e);
    } finally {
      self.isFirstDetailsRepoll = true;
      self.loadingDetails = false;
    }
  });


  const getDetailsCoreReports = flow(function* (id: number, criteria: any) {
    self.loadingDetailsCoreReports = true;
    try {
      const data = yield KEYWORD_RESEARCH_API_V2.getCoreReports(id, criteria, getPublicHash());
      if (data.isCancel) return;
      self.detailsCoreReports = cast(data);
    } catch (e) {
      useErrorNotification({
        e,
        msg: 'Failed to get reports',
        desc: 'Please try again later.'});
      return Promise.reject(e);
    } finally {
      self.loadingDetailsCoreReports = false;
    }
  });
  // fetches data for project details page
  const getKeywordsDetails = flow(function* (id: number, criteria: any) {
    const payLoad = {
      keyword: self.krtFiltersList?.find(item=> item?.id == 1)?.query,
      keyword_operator: self.krtFiltersList?.find(item=> item?.id == 1)?.type,
      position: self.krtFiltersList?.find(item=> item?.id == 2)?.query,
      position_operator: self.krtFiltersList?.find(item=> item?.id == 2)?.type,
      search_volume: self.krtFiltersList?.find(item=> item?.id == 3)?.query,
      search_volume_operator: self.krtFiltersList?.find(item=> item?.id == 3)?.type,
      keyword_movement: self.krtFiltersList?.find(item=> item?.id == 4)?.type,
      serp_feature: self.krtFiltersList?.find(item=> item?.id == 5)?.type,
      location: self.krtFiltersList?.find(item=> item?.id == 7)?.query,
      ...(self.krtFiltersList?.find(item=> item?.id == 6)?.active && {is_starred: self.krtFiltersList?.find(item=> item?.id == 6)?.type == 'yes'}),
    };
    const sorters = {
      keyword: 'keyword',
      currentAvgPosition: 'current_avg_position',
      sf: 'sf',
      lastDayAvgPosition: 'last_day_avg_position',
      lastWeekAvgPosition: 'last_week_avg_position',
      updatedAt: 'updated_at',
      searchVolume: 'search_volume',
      previousAvgPosition: 'previous_avg_position',
    };

    const formattedSorters = formatSorters(sorters, self.keywordsDetailsSortField, self.keywordsDetailsSortDirection);

    self.loadingKeywordsDetails = true;
    try {
      const data = yield KEYWORD_RESEARCH_API_V2.getKeywordsDetails({
        id,
        criteria,
        page: self.keywordDetailsPageNumber,
        pageSize: self.keywordDetailsPageSize,
        sortBy: formattedSorters,
        search: self.keywordDetailsSearchTerm,
        filters: payLoad,
        hash: getPublicHash(),
        sortingKey: self.trackedKeywordsSortingKey,
      });
      if (data.isCancel) return;
      const keywordsList = data.results?.map(item => item?.keyword);
      let searchIntents = {};
      if (keywordsList?.length) {
        searchIntents = yield KEYWORD_RESEARCH_API_V2.getKeywordsDetailsSearchIntents(keywordsList, getPublicHash());
      }
      const formattedSearchIntents = Object.entries(searchIntents)?.map(item => ({keyword: item[0], searchIntent: item[1]}));

      const updatedResultsDataWithSI = [];
      for (const i of data.results) {
        const keywordSearchIntent = formattedSearchIntents?.find(item => item?.keyword == i?.keyword);
        updatedResultsDataWithSI?.push({...i, searchIntent: keywordSearchIntent?.searchIntent});
      }
      const updatedData = {...data, results: updatedResultsDataWithSI};

      self.keywordsDetails = cast(updatedData);
      const positionHists = data?.results?.map(item => item.positionHist)?.flat();
      const dates = positionHists?.map(item => new Date(item.date)) || [];
      self.keywordsDetailsChartMinDate = dates.length ? moment(new Date(Math.min(...dates))).format('YYYY-MM-DD') : null;
    } catch (e) {
      useErrorNotification({
        e,
        msg: 'Failed to get keywords details',
        desc: 'Please try again later.'});
      return Promise.reject(e);
    } finally {
      self.loadingKeywordsDetails = false;
      self.loadingInitKeywordsDetails = false;
    }
  });

  // fetches data for single keyword drawer v2
  const getKeywordDetailsV2 = flow(function* (id: number, keyword: string, location = '') {
    const sorters = {
      position: 'position',
      estimatedTraffic: 'estimated_traffic',
      searchVisibility: 'search_visibility',
      searchVolume: 'search_volume',
      date: 'date',
      topUrl: 'top_url',
    };
    const formattedSorters = formatSorters(sorters, self.keywordDetailsSortField, self.keywordDetailsSortDirection);

    self.loadingKeywordDetails = true;
    try {
      const data = yield KEYWORD_RESEARCH_API_V2.getKeywordDetailsV2({id, keyword, location, sortBy: formattedSorters, hash: getPublicHash()});
      if (data.isCancel) return;

      self.keywordDetailsDrawerData = cast(data);
    } catch (e) {
      useErrorNotification({
        e,
        msg: 'Failed to get keywords details',
        desc: 'Please try again later.'});
      return Promise.reject(e);
    } finally {
      self.loadingKeywordDetails = false;
      self.loadingInitKeywordDetails = false;
    }
  });


  const getProjectCompetitors = flow(function* (id: number, criteria: any, setPageNumber?: boolean, search?: string) {
    const sorters = {
      url: 'url',
      keywordsInTop10: 'keywords_in_top_10',
      avgPosition: 'avg_position',
      previousSearchVisibility: 'previous_search_visibility',
      currentSearchVisibility: 'current_search_visibility',
    };

    const formattedSorters = formatSorters(sorters, self.projectCompetitorsSortField, self.projectCompetitorsSortDirection);

    self.loadingProjectCompetitors = true;
    try {
      const data = yield KEYWORD_RESEARCH_API_V2.getProjectCompetitors(
        {
          id,
          criteria,
          page: setPageNumber ? self.projectCompetitorsPageNumber+1 : self.projectCompetitorsPageNumber,
          pageSize: self.projectCompetitorsPageSize,
          sortBy: formattedSorters,
          hash: getPublicHash(),
          search: search,
        },
      );
      if (data.isCancel) return self.projectCompetitors;

      const colorsMap = ['#F1AA3E', '#7F4EAD', '#2D6CCA', '#617C14', '#019197', '#B61945', '#F58069', '#00D1ED', '#FF8536', '#6fc764'];

      const updateData = {
        ...data,
        results: data?.results?.map((item, index) => {
          return {
            ...item,
            color: colorsMap[index],
          };
        }) ?? [],
        sharedKeywords: data?.sharedKeywords?.map((item, index) => {
          return {
            ...item,
            color: colorsMap[index],
          };
        }) ?? [],

      };


      self.projectCompetitors = cast(updateData);
      if (setPageNumber) {
        self.projectCompetitorsPageNumber = self.projectCompetitorsPageNumber + 1;
      }
      return updateData;
    } catch (e) {
      if (e.response.status != 404) {
        useErrorNotification({
          e,
          msg: 'Failed to get keywords details',
          desc: 'Please try again later.'});
        return Promise.reject(e);
      } else {
        return {results: []};
      }
    } finally {
      self.loadingProjectCompetitors = false;
      self.loadingInitProjectCompetitors = false;
    }
  });

  const getProjectCompetitorsV2 = flow(function* (id: number, criteria: any) {
    self.competitorsLoading = true;
    try {
      const data = yield KEYWORD_RESEARCH_API_V2.getProjectCompetitorsV2({id, criteria, sortingKey: self.competitorsSortingKey}, getPublicHash());
      if (data.isCancel) return;

      return data;
    } catch (e) {
      useErrorNotification({
        e,
        msg: 'Failed to get keywords details',
        desc: 'Please try again later.'});
      return Promise.reject(e);
    } finally {
      self.competitorsLoading = false;
    }
  });

  const refreshProjectDetailReports = flow(function* (projectId: number, criteria: any) {
    self.refreshProjectLoading = true;


    try {
      const data = yield KEYWORD_RESEARCH_API_V2.refreshProjectDetails(projectId);
      if (data.isCancel) return;

      stopRepollingDetails(false);

      yield getDetails(projectId, criteria);
      yield getDetailsCoreReports(projectId, criteria);
      yield getKeywordsDetails(projectId, criteria);
    } catch (e) {
      useErrorNotification({
        e,
        msg: 'Failed to refresh data',
        desc: 'Please try again later.',
        isQuotaIssue: false,
        handleStatuses: [
          {
            statuses: [400],
            msg: 'Could not refresh SEPRs at the moment.',
            desc: e.response.data.message,
            permanent: false,
            showDetails: false,
          },
        ],
      });


      return Promise.reject(e);
    } finally {
      self.refreshProjectLoading = false;
    }
  });

  const updateKrtSortingList = (id, criteria, sortingKey) => {
    self.trackedKeywordsSortingKey = sortingKey;
    if (id) {
      getKeywordsDetails(id, criteria);
    }
  };

  const updateCompetitorsSortingList = async (id, criteria, sortingKey) => {
    self.competitorsSortingKey = sortingKey;
    if (id) {
      const res = await getProjectCompetitorsV2(id, criteria);
      return res;
    }
  };

  const updateKrtFiltersList = (filters, id, criteria) => {
    self.krtFiltersList = cast(filters);
    if (id) {
      getKeywordsDetails(id, criteria);
    }
  };

  const addTrackedKeywordsWithLocation = flow(function* (payload: any, projectId: number) {
    if (getPublicHash()) return;
    self.loadingList = true;
    self.updatingTrackedKeywords = true;

    try {
      const data = yield KEYWORD_RESEARCH_API_V2.addTrackedKeywordsV2(payload, projectId);
      if (data.isCancel) return;

      const foundProject = self.rankTrackerList?.results?.length ? toJS(self.rankTrackerList.results).find(project => project.id == projectId) : null;
      if (foundProject) {
        // updating project with added tracked keywords count
        // very important as it will allow us to avoid showing no kw view
        const formatData = {
          ...self.rankTrackerList,
          results: toJS(self.rankTrackerList).results.map(project => {
            return {
              ...project,
              ...(project.id == projectId && {trackedKeywordsCount: data.count}),
            };
          }),
        };

        if (formatData) self.rankTrackerList = cast(formatData);
      }

      setShowProcessingKwBanner(true);
      return data;
    } catch (e) {
      useErrorNotification({
        e,
        msg: 'Failed to submit tracked keywords',
        desc: 'Please try again later.'});

      return Promise.reject(e);
    } finally {
      self.loadingList = false;
      self.updatingTrackedKeywords = false;
    }
  });

  const addTrackedKeywordsWithLocationV3 = flow(function* (payload: any, projectId: number) {
    if (getPublicHash()) return;
    self.loadingList = true;
    self.updatingTrackedKeywords = true;

    try {
      const data = yield KEYWORD_RESEARCH_API_V2.addTrackedKeywordsV3(payload, projectId);
      if (data.isCancel) return;

      const foundProject = self.rankTrackerList?.results?.length ? toJS(self.rankTrackerList.results).find(project => project.id == projectId) : null;
      if (foundProject) {
        // updating project with added tracked keywords count
        // very important as it will allow us to avoid showing no kw view
        const formatData = {
          ...self.rankTrackerList,
          results: toJS(self.rankTrackerList).results.map(project => {
            return {
              ...project,
              ...(project.id == projectId && {trackedKeywordsCount: data.count}),
            };
          }),
        };

        if (formatData) self.rankTrackerList = cast(formatData);
      }

      setShowProcessingKwBanner(true);
      return data;
    } catch (e) {
      useErrorNotification({
        e,
        msg: 'Failed to submit tracked keywords',
        desc: 'Please try again later.'});

      return Promise.reject(e);
    } finally {
      self.loadingList = false;
      self.updatingTrackedKeywords = false;
    }
  });

  const addToWatchlist = flow(function* (trackedKeywordsIds: number[], removeFromWatchlist: boolean) {
    try {
      const data = yield KEYWORD_RESEARCH_API_V2.addToWatchlist(self.details?.id, trackedKeywordsIds, removeFromWatchlist);
      if (data.isCancel) return;

      return data;
    } catch (e) {
      useErrorNotification({
        e,
        msg: 'Failed to add to watchlist.',
        desc: 'Please try again later.',
      });

      return Promise.reject(e);
    }
  });

  const clearProject = () => self.project = null;
  const clearTargetKeywords = () => self.targetKeywords = null;
  const setCompetitorReportsSearchTerm = (value: string) => self.keywordDetailsSearchTerm = value;
  const setKeywordsDetailSortField = (value: string) => self.keywordsDetailsSortField = value;
  const setKeywordsDetailSortDirection = (value: string) => self.keywordsDetailsSortDirection = value;
  const setKrtSearchTerm = (value: string) => self.krtSearchTerm = value;
  const setKrtSortField = (value: string) => self.krtSortField = value;
  const setKrtSortDirection = (value: string) => self.krtSortDirection = value;
  const setKeywordDetailPageNumber = (value: number) => self.keywordDetailsPageNumber = value;
  const setKeywordDetailPageSize = (value: number) => self.keywordDetailsPageSize = value;
  const setLoadingInitKeywordsDetails = (value: boolean) => self.loadingInitKeywordsDetails = value;
  const stopRepollingList = (value: boolean) => self.shouldStopRepolling = value;
  const stopRepollingTargetKeywords = (value: boolean) => self.shouldStopTargetKeywordsRepolling = value;
  const stopRepollingDetails = (value: boolean) => self.shouldStopDetailsRepolling = value;
  const setProjectEditModalCurStep = (value: number) => self.projectEditModalCurStep = value;
  const setIsEditKeywords = (value: boolean) => self.isEditKeywords = value;
  const setIsEditSettings = (value: boolean) => self.isEditSettings = value;
  const clearDetails = () => self.details = null;
  const clearDetailsCoreReports = () => self.detailsCoreReports = null;
  const clearKeywordsDetails = () => self.keywordsDetails = null;
  const setCurrentListPage = (value: number) => self.currentListPage = value;
  const setShowDetailsDrawer = (value: boolean) => self.showDetailsDrawer = value;
  const setKeywordDetailSortField = (value: string) => self.keywordDetailsSortField = value;
  const setKeywordDetailSortDirection = (value: string) => self.keywordDetailsSortDirection = value;
  const clearKeywordDetailsDrawerData = () => self.keywordDetailsDrawerData = null;
  const setIsDrawerCloseAction = (value: boolean) => self.isDrawerCloseAction = value;
  const setLoadInitKeywordDetails = (value: boolean) => self.loadingInitKeywordDetails = value;
  const setShowProcessingKwBanner = (value: boolean) => self.showProcessingKeywordsBanner = value;
  const setProjectCompetitorsSortField = (value: string) => self.projectCompetitorsSortField = value;
  const setProjectCompetitorsSortDirection = (value: string) => self.projectCompetitorsSortDirection = value;
  const setProjectCompetitorsPageNumber = (value: number) => self.projectCompetitorsPageNumber = value;
  const setProjectCompetitorsPageSize = (value: number) => self.projectCompetitorsPageSize = value;
  const setForceShowDetailsPage = (value: boolean) => self.forceShowDetailsPage = value;
  const resetKrtFilters = () => self.krtFilters = cast(KrtFilters);
  const setAddMore = (value: boolean) => {
    self.addMore = value;
  };
  const resetRepollingDetailId = () => {
    self.repollingDetailId = -1;
    self.detailsRepolling = false;
  };

  // used to clean all table settings when leaving details page
  const detailsTablesDataCleanup = () => {
    setKeywordsDetailSortField('');
    setKeywordsDetailSortDirection('');
    setKeywordDetailPageNumber(1);
    setKeywordDetailSortField('');
    setKeywordDetailSortDirection('');
    setProjectCompetitorsSortField('');
    setProjectCompetitorsSortDirection('');
    setProjectCompetitorsPageNumber(1);
  };

  const krtListDataCleanup = () => {
    setKrtSortField('');
    setKrtSortDirection('');
    setKrtSearchTerm('');
  };

  const setProjectList = (value: any) => {
    self.projectList = value;
  };

  return {
    getList,
    getListWithoutRepoll,
    createProject,
    getTargetKeywords,
    getTrackedKeywords,
    addTrackedKeywords,
    clearProject,
    clearTargetKeywords,
    getDetails,
    updateKrtFiltersList,
    getDetailsCoreReports,
    getKeywordsDetails,
    setCompetitorReportsSearchTerm,
    setKeywordsDetailSortField,
    setKeywordsDetailSortDirection,
    setKeywordDetailPageNumber,
    setKeywordDetailPageSize,
    setLoadingInitKeywordsDetails,
    updateProject,
    stopRepollingList,
    stopRepollingDetails,
    setProjectEditModalCurStep,
    setIsEditKeywords,
    setIsEditSettings,
    clearDetails,
    clearDetailsCoreReports,
    clearKeywordsDetails,
    stopRepollingTargetKeywords,
    deleteProject,
    refreshProjectDetailReports,
    setCurrentListPage,
    setShowDetailsDrawer,
    setKeywordDetailSortField,
    setKeywordDetailSortDirection,
    clearKeywordDetailsDrawerData,
    setIsDrawerCloseAction,
    setLoadInitKeywordDetails,
    setShowProcessingKwBanner,
    getDetailsWithoutRepoll,
    getProjectCompetitors,
    setProjectCompetitorsSortField,
    setProjectCompetitorsSortDirection,
    setProjectCompetitorsPageNumber,
    setProjectCompetitorsPageSize,
    setForceShowDetailsPage,
    detailsTablesDataCleanup,
    resetRepollingDetailId,
    addTrackedKeywordsWithLocation,
    getKeywordDetailsV2,
    krtListDataCleanup,
    updateKrtFilters,
    setKrtSortField,
    setKrtSortDirection,
    setKrtSearchTerm,
    updateKrtSortingList,
    addTrackedKeywordsWithLocationV3,
    getProjectCompetitorsV2,
    addCompetitors,
    deleteCompetitor,
    addToWatchlist,
    setAddMore,
    setProjectList,
    editCompetitors,
    updateCompetitorsSortingList,
    resetKrtFilters,
  };
});

export function initKeywordRankTracker() {
  return KeywordRankTracker.create({
    loadingList: false,
    krtFiltersList: KRT_FILTER_LIST,
    creatingProject: false,
    updatingTrackedKeywords: false,
    loadingTrackedKeywords: false,
    loadingTargetKeywords: false,
    shouldStopTargetKeywordsRepolling: false,
    loadingDetails: false,
    detailsRepolling: false,
    loadingInitKeywordsDetails: true,
    loadingDetailsCoreReports: true,
    loadingKeywordsDetails: false,
    targetKeywords: null,
    project: null,
    details: null,
    keywordsDetails: null,
    keywordDetailsSearchTerm: '',
    keywordsDetailsSortField: '',
    keywordsDetailsSortDirection: '',
    keywordDetailsPageNumber: 1,
    keywordDetailsPageSize: 10,
    isFirstProjectListRepoll: false,
    projectListRepollCount: 0,
    shouldStopRepolling: false,
    isFirstDetailsRepoll: true,
    shouldStopDetailsRepolling: false,
    projectEditModalCurStep: 1,
    isEditKeywords: false,
    isEditSettings: false,
    refreshProjectLoading: false,
    currentListPage: 1,
    keywordDetailsSortField: '',
    keywordDetailsSortDirection: '',
    showDetailsDrawer: false,
    loadingKeywordDetails: false,
    loadingInitKeywordDetails: true,
    isDrawerCloseAction: false,
    showProcessingKeywordsBanner: false,
    projectCompetitors: null,
    projectCompetitorsV2: null,
    loadingProjectCompetitors: false,
    loadingInitProjectCompetitors: true,
    projectCompetitorsSortField: '',
    projectCompetitorsSortDirection: '',
    projectCompetitorsPageNumber: 1,
    projectCompetitorsPageSize: 10,
    forceShowDetailsPage: true,
    repollingDetailId: -1,
    startDate: new Date(),
    endDate: new Date(),
    krtFilters: KrtFilters,
    trackedKeywordsSortingKey: 'desktop',
    competitorsSortingKey: 'desktop',
    competitorsLoading: false,
    addingCompetitors: false,
    editingCompetitors: false,
    deletingCompetitor: false,
    addMore: true,
  });
}
