import {Col, DatePicker, Form, Row, Select} from 'antd';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {ButtonStyled} from '../../GbpOptimization/styles';
import {useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import moment from 'moment';

interface Props {
  onUpdate?: any;
  onCancel: any;
  dateValue: any;
  setDateValue: any;
  onConfirm: any;
}

export const OpeningDateModalContent = observer(({setDateValue, dateValue, onCancel, onConfirm}: Props) => {
  const [form] = Form.useForm();

  const months = [
    {value: 1, label: 'January'},
    {value: 2, label: 'February'},
    {value: 3, label: 'March'},
    {value: 4, label: 'April'},
    {value: 5, label: 'May'},
    {value: 6, label: 'June'},
    {value: 7, label: 'July'},
    {value: 8, label: 'August'},
    {value: 9, label: 'September'},
    {value: 10, label: 'October'},
    {value: 11, label: 'November'},
    {value: 12, label: 'December'},
  ];

  const getDaysInMonth = (monthNumber, year) => {
    if (monthNumber >= 1 && monthNumber <= 12) {
      const currentYear = year || moment().year();
      const startOfMonth = moment(`${currentYear}-${monthNumber}`, 'YYYY-MM').startOf('month');
      const daysInMonth = startOfMonth.daysInMonth();
      const daysArray = Array.from({length: daysInMonth}, (_, i) => i + 1);
      return daysArray.map(day => ({value: day, label: day})) || [];
    } else {
      const daysArray = Array.from({length: 30}, (_, i) => i + 1);
      return daysArray.map(day => ({value: day, label: day})) || [];
    }
  };

  useEffect(() => {
    const daysArray = getDaysInMonth(dateValue?.month, dateValue?.year);
    if (daysArray?.length && dateValue?.day) {
      if (!daysArray?.map(obj => obj?.value)?.includes(dateValue?.day)) {
        setDateValue(prev => ({...prev, day: daysArray[daysArray?.length - 1]?.value || null}));
      }
    }
  }, [dateValue?.year, dateValue?.month]);

  useEffect(() => {
    form.setFieldsValue(dateValue);
  }, [dateValue]);

  const isSelectedDateWithinOneYear = () => {
    if (!dateValue?.year || !dateValue?.month) return true;
    const today = moment();
    const oneYearFromNow = moment().add(1, 'year');
    const value = dateValue?.day === 0 || !dateValue?.day ?
      moment(new Date(dateValue?.year, dateValue?.month-1)) :
      moment(new Date(dateValue?.year, dateValue?.month-1, dateValue?.day));
    if (moment(value).isAfter(today, 'day') && !moment(value).isBetween(today, oneYearFromNow, 'day', '[]')) {
      return false;
    } else return true;
  };

  return (
    <StyledForm
      form={form}
      layout='vertical'
      initialValues={dateValue}
      requiredMark={false}
      onFinish={() => {
        if (!isSelectedDateWithinOneYear()) return;
        onConfirm();
      }}
    >
      <Description>Add the date that you opened or will open at this address.</Description>
      <Row gutter={[12, 4]} style={{marginTop: 20}}>
        <Col span={8}>
          <Form.Item label='Year' name='year' rules={[{required: true, message: 'Year is required.'}]} valuePropName='date'>
            <DatePicker
              picker='year'
              onChange={val => {
                setDateValue(prev => ({...prev, year: val?.year()}));
              }}
              suffixIcon={null}
              value={dateValue?.year ? moment()?.year(dateValue?.year) : null}
              allowClear={false}
              style={{borderRadius: 8, height: 38}}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label='Month' name='month' rules={[{required: true, message: 'Month is required.'}]}>
            <StyledSelect
              suffixIcon={<FontAwesomeIcon color='#121212' icon={faCaretDown} />}
              value={dateValue?.month}
              options={months}
              onChange={val => setDateValue(prev => ({...prev, month: val}))}
              placeholder='Select'
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label='Date (Optional)'>
            <StyledSelect
              suffixIcon={<FontAwesomeIcon color='#121212' icon={faCaretDown} />}
              value={dateValue?.day === 0 ? null : dateValue?.day}
              options={getDaysInMonth(dateValue?.month, dateValue?.year)}
              onChange={val => setDateValue(prev => ({...prev, day: val}))}
              placeholder='Select'
              allowClear={true}
            >
            </StyledSelect>
          </Form.Item>
        </Col>
      </Row>
      {!isSelectedDateWithinOneYear() && <div style={{color: '#ff4d4f', fontSize: 12, marginTop: 8}}>Please select a date within 1 year from today.</div>}
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 30}}>
        <div style={{display: 'flex', alignItems: 'center', gap: 3}}>
          <StyledButton type='primary' htmlType='submit' style={{lineHeight: '14px'}}>Save Changes</StyledButton>
          <button type='button' className='unstyledButton' style={{color: '#4E5156', paddingInline: '10px'}} onClick={() => onCancel()}>Cancel</button>
        </div>
        <button type='button' className='unstyledButton' style={{color: '#2D6CCA', paddingInline: '10px'}} onClick={() => {
          setDateValue({day: null, year: null, month: null});
        }}>Delete</button>
      </div>
    </StyledForm>
  );
});

const StyledButton = styled(ButtonStyled)`
  &:hover {
    border: none !important;
  }
`;

const Description = styled.div`
  color: #4E5156;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  margin-top: 2px;
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    height: 38px !important;
    border-radius: 8px !important;
  }
  .ant-select-selection-item, .ant-select-selection-placeholder {
    line-height: 34px !important;
  }
`;

const StyledForm = styled(Form)`
  .title {
    font-size: 24px;
    font-weight: 600;
    line-height: 29.05px;
    color: #121212;
  }
  .description {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    color: #4E5156;
    margin-top: 5px;
    margin-bottom: 20px;
  }
  .unstyledButton {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    border: none;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-form-item-label {
    padding-bottom: 0;
    label {
      color: #4E5156;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.52px;
    }
  }
`;
