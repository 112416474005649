import {Modal} from '@/components/common-components/components/modal';
import {Button} from '@/components/common-components/v2/Button';
import {Checkbox} from '@/components/common-components/v2/Checkbox';
import {Input, Select} from 'antd';
import styled from 'styled-components';

export const FlexDiv = styled.div`
  display: flex;
  gap: 5px;
  > div:first-child {
    flex-grow: 1;
  }
`;

export const ButtonModal = styled(Button)`
height: 44px;
margin-left: 0;
margin-top: 15px;
font-weight: 500;
font-size: 14px;
line-height: 17px;
text-align: center;
color: #FFFFFF;
display: flex;
align-items: center;
justify-content: center;
`;

export const Heading = styled.div`
  font-weight: 600;
font-size: 24px;
line-height: 32px;
color: #121212;
margin-bottom: 12px;
`;
export const SubHeading = styled.div`
  font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #4E5156;
`;
export const InputModal = styled(Input)`
height: 44px;
margin-top: 15px;
background: #FFFFFF;
border: 1px solid #E8E8E8;
box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.02);
border-radius: 6px;
`;

export const ModalStyled = styled(Modal)`
  .rc-dialog-close {
    color: #fff;
    right: -35px;
    top: -10px;
    font-size: 30px;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
`;

export const DynamicOption = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  > div {
    display: flex;
    align-items: center;
  }
  span {
    color: #2D6CCA;
  }
  b {
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }
`;

export const StyledSelectModal = styled(Select)`
 min-width: 125px;
 max-width: 125px;
 margin-top: 15px;
 .ant-select-selector {
    height: 44px !important;
    border: 1px solid #E8E8E8 !important;
    box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.02) !important;
    border-radius: 6px !important;
    border-color: 1px solid #E8E8E8 !important;
    cursor: pointer !important;
  }
  .ant-select-selection-item {
    padding-top: 6px !important;
  }
  .ant-select-selection-search-input {
    height: 100% !important;
  }
`;


export const StyledSelect = styled(Select)`
 width: 125px;
 height: fit-content;
 .ant-select-selector {
    max-width:180px !important;
    border-color: 1px solid #E8E8E8 !important;
    height: 32px !important;
    cursor: pointer !important;
    line-height: unset !important;
    border-radius: 0;
  }
  .ant-select-arrow {
    height: -webkit-fill-available;
  }
`;

export const StyledSelectDiv = styled.div<{isHeader?: boolean}>`
 height: fit-content;
 .ant-select-selector {
    max-width:180px !important;
    border-color: 1px solid #E8E8E8 !important;
    height: 32px !important;
    cursor: pointer !important;
    line-height: unset !important;
    margin: 0 !important;
    border: 0 !important;
    border-radius: 0 !important;
  }
  .ant-select-selection-item {
    margin-top: -5px;
  }
    .ant-select-arrow{
    color: ${p=> p.isHeader ? 'white' : ''} !important;
    }
  .ant-select-selection-search-input {
    color: ${p=> p.isHeader ? 'white' : ''} !important;
    margin-top: ${p=> p.isHeader ? '-10px' : '-5px'} !important;
  }
    .ant-select-selector{
    background-color: ${p=> p.isHeader ? '#282b2f' : ''} !important;
    .ant-select-selection-search{
    background-color: ${p=> p.isHeader ? '#282b2f' : ''} !important;
    }
    .ant-select-selection-item{
    background-color: ${p=> p.isHeader ? '#282b2f' : ''} !important;
    div{
    background-color: ${p=> p.isHeader ? '#282b2f' : ''} !important;
    color: ${p=> p.isHeader ? 'white' : ''} !important;

    }
    }
    }
`;

export const GlobeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;

export const InputContainer = styled.div`
  position: relative;
  .ant-select-dropdown {
    border-radius: 10px;
  }
  .rc-virtual-list-holder-inner {
    .ant-select-item:last-child {
      box-shadow: 0px -1px 0px #D3D3D3;
    }
  }
`;

export const InputStyled = styled(Input)`
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  height: 32px !important;
  width: 330px;
  &::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #4E5156;
  }
  @media screen and (max-width: 440px) {
    width: 270px;
  }
  @media screen and (max-width: 381px) {
    width: 240px;
  }
`;

export const ButtonStyled = styled(Button)`
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  color: #fff;
  cursor: pointer;
  font-size: 14;
  height: 32px !important;
  margin-left: 0  !important;
`;

export const CheckboxStyled = styled(Checkbox)`
color: #4E5156;
.ant-checkbox {
  .ant-checkbox-inner {
    background-color: #fff;
    border: 1px solid #E8E8E8;
    border-radius: 5px;
  }
}
.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: #7F4EAD;
  }
}

`;
export const ErrorText = styled.p`
  color: #ff4d4f;
  margin-bottom: 0;
  text-align: center;
  position: absolute;
`;
export const GrayText = styled.div<{color?: string}>`
  color: ${p=> p?.color ? p?.color : '#a3a4a4'};
`;
export const OptionContent = styled.div`
border-color: #fff !important;
max-width: 140px;
height: 100%;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
font-weight:400;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px 0px;
`;
