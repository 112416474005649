
import React, {useState} from 'react';
import {ButtonStyled, StyledSpace} from '../styles';
import {Form, Input, Button, Empty, Select, Tooltip} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight, faXmark} from '@fortawesome/pro-regular-svg-icons';
import UpdateServiceModal from './UpdateServiceModal';
import styled from 'styled-components';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';

const ServicesModal = ({
  primaryCategory,
  updateData,
  setIsModalOpen,
  setModalLoader,
  servicesList,
  modalSuggestion,
  categories,
  services,
}) => {
  const serviceData = servicesList || [];
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [form] = Form.useForm();

  const handleSubmit = async values => {
    try {
      setModalLoader('true');
      const newServices = values?.services?.map(service => ({
        type: service.type || 'custom',
        displayName: service.displayName?.trim(),
        categoryName: service?.categoryName || primaryCategory?.key,


      })) || [];
      const existingServices = serviceData?.map(service => ({
        type: service.type || 'custom',
        displayName: service.displayName?.trim(),
        price: service?.price || null,
        currency: service?.currency,
        description: service?.description,
        ...(service?.serviceTypeId ? {serviceTypeId: service?.serviceTypeId} : {categoryName: service?.categoryName || primaryCategory?.key}),
      }));
      const payload = [...existingServices, ...newServices];
      const field = [
        {
          fieldName: 'services',
          fieldType: 'FIELD',
          value: payload,
        },
      ];
      await updateData(field);
      setIsModalOpen(false);
    } catch (error) {
      return false;
    }
  };

  const openUpdateModal = service => {
    setSelectedService(service);
    setIsModalVisible(true);
  };


  const handleAddCustomService = () => {
    const services = form.getFieldValue('services') || [];
    form.setFieldsValue({
      services: [...services, {displayName: '', type: 'custom'}],
    });
  };

  const handelClose = (openPreviousModal = false) => {
    setIsModalVisible(false);
    if (openPreviousModal) setIsModalOpen(true);
  };

  const validateUniqueName = (value, index, selectedCategory) => {
    const values = form.getFieldValue('services')?.filter((_, i) => index !== i) || [];
    const services = [...(servicesList || []), ...values]?.filter(service => service?.categoryName === selectedCategory);
    if (value && services?.length >= 1 && services?.some(item => (item?.displayName?.toLowerCase() == value?.trim()?.toLowerCase()))) {
      return Promise.reject(new Error('Service names in a category should be unique.'));
    }
    return Promise.resolve();
  };

  const getOptions = categories => {
    return categories?.map(category => (
      <Select.Option key={category.key} value={category.key}>
        {category.label}
      </Select.Option>
    ));
  };

  const isNewServiceButtonDisabled = () => {
    const customServices = servicesList?.filter(service => service?.displayName && service?.categoryName) || [];
    const values = form.getFieldValue('services')?.filter(item => item.type == 'custom') || [];
    const services = [...customServices, ...values];
    return services?.length >= 100;
  };

  const getServiceName = service => {
    if (service?.serviceTypeId) {
      const value = services?.find(item => item?.key === (service?.serviceTypeId?.key || service?.serviceTypeId))?.label;
      return value;
    } else if (service?.displayName) {
      return service?.displayName;
    }
  };

  // console.log(isModal);

  return (
    <>
      <Warpper>
        <div style={{padding: '5px 28px 0'}}>
          <HeadingPrimary style={{color: '#4E5156'}}>
          Primary category: <span style={{color: '#000'}}>{primaryCategory?.label || 'None'}</span>
          </HeadingPrimary>

          {/* Display existing services */}
          <Container name='services'>
            <ScrollableContainer>

              {serviceData?.length ? serviceData?.map((service, index) => (
                <ServiceList key={service?.id} onClick={() => openUpdateModal(index)}>
                  <LeftContainer>
                    <span style={{overflow: 'hidden', fontSize: '14px', color: '#000000', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                      {getServiceName(service) || 'No Name Available'}
                    </span>
                    <p style={{fontSize: '12px', color: '#4E5156', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', margin: 0}}>
                      {service?.description || 'No description available'}
                    </p>
                  </LeftContainer>
                  <RightContainer>
                    <h4 style={{color: '#4E5156', display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', margin: 0}}>
                      {service?.price && service?.price != '0' ? `From ${service?.currency || 'USD'} ${service?.price || 'N/A'}` : 'Free'}
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        style={{
                          marginLeft: '8px',
                          width: '8px',
                          height: '14px',
                          color: '#A3A4A4',
                          cursor: 'pointer',

                        }}
                      />
                    </h4>
                  </RightContainer>
                </ServiceList>
              )) : <Empty />}
            </ScrollableContainer>
          </Container>
        </div>

        {/* Form to edit services */}
        <StyledForm form={form} onFinish={handleSubmit} layout='vertical' requiredMark={false}>
          <CustomList style={{padding: '0 28px 0'}}>
            <CustomHeading>
              Don’t see a service you offer? Create your own
            </CustomHeading>
            <ScrollableContainer style={{paddingRight: '10px', maxHeight: '200px', paddingTop: 5, marginTop: 0}}>
              <Container name='services'>
                <Form.List name='services'>
                  {(fields, {remove}) => (
                    <>
                      {fields.map(({key, name, fieldKey, ...restField}, index) => (
                        <StyledSpace
                          key={key}
                          style={{display: 'flex', flexDirection: 'row', marginBottom: '8px', alignItems: 'flex-start'}}
                        >
                          <Form.Item
                            {...restField}
                            name={[name, 'displayName']}
                            fieldKey={[fieldKey, 'displayName']}
                            rules={[
                              {required: true, message: 'Please enter service name'},
                              {validator: (_, value) => {
                                const category = form.getFieldValue('services')?.find((_, i) => i == index)?.categoryName;
                                return validateUniqueName(value, index, category);
                              }},
                            ]}
                            label='Service Name'
                            validateFirst
                          >
                            <Input placeholder='Service Name' style={{borderRadius: '8px', width: '280px', height: 38}} />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'categoryName']}
                            fieldKey={[fieldKey, 'categoryName']}
                            rules={[
                              {required: true, message: 'Please select a category'},
                              {validator: (_, value) => {
                                const values = form.getFieldValue('services')?.filter((_, i) => index !== i) || [];
                                const serviceCategories = [...(servicesList || []), ...values].map(service => service?.categoryName);
                                if (serviceCategories?.filter(category => category === value)?.length >= 100) {
                                  return Promise.reject(new Error('A category can have a maximum of 100 services.'));
                                } else {
                                  return Promise.resolve();
                                }
                              }},
                            ]}
                            initialValue={primaryCategory?.key}
                            label='Category'
                            validateFirst
                          >
                            <StyledSelect
                              suffixIcon={<FontAwesomeIcon icon={faCaretDown} color='#121212' />}
                              disabled={!categories?.length}
                              notFoundContent={(
                                <div style={{padding: '8px', textAlign: 'center'}}>No data found</div>
                              )}
                              placeholder='Select a Category'
                              filterOption={false}
                              style={{width: 280}}
                              onChange={() => {
                                const nameValue = form.getFieldValue(['services', name, 'displayName']);
                                if (nameValue) form.validateFields(['services', name, 'displayName']);
                              }}
                            >
                              {getOptions(categories)}
                            </StyledSelect>
                          </Form.Item>
                          <Button
                            type='link'
                            onClick={() => remove(name)}
                            style={{margin: 0, padding: 0, marginLeft: 8, marginTop: 30, color: '#4E5156'}}
                          >
                            <FontAwesomeIcon icon={faXmark} />
                          </Button>
                        </StyledSpace>
                      ))}
                      <Tooltip title={isNewServiceButtonDisabled() ? 'You cannot add more than 100 custom services.' : ''}>
                        <div style={{width: 'fit-content'}}>
                          <StyledButton
                            className='add-button'
                            type='link'
                            onClick={handleAddCustomService}
                            style={{marginLeft: '-14px', color: '#2D6CCA'}}
                            htmlType='button'
                            disabled={isNewServiceButtonDisabled()}
                          >
                        + Add Custom Service
                          </StyledButton>
                        </div>
                      </Tooltip>
                    </>
                  )}
                </Form.List>
              </Container>
            </ScrollableContainer>
          </CustomList>
          <div className={'footer'}>
            <ButtonStyled htmlType='submit' style={{lineHeight: '14px', color: '#fff'}}>Save Changes</ButtonStyled>
            <button type='button' className={'unstyledButton'} onClick={() => {
              setIsModalVisible(false);
              setIsModalOpen(false);
            }}>Cancel</button>
          </div>
        </StyledForm>
      </Warpper>
      {isModalVisible && <UpdateServiceModal servicesList={services} getCategoryOptions={getOptions} categories={categories} setIsModalOpen={setIsModalOpen} serviceData={serviceData} primaryCategory={primaryCategory} serviceIndex={selectedService} onClose={handelClose} updateData={updateData} modalSuggestion={modalSuggestion} />}
    </>
  );
};

export default ServicesModal;

const StyledButton = styled(Button)`
  &:disabled {
    opacity: 60% !important;
  }
`;

const StyledForm = styled(Form)`
  .ant-form-item-explain-error {
    font-size: 12px;
  }
  .ant-form-item-label {
    padding-bottom: 5px;
    label {
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      color: rgb(78, 81, 86);
    }
  }
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    height: 38px !important;
    border-radius: 8px !important;
  }
  .ant-select-selection-item, .ant-select-selection-placeholder {
    line-height: 34px !important;
  }
`;

const ScrollableContainer = styled.div`
  max-height: 380px;
  overflow-y: auto;
  padding-right: 5px;
  margin-top: 18px;


  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: lightgray;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const Warpper = styled.div`
  rc-dialog-body {
    margin-top: -11px !important;
  }
  .unstyledButton {
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    gap: 4px;
    &:disabled {
      cursor: not-allowed;
      opacity: 60%;
    }
  }
  .footer {
    padding: 18px 30px;
    display: flex;
    align-items: center;
    border-top: 1px solid #E8E8E8;
    gap: 30px;
  }
`;

const HeadingPrimary = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: left;
`;

const Container = styled(Form.Item)`
  display: flex;
  flex-direction: column !important;
  padding-bottom: 10px !important;
`;

const ServiceList = styled.div`
  display: flex;
  align-items: center;
  height: 57px;
  overflow: hidden;
  border-radius: 8px;
  gap: 16px;
  background-color: #fff;
  border: 1px solid #d8d8d8;
  justify-content: space-between;
  padding-inline: 20px;
  cursor: pointer;
  &:not(:first-child) {
    margin-top: 10px;
  }
`;

const LeftContainer = styled.div`
  margin-bottom: 5px;
  border-radius: 8px;
  flex-grow: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const RightContainer = styled.div`
  justify-content: flex-end;
  font-size: 12px;
  color: #4e5156;
`;

const CustomList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  .ant-form-item {
  margin-bottom:2px !important;
  }
`;

const CustomHeading = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  color: #4e5156;
`;

