import {BaseApi} from '@/api/base-api';
import {getApiUrl, getAuthorizationHeader} from '@/api/common-utils';

class AnalyticsApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.GSC_ENDPOINT, '/analytics/api/v1');
  private static readonly baseUrlV2: string = getApiUrl(BaseApi.GSC_ENDPOINT, '/analytics/api/v2');
  private static readonly baseUrlV1: string = getApiUrl(BaseApi.GSC_ENDPOINT, '/business/api');
  private static readonly fbAdsBaseUrl: string = getApiUrl(BaseApi.GSC_ENDPOINT, '/facebookads/api/v1');
  private static readonly googleAdsBaseUrl: string = getApiUrl(BaseApi.GSC_ENDPOINT, '/googleads/api/v1');


  /**
  * Fetch list of accounts for Google Analytics Modal
  */
  public async getAccounts() {
    try {
      const response = await this.axios.get(`${AnalyticsApi.baseUrlV2}/settings/available-accounts/`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getAccountsV2Api() {
    try {
      const response = await this.axios.get(`${AnalyticsApi.baseUrlV2}/settings/available-accounts/`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async refreshAccount(email: string) {
    try {
      const response = await this.axios.post(`${AnalyticsApi.baseUrlV2}/settings/refresh-data/`, {email: email}, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        // cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getGlobalSetting() {
    try {
      const response = await this.axios.get(`${AnalyticsApi.baseUrlV2}/settings/global-settings/`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getGMBSetting() {
    try {
      const response = await this.axios.get(`${AnalyticsApi.baseUrlV1}/accounts/`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getFBAdsSetting() {
    try {
      const response = await this.axios.get(`${AnalyticsApi.fbAdsBaseUrl}/customer-ad-accounts/`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getGoogleAdsSetting() {
    try {
      const response = await this.axios.get(`${AnalyticsApi.googleAdsBaseUrl}/customer-ad-accounts/`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }


  /**
  * Fetch saved setting by id
  * @param {number} settingId
  */

  public async getSingleSetting(settingId: number, selectedEmail: string) {
    try {
      const response = await this.axios.get(`${AnalyticsApi.baseUrl}/settings/${settingId}/`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        params: {
          'gup_email': selectedEmail,
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
  * Fetch all saved settings
  * @param {string} property
  */
  public async getAllSettings(property: string) {
    try {
      const response = await this.axios.get(`${AnalyticsApi.baseUrl}/settings/`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        params: {
          'selected_property': property,
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
  * Fetch last analytics setup
  * @param {number} accountId
  * @param {string} propertyId
  * @param {string} viewId
  * @param {string} property
  */

  public async postSettings(accountId: number, propertyId: string, property: string, selectedEmail?: string) {
    const payload = {
      'account_id': accountId,
      'property_id': propertyId,
      'selected_property': property,
    };
    if (selectedEmail) {
      payload['gup_email'] = selectedEmail;
    }
    try {
      const response = await this.axios.post(`${AnalyticsApi.baseUrl}/settings/`, payload,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
  * Activate GA for that site
  * @param {number} settingsId
  */
  public async activateGaSite(settingsId: number) {
    try {
      const response = await this.axios.patch(`${AnalyticsApi.baseUrl}/settings/${settingsId}/activate/`, {},
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
    * Deactivate GA for that site
    * @param {number} settingsId
    */
  public async deactivateGaSite(settingsId: number) {
    try {
      const response = await this.axios.patch(`${AnalyticsApi.baseUrl}/settings/${settingsId}/deactivate/`, {},
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async disconnectGMBSetting(payload) {
    try {
      const response = await this.axios.post(`${AnalyticsApi.baseUrlV1}/accounts/disconnect/`, payload, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async refreshGBPAccounts(accountId) {
    try {
      const response = await this.axios.get(`${AnalyticsApi.baseUrlV1}/accounts/refresh-accounts/?id=${accountId}`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

export default AnalyticsApi;
