
import {faXmark} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useEffect} from 'react';
import styled from 'styled-components';
import {faCircleCheck, faCircleExclamation, faCircleInfo, faCircleXmark} from '@fortawesome/pro-duotone-svg-icons';

export interface notificationBannerProps {
  active: boolean;
  status: string;
  message: string;
  count: number;
  showMore: boolean;
  type: string;
  index: number;
}

interface BannerNotificationProps {
  state: notificationBannerProps;
  setState: (value: notificationBannerProps) => void;
  delay?: number;
  hideShowMore?: boolean;
}

export const statusMapping = {
  success: {
    backgroundColor: 'rgba(42, 193, 85, 0.1)',
    color: 'rgba(42, 193, 85, 1)',
    icon: faCircleCheck,
  },
  error: {
    backgroundColor: 'rgba(244, 67, 67, 0.1)',
    color: 'rgba(244, 67, 67, 1)',
    icon: faCircleXmark,
  },
  info: {
    backgroundColor: 'rgba(45, 108, 202, 0.1)',
    color: 'rgba(45, 108, 202, 1)',
    icon: faCircleInfo,
  },
  warning: {
    backgroundColor: 'rgba(241, 170, 62, 0.1)',
    color: 'rgba(241, 170, 62, 1)',
    icon: faCircleExclamation,
  },
};


export const BannerNotification = ({state, setState, delay=5, hideShowMore=false}: BannerNotificationProps) => {
  useEffect(() => {
    if (state?.active && delay) {
      setTimeout(() => {
        setState({
          active: false,
          status: '',
          message: '',
          count: 0,
          showMore: false,
          type: '',
          index: -1,
        });
      }, (delay*1000));
    }
  }, [state?.active]);

  return (
    <>
      {
        state?.active ?
          <Banner status={state.status}>
            <div>
              <FontAwesomeIcon icon={statusMapping[state?.status]?.icon} color={statusMapping[state?.status]?.color} style={{marginRight: '5px'}} />
              {state.count}&nbsp;{state.message}
            </div>
            <div>
              {!hideShowMore && <Link onClick={()=>setState({...state, showMore: true})}>Show 1 link</Link>}
              <FontAwesomeIcon icon={faXmark} color='#f0f0f0)' style={{cursor: 'pointer'}} onClick={()=> {
                localStorage.setItem('hasUserHiddenMondayMessage', 'true');
                setState({
                  active: false,
                  status: '',
                  message: '',
                  count: 0,
                  showMore: false,
                  type: '',
                  index: -1,
                });
              }
              }/>
            </div>
          </Banner> : <></>

      }
    </>
  );
};

const Banner = styled.div<{status: string}>`
  background: ${p => (statusMapping[p.status]?.backgroundColor || 'transparent')};
  padding: 5px 15px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-items: center;
  margin-bottom: 15px;
`;

const Link = styled.span`
  color: #2D6CCA;
  cursor: pointer;
  margin-right: 20px;
`;

