import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {COLORS} from './colors';
import {CountryDropdown} from './CountryDropdown';
import {Input} from './Input';
import {Col, Form, Row, Select as AntdSelect, Tooltip} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight, faCheck} from '@fortawesome/pro-regular-svg-icons';
import {LoadingOutlined} from '@ant-design/icons';
import {faList, faSearch, faTriangleExclamation} from '@fortawesome/pro-solid-svg-icons';
import {Button} from '@/components/common-components/v2';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import {containLetterAndSpace, urlValidationRegex} from '@/constants/regex';
import {SiteBulkAuditor} from '@/components/dashboard/pages/site-audit-v2/common/SiteBulkAuditor';
import {CrawlSettingModal} from '@/components/dashboard/pages/site-audit-v2/common/CrawlSettingModal';
import {observer} from 'mobx-react-lite';
import {useStore} from '@/store/root-store';
import {Checkbox} from './Checkbox';
import style from './style.module.scss';
import {ScaNewArticleCountryDropdown} from '@/components/dashboard/pages/landing-page-optimizer/blocks/hero/location-select';
import {isSAPublicPage} from '@/utils/url';
import WriteInBulkModal from './writeInBulkModal';


const {Option} = AntdSelect;
interface Guide {
  title: string;
  value: number;
}
interface HeroSectionProps {
  title?: string;
  description?: string;
  lightInputFieldVariant?:boolean;
  additionalInputCheck?:boolean;
  focusInput?: string;
  inputFieldWidth?: number;
  inputFieldPlaceholder?: string;
  buttonTitle?: string;
  showSearchIcon?: boolean;
  quota?: boolean;
  quotaDescription?: string;
  quotaUtilization?:any;
  hideCountryDropdown?: boolean;
  additionalInput?: boolean;
  additionalInputRequired?: boolean;
  hideMainForm?: boolean;
  additionalInputPlaceholder?: string;
  utilizedQuota?: number;
  totalQuota?: number;
  showPopover?: boolean;
  modeOptions?: any[];
  selectedModeOption?: string;
  showLoader?: boolean;
  width?: string;
  inValidKeywordError?:string;
  isURL?: boolean;
  isKeyword?: boolean;
  bulkAction?: boolean;
  bulkButton?: boolean;
  isSiteExplorer?: boolean;
  isCenter?: boolean;
  showQuotaGuide?: boolean;
  isUrlWithProtocol?: boolean;
  onSubmitHandler?: (...form: any) => any;
  onKwUpdateHandler?: (e) => void;
  onChangeUrlField?: (value: string) => void;
  onCountryChange?: (value: string) => void;
  setErrorAndUpdateField?: () => void;
  alertMessage?: string;
  keywordError?: string;
  errorAndUpdateUrl?: string;
  rootFieldWidth?: number;
  showDropdown?: boolean;
  buttonBackgroundColor?: string;
  countriesToInclude?: string[];
  clearFields?: boolean;
  showWorldWide?:boolean;
  isOnPageAudit?: boolean;
  replaceInputs?: boolean;
  dropdownColor?: string;
  isSA?:boolean;
  useGoogleLocationsApi?:boolean;
  onGoogleLocationSelected?: (value: any) => void;
  boxShadow?: string;
  quotaGuideData?: Guide[];
  isAlligned?:boolean;
  isLookup?:boolean;
  lookupValue?:string;
  isContentPlaner?: boolean;
  showBulkAudit?: boolean;
  quotaHeading?: string;
  buttonWidth?: string;
  showArrowInButton?: boolean;
  showButtonInInputRow?: boolean;
  isSE?: boolean;
  paddingTop?: string;
}
interface RegistrationForm {
  keyword: string;
  url: string;
  selectedCountry: string;
  mode: string;
  URL: string;
}


export const HeroSection = observer(({...props}: HeroSectionProps) => {
  const [form] = Form.useForm<RegistrationForm>();
  const [urlFieldValue, setUrlFieldValue] = useState('');
  const [addBulkModal, setAddBulkModal] = useState(false);
  const [showCrawlSetting, setShowCrawlSetting] = useState(false);
  const {siteAuditorV2: {auditSettingModal, setAuditSettingModal}} = useStore('');
  const [openWriteInBulkModal, setOpenWriteInBulkModal] = useState(false);
  const {
    competitorResearcherV2: {setOpenSiteExplorerBulkModal},
    settings: {
      customer: {isGHLAccount, setAuthModal, profile},
    },
    plans: {showSidebarPaymentDrawer},
  } = useStore('');

  const onSubmit = async companyInfo=> {
    if (props.isOnPageAudit) {
      companyInfo.url = (!/^(https?:)?\/\//i.test(companyInfo.url)) ? 'https://' + companyInfo.url : companyInfo.url;
    }
    await props.onSubmitHandler(companyInfo);
    if (props.clearFields) {
      form.setFieldsValue({
        url: '',
        keyword: '',
        selectedCountry: profile?.address?.defaultLocation ?? 'US',
      });
      form.resetFields();
      setUrlFieldValue('');
    }
    if (props.isOnPageAudit) {
      form.setFieldsValue({
        url: '',
        keyword: '',
        selectedCountry: profile?.address?.defaultLocation ?? 'US',
      });
      form.resetFields();
      setUrlFieldValue('');
    }
  };

  useEffect(()=>{
    if (!auditSettingModal) {
      setUrlFieldValue('');
    }
  }, [auditSettingModal]);

  const antIcon = <LoadingOutlined style={{fontSize: 16, marginLeft: 10}} spin />;

  useEffect(() => {
    if (props.selectedModeOption) {
      form.setFieldsValue({mode: props.selectedModeOption});
    }
  }, [props.selectedModeOption]);

  // const pagesQuotaDiff=props.quotaUtilization?.ca?.allowedSiteAuditorProjects?.total-props.quotaUtilization?.ca?.allowedSiteAuditorProjects?.consumed;
  // const projectQuotaDiff=props.quotaUtilization?.ca?.allowedSiteAuditorPages?.total-props.quotaUtilization?.ca?.allowedSiteAuditorPages?.consumed;
  return (<MainContainer>
    <MainContainerInner style={{width: `${props.width}%` || '760PX', paddingTop: `${props.paddingTop ?? '40px'}`}} >
      {props?.isLookup && <div style={{color: '#A3A4A4'}} >{`Quota: ${props?.lookupValue}`}</div>}
      <Title >{props.title}</Title>
      <Description>{props.description}</Description>
      {!props.hideMainForm && <><StyledForm form={form} onFinish={onSubmit}
        initialValues={{
          selectedCountry: profile?.address?.defaultLocation ?? 'US',
          mode: props.selectedModeOption || '',
        }}
        isSA={props.isSA}
        isAlligned={props.isAlligned}
      >
        <Row gutter={24}>
          <Col span={24}>

            {props.replaceInputs?
              <div style={{display: 'flex'}}>
                <Form.Item
                  name={props.isURL || props.isUrlWithProtocol ? 'URL' : 'keyword'}
                  rules={[
                    {
                      required: true,
                      message: props.isURL || props.isUrlWithProtocol ? 'Please enter Page URL':props.inValidKeywordError?.length ? props.inValidKeywordError : 'Please enter Keyword',
                    }, {
                      pattern: props.isURL && urlValidationRegex,
                      message: props.isURL && 'Please enter a valid URL',
                    },
                    {
                      pattern: props.isKeyword && containLetterAndSpace,
                      message: props.isKeyword && 'Please enter a valid Keyword',
                    },
                  ]}
                >
                  <InputContainer isSE={props.isSE} >
                    <div style={{display: 'flex', justifyContent: 'space-between', paddingTop: props?.isOnPageAudit? '10px' : ''}}>
                      <InputField
                        autoFocus={props.focusInput || props?.isContentPlaner ? true : false}
                        shadowColor={props.focusInput}
                        onChange={e => {
                          setUrlFieldValue(e.target.value);
                          if (typeof props?.setErrorAndUpdateField == 'function') {
                            props?.setErrorAndUpdateField();
                          }
                          if (props.onKwUpdateHandler) {
                            props.onKwUpdateHandler(e);
                          } else if ((props.onChangeUrlField)) {
                            props.onChangeUrlField(e.target.value);
                          }
                        }}
                        value={urlFieldValue}
                        style={{width: `${props.inputFieldWidth}px` || '470px', borderRadius: '5px'}}
                        placeholder={props.inputFieldPlaceholder} variant={props.lightInputFieldVariant ? 'light' : 'dark'} />
                    </div>
                    {props?.errorAndUpdateUrl ? <ErrorDivStyled>
                      <div className='triangle'/>
                      <FontAwesomeIcon icon={faTriangleExclamation} color='#ff8536' fontSize={17} style={{marginRight: 10}} />
                      <p style={{marginBottom: '0'}}> {props?.errorAndUpdateUrl?.split('=')[0]} &nbsp; </p>
                      <span onClick={() => {
                        form.setFieldsValue({
                          URL: `${props?.errorAndUpdateUrl?.split('=')[1]}`,
                        });
                        setUrlFieldValue(`${props?.errorAndUpdateUrl?.split('=')[1]}`);
                        props?.setErrorAndUpdateField();
                      }}>
                        {props?.errorAndUpdateUrl?.split('=')[1]}
                      </span></ErrorDivStyled> : ''}
                  </InputContainer>
                </Form.Item>
                {props.keywordError && <Tooltip title={props.keywordError}><FontAwesomeIcon icon={faTriangleExclamation} color='#ff8536' fontSize={20} style={{marginBottom: 20, marginRight: 10, marginTop: props?.isOnPageAudit? '22px' : '', marginLeft: 6}} /></Tooltip>}</div> :
              <>{props.additionalInput && <Form.Item
                name='url'
                rules={
                  [
                    {
                      required: true,
                      message: 'Page url is required',
                    }, {
                      pattern: urlValidationRegex,
                      message: 'Please enter a valid Page URL',
                    },
                  ] }
              >
                <AdditionalInput shadowColor={props.focusInput} autoFocus={props.focusInput ? true : false} placeholder={props.additionalInputPlaceholder} variant='dark' />
              </Form.Item>}</>}
          </Col>
        </Row>
        <Row gutter={24}>
          <StyledCol span={24} isSE={props.isSE}>

            {props.replaceInputs ?
              <>{props.additionalInput && <Form.Item
                name='url'
                rules={
                  [
                    {
                      required: (props.additionalInputRequired == false) ? false : true,
                      message: 'Page url is required',
                    }, {
                      pattern: urlValidationRegex,
                      message: 'Please enter a valid Page URL',
                    },
                  ] }
              >
                <AdditionalInput shadowColor={props.focusInput} autoFocus={props?.focusInput && !props?.isContentPlaner && !props.isOnPageAudit ? true : false} style={{width: 346}} placeholder={props.additionalInputPlaceholder} variant={props.lightInputFieldVariant ? 'light' : 'dark'} />
              </Form.Item>}</>:

              <StyledInputFormItem
                name={props.isURL || props.isUrlWithProtocol ? 'URL' : 'keyword'}
                rules={[
                  {
                    required: true,
                    message: props.isURL || props.isUrlWithProtocol ? 'Please enter Page URL':props.inValidKeywordError?.length ? props.inValidKeywordError : 'Please enter Keyword',
                  }, {
                    pattern: props.isURL && urlValidationRegex,
                    message: props.isURL && 'Please enter a valid URL',
                  },
                  {
                    pattern: props.isKeyword && containLetterAndSpace,
                    message: props.isKeyword && 'Please enter a valid Keyword',
                  },
                ]}
              >
                <InputContainer isSE={props.isSE || props.isSA}>
                  <div style={{display: 'flex', justifyContent: 'space-between', paddingTop: props?.isOnPageAudit? '10px' : ''}}>
                    <InputField
                      autoFocus={props.focusInput ? true : false}
                      shadowColor={props.focusInput}
                      onChange={e => {
                        setUrlFieldValue(e.target.value);
                        if (typeof props?.setErrorAndUpdateField == 'function') {
                          props?.setErrorAndUpdateField();
                        }
                        if (props.onKwUpdateHandler) {
                          props.onKwUpdateHandler(e);
                        } else if ((props.onChangeUrlField)) {
                          props.onChangeUrlField(e.target.value);
                        }
                      }}
                      value={urlFieldValue}
                      style={{width: `${props.inputFieldWidth}px` || '470px'}}
                      placeholder={props.inputFieldPlaceholder} variant={props.isSA || props.lightInputFieldVariant ? 'light' : 'dark'} />
                  </div>
                  {props?.errorAndUpdateUrl ? <ErrorDivStyled>
                    <div className='triangle'/>
                    <FontAwesomeIcon icon={faTriangleExclamation} color='#ff8536' fontSize={17} style={{marginRight: 10}} />
                    <p style={{marginBottom: '0'}}> {props?.errorAndUpdateUrl?.split('=')[0]} &nbsp; </p>
                    <span onClick={() => {
                      form.setFieldsValue({
                        URL: `${props?.errorAndUpdateUrl?.split('=')[1]}`,
                      });
                      setUrlFieldValue(`${props?.errorAndUpdateUrl?.split('=')[1]}`);
                      props?.setErrorAndUpdateField();
                    }}>
                      {props?.errorAndUpdateUrl?.split('=')[1]}
                    </span></ErrorDivStyled> : ''}
                </InputContainer>
              </StyledInputFormItem>}
            {props.showDropdown &&
              <Form.Item
                name={'mode'}
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <StyledSelect
                  color={props?.dropdownColor}
                  dropdownStyle={{backgroundColor: props?.dropdownColor, color: '#fff'}}
                  suffixIcon={<FontAwesomeIcon color={props.dropdownColor ? '#121212' : '#fff'} icon={faCaretDown} />}
                  style={{minWidth: 130, width: '100%'}} onChange={props.onCountryChange}>
                  {props.modeOptions?.map((item, index) => {
                    return <Option key={index} style={{backgroundColor: props?.dropdownColor ? props?.dropdownColor : '#2D2F34'}} value={item?.value}> <OptionContent color={props?.dropdownColor}>{item?.name}</OptionContent></Option>;
                  },
                  )}
                </StyledSelect>
              </Form.Item>}
            {props.useGoogleLocationsApi ?
              <ArticleCountryDropdown className={style.scaCountryDropdownContainer}>
                <ScaNewArticleCountryDropdown
                  variant={props.lightInputFieldVariant ? 'light' : 'dark'}
                  suffixIcon={<FontAwesomeIcon color='#fff' icon={faCaretDown}/>}
                  showSearch
                  className={style.scaLocationDropdown}
                  onChange={(value => {
                    if (typeof props.onGoogleLocationSelected == 'function') {
                      props.onGoogleLocationSelected(value);
                    }
                  })}/>
              </ArticleCountryDropdown> :
              !props.hideCountryDropdown && <Form.Item name={'selectedCountry'}>
                <CountryDropdown showSearch aligned={true} isSE variant={props.lightInputFieldVariant?'light':'dark'} showWorldWide={props.showWorldWide} countriesToInclude={props.countriesToInclude} customIcon/>
              </Form.Item>}
            {props.bulkButton && <Button disabled={props.showLoader} onClick={() => setShowCrawlSetting(true)} color='textGrayDark' mode='dark' variant='ghost'>Crawl Setting</Button>}
            <div>
              {props?.showButtonInInputRow ? <StyledButtonFormItem
                isSE={props.isSE}
                isAlligned={props.isAlligned}
              >
                <StyledButton
                  buttonBackgroundColor={props.buttonBackgroundColor}
                  htmlType='submit'
                  height={'44px'}
                  disabled={props.showLoader || props.keywordError?.length > 0}
                  style={{margin: (props.isSA || props.showButtonInInputRow) && 0, width: props?.buttonWidth ? props?.buttonWidth : props.isSA && 138, fontWeight: props.isSA && 500}}
                >
                  {props.isSA || props?.showSearchIcon &&<FontAwesomeIcon icon={faSearch} color='#fff' fontSize={17} style={{marginRight: 10, height: props?.showSearchIcon ? '12px' : ''}} />}   {props.buttonTitle}
                  {props?.showArrowInButton && <FontAwesomeIcon icon={faArrowRight} color='#fff' fontSize={16} style={{marginLeft: 10, height: props?.showSearchIcon ? '12px' : ''}} />}
                  {props.showLoader && antIcon}
                </StyledButton>
              </StyledButtonFormItem> : ''}
            </div>
          </StyledCol>
        </Row>
        {props.additionalInputCheck && <Row gutter={24}>
          <Col span={24}>
            <Form.Item name='renderWithBrowser' valuePropName='checked'>
              <CheckboxStyled>Render page content with Browser</CheckboxStyled>
            </Form.Item>
          </Col>
        </Row>}

        <StyledRow>
          {!props?.showButtonInInputRow ? <StyledButtonFormItem
            isAlligned={props.isAlligned}
          >
            <StyledButton
              buttonBackgroundColor={props.buttonBackgroundColor}
              htmlType='submit'
              height={'44px'}
              disabled={props.showLoader || props.keywordError?.length > 0}
              style={{margin: props.isSA && 0, width: props?.buttonWidth ? props?.buttonWidth : props.isSA && 138, fontWeight: props.isSA && 500}}
            >
              {props.isSA || props?.showSearchIcon &&<FontAwesomeIcon icon={faSearch} color='#fff' fontSize={17} style={{marginRight: 10, height: props?.showSearchIcon ? '12px' : ''}} />}   {props.buttonTitle}
              {props?.showArrowInButton && <FontAwesomeIcon icon={faArrowRight} color='#fff' fontSize={16} style={{marginLeft: 10, height: props?.showSearchIcon ? '12px' : ''}} />}
              {props.showLoader && antIcon}
            </StyledButton>
          </StyledButtonFormItem> : ''}
          {props.showPopover && <StyledPopover ><div><FontAwesomeIcon icon={faCheck} style={{color: '#2AC155'}} />{props.alertMessage ?? 'Content plan created'}</div></StyledPopover>}
          {props.showBulkAudit && !isGHLAccount && <div style={{display: 'flex', flexDirection: 'row', marginLeft: 'auto'}}>
            {profile.planName == 'PRO' ?
              <AuditInBulk onClick={() => setOpenWriteInBulkModal(true)}>
                <FontAwesomeIcon icon={faList} style={{color: '#34AEF3', marginRight: 7, marginTop: 2}} />
                <StyledSpan>Audit Pages in Bulk</StyledSpan>
              </AuditInBulk>:
              <Tooltip title='Please upgrade to the Pro Plan to use this feature'>
                <AuditInBulk>
                  <FontAwesomeIcon icon={faList} style={{color: '#34AEF3', marginRight: 7, marginTop: 2}} />
                  <StyledSpan>Audit Pages in Bulk</StyledSpan>
                </AuditInBulk>
              </Tooltip>}
          </div>
          }
        </StyledRow>


      </StyledForm>
      <StyledBulkActionRow>
        <div>
          {props.bulkAction && !isGHLAccount && <div style={{display: 'flex', flexDirection: 'row'}}>
            <div>
              {profile.planName == 'PRO' ? <div>
                <FontAwesomeIcon icon={faList} style={{color: '#34AEF3', marginRight: 7, marginTop: 4}} />
                <AddBulkBtn onClick={() => {
                  if (isSAPublicPage()) {
                    setAuthModal(true, false);
                  } else {
                    if (props?.isSiteExplorer) {
                      setOpenSiteExplorerBulkModal(true);
                    } else {
                      setAddBulkModal(true);
                    }
                  }
                }}>
                  Add Sites in Bulk
                </AddBulkBtn></div> :
                <Tooltip title='Please upgrade to the Pro Plan to use this feature'>
                  <FontAwesomeIcon icon={faList} style={{color: '#34AEF3', marginRight: 7, marginTop: 4}} />
                  <AddBulkBtn onClick={() => showSidebarPaymentDrawer()}>
                    Add Sites in Bulk
                  </AddBulkBtn>
                </Tooltip>}
            </div>
            <ProFeature>
              <div style={{color: '#34AEF3'}}>Pro feature</div>
            </ProFeature>
          </div>
          }
        </div>
      </StyledBulkActionRow></>}

      <SiteBulkAuditor
        visible={addBulkModal}
        onClose={() => setAddBulkModal(false)}
      />
      <CrawlSettingModal
        url={auditSettingModal && urlFieldValue}
        title={auditSettingModal &&'Audit Site'}
        buttonTitle={auditSettingModal &&'Start Audit'}
        visible={showCrawlSetting || auditSettingModal}
        onClose={() => {
          setShowCrawlSetting(false);
          setAuditSettingModal(false);
          form.resetFields();
          setUrlFieldValue('');
        }
        }
      />
      <WriteInBulkModal
        openWriteInBulkModal={openWriteInBulkModal}
        setOpenWriteInBulkModal={setOpenWriteInBulkModal}
        quota={{isQuota: props.quota, isSA: props.isSA, consumedData: props.utilizedQuota, totalData: props.totalQuota,
          quotaDescription: props?.quotaDescription, isCenter: props.isCenter, showGuide: props.showQuotaGuide, guideData: props.quotaGuideData,
          scaPublicConsumed: props.quotaUtilization?.ca?.allowedSiteAuditorPages?.consumed,
          scaPublicTotal: props.quotaUtilization?.ca?.allowedSiteAuditorPages?.total}}
      />
    </MainContainerInner>
  </MainContainer>
  );
});
const MainContainer = styled.div`
  .ant-form-item {
   margin-bottom: 0 !important; 
  }
`;

const StyledForm = styled(Form)<{isSA: any; isAlligned: any}>`
  display: ${props => (props.isSA || props.isAlligned) ? 'flex' : ''};
  align-items: ${props => props.isAlligned ? 'baseline' : ''};
  flex-wrap: wrap;
  @media only screen and (max-width: 558px) {
    display: block;
  }
`;

const StyledBulkActionRow = styled(Row)`
  margin-top: 10px;
`;

const StyledButton = styled(Button) <{ buttonBackgroundColor: string;height:string}>`
  margin: 10px 12px 10px 0;
  background-color: ${p => p.buttonBackgroundColor ? p.buttonBackgroundColor : '#7F4EAD'} !important;
  border:1px solid ${p => p.buttonBackgroundColor ? p.buttonBackgroundColor : '#7F4EAD'} !important;
  &:hover,&:active,&:focus{
    background-color: ${p => p.buttonBackgroundColor ? p.buttonBackgroundColor : '#7F4EAD'} !important;
    border:1px solid ${p => p.buttonBackgroundColor ? p.buttonBackgroundColor : '#7F4EAD'} !important; 
  }
  height:${p => p.height ? p.height : '#7F4EAD'} !important;
  @media only screen and (max-width: 558px) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; 
    padding: 0;
   }
`;


const ArticleCountryDropdown = styled.div`
.ant-select-arrow{
  right: 10px !important;
}
  .ant-select-arrow > svg{
    color: #0f0c0c !important;
  }
`;

const StyledPopover = styled.div`
  background: #2d2f34;
  color: #fff;
  height: 40px;
  padding: 10px;
  border-radius: 5px;
  transition: opacity 2s ease-out;
  .ant-popover-arrow {
      display: none !important;
  }
`;

const MainContainerInner = styled.div`
  max-width: 890px;
  margin: 0px auto 50px;
  padding-top: 40px;
  @media only screen and (max-width: 558px) {
    .ant-form-item-control-input-content {
      flex: unset !important;
    };
    .ant-form-item-control-input{
      display: block;
    }
    .ant-row {
      display: block;
    }
  };
  @media screen and (max-width: 768px) {
    padding-top: 24px;
  }
`;

const Title = styled.div`
  font-size:28px;
  font-weight:700;
  color:${COLORS.white};
  margin-bottom:15px;
  @media screen and (max-width: 768px) {
    font-size: 22px;
    line-height: 28px;
  }
`;
const Description = styled.div`
  font-size:14px;
  font-weight:400;
  color: ${COLORS.textGrayLight};
  margin-bottom:20px;
  max-width:540px;
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

const InputContainer = styled.div<{isSE?: boolean}>`
  display:flex;
  position: relative;
  ${p => p.isSE ? `
    @media only screen and (max-width: 558px) {
    margin-bottom: 10px;
    display: block;
  }
  `:
    'margin-bottom: 10px;'
}
`;

const AddBulkBtn = styled.span`
  color: #34AEF3;
  font-weight: 400;
  font-size: 14px;
  margin: 0 0 3px 5px;
  cursor: pointer;
`;

const ErrorDivStyled = styled.div`
  width: max-content;
  height: 32px;
  background: #FFFFFF;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  position: absolute;
  top: 45px;
  z-index: 99;
  left: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 10px;
  span {
    color: #2D6CCA;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .triangle {
    width: 20px;
    position: absolute;
    height: 8px;
    border-bottom: solid 11px rgb(255 255 255);
    border-left: solid 11px transparent;
    border-right: solid 11px transparent;
    top: -10px;
    left: 20px;
  }
`;

const AdditionalInput = styled(Input)<{shadowColor?: string}>`
  width:520px;
  height:44px;
  border-radius: 6px;
  &:focus {
    box-shadow: ${p => p?.shadowColor ? `0 0 0 2px ${p.shadowColor}` : 'rgb(24 144 255 / 20%)'} !important;
  }
`;

const InputField = styled(Input)<{shadowColor?: string}>`
  height:44px;
  border-radius: 6px;
  &:focus {
    box-shadow: 0 0 0 2px ${p => p?.shadowColor ? p.shadowColor : 'rgb(24 144 255 / 20%)'} !important;
  }
  @media only screen and (max-width: 558px) {
    width: 100% !important;
    margin-right: 0px;
  };
`;

const StyledSelect = styled(AntdSelect)<{color?: string}>`
  color:#fff;
  .ant-select-selector {
    max-width:128px !important;
    min-width:128px !important;
    border-radius: 5px !important;
    background-color:${p=> p?.color ? p?.color : '#2D2F34'} !important;
    border-color: #2D2F34 !important;
    padding: 6px 15px !important;
    height: 44px !important;
    cursor: pointer !important;
    line-height: unset !important;
    margin-top: 0px !important;
    margin-left: ${p=> p?.color ? '0px' : '5px'};
  }
  @media only screen and (max-width: 558px) {
    .ant-select-selector {
      max-width: 100% !important;
      width: 100% !important;
      margin-left: 0px;
    }
  };
  .ant-select-selection-search {
    padding: 6px 0;
  }

 .ant-select-arrow {
  color:#fff;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    background-color:#2D2F34 !important;
    border-color: #2D2F34 !important;
    color:#fff;
  }
`;

const OptionContent = styled.div<{color?: string}>`
  background-color:${p=> p?.color ? p?.color : '#2D2F34'} !important;
  border-color: #fff !important;
  max-width: 140px;
  height: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color:${p=> p?.color ? '#121212' : '#fff'};
  font-weight:400;

`;

const CheckboxStyled = styled(Checkbox)`
color: #a3a4a4;

.ant-checkbox {
  .ant-checkbox-inner {
    background-color: #3e4144 !important;
    border: 1px solid transparent;
    border-radius: 5px;
  }

  &:hover {
    .ant-checkbox-inner {
      border: 1px solid transparent;
    }
  }
}

`;

const ProFeature = styled.div`
  color: #34AEF3;
  background:  #34AEF333;
  text-align: center;
  border-radius: 8px;
  width: 83px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 8px;
  cursor: pointer;
`;

const StyledSpan = styled.span`
  color: var(--main-variations-blue-light, var(--Blue-light, #34AEF3));
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 5px;
`;
const StyledRow = styled(Row)`
  align-items: center;
`;
const AuditInBulk = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  cursor: pointer;
`;

const StyledButtonFormItem = styled(Form.Item)<{isAlligned: any; isSE?: boolean}>`
  margin-left: ${props => props?.isSE ? '0' : props.isAlligned ? '12px': ''};
  @media only screen and (max-width: 597px) {
    margin-left: 0px;
  }
`;

const StyledCol =styled(Col)<{isSE?: boolean}>`
  display: flex;
  ${p => p.isSE ? `
    flex-wrap: wrap;
    gap: 8px;
  ` : ''}
  @media only screen and (max-width: 558px) {
    display: block;
  }
`;

const StyledInputFormItem = styled(Form.Item)`
  

`;
