import React from 'react';
import {GscEmailUpdatesV2} from './sections/gscEmailSettings';
import {OttoEmailUpdatesUpdates} from './sections/ottoEmailSettings';

export const EmailUpdatesV2 = () => {
  return (
    <div className='email-updates-wrapper'>
      <>
        <GscEmailUpdatesV2/>
      </>
      <>
        <OttoEmailUpdatesUpdates/>
      </>
    </div>
  );
};
