import {BaseApi} from '@/api/base-api';
import {getAuthHeader, getAuthorizationHeader} from '@/api/common-utils';
import snakeCase from 'lodash/snakeCase';
import {AxiosResponse} from 'axios';

import type {CreateProps, UpdateProps, Watchlist, UpdatePropsV2} from './watchlist.types';

/**
 * Creates a new Watchlist API client.
 *
 * @class
 */
export class WatchlistApi extends BaseApi {
  /**
   * Returns the Watchlist array associated with user.
   *
   * @method get
   */
  public async get({siteProperty, countryCode}) {
    try {
      const {data}: AxiosResponse<Watchlist[]> = await this.axios({
        method: 'GET',
        url: '/watchlists/',
        params: {
          site_property: siteProperty,
          country_code: countryCode,
        },
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
   * Creates watchlist with the given parameters.
   *
   * @method create
   */
  public async create({siteProperty, ...rest}: CreateProps) {
    try {
      const parsedRequest = Object.keys(rest).reduce((acc, curr) => {
        const snakeCaseKey = snakeCase(curr);
        acc[snakeCaseKey] = rest[curr];

        return acc;
      }, {});

      const {data}: AxiosResponse<Watchlist> = await this.axios({
        method: 'POST',
        url: `/watchlists/`,
        params: {
          site_property: siteProperty,
        },
        data: {
          site_property: siteProperty,
          ...parsedRequest,
        },
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async updatev2({...rest}: UpdatePropsV2) {
    try {
      const parsedRequest = Object.keys(rest).reduce((acc, curr) => {
        const snakeCaseKey = snakeCase(curr);
        acc[snakeCaseKey] = rest[curr];

        return acc;
      }, {});

      const {data}: AxiosResponse<Watchlist> = await this.axios({
        method: 'PATCH',
        url: `/watchlists/update-watchlist/`,
        data: {
          ...parsedRequest,
          ...(!rest?.countryCode && {'country_code': ''}),
        },
        params: {
          site_property: rest.siteProperty,
        },
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
   * Updates existing watchlist
   *
   * @method update
   */
  public async update({id, ...rest}: UpdateProps) {
    try {
      const parsedRequest = Object.keys(rest).reduce((acc, curr) => {
        const snakeCaseKey = snakeCase(curr);
        acc[snakeCaseKey] = rest[curr];

        return acc;
      }, {});

      const {data}: AxiosResponse<Watchlist> = await this.axios({
        method: 'PATCH',
        url: `watchlists/${id}/`,
        data: {
          ...parsedRequest,
        },
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
   * Deletes the watchlist
   *
   * @param {number} id Watchlist id
   * @method delete
   */
  public async delete(id: number) {
    try {
      const {data}: AxiosResponse<Watchlist> = await this.axios({
        method: 'DELETE',
        url: `watchlists/${id}/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
