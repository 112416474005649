import {ValidateFunctions} from '.';
import {notification} from '@/utils/notifications';

export const getStatusText = (isApproved, currentProject) => {
  if ((currentProject?.pixelTagState == 'installed' || currentProject?.pixelTagState == 'wp_plugin') && currentProject?.isEngaged) return isApproved ? 'Deployed' : 'Deploy';
  return isApproved ? 'Enabled' : 'Enable';
};

export const getDeployStatus = key => {
  switch (key) {
    case 'deployed':
      return true;
    default:
      return false;
  }
};

export const canDeploy = (record, issueType) => {
  const validate = ValidateFunctions[issueType];
  return typeof validate === 'function' ? validate(record) : true;
};

export const copyLink = (str: string) => {
  navigator.clipboard.writeText(str).then(function() {
    notification.success('URL copied to clipboard');
  }, function(err) {
    notification.error('Error', err);
  });
};

export const disableCheck = (record, urlId) => {
  if (record?.issueType === 'nlp_terms' && urlId === `${record?.id}nlp_terms`) {
    return '20%';
  } else if (record?.issueType === 'nlp_faq' && urlId === `${record?.id}nlp_faq`) {
    return '20%';
  } else {
    return '';
  }
};

export const getGeneratedTitle = (issueType: string, length: number) => {
  switch (issueType) {
    case 'page_title':
      return `Title${length > 1 ? 's' : ''}`;
    case 'meta_description':
      return `Description${length > 1 ? 's' : ''}`;
    case 'images':
      return `Image${length > 1 ? 's' : ''}`;
    case 'meta_keywords':
      return `Keyword${length > 1 ? 's' : ''}`;
    case 'missing_headings':
      return `Heading${length > 1 ? 's' : ''}`;
    case 'h1_under_20_over_70':
      return `H1${length > 1 ? `'s` : ''}`;
    case 'h2_under_20_over_70':
      return `H2${length > 1 ? `'s` : ''}`;
    case 'og_description':
      return `OG Description${length > 1 ? 's' : ''}`;
    case 'og_title':
      return `OG Title${length > 1 ? 's' : ''}`;
    case 'og_url':
      return `OG URL${length > 1 ? `'s` : ''}`;
    case 'twitter_description':
      return `Twitter Description${length > 1 ? 's' : ''}`;
    case 'twitter_title':
      return `Twitter Title${length > 1 ? 's' : ''}`;
    case 'twitter_site_property':
      return `Twitter Site${length > 1 ? 's' : ''}`;
    case 'twitter_card':
      return `Twitter Card${length > 1 ? 's' : ''}`;
    case 'nlp_terms':
      return `NLP Term${length > 1 ? 's' : ''}`;
    case 'nlp_faq':
      return `NLP FAQ${length > 1 ? `'s` : ''}`;
    case 'organization_schema':
      return `Schema${length > 1 ? `'s` : ''}`;
    case 'internal_link_suggestions':
      return `Linking Suggestion${length > 1 ? 's' : ''}`;
    case 'missing_keywords':
      return `Missing Keyword${length > 1 ? 's' : ''}`;
    default:
      return;
  }
};
