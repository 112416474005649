import {observer} from 'mobx-react';
import React from 'react';
import {numberWithCommas} from '@/utils/number';
import {faSquareQuestion, faCoins} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Tooltip, Divider} from 'antd';
import styled from 'styled-components';
import {useStore} from '@/store/root-store';

interface Guide {
  title: string;
  value: number | string;
}


interface Props {
  consumedData: number;
  totalData: number;
  heading?: string;
  description?: string;
  isCenter?: boolean;
  showGuide?: boolean;
  guideData?: Guide[];
  isConsemed?:string;
  isRemaining?:string;
  isTotal?:string;
  isNoProgressBar?:boolean;
  isKrPublic?:boolean;
  showTopupButton?:boolean;
  showPurchasedQuota?: number | boolean;
  titlePlacementStyle?: boolean;
  showTopupOnCompleteConsumption?: boolean;
  isHeader?: any;
  component?: any;
}

export const AiQuotaInfoDetailToolTip: React.FC<Props> = observer(({
  consumedData,
  totalData,
  description,
  showGuide,
  isConsemed,
  isRemaining,
  isTotal,
  guideData,
  showTopupButton,
  isHeader=false,
  component=null,
}) => {
  const difference = totalData - consumedData;
  const {
    contentOptimizer: {
      aiOutline: {
        setTopUpBulkVisibility,
        setShowDesc,
      },
    },
    settings: {customer: {profile: {isVendastaClient}}},
  } = useStore('');

  return <Tooltip
    trigger={'hover'}
    overlayInnerStyle={{
      width: showGuide ? 200 : 157,
      padding: '10px',
      background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), #2D2F34',
      boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.08)',
      borderRadius: 8,
    }}
    placement='bottom'
    title={<>
      <TooltipTitle showGuide={showGuide}>{description}</TooltipTitle>
      {!showGuide ? <Divider style={{background: '#4E5156', margin: '10px 0px'}}/> : null}
      <div style={{display: 'flex', justifyContent: 'space-between', color: '#fff', fontSize: 12}}>
        <> <span>{isConsemed ?? 'Used'}:</span><span>{numberWithCommas(consumedData)}</span></>
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between', color: '#fff', fontSize: 12}}>
        <> <span>{isRemaining ?? 'Remaining'}:</span><span style={{color: difference > 0 ? '#15ac47' : '#f44343'}}>{difference > 0 ? numberWithCommas(difference) : 0}</span></>
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between', color: '#fff', fontSize: 12}}>
        <> <span> {isTotal ?? 'Total'}:</span><span>{totalData ? numberWithCommas(totalData) : 100}</span></>
      </div>
      {showTopupButton && <TopupButton disabled={isVendastaClient} style={{cursor: consumedData == 0 || isVendastaClient ? 'not-allowed' : ''}} onClick={() => {
        if (consumedData !== 0) {
          setShowDesc(false);
          setTopUpBulkVisibility(true);
        }
      }}>
        <FontAwesomeIcon icon={faCoins} color='#2AC155' />
        <TopupText>Top up</TopupText>
      </TopupButton>}
      {showGuide ? <>
        <Divider style={{background: '#4E5156', margin: '10px 0px'}} />
        <TooltipTitle showGuide={showGuide}>Quota consumption guide</TooltipTitle>
        {guideData.map((item: Guide, key: number) => (
          <div key={key} style={{display: 'flex', justifyContent: 'space-between', color: '#fff', fontSize: 12}}>
            <> <span>{`${item.title}:`}</span><span>{numberWithCommas(item.value as number)}</span></>
          </div>
        ))}
      </> : null
      }

    </>} >
    {isHeader ? component : <FontAwesomeIcon icon={faSquareQuestion} color='#A3A4A4' fontSize={14} style={{marginLeft: 6, marginTop: 4, cursor: 'pointer'}} />}
  </Tooltip>;
});

const TooltipTitle = styled.div<{showGuide?: boolean; calledFrom?:string}>`
font-family: 'Inter';
font-style: normal;
font-weight: ${p => p.showGuide ? 600 : 400};
font-size: ${p => p.showGuide ? 13 : '12px'};
line-height: ${p => p.showGuide ? 15.73 : 14}px;
color: '#FFFFFF';
margin-bottom: ${p => p.showGuide ? 10 : 0}px;
`;

const TopupButton = styled.button`
  background-color: rgba(42, 193, 85, 0.15);
  width: 96px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 5px;
  border: none;
  margin-top: 12px;
  cursor: pointer;
`;

const TopupText = styled.span`
margin-left: 8px;
color: var(--White, #FFF);
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 22px;
`;
