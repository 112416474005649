import {Modal, ModalProps, Progress} from 'antd';
import React, {useState} from 'react';
import styled from 'styled-components';
import styles from './styles.module.scss';
import {Checkbox} from '../Checkbox';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlay} from '@fortawesome/pro-duotone-svg-icons';
import moment from 'moment';

interface videoUrlsProps {
  url: string;
  title?: string;
  duration?: number;
}

interface Props extends ModalProps {
  heading: string;
  videoUrls: videoUrlsProps[];
  color?: string;
}

export const VideoPlayerModal = ({heading, videoUrls, ...restProps}: Props) => {
  const [currentVideo, setCurrentVideo] = useState(0);
  const isPlayList = videoUrls?.length > 1;
  const totalDuration = videoUrls?.reduce((acc, b)=> acc + b.duration, 0);
  const [videosWatched, setVideosWatched] = useState({0: true});

  return (
    <ModalWrapper centered width={isPlayList ? 1473 : 1100} footer={false} {...restProps}>
      <div>
        <img style={{width: 41}} src='/img/SA-Favicon Black Circle.png'/>
      </div>
      <h1 className={styles.heading}>
        {heading}
      </h1>
      <p className={styles.subHeading}>
          Join thousands of SEO experts who&apos;ve mastered this powerful tool with our quick guide
      </p>
      <div className={styles.videoSection}>
        <Iframe src={videoUrls[currentVideo].url} height='480' allow='autoplay; fullscreen' allowFullScreen />
        {isPlayList && <VideoPlayer >
          <div className='playlistHeader'>
            <Progress type='circle' strokeWidth={10} strokeColor={'#2AC155'} percent={(Object.keys(videosWatched)?.length / videoUrls.length) * 100} />
            <div className='playlistTitle'>
              <h1>Learn all about Content Genius</h1>
              <p>{currentVideo + 1} / {videoUrls?.length}  •  {moment.duration(totalDuration, 'seconds').minutes()} min</p>
            </div>
          </div>
          <VideosList>
            {videoUrls.map((video, index)=>{
              return (
                <div style={{background: index === currentVideo && '#2AC15580'}} className='video' onClick={()=> {
                  const watchedVideos = videosWatched;
                  videosWatched[index] = true;
                  setVideosWatched(watchedVideos);
                  setCurrentVideo(index);
                }} key={index}>
                  <div style={{display: 'flex', alignItems: 'center', gap: 12}}>
                    {videosWatched[index] ?<Checkbox checked customBackground='#2AC155' /> :
                      <div className='videoPlayIcon'>
                        <FontAwesomeIcon color='#fff' style={{width: 6}} icon={faPlay} />
                      </div>
                    }
                    <p className='videoTitle'>{video?.title}</p>
                  </div>
                  <p className='videoDuration'>{moment.duration(video.duration, 'seconds').minutes()}min</p>
                </div>
              );
            } )}
          </VideosList>

        </VideoPlayer>}
      </div>
    </ModalWrapper>
  );
};


export const ModalWrapper = styled(Modal)`
  .ant-modal-close-x {
    position: absolute;
    top: -19px;
    left: 0px;
    right: -70px;
    color: white;
  }

  .ant-modal-body {
    padding: 36px;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    }
    
    .ant-modal-content {
      border-radius: 16px;
    background: linear-gradient(180deg, #3C236A -0.96%, #2E2047 23.61%, #2E2047 39.55%, #4C2A89 65.5%, #3E256C 74.8%, #17181B 149.03%);
    min-height: 75vh;
  }
`;

const Iframe = styled.iframe<{isPlaylist?: boolean}>`
   border: none;
   width: ${e=> e?.isPlaylist ? '70%' : '100%'};
   border-radius: 8px;
`;

const VideoPlayer = styled.div`
  border-radius: 8px;
  height: fit-content;
  width: 35%;


  .playlistHeader {
    display: flex;
    align-items: center;
    gap: 20px;

    .ant-progress-inner {
      width: 60px !important;
      height: 60px !important;
      font-size: 13px !important;
      

      .ant-progress-text {
        color: #2AC155;
      }
    }

    .playlistTitle {
     h1 {
      font-size: 18px;
      font-weight: 500;
      line-height: 20.61px;
      color: #fff;
     }

     p {
      font-size: 14px;
      line-height: 16.03px;
      color: #A3A4A4
     }
    }
  }
  
`;


const VideosList = styled.div`
  margin-top: 20px;
   .video {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      padding: 8px 17px;
      border-radius: 8px;

      .videoPlayIcon {
        width: 17.41px;
        height: 17.41px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #B0B3B8;
        border-radius: 50%;

      }

      .videoTitle {
        font-size: 14px;
        font-weight: 500;
        line-height: 16.03px;
        color: #fff;
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        max-width: 200px;
        text-overflow: ellipsis;
      }


      .videoDuration {
        font-size: 10.35px;
        font-weight: 500;
        line-height: 35.47px;
        color: #A3A4A4;
        margin: 0;

      }
   }
`;
