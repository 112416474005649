import React, {ReactNode} from 'react';
import {useRouter} from 'next/router';
import {observer} from 'mobx-react-lite';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styles from './style.module.scss';

import {faArrowRight, faBriefcaseArrowRight, faBullhorn, faEllipsis, faFileChartColumn, faFolderCheck, faKeySkeleton, faLifeRing, faLink, faListAlt, faLocationDot, faMagnifyingGlassWaveform, faMapLocationDot, faMicrochipAi, faPen, faThumbsUp} from '@fortawesome/pro-regular-svg-icons';
import {Dropdown, Menu, Tooltip} from 'antd';
import styled from 'styled-components';
import {useStore} from '@/store/root-store';
import {
  faChartSimpleHorizontal as faDuotoneChartSimpleHorizontal, faBallPile,
} from '@fortawesome/pro-duotone-svg-icons';
import Link from 'next/link';
import {routes} from '@/utils/const';
import ButtonComponent from '@/components/common-components/components/button-component';
import {MixPanel} from '@/utils/mixpanel';
import {CP_OPENED_FROM_KE} from '@/constants/events';
import {CaretDownOutlined, LoadingOutlined} from '@ant-design/icons';
import {faBug, faCalendar, faCircleDollar, faCircleQuestion, faClapperboardPlay, faSquareStar} from '@fortawesome/pro-light-svg-icons';
import useWindowSize from '@/utils/hooks/useWindowSize';
import {openLiveChat} from '@/utils/intercom';

type ChildPagesProps = {
  commingSoon?: boolean;
  disabledMsg?: string;
  color?: string;
  key: string;
  title: string;
  disabled?: boolean;
  hidden?: boolean;
  icon?: ReactNode;
  external?: boolean;
  newTab?: boolean;
  url?: string;
  path?: string;
  isAlpha?:boolean;
  isBeta?: boolean;
  isNew?: boolean;
  unreadEmails?: boolean;
  size?: string;
};

type SideBarProps = {
  commingSoon?: boolean;
  disabledMsg?: string;
  color?: string;
  key: string;
  title: string;
  disabled?: boolean;
  hidden?: boolean;
  icon?: ReactNode;
  external?: boolean;
  newTab?: boolean;
  url?: string;
  path?: string;
  isAlpha?:boolean;
  isBeta?: boolean;
  isNew?: boolean;
  unreadEmails?: boolean;
  childPages?: ChildPagesProps[];
  type?: string;
  shortTitle?: string;
  size?: string;

};

type SiderProps = {
  sideBarItems?: SideBarProps[];
  isDockedSider?: any;
  publicPage?:any;
  contentRedirectLoader?: boolean;
  setContentRedirectLoader?: any;
};

export const CloseDrawer: React.FC<SiderProps> = observer(({setContentRedirectLoader, contentRedirectLoader, sideBarItems, isDockedSider, publicPage}) => {
  const router = useRouter();
  const currentCustomerId = localStorage.getItem('currentCustomerId');
  const {home: {isChild}, settings: {customer: {isInitialExtendedDetailsFetched, profile: {whitelabelOtto, isLinkgraph, isWhitelabel, isParentAccount}}}} = useStore('');
  const {settings: {customer: {profile, setAuthModal, remainingExpiryDays, isGHLAccount, profile: {isVendastaClient, isVerified}}}} = useStore('');
  const researchTools = ['/research/keyword-researcher', '/research/keyword-magic-tool', '/research/keyword-rank-tracker', '/research/keyword-gap-analysis'];
  const isKeywordPage = researchTools?.includes(router?.pathname);
  const {height} = useWindowSize();

  const iconsNames = [
    {url: '/home', name: 'Projects', icon: faFileChartColumn, color: router.pathname == '/home' ? '#7F4EAD' : '#111213'},
    {url: isGHLAccount ? '/site-audit/list' : '/otto-page-v2', name: `${isGHLAccount ? 'Site' : `${whitelabelOtto} &`}  Audit`, icon: faFolderCheck, color: sideBarItems?.find(item=> item.path?.startsWith('/otto-page') || item.path?.startsWith('/site-audit'))?.color || '#111213'},
    {url: '/otto-ppc/google-ads', name: `${whitelabelOtto} PPC`, icon: faThumbsUp, color: sideBarItems?.find(item=> item.path?.includes('otto-ppc'))?.color || '#111213', hidden: isGHLAccount || isVendastaClient || (isWhitelabel && !isParentAccount)},
    {url: '/content/seo-content-assistant', name: 'Content', icon: faPen, color: sideBarItems?.find(item=> item.path?.startsWith('/content'))?.color || '#111213'},
    {url: '/site-explorer/list', name: 'Site Metrics', icon: faMagnifyingGlassWaveform, color: sideBarItems?.find(item=> item.path?.startsWith('/site-explorer/') || item.path?.startsWith('gsc'))?.color || '#111213'},
    {url: '/research/keyword-researcher', name: 'Keywords', icon: faKeySkeleton, color: sideBarItems?.find(item=> item.path?.startsWith('/research/keyword'))?.color || '#111213'},
    {url: '/gbp-galactic/gbp-projects', name: 'GBP Galactic', icon: faLocationDot, color: sideBarItems?.find(item=> item.path?.includes('gbp-galactic'))?.color || '#111213', hidden: isGHLAccount},
    ...(height <= 780 ? [] : [{url: '/report-builder/projects-list', name: 'Report Builder', icon: faFileChartColumn, color: sideBarItems?.find(item=> item.path?.includes('report-builder')) ? '#2d3033' : '#111213'},
      {url: '/outreach/press-release', name: 'Authority Building', icon: faBullhorn, color: sideBarItems?.find(item=> item.path?.includes('/outreach/press-release'))?.color || '#111213', hidden: isGHLAccount}]),
  ];

  const moreBtnRoutes = (
    <Menu>
      {height >= 780 ? <></> : <>
        <MenuWrapper style={{padding: 0}} key='1'><Link legacyBehavior href={'/report-builder/projects-list'} passHref ><a><p style={{display: 'block', width: '22px'}}><FontAwesomeIcon icon={faFileChartColumn} color='#000' /></p> Report Builder</a></Link></MenuWrapper>
        {!isGHLAccount && <MenuWrapper style={{padding: 0}} key='2'><Link legacyBehavior href={'/outreach/press-release'} passHref ><a><p style={{display: 'block', width: '22px'}}><FontAwesomeIcon icon={faBullhorn} color='#000' /></p> Authority Building</a></Link></MenuWrapper>}</>}
      <MenuWrapper style={{padding: 0}} key='4'> <Link legacyBehavior href={'/schema'} passHref ><a><p style={{display: 'block', width: '22px'}}><FontAwesomeIcon icon={faListAlt} color='#000' /></p>Schema Creator</a></Link></MenuWrapper>
      <MenuWrapper style={{padding: 0}} key='5'> <Link legacyBehavior href={routes.backlinkResearcher} passHref ><a><p style={{display: 'block', width: '22px'}}><FontAwesomeIcon icon={faLink} color='#000' /></p>Backlinks</a></Link></MenuWrapper>
      <MenuWrapper style={{padding: 0}} key='6'> <Link legacyBehavior href={'/bulk-url-analyzer'} passHref ><a><p style={{display: 'block', width: '22px'}}><FontAwesomeIcon icon={faDuotoneChartSimpleHorizontal} color='#000' /></p>Bulk URL Analyzer</a></Link></MenuWrapper>
      {!isGHLAccount && <MenuWrapper style={{padding: 0}} key='7'> <Link legacyBehavior href={`/${routes.ottopage}`} passHref ><a><p style={{display: 'block', width: '22px'}}><FontAwesomeIcon icon={faMicrochipAi} color='#000' /></p>{whitelabelOtto} v1.0</a></Link></MenuWrapper>}
      <MenuWrapper style={{padding: 0}} key='8'> <Link legacyBehavior href={routes.localSeoProjectsList} passHref ><a><p style={{display: 'block', width: '22px'}}><FontAwesomeIcon icon={faMapLocationDot} color='#000' /></p>Local SEO</a></Link></MenuWrapper>
    </Menu>
  );


  const items: any = [
    {
      label: (
        <div>
          <FontAwesomeIcon icon={faSquareStar} style={{marginRight: '10px', height: '16px'}}/>
          <a style={{color: 'black'}} href='/onboarding/step-1?resetSetting=true' rel='noopener noreferrer'>
          Get Started Guide
          </a></div>
      ),
      key: '0',
    },
    ...(!isLinkgraph ? [] : [
      {
        label: (<>
          <FontAwesomeIcon icon={faClapperboardPlay} style={{marginRight: '10px', height: '16px'}}/>
          <a style={{color: 'black'}} href='/tutorials' target='_blank' rel='noopener noreferrer'>
          Video Tutorials
          </a>
        </>
        ),
        key: '1',
      },
      {
        label: (
          <>
            <FontAwesomeIcon icon={faCircleQuestion} style={{marginRight: '10px', height: '16px'}}/>
            <a style={{color: 'black'}} href='https://help.searchatlas.com/' target='_blank' rel='noopener noreferrer'>
          Help Center
            </a></>
        ),
        key: '2',
      },
      {
        label: (
          <>
            <FontAwesomeIcon icon={faCalendar} style={{marginRight: '10px', height: '16px'}}/>
            <a style={{color: 'black'}} href='/onboarding' rel='noopener noreferrer'>
          Join Onboarding
            </a></>
        ),
        key: '3',
      }]),
    {
      label: (
        <>
          <FontAwesomeIcon icon={faBug} style={{marginRight: '10px', height: '16px'}}/>
          <a style={{color: 'black'}} onClick={e=> {
            e.preventDefault();
            openLiveChat();
          }}>
          Report a Bug
          </a></>
      ),
      key: '4',
    },
    true && {
      label: (
        <>
          <FontAwesomeIcon icon={faCircleDollar} style={{marginRight: '10px', height: '16px'}}/>
          <span style={{color: 'black'}} onClick={()=>{
            (!publicPage || isVerified) && router.push(`/pricing${profile.isWhitelabel?'-agency':''}${isChild ? `&customerId=${currentCustomerId ? currentCustomerId: ''}` : ''}`);
          }}
          >
          Pricing
          </span></>
      ),
      key: '5',
    },
  ];

  const getPercentage = (val1, val2) => {
    if (val1 && val2) {
      return val1/val2 * 100;
    } else {
      return 0;
    }
  };

  const handleClickOnCluster = ()=> {
    setContentRedirectLoader(true);
    if (!publicPage) {
      MixPanel.track(CP_OPENED_FROM_KE);
      router?.push('/content/content-planner');
    } else {
      setContentRedirectLoader(false);
      return;
    }
  };


  return <div className='outerWrap' style={{padding: '10px 8px 0 0px', width: '66px', borderRight: isDockedSider ? '1px solid #3c3c3c' : '', display: 'flex', flexDirection: 'column', gap: '5px'}}>
    <div>
      {iconsNames.map((item:any, index)=> {
        return item?.hidden ? <></> : <Link legacyBehavior key={index} href={item.url} passHref ><a style={{backgroundColor: item.color, margin: '6px 0px'}} className='blockWrapper'><FontAwesomeIcon icon={item.icon} /><p>{item.name}</p></a></Link>;
      })}
      <Dropdown
        trigger={['click']}
        placement={'topRight'}
        overlay={moreBtnRoutes}
        className={styles.dropdown}
        overlayClassName={`${styles.menu} ${styles.dropdownOffset}`}
      ><div className=''>
          <FontAwesomeIcon icon={faEllipsis} color='white' style={{fontSize: '25px', marginLeft: '15px', cursor: 'pointer'}} />
        </div>
      </Dropdown>
    </div>
    <div style={{width: '100%'}}>
      <div className={styles.opacity} />
      {(!isInitialExtendedDetailsFetched || isVendastaClient) && !isGHLAccount ? '': <div className={styles.trailSec} style={{width: isDockedSider ? '214px' : '0', padding: isDockedSider ? '10px' : ''}} onClick={() => {
        if (publicPage && !isVerified) {
          setAuthModal(true, true);
        }
      }}>
        {(!isInitialExtendedDetailsFetched || isVendastaClient) && !isGHLAccount ? '':
          <>
            {isDockedSider && remainingExpiryDays && Number(remainingExpiryDays) > -1 ? <>
              {(isKeywordPage && isDockedSider) && (
                <StyledKeywordBanner>
                  <div style={{marginBottom: '10px'}}><FontAwesomeIcon icon={faBallPile} fontSize={22} color={'#fff'}/></div>
                  <KeywordBannerDescription>Looking for a more modern way to find relevant keywords to target in SEO Campaigns? </KeywordBannerDescription>
                  <Tooltip
                    placement={'right'}
                    title={`Content Planner finds keywords based on what the top 100 results on Google are ranking for and clusters them algorithmically, giving you much more powerful SEO insights than traditional keyword research.`}>
                    <ButtonComponent
                      style='solid'
                      color='orange'
                      disabled={contentRedirectLoader}
                      onClick={handleClickOnCluster}
                      className={styles.keywordBannerButton}
                    >
                      {'Create Clusters'}{contentRedirectLoader ? <div style={{marginLeft: '10px'}}><LoadingOutlined style={{fontSize: 16, color: '#fff'}} spin /> </div>: <FontAwesomeIcon style={{marginLeft: '10px'}} icon={faArrowRight} fontSize={16} color={'#fff'}/>}
                    </ButtonComponent>
                  </Tooltip>
                </StyledKeywordBanner>
              )}
              <div>
                <p style={{marginTop: '10px', color: '#A3A4A4'}}>Your trial ends in {remainingExpiryDays} days</p>
                <TrialBar>
                  <div style={{background: '#8860D4', height: 5, borderRadius: 3,
                    width: `${getPercentage(7- remainingExpiryDays, 7)/100}%`}}/>
                </TrialBar>
              </div> </> :
              ''}
          </>}
      </div>}
    </div>
    {!isGHLAccount && <div style={{bottom: '0', position: 'fixed', backgroundColor: '#0c0e12', width: '62px'}}>
      <div style={{backgroundColor: '#0c0e12', marginBottom: '1px'}} className='blockWrapper'><Link legacyBehavior href={'/agency-settings'} passHref><a style={{color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
        <FontAwesomeIcon icon={faBriefcaseArrowRight} /><p>Agency Whitelabel</p></a></Link></div>
      <div style={{backgroundColor: '#0c0e12', marginBottom: '23px'}} className='blockWrapper'><FontAwesomeIcon icon={faLifeRing} />
        <Dropdown
          menu={{items}}
          trigger={['click']}
          className={styles.dropdown}
          overlayClassName={`${styles.menu} ${styles.dropdownOffset}`}
          placement='topRight'
        >
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '-10px'}}>
            <p>Help</p>
            <span className={styles['down-icon']}><CaretDownOutlined /></span>
          </div>
        </Dropdown>
      </div>
    </div>}
  </div>;
});

const MenuWrapper = styled(Menu.Item)`
a{
display: flex;
align-items: center;
margin: 5px 17px;
height: 25px;
p{
margin-top: 13px;
}
}
`;
const TrialBar = styled.div`
  width: 200px;
  background: #4E5156;
  margin: -10px 0 15px 0;
  height: 5px;
  border-radius:3px;
`;
const StyledKeywordBanner = styled.div`
padding: 18px 6px 18px 13px;
background-color: rgba(255, 133, 54, 0.15);
border-radius: 8px;
margin-top: 36px;
`;
const KeywordBannerDescription = styled.div`
color: #fff;
font-family: 'Inter', sans-serif;
font-weight: 500;
font-size: 13px;
`;
