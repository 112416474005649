import React, {useEffect, useRef} from 'react';
import {RenderFunction, TooltipPlacement} from 'antd/es/tooltip';
import {Tooltip} from 'antd';
import {observer} from 'mobx-react';


interface Props {
  children: React.ReactNode;
  placement?: TooltipPlacement;
  removeTooltip?: boolean;
  title: React.ReactNode | RenderFunction;
  overlayWidth?: string;
  padding?: string;
  shouldOpenWithState?: boolean;
  visible?: boolean;
  trigger?: 'hover' | 'focus' | 'click' | 'contextMenu' | Array<'hover' | 'focus' | 'click' | 'contextMenu'>;
  setVisible?: React.Dispatch<React.SetStateAction<string>>;
  backgroundColor?: string;
  arrowColor?: string;
}
function isRenderFunction(title: any): title is RenderFunction {
  return typeof title === 'function';
}
export const CustomTooltip = observer(({
  children,
  placement = 'top',
  title,
  overlayWidth='',
  padding='',
  shouldOpenWithState=false,
  visible,
  trigger=['hover'],
  setVisible,
  backgroundColor,
  arrowColor,
} : Props) => {
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
        // Only close if the tooltip is visible and clicked outside
        if (setVisible) {
          setVisible('');
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [tooltipRef, setVisible]);

  const resolvedTitle = isRenderFunction(title) ? title() : title;
  return (
    <Tooltip
      zIndex={1050}
      title={resolvedTitle && <div ref={tooltipRef}>{resolvedTitle}</div>}
      overlayInnerStyle={{backgroundColor: backgroundColor && backgroundColor, width: overlayWidth && overlayWidth, borderRadius: '8px', padding: padding && padding}}
      placement={placement}
      color={arrowColor && arrowColor}
      {...((!shouldOpenWithState && trigger) && {trigger})}
      {...(shouldOpenWithState && {visible})}
    >
      {children}
    </Tooltip>
  );
});

