import {Instance, types} from 'mobx-state-tree';
import {TargetKeywordsModel} from './TargetKeywordsModel';

export const PageModel = types.model({
  url: types.maybeNull(types.string),
  uuid: types.maybeNull(types.string),
  targetKeywords: types.maybeNull(types.array(TargetKeywordsModel)),
  title: types.maybeNull(types.string),
  importedFromUrl: types.maybeNull(types.string),
  dueDate: types.maybeNull(types.string),
  requiredWordCount: types.maybeNull(types.number),
  version: types.maybeNull(types.string),
  maxSerpPosition: types.maybeNull(types.number),
  textPreview: types.maybeNull(types.string),
  projectName: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  wordCount: types.maybeNull(types.number),
  readability: types.maybeNull(types.string),
  score: types.maybeNull(types.number),
  status: types.enumeration('STATUS', ['IN_PROGRESS', 'COMPLETED', 'NOT_BEGUN', 'ARCHIVED', 'NEEDS_REVIEW', 'AI_IN_PROGRESS']),
  readabilityGrade: types.maybeNull(types.string),
  technicalScore: types.maybeNull(types.number),
  assignedToUsers: types.maybeNull(types.array(types.string)),
  isOwner: types.maybeNull(types.boolean),
  aiInProgress: types.maybeNull(types.boolean),
  wpSynced: types.maybeNull(types.boolean),
  wpSyncingTaskStatus: types.maybeNull(types.string),
  wasDeleted: types.maybeNull(types.boolean),
  defaultWpPostCategories: types.maybeNull(types.array(types.string)),
  defaultWpPostStatus: types.maybeNull(types.string),
  defaultWpPostType: types.maybeNull(types.string),
  defaultWpUrlSlug: types.maybeNull(types.string),
  defaultWpWebsiteId: types.maybeNull(types.number),
});

export type PageModelInstance = Instance<typeof PageModel>;

const CompanyInfoModal = types.model({
  companyName: types.maybeNull(types.string),
  websiteUrl: types.maybeNull(types.string),
  targetUrl: types.maybeNull(types.string),
  businessAddress: types.maybeNull(types.string),
  gbpUrl: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  phone: types.maybeNull(types.string),
  mediaContactEmail: types.maybeNull(types.string),
  mediaContactPhone: types.maybeNull(types.string),
  mediaContactName: types.maybeNull(types.string),
});

export const ProjectDefaultAISettingsModel = types.model({
  disableConclusion: types.maybeNull(types.boolean),
  disableSkinnyParagraph: types.maybeNull(types.boolean),
  enableFaq: types.maybeNull(types.string),
  enableKeyTakeaways: types.maybeNull(types.boolean),
  enableLiteraryDevices: types.maybeNull(types.boolean),
  length: types.maybeNull(types.string),
  pointOfView: types.maybeNull(types.string),
  toneOfVoice: types.maybeNull(types.string),
  useList: types.maybeNull(types.boolean),
  useTable: types.maybeNull(types.boolean),
  useTease: types.maybeNull(types.boolean),
  generateMetadata: types.maybeNull(types.boolean),
  generateHeroImage: types.maybeNull(types.boolean),
  generateImages: types.maybeNull(types.boolean),
  imagesAspectRatio: types.maybeNull(types.string),
  imagesEngine: types.maybeNull(types.string),
  language: types.maybeNull(types.string),
  boldTermsToInclude: types.maybeNull(types.boolean),
  llm: types.maybeNull(types.string),
  actAsWho: types.maybeNull(types.string),
  readingLevel: types.maybeNull(types.string),
  writingStyle: types.maybeNull(types.string),
  intent: types.maybeNull(types.string),
  defaultAudience: types.maybeNull(types.string),
  searchIntent: types.maybeNull(types.string),
  niche: types.maybeNull(types.string),
  websitePurpose: types.maybeNull(types.string),
  tableOfContents: types.maybeNull(types.boolean),
  openaiSecretKey: types.maybeNull(types.string),
  anthropicSecretKey: types.maybeNull(types.string),
  imagesAdditionalContext: types.maybeNull(types.string),
});

export const ProjectLinksModel = types.model({
  url: types.maybeNull(types.string),
  anchor: types.maybeNull(types.string),
});

export const ProjectModel = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  articlesCount: types.maybeNull(types.number),
  syncedCount: types.maybeNull(types.number),
  requiredInternalLinks: types.maybeNull(types.union(types.array(types.string), types.array(ProjectLinksModel))),
  requiredExternalLinks: types.maybeNull(types.union(types.array(types.string), types.array(ProjectLinksModel))),
  optionalExternalLinks: types.maybeNull(types.union(types.array(types.string), types.array(ProjectLinksModel))),
  optionalInternalLinks: types.maybeNull(types.union(types.array(types.string), types.array(ProjectLinksModel))),
  defaultAiSettings: types.maybeNull(ProjectDefaultAISettingsModel),
  domain: types.maybeNull(types.string),
  globalBackground: types.maybeNull(types.string),
  wpAutoUpload: types.maybeNull(types.boolean),
  wpPostCategories: types.maybeNull(types.array(types.string)),
  wpPostStatus: types.maybeNull(types.string),
  wpPostType: types.maybeNull(types.string),
  wpUrlSlug: types.maybeNull(types.string),
  wpWebsiteId: types.maybeNull(types.number),
  excludedFocusterms: types.maybeNull(types.array(types.string)),
  companyInfo: types.maybeNull(CompanyInfoModal),
  imagesAdditionalContext: types.maybeNull(types.string),
  shopifyAccountId: types.maybeNull(types.string),
  shopifyAuthor: types.maybeNull(types.union(types.string, types.number)),
  shopifyTags: types.maybeNull(types.array(types.string)),
  shopifyBlogTitle: types.maybeNull(types.string),
  shopifyPostType: types.maybeNull(types.string),
  shopifyAutoUpload: types.maybeNull(types.boolean),
});

export type ProjectModelInstance = Instance<typeof ProjectModel>;
