import {cast, flow, types} from 'mobx-state-tree';
import {INBOX_API} from '@/api/campaigns/inbox';
import {useErrorNotification} from '@/utils/notification-v2';
import {apiError} from '@/utils/api';
import {toJS} from 'mobx';
import {inboxTableFilter} from './data';
import {getFilter} from '@/utils/filters';
import {notification} from '@/utils/notification-v2';

const InboxDataResult = types.model({
  id: types.number,
  sender: types.maybeNull(types.string),
  contactEmail: types.maybeNull(types.string),
  isReplied: types.maybeNull(types.boolean),
  sentAt: types.maybeNull(types.string),
  repliedAt: types.maybeNull(types.string),
  subject: types.maybeNull(types.string),
  campaignName: types.maybeNull(types.string),
  contactFirstName: types.maybeNull(types.string),
  contactLastName: types.maybeNull(types.string),
  body: types.maybeNull(types.string),
  milestone: types.maybeNull(types.string),
  isRead: types.maybeNull(types.boolean),
});

const InboxData = types.model({
  count: types.maybeNull(types.number),
  totalPages: types.maybeNull(types.number),
  pageSize: types.maybeNull(types.number),
  results: types.array(InboxDataResult),
});

const OpportunityDetails = types.model({
  id: types.number,
  ahrefsDr: types.maybeNull(types.number),
  ahrefsOk: types.maybeNull(types.number),
  ahrefsOt: types.maybeNull(types.number),
  ahrefsRefdomains: types.maybeNull(types.number),
  domain: types.maybeNull(types.string),
  isScheduled: types.maybeNull(types.boolean),
  metaDesc: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
});

const ThreadData = types.model({
  messageId: types.maybeNull(types.string),
  threadId: types.maybeNull(types.string),
  from: types.maybeNull(types.string),
  to: types.maybeNull(types.string),
  subject: types.maybeNull(types.string),
  body: types.maybeNull(types.string),
  date: types.maybeNull(types.string),
});

const ConversationOpportunity = types.model({
  id: types.maybeNull(types.number),
  emailSender: types.maybeNull(types.string),
  emailTo: types.maybeNull(types.string),
  opportunityDetails: types.maybeNull(OpportunityDetails),
  thread: types.maybeNull(types.array(ThreadData)),
});

const filterListModel = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  header: types.maybeNull(types.string),
  customFilterValue: types.optional(types.string, ''),
  type: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  isDecimals: types.maybeNull(types.boolean),
  maxLimit: types.maybeNull(types.number),
  filterField: types.maybeNull(types.string),
  query: types.maybeNull(types.string),
  from: types.maybeNull(types.string),
  to: types.maybeNull(types.string),
  equal: types.maybeNull(types.union(types.string, types.number)),
  customCategoryType: types.maybeNull(types.string),
  isSearch: types.maybeNull(types.boolean),
  active: types.boolean,
  category: types.maybeNull(types.array(types.string)),
  filterTypes: types.maybeNull(types.array(types.model({
    label: types.maybeNull(types.string),
    value: types.maybeNull(types.string),
  }))),
  customOptions: types.maybeNull(types.array(types.model({
    name: types.maybeNull(types.string),
    info: types.optional(types.string, ''),
    showPercent: types.optional(types.boolean, false),
    min: types.maybeNull(types.union(types.string, types.number)),
    max: types.maybeNull(types.union(types.string, types.number)),
    equal: types.maybeNull(types.union(types.string, types.number)),
  }))),
  customOptionsTop: types.maybeNull(types.array(types.model({
    name: types.maybeNull(types.string),
    info: types.optional(types.string, ''),
    showPercent: types.optional(types.boolean, false),
    min: types.maybeNull(types.union(types.string, types.number)),
    max: types.maybeNull(types.union(types.string, types.number)),
  }))),
  customFields: types.maybeNull(types.array(types.model({
    label: types.maybeNull(types.string),
    operator: types.maybeNull(types.string),
  }))),
});

const paramsModel = types.model({
  page_size: types.maybeNull(types.number),
  page: types.maybeNull(types.number),
  ordering: types.maybeNull(types.string),
  search: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
});

export const InboxStore = types.model({
  emailInboxLoading: types.boolean,
  conversationModalVisible: types.boolean,
  opConversationLoading: types.boolean,
  emailInboxData: types.maybeNull(InboxData),
  conversationOpportunityData: types.maybeNull(ConversationOpportunity),
  replyInThreadLoading: types.boolean,
  sendingOutreachEmailLoading: types.boolean,
  contactId: types.number,
  inboxPageSize: types.number,
  inboxPageNumber: types.number,
  inboxTableFilter: types.array(filterListModel),
  allInboxParams: types.maybeNull(paramsModel),
  opportunityId: types.number,
  emailUnreadCount: types.maybeNull(types.number),
}).views(self => ({
  get getInboxTableFilter() {
    return toJS(self.inboxTableFilter);
  },
})).actions(self => {
  const setInboxPagination = (page: number, pageSize: number) => {
    self.allInboxParams = {...self.allInboxParams, page: page, page_size: pageSize};
    getEmailInboxData();
  };
  const updateInboxFilter = (filters, ottoProjectId?: number) => {
    self.allInboxParams = {...self.allInboxParams, page: 1};
    self.inboxTableFilter = cast(filters);
    if (ottoProjectId) {
      getEmailInboxData(false, ottoProjectId);
    } else {
      getEmailInboxData();
    }
  };
  const setInboxOrdering = (data: any) => {
    self.allInboxParams = data;
    getEmailInboxData();
  };
  const resetEmailInboxData = function() {
    self.emailInboxData = null;
  };
  const getEmailInboxData = flow(function* (noLoading?: boolean, ottoProjectId?: number) {
    if (!noLoading) self.emailInboxLoading = true;
    try {
      const activeFilters = self.inboxTableFilter?.filter(z => z?.active);
      const params = {
        ...self.allInboxParams,
        ...(getFilter(activeFilters, 'exclude')?.filterField === 'search' && {'search': getFilter(activeFilters, 'exclude')?.query}),
        ...(activeFilters.find((filter:any)=>filter?.header == 'Status') && {'is_replied': activeFilters.find((filter:any)=>filter?.header == 'Status')?.type == 'replied' || false}),
        ...(activeFilters.find((filter:any)=>filter?.header == 'Campaign') && {'campaign_id': activeFilters.find((filter:any)=>filter?.header == 'Campaign')?.type}),
        ...(activeFilters.find((filter:any)=>filter?.header == 'Subject') && {'subject': activeFilters.find((filter:any)=>filter?.header == 'Subject')?.query}),
        ...(activeFilters.find((filter:any)=>filter?.header == 'Milestone') && {'milestone': activeFilters.find((filter:any)=>filter?.header == 'Milestone')?.type}),
        ...(ottoProjectId) && {'otto_project_id': ottoProjectId},
      };
      const response = yield INBOX_API.emailInbox(params);
      if (response?.isCancel) return;
      self.emailInboxData = response;
    } catch (e) {
      useErrorNotification({
        e,
        msg: 'Something went wrong.',
        desc: '',
        permanent: false,
        handleStatuses: [
          {
            statuses: [401, 403],
            msg: 'Something went wrong',
            permanent: false,
            showDetails: false,
          },
          {
            statuses: [400],
            msg: apiError(e) as string,
            ...(e.response?.data?.nonFieldErrors && {desc: e.response?.data?.nonFieldErrors.join('\n\n')}),
            showDetails: false,
          },
        ],
      });
    } finally {
      self.emailInboxLoading = false;
    }
  });

  const updateMilestone = flow(function* (payload) {
    self.emailInboxLoading = true;
    try {
      const response = yield INBOX_API.updateMilestone(payload);
      if (response?.isCancel) return;
      return response;
    } catch (e) {
      useErrorNotification({
        e,
        msg: 'Something went wrong.',
        desc: '',
        permanent: false,
        handleStatuses: [
          {
            statuses: [401, 403],
            msg: 'Something went wrong',
            permanent: false,
            showDetails: false,
          },
          {
            statuses: [400],
            msg: apiError(e) as string,
            ...(e.response?.data?.nonFieldErrors && {desc: e.response?.data?.nonFieldErrors.join('\n\n')}),
            showDetails: false,
          },
        ],
      });
    } finally {
      self.emailInboxLoading = false;
    }
  });

  const viewConversationOpportunity = flow(function* (contactId: number, opportunityId: number) {
    self.opConversationLoading = true;
    try {
      const response = yield INBOX_API.viewConversationOpportunity(contactId, opportunityId);
      if (response?.isCancel) return;
      self.conversationOpportunityData = response;
    } catch (e) {
      useErrorNotification({
        e,
        msg: 'Something went wrong.',
        desc: '',
        permanent: false,
        handleStatuses: [
          {
            statuses: [401, 403],
            msg: 'Something went wrong',
            permanent: false,
            showDetails: false,
          },
          {
            statuses: [400],
            msg: apiError(e) as string,
            ...(e.response?.data?.nonFieldErrors && {desc: e.response?.data?.nonFieldErrors.join('\n\n')}),
            showDetails: false,
          },
        ],
      });
    } finally {
      self.opConversationLoading = false;
    }
  });

  const getInboxEmailThread = flow(function* (emailId: number) {
    self.opConversationLoading = true;
    try {
      const response = yield INBOX_API.getInboxEmailThread(emailId);
      if (response?.isCancel) return;
      self.conversationOpportunityData = response;
    } catch (e) {
      useErrorNotification({
        e,
        msg: 'Something went wrong.',
        desc: '',
        permanent: false,
        handleStatuses: [
          {
            statuses: [401, 403],
            msg: 'Something went wrong',
            permanent: false,
            showDetails: false,
          },
          {
            statuses: [400],
            msg: apiError(e) as string,
            ...(e.response?.data?.nonFieldErrors && {desc: e.response?.data?.nonFieldErrors.join('\n\n')}),
            showDetails: false,
          },
        ],
      });
    } finally {
      self.opConversationLoading = false;
    }
  });

  const replyInThread = flow(function* (emailId: number, payload: any) {
    self.replyInThreadLoading = true;
    try {
      const response = yield INBOX_API.replyInThread(emailId, payload);
      if (response?.isCancel) return;
      getInboxEmailThread(emailId);
      return response;
    } catch (e) {
      useErrorNotification({
        e,
        msg: 'Something went wrong.',
        desc: '',
        permanent: false,
        handleStatuses: [
          {
            statuses: [401, 403],
            msg: 'Something went wrong',
            permanent: false,
            showDetails: false,
          },
          {
            statuses: [400],
            msg: apiError(e) as string,
            ...(e.response?.data?.nonFieldErrors && {desc: e.response?.data?.nonFieldErrors.join('\n\n')}),
            showDetails: false,
          },
        ],
      });
    } finally {
      self.replyInThreadLoading = false;
    }
  });

  const sendSingleOutreachEmail = flow(function* (contactId: number, opportunityId: number, payload: any) {
    self.sendingOutreachEmailLoading = true;
    try {
      const response = yield INBOX_API.sendSingleOutreachEmail(contactId, opportunityId, payload);
      if (response?.isCancel) return;
      if (response) {
        return notification.success('', 'Email Sent Successfully.');
      }
      return response;
    } catch (e) {
      useErrorNotification({
        e,
        msg: 'We are unable to sent the Email.',
        desc: '',
        permanent: false,
        handleStatuses: [
          {
            statuses: [401, 403],
            msg: 'We are unable to sent the Email.',
            permanent: false,
            showDetails: false,
          },
          {
            statuses: [400],
            msg: apiError(e) as string,
            ...(e.response?.data?.nonFieldErrors && {desc: e.response?.data?.nonFieldErrors.join('\n\n')}),
            showDetails: false,
          },
        ],
      });
    } finally {
      self.sendingOutreachEmailLoading = false;
    }
  });

  const getUnreadEmailCount = flow(function* () {
    try {
      const response = yield INBOX_API.getUnreadEmailCount();
      if (response?.isCancel) return;
      self.emailUnreadCount = response;
      return response;
    } catch (e) {
      const errorMessage = apiError(e);
      notification.error('', errorMessage);
    }
  });

  const setConversationModalVisible = (value: boolean) => self.conversationModalVisible = value;
  const setContactId = (value: number) => self.contactId = value;
  const setOpportunityId = (value: number) => self.opportunityId = value;

  return {
    getEmailInboxData,
    resetEmailInboxData,
    setConversationModalVisible,
    viewConversationOpportunity,
    replyInThread,
    getInboxEmailThread,
    setContactId,
    sendSingleOutreachEmail,
    setInboxPagination,
    setInboxOrdering,
    updateInboxFilter,
    setOpportunityId,
    updateMilestone,
    getUnreadEmailCount,
  };
});

export const initInboxStore = () => {
  return InboxStore.create({
    emailInboxLoading: false,
    conversationModalVisible: false,
    opConversationLoading: false,
    replyInThreadLoading: false,
    contactId: 0,
    sendingOutreachEmailLoading: false,
    inboxPageSize: 10,
    inboxPageNumber: 1,
    inboxTableFilter: inboxTableFilter(),
    allInboxParams: {
      page_size: 10,
      page: 1,
      ordering: '',
      search: '',
      type: '',
    },
    opportunityId: 0,
  });
};
