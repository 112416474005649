import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {Row, Col, Tooltip, Spin, Dropdown, Popover} from 'antd';
import Image from 'next/image';
import React, {useCallback, useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPen} from '@fortawesome/pro-solid-svg-icons';
import {faBallotCheck, faBookOpen, faClockTwo, faFaceFrown, faFaceSmile, faGear} from '@fortawesome/pro-regular-svg-icons';
import {NewQuotaDropDown} from '@/components/common-components/components/new-quote-dropdown';
import {TopupModal} from '../Modals/topupModal';
import {LoadingOutlined} from '@ant-design/icons';
import {isArray} from 'lodash';
import {camelCaseToHumanize, camelToSnakeCase} from '@/utils/string';
import {planApi} from '@/api/account';
import {getBlockedOttoSitesTooltip, getHyperDriveTooltip} from './contentDetailV2';
import {convertMinutesIntoReadAbleTime} from '@/utils/moment';
import {SmallBadge} from '@/components/common-components/components/badge';
import {Button} from '@/components/common-components';
import {getSingleUrlParam} from '@/utils/url';
import PublicPageFreezeWrapper from './publicPageFreeze';
import moment from 'moment';
import useWindowSize from '@/utils/hooks/useWindowSize';

interface Props {
  setGbpModalVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsKnowledgeModalVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  handleOpenGSCModal?: (isGaObject: any) => void | Promise<void>;
  openSettings?: () => void | Promise<void>;
  knowledgeGraphInitialLoader?: boolean;
  tooltipShown?: any;
  handleLaterClick?: any;
  currentProjectLoader?: boolean;
}

const OttoCtaSection = observer(({setGbpModalVisible, handleOpenGSCModal, setIsKnowledgeModalVisible, openSettings, knowledgeGraphInitialLoader, tooltipShown, handleLaterClick, currentProjectLoader}: Props) => {
  const [hyperDriveRecurring, setHyperDriveRecurring] = useState<'yes' | 'no'>('no');
  const {
    ottoV2Store: {getOttoV2Project, knowledgeGraph, quotaAllocations, isTopUpHyperDrive, blockedQuotas, paymentTopups},
    settings: {customer: {profile: {whitelabelOtto, quotaUtilization, nextPaymentAt, addons, setTopupPrices, isLinkgraph}}},
  } = useStore('');
  const [topUpBulkVisibility, setTopUpBulkVisibility] = useState({
    visibility: false,
    price: 0,
    title: '',
    options: [],
    consumed: 0,
    total: 0,
    recurring: '',
    quotaKey: '',
    desc: '',
    buttonTxt: '',
    overAllQuota: 0,
    wholeCredits: false,
  });
  const [issueLabels, setIssueLabels] = useState({});
  const isPublicHash = !!getSingleUrlParam('public_hash');
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const {width} = useWindowSize();

  const getOttoPlans = async () => {
    const response = await planApi.getPlans({is_addon: 'True'});
    if (response?.isCancel) {
      return false;
    }
    const activeAddon = addons?.length > 0 && addons[addons?.length - 1];
    const selectedPlan = response?.find(plan => plan?.id == activeAddon?.planId);
    if (selectedPlan) {
      typeof setTopupPrices === 'function' && setTopupPrices(
        {
          topupHyperdriveCreditCost: selectedPlan?.topupHyperdriveCreditCost,
          topupOttoAiQuotaCost: selectedPlan?.topupOttoAiQuotaCost,
          topupOttoPageCost: selectedPlan?.topupOttoPageCost,
          topupOttoProjectCost: selectedPlan?.topupOttoProjectCost,
        },
      );
    }
  };

  useEffect(() => {
    if (addons?.length) getOttoPlans();
  }, [addons?.length]);

  const getTopupQuotaConf = key => {
    return quotaAllocations?.find(i => i?.quotaKey === key);
  };

  useEffect(() => {
    const obj = {};
    const issueTypesFrontendMappingV2 = getOttoV2Project?.issueTypesFrontendMappingV2;
    issueTypesFrontendMappingV2?.forEach(item => {
      item?.subGroups?.forEach(group => {
        obj[group?.group] = group?.label;
      });
    });
    setIssueLabels(obj);
  }, [getOttoV2Project?.issueTypesFrontendMappingV2]);

  const getSummedHyperDriveQuotaValues = key => {
    const quotaObj = quotaUtilization?.ca?.hyperdriveCredits;
    return (quotaUtilization?.ca?.hyperdriveCreditsPurchased[key] ?? 0) + (quotaObj[key] ?? 0);
  };
  const getHyperDriveObj = () => isLinkgraph ? getTopupQuotaConf('quota_ca_allowed_otto_hyperdrive') : getTopupQuotaConf('quota_ca_hyperdrive_credits');
  const hyperDriveQuotaKey = 'hyperdriveCredits';
  const paymentTopup = paymentTopups?.find(i => i?.quotaKey === 'quota_ca_allowed_otto_v2_projects');

  const quotaArray = [
    {
      quotaTitle: `${whitelabelOtto} Sites`,
      available: quotaUtilization?.ca?.allowedOttoV2Projects?.total - quotaUtilization?.ca?.allowedOttoV2Projects?.consumed,
      used: quotaUtilization?.ca?.allowedOttoV2Projects?.consumed,
      total: quotaUtilization?.ca?.allowedOttoV2Projects?.total,
      quotaInfoTooltip: blockedQuotas?.length ? getBlockedOttoSitesTooltip(blockedQuotas) : '',
      quotaInfoTooltipWidth: 470,
      topupConf: {
        price: getTopupQuotaConf('quota_ca_allowed_otto_v2_projects')?.pricePerUnit || 0,
        title: `${whitelabelOtto} Sites`,
        options: ['price', 'AI_generation', 'hyperdrive'],
        recurring: getTopupQuotaConf('quota_ca_allowed_otto_v2_projects')?.topOptions[0],
        quotaKey: getTopupQuotaConf('quota_ca_allowed_otto_v2_projects')?.quotaKey,
        desc: '',
        nextPaymentAt,
        buttonTxt: `${whitelabelOtto} site`,
        showSlider: true,
        siteDesc: 'Get more from OTTO Sites and enjoy bigger discounts with each additional site quota you secure.',
        minLimit: 1,
        buttonLabel: 'site',
        priceLabel: 'per site',
        showPriceOnButton: false,
        showDiscount: true,
        prefillTopupPoints: paymentTopup ? (quotaUtilization?.ca?.allowedOttoV2Projects?.total + paymentTopup?.quotaToBeAddedOnNextBilling) - paymentTopup?.quotaToBeRemovedOnNextBilling : quotaUtilization?.ca?.allowedOttoV2Projects?.total,
      },
    },
    {
      quotaTitle: `${whitelabelOtto} HyperDrive`,
      available: getSummedHyperDriveQuotaValues('total') - getSummedHyperDriveQuotaValues('consumed'),
      used: getSummedHyperDriveQuotaValues('consumed'),
      total: getSummedHyperDriveQuotaValues('total'),
      quotaInfoTooltip: getHyperDriveTooltip(
        [
          {used: quotaUtilization?.ca?.[hyperDriveQuotaKey]?.consumed, total: quotaUtilization?.ca?.[hyperDriveQuotaKey]?.total, label: 'Recurring'},
          {used: quotaUtilization?.ca?.hyperdriveCreditsPurchased?.consumed, total: quotaUtilization?.ca?.hyperdriveCreditsPurchased?.total, label: 'Non-Recurring'},
        ],
      ),
      topupConf: {
        price: (hyperDriveRecurring === 'yes' || isLinkgraph) ? (getHyperDriveObj()?.pricePerUnit || 0) * 120 : 1,
        title: `${whitelabelOtto} HyperDrive`,
        options: isLinkgraph ? ['price', 'current_quota', 'used_card', 'recurring_payment'] : ['quantity', 'current_quota', 'used_card', 'recurring_payment'],
        recurring: (hyperDriveRecurring === 'yes' || isLinkgraph) ? getHyperDriveObj()?.topOptions[0] : 'purchased',
        quotaKey: getHyperDriveObj()?.quotaKey,
        desc: isLinkgraph ? 'per 100 credits' : hyperDriveRecurring === 'yes' ? 'per 120 credits per month' : 'per 1 credit',
        nextPaymentAt,
        buttonTxt: 'credit',
        step: isLinkgraph ? 100 : hyperDriveRecurring === 'yes' ? 120 : 1,
        multiplesOfStep: (hyperDriveRecurring === 'yes' || isLinkgraph) ? true : false,
        unitIsMultipleOfStep: (hyperDriveRecurring === 'yes' || isLinkgraph) ? true : false,
        recurringIsOptional: !isLinkgraph,
        recurringValue: hyperDriveRecurring,
        setRecurringValue: setHyperDriveRecurring,
        wholeCredits: true,
        showSlider: true,
        siteDesc: 'Used for Press Releases and Cloud Stack services.',
      },
    },
    {
      quotaTitle: 'AI Premium Quota',
      available: (quotaUtilization?.ca?.allowedAiPremiumContentGeneration?.total + Number(quotaUtilization?.ca?.purchasedPremiumAiQuota?.total || 0)) - (quotaUtilization?.ca?.allowedAiPremiumContentGeneration?.consumed + Number(quotaUtilization?.ca?.purchasedPremiumAiQuota?.consumed)),
      used: quotaUtilization?.ca?.allowedAiPremiumContentGeneration?.consumed + Number(quotaUtilization?.ca?.purchasedPremiumAiQuota?.consumed),
      total: (quotaUtilization?.ca?.allowedAiPremiumContentGeneration?.total + Number(quotaUtilization?.ca?.purchasedPremiumAiQuota?.total || 0)),
      topupConf: {
        price: 1,
        title: 'AI Premium Quota',
        options: isLinkgraph ? ['price', 'current_quota', 'used_card', 'recurring_payment'] : ['quantity', 'current_quota', 'used_card', 'recurring_payment'],
        recurring: '',
        quotaKey: 'allowed_ai_premium_content_generation',
        desc: 'per article',
        buttonTxt: 'point',
        overAllQuota: (quotaUtilization?.ca?.allowedAiPremiumContentGeneration?.total + Number(quotaUtilization?.ca?.purchasedPremiumAiQuota?.total || 0)),
      },
      purchasedQuota: quotaUtilization?.ca?.purchasedPremiumAiQuota?.total,
    },
    {
      quotaTitle: 'AI Generation',
      available: quotaUtilization?.ca?.allowedOttoAiSuggestions?.total - quotaUtilization?.ca?.allowedOttoAiSuggestions?.consumed,
      used: quotaUtilization?.ca?.allowedOttoAiSuggestions?.consumed,
      total: quotaUtilization?.ca?.allowedOttoAiSuggestions?.total,
      topupConf: {
        price: getTopupQuotaConf('quota_ca_allowed_otto_ai_suggestions')?.pricePerUnit * 500 || 0,
        title: 'AI Generation Quota',
        options: isLinkgraph ? ['price', 'current_quota', 'used_card', 'recurring_payment'] : ['quantity', 'current_quota', 'used_card', 'recurring_payment'],
        recurring: getTopupQuotaConf('quota_ca_allowed_otto_ai_suggestions')?.topOptions[0],
        quotaKey: getTopupQuotaConf('quota_ca_allowed_otto_ai_suggestions')?.quotaKey,
        desc: 'per 500 points',
        buttonTxt: 'AI Generation',
        overAllQuota: quotaUtilization?.ca?.allowedOttoAiSuggestions?.total,
        nextPaymentAt,
        step: 500,
        multiplesOfStep: true,
        unitIsMultipleOfStep: true,
        wholeCredits: true,
        showSlider: true,
      },
    },
  ];

  useEffect(() => {
    if (topUpBulkVisibility?.visibility || isTopUpHyperDrive) {
      const item: any = quotaArray?.find(item => item?.quotaTitle === `${whitelabelOtto} HyperDrive`);
      setTopUpBulkVisibility({
        ...item?.topupConf,
        visibility: true,
        consumed: item?.used,
        total: item?.total,
      });
    }
  }, [hyperDriveRecurring, isTopUpHyperDrive]);

  const isQuotaAvailable = !!quotaArray.filter(quota => quota.available > 0).length;
  const getKnowledgeGraphCompletion = () => {
    // keys that are not used in form
    const removeFromTotal = ['backgroundInfo', 'email', 'gbpData', 'linkUrls', 'productFeatures', 'type', 'logo'];
    const filterFormat = {};
    for (const key in knowledgeGraph) {
      if (!removeFromTotal.includes(key)) {
        filterFormat[key] = knowledgeGraph[key];
      }
    }
    const completedCount = Object.values(filterFormat || {})?.filter(el => {
      if (el && Object.keys(el).length > 0) {
        return el;
      } else if (typeof el == 'number') {
        return el;
      } else if (isArray(el) && el.length > 0) {
        return el;
      } else if (el && el != '') {
        return el;
      }
    })?.length;
    const totalCount = Object.values(filterFormat || {})?.length;
    return Math.round((completedCount / totalCount) * 100);
  };

  const antIcon = <LoadingOutlined style={{fontSize: 12, color: '#fff', marginRight: 10, marginLeft: -3}} spin />;

  const timeSavedBreakdownStrings = getOttoV2Project?.timeSavedBreakdownStrings;
  const timeBreakdownList = (typeof timeSavedBreakdownStrings === 'object' && timeSavedBreakdownStrings) ? Object.entries(timeSavedBreakdownStrings) : [];

  const timeSavedDropdown = () => {
    return (
      <TimeSavedOverlayWrapper>
        <div className='content'>
          <table className='time-saved-table'>
            <thead className='table-head'>
              <tr className='table-row'>
                <th className='title text-left'>ISSUE CATEGORIES</th>
                <th className='title text-right'>SAVED TIME</th>
              </tr>
            </thead>
            <tbody className='table-body'>
              {timeBreakdownList?.map((item, index) => {
                return (<tr className='table-row' style={{marginTop: 6, marginBottom: 6}} key={index}>
                  <td style={{width: 'auto', minWidth: 156, maxWidth: 240, textTransform: 'capitalize'}} className={`quota-name sans-serif font-14 font-600 color-white`}>
                    <Tooltip placement='left' title={issueLabels[camelToSnakeCase(item?.[0])] || camelCaseToHumanize(item?.[0])}>
                      <div className='inner-text'>{issueLabels[camelToSnakeCase(item?.[0])] || camelCaseToHumanize(item?.[0])}</div>
                    </Tooltip>
                  </td>
                  <td style={{width: 'auto', minWidth: 66, maxWidth: 170, verticalAlign: 'top'}} className={`available-value sans-serif font-14 font-600 text-right color-green`}>{
                    item?.[1] || '-'}
                  </td>
                </tr>);
              })}
            </tbody>
          </table>
        </div>
      </TimeSavedOverlayWrapper>
    );
  };

  const handlePopoverClick = useCallback(e => {
    e.stopPropagation();
    e.preventDefault();
  }, []);

  const nextAnalysisCountdown = nextAnalysisAt => {
    const nextAnalysis = moment(nextAnalysisAt);
    const currentTime = moment();
    const diffInDays = nextAnalysis.diff(currentTime, 'days');
    const diffInHours = nextAnalysis.diff(currentTime, 'hours');
    const diffInMinutes = nextAnalysis.diff(currentTime, 'minutes');
    if (diffInDays > 0) {
      return `Next analysis in ${diffInDays} ${diffInDays === 1 ? 'day' : 'days'}`;
    } else if (diffInHours > 0) {
      return `Next analysis in ${diffInHours} ${diffInHours === 1 ? 'hour' : 'hours'}`;
    } else if (diffInMinutes > 0) {
      return `Next analysis starts soon`;
    } else {
      return '';
    }
  };

  return (
    <Wrapper>
      <StyledRow gutter={[12, 12]} style={{width: '100%'}}>
        <Col md={12} lg={12} xl={width <= 1600 ? 12 : 8}>
          <PublicPageFreezeWrapper>
            <ToolCard
              onClick={() => getOttoV2Project?.connectedData?.isGbpConnected && setGbpModalVisible(true)}
              className={getOttoV2Project?.connectedData?.isGbpConnected ? 'integrated' : ''}
            >
              <div className='icon-img'><Image src='/img/gmbIcon.svg' alt='google-business' width={30} height={26} /></div>
              <div>
              Google Business
                {getOttoV2Project?.connectedData?.isGbpConnected ? (
                  <div className='gsc-text'>{getOttoV2Project?.connectedData?.gbpDetailsV2[0]?.businessName || '-'}</div>
                ) : currentProjectLoader ? <div><Spin indicator={antIcon} style={{marginLeft: 5}} /></div> : (
                  <div>
                    <span className='connect-btn' onClick={() => setGbpModalVisible(true)}>Connect GBP</span>
                  </div>
                )}
              </div>
              <div className='edit-icon'>
                <FontAwesomeIcon icon={faPen} color={'#A3A4A4'} fontSize={14} />
              </div>
            </ToolCard>
          </PublicPageFreezeWrapper>
        </Col>
        <Col md={12} lg={12} xl={width <= 1600 ? 12 : 8}>
          <PublicPageFreezeWrapper>
            <ToolCard
              onClick={() => getOttoV2Project?.connectedData?.isGscConnected && handleOpenGSCModal(getOttoV2Project)}
              className={getOttoV2Project?.connectedData?.isGscConnected ? 'integrated' : ''}
            >
              <div className='icon-img'><Image src='/img/gscicon.svg' alt='google-search-console' width={30} height={26} /></div>
              <div className='text-wrapper'>
              Google Search Console Property
                {getOttoV2Project?.connectedData?.isGscConnected ? (
                  <div className='gsc-text'>{getOttoV2Project?.connectedData?.gscDetails?.propertyUrl || '-'}</div>
                ) : (
                  <div>
                    <span className='connect-btn' onClick={() => handleOpenGSCModal(getOttoV2Project)}>Connect GSC</span>
                  </div>
                )}
              </div>
              <div className='edit-icon'>
                <FontAwesomeIcon icon={faPen} color={'#A3A4A4'} fontSize={14} />
              </div>
            </ToolCard>
          </PublicPageFreezeWrapper>
        </Col>
        <Col md={8} lg={12} xl={width <= 1600 ? 12 : 8}>
          <MiniCards style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', rowGap: 6}}>
            <Tooltip title={timeBreakdownList?.length > 0 ? 'Click to view detailed breakdown' : ''}>
              <Dropdown disabled={timeBreakdownList?.length <= 0} overlay={timeSavedDropdown()} placement='bottomRight' trigger={['click']}>
                <ToolBanner bg={'rgba(52, 174, 243, 0.15)'} style={{cursor: 'pointer', height: 'auto', minHeight: '46%'}}>
                  <FontAwesomeIcon icon={faClockTwo} width={20} height={20} color={'#34AEF3'} fontSize={20} />
                  <div>
                    <div style={{lineClamp: 2, maxWidth: '100%'}}>{convertMinutesIntoReadAbleTime(getOttoV2Project?.timeSavedTotal || '-')}</div>
                    <div className='sm-text'>{`Saved with ${whitelabelOtto}`}</div>
                  </div>
                </ToolBanner>
              </Dropdown>
            </Tooltip>
            <PublicPageFreezeWrapper>
              <NewQuotaDropDown quotaTableArray={quotaArray} placement={'bottomRight'} setTopUpBulkVisibility={setTopUpBulkVisibility} setDropDownOpen={setDropDownOpen} dropDownOpen={dropDownOpen}>
                <ToolBanner bg={isQuotaAvailable ? 'rgba(42, 193, 85, 0.15)' : 'rgba(244, 67, 67, 0.15)'} style={{cursor: 'pointer'}}>
                  <FontAwesomeIcon
                    icon={isQuotaAvailable ? faFaceSmile : faFaceFrown}
                    width={20}
                    height={20}
                    color={isQuotaAvailable ? '#2AC155' : '#F44343'}
                    fontSize={20}
                  />
                  <div>
                  Quota: {isPublicHash ? 'Not Available' : isQuotaAvailable ? 'Available' : 'Fully Consumed'}
                    {!isPublicHash && <div className='sm-text'>Click to View</div>}
                  </div>
                </ToolBanner>
              </NewQuotaDropDown>
            </PublicPageFreezeWrapper>
          </MiniCards>
        </Col>
        <Col md={8} lg={12} xl={width <= 1600 ? 12 : 8}>
          <PublicPageFreezeWrapper>
            <ToolCard
              onClick={() => !knowledgeGraphInitialLoader && setIsKnowledgeModalVisible(true)}
              className={'integrated'}
              style={{cursor: knowledgeGraphInitialLoader ? 'not-allowed' : ''}}
            >
              <div className='icon-img'><FontAwesomeIcon icon={faBookOpen} width={30} height={26} color={'#E8E8E8'} fontSize={30} /></div>
              <div>
              Knowledge Graph
                {isPublicHash ? '' : knowledgeGraphInitialLoader ? <div><Spin indicator={antIcon} style={{marginLeft: 5}} /></div> : <div className='gsc-text'>{getKnowledgeGraphCompletion()}% Complete</div>}
                <div className='edit-icon'>
                  <FontAwesomeIcon icon={faPen} color={'#A3A4A4'} fontSize={14} />
                </div>
              </div>
            </ToolCard>
          </PublicPageFreezeWrapper>
        </Col>
        <Col md={8} lg={12} xl={width <= 1600 ? 12 : 8}>
          <PublicPageFreezeWrapper>
            <ToolCard
              onClick={openSettings}
              className={'integrated'}
            >
              <div className='icon-img'><FontAwesomeIcon icon={faGear} width={30} height={26} color={'#E8E8E8'} fontSize={30} /></div>
              <div>
                {isLinkgraph ? `${whitelabelOtto} Autopilot / Settings` : 'Widget & AI Settings'}
                <Popover
                  zIndex={1}
                  overlayInnerStyle={{width: '468px', borderRadius: '8px'}}
                  visible={(!tooltipShown || !(JSON.parse(tooltipShown) as boolean)) && isLinkgraph}
                  placement={'bottom'}
                  content={<div style={{fontSize: '12px', color: '#121212'}} onClick={handlePopoverClick}>
                    <div>During your next recrawl, the AI Generation Quota may be quickly consumed.</div>
                    <div>Set your allowance based on your goals to optimize performance.</div>
                    <div style={{display: 'flex', alignItems: 'center', gap: '25px'}}>
                      <OptimizeAiButton
                        onClick={openSettings}>
                        Optimize AI Quota Allowance
                      </OptimizeAiButton>
                      <div
                        style={{fontSize: '12px', color: '#4E5156', cursor: 'pointer'}}
                        onClick={handleLaterClick}>I’ll do it later</div>
                    </div>
                  </div>}>
                  {!isPublicHash && <div onClick={e => e.stopPropagation()}><Tooltip
                    title={(
                      <div>
                        {isLinkgraph && (getOttoV2Project?.autopilotIsActive ? <p>
                      The OTTO Autopilot is performing an analysis every {getOttoV2Project?.autopilotFrequencyDays ?? '-'} days to find new issues and improvement opportunities on your site.
                        </p> : <p>Autopilot disabled.</p>)}
                        <span>
                      Last analysis: {getOttoV2Project?.taskStatusUpdatedAt ? moment(getOttoV2Project?.taskStatusUpdatedAt).format('MMM DD, YYYY') : '-'}
                        </span>
                      </div>
                    )}
                    placement='bottom'
                    overlayInnerStyle={{cursor: 'default', borderRadius: '8px', backgroundColor: '#000', width: getOttoV2Project?.autopilotIsActive && '370px', fontSize: 12, padding: 10}}
                  >
                    <div className='gsc-text'>{isLinkgraph ? (getOttoV2Project?.autopilotIsActive ? nextAnalysisCountdown(getOttoV2Project?.nextAnalysisAt) : 'Autopilot disabled.') : 'Click to edit'}</div>
                  </Tooltip></div>}
                </Popover>
                <div className='edit-icon'>
                  <FontAwesomeIcon icon={faPen} color={'#A3A4A4'} fontSize={14} />
                </div>
              </div>
              {isLinkgraph ? <SmallBadge alpha customStyle={{top: '10px', left: '167px', position: 'absolute'}} /> : null}
            </ToolCard>
          </PublicPageFreezeWrapper>
        </Col>
        {getOttoV2Project?.customReportId ? <Col md={8} lg={12} xl={width <= 1600 ? 12 : 8}>
          <PublicPageFreezeWrapper>
            <ToolCard className='yellow-card'>
              <div className='icon-img'><FontAwesomeIcon icon={faBallotCheck} width={30} height={26} color={'#F1AA3E'} fontSize={30} /></div>
              <div>
              Detailed Domain Report
                <div>
                  <a className='connect-btn' href={`/report-builder/full-report?report_id=${getOttoV2Project?.customReportId}&domain=${getOttoV2Project?.hostname}`} target='_blank' rel='noreferrer'>Click to View</a>
                </div>
              </div>
            </ToolCard>
          </PublicPageFreezeWrapper>
        </Col> : null}
      </StyledRow>
      <TopupModal topUpBulkVisibility={topUpBulkVisibility} setTopUpBulkVisibility={setTopUpBulkVisibility} />
    </Wrapper>
  );
});

export default OttoCtaSection;

const Wrapper = styled.div`
  background-color: rgba(163, 164, 164, 0.1);
  border-radius: 14px;
  height: 100%;
  padding: 10px 14px;
  display: flex;
  justify-content: space-between;
  .integrated {
    &:hover {
      background-color: rgba(163, 164, 164, 0.25);
      cursor: pointer;
      .edit-icon {
        display: inline-block !important;
      }
    }
  }
  .yellow-card {
    background-color: rgba(241, 170, 62, 0.15);
  }
`;

const MiniCards = styled.div`
  .freeze-wrapper-span {
    max-height: 46% !important;
    .ant-dropdown-trigger {
      height: 100% !important;
    }
  }
`;

const StyledRow = styled(Row)`
  width: 100%;
  flex-grow: 1;
  .freeze-wrapper-span {
    display: block !important;
    width: 100% !important;
    height: 100% !important;
    .text-wrapper {
      white-space: normal !important;
    }
    .freeze-wrapper {
      width: 100% !important;
      height: 100% !important;
    }
  }
`;

const ToolCard = styled.div`
  position: relative;
  background-color: rgba(163, 164, 164, 0.15);
  border-radius: 12px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 14px;
  line-height: 16.94px;
  font-weight: 500;
  height: 100%;
  .gsc-text {
    margin-top: 4px;
    font-size: 12px;
    line-height: 14.52px;
    font-weight: 400;
    color: #E8E8E8;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // width: fit-content;
  }
  .connect-btn {
    font-size: 12px;
    line-height: 14.52px;
    font-weight: 400;
    cursor: pointer;
    border-bottom: 1px solid #34AEF3;
    color: #34AEF3;
  }
  .edit-icon {
    display: none;
    position: absolute;
    top: 14px;
    right: 15px;
  }
`;

const ToolBanner = styled.div<{bg?: string}>`
  width: 100%;
  padding: 6px 16px;
  background-color: ${p => p?.bg};
  border-radius: 12px;
  height: 46%;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  line-height: 16.94px;
  font-weight: 400;
  .sm-text {
    margin-top: 4px;
    font-size: 12px;
    line-height: 14.52px;
    font-weight: 400;
    color: #E8E8E8;
    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
`;

const TimeSavedOverlayWrapper = styled.div`
  background-color: #121212;
  border-radius: 12px;
  min-width: 480px;
  width: fit-content;
  max-width: 520px;
  padding: 10px 14px;
  @media screen and (max-width: 520px) {
    width: 360px !important;
    min-width: 360px !important;
    max-width: 360px !important;
  }
  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .time-saved-table {
      width: 100%;
      .table-head {
        .table-row {
          .text-left {
            text-align: left;
          }
          .text-right {
            text-align: right;
          }
          .title {
            font-family: 'Inter', sans-serif;
            font-size: 13px;
            font-weight: 700;
            color: #fff;
          }
        }
      }
      .table-body {
        .table-row {
          .sans-serif {
            font-family: 'Inter', sans-serif;
          }
          .font-14 {
            font-size: 14px;
          }
          .font-600 {
            font-weight: 600;
          }
          .font-400 {
            font-weight: 400;
          }
          .color-white {
            color: #fff;
          }
          .color-green {
            color: #2AC155;
          }
          .text-right {
            text-align: right;
          }
          .inner-text {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100%;
            width: fit-content;
          }

          .quota-name {
            width: 156px;
            @media screen and (max-width: 520px) {
              width: 190px !important;
            }
          }
          .available-value {
            width: 66px;
          }
          .used-value {
            width: 63px;
          }
          .total-value {
            width: 63px;
          }
          .top-up-button {
            color: #34AEF3;
            cursor: pointer;
            width: 72px;
          }
          .purchased-quota {
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }
  }
`;

const OptimizeAiButton = styled(Button)`
  display: flex;
  align-items: center;
  width: fit-content;
  height: 30px;
  border-radius: 6px;
  background-color: #2D6CCA !important;
  color: #fff !important;
  font-size: 12px;
  margin-top: 6px;
  &:hover {
    background-color: #2D6CCA !important;
  }
  &:focus {
    background-color: #2D6CCA !important;
  }
`;
