import React from 'react';
import styles from '../index.module.scss';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Modal} from 'antd';
import {
  faPlug,
  faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import {observer} from 'mobx-react';
import {useStore} from '@/store/root-store';
import {getLocation} from '@/utils/string';
import {NextImg} from '@/utils/nextImg';
import {openLiveChat} from '@/utils/intercom';

/*
* Wordpress Add new Site Instructions modal - opens on + Add site click
*/
export const WpAddSiteModal = observer(() => {
  const {contentOptimizer: {
    wpPublish: {
      setShowInstructionsModal,
      setShowAddSiteModal,
      showAddSiteModal,
    },
  },
  settings: {
    customer: {isGHLAccount, profile: {isWhitelabel}},
  },
  } = useStore('');


  return <Modal
    centered
    visible={showAddSiteModal}
    closable={true}
    className={styles.wpAddSiteModal}
    closeIcon={<FontAwesomeIcon icon={faXmark} onClick={() => setShowAddSiteModal(false)}/>}
    footer={null}
    zIndex={1200}
  >
    <PlugIconWrapper blueIcon={true}>
      <FontAwesomeIcon style={{fontSize: 24, color: '#fff', marginTop: '13px'}} icon={faPlug} />
    </PlugIconWrapper>
    <ModalTitle>Add any of your WordPress sites in under 30 seconds</ModalTitle>
    <ModalDesc style={{width: 340}}>
      <div style={{textAlign: 'left', marginBottom: 15, marginTop: 20}}>
        <span style={{color: '#A3A4A4', marginRight: 10}}>1.</span>
        <span>Open your</span><span style={{fontWeight: 500}}> {'WP Dashboard > Plugins > Add New'}</span>
      </div>
      <div style={{textAlign: 'left', marginBottom: 15}}>
        <span style={{color: '#A3A4A4', marginRight: 10}}>2.</span>
        <span>Search for</span><span style={{fontWeight: 500}}> {`${!isWhitelabel ? 'Linkgraph' : getLocation(location)} Meta Sync`}</span>
      </div>
      <div style={{textAlign: 'left', marginBottom: 15}}>
        <span style={{color: '#A3A4A4', marginRight: 10}}>3.</span>
        <span>Click on Install</span><span style={{fontWeight: 500}}> {'Now > Activate'}</span><span> and set up the plugin if necessary</span>
      </div>

    </ModalDesc>
    <div style={{textAlign: 'center', marginTop: 40, marginBottom: 40}}><NextImg width={402} height={240} style={{width: '100%', borderRadius: 10}} src='/img/Install_WP_Plugin.gif'/></div>
    <div style={{marginTop: 20, textAlign: 'center', color: '#a3a4a4', fontSize: 12}}>Need help installing the plugin?</div>
    {isGHLAccount ? '' : <div style={{textDecoration: 'underline', cursor: 'pointer', textAlign: 'center', color: '#fff', marginBottom: 20}} onClick={() => {
      openLiveChat();
      setShowInstructionsModal(false);
    }}>Contact Support</div>}
  </Modal>;
});

const PlugIconWrapper = styled.div<{blueIcon?: boolean}>`
  background-color: ${p => p.blueIcon ? '#375da7' : '#6e4990'};
  width: 50px;
  height: 50px;
  border-radius: 100%;
  text-align: center;
  margin: 30px auto 20px auto;
`;
const ModalTitle = styled.div`
  font-weight: 600;
  color: #fff;
  font-size: 20px;
  text-align: center;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
`;
const ModalDesc = styled.div`
  color: #fff;
  text-align: center;
  width: 320px;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 600px) {
    width: auto !important;
    white-space: normal;
  }
`;
