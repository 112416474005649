import {
  Avatar,
  Dropdown,
  Menu,
  Button,
} from 'antd';
import React, {useEffect, useState} from 'react';
import {useRouter} from 'next/router';

import {clearHomeSortFromStorage, flushTokenFromCookies} from '@/api/common-utils';
import styles from './style.module.scss';
import {
  CaretDownOutlined,
} from '@ant-design/icons';
import {Modal} from '@/components/common-components';
import {observer} from 'mobx-react-lite';
import {useStore} from '@/store/root-store';
import {routes} from '@/utils/const';

import {MixPanel} from '@/utils/mixpanel';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFileLines, faUserGroup, faArrowRightFromBracket, faCreditCard, faCodeCompare, faUser, faGear, faBagShoppingPlus} from '@fortawesome/pro-light-svg-icons';
import Link from 'next/link';
// import {isAllowedPage} from '@/utils/functions';

const UserMenu: React.FC<{name: string; email: string}> = observer(({name, email}) => {
  const router = useRouter();
  const [isVisibleRefferModal, setIsVisibleRefferModal] = useState(false);

  const {
    reportBuilder: {setreportBuilderIdAndDomain},
    gsc: {setIsTeamMember, criteria: {clearCriteria}},
    settings: {customer: {isInitialExtendedDetailsFetched, profile, isGHLAccount, profile: {
      isWhitelabel,
    }}},
  } = useStore('');

  const profileEmail = profile?.emailAddress?.split('@').reduce((acc, n)=>acc+n[0], '').toLocaleUpperCase();
  const avatarName = name.length ? name.split(' ').reduce((acc, n)=>acc+n[0], '').toLocaleUpperCase() : profileEmail;
  const isVendastaClient= profile?.isVendastaClient;

  useEffect(() => {
    const freeUiCheck = JSON.parse(localStorage.getItem('free-ui'));
    if (freeUiCheck) {
      profile.togglePlan(freeUiCheck);
    }
  }, []);

  const hideForLinkgraphUser = () => {
    return !profile?.customerSuccessManager && !profile?.isVendastaClient;
  };
  const refferalIframe = (
    <iframe src='invite-afriend.com' id='refferFrame' className={styles.refferalIframe} />
  );


  const renderMenuContent = () => {
    const eleId = document.getElementById('customerToken') as any;
    const onLogout = () => {
      localStorage.removeItem('whitelabelOtto');
      if (!eleId?.value) {
        flushTokenFromCookies();
        localStorage.removeItem('currentCustomerId');
        setreportBuilderIdAndDomain('');
        localStorage.removeItem('reportBuilderIdAndDomain');
      }
      localStorage.removeItem('currentCustomerId');
      setreportBuilderIdAndDomain('');
      localStorage.removeItem('reportBuilderIdAndDomain');
      localStorage.removeItem('hideGscBanner');
      clearCriteria();
      localStorage.removeItem('onboardingData');
      clearHomeSortFromStorage();
      localStorage.removeItem('meetingBanner');
      MixPanel.reset();
      router.push('/login');
    };
    // const getColor = ()=>{
    //   switch (serviceTier?.toLowerCase()) {
    //     case 'silver':
    //       return '#FFFFFF';
    //     case 'gold':
    //       return '#CB9B54';
    //     case 'diamond':
    //       return '#9FF4FF';
    //     case 'bronze':
    //       return '#CB9B54';
    //     default:
    //       return '#E8E8E8';
    //   }
    // };

    // const isAppEcoSystem = () => {
    //   if (!profile?.isWhitelabel || (profile?.isWhitelabel && profile?.isParentAccount)) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // };

    return (
      <Menu>
        <div style={{display: 'flex', alignItems: 'center', gap: '9px', padding: '10px 16px 11px 16px', fontSize: '12px', lineHeight: '14px', justifyContent: 'space-between'}}>
          <div><p style={{margin: '0', fontSize: '14px', maxWidth: 165, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{name}</p><p style={{margin: '0', color: '#4E5156', maxWidth: 165, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{email}</p></div>
          <Avatar>{avatarName}</Avatar>
        </div>
        {isVendastaClient && !isGHLAccount ? '' : <Menu.Item key='settings'>
          <Button type='link' className={styles.menuBtn}
            onClick={() => {
              const currentQuery = {...router.query};
              if (!currentQuery['active_modal']) {
                currentQuery['active_modal'] = 'settings';
                currentQuery['active_section'] = isGHLAccount ? 'integration' : 'profile';
                router.replace({
                  pathname: router.pathname,
                  query: currentQuery,
                }, undefined, {shallow: true});
              }
            }}>
            <a style={{display: 'flex', alignItems: 'center'}}>
              <span className={styles.iconWrapper}>
                <FontAwesomeIcon icon={faGear} style={{color: '#000'}} />
              </span>
              <span style={{fontSize: '14px', paddingLeft: '5px'}}>{'Settings'}</span>
            </a>
          </Button>
        </Menu.Item>}
        {isGHLAccount ? '' : !profile?.isParentAccount ?
          (!isInitialExtendedDetailsFetched || profile?.isViewer || isVendastaClient || (isWhitelabel && !profile.isParentAccount && !profile.shouldShowOrdersPage) ? null : <Menu.Item key='my-order'>
            <Button type='link' className={styles.menuBtn}>
              <Link legacyBehavior style={{display: 'flex', alignItems: 'center'}} href={`/${routes.orders.default}`} passHref >
                <a ><span className={styles.iconWrapper}>
                  <FontAwesomeIcon icon={faFileLines} style={{color: '#000'}} />
                </span>
                <span style={{fontSize: '14px'}}>{'Billing'}</span></a>
              </Link>
            </Button>
          </Menu.Item>) :
          (!isInitialExtendedDetailsFetched || profile?.isViewer|| isVendastaClient || (isWhitelabel && !profile.isParentAccount && !profile.shouldShowOrdersPage) ? null : <Menu.Item key='my-order'>
            <Button type='link' className={styles.menuBtn}>
              <Link legacyBehavior style={{display: 'flex', alignItems: 'center'}} href={`/${routes.orders.default}`} passHref >
                <a ><span className={styles.iconWrapper}>
                  <FontAwesomeIcon icon={faFileLines} style={{color: '#000'}} />
                </span>
                <span style={{fontSize: '14px', paddingLeft: '5px'}}>{'My Orders'}</span></a>
              </Link>
            </Button>
          </Menu.Item>)}
        {isGHLAccount ? '' : <>
          {isVendastaClient ? '' : !profile?.isParentAccount ?
            profile?.isVendastaClient || !isInitialExtendedDetailsFetched || profile?.isViewer || (isWhitelabel && !profile.isParentAccount && !profile.shouldShowBillingPage) ? '' :
              <Menu.Item key='billing'>
                <Button type='link' className={styles.menuBtn}>
                  <Link style={{display: 'flex', alignItems: 'center'}} href={`/${routes.billing}`} passHref>
                    <span className={styles.iconWrapper}>
                      <FontAwesomeIcon icon={faCreditCard} style={{color: '#000'}} />
                    </span>
                    <span style={{fontSize: '14px', paddingLeft: '5px'}}>{'Billing'}</span>
                  </Link>
                </Button>
              </Menu.Item> :
            profile?.isVendastaClient || !isInitialExtendedDetailsFetched || profile?.isViewer || (isWhitelabel && !profile.isParentAccount && !profile.shouldShowBillingPage) ? '' :
              <Menu.Item key='billing'>
                <Button type='link' className={styles.menuBtn}>
                  <Link style={{display: 'flex', alignItems: 'center'}} href={`/${routes.billing}`} passHref>
                    <span className={styles.iconWrapper}>
                      <FontAwesomeIcon icon={faCreditCard} style={{color: '#000'}} />
                    </span>
                    <span style={{fontSize: '14px', paddingLeft: '5px'}}>{'Billing'}</span>
                  </Link>
                </Button>
              </Menu.Item>}
          {isVendastaClient && !isGHLAccount ? '' : <Menu.Item key='team-members'>
            <Button type='link' className={styles.menuBtn} onClick={() => {
            // router.push(`${location.pathname}?active_tab=team-members`);
              setIsTeamMember(true);
              // setDrawerVisibility(true);
              const currentQuery = {...router.query};
              if (!currentQuery['active_modal']) {
                currentQuery['active_modal'] = 'settings';
                currentQuery['active_section'] = 'team';
                router.replace({
                  pathname: router.pathname,
                  query: currentQuery,
                }, undefined, {shallow: true});
              }
            }}>
              <a style={{display: 'flex', alignItems: 'center'}}>
                <span className={styles.iconWrapper}>
                  <FontAwesomeIcon icon={faUserGroup} style={{color: '#000'}} />
                </span>
                <span style={{fontSize: '14px', paddingLeft: '5px'}}>{'Team Members'}</span>
              </a>
            </Button>
          </Menu.Item>}
          {hideForLinkgraphUser() ? <Menu.Item key='app-ecosystem'>
            <Button type='link' className={styles.menuBtn}>
              <Link legacyBehavior style={{display: 'flex', alignItems: 'center'}} href={`/${routes.appsEcoSystem}`} passHref>
                <a><span className={styles.iconWrapper}>
                  <FontAwesomeIcon icon={faCodeCompare} style={{color: '#000'}} />
                </span>
                <span style={{fontSize: '14px', paddingLeft: '5px'}}>{'App Ecosystem'}</span></a>
              </Link>
            </Button>
          </Menu.Item> : ''}
          {!profile.isWhitelabel && profile.isParentAccount && <Menu.Item key='order-builder'>
            <Button type='link' className={styles.menuBtn}>
              <Link legacyBehavior style={{display: 'flex', alignItems: 'center'}} href={`/${routes.orderBuilder}`} passHref>
                <a><span className={styles.iconWrapper}>
                  <FontAwesomeIcon icon={faBagShoppingPlus} style={{color: '#000'}} />
                </span>
                <span style={{fontSize: '14px', paddingLeft: '5px'}}>{'Order Builder'}</span></a>
              </Link>
            </Button>
          </Menu.Item>}
          {isVendastaClient && !isGHLAccount ? '' : <Menu.Item key='logout' style={{marginBottom: '5px'}}>
            <Button type='link' className={styles.menuBtn} onClick={onLogout}>
              <a style={{display: 'flex', alignItems: 'center'}}>
                <span className={styles.iconWrapper}>
                  <FontAwesomeIcon icon={faArrowRightFromBracket} style={{color: '#000'}} />
                </span>
                <span style={{fontSize: '14px', paddingLeft: '5px'}}>{eleId?.value ? 'Logout customer' : 'Log Out'}</span>
              </a>
            </Button>
          </Menu.Item>}
        </>}
      </Menu>
    );
  };
  return (
    <>
      <Dropdown
        overlay={renderMenuContent}
        trigger={['click']}
        className={styles.dropdown}
        overlayClassName={`${styles.menu} ${styles.dropdownOffset}`}
        placement='bottomRight'
      >
        <div>
          <input type='hidden' name='logedInUserEmail' id='logedInUserEmail' value={email} />
          <input type='hidden' name='logedInUserFullName' id='logedInUserFullName' value={name} />
          {/* <Avatar>{avatarName}</Avatar>{' '} */}
          <FontAwesomeIcon icon={faUser} style={{height: '12px', margin: '-5px 3px 0 10px'}} />
          <span style={{fontSize: '11px', color: 'e8e8e8'}}>Account</span>
          <span className={styles['down-icon']}><CaretDownOutlined /></span>
          <Modal visible={isVisibleRefferModal} onClose={() => setIsVisibleRefferModal(false)}>
            {refferalIframe}
          </Modal>
        </div>
      </Dropdown>
    </>
  );
});

export default UserMenu;
