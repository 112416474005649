import {observer} from 'mobx-react';
import React, {useState, useEffect} from 'react';
import {Dropdown, notification} from 'antd';
import {useRouter} from 'next/router';
import {Modal} from '@/components/common-components/components/modal';
import {Button, CountryDropdown} from '@/components/common-components/v2';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import {useStore} from '@/store/root-store';
import {toJS} from 'mobx';
import styled from 'styled-components';
import {integrationApi} from '@/api/gsc';
import {MixPanel} from '@/utils/mixpanel';
import {PROJECTS_GSC_AUTHORIZE_CLICKED} from '@/constants/events';
import {ProjectSelector} from '../../../report-builder/components/projectSelector/ProjectSelector';
import {SkeletonHorizontalLoaderGray} from '@/components/common-components';
import {getDomain, getSingleUrlParam} from '@/utils/url';
import GoogleAnalyticsProjectModalContent from '../../../report-builder/components/googleAnalyticsProjectModalContent';
import {faBoxArchive, faCircleInfo} from '@fortawesome/pro-duotone-svg-icons';
import {faCircleXmark} from '@fortawesome/pro-solid-svg-icons';
import FreezeWrapper from '../../../otto-page-detail/Component/freezTooltip';
import {FreezeBanner} from '../../../otto-page-detail/Component/ottoHeaderV2';

interface Props{
  openGscModal?: boolean;
  closeGscModal?: (value) =>void;
  loading?: boolean;
  reCallTool?: ()=>void;
  callGscModalApi?: boolean;
}

export const GscConnectModal: React.FC<Props> = observer(({openGscModal, closeGscModal, loading, callGscModalApi}) => {
  const {navBar, navBar: {
    setIsActivatePropertiesDrawerOpen,
    propertiesData,
  }, gsc: {criteria, googleAnalytics: {
    gaDataModal,
    setGaLoading,
    gaLoading,
    setGaDataModal,
  }}, ottoV2Store: {loadOttoV2Projects, connectGBPOtto, setOttoIdForGscSettings, ottoIdForGscSettings}} = useStore('');
  const {
    reportBuilder: {details: {loadReportBuilderGAList, getReportsGAList}},
    ottoV2Store: {getOttoV2Project, loadOttoV2Project},
    siteAuditorV2: {
      checkSpecificIDResponse,
      setGscOpenModalValue,
      siteAuditConnectGsc,
      auditedSitesList,
      stopLoadProjectApi,
      setStopLoadProjectApi,
      loadAuditedSitesList,
    }} = useStore('');
  const {
    settings: {customer: {profile: {address}}, integration},
  } = useStore('');
  const {reportBuilder: {details: {
    getReportsGSCList,
    loadReportBuilderGSCList,
  }}} = useStore('');
  const router = useRouter();
  const path = router.pathname;
  const uuid = getSingleUrlParam('uuid');
  const [siteProperty, setSiteProperty] = useState(criteria.property);
  const [country, setCountry] = useState(gaDataModal?.countryCode === null ? '' : gaDataModal?.countryCode);
  const [gaSitesList, setGASitesList] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [gscPropertyUpdated, setGscPropertyUpdated] = useState(false);
  const [isGscModal, setIsGscModal] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState<any>({
    name: null,
    id: null,
  });
  const currentProperty = navBar.getSiteProperty(criteria.property);
  const propertiesDataForSA = toJS(propertiesData).filter(item => item?.activeForCurrentCustomer);
  const availableCountryData = toJS(currentProperty?.availableCountryCodes)?.filter(code => currentProperty?.activeCountryCodes?.includes(code) || code == country || code == address?.defaultLocation);
  const domainSiteUrl = getDomain(gaDataModal.siteUrl);
  const formatSiteUrl = domainSiteUrl?.startsWith('http://') || domainSiteUrl?.startsWith('https://') ? domainSiteUrl : `http://${domainSiteUrl}`;
  useEffect(() => {
    if (!gaDataModal?.saProjectId && toJS(auditedSitesList)?.length) {
      setGaDataModal({
        ...gaDataModal,
        saProjectId: toJS(auditedSitesList)[0]?.id,
      });
    }
  }, [gaDataModal, auditedSitesList]);
  const onGSCConnect = async () => {
    if (gaDataModal?.saProjectId) {
      let payloadData = {};
      if (selectedProperty?.id?.length || (siteProperty?.length && siteProperty !== 'disconnect')) {
        payloadData = {
          ...((siteProperty !== 'disconnect' && siteProperty?.length) && {property_url: siteProperty}),
          ga_property_id: selectedProperty?.id?.length ? selectedProperty?.id : null,
          country_code: selectedProperty?.id || siteProperty?.length ? country === 'Worldwide' ? '' : country : null,
        };
      }
      if (isOttoV2Page()) {
        payloadData = {
          service: 'gsc',
          property_url: siteProperty === null ? null : siteProperty,
          country_code: siteProperty === null ? null : country === 'Worldwide' ? '' : country,
        };
      }
      const isUpdatingGSC = siteProperty !== 'disconnect' && siteProperty?.length && gscPropertyUpdated;
      if (isOttoV2Page()) {
        await connectGBPOtto(uuid || ottoIdForGscSettings, payloadData, false, stopLoadProjectApi);
        setOttoIdForGscSettings('');
        setStopLoadProjectApi(false);
      } else {
        await siteAuditConnectGsc(gaDataModal?.saProjectId, payloadData);
      }
      if (selectedProperty?.id?.length && isUpdatingGSC) {
        notification.success({placement: 'topRight', message: `GA/GSC Settings Updated successfully.`});
      } else if (selectedProperty?.id?.length || isUpdatingGSC) {
        notification.success({placement: 'topRight', message: `${selectedProperty?.id?.length ? 'GA' : 'GSC'} Property Updated.`});
      } else {
        notification.success({placement: 'topRight', message: `Google Integration Settings Updated.`});
      }
      if (router?.pathname === '/site-audit/list') {
        await loadAuditedSitesList();
      } else if (isOttoV2Page()) {
        uuid && await loadOttoV2Project(uuid);
        loadOttoV2Projects();
      } else {
        window.location.reload();
      }
      setGscOpenModalValue(false);
    }
  };
  useEffect(()=> {
    loadReportBuilderGSCList(country);
  }, [country]);

  const getInitialSetupHandler = async () => {
    setGaLoading(true);
    const res = await loadReportBuilderGAList();
    if (res) {
      const selectedProp = res?.find(item => item.hostname == gaDataModal?.siteUrl);
      if (gaDataModal?.saProjectId) {
        const response = await checkSpecificIDResponse(gaDataModal?.saProjectId);
        if (response?.gscContext) {
          setCountry([null, undefined].includes(response?.gscContext?.countryCode) ? (address?.defaultLocation?.toUpperCase() || 'Worldwide') : response?.gscContext?.countryCode?.toUpperCase() || 'Worldwide');
          setSiteProperty(response?.gscContext?.propertyUrl);
          setSelectedProperty({
            name: selectedProp?.hostname,
            id: response?.gscContext?.gaPropertyId,
          });
        }
      } else if (gaDataModal?.settingId) {
        setSelectedProperty({
          name: selectedProp?.hostname,
          id: gaDataModal?.settingId,
        });
      }
    }
    setGaLoading(false);
  };
  const selectPropertiesHandler = async value => {
    if (value) {
      const selectedProp = gaSitesList?.find(item => item.propertyId === value);
      setSelectedProperty({
        name: selectedProp?.hostname,
        id: value,
      });
    } else {
      setSelectedProperty(null);
    }
  };
  const onModalPropertyChange = value => {
    setGscPropertyUpdated(true);
    setSiteProperty(value);
    setIsGscModal(false);
  };
  const onCountryChange = value => {
    if (value === null) {
      setSiteProperty(null);
    } else {
      setCountry(value);
    }
  };

  useEffect(()=>{
    if (!propertiesDataForSA?.length && openGscModal) {
      integration.loadGoogleProfiles();
      // navBar.loadSiteProperties();
    }
    if (openGscModal) {
      if (callGscModalApi) {
        getInitialSetupHandler();
      }
    } else {
      setGscOpenModalValue(false, 'GSC Setting', false, '', true);
    }
  }, [openGscModal]);


  const onGASearchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e?.target?.value?.toLowerCase();
    setInputValue(value);
    if (value) {
      setGASitesList(getReportsGAList.filter(option => option?.hostname?.toLowerCase()?.includes(value) || option?.name?.toLowerCase()?.includes(value)));
    } else {
      setGASitesList(getReportsGAList);
    }
  };
  useEffect(()=> {
    setGASitesList(getReportsGAList);
  }, [getReportsGAList]);

  const isOttoV2Page = () => {
    return path === '/otto-page-v2' || path === '/otto-page-v2/tasks';
  };
  const onOpenGSCDropdown = () => {
    setIsGscModal(!isGscModal);
  };

  return (
    <div>
      <StyledModal
        visible={openGscModal}
        width={550}
        onClose={closeGscModal}
        zIndex={10}
      >
        {(getOttoV2Project?.isFrozen && isOttoV2Page()) ? <FreezeBanner style={{padding: 8, height: 50, marginTop: 12}}>
          <FontAwesomeIcon icon={faBoxArchive} color='#F1AA3E' />
          <div className='txt' style={{color: 'rgba(0, 0, 0, 0.85)'}}>
             The changes on this site are frozen. You will be unable to make any modifications.
          </div>
        </FreezeBanner> : ''}
        <div>
          <div><p style={{fontSize: 20, fontWeight: 600, marginBottom: 2}}>{'Google Integration Settings'}</p></div>
          <div><Url onClick={() => window.open(formatSiteUrl, '_blank')}>{gaDataModal.siteUrl}</Url></div>
          <CountryWrapper style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px', flexWrap: 'wrap'}}>
            <p style={{fontSize: 14, color: '#4E5156', margin: '0'}}>Location</p>
            <div className='dropdown-wrapper'>
              <StyledCountryDropdown
                countriesToInclude={availableCountryData}
                defaultValue={'US'}
                aligned={true}
                variant='grey'
                isReport={true}
                suffixIcon={<FontAwesomeIcon color='#121212' icon={faCaretDown} />}
                width={'300px'}
                className={'selectInput'}
                value={country}
                onChange={onCountryChange}
                gscConnectModal={true}
              />
            </div>
          </CountryWrapper>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px', flexWrap: 'wrap', rowGap: 8}}>
            <p style={{fontSize: 14, margin: 0, color: '#4E5156'}}>GSC Property</p>
            {!gaLoading ?
              <DropdownWrapper
                visible={isGscModal}
                onVisibleChange={()=> setIsGscModal(!isGscModal)}
                getPopupContainer={trigger => trigger}
                disabled={country === null}
                overlay={
                  <GoogleAnalyticsProjectModalContent
                    sitePropertiesList={getReportsGSCList || []}
                    isOtto={true}
                    countryCode={country === null ? '' : country}
                    onProjectChange={onModalPropertyChange}
                    selectedGSCProject={siteProperty === null ? '' : siteProperty}
                    selectedCountry={country}
                    title={'Google Search Console Domains'}
                  />
                }
                overlayStyle={{zIndex: 10, background: '#fff', width: '400px', borderRadius: '8px'}} trigger={['click']}>
                <SelectGSCStyled onClick={() => country === null ? onOpenGSCDropdown() : {}}><div onClick={onOpenGSCDropdown}>{siteProperty ? siteProperty : 'Select Property'}</div><div>{siteProperty !== null ? <FontAwesomeIcon onClick={() => onCountryChange(null)} icon={faCircleXmark} color='#A0A0A0' /> :
                  <FontAwesomeIcon onClick={onOpenGSCDropdown} color='#121212' icon={faCaretDown} />}</div></SelectGSCStyled>
              </DropdownWrapper> : <SkeletonHorizontalLoaderGray lightLoader height={'38px'} skeletonHeight={'12px'} width={200}/>}
          </div>
          <span style={{display: 'flex', justifyContent: 'flex-end'}}>
            <FreezeWrapper removeTooltip={!router?.pathname?.includes('otto-page-v2')}>
              <ConnectToGSCWrapper onClick={() => {
                MixPanel.track(PROJECTS_GSC_AUTHORIZE_CLICKED);
                location.href=integrationApi.getGoogleConnectUrl(gaDataModal?.fromOttoUuidParam || '');
              }}>+ Connect new GSC account</ConnectToGSCWrapper>
            </FreezeWrapper>
          </span>
          {!isOttoV2Page() && <>
            <SingleSection>
              <SectionLabel>GA4 Property</SectionLabel>
              <SectionAction>
                {!gaLoading ?
                  <StyledProjectSelector property={'GA'} keyValue={'propertyId'} searchValue={inputValue} selectedId={selectedProperty?.id?.length ? selectedProperty?.id : 'Select property'} onProjectChange={selectPropertiesHandler}
                    dropDownWidth={'300px'} isReportBuilder={true} sitePropertiesList={gaSitesList} onSearch={onGASearchHandler}/> : <SkeletonHorizontalLoaderGray lightLoader skeletonHeight={'12px'} width={200}/>}
              </SectionAction>
            </SingleSection>
            <span style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '10px'}}>
              <FreezeWrapper removeTooltip={!router?.pathname?.includes('otto-page-v2')}>
                <ConnectToGSCWrapper
                  onClick={() => window.open(integrationApi.getGoogleAnalyticsConnectUrl(), '_self')}>
          + Connect new GA 4 account
                </ConnectToGSCWrapper>
              </FreezeWrapper></span></>}
          {isOttoV2Page() && (
            <Banner>
              <FontAwesomeIcon color='#2D6CCA' icon={faCircleInfo}/>
              <div>Only active Google Search Console (GSC) properties are displayed. If a website you need is missing, <FreezeWrapper removeTooltip={!router?.pathname?.includes('otto-page-v2')}><a style={{color: '#2D6CCA'}} onClick={setIsActivatePropertiesDrawerOpen}>click here to locate and activate it.</a></FreezeWrapper></div>
            </Banner>
          )}
          <ButtonWrapper>
            <Button
              style={{border: '1px solid #e8e8e8'}}
              onClick={closeGscModal}
              color='white'
              textColor='#4E5156'>
              Cancel

            </Button>
            <FreezeWrapper removeTooltip={!router?.pathname?.includes('otto-page-v2')}>
              <Button
                onClick={onGSCConnect}
                loading={loading}>
                Update
              </Button>
            </FreezeWrapper>
          </ButtonWrapper>
        </div>
      </StyledModal>
    </div>
  );
},
);

const DropdownWrapper = styled(Dropdown)`
    .ant-dropdown{
      top: 196px !important;
    }
`;
const ButtonWrapper = styled.div`
  border-top: 1px solid #e8e8e8;
  padding-top: 20px;
  display:flex;
  justify-content: right;
  align-items: center;
`;

const Banner = styled.div`
  background: rgba(45, 108, 202, 0.2);
  padding: 8px 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 12px 0px;
  font-size: 12px;
  @media screen and (max-width: 1250px) {
    flex-wrap: wrap;
    row-gap: 0;
  }
`;

const SelectGSCStyled = styled.div`
height: 44px;
cursor: pointer;
align-items: center;
justify-content: space-between;
display: flex;
width: 61%;
padding: 11px;
border: 1px solid #e0e0e0;
border-radius: 8px;
`;
const StyledProjectSelector = styled(ProjectSelector)`
  @media screen and (max-width: 430px) {
    .ant-select-selector {
      width: 100% !important;
    }
  }
`;

const StyledModal = styled(Modal)`
  @media screen and (max-width: 560px) {
    .rc-dialog-body {
      padding: 15px !important;
    }
  }
`;

const ConnectToGSCWrapper = styled.span`
margin-top: 4px;
font-size : 12px;
cursor: pointer;
color : #2D6CCA;
`;
const Url = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #2D6CCA;
  cursor: pointer;
  max-width: 235px;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledCountryDropdown = styled(CountryDropdown)`
  @media screen and (max-width: 430px) {
    width: 100% !important;
  }
`;

const SingleSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  flex-wrap: wrap;
  row-gap: 8px;
`;
const SectionLabel = styled.div`
  color: #4E5156;
  display: flex;
  align-items: center;
`;
const CountryWrapper = styled.div`
  @media screen and (max-width: 480px) {
    .dropdown-wrapper {
      min-width: 300px;
      width: 100%;
    }
  }
`;
const SectionAction = styled.div`
  height: 38px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 430px) {
    width: 100% !important;
    .ant-select-selector {
      width: 100% !important;
    }
    .ant-select {
      width: 100% !important;
    }
  }
`;
