import styled from 'styled-components';
import {useStore} from '@/store/root-store';
import React, {useState, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useRouter} from 'next/router';
import {observer} from 'mobx-react-lite';
import {Modal} from '@/components/common-components/components';
import {Button} from '@/components/common-components/v2/Button';
import {faBook, faCircleInfo} from '@fortawesome/pro-regular-svg-icons';
import {openUrl} from '@/utils/router';
import {SettingsModal} from './Component/settings-modal';
import {NewQuotaDropDown} from '@/components/common-components/components/new-quote-dropdown';
import {TopupModal} from '../otto-page-detail/Modals/topupModal';
import {planApi} from '@/api/account';
import {getBlockedOttoSitesTooltip, getHyperDriveTooltip} from '../otto-page-detail/Component/contentDetailV2';
import WidgetModal from './add-site-modal';
import {VideoWrapper} from '@/components/common-components/components/videos-wrapper';
import {ImportBusinessModalV2} from '../otto-page-detail/Modals/importBusinessModal';
import {ProjectsTable} from './projectsTable';
import {Tooltip} from 'antd';
import {getSaUrl} from '@/api/gsc/utils';
import {headerStore} from '@/store/storeCompTest';

interface Props {
  isCompleted?: boolean;
}
export const OttoV2Main:React.FC<Props> = observer(() => {
  const {
    ottoV2Store: {
      loadOttoV2Projects,
      setProjectListShouldRepoll,
      loadOttoV2Project,
      setDefaultParams,
      defaultParams,
      getOttoV2Project,
      resetOttoV2Project,
      quotaAllocations,
      getOttoQuotaAllocations,
      setOttoIdForGscSettings,
      getOttoPlanQuotaAllocations,
      getBlockedQuotas,
      blockedQuotas,
      getPaymentTopups,
      paymentTopups,
    },
    siteAuditorV2: {
      loadAuditedSitesList,
      params,
      setParams,
      setGscOpenModalValue,
      setStopLoadProjectApi,
      openGscModal,
    },
    settings: {customer: {getCustomerQuota, profile: {whitelabelOtto, quotaUtilization, isWhitelabel, nextPaymentAt, isParentAccount, addons, setTopupPrices, isLinkgraph}}},
    gsc: {googleAnalytics: {setGaDataModal, gaDataModal, getGMBData, getGMBSetting}, criteria},
  } = useStore('');

  const {reportBuilder: {details: {
    loadReportBuilderGSCList,
  }}} = useStore('');
  const [gbpModalVisible, setGbpModalVisible] = useState(false);
  const [dropDownOpen, setDropDownOpen] = useState(false);

  const [shouldOpenGBP, setShouldOpenGBP] = useState(false);
  const [connectGMBLoader, setConnectGMBLoader] = useState(-1);
  const router = useRouter();
  const [isWidgetModalVisible, setIsWidgetModalVisible] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [uuid, setUuid] = useState('');
  const [isInstructionModalVisible, setIsInstructionModalVisible] = useState(false);
  const [selectedConnectingProject, setSelectedConnectingProject] = useState(null);
  const [connectGSCLoader, setConnectGSCLoader] = useState(-1);
  const [hyperDriveRecurring, setHyperDriveRecurring] = useState<'yes' | 'no'>('no');
  const [instructionsData, setInstructionsData] = useState<{
    domainToOpen: string;
    uuid?: string;
  }>(null);
  const [topUpBulkVisibility, setTopUpBulkVisibility] = useState({
    visibility: false,
    price: 0,
    title: '',
    options: [],
    consumed: 0,
    total: 0,
    recurring: '',
    quotaKey: '',
    desc: '',
    buttonTxt: '',
    overAllQuota: 0,
    isAiGeneration: false,
  });

  const getOttoPlans = async () => {
    const response = await planApi.getPlans({is_addon: 'True'});
    if (response?.isCancel) {
      return false;
    }
    const activeAddon = addons?.length > 0 && addons[addons?.length - 1];
    const selectedPlan = response?.find(plan => plan?.id == activeAddon?.planId);
    if (selectedPlan) {
      setTopupPrices(
        {
          topupHyperdriveCreditCost: selectedPlan?.topupHyperdriveCreditCost,
          topupOttoAiQuotaCost: selectedPlan?.topupOttoAiQuotaCost,
          topupOttoPageCost: selectedPlan?.topupOttoPageCost,
          topupOttoProjectCost: selectedPlan?.topupOttoProjectCost,
        },
      );
    }
  };

  useEffect(() => {
    getPaymentTopups();
    getBlockedQuotas();
  }, []);

  useEffect(() => {
    if (addons?.length) getOttoPlans();
  }, [addons?.length]);

  const getTopupQuotaConf = key => {
    return quotaAllocations?.find(i => i?.quotaKey === key);
  };

  // useEffect(()=> {
  //   debugger
  //   country && loadReportBuilderGSCList(country);
  // }, [country]);
  const getSummedHyperDriveQuotaValues = key => {
    const quotaObj = quotaUtilization?.ca?.hyperdriveCredits;
    return (quotaUtilization?.ca?.hyperdriveCreditsPurchased[key] ?? 0) + (quotaObj[key] ?? 0);
  };
  const getHyperDriveObj = () => isLinkgraph ? getTopupQuotaConf('quota_ca_allowed_otto_hyperdrive') : getTopupQuotaConf('quota_ca_hyperdrive_credits');
  const hyperDriveQuotaKey = 'hyperdriveCredits';
  const paymentTopup = paymentTopups?.find(i => i?.quotaKey === 'quota_ca_allowed_otto_v2_projects');

  const quotaArray = [
    {
      quotaTitle: `${whitelabelOtto} Sites`,
      available: quotaUtilization?.ca?.allowedOttoV2Projects?.total - quotaUtilization?.ca?.allowedOttoV2Projects?.consumed,
      used: quotaUtilization?.ca?.allowedOttoV2Projects?.consumed,
      total: quotaUtilization?.ca?.allowedOttoV2Projects?.total,
      quotaInfoTooltip: blockedQuotas?.length ? getBlockedOttoSitesTooltip(blockedQuotas) : '',
      quotaInfoTooltipWidth: 470,
      topupConf: {
        price: getTopupQuotaConf('quota_ca_allowed_otto_v2_projects')?.pricePerUnit || 0,
        title: `${whitelabelOtto} Sites`,
        options: ['price', 'AI_generation', 'hyperdrive'],
        recurring: getTopupQuotaConf('quota_ca_allowed_otto_v2_projects')?.topOptions[0],
        quotaKey: getTopupQuotaConf('quota_ca_allowed_otto_v2_projects')?.quotaKey,
        desc: 'per site per month',
        nextPaymentAt,
        buttonTxt: `${whitelabelOtto} site`,
        showSlider: true,
        siteDesc: 'Get more from OTTO Sites and enjoy bigger discounts with each additional site quota you secure.',
        minLimit: 1,
        buttonLabel: 'site',
        priceLabel: 'per site',
        showPriceOnButton: false,
        showDiscount: true,
        prefillTopupPoints: paymentTopup ? (quotaUtilization?.ca?.allowedOttoV2Projects?.total + paymentTopup?.quotaToBeAddedOnNextBilling) - paymentTopup?.quotaToBeRemovedOnNextBilling : quotaUtilization?.ca?.allowedOttoV2Projects?.total,
      },
    },
    {
      quotaTitle: `${whitelabelOtto} HyperDrive`,
      available: getSummedHyperDriveQuotaValues('total') - getSummedHyperDriveQuotaValues('consumed'),
      used: getSummedHyperDriveQuotaValues('consumed'),
      total: getSummedHyperDriveQuotaValues('total'),
      quotaInfoTooltip: getHyperDriveTooltip(
        [
          {used: quotaUtilization?.ca?.[hyperDriveQuotaKey]?.consumed, total: quotaUtilization?.ca?.[hyperDriveQuotaKey]?.total, label: 'Recurring'},
          {used: quotaUtilization?.ca?.hyperdriveCreditsPurchased?.consumed, total: quotaUtilization?.ca?.hyperdriveCreditsPurchased?.total, label: 'Non-Recurring'},
        ],
      ),
      topupConf: {
        price: (hyperDriveRecurring === 'yes' || isLinkgraph) ? (getHyperDriveObj()?.pricePerUnit || 0) * 120 : 1,
        title: `${whitelabelOtto} HyperDrive`,
        options: isLinkgraph ? ['price', 'current_quota', 'used_card', 'recurring_payment'] : ['quantity', 'current_quota', 'used_card', 'recurring_payment'],
        recurring: (hyperDriveRecurring === 'yes' || isLinkgraph) ? getHyperDriveObj()?.topOptions[0] : 'purchased',
        quotaKey: getHyperDriveObj()?.quotaKey,
        desc: isLinkgraph ? 'per 100 credits' : hyperDriveRecurring === 'yes' ? 'per 120 credits per month' : 'per 1 credit',
        nextPaymentAt,
        buttonTxt: 'credit',
        step: isLinkgraph ? 100 : hyperDriveRecurring === 'yes' ? 120 : 1,
        multiplesOfStep: (hyperDriveRecurring === 'yes' || isLinkgraph) ? true : false,
        unitIsMultipleOfStep: (hyperDriveRecurring === 'yes' || isLinkgraph) ? true : false,
        recurringIsOptional: !isLinkgraph,
        recurringValue: hyperDriveRecurring,
        setRecurringValue: setHyperDriveRecurring,
        wholeCredits: true,
        showSlider: true,
        siteDesc: 'Used for Press Releases and Cloud Stack services.',
      },
    },
    {
      quotaTitle: 'AI Premium Quota',
      available: (quotaUtilization?.ca?.allowedAiPremiumContentGeneration?.total + Number(quotaUtilization?.ca?.purchasedPremiumAiQuota?.total || 0)) - (quotaUtilization?.ca?.allowedAiPremiumContentGeneration?.consumed + Number(quotaUtilization?.ca?.purchasedPremiumAiQuota?.consumed)),
      used: quotaUtilization?.ca?.allowedAiPremiumContentGeneration?.consumed + Number(quotaUtilization?.ca?.purchasedPremiumAiQuota?.consumed),
      total: (quotaUtilization?.ca?.allowedAiPremiumContentGeneration?.total + Number(quotaUtilization?.ca?.purchasedPremiumAiQuota?.total || 0)),
      topupConf: {
        price: 1,
        title: 'AI Premium Quota',
        options: isLinkgraph ? ['price', 'current_quota', 'used_card', 'recurring_payment'] : ['quantity', 'current_quota', 'used_card', 'recurring_payment'],
        recurring: '',
        quotaKey: 'allowed_ai_premium_content_generation',
        desc: 'per article',
        buttonTxt: 'point',
        overAllQuota: (quotaUtilization?.ca?.allowedAiPremiumContentGeneration?.total + Number(quotaUtilization?.ca?.purchasedPremiumAiQuota?.total)),
      },
      purchasedQuota: quotaUtilization?.ca?.purchasedPremiumAiQuota?.total,
    },
    {
      quotaTitle: 'AI Generation',
      available: quotaUtilization?.ca?.allowedOttoAiSuggestions?.total - quotaUtilization?.ca?.allowedOttoAiSuggestions?.consumed,
      used: quotaUtilization?.ca?.allowedOttoAiSuggestions?.consumed,
      total: quotaUtilization?.ca?.allowedOttoAiSuggestions?.total,
      topupConf: {
        price: getTopupQuotaConf('quota_ca_allowed_otto_ai_suggestions')?.pricePerUnit * 500 || 0,
        title: 'AI Generation Quota',
        options: isLinkgraph ? ['price', 'current_quota', 'used_card', 'recurring_payment'] : ['quantity', 'current_quota', 'used_card', 'recurring_payment'],
        recurring: getTopupQuotaConf('quota_ca_allowed_otto_ai_suggestions')?.topOptions[0],
        quotaKey: getTopupQuotaConf('quota_ca_allowed_otto_ai_suggestions')?.quotaKey,
        desc: 'per 500 points',
        buttonTxt: 'AI Generation',
        overAllQuota: quotaUtilization?.ca?.allowedOttoAiSuggestions?.total,
        nextPaymentAt,
        step: 500,
        multiplesOfStep: true,
        unitIsMultipleOfStep: true,
        wholeCredits: true,
        showSlider: true,
      },
    },
  ];
  useEffect(() => {
    if (topUpBulkVisibility?.visibility) {
      const item: any = quotaArray?.find(item => item?.quotaTitle === `${whitelabelOtto} HyperDrive`);
      setTopUpBulkVisibility({
        ...item?.topupConf,
        visibility: true,
        consumed: item?.used,
        total: item?.total,
      });
    }
  }, [hyperDriveRecurring]);

  useEffect(() => {
    const now = new Date().getTime();
    loadAuditedSitesList(false, false, now);
    getOttoQuotaAllocations();
    getOttoPlanQuotaAllocations();
  }, []);

  useEffect(() => {
    if (!openGscModal) {
      setSelectedConnectingProject(null);
    }
  }, [openGscModal]);

  useEffect(() => {
    if (getOttoV2Project === null && selectedConnectingProject !== null && !selectedConnectingProject?.is_gbp) {
      loadOttoV2ProjectFromApi(selectedConnectingProject);
    } else if (getOttoV2Project && getOttoV2Project?.uuid === selectedConnectingProject?.uuid && !selectedConnectingProject?.is_gbp) {
      setConnectGSCLoader(-1);
      const connectedData = getOttoV2Project?.connectedData;
      loadReportBuilderGSCList(connectedData?.gscDetails?.countryCode || '');
      criteria.updateCriteria({
        ...criteria,
        property: connectedData?.gscDetails?.propertyUrl || '',
      });
      setGscOpenModalValue(true, 'GSC Setting', true, selectedConnectingProject?.siteAudit, false);
      setStopLoadProjectApi(true);
      setOttoIdForGscSettings(selectedConnectingProject?.uuid || '');
      setGaDataModal({
        ...gaDataModal,
        visible: true,
        siteUrl: connectedData?.gscDetails?.propertyUrl || '',
        gaConnected: getOttoV2Project?.connectedData?.isGscConnected,
        settingId: null,
        settingsEnabled: false,
        hasSiteGaCredentials: null,
        saProjectId: selectedConnectingProject?.siteAudit,
        countryCode: connectedData?.gscDetails?.countryCode || '',
      });
    }

    if (getOttoV2Project === null && selectedConnectingProject !== null && selectedConnectingProject?.is_gbp) {
      loadOttoV2ProjectFromApi(selectedConnectingProject);
    } else if (getOttoV2Project && getOttoV2Project?.uuid === selectedConnectingProject?.uuid && selectedConnectingProject?.is_gbp) {
      if (shouldOpenGBP && getGMBData?.length) {
        setConnectGMBLoader(-1);
        setShouldOpenGBP(false);
        setGbpModalVisible(true);
      }
    }
  }, [getOttoV2Project, selectedConnectingProject, getGMBData]);

  const getSeoTask = () => {
    setParams({
      ...params,
      page: 1,
      search: '',
    }, true, true);
    loadAuditedSitesList();
    setIsWidgetModalVisible(true);
  };


  useEffect(() => {
    setDefaultParams({...defaultParams, pageSize: 20, page: 1});
    getCustomerQuota();
    return () => setDefaultParams({...defaultParams, pageSize: 20, page: 1, search: ''}, true);
  }, []);

  useEffect(() => {
    setProjectListShouldRepoll(true);
    return () => {
      setProjectListShouldRepoll(false);
    };
  }, []);

  const loadOttoV2ProjectFromApi = async record => {
    await loadOttoV2Project(record?.uuid);
    if (selectedConnectingProject?.is_gbp) {
      await getGMBSetting();
    }
  };
  const knowledgeBaseUrl = 'https://intercom.help/search-atlas/en/collections/10864692-knowledge-base';

  useEffect(()=> {
    headerStore.setComponent(null, false, [
      {name: '', spend: 0, total: 0, color: 'green', quotaProps: null},
    ]);
  }, []);

  return (<>
    <HeaderWrapper className='bg-main-color'>
      <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: '1200px', margin: '0px auto', borderRadius: '8px'}}>
        <HeadingContainer isWhitelabel={isWhitelabel}>
          {router?.pathname?.includes('public') ? <div style={{color: '#A3A4A4'}}>Quota: 2 projects</div> :
            <NewQuotaDropDown quotaTableArray={quotaArray} setTopUpBulkVisibility={setTopUpBulkVisibility} setDropDownOpen={setDropDownOpen} dropDownOpen={dropDownOpen} />}
          {/* <QuotaInfoDetail isCenter consumedData={quotaUtilization?.ca?.allowedOttoV2Projects?.consumed} totalData= {quotaUtilization?.ca?.allowedOttoV2Projects?.total} heading={'Quota'} description={'Total number of OTTO projects'}/> */}
          <FlexWrapperHeading>
            <HeadingWrapper>
              <Heading>{`${whitelabelOtto} SEO Automation`}</Heading>
            </HeadingWrapper>
            <Description>{`Revolutionize your website maintenance with ${whitelabelOtto}'s cutting-edge technology. Seamlessly detect and resolve issues across your site, guaranteeing smooth functionality and enhancing user satisfaction.`}</Description>
            <FlexWrapper>
              <Button style={{height: '43px', margin: '0px'}} color='blue' onClick={() => getSeoTask()}>+ Create</Button>
            </FlexWrapper>
          </FlexWrapperHeading>
        </HeadingContainer>
        <div>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '24px'}}>
            {isWhitelabel && !isParentAccount ? null : <WhitelistCrawler onClick={() => openUrl(`${getSaUrl()}/blog/whitelist-monitoring-on-cloudflare/`, '_blank')}>
              <FontAwesomeIcon icon={faCircleInfo} color='#E8E8E8' fontSize={12} />
            How to Whitelist our Crawler
            </WhitelistCrawler>}
            {<Tooltip title={isWhitelabel ? !isParentAccount ? 'Feature visible only to the Agency Owner' : '' : ''}
              placement={'right'}
              overlayInnerStyle={{
                width: 200,
                padding: '10px',
                background: '#0c0a09',
                boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.08)',
                borderRadius: 8,
              }}>
              <div
                onClick={()=> isWhitelabel ? !isParentAccount ? {} : openUrl(knowledgeBaseUrl, '_blank') : openUrl(knowledgeBaseUrl, '_blank')}
                style={{
                  width: '132px',
                  color: isWhitelabel ? !isParentAccount ? 'rgba(52, 174, 243, 50%)' : '#34AEF3' : '#34AEF3',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  lineHeight: '20px',
                  backgroundColor: 'transparent',
                  cursor: isWhitelabel ? !isParentAccount ? 'not-allowed' : 'pointer' : 'pointer',
                }}>
                <><FontAwesomeIcon icon={faBook} color={isWhitelabel ? !isParentAccount ? 'rgba(52, 174, 243, 50%)' : '#34AEF3' : '#34AEF3'} /></>Knowledge Base
              </div>
            </Tooltip>}
          </div>
          {!isWhitelabel && <VideoWrapper/>}
        </div>
      </div>
    </HeaderWrapper>
    {<ProjectsTable
      setUuid={setUuid}
      setActiveTab={setActiveTab}
      setIsWidgetModalVisible={setIsWidgetModalVisible}
      setInstructionsData={setInstructionsData}
      setIsInstructionModalVisible={setIsInstructionModalVisible}
      setConnectGSCLoader={setConnectGSCLoader}
      setSelectedConnectingProject={setSelectedConnectingProject}
      connectGSCLoader={connectGSCLoader}
      setConnectGMBLoader={setConnectGMBLoader}
      setShouldOpenGBP={setShouldOpenGBP}
      connectGMBLoader={connectGMBLoader}
      isWidgetModalVisible={isWidgetModalVisible}
      isInstructionModalVisible={isInstructionModalVisible}
      gbpModalVisible={gbpModalVisible}
    />}
    <SettingsModal
      isVisible={isInstructionModalVisible}
      setIsVisible={setIsInstructionModalVisible}
      instructionsData={instructionsData}
      setInstructionsData={setInstructionsData}
    />
    <ImportBusinessModalV2
      onOttoGBPConnect={() => {
        loadOttoV2Project('uuid' in selectedConnectingProject ? selectedConnectingProject?.uuid : null);
        setSelectedConnectingProject(null);
        resetOttoV2Project(null);
        loadOttoV2Projects(true);
      }}
      visible={gbpModalVisible}
      setVisible={setGbpModalVisible}
      ottoProjectId={getOttoV2Project?.uuid}
      ottoConnectedGBPId={getOttoV2Project?.connectedData?.gbpDetailsV2}
      fromOtto={`?uuid=${uuid}`}
      isOttoV2
      isOttoListingPage
    />
    <TopupModal topUpBulkVisibility={topUpBulkVisibility} setTopUpBulkVisibility={setTopUpBulkVisibility} />
    <WidgetModal
      isWidgetModalVisible={isWidgetModalVisible}
      setIsWidgetModalVisible={setIsWidgetModalVisible}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      uuid={uuid}
      setUuid={setUuid}
    />
  </>
  );
});

export const StyledWidgetModal = styled(Modal)`
  .rc-dialog-content {
    .rc-dialog-body {
      border-radius: 10px;
      overflow: hidden !important;
      padding: 0px !important;
      .modal-video {
        height: 230px;
        background-color: #4e0db1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .content-wrapper {
        padding: 25px 25px;
        .body-heading {
          font-size: 24px;
          font-family: 'Inter', sans-serif;
          font-weight: 600;
          margin-bottom: 10px;
          color: #121212;
        }
        .site {
          font-family: Inter;
          font-size: 14px;
          font-weight: 400;
          line-height: 16.94px;
          text-align: left;
          color: #121212;
          margin-bottom: 10px;
        }
        .inputs-wrapper {
          margin-bottom: 8px;
          .label-wrapper {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            font-family: 'Inter', sans-serif;
            font-weight: 400;
            margin-bottom: 6px;
            .label {
              color: #121212;
            }
            .heading {
              color: #2D6CCA;
              cursor: pointer;
            }

          }
          .domain-input {
            padding: 10px 11px !important;
            color: #A3A4A4 !important;
            border-radius: 8px !important;
          }
          .snippet-wrapper {
            position: relative;
            border-radius: 8px;
            background-color: #F2F2F5;
            padding: 14px 12px;

            .snippet-buttons {
              display: flex;
              align-items: center;
              gap: 20px;
              button {
                margin: 0px !important;
              }
              .email-button {
                display: flex;
                align-items: center;
                gap: 6px; color: #2D6CCA;
                font-size: 12px;
                font-weight: 400;
                cursor: pointer;
                :hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
        .website-wrapper {
          margin-bottom: 12px;
          width: 100%;
          display: flex !important;
          align-items: center;
          justify-content: space-between !important;
          .label {
            font-size: 12px;
            font-family: 'Inter', sans-serif;
            font-weight: 400;
            color: #121212;
            margin-bottom: 6px;
          }
          .domain-input {
            padding: 10px 11px !important;
            border-radius: 12px !important;
            width: 100%;
            height: 38px;
          }
        }

        .embed-successfully {
          display: flex;
          align-items: center;
          padding: 8px 12px;
          background-color: rgba(42, 193, 85, 0.15);
          border-radius: 6px;
          margin-bottom: 6px;
          gap: 4px;

          .text {
            display: flex;
            align-items: center;
            color: #121212;
            font-weight: 400;
            font-size: 12px;
            font-family: 'Inter', sans-serif;
            line-height: normal;
          }
        }
        .not-embed {
          display: flex;
          align-items: center;
          padding: 8px 12px;
          background-color: rgba(244, 67, 67, 0.15);
          border-radius: 6px;
          gap: 4px;
          .text {
            display: flex;
            align-items: center;
            color: #121212;
            font-weight: 400;
            font-size: 12px;
            font-family: 'Inter', sans-serif;
            line-height: normal;
            gap: 2px;

            .check-again-button {
              display: flex;
              align-items: center;
              font-weight: 400;
              font-size: 12px;
              font-family: 'Inter', sans-serif;
              color: #2D6CCA;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
        .already-embed {
          display: flex;
          align-items: center;
          padding: 8px 12px;
          border: 1px solid #E8E8E8;
          border-radius: 6px;
          margin-bottom: 6px;
          gap: 4px;

          .text {
            display: flex;
            align-items: center;
            color: #121212;
            font-weight: 400;
            font-size: 12px;
            font-family: 'Inter', sans-serif;
            line-height: normal;

            .link {
              color: #2D6CCA;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
        .banner {
            padding: 5px 10px;
            display: flex;
            align-items: center;
            gap: 8px;
            border-radius: 6px;
            background-color: rgba(45, 108, 202, 0.14);
            margin-bottom: 10px;
            span {
              font-family: 'Inter', sans-serif;
              font-size: 12px;
              font-weight: 400;
            }
          }
        .steps-wrapper {
          position: relative;
          margin-bottom: 8px;

          .steps {
            display: flex;
          }

          .border-line {
            bottom: 3px;
            position: relative;
            width: 100%;
            height: 2px;
            border-bottom: 2px solid #e8e8e8 !important;
          }
          .section {
            flex-grow: 1;
            cursor: pointer;

            &.active-section {
              z-index: 1;
              padding-bottom: 8px;
              border-bottom: 4px solid rgba(45, 108, 202, 1);
            }
            &.restricted {
              cursor: default;
            }
            .step {
              font-size: 12px;
              line-height: 14px;
              font-weight: 400;
              color: #4e5156;

              &.active {
                font-weight: 500;
                color: #121212;
              }
            }
          }
        }
      }
    }
    
    .rc-dialog-close {
    font-size: 21px;
    right: -34px;
    top: 0px;
    font-weight: 700;
    color: #fff !important;
    opacity: 0.8;
    &:hover {
      opacity: 1 !important;
    }
    }
  }
`;

const HeadingWrapper = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  margin-bottom: 13px;
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #E8E8E8;
  width: 470px;
  margin-bottom: 26px;
`;
const FlexWrapper= styled.div`
  display: flex;
  align-items: center;
  // justify-content: center;
  gap: 12px;
`;
const FlexWrapperHeading= styled.div`
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Heading = styled.div`
  font-weight: 700;
font-size: 28px;
line-height: 32px;
color: #FFFFFF;
`;

const HeadingContainer = styled.div<{isWhitelabel: boolean}>`
  @media screen and (max-width: 1550px) {
    max-width: 1200px;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }
  @media screen and (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;
const HeaderWrapper = styled.div`
    cursor: default;
    padding-bottom: 50px;
    padding-top: 40px;
`;

export const SiteAuditWrapper = styled.div`
  width: 100%;
  border-radius: 8px;
  margin-bottom: 14px;

  .existing-site {
    display: flex;
    align-items: center;
    color: #121212;
    font-weight: 400;
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    line-height: normal;
    color: #2D6CCA;
    cursor: pointer;
    gap: 8px;
    margin-bottom: 16px;
  }
    .ant-collapse-header{
    padding-left: 0 !important;
    padding-bottom: 0 !important;
    width: fit-content !important;
    }
    .ant-collapse-content-box{
    padding-left: 0 !important;
    }
    .ant-collapse-arrow{
    margin-top: 7px !important;
    }
    `;

export const ErrorDivStyled = styled.div`
  width: max-content;
  height: 32px;
  background: #FFFFFF;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  position: absolute;
  top: 157px;
  z-index: 99;
  left: 14px;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 10px;
  span {
    color: #2D6CCA;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .triangle {
    width: 20px;
    position: absolute;
    height: 8px;
    border-bottom: solid 11px rgb(255 255 255);
    border-left: solid 11px transparent;
    border-right: solid 11px transparent;
    top: -10px;
    left: 20px;
  }
`;

// const DisableButton = styled.div`
//   background: linear-gradient(0deg, rgba(0, 0, 0, 0.17), rgba(0, 0, 0, 0.17)), #2D6CCA;
//   background-color: #2D6CCA;
//   border-color: #2D6CCA;
//   color: #fff;
//   opacity: 50%;
//   text-align: center;
//   display: flex;
//   padding: 0 20px;
//   align-items: center;
//   border-radius: 6px;
//   cursor: not-allowed;
// `;

const WhitelistCrawler = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #E8E8E8; 
  cursor: pointer; 
  white-space: nowrap;
`;

