import React, {useEffect, useState, ReactNode} from 'react';
import {Tooltip} from 'antd';
import {useRouter} from 'next/router';
import {observer} from 'mobx-react-lite';
import styled from 'styled-components';
import {useStore} from '@/store/root-store';
import styles from './style.module.scss';
import LoginModal from '@/components/common-components/components/authentication-modal';
import useWindowSize from '@/utils/hooks/useWindowSize';
import {NoSsr, Typography} from '@/components/common-components';
import moment from 'moment';
// import {getDateDifferenceForLocalStorage} from '@/components/dashboard/gsc/gsc/utils';
import {
  getLastPropertyFromCookies,
  getPeriodFromLocalStorage,
  getTokenFromCookies,
  setCountryCodeToLocalStorage,
  setLastPropertyIntoCookies,
} from '@/api/common-utils';
import {RenderSitePropertySelect} from '../header/headerComponents/renderSitePropertySelect';
import {RenderSaProjectSelect} from '../header/headerComponents/renderSaProjectSelect';
import {SiteHealthWithProgress} from '../header/headerComponents/siteHealthWithProgress';
// import {RenderScaProjectsDropdown} from '../header/headerComponents/renderScaProjectsDropdown';
// import {RenderBacklinkProjectSelect} from '../header/headerComponents/renderBacklinkProjectSelect';
import {RenderSiteEventsDateRangePicker} from '../header/headerComponents/siteEventsRangePicker';
import {RenderDateRangePicker} from '../header/headerComponents/rangePicker';
import {RenderCountrySelect} from '../header/headerComponents/renderCountrySelect';
import {getSingleUrlParam} from '@/utils/url';
import {useTranslation} from 'next-i18next';
import {HOME_UPGRADE_CTA_CLICKED} from '@/constants/events';

import {MixPanel} from '@/utils/mixpanel';
import ButtonComponent from '@/components/common-components/components/button-component';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import {Button} from '@/components/common-components/v2';
// import {faArrowRight, faXmark} from '@fortawesome/pro-regular-svg-icons';
// import classNames from 'classnames';
import {SiteHealthWithProgressPublic} from '../header/headerComponents/siteHealthWithProgressPublic';
import {RenderDateRangePickerSA} from '../header/headerComponents/rangePickerSA';
import Link from 'next/link';
import {PostOnboardingModal} from '@/components/dashboard/pages/competitor-researcher/domain-analyzer-public/post-onboarding-modal';
import {RenderKrtProjectSelect} from '../header/headerComponents/renderKrtProjectSelect';
// import {RenderKrtDateRangePicker} from '../header/headerComponents/rangePickerKrt';
import {LoadingOutlined} from '@ant-design/icons';
// import {routes} from '@/utils/const';
import {GscConnectModal} from '@/components/dashboard/pages/site-audit-v2/common/gscConnectModal';
import {AiContentToolsDrawer} from '@/components/dashboard/pages/landing-page-optimizer/page-editor/Drawers/AiContentToolsDrawer';
import {toJS} from 'mobx';
import {routes} from '@/utils/const';
// import {LocalSeoProjectSelector} from '../header/headerComponents/localSeoProjectSelector';
import {AiWriterSideDrawer} from '@/components/dashboard/pages/landing-page-optimizer/page-editor/Drawers/AiWriterSideDrawer';
import {SmallBadge} from '@/components/common-components/components/badge';
import {faAngleRight, faAngleLeft} from '@fortawesome/pro-regular-svg-icons';
import {useLocalStorage} from '@/utils/hooks/useLocalStorage';
import {CloseDrawer} from './sideDrawer';
// import { RenderProjectSelection } from '../header/headerComponents/renderProjectSelection';

type ChildPagesProps = {
  commingSoon?: boolean;
  disabledMsg?: string;
  color?: string;
  key: string;
  title: string;
  disabled?: boolean;
  hidden?: boolean;
  icon?: ReactNode;
  external?: boolean;
  newTab?: boolean;
  url?: string;
  path?: string;
  isAlpha?:boolean;
  isBeta?: boolean;
  isNew?: boolean;
  unreadEmails?: boolean;
  size?: string;
};

type SideBarProps = {
  commingSoon?: boolean;
  disabledMsg?: string;
  color?: string;
  key: string;
  title: string;
  disabled?: boolean;
  hidden?: boolean;
  icon?: ReactNode;
  external?: boolean;
  newTab?: boolean;
  url?: string;
  path?: string;
  linkProps?: any;
  isAlpha?:boolean;
  isBeta?: boolean;
  isNew?: boolean;
  unreadEmails?: boolean;
  childPages?: ChildPagesProps[];
  type?: string;
  shortTitle?: string;
  size?: string;
};

type SiderProps = {
  sideBarItems?: SideBarProps[];
  publicPage?: boolean;
};

export const CommonSidebar: React.FC<SiderProps> = observer(({sideBarItems, publicPage}) => {
  // const backlinkDisabled = planName !== 'PRO' && planName !== 'Custom' && planName !== 'GROWTH';
  const [isLoader, setIsLoader]= useState(false);
  const [isDropDownSa, setDropDownSa] = useState(false);
  const [loaderInSideBar, setLoaderInSideBar] = useState('');
  const [contentRedirectLoader, setContentRedirectLoader] = useState(false);
  const router = useRouter();
  // const pathname = router?.pathname;
  // const isLocalSeoPage = pathname == '/research/local-seo';

  const {width} = useWindowSize();
  const {
    keywordResearcherV2: {loadSideBarKEList, sideBarKEListParams, setSideBarParam, getSideBarKEList, sideBarKeywordListPages, keywordDetails: {keSelectedId}},
    settings: {
      customer: {setAuthModal, profile},
    },
  } = useStore('');
  const {
    settings, navBar, gsc,
  } = useStore('');
  const {
    navBar: {isDockedSider, setIsDockedSider, loadSiteProperties},
    settings: {integration: {hasGoogleProfiles}},
  } = useStore('');
  const {settings: {customer: {profile: {isWhitelabel, gscProductOverview}}}} = useStore('');

  let htmlToDraft = null;
  if (typeof window === 'object') {
    htmlToDraft = require('html-to-draftjs').default;
  }

  // const path = router.pathname;
  const isGSCDashboard = router.pathname.match(/\/gsc.*/g);
  const isSiteEventsPage = router.pathname.match(/\/gsc\/site-events.*/g);
  const isKrtDetailsPage = router.pathname.match(/\/research\/keyword-rank-tracker\/.*/g);
  const isSitesListPage = router.pathname.match(/\/gsc\/sites-list.*/g);
  const isSiteAudit = router.pathname.match(/\/site-audit-old\/.*/g);
  const isSiteAuditV2 = router.pathname.match(/\/site-audit\/.*/g);
  const isGscBacklinksPage = router.pathname.match(/\/gsc\/backlinks.*/g);
  const isSiteStructurePage = router.pathname.match(/\/gsc\/site-structure.*/g);
  const isTopPagesPage = router.pathname.match(/\/gsc\/pages.*/g);
  const isKeywordsPage = router.pathname.match(/\/gsc\/keywords.*/g);
  const isGscOverview = router.pathname.match(/\/gsc\/overview.*/g);
  const isAnyScaPage = router.pathname.match(/\/content\/seo-content-assistant/);
  const isScaPage = router.pathname.match(/\/content\/seo-content-assistant\/.*/g);

  const {
    plans: {showSidebarPaymentDrawer},
    settings: {customer: {profile: {plan}}},
    contentOptimizer: {
      currentPage: {content},
      aiOutline: {
        isOpenAiWriterDrawer,
        aiArticleAlreadyReplaced,
      },
    },
  } = useStore('');

  const {siteAuditorV2: {getAuditSitesListBasic, getSiteAuditSpacificData, siteAuditSelectedProperty, gscLoading, openGscModal, callGscModalApi, setGscOpenModalValue, auditedSitesList}} = useStore('');

  const loading = content.aiInProgress;
  const [contentLength] = useState<'short' | 'medium' | 'long'>('medium');
  const [elapsedTime, setElapsedTime] = useState(0);
  const [isDocked, setIsDocked] = useLocalStorage<boolean>('isDocked', true);
  const {
    contentOptimizer,
  } = useStore('');
  const {currentPage} = contentOptimizer;

  const {t} = useTranslation('common');
  const projectId = siteAuditSelectedProperty ? siteAuditSelectedProperty : (localStorage.getItem('lastPropertySiteAudit') || router.query?.id);
  const isGscObject = getAuditSitesListBasic?.find(item=>item?.id == projectId) ?? getSiteAuditSpacificData;
  const showPropertiesPicker = (
    !settings.integration.integrationLoading &&
    !navBar.loading
  );

  useEffect(() => {
    setIsDockedSider(isDocked);
  }, [isDocked]);


  useEffect(() => {
    if (isOpenAiWriterDrawer) {
      let totalTime = 100 * 1000;

      switch (contentLength) {
        case 'short':
          totalTime = 100 * 1000;
          break;
        case 'medium':
          totalTime = 180 * 1000;
          break;
        case 'long':
          totalTime = 300 * 1000;
          break;
        default:
          break;
      }

      if (!loading) return;

      const intervalId = setInterval(() => {
        if (elapsedTime < totalTime) {
          setElapsedTime(prevElapsedTime => prevElapsedTime + 1000);
        } else {
          clearInterval(intervalId);
        }
      }, 1000);

      return () => {
        clearInterval(intervalId); // Clean up the interval on component unmount
      };
    } else if (aiArticleAlreadyReplaced) {
      setElapsedTime(0);
    }
  }, [elapsedTime, loading, isOpenAiWriterDrawer]);


  // const addDefaultSrc = e => {
  //   e.target.src = '';
  // };

  const defaultPreset = [
    moment().subtract(1, 'years').subtract(1, 'days'),
    moment().subtract(1, 'days'),
  ];

  const {criteria} = gsc;
  const googleProfiels = settings.integration.googleProfiles;
  const siteProperties = navBar.propertiesData;

  const updateDefaultCriteria = (url: string, lastPeriod?: any, countryCode?: string) => {
    const [currentPeriodStart, currentPeriodEnd] = defaultPreset;
    const diff = moment(currentPeriodEnd).diff(moment(currentPeriodStart), 'days') + 1;
    const previousPeriodStart = moment(currentPeriodStart).subtract(1, 'days').subtract(diff, 'days');
    const previousPeriodEnd = moment(currentPeriodStart).subtract(2, 'days');

    // TO DO: Remove after Datepicker is tested out with this block of code removed
    // const storageP2End = lastPeriod && lastPeriod.p2End ? lastPeriod.p2End : currentPeriodEnd.format('YYYY-MM-DD');
    // const diffStorageAndState = getDateDifferenceForLocalStorage(storageP2End, currentPeriodEnd.format('YYYY-MM-DD'));

    // if (lastPeriod && diffStorageAndState > 0) {
    //   Object.keys(lastPeriod).forEach(key => {
    //     lastPeriod[key] = moment(lastPeriod[key]).add(diffStorageAndState, 'days').format('YYYY-MM-DD');
    //   });
    // }

    criteria.updateCriteria({
      property: url,
      previousPeriodStart: lastPeriod?.p1Start ? lastPeriod.p1Start : previousPeriodStart.format('YYYY-MM-DD'),
      previousPeriodEnd: lastPeriod?.p1End ? lastPeriod.p1End : previousPeriodEnd.format('YYYY-MM-DD'),
      currentPeriodStart: lastPeriod?.p2Start ? lastPeriod.p2Start : currentPeriodStart.format('YYYY-MM-DD'),
      currentPeriodEnd: lastPeriod?.p2End ? lastPeriod.p2End : currentPeriodEnd.format('YYYY-MM-DD'),
      periodStatus: lastPeriod?.periodStatus ? lastPeriod.periodStatus : '1 year',
      countryCode: countryCode ? countryCode : '',
    });


    gsc.sitePropertyEvents.siteEventCriteriaUpdate({
      property: url,
      previousPeriodStart: lastPeriod?.p1Start ? lastPeriod.p1Start : previousPeriodStart.format('YYYY-MM-DD'),
      previousPeriodEnd: lastPeriod?.p1End ? lastPeriod.p1End : previousPeriodEnd.format('YYYY-MM-DD'),
      currentPeriodStart: lastPeriod?.p2Start ? lastPeriod.p2Start : currentPeriodStart.format('YYYY-MM-DD'),
      currentPeriodEnd: lastPeriod?.p2End ? lastPeriod.p2End : currentPeriodEnd.format('YYYY-MM-DD'),
      periodStatus: lastPeriod?.periodStatus ? lastPeriod.periodStatus : '1 year',
      countryCode: countryCode ? countryCode : '',
    });
  };
  const closeGscModal = ()=>{
    setGscOpenModalValue(false);
  };

  const loadGSCSiteProperties = async () => {
    // Load G Profiles.
    const {integration} = settings;
    integration.loadGoogleProfiles();
    // Got G profiles, try to load site properties.
    const adminCode = getSingleUrlParam('gsc_admin_code');
    if (adminCode) {
      await loadSiteProperties(adminCode || '');
    }
    if (navBar.hasSiteProperties) {
      const {propertiesData} = navBar;
      const lastProperty = getLastPropertyFromCookies();
      const lastPeriod = JSON.parse(getPeriodFromLocalStorage());

      const property = propertiesData.filter(el => el.url === lastProperty).length > 0 ? propertiesData.filter(el => el.url === lastProperty) : propertiesData.filter(el => el.activeForCurrentCustomer);
      const urlProperty = router.query.sp?.includes('?') ? router.query.sp?.slice(0, -1) : router.query.sp;
      const selectedSiteProperty = navBar.getSiteProperty(urlProperty || (property.length ? property[0].url : null));
      // update query parameter for GSC dashboard.
      // const {kw} = router.query;
      // const {url} = router.query;
      const countryCodeParam = getSingleUrlParam('country_code');
      const matchCountryFromParam = selectedSiteProperty?.activeCountryCodes?.length ? selectedSiteProperty?.activeCountryCodes?.find(item => item == countryCodeParam) : '';
      const countryCode = matchCountryFromParam ? matchCountryFromParam : (selectedSiteProperty?.activeCountryCodes?.length ? selectedSiteProperty?.activeCountryCodes?.find(code => code !== '') : '');
      updateDefaultCriteria(selectedSiteProperty?.url, lastPeriod, countryCode);
      setCountryCodeToLocalStorage(countryCode);
      // setCountry(countryCode);
      setLastPropertyIntoCookies(selectedSiteProperty?.url);
    }
  };

  useEffect(() => {
    // necessary for settings sidedrawer to get GSC list
    if (!publicPage) {
      loadGSCSiteProperties(); // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [
    googleProfiels,
    siteProperties,
    navBar.hasSiteProperties,
  ]);

  useEffect(() => {
    if (router.asPath === '/home' || width < 1300) {
      setIsDocked(false);
    } else if (router.asPath.includes('/otto-page-v2/tasks') || router.route == '/content/seo-content-assistant/[uuid]') {
      setIsDocked(false);
    } else {
      setIsDocked(true);
    }
    setContentRedirectLoader(false);
  }, [width, router, JSON.stringify(currentPage.content)]);


  let renderFreeConsultation = null;
  if (router.pathname !== '/checkout' && renderFreeConsultation == null ) {
    renderFreeConsultation= (
      <div className={styles.sidebarCTA}>
        <Typography tag='p'>
          {isWhitelabel ? t('ready-most-powerful').replace(' of Search Atlas', '') : t('ready-most-powerful')}
        </Typography >
        <ButtonComponent
          style='solid'
          color='green'
          onClick={() => {
            showSidebarPaymentDrawer();

            MixPanel.track(HOME_UPGRADE_CTA_CLICKED);
          }}
          /*   buttonType='transparent' */
          className={ styles.freeConsultation }>
          {t('upgrade-to-pro')}
        </ButtonComponent>
        <div className={styles.borderCTA} />
      </div>
    );
  }

  const tableName = getSingleUrlParam('table');
  const isMagicTool = router.pathname?.includes('/keyword-magic-tool');
  const pagesToShowKEList = [
    '/research/keyword-researcher/[...keyword]',
    '/research/keyword-researcher-magic/[...keyword]',
  ];

  const handleKEList = async () => {
    setIsLoader(true);
    await loadSideBarKEList();
    setIsLoader(false);
  };

  const handleScroll = e => {
    if (sideBarKEListParams.page < sideBarKeywordListPages && !isLoader) {
      if ([e.target.scrollHeight + 1, e.target.scrollHeight, e.target.scrollHeight - 1].includes(Math.round(e.target.offsetHeight + e.target.scrollTop))) {
        setSideBarParam(sideBarKEListParams.page + 1);
        handleKEList();
      }
    }
  };

  useEffect(() => {
    if (pagesToShowKEList.includes(router?.pathname)) {
      setSideBarParam(1);
      handleKEList();
    }
    // if (!publicPage && router?.pathname?.includes('gsc/sites-list') && !navBar?.getSiteProperties?.length) {
    //   loadGSCSiteProperties();
    // }
  }, [router?.pathname]);
  let getSidebarItemHeight = null;
  const getSidebarItem = document.getElementById('sidebar_upper_items_div');
  if (getSidebarItem) {
    getSidebarItemHeight = getSidebarItem.clientHeight + 65 + 200;
  }
  const antIcon = <div style={{width: '100%', textAlign: 'center'}}><LoadingOutlined style={{fontSize: 20, color: '#A3A4A4'}} spin /></div>;
  const antIconSmall = <LoadingOutlined style={{fontSize: 12, marginLeft: '4px', color: '#A3A4A4'}} spin />;
  const handleKERedirection = async item => {
    setLoaderInSideBar(item?.name);
    localStorage.setItem('listname', item?.name);
    const selectedLocation = item?.locationId ? `locationId=${item.locationId}` : `countryCode=${item?.countryCode ? item.countryCode : ''}`;
    const checkPrevPage = isMagicTool && !tableName ? 'keyword-magic-tool' : 'keyword-researcher-magic';
    if (item?.keywords?.length == 1 && item?.id) {
      await router.push(`/research/keyword-researcher/${item.id}/${item.keywords ? item.keywords[0] : ''}?${selectedLocation}`);
    } else {
      await router.push(`/research/${checkPrevPage}/${item?.id}/${item?.keywords ? item?.keywords[0] : ''}?${selectedLocation}`);
    }
    setLoaderInSideBar('');
  };
  const handleKwReAndMaToolRedirection = item => {
    const getUrl = item.path + '-public';
    if (item.path == '/research/keyword-researcher' || item.path == '/research/keyword-magic-tool') {
      router.push(getUrl);
    } else {
      setAuthModal(true, true);
    }
  };


  const gbpAutomationsPaths = [
    routes?.myTasks,
    routes?.gbpAutomationsPosts,
    routes?.gbpAutomationsReviews,
    routes?.gbpAutomationsQuestions,
  ];

  useEffect(()=> {
    if (!sideBarItems || sideBarItems?.length == 0) {
      setIsDocked(false);
    } else {
      setIsDocked(true);
    }
  }, [sideBarItems]);

  return <NoSsr>
    {<SideBar isDockedSider={isDocked} className='bg-main-color'>
      <AiContentToolsDrawer htmlToDraft={htmlToDraft}/>
      <AiWriterSideDrawer htmlToDraft={htmlToDraft} elapsedTime={elapsedTime} setElapsedTime={setElapsedTime} />
      <PostOnboardingModal />
      <DrawerCloseIcon style={{display: !sideBarItems || sideBarItems?.length == 0 ? 'none' : 'block'}}>
        <FontAwesomeIcon icon={isDocked ? faAngleLeft : faAngleRight} onClick={() => setIsDocked(!isDocked)} />
      </DrawerCloseIcon>
      <SideBarWrapper id={'sidebar_upper_items_div'}>
        <CloseDrawer setContentRedirectLoader={setContentRedirectLoader} contentRedirectLoader={contentRedirectLoader} sideBarItems={sideBarItems} isDockedSider={isDocked} publicPage={publicPage}/>
        <div style={{paddingTop: '10px', paddingLeft: '5px'}}>
          {!publicPage ? <div style={{height: 'fit-content'}}>
            {/* {(router.pathname.startsWith('gsc') && router.pathname.endsWith('list')) || (router.pathname.startsWith('site') && router.pathname.endsWith('list')) ? <RenderProjectSelection /> : !router.pathname.includes('gsc') && !router.pathname.includes('site-audit') ? <RenderProjectSelection /> : ''} */}
            {showPropertiesPicker && isGSCDashboard && !gscProductOverview && !isGscBacklinksPage && (
              <div style={!isSitesListPage ? {marginBottom: '20px'} : {}}>
                { !isSitesListPage && <>
                  <RenderSitePropertySelect setDropDownSa={setDropDownSa} isDropDownSa={isDropDownSa} isDockeSideBar={isDockedSider} />
                  {(!isKeywordsPage && !isTopPagesPage && !isGscOverview && !isSiteStructurePage) ?
                    isSiteEventsPage ? <RenderSiteEventsDateRangePicker /> : <RenderDateRangePicker setDropDownSa={setDropDownSa} isDropDownSa={isDropDownSa} /> :
                    null}
                  {(router.asPath.includes('gsc/reports/')) && <RenderCountrySelect isDocked={isDockedSider}/>}
                </>}
              </div>
            )}
            {(isGscBacklinksPage && navBar.hasSiteProperties) && (
              <div style={{marginBottom: '20px'}}>
                <RenderSitePropertySelect setDropDownSa={setDropDownSa} isDropDownSa={isDropDownSa} isDockeSideBar={isDockedSider} />
              </div>
            )}
            {(isSiteAudit || router.pathname == '/site-audit-old') && hasGoogleProfiles && (
              <div>
                <RenderSitePropertySelect />
              </div>
            )}
            {isSiteAuditV2 && !isSiteAuditV2?.includes('/site-audit/list') && <div style={{marginBottom: '20px'}}>
              <RenderSaProjectSelect setDropDownSa={setDropDownSa} isDropDownSa={isDropDownSa} isDockedSider={isDockedSider} />
              {isSiteAuditV2?.includes('/site-audit/overview') || isSiteAuditV2?.includes(`/site-audit/crawl-monitoring`) ?
                <RenderDateRangePickerSA setDropDownSa={setDropDownSa} isDropDownSa={isDropDownSa} isDockedSider={isDockedSider}/> : ''}
              {!isSiteAuditV2?.includes('/site-audit/site-visualization') ?
                <SiteHealthWithProgress isDockedSider={isDockedSider}/> : ''}
            </div>}

            {/* {isKrtDetailsPage ? <RenderKrtDateRangePicker customStartDate={details?.created} customEndDate={details?.postProcessingUpdatedAt} setDropDownSa={setDropDownSa} isDropDownSa={isDropDownSa} initialDateRange={dateRange} /> : null} */}
            {isKrtDetailsPage ? <RenderKrtProjectSelect setDropDownSa={setDropDownSa} isDropDownSa={isDropDownSa} isDockedSider={isDockedSider} /> : null}

            {isAnyScaPage || isScaPage ? <div style={{marginBottom: '20px'}}>
              {(isAnyScaPage /* && settings.customer.profile.customerId === 439 */) && (
                <div>
                  {/* <RenderScaProjectsDropdown isDockeSideBar={isDockedSider} /> */}
                </div>
              )}
              {/* {(isScaPage) &&
        <div style={{display: 'flex', flexDirection: 'column'}}>
          {currentPage?.content?.targetKeywords?.length > 0 ? <span className={styles['header__sca-menu-item']} onClick={() => openContentResearchDrawer()}>
            <FontAwesomeIcon icon={faFileChartPie}/>{isDockedSider ? <>&nbsp;&nbsp;&nbsp;&nbsp;Content Research</> : ''}</span> :
            <Tooltip title='Add at least 1 Target Keyword to view Content Research data'>
              <span className={styles['header__sca-menu-item']}><FontAwesomeIcon icon={faFileChartPie}/>{isDockedSider ? <>&nbsp;&nbsp;&nbsp;&nbsp;Content Research</> : ''}</span>
            </Tooltip>}


          {currentPage?.content?.targetKeywords?.length > 0 ? <span className={styles['header__sca-menu-item']} onClick={() => currentPage.openCompetingFocusTermsDrawer()}><FontAwesomeIcon icon={faList}/>{isDockedSider ? <>&nbsp;&nbsp;&nbsp;Terms &amp; Competitors</> : ''}</span> :
            <Tooltip title='Add at least 1 Target Keyword to view Terms and Competitors'>
              <span className={styles['header__sca-menu-item']}><FontAwesomeIcon icon={faList}/>{isDockedSider ? <>&nbsp;&nbsp;&nbsp;Terms &amp; Competitors</> : ''}</span>
            </Tooltip>}
        </div>} */}
            </div> : ''}


            {!isGscBacklinksPage && (
              <div>
                {/* <RenderBacklinkProjectSelect /> */}

              </div>
            )}
          </div> : isSiteAuditV2 && !isSiteAuditV2?.includes('/site-audit/list-public') && <div style={{height: 'fit-content'}}>
            <RenderSaProjectSelect setDropDownSa={setDropDownSa} isDropDownSa={isDropDownSa} isDockedSider={isDockedSider} />
            {isSiteAuditV2?.includes('/site-audit/overview-public') ?
              <DateRangePublic> <RenderDateRangePickerSA isDockedSider={isDockedSider}/></DateRangePublic> : ''}
            <SiteHealthWithProgressPublic isDockedSider={isDockedSider}/>
          </div>}
          <div style={{width: '192px'}}>
            {sideBarItems?.filter(z => !z?.hidden)?.map((item, idx) => {
              if (item['name']) {
                return <p key={idx} style={{color: '#A3A4A4', fontSize: '11px', margin: `${item.key != '0' ? '18px' : '10px'} 0 10px 10px`}}>{item['name']}</p>;
              }
              const selected = router.pathname == item.path || (item.path !== '/' && router.asPath.startsWith(item.key)) || router.pathname?.includes(item?.path);
              const isSmall = item?.size === 'small';
              const titleLength = item.title?.length > 11;
              if (publicPage) {
                if (item?.path?.includes('/site-audit/') && item?.path !== '/site-audit/pruning-page') {
                  const getUrlId = getSingleUrlParam('id') || getSingleUrlParam('audit') || toJS(auditedSitesList)[0]?.id;
                  const getHash = getSingleUrlParam('public_hash') || toJS(auditedSitesList)[0]?.publicShareHash;
                  const makePath = item?.path + `-public?id=${getUrlId || ''}&public_hash=${getHash || ''}`;
                  return <Link legacyBehavior key={idx} href={makePath} passHref><a>
                    <Tooltip placement='right' title={isDocked ? '' : `${item.title}`} overlayInnerStyle={{borderRadius: '4px'}} color='#121212'>
                      <PopoverItem itemColor={item?.color} key={item.key} highlight={router.pathname == item.path || (router.asPath.startsWith(item.key)) || router.pathname?.includes(item?.path)}>
                        <IconSpan>{item?.icon}</IconSpan> {isDocked ? item?.title : ''}
                      </PopoverItem>
                    </Tooltip>
                  </a></Link>;
                } else {
                  return <Tooltip key={idx} placement='right' title={isDocked ? '' : `${item.title}`} overlayInnerStyle={{borderRadius: '4px'}} color='#121212'>
                    <PopoverItem itemColor={item?.color} key={item.key} highlight={router.pathname == item.path || (item.path !== '/' && router.asPath.startsWith(item.key)) || router.pathname?.includes(item?.path)} onClick={() => {
                      (router.pathname?.includes('public') && !getTokenFromCookies()) && handleKwReAndMaToolRedirection(item);
                    }}>
                      <IconSpan>{item?.icon}</IconSpan> {isDocked ? item?.title : ''}
                    </PopoverItem>
                  </Tooltip>;
                }
              } else {
                if (item?.commingSoon) {
                  return (['GBP Automations', 'Local Listings'].includes(item?.title) ? <></> :
                    <Tooltip placement='right' title={item?.commingSoon ? (item?.disabledMsg || 'Feature coming soon.') : ''}>
                      <PopoverItem itemColor={item?.color} key={item.key} style={item?.commingSoon ? {opacity: '50%', position: 'relative'} : {position: 'relative'}} highlight={router.pathname == item.path || (item.path !== '/' && router.asPath.startsWith(item.key)) || router.pathname?.includes(item?.path)}>
                        <IconSpan>{item?.icon}</IconSpan> {isDocked ? item?.title : ''}
                        {isDocked && item?.isAlpha && <SmallBadgeWrapper titleLength={titleLength}><SmallBadge isSmall={isSmall} alpha /></SmallBadgeWrapper>}
                      </PopoverItem>
                    </Tooltip>
                  );
                } else {
                  if (item?.title == 'Page Pruning' && !isGscObject?.gscConnected) {
                    return <div key={idx} onClick={()=>setGscOpenModalValue(true, 'Google Search Console Settings', true, isGscObject?.id)}>
                      <Tooltip placement='right' title={isDocked ? '' : `${item.title}`} overlayInnerStyle={{borderRadius: '4px'}} color='#121212'>
                        <PopoverItem itemColor={item?.color} key={item.key} highlight={router.pathname == item.path || (item.path !== '/' && router.asPath.startsWith(item.key)) || router.pathname?.includes(item?.path)} style={{position: 'relative'}}>
                          <IconSpan>{item?.icon}</IconSpan> {isDocked ?<span> {item?.title}</span> : ''}
                          {isDocked && item?.isAlpha && <SmallBadgeWrapper titleLength={titleLength}><SmallBadge isSmall={isSmall} alpha /></SmallBadgeWrapper>}
                          {isDocked && item?.isBeta && <SmallBadgeWrapper titleLength={titleLength}><SmallBadge isSmall={isSmall} beta /></SmallBadgeWrapper>}
                          {isDocked && item?.isNew && <SmallBadgeWrapper titleLength={titleLength}><SmallBadge isNew isSmall={isSmall} selected={selected}/></SmallBadgeWrapper>}
                        </PopoverItem>
                      </Tooltip>
                    </div>;
                  } else {
                    if (item.type == 'group') {
                      return <GroupTitleStyled key={item.key}>{isDocked ? item.title : item.shortTitle}</GroupTitleStyled>;
                    } else {
                      return <div key={idx}>
                        <Link legacyBehavior href={item.path} passHref><a {...(item?.linkProps || {})}>
                          <Tooltip placement='right' title={isDocked ? '' : `${item.title}`} overlayInnerStyle={{borderRadius: '4px'}} color='#121212'>
                            <PopoverItem
                              itemColor={item?.color}
                              key={item.key}
                              highlight={router.pathname == item.path || (item.path !== '/' &&
                                router.asPath.startsWith(item.key)) || router.pathname?.includes(item?.path) || router.asPath?.includes(item?.path?.split('?')[0]) || (gbpAutomationsPaths?.includes(router.asPath) && item?.path?.includes('gbp-automations'))} style={{position: 'relative'}}>
                              <IconSpan>{item?.icon}</IconSpan> <div style={{display: 'flex', alignItems: 'center'}}> {isDocked ? <SpanWrapper style={{width: titleLength ? '145px' : ''}}> {item?.title} {item?.unreadEmails ? <UnreadEmails>{item?.unreadEmails}</UnreadEmails> : ''}</SpanWrapper> : ''}
                                {isDocked && item?.isAlpha && <SmallBadgeWrapper titleLength={titleLength}><SmallBadge isSmall={isSmall} alpha /></SmallBadgeWrapper>}
                                {isDocked && item?.isBeta && <SmallBadgeWrapper titleLength={titleLength}><SmallBadge isSmall={isSmall} beta /></SmallBadgeWrapper>}
                                <SmallBadgeWrapper titleLength={titleLength}>{(isDocked && item?.isNew) ? item?.title === 'Topical Maps' ?
                                  <SmallBadge isSmall={isSmall} selected={selected} isNew={item?.isNew} customStyle={{backgroundColor: '#514568'}}/> : <SmallBadge isSmall={item?.size === 'small'} selected={selected} isNew /> : ''}</SmallBadgeWrapper></div>
                            </PopoverItem>
                          </Tooltip>
                        </a></Link>
                        <ChildPagesWrapper>
                          {item?.childPages && isDocked && item?.childPages?.map(child => {
                            const isSmall = child?.size === 'small';
                            return (
                              !child?.hidden && <Link legacyBehavior key={child?.key} href={child?.path} passHref>
                                <a>
                                  <ChildPage style={{marginBottom: 15, display: 'flex', alignItems: 'center', borderBottom: router.asPath?.includes(child?.path?.split('?')[0]) ? '2px solid #FFFFFF' : ''}}>
                                    <SpanWrapper style={{width: titleLength ? '150px' : ''}}>{child?.title}</SpanWrapper>
                                    {isDocked && child?.isAlpha && <SmallBadgeWrapper titleLength={titleLength}><SmallBadge isSmall={isSmall} alpha /></SmallBadgeWrapper>}
                                    {isDocked && child?.isBeta && <SmallBadgeWrapper titleLength={titleLength}><SmallBadge isSmall={isSmall} beta/></SmallBadgeWrapper>}
                                    {isDocked && child?.isNew && <SmallBadgeWrapper titleLength={titleLength}><SmallBadge selected={router.asPath?.includes(child?.path?.split('?')[0])} isSmall={isSmall} isNew/></SmallBadgeWrapper>}
                                  </ChildPage>
                                </a>
                              </Link>);
                          })}
                        </ChildPagesWrapper>
                      </div>;
                    }
                  }
                }
              }
            })}
          </div>
        </div>
      </SideBarWrapper>
      <div >
        {pagesToShowKEList.includes(router?.pathname) && getSideBarKEList?.length ? <WrapperList style={{height: `calc(100vh - ${getSidebarItemHeight}px)`}} onScroll={handleScroll}>
          <KeywordList>KEYWORD LISTS</KeywordList>
          {getSideBarKEList?.map((item, idx) => {
            return <KeywordItem key={`KeywordItem_${idx}`} selected={`${item?.id}` == keSelectedId} onClick={() => handleKERedirection(item)}>
              <div>{item?.name}<span>{loaderInSideBar == item?.name ? antIconSmall : ''}</span></div>
              <div>{item?.keywords?.length ?? '0'}</div>
            </KeywordItem>;
          })}
          {isLoader ? antIcon : ''}
        </WrapperList> : ''}
      </div>

      {!plan && isDockedSider && !publicPage && !profile?.isShopifyCustomer ? renderFreeConsultation : ''}
    </SideBar>}
    <LoginModal />
    {openGscModal && <GscConnectModal loading= {gscLoading} openGscModal={openGscModal} closeGscModal={closeGscModal} callGscModalApi={callGscModalApi}/>}
  </NoSsr>;
});

const GroupTitleStyled = styled.div`
  color: #A3A4A4;
  font-size: 11px;
  font-weight: 500;
  line-height: 13.31px;
  padding: 5px 13px;
`;


const SideBarWrapper = styled.div`
height: inherit;
a{
  text-decoration: none;
}
  .outerWrap{
  border-image-source: linear-gradient(rgb(76 76 76) 0%, rgba(151, 156, 163, 0) 100%);
    border-image-slice: 1;
  }

  display: flex;
  overflow: hidden;
  border-top: 1px solid #454545;
  border-image-source: linear-gradient(to right, rgb(102 103 105) 0%, rgba(151, 156, 163, 0) 100%);
    border-image-slice: 1;
  .blockWrapper{
  width: 60px;
  cursor: pointer;
  border-radius: 10px;
  padding-top: 5px;
  min-height: 52px;
    padding: 0 6px;
      align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    gap: 4px;
    p{
    text-align: center;
    align-items: center;
    font-size: 10px;
    margin: 0;
    line-height: 11px;
    padding-bottom: 6px;
    }
    svg{
    font-size: 20px;
    margin-top: 10px;
    padding-bottom: 2px;
    }
  }
`;

const SideBar = styled.div<{isDockedSider: boolean}>`
  z-index: 50;
  height: calc(100vh - 66px);
  width: ${p => p.isDockedSider ? '290px' : '83px'};
  padding-bottom: 30px;
  border-right: 1px solid #3f3f3f;
    border-image-source: linear-gradient(rgb(76 76 76) 0%, rgba(151, 156, 163, 0) 62%);
    border-image-slice: 1;
      transition: width 0.2s;
  padding-left: 8px;
  padding-right: 8px;
  min-height: calc(100vh - 66px);
  .logo-container {
    width: 100%;
    height: 45px;
  }
  @media screen and (max-width: 768px) {
    padding-top: 16px;
    position: ${p => p.isDockedSider ? 'absolute' : ''};
    transition: none !important;
    min-height: 100%;
  }
  @media screen and (min-width: 770px) {
    .logo-responsive {
    }
    .logo-container {
    }
  }
`;

const IconSpan = styled.span`
  /* margin-right: 5px; */
  display: flex;
  align-items: center;
  height: fit-content;
  min-width:36px;
  svg{
    width: 20px;
    height: 20px;
  }
`;
const WrapperList = styled.div`
  overflow: auto;
  margin-top: 30px;
  /* height: 200px; */
  &::-webkit-scrollbar{
    height: 5px;
      width: 5px;
      }
  &::-webkit-scrollbar-thumb{
    background: rgba(138,138,138,0.32);
  }
  &::-webkit-slider-runnable-track{
    background: rgba(138,138,138,0.12);
  }

`;

const PopoverItem = styled.div<{highlight: boolean; itemColor: string}>`
  background: ${p => p.highlight ? `${p.itemColor}33` : 'transparent'};
  border-radius: 4px;
  color: ${p => p.highlight ? '#fff' : '#E8E8E8'};
  padding: 5px 13px;
  cursor: pointer;
  white-space: nowrap;
  width: 100%;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.01em;
  height: 36px;
  display: flex;
  align-items: center;
  svg {
    color: ${p => p.highlight ? p.itemColor : '#E8E8E8'};
    fill: ${p => p.highlight ? p.itemColor : '#E8E8E8'};
  }

  &:hover {
    background: #34363B;
    color: #FFF;
  }
`;

const DateRangePublic=styled.div`
  pointer-events: none;
`;

const KeywordList = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #FFFFFF;
  padding-left: 12px;
  margin-bottom: 10px;
`;

const KeywordItem = styled.div<{selected?: boolean}>`
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: ${p => p.selected ? '#FFFFFF' : '#A3A4A4'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 4px 12px;
  background: ${p => p.selected ? '#4E515666' : 'transparent'};
  border-radius: ${p => p.selected ? '5px' : '0'};
  cursor: pointer;
  &:hover {
    background: #4E515666;
    border-radius: 5px;
    color: #FFFFFF;
  }
`;

const UnreadEmails = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 19px;
  height: 16px;
  position: absolute;
  top: 2px;
  right: 47%;
  padding-top: 3px;
  padding-right: 2px;
  background-color: #F44343;
  border-radius: 31px;
  font-family: Inter;
  font-size: 10px;
  font-weight: 600;
  line-height: 12.1px;
  text-align: left;
  color: #FFFFFF;
`;

const ChildPagesWrapper = styled.div`
  margin-left: 55px;
`;

const ChildPage = styled.div`
  width: max-content;
  position: relative;
  color: #FFFFFF;
`;

const SpanWrapper = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
`;

const SmallBadgeWrapper = styled.div<{titleLength?: boolean}>`
margin-left: ${p => p.titleLength ? '-61px' : ''};
margin-top:  ${p => p.titleLength ? '-30px' : ''};
`;

const DrawerCloseIcon = styled.div`
  position: absolute;
  right: -8px;
  top: 18px;
  z-index: 1;


  svg {
    color: white;
    padding: 4px 5px;
    background: black;
    border-radius: 7px;
    border: 1px solid #3f3f49;
    font-size: 12px;
    cursor: pointer;
  }
`;
