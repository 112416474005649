import {Modal} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import styles from './style.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLayerGroup} from '@fortawesome/pro-duotone-svg-icons';
import styled from 'styled-components';
import {faFileArrowUp} from '@fortawesome/pro-regular-svg-icons';
import {observer} from 'mobx-react-lite';
import {useStore} from '@/store/root-store';
import {useRouter} from 'next/router';
import {CustomTextarea} from '@/components/common-components/components/form/input/customTextarea';
import {getSingleUrlParam} from '@/utils/url';
import {faCaretDown, faX} from '@fortawesome/pro-solid-svg-icons';
import {OptionContent} from '../../keyword-researcher-v2/magickeyword/style';
import {StyledSelect} from '../../local-seo-scan/local-seo-list/styled';
import {Select, Checkbox} from 'antd';
import {CountryDropdown} from '@/components/common-components/v2';
import {removeNotePadTags, targetPageRegex, urlDomainRegex, urlSubDomainRegex} from '@/constants/regex';
import {countriesListForCr} from './header';

const modeOptions=[
  {name: 'Domain', value: 'domain'},
  {name: 'Sub Domain', value: 'subdomain'},
  {name: 'Target Page', value: 'target_page'},
  {name: 'Sub Folder', value: 'subfolder'},
];
const {Option} = Select;
export const SiteExplorerBulkModal = observer(() => {
  const {
    competitorResearcherV2: {openSiteExplorerBulkModal, setOpenSiteExplorerBulkModal, createBulk, isBulkLoading},
    settings: {customer: {setAuthModal}}} = useStore('');
  const hiddenFileInput = useRef(null);
  const router = useRouter();
  const [keywords, setKeywords] = useState<string>('') as any;
  const [isEmptyInput, setIsEmptyInput] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('US');
  const [selectedModeOption, setSelectedModeOption] = useState('domain');
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [sitesLengthError, setSitesLengthError] = useState(false);
  const [fileFormat, setFileFormat] = useState(false);

  const handleOnSubmit = async ()=> {
    const keywordsGroup = keywords?.split('\n');
    const keywordList = [];
    keywordsGroup?.map(item =>{
      const url = item.replace(removeNotePadTags, '');
      const domain = url?.match(urlDomainRegex);
      const subDomain = url?.match(urlSubDomainRegex);
      const targetPage = url?.match(targetPageRegex);
      if (url) {
        keywordList.push({
          keyword: url,
          isValid: (domain || subDomain || targetPage) ? true : false,
        });
      }
    });
    const isInValid = keywordList?.filter(item=> !item?.isValid);
    if (isInValid?.length) {
      setIsEmptyInput(true);
    } else if (!isInValid?.length && keywordList?.length > 20) {
      setSitesLengthError(true);
    } else {
      setSitesLengthError(false);
      setIsEmptyInput(false);
      await createBulk({
        urls: keywordList.map(item => item.keyword),
        mode: selectedModeOption,
        countryCode: selectedCountry,
        isSaved: isCheckboxChecked,
      });
      setOpenSiteExplorerBulkModal(false);
      setIsCheckboxChecked(false);
      setKeywords('');
    }
  };
  const checkPublicPages = router.pathname?.includes('public');
  const handleClick = () => {
    setFileFormat(false);
    if (checkPublicPages) {
      setAuthModal(true, false);
    } else {
      hiddenFileInput.current.click();
    }
  };
  const handleChange = async e =>{
    e.preventDefault();
    if (e?.target?.files[0]?.type == 'text/plain' || e?.target?.files[0]?.type == 'text/csv') {
      const reader = new FileReader();
      reader.onload = async e => {
        const text = (e.target.result);
        setKeywords(text);
      };
      reader?.readAsText(e?.target?.files[0]);
    } else {
      setFileFormat(true);
    }
    setSitesLengthError(false);
    setIsEmptyInput(false);
  };
  const chechCreateBtn = getSingleUrlParam('isCreate');
  useEffect(()=>{
    if (chechCreateBtn) {
      window.scrollTo(0, 0);
    }
  }, []);
  const handleKeywords = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFileFormat(false);
    if (keywords) setIsEmptyInput(false);
    if (keywords) setSitesLengthError(false);
    if (e?.nativeEvent['data'] == ',') {
      setKeywords(e?.target?.value?.replace(',', '\n'));
    } else {
      setKeywords(e?.target?.value);
    }
  };
  const onCountryChange = value=> {
    setSelectedCountry(value);
  };
  const handleClickOnCheckbox = value=> {
    setIsCheckboxChecked(value?.target?.checked);
  };
  return (
    <>
      <Modal
        visible={openSiteExplorerBulkModal}
        className={styles.modalBulkStyle}
        width={'600px'}
        closeIcon={<FontAwesomeIcon icon={faX} fontSize='20px'/>}
        okButtonProps={{loading: isBulkLoading, onClick: handleOnSubmit, disabled: (isBulkLoading || isEmptyInput || sitesLengthError || !keywords?.length)}}
        centered
        okText={'Import sites'}
        onCancel={()=> {
          setOpenSiteExplorerBulkModal(false);
          setIsCheckboxChecked(false);
          setKeywords('');
          setIsEmptyInput(false);
          setSitesLengthError(false);
        }
        }
      >
        <>
          <FontAwesomeIcon icon={faLayerGroup} color='#7F4EAD' fontSize='30px'/>
          <TitleDark>Add Sites in Bulk</TitleDark>
          <GrayText>Divide URLs by commas or in a new line <div style={{fontWeight: 600, color: 'black'}}>Import up to 20 Sites</div></GrayText>
          <FlexWrapper justify='space-between' margin='13px 0' style={{flexWrap: 'wrap'}}>
            <FlexWrapper gap='7px'>
              <div>
                <CountryDropdown onChange={onCountryChange} showSearch aligned={true} variant={'lightGray'} showWorldWide={false} countriesToInclude={countriesListForCr}/>
              </div>
              <div>
                <StyledSelect
                  background='#f9f9fb'
                  value={selectedModeOption}
                  dropdownStyle={{backgroundColor: '#fff', color: '#2D2F34'}}
                  suffixIcon={<FontAwesomeIcon icon={faCaretDown} color='#121212'/>}
                  style={{minWidth: 130}} onChange={setSelectedModeOption}>
                  {modeOptions?.map((item, index) => {
                    return <Option key={index} value={item?.value}> <OptionContent>{item?.name}</OptionContent></Option>;
                  },
                  )}
                </StyledSelect>
              </div>
            </FlexWrapper>
            <div>
              <div style={{display: 'flex', paddingTop: '6px', cursor: 'pointer'}}>
                <FontAwesomeIcon onClick={handleClick} style={{color: '#2D6CCA', fontSize: '16px', paddingTop: '3px', paddingRight: '8px'}} icon={faFileArrowUp} />
                <ImportTag onClick={handleClick}>
                Import from file
                </ImportTag>
              </div>
              <input type='file'
                ref={hiddenFileInput}
                accept='.csv,.txt'
                onClick={ (event:any) => {
                  event.target.value = null;
                }}
                onChange={e => handleChange(e)}
                style={{display: 'none'}}
              />
            </div>
          </FlexWrapper>
          <FormWrapper>
            <TextWrapper isEmpty={isEmptyInput || sitesLengthError}>
              <CustomTextarea paddingToNumber='15px' paddingToText='12px' focus={`#${chechCreateBtn}`} isKeyWordv2={true} autoSize={true} minHeight='270px' width='540px' maxWidth='540px' maxHeight='270px' variant='light' autoSizeCheck={true}
                onChange={handleKeywords}
                value={keywords}
              ></CustomTextarea>
            </TextWrapper>
          </FormWrapper>
          {isEmptyInput ? <ErrorDiv>*Please enter a valid url</ErrorDiv> : ''}
          {fileFormat && <ErrorDiv>Please provide either csv or txt.</ErrorDiv>}
          {sitesLengthError ? <ErrorDiv>*Import up to 20 Sites</ErrorDiv> : ''}
          <div style={{marginTop: '15px'}}>
            <CheckboxStyled checked={isCheckboxChecked} onChange={handleClickOnCheckbox} color='#7F4EAD'>
            Mark all added sites as “Saved Projects”
            </CheckboxStyled>
          </div>
        </>
      </Modal>

    </>
  );
});

const TitleDark = styled.div`
  color: #121212;
  font-size: 20px;
  font-weight: 700;
  margin-top: 5px;
`;
const GrayText = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  color: #4E5156;
`;
const FlexWrapper = styled.div<{justify?: string; margin?: string; gap?: string}>`
  display: flex;
  align-items: center;
  justify-content: ${p=> p?.justify};
  margin: ${p=> p?.margin};
  gap: ${p=> p?.gap};
`;
const ImportTag = styled.div`
 color: #2D6CCA;
  font-size: 12px;
  line-height: 20px;
`;
const FormWrapper = styled.div`
  display: flex;
`;
const TextWrapper = styled.div<{isEmpty}>`
   margin: 10px 0;
   max-width: 100%;
   overflow: hidden;
   border-radius: 10px;
   border: ${p=> p?.isEmpty ? '1px solid #F44343' : '1px solid #e8e8e8'};
   textarea {
    resize: none !important;
   } 
`;
export const CheckboxStyled = styled(Checkbox)<{color}>`
user-select: none !important;
.ant-checkbox-inner {
    border-color: ${p => p.color};
    border-radius: 4px;
    margin-right: 4px;
}
.ant-checkbox-checked .ant-checkbox-inner {
    background-color:${p => p.color};
    border-color: ${p => p.color};
    border-radius: 4px;
    margin-right: 4px;
}
.ant-checkbox {
  &::after {
    border: none !important;
  }
}
`;
const ErrorDiv = styled.div`
  color: #F44343;
  font-weight: 500;
`;
