import {useStore} from '@/store/root-store';
import {apiError} from '@/utils/api';
import {notification} from '@/utils/notification-v2';
import {getSingleUrlParam} from '@/utils/url';
import {Button, Form, Select, FormInstance} from 'antd';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {languages} from '../../landing-page-optimizer/page-editor/Drawers/AiContentToolsDrawer/languages';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import Flag from '@/components/common-components/components/flag';
import {formatCountryCode} from '../../content-ideas/drawer/languageUtil';
import {RingLoaderV2} from '@/components/common-components/components/skeleton/ring-loader-v2';

const {Option} = Select;
interface Props {
  setOpenModel: (value: boolean) => void;
  form: FormInstance;
}

const AiSettings: React.FC<Props> = ({setOpenModel, form}) => {
  const {
    ottoV2Store: {
      updateOttoV2Project,
      loadOttoV2Project,
      ottoV2Project,
      loadingProject,
    },
    settings: {customer: {profile: {whitelabelOtto}}},
  } = useStore('');
  const uuid = getSingleUrlParam('uuid');
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState<{language: string; code: string}>();

  const aiModelOptions = [{label: 'OpenAI (GPT)', value: 'openai'}, {label: 'Anthropic (Claude)', value: 'anthropic'}];
  useEffect(() => {
    if (ottoV2Project?.languageCode !== null) {
      setLanguage({language: languages?.find(item => item?.code === ottoV2Project?.languageCode)?.language, code: ottoV2Project?.languageCode});
    } else {
      setLanguage({language: 'Automatic Detection', code: null});
    }
  }, []);
  const onLanguageChangeHandler = async (value, option) => {
    if (option.value) {
      try {
        // await updateUserAiSettings({language: option.value});
        setLanguage({language: option.country, code: option.value});
      } catch (err) {
        notification.error('', err);
      }
    }
  };

  const onUpdate = async data => {
    setLoading(true);
    try {
      await updateOttoV2Project(uuid, {
        llm_provider: data.llm_provider,
        knowledge_graph: {
          language_code: data?.language_code === 'None' ? null : data?.language_code,
        }});
      await loadOttoV2Project(uuid, true);
      setLoading(false);
      notification.success('', 'AI settings updated successfully');
      setOpenModel(false);
    } catch (error) {
      const errorMessage = apiError(error, `Failed to update ${whitelabelOtto} project details`) as string;
      notification.error('', errorMessage);
      setLoading(false);
    }
  };

  return (
    <>
      {loadingProject ? <RingLoaderV2 height='20vh'/> : <Form style={{marginTop: '8px'}} form={form} onFinish={onUpdate}>
        <StyledDiv style={{display: 'flex'}}>
          <Label>AI Model</Label>
          <StyledFormItem name='llm_provider'>
            <StyledSelect
              suffixIcon={<FontAwesomeIcon color={'#121212'} style={{marginBottom: '5px'}} icon={faCaretDown} />}
              defaultValue='openai'>
              {aiModelOptions.map((item, index) => {
                return (
                  <Option key={index} value={item.value}>
                    {item.label}
                  </Option>
                );
              })}
            </StyledSelect>
          </StyledFormItem>
        </StyledDiv>
        <StyledDiv>
          <Label>Language</Label>
          <StyledFormItem name='language_code'>
            <LanguageSelect
              variant='grey'
              showSearch
              value={language?.language}
              width={'300px'}
              height={'36px'}
              borderRadius={'8px'}
              onChange={onLanguageChangeHandler}
              suffixIcon={<FontAwesomeIcon icon={faCaretDown} color={'#121212'} />}
              filterOption={(input, option) =>
                option.country.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {languages.map((lang, index) => {
                return (
                  <Option disabled={false} key={index} country={lang.language} title={' '} value={lang.code}>
                    <Flag width={12} code={formatCountryCode(lang.language, lang.code)} style={{marginRight: '5px'}}></Flag>
                    {lang.language}
                  </Option>
                );
              })
              }
            </LanguageSelect>
          </StyledFormItem>
        </StyledDiv>
        <Footer>
          <button className='cancel-button' type='button' onClick={() => setOpenModel(false)}>Cancel</button>
          <UpdateAiSettings htmlType='submit' loading={loading}>Update Writing Settings</UpdateAiSettings>
        </Footer>
      </Form>}
    </>
  );
};

export default AiSettings;

const Label = styled.div`
  margin-bottom: 5px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  color: #121212;
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledSelect = styled(Select)`
  height: 38px;
  .ant-select-selector {
    border-radius: 8px !important;
    background-color: #F9F9FB !important;
  }
`;

// const StyledTextArea = styled(TextArea)`
//   min-height: 140px !important;
//   border-radius: 8px;
//   background-color: #F9F9FB !important;
// `;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 14px;
  width: 300px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 31px;
  margin-top: 30px;
  .cancel-button {
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: left;
    color: #4E5156;
    background-color: transparent;
    border: 0px;
  }
`;

const UpdateAiSettings = styled(Button)`
  width: 196px;
  height: 44px;
  border-radius: 8px;
  background-color: #2D6CCA !important;
  color: #fff !important;
`;
const dropdownColors = {
  grey: {
    color: '#333',
    backgroundColor: '#F9F9FB',
    listItemsBackgroundColor: '#F9F9FB',
    borderColor: '#F9F9FB',
  },
};
const LanguageSelect = styled(Select)<{variant?: string; borderRadius?: string; width?: string; height?: string; marginLeft?: string}>`
  color:${ p => dropdownColors[p.variant].color};
    background-color: ${ p => dropdownColors[p.variant].backgroundColor} !important;
    border-radius: ${ p => p.borderRadius ? p.borderRadius : '5px'} !important;
  .ant-select-selector {
    /* max-width:180px !important; */
    min-width: ${p => p.width ? p.width : '168px'} !important;
    width: ${p => p.width ? p.width : '168px'} !important;
    height: ${p => p.height ? p.height : '38px'} !important;
    border-radius: ${ p => p.borderRadius ? p.borderRadius : '5px'} !important;
    background-color: ${ p => dropdownColors[p.variant].backgroundColor} !important;
    border-color: #E8E8E8 !important;
    padding: 1px 8px 2px 11px !important;
    cursor: pointer !important;
  }
  .ant-select-selection-search {
    padding: 0px !important;

    .ant-select-selection-search-input{
      height: 32px !important;
    }
  }

 .ant-select-arrow {
  color:${ p => dropdownColors[p.variant].color};
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    background-color: ${ p => dropdownColors[p.variant].backgroundColor} !important;
    border-color: ${ p => dropdownColors[p.variant].borderColor} !important
  }
  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
`;
