import {observer} from 'mobx-react-lite';
import React, {Fragment} from 'react';
import {UpdateProfileSectionV2} from './UpdateProfile/updateProfileV2';
import {UpdatePasswordSectionV2} from './UpdatePassword/updatePasswordV2';

export const YourProfileV2: React.FC = observer(() => {
  return (
    <Fragment>
      <UpdateProfileSectionV2 />
      <UpdatePasswordSectionV2 />
    </Fragment>
  );
});
