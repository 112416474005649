import {faSearch} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {Input} from 'antd';
interface props {
  Placeholder?: string;
  onSearchChange?: (search: string) => void;
  value?: string;
  width?: string;
  height?: string;
  bottom?: string;
  isKeywordRankTracker?: boolean;
  allowClear?: boolean;
}
export const SearchV2 = observer(({Placeholder, onSearchChange, value='', height, width, isKeywordRankTracker=false, allowClear=false}:props) => {
  return (<DivStyled className='spanFull'>
    <InputStyled isKeywordRankTracker={isKeywordRankTracker} height={height} width={width} prefix={<IconStyled isKeywordRankTracker={isKeywordRankTracker} icon={faSearch}/>}
      value={value} placeholder={Placeholder} onChange={e => onSearchChange(e.target.value)} allowClear={allowClear}/>
  </DivStyled>
  );
});
const DivStyled = styled.div`
 display: flex;
 flex-direction: row;
 position: relative;
`;
const InputStyled = styled(Input)<{height: string; width: string; isKeywordRankTracker: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${p => p?.height ? p.height : '39px'};
  width: ${p => p?.width ? p.width : '150px'};
  background: #FFFFFF;
  border: 1px solid #CDCDCD;
  border-radius: 6px;
  cursor: pointer;
  box-shadow: none !important;
  outline: none !important;
    &:hover {
      background: #E8E8E8;
      .ant-input {
        background: #E8E8E8;
      }
    }
    ::placeholder{
      color: ${p => p?.isKeywordRankTracker ? 'rgba(78, 81, 86, 1)' : '#A3A4A4'};
    }
  @media screen and (max-width: 504px) {
  width: 100%;
  }
  @media screen and (max-width: 630px) {
    height: fit-content !important;
    .ant-input {
      font-size: 12px;
    }
  }
`;

const IconStyled = styled(FontAwesomeIcon)<{isKeywordRankTracker: boolean}>`
  color: ${p => p?.isKeywordRankTracker ? 'rgba(78, 81, 86, 1)' : '#A3A4A4'};
`;
