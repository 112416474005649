import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import TextArea from 'antd/lib/input/TextArea';
import {openUrl} from '@/utils/router';
import {addProtocolToDomain} from '@/utils/url';
import {Button, Modal, Tooltip} from 'antd';
import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react';

const EditTargetKeywordsModal = observer(({modalConf, setModalConf, uuid}) => {
  const [keywords, setKeywords] = useState('');
  const [error, setError] = useState(false);
  const [emptyError, setEmptyError] = useState(false);
  const {ottoV2Store: {updateTargetKeywords, loadingEditTargetKeyword, loadIssueTableData}} = useStore('');

  useEffect(() => {
    setKeywords(modalConf?.keywords?.join(', '));
  }, [modalConf?.keywords]);

  const handleKeywordUpdate = async () => {
    if (error || emptyError) return;
    const prePayload = {target_keywords: keywords};
    await updateTargetKeywords(modalConf?.id, uuid, prePayload);
    setModalConf({visible: false, keywords: [], path: '', id: null});
    const params = {
      uuid,
      issue_type: modalConf.issueType,
      page_size: 10,
      page: 1,
      is_loading: false,
    };
    await loadIssueTableData(params, false);
  };

  return (
    <div>
      <StyledModal
        visible={modalConf.visible}
        onCancel={() => {
          setModalConf({visible: false, keywords: [], path: '', id: null});
          setError(false);
          setEmptyError(false);
        }}
        footer={false}
        width={560}
      >
        <div className='title'>Edit Target Keywords</div>
        <div className='url-container'>
          <div className='target-page'>Target Page: </div>
          <Tooltip title={modalConf.path}>
            <div className='url' onClick={() => openUrl(addProtocolToDomain(modalConf.path), '_blank')}>{modalConf.path}</div>
          </Tooltip>
        </div>
        <div className='target-keywords'>Target Keywords (Divide by commas)</div>
        <StyledTextArea
          error={error || emptyError}
          onChange={e => {
            const value = e.target.value;
            if (value?.split(',').length > 3) {
              setError(true);
            } else {
              setError(false);
            }
            if (!value.length) {
              setEmptyError(true);
            } else {
              setEmptyError(false);
            }
            setKeywords(value);
          }}
          value={keywords}
          disabled={loadingEditTargetKeyword}
        />

        {error && <p className='error-message'>Maximum number of keywords you can select is 3</p>}
        {emptyError && <p className='error-message'>Keyword is required.</p>}
        <div className='footer'>
          <Button
            className='update'
            onClick={handleKeywordUpdate}
            loading={loadingEditTargetKeyword}
          >
            Update Keywords
          </Button>
          <button className='cancel' onClick={() => {
            setModalConf({visible: false, keywords: [], path: '', id: null});
            setError(false);
            setEmptyError(false);
          }}>Cancel</button>
        </div>
      </StyledModal>
    </div>
  );
});

export default EditTargetKeywordsModal;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px;
  }
  .title {
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 29.05px;
    text-align: left;
    color: #121212;
  }
  .url-container {
    display: flex;
    align-items: center;
    margin-top: 13px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    .target-page {
      color: #121212;
      white-space: nowrap;
    }
    .url {
    margin-left: 8px;
    color: #2D6CCA;
    max-width: 400px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
    cursor: pointer;
  }
  }
  .target-keywords {
    margin-top: 16px;
    margin-bottom: 8px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #121212;
  }
  .footer {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-top: 16px;
    .update {
      width: 168px;
      height: 44px;
      gap: 10px;
      border-radius: 8px;
      background-color: #2D6CCA !important;
      color: #fff;
      &:hover, &:focus {
        border: 0px;
        color: #fff;
      }
    }
    .cancel {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.94px;
      text-align: left;
      color: #4E5156;
      background-color: transparent;
      border: 0px;
      cursor: pointer;
    }
  }
  .ant-modal-close {
    top: -19px;
    right: -47px;
    svg {
      color: #fff;
    }
  }
  .error-message {
    color:#ff4d4f;
    font-size: 12px;
  }
`;

const StyledTextArea = styled(TextArea)<{error?: boolean}>`
  width: 504px !important;
  height: 120px !important;
  border-radius: 8px;
  border: 1px solid ${p => p.error ? '#ff4d4f' : '#D8D8D8'};
  &:focus, &:hover {
    border: 1px solid ${p => p.error ? '#ff4d4f' : '#D8D8D8'};
    box-shadow: none !important;
  }
`;
