import React from 'react';
import {numberWithCommas} from '@/utils/number';
import {faMoneySimpleFromBracket} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Tooltip, Divider, Button} from 'antd';
// import styles from './styles.module.scss';
import styled from 'styled-components';
import {useStore} from '@/store/root-store';
import useWindowSize from '@/utils/hooks/useWindowSize';
import {observer} from 'mobx-react';


interface Guide {
  title: string;
  value: number | string;
}

export const QuotaTooltip = observer(({
  description,
  showGuide,
  showHeatmapGuide,
  isConsemed,
  isRemaining,
  isTotal,
  guideData,
  showCta,
  customCta,
  ctaColor,
  ctaLabel,
  heatmapGuideData,
  ctaIcon,
  showTopup = false,
  showTopupAfterDesc = false,
  topupCallback,
  tooltipOnVisibleChange,
  visible,
  infoDescriptionWidth,
  infoDescriptionIcon,
  infoDescription,
  tooltipWidth,
  dontShowGuideData,
  tooltipPlacement = 'bottom',
  disableCtaButton= false,
  varient= 'white',
  consumedData,
  totalData,
  forHeatmap = false,
  component,
}:any)=> {
  const difference = totalData - consumedData;
  const {settings: {customer: {profile: {isVendastaClient}}}} = useStore(null);
  const {width} = useWindowSize();

  const alignConfig = {
    points: ['tr', 'br'],
    offset: [-50, 4],
  };
  return <Tooltip
    trigger={'hover'}
    align={dontShowGuideData ? alignConfig : {}}
    overlayInnerStyle={{
      width: showGuide ? 200 : showHeatmapGuide ? width > 650 ? tooltipWidth : 'auto' : 157,
      padding: showHeatmapGuide ? '15px' : '10px',
      background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), #2D2F34',
      boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.08)',
      borderRadius: 8,
    }}
    placement={tooltipPlacement}
    onVisibleChange={tooltipOnVisibleChange && tooltipOnVisibleChange}
    visible={visible}
    title={<>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <TooltipTitle showHeatmapGuide={showHeatmapGuide} showGuide={showGuide}>{description}</TooltipTitle>
        {showTopup && <span style={{display: 'flex'}}> <TopupButton disabled={isVendastaClient} onClick={() => !isVendastaClient && topupCallback()}>
          <FontAwesomeIcon icon={faMoneySimpleFromBracket} color='#fff' /> Top up
        </TopupButton></span>}
      </div>
      {!showGuide ? showHeatmapGuide && forHeatmap ? null : <Divider style={{background: '#4E5156', margin: '10px 0px'}}/> : null}
      {!showHeatmapGuide && !forHeatmap ? <><div style={{display: 'flex', justifyContent: 'space-between', color: '#fff', fontSize: 12}}>
        <> <span>{isConsemed ?? 'Used'}:</span><span>{numberWithCommas(consumedData)}</span></>
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between', color: '#fff', fontSize: 12}}>
        <> <span>{isRemaining ?? 'Remaining'}:</span><span style={{color: difference > 0 ? '#15ac47' : '#f44343'}}>{difference > 0 ? numberWithCommas(difference) : 0}</span></>
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between', color: '#fff', fontSize: 12}}>
        <> <span> {isTotal ?? 'Total'}:</span><span>{totalData ? numberWithCommas(totalData) : 0}</span></>
      </div></> : <div style={{width: '120px'}}>
        <div style={{display: 'flex', justifyContent: 'space-between', color: '#fff', fontSize: 12}}>
          <span style={{width: '105px', color: '#fff', fontSize: 12, fontWeight: 600}}>{isConsemed ?? 'Used'}</span>
          <div style={{width: '80px', display: 'flex', alignItems: 'left'}}>
            <span style={{color: '#E8E8E8', fontSize: 12}}>&nbsp;{numberWithCommas(consumedData)}</span>
          </div>
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between', color: '#fff', fontSize: 12}}>
          <span style={{width: '105px', color: '#fff', fontSize: 12, fontWeight: 600}}>{isRemaining ?? 'Remaining'}</span>
          <div style={{width: '80px', display: 'flex', alignItems: 'left'}}>
            <span style={{color: difference > 0 ? '#2AC155' : '#f44343', fontSize: 12}}>&nbsp;{difference > 0 ? numberWithCommas(difference) : 0}</span>
          </div>
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between', color: '#fff', fontSize: 12}}>
          <span style={{width: '105px', color: '#fff', fontSize: 12, fontWeight: 600}}> {isTotal ?? 'Total'}</span>
          <div style={{width: '80px', display: 'flex', alignItems: 'left'}}>
            <span style={{color: '#E8E8E8', fontSize: 12}}>&nbsp;{totalData ? numberWithCommas(totalData) : '-'}</span>
          </div>
        </div>
      </div>}
      {showHeatmapGuide && forHeatmap ? <>
        <GuideInfoWrapper width={infoDescriptionWidth}><FontAwesomeIcon icon={infoDescriptionIcon} color={'#FFFFFF'} /><div>{infoDescription}</div></GuideInfoWrapper>
        {showTopupAfterDesc && <span style={{display: 'flex', marginTop: '20px'}}> <TopupButton disabled={isVendastaClient} style={{cursor: isVendastaClient ? 'not-allowed': 'pointer'}} onClick={topupCallback}>
          <FontAwesomeIcon icon={faMoneySimpleFromBracket} color='#fff' /> Top up
        </TopupButton></span>}
        {!dontShowGuideData && <DataWrapper style={{display: 'flex', justifyContent: 'space-between'}}>
          <div>
            <div style={{fontWeight: 600}}>Circle</div>
            {heatmapGuideData?.circle?.map((item: Guide, key: number) => (
              <div key={key} style={{display: 'flex', justifyContent: 'space-between'}}>
                <span style={{width: '55px', color: '#fff', fontSize: 12, fontWeight: 600}}>{`${item.title}`}</span>
                <div style={{width: '80px', display: 'flex', alignItems: 'left'}}>
                  <span style={{color: '#E8E8E8', fontSize: 12}}>&nbsp;{item.value == '...' ? '' : numberWithCommas(item.value as number)}</span>
                </div>
              </div>
            ))}
          </div>
          <div>
            <div style={{fontWeight: 600}}>Grid</div>
            {heatmapGuideData?.rectangle?.map((item: Guide, key: number) => (
              <div key={key} style={{display: 'flex', justifyContent: 'space-between'}}>
                <span style={{width: '73px', color: '#fff', fontSize: 12, fontWeight: 600}}>{`${item.title}`}</span>
                <div style={{width: '70px', display: 'flex', alignItems: 'left'}}>
                  <span style={{color: '#E8E8E8', fontSize: 12}}>&nbsp;{item.value == '...' ? '' : numberWithCommas(item.value as number)}</span>
                </div>
              </div>
            ))}
          </div>
        </DataWrapper>}
      </> : null}
      {showGuide ? <>
        <Divider style={{background: '#4E5156', margin: '10px 0px'}} />
        <TooltipTitle showGuide={showGuide}>Quota consumption guide</TooltipTitle>
        {guideData?.map((item: Guide, key: number) => (
          <div key={key} style={{display: 'flex', justifyContent: 'space-between', color: '#fff', fontSize: 12}}>
            <> <span>{`${item.title}:`}</span><span>{numberWithCommas(item.value as number)}</span></>
          </div>
        ))}
      </> : null
      }
      {showCta ? <>
        <Divider style={{background: '#4E5156', margin: '10px 0px'}}/>
        <CtaButton disabled={disableCtaButton || isVendastaClient} onClick={() => {
          if (customCta && typeof customCta === 'function' && !(disableCtaButton || isVendastaClient)) {
            customCta();
          }
        }}
        color={ctaColor ?? 'green'}
        varient={varient}
        style={{marginTop: '10px', marginLeft: 0, cursor: isVendastaClient ? 'not-allowed' : ''}}
        >
          {ctaIcon? ctaIcon : ''}
          {ctaLabel ?? ''}
        </CtaButton>
      </> : null}

    </>} >
    {component}
  </Tooltip>;
});


const DataWrapper = styled.div`
  @media screen and (max-width: 650px) {
    flex-wrap: wrap;
  }
`;

const TooltipTitle = styled.div<{showHeatmapGuide?: boolean; showGuide?: boolean; calledFrom?:string}>`
font-family: 'Inter';
font-style: normal;
font-weight: ${p => p.showGuide || p.showHeatmapGuide ? 600 : 400};
font-size: ${p => p.showGuide ? 13 : '12px'};
line-height: ${p => p.showGuide ? 15.73 : 14}px;
color: '#FFFFFF';
margin-bottom: ${p => p.showGuide || p.showHeatmapGuide ? 10 : 0}px;
`;

const GuideInfoWrapper = styled.div<{width?: number | string}>`
  width: ${p => typeof p?.width == 'string' ? p?.width : (typeof p?.width == 'number' ? `${p?.width}px` : '310px')};
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: 12px;
  color: #FFFFFF;
  margin: 10px 0;
  height: auto;
  padding: 3px 10px 3px 8px;
  border-radius: 21px;
  background: #4E5156;
  @media screen and (max-width: 650px) {
    width: auto;
  }
`;

const CtaButton = styled(Button)<{varient?: string; disabled?: boolean}>`
  ${p => p.varient === 'green' ? `
      background-color: rgba(42, 193, 85, 0.15) !important;
      width: 96px;
      height: 32px;
      flex-shrink: 0;
      border-radius: 5px;
      border: none;
      margin-top: 12px;
      cursor: pointer;
      color: #fff !important;
      &:hover{
        color: #fff;
      }
  ` : `
    color: black !important;
  `}
  ${p => p.disabled ? `
  cursor: not-allowed !important;
  
  ` : 'cursor: pointer'}
`;

const TopupButton = styled(Button)<{disabled?: boolean}>`
  display: flex;
  align-items: center;
  gap: 4.5px;
  width: 96px;
  height: 32px;
  border-radius: 6px;
  background-color: #2AC155 !important;
  color: #fff !important;
  border: 0px;
  &:hover {
    color: #fff;
  }
  &:focus {
    color: #fff;
  }

    ${p => p.disabled ? `
    cursor: not-allowed !important; 
  ` : 'cursor: pointer'}
`;
