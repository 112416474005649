import {types, Instance, flow} from 'mobx-state-tree';
import {initCriteriaStore, Criteria, CriteriaType} from '@/store//gsc-store/criteria';
import {initCoreReports, CoreReports} from '@/store/gsc-store/report';
import {initFilters, FilterModel} from '@/store/gsc-store/filter';
import {initSpPerformanceReport, SpPerformanceReport} from '@/store/gsc-store/sp-performance';
import {initPageGroupStore, PageGroupStore} from '@/store/gsc-store/page-groups';
import {initWatchlistsStore, WatchlistsStore} from '@/store/gsc-store/watchlist';
import {BannerVariant} from '@/components/common-components/components/banner';
import {initKeywordSearchTable, keywordSearchTable} from '@/store/gsc-store/keyword-search-table/keywords';
import {initPagesSearchTable, pagesSearchTable} from '@/store/gsc-store/keyword-search-table/pages';
import {initKeywordMovementsTable, keywordMovementsTable} from '@/store/gsc-store/keyword-search-table/keywordMovements';
import {initKeywordCannibalization, keywordCannibalization} from '@/store/gsc-store/keyword-search-table/keywordCannibalization';
import {initHistoricalData, HistoricalData} from '@/store/gsc-store/keyword-search-table/pages';
import {initKeywordHistoricalData, KeywordsHistoricalData} from '@/store/gsc-store/keyword-search-table/keywords';
import {initGscExportStore, GscExportStore} from './gsc-export';
import {initSitePropertyEvents, SitePropertyEvents} from '@/store/gsc-store/site-property-events';
import {initTopPagesStore, TopPagesStore} from '@/store/gsc-store/top-pages';
import {initTopKeywordsChangesStore, TopKeywordsChangesStore} from '@/store/gsc-store/top-keywords';
import {GscBacklinksStore, initGscBacklinksStore} from '@/store/gsc-store/backlinks';
import {GscReportsStore, initGscReportsStore} from './gscReports';
import {CompetitorDescoveryStore, initCompetitorDescoveryStore} from './competitor-descovery';
import {GoogleAnalyticsStore, initGoogleAnalyticsStore} from './google-analytics';
import {SiteStructureStore, initSiteStructureStore} from './site-structure';
import {initKeywordCannibalizationNew, keywordCannibalizationNew} from './keyword-search-table/cannibalizationNew';
import {initKeywordMovementsNew, keywordMovementsNew} from './keyword-search-table/keywordMovementsNew';
import {getSingleUrlParam} from '@/utils/url';

export const GSCStore = types.model({
  criteria: Criteria,
  keywordSearchTable: keywordSearchTable,
  pagesSearchTable: pagesSearchTable,
  keywordMovementsTable: keywordMovementsTable,
  keywordCannibalization: keywordCannibalization,
  keywordCannibalizationNew: keywordCannibalizationNew,
  keywordMovementsNew: keywordMovementsNew,
  historicalData: HistoricalData,
  keywordsHistoricalData: KeywordsHistoricalData,
  coreReports: CoreReports,
  gscReports: GscReportsStore,
  filterModel: FilterModel,
  spPerformanceReport: SpPerformanceReport,
  competitorDescovery: CompetitorDescoveryStore,
  showProgressToast: types.boolean,
  pageGroupStore: PageGroupStore,
  pricingModalOpened: types.boolean,
  sitePropertyEvents: SitePropertyEvents,
  gscBacklinks: GscBacklinksStore,
  watchlists: WatchlistsStore,
  gscExport: GscExportStore,
  googleAnalytics: GoogleAnalyticsStore,
  siteStructure: SiteStructureStore,
  loading: types.boolean,
  banner: types.model({
    show: types.boolean,
    title: types.string,
    subtitle: types.string,
    variant: types.enumeration<BannerVariant>('BannerVariant', Object.values(BannerVariant)),
  }),
  isDrawerVisible: types.maybeNull(types.boolean),
  isTeamMember: types.maybeNull(types.boolean),
  isBannerVisible: types.maybeNull(types.boolean),
  topPagesStore: TopPagesStore,
  topKeywordsStore: TopKeywordsChangesStore,
  siteEventsRelativeDays: types.maybeNull(types.string),
  siteEventsRange: types.maybeNull(types.string),
  previousListInStore: types.maybeNull(types.string),
  scrollRef: types.maybeNull(types.string),
  isSettingModalVisible: types.maybeNull(types.boolean),
}).actions(self => {
  const hideProgressToast = () => {
    self.showProgressToast = false;
  };

  const setPreviousListInStore = value => {
    self.previousListInStore = value;
  };

  const loadGSCData = flow(function* (criteria: CriteriaType, isLoadPageGroups?:boolean) {
    const publicHash = getSingleUrlParam('public_hash');
    self.loading = true;
    const coreReports = self.coreReports.loadCoreReports(criteria);
    const spPerformanceReport = self.spPerformanceReport.loadPerformanceReport(criteria.property, criteria.countryCode);
    const reLoadPageGroupings = isLoadPageGroups && !publicHash && self.coreReports.pageGroupingStatsStore.reLoadPageGroupings(criteria);
    const pageGroupings = isLoadPageGroups && !publicHash && self.pageGroupStore.loadPageGroups(criteria);
    const watchlists = yield self.watchlists.fetchWatchlists();
    self.loading = false;
    return {
      coreReports,
      spPerformanceReport,
      reLoadPageGroupings,
      watchlists,
      pageGroupings,
    };
  });

  const togglePricingModal = () => self.pricingModalOpened = !self.pricingModalOpened;

  const setBanner = (show: boolean, title: string, subtitle: string, variant: BannerVariant) => {
    self.banner = {
      show,
      title,
      subtitle,
      variant,
    };
  };

  const setDrawerVisibility = (visible: boolean) => {
    self.isDrawerVisible = visible;
  };
  const setIsTeamMember = (visible: boolean) => {
    self.isTeamMember = visible;
  };
  const setBannerVisibility = (visible: boolean) => {
    self.isBannerVisible = visible;
  };

  const setSiteEventsRelativeDays = value => {
    self.siteEventsRelativeDays = value;
  };

  const setSiteEventsRange = value => {
    self.siteEventsRange = value;
  };

  const setScrollRef = value =>{
    self.scrollRef=value;
  };
  const setSettingModalVisible = (visible: boolean) => {
    self.isSettingModalVisible = visible;
  };
  return {
    setScrollRef,
    hideProgressToast,
    loadGSCData,
    togglePricingModal,
    setBanner,
    setIsTeamMember,
    setDrawerVisibility,
    setBannerVisibility,
    setSiteEventsRelativeDays,
    setSiteEventsRange,
    setPreviousListInStore,
    setSettingModalVisible,
  };
});

export type GSCStoreType = Instance<typeof GSCStore>;

export function initGSCStore() {
  return GSCStore.create({
    criteria: initCriteriaStore(),
    keywordSearchTable: initKeywordSearchTable(),
    pagesSearchTable: initPagesSearchTable(),
    keywordMovementsTable: initKeywordMovementsTable(),
    keywordCannibalization: initKeywordCannibalization(),
    keywordCannibalizationNew: initKeywordCannibalizationNew(),
    keywordMovementsNew: initKeywordMovementsNew(),
    historicalData: initHistoricalData(),
    keywordsHistoricalData: initKeywordHistoricalData(),
    gscBacklinks: initGscBacklinksStore(),
    coreReports: initCoreReports(),
    gscReports: initGscReportsStore(),
    filterModel: initFilters(),
    spPerformanceReport: initSpPerformanceReport(),
    competitorDescovery: initCompetitorDescoveryStore(),
    pageGroupStore: initPageGroupStore(),
    watchlists: initWatchlistsStore(),
    gscExport: initGscExportStore(),
    sitePropertyEvents: initSitePropertyEvents(),
    pricingModalOpened: false,
    showProgressToast: false,
    loading: false,
    banner: {
      show: false,
      title: '',
      subtitle: '',
      variant: BannerVariant.ERROR,
    },
    isDrawerVisible: false,
    isBannerVisible: false,
    topPagesStore: initTopPagesStore(),
    topKeywordsStore: initTopKeywordsChangesStore(),
    siteEventsRelativeDays: '',
    siteEventsRange: '',
    previousListInStore: '',
    googleAnalytics: initGoogleAnalyticsStore(),
    siteStructure: initSiteStructureStore(),
    scrollRef: '',
  });
}
