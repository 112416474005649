import React, {useEffect, useState} from 'react';
import {Modal, Spin, Upload} from 'antd';
import styled from 'styled-components';
import {Button} from '@/components/common-components/v2';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight, faFileArrowUp, faFileCheck, faFileSpreadsheet, faXmark as regFaXmark} from '@fortawesome/pro-regular-svg-icons';
import {notification} from '@/utils/notification-v2';
import {LoadingOutlined} from '@ant-design/icons';
import {googleSheetUrlValidationRegex} from '@/constants/regex';
import styles from '../style.module.scss';
import {useStore} from '@/store/root-store';
import {isSAPublicPage} from '@/utils/url';
import {QuotaInfoDetail} from './quotaInfo';
import {read, utils} from 'xlsx';
import {fileErrors} from '@/utils/const';
import {PAGES_API} from '@/api/content-optimizer';
const {Dragger} = Upload;

interface writeInBulkProps {
  setOpenWriteInBulkModal: (value: boolean) => void;
  openWriteInBulkModal: boolean;
  quota: any;
}

const WriteInBulkModal = ({openWriteInBulkModal, setOpenWriteInBulkModal, quota} : writeInBulkProps) => {
  const {isQuota, isSA, consumedData, totalData, quotaDescription, isCenter, showGuide, guideData, scaPublicConsumed, scaPublicTotal} = quota;

  const {
    contentOptimizer: {
      aiOutline,
      pages: {activeProjectId, currentProject, setShowAiProgressTooltip},
    },
  } = useStore('');
  const {contentAuditStore: {fetchAuditData}} = useStore('');
  const {settings: {customer: {profile: {isWhitelabel}}}} = useStore('');
  const {settings: {customer: {profile: {isAllowedAiContentGenerationQuotaLimitReached}}}} = useStore('');

  const [file, setFile] = useState(null);
  const [urlUpload, setUrlUpload] = useState(false);
  const [fileUrl, setFileUrl] = useState('');
  const [isFileError, setIsFileError] = useState<boolean>(false);
  const [articlesLength, setArticlesLength] = useState(0);
  const [isNewPageLoading, setIsNewPageLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [showInputError, setShowInputError] = useState(false);
  const [findKeyword] = useState<boolean>(true);

  const onClose = () => {
    setOpenWriteInBulkModal(false);
    setFile(null);
    setFileUrl('');
    setUrlUpload(false);
    setIsFileError(false);
    setShowInputError(false);
  };

  useEffect(() => {
    getArticles();
  }, [file]);

  const getArticles = async () => {
    if (file?.name) {
      try {
        const fileURL = URL?.createObjectURL(file);
        const getFile = await fetch(fileURL);
        const bufferFile = await getFile?.arrayBuffer();
        const wordBookFile = read(bufferFile);
        const ws = wordBookFile?.Sheets[wordBookFile.SheetNames[0]];
        const fileToJson = utils?.sheet_to_json(ws);
        let formattedData = [];
        fileToJson &&
          fileToJson.forEach((item: any, index: number) => {
            if ((item['A'] || item['B']) && index != 0) {
              formattedData.push(item);
            }
          });
        if ((formattedData?.length || fileToJson?.length) - 2) {
          // setArticlesInProgress((formattedData?.length || fileToJson?.length) - 2);
          setArticlesLength((formattedData?.length || fileToJson?.length) - 2);
        } else {
          // setArticlesInProgress(0);
          setArticlesLength(0);
        }
        formattedData = [];
      } catch (e) {
        notification.error(
          'Error opening GoogleSheets file.',
          'Please check your file is valid and public.',
          false,
          'OK',
        );
        setDisableBtn(true);
      }
    }
  };

  const clearSelection = () => {
    setFile(null);
    setFileUrl('');
    setUrlUpload(false);
  };

  const onUrlUpload = async (e:any) => {
    e.preventDefault();
    if (file?.name) {
      notification.error('', 'Please clear selection first, either file or url can be used.', false, 'OK');
      return;
    }
    if (googleSheetUrlValidationRegex.test(fileUrl)) {
      try {
        const responses = await fetch(fileUrl);
        const fileData = await responses.blob();
        const fileFromUrl = new File([fileData], 'Bulk SCA Upload Template.xlsx');
        setFile(fileFromUrl);
        setFileUrl(fileUrl);
      } catch (e) {
        setFile('');
      }
      setUrlUpload(true);
      setIsFileError(false);
      setShowInputError(false);
    } else {
      setShowInputError(true);
    }
  };

  const RequiredFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';

  const antIcon = <LoadingOutlined style={{fontSize: 12, color: '#fff', marginRight: 10, marginLeft: -3}} spin />;
  const addEllipsis = (str, n)=>{
    return (str?.length > n) ? str?.slice(0, n-1) + '....' : str;
  };

  const defaultProjects = ['Total Articles', 'Uncategorized Articles'];

  const onCreateBulkPageWithAi = () => {
    setShowInputError(false);

    if (!file?.name && !urlUpload) {
      setIsFileError(true);
      return;
    }
    if (file?.name || urlUpload) setIsFileError(false);

    const formData = new FormData();
    if (urlUpload) {
      formData.append('gsheets_url', fileUrl);
      if (currentProject && activeProjectId && !defaultProjects.includes(currentProject)) {
        formData.append('project', String(activeProjectId));
      }
    } else if (file?.name) {
      formData.append('file', file);
      if (currentProject && activeProjectId && !defaultProjects.includes(currentProject)) {
        formData.append('project', String(activeProjectId));
      }
    }

    if (isWhitelabel && isAllowedAiContentGenerationQuotaLimitReached) {
      notification.error('Quota is exceeded', 'You’ve exceeded the usage limit for this tool.');
    } else if (!isAllowedAiContentGenerationQuotaLimitReached) {
      createBulkPagesWithAi(formData);
    } else {
      aiOutline.setTopUpVisibility(true);
    }
  };


  const createBulkPagesWithAi = async formData => {
    setIsNewPageLoading(true);

    try {
      await PAGES_API.createAuditBulkPages(formData);
      setTimeout( async ()=> {
        // getCustomerQuota();
        await fetchAuditData();
        setFile(null);
        setShowAiProgressTooltip(true);
        setFile('');
        setUrlUpload(false);
        setFileUrl('');
        setIsNewPageLoading(false);
      }, 1000);
      setOpenWriteInBulkModal(false);
    } catch (e) {
      setIsNewPageLoading(false);
      if (e?.response?.status && e?.response?.status === 429) {
        // setUpgradePopup(true);
      } else if (e?.response?.status && e?.response?.status === 406) {
        notification.error('Could not create Article', e?.response?.data?.keywordsList, false);
      } else if (e?.response?.status && e?.response?.status === 400) {
        const nonFieldErrors = e?.response?.data?.nonFieldErrors;
        notification.error(nonFieldErrors? nonFieldErrors : 'Could not create Article', nonFieldErrors? fileErrors[nonFieldErrors] : 'Something went wrong, please try again.', false, 'OK');
      }
      return Promise.reject(e);
    }
    setIsNewPageLoading(false);
  };

  const onUrlChange = e => {
    const value = e.target.value;
    value.length > 0 && setShowInputError(false);
    setDisableBtn(false);
    setFileUrl(value);
    if (isFileError) setIsFileError(false);
  };

  return (
    <div>
      <StyledModal
        visible={openWriteInBulkModal}
        onCancel={onClose}
        footer={null}
        closable={false}
        width={565}
      >
        <div style={{padding: '20px'}}>
          {isSA ? <>
            <QuotaContainer>
              <QuotaInfoDetail consumedData={consumedData} totalData= {consumedData} heading={'Projects'} description={'Total number of Site Audit projects'}/>
              {!isSAPublicPage() && <QuotaInfoDetail consumedData={scaPublicConsumed} totalData= {scaPublicTotal} heading={'Pages'} description={'Maximum number of monitored pages across all projects.'}/>}
            </QuotaContainer>
          </>:isQuota &&
        <QuotaInfoDetail consumedData={consumedData} totalData={totalData} heading={'Audits'} description={quotaDescription ? quotaDescription : 'Total Quota'} isCenter={isCenter} showGuide={showGuide} guideData={guideData} />
          }
          <HeadingAudit>
            <Headingh2>Audit Pages in Bulk</Headingh2>
          </HeadingAudit>
          <StepWrapper>
            <div>
              <StepStyled>
                STEP 1
              </StepStyled>
              <TitleStyled>
                Download and complete the template
              </TitleStyled>
              <DescriptionStyled>
                <div style={{marginBottom: '4px'}}>Make a copy of the template, fill the table and upload it to the form below. <br/><span style={{fontWeight: '530'}}>Don’t forget to make the Google Sheet URL visible to anyone when sharing.</span></div>
                {/* Need help filling the template? <span style={{color: '#34AEF3'}}>Watch a 1 minute video tutorial</span> */}
              </DescriptionStyled>
              <TemplateButton>
                <a href='https://docs.google.com/spreadsheets/d/18VciGyrdELIUpOjqpI1kh-35DetckMqw4k-nofYy6M4/edit?pli=1#gid=0' target='_blank' rel='noopener noreferrer' style={{color: 'white'}}>
                  <div style={{color: '#121212'}}>
                    <FontAwesomeIcon icon={faFileSpreadsheet} color='#2AC155' size={'lg'} style={{marginRight: '10px'}} />Get .xls template
                  </div>
                </a>
              </TemplateButton>
            </div>
            <div>
              <StepStyled>
                STEP 2
              </StepStyled>
              <TitleStyled>
                Upload the filled template
              </TitleStyled>
              {
                file?.name || urlUpload ?
                  <SuccessContainer>
                    <div className='wrapper'>
                      <FontAwesomeIcon icon={faFileCheck} color='#2AC155' size='2x' style={{marginRight: '12px'}} />
                      <div>
                        {fileUrl ?
                          <>
                            <div className='title'>{addEllipsis(fileUrl || '', 50)}</div>
                            {articlesLength ? <div className='description'>Audits to create:&nbsp;{articlesLength}</div> : <></>}
                          </> :
                          <>
                            <div className='title'>{addEllipsis(file?.name || '', 50)}</div>
                            {articlesLength ? <div className='description'>Audits to create:&nbsp;{articlesLength}</div> : <></>}
                          </>
                        }
                      </div>
                    </div>
                    <div className='clear' onClick={clearSelection}>
                      <FontAwesomeIcon icon={regFaXmark} color='#4E5156' size='lg'/>
                    </div>
                  </SuccessContainer> :
                  <FileContainer>
                    <StyledDragger
                      multiple={false}
                      showUploadList={false}
                      accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'}
                      style={{height: '40px'}}
                      disabled={urlUpload}
                      customRequest={(data:any) => {
                        setDisableBtn(false);
                        if (RequiredFileType.split(',').includes(data?.file?.type)) {
                          setIsFileError(false);
                          setFile(data?.file as any);
                        } else {
                          notification.error('', 'Acceptable file format: XLS, Google Sheets, please try again.', false, 'OK');
                        }
                      }}
                      onDrop={ e => {
                        setDisableBtn(false);
                        const getFile = e.dataTransfer.files?.length ? e.dataTransfer.files[0] : null;
                        if (RequiredFileType.split(',').includes(getFile?.type)) {
                          setIsFileError(false);
                          setFile(getFile as any);
                        } else {
                          notification.error('', 'Acceptable file format: XLS, Google Sheets, please try again.', false, 'OK');
                        }
                      }}
                    >
                      <DragContainer>
                        <FontAwesomeIcon icon={faFileArrowUp} color='#1FAC47' size={'2x'} style={{marginBottom: '15px'}}/>
                        <TitleStyled style={{display: 'flex', margin: 'auto'}}>
                          Drop your file here, or&nbsp;<span style={{textDecoration: 'underline'}}>click to browse</span>
                        </TitleStyled>
                        <StepStyled style={{margin: '5px auto 0px auto', color: '#4E5156'}}>
                          File format: XLS, Google Sheets
                        </StepStyled>
                      </DragContainer>
                    </StyledDragger>
                    <div style={{display: 'flex', alignItems: 'self-end'}}>
                      <Dottedline />
                      or
                      <Dottedline />
                    </div>
                    <UrlContainer onSubmit={onUrlUpload}>
                      <input placeholder='Paste Google Sheets URL' value={fileUrl} onChange={onUrlChange} />
                      <button className='button' type='submit'>UPLOAD</button>
                    </UrlContainer>
                    {showInputError && <div style={{color: 'red'}} className={styles.urlErrorMsg}> {showInputError ? 'Enter a valid Google Sheet URL' : null}</div>}
                  </FileContainer>
              }
              {/* <InfoContainer>
                <FontAwesomeIcon icon={faCircleInfo} color='#34AEF3' size='lg' style={{marginRight: '12px'}}/>
                <div>To customize the AI output, please check and adjust your&nbsp;
                  <span style={{color: '#34AEF3', textDecoration: 'underline', cursor: projectId === 0 ? 'not-allowed' : 'pointer'}} onClick={() => setOpenWriteInBulkModal(true)}>Project AI Settings</span>
                </div>
              </InfoContainer> */}
            </div>
          </StepWrapper>
          {isFileError && <div style={{color: 'red'}} className={styles.urlErrorMsg}> {isFileError ? 'Please provide a xls, google sheet file or URL' : null}</div>}
          <FooterContainer>
            <CancelButton onClick={onClose}>Cancel</CancelButton>
            <Button
              disabled={!findKeyword || isNewPageLoading || disableBtn}
              onClick={onCreateBulkPageWithAi}
              color='green'
              style={{height: 44, marginLeft: 0}}
            >
          Bulk Generate Audits
              {isNewPageLoading ? <Spin indicator={antIcon} style={{marginLeft: 5}} /> : <FontAwesomeIcon icon={faArrowRight} style={{marginLeft: 5}}/>}
              <button id='openInNewTabBtn' style={{display: 'none', height: '0', width: '0'}}></button>
            </Button>
          </FooterContainer>
        </div>
      </StyledModal>
    </div>
  );
};

export default WriteInBulkModal;

const StyledModal = styled(Modal)`
  .ant-modal-content{
    background-color: #F2F2F5 !important;
    border-radius: 12px !important;
  }
  .ant-modal-body{
    padding: 11px !important;
  }
`;

const QuotaContainer = styled.div`
display:flex;
gap: 25px;
`;

const HeadingAudit = styled.div`
margin-top: 15px;
`;

const Headingh2 = styled.h2`
  color: var(--text-black, #121212);
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 32px; /* 133.333% */

`;

const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px
`;

const StepStyled = styled.div`
  font-weight: 500;
  line-height: 14.52px;
  font-size: 12px;
  color: #121212;
  margin-bottom: 2px;
`;

const TitleStyled = styled.div`
  font-weight: 500;
  line-height: 19.36px;
  font-size: 14px;
  color: #121212;
  margin-bottom: 2px;
`;

const DescriptionStyled = styled.div`
  font-weight: 400;
  line-height: 14.53px;
  font-size: 12px;
  color: #121212;
  margin-bottom: 2px;
`;

const StyledDragger = styled(Dragger)`
  border: none !important;
  background-color: #FFF !important;
`;

const TemplateButton = styled.button`
  border-radius: 8px;
  border-radius: 8px;
  border: 1px solid #EBEBEB;
  background: var(--White, #FFF);
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.02);
  height: 32px;
  padding: 5px 15px;
  gap: 10px;
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
  font-size: 14px;
  margin: 15px 0;
`;

const FileContainer = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 8px;
  border-radius: 12px;
  border: 1px solid #EBEBEB;
  background: var(--White, #FFF);
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.02);
  width: 100%;
  padding: 25px;
  margin-top: 10px;
  flex-direction: column;
  .dragFile {
    background: #47494D !important;
    border:none;
    .ant-upload {
      padding: 0 0 10px 0;
    }
    .ant-upload-drag {
      height: 50px;
    }
  }
`;

const Dottedline = styled.hr`
  border-color: #E8E8E8 !important;
  border: none;
  border-top: 3px dashed #f00;
  height: 1px;
  width: 45%;
  margin-top: 0px !important;
  margin-bottom: 10px !important;
`;


const DragContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const UrlContainer = styled.form`
  background: #F9F9FB;
  margin-top: 10px;
  border-radius: 6px;
  width: 100%;
  height: 38px;
  padding: 10px 0;
  display: flex;
  align-items: center;
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid var(--text-gray-light, #E8E8E8);
  background: var(--Table-row-stripe, #F9F9FB);

  input {
    width: 100%;
    background: transparent;
    border: none;
    margin: 0 20px;
    outline: none;
    ::-webkit-input-placeholder{
      color: #A3A4A4;
    }
    :-moz-placeholder{
      color: #A3A4A4;
    }
    ::-moz-placeholder{
      color: #A3A4A4;
    }
    :-ms-input-placeholder{
      color: #A3A4A4;
    }
    ::placeholder {
      color: #A3A4A4;
    }
  }

  .button {
    width: 100%;
    height: 100%;
    max-width: 84px;
    height: 38px;
    flex-shrink: 0;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    border-radius: 0px 8px 8px 0px;
    color: #1FAC47;
    background: rgba(31, 172, 71, 0.15);
    border: none;
    cursor: pointer;
    :active {
      color: #00a2ff;
    };
  }
`;

/* const InfoContainer = styled.div`
  display: flex;
  width: 100%;
  background: rgb(52,174,243, 0.2);
  border-radius: 8px;
  font-size: 12px;
  line-height: 22px;
  height: 32px;
  padding: 20px 20px;
  align-items: center;
  font-weight: 400;
  margin: 10px 0;
`; */

const SuccessContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: rgb(42, 193, 85, 0.2);
  border-radius: 12px;
  height: 60px;
  padding: 20px 20px;
  align-items: center;
  font-weight: 400;
  margin: 10px 0;

  .wrapper {
    display: flex;
    align-items: center;

    .title {
      font-size: 14px;
      line-height: 16.94px;
      margin: 5px 0;
    }
  
    .description {
      font-size: 12px;
      line-height: 14.52px;
    }
  }
  .clear {
    background: transparent;
    padding: 10px 15px;
    border-radius: 8px;
    cursor: pointer;
    :active {
      background: transparent;
    };
  };
`;

const FooterContainer = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
`;
const CancelButton = styled.button`
  display: inline-flex;
  padding: 11px 25px 11px 25px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--text-gray-light, #E8E8E8);
  background: var(--Table-row-stripe, #F9F9FB);
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.02);
  cursor: pointer;
`;
