import {cast, flow, types} from 'mobx-state-tree';
import {planApi, stripeApi} from '@/api/account';
import moment from 'moment';
import {getPricingArray} from '@/store/pricing/data';
import {getSingleUrlParam} from '@/utils/url';
import Router from 'next/router';
import {routes} from '@/utils/const';
import {TFunction} from 'next-i18next';
import {notification} from '@/utils/notification-v2';
import {toJS} from 'mobx';
import {apiError} from '@/utils/api';

export const keywordDefaultData = [
  {
    targetKeyword: '',
    siteURL: '',
    links: 1,
    publicationDAAmount: 100,
    publicationDAName: 'DA 20-30',
    optional: '',
    type: 'targetKeyword',
  }];

const featuresModel = types.model({
  str: types.maybeNull(types.string),
  iconColor: types.maybeNull(types.string),
  infoTooltip: types.maybeNull(types.string),
  iconName: types.maybeNull(types.string),
  background: types.maybeNull(types.boolean),
  isBold: types.maybeNull(types.boolean),
  header: types.maybeNull(types.boolean),
  isUnderLine: types.maybeNull(types.boolean),
  comingSoon: types.maybeNull(types.string),
  pointsList: types.maybeNull(types.array(types.string)),
});

export const Plan = types.model({
  checkmark: types.optional(types.string, ''),
  check1: types.optional(types.string, ''),
  check2: types.optional(types.string, ''),
  check3: types.optional(types.string, ''),
  check4: types.optional(types.string, ''),
  check5: types.optional(types.string, ''),
  check6: types.optional(types.string, ''),
  check7: types.optional(types.string, ''),
  check8: types.optional(types.string, ''),
  check9: types.optional(types.string, ''),
  check10: types.optional(types.string, ''),
  check11: types.optional(types.string, ''),
  check12: types.optional(types.string, ''),
  check13: types.optional(types.string, ''),
  check14: types.optional(types.string, ''),
  bestValueTag: types.maybeNull(types.boolean),
  id: types.maybeNull(types.number),
  icon: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  buttonOverride: types.maybeNull(types.boolean),
  main: types.maybeNull(types.boolean),
  disabled: types.maybeNull(types.boolean),
  buttonType: types.maybeNull(types.string),
  darker: types.maybeNull(types.boolean),
  buttonColor: types.maybeNull(types.string),
  buttonTextColor: types.maybeNull(types.string),
  tooltip: types.maybeNull(types.boolean),
  price: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  iconsTooltipText: types.maybeNull(types.string),
  buttonText: types.maybeNull(types.string),
  summary: types.maybeNull(types.string),
  features: types.maybeNull(types.array(featuresModel)),
  date: types.maybeNull(types.string),
  oldPrice: types.maybeNull(types.string),
  yearlyCost: types.maybeNull(types.string),
  yearlyCostAmount: types.maybeNull(types.string),
  productionCheckoutLink: types.maybeNull(types.string),
  stagingCheckoutLink: types.maybeNull(types.string),
  checkoutLink: types.maybeNull(types.string),
});

export const Card = types.model({
  address_city: types.maybeNull(types.string),
  address_country: types.maybeNull(types.string),
  address_line1: types.maybeNull(types.string),
  address_line1_check: types.maybeNull(types.string),
  address_line2: types.maybeNull(types.string),
  address_state: types.maybeNull(types.string),
  address_zip: types.maybeNull(types.string),
  address_zip_check: types.maybeNull(types.string),
  brand: types.maybeNull(types.string),
  country: types.maybeNull(types.string),
  cvc_check: types.maybeNull(types.string),
  dynamic_last4: types.maybeNull(types.string),
  exp_month: types.maybeNull(types.number),
  exp_year: types.maybeNull(types.number),
  funding: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
  last4: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  object: types.maybeNull(types.string),
  tokenization_method: types.maybeNull(types.string),
});

export const Stripe = types.model({
  card: types.maybeNull(Card),
  client_ip: types.maybeNull(types.string),
  created: types.maybeNull(types.number),
  id: types.maybeNull(types.string),
  livemode: types.maybeNull(types.boolean),
  object: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  used: types.maybeNull(types.boolean),
});

export const Invoice = types.model({
  id: types.maybeNull(types.number),
  amount: types.maybeNull(types.string),
  slug: types.maybeNull(types.string),
  next_payment_at: types.maybeNull(types.string),
  plan_name: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
});

export const keywordDataList = types.model({
  targetKeyword: types.maybeNull(types.string),
  siteURL: types.maybeNull(types.string),
  links: types.maybeNull(types.number),
  publicationDAAmount: types.maybeNull(types.number),
  optional: types.maybeNull(types.string),
  publicationDAName: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
});

export const servicesProductsVariantsModel = types.model({
  checkoutPrompt: types.maybeNull(types.string),
  idx: types.maybeNull(types.number),
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  shortDescription: types.maybeNull(types.string),
  sku: types.maybeNull(types.string),
  unitPrice: types.maybeNull(types.string),
  unitPriceCurrency: types.maybeNull(types.string),
  orderDetailsFormLink: types.maybeNull(types.string),
});

export const servicesProductsModel = types.model({
  id: types.maybeNull(types.number),
  idx: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  productDetailsHtml: types.maybeNull(types.string),
  shortDescription: types.maybeNull(types.string),
  slug: types.maybeNull(types.string),
  variants: types.maybeNull(types.array(servicesProductsVariantsModel)),
});

export const plansDataModel = types.model({
  description: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  monthlyCost: types.maybeNull(types.string),
  monthlyCostCurrency: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  quarterlyCost: types.maybeNull(types.string),
  quarterlyCostCurrency: types.maybeNull(types.string),
  quotaBlAllowedBacklinkResearches: types.maybeNull(types.number),
  quotaBlBudget: types.maybeNull(types.number),
  quotaBlMaxCompetitorsPerProject: types.maybeNull(types.number),
  quotaBlMaxNumberOfProjects: types.maybeNull(types.number),
  quotaBlUseDemo: types.maybeNull(types.boolean),
  quotaBulkDaCheckerMaxLookupsPerDay: types.maybeNull(types.number),
  quotaCaAllowedAiContentGeneration: types.maybeNull(types.number),
  quotaCaAllowedFocusTerms: types.maybeNull(types.number),
  quotaCaAllowedOnpageAudits: types.maybeNull(types.number),
  quotaCaAllowedPages: types.maybeNull(types.number),
  quotaCaAllowedSiteAuditorPages: types.maybeNull(types.number),
  quotaCaAllowedSiteAuditorProjects: types.maybeNull(types.number),
  quotaCaCrawledPagesAllowedPerMonth: types.maybeNull(types.number),
  quotaGscAllowedActiveProjects: types.maybeNull(types.number),
  quotaCaAllowedOttoV2Projects: types.maybeNull(types.number),
  quotaGscAllowedPages: types.maybeNull(types.number),
  quotaGscAllowedSiteKeywords: types.maybeNull(types.number),
  quotaGscHistoricalLimit: types.maybeNull(types.string),
  quotaGscProjectDeactivationsAllowedPerMonth: types.maybeNull(types.number),
  quotaKeAllowedCompetitorResearches: types.maybeNull(types.number),
  quotaKeAllowedTrackedKeywords: types.maybeNull(types.number),
  quotaKeAllowedTrackedProjects: types.maybeNull(types.number),
  quotaKeMaxKeywordLookups: types.maybeNull(types.number),
  quotaKeMaxKeywordLookupsPerWeek: types.maybeNull(types.number),
  quotaPsiAllowedPsiPages: types.maybeNull(types.number),
  quotaKeAllowedLocalSerpsHeatmapSearches: types.maybeNull(types.number),
  quotaQpAllowedHaroReplies: types.maybeNull(types.number),
  quotaCaAllowedAiPremiumContentGeneration: types.maybeNull(types.number),
  seats: types.maybeNull(types.number),
  yearlyCost: types.maybeNull(types.string),
  yearlyCostAmount: types.maybeNull(types.string),
  yearlyCostCurrency: types.maybeNull(types.string),
  quotaKeAllowedContentPlans: types.maybeNull(types.number),
  quotaCaAllowedOttoPages: types.maybeNull(types.number),
  quotaCaHyperdriveCredits: types.maybeNull(types.number),
});
export const plansAndPricesModel = types.model({
  planId: types.maybeNull(types.number),
  price: types.maybeNull(types.string),
  yearlyCost: types.maybeNull(types.string),
  yearlyCostAmount: types.maybeNull(types.string),
});

export const orderListModel = types.model({
  variantId: types.maybeNull(types.number),
  key: types.maybeNull(types.number),
  amount: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  subTitle: types.maybeNull(types.string),
  productIcon: types.optional(types.string, ''),
  orderDetailsFormLink: types.maybeNull(types.string),
});

export const Plans = types.model({
  count: types.number,
  pageSize: types.maybeNull(types.number),
  plansList: types.maybeNull(types.array(Plan)),
  selectedPlanId: types.maybeNull(types.number),
  submitHandler: types.boolean,
  stripeToken: types.maybeNull(Stripe),
  checkoutConfirmation: types.boolean,
  country: types.string,
  state: types.string,
  phoneCode: types.string,
  serverErrors: types.array(types.string),
  invoiceDetails: types.maybeNull(Invoice),
  alreadyCreated: types.maybeNull(types.string),
  selectedDomainForUpgrade: types.maybeNull(types.string),
  selectedDomainIdForUpgrade: types.maybeNull(types.number),
  projectActivePopup: types.maybeNull(types.boolean),
  sideBarPayment: types.maybeNull(types.boolean),
  loading: types.boolean,
  orderSlug: types.maybeNull(types.string),
  servicesList: types.maybeNull(types.array(types.model({
    variantId: types.maybeNull(types.number),
    key: types.maybeNull(types.string),
    amount: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    subTitle: types.maybeNull(types.string),
    productIcon: types.optional(types.string, ''),
    links: types.optional(types.number, 0),
  }))),
  servicesListFromApi: types.maybeNull(types.array(types.model({
    description: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    image: types.maybeNull(types.string),
    products: types.maybeNull(types.array(servicesProductsModel)),
  }))),
  servicesDetailDrawerData: types.maybeNull(types.string),
  productDetailsHtml: types.maybeNull(types.string),
  keywordData: types.array(keywordDataList),
  additionalServicesData: types.maybeNull(types.number),
  additionalServicesCheckbox: types.boolean,
  promoCode: types.maybeNull(types.boolean),
  products: types.maybeNull(types.array(types.model({
    id: types.maybeNull(types.number),
    name: types.maybeNull(types.string),
  }))),
  servicesDetailDrawer: types.maybeNull(types.boolean),
  orderStepOne: types.maybeNull(types.boolean),
  subscriptionsLoading: types.maybeNull(types.boolean),
  loadProduct: types.maybeNull(types.boolean),
  promoCodeValue: types.maybeNull(types.string),
  inValidStatusPromoCode: types.maybeNull(types.boolean),
  validatingPromoCode: types.maybeNull(types.boolean),
  additionalNotesList: types.maybeNull(types.array(orderListModel)),
  discount: types.number,
  useDiscountPrice: types.maybeNull(types.boolean),
  discountPrice: types.maybeNull(types.number),
  sideBarPaymentContent: types.model({
    title: types.string,
    subtitle: types.string,
    text: types.string,
  }),
  plansAndPrices: types.maybeNull(types.array(plansAndPricesModel)),
  planAndPricesLoading: types.maybeNull(types.boolean),
  plansData: types.maybeNull(types.array(plansDataModel)),
  isSummaryScrolled: types.maybeNull(types.number),
  payAnnualValue: types.boolean,
  changeOttoPlanLoading: types.maybeNull(types.number),
}).views(self => ({
  get getDiscountPrice() {
    return self.discountPrice;
  },

  get getPromoCode() {
    return self.promoCode;
  },
  get getInValidStatusPromoCode() {
    return self.inValidStatusPromoCode;
  },
  get getDiscount() {
    return self.discount;
  },
  get getPlansList() {
    return toJS(self.plansList);
  },
  get getPlansAndPrices() {
    return toJS(self.plansAndPrices);
  },
  get getPlansAndData() {
    return toJS(self.plansData);
  },

  get getSelectedPlan() {
    const planObj = self?.plansList?.find(item => item.id === self.selectedPlanId);
    const tempObj = {
      ...planObj,
      price: self.payAnnualValue ? planObj?.yearlyCost : planObj?.price,
    };
    if (tempObj) {
      tempObj['date'] = self.payAnnualValue ? moment().add(1, 'year').format('MMM DD, YYYY') : moment().add(1, 'M').format('MMM DD, YYYY');
      return tempObj;
    } else {
      return {id: '', price: '0', name: '', date: ''};
    }
  },

  get getSubmitStripe() {
    return self.submitHandler;
  },

  get getAdditionalServicesData() {
    return self.additionalServicesData;
  },

  get getAdditionalServicesCheckbox() {
    return self.additionalServicesCheckbox;
  },

  get getStripeToken() {
    return self.stripeToken;
  },

  get getCheckoutConfirmation() {
    return self.checkoutConfirmation;
  },

  get getCountry() {
    return self.country;
  },

  get getState() {
    return self.state;
  },

  get getPhoneCode() {
    return self.phoneCode;
  },
  get getServicesDetailDrawerData() {
    return self.servicesListFromApi?.map(item => item?.products?.find(obj => {
      return obj?.name == self.servicesDetailDrawerData;
    }))?.filter(x => x !== undefined)[0];
  },
  get getSidebarPayment() {
    return self.sideBarPayment;
  },
  get getSelectedDomainForUpgrade() {
    return self.selectedDomainForUpgrade;
  },
  get getSelectedDomainIdForUpgrade() {
    return self.selectedDomainIdForUpgrade;
  },
  get getServerErrors() {
    return self.serverErrors;
  },

  get getInvoiceDetails() {
    return self.invoiceDetails;
  },

  get getAlreadyCreated() {
    return self.alreadyCreated;
  },

  get isLoading() {
    return self.loading;
  },

  get getKeywordData() {
    return self.keywordData;
  },

})).actions(self => {
  const resetServerErrors = ()=> {
    self.serverErrors = cast([]);
    self.alreadyCreated = '';
  };
  const setOrderSlug = value=> {
    self.orderSlug = value;
  };
  const setIsSummaryScrolled= value => {
    self.isSummaryScrolled = value;
  };
  const loadPlans = flow(function* (t: TFunction, isWhiteLabel?: boolean, whitelabelOtto?: string) {
    const checkoutPage = typeof window !== 'undefined' && location?.pathname?.includes('/checkout');
    try {
      self.planAndPricesLoading = true;
      const data = yield planApi.getPlans();
      const response = Array.isArray(data) ? data : [];
      const subscriptions = JSON.parse(JSON.stringify(getPricingArray(t, isWhiteLabel)));
      if (response) {
        self.plansData = cast(response);
        self.plansAndPrices = cast(response?.map(z => {
          return {
            planId: z?.id,
            price: `$${parseInt(z?.monthlyCost).toFixed(0)}`,
            yearlyCost: checkoutPage ? `$${(parseInt(z?.yearlyCost)).toFixed(0)}` : `$${(parseInt(z?.yearlyCost)/12).toFixed(0)}`,
            yearlyCostAmount: parseInt(z?.yearlyCost)?.toFixed(0),
          };
        }));
      }
      response?.forEach(item => {
        const index = subscriptions.findIndex(pricingItem => pricingItem.id === item.id);
        if (index > -1) {
          subscriptions[index].name = item.name;
          // subscriptions[index].features[1].str = `${String(item?.quotaCaAllowedAiPremiumContentGeneration)} AI Written articles`;
          // subscriptions[index].features[2].str = `${String(item?.quotaKeAllowedCompetitorResearchProjects)} Site reports`;
          subscriptions[index].features[0].str = `${String(item?.quotaCaAllowedOttoV2Projects)} ${whitelabelOtto?.length ? whitelabelOtto : 'OTTO'} SEO Site${item?.quotaCaAllowedOttoV2Projects == 1 ? '' : 's'} Activation`;
          subscriptions[index].features[1].str = `${whitelabelOtto?.length ? whitelabelOtto : 'OTTO'} PPC`;
          subscriptions[index].features[2].str = `${subscriptions[index].icon == 'faStar' ? 'Unlimited' : String(item?.quotaGscAllowedActiveProjects)} GSC Site Projects`;
          subscriptions[index].features[3].str = `${String(item?.quotaCaGbpAutomationLocations)} Automated GBP`;
          if (subscriptions[index].features[4].str === 'X User Seats') subscriptions[index].features[4].str = `${String(item?.seats)} User Seats`;
          subscriptions[index].price = `$${parseInt(item.monthlyCost).toFixed(0)}`;
          subscriptions[index].yearlyCost = checkoutPage ? `$${(parseInt(item.yearlyCost)).toFixed(0)}` : `$${(parseInt(item.yearlyCost) / 12).toFixed(0)}`;
          subscriptions[index].yearlyCostAmount = parseInt(item.yearlyCost)?.toFixed(0);
          subscriptions[index].productionCheckoutLink = item?.productionCheckoutLink;
          subscriptions[index].stagingCheckoutLink = item?.stagingCheckoutLink;
          subscriptions[index].checkoutLink = item?.checkoutLink;
        }
      });
      const planId = getSingleUrlParam('plan_id');
      if (planId) {
        const planIdIndex = response?.findIndex(pricingItem => pricingItem.id == parseInt(planId));
        const subscriptionIndex = subscriptions.findIndex(pricingItem => pricingItem.id == parseInt(planId));
        // update static plan with dynamic plan
        if (planIdIndex > -1 && subscriptionIndex > -1) {
          subscriptions[subscriptionIndex].id = response[planIdIndex].id;
          subscriptions[subscriptionIndex].name = response[planIdIndex].name;
          subscriptions[subscriptionIndex].price = `$${parseInt(response[planIdIndex].monthlyCost).toFixed(0)}`;
          subscriptions[subscriptionIndex].yearlyCost = checkoutPage ? `$${(parseInt(response[planIdIndex].yearlyCost)).toFixed(0)}` : `$${(parseInt(response[planIdIndex].yearlyCost) / 12).toFixed(0)}`;
          subscriptions[subscriptionIndex].yearlyCostAmount = parseInt(response[planIdIndex].yearlyCost)?.toFixed(0);
          self.selectedPlanId = cast(subscriptions[subscriptionIndex].id);
          subscriptions[subscriptionIndex].productionCheckoutLink = response?.[planIdIndex]?.productionCheckoutLink;
          subscriptions[subscriptionIndex].stagingCheckoutLink = response?.[planIdIndex]?.stagingCheckoutLink;
          subscriptions[subscriptionIndex].checkoutLink = response?.[planIdIndex]?.checkoutLink;
        }
        // Added hidden plan and show on checkout page
        if (planIdIndex > -1 && subscriptionIndex == -1) {
          subscriptions[0].id = response[planIdIndex].id;
          subscriptions[0].name = response[planIdIndex].name;
          subscriptions[0].price = `$${parseInt(response[planIdIndex].monthlyCost).toFixed(0)}`;
          subscriptions[0].yearlyCost = checkoutPage ? `$${(parseInt(response[planIdIndex].yearlyCost)).toFixed(0)}` : `$${(parseInt(response[planIdIndex].yearlyCost) / 12).toFixed(0)}`;
          subscriptions[0].yearlyCostAmount = parseInt(response[planIdIndex].yearlyCost)?.toFixed(0);
          self.selectedPlanId = cast(subscriptions[0].id);
          subscriptions[0].productionCheckoutLink = response?.[planIdIndex]?.productionCheckoutLink;
          subscriptions[0].stagingCheckoutLink = response?.[planIdIndex]?.stagingCheckoutLink;
          subscriptions[0].checkoutLink = response?.[planIdIndex]?.checkoutLink;
        }
        if (planIdIndex == -1 && subscriptionIndex > -1) {
          self.selectedPlanId = cast(subscriptions[subscriptionIndex].id);
        }
        // redirect to pricing page if no plan found
        if (planIdIndex == -1 && subscriptionIndex == -1) {
          Router.push({pathname: `/${routes.pricing}`});
        }
      }
      self.plansList = cast(subscriptions);
    } catch (e) {
      self.plansList = null;
      const errorMessage = apiError(e) as string;
      notification.error('Failed to load plans', errorMessage);
    } finally {
      self.planAndPricesLoading = false;
    }
  });

  const submitStripe = () => {
    self.submitHandler = !self.submitHandler;
  };

  const setCheckoutConfirmation = () => {
    return self.checkoutConfirmation = true;
  };

  const setPlanId = id => {
    self.selectedPlanId = cast(id);
  };
  const showSidebarPaymentDrawer = (domain = '', sideBarPaymentContent = {title: '', subtitle: '', text: ''}) => {
    self.selectedDomainForUpgrade = domain;
    self.sideBarPaymentContent = sideBarPaymentContent,
    self.sideBarPayment = true;
  };
  const showActiveProjectPopup = (domain, id) => {
    self.selectedDomainForUpgrade = domain;
    self.selectedDomainIdForUpgrade = id;
    self.projectActivePopup = true;
  };
  const hideActiveProjectPopup = () => {
    self.projectActivePopup = false;
  };
  const hideSidebarPaymentDrawer = () => {
    self.sideBarPayment = false;
  };
  const setStripeToken = tokenId => {
    self.stripeToken = tokenId;
  };

  const setCountry = country => {
    self.country = country;
  };

  const setState = state => {
    self.state = state;
  };

  const setPhoneCode = phoneCode => {
    self.phoneCode = phoneCode;
  };

  const subscribePlan = flow(function* (data: any, cardNumElement, stripe ) {
    data.phone = `+${self.phoneCode}${data.phone}`;
    const stripeCpm = yield stripe.createPaymentMethod({
      type: 'card',
      card: cardNumElement,
      billing_details: {
        address: {
          country: self.country,
          line1: data.address,
          line2: null,
          state: self.state,
        },
        email: data.emailAddress,
        name: `${data.firstName} ${data.lastName}`,
      },
    });
    if (stripeCpm.error) {
      stopLoading();
      return stripeCpm;
    }

    const dataObj = {
      charge_type: 'customer_plan',
      plan_id: self.selectedPlanId,
      promo_code: !self.inValidStatusPromoCode && self.discount ? self.promoCodeValue : null,
      payment_method: {
        source: 'stripe',
        firstName: data.firstName,
        last_name: data.lastName,
        address: data.address,
        country: self.country,
        state: self.state,
        stripe_payment_method: stripeCpm.paymentMethod,
      },
    };
    if (self.payAnnualValue) {
      dataObj['payment_frequency'] = 'annually';
    }
    try {
      const response = yield planApi.subscribePlan(dataObj);
      if ('success' in response && !response?.success) {
        self.alreadyCreated = response?.details;
      } else {
        self.invoiceDetails = cast({
          id: response?.invoice.id,
          amount: response?.invoice.amount,
          slug: response?.invoice.slug,
          next_payment_at: moment(response?.customerPlan.nextPaymentAt).format('MMM DD, YYYY'),
          plan_name: response?.customerPlan.plan.name,
          type: response?.invoice?.type,
        });
        self.checkoutConfirmation = true;
      }
    } catch (e) {
      const {response} = e;
      if (response?.status == 400) {
        const {data} = response;
        self.serverErrors.length = 0;
        Object.keys(data?.paymentMethod).forEach(key => {
          self.serverErrors.push(data?.paymentMethod[key][0]);
        });
      }
      if (response?.status == 500) {
        notification.error('Payment Error', 'Unable to process payment. Please contact support. Thanks');
      }
      throw e;
    } finally {
      stopLoading();
    }
  });

  const subscribePlanWithACH = flow(function* (data: any ) {
    const dataObj = {
      charge_type: 'customer_plan',
      plan_id: self.selectedPlanId,
      promo_code: !self.inValidStatusPromoCode && self.discount ? self.promoCodeValue : null,
      payment_method: {
        source: 'bill.com',
        bill_payment_method: {
          account_number: data.accountNumber,
          routing_number: data.routingNumber,
          name_on_account: data.accountName,
        },

      },

    };
    if (self.payAnnualValue) {
      dataObj['payment_frequency'] = 'annually';
    }
    try {
      const response = yield planApi.subscribePlan(dataObj);
      if ('success' in response && !response?.success) {
        self.alreadyCreated = response?.details;
      } else {
        self.invoiceDetails = cast({
          id: response?.invoice.id,
          amount: response?.invoice.amount,
          slug: response?.invoice.slug,
          next_payment_at: moment(response?.customerPlan.nextPaymentAt).format('MMM DD, YYYY'),
          plan_name: response?.customerPlan.plan.name,
          type: response?.invoice?.type,
        });
        self.checkoutConfirmation = true;
      }
    } catch (e) {
      const {response} = e;
      if (response?.status == 400) {
        const {data} = response;
        self.serverErrors.length = 0;
        self.serverErrors.push(data.details);
      }
      if (response?.status == 500) {
        notification.error('Payment Error', 'Unable to process payment. Please contact support. Thanks');
      }
      throw e;
    } finally {
      stopLoading();
    }
  });

  const chargeInvoiceWithNewCard = flow(function* (data: any, cardNumElement, stripe, invoiceId) {
    const stripeCpm = yield stripe.createPaymentMethod({
      type: 'card',
      card: cardNumElement,
      billing_details: {
        address: {
          country: self.country,
          line1: data.address,
          line2: null,
          state: self.state,
        },
        email: data.emailAddress,
        name: `${data.firstName} ${data.lastName}`,
      },
    });
    if (stripeCpm.error) {
      stopLoading();
      return stripeCpm;
    }

    const dataObj = {
      charge_type: 'order',
      invoice_id: invoiceId,

      payment_method: {
        source: 'stripe',
        firstName: data.firstName,
        last_name: data.lastName,
        address: data.address,
        country: self.country,
        state: self.state,
        stripe_payment_method: stripeCpm.paymentMethod,
      },
    };
    if (self.payAnnualValue) {
      dataObj['payment_frequency'] = 'annually';
    }
    try {
      const response = yield planApi.subscribePlan(dataObj);
      if ('success' in response && !response?.success) {
        self.alreadyCreated = response?.details;
      } else {
        return response;
      }
    } catch (e) {
      const {response} = e;
      if (response?.status == 400) {
        const {data} = response;
        self.serverErrors.length = 0;
        Object.keys(data).forEach(key => {
          self.serverErrors.push(data[key][0]);
        });
      }
      if (response?.status == 500) {
        notification.error('Payment Error', 'Unable to process payment. Please contact support. Thanks');
      }
    } finally {
      stopLoading();
    }
  });

  const orderCheckoutWithNewCard = flow(function* (data: any, cardNumElement, stripe, cardId) {
    const stripeCpm = yield stripe.createPaymentMethod({
      type: 'card',
      card: cardNumElement,
      billing_details: {
        address: {
          line1: data.address,
          line2: null,
          postal_code: null,
        },
        email: data.emailAddress,
        phone: null,
      },
    });

    if (stripeCpm.error) {
      stopLoading();
      return stripeCpm;
    }

    const dataObj = {
      source: 'stripe',
      zipcode: data.zipCode,
      stripe_payment_method: stripeCpm.paymentMethod,
    };
    const response = yield stripeApi.createPaymentMethod(dataObj);
    if (response?.id) {
      const payload = {payment_method_id: response?.id};
      const cartResponse = postCheckoutCart(cardId, payload);
      return cartResponse;
    } else {
      stopLoading();
    }
  });

  const invoicePaid = flow(function* (data: any) {
    const dataObj = data;
    if (self.payAnnualValue) {
      dataObj['payment_frequency'] = 'annually';
    }
    try {
      startLoading();
      const response = yield planApi.subscribePlan(dataObj);
      if (!response?.isCancel) {
        const newPayment = response?.customerPlan ? {next_payment_at: moment(response?.customerPlan?.nextPaymentAt).format('MMM DD, YYYY')} : {};
        const planName = response?.customerPlan ? {plan_name: response?.customerPlan?.plan.name} : response?.plan ? {plan_name: response?.plan?.name} : {};
        self.invoiceDetails = cast({
          id: response?.invoice.id,
          amount: response?.invoice.amount,
          slug: response?.invoice.slug,
          ...newPayment,
          ...planName,
          type: response?.invoice?.type,
        });
        notification.success('Invoice paid successfully.', '');
      }
      return response;
    } catch (e) {
      const errorMessage = apiError(e);
      const {response} = e;
      if (response?.status == 400) {
        notification.error('', errorMessage);
        if (!errorMessage) {
          const {data} = response;
          self.serverErrors.length = 0;
          Object.keys(data).forEach(key => {
            self.serverErrors.push(data[key][0]);
          });
        }
      }
      if (response?.status == 500) {
        notification.error('Payment Error', 'Unable to process payment. Please contact support. Thanks');
        throw e;
      }
    } finally {
      stopLoading();
    }
  });

  const subscribeAddonPlanWithExistingPaymentMethod = flow(function* (paymentMethodId, addonId ) {
    self.changeOttoPlanLoading = addonId;
    const dataObj = {
      charge_type: 'addon',
      addon_id: addonId,
      payment_method_id: paymentMethodId,
    };
    let responseStatus = false;
    try {
      const response = yield planApi.subscribePlan(dataObj);
      if ('success' in response && !response?.success) {
        notification.error('Payment Error', 'Unable to process payment. Please Try again. Thanks');
      } else {
        const whiteLabel = localStorage.getItem('whitelabelOtto') || 'OTTO';
        notification.success(`${whiteLabel} Plan Changed`, `Your ${whiteLabel} plan has been changed successfully.`);
        responseStatus = true;
      }
    } catch (e) {
      const {response} = e;
      if (response?.status == 400) {
        const {data} = response;
        self.serverErrors.length = 0;
        Object.keys(data).forEach(key => {
          notification.error('Payment Error', data[key][0]);
        });
      }

      if (response?.status == 500) {
        notification.error('Payment Error', 'Unable to process payment. Please contact support. Thanks');
      }
      throw e;
    } finally {
      self.changeOttoPlanLoading = null;
      stopLoading();
    }
    return responseStatus;
  });

  const subscribePlanWithExistingPaymentMethod = flow(function* (paymentMethodId ) {
    const dataObj = {
      charge_type: 'customer_plan',
      plan_id: self.selectedPlanId,
      promo_code: !self.inValidStatusPromoCode && self.discount ? self.promoCodeValue : null,
      payment_method_id: paymentMethodId,
    };
    if (self.payAnnualValue) {
      dataObj['payment_frequency'] = 'annually';
    }
    let responseStatus = false;
    try {
      const response = yield planApi.subscribePlan(dataObj);
      if ('success' in response && !response?.success) {
        self.alreadyCreated = response?.details;
      } else {
        self.invoiceDetails = cast({
          id: response?.invoice.id,
          amount: response?.invoice.amount,
          slug: response?.invoice.slug,
          next_payment_at: moment(response?.customerPlan.nextPaymentAt).format('MMM DD, YYYY'),
          plan_name: response?.customerPlan.plan.name,
          type: response?.invoice?.type,
        });
        self.checkoutConfirmation = true;
        responseStatus = true;
      }
    } catch (e) {
      const {response} = e;
      if (response?.status == 500) {
        notification.error('Payment Error', 'Unable to process payment. Please contact support. Thanks');
        return;
      }
      const errorMessage = apiError(e);
      self.serverErrors.push(errorMessage);
      throw e;
    } finally {
      stopLoading();
    }
    return responseStatus;
  });

  const subscribePlanSidebarPayment = flow(function* (data) {
    const dataObj = data;
    if (self.payAnnualValue) {
      dataObj['payment_frequency'] = 'annually';
    }
    let responseStatus = false;
    try {
      const response = yield planApi.subscribePlan(dataObj);
      if ('success' in response && !response?.success) {
        self.alreadyCreated = response?.details;
      } else {
        self.sideBarPayment = false;
        responseStatus = true;
      }
    } catch (e) {
      const {response} = e;
      if (response?.status == 400) {
        const {data} = response;
        self.serverErrors.length = 0;
        Object.keys(data).forEach(key => {
          self.serverErrors.push(data[key][0]);
        });
      }

      if (response?.status == 500) {
        notification.error('Payment Error', 'Unable to process payment. Please contact support. Thanks');
      }
    } finally {
      stopLoading();
    }
    return responseStatus;
  });
  const validatePromoCode = flow(function* (data: object) {
    self.inValidStatusPromoCode = false;
    self.validatingPromoCode = true;
    try {
      const response = yield planApi.validatePromoCode(data);
      if (response?.isCancel) return;
      if (response?.success) {
        self.promoCode = response?.success;
        if (response?.shouldUseDiscountPrice) {
          self.useDiscountPrice = response?.shouldUseDiscountPrice;
          self.discountPrice = response?.discountPrice;
        } else {
          self.discount = response?.discount;
        }
        self.inValidStatusPromoCode = false;
      } else {
        self.discount = 0;
        notification.warning(response?.details?.replace('_', ' '), '');
        self.inValidStatusPromoCode = true;
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.validatingPromoCode = false;
    }
  });

  const orderBuilderValidatePromoCode = flow(function* (data: object) {
    self.inValidStatusPromoCode = false;
    self.validatingPromoCode = true;
    try {
      const response = yield planApi.validatePromoCodeForOderBuilder(data);
      if (response?.isCancel) return;
      if (response?.success) {
        self.promoCode = response?.success;
        self.discount = response?.discount;
        self.products = response?.products;
        self.inValidStatusPromoCode = false;
      } else {
        self.discount = 0;
        const errorMsg = response?.details?.replace('_', ' ');
        notification.warning(errorMsg.charAt(0).toUpperCase() + errorMsg.slice(1).toLowerCase(), '');
        self.inValidStatusPromoCode = true;
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.validatingPromoCode = false;
    }
  });


  const onOrderBuilderValidatePromoCode = promoCode => {
    self.promoCodeValue = promoCode;
    const data = {promo_code: promoCode};
    orderBuilderValidatePromoCode(data);
  };

  const getSubscriptionsData = flow(function* () {
    self.loadProduct = true;
    try {
      const response = yield planApi.getSubscriptions();
      if (response) {
        self.servicesListFromApi = response;
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.loadProduct = false;
    }
  });

  const postServicesList = flow(function* () {
    const makeList = self.servicesList?.map(item => {
      return {
        variant_id: item?.variantId,
        quantity: item?.links,
      };
    });
    const payLoad = {
      data: makeList,
    };
    try {
      const response = yield planApi.postServices(payLoad);
      if (response) {
        localStorage.setItem('servicesListId', response?.id);
        return response;
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.subscriptionsLoading = false;
    }
  });

  const makeAdditionalNotesList = selectedList => {
    const data = selectedList?.map(item => {
      return {
        variantId: item?.productVariant?.id,
        key: item?.id,
        amount: item?.productVariant?.unitPrice,
        orderDetailsFormLink: item?.productVariant?.orderDetailsFormLink,
        title: self.servicesListFromApi?.find(x => !!findNestedObj(x?.products, 'name', item?.productVariant?.name))?.products?.find(x => !!findNestedObj(x?.variants, 'name', item?.productVariant?.name))?.name,
        productIcon: self.servicesListFromApi?.find(x => !!findNestedObj(x?.products, 'name', item?.productVariant?.name))?.image,
        subTitle: item?.productVariant?.name,
      };
    });
    self.additionalNotesList = cast(data);
  };

  const payWithCreditsOrder = flow(function* (payWithCredits, id) {
    const payLoad = {
      charge_via_credits: payWithCredits,
    };
    try {
      const response = yield planApi.payWithCreditsOrder(payLoad, id);
      makeAdditionalNotesList(response?.cart?.order?.deliverables);
      return response;
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.subscriptionsLoading = false;
    }
  });
  const getPreviousList = flow(function* (id) {
    try {
      const response = yield planApi.getPreviousListById(id);
      if (response?.items?.length) {
        return response;
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.subscriptionsLoading = false;
    }
  });

  const findNestedObj = (entireObj, keyToFind, valToFind) => {
    let foundObj;
    JSON.stringify(entireObj, (_, nestedValue) => {
      if (nestedValue && nestedValue[keyToFind] === valToFind) {
        foundObj = nestedValue;
      }
      return nestedValue;
    });
    return foundObj;
  };

  const previousSelectionList = selectedList => {
    const data = selectedList?.map(item => {
      return {
        variantId: item?.variant?.id,
        key: `product-${item?.variant?.product?.id}`,
        amount: item?.variant?.unitPrice,
        title: item?.variant?.name,
        subTitle: self.servicesListFromApi?.slice()?.find(x => !!findNestedObj(x?.products, 'name', item?.variant?.name))?.name,
        links: item?.quantity,
        productIcon: self.servicesListFromApi?.slice()?.find(x => !!findNestedObj(x?.products, 'name', item?.variant?.name))?.image,
      };
    });
    if (!self.servicesList?.length) {
      self.servicesList = data;
    }
  };


  const postCheckoutCart = flow(function* (id, payLoad) {
    try {
      const updatedPayload = payLoad;
      if (!self.inValidStatusPromoCode && self.discount) {
        updatedPayload['promo_code'] = self.promoCodeValue;
      }
      const response = yield planApi.checkoutCartApi(id, updatedPayload);
      if (response?.success) {
        makeAdditionalNotesList(response?.cart?.order?.deliverables);
        return response;
      } else {
        stopLoading();
        self.serverErrors.push(response?.details);
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.subscriptionsLoading = false;
    }
  });

  const submitAdditionalNotes = flow(function* (payLoad) {
    try {
      const response = yield planApi.addAdditionalNotes(payLoad);
      if (response) {
        return response;
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.subscriptionsLoading = false;
    }
  });

  const onValidatePromoCode = promoCode => {
    self.promoCodeValue = promoCode;
    const data = {promo_code: promoCode};
    validatePromoCode(data);
  };
  const placeCheckoutOrder = flow(function* (data ) {
    return yield planApi.orderCheckout(data);
  });

  const chargeCheckoutOrder = flow(function* (data) {
    const dataObj = data;
    if (self.payAnnualValue) {
      dataObj['payment_frequency'] = 'annually';
    }
    let responseStatus = false;
    try {
      const response = yield planApi.subscribePlan(dataObj);
      if ('success' in response && !response?.success) {
        self.alreadyCreated = response?.details;
      } else {
        self.sideBarPayment = false;
        responseStatus = true;
      }
    } catch (e) {
      const {response} = e;
      if (response?.status == 400) {
        const {data} = response;
        self.serverErrors.length = 0;
        Object.keys(data).forEach(key => {
          self.serverErrors.push(data[key][0]);
        });
      }

      if (response?.status == 500) {
        notification.error('Payment Error', 'Unable to process payment. Please contact support. Thanks');
      }
    } finally {
      stopLoading();
    }
    return responseStatus;
  });

  const resetPromoCode = () => {
    self.promoCode = false;
    self.discount = 0;
    self.promoCodeValue = '';
    self.inValidStatusPromoCode = false;
  };

  const startLoading = () => {
    self.loading = true;
  };
  const stopLoading = () => {
    self.loading = false;
  };
  const setKeywordData = keywordData => {
    self.keywordData = keywordData;
  };

  const setServicesDetailDrawer = (value, data, html, slug) => {
    self.servicesDetailDrawer = value;
    self.servicesDetailDrawerData = data;
    self.productDetailsHtml = html;
    if (slug) {
      Router.push(`?product_slug=${slug}`, undefined, {scroll: false});
    } else {
      Router.push(Router?.pathname, undefined, {scroll: false});
    }
  };

  const updateKeywordData = index => {
    self.keywordData.splice(index, 1);
  };

  const cleaningCheckoutConfirmation = () =>{
    self.checkoutConfirmation = false;
  };

  const setOrderStepOne = value => {
    self.orderStepOne = value;
  };

  const makeServicesList = data => {
    const index = self.servicesList?.findIndex(item => item?.key == data?.key && item?.variantId == data?.variantId);
    if (data?.links == 0) {
      const obj = self.servicesList?.find(item => item?.variantId == data?.variantId);
      if (obj?.key) {
        const dataList = self.servicesList?.filter(item => item?.variantId !== data?.variantId);
        self.servicesList = cast(dataList);
      }
    } else {
      if (index == -1) {
        self.servicesList?.push(data);
      } else {
        self.servicesList[index] = data;
      }
    }
  };

  const setServicesList = data => {
    self.servicesList = data;
  };

  const handlePayAnnual = value => {
    self.payAnnualValue = value;
  };

  return {
    payWithCreditsOrder,
    setOrderSlug,
    handlePayAnnual,
    setIsSummaryScrolled,
    loadPlans,
    makeServicesList,
    postServicesList,
    postCheckoutCart,
    orderCheckoutWithNewCard,
    setServicesList,
    getPreviousList,
    submitAdditionalNotes,
    setPlanId,
    submitStripe,
    findNestedObj,
    setStripeToken,
    getSubscriptionsData,
    subscribePlan,
    subscribePlanWithACH,
    previousSelectionList,
    resetServerErrors,
    setOrderStepOne,
    invoicePaid,
    onValidatePromoCode,
    onOrderBuilderValidatePromoCode,
    setServicesDetailDrawer,
    resetPromoCode,
    placeCheckoutOrder,
    chargeCheckoutOrder,
    subscribePlanWithExistingPaymentMethod,
    subscribeAddonPlanWithExistingPaymentMethod,
    subscribePlanSidebarPayment,
    chargeInvoiceWithNewCard,
    showSidebarPaymentDrawer,
    hideSidebarPaymentDrawer,
    hideActiveProjectPopup,
    showActiveProjectPopup,
    setCountry,
    setState,
    setPhoneCode,
    startLoading,
    stopLoading,
    setKeywordData,
    updateKeywordData,
    cleaningCheckoutConfirmation,
    setCheckoutConfirmation,
  };
});

export function initPlans() {
  return Plans.create({
    orderSlug: '',
    count: 0,
    pageSize: 0,
    plansList: [],
    servicesList: [],
    selectedPlanId: null,
    submitHandler: false,
    stripeToken: null,
    checkoutConfirmation: false,
    servicesDetailDrawer: false,
    subscriptionsLoading: false,
    loadProduct: false,
    payAnnualValue: false,
    country: 'US',
    state: 'Alabama',
    phoneCode: '1',
    serverErrors: [],
    invoiceDetails: {},
    servicesDetailDrawerData: '',
    alreadyCreated: null,
    loading: false,
    keywordData: keywordDefaultData,
    additionalServicesData: 0,
    additionalServicesCheckbox: false,
    sideBarPayment: false,
    orderStepOne: true,
    promoCode: false,
    promoCodeValue: '',
    inValidStatusPromoCode: false,
    validatingPromoCode: false,
    discount: 0,
    sideBarPaymentContent: {
      title: '',
      subtitle: '',
      text: '',
    },
  });
}
