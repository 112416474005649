import {observer} from 'mobx-react-lite';
import {useStore} from '@/store/root-store';
import {HeroSection} from '@/components/common-components/v2/HeroSection';
import {targetPageRegexV3, urlDomainRegex, urlSubDomainRegex} from '@/constants/regex';
import React, {useEffect, useState} from 'react';
import {getSingleUrlParam, isSiteExplorerPublic} from '@/utils/url';
import {useRouter} from 'next/router';
import {SiteExplorerBulkModal} from './bulkModal';
import {MixPanel} from '@/utils/mixpanel';
import {SE_PROJECT_CREATED} from '@/constants/events';
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import {faTriangleExclamation} from '@fortawesome/pro-duotone-svg-icons';
// import styled from 'styled-components';

interface Props {
  maxWidth?: string;
  isSE?: boolean;
}
export const countriesListForCr = ['DZ', 'AO', 'AZ', 'AR', 'AU', 'AT', 'BH', 'BD', 'AM', 'BE',
  'BO', 'BR', 'BG', 'MM', 'BY', 'KH', 'CM', 'CA', 'LK', 'CL', 'TW', 'CO', 'CR', 'HR',
  'CY', 'CZ', 'DK', 'EC', 'SV', 'EE', 'FI', 'FR', 'DE', 'GH', 'GR', 'GT', 'HK', 'HU',
  'IN', 'ID', 'IE', 'IL', 'IT', 'CI', 'JP', 'KZ', 'JO', 'KE', 'KR', 'LV', 'LT', 'MY',
  'MT', 'MX', 'MA', 'NL', 'NZ', 'NI', 'NG', 'NO', 'PK', 'PY', 'PE', 'PH', 'PL', 'PT',
  'RO', 'SA', 'SN', 'RS', 'SG', 'SK', 'VN', 'SI', 'ZA', 'ES', 'SE', 'CH', 'TH', 'AE',
  'TN', 'TR', 'UA', 'MK', 'EG', 'GB', 'US', 'BF', 'UY', 'VE'];

export const countriesListForSE = ['AR', 'AU', 'BE', 'BR', 'CA', 'DK', 'DE', 'FI', 'FR', 'HK', 'HU', 'IE', 'IL', 'IN', 'IT', 'JP', 'MX', 'NL', 'NO', 'PL', 'RU', 'SG', 'ES', 'SE', 'CH', 'TR', 'US', 'GB', 'NZ', 'RO'];

export const Header:React.FC<Props> = observer(({maxWidth, isSE}) => {
  const {
    competitorResearcherV2: {postNewCompetitor, searchBtnLoading,
      overview: {setSiteExplorerCountry},
      loadCRHistoryList,
    },
    settings: {customer: {profile: {quotaUtilization}}},
  } = useStore('');
  const router = useRouter();
  const {
    settings: {customer: {getCustomerQuota}},
  } = useStore('');
  const [selectedModeOption, setSelectedModeOption] = useState('domain');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [placeholder, setPlaceholder] = useState('example.com');
  const [placeholderModeOption, setPlaceholderModeOption] = useState('domain');
  const [shouldClearFormFields, setShouldClearFormFields] = useState(false);

  const handleSubmit = async values => {
    const getUrl = values.URL;
    const payload = {url: getUrl, mode: selectedModeOption, country_code: values.selectedCountry};
    const response = await postNewCompetitor(payload, true);
    setSiteExplorerCountry(values.selectedCountry);
    loadCRHistoryList();
    if (response?.id) {
      MixPanel.track(SE_PROJECT_CREATED, {
        'projectId': response?.id,
      });
      getCustomerQuota();
      router.push(`/site-explorer/detail${isSiteExplorerPublic() ? '-public' : ''}/${response?.id}?domain=${getUrl}${isSiteExplorerPublic() ? `&public_hash=${response?.publicShareHash}` : ''}`);
    }
    setSelectedModeOption('domain');
    setPlaceholder('example.com');
    setPlaceholderModeOption('domain');
    setShouldClearFormFields(true);
  };
  useEffect(() => {
    if (placeholderModeOption == 'domain') {
      setPlaceholder('example.com');
    }
    if (placeholderModeOption == 'subdomain') {
      setPlaceholder('subdomain.example.com');
    }
    if (placeholderModeOption == 'target_page') {
      setPlaceholder('example.com/target-page');
    }
    if (placeholderModeOption == 'subfolder') {
      setPlaceholder('example.com/target-page/subfolder');
    }
  }, [placeholderModeOption]);

  const chechCreateBtn = getSingleUrlParam('isCreate');
  useEffect(()=>{
    if (chechCreateBtn) {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <>
      {/* <WarningBanner>
        <FontAwesomeIcon icon={faTriangleExclamation} style={{color: '#FF8536', fontSize: '16px', marginRight: '5px'}}/>
        You may experience performance or data loss while our team is working on improving this tool.</WarningBanner> */}

      <div style={{margin: '30px auto', maxWidth: maxWidth || '900px', display: 'flex', justifyContent: 'space-between'}}>
        <HeroSection
          bulkAction= {true}
          isSiteExplorer= {true}
          quota={!isSiteExplorerPublic()}
          isLookup={isSiteExplorerPublic()}
          lookupValue={'2 searches'}
          focusInput={chechCreateBtn ? `#${chechCreateBtn}99` : ''}
          utilizedQuota={quotaUtilization?.ke?.allowedCompetitorResearchProjects?.consumed}
          totalQuota={quotaUtilization?.ke?.allowedCompetitorResearchProjects?.total}
          title='Site Explorer'
          countriesToInclude={isSE ? countriesListForSE : countriesListForCr}
          lightInputFieldVariant={true}
          description='Get instant insights into strengths and weaknesses of your competitor or prospective customer.'
          inputFieldWidth={320}
          inputFieldPlaceholder={'Enter domain, subdomain or URL'}
          buttonTitle='Search'
          showSearchIcon={true}
          showDropdown={true}
          dropdownColor='#fff'
          isCenter={true}
          isAlligned={true}
          showLoader={searchBtnLoading}
          isURL={true}
          rootFieldWidth={208}
          showWorldWide={true}
          modeOptions={[
            {name: 'Domain', value: 'domain'},
            {name: 'Sub Domain', value: 'subdomain'},
            {name: 'Target Page', value: 'target_page'},
            {name: 'Sub Folder', value: 'subfolder'},
          ]}
          onCountryChange={value => {
            setPlaceholderModeOption(value);
            setSelectedModeOption(value);
          }}
          onChangeUrlField={value => {
            const checkDomain = value?.match(urlDomainRegex);
            const checkSubDomain = value?.match(urlSubDomainRegex);
            const checkTargetPage = value?.match(targetPageRegexV3);
            if (checkDomain) {
              setSelectedModeOption('domain');
              setPlaceholder('example.com');
            } else if (checkSubDomain) {
              setSelectedModeOption('subdomain');
              setPlaceholder('subdomain.example.com');
            } else if (checkTargetPage) {
              setSelectedModeOption('target_page');
              setPlaceholder('example.com/target-page');
            } else {
              setSelectedModeOption('domain');
              setPlaceholder('example.com');
            }
          }}
          selectedModeOption={selectedModeOption}
          onSubmitHandler={form=>{
            handleSubmit(form);
          }}
          showButtonInInputRow
          clearFields={shouldClearFormFields}
          isSE
        />
      </div>
      <SiteExplorerBulkModal/>
    </>
  );
});

// const WarningBanner=styled.div`
// height: 38px;
// border-radius: 5px;
// background: rgba(255, 133, 54, 0.15);
// color:#ffff;
// margin:20px 20px;
// display:flex;
// align-items:center;
// justify-content:center;
// `;
