import React from 'react';
import {faArrowLeft} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import {observer} from 'mobx-react-lite';
import {ColumnsType} from 'antd/lib/table';
import {faImageSlash} from '@fortawesome/pro-solid-svg-icons';
import {Table} from '@/components/common-components/components';
// import {faCheck} from '@fortawesome/pro-regular-svg-icons';
// import {faXmark} from '@fortawesome/pro-duotone-svg-icons';
import {addProtocolToDomainHttps, getDomain} from '@/utils/url';
import {toJS} from 'mobx';
import {tagsRegex} from '@/constants/regex';
import {routes} from '@/utils/const';
import {useRouter} from 'next/router';
import {Row, Col} from 'antd';
import {NextImg} from '@/utils/nextImg';
import {openUrl} from '@/utils/router';

interface Props {
  setGaDetail?: (value) => void;
  gscDetailData?: any;
  onDeleteAccount?: (email) => void;
  setShowConnectAccountHeader?: React.Dispatch<React.SetStateAction<boolean>>;
}
export const GSCSettingsDetailV2: React.FC<Props> = observer(
  ({
    setGaDetail,
    gscDetailData,
    onDeleteAccount,
    setShowConnectAccountHeader,
  }) => {
    const router = useRouter();
    const parseProject = (url: string) => {
      const match = url.match(tagsRegex);
      return {
        url: url
          .replace(tagsRegex, '')
          .replace('/', '')
          .replace('sc-domain:', ''),
        tag: match?.[0] ?? 'Domain',
      };
    };
    const columns: ColumnsType = [
      {
        title: () => <span>TYPE</span>,
        key: 'url',
        dataIndex: 'url',
        align: 'left',
        fixed: 'left' as 'left',
        width: 300,
        render: (_, data: any) => (
          <Type>{data?.url ? parseProject(data.url)?.tag : '-'}</Type>
        ),
      },
      {
        title: () => <span>GSC PROPERTY</span>,
        key: 'url',
        dataIndex: 'url',
        align: 'left',
        render: (_, data: any) => (
          <FlexWrapper
            style={{gap: '6px'}}
            onClick={() => {
              if (parseProject(data.url)?.url) {
                router.push(
                  `/${routes.gsc}/overview?&sp=${
                    parseProject(data.url)?.url
                  }&country_code=`,
                );
              }
            }}
          >
            <div>
              {data?.url ? (
                <NextImg
                  style={{height: '30px', width: '30px'}}
                  src={`https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${getDomain(
                    !/^(https?:)?\/\//i.test(data.url) ?
                      'https:' + data.url :
                      data.url,
                  )}&size=64`}
                />
              ) : (
                <FontAwesomeIcon
                  style={{fontSize: 25, color: '#A3A4A4'}}
                  icon={faImageSlash}
                />
              )}
            </div>
            <ProjectName>
              {data?.url ? parseProject(data.url)?.url : '-'}
            </ProjectName>
          </FlexWrapper>
        ),
      },
      {
        title: () => <span>ACTIONS</span>,
        key: 'url',
        dataIndex: 'url',
        align: 'left',
        width: 300,
        render: (_, data: any) => {
          return (
            <ActionsWrapper>
              <div
                className='open-gsc-button'
                onClick={() => {
                  if (parseProject(data.url)?.url) {
                    router.push(
                      `/${routes.gsc}/overview?&sp=${
                        parseProject(data.url)?.url
                      }&country_code=`,
                    );
                  }
                }}
              >
                Open in GSC Performance
              </div>
              <div
                className='view-button'
                onClick={() => {
                  const domain = getDomain(data?.url);
                  const withProtocol = addProtocolToDomainHttps(domain);
                  openUrl(`${withProtocol}`, '_blank');
                }}
              >
                View site
              </div>
            </ActionsWrapper>
          );
        },
      },
    ];
    const HandleGoBack = () => {
      setGaDetail(false);
      setShowConnectAccountHeader && setShowConnectAccountHeader(true);
    };
    return (
      <MainWrapper>
        <div
          className='back-button'
        >
          <div style={{display: 'flex', gap: '18px', cursor: 'pointer', alignItems: 'center'}} onClick={HandleGoBack}>
            <FontAwesomeIcon icon={faArrowLeft} style={{cursor: 'pointer'}} />
            <div className='back-text'>
              Back to list of GSC Connected Accounts
            </div>
          </div>
        </div>
        <Row gutter={[16, 16]}>
          <Col span={16}>
            <div className='gsc-detail'>
              <div
                style={{display: 'flex', alignItems: 'center', gap: '20px'}}
              >
                <Email>{gscDetailData?.email}</Email>
                <DisconnectEmail
                  onClick={() => onDeleteAccount(gscDetailData?.email)}
                >
                  Disconnect this Email
                </DisconnectEmail>
              </div>
              <div className='gsc-properties'>
                {gscDetailData?.siteproperties?.length} properties found
              </div>
            </div>
          </Col>
        </Row>
        <TableContainer>
          <Table
            dataSource={toJS(gscDetailData)?.siteproperties || []}
            columns={columns}
            pagination={false}
            scroll={{x: 850, y: 590}}
          />
        </TableContainer>
      </MainWrapper>
    );
  },
);
// const GscProject = styled.div`
//   display: flex;
//   justify-content: center;
//   gap: 6px;
// `;
// const MissingWrapper = styled.div`
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 17px;
//   color: #A3A4A4;
// `;
const Type = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #4e5156;
`;
const ProjectName = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #121212;
`;
// const ViewProject = styled.div`
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 17px;
//   color: #2D6CCA;
// `;
const DisconnectEmail = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #f44343;
  cursor: pointer;
  @media only screen and (max-width: 767px) {
    padding-left: 30px;
  }
`;
const Email = styled.div`
  font-family: "Inter" sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const MainWrapper = styled.div`
  padding-top: 15px;
  .back-button {
    display: flex;
    align-items: center;
    .back-text {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      font-weight: 500;
      color: #121212;
    }
  }
  .gsc-detail {
    padding-left: 27px;
    padding-top: 16px;
  }
  .gsc-properties {
    margin-top: 4px;
    font-family: "Inter" sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: normal;
  }
`;

const TableContainer = styled.div`
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  margin-top: 10px;
  padding: 0px;
  overflow: hidden;
  .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table {
    padding: 0px !important;
  }

  .ant-table-body {
    &::-webkit-scrollbar {
      height: 5px;
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(138, 138, 138, 0.32);
    }

    &::-webkit-scrollbar-track {
      background: rgba(138, 138, 138, 0.12);
    }
  }

  table tr th {
    background-color: #fff;
  }

  .ant-table-content {
    border-radius: 0 !important;
  }

  .ant-table-column-sorters-with-tooltip:not(:first-child) {
    text-align: center;
  }

  table {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;

    th::before {
      width: 0 !important;
    }

    .ant-table-column-sorter {
      display: flex;
      padding-left: 3px !important;
    }

    .ant-table-column-sorters {
      display: inline-flex !important;
      padding: 5px !important;
    }

    tr td {
      &:first-child {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
      }
    }

    .ant-table-tbody {
      tr:nth-child(even) {
        td {
          background: #fff !important;
          border-bottom: 1px solid #f4f4f4 !important;
          box-shadow: none;
          font-weight: 400;
          white-space: nowrap;
        }

        &:hover {
          td {
            background: #eef4fe !important;
          }

          button {
            background: #eef4fe !important;
            border: 0 !important;
            color: #2d6cca;
            font-weight: 500;
          }
        }
      }

      tr:nth-child(odd) {
        td {
          background: #fff;
          border-bottom: 1px solid #f4f4f4 !important;
          box-shadow: none;
          font-weight: 400;
          white-space: nowrap;
        }

        &:hover {
          td {
            background: #eef4fe !important;
          }

          button {
            background: #eef4fe !important;
            border: 0 !important;
            color: #2d6cca;
            font-weight: 500;
          }
        }
      }
    }

    .ant-table-thead {
      background: #f7f7f7 !important;

      th {
        background: #f7f7f7 !important;
        color: #121212 !important;
        font-size: 12px;
        font-weight: 600;
        line-height: 14px;
        padding: 0 !important;
      }

      .ant-table-cell-fix-left {
        text-align: left !important;

        &:first-child {
          border-bottom-left-radius: 8px !important;
          border-top-left-radius: 8px !important;
        }
      }

      th:first-child {
        background: #f7f7f7 !important;
        border-bottom-left-radius: 8px !important;
        border-top-left-radius: 8px !important;
        color: #121212;
        font-size: 12px;
        font-weight: 600;
      }

      th:nth-child(2) {
        background: #f7f7f7 !important;
        color: #121212;
        font-size: 12px;
        font-weight: 600;
      }

      th:last-child {
        background-color: #f7f7f7 !important;
        border-bottom-right-radius: 8px !important;
        border-top-right-radius: 8px !important;
      }
    }

    .ant-table-cell-fix-left-last {
      text-align: left !important;
    }

    tr:first-child td {
      background: #fff !important;
    }

    tr:nth-child(even) td {
      background: #f9f9fb !important;
    }

    tr:nth-child(odd) td {
      background-color: #fff !important;
    }

    tr th {
      height: 50px !important;
      line-height: 22px;
      white-space: nowrap;
    }

    .ant-column-sorters {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }

    tr td {
      padding-bottom: 15px;
      padding-top: 15px;
      text-align: center;
    }

    tr th:first-child,
    tr td:first-child {
      padding-left: 25px !important;
      padding-right: 25px !important;
    }
  }
`;
const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 36px;
  .open-gsc-button,
  .view-button {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #2d6cca;
    cursor: pointer;
  }
`;
