import {useEffect, useState} from 'react';
import {SubscriptionType} from '@/store/settings/customer/index';
import {routes} from '@/utils/const';
import {hasProductAccess} from '@/utils/subscription';
import {
  faLink,
  faPencil,
  // faMagnifyingGlass,
  faListAlt,
  // faMapMarkerAlt,
  faCheckDouble,
  // faChartSimple as faChartSimpleDuotone,
  faArrowsRotate,
  faShuffle,
  faHome,
  faArrowUpArrowDown,
  faMagnifyingGlassChart,
  faMegaphone,
  // faObjectsColumn,
  faFileChartColumn as faFileChartColumnDuotone,
  faChartSimpleHorizontal as faDuotoneChartSimpleHorizontal,
  faMicrochipAi,
} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import {ThunderboltOutlined} from '@ant-design/icons';
import {observer} from 'mobx-react-lite';
import {useStore} from '@/store/root-store';
import Menu, {ItemsGroup} from '.';
import {
  faList,
  faChartSimple,
  faFile,
  faKeySkeleton as faKeySkeletonRegular,
  faMagnifyingGlass as faMagnifyingGlassRegular,
  faLinkSimple,
  // faLeaf,
  faCalendar,
  faFileChartColumn,
  // faNewspaper,
  // faImageLandscape,
  faPenToSquare,
  faGrid2Plus,
  faLightbulb,
  faChartSimpleHorizontal,
  faArrowUpRightDots,
  faFileMagnifyingGlass,
  faSitemap,
  faBug,
  faChartScatterBubble,
  faEnvelopeOpenText,
  faChartGantt,
  faArrowRightArrowLeft,
  faDiagramVenn,
  faChartLineUp,
  faFileCircleExclamation,
  faFolderTree,
  faGlobe,
  faRectangleAd,
  faNotebook,
  faLayerGroup,
  faListOl,
  faFileLines,
  faMagnifyingGlass,
  faListUl,
  faMapLocationDot,
  faChartTreeMap,
  faMemoPad,
  faCalendarClock,
  faObjectExclude,
  faPaperPlaneTop,
  faMessageLines,
  faQuestion,
  faBook,
  faEnvelope,
  faMonitorWaveform,
  faCodeCompare,
} from '@fortawesome/pro-regular-svg-icons';
import {faBullseyeArrow, faChartScatter3d, faInbox, faSpider, faWandMagicSparkles, faStarHalfStroke, faQuoteLeft} from '@fortawesome/pro-solid-svg-icons';
import {useRouter} from 'next/router';
import {getSingleUrlParam} from '@/utils/url';
import {GROWTH_PLAN_ID, PRO_PLAN_ID} from '@/constants';
import {faFacebookSquare} from '@fortawesome/free-brands-svg-icons';
import {faLocationDot} from '@fortawesome/pro-duotone-svg-icons';
import {faQ} from '@fortawesome/pro-light-svg-icons';

type ItemsBarProps = {
  handleSideBarItems?: (items) => void;
  isSmallScreen?: boolean;
  isMediumScreen?: boolean;
};

export const MenuItemsBar = observer(({handleSideBarItems, isSmallScreen, isMediumScreen}: ItemsBarProps) => {
  const {ottoV2Store: {getOttoV2ProjectsList, getOttoV2Project}, settings: {customer: {isGHLAccount}}, navBar} = useStore('');
  const {settings: {customer: {WLchildrenPages, profile: {whitelabelOtto, subscription, isLinkgraph, plan, customerId, customerSuccessManager, isVendastaClient}}}, inbox: {getUnreadEmailCount, emailUnreadCount}, siteAuditorV2: {getAuditSitesList}, ottoV2Store: {businessesList}} = useStore('');
  const {competitorResearcherV2: {competitorResearcherUrl, getCompetitorResearcherId}, reportBuilder: {getReportsProjectsList, reportBuilderIdAndDomain}} = useStore('');
  const router = useRouter();
  const reportBuilderId = reportBuilderIdAndDomain?.split('-')[0] || '';
  const reportBuilderDomain = reportBuilderIdAndDomain?.split('-')[1] || '';
  const getSiteExplorerDomain = getSingleUrlParam('domain');
  const fromRouterResearcherId = router.query?.id?.length ? Number(router.query?.id[0]) : getCompetitorResearcherId;

  // const isAppEcoSystem = () => {
  //   if (!isWhitelabel || (isWhitelabel && isParentAccount)) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };
  const hideForLinkgraphUser = () => customerSuccessManager || isVendastaClient;

  const getMenuItems = (subscription: SubscriptionType) => {
    return [
      {
        title: 'Home',
        key: 'home',
        color: '#F1AA3E',
        path: '/home',
        icon: <Icon onClick={() => {}} icon={faHome} color='#A3A4A4' />,
        items: [],
      },
      {
        title: 'Content',
        key: 'content',
        color: '#1FAC47',
        icon: <Icon onClick={() => {}} icon={faPencil} color='#A3A4A4' />,
        items: [
          {
            title: 'Content Genius',
            key: `/${routes.landingPageOptimizer}`,
            path: `/${routes.landingPageOptimizer}`,
            color: '#1FAC47',
            icon: <Icon onClick={() => {}} icon={faPenToSquare} color='#1FAC47'/>,
          },
          {
            title: 'Onpage Audit',
            key: `/${routes.onPageAudit}`,
            path: `/${routes.onPageAudit}`,
            color: '#1FAC47',
            icon: <Icon onClick={() => {}} icon={faFileChartColumn} color='#A3A4A4'/>,
          },
          {
            title: 'Topical Maps',
            key: `/${routes.topicalMaps}`,
            path: `/${routes.topicalMaps}`,
            color: '#1FAC47',
            isNew: true,
            icon: <Icon onClick={() => {}} icon={faChartTreeMap} color='#A3A4A4' />,
          },
          {
            title: 'Content Planner',
            key: '/content/content-planner',
            path: '/content/content-planner',
            color: '#1FAC47',
            icon: <Icon onClick={() => {}} icon={faGrid2Plus} color='#A3A4A4'/>,
          },
          {
            title: 'QUEST',
            key: '/content/quest',
            path: '/content/quest',
            color: '#1FAC47',
            isNew: true,
            icon: <Icon onClick={() => {}} icon={faQ} color='#A3A4A4'/>,
            ...(isGHLAccount && {hidden: true}),
          },
          {
            title: 'Scholar',
            key: '/content/semantic-grader',
            path: '/content/semantic-grader',
            color: '#1FAC47',
            isNew: true,
            icon: <Icon onClick={() => {}} icon={faChartScatter3d} color='#A3A4A4'/>,
            ...(isGHLAccount && {hidden: true}),
          },
          {
            title: 'Meta Generator',
            key: `/${routes.contentIdeas}`,
            path: `/${routes.contentIdeas}`,
            color: '#1FAC47',
            icon: <Icon onClick={() => {}} icon={faLightbulb} color='#A3A4A4'/>,
          },
          // {
          //   title: 'AI Content Templates',
          //   key: `/${routes.aiContentGenerator}`,
          //   path: `/${routes.aiContentGenerator}`,
          //   color: '#1FAC47',
          //   isBeta: false,
          //   icon: <Icon onClick={() => {}} icon={faLightbulb} color='#A3A4A4'/>,
          // },
          {
            title: 'Content Rewriting',
            key: '/content/rewriting-tool',
            path: '/content/rewriting-tool',
            color: '#1FAC47',
            icon: <Icon onClick={() => {}} icon={faArrowsRotate} color='#A3A4A4'/>,
          },
          // {
          //   title: 'Content Brief',
          //   key: '/content/content-brief',
          //   path: '/content/content-brief',
          //   hidden: true,
          //   isAlpha: true,
          //   color: '#1FAC47',
          //   icon: <Icon onClick={() => {}} icon={faArrowsRotate} color='#A3A4A4'/>,
          // },
        ],
      },
      {
        title: `${whitelabelOtto} SEO V2`,
        key: routes.ottoPageV2,
        color: '#2D6CCA',
        icon: <Icon onClick={() => {}} icon={faMicrochipAi} color='#fff'/>,
        path: '/otto-page-v2',
        items: [
          {name: `${whitelabelOtto} SEO V2`, key: '0', hidden: isGHLAccount},
          {
            title: 'All Sites',
            key: '/otto-page-v2',
            path: '/otto-page-v2',
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faList} color='#A3A4A4' />,
            hidden: isGHLAccount,
          },
          {
            title: 'Overview',
            key: `/otto-page-v2/tasks?uuid=${getOttoV2Project?.uuid || getOttoV2ProjectsList?.results[0]?.uuid}`,
            path: `/otto-page-v2/tasks?uuid=${getOttoV2Project?.uuid || getOttoV2ProjectsList?.results[0]?.uuid}`,
            commingSoon: getOttoV2ProjectsList?.results?.length == 0,
            disabledMsg: 'You need to create a project.',
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faChartSimpleHorizontal} color='#A3A4A4' />,
            hidden: isGHLAccount,
          },
          {
            title: 'Knowledge Base',
            key: `https://intercom.help/search-atlas/en/collections/10864692-knowledge-base`,
            path: `https://intercom.help/search-atlas/en/collections/10864692-knowledge-base`,
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faBook} color='#A3A4A4' />,
            hidden: isGHLAccount,
            linkProps: {target: '_blank'},
          },
          {name: 'Site Audit'},
          {
            title: 'All Audits',
            key: '/site-audit-list',
            path: '/site-audit/list',
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faList} color='#A3A4A4'/>,
          },
          {
            title: 'Overview',
            key: '/site-audit/overview',
            path: '/site-audit/overview',
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faChartSimple} color='#A3A4A4'/>,
            commingSoon: !(getAuditSitesList?.length || (router?.query?.id && router?.pathname?.includes('site-audit'))),
            disabledMsg: 'You need to create a project.',
          },
          {
            title: 'Page Explorer',
            key: '/site_audit/page_explorer',
            path: '/site-audit/page-explorer',
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faFileMagnifyingGlass} color='#A3A4A4'/>,
            commingSoon: !(getAuditSitesList?.length || (router?.query?.id && router?.pathname?.includes('site-audit'))),
            disabledMsg: 'You need to create a project.',
          },
          {
            title: 'Crawl Monitoring',
            key: '/site_audit/crawl_monitoring',
            path: '/site-audit/crawl-monitoring',
            color: '#2D6CCA',
            commingSoon: !(getAuditSitesList?.length || (router?.query?.id && router?.pathname?.includes('site-audit'))),
            disabledMsg: 'You need to create a project.',
            icon: <Icon onClick={() => {}} icon={faSpider} color='#A3A4A4'/>,
          },
          {
            title: 'Content Velocity',
            key: '/site_audit/content-velocity',
            path: '/site-audit/content-velocity',
            color: '#2D6CCA',
            commingSoon: !(getAuditSitesList?.length || (router?.query?.id && router?.pathname?.includes('site-audit'))),
            disabledMsg: 'You need to create a project.',
            icon: <Icon onClick={() => {}} icon={faCalendarClock} color='#A3A4A4'/>,
            isBeta: true,
          },
          {
            title: 'Domain-level',
            key: '/site_audit/sitewide_data',
            path: '/site-audit/sitewide-data',
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faSitemap} color='#A3A4A4'/>,
            commingSoon: !(getAuditSitesList?.length || (router?.query?.id && router?.pathname?.includes('site-audit'))),
            disabledMsg: 'You need to create a project.',
          },
          {
            title: 'Issues',
            key: `${routes.saIssuesPage}`,
            path: `${routes.saIssuesPage}`,
            color: '#2D6CCA',
            icon: <Icon onClick={() => { }} icon={faBug} color='#A3A4A4' />,
            commingSoon: !(getAuditSitesList?.length || (router?.query?.id && router?.pathname?.includes('site-audit'))),
            disabledMsg: 'You need to create a project.',
          },
          {
            title: 'Page Pruning',
            key: 'page pruning',
            path: '/site-audit/pruning-page',
            color: '#2D6CCA',
            isBeta: false,
            icon: <Icon onClick={() => { }} icon={faFileCircleExclamation} color='#A3A4A4' />,
            commingSoon: !(getAuditSitesList?.length || (router?.query?.id && router?.pathname?.includes('site-audit'))),
            disabledMsg: 'You need to create a project.',
          },
          {
            title: 'Site Visualization',
            key: 'siteVisualization',
            path: '/site-audit/site-visualization',
            isBeta: true,
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faChartScatterBubble} color='#A3A4A4'/>,
            commingSoon: !(getAuditSitesList?.length || (router?.query?.id && router?.pathname?.includes('site-audit'))),
            disabledMsg: 'You need to create a project.',
          },
          {
            title: 'Email Alerts',
            key: 'email-alerts',
            path: '/site-audit/email-alerts',
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faEnvelope} color='#A3A4A4'/>,
            commingSoon: !(getAuditSitesList?.length || (router?.query?.id && router?.pathname?.includes('site-audit'))),
            disabledMsg: 'You need to create a project.',
          },
          {
            title: 'Live Monitoring',
            key: `${routes.saAlertsPage}`,
            path: `${routes.saAlertsPage}`,
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faMonitorWaveform} color='#A3A4A4' />,
            commingSoon: !(getAuditSitesList?.length || (router?.query?.id && router?.pathname?.includes('site-audit'))),
            disabledMsg: 'You need to create a project.',
          },
          // {
          //   title: 'Focus Terms',
          //   key: `/${routes.focusTerms}`,
          //   path: `/${routes.focusTerms}`,
          //   color: '#1FAC47',
          //   icon: <Icon onClick={() => {}} icon={faHighlighter} color='#A3A4A4' />,
          // },
        ],
      },
      {
        title: 'Site Explorer',
        key: `/${routes.competitorResearcher.default}`,
        path: `/${routes.competitorResearcher.default}`,
        color: '#7F4EAD',
        icon: <Icon onClick={() => {}} icon={faMagnifyingGlassChart} color='#A3A4A4' />,
        items: [
          {name: 'Site Explorer', key: '0'},
          {
            title: 'All Sites',
            key: `/${routes.competitorResearcher.default}`,
            path: `/${routes.competitorResearcher.default}`,
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faList} color='#A3A4A4'/>,
          },
          {
            title: 'Overview',
            key: `/${routes.competitorResearcher.detail}/${fromRouterResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl || ''}`,
            path: `/${routes.competitorResearcher.detail}/${fromRouterResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl || ''}`,
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faChartSimpleHorizontal} color='#A3A4A4'/>,
            commingSoon: !fromRouterResearcherId || isNaN(+(fromRouterResearcherId)),
            disabledMsg: 'You need to create a project.',
          },
          {
            title: 'Topical Dominance',
            key: `/${routes.competitorResearcher.topicalDominance}/${fromRouterResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl || ''}`,
            path: `/${routes.competitorResearcher.topicalDominance}/${fromRouterResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl || ''}`,
            color: '#7F4EAD',
            isNew: true,
            size: 'small',
            icon: <Icon onClick={() => {}} icon={faObjectExclude} color='#A3A4A4'/>,
            commingSoon: !fromRouterResearcherId || isNaN(+(fromRouterResearcherId)),
            disabledMsg: 'You need to create a project.',
          },
          {
            title: 'Keywords',
            key: `/${routes.competitorResearcher.keywords}/${fromRouterResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl || ''}`,
            path: `/${routes.competitorResearcher.keywords}/${fromRouterResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl || ''}`,
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faKeySkeletonRegular} color='#A3A4A4'/>,
            commingSoon: !fromRouterResearcherId || isNaN(+(fromRouterResearcherId)),
            disabledMsg: 'You need to create a project.',
          },
          {
            title: 'Pages',
            key: `/${routes.competitorResearcher.pages}/${fromRouterResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl || ''}`,
            path: `/${routes.competitorResearcher.pages}/${fromRouterResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl || ''}`,
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faFile} color='#A3A4A4'/>,
            commingSoon: !fromRouterResearcherId || isNaN(+(fromRouterResearcherId)),
            disabledMsg: 'You need to create a project.',
          },
          {
            title: 'Subdomains',
            key: `/${routes.competitorResearcher.subdomains}/${fromRouterResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl || ''}`,
            path: `/${routes.competitorResearcher.subdomains}/${fromRouterResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl || ''}`,
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faGlobe} color='#A3A4A4'/>,
            commingSoon: !fromRouterResearcherId || isNaN(+(fromRouterResearcherId)),
            disabledMsg: 'You need to create a project.',
          },
          {
            title: 'Position Changes',
            key: `/${routes.competitorResearcher.positionChanges}/${fromRouterResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl || ''}`,
            path: `/${routes.competitorResearcher.positionChanges}/${fromRouterResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl || ''}`,
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faArrowUpArrowDown} color='#A3A4A4'/>,
            commingSoon: !fromRouterResearcherId || isNaN(+(fromRouterResearcherId)),
            disabledMsg: 'You need to create a project.',
          },
          {
            title: 'Backlinks',
            key: `/${routes.competitorResearcher.backlinks}/${fromRouterResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl || ''}`,
            path: `/${routes.competitorResearcher.backlinks}/${fromRouterResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl || ''}`,
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faLinkSimple} color='#A3A4A4'/>,
            commingSoon: !fromRouterResearcherId || isNaN(+(fromRouterResearcherId)),
            disabledMsg: 'You need to create a project.',
          },
          {
            title: 'Competitors',
            key: `/${routes.competitorResearcher.competitors}/${fromRouterResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl || ''}`,
            path: `/${routes.competitorResearcher.competitors}/${fromRouterResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl || ''}`,
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faArrowUpRightDots} color='#A3A4A4'/>,
            commingSoon: !fromRouterResearcherId || isNaN(+(fromRouterResearcherId)),
            disabledMsg: 'You need to create a project.',
          },
          {
            title: 'Google Ads',
            key: `/${routes.competitorResearcher.googlePage}/${fromRouterResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl || ''}`,
            path: `/${routes.competitorResearcher.googlePage}/${fromRouterResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl || ''}`,
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faRectangleAd} color='#A3A4A4'/>,
            commingSoon: !fromRouterResearcherId || isNaN(+(fromRouterResearcherId)),
            disabledMsg: 'You need to create a project.',
          },
          {
            title: 'Facebook Ads',
            key: `${routes.competitorResearcher.facebookAds}/${fromRouterResearcherId}`,
            path: `${routes.competitorResearcher.facebookAds}/${fromRouterResearcherId}`,
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faFacebookSquare} color='#A3A4A4'/>,
            isNew: true,
            commingSoon: !fromRouterResearcherId || isNaN(+(fromRouterResearcherId)),
            disabledMsg: 'You need to create a project.',
          },
          {name: 'GSC Performance'},
          {
            title: 'All Sites',
            key: '/gsc/sites-list',
            path: '/gsc/sites-list',
            color: '#936BDA',
            icon: <Icon onClick={() => {}} icon={faList} color='#A3A4A4'/>,
          },
          {
            title: 'Overview',
            key: 'gsc/overview',
            path: `/${routes.overview}`,
            color: '#936BDA',
            commingSoon: !(navBar?.hasSiteProperties),
            disabledMsg: 'You need to create a project.',
            icon: <Icon onClick={() => {}} icon={faChartSimpleHorizontal} color='#A3A4A4'/>,
          },
          {
            title: 'Keywords',
            key: 'gsc/top-keywords',
            path: `/${routes.keywords}`,
            isAlpha: false,
            color: '#936BDA',
            commingSoon: !(navBar?.hasSiteProperties),
            disabledMsg: 'You need to create a project.',
            icon: <Icon onClick={() => {}} icon={faKeySkeletonRegular} color='#A3A4A4'/>,
          },
          {
            title: 'Pages',
            key: 'gsc/pages',
            path: `/${routes.topPagesNew}`,
            hidden: false,
            color: '#936BDA',
            commingSoon: !(navBar?.hasSiteProperties),
            disabledMsg: 'You need to create a project.',
            icon: <Icon onClick={() => {}} icon={faFile} color='#A3A4A4'/>,
            isAlpha: false,
          },
          // {
          //   title: 'Backlinks',
          //   key: 'gsc/backlinks',
          //   path: `/${routes.backlinks}`,
          //   color: '#936BDA',
          //   icon: <Icon onClick={() => {}} icon={faLinkSimple} color='#A3A4A4'/>,
          // },
          {
            title: 'Site Events',
            key: 'site events',
            path: '/gsc/site-events',
            isBeta: false,
            color: '#936BDA',
            commingSoon: !(navBar?.hasSiteProperties),
            disabledMsg: 'You need to create a project.',
            icon: <Icon onClick={() => {}} icon={faCalendar} color='#A3A4A4'/>,
          },
          {
            title: 'Site Structure',
            key: `/${routes.siteStructure}`,
            path: `/${routes.siteStructure}`,
            commingSoon: !(navBar?.hasSiteProperties),
            disabledMsg: 'You need to create a project.',
            color: '#936BDA',
            icon: <Icon onClick={() => {}} icon={faFolderTree} color='#A3A4A4'/>,
          },
          // {
          //   title: 'Competitor Discovery',
          //   key: 'gsc/competitor-descovery',
          //   path: `/${routes.competitorDiscovery}`,
          //   isAlpha: true,
          //   size: 'small',
          //   color: '#2D6CCA',
          //   icon: <Icon onClick={() => {}} icon={faArrowUpRightDots} color='#A3A4A4'/>,
          // },
          {
            title: 'Reports',
            key: '/gsc/reports',
            path: '/gsc/reports',
            isAlpha: true,
            color: '#2D6CCA',
            commingSoon: !(navBar?.hasSiteProperties),
            disabledMsg: 'You need to create a project.',
            icon: <Icon onClick={() => {}} icon={faFileChartColumn} color='#A3A4A4'/>,
          },
          // {
          //   title: 'Additional Info',
          //   key: '/gsc/additional-info',
          //   path: '/gsc/additional-info',
          //   isBeta: true,
          //   color: '#2D6CCA',
          //   icon: <Icon onClick={() => {}} icon={faGrid2} color='#A3A4A4'/>,
          // },
        ],
      },
      {
        title: 'Keywords',
        key: 'research',
        color: '#FF8536',
        icon: <Icon onClick={() => {}} icon={faKeySkeletonRegular} color='#A3A4A4' />,
        items: [
          {
            title: 'Keyword Research',
            key: `/research/keyword-researcher`,
            path: `/research/keyword-researcher`,
            color: '#FF8536',
            isBeta: true,
            size: 'small',
            hidden: !router?.pathname?.includes('public') && !hasProductAccess(subscription, 'keyword-researcher'),
            icon: <Icon onClick={() => {}} icon={faMagnifyingGlassRegular} color='#A3A4A4'/>,
          },
          {
            title: 'Keyword Magic Tool',
            key: `/research/keyword-magic-tool`,
            path: `/research/keyword-magic-tool`,
            color: '#FF8536',
            isBeta: true,
            size: 'small',
            icon: <Icon onClick={() => {}} icon={faWandMagicSparkles} color='#A3A4A4'/>,
          },
          {
            title: 'Keyword Rank Tracker',
            key: `${routes.keywordRankTracker}`,
            path: `${routes.keywordRankTracker}`,
            color: '#FF8536',
            icon: <Icon onClick={() => {}} icon={faShuffle} color='#A3A4A4'/>,
          },
          {
            title: 'Keyword Gap',
            key: `${routes.keywordGapAnalysis}`,
            path: `${routes.keywordGapAnalysis}`,
            color: '#FF8536',
            icon: <Icon onClick={() => {}} icon={faChartGantt} color='#A3A4A4'/>,
          },
        ],
      },
      {
        title: 'Site Audit',
        key: 'site-audit',
        color: '#2D6CCA',
        icon: <ThunderboltOutlined style={{color: '#A3A4A4'}} />,
        items: [
          {
            title: 'Audits list',
            key: '/site-audit-list',
            path: '/site-audit/list',
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faList} color='#A3A4A4'/>,
          },
          {
            title: 'Overview',
            key: '/site-audit/overview',
            path: '/site-audit/overview',
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faChartSimple} color='#A3A4A4'/>,
            commingSoon: !(getAuditSitesList?.length || (router?.query?.id && router?.pathname?.includes('site-audit'))),
            disabledMsg: 'You need to create a project.',
          },
          {
            title: 'Page Explorer',
            key: '/site_audit/page_explorer',
            path: '/site-audit/page-explorer',
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faFileMagnifyingGlass} color='#A3A4A4'/>,
            commingSoon: !(getAuditSitesList?.length || (router?.query?.id && router?.pathname?.includes('site-audit'))),
            disabledMsg: 'You need to create a project.',
          },
          {
            title: 'Crawl Monitoring',
            key: '/site_audit/crawl_monitoring',
            path: '/site-audit/crawl-monitoring',
            color: '#2D6CCA',
            commingSoon: !(getAuditSitesList?.length || (router?.query?.id && router?.pathname?.includes('site-audit'))),
            disabledMsg: 'You need to create a project.',
            icon: <Icon onClick={() => {}} icon={faSpider} color='#A3A4A4'/>,
          },
          {
            title: 'Content Velocity',
            key: '/site_audit/content-velocity',
            path: '/site-audit/content-velocity',
            color: '#2D6CCA',
            commingSoon: !(getAuditSitesList?.length || (router?.query?.id && router?.pathname?.includes('site-audit'))),
            disabledMsg: 'You need to create a project.',
            icon: <Icon onClick={() => {}} icon={faCalendarClock} color='#A3A4A4'/>,
            isBeta: true,
          },
          {
            title: 'Domain-level',
            key: '/site_audit/sitewide_data',
            path: '/site-audit/sitewide-data',
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faSitemap} color='#A3A4A4'/>,
            commingSoon: !(getAuditSitesList?.length || (router?.query?.id && router?.pathname?.includes('site-audit'))),
            disabledMsg: 'You need to create a project.',
          },
          {
            title: 'Issues',
            key: `${routes.saIssuesPage}`,
            path: `${routes.saIssuesPage}`,
            color: '#2D6CCA',
            icon: <Icon onClick={() => { }} icon={faBug} color='#A3A4A4' />,
            commingSoon: !(getAuditSitesList?.length || (router?.query?.id && router?.pathname?.includes('site-audit'))),
            disabledMsg: 'You need to create a project.',
          },
          {
            title: 'Page Pruning',
            key: 'page pruning',
            path: '/site-audit/pruning-page',
            color: '#2D6CCA',
            isBeta: false,
            icon: <Icon onClick={() => { }} icon={faFileCircleExclamation} color='#A3A4A4' />,
            commingSoon: !(getAuditSitesList?.length || (router?.query?.id && router?.pathname?.includes('site-audit'))),
            disabledMsg: 'You need to create a project.',
          },
          {
            title: 'Site Visualization',
            key: 'siteVisualization',
            path: '/site-audit/site-visualization',
            isBeta: true,
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faChartScatterBubble} color='#A3A4A4'/>,
            commingSoon: !(getAuditSitesList?.length || (router?.query?.id && router?.pathname?.includes('site-audit'))),
            disabledMsg: 'You need to create a project.',
          },
          {
            title: 'Email Alerts',
            key: 'email-alerts',
            path: '/site-audit/email-alerts',
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faEnvelopeOpenText} color='#A3A4A4'/>,
            commingSoon: !(getAuditSitesList?.length || (router?.query?.id && router?.pathname?.includes('site-audit'))),
            disabledMsg: 'You need to create a project.',
          },
          {
            title: 'Live Alerts Monitoring',
            key: `${routes.saAlertsPage}`,
            path: `${routes.saAlertsPage}`,
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faEnvelopeOpenText} color='#A3A4A4' />,
            commingSoon: !(getAuditSitesList?.length || (router?.query?.id && router?.pathname?.includes('site-audit'))),
            disabledMsg: 'You need to create a project.',
          },
        ],
      },
      // {
      //   title: <div>GSC Performance</div>,
      //   key: 'gsc',
      //   color: '#2D6CCA',
      //   icon: <Icon onClick={() => {}} icon={faChartLine} color='#A3A4A4' size='sm' />,
      //   items: ,
      // },
      // /authority-building
      {
        title: 'Authority Building',
        key: '/outreach/press-release',
        color: '#2D6CCA',
        icon: <Icon onClick={() => {}} icon={faMegaphone} color='#A3A4A4'/>,
        ...(isGHLAccount && {hidden: true}),
        items: [
          {name: 'Authority Building', key: '0'},
          {
            title: 'PR Distribution',
            key: '/outreach/press-release',
            path: '/outreach/press-release',
            color: '#2D6CCA',
            isNew: true,
            icon: <FontAwesomeIcon icon={faFileLines} color='#2D6CCA' />,
          },
          {
            title: 'Link Building Exchange',
            key: 'link-building-exchange',
            path: `https://app.linklaboratory.com/my-projects?jwtToken=${localStorage.getItem('token')}`,
            color: '#2D6CCA',
            icon: <FontAwesomeIcon icon={faCodeCompare} color='#2D6CCA' />,
            hidden: hideForLinkgraphUser(),
            linkProps: {target: '_blank'},
          },
          {name: 'Digital PR'},
          {
            title: 'Campaigns',
            key: '/outreach/campaigns',
            path: '/outreach/campaigns',
            color: '#2D6CCA',
            isBeta: true,
            icon: <FontAwesomeIcon icon={faBullseyeArrow} color='#2D6CCA' />,
          },
          {
            title: 'Inbox',
            key: '/outreach/inbox',
            path: '/outreach/inbox',
            color: '#2D6CCA',
            isBeta: true,
            icon: <FontAwesomeIcon icon={faInbox} color='#2D6CCA' />,
            unreadEmails: emailUnreadCount,
          },
          {
            title: 'Sent',
            key: '/outreach/sent',
            path: '/outreach/sent',
            color: '#2D6CCA',
            isBeta: true,
            icon: <FontAwesomeIcon icon={faPaperPlaneTop} color='#2D6CCA' />,
          },
          {
            title: 'Templates',
            key: '/outreach/templates',
            path: '/outreach/templates',
            color: '#2D6CCA',
            isBeta: true,
            icon: <FontAwesomeIcon icon={faMemoPad} color='#2D6CCA' />,
          },
          {name: 'Journalist Outreach', hidden: true},
          {
            title: 'Journalist Details',
            key: `/${routes.journalistDetails}`,
            path: `/${routes.journalistDetails}`,
            hidden: true,
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faNotebook} color='#2D6CCA'/>,
          },
          {
            title: 'Media Outlets',
            key: `/${routes.mediaOutletsDetail}`,
            path: `/${routes.mediaOutletsDetail}`,
            hidden: true,
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faNotebook} color='#2D6CCA'/>,
          },
        ],
      },
      // {
      //   title: 'Digital PR',
      //   key: 'digital-pr',
      //   color: '#2D6CCA',
      //   icon: <Icon onClick={() => {}} icon={faMegaphone} color='#A3A4A4'/>,
      //   ...(!isParentAccount && isWhitelabel && {hidden: !isAllowedPage('enableDigitalPr', WLchildrenPages)}),
      //   items: [
      //     {
      //       title: 'Journalist Details',
      //       key: `/${routes.journalistDetails}`,
      //       path: `/${routes.journalistDetails}`,
      //       hidden: true,
      //       color: '#2D6CCA',
      //       icon: <Icon onClick={() => {}} icon={faNotebook} color='#2D6CCA'/>,
      //     },
      //     {
      //       title: 'Media Outlets',
      //       key: `/${routes.mediaOutletsDetail}`,
      //       path: `/${routes.mediaOutletsDetail}`,
      //       hidden: true,
      //       color: '#2D6CCA',
      //       icon: <Icon onClick={() => {}} icon={faNotebook} color='#2D6CCA'/>,
      //     },
      //     {
      //       title: 'Campaigns',
      //       key: '/outreach/campaigns',
      //       path: '/outreach/campaigns',
      //       color: '#2D6CCA',
      //       isBeta: true,
      //       icon: <FontAwesomeIcon icon={faBullseyeArrow} color='#2D6CCA' />,
      //       ...(!isParentAccount && isWhitelabel && {hidden: !isAllowedPage('enableDigitalPr', WLchildrenPages)}),
      //     },
      //     {
      //       title: 'Inbox',
      //       key: '/outreach/inbox',
      //       path: '/outreach/inbox',
      //       color: '#2D6CCA',
      //       isBeta: true,
      //       icon: <FontAwesomeIcon icon={faInbox} color='#2D6CCA' />,
      //       unreadEmails: emailUnreadCount,
      //       ...(!isParentAccount && isWhitelabel && {hidden: !isAllowedPage('enableDigitalPr', WLchildrenPages)}),
      //     },
      //     {
      //       title: 'Sent',
      //       key: '/outreach/sent',
      //       path: '/outreach/sent',
      //       color: '#2D6CCA',
      //       isBeta: true,
      //       icon: <FontAwesomeIcon icon={faPaperPlaneTop} color='#2D6CCA' />,
      //       ...(!isParentAccount && isWhitelabel && {hidden: !isAllowedPage('enableDigitalPr', WLchildrenPages)}),
      //     },
      //     {
      //       title: 'Templates',
      //       key: '/outreach/templates',
      //       path: '/outreach/templates',
      //       color: '#2D6CCA',
      //       isBeta: true,
      //       icon: <FontAwesomeIcon icon={faMemoPad} color='#2D6CCA' />,
      //       ...(!isParentAccount && isWhitelabel && {hidden: !isAllowedPage('enableDigitalPr', WLchildrenPages)}),
      //     },
      //   ],
      // },
      {
        title: 'Report Builder',
        key: routes.reportBuilder.history,
        color: '#47494c',
        icon: <Icon onClick={() => {}} icon={faFileChartColumnDuotone} color='#47494c'/>,
        path: '/report-builder',
        // ...(!isParentAccount && {hidden: !isAllowedPage('enableReportBuilder', WLchildrenPages)}),
        items: [
          {
            title: 'Reports List',
            key: `/${routes.reportBuilder.list}`,
            path: `/${routes.reportBuilder.list}`,
            color: '#47494c',
            icon: <Icon onClick={() => {}} icon={faList} color='#47494c' />,
          },
          {
            title: 'Full Report',
            key: `/${routes.reportBuilder.bulkExport}?report_id=${reportBuilderId}&domain=${reportBuilderDomain}`,
            path: `/${routes.reportBuilder.bulkExport}?report_id=${reportBuilderId}&domain=${reportBuilderDomain}`,
            color: '#47494c',
            commingSoon: !router?.query?.['report_id'] && (getReportsProjectsList?.length==0 || !getReportsProjectsList),
            disabledMsg: 'You need to create a project.',
            icon: <Icon onClick={() => {}} icon={faLayerGroup} color='#47494c' />,
          },
          {
            title: 'Individual Reports',
            key: `/${routes.reportBuilder.detail}?report_id=${reportBuilderId}`,
            path: `/${routes.reportBuilder.detail}?report_id=${reportBuilderId}`,
            color: '#47494c',
            commingSoon: !router?.query?.['report_id'] && (getReportsProjectsList?.length==0 || !getReportsProjectsList),
            disabledMsg: 'You need to create a project.',
            icon: <Icon onClick={() => {}} icon={faListOl} color='#47494c' />,
          },
        ],
      },
      // {
      //   title: 'Other Tools',
      //   key: 'other-tools',
      //   color: '#019197',
      //   icon: <Icon onClick={() => {}} icon={faObjectsColumn} color='#A3A4A4' />,
      //   items: [
      //     {
      //       title: 'Focus Terms',
      //       key: 'focus-terms',
      //       path: `/${routes.focusTerms}`,
      //       color: '#019197',
      //       icon: <Icon onClick={() => {}} icon={faHighlighter} color='#A3A4A4' />,
      //     },
      // {
      //   title: 'Local Search Results',
      //   key: 'local-search-results',
      //   path: '/local-search-results',
      //   color: '#019197',
      //   icon: <Icon onClick={() => {}} icon={faMapMarkerAlt} color='#A3A4A4' />,
      // },
      // {
      //   title: 'Schema Creator',
      //   key: 'schema',
      //   path: '/schema',
      //   color: '#019197',
      //   icon: <Icon onClick={() => {}} icon={faListAlt} color='#A3A4A4' />,
      // },
      // {
      //   title: 'Bulk DA Checker',
      //   key: 'bulk-da-checker',
      //   color: '#019197',
      //   icon: <Icon onClick={() => {}} icon={faCheckDouble} color='#A3A4A4' />,
      //   path: '/bulk-da-checker',
      // },
      //   ],
      // },
      {
        title: 'Schema Creator',
        key: '/schema',
        path: '/schema',
        color: '#ffffff',
        hidden: !isLinkgraph,
        isAlpha: true,
        icon: <Icon onClick={() => {}} icon={faListAlt} color='#A3A4A4' />,
      },
      // {
      //   title: 'Local Search Results',
      //   key: '/local-search-results',
      //   path: '/local-search-results',
      //   color: '#ffffff',
      //   icon: <Icon onClick={() => {}} icon={faMapMarkerAlt} color='#A3A4A4' />,
      // },
      {
        title: 'Backlinks',
        key: 'backlink',
        color: '#34AEF3',
        icon: <Icon onClick={() => {}} icon={faLink} color='#A3A4A4' />,
        items: [{
          title: 'Backlink Research',
          key: `${routes.backlinkResearcher}`,
          path: `${routes.backlinkResearcher}`,
          // hidden: !isLinkgraph,
          // isAlpha: true,
          color: '#34AEF3',
          icon: <Icon onClick={() => {}} icon={faMagnifyingGlassRegular} color='#A3A4A4'/>,
        },
        {
          title: 'Projects List',
          key: `/${routes.backlinkAnalyzer}`,
          path: `/${routes.backlinkAnalyzer}`,
          color: '#34AEF3',
          hidden: !isLinkgraph,
          isAlpha: true,
          icon: <Icon onClick={() => {}} icon={faList} color='#A3A4A4'/>,
        },
        {
          title: 'Link Gap Analysis',
          key: `${routes.backlinkGapAnalysis}`,
          path: `${routes.backlinkGapAnalysis}`,
          hidden: false,
          isBeta: false,
          color: '#34AEF3',
          icon: <Icon onClick={() => {}} icon={faChartGantt} color='#A3A4A4'/>,
        },
        {
          title: 'Compare Backlinks',
          key: `${routes.backlinkProfileAnalysis}`,
          path: `${routes.backlinkProfileAnalysis}`,
          hidden: false,
          isBeta: false,
          color: '#34AEF3',
          icon: <Icon onClick={() => {}} icon={faArrowRightArrowLeft} color='#A3A4A4'/>,
        },
        {
          title: 'Topical Relevance',
          key: `/${routes.topicalRelevance}`,
          path: `/${routes.topicalRelevance}`,
          color: '#34AEF3',
          icon: <Icon onClick={() => {}} icon={faDiagramVenn} color='#A3A4A4'/>,
          commingSoon: true,
        },
        {
          title: 'Backlink Velocity',
          key: `/${routes.backlinkVelocity}`,
          path: `/${routes.backlinkVelocity}`,
          color: '#34AEF3',
          icon: <Icon onClick={() => {}} icon={faChartLineUp} color='#A3A4A4'/>,
          commingSoon: true,
        },
        ],
      },
      {
        title: 'Bulk URL Analyzer',
        key: '/bulk-url-analyzer',
        color: '#ffffff',
        icon: <Icon onClick={() => {}} icon={faDuotoneChartSimpleHorizontal} color='#A3A4A4' />,
        path: '/bulk-url-analyzer',
        hidden: ![GROWTH_PLAN_ID, PRO_PLAN_ID].includes(plan),
        // isBeta: true,
        isNew: true,
        // hidden: !isParentAccount ? !isAllowedPage('enableBulkUrlAnalyzer', WLchildrenPages) ? true : ![GROWTH_PLAN_ID, PRO_PLAN_ID].includes(plan) : ![GROWTH_PLAN_ID, PRO_PLAN_ID].includes(plan),
      },
      {
        title: `${whitelabelOtto} v1.0`,
        key: routes.ottopage,
        color: '#1FAC47',
        icon: <Icon onClick={() => {}} icon={faMicrochipAi} color='#fff'/>,
        path: '/otto-page',
        ...(isGHLAccount && {hidden: true}),
      },
      {
        title: 'GBP Projects',
        key: routes.gbpProjectsV2,
        path: routes.gbpProjectsV2,
        color: '#2D6CCA',
        icon: <Icon onClick={() => {}} icon={faListUl} color='#A3A4A4'/>,
        items: [
          {
            title: 'GBP Projects',
            key: routes.gbpProjectsV2,
            path: routes.gbpProjectsV2,
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faListUl} color='#A3A4A4'/>,
          },
          {
            title: 'Overview',
            key: routes.gbpProjectOverview,
            path: routes.gbpProjectOverview,
            color: '#2D6CCA',
            commingSoon: !(businessesList?.data?.length || (router?.query?.id && router?.pathname?.includes('research-v2'))),
            disabledMsg: 'You need to connect a project.',
            icon: <Icon onClick={() => {}} icon={faChartSimpleHorizontal} color='#A3A4A4'/>,
          },
          {name: 'AI AUTOMATIONS'},
          {
            title: 'Posts',
            key: routes.gbpPosts,
            path: routes.gbpPosts,
            color: '#2D6CCA',
            commingSoon: !(businessesList?.data?.length || (router?.query?.id && router?.pathname?.includes('research-v2'))),
            disabledMsg: 'You need to connect a project.',
            icon: <Icon onClick={() => {}} icon={faMessageLines} color='#A3A4A4'/>,
          },
          {
            title: 'Reviews',
            key: routes.gbpReviews,
            path: routes.gbpReviews,
            color: '#2D6CCA',
            commingSoon: !(businessesList?.data?.length || (router?.query?.id && router?.pathname?.includes('research-v2'))),
            disabledMsg: 'You need to connect a project.',
            icon: <Icon onClick={() => {}} icon={faStarHalfStroke} color='#A3A4A4'/>,
            isBeta: false,
          },
          {
            title: 'Q&As',
            key: routes.gbpQAs,
            path: routes.gbpQAs,
            color: '#2D6CCA',
            commingSoon: !(businessesList?.data?.length || (router?.query?.id && router?.pathname?.includes('research-v2'))),
            disabledMsg: 'You need to connect a project.',
            icon: <Icon onClick={() => {}} icon={faQuestion} color='#A3A4A4'/>,
          },
          {name: 'LOCAL SEO TOOLS'},
          {
            title: 'Local SEO Heatmaps',
            key: routes.localSeoHeatmaps,
            path: routes.localSeoHeatmaps,
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faMapLocationDot} color='#A3A4A4'/>,
          },
          {
            title: 'Local Citations',
            key: routes.localCitationsV2,
            path: routes.localCitationsV2,
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faQuoteLeft} color='#A3A4A4'/>,
            isBeta: false,
            ...(isGHLAccount && {hidden: true}),
          },
          {
            title: 'Local SERPS',
            key: routes.localSerpsV2,
            path: routes.localSerpsV2,
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faMagnifyingGlass} color='#A3A4A4' />,
          },
        ],
      },
      ...(
        isSmallScreen ? [

          {
            title: 'GBP Projects V2',
            key: `${routes.gbpProjectsV2}`,
            path: `${routes.gbpProjectsV2}`,
            color: '#2D6CCA',
            hidden: !isLinkgraph,
            icon: <Icon onClick={() => {}} icon={faLocationDot} color='#A3A4A4'/>,
            items: [
              {
                title: 'All GBPs',
                key: `${routes.gbpProjectsV2}`,
                path: `${routes.gbpProjectsV2}`,
                color: '#2D6CCA',
                icon: <Icon onClick={() => {}} icon={faListUl} color='#A3A4A4'/>,
              },
              {
                title: 'Overview',
                key: `${routes.gbpProjectOverview}`,
                path: `${routes.gbpProjectOverview}`,
                color: '#2D6CCA',
                commingSoon: !(businessesList?.data?.length || (router?.query?.id && router?.pathname?.includes('gbp-galactic'))),
                disabledMsg: 'You need to connect a project.',
                icon: <Icon onClick={() => {}} icon={faChartSimpleHorizontal} color='#A3A4A4'/>,
              },
              {
                title: 'Posts',
                key: routes.gbpPosts,
                path: routes.gbpPosts,
                color: '#2D6CCA',
                commingSoon: !(businessesList?.data?.length || (router?.query?.id && router?.pathname?.includes('gbp-galactic'))),
                disabledMsg: 'You need to connect a project.',
                icon: <Icon onClick={() => {}} icon={faMessageLines} color='#A3A4A4'/>,
              },
              {
                title: 'Reviews',
                key: routes.gbpReviews,
                path: routes.gbpReviews,
                color: '#2D6CCA',
                commingSoon: !(businessesList?.data?.length || (router?.query?.id && router?.pathname?.includes('gbp-galactic'))),
                disabledMsg: 'You need to connect a project.',
                icon: <Icon onClick={() => {}} icon={faStarHalfStroke} color='#A3A4A4'/>,
                isBeta: false,
              },
              {
                title: 'Q&As',
                key: routes.gbpQAs,
                path: routes.gbpQAs,
                color: '#2D6CCA',
                commingSoon: !(businessesList?.data?.length || (router?.query?.id && router?.pathname?.includes('gbp-galactic'))),
                disabledMsg: 'You need to connect a project.',
                icon: <Icon onClick={() => {}} icon={faQuestion} color='#A3A4A4'/>,
              },
              {
                title: 'Local SEO Heatmaps',
                key: routes.localSeoHeatmaps,
                path: routes.localSeoHeatmaps,
                color: '#2D6CCA',
                icon: <Icon onClick={() => {}} icon={faMapLocationDot} color='#A3A4A4'/>,
              },
              {
                title: 'Local Citations',
                key: routes.localCitationsV2,
                path: routes.localCitationsV2,
                color: '#2D6CCA',
                icon: <Icon onClick={() => {}} icon={faQuoteLeft} color='#A3A4A4'/>,
                isBeta: false,
                ...(isGHLAccount && {hidden: true}),
              },
              {
                title: 'Local SERPS',
                key: routes.localSerpsV2,
                path: routes.localSerpsV2,
                color: '#2D6CCA',
                icon: <Icon onClick={() => {}} icon={faMagnifyingGlass} color='#A3A4A4' />,
              },
            ],
          },
        ] : []
      ),
      {
        title: `${whitelabelOtto} PPC`,
        key: routes.ottoPpcAdsGoogle,
        hidden: true,
        color: '#2D6CCA',
        items: [
          {name: `${whitelabelOtto} PPC`},
          {
            title: `${whitelabelOtto} Google Ads`,
            key: `/${routes.ottoPpcAdsGoogle}`,
            path: `/${routes.ottoPpcAdsGoogle}`,
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faList} color='#A3A4A4' />,
          },
          {
            title: `${whitelabelOtto} Meta Ads`,
            key: `/${routes.ottoPpcAdsGoogle}/`,
            path: `/${routes.ottoPpcAdsGoogle}/`,
            color: '#2D6CCA',
            commingSoon: true,
            disabledMsg: 'Coming Soon',
            icon: <Icon onClick={() => {}} icon={faList} color='#A3A4A4' />,
          },
        ],
      },
      ...(isMediumScreen ? [
        {
          title: `${whitelabelOtto} SEO V2`,
          key: routes.ottoPageV2,
          color: '#2D6CCA',
          icon: <Icon onClick={() => {}} icon={faMicrochipAi} color='#fff'/>,
          path: '/otto-page-v2',
        },
      ] : []
      ),
      {
        title: 'Bulk DA Checker',
        key: '/bulk-da-checker',
        color: '#ffffff',
        icon: <Icon onClick={() => {}} icon={faCheckDouble} color='#A3A4A4' />,
        path: '/bulk-da-checker',
        ...(isGHLAccount && {hidden: true}),
        // ...(!isParentAccount && {hidden: !isAllowedPage('enableBulkDaChecker', WLchildrenPages)}),
      },
      // {
      //   title: 'Competitors',
      //   key: 'competitors',
      //   color: '#019197',
      //   icon: <Icon onClick={() => {}} icon={faChartSimpleDuotone} color='#A3A4A4' />,
      //   items: [
      //     {
      //       title: 'Competitor Research',
      //       key: `/${routes.competitorExplorer.default}`,
      //       path: `/${routes.competitorExplorer.default}`,
      //       color: '#019197',
      //       icon: <Icon onClick={() => {}} icon={faMagnifyingGlassRegular} color='#A3A4A4'/>,
      //     },
      //   ],
      // },
      // {
      //   title: 'Organic Landscapes',
      //   key: '/gsc/organic-search',
      //   path: '/gsc/organic-search',
      //   hidden: !isLinkgraph,
      //   isAlpha: true,
      //   color: '#ffffff',
      //   icon: <Icon onClick={() => {}} icon={faImageLandscape} color='#A3A4A4'/>,
      // },
      // {
      //   title: 'PPC Landscapes',
      //   key: '/gsc/ppc-search',
      //   path: '/gsc/ppc-search',
      //   hidden: !isLinkgraph,
      //   isAlpha: true,
      //   color: '#ffffff',
      //   icon: <Icon onClick={() => {}} icon={faImageLandscape} color='#A3A4A4'/>,
      // },
      // {
      //   title: 'Anchor Text',
      //   key: `/${routes.anchorText}`,
      //   path: `/${routes.anchorText}`,
      //   hidden: !isLinkgraph,
      //   isAlpha: true,
      //   color: '#ffffff',
      //   icon: <Icon onClick={() => {}} icon={faFile} color='#A3A4A4'/>,
      // },
      // {
      //   title: 'Toxic Backlinks',
      //   key: `/${routes.toxicBacklink}`,
      //   path: `/${routes.toxicBacklink}`,
      //   hidden: !isLinkgraph,
      //   isAlpha: true,
      //   color: '#ffffff',
      //   icon: <Icon onClick={() => {}} icon={faLeaf} color='#A3A4A4'/>,
      // },
      // {
      //   title: 'HARO',
      //   key: `/${routes.haro}`,
      //   path: `/${routes.haro}`,
      //   color: '#019197',
      //   isBeta: true,
      //   icon: <Icon onClick={() => {}} icon={faChartSimpleDuotone} color='#A3A4A4' />,
      // items: [
      //   {
      //     title: 'Submited Pitches',
      //     key: `/reporter/submited-pitches`,
      //     path: `/reporter/submited-pitches`,
      //     color: '#019197',
      //     icon: <Icon onClick={() => {}} icon={faMagnifyingGlassRegular} color='#A3A4A4'/>,
      //   },
      // ],
      // },
    ];
  };

  const [menuItems, setMenuItems] = useState<any>(getMenuItems(subscription));
  const [isListEmpty, setIsListEmpty] = useState(true);

  useEffect(()=> {
    setMenuItems(getMenuItems(subscription));
  }, [subscription, router?.pathname, fromRouterResearcherId, whitelabelOtto, customerId, WLchildrenPages, getOttoV2ProjectsList, getOttoV2Project]);

  useEffect(() => {
    const loadInboxData = async () => {
      if (router && (router.pathname.includes('digital-pr') || router.pathname.includes('haro') || router.pathname.includes('outreach'))) {
        await getUnreadEmailCount();
        const updatedMenuItems = getMenuItems(subscription)?.map(i => {
          if (i?.key === 'digital-pr') {
            return {
              ...i,
              items: i?.items?.map(value => {
                if (value?.key === '/outreach/inbox') {
                  const unreadCount = emailUnreadCount;
                  return {...value, unreadEmails: unreadCount};
                }
                return value;
              }),
            };
          }
          return i;
        });
        setMenuItems(updatedMenuItems);
      }
    };

    loadInboxData();
  }, [router?.pathname, emailUnreadCount]);

  useEffect(() => {
    if (router?.pathname.includes('/report-builder')) {
      if ((!getReportsProjectsList?.length) == isListEmpty) {
        setMenuItems(getMenuItems(subscription));
        setIsListEmpty(getReportsProjectsList?.length == 0);
      }
    }
  }, [router?.pathname, getReportsProjectsList]);

  useEffect(() => {
    if (router?.pathname.includes('/site-audit') || router?.pathname.includes('/gsc')) {
      setMenuItems(getMenuItems(subscription));
    }
  }, [router?.pathname, getAuditSitesList, navBar?.hasSiteProperties]);

  useEffect(() => {
    if (router?.pathname.includes('/gbp-galactic')) {
      setMenuItems(getMenuItems(subscription));
    }
  }, [router?.pathname, businessesList]);
  return <Menu groups={menuItems as ItemsGroup[]} reportBuilderId={reportBuilderId} isSmallScreen={isSmallScreen} handleSideBarItems={handleSideBarItems}/>;
});

const Icon = styled(FontAwesomeIcon)`
  font-size: 16px !important;
`;
