import {Button, Pagination} from '@/components/common-components/components';
import {Table} from '@/components/common-components/components/table';
import {FiltersV2} from '@/components/common-components/v2/filtersV2';
import React, {useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faListCheck, faEnvelope, faFileArrowUp} from '@fortawesome/pro-solid-svg-icons';
import {useStore} from '@/store/root-store';
import styles from '../style.module.scss';
import {faCircleArrowRight} from '@fortawesome/pro-duotone-svg-icons';
import {useRouter} from 'next/router';
import {Checkbox} from '@/components/common-components/v2';
import {FlexWrapper, HeaderCheckboxContainer, HeadingWrapper} from './StyledComponent';
import {StatusSelectorOpportunity} from '../status-selector-opportunity-v2';
import {Tooltip, Button as AntdButton} from 'antd';
import {ListContactsModal} from '../listContactsModal';
import {ModalStyled} from '../styledComponents';
import {EmailSendModal} from '../listContactsModal/viewEmail';
import {EditorState} from 'draft-js';
import {EmailConversationModal} from '../emailConversationModal';
import {SortOrder} from 'antd/lib/table/interface';
import {getFilterValues, getKey, getValue} from '@/components/dashboard/pages/site-explorer-page/detail-page/components/functions';
import {copyAndModifyQueryParams} from '@/utils/url';
import {DETAIL_FILTER} from '@/store/campaign/data';
import {notification} from '@/utils/notifications';
import {QuotaTracker} from '@/components/common-components/v2/quotaTracker';
import {toJS} from 'mobx';
import FreezeWrapper from '../../otto-page-detail/Component/freezTooltip';
import {formatLargeNumber} from '@/utils/number';

interface CampaignsDetailProps {
  isInOTTO?: boolean;
  campaignsId?: string;
  setCampaignsId?: (value: string) => void;
}

export const CampaignDetailTable: React.FC<CampaignsDetailProps> = observer(({isInOTTO, campaignsId}) => {
  const [checked, stIsChecked] = useState(null);
  const [activeSort, setActiveSort] = useState('all');
  const [result, setResult] = useState([]);
  const router = useRouter();
  const [rowSelect, setRowSelect] = useState(null);
  const [selectedOpportunity, setSelectedOpportunity] = useState([]);
  const [selectedOpportunityForEmail, setSelectedOpportunityForEmail] = useState([]);
  const hiddenFileInput = useRef(null);
  const [data, setDataMail] = useState({linked_email_id: null, subject: '', body: ''});

  const {campaigns: {
    getDetailFilter,
    getCampaignsOpportunity,
    getOpportunityCompaign,
    setOpPagination,
    opportunityCount,
    campaignsOpLoader,
    setCurrentEditorState,
    setVisible,
    setOpOrdering,
    allOpportunityParams,
    updateOpportunityFilter,
    addToOutreachList,
    addOpportunitiesFromCsv,
    setCampaignValue,
    loadingImportFromCsv,
    campaignRepollingLoading,
    chartData,
  }, settings: {customer: {profile: {quotaUtilization}}}, ottoV2Store: {getOttoV2Project}} = useStore('');
  const {inbox: {setOpportunityId}} = useStore('');
  const id = isInOTTO ? campaignsId : router?.query?.id ? router?.query?.id[0] : '0';
  const [visibleBulk, setVisibleBulk] = useState(false);
  const [editorStateV2, setEditorStateV2] = useState('');
  const [domains, setDomains] = useState<any>('');

  useEffect(()=>{
    const campaignId = isInOTTO ? campaignsId : router?.query?.id;
    async function loadOpportunity() {
      await getCampaignsOpportunity(campaignId);
    }
    (isInOTTO && campaignsId || router.query.id) && loadOpportunity();
  }, [router.query.id, campaignsId]);

  const addOpportunities = async () => {
    const campaignId = isInOTTO ? campaignsId : router?.query?.id;
    const keywords = domains.split('\n')
      .map(keyword => keyword.trim())
      .filter(Boolean);

    const opportunities = keywords.join(', ');
    await addOpportunitiesFromCsv(campaignId, {domains: opportunities?.split(',')});
  };


  useEffect(() => {
    setDataMail(prevData => ({...prevData, subject: toJS(chartData)?.template?.subject, body: toJS(chartData)?.template?.body}));
    setEditorStateV2(toJS(chartData)?.template?.body || '');
  }, [visibleBulk, chartData]);

  useEffect(() => {
    if (domains?.length) {
      addOpportunities();
    }
  }, [domains]);

  useEffect(() => {
    if (router?.query) {
      const newArr = [...DETAIL_FILTER];
      for (const [key, value] of Object.entries(router.query)) {
        const idx = newArr?.findIndex(item => [item?.name, item?.filterField].includes(key));
        if (newArr[idx] && value) {
          newArr[idx] = getFilterValues(newArr[idx], value) as any;
        }
      }
      updateOpportunityFilter(newArr, id, activeSort);
    }
  }, [router?.query]);

  useEffect(()=>{
    setResult(getOpportunityCompaign);
  }, [getOpportunityCompaign]);

  useEffect(()=>{
    return () => {
      setCampaignValue(null);
    };
  }, []);

  useEffect(() => {
    if (result?.length && (selectedOpportunityForEmail.length === getOpportunityCompaign?.length || selectedOpportunity.length === getOpportunityCompaign?.length)
    ) {
      stIsChecked(true);
    } else {
      stIsChecked(false);
    }
  }, [selectedOpportunity, selectedOpportunityForEmail]);

  const status = {
    discovered: 'DISCOVERED',
    outreach: 'OUTREACH',
  };

  const setAllOpportunities = event => {
    stIsChecked(event?.target?.checked);

    const opportunityArr = result?.map(item => item.id);
    const updatedArr = opportunityArr.filter(element => !selectedOpportunity.includes(element));

    const addToOutreachArr = result?.filter(value => !value.isScheduled)?.map(item => item.id);
    const updatedaddToOutreachArr = addToOutreachArr.filter(
      element => !selectedOpportunity.includes(element),
    );

    if (event.target.checked) {
      setSelectedOpportunity([...updatedaddToOutreachArr]);
      setSelectedOpportunityForEmail([...updatedArr]);
    } else {
      setSelectedOpportunity([]);
      setSelectedOpportunityForEmail([]);
    }
  };


  const setKeywordsList = (event, id) => {
    if (event.target.checked) {
      const opportunityArr = result?.filter(item => !item.isScheduled)?.map(item => item.id);
      const updatedArr = opportunityArr.filter(item => item == id);

      setSelectedOpportunity(selectedOpportunity => [...selectedOpportunity, ...updatedArr]);
      setSelectedOpportunityForEmail(selectedOpportunityForEmail => [...selectedOpportunityForEmail, id]);
    } else {
      setSelectedOpportunity(selectedOpportunity.filter(item=> item !== id ));
      setSelectedOpportunityForEmail(selectedOpportunityForEmail.filter(item=> item !== id ));
    }
  };

  const campaignColumns = [
    {
      title: <FreezeWrapper removeTooltip={chartData?.isActive || !isInOTTO}><Checkbox customBackground='#2D6CCA' onChange={e => setAllOpportunities(e)} checked={checked} style={{lineHeight: 'normal', marginRight: '12px'}} /></FreezeWrapper>,
      key: 'title',
      align: 'left' as 'left',
      fixed: 'left' as 'left',
      width: 40,
      render: (_, record) => {
        if (record.title) {
          return (
            <FreezeWrapper removeTooltip={chartData?.isActive || !isInOTTO}>
              <Checkbox customBackground='#2D6CCA' checked={selectedOpportunity?.find(item => item == record.id) || selectedOpportunityForEmail?.find(item => item == record.id)} onChange={e=> setKeywordsList(e, record.id)} style={{marginLeft: '26px'}}/>
            </FreezeWrapper>
          );
        }
      },
    },
    {
      title: <HeaderCheckboxContainer><HeadingWrapper style={{marginLeft: '18px'}}>OPPORTUNITY</HeadingWrapper></HeaderCheckboxContainer>,
      dataIndex: 'title',
      key: 'title',
      sortFieldName: 'domain',
      sortOrder: (activeSort?.includes('domain') ? activeSort?.startsWith('-') ? 'descend' : 'ascend' : false) as SortOrder,
      align: 'left' as 'left',
      fixed: 'left' as 'left',
      sorter: true,
      width: 280,
      render: (_, record) => {
        if (record.title) {
          return (
            <FlexWrapper>
              <FirstColumnWrapper>
                <a onClick={()=> {
                  if (getOttoV2Project?.isFrozen) return;
                  setRowSelect(record);
                  setVisible(true);
                  setOpportunityId(record?.id);
                }}>
                  <CampaignName>
                    <span>{record.title}</span> <FontAwesomeIcon icon={faCircleArrowRight} color='#2D6CCA'/>
                  </CampaignName>
                </a>
                <DateWrapper>{record.url}</DateWrapper>
              </FirstColumnWrapper>
            </FlexWrapper>
          );
        }
      },
    },
    {
      title: <div>EMAILED<br/>CONTACTS</div>,
      dataIndex: 'contacts',
      key: 'contacts',
      align: 'center' as 'center',
      sortFieldName: 'contacts_total',
      sortOrder: (activeSort?.includes('contacts_total') ? activeSort?.startsWith('-') ? 'descend' : 'ascend' : false) as SortOrder,
      sorter: true,
      width: 100,
      skeletonAlign: 'flex-end',
      render: (_, record) => {
        return (
          <div style={{display: 'flex', justifyContent: 'center', color: '#2D6CCA', paddingRight: '10px'}}>
            <div>{record.contacted ?? 0}</div>&nbsp;
            <div>of</div>&nbsp;
            <div>{record.contacts ?? 0}</div>
          </div>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      align: 'left' as 'left',
      sorter: false,
      width: 130,
      skeletonAlign: 'flex-end',
      render: (_, record) => {
        return (
          <StatusSelectorOpportunity isActive={chartData?.isActive} isInOTTO={isInOTTO} campaignId={Number(isInOTTO ? campaignsId : router?.query?.id[0])} opportunityId={record?.id} articleStatus={record.isScheduled ? status['outreach'] : status['discovered']} isScheduled={activeSort} />
        );
      },
    },
    {
      title: <div>DR</div>,
      dataIndex: 'dr',
      key: 'dr',
      align: 'center' as 'center',
      sortFieldName: 'ahrefs_dr',
      sortOrder: (activeSort?.includes('ahrefs_dr') ? activeSort?.startsWith('-') ? 'descend' : 'ascend' : false) as SortOrder,
      sorter: true,
      width: 70,
      skeletonAlign: 'flex-end',
      render: (_, record) => {
        return (
          <Tooltip title={record?.ahrefsDr}>
            {record?.ahrefsDr ?? '-'}
          </Tooltip>
        );
      },
    },
    {
      title: 'KEYWORDS',
      dataIndex: 'keywords',
      key: 'keywords',
      align: 'center' as 'center',
      sortFieldName: 'ahrefs_ok',
      sortOrder: (activeSort?.includes('ahrefs_ok') ? activeSort?.startsWith('-') ? 'descend' : 'ascend' : false) as SortOrder,
      sorter: true,
      width: 100,
      skeletonAlign: 'flex-end',
      render: (_, record) => {
        return (
          <Tooltip title={record?.ahrefsOk} placement='top'>
            <div>
              {record?.ahrefsOk != null ?
                String(formatLargeNumber(record.ahrefsOk, 1)).replace('.0', '') :
                ''}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: 'TRAFFIC',
      dataIndex: 'traffic',
      key: 'traffic',
      align: 'center' as 'center',
      sortFieldName: 'ahrefs_ot',
      sortOrder: (activeSort?.includes('ahrefs_ot') ? activeSort?.startsWith('-') ? 'descend' : 'ascend' : false) as SortOrder,
      sorter: true,
      width: 90,
      skeletonAlign: 'flex-end',
      render: (_, record) => {
        return (
          <Tooltip title={record?.ahrefsOt} placement='top'>
            <div className={styles.projectSelectorContainer}>
              {record?.ahrefsOt != null ? (formatLargeNumber(record.ahrefsOt, 1) as string).replace('.0', '') : ''}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: 'REF DOMAIN',
      dataIndex: 'ahrefsRefdomains',
      key: 'ahrefsRefdomains',
      align: 'center' as 'center',
      sortFieldName: 'ahrefs_refdomains',
      sortOrder: (activeSort?.includes('ahrefs_refdomains') ? activeSort?.startsWith('-') ? 'descend' : 'ascend' : false) as SortOrder,
      sorter: true,
      width: 86,
      skeletonAlign: 'flex-end',
      render: (_, record) => {
        return (
          <Tooltip title={record?.ahrefsRefdomains} placement='top'>
            <div className={styles.projectSelectorContainer}>
              {record.ahrefsRefdomains != null ? (formatLargeNumber(record.ahrefsRefdomains, 1) as string).replace('.0', '') : ''}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: '',
      dataIndex: '',
      align: 'left' as 'left',
      sorter: false,
      width: 80,
      skeletonAlign: 'flex-end',
      render: (_, record) => {
        return (
          <ActionWrapper>
            <FreezeWrapper placement='topRight' removeTooltip={chartData?.isActive || !isInOTTO}>
              <span>
                <ViewButton onClick={()=> {
                  setRowSelect(record);
                  setVisible(true);
                  setOpportunityId(record?.id);
                }} buttonType={'transparent'} size='sm'>View</ViewButton>
              </span>
            </FreezeWrapper>
          </ActionWrapper>
        );
      },
    },
  ];

  const handleSort = value => {
    setActiveSort(value);
    updateOpportunityFilter(null, id, value);
  };

  const sortList = [
    {name: 'All', active: activeSort.includes('all'), value: 'all'},
    {name: 'Discovered', active: activeSort.includes('discovered'), value: 'discovered'},
    {name: 'Ready for outreach', active: activeSort.includes('outreach'), value: 'outreach'},
  ];

  const handleFilterChange = filters => {
    const idx = filters?.findIndex(item=> item?.filterField == 'targetPage');
    filters[idx] = {
      ...filters[idx],
      type: 'targetPage',
    } as any;
    const filterParams = filters?.map(z => {
      return {
        key: getKey(z),
        value: getValue(z),
      };
    });
    router.push(`${copyAndModifyQueryParams(filterParams)}`, undefined, {shallow: true});
  };

  const handleTableChange = async (pagination, filters, sorter) => {
    const {column, order} = sorter;
    const {sortFieldName} = column || {};
    const sorterKey = order ? `${order == 'descend' ? '-' : ''}${sortFieldName}` : '';
    setActiveSort(sorterKey);
    setOpOrdering({...allOpportunityParams, ordering: sorterKey}, id);
  };

  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  const handleChange = event => {
    try {
      const file = event?.target?.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = data => {
          const contents = data?.target?.result;
          const domains = contents;
          setDomains(domains);
        };
        reader.readAsText(file);
      } else {
        notification.error('Failed to read file data');
      }
    } catch (error) {
      notification.error('Failed to read file data');
    }
  };

  return (
    <div style={{border: isInOTTO ? '1px solid #E8E8E8': '', borderRadius: isInOTTO ? '12px': ''}}>
      <TableHeaderSeparated>
        <input type='file'
          ref={hiddenFileInput}
          accept='.csv,.txt'
          onClick={ (event:any) => {
            event.target.value = null;
          }}
          onChange={e=>handleChange(e)}
          style={{display: 'none'}}
        />
        <FiltersV2
          onChange={handleFilterChange}
          sortList={sortList}
          onSortChange={handleSort}
          activeSort={activeSort}
          isSiteExplorer={true}
          inMoreBtnIds={[5, 6]}
          simpleMoreBtn={true}
          mainFilters={getDetailFilter}
          isShowSorters={true}
        />
        <div>
          <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '10px'}}>
            {
              isInOTTO ?
                <QuotaTracker consumedData={quotaUtilization?.ke?.allowedOutreachSentEmails?.consumed}
                  totalData= {quotaUtilization?.ke?.allowedOutreachSentEmails?.total} heading={'Email Sent'}
                  fontSize={12} /> :
                <ImportFromCsv onClick={handleClick} loading={loadingImportFromCsv}>
                  <FontAwesomeIcon icon={faFileArrowUp} /> Import Opportunities from CSV
                </ImportFromCsv>
            }
          </div>
          <MoreButtonWrapper>
            <Button style={{color: selectedOpportunity?.length ? 'black' : 'lightgray'}} disabled={!selectedOpportunity?.length} buttonType='transparent' onClick={()=> {
              addToOutreachList({opportunities: selectedOpportunity}, isInOTTO ? campaignsId : router.query.id[0], activeSort);
              setSelectedOpportunity([]);
              setSelectedOpportunityForEmail([]);
            }}>
              <FontAwesomeIcon icon={faListCheck}/> Add to Outreach list
            </Button>
            <Button style={{color: selectedOpportunityForEmail?.length ? 'black' : 'lightgray'}} disabled={!selectedOpportunityForEmail?.length} buttonType='transparent' onClick={()=> setVisibleBulk(true)}>
              <FontAwesomeIcon icon={faEnvelope}/> Contact in bulk
            </Button>
          </MoreButtonWrapper>
        </div>
      </TableHeaderSeparated>
      <TableWrapper>
        <Table
          className={styles.tableStyles}
          pagination={false}
          columns={campaignColumns}
          scroll={{x: 600, y: 600}}
          dataSource={result}
          loading={campaignsOpLoader || campaignRepollingLoading==(+id)}
          loadingRows={10}
          onChange={handleTableChange}
        />
        <PaginationWrapper>
          <Pagination
            onChange={(page, pageSize) => {
              setOpPagination(page, pageSize, id, activeSort);
              setSelectedOpportunity([]);
              setSelectedOpportunityForEmail([]);
            }}
            total={opportunityCount}
            pageSize={allOpportunityParams?.page_size}
            style={{zIndex: 10}}
            current={allOpportunityParams?.page}
            showSizeChanger
            showTotal= {(total, [from, to])=> {
              return (
                <PaginationSpan>
                  {from}-{to} {'of'} {total} {'results shown'}
                </PaginationSpan>
              );
            }}
            defaultPageSize={1}
            pageSizeOptions={['10', '20', '50', '100']}
            showQuickJumper={false}
            disabled={false}
          />
        </PaginationWrapper>
        <ListContactsModal rowSelect={rowSelect} setRowSelect={setRowSelect} result={result} campaignsId={campaignsId} isScheduled={activeSort} />
        <Modal
          width={735}
          closable={true}
          mask={true}
          visible={visibleBulk}
          onClose={() => {
            setVisibleBulk(false);
            setCurrentEditorState(EditorState.createEmpty());
            setEditorStateV2('');
          }}
          zIndex={20}
          destroyOnClose
        >
          <EmailSendModal
            rowSelect={rowSelect}
            visibleBulk={visibleBulk}
            setVisible={setVisibleBulk}
            selectedOpportunities={selectedOpportunityForEmail}
            editorStateV2={editorStateV2}
            setEditorStateV2={setEditorStateV2}
            setSelectedOpportunity={setSelectedOpportunity}
            setSelectedOpportunityForEmail={setSelectedOpportunityForEmail}
            data={data}
            setDataMail={setDataMail}
            campaignsId={campaignsId}
            isScheduled={activeSort}
          />
        </Modal>
      </TableWrapper>
      <EmailConversationModal />
    </div>
  );
});

const Modal = styled(ModalStyled)`
margin-top: 100px;
  .rc-dialog-content {
    background-color: #f9f9fb !important;
  }  
  .ant-form{
    width: 100% !important;
  }
  .rc-dialog-close{
    margin-right: -60px;
    position: absolute;
    color: white;
    margin-top: -34px;
    opacity: 1;
    font-size: 18px;
  }
  .ant-table-thead th {
    background-color: #f7f7f7 !important;
  }
  .rc-dialog-body{
    padding-bottom: 15px;
  }
`;

const PaginationWrapper = styled.div`
padding: 20px 25px;
display: flex;
justify-content: flex-end;
`;
const TableHeaderSeparated = styled.div`
align-items: center;
background: #fff;
display: flex;
line-height: 22px;
padding-left: 12px;
padding-right: 12px;
position: relative;
border-radius: 12px 12px 0px 0px;
`;
const TableWrapper = styled.div`
  background: #fff;
  border-radius: 0 0 12px 12px;
  padding: 10px 10px 0 10px;
  margin-bottom: 20px;

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
    background-color: transparent !important;
  }
  table {
    .ant-table-column-sorter {
      display: flex;
      padding-left: 3px !important;
    }

    .ant-table-column-sorters {
      display: inline-flex !important;
      padding: 5px !important;
    }

    .ant-table-tbody {
      tr:nth-child(even) {
        td {
          background: #fff !important;
          border-bottom: 1px solid #f4f4f4 !important;
          box-shadow: none;
          font-weight: 400;
          white-space: nowrap;
        }

        &:hover {
          td {
            background: #eef4fe !important;
          }
        }
      }

      tr:nth-child(odd) {
        td {
          background: #fff !important;
          border-bottom: 1px solid #f4f4f4 !important;
          box-shadow: none;
          font-weight: 400;
          white-space: nowrap;
        }

        &:first-child {
          td {
            border-bottom: 0 solid transparent !important;
          }
        }

        &:hover {
          td {
            background: #eef4fe !important;
          }
        }
      }
      tr td {
        padding: 5px 10px;
      }

    }

    .ant-table-thead {

      .ant-table-column-has-sorters {
        text-align: center !important;
  
        .ant-table-column-title {
          text-align: center;
          z-index: 0 !important;
        }
      }

      th {
        background: #f7f7f7 !important;
        color: #121212 !important;
        font-size: 12px;
        font-weight: 600;
        line-height: 14px;
        padding: 0 !important;
      }

      .ant-table-cell-fix-left {
        text-align: left !important;
      }
    }

    tr th {
      height: 60px !important;
      line-height: 22px;
      white-space: nowrap;
    }

    tr th:first-child,
    tr td:first-child {
      padding-left: 25px !important;
      padding-right: 25px !important;
    }

    tr th:last-child,
    tr td:last-child {
      padding-left: 25px !important;
      padding-right: 25px !important;
      text-align: center;
    }
  }
`;

const PaginationSpan = styled.span`
color: #a3a4a4;
padding: 40px 40px 0;
width: 100%;
`;

const MoreButtonWrapper = styled.div`
padding-bottom: 2px;
margin-top: 9px;
display: flex;
button{
  border: 1px solid lightgray;
  font-size: 14px;
  width: max-content;
  color: black;
}
button:last-child{
  margin-left: 10px;
}
`;

const CampaignName = styled.div``;
const DateWrapper = styled.div``;
const FirstColumnWrapper = styled.div`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: normal;
  font-style: normal;
  margin-left: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${CampaignName} {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  span{
  color: #2d6cca;
  cursor: pointer;
  }
  svg {
    cursor: pointer;
  }
}

${DateWrapper} {
  font-size: 12px;
  color: #4e5156;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
`;

const ViewButton = styled(Button)``;
const ActionWrapper = styled.div`
display: flex;
align-items: center;
gap: 10px;
${ViewButton} {
  width: 60px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9f9fB;
  border-radius: 4px;
  border: 1px solid #e8e8e8;
  color: #121212;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
}
svg {
width: 6px;
height: 30px;
color: #4E5156;
cursor: pointer;
}
`;

const ImportFromCsv = styled(AntdButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  min-width: 241.5px;
  height: 28px;
  border-radius: 6px;
  background: #FFFFFF;
  border: 1px solid #CDCDCD;
  &:hover, &:focus {
    background: #FFFFFF;
    border: 1px solid #CDCDCD;
    color: #121212;
  }
`;
