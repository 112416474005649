import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import styled from 'styled-components';
import ReactEcharts from 'echarts-for-react';
import {formatNumber} from '@/utils/number';
import {useRouter} from 'next/router';
import {headerStore} from '@/store/storeCompTest';
import {SearchBarV2} from '../components/searchBarV2';
import {QuotaTooltip} from '../v2/quotaTooltip';
import {KeywordsQuotaV2} from '@/components/dashboard/pages/keyword-rank-tracker/quotaComponents';
import {AiQuotaInfoDetail} from '../v2/aiQuotaInfo';
import {toJS} from 'mobx';
import {useStore} from '@/store/root-store';

const HeaderMenu = observer(({isSearch, quotaList}: any) => {
  const router = useRouter();
  const {settings: {customer: {isGHLAccount}}} = useStore('');
  useEffect(()=> {
    if (router.pathname == '/home') {
      headerStore.setComponent(null, false, [{name: 'AI Generation', spend: 0, total: 0, color: 'green', quotaProps: null}]);
    }
  }, [router]);
  const items = quotaList?.map((item, index)=> {
    const chartOption = {
      series: [
        {
          type: 'gauge',
          startAngle: 90,
          endAngle: -270,
          itemStyle: {
            color: item.color,
          },
          progress: {
            show: true,
            width: 3,
          },
          axisLine: {
            lineStyle: {
              width: 3,
              color: [[item.spend ? (item.spend / item.total) : 0, item.color], [1, '#4E5156']],
            },
          },
          pointer: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            distance: -13,
            show: false,
            length: 10,
            lineStyle: {
              color: 'black',
              width: 0,
            },
          },
          splitNumber: 0,
          axisLabel: {
            show: false,
            color: 'black',
            distance: 40,
            fontSize: 20,
          },
          detail: {
            show: true,
            offsetCenter: [0, '10%'],
            formatter: value => (value || value == 0 ? `${value}` : ''),
            color: 'transparent',
            fontSize: 18,
          },
          data: [
            {
              value: (item.spend/item.total)*100,
            },
          ],
        },
      ],
    };
    return <p key={index} className='quota'><span style={{color: '#A3A4A4', marginTop: '-4px'}}>{item.name}:</span> {item.quotaProps ? item.quotaProps?.tool == 'CA' ? <AiQuotaInfoDetail {...item.quotaProps} component={<div><ReactEcharts style={{width: 25, height: 25}}
      option={chartOption} /> </div>} isHeader={true}/> : item.quotaProps?.tool == 'KRT' ? <KeywordsQuotaV2 {...item.quotaProps} component={<div><ReactEcharts style={{width: 25, height: 25}} option={chartOption} /> </div>} isHeader={true}/> :
      <QuotaTooltip {...toJS(item.quotaProps)} component={<div><ReactEcharts style={{width: 25, height: 25}} option={chartOption} /> </div>} /> : <ReactEcharts style={{width: 25, height: 25}} option={chartOption} />}
    {item.spend ? `${formatNumber(item.spend, 1)}`.replace('.0', '') : 0} of {`${formatNumber(item.total, 1)}`.replace('.0', '')}</p>;
  });

  // return
  return (
    <Wrapper>
      {isSearch && router.pathname.includes('site-explorer') && <SearchBarV2 quota={false} isSE={true} isHeader={true} />}
      {!isGHLAccount && items}
    </Wrapper>
  );
});

export default HeaderMenu;

const Wrapper = styled.div`
    display: flex;
    width: -webkit-fill-available;
    gap: 25px;
    margin-top: 10px;
    .quota{
    display: flex;
    align-items: center;
    gap: 4px;
    color: white;
    margin: 0;
    }
.searchWrapper{
display: flex;
height: 34px;
align-items: center;
    background: #282B2F !important;
    border-radius: 9px;
.fa-magnifying-glass{
color: white;
}
}
.ant-input{
width: 158px;
font-size: 14px;
padding-right: 0;
color: white !important;
height: 32px;
background: #282B2F !important;
    border: none;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
}
.search{
    padding: 9px 10px;
    background: #3B3E43;
    border-top-right-radius: 9px;
    border-bottom-right-radius: 9px;
}
    .searchicon{
    padding: 6px 10px;
    background: #3B3E43;
    border-top-right-radius: 9px;
    border-bottom-right-radius: 9px;
    }
    button{
    width: 0px !important;
    padding: 0px !important;
    border: none !important;
    }
`;

