import styles from '@/components/layoutV2/header/style.module.scss';
import {
  DatePicker,
} from 'antd';
import {ChevronDown, ChevronUp} from 'react-feather';
import moment from 'moment';
import {observer} from 'mobx-react-lite';
import {CaretDownOutlined} from '@ant-design/icons';
import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'next-i18next';
import {useStore} from '@/store/root-store';
import styled from 'styled-components';
import {getDomain, isPublicAuditOverview} from '@/utils/url';
import {linkgraphDomains} from '@/utils/router';
import {NextImg} from '@/utils/nextImg';


const {RangePicker} = DatePicker;
interface Props {
  initialDateRange?: string;
  isDockedSider?: boolean;
  setDropDownSa?: (value)=>void;
  isDropDownSa?: boolean;
}

export const RenderDateRangePickerSA: React.FC<Props> = observer(({initialDateRange='1 month', isDockedSider, setDropDownSa}) => {
  const {
    siteAuditorV2: {setAuditDateRange, auditPeriodStart, auditPeriodEnd},
    settings: {customer: {profile}}} = useStore('');

  const {t} = useTranslation('common');
  const [defaultDate, setDefaultDate] = useState([
    moment().subtract(1, 'months'),
    moment(),
  ]);
  const [dateRange, setDateRange] = useState('');
  const currentDomain = getDomain(window.location.hostname);
  const isWhiteLabelDomain = !linkgraphDomains.includes(currentDomain);
  useEffect(() => {
    setDateRange(initialDateRange);
    if (initialDateRange?.includes('month') || initialDateRange?.includes('year')) {
      setShowDateRange('Last ' + initialDateRange);
    }
  }, [initialDateRange]);

  useEffect(() => {
    if (auditPeriodStart) {
      setDefaultDate([
        moment(auditPeriodStart),
        moment(auditPeriodEnd),
      ]);
    }
  }, [auditPeriodStart, auditPeriodEnd]);

  const [isPickerOpen, setPickerOpen] = useState(false);
  const [showDateRange, setShowDateRange] = useState('');

  const {plan} = profile;
  const ActionsRangePicker = useRef(null);
  const [dateRangeVisible, setDateRangeVisible] = useState(false);

  useEffect(() => {
    window.addEventListener('click', clickHandler);
    return () => {
      window.removeEventListener('click', clickHandler);
    };
  }, []);
  const dateFormat = 'DD MMM YYYY';

  const customFormat = value => {
    return `${value.format(dateFormat)}`;
  };

  const clickHandler = function(e) {
    const path = e?.path || (e?.composedPath && e?.composedPath());
    const isInPath = path?.includes(ActionsRangePicker.current);
    if (!isInPath) {
      setDateRangeVisible(false);
    }
  };


  const dateRangePickerClickHandler = event => {
    event.stopPropagation();
    if (plan) {
      setDropDownSa(false);
      setDateRangeVisible(true);
    }
  };

  return (
    <div className={styles.ranger} style={{border: `${dateRangeVisible ? '1px solid #4e5156': ''}`, background: ` 'rgba(84, 86, 90, 0.17)': ''}`, borderRadius: `${dateRangeVisible ? '5px': ''}`}}>
      <div className={`${styles.rangerButton} ${isPickerOpen && plan ? styles.rangerButtonOpen : ''}`}
        ref={ActionsRangePicker}
      >
        <div onClick={e => dateRangePickerClickHandler(e)} className={`${!isDockedSider ? styles.close : styles.open}`} style={{display: 'flex', alignItems: 'center'}}><NextImg src='/icons/calendarline.svg' width={20} height={30} />
          {isDockedSider || dateRangeVisible ?
            <div style={{margin: '3px 0 0 13px'}}>

              <RangeHeading className={!isDockedSider ? styles.close : styles.open}>
                {t('date-range')}
              </RangeHeading>
              <DateRangeInText onClick={e => dateRangePickerClickHandler(e)} >{showDateRange}</DateRangeInText>

              <RangePicker
                picker={'date'}
                open={dateRangeVisible}
                className={`${styles.pickerHeader} ${!isDockedSider ? styles.closed : styles.opened}`}
                style={{visibility: showDateRange ? 'hidden' : 'visible'}}
                dropdownClassName={`${!isDockedSider ? styles.closed : styles.opened} ${dateRange === '1 month' ?
                  styles.customPickerOneMonth :
                  dateRange === '3 months' ? styles.customPickerThreeMonths :
                    dateRange === '6 months' ? styles.customPickerSixMonths :
                      dateRange === '1 year' ? styles.customPickerOneYear :
                        styles.customPicker}`}
                // format={'DD MMM YYYY'  }
                format={customFormat}
                suffixIcon={isPickerOpen ? <ChevronUp/>:<ChevronDown/>}
                allowClear={false}
                ranges={{
                  'Last 1 month': [
                    moment().subtract(1, 'months'),
                    moment(),
                  ],
                  'Last 3 months': [
                    moment().subtract(3, 'months'),
                    moment(),
                  ],
                  'Last 6 months': [
                    moment().subtract(6, 'months'),
                    moment(),
                  ],
                  'Last 1 year': [
                    moment().subtract(1, 'years'),
                    moment(),
                  ],
                }}
                value={[
                  defaultDate[0],
                  defaultDate[1],
                ]}
                separator={'-'}
                allowEmpty={[false, false]}
                onCalendarChange={(dates: Array<moment.Moment>, [currentPeriodStart, currentPeriodEnd]: [string, string]) => {
                  if (currentPeriodStart && currentPeriodEnd) {
                    const dateRange = dates && dates[1].diff(dates[0], 'days');
                    let periodStatus = '';
                    if ([28, 29, 30, 31].includes(dateRange)) {
                      setDateRange('1 month');
                      periodStatus = '1 month';
                      setShowDateRange('Last 1 Month');
                    }
                    if ([87, 88, 89, 90, 91, 92].includes(dateRange)) {
                      setDateRange('3 months');
                      periodStatus = '3 months';
                      setShowDateRange('Last 3 Month');
                    }
                    if ([180, 181, 182, 183, 184].includes(dateRange)) {
                      setDateRange('6 months');
                      periodStatus = '6 months';
                      setShowDateRange('Last 6 Month');
                    }
                    if ([364, 365, 366, 367].includes(dateRange)) {
                      setDateRange('1 year');
                      periodStatus = '1 year';
                      setShowDateRange('Last 1 Year');
                    }
                    if (moment(currentPeriodEnd).diff(moment(currentPeriodStart), 'days') !== 0) {
                      setAuditDateRange(moment(currentPeriodStart).format('YYYY-MM-DD'), moment(currentPeriodEnd).format('YYYY-MM-DD'));
                    }
                    if (!periodStatus) {
                      setShowDateRange('');
                    }
                  }
                }}
                disabledDate={currentDate => currentDate >= moment() || currentDate <= moment().subtract(1, 'years').subtract(2, 'days')}
                onOpenChange={(open: boolean) => {
                  setPickerOpen(open);
                }}
              />

            </div> : '' }
        </div>
        {isPublicAuditOverview() && isWhiteLabelDomain ? '' : <ArrowIcon className={!isDockedSider ? styles.close : styles.open} onClick={e => dateRangePickerClickHandler(e)}>
          <CaretDownOutlined />
        </ArrowIcon>}
        {/* <span>{gsc.coreReports.loading && <LoadingOutlined className={styles.loading} hidden={!gsc.coreReports.loading}/>}</span> */}
      </div>

    </div>
  );
});
const DateRangeInText=styled.div`
  letter-spacing: -0.02em;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #FFFFFF;
  position: absolute;
`;

const RangeHeading = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #A3A4A4;
`;

const ArrowIcon = styled.span`
  position: absolute;
  top: 6px;
  left: 120px;
  color: #fff;
`;
