import moment from 'moment';

export const getFilter = (filters, searchedFilter: string ) => {
  if (filters && filters.length > 0) {
    const filter = filters.find((item: any) => item.name == searchedFilter);
    return filter;
  }
  return null;
};

export const formatSorters = (sorters: any, sortField: string, sortDirection: string) => {
  Object.keys(sorters).forEach(key => {
    sorters[key] = sortDirection !== undefined && sortDirection === 'descend' ? `-${sorters[key]}` : sorters[key];
  });
  return sortField ? sorters[sortField] : null;
};

export const filterSchemaInput = event => {
  const str = event.target.value;
  let result = '';
  str.split('').forEach((letter, idx) => {
    result += (letter == '"' && str[idx -1] !== '/') ? '/"' : letter;
  });
  return event.target.value= result;
};

export const convertDate = date => {
  return moment(date).format('MMM DD, YYYY');
};

export const getFilters = filter => {
  switch (filter) {
    case 'equals':
      return 'exact';
    case 'does_not_equal':
      return 'iexact';
    case 'contains':
      return 'contains';
    case 'does_not_contain':
      return 'icontains';
    case 'starts_with':
      return 'startswith';
    case 'ends_with':
      return 'endswith';
    default:
      return ``;
  }
};

export const getFiltersInverse = filter => {
  switch (filter) {
    case 'exact':
      return 'equals';
    case 'iexact':
      return 'equals';
    case 'contains':
      return 'contains';
    case 'icontains':
      return 'contains';
    case 'startswith':
      return 'starts_with';
    case 'endswith':
      return 'ends_with';
    case 'istartswith':
      return 'starts_with';
    case 'iendswith':
      return 'ends_with';
    default:
      return ``;
  }
};

export const getSortingKeyAndValue = value => {
  switch (value) {
    case 'desktop':
    case 'mobile':
      return {key: 'device', value};
    case 'is_local_pack':
      return {key: 'is_local_pack', value: true};
    case 'is_local_search':
      return {key: 'is_local_search', value: true};
    default:
      return {key: 'device', value};
  }
};
