import styled from 'styled-components';
import {Heading, Description, ModalStyled} from '../styledComponents';

export const HeaderWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
`;

export const HeadingTitle = styled(Heading)`
  justify-content: space-between;
  display: flex;
`;

export const ModalConfig = styled.p`
  width: fit-content;
  display: flex;
  align-items: center;
  margin: 0 18px 0 0;
  
  span {
    display: flex;
    align-items: center;
  }
`;

export const UrlDescription = styled(Description)`
  color: #2D6CCA !important;
  .fa-arrow-up-right-from-square{
    margin-left: 5px;
  }
`;

export const ImageStyled = styled.img`
  width: 40px;
  height: 40px;
  margin-top: 10px;
  border-radius: 8px;
`;

export const ThreadContainer = styled.div`
  max-height: 700px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
 
  &::-webkit-scrollbar-thumb {
    background: rgba(78, 81, 86, 0.4);
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(78, 81, 86, 0.5);
  }
`;

export const ThreadWrapper = styled.div<{padding: string}>`
  padding: ${p => p?.padding && p?.padding};
`;

export const ThreadFromWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  gap: 7px;
`;

export const BodyWrapper = styled.div<{sender?: boolean}>`
  width: 100%;
  margin-top: 13px;
  border-radius: 16px;
  ${p => p?.sender ? 'border: 1px solid #D9D9D9; background: #FFFFFF;' : 'background: rgba(52, 174, 243, 0.15);'}
  padding: 15px;
`;

export const Subject = styled.div`
  color: #121212;
  font-weight: 600;
`;

export const Body = styled.div`
  color: #121212;
  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
  }
`;

export const StyledModal = styled(ModalStyled)`
  .rc-dialog-close {
    font-size: 28px !important;
    right: 15px !important;
    left: auto !important;
    top: 15px !important;
    font-weight: 700 !important;
    color: #A3A4A4 !important;
    opacity: 1 !important;
    &:hover {
      opacity: 0.5 !important;
    }
  }
`;
