import React, {useState, Fragment} from 'react';
import {observer} from 'mobx-react';
import styles from '@/components/dashboard/settings/styles.module.scss';
import {
  Typography,
  Button,
  Modal,
} from '@/components/common-components/components/index';
import {AccountsFailedCredBanner} from '@/components/dashboard/gsc/gsc/BannerPages/AccountsFailedCredBanner';
import {AccountsHaveNoGscConnectedBanner} from '@/components/dashboard/gsc/gsc/BannerPages/AccountsHaveNoGscConnectedBanner';
import {Button as V2Button} from '@/components/common-components/v2/';
import {GoogleProfileType} from '@/store/settings/integration';
import {useStore} from '@/store/root-store';
import {useTranslation} from 'next-i18next';
import {MoreOutlined, UserOutlined} from '@ant-design/icons';
import {Dropdown, Empty, notification} from 'antd';
import {SETTINGS_GSC_AUTHORIZE_CLICKED, SETTINGS_DISCONNECT_ACCOUNT} from '@/constants/events';
import {integrationApi} from '@/api/gsc';
import {AuthorizedModal} from '@/components/common-components/components/authorized-modal';
import {MixPanel} from '@/utils/mixpanel';
import {getHubSpotLink} from '@/utils/legacy';
import styled, {keyframes} from 'styled-components';
import {GSCSettingsDetail} from './gscSettingsDetail';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faRotate} from '@fortawesome/pro-solid-svg-icons';
import {toJS} from 'mobx';
import {NextImg} from '@/utils/nextImg';

interface GSCSettingsV2Props {
  profiles: Array<GoogleProfileType>;
}

export const GSCSettingsV2: React.FC<GSCSettingsV2Props> = observer(() => {
  const [deleteEmail, setDeleteEmail] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [isGscDetail, setIsGscDetail] = useState(false);
  const [gscDetailData, setGscDetailData] = useState(null);
  const {t} = useTranslation('common');
  const {settings: {
    integration: {
      getIsAnyGscNotConnectedAccounts,
      gscIsAnyAccHaveNoSitesConnected,
      gscNoSitesConnectedAccList,
      getNotConnectedGscAccounts,
      refreshEmail,
    },
    integration,
    customer: {
      profile: {isWhitelabel},
    }},
  } = useStore('');
  const {gsc: {setDrawerVisibility}} = useStore('');

  const googleProfilesList = integration.googleProfiles;
  const [confirmModal, setConfirmModal] = useState(false);
  const [emailIndex, setEmailIndex] = useState(-1);
  const onClose = ()=>{
    setConfirmModal(false);
  };

  const deleteAccount = () => {
    setDeleteModal(false);
    notification.success({
      placement: 'bottomRight',
      bottom: 70,
      duration: 3,
      style: {
        zIndex: 2,
      },
      message: t('account-disconnected-successfully'),
    });
    setDrawerVisibility(false);
  };


  const openDeleteModal = (profile: string) => {
    setDeleteModal(true);
    setDeleteEmail(profile);
  };

  const handleAuthorizeConnection = () => {
    MixPanel.track(SETTINGS_GSC_AUTHORIZE_CLICKED);

    window.open(integrationApi.getGoogleConnectUrl());
  };

  const onRefreshHandler = async email => {
    if (email) {
      await refreshEmail(email);
      setEmailIndex(-1);
    }
  };
  const hideGscBanner = localStorage.getItem('hideGscBanner');

  return (
    <Fragment>
      <div className={styles.TabPanContainer} style={{background: 'none'}}>
        {googleProfilesList?.length < 1 ? <div style={{marginTop: '21px'}}>
          <div className={styles.gscIntegration}>
            <div><NextImg src='/img/Groupgsc.svg' /></div>
            <div style={{marginTop: '25px', marginLeft: '46px', marginRight: '46px'}}>
              <h3><span onClick={() => setConfirmModal(true)} className={styles.spanStyle} style={{cursor: 'pointer'}}>Connect</span> {!isWhitelabel && <>or <span className={styles.spanStyle}>
                <a href={`${process.env.LINKGRAPH_COM}/blog/how-to-use-google-search-console/`} target={'_blank'} style={{color: 'unset'}} rel='noreferrer'>Learn how to set up</a></span> </>} Google Search Console</h3>
            </div>
            <div className={styles.paragraph}><p>Follow our detailed guide to set up Google Search Console <br></br> and view detailed data for your website!</p></div>
            <div className={styles.btnSetup}>
              <Button
                className={styles.btnFirst}
                onClick={() => {
                }}
              >
                <a onClick={() => setConfirmModal(true)} className={styles.btnFirstLink}>Connect Search Console</a>
              </Button>
              {isWhitelabel ? '' :
                <Button className={styles.btnSecond}>
                  <a href={`${process.env.LINKGRAPH_COM}/how-to-use-google-search-console/`} target='_blank' rel='noreferrer'>View Detailed Guide</a>
                </Button>}
            </div>
          </div>
          <div className={styles.setupAccount}>
            {!isWhitelabel && <a href={getHubSpotLink()} target='_blank' rel='noreferrer'>Ask us to Set Up your Google Account</a>}
          </div>
        </div>:
          <>
            {getIsAnyGscNotConnectedAccounts ? <AccountsFailedCredBanner showButton={false} emailList={getNotConnectedGscAccounts} className={styles.container}/> : null}
            {hideGscBanner !== 'hide' && gscIsAnyAccHaveNoSitesConnected ? <AccountsHaveNoGscConnectedBanner
              title={`No properties found for ${toJS(integration.googleProfiles)?.length} account${toJS(integration.googleProfiles)?.length > 1 ? 's' : ''}`}
              showCancelButton
              showButton={false}
              emailList={gscNoSitesConnectedAccList}
              className={styles.container}/> :
              null}

            <Account className={styles.WrapperDiv} style={{border: '1px solid #E8E8E8', boxShadow: '0px 3px 2px rgba(0, 0, 0, 0.02)'}}>
              <div>
                {googleProfilesList?.length > 0 ?
                  (isGscDetail ? <GSCSettingsDetail
                    setGaDetail={setIsGscDetail}
                    onDeleteAccount={email => openDeleteModal(email)}
                    gscDetailData={gscDetailData}/> : googleProfilesList.map((profile, index) => {
                    return profile.isAuthorizedForGsc && (
                      <AccountRow key={index}>
                        <div key={index}>
                          <span style={{paddingRight: '5px'}}><UserOutlined /></span>
                          <span>{profile.email}</span>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', width: 260, color: '#2D6CCA'}}>
                          <span style={{cursor: 'pointer'}} onClick={() => {
                            setEmailIndex(index);
                            onRefreshHandler(profile.email);
                          }}>{emailIndex === index ? <RotatingIcon icon={faRotate}/> : <FontAwesomeIcon icon={faRotate}/>} Refresh</span>
                          <div>
                            {profile?.siteproperties?.length === 0 ?
                              <span style={{color: '#A3A4A4'}}>No accounts found</span> :
                              <span onClick={()=>{
                                setIsGscDetail(true);
                                setGscDetailData(profile);
                              }} style={{color: '#2D6CCA', cursor: 'pointer'}}>View {profile.siteproperties.length} {profile.siteproperties.length === 1 ? t('account') : t('accounts')}
                              </span>
                            }
                            <span style={{paddingLeft: '8px', cursor: 'pointer'}}>
                              <Dropdown placement={'bottomRight'} overlayStyle={{background: '#fff', border: '1px solid #e8e8e8', padding: '5px 8px', borderRadius: '8px'}}
                                overlay={<div style={{color: '#F44343', cursor: 'pointer'}} onClick={() => openDeleteModal(profile?.email)}>Disconnect</div>}>
                                <MoreOutlined style={{color: '#121212'}}/>
                              </Dropdown>
                            </span>
                          </div>
                        </div>
                      </AccountRow>
                    );
                  })) : <Empty />}

              </div>
            </Account></>}
        <div style={{display: 'flex', paddingTop: '15px'}}>
          <V2Button variant='ghost' color='blue' onClick={handleAuthorizeConnection}>
            {'+ Connect new GSC account'}
          </V2Button>
        </div>
      </div>
      { confirmModal && (<AuthorizedModal trackingId={SETTINGS_GSC_AUTHORIZE_CLICKED} isOpen={confirmModal} onClose={onClose}/> )}
      <Modal className={styles.Modal} isDark visible={deleteModal} onClose={() => setDeleteModal(false)}>
        <div className={styles.ConfirmationModal}>
          <Typography tag='h2'>{t('disconnect-account-question')}</Typography>
          <span>{t('all-gsc-projects-imported')} <span style={{fontWeight: 600}}>{deleteEmail}</span> {t('will-be-deactived')}</span>
        </div>
        <div className={styles.ButtonDisplay}>
          <Button className={styles.Back} onClick={() => setDeleteModal(false)}>{t('cancel')}</Button>
          <Button className={styles.Danger} onClick={async () => {
            try {
              await integration.deleteGoogleProfile(deleteEmail);
              deleteAccount();
              const detail = {
                profile: deleteEmail,
              };

              MixPanel.track(SETTINGS_DISCONNECT_ACCOUNT, {
                'disconnectingAccount': `${detail}`,
              });
            } catch (e) {
              return Promise.reject(e);
            }
          }}>
            {t('disconnect-account')}
          </Button>
        </div>
      </Modal>
    </Fragment>

  );
});
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
const AccountRow = styled.div`
  display: flex;
  justify-content: space-between;
  height: 42px;
  align-items: center;
  @media only screen and (max-width: 760px) {
    width: 580px !important;
    overflow: auto;
    padding-right: 13px;
  }
`;
const Account = styled.div`
    overflow: auto;
`;
const RotatingIcon = styled(FontAwesomeIcon)`
animation: ${spin} 2s linear infinite;
`;
