import styles from './index.module.scss';
import React, {useEffect, useState} from 'react';
import {Modal} from 'antd';
import styled from 'styled-components';
import {Button} from '@/components/common-components/v2';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {observer} from 'mobx-react-lite';
import {useStore} from '@/store/root-store';
import {faInfoCircle} from '@fortawesome/pro-light-svg-icons';
import {CustomTextarea} from '@/components/common-components/components/form/input/customTextarea';
import {Input, Select, Slider, Tooltip, Alert} from 'antd';
import {getLocation, truncate} from '@/utils/string';
import {numberWithCommas} from '@/utils/number';
import {faArrowLeft} from '@fortawesome/pro-light-svg-icons';
import {TableStyled} from '../../../home-page-main/components/table-styled';
import {Modal as ConfirmModal} from '@/components/common-components';
import {BackButton, DeleteButton, ModalWrapper, Wrapper} from '../../../otto-page-detail/style';
import {notification} from '@/utils/notification-v2';
const {Option} = Select;
import {NextImg} from '@/utils/nextImg';
import {SwitchStyled} from '../../../otto-page-v2-main/Component/styledComponents';
import {PRO_PLAN_ID} from '@/constants';

interface Props {
  onClose?: () => void;
  visible?: boolean;
}

export const SiteBulkAuditor: React.FC<Props> = observer(({onClose, visible}) => {
  const {siteAuditorV2: {handleBulkSiteAudit, loadingBulkUpload, setCrawlBudgetError, crawlBudgetError, maxTotalPageBudget},
    settings: {
      customer: {
        getCustomerQuota,
        profile: {
          quotaUtilization,
          isWhitelabel,
          plan,
        },
      },
    }} = useStore('');
  const agencyName = getLocation(location)?.split('.')[0];
  const utilizedQuota = quotaUtilization?.ca?.allowedSiteAuditorProjects?.consumed ?? 0;
  const totalQuota = quotaUtilization?.ca?.allowedSiteAuditorProjects?.total ?? 0;
  const [sitesList, setSitesList] = useState([]);
  const [selectedSitesList, setSelectedSitesList] = useState([]);
  const [crawlFrequency, setCrawlFrequency] = useState('7');
  const [sitesAdded, setSitesAdded] = useState(false);
  const [userAgent, setUserAgent] = useState('searchatlas');
  const [crawlSpeed, setCrawlSpeed] = useState(5);
  const [crawlPage, setCrawlPage] = useState(5000);
  const [showPlaceHolder, setShowPlaceHolder] = useState(true);
  const [crawlPageTotal, setCrawlPageTotal] = useState(null);
  const [inputSites, setInputSites] = useState('');
  const [crawlSelectOption, setCrawlSelectOption] = useState('weekly');
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [selectedIdx, setSelectedIdx] = useState(null);
  const [crawlCriteria, setCrawlCriteria] = useState<boolean>(true);
  const [jsRendering, setJsRendering] = useState(false);
  const [crawlPagesInputErros, setCrawlPagesInputErros] = useState([]);
  const [applyAllError, setApplyAllError] = useState(false);


  const handleListInput = (val: any) => {
    const rawList = val.target.value.replaceAll(',', '\n');
    setInputSites(rawList);
    setSitesList(rawList.split('\n').filter(Boolean));
  };

  useEffect(() => {
    if (crawlSelectOption) {
      switch (crawlSelectOption) {
        case 'daily':
          setCrawlFrequency('1');
          break;
        case 'weekly':
          setCrawlFrequency('7');
          break;
        case 'monthly':
          setCrawlFrequency('30');
          break;
        case 'never':
          setCrawlFrequency('0');
          break;
        default:
          break;
      }
    }
  }, [crawlSelectOption]);
  const onSiteAdd = () => {
    const sites = [];
    sitesList?.map(item =>{
      try {
        const urlSegment = new URL(item);
        sites.push({
          siteproperty: urlSegment.origin != 'null' ? urlSegment.origin : item,
          isValid: urlSegment.origin != 'null',
          crawlPage: 5000,
        });
      } catch {
        sites.push({
          siteproperty: item,
          isValid: false,
          crawlPage: 5000,
        },
        );
      }
    });
    if (sites?.length > 0) {
      const uniqueSites = Array.from(new Set(sites.map(a => a.siteproperty)))
        .map(siteproperty => {
          return sites.find(a => a.siteproperty === siteproperty);
        });
      setSelectedSitesList(uniqueSites);
      setSitesAdded(true);
      setSitesList([]);
    }
  };

  const onUserAgentChange = (val: any) => {
    setUserAgent(val);
  };

  const onCrawlSpeedChange = (val: any) => {
    setCrawlSpeed(val);
  };

  const onFrequencyChange = (value: any) => {
    const val = Number(value);
    if (val == 0) {
      setCrawlFrequency('');
    } else {
      if (val < 31 && val> -1) {
        setCrawlFrequency(val?.toString());
      }
    }
  };

  const onCrawlPageChange = (val: any) => {
    setCrawlPage(val);
  };

  const onApplyAll = () => {
    const updateSite = selectedSitesList?.map(item=>{
      item.crawlPage = crawlPage;
      return item;
    });
    setSelectedSitesList(updateSite);
  };

  const onCrawlBudgetInputChange = (value, idx)=>{
    const newArr = [...selectedSitesList];
    newArr[idx].crawlPage = value;
    setSelectedSitesList(newArr);
  };

  const onChangeCrawlSpeed = (value, idx)=>{
    if (value <= 0) setCrawlPagesInputErros(prev => [...prev, idx]);
    else setCrawlPagesInputErros(prev => prev.filter(item => item !== idx));
    const newArr = [...selectedSitesList];
    newArr[idx].crawlPage = value;
    setSelectedSitesList(newArr);
  };

  const onSiteRemove = value=>{
    setShowConfirmationPopup(false);
    setSelectedSitesList(selectedSitesList?.map((item, idx)=> idx != value ? item : null).filter(Boolean));
    if (selectedSitesList?.length < 2) {
      setSitesAdded(false);
      setInputSites('');
    }
    notification.success('URL Deleted', 'URL Deleted Successfully');
  };

  const onAuditSites = async () => {
    const payload = [];
    selectedSitesList?.filter(e=>e.isValid).map(item => {
      payload.push({
        siteproperty: item.siteproperty,
        selected_user_agent: userAgent,
        crawl_budget: item.crawlPage,
        crawl_concurrency: crawlSpeed,
        should_respect_robots_txt: crawlCriteria,
        next_processing_delta_days: crawlFrequency,
        javascript_rendering_enabled: jsRendering,
      });
    });
    await handleBulkSiteAudit(payload);
    if (crawlBudgetError?.length==0) {
      onClose();
    }
    await getCustomerQuota();
    // onClose();
  };
  useEffect(()=>{
    if (selectedSitesList?.filter(e=>e.isValid)?.length > 0 && !loadingBulkUpload) {
      if (crawlBudgetError?.length==0) {
        onClose();
      }
      // onClose();
    }
  }, [loadingBulkUpload]);

  useEffect(()=>{
    let total = 0;
    selectedSitesList?.map(item =>{
      total = total + Number(item.crawlPage);
    });
    setCrawlPageTotal(total);
  }, [selectedSitesList]);

  const isValidQuota = ()=>{
    return utilizedQuota + selectedSitesList?.filter(e=>e.isValid)?.length < totalQuota;
  };

  const isValidPageBudget = ()=>{
    return crawlPageTotal < maxTotalPageBudget;
  };

  const onCrawlSpeedInputChange = (value: any) => {
    const val = Number(value.target.value);
    if (value.target.value?.match(/^[0-9]+$/)) {
      if (val < 51) {
        setCrawlSpeed(val);
      }
    } else if (value.target.value == '') {
      setCrawlSpeed(1);
    }
  };

  const onCrawledPagesInputChange = (value: any) => {
    const val = Number(value.target.value);
    if (value.target.value?.match(/^(?!0$)\d+$/)) {
      if (val < 10001) {
        setCrawlPage(val);
      }
    } else if (value.target.value == '' || value.target.value == '0') {
      setCrawlPage(value.target.value);
    }
  };

  useEffect(()=>{
    selectedSitesList.map((item, idx)=>{
      if (item?.crawlPage == '' || item?.crawlPage == '0') setCrawlPagesInputErros(prev => [...prev, idx]);
      else setCrawlPagesInputErros(prev => prev.filter(item => item !== idx));
    });
  }, [selectedSitesList]);

  useEffect(()=>{
    if (!crawlPage || crawlPage == 0) setApplyAllError(true);
    else setApplyAllError(false);
  }, [crawlPage]);

  const columns = [
    {
      title: <SiteListTitle>
        WEBSITES
        <SiteStatus>
          {selectedSitesList?.filter(e=>e.isValid)?.length > 0 && <div style={{color: '#1FAC47'}}> {selectedSitesList?.filter(e=>e.isValid)?.length}  OK</div>}
          {selectedSitesList?.filter(e=> !e.isValid)?.length > 0 && <div style={{color: '#F44343'}}>{selectedSitesList?.filter(e=> !e.isValid)?.length} Failed</div> }
        </SiteStatus>
      </SiteListTitle>,
      dataIndex: 'WEBSITES',
      key: 'WEBSITES',
      align: 'left' as 'left',
      width: 100,
      render: (_, data, idx) => {
        return <UrlItem color={data.isValid ? '#121212' : '#F44343' } title={data.siteproperty.length > 40 ? data.siteproperty : '' }>
          { truncate(data.siteproperty, 40)}
          { !data.isValid && <NextImg src='/img/cross-icon.svg' onClick={()=>{
            setShowConfirmationPopup(true);
            setSelectedIdx(idx);
          }} style={{marginLeft: '5px', cursor: 'pointer'}} /> }
        </UrlItem>;
      },
    },
    {
      title: <SiteListTitle>
        CRAWLED PAGES
        <div >
          <Slider
            onChange={onCrawlPageChange}
            min={0}
            max={10000}
            value={crawlPage}
            className={styles.itemSlider}
            trackStyle={{backgroundColor: '#2D6CCA', height: 6}}
          // defaultValue={30}
          />
        </div>
      </SiteListTitle>,
      dataIndex: 'CRAWLEDPAGES',
      key: 'CRAWLEDPAGES',
      align: 'left' as 'left',
      width: 100,
      render: (_, data, idx) => {
        return <SliderSec>
          <Slider
            className={styles.itemSlider}
            // defaultValue={30}
            value={data.crawlPage}
            min={0}
            max={10000}
            onChange={e=>{
              onChangeCrawlSpeed(e, idx);
            }}
            trackStyle={{backgroundColor: '#2D6CCA', height: 6}}
          />
        </SliderSec>;
      },
    },
    {
      title: <ApplyToAllBtn style={{marginTop: applyAllError && 9}}>
        <span onClick={()=>{
          if (!applyAllError) onApplyAll();
        }} style={{paddingLeft: 5, cursor: applyAllError ? 'not-allowed' : 'pointer'}}> Apply to all </span>
        <div style={{width: 75, marginTop: 3}}>
          <RoundInput
            onChange={onCrawledPagesInputChange}
            value={crawlPage}
          />
          {applyAllError && <div style={{color: '#F44343', fontSize: '12px'}}>
              Invalid Input
          </div>}
        </div >
      </ApplyToAllBtn>,
      dataIndex: 'APPLYTOALL',
      key: 'APPLYTOALL',
      align: 'left' as 'left',
      width: 100,
      render: (_, data, idx) => {
        return <div style={{marginTop: crawlPagesInputErros.includes(idx) && 17}}>
          <div style={{display: 'flex'}}>
            <ItemInputSec>
              <RoundInput
                onChange={e => {
                  const val = Number(e.target.value);
                  if (e.target.value?.match(/^(?!0$)\d+$/)) {
                    if (val < 10001) {
                      onCrawlBudgetInputChange(val, idx);
                    }
                  } else if (e.target.value == '' || e.target.value == '0') {
                    onCrawlBudgetInputChange(e.target.value, idx);
                  }
                }}
                value={data.crawlPage}
              />
            </ItemInputSec>
            <DeleteSite onClick={()=>{
              setShowConfirmationPopup(true);
              setSelectedIdx(idx);
            }}>
              <NextImg src='/img/site-audit/trash-icon.png' height={16} width={14} /></DeleteSite>
          </div>
          {crawlPagesInputErros.includes(idx) && <div style={{color: '#F44343', fontSize: '12px', marginLeft: 18}}>
              Invalid Input
          </div>}
        </div>;
      },
    },
  ];

  return (
    <>
      <Modal
        visible={visible}
        className={styles.modalBulkStyle}
        width={650}
        onCancel={()=>{
          setSelectedSitesList([]);
          setSitesAdded(false);
          setInputSites('');
          onClose();
          setCrawlBudgetError('');
          setCrawlPagesInputErros([]);
        }}
        footer={false}
      >
        <MainDiv>
          <HeadingH2>Audit Websites in Bulk</HeadingH2>

          {!sitesAdded ? (<><Paragraph>The process involves scanning websites for common issues, and then creating detailed report with instructions on how to fix the found issues.</Paragraph><Paragraph>The website URLs should contain the http:// or https:// protocols.</Paragraph></>) :
            <Paragraph>Adjust the crawl settings based on your requirements and budget.</Paragraph>}
          {crawlBudgetError &&<div style={{paddingTop: '10px', paddingBottom: '20px'}}> <Alert
            // message='Error Text'
            description={crawlBudgetError}
            type='error'
            closable
            onClose={()=>setCrawlBudgetError('')}
          /> </div>}
          {sitesAdded && selectedSitesList?.length > 0 && <div>
            <GeneralSection>
              <div>
                <GeneralTitle style={{marginBottom: 0}}>
                  User Agent
                  <Tooltip title={`User Agent controls how the ${!isWhitelabel ? 'LinkGraph' : getLocation(location)} Crawler identifies itself.`}>
                    <FontAwesomeIcon icon={faInfoCircle} color={'#DADADA'} style={{fontSize: '12px', marginLeft: '5px'}} />
                  </Tooltip>
                </GeneralTitle>
                <Select
                  style={{width: 250, marginTop: 9}}
                  className={styles.selectStyle}
                  value={userAgent}
                  showSearch
                  placeholder='Select user agent'
                  optionFilterProp='children'
                  onChange={onUserAgentChange}
                  filterOption={(input, option) =>
                    (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                  }
                >
                  <Option value='google_chrome_desktop'>Google Chrome Desktop</Option>
                  <Option value='google_chrome_mobile'>Google Chrome Mobile</Option>
                  <Option value='googlebot_desktop'>Googlebot Desktop</Option>
                  <Option value='googlebot_mobile'>Googlebot Mobile</Option>
                  <Option value='bingbot_desktop'>Bingbot Desktop</Option>
                  <Option value='bingbot_mobile'>Bingbot Mobile</Option>
                  <Option value='slurp'>Slurp</Option>
                  <Option value='yandexbot'>Yandexbot</Option>
                  <Option value='baiduspider'>Baiduspider</Option>
                  <Option value='screaming_frog'>Screaming frog</Option>
                  <Option value='duckduckgo'>Duckduckgo</Option>
                  <Option value='searchatlas'>{isWhitelabel ? agencyName : 'Searchatlas'}</Option>
                </Select>
              </div>
              <GeneralSliderSec>
                <GeneralTitle>
                  Crawl Speed
                  <Tooltip title={'Increase speed if you have large site for quick page crawls'}>
                    <FontAwesomeIcon icon={faInfoCircle} color={'#DADADA'} style={{fontSize: '12px', marginLeft: '5px'}} />
                  </Tooltip>
                </GeneralTitle>
                <Slider
                  value={crawlSpeed}
                  onChange={onCrawlSpeedChange}
                  className={styles.mainSlider}
                  min={1}
                  max={50}
                  trackStyle={{backgroundColor: '#2D6CCA', height: 7}}
                />
              </GeneralSliderSec>
            </GeneralSection>
            <CrawelFrequency>
              <div>
                <GeneralTitle style={{marginTop: 10}}>
                  Crawl Frequency
                </GeneralTitle>
                <Select
                  style={{width: 250, marginTop: '-4px'}}
                  className={styles.selectStyle}
                  defaultValue={crawlSelectOption}
                  showSearch
                  placeholder='Select Crawl Frequency'
                  optionFilterProp='children'
                  // onChange={onFrequencyChange}
                  onChange={value => setCrawlSelectOption(value)}
                  value={crawlSelectOption}
                >
                  <Option value='daily'>Daily</Option>
                  <Option value='weekly'>Weekly</Option>
                  <Option value='monthly'>Monthly</Option>
                  <Option value='Custom'>Custom</Option>
                  <Option value='never'>Never</Option>

                </Select>
              </div>
              <div className='parallelRequest'>
                <div style={{display: 'flex'}}>
                  <span style={{marginTop: 6, fontSize: 12, width: '105px', color: '#4E5156'}}>Parallel Requests: </span>
                  <span style={{width: '40px'}}>
                    <RoundInput
                      onChange={onCrawlSpeedInputChange}
                      value={crawlSpeed}
                    />
                  </span>
                </div>
              </div>
            </CrawelFrequency>
            <div>
              <CrawlCriteria>
                <p style={{fontSize: 12, color: '#4E5156', marginTop: 10}}>Crawl Criteria</p>
                <div style={{height: 35, gap: '10px', display: 'flex', alignItems: 'center'}}>
                  <SwitchStyled checked={crawlCriteria} onChange={checked=>{
                    setCrawlCriteria(checked);
                  }}/>
                  {crawlCriteria ? 'Respect' : 'Ignore'} robots.txt
                </div>
              </CrawlCriteria>
            </div>
            <div>
              <CrawlCriteria>
                <p style={{fontSize: 12, color: '#4E5156', marginTop: 10}}>JS Rendering</p>
                <div style={{height: 35, gap: '10px', display: 'flex', alignItems: 'center'}}>
                  <Tooltip title={plan !== PRO_PLAN_ID ? 'Available only in PRO plan' : ''}>
                    <SwitchStyled disabled={plan !== PRO_PLAN_ID} checked={jsRendering} defaultChecked={jsRendering} onChange={checked=>{
                      setJsRendering(checked);
                    }}/>
                  </Tooltip>
                  {crawlCriteria ? 'Respect' : 'Ignore'} robots.txt
                </div>
              </CrawlCriteria>
            </div>
            {crawlSelectOption== 'Custom'&&
              <div style={{display: 'flex', marginLeft: 20, marginTop: '10px'}}>
                <span style={{marginTop: 4, fontSize: 12, color: '#4E5156'}}>Every  </span>
                <span>
                  <div style={{width: '75px', marginLeft: 10, marginRight: 10}}>
                    <RoundInput
                      crawlFrequency ={crawlFrequency}
                      value={crawlFrequency}
                      onChange={e=>onFrequencyChange(e?.target?.value)}
                    />
                  </div>
                </span>
                <span style={{fontSize: 12, marginTop: 4, color: '#4E5156'}}>Days</span>
              </div>}
            <HorizantalLine />

            <QuotaContainer>
              <Quota> Quota: <span style={isValidQuota() ? {} : {color: '#F44343'} }>{ utilizedQuota + selectedSitesList?.filter(e=>e.isValid)?.length }</span>  / <span style={{color: '#A3A4A4'}}>{totalQuota}</span> </Quota>
              <TotalPageBudget> Total Page Budget: <span style={isValidPageBudget() ? {}: {color: '#F44343'}}>{numberWithCommas(crawlPageTotal || 0)}</span>  / <span style={{color: '#A3A4A4'}}>{numberWithCommas(maxTotalPageBudget || 0 )}</span>  </TotalPageBudget>
            </QuotaContainer>
            <div style={{width: '100%'}}>
              <StyledTable columns={columns} dataSource={selectedSitesList} pagination={false}/>
            </div>
          </div>}
          {!sitesAdded && <CustomTextarea
            orderListWidth={!inputSites?.length ? '15px' : '45px'}
            margin='17px 6px 0px 0px'
            autoSize={true}
            rows={2}
            value={inputSites}
            minHeight='80px'
            width='100%'
            variant='light'
            autoSizeCheck={true}
            onFocus={() => setShowPlaceHolder(false)}
            onBlur={() => setShowPlaceHolder(true)}
            placeholder={showPlaceHolder ? 'Input or paste a list of website URLs' : ''}
            onChange={handleListInput}
          ></CustomTextarea>}
          <HorizantalLine />
          <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: '8px'}}>
            <div>
              {sitesAdded && <Button
                style={{borderColor: '#E8E8E8'}}
                background='white'
                textColor='#4E5156'
                onClick={()=>{
                  const rawList = inputSites.replaceAll(',', '\n');
                  setSitesAdded(false);
                  setSitesList(rawList.split('\n').filter(Boolean));
                  setCrawlPagesInputErros([]);
                }}
              >
                <FontAwesomeIcon icon={faArrowLeft} color={'#4E5156'} style={{fontSize: '12px', marginRight: '9.7px'}} />
                Edit URLs
              </Button>}
            </div>
            <div style={{display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap'}}>
              <Button
                style={{borderColor: '#E8E8E8'}}
                onClick={()=>{
                  setSelectedSitesList([]);
                  setSitesAdded(false);
                  setInputSites('');
                  onClose();
                  setCrawlBudgetError('');
                }}
                background='white'
                textColor='#4E5156'
                mode={'dark'}
                color={'tableGray'}>
                Cancel
              </Button>
              {sitesAdded && selectedSitesList?.length > 0 ? <Button
                disabled={selectedSitesList?.filter(e=>!e.isValid)?.length > 0 || !isValidPageBudget() || !isValidQuota() || !crawlFrequency || crawlPagesInputErros.length > 0}
                loading={loadingBulkUpload}
                onClick={onAuditSites}
              >
                Audit {selectedSitesList?.filter(e=>e.isValid)?.length > 0 ? selectedSitesList?.filter(e=>e.isValid)?.length : ''} {selectedSitesList?.filter(e=>e.isValid)?.length > 1 ? 'sites' : 'site'}
              </Button> : <Button
                disabled={!inputSites.trim()}
                loading={loadingBulkUpload}
                onClick={onSiteAdd}
              >
                Next
              </Button>}
            </div>
          </div>
        </MainDiv>
      </Modal>
      {showConfirmationPopup && <Wrapper>
        <ConfirmModal
          onClose={() => setShowConfirmationPopup(false)}
          visible={showConfirmationPopup}
          className={'customConfirmationModal'}
        >
          <ModalWrapper>
            <p>Are you sure you want to delete this record? </p>
            <p className={'warningText'}>This action cannot be undone.</p>

            <DeleteButton
              buttonType='transparent' size='lg'
              onClick={() => onSiteRemove(selectedIdx)}>
            Delete
            </DeleteButton>
            <BackButton onClick={() => setShowConfirmationPopup(false)} buttonType='transparent' size='lg'>
            Go Back
            </BackButton>

          </ModalWrapper>
        </ConfirmModal>
      </Wrapper>}
    </>
  );
});


const GeneralTitle = styled.p`
  font-weight: 400;
  font-size: 12px;
  color: #4E5156;
`;

const GeneralSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0px 0px 0px;
  @media screen and (max-width: 645px) {
    display: block;
  }
`;

const GeneralSliderSec = styled.div`
  width: 220px;
  margin-right: 70px;
  @media screen and (max-width: 645px) {
    margin-top: 8px;
    margin-right: 0px;
  }
`;

const SiteStatus = styled.div`
  margin-top: 8px;
  font-weight: 400;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  width: 85px;
`;

const ApplyToAllBtn = styled.div`
  color: #2D6CCA;
  font-weight: 400;
  font-size: 12px;
  margin-left: 20px;
`;

const SiteListTitle = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: #121212;
`;

const UrlItem = styled.div<{ color: string }>`
  color: ${p => p.color || '#121212'};
  width: 290px;
`;

const SliderSec = styled.div`
  width: 120px;
`;

const ItemInputSec = styled.div`
  margin-left: 18px;
  width: 75px;
`;

const RoundInput = styled(Input)<{crawlFrequency?: string}>`
    border: ${p => p.crawlFrequency=='' || p.crawlFrequency == '0' ? '1px solid #FF0000' : ''};
    box-shadow:   ${p => p.crawlFrequency=='' || p.crawlFrequency == '0' ? ' 0 0 0 1px #FF0000' : ''};

    border-radius: 5px;
    font-size: 12px;
    &:focus {
      border: ${p => p.crawlFrequency=='' || p.crawlFrequency == '0' ? '1px solid #FF0000' : ''};
    
      box-shadow:   ${p => p.crawlFrequency=='' || p.crawlFrequency == '0' ? ' 0 0 0 1px #FF0000' : ''};
    }
    &:hover {
      border: ${p => p.crawlFrequency=='' || p.crawlFrequency == '0' ? '1px solid #FF0000' : ''};
      box-shadow:   ${p => p.crawlFrequency=='' || p.crawlFrequency == '0' ? ' 0 0 0 1px #FF0000' : ''};

    }
`;

const DeleteSite = styled.div`
  margin-left: 34px;
  cursor: pointer;
`;

const HorizantalLine = styled.hr`
  background-color: #E8E8E8;
  opacity: 0.4;
  height: 0.5px;  
  margin: 20px 0px 29px 0px;
`;

const Paragraph = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4E5156;
  padding-right: 10px;;
  padding: 10px auto;
`;

const MainDiv = styled.div`
  background: #F9F9FB;
  max-width: 600px;
  height: auto;
  box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
`;

const HeadingH2 = styled.h2`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #121212;
`;

const CrawelFrequency = styled.div`
 display: flex;
 justify-content: space-between;
 margin-right: 140px; 
 @media screen and (max-width: 645px) {
    display: block;
    .parallelRequest {
      margin-top: 8px;
    }
  }
`;

const StyledTable = styled(TableStyled)`
  table .ant-table-thead {
    background: #F2F2F5 !important;
    padding: 14px 0px 12px 20px;
  }
`;

const TotalPageBudget = styled.p`
  margin-left: 203;
  font-size: 14;
  font-weight: 400;  
  @media screen and (max-width: 760px) {
    margin-left: 0px;
  }
`;

const Quota = styled.p`
  font-size: 14;
  font-weight: 400;
`;

const QuotaContainer = styled.div`
 display: flex;
 @media screen and (max-width: 760px) {
  display: block;
 }
`;

const CrawlCriteria = styled.div`
    .ant-switch-checked {
      background: #2e904e !important;
    }
`;
