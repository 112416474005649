import {PAGES_API} from '@/api/content-optimizer';
import {cast, flow, getParent, getRoot, Instance, types} from 'mobx-state-tree';
import {notification, useErrorNotification} from '@/utils/notification-v2';
import {toJS} from 'mobx';
import {contentReWritingApi} from '@/api/account';
import {getTokenFromCookies} from '@/api/common-utils';
import {isNil} from 'lodash';
import {apiError} from '@/utils/api';
import {isScaPublicPage} from '@/utils/url';

export const AiOutlineModel = types.model({
  heading: types.maybeNull(types.string),
  paragraph: types.maybeNull(types.string),
  isAdded: types.maybeNull(types.boolean),
});
export const AiOutlineHistoryModel = types.model({
  heading: types.maybeNull(types.string),
  paragraph: types.maybeNull(types.string),
  isAdded: types.maybeNull(types.boolean),
});
export const AiWritterHistoryModel = types.model({
  heading: types.maybeNull(types.string),
  paragraph: types.maybeNull(types.string),
  isAdded: types.maybeNull(types.boolean),
});
export const HeadingSuggestionsModel = types.model({
  h1: types.maybeNull(types.string),
  h2S: types.maybeNull(types.array(types.string)),
});

export const UserAiSettingsModel = types.model({
  createdAt: types.maybeNull(types.string),
  defaultLanguage: types.maybeNull(types.string),
  toneOfVoice: types.maybeNull(types.string),
  pointOfView: types.maybeNull(types.string),
  typeOfContent: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  user: types.maybeNull(types.number),
});

export const AiInputsModel = types.model({
  fieldLabel: types.maybeNull(types.string),
  fieldName: types.maybeNull(types.string),
  fieldPlaceholder: types.maybeNull(types.string),
  fieldType: types.maybeNull(types.string),
  maxLength: types.maybeNull(types.number),
  isMandatory: types.maybeNull(types.boolean),
});

export const AiTemplatesModel = types.model({
  category: types.maybeNull(types.array(types.string)),
  description: types.maybeNull(types.string),
  inputs: types.maybeNull(types.array(AiInputsModel)),
  label: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  imageUrl: types.maybeNull(types.string),
});

export type AiTemplateType = Instance<typeof AiTemplatesModel>;

export const AiTemplateControllerModel = types.model({
  showForm: types.maybeNull(types.boolean),
  templateType: types.maybeNull(types.string),
  templateIcon: types.maybeNull(types.string),
  templateName: types.maybeNull(types.string),
  templateDesc: types.maybeNull(types.string),
  fieldsSetup: types.maybeNull(types.array(AiInputsModel)),
  defaultForm: types.maybeNull(types.boolean),
  defaultFormType: types.maybeNull(types.string),
  lastGeneratedOutput: types.maybeNull(types.string),
  isStandaloneTool: types.maybeNull(types.boolean),
  isUseMask: types.maybeNull(types.boolean),
});

export const AiWriterControllerModel = types.model({
  showForm: types.maybeNull(types.boolean),
  templateUsed: types.maybeNull(types.string),
  templateIcon: types.maybeNull(types.string),
  templateName: types.maybeNull(types.string),
  templateDesc: types.maybeNull(types.string),
  isStandaloneTool: types.maybeNull(types.boolean),
});

export type AiTemplateControllerType = Instance<typeof AiTemplateControllerModel>;

export const AiTemplatesResponseModel = types.model({
  templates: types.maybeNull(types.array(AiTemplatesModel)),
  categories: types.maybeNull(types.array(types.string)),
});

export const TemplateOutlinesInputsModel = types.model({
  fieldLabel: types.maybeNull(types.string),
  fieldName: types.maybeNull(types.string),
  fieldValue: types.maybeNull(types.union(types.string, types.boolean)),
});

export const TemplateOutlinesModel = types.model({
  id: types.maybeNull(types.number),
  type: types.maybeNull(types.string),
  uuid: types.maybeNull(types.string),
  inputs: types.maybeNull(types.array(TemplateOutlinesInputsModel)),
  result: types.maybeNull(types.string),
  isFavorite: types.maybeNull(types.boolean),
  createdAt: types.maybeNull(types.string),
  label: types.maybeNull(types.string),
});

export const HistoryInputsModel = types.model({
  fieldLabel: types.maybeNull(types.string),
  fieldName: types.maybeNull(types.string),
  fieldValue: types.maybeNull(types.union(types.string, types.boolean)),
});

export const AiTemplatesHistoryModel = types.model({
  id: types.maybeNull(types.number),
  type: types.maybeNull(types.string),
  uuid: types.maybeNull(types.string),
  result: types.maybeNull(types.string),
  isFavorite: types.maybeNull(types.boolean),
  createdAt: types.maybeNull(types.string),
  label: types.maybeNull(types.string),
  inputs: types.maybeNull(types.array(HistoryInputsModel)),
});

const CompleteSEOOutlineModel = types.model({
  id: types.maybeNull(types.number),
  type: types.maybeNull(types.string),
});

const outlinesArray = types.model({
  cleanedHtml: types.maybeNull(types.string),
  editorState: types.maybeNull(types.string),
});

const aiOutlinesArrayType = types.model({
  uuid: types.maybeNull(types.string),
  outlines: types.maybeNull(types.array(outlinesArray)),
});
const AiWritterHistoryArrayType = types.model({
  uuid: types.maybeNull(types.string),
  outlines: types.maybeNull(types.array(outlinesArray)),
});

const DummyImagesType = types.model({
  load: types.boolean,
  title: types.string,
});

export const AiOutline = types.model({
  aiOutline: types.array(AiOutlineModel),
  aiOutlineHistory: types.array(AiOutlineHistoryModel),
  v2AiOutline: types.maybeNull(types.array(types.string)),
  completeSeoArticleOutline: types.maybeNull(CompleteSEOOutlineModel),
  oneClickOutline: types.maybeNull(types.array(types.string)),
  aiWriterOutlines: types.maybeNull(types.array(aiOutlinesArrayType)),
  aiWritterHistory: types.maybeNull(types.array(AiWritterHistoryArrayType)),
  id: types.maybeNull(types.number),
  oneClickContentIdea: types.maybeNull(types.string),
  firstDraftHistoryOutlines: types.maybeNull(types.array(types.string)),
  tempalteOutlines: types.maybeNull(types.array(TemplateOutlinesModel)),
  templatesHistory: types.maybeNull(types.array(AiTemplatesHistoryModel)),
  dummyImages: types.maybeNull(types.array(DummyImagesType)),
  imageGenerateInputValue: types.string,
  lastUsedHistoryTemplate: types.maybeNull(types.string),
  loading: types.boolean,
  copyStatus: types.string,
  loadingStatus: types.maybeNull(types.string),
  historyLoading: types.boolean,
  aiWritterHistoryLoding: types.boolean,
  headingSuggestionsLoading: types.boolean,
  headingSuggestions: types.maybeNull(types.array(HeadingSuggestionsModel)),
  isOpenAiOutlineDrawer: types.boolean,
  isOpenAiWriterDrawer: types.boolean,
  showOutlinesInDrawer: types.boolean,
  isOpenAiContentToolsDrawer: types.boolean,
  userAiSettings: types.maybeNull(UserAiSettingsModel),
  loadingUserAiSettings: types.boolean,
  loadingAiQuota: types.boolean,
  rewritingContent: types.boolean,
  rewritingId: types.maybeNull(types.number),
  aiTemplatesController: AiTemplateControllerModel,
  aiWriterController: AiWriterControllerModel,
  loadingTemplates: types.boolean,
  topUpVisibility: types.boolean,
  topUpBulkVisibility: types.boolean,
  showLessResultsBanner: types.boolean,
  aiTemplates: types.maybeNull(AiTemplatesResponseModel),
  aiArticleAlreadyReplaced: types.boolean,
  isHTMLLength: types.boolean,
  creatingAiArticleSuccessStatus: types.boolean,
  creatingAiArticleFailureStatus: types.boolean,
  disabledArticleId: types.number,
  showRemainingTerms: types.boolean,
  shouldOverwriteContent: types.boolean,
  shouldOverwriteContentSuccessMessage: types.boolean,
  shouldPopulatePageHtml: types.maybeNull(types.boolean),
  openUpdateProjectModal: types.maybeNull(types.boolean),
  isShowWpSync: types.maybeNull(types.boolean),
  showDesc: types.maybeNull(types.boolean),
  isEdit: types.boolean,
  bulkAiTopup: types.boolean,
}).actions(self => {
  /**
  * Returns list of all AI templates
  *
  * @method getAiTemplatesList
  */
  const getAiTemplatesList = flow(function* () {
    self.loadingTemplates = true;
    try {
      const response = yield PAGES_API.getAiTempaltes();
      if (response.isCancel) return;

      self.aiTemplates = cast(response);
    } catch (e) {
      // we are replacing data with hard coded JSON in case api fails so we dont need notification attm
      // useErrorNotification({
      //   e,
      //   msg: 'Could not load templates.',
      //   desc: 'Please try again later.'
      // })
      return Promise.reject(e);
    } finally {
      self.loadingTemplates = false;
    }
  });

  const setAiTemplate = data => {
    self.aiTemplatesController = data;
  };
  const setTopUpVisibility = value => {
    self.topUpVisibility = value;
  };

  const setIsEdit = value => self.isEdit = value;

  const setTopUpBulkVisibility = value => {
    self.topUpBulkVisibility = value;
  };

  const setShowRemainingTerms = value => {
    self.showRemainingTerms = value;
  };
  const setShouldOverwriteContent = value => {
    self.shouldOverwriteContent = value;
  };
  const setShouldOverwriteContentSuccessMessage = value => {
    self.shouldOverwriteContentSuccessMessage = value;
  };
  const setShowDesc = value => {
    self.showDesc = value;
  };
  const setBulkAiTopup = value => self.bulkAiTopup = value;

  const setCopyStatus = value => {
    self.copyStatus = value;
  };
  const setAiWriter = data => {
    self.aiWriterController = data;
  };
  const setOpenUpdateProjectModal = value => {
    self.openUpdateProjectModal = value;
  };

  const setIsShowWpSync = value => {
    self.isShowWpSync = value;
  };


  const loadAiOutline = flow(function* (uuid: string, topic?: string, termsToInclude?: string, resultsCounter?: number) {
    self.loading = true;
    try {
      const response = yield PAGES_API.getAiOutline(uuid, topic, termsToInclude, resultsCounter);
      if (response.isCancel) return;
      self.loading = false;
      self.aiTemplatesController = {
        ...toJS(self.aiTemplatesController),
        lastGeneratedOutput: 'ContentIdea',
      };
      self.aiOutline = response;
    } catch (e) {
      self.loading = false;
      const {status} = e.response;
      if (status === 429) {
        const rootStore = getRoot(self) as any;
        // rootStore?.settings?.customer?.profile?.matchAiConsumedTotalQuota();
        // const totalAiQuota = rootStore?.settings?.customer?.profile?.quotaUtilization?.ca?.allowedAiContentGeneration?.total;
        // const quotaTitle = totalAiQuota ? `You've consumed all quota points ${totalAiQuota}/${totalAiQuota}.` : `You've consumed all quota points.`;
        if (rootStore?.settings?.customer?.profile?.isWhitelabel) {
          notification.error('Quota is exceeded', 'You’ve exceeded the usage limit for this tool.');
        } else {
          const quotaTitle = 'You are trying to use more quota than available.';
          notification.warning(quotaTitle, 'Upgrading to a higher plan will increase the quota limits.', 'View pricing', () => rootStore?.plans?.showSidebarPaymentDrawer());
        }
      } else {
        useErrorNotification({
          e,
          msg: 'Error loading Content Ideas.',
          desc: 'Try again later',
          permanent: false,
          handleStatuses: [{
            statuses: [401, 403],
            msg: 'Please sign up to edit this article.',
            permanent: false,
            showDetails: false,
          }],
        });
      }

      return Promise.reject(e);
    } finally {
      self.loading = false;
    }
  });

  // This is work in progress, when complete it should be used instead of those if/else conditions in the method bellow
  // function waitForConditionWithInterval(conditionFn, interval, maxAttempts) {
  //   return new Promise((resolve, reject) => {
  //     let attempts = 0;
  //     const intervalId = setInterval(() => {
  //       if (conditionFn()) {
  //         clearInterval(intervalId);
  //         resolve();
  //       } else {
  //         attempts++;
  //         if (attempts >= maxAttempts) {
  //           clearInterval(intervalId);
  //           reject(new Error('Max attempts reached'));
  //         }
  //       }
  //     }, interval);
  //   });
  // }

  const refetchOneClickDraftResult = flow(function* (id: number) {
    self.loading = true;
    try {
      const response = yield PAGES_API.loadOneClickDraftResults(id);
      if (response.isCancel) return;
      if (response?.status !== 'SUCCESS') self.loadingStatus = response.status;
      if (Array.isArray(response?.results)) {
        self.oneClickOutline = cast(response.results);
      } else {
        self.oneClickOutline = cast([]);
      }
      if (!isNil(response.resultCount)) {
        self.oneClickOutline = cast(response.results);
        self.oneClickContentIdea = cast(response.kewyord);
        self.loading = false;
        return response;
      } else {
        // TO DO: Refactor asap - quick fix to make sure these booleans dont change back too soon
        if (self.isOpenAiWriterDrawer && self.aiWriterController?.showForm) {
          yield new Promise(r => setTimeout(r, 500));
          if (self.isOpenAiWriterDrawer && self.aiWriterController?.showForm) {
            yield new Promise(r => setTimeout(r, 500));
            if (self.isOpenAiWriterDrawer && self.aiWriterController?.showForm) {
              yield new Promise(r => setTimeout(r, 500));
              if (self.isOpenAiWriterDrawer && self.aiWriterController?.showForm) {
                yield new Promise(r => setTimeout(r, 500));
                if (self.isOpenAiWriterDrawer && self.aiWriterController?.showForm) {
                  yield new Promise(r => setTimeout(r, 500));
                  if (self.isOpenAiWriterDrawer && self.aiWriterController?.showForm) {
                    yield new Promise(r => setTimeout(r, 500));
                    return refetchOneClickDraftResult(id);
                  } else {
                    self.loading = false;
                  }
                } else {
                  self.loading = false;
                }
              } else {
                self.loading = false;
              }
            } else {
              self.loading = false;
            }
          } else {
            self.loading = false;
          }
        } else {
          self.loading = false;
        }
      }
    } catch (e) {
      self.loading = false;
      const {status} = e.response;
      if (status === 429) {
        const rootStore = getRoot(self) as any;
        if (rootStore?.settings?.customer?.profile?.isWhitelabel) {
          notification.error('Quota is exceeded', 'You’ve exceeded the usage limit for this tool.');
        } else {
          const quotaTitle = 'You are trying to use more quota than available.';
          notification.warning(quotaTitle, 'Upgrading to a higher plan will increase the quota limits.', 'View pricing', () => rootStore?.plans?.showSidebarPaymentDrawer());
        }
      } else {
        useErrorNotification({
          e,
          msg: 'Error loading Content Ideas.',
          desc: 'Try again later',
          permanent: false,
          handleStatuses: [{
            statuses: [401, 403],
            msg: 'Please sign up to edit this article.',
            permanent: false,
            showDetails: false,
          }],
        });
      }
    }
  });

  const getAiGeneratedContentStatusData = async (key: string, pageSize: Number, currentPage: Number) => {
    try {
      const response = await PAGES_API.loadAiGeneratedContentStatus(key, pageSize, currentPage);
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  };

  // saving generated content per article uuid
  // this is done so each article AI Writer drawer will ONLY see any generated results for that particular UUID
  // this also applies to loaders
  const saveOutlineByUuid = (uuid: string, outlinesArr) => {
    const existingOutlines = toJS(self.aiWriterOutlines) ?? [];
    if (!uuid) return;
    const foundElemId = existingOutlines?.findIndex(item => item.uuid == uuid);
    if (foundElemId >= 0) {
      existingOutlines[foundElemId]['outlines'] = outlinesArr;
    } else {
      existingOutlines.push({
        uuid: uuid,
        outlines: outlinesArr,
      });
    }

    self.aiWriterOutlines = cast(existingOutlines);
  };
  const saveOutlinesHistoryByUuid = (uuid: string, outlinesArr) => {
    const existingOutlines = toJS(self.aiWriterOutlines) ?? [];

    if (!uuid) return;
    const foundElemId = existingOutlines?.findIndex(item => item.uuid == uuid);
    if (foundElemId >= 0) {
      existingOutlines[foundElemId]['outlines'] = outlinesArr;
    } else {
      existingOutlines.push({
        uuid: uuid,
        outlines: outlinesArr,
      });
    }

    self.aiWritterHistory = cast(existingOutlines);
  };

  const refetchOneClickDraftResultForArticleCreation = flow(function* (uuid: string, timeStamp?: number) {
    const parent = getParent(self) as any;
    self.loading = true;
    try {
      const response = yield PAGES_API.getAiWriterHistory(uuid);

      if (response[0]?.status != null) {
        self.isHTMLLength = true;
      } else {
        self.isHTMLLength = false;
      }

      if (response[0]?.status == 'SUCCESS') {
        self.creatingAiArticleSuccessStatus = true;
        self.creatingAiArticleFailureStatus = false;
      }

      if (response[0]?.status == 'FAILURE') {
        self.creatingAiArticleFailureStatus = true;
        self.creatingAiArticleSuccessStatus = false;
      }

      if (response.isCancel) return;

      if (!isNil(response[0]?.status) && response[0]?.status!== 'SUCCESS') {
        self.loadingStatus = response[0].status;
      }

      self.shouldPopulatePageHtml = response[0].shouldPopulatePageHtml;

      const responsesToAdd = () => {
        const output = [];
        response?.forEach(item => {
          if (Array.isArray(item.results)) {
            output.push({
              cleanedHtml: item.isTranslated ? item.translatedResults[0] : item.results[0],
              editorState: item.editorState,
            });
          }
        });

        return output;
      };


      saveOutlineByUuid(uuid, responsesToAdd());
      saveOutlinesHistoryByUuid(uuid, responsesToAdd());

      if ((!isNil(response[0]?.resultCount) && response[0]?.status === 'SUCCESS') || response[0]?.status === 'FAILURE') {
        parent.currentPage.loadPage(uuid as string, false);
        self.loadingStatus = cast('');
        self.loading = false;

        return response[0];
      } else {
        yield new Promise(r => setTimeout(r, 3000));
        const now = new Date().getTime();

        if (now - timeStamp < 30 * 60 * 1000) {
          return refetchOneClickDraftResultForArticleCreation(uuid, timeStamp);
        }
      }
    } catch (e) {
      const rootStore = getRoot(self) as any;
      if (e?.response?.status === 520 || e?.response?.status === 524) {
        yield new Promise(r => setTimeout(r, 3000));
        const now = new Date().getTime();

        if (now - timeStamp < 30 * 60 * 1000) {
          return refetchOneClickDraftResultForArticleCreation(uuid, timeStamp);
        }
      } else if (e?.response?.status === 429) {
        if (rootStore?.settings?.customer?.profile?.isWhitelabel) {
          notification.error('Quota is exceeded', 'You’ve exceeded the usage limit for this tool.');
        } else {
          const quotaTitle = 'You are trying to use more quota than available.';
          notification.warning(quotaTitle, 'Upgrading to a higher plan will increase the quota limits.', 'View pricing', () => rootStore?.plans?.showSidebarPaymentDrawer());
        }
        self.loading = false;
        rootStore?.contentOptimizer?.currentPage?.setAiInProgress(false);
        parent.currentPage.loadPage(uuid as string, false);
        self.loadingStatus = cast('');
      } else {
        self.loading = false;
        rootStore?.contentOptimizer?.currentPage?.setAiInProgress(false);
        parent.currentPage.loadPage(uuid as string, false);
        self.loadingStatus = cast('');
      }
    } finally {
      parent?.contentOptimizer?.currentPage?.setAiInProgress(false);
    }
  });
  const loadOneClickDraft = flow(function* (contentIdea: string, termsToInclude: string[], length: string) {
    self.loading = true;
    try {
      const response = yield PAGES_API.getOneClickDraft(contentIdea, termsToInclude, length);
      if (response.isCancel) return;
      return response;
    } catch (e) {
      self.loading = false;
      const {status} = e.response;
      if (status === 429) {
        const rootStore = getRoot(self) as any;
        if (rootStore?.settings?.customer?.profile?.isWhitelabel) {
          notification.error('Quota is exceeded', 'You’ve exceeded the usage limit for this tool.');
        } else {
          const quotaTitle = 'You are trying to use more quota than available.';
          notification.warning(quotaTitle, 'Upgrading to a higher plan will increase the quota limits.', 'View pricing', () => rootStore?.plans?.showSidebarPaymentDrawer());
        }
      } else {
        useErrorNotification({
          e,
          msg: 'Error loading Content Ideas.',
          desc: 'Try again later',
          permanent: false,
          handleStatuses: [{
            statuses: [401, 403],
            msg: 'Please sign up to edit this article.',
            permanent: false,
            showDetails: false,
          }],
        });
      }
    } finally {
      self.loading = false;
    }
  });

  const loadAiOutlineStandalone = flow(function* (topic?: string, termsToInclude?: string, resultsCounter?: number) {
    self.loading = true;
    try {
      const response = yield PAGES_API.getAiOutlineStandalone(topic, termsToInclude, resultsCounter);
      if (response.isCancel) return;
      self.loading = false;
      self.aiTemplatesController = {
        ...toJS(self.aiTemplatesController),
        lastGeneratedOutput: 'ContentIdea',
      };
      self.aiOutline = response;
    } catch (e) {
      self.loading = false;
      const {status} = e.response;
      if (status === 429) {
        const rootStore = getRoot(self) as any;
        if (rootStore?.settings?.customer?.profile?.isWhitelabel) {
          notification.error('Quota is exceeded', 'You’ve exceeded the usage limit for this tool.');
        } else {
          const quotaTitle = 'You are trying to use more quota than available.';
          notification.warning(quotaTitle, 'Upgrading to a higher plan will increase the quota limits.', 'View pricing', () => rootStore?.plans?.showSidebarPaymentDrawer());
        }
      } else {
        useErrorNotification({
          e,
          msg: 'Error loading Content Ideas.',
          desc: 'Try again later',
          permanent: false,
          handleStatuses: [{
            statuses: [401, 403],
            msg: 'Please sign up to edit this article.',
            permanent: false,
            showDetails: false,
          }],
        });
      }


      return Promise.reject(e);
    } finally {
      self.loading = false;
    }
  });

  /**
   * @method loadAiOutlineHistory returns history results for AI Outline tool
   *
   * @param {string} uuid uuid of the current article
   */
  const loadAiWriterHistory = flow(function* (uuid: string) {
    self.aiWritterHistoryLoding = true;
    try {
      const response = yield PAGES_API.getAiWriterHistory(uuid);
      self.id = response[0]?.id;
      if (response.isCancel) return;

      const responsesToAdd = () => {
        const output = [];
        response?.forEach(item => {
          if (Array.isArray(item.results)) {
            output.push({
              cleanedHtml: item.isTranslated ? item.translatedResults[0] : item.results[0],
              editorState: item.editorState,
            });
          }
        });

        return output;
      };
      saveOutlinesHistoryByUuid(uuid, responsesToAdd());
    } catch (e) {
      self.aiWritterHistoryLoding = false;
      const {status} = e.response;
      if (status === 429) {
        const rootStore = getRoot(self) as any;
        if (rootStore?.settings?.customer?.profile?.isWhitelabel) {
          notification.error('Quota is exceeded', 'You’ve exceeded the usage limit for this tool.');
        } else {
          const quotaTitle = 'You are trying to use more quota than available.';
          notification.warning(quotaTitle, 'Upgrading to a higher plan will increase the quota limits.', 'View pricing', () => rootStore?.plans?.showSidebarPaymentDrawer());
        }
      } else {
        useErrorNotification({
          e,
          msg: 'Error loading Outlines history.',
          desc: 'Content Ideas history did not load properly. To fix the issue:',
          permanent: false,
          handleStatuses: [{
            statuses: [401, 403],
            msg: 'Please sign up to edit this article.',
            permanent: false,
            showDetails: false,
          }],
        });
      }

      return Promise.reject(e);
    } finally {
      self.aiWritterHistoryLoding = false;
    }
  });

  /**
   * @method loadAiOutlineHistory returns history results for AI Outline tool
   *
   * @param {string} uuid uuid of the current article
   */
  const loadAiOutlineHistory = flow(function* (uuid: string) {
    self.historyLoading = true;
    try {
      const response = yield PAGES_API.getAiOutlineHistory(uuid);
      if (response.isCancel) return;
      self.historyLoading = false;
      self.aiOutlineHistory = response;
    } catch (e) {
      self.loading = false;
      if (e?.response?.status === 429) {
        const rootStore = getRoot(self) as any;
        if (rootStore?.settings?.customer?.profile?.isWhitelabel) {
          notification.error('Quota is exceeded', 'You’ve exceeded the usage limit for this tool.');
        } else {
          const quotaTitle = 'You are trying to use more quota than available.';
          notification.warning(quotaTitle, 'Upgrading to a higher plan will increase the quota limits.', 'View pricing', () => rootStore?.plans?.showSidebarPaymentDrawer());
        }
      } else {
        useErrorNotification({
          e,
          msg: 'Error loading Outlines history.',
          desc: 'Content Ideas history did not load properly. To fix the issue:',
          permanent: false,
          handleStatuses: [{
            statuses: [401, 403],
            msg: 'Please sign up to edit this article.',
            permanent: false,
            showDetails: false,
          }],
        });
      }

      return Promise.reject(e);
    } finally {
      self.loading = false;
    }
  });
  /**
   * @method loadAiOutlineHistoryStandalone returns history results for AI Outline tool
   */
  const loadAiOutlineHistoryStandalone = flow(function* () {
    self.historyLoading = true;
    try {
      const response = yield PAGES_API.getAiOutlineHistoryStandalone();
      if (response.isCancel) return;
      self.historyLoading = false;
      self.aiOutlineHistory = response;
    } catch (e) {
      self.loading = false;
      const {status} = e.response;
      if (status === 429) {
        const rootStore = getRoot(self) as any;
        if (rootStore?.settings?.customer?.profile?.isWhitelabel) {
          notification.error('Quota is exceeded', 'You’ve exceeded the usage limit for this tool.');
        } else {
          const quotaTitle = 'You are trying to use more quota than available.';
          notification.warning(quotaTitle, 'Upgrading to a higher plan will increase the quota limits.', 'View pricing', () => rootStore?.plans?.showSidebarPaymentDrawer());
        }
      } else {
        useErrorNotification({
          e,
          msg: 'Error loading Outlines history.',
          desc: 'Content Ideas history did not load properly. To fix the issue:',
          permanent: false,
          handleStatuses: [{
            statuses: [401, 403],
            msg: 'Please sign up to edit this article.',
            permanent: false,
            showDetails: false,
          }],
        });
      }

      return Promise.reject(e);
    } finally {
      self.loading = false;
    }
  });

  /**
   * @method loadFirstDraftHistory returns history results for AI Outline tool
   *
   * @param {string} uuid uuid of the current article
   */
  const loadFirstDraftHistory = flow(function* (uuid: string) {
    self.historyLoading = true;
    try {
      const response = yield PAGES_API.getFitstDraftHistory(uuid);
      if (response.isCancel) return;
      self.historyLoading = false;
      self.firstDraftHistoryOutlines = response;
    } catch (e) {
      self.historyLoading = false;
      const {status} = e.response;
      if (status === 429) {
        const rootStore = getRoot(self) as any;
        if (rootStore?.settings?.customer?.profile?.isWhitelabel) {
          notification.error('Quota is exceeded', 'You’ve exceeded the usage limit for this tool.');
        } else {
          const quotaTitle = 'You are trying to use more quota than available.';
          notification.warning(quotaTitle, 'Upgrading to a higher plan will increase the quota limits.', 'View pricing', () => rootStore?.plans?.showSidebarPaymentDrawer());
        }
      } else {
        useErrorNotification({
          e,
          msg: 'Error loading First Draft history',
          desc: 'The First Draft history results did not load properly. To fix the issue:',
        });
      }

      return Promise.reject(e);
    } finally {
      self.historyLoading = false;
    }
  });

  /**
   * @method loadFirstDraftHistoryStandalone returns history results for AI Outline tool
   */
  const loadFirstDraftHistoryStandalone = flow(function* () {
    self.historyLoading = true;
    try {
      const response = yield PAGES_API.getFitstDraftHistoryStandalone();
      if (response.isCancel) return;
      self.historyLoading = false;
      self.firstDraftHistoryOutlines = response;
    } catch (e) {
      self.historyLoading = false;
      const {status} = e.response;
      if (status === 429) {
        const rootStore = getRoot(self) as any;
        if (rootStore?.settings?.customer?.profile?.isWhitelabel) {
          notification.error('Quota is exceeded', 'You’ve exceeded the usage limit for this tool.');
        } else {
          const quotaTitle = 'You are trying to use more quota than available.';
          notification.warning(quotaTitle, 'Upgrading to a higher plan will increase the quota limits.', 'View pricing', () => rootStore?.plans?.showSidebarPaymentDrawer());
        }
      } else {
        useErrorNotification({
          e,
          msg: 'Error loading First Draft history',
          desc: 'The First Draft history results did not load properly. To fix the issue:',
        });
      }

      return Promise.reject(e);
    } finally {
      self.historyLoading = false;
    }
  });

  /**
   * @method loadAiOutlineFromTemplate returns results from dynamicaly generated forms
   *
   * @param {string} templateType shows the type of template
   * @param {any[]} inputs list of submited fields
   * @param {string} resultsCounter number of results we want to show
   */
  const loadAiOutlineFromTemplate = flow(function* (templateType: string, inputs: any[], resultsCounter?: string) {
    self.loading = true;
    self.showLessResultsBanner = false;
    try {
      const response = yield PAGES_API.getAiOutlineFromTemplates(templateType, inputs, resultsCounter);
      if (response.isCancel) return;
      self.loading = false;
      self.aiTemplatesController = {
        ...toJS(self.aiTemplatesController),
        lastGeneratedOutput: 'Templates',
      };
      self.tempalteOutlines = response;
      const lessResultsBanenrTemplates = [
        'seo_blog_posts_title_and_meta_descriptions',
        'seo_homepage_title_and_meta_descriptions',
        'seo_product_page_title_and_meta_descriptions',
        'seo_services_pages_title_and_meta_descriptions',
      ];
      if (response.length < resultsCounter && lessResultsBanenrTemplates.includes(templateType)) {
        self.showLessResultsBanner = true;
      }
    } catch (e) {
      self.loading = false;
      const {status} = e.response;
      if (status === 429) {
        const rootStore = getRoot(self) as any;
        if (rootStore?.settings?.customer?.profile?.isWhitelabel) {
          notification.error('Quota is exceeded', 'You’ve exceeded the usage limit for this tool.');
        } else {
          const quotaTitle = 'You are trying to use more quota than available.';
          notification.warning(quotaTitle, 'Upgrading to a higher plan will increase the quota limits.', 'View pricing', () => rootStore?.plans?.showSidebarPaymentDrawer());
        }
      } else {
        useErrorNotification({
          e,
          msg: 'Error loading Content Ideas.',
          desc: 'The Content Ideas data did not load properly. To fix the issue:',
          permanent: false,
          handleStatuses: [{
            statuses: [401, 403],
            msg: 'Please sign up to edit this article.',
            permanent: false,
            showDetails: false,
          }],
        });
      }

      return Promise.reject(e);
    } finally {
      self.loading = false;
    }
  });

  const setDummyImages = (data, isRemove) => {
    self.dummyImages = cast([...(isRemove ? [] : self.dummyImages), ...data]);
  };

  const loadAiGeneratedImage = flow(function* (prompt: string, aspectRatio: string, isRealistic: boolean, returnRandomChoice, imagesEngine) {
    self.loading = true;
    self.showLessResultsBanner = false;
    try {
      const response = yield PAGES_API.getAiGeneratedImage(prompt, aspectRatio, isRealistic, returnRandomChoice, imagesEngine);
      if (response.isCancel) return;
      self.loading = false;

      if (response.length > 0) {
        self.dummyImages = cast(self.dummyImages.filter(img => img.title !== response[0]?.inputs[0]?.fieldValue));
        self.templatesHistory = cast([...response, ...self.templatesHistory]);
      } else {
        notification.error('', 'Failed to AI Generate an image. Please try again.');
      }
    } catch (e) {
      self.loading = false;
      const {status} = e.response;
      if (status === 429) {
        const rootStore = getRoot(self) as any;
        if (rootStore?.settings?.customer?.profile?.isWhitelabel) {
          notification.error('Quota is exceeded', 'You’ve exceeded the usage limit for this tool.');
        } else {
          const quotaTitle = 'You are trying to use more quota than available.';
          notification.warning(quotaTitle, 'Upgrading to a higher plan will increase the quota limits.', 'View pricing', () => rootStore?.plans?.showSidebarPaymentDrawer());
        }
      } else {
        useErrorNotification({
          e,
          msg: 'Image generation taking too long.',
          desc: 'Please refresh the page and try again',
          permanent: false,
          handleStatuses: [{
            statuses: [401, 403],
            msg: 'Please sign up to edit this article.',
            permanent: false,
            showDetails: false,
          }],
        });
      }

      return Promise.reject(e);
    } finally {
      self.loading = false;
    }
  });


  /**
   * @method loadAiTemplateOutlinesHistory returns results from dynamicaly generated forms
   *
   * @param {string} templateType shows the type of template
   */
  const loadAiTemplateOutlinesHistory = flow(function* (templateType: string) {
    self.historyLoading = true;
    const token = getTokenFromCookies();
    try {
      if (token) {
        const response = yield PAGES_API.getAiTemplatesHistory(templateType);
        if (response.isCancel) return;
        self.historyLoading = false;
        self.templatesHistory = cast(response);
      }
    } catch (e) {
      self.historyLoading = false;
      const {status} = e.response;
      if (status === 429) {
        const rootStore = getRoot(self) as any;
        if (rootStore?.settings?.customer?.profile?.isWhitelabel) {
          notification.error('Quota is exceeded', 'You’ve exceeded the usage limit for this tool.');
        } else {
          rootStore?.settings?.customer?.profile?.matchAiConsumedTotalQuota();
          const totalAiQuota = rootStore?.settings?.customer?.profile?.quotaUtilization?.ca?.allowedAiContentGeneration?.total;
          const quotaTitle = totalAiQuota ? `You've consumed all quota points ${totalAiQuota}/${totalAiQuota}.` : `You've consumed all quota points.`;
          notification.warning(quotaTitle, 'Upgrading to a higher plan will increase the quota limits.', 'View pricing', () => rootStore?.plans?.showSidebarPaymentDrawer());
        }
      } else {
        useErrorNotification({
          e,
          msg: 'Error loading Content Ideas',
          desc: 'The Content Ideas data did not load properly. To fix the issue:',
        });
      }

      return Promise.reject(e);
    } finally {
      self.historyLoading = false;
    }
  });

  const updateSingleOutline = (value: boolean, id: number) => {
    const outlines = toJS(self.aiOutline).map((outline, idx) => {
      return {
        ...outline,
        isAdded: id === idx ? value : outline.isAdded,
      };
    });

    self.aiOutline = cast(outlines);
  };

  interface GenerateAiOutlineProps {
    h1: string;
    content: any[];
  }

  interface CompleteSeoArticleProps {
    title?: string;
    content_topic?: string;
    headings?: string[];
    use_table?: boolean;
    use_list?: boolean;
    // enable_literary_devices?: boolean;
    tone_of_voice?: string;
    point_of_view?: string;
    use_global_background?: boolean;
    global_background?: string;
    disable_active_voice?: boolean;
    disable_skinny_paragraph?: boolean;
    use_tease?: boolean;
    use_faq_short_answers?: boolean;
    use_faq_long_answers?: boolean;
    enable_key_takeaways?: boolean;
    length?: string;
    terms_to_include?: string[];
    generate_images?: boolean;
    disable_conclusion?: boolean;
    uuid?: string;
    questions_to_include?: string[];
    links_to_include?: any[];
    should_populate_page_html?: boolean;
    images_additional_context?: string;
  }

  const loadAiOutlineV2 = flow(function* (props: GenerateAiOutlineProps, uuid: string) {
    self.loading = true;
    try {
      const response = yield PAGES_API.getAiOutlineV2(props, uuid);
      if (response.isCancel) return;
      self.loading = false;
      self.v2AiOutline = response;
      self.aiTemplatesController = {
        ...toJS(self.aiTemplatesController),
        lastGeneratedOutput: 'FirstDraft',
      };
    } catch (e) {
      self.loading = false;
      const {status} = e.response;
      if (status === 429) {
        const rootStore = getRoot(self) as any;
        if (rootStore?.settings?.customer?.profile?.isWhitelabel) {
          notification.error('Quota is exceeded', 'You’ve exceeded the usage limit for this tool.');
        } else {
          const quotaTitle = 'You are trying to use more quota than available.';
          notification.warning(quotaTitle, 'Upgrading to a higher plan will increase the quota limits.', 'View pricing', () => rootStore?.plans?.showSidebarPaymentDrawer());
        }
      } else {
        useErrorNotification({
          e,
          msg: 'Error loading Content Ideas.',
          desc: 'The Content Ideas data did not load properly. To fix the issue:',
          permanent: false,
          handleStatuses: [{
            statuses: [401, 403],
            msg: 'Please sign up to edit this article.',
            permanent: false,
            showDetails: false,
          }],
        });
      }

      return Promise.reject(e);
    } finally {
      self.loading = false;
    }
  });
  // eslint-disable-next-line require-yield
  const loadCompleteSeoArticle = flow(function* (props: CompleteSeoArticleProps) {
    self.loading = true;
    try {
      const response = yield PAGES_API.getCompleteSeoArticle(props);
      if (response.isCancel) return;
      self.loading = false;
      self.completeSeoArticleOutline = response;
      if (response?.message) {
        notification.info('', response?.message);
      }
      return response;
    } catch (e) {
      self.loading = false;
      const {status} = e.response;
      if (status === 429) {
        const rootStore = getRoot(self) as any;
        if (rootStore?.settings?.customer?.profile?.isWhitelabel) {
          notification.error('Quota is exceeded', 'You’ve exceeded the usage limit for this tool.');
        } else {
          const quotaTitle = 'You are trying to use more quota than available.';
          notification.warning(quotaTitle, 'Upgrading to a higher plan will increase the quota limits.', 'View pricing', () => rootStore?.plans?.showSidebarPaymentDrawer());
        }
      } else {
        useErrorNotification({
          e,
          msg: 'Error loading Content Ideas.',
          desc: 'The Content Ideas data did not load properly. To fix the issue:',
          permanent: false,
          handleStatuses: [
            {
              statuses: [401, 403],
              msg: 'Please sign up to edit this article.',
              permanent: false,
              showDetails: false,
            },
            {
              statuses: [400],
              msg: apiError(e) as string,
              ...(e.response?.data?.nonFieldErrors && {desc: e.response?.data?.nonFieldErrors.join('\n\n')}),
              showDetails: false,
            },
          ],
        });
      }

      return e;
    } finally {
      self.loading = false;
    }
  });

  const loadAiOutlineV2Standalone = flow(function* (props: GenerateAiOutlineProps) {
    self.loading = true;
    try {
      const response = yield PAGES_API.getAiOutlineV2Standalone(props);
      if (response.isCancel) return;
      self.loading = false;
      self.v2AiOutline = response;
      self.aiTemplatesController = {
        ...toJS(self.aiTemplatesController),
        lastGeneratedOutput: 'FirstDraft',
      };
    } catch (e) {
      self.loading = false;
      const {status} = e.response;
      if (status === 429) {
        const rootStore = getRoot(self) as any;
        if (rootStore?.settings?.customer?.profile?.isWhitelabel) {
          notification.error('Quota is exceeded', 'You’ve exceeded the usage limit for this tool.');
        } else {
          const quotaTitle = 'You are trying to use more quota than available.';
          notification.warning(quotaTitle, 'Upgrading to a higher plan will increase the quota limits.', 'View pricing', () => rootStore?.plans?.showSidebarPaymentDrawer());
        }
      } else {
        useErrorNotification({
          e,
          msg: 'Error loading Content Ideas.',
          desc: 'The Content Ideas data did not load properly. To fix the issue:',
          permanent: false,
          handleStatuses: [{
            statuses: [401, 403],
            msg: 'Please sign up to edit this article.',
            permanent: false,
            showDetails: false,
          }],
        });
      }

      return Promise.reject(e);
    } finally {
      self.loading = false;
    }
  });


  const getHeadingSuggestions = flow(function* (uuid: string) {
    self.headingSuggestionsLoading = true;
    try {
      const response = yield PAGES_API.getHeadingSuggestions(uuid);
      if (response.isCancel) return;

      self.headingSuggestions = cast(response);
    } catch (e) {
      const {status} = e.response;
      if (status === 429) {
        const rootStore = getRoot(self) as any;
        if (rootStore?.settings?.customer?.profile?.isWhitelabel) {
          notification.error('Quota is exceeded', 'You’ve exceeded the usage limit for this tool.');
        } else {
          const quotaTitle = 'You are trying to use more quota than available.';
          notification.warning(quotaTitle, 'Upgrading to a higher plan will increase the quota limits.', 'View pricing', () => rootStore?.plans?.showSidebarPaymentDrawer());
        }
      } else {
        useErrorNotification({
          e,
          msg: 'Error loading heading suggestions.',
          desc: 'Heading suggestionss data did not load properly. To fix the issue:',
          permanent: false,
          handleStatuses: [{
            statuses: [401, 403],
            msg: 'Please sign up to edit this article.',
            permanent: false,
            showDetails: false,
          }],
        });
      }
      return Promise.reject(e);
    } finally {
      self.headingSuggestionsLoading = false;
    }
  });
  const getUserAiSettings = flow(function* () {
    if (isScaPublicPage()) return;
    self.loadingUserAiSettings = true;
    try {
      const response = yield PAGES_API.getUserAiSettings();
      if (response.isCancel) return;

      self.userAiSettings = cast(response);
    } catch (e) {
      useErrorNotification({
        e,
        msg: 'Error loading user AI settings',
        desc: 'To fix the issue:',
      });
      return Promise.reject(e);
    } finally {
      self.loadingUserAiSettings = false;
    }
  });

  interface Props {
    language?: string;
    toneOfVoice?: string;
    typeOfContent?: string;
    pointOfView?: string;
  }

  const updateUserAiSettings = flow(function* ({language, toneOfVoice, pointOfView}: Props) {
    self.loadingUserAiSettings = true;
    try {
      const response = yield PAGES_API.updateUserAiSettings({language, toneOfVoice, pointOfView});
      if (response.isCancel) return;

      self.userAiSettings = cast(response);
    } catch (e) {
      useErrorNotification({
        e,
        msg: 'Error updating user AI settings',
        desc: 'To fix the issue:',
      });
      return Promise.reject(e);
    } finally {
      self.loadingUserAiSettings = false;
    }
  });

  interface TopUpProps {
    units?: number;
  }

  const addTopUpQuote = flow(function* ({units}: TopUpProps) {
    self.loadingAiQuota = true;
    try {
      const response = yield PAGES_API.addTopUpQuote({units});
      if (response.isCancel) return;
      if (response.details) {
        notification.error('AI Premium Quota', response?.details?.length ? response.details : 'Failed to purchase AI Quota');
      } else notification.success('AI Premium Quota', 'AI Quota purchased successfully');
    } catch (e) {
      useErrorNotification({
        e,
        msg: apiError(e) as string,
      });
      return Promise.reject(e);
    } finally {
      self.loadingAiQuota = false;
    }
  });

  const rewriteOutline = flow(function* (value: any, outlineIndex: number) {
    self.rewritingContent = true;
    self.rewritingId = outlineIndex;
    try {
      const payload = {
        keyword: value,
        type: 'rewrite_content',
      };
      const response = yield contentReWritingApi.getContentRewritingResponse(payload);
      if (response?.isCancel) return;
      if (response?.results?.length && self.aiOutline?.length) {
        const existingOutlines = toJS(self.aiOutline);

        const updatedOutlines = existingOutlines?.map((outline, idx) => {
          if (outlineIndex == idx) {
            return {
              ...outline,
              paragraph: response?.results[0] ?? outline.paragraph,
            };
          } else {
            return outline;
          }
        });

        if (updatedOutlines) self.aiOutline = cast(updatedOutlines);
      }
    } catch (e) {
      useErrorNotification({
        e,
        msg: 'Error revriting outline',
        desc: 'To fix the issue:',
      });
      return Promise.reject(e);
    } finally {
      self.rewritingContent = false;
      self.rewritingId = null;
    }
  });

  const clearAiOutline = () => {
    self.aiOutline.length = 0;
  };
  const clearOneClickOutlines = () => {
    if (self.oneClickOutline) self.oneClickOutline.length = 0;
  };
  const clearV2AiOutline = () => {
    if (self.v2AiOutline) {
      self.v2AiOutline.length = 0;
    }
  };
  const clearTemplateOutlines = () => {
    if (self.tempalteOutlines) self.tempalteOutlines.length = 0;
  };

  const setIsOpenAiContentToolsDrawer = (value: boolean) => self.isOpenAiContentToolsDrawer = value;
  const setIsOpenAiWriterDrawer = (value: boolean) => self.isOpenAiWriterDrawer = value;
  const setIsOpenAiOutlineDrawer = (value: boolean) => self.isOpenAiOutlineDrawer = value;
  const setShowOutlinesInDrawer = (value: boolean) => self.showOutlinesInDrawer = value;
  const setImageGenerateInputValue = (value: string) => self.imageGenerateInputValue = value;
  const setAiArticleAlreadyReplaced = (value: boolean) => self.aiArticleAlreadyReplaced = value;
  const setCreatingAiArticleSuccessStatus = (value: boolean) => self.creatingAiArticleSuccessStatus = value;
  const setCreatingAiArticleFailureStatus = (value: boolean) => self.creatingAiArticleFailureStatus = value;
  const setDisabledArticleId = (value: number) => self.disabledArticleId = value;
  const clearUserAiSettings = () => self.userAiSettings = null;
  const clearTemplates = () => {
    // if (self.aiTemplates?.templates) self.aiTemplates.templates.length = 0;
    // if (self.aiTemplates?.categories) self.aiTemplates.categories.length = 0;
  };

  const setLastUsedHistoryTemplate = (templateType: string) => self.lastUsedHistoryTemplate = templateType;
  const clearOneClickIdea = () => self.oneClickContentIdea = null;

  return {
    loadAiOutline,
    clearAiOutline,
    setIsOpenAiOutlineDrawer,
    setImageGenerateInputValue,
    loadAiOutlineV2,
    clearV2AiOutline,
    setShowOutlinesInDrawer,
    updateSingleOutline,
    getHeadingSuggestions,
    setIsOpenAiContentToolsDrawer,
    setAiArticleAlreadyReplaced,
    setCreatingAiArticleSuccessStatus,
    setCreatingAiArticleFailureStatus,
    setDisabledArticleId,
    getUserAiSettings,
    clearUserAiSettings,
    updateUserAiSettings,
    addTopUpQuote,
    rewriteOutline,
    getAiTemplatesList,
    setAiTemplate,
    setTopUpVisibility,
    setTopUpBulkVisibility,
    setShowRemainingTerms,
    setShouldOverwriteContent,
    setShouldOverwriteContentSuccessMessage,
    setCopyStatus,
    setAiWriter,
    setOpenUpdateProjectModal,
    clearTemplates,
    loadAiOutlineFromTemplate,
    clearTemplateOutlines,
    loadAiTemplateOutlinesHistory,
    setLastUsedHistoryTemplate,
    loadAiOutlineHistory,
    loadFirstDraftHistory,
    loadFirstDraftHistoryStandalone,
    loadAiOutlineHistoryStandalone,
    loadAiOutlineStandalone,
    loadAiOutlineV2Standalone,
    setIsOpenAiWriterDrawer,
    loadOneClickDraft,
    setIsShowWpSync,
    refetchOneClickDraftResult,
    refetchOneClickDraftResultForArticleCreation,
    clearOneClickIdea,
    clearOneClickOutlines,
    loadCompleteSeoArticle,
    loadAiWriterHistory,
    getAiGeneratedContentStatusData,
    loadAiGeneratedImage,
    setDummyImages,
    setShowDesc,
    setIsEdit,
    setBulkAiTopup,
  };
});

export const initAiOutline = () => {
  return AiOutline.create({
    showDesc: true,
    bulkAiTopup: false,
    loading: false,
    imageGenerateInputValue: '',
    aiArticleAlreadyReplaced: false,
    topUpVisibility: false,
    topUpBulkVisibility: false,
    disabledArticleId: -1,
    isHTMLLength: true,
    creatingAiArticleSuccessStatus: false,
    creatingAiArticleFailureStatus: false,
    showRemainingTerms: false,
    shouldOverwriteContent: true,
    shouldOverwriteContentSuccessMessage: true,
    copyStatus: 'Copy',
    loadingStatus: '',
    historyLoading: false,
    aiWritterHistoryLoding: false,
    headingSuggestionsLoading: false,
    isOpenAiOutlineDrawer: false,
    isOpenAiWriterDrawer: false,
    isOpenAiContentToolsDrawer: false,
    aiOutline: [],
    firstDraftHistoryOutlines: [],
    oneClickContentIdea: null,
    aiOutlineHistory: [],
    aiWritterHistory: [],
    showOutlinesInDrawer: false,
    userAiSettings: null,
    loadingUserAiSettings: false,
    loadingAiQuota: false,
    rewritingContent: false,
    rewritingId: null,
    aiTemplatesController: {
      templateType: '',
      templateIcon: '',
      templateName: '',
      templateDesc: '',
      showForm: false,
      fieldsSetup: [],
      defaultForm: false,
      defaultFormType: null,
      isStandaloneTool: false,
      isUseMask: false,
    },
    aiWriterController: {
      showForm: false,
      templateUsed: '1-click-draft',
      templateIcon: 'pen-icon-color.svg',
      templateName: '',
      templateDesc: '',
      isStandaloneTool: false,
    },
    loadingTemplates: false,
    showLessResultsBanner: false,
    aiTemplates: {
      templates: [],
      categories: [],
    },
    lastUsedHistoryTemplate: '',
    isEdit: false,
  });
};

export type AiOutlineType = Instance<typeof AiOutline>;
export type SingleAiOutlineType = Instance<typeof AiOutlineModel>;

