import {Tooltip} from 'antd';
import styled from 'styled-components';
import styles from './style.module.scss';
import classNames from 'classnames';
import {faSparkles} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export const LargeBadge = ({alpha, beta, isNew, style = {}, selected, light}: {alpha?: boolean; beta?: boolean; isNew?: boolean; style?: any; selected?: boolean; light?: boolean}) =>{
  return (
    <LargePill className={classNames({[styles.newCard]: isNew, [styles.cardSelected]: isNew && selected, [styles.light]: isNew && light})} style={{...style}}>
      <div className={classNames({[styles.cardInner]: isNew, [styles.cardInnerSelected]: isNew && selected}, 'cardInner')}>
        {isNew && <FontAwesomeIcon width={9} height={9} color='#2D6CCA' className={classNames(styles.icon, 'icon')} icon={faSparkles}/>}
        {alpha ? 'alpha' : beta ? 'beta' : isNew ? 'new': 'alpha'}
      </div>
    </LargePill>
  );
};

export const SmallBadge = ({alpha, beta, isNew, isLocalSeo, customStyle, selected, isSmall}: {alpha?: boolean; beta?: boolean; isNew?: boolean; isLocalSeo?: boolean; customStyle?: any;selected?: boolean; isSmall?:boolean}) =>{
  return (
    <SmallPill className={classNames({[styles.newCard]: isNew, [styles.cardSelected]: isNew && selected, [styles.smallBadge]: isSmall})} isLocalSeo={isLocalSeo} style={{...customStyle}} >
      <div className={classNames({[styles.cardInner]: isNew, [styles.cardInnerSelected]: isNew && selected}, 'cardInner')}>
        {isNew && !isSmall && <FontAwesomeIcon width={9} height={9} color='#fff' className={styles.icon} icon={faSparkles}/>}
        {alpha ? 'alpha' : beta ? 'beta' : isNew ? 'new' : 'alpha'}
      </div>
    </SmallPill>
  );
};


export const BadgePill = ({alpha, beta}: {alpha?: boolean; beta?: boolean}) =>{
  return (
    <BadgePillDefault>
      {alpha ? 'alpha' : beta ? 'beta' : 'alpha'}
    </BadgePillDefault>
  );
};


export const PurchasedQuota = ({total, purchasedQuotaTooltipTxt}: {total?: number | string; purchasedQuotaTooltipTxt?: string}) => {
  if (total) {
    return (
      <Tooltip
        title={purchasedQuotaTooltipTxt || 'Remaining Purchased AI Premium Quota. These points will be utilized after the base quota allowance is completely exhausted.'}
        overlayInnerStyle={{
          maxWidth: 300,
          padding: '10px',
          background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), #2D2F34',
          boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.08)',
          borderRadius: 8,
        }}
        placement='bottom'
      >
        <GreenPill>
          {total}
        </GreenPill>
      </Tooltip>
    );
  }
};

const LargePill = styled.div`
  align-items: center;
  background-color: #4a4c51;
  border-radius: 50px;
  color: #fff;
  display: flex;
  font-size: 11px;
  height: 19px;
  margin-left: 7px;
  padding: 0 9px;
  width: max-content;
  text-transform: capitalize;
`;

const SmallPill = styled.div<{isLocalSeo?: boolean}>`
  border-radius: 34px;
  color: #fff;
  font-weight: 500;
  margin-left: 3px;
  background: #4E5156;
  padding: 0px 4px;
  font-size: 10px;
  position: relative;
  text-transform: capitalize;
  height: fit-content;
`;

const BadgePillDefault = styled.div`
  border-radius: 34px;
  color: #fff;
  font-weight: 500;
  line-height: 15px;
  margin-left: 1px;
  background: #4E5156;
  padding: 0px 1px;
  font-size: 9px;
  text-transform: capitalize;
`;

const GreenPill = styled.div`
  background: #2ac15521;
  border-radius: 21px;
  font-size: 12px;
  line-height: 22px;
  padding: 0px 5px;
  color: #2AC155;
  margin-left: 5px;
  cursor: pointer;
`;
