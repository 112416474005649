export function getGoogleConnectUrl() {
  return process.env.GOOGLE_CONNECT_URL;
}

export function getGoogleAnalyticsConnectUrl() {
  return process.env.GOOGLE_ANALYTICS_CONNECT_URL;
}

export function getGoogleMyBusinessConnectUrl() {
  return process.env.GOOGLE_MY_BUSINESS_CONNECT_URL;
}
export function getSaUrl() {
  return process.env.SA_URL;
}
export function getAffiliatesUrl() {
  return process.env.AFFILIATES_URL;
}
export function getHelpSaUrl() {
  return process.env.HELP_SA_URL;
}
export function getStatusSaUrl() {
  return process.env.STATUS_SA_URL;
}
