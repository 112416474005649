import React, {useEffect, useState} from 'react';
import {Form, Input, Select} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import PhoneInput from 'react-phone-input-2';
import styled from 'styled-components';
import {Button} from '@/components/common-components/v2';
import {faCaretDown, faCheck} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import ImagePicker from './imagePicker';
import {LocationSelect} from '../../../keyword-rank-tracker/add-new-website/location-select';

const {Option} = Select;
interface OttoKnowledgeGraphFieldsProps {
  placeholder?: string;
  defaultValue?: any;
  fieldType?: string;
  name?: string;
  options?: any;
  form?: any;
  serpLocation?: string;
  setSerpLocation?: (value) => void;
  setSerpLocationId?: (value) => void;
  style?: React.CSSProperties;
  setLanguageCode?: any;
  languageCode?: any;
  dropDownBottomValue?: number;
  disabled?:boolean;
  passedValue?: string | null;
  markEmptyFields?: boolean;
  showFieldsCompletion?: boolean;
}
const OttoKnowledgeGraphFields = ({placeholder, defaultValue, fieldType, name, options, form, serpLocation, setSerpLocation, setSerpLocationId, style, setLanguageCode, languageCode, dropDownBottomValue, disabled=false, passedValue=null, markEmptyFields, showFieldsCompletion}: OttoKnowledgeGraphFieldsProps) => {
  const stateValuesObj = {'language_code': languageCode === null ? true : languageCode, 'geolocation-search': serpLocation};
  const stateValue = stateValuesObj[fieldType] || defaultValue;

  const [locationError, setLocationError] = useState<boolean>(false);
  const [showCompleteIcon, setShowCompleteIcon] = useState<boolean>(Boolean(markEmptyFields || stateValue));
  const onLocationChange = value => {
    setSerpLocation(value.value);
    onInputChange(value.value);
    if (value.value) locationError && setLocationError(false);
  };

  const sanitizeValue = value => {
    if (fieldType === 'phone') {
      if (value === '+') return '';
    }
    return value;
  };

  const getSuffixIcon = (style = {}) => <FontAwesomeIcon icon={faCheck} color='rgb(31, 172, 71)' style={{...style, display: (showCompleteIcon && showFieldsCompletion) ? '' : 'none'}} />;

  const onInputChange = value => {
    const isEmptyLanguageCode = fieldType === 'language_code' && value === null;
    setShowCompleteIcon((markEmptyFields || isEmptyLanguageCode) ? true : !!value);
  };

  useEffect(() => {
    const formValue = name ? form?.getFieldValue(name) : null;
    const sanitizedValue = sanitizeValue(formValue);
    setShowCompleteIcon(markEmptyFields ? true : Boolean(stateValuesObj[fieldType] || sanitizedValue));
  }, [markEmptyFields]);

  const fields = (placeholder, defaultValue, fieldType, fieldName, options, form, style, setLanguageCode, languageCode, disabled, passedValue) => {
    const fieldsObj = {
      'text': <StyledInput placeholder={placeholder} defaultValue={defaultValue} style={style ? style : {}} spellCheck suffix={getSuffixIcon()} onChange={e => onInputChange(e?.target?.value)} />,
      'text_area': (
        <div style={{position: 'relative'}}>
          <StyledTextarea defaultValue={defaultValue} className='text-area' placeholder={placeholder} style={style ? style : {}} spellCheck onChange={e => onInputChange(e?.target?.value)}/>
          {getSuffixIcon({position: 'absolute', top: '45%', right: '12px'})}
        </div>
      ),
      'number': <StyledInput placeholder={placeholder} defaultValue={defaultValue} type='number' min={fieldName === 'number_of_employees' ? 0 : null} style={style ? style : {}} suffix={getSuffixIcon()} onChange={e => onInputChange(e?.target?.value)}/>,
      'phone': (
        <div style={{position: 'relative'}}>
          <StyledPhoneInput inputClass='support-phone-input' country='us' value={defaultValue} width={style?.width ? style.width : ''} onChange={val => onInputChange(val)} />
          {getSuffixIcon({position: 'absolute', top: '35%', right: '12px'})}
        </div>
      ),
      'email': <Input defaultValue={defaultValue} className='email-input' placeholder={placeholder} type='email' style={style ? style : {}} suffix={getSuffixIcon()} onChange={e => onInputChange(e?.target?.value)}/>,
      'url': <Input defaultValue={defaultValue} className='email-input' placeholder={placeholder} style={style ? style : {}} disabled={disabled} {...(passedValue && {value: passedValue})} suffix={getSuffixIcon()} onChange={e => onInputChange(e?.target?.value)}/>,
      'dropdown': <StyledSelect
        onChange={value => {
          const obj = {};
          obj[fieldName] = value;
          form.setFieldsValue(obj);
          onInputChange(value);
        }}
        getPopupContainer={trigger => trigger.parentNode}
        defaultValue={defaultValue}
        suffixIcon={(
          <div style={{display: 'flex', alignItems: 'center', gap: 4}}>
            {getSuffixIcon()}
            <FontAwesomeIcon icon={faCaretDown} color='#000000' />
          </div>
        )}
        placeholder={placeholder}
        showSearch
        filterOption={(inputValue, option) =>
          option?.children?.toLowerCase()?.includes(inputValue?.toLowerCase())
        }
      >
        {options?.map((item, index) => (
          <Option key={index} value={item?.value}>
            {item?.label}
          </Option>
        ))}
      </StyledSelect>,
      'geolocation-search': <LocationSelect
        variant='grey'
        placeholderTextColorChange={true}
        width={'100%'}
        height='44px'
        dropDownBottomValue={dropDownBottomValue}
        getPopupContainer={trigger => trigger.parentNode}
        borderRadius='5px'
        style={{border: locationError ? '1px solid red' : ''}}
        marginLeft='0px'
        suffixIcon={(
          <div style={{display: 'flex', alignItems: 'center', gap: 4}}>
            {getSuffixIcon()}
            <FontAwesomeIcon color='#000' width={20} icon={faCaretDown}/>
          </div>
        )}
        showSearch
        isKrtLocationSelect
        value={serpLocation}
        onChange={onLocationChange}
        excludeWorldwide
        setSerpLocationId={setSerpLocationId}
        setSelectedCountryCode={() => ('')}
        isKnowledgeGraph={!showCompleteIcon}
      />,
      'language_code': <StyledSelect
        onChange={value => {
          setLanguageCode(value);
          onInputChange(value);
        }}
        defaultValue={defaultValue}
        value={languageCode}
        suffixIcon={(
          <div style={{display: 'flex', alignItems: 'center', gap: 4}}>
            {getSuffixIcon()}
            <FontAwesomeIcon icon={faCaretDown} color='#000000' />
          </div>
        )}
        placeholder={placeholder}
        getPopupContainer={trigger => trigger.parentNode}
        showSearch
        filterOption={(inputValue, option) =>
          option?.children?.toLowerCase()?.includes(inputValue?.toLowerCase())
        }
      >
        {options?.map((item, index) => (
          <Option key={index} value={item?.value}>
            {item?.label}
          </Option>
        ))}
      </StyledSelect>,
    };
    return fieldsObj[fieldType];
  };

  return fields(placeholder, defaultValue, fieldType, name, options, form, style, setLanguageCode, languageCode, disabled, passedValue);
};

export default OttoKnowledgeGraphFields;


export const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0px !important;
  .ant-form-item-explain-error {
    margin-left: 25%;
    margin-top: -8px;
    font-size: 12px;
  }
`;

const StyledPhoneInput = styled(PhoneInput)<{width?: string}>`
  .form-control {
    width: ${p => p?.width ? p.width : ''};
  }
  .country-list {
    max-height: 145px !important;
  }
`;

export const StyledButton = styled(Button)`
  border-radius: 8px !important;
`;

const StyledInput = styled(Input)`
  border-radius: 8px;

`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 8px !important;
  }
  .ant-select-dropdown{
    top:32px !important;
  }
`;

const StyledTextarea = styled(TextArea)`
  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(138,138,138,0.32);
  }

  &::-webkit-scrollbar-track {
    background: rgba(138,138,138,0.12);
  }
`;
