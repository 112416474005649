export enum COLORS {
  purple = '#7f4ead',
  white = '#fff',
  black = '#121212',
  green = '#1FAC47',
  paleGreen = '#2f779f',
  red = '#F44343',
  gray = '#A3A4A4',
  grayLight = '#3c3e42',
  grayDark = '#2d2f34',
  tableGray = '#F9F9FB',
  textGrayLight = '#E8E8E8',
  textGrayDark = '#4E5156',
  yellow ='#F1AA3E',
  blue = '#2D6CCA',
  neonBlue = '#34aef3',
  pink = 'rgba(244, 67, 67, 0.15)',
  grayHeader='#24262a',
  orange='#FF8536',
  lightGray = '#4E51561A',
}
