import {observer} from 'mobx-react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faUpload,
  faDownload,
} from '@fortawesome/pro-solid-svg-icons';
import {BodyWrapper, Subject, Body, ThreadFromWrapper, ThreadWrapper, ThreadContainer} from '../styledComponents';
import {useStore} from '@/store/root-store';
import {formatDateWithTimeZone} from '@/utils/moment';
import {Skeleton} from 'antd';
import EditorComponentV2 from '@/components/common-components/components/outreach/editor/templateEditor';
import {useState} from 'react';
import {removeDefaultMarginsFromEmailBody} from '@/utils/string';

export const ThreadSection = observer(() => {
  const {inbox: {conversationOpportunityData, opConversationLoading, replyInThread, replyInThreadLoading}, settings: {customer: {getCustomerQuota}}} = useStore('');
  const today = new Date();
  const emailId = conversationOpportunityData?.id;
  const [editorState, setEditorState] = useState('');

  const onEditorChange = value => {
    setEditorState(value);
  };

  const onBtnClick = async () => {
    const payload = {
      body: removeDefaultMarginsFromEmailBody(editorState),
    };
    await replyInThread(emailId, payload);
    await getCustomerQuota();
    setEditorState('');
  };

  return <div style={{paddingBottom: '25px'}}>
    <ThreadContainer>
      {opConversationLoading ? <>
        <div style={{padding: '25px'}}><Skeleton active /></div>
        <div style={{padding: '25px'}}><Skeleton active /></div>
        <div style={{padding: '25px'}}><Skeleton active /></div>
      </> :
        <>
          {conversationOpportunityData?.thread?.map((item, idx) => <ThreadWrapper key={idx} padding='25px 17px 0 0'>
            <ThreadFromWrapper>
              {conversationOpportunityData?.emailSender == item?.from ?
                <FontAwesomeIcon icon={faUpload} color='#A3A4A4' style={{paddingBottom: '2px'}}/> :
                <FontAwesomeIcon icon={faDownload} color='#34AEF3' style={{paddingBottom: '2px'}}/>}
              <div style={{color: '#4E5156'}}>From:</div>
              <div style={{color: '#121212', fontWeight: '500'}}>{item?.from}</div>
              <div style={{color: '#4E5156'}}>{formatDateWithTimeZone(item?.date)}</div>
            </ThreadFromWrapper>
            <BodyWrapper sender={conversationOpportunityData?.emailSender == item?.from}>
              {item?.subject ? <Subject>Subject: {item?.subject}</Subject> : ''}
              &nbsp;
              <Body dangerouslySetInnerHTML={{__html: item?.body}} />
            </BodyWrapper>
          </ThreadWrapper>)}
          <ThreadWrapper padding='25px 0 0 0'>
            <ThreadFromWrapper>
              <FontAwesomeIcon icon={faUpload} color='#A3A4A4' style={{paddingBottom: '2px'}}/>
              <div style={{color: '#4E5156'}}>From:</div>
              <div style={{color: '#121212', fontWeight: '500'}}>{conversationOpportunityData?.emailSender}</div>
              <div style={{color: '#4E5156'}}>{formatDateWithTimeZone(today)}</div>
            </ThreadFromWrapper>
            <div style={{marginTop: '13px', paddingRight: '17px'}}>
              <EditorComponentV2 loading={replyInThreadLoading} value={editorState} onChange={onEditorChange} style={{width: '100%', height: '150px'}} showButton onBtnClick={onBtnClick} />
            </div>
          </ThreadWrapper>
        </>
      }
    </ThreadContainer>
  </div>;
});
