import {faCalendarCheck, faPage, faXmark} from '@fortawesome/pro-regular-svg-icons';
import {faCheck, faHourglassStart} from '@fortawesome/pro-solid-svg-icons';
import {faCalendars, faImage, faSquareCheck, faPlay, faStarHalfStroke} from '@fortawesome/pro-duotone-svg-icons';
import React from 'react';

export type ButtonOptions = 'frequencyOption' | 'startDateOption' | 'imageOption' | 'approvalOption' | 'publishOptions';
interface Option {
  label: string;
  value: string;
  description?: string;
  custom?: boolean;
  showHeaderButton?: boolean;
  tooltipMessage?: string;
  disabled?: boolean;
  showTooltip?: boolean;
}

export interface OptionsConfig {
  label: string;
  icon: any;
  options: Option[];
  customComponent?: React.ReactNode;
  extraHeaderButton?: React.ReactNode;
}
// constants
export const hideOriginalTitle = ['page_title', 'nlp_terms', 'nlp_faq', 'nlp_schema', 'nlp_faq_schema', 'links', 'images', 'missing_keywords', 'internal_link_suggestions', 'gbp_posts', 'qanda_suggestions', 'missing_headings', 'organization_schema', 'custom_content'];
export const hideUrl = ['images', 'internal_link_suggestions', 'gbp_posts', 'qanda_suggestions', 'organization_schema'];
export const hideProposed = ['internal_link_suggestions', 'gbp_posts', 'qanda_suggestions', 'nlp_terms', 'nlp_faq', 'missing_keywords', 'custom_content'];
export const showTarget = ['internal_link_suggestions'];
export const showSourcePage = ['internal_link_suggestions'];
export const showGBPPostsColumn = ['gbp_posts'];
export const showTrashColumn = ['qanda_suggestions'];
export const showGBPQuestionAnswersColumn = ['qanda_suggestions'];
export const hideStatusColumn = ['internal_link_suggestions', 'missing_keywords', 'custom_content'];
export const showTermsColumn = ['nlp_terms', 'nlp_faq'];
export const showKeywordsToAddColumn = ['missing_keywords'];

export const possibleToAiGenerate = ['og_title', 'twitter_title', 'meta_description', 'og_description', 'twitter_description', 'uniqueness', 'meta_keywords', 'page_title', 'missing_headings', 'h1_under_20_over_70', 'h2_under_20_over_70', 'qanda_suggestions', 'unanswered_reviews'];
export const showToolTipTracker = ['og_title', 'twitter_title', 'meta_description', 'og_description', 'twitter_description', 'uniqueness', 'meta_keywords', 'page_title', 'missing_headings', 'h1_under_20_over_70', 'h2_under_20_over_70', 'qanda_suggestions', 'unanswered_reviews', 'nlp_faq', 'ai_landing_page_builder'];
export const recordsWithObjects = ['nlp_schema', 'nlp_faq_schema', 'missing_keywords', 'internal_link_suggestions', 'nlp_faq_schema', 'organization_schema'];

export const gbpList = ['gbp_posts', 'qanda_suggestions', 'unanswered_reviews'];
// const twitterCardList = ['twitter_card', 'twitter_site_property', 'twitter_title', 'twitter_description'];

export const showAddButton = ['gbp_posts', 'nlp_terms', 'nlp_faq', 'topical_maps_and_supplemental_content', 'dynamic_indexing'];
export const showRefreshButton = ['gbp_posts', 'unanswered_reviews', 'qanda_suggestions'];
export const businessAddress = ['gbp_posts', 'unanswered_reviews', 'qanda_suggestions', 'aggregator_network', 'profile_optimizations'];
export const isGBPFilters = ['gbp_posts', 'organization_schema', 'cloud_stacks', 'topical_maps_and_supplemental_content', 'press_release_distribution', 'dynamic_indexing', 'sitemap_indexing', 'custom_url_based_indexing', 'ai_landing_page_builder'];
export const isGBPPostFilters = ['gbp_posts'];
export const gbpPostTypes = [
  {
    label: 'All',
    key: 'ALL',
  },
  {
    label: 'Update',
    key: 'UPDATE',
  },
  {
    label: 'Event',
    key: 'EVENT',
  },
  {
    label: 'Offer',
    key: 'OFFER',
  },
];
export const gbpPostStatus = {
  'All': 'All',
  'Draft': 'Draft',
  'Scheduled': 'Scheduled',
  'Published': 'Published',
};
export const showAddButtonLabel = {
  'gbp_posts': 'Add Bulk Posts',
  'internal_link_suggestions': 'Add Target Page',
  'topical_maps_and_supplemental_content': 'Create Topical Map',
  'dynamic_indexing': 'Add Page',
  'gsc_instant_indexing': 'Add Service Account',
};
export const gbpDatesList = [
  {
    label: 'Clear all',
    key: 'clearAll',
  },
  {
    label: 'Created',
    key: 'created',
    children: [{label: 'Start date', key: 'startDate', parent: 'created'}, {label: 'End date', key: 'endDate', parent: 'created'}],
  },
  {
    label: 'Scheduled',
    key: 'scheduled',
    children: [{label: 'Start date', key: 'startDate', parent: 'scheduled'}, {label: 'End date', key: 'endDate', parent: 'scheduled'}],
  },
  {
    label: 'Published',
    key: 'published',
    children: [{label: 'Start date', key: 'startDate', parent: 'published'}, {label: 'End date', key: 'endDate', parent: 'published'}],
  },
  {
    label: 'Unpublished',
    key: 'unpublished',
    children: [{label: 'Start date', key: 'startDate', parent: 'unpublished'}, {label: 'End date', key: 'endDate', parent: 'unpublished'}],
  },
  {
    label: 'Updated',
    key: 'updated',
    children: [{label: 'Start date', key: 'startDate', parent: 'updated'}, {label: 'End date', key: 'endDate', parent: 'updated'}],
  },
  {
    label: 'Rejected',
    key: 'rejected',
    children: [{label: 'Start date', key: 'startDate', parent: 'rejected'}, {label: 'End date', key: 'endDate', parent: 'rejected'}],
  },
];
export const showTargetKeywordsColumn = ['nlp_terms', 'nlp_schema', 'nlp_faq', 'nlp_faq_schema'];
export const showTablePagination = ['topical_maps_and_supplemental_content'];
export const showHtmlColumn = ['custom_content'];
export const showSchemaColumn = ['nlp_terms', 'nlp_faq'];
export const showCurrentTitleColumn = ['page_title'];
export const termQAError = {
  'question': {
    message: 'Question is required.',
    state: false,
  },
  'answer': {
    message: 'Answer is required.',
    state: false,
  },
};

export const refreshKeyMapping = {
  'gbp_posts': 'posts',
  'unanswered_reviews': 'reviews',
  'qanda_suggestions': 'questions',
};

export const getIssueCategoriesTooltipText = (keyword: string) => {
  return {
    'page_title': `${keyword} optimizes your title tags for search engines, ensuring they accurately reflect your page content and improve your rankings`,
    'meta_description': 'Generates compelling and SEO Optimized meta descriptions that boost click-through rates and provide a summary of your page’s content.',
    'images': 'Detects and adds descriptive alt text to images, improving accessibility and search engine optimization.',
    'links': 'Identifies and fixes broken or problematic links to enhance user experience and maintain site integrity.',
    'meta_keywords': 'Adds or optimizes meta keywords to align with your content, improving search engine ranking.',
    'missing_headings': 'OTTO identifies missing headings on your site, helping you structure your content effectively for search engines and enhancing user experience.',
    'headings_length': 'OTTO evaluates your headings’ length, ensuring they’re concise yet descriptive, optimizing both search visibility and content clarity.',
    'headings': 'Structures your content with proper headings to enhance readability and SEO performance.',
    'canonical_link': 'Automatically implements canonical links to prevent duplicate content issues and consolidate page authority.',
    'og_meta': 'Optimizes Open Graph tags to ensure your content is shared correctly and attractively on social media.',
    'twitter_meta': 'Uses Twitter Card tags to make your content stand out when shared on Twitter.',
    'misc': 'Addresses a variety of technical issues to improve overall site performance.',
    'missing_keywords': 'Our tool identifies missing keywords and integrates them seamlessly to enhance content relevance and search rankings.',
    'internal_links': 'Suggests internal linking opportunities to improve site navigation and SEO.',
    'semantic_analysis': 'Enhances content through semantic analysis and knowledge graph optimization for better search visibility.',
    'knowledge_based_trust': 'Enhances content credibility through knowledge-based trust and factual enhancement for better search visibility.',
    'topical_maps_and_supplemental_content': 'Generates topical maps and supplemental content to broaden your site’s relevance and authority.',
    'custom_content': 'Inject Custom HTML to the Header or Body of your pages.',
    'schema_markup': 'Automatically implements schema markup to help search engines understand your content and improve visibility in search results.',
    'gbp_posts': 'AI Create and Schedule engaging GBP posts to keep your audience informed and improve local SEO.',
    'qanda_suggestions': 'AI Generate Q&A suggestions to address common queries and enhance customer interaction.',
    'unanswered_reviews': 'Instruct the AI to answer reviews, improving customer satisfaction and engagement.',
    'cloud_stacks': 'Builds cloud stacks to enhance your site’s authority and improve search engine rankings.',
    'gsc_instant_indexing': 'OTTO integrates with Google Search Console to instantly index your pages, ensuring your latest updates are instantly visible to search engines.',
    'dynamic_indexing': `Add any Page URL to the Dynamic Indexing table, and ${keyword} will index it in a short time.`,
    'campaigns': 'Streamline link prospecting, campaign management, and outreach. Find bloggers and publishers in record time.',
    'inbox': 'Seamlessly track and organize your crucial conversations with contacts, ensuring no opportunity is missed in your inbox!',
    'templates': 'Create email templates for various professional and personal communication needs.',
    'organization_schema': 'Automatically implements schema markup to help search engines understand your content and improve visibility in search results.',
    'nlp_terms': 'Enhances content through semantic analysis and knowledge graph optimization for better search visibility.',
    'nlp_faq': 'Enhances content credibility through knowledge-based trust and factual enhancement for better search visibility.',
    'internal_link_suggestions': 'Suggests internal linking opportunities to improve site navigation and SEO.',
  };
};

export const IssueCategoriesIcons = {
  'page_title': '/img/dashboard/otto/text.svg',
  'meta_description': '/img/dashboard/otto/textalign-left.svg',
  'images': '/img/dashboard/otto/gallery.svg',
  'links': '/img/dashboard/otto/link-square-1.svg',
  'meta_keywords': '/img/dashboard/otto/key.svg',
  'missing_headings': '/img/dashboard/otto/emptySet.svg',
  'headings_length': '/img/dashboard/otto/diagramSubtask.svg',
  'headings': '/img/dashboard/otto/text-block.svg',
  'canonical_link': '/img/dashboard/otto/link-2.svg',
  'og_meta': '/img/dashboard/otto/chart.svg',
  'twitter_meta': '/img/dashboard/otto/hashtag.svg',
  'misc': '/img/dashboard/otto/more.svg',
  'missing_keywords': '/img/dashboard/otto/search-zoom-in.svg',
  'internal_links': '/img/dashboard/otto/link-square-1.svg',
  'semantic_analysis': '/img/dashboard/otto/book.svg',
  'knowledge_based_trust': '/img/dashboard/otto/glass.svg',
  'topical_maps_and_supplemental_content': '/img/dashboard/otto/hierarchy.svg',
  'custom_content': '/img/dashboard/otto/code.svg',
  'schema_markup': '/img/dashboard/otto/search-zoom-in.svg',
  'gbp_posts': '/img/dashboard/otto/message-text.svg',
  'aggregator_network': '/img/dashboard/otto/message-text.svg',
  'qanda_suggestions': '/img/dashboard/otto/message-question.svg',
  'unanswered_reviews': '/img/dashboard/otto/star.svg',
  'cloud_stacks': '/img/dashboard/otto/cloud-add.svg',
  'press_release_distribution': '/img/dashboard/otto/cloud-add.svg',
  'gsc_instant_indexing': '/img/dashboard/otto/fileCheck.svg',
  'dynamic_indexing': '/img/dashboard/otto/dynamic_indexing.svg',
  'ai_landing_page_builder': '/img/dashboard/otto/codeTag.png',
  'campaigns': '/img/dashboard/otto/bullsEyeArrow.svg',
  'inbox': '/img/dashboard/otto/inbox.svg',
  'templates': '/img/dashboard/otto/fileLines.svg',
  'my_tasks': '/img/dashboard/otto/fileCheck.svg',
};

export const Transitional = `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><rect opacity="0.1" width="22" height="22" rx="4" fill="#1F9168"/><path d="M7.91832 8.79403H10.5902V16H12.4141V8.79403H15.0859V7.27273H7.91832V8.79403Z" fill="#1F9168"/></svg>`;
export const Informational = `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><rect opacity="0.1" width="22" height="22" rx="4" fill="#34AEF3"/><path d="M11.9221 7.27273H10.0769V16H11.9221V7.27273Z" fill="#34AEF3"/></svg>`;
export const Commercial = `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><rect opacity="0.1" width="22" height="22" rx="4" fill="#F1AA3E"/>
  <path d="M14.9471 10.3281C14.7042 8.3125 13.1786 7.15341 11.1587 7.15341C8.85334 7.15341 7.09339 8.78125 7.09339 11.6364C7.09339 14.483 8.82351 16.1193 11.1587 16.1193C13.396 16.1193 14.7468 14.6321
  14.9471 13.0256L13.0806 13.017C12.9059 13.9503 12.1729 14.4872 11.1886 14.4872C9.86328 14.4872 8.96413 13.5028 8.96413 11.6364C8.96413 9.82102 9.8505 8.78551 11.2013 8.78551C12.2113 8.78551 12.94 9.36932 13.0806 10.3281H14.9471Z" fill="#F1AA3E"/></svg>`;
export const Navigational = `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><rect opacity="0.1" width="22" height="22" rx="4" fill="#B98CE4"/><path d="M15.1461 7.27273H13.3095V12.7614H13.2328L9.46573 7.27273H7.84641V16H9.69158V10.5071H9.7555L13.5524 16H15.1461V7.27273Z" fill="#B98CE4"/></svg>`;

export const IssuesSubGroup = {
  'gbp_audit': [
    {
      'qanda_suggestions': {
        label: 'Q&A',
        group: 'qanda_suggestions',
      },
      'gbp_posts': {
        label: 'Posts',
        group: 'gbp_posts',
      },
      'unanswered_reviews': {
        label: 'Unanswered Reviews',
        group: 'unanswered_reviews',
      },
      'aggregator_network': {
        label: 'Data Aggregator & Network Submissions',
        group: 'aggregator_network',
      },
      'profile_optimizations': {
        label: 'GBP Optimization',
        group: 'profile_optimizations',
      },
      'my_tasks': {
        label: 'My Tasks',
        group: 'my_tasks',
      },
    },
  ],
  'authority_building': [
    {
      'cloud_stacks': {
        label: 'Cloud Stacks',
        group: 'cloud_stacks',
      },
      'press_release_distribution': {
        label: 'Press Release Distribution',
        group: 'press_release_distribution',
      },
    },
  ],
  'new_content': [
    {
      'topical_maps_and_supplemental_content': {
        label: 'Topical Maps and Supplemental Content',
        group: 'topical_maps_and_supplemental_content',
      },
      'ai_landing_page_builder': {
        label: 'Pages',
        group: 'ai_landing_page_builder',
      },
    },
  ],
  'digital_outreach': [
    {
      'campaigns': {
        label: 'Campaigns',
        group: 'campaigns',
      },
      'inbox': {
        label: 'Inbox',
        group: 'inbox',
      },
      'templates': {
        label: 'Templates',
        group: 'templates',
      },
    },
  ],
};

export const searchPlaceholder = {
  'qanda_suggestions': 'Search by QA',
  'gbp_posts': 'Search by post content',
  'unanswered_reviews': 'Search...',
  'internal_link_suggestions': 'Search by target page',
};

export const ratingOptions = {
  5: '5 stars',
  4: '4 stars',
  3: '3 stars',
  2: '2 stars',
  1: '1 star',
};

export const ValidateFunctions = {
  page_title: record => typeof record?.recommendedValue === 'string' && record?.recommendedValue?.trim() !== '',
  meta_description: record => typeof record?.recommendedValue === 'string' && record?.recommendedValue?.trim() !== '',
  images: record => typeof record?.recommendedValue === 'string' && record?.recommendedValue?.trim() !== '',
  meta_keywords: record => typeof record?.recommendedValue === 'string' && record?.recommendedValue?.trim() !== '',
  missing_headings: record => Array.isArray(record?.recommendedValue) && record?.recommendedValue?.length > 0,
  h1_under_20_over_70: record => typeof record?.recommendedValue === 'string' && record?.recommendedValue?.trim() !== '',
  h2_under_20_over_70: record => typeof record?.recommendedValue === 'string' && record?.recommendedValue?.trim() !== '',
  og_description: record => typeof record?.recommendedValue === 'string' && record?.recommendedValue?.trim() !== '',
  og_title: record => typeof record?.recommendedValue === 'string' && record?.recommendedValue?.trim() !== '',
  og_url: record => typeof record?.recommendedValue === 'string' && record?.recommendedValue?.trim() !== '',
  twitter_description: record => typeof record?.recommendedValue === 'string' && record?.recommendedValue?.trim() !== '',
  twitter_title: record => typeof record?.recommendedValue === 'string' && record?.recommendedValue?.trim() !== '',
  twitter_site_property: record => typeof record?.recommendedValue === 'string' && record?.recommendedValue?.trim() !== '',
  twitter_card: record => typeof record?.recommendedValue === 'string' && record?.recommendedValue?.trim() !== '',
  organization_schema: record => !!record?.recommendedValue, // is object
  canonical_link: record => typeof record?.recommendedValue === 'string' && record?.recommendedValue?.trim() !== '',
  missing_keywords: record => Array.isArray(record?.values) && record?.values?.length > 0,
  internal_link_suggestions: record => Array.isArray(record?.values) && record?.values?.length > 0,
  nlp_terms: record => Array.isArray(record?.values) && record?.values?.length > 0,
  nlp_faq: record => Array.isArray(record?.values) && record?.values?.length > 0 && record?.values?.some(val => val?.answer?.length > 0),
  links: record => Array.isArray(record?.values) && record?.values?.length > 0,
  qanda_suggestions: record => record?.ownerAnswer?.content && record?.ownerAnswer?.content?.trim() !== '',
  gbp_posts: record => record?.content && record?.content?.trim() !== '',
  unanswered_reviews: record => record?.reply?.content && record?.reply?.content?.trim() !== '',
};

export const statusMappingForGbpPosts = {
  'Scheduled': 'Approved',
  'Published': 'Published',
  'Publishing': 'Publishing',
  'Rejected': 'Rejected',
  'Pending Review': 'Not approved',
  'Unpublished': 'Unpublished',
};

export const DeployStatusFilterLabelMapping = {
  deployed: {
    'gbp_posts': 'Published',
    'qanda_suggestions': 'Posted',
    'unanswered_reviews': 'Answered',
  },
  notDeployed: {
    'gbp_posts': 'Not Published',
    'qanda_suggestions': 'Not Posted',
    'unanswered_reviews': 'Unanswered',
  },
};
export const deployStatusFilterLabelMapping = (isV2 = false) => (
  {
    deployed: {
      'gbp_posts': 'Published',
      'qanda_suggestions': 'Posted',
      'unanswered_reviews': isV2 ? 'Replied' : 'Answered',
    },
    notDeployed: {
      'gbp_posts': 'Not Published',
      'qanda_suggestions': 'Not Posted',
      'unanswered_reviews': isV2 ? 'Not Replied' : 'Unanswered',
    },

  }
);
export const statusStyles = {
  'Pending Review': {backgroundColor: '#F2F2F5', textColor: '#4E5156', icon: faPage, label: 'Draft'},
  'Unpublished': {backgroundColor: '#F2F2F5', textColor: '#4E5156', icon: faPage, label: 'Draft'},
  'Scheduled': {backgroundColor: '#2AC1551F', textColor: '#18923B', icon: faCalendarCheck, label: 'Scheduled'},
  'Rejected': {backgroundColor: '#F443431F', textColor: '#F44343', icon: faXmark, label: 'Rejected'},
  'Published': {backgroundColor: '#2AC1551F', textColor: '#18923B', icon: faCheck, label: 'Published'},
  'Publishing': {backgroundColor: '#F1AA3E1F', textColor: '#F1AA3E', icon: faHourglassStart, label: 'Pending'},
};

export const reviewStatusStyles = {
  'Pending Review': {backgroundColor: '#F2F2F5', textColor: '#4E5156', icon: faPage, label: 'Draft'},
  'Scheduled': {backgroundColor: '#F2F2F5', textColor: '#4E5156', icon: faPage, label: 'Scheduled'},
  'Unpublished': {backgroundColor: '#F443431F', textColor: '#F44343', icon: faXmark, label: 'Unpublished'},
  'Published': {backgroundColor: '#2AC1551F', textColor: '#18923B', icon: faCheck, label: 'Published'},
};

export const qAndAStatusStyles = {
  'Pending Review': {backgroundColor: '#F2F2F5', textColor: '#4E5156', icon: faPage, label: 'Draft'},
  'Scheduled': {backgroundColor: '#2AC1551F', textColor: '#18923B', icon: faCalendarCheck, label: 'Scheduled'},
  'Unpublished': {backgroundColor: '#F443431F', textColor: '#F44343', icon: faXmark, label: 'Unpublished'},
  'Published': {backgroundColor: '#2AC1551F', textColor: '#18923B', icon: faCheck, label: 'Published'},
};
export const showBulkActionButton = [
  'page_title',
  'meta_description',
  'meta_keywords', 'missing_headings',
  'h1_under_20_over_70',
  'h2_under_20_over_70',
  'og_description',
  'og_title',
  'og_url',
  'twitter_description',
  'twitter_title',
  'twitter_site_property',
  'nlp_terms',
  'nlp_faq',
  'organization_schema',
  'missing_keywords',
  'internal_link_suggestions',
  'custom_url_based_indexing',
];

export const topicalMapStepsTitles = [
  'Generate content',
  'Publish',
  'View in WordPress',
];
export const gbpPostInitialDateFilter = {
  created: {
    startDate: '',
    endDate: '',
  },
  scheduled: {
    startDate: '',
    endDate: '',
  },
  published: {
    startDate: '',
    endDate: '',
  },
  unpublished: {
    startDate: '',
    endDate: '',
  },
  updated: {
    startDate: '',
    endDate: '',
  },
  rejected: {
    startDate: '',
    endDate: '',
  },
};

export const gscInstantIndexingStatus = {
  keepChecking: [
    `Discovered - currently not indexed`,
    `Crawled - currently not indexed`,
    `URL is unknown to Google`,
    `Indexing requested`,
    `GSC Check pending`,
    `Indexing request pending`,
    `Indexing requested`,
    `GSC Check failed`,
  ],
  stopChecking: [
    `Submitted URL seems to be a Soft 404`,
    `Server error (5xx)`,
    `Not found (404)`,
    `Redirect error`,
    `Blocked by robots.txt`,
    `Blocked due to access forbidden (403)`,
    `Duplicate, Google chose different canonical than user`,
    `Duplicate, submitted URL not selected as canonical`,
    `Duplicate without user-selected canonical`,
    `Excluded by 'noindex' tag`,
    `Page with redirect`,
    `Submitted URL marked 'noindex'`,
    `Indexed, though blocked by robots.txt`,
  ],
  finalStatus: [
    `Submitted and indexed`,
    `Indexed, not submitted in sitemap`,
    `Indexed, though blocked by robots.txt`,
    `Page indexed without content`,
  ],
};

export const statusColorMapping = {
  'Server error (5xx)': '#F44343',
};

gscInstantIndexingStatus.finalStatus.forEach(status => {
  statusColorMapping[status] = '#1BAC47';
});

export const publicPageCategories = ['technical_fixes', 'content_optimizations'];
export const automationOptions: Record<ButtonOptions, OptionsConfig> = {
  frequencyOption: {
    label: 'Post frequency',
    icon: faCalendars,
    options: [
      {label: 'OTTO Optimized frequency', value: 'OPTIMIZED',
        description: 'OTTO automatically adjusts the frequency based on a weekly analysis of over 2,000 similar businesses, helping you rank higher, faster.'},
      {label: 'Set a custom frequency', value: 'CUSTOM', custom: true},
    ],
  },
  startDateOption: {
    label: 'Start date',
    icon: faPlay,
    options: [
      {label: 'Start posting today', value: 'today'},
      {label: 'Choose a future date', value: 'separately', custom: true},
    ],
  },
  imageOption: {
    label: 'Image source',
    icon: faImage,
    options: [
      {label: 'AI Generate all images', value: 'AI_GENERATE', description: 'Each post will have it’s unique AI Generated & SEO Optimized image.'},
      {label: 'Select only from Media Library (48 images)', value: 'MEDIA_LIBRARY', showHeaderButton: true},
      {label: 'Select from Media Library or let AI create images when needed.', value: 'AUTOMATIC'},
    ],
  },
  approvalOption: {
    label: 'Approval',
    icon: faSquareCheck,
    options: [
      {label: 'Post automatically (no approval required)', value: 'AUTO_PUBLISH'},
      {label: 'Approve before posting', value: 'APPROVE_FOR_PUBLISH'},
      {label: 'Approve before posting (only if contains AI Generated images)', value: 'APPROVE_IF_IMAGE'},
    ],
  },
  publishOptions: {
    label: 'Publish Review',
    icon: faStarHalfStroke,
    options: [
      {label: 'Publish positive reviews as posts', value: 'REVIEWS_AND_AI', custom: true},
      {label: 'Do not publish reviews as posts', value: 'AI_GENERATE'},
    ],
  },
};

export const optionsCTA = [
  {label: 'None', value: null},
  {label: 'Book', value: 'BOOK'},
  {label: 'Order online', value: 'ORDER'},
  {label: 'Buy', value: 'SHOP'},
  {label: 'Learn more', value: 'LEARN_MORE'},
  {label: 'Sign up', value: 'SIGN_UP'},
  {label: 'Call now', value: 'CALL'},
];

export const getHowItWorksTooltipTexts = (whitelabelOtto: string) => {
  return {
    links: `${whitelabelOtto} identifies links that are broken or contain redirects and suggests direct replacements to maximize crawl efficiency, preserve link equity, and strengthen overall site authority while improving user experience.`,
    misc: `${whitelabelOtto} identifies pages and content sections with missing or incorrect language attributes and suggests proper language declarations to improve search engine visibility, international targeting, and content relevancy for multilingual audiences.`,
  };
};
