import {useState, useEffect, Dispatch, SetStateAction} from 'react';
import {
  Tooltip,
  Form,
  Spin,
  Radio,
  Select,
  Popover,
} from 'antd';
import styles from '../index.module.scss';
import {useStore} from '@/store/root-store';
import {Button} from '@/components/common-components/components';
import {Button as V2Button, Input} from '@/components/common-components/v2';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faWordpressSimple} from '@fortawesome/free-brands-svg-icons';
import {toJS} from 'mobx';
import {
  getDomain,
  sanitizeUrl,
} from '@/utils/url';
import moment from 'moment';
import {
  faUpRightFromSquare} from '@fortawesome/pro-light-svg-icons';
import {LoadingOutlined} from '@ant-design/icons';
import {observer} from 'mobx-react';
import {faArrowUpFromLine, faCircleCheck, faHexagonExclamation} from '@fortawesome/pro-solid-svg-icons';
import {faCircleInfo} from '@fortawesome/pro-regular-svg-icons';
// import {VideoPlayerModal} from '@/components/common-components/v2/VideoPlayerModal';
import {notification as notificationV2} from '@/utils/notification-v2';
import {openLiveChat} from '@/utils/intercom';
import {useRouter} from 'next/router';

interface Props {
  titleUuid?: string;
  uuid?: string;
  topicalMapName?: string;
  errorId?: any;
  setErrorId?: any;
  getPopupContainer?: () => any;
  isInContentTopicMap?: boolean;
  setDataLoader?: Dispatch<SetStateAction<boolean>>;
  topicId?: number;
  componentIssueType?: string;
  ProjectUuid?: string;
}

export const OneClickPublisher = observer(({titleUuid, uuid, topicalMapName, errorId, setErrorId, getPopupContainer, isInContentTopicMap, setDataLoader, topicId, componentIssueType, ProjectUuid}: Props) => {
  const {contentOptimizer: {
    pages: {
      projects,
    },
    currentPage: {
      content,
      isPublic,
      isExportingPage,
      checkIfWpSynced,
      loadPage,
      setUnsavedChanges,
      unsavedChangesInContent,
    },
    wpPublish: {
      fetchWpPublishData,
      loadingData,
      postingData,
      showDataModal,
      setShowDataModal,
      wpArray,
      publishWpPage,
      setShowAddSiteModal,
      isApiError,
      updateApiError,
      setShowIndividualPopover,
      showIndividualPopover,
      setLoadingData,
    },
    contentIdeas: {loadSingleTopicMapData},
  },
  ottoV2Store: {
    ottoUrls,
    loadIssueTableData,
  },
  settings: {
    customer: {isGHLAccount},
  },
  } = useStore('');

  const [selectedWpSite, setSelectedWpSite] = useState<any>();
  const [showWpVideo, setShowWpVideo] = useState<boolean>(false);
  const [filteredObject, setFilteredObject] = useState<any>({});
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [selectedWpSiteUsers, setSelectedWpSiteUsers] = useState([]);
  const [trigger, setTrigger] = useState<boolean>(false);
  const [loadingId, setLoadingId] = useState('');
  const [parent, setParent] = useState([]);
  const router = useRouter();

  // Local state settings for dynamic new post/page button label
  const [newBtnLabelOptions, setNewBtnLabelOptions] = useState({
    action: 'Create',
    status: 'Draft',
    type: 'Blog Post',
  });

  useEffect(() => {
    const {wpPostCategories, wpPostStatus, wpPostType, wpPublishedAtWebsiteId,
      defaultWpPostCategories, defaultWpPostStatus, defaultWpPostType, defaultWpWebsiteId, wpPostAuthorId} = content;
    let initialValues;
    if (wpPublishedAtWebsiteId) {
      initialValues = {
        wpPostType,
        wpPostStatus,
        wpPostCategories,
      };
      setSelectedWpSite(wpPublishedAtWebsiteId);
      wpForm.setFieldsValue({wpWebsite: wpPublishedAtWebsiteId, wpPostCategory: wpPostCategories || [], author: String(wpPostAuthorId || ''), postParent: wpPublishedAtWebsiteId});
      setSelectedUser(String(wpPostAuthorId || ''));
    } else {
      initialValues = {
        wpPostType: defaultWpPostType,
        wpPostStatus: defaultWpPostStatus,
        wpPostCategories: defaultWpPostCategories,
      };
      setSelectedWpSite(defaultWpWebsiteId);
      wpForm.setFieldsValue({wpWebsite: defaultWpWebsiteId, wpNewPostCategory: wpPostCategories?.length ? wpPostCategories : defaultWpPostCategories || [], wpPostType: defaultWpPostType, wpPostStatus: defaultWpPostStatus, postParent: defaultWpWebsiteId});
    }

    setFilteredObject(initialValues);

    setNewBtnLabelOptions({
      ...newBtnLabelOptions,
      status: initialValues?.wpPostStatus === 'draft' ? 'Draft' : 'Publish',
      type: initialValues?.wpPostType === 'post' ? 'Blog Post' : 'Page',
      action: initialValues?.wpPostStatus === 'draft' ? 'Create' : '',
    });
    updateApiError(false);
    setShowWpFormType({
      syncWp: offerPublishUrl() ? true : false,
      createNewWp: offerPublishUrl() ? false : true,
    });
  }, [projects, content, trigger, showIndividualPopover]);

  useEffect(() => {
    const selectedWPsiteData = wpArray.find(item => item.id === selectedWpSite);
    if (selectedWPsiteData) {
      if (selectedWPsiteData?.users?.length) {
        if (!content.wpPostAuthorId) {
          wpForm.setFieldsValue({author: ''});
          setSelectedUser(null);
        }
        const articles = selectedWPsiteData?.articles;
        const updatedArticles = articles?.length ? [{id: 0, title: 'No Parent'}, ...toJS(articles)] : [];
        const parent = updatedArticles?.find(item => item.id == content?.wpPostParent);
        if (!parent) {
          setFilteredObject({...filteredObject, wpPostParent: null});
          wpForm.setFieldsValue({postParent: null});
        } else {
          const websiteId = content?.wpPublishedAtWebsiteId || content?.defaultWpWebsiteId;
          if (websiteId == selectedWpSite) {
            setFilteredObject({...filteredObject, wpPostParent: content?.wpPostParent});
            wpForm.setFieldsValue({postParent: content?.wpPostParent});
          }
        }
        selectedWPsiteData && setParent(updatedArticles);
        setSelectedWpSiteUsers(selectedWPsiteData.users);
      } else {
        wpForm.setFieldsValue({author: ''});
        setSelectedUser(null);
        setSelectedWpSiteUsers([]);
      }
    } else {
      wpForm.setFieldsValue({author: ''});
      setSelectedUser(null);
      setSelectedWpSiteUsers([]);
    }
  }, [selectedWpSite, content, showDataModal, showIndividualPopover]);

  useEffect(() => {
    if (!showDataModal && !showIndividualPopover) {
      wpForm.setFieldsValue({author: ''});
      setSelectedUser(null);
      setSelectedWpSiteUsers([]);
    }
  }, [showDataModal, showIndividualPopover]);

  // Final label for dynamic new post/page/ button
  const [newBtnLabel, setNewBtnLabel] = useState('Create Draft Blog Post');

  // Generate new post/page button label every time any new option is selected
  useEffect(() => {
    setNewBtnLabel(`${newBtnLabelOptions.action} ${newBtnLabelOptions.status} ${newBtnLabelOptions.type}`);
  }, [newBtnLabelOptions]);

  const [syncBtnLabel, setSyncBtnLabel] = useState(content?.wpPostType ? `Sync to ${content?.wpPostType}` : 'Sync');
  useEffect(() => {
    if (content?.wpPostType) setSyncBtnLabel(`Sync to ${content?.wpPostType}`);
  }, [content?.wpPostType, content?.wpPublishedAtUrl]);

  const {Option} = Select;
  const {Group} = Radio;

  const [wpForm] = Form.useForm();
  const antdIconLarge = <LoadingOutlined style={{fontSize: 18, marginBottom: 2, color: '#2D6CCA'}} spin />;
  const antdIcon = <LoadingOutlined style={{fontSize: 14, marginBottom: 2, color: titleUuid ? '#7F4EAD' : '#2D6CCA'}} spin />;
  const antdIconErr = <LoadingOutlined style={{fontSize: 14, marginBottom: 2, color: 'red'}} spin />;

  /*
  * Sets initial value for meta description to match current SCA page meta description
  */
  useEffect(() => {
    wpForm.setFieldsValue({wpPostMetaDesc: content?.metaDesc || ''});
  }, [content?.metaDesc]);


  const offerPublishUrl = () => {
    if (content?.importedFromUrl) {
      if (content?.wpPublishedAtUrl) {
        return true;
      } else {
        const cleanWpArr = toJS(wpArray).map(item => getDomain(item.url));
        if (cleanWpArr.includes(getDomain(content.importedFromUrl))) {
          return true;
        }
      }
    } else {
      return content?.wpPublishedAtUrl ? true : false;
    }
  };

  // used for wordpress popup to decide to show publisher or syncer
  const [showWpFormType, setShowWpFormType] = useState({
    syncWp: offerPublishUrl() ? true : false,
    createNewWp: offerPublishUrl() ? false : true,
  });

  /*
    * Gets Url for wordpress page
    */
  const getTopLinkUrl = () => {
    return content?.wpPublishedAtUrl || content?.importedFromUrl;
  };


  /*
    * Gets Id for wordpress page
    */
  const getTopLinkId = () => {
    if (content?.wpPublishedAtUrl) return wpArray.filter(item => getDomain(item.url) === getDomain(content?.wpPublishedAtUrl))[0]?.id;
    if (content?.importedFromUrl) return wpArray.filter(item => getDomain(item.url) === getDomain(content?.importedFromUrl))[0]?.id;
  };

  /*
  * Handles publishing of data from WordPress form
  * makes different payload depeneding if user is creating new WP article or updating existing one
  */
  const publishWpPageHandler = async values => {
    if (showWpFormType.createNewWp && topicalMapName) {
      notificationV2.error('Failed', 'Article was already published to the page.');
      return false;
    }
    try {
      let payload;
      const filter = ottoUrls?.filter(d => d.issueType === componentIssueType);
      const params = {
        uuid: ProjectUuid,
        issue_type: componentIssueType,
        page_size: filter?.length ? filter[0].pageSize : 10,
        page: filter?.length ? filter[0].page : 1,
        is_loading: false,
      };
      if (showWpFormType.syncWp) {
        const pageId = getTopLinkId();
        const pageUrl = getTopLinkUrl();

        payload = {
          pageId: pageId,
          uuid: content?.uuid,
          pageUrl: pageUrl,
          postStatus: values['wpPostStatus'] || 'draft',
          postType: values['wpPostType'] || 'post',
          postAuthor: values['author'] || '',
          ...((values['wpPostCategory']?.length && values['wpPostType'] !== 'page') && {postCategories: values['wpPostCategory']}),
          // ...(values['wpPostMetaDesc'] && {metaDesc: values['wpPostMetaDesc']}),
          // ...(values['wpPostMetaRobots'] && {robots: values['wpPostMetaRobots']}),
        };

        if (values?.postParent!==null) {
          payload['postParent'] = values.postParent;
        }
        await publishWpPage(payload, topicalMapName ? true : false);
        setUnsavedChanges({
          wpPostStatus: false,
          wpPostType: false,
          wpPostAuthorId: false,
          wpPostCategory: false,
          postParent: false,
          content: false,
        });
        setShowIndividualPopover('');
        setDataLoader && setDataLoader(true);
        if (isInContentTopicMap) {
          await loadSingleTopicMapData(Number(router.query['topicId']), true);
        } else if (titleUuid) {
          if (topicId) {
            await loadSingleTopicMapData(topicId, true);
          }
          await loadIssueTableData(params, false, true);
        }
      }


      if (showWpFormType.createNewWp) {
        payload = {
          pageId: values['wpWebsite'] || wpArray[0]?.id,
          uuid: content?.uuid,
          postStatus: values['wpPostStatus'] || 'draft',
          postType: values['wpPostType'] || 'post',
          postAuthor: values['author'] || '',
          ...(values['wpPostPermalinkUrl'] && {permalink: values['wpPostPermalinkUrl']}),
          ...((values['wpNewPostCategory']?.length && newBtnLabelOptions.type !== 'Page') && {postCategories: values['wpNewPostCategory']}),
        };

        if (values?.postParent!==null) {
          payload['postParent'] = values.postParent;
        }

        await publishWpPage(payload, topicalMapName ? true : false);
        setShowIndividualPopover('');
        setDataLoader && setDataLoader(true);
        if (isInContentTopicMap) {
          await loadSingleTopicMapData(Number(router.query['topicId']), true);
        } else if (titleUuid) {
          if (topicId) {
            await loadSingleTopicMapData(topicId, true);
          }
          await loadIssueTableData(params, false, true);
        }
      }
    } catch (e) {
      setShowIndividualPopover('');
      setErrorId([...errorId, titleUuid]);
    }
  };

  useEffect(() => {
    if (newBtnLabelOptions.type === 'Page') {
      wpForm.setFields([{name: 'wpNewPostCategory', errors: []}]);
    }
  }, [newBtnLabelOptions.type]);

  const extractOptions = (categories, options) => {
    categories?.forEach(category => {
      options.push(<Option key={category?.id} value={category.name}>{category.name}</Option>);
      if (category?.children?.length) {
        extractOptions(category.children, options);
      }
    });
    return options;
  };

  /*
    * Returns HTML for top part of Wordpress publishing popup including sync to existing wp page/post
    */
  const wpPublisherSyncSection = (<WpUpdatePostSection isActive={showWpFormType.syncWp}>
    <>
      {showWpVideo ?
        <div style={{width: '100%', textAlign: 'center', marginBottom: 20}}>
          <iframe
            width='90%'
            height='auto'
            src='https://www.youtube.com/embed/71vTcFMkphw'
            title='YouTube video player'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
            style={{borderRadius: '8px'}}>
          </iframe>
        </div> :
        null}
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <CustomRadioButton onChange={() => {
          setShowWpFormType({
            syncWp: true,
            createNewWp: false,
          });
        }} checked={showWpFormType.syncWp}>WordPress Synchronize</CustomRadioButton>
        <VideoTutorialBtn onClick={() => setShowWpVideo(!showWpVideo)}>
          <FontAwesomeIcon icon={faCircleInfo} style={{marginRight: 5, color: '#2D6CCA'}}/>
          {showWpVideo ? 'Hide Video Tutorial' : 'Show Video Tutorial'}
        </VideoTutorialBtn>
      </div>

      <InputsContainer>
        {content?.wpSyncedAt ? <div>
          <WpLastSyncLeft>
            {`Last sync: ${moment(content?.wpSyncedAt).format('ll')} at ${moment(content?.wpSyncedAt).format('LT')}`}
          </WpLastSyncLeft>
          <WpSyncedPostDetails>
            {content?.wpPostStatus ? <WpStatusBox>
              {content.wpPostStatus}
            </WpStatusBox> : null}
            {content?.wpPostType ? <WpStatusBox>
              {content.wpPostType}
            </WpStatusBox> : null}
            <WpSyncInfoBox isSynced={content?.wpSynced}>
              {content?.wpSynced ? 'Synchronized' : 'Out of Sync'}
            </WpSyncInfoBox>
          </WpSyncedPostDetails>
        </div> : null}
        {content?.wpPublishedAtUrl ? <Tooltip title={content?.wpPostStatus == 'draft' ? `You can view drafts only when logged in to the WordPress Admin Dashboard of ${content?.wpPublishedAtUrl}.` : sanitizeUrl(content?.wpPublishedAtUrl)}>
          <a href={content?.wpPublishedAtUrl} target='_blank' rel='noreferrer' style={{display: 'flex'}}>
            <span style={{maxWidth: 250, fontSize: 12, display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginTop: 10, marginBottom: 15}}>{sanitizeUrl(content?.wpPublishedAtUrl)}</span>
            <FontAwesomeIcon icon={faUpRightFromSquare} style={{marginTop: 10, marginLeft: 5}}/>
          </a>
        </Tooltip> : null}
      </InputsContainer>
      {showWpFormType.syncWp ? <InputsContainer>
        <InputLabelWrapper>
          <FormLabel>Post Status</FormLabel>
          <Form.Item name='wpPostStatus' initialValue={filteredObject.wpPostStatus}>
            <Group>
              <PurpleRadioButton blueColor={true} value='draft' defaultChecked style={{fontSize: 12, width: 85}}
                onChange={() => {
                  content?.wpPostStatus != 'draft' ? setUnsavedChanges({...unsavedChangesInContent, wpPostStatus: true}) : setUnsavedChanges({...unsavedChangesInContent, wpPostStatus: false});
                  setNewBtnLabelOptions({
                    ...newBtnLabelOptions,
                    action: 'Create',
                    status: 'Draft',
                  });
                }}>Draft</PurpleRadioButton>
              <PurpleRadioButton blueColor={true} value='publish' style={{fontSize: 12}} onChange={() => {
                content?.wpPostStatus != 'publish' ? setUnsavedChanges({...unsavedChangesInContent, wpPostStatus: true}) : setUnsavedChanges({...unsavedChangesInContent, wpPostStatus: false});
                setNewBtnLabelOptions({
                  ...newBtnLabelOptions,
                  action: 'Publish',
                  status: '',
                });
              }}>Publish</PurpleRadioButton>
            </Group>
          </Form.Item>
        </InputLabelWrapper>

        <InputLabelWrapper>
          <FormLabel>Post Type</FormLabel>
          <Form.Item
            name='wpPostType'
            initialValue={filteredObject.wpPostType}
            rules={[{required: true, message: 'Post Type is required'}]}
          >
            <Group>
              <PurpleRadioButton blueColor={true} value='post' defaultChecked style={{fontSize: 12}}
                onChange={() => {
                  content?.wpPostType != 'blog' ? setUnsavedChanges({...unsavedChangesInContent, wpPostType: true}) : setUnsavedChanges({...unsavedChangesInContent, wpPostType: false});
                  setNewBtnLabelOptions({
                    ...newBtnLabelOptions,
                    type: 'Blog Post',
                  });
                }}>Blog Post</PurpleRadioButton>
              <PurpleRadioButton blueColor={true} value='page' style={{fontSize: 12}}
                onChange={() => {
                  content?.wpPostType != 'page' ? setUnsavedChanges({...unsavedChangesInContent, wpPostType: true}) : setUnsavedChanges({...unsavedChangesInContent, wpPostType: false});
                  wpForm.setFieldsValue({wpPostCategory: []});
                  setNewBtnLabelOptions({
                    ...newBtnLabelOptions,
                    type: 'Page',
                  });
                }}>Page</PurpleRadioButton>
            </Group>
          </Form.Item>
        </InputLabelWrapper>
        <InputLabelWrapper >
          <FormLabel>Author</FormLabel>
          <Form.Item
            name='author' initialValue={selectedUser}
            rules={[{required: true, message: 'Author is required'}]}>
            <Select
              onChange={id => {
                content?.wpPostAuthorId!=id ? setUnsavedChanges({...unsavedChangesInContent, wpPostAuthorId: true}) : setUnsavedChanges({...unsavedChangesInContent, wpPostAuthorId: false});
                setSelectedUser(id);
              }}
            >
              {selectedWpSiteUsers.map((user, i) => <Option on value={user.id} key={i}>{user.userEmail}</Option>)}
            </Select>
          </Form.Item>
        </InputLabelWrapper>
        <InputLabelWrapper style={{height: 'auto'}}>
          <FormLabel>Category</FormLabel>
          <Form.Item name='wpPostCategory' rules={newBtnLabelOptions.type === 'Page' ? [] : [{required: true, message: 'Category is required'}]}>
            <Select onChange={itemsArray => {
              const areCategoriesSame = Array.isArray(content?.wpPostCategories) && content?.wpPostCategories.length === itemsArray.length && content?.wpPostCategories.every(item => itemsArray.includes(item));
              setUnsavedChanges({...unsavedChangesInContent, wpPostCategory: !areCategoriesSame});
            }} mode='multiple' disabled={newBtnLabelOptions.type === 'Page'}
            // defaultValue={content?.wpPostCategories || []}
            >
              {extractOptions(wpArray.filter(item => getDomain(item.url) === getDomain(content?.wpPublishedAtUrl))[0]?.categories, [])?.map(item => item)}
            </Select>
          </Form.Item>
        </InputLabelWrapper>
        {parent?.length ? (
          <InputLabelWrapper style={{height: 'auto'}}>
            <FormLabel>Parent</FormLabel>
            <Form.Item
              name='postParent'
              initialValue={filteredObject?.wpPostParent}
            >
              <Select onChange={id => content?.wpPostParent!=id ? setUnsavedChanges({...unsavedChangesInContent, postParent: true}) : setUnsavedChanges({...unsavedChangesInContent, postParent: false})}>
                {parent?.map(item => (
                  <Option key={item.id} value={item.id}>{item.title}</Option>
                ))}
              </Select>
            </Form.Item>
          </InputLabelWrapper>
        ) : null}
        <div style={{display: 'flex'}}>
          <PublishButton disabled={Object.values(unsavedChangesInContent).every(value => value === false) || postingData} type='default' variant='solid' color='blue' htmlType='submit'>{syncBtnLabel}</PublishButton>
          {postingData && <Spin indicator={antdIconLarge} style={{marginTop: 'auto', marginBottom: 5, marginLeft: 10}}/>}
        </div>
      </InputsContainer> : null}
    </>
  </WpUpdatePostSection>);

  const isPosting = Boolean(postingData);
  const isContentUnavailable = !content;
  const isAIInProgress = content?.aiInProgress || false;
  const isWordCountInvalid = !content?.wordCount || content.wordCount === 0;

  const isDisabled = isPosting || isContentUnavailable || isAIInProgress || isWordCountInvalid;
  /*
    * Returns HTML for bottom part of Wordpress publishing popup including creation of new wordpress post/page
    */
  const wpPublisherNewPostSection = (<WpNewPostSection isActive={showWpFormType.createNewWp}>
    {!content?.wpPublishedAtUrl ?
      <div style={{width: '100%', textAlign: 'center', marginBottom: 20}}>
        <iframe
          width='90%'
          height='auto'
          src='https://www.youtube.com/embed/71vTcFMkphw'
          title='YouTube video player'
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
          style={{borderRadius: '8px'}}>
        </iframe>
      </div> :
      null}
    <div style={{display: 'flex'}}>{offerPublishUrl() ? <PurpleRadioButton onChange={() => {
      setShowWpFormType({
        syncWp: false,
        createNewWp: true,
      });
    }} checked={showWpFormType.createNewWp} style={{fontWeight: 500, marginBottom: 10}}>Create new post</PurpleRadioButton> :
      <div style={{fontWeight: 500, marginBottom: 10, marginLeft: 25}}>Create new post</div>}
    <div style={{marginLeft: 'auto', color: '#2D6CCA', cursor: 'pointer'}} onClick={() => {
      setShowDataModal(false);
      setShowAddSiteModal(true);
      setShowIndividualPopover('');
    }}>+ Add site</div>
    </div>
    <InputsContainer>
      <InputLabelWrapper >
        <FormLabel>Website</FormLabel>
        <StyledFormItem
          name='wpWebsite'
          initialValue={selectedWpSite}
          rules={[{required: true, message: 'Wp website is required'}]}
        >
          <Select
            onClick={() => {
              setShowWpFormType({
                syncWp: false,
                createNewWp: true,
              });
            }}
            onChange={value => {
              setSelectedWpSite(value);
            }}
          >
            {toJS(wpArray).map((wpSite, idx) => {
              return <Option on value={wpSite.id} key={idx}>{getDomain(wpSite.url)}</Option>;
            })}
          </Select>
        </StyledFormItem>
      </InputLabelWrapper>
      {showWpFormType.createNewWp ? <div>
        <InputLabelWrapper>
          <FormLabel>Post Status</FormLabel>
          <StyledFormItem
            name='wpPostStatus'
            initialValue={filteredObject.wpPostStatus}
            rules={[{required: true, message: 'Post Status is required'}]}
          >
            <Group>
              <PurpleRadioButton value='draft' defaultChecked style={{fontSize: 12, width: 85}}
                onChange={() => {
                  setNewBtnLabelOptions({
                    ...newBtnLabelOptions,
                    action: 'Create',
                    status: 'Draft',
                  });
                }}>Draft</PurpleRadioButton>
              <PurpleRadioButton value='publish' style={{fontSize: 12}} onChange={() => {
                setNewBtnLabelOptions({
                  ...newBtnLabelOptions,
                  action: 'Publish',
                  status: '',
                });
              }}>Publish</PurpleRadioButton>
            </Group>
          </StyledFormItem>
        </InputLabelWrapper>

        <InputLabelWrapper>
          <FormLabel>Post Type</FormLabel>
          <StyledFormItem
            name='wpPostType'
            initialValue={filteredObject.wpPostType}
            rules={[{required: true, message: 'Post Type is required'}]}
          >
            <Group>
              <PurpleRadioButton value='post' defaultChecked style={{fontSize: 12}}
                onChange={() => {
                  setNewBtnLabelOptions({
                    ...newBtnLabelOptions,
                    type: 'Blog Post',
                  });
                }}>Blog Post</PurpleRadioButton>
              <PurpleRadioButton value='page' style={{fontSize: 12}}
                onChange={() => {
                  setNewBtnLabelOptions({
                    ...newBtnLabelOptions,
                    type: 'Page',
                  });
                }}>Page</PurpleRadioButton>
            </Group>
          </StyledFormItem>
        </InputLabelWrapper>

        <InputLabelWrapper>
          <FormLabel>
            Permalink URL
            <OptionalTag>(Optional)</OptionalTag>
          </FormLabel>
          <StyledFormItem name='wpPostPermalinkUrl'
            rules={[
              {
                pattern: /^[a-z0-9-_]+(?:\/[a-z0-9-_]+)*$/,
                message: 'Please enter a valid permalink',
              },
            ]}>
            <Input variant='bordered'/>
          </StyledFormItem>
        </InputLabelWrapper>

        <InputLabelWrapper >
          <FormLabel>Author</FormLabel>
          <StyledFormItem
            name='author'
            initialValue={selectedUser}
            rules={[{required: true, message: 'Author is required'}]}
          >
            <Select
              onChange={id => setSelectedUser(id)}
            >
              {selectedWpSiteUsers.map((user, i) => <Option on value={user.id} key={i}>{user.userEmail}</Option>)}
            </Select>
          </StyledFormItem>
        </InputLabelWrapper>

        <InputLabelWrapper style={{height: 'auto'}}>
          <FormLabel>Category</FormLabel>
          <StyledFormItem
            name='wpNewPostCategory'
            rules={newBtnLabelOptions.type === 'Page' ? [] : [{required: true, message: 'Category is required'}]}
          >
            <Select mode='multiple' disabled={newBtnLabelOptions.type === 'Page'}>
              {extractOptions(wpArray?.filter(item => item.id === selectedWpSite)[0]?.categories, [])?.map(item => item)}
            </Select>
          </StyledFormItem>
        </InputLabelWrapper>
        {parent?.length ? (
          <InputLabelWrapper style={{height: 'auto'}}>
            <FormLabel>Parent</FormLabel>
            <StyledFormItem
              name='postParent'
              initialValue={filteredObject?.wpPostParent}
            >
              <Select>
                {parent?.map(item => (
                  <Option key={item.id} value={item.id}>{item.title}</Option>
                ))}
              </Select>
            </StyledFormItem>
          </InputLabelWrapper>
        ) : null}
        <Tooltip title={isAIInProgress ? <div>AI in progress<br/>Please wait till AI complete the content generation</div> : isWordCountInvalid ? `Empty content can't be ${newBtnLabelOptions?.action === 'Create' ? 'draft' : 'publish'}` : ''}>
          <div style={{display: 'flex', width: 'fit-content', marginTop: '16px'}}>
            <PublishButton style={{marginTop: '0px'}} disabled={isDisabled} type='default' variant='solid' color='purple' htmlType='submit'>{newBtnLabel}</PublishButton>
            {postingData && <Spin indicator={antdIconLarge} style={{marginTop: 'auto', marginBottom: 5, marginLeft: 10}}/>}
          </div></Tooltip>
      </div> : null}
    </InputsContainer>
  </WpNewPostSection>);

  const wpPublishTooltipContent = (<div style={{borderRadius: 8}} id='wp-publish=modal'>
    {((isApiError && errorId?.find(item=> item == titleUuid)) || errorId?.find(item=> item == titleUuid)) ? <ErrorNotificationContainer>
      <ErrorNotification>
        <div>
          <FontAwesomeIcon icon={faHexagonExclamation} style={{color: '#F44343', fontSize: 14, marginTop: 10, marginRight: 10}}/>
        </div>
        <div>
          <div>{`We're unable to sync with your WordPress site.`}</div>
          <div>Check your configuration {isGHLAccount ? '' : <>or
            <span
              style={{marginLeft: 5, color: '#2D6CCA', textDecoration: 'underline', cursor: 'pointer'}}
              onClick={() => openLiveChat()}>
              reach out to support
            </span></>}
          </div>
        </div>
      </ErrorNotification>
    </ErrorNotificationContainer> : null}

    <Form onFinish={publishWpPageHandler} form={wpForm}>
      {content?.wpPublishedAtUrl ? wpPublisherSyncSection : null}
      {wpPublisherNewPostSection}
    </Form>
  </div>);

  const onClick = async () => {
    if (!showDataModal) {
      if (titleUuid) {
        setLoadingId(titleUuid);
        setLoadingData(true);
        await loadPage(uuid);
        setTrigger(true);
        await fetchWpPublishData();
        setShowIndividualPopover(titleUuid);
      } else {
        fetchWpPublishData(true);
      }
    } else {
      setShowDataModal(false);
      setShowIndividualPopover('');
    }
    setLoadingId('');
  };

  return !isPublic && <>
    {/* <VideoPlayerModal title={'How to Publish to WordPress in 1-Click'}
      videoLink='https://www.youtube.com/embed/71vTcFMkphw'/> */}
    <Popover
      destroyTooltipOnHide={true}
      overlayInnerStyle={{borderRadius: 8}}
      content={wpPublishTooltipContent}
      visible={showDataModal||showIndividualPopover === titleUuid}
      overlayClassName={styles.wpPublishPopupContent}
      placement={titleUuid ? 'left' : 'bottom'}
      trigger='click'
      getPopupContainer={getPopupContainer}
      onVisibleChange={() => {
        if (showDataModal) setShowDataModal(false);
        if (showIndividualPopover) setShowIndividualPopover('');
        if (!showDataModal && !showIndividualPopover) checkIfWpSynced();
        if (showDataModal || showIndividualPopover) {
          if (content?.wpPublishedAtWebsiteId) {
            setSelectedWpSite(content?.wpPublishedAtWebsiteId);
            wpForm.setFieldsValue({wpWebsite: content?.wpPublishedAtWebsiteId, postParent: parent?.find(item => item.id == content?.wpPostParent)?.id ?? null});
          } else {
            setSelectedWpSite(content?.defaultWpWebsiteId || wpArray[0]?.id);
            wpForm.setFieldsValue({wpWebsite: content?.defaultWpWebsiteId || wpArray[0]?.id, postParent: parent?.find(item => item.id == content?.wpPostParent)?.id ?? null});
          }
        }
        setUnsavedChanges({
          content: content?.isCleanedHtmlUpdated || false,
          wpPostStatus: false,
          wpPostType: false,
          wpPostAuthorId: false,
          wpPostCategory: false,
          postParent: false,
        });
      }}>
      {((isApiError && errorId?.find(item=> item == titleUuid)) || errorId?.find(item=> item == titleUuid)) ? (
        <SyncFailedIndictor
          style={{cursor: 'pointer', display: isExportingPage ? 'none' : 'block', marginLeft: 4}}
          disabled={loadingData}
          onClick={onClick}>
          {titleUuid ? (loadingId !== titleUuid || !loadingData) ? <FontAwesomeIcon
            icon={faWordpressSimple}
            className={styles.wpIcon}
            color='#F44343'
          /> : <Spin indicator={antdIconErr} className={styles.spinnerLoader} />: <>WP Failed</>}
          WP Sync Failed
        </SyncFailedIndictor>
      ) : (
        <WpButtonStyled
          hasId={titleUuid ? true : false}
          generatedId={topicalMapName ? true : false}
          inCA={isInContentTopicMap}
          buttonType={titleUuid ? 'transparent' : content?.wpSynced ? 'green-opaque' : 'blue-opaque'}
          style={{
            cursor: 'pointer',
            display: titleUuid ? 'block' : isExportingPage ? 'none' : 'block',
            marginLeft: 4,
            color: titleUuid ? topicalMapName ? isInContentTopicMap ? '#121212' : '#4E5156' : isInContentTopicMap ? '#FFFFFF' : '#7F4EAD': content?.wpSynced ? '#1FAC47' : '#2d6cca',
            fontWeight: topicalMapName ? '500': '',
          }}
          disabled={loadingData}
          onClick={onClick}>
          {(titleUuid ? (loadingId !== titleUuid || !loadingData) : !loadingData) ?
            <FontAwesomeIcon
              fontSize={14}
              icon={titleUuid ? topicalMapName ? faCircleCheck : faArrowUpFromLine: faWordpressSimple}
              className={styles.wpIcon}
              color={titleUuid ? isInContentTopicMap && !topicalMapName ? '#FFFFFF' : '#7F4EAD': content?.wpSynced ? '#1FAC47' : '#2d6cca'}
              style={{fontSize: 14}}
            /> : <Spin indicator={antdIcon} className={styles.spinnerLoader} />}
          <span style={{lineHeight: 'normal'}}>
            {content?.wpSynced ? (
              titleUuid ? topicalMapName ? 'Published' : 'Publish' : 'Synchronized'
            ) : (
              titleUuid ? topicalMapName ? 'Published': 'Publish': 'WP Synchronize'
            )}
          </span>
        </WpButtonStyled>
      )}
    </Popover>
  </>;
});

const WpButtonStyled = styled(Button)<{hasId?: boolean; inCA?: boolean; generatedId?: boolean}>`
  height: 27px;
  padding: ${p => p.hasId ? '0px 8px' : '1px 8px 2px 8px'};
  margin-left: ${p => p.hasId ? '0px!important' : '8px'};
  font-size: ${p => p.hasId ? '13px' : '12px'};
  align-items: center;
  background-color:  ${p => p.inCA ? p.generatedId ? '#FFFFFF' : '#7F4EAD' : p.hasId && 'rgba(127, 78, 173, 0.15)'} !important;
  border: ${p => p.inCA ? '1px solid #CDCDCD' : 'none'}  !important;
  &:hover, &:active, &:hover {
    background-color:  ${p => p.inCA ? p.generatedId ? '#FFFFFF' : '#7F4EAD' : p.hasId && 'rgba(127, 78, 173, 0.15)'} !important;
  }
  span {
    display: flex;
    align-items: center;
    line-height: normal;
  }
`;

const CustomRadioButton = styled(Radio)`
  .ant-radio-inner {
    background-color: transparent !important;
  }
  .ant-radio-checked {
    .ant-radio-inner {
      background-color: #1890ff  !important;

      &::after {
        background-color: #fff;
      }
    }
  }
`;

const PurpleRadioButton = styled(Radio)<{blueColor?: boolean}>`
  .ant-radio-inner {
    background-color: transparent !important;

    &:hover {
      border-color: ${p => p?.blueColor ? '#2D6CCA' : '#7F4EAD'} !important;
    }
  }
  .ant-radio-checked {
    .ant-radio-inner {
      background-color: ${p => p?.blueColor ? '#2D6CCA' : '#7F4EAD'} !important;
      border-color: ${p => p?.blueColor ? '#2D6CCA' : '#7F4EAD'} !important;

      &::after {
        background-color: #fff;
      }
    }
  }
`;

const InputLabelWrapper = styled.div`
  display: flex;

  .ant-input {
    height: 30px;
    border-radius: 5px;
  }

  .ant-select-selector {
    border-radius: 5px !important;
  }

  .ant-form-item {
    width: 200px;
  }
`;

const WpNewPostSection = styled.div<{isActive: boolean}>`
  padding: 20px 10px;
  background-color: ${p => p.isActive ? '#F9F9FB' : '#fff'};
`;

const WpUpdatePostSection = styled.div<{isActive: boolean}>`
  padding: 20px 10px;
  background-color: ${p => p.isActive ? '#F9F9FB' : '#fff'};
`;

const FormLabel = styled.span`
  display: inline-block;
  width: 105px;
  margin-top: 5px;
  font-size: 12px;
  position: relative;
`;

const OptionalTag = styled.div`
position: absolute;
top: 12px;
left: 0px;
font-size: 10px;
width: 105px;
`;

const InputsContainer = styled.div`
  padding-left: 25px;
`;

const WpLastSyncLeft = styled.div`
  font-size: 12px;
  color: #4e5156;
`;
const ErrorNotification = styled.div`
  width: 340px;
  height: auto;
  background-color: #feecec;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  padding: 8px 14px;
  font-size: 12px;
  display: flex;
`;
const ErrorNotificationContainer = styled.div`
  width: 100%;
  background-color: #F9F9FB;
  padding-top: 10px;
`;
const VideoTutorialBtn = styled.div`
  font-size: 12px;
  color: #2D6CCA;
  cursor: pointer;
`;
const WpSyncedPostDetails = styled.div`
  display: flex;
`;
const WpStatusBox = styled.div`
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  padding: 3px 8px;
  color: #4E5156;
  font-size: 12px;
  margin-right: 10px;
  background-color: #F9F9FB;
`;
const WpSyncInfoBox = styled.div<{isSynced: boolean}>`
  border: ${p => p.isSynced ? '1px solid rgba(31, 172, 71, 0.5)' : '1px solid rgba(255, 133, 54, 0.5);'};
  background-color: ${p => p.isSynced ? 'rgba(31, 172, 71, 0.1)' : 'rgba(255, 133, 54, 0.1)'};
  border-radius: 5px;
  padding: 3px 8px;
  color: ${p => p.isSynced ? '#1FAC47' : '#FF8536'};
  font-size: 12px;
`;
// const WpLastSyncRight = styled.div<{isSynced?: boolean}>`
//   font-size: 12px;
//   color: ${p => p.isSynced ? '#1FAC47' : '#FF8536'};
// `;
const PublishButton = styled(V2Button)`
  display: block;
  margin: 15px 0 0 0;
`;

const SyncFailedIndictor = styled(WpButtonStyled)`
  color: #F44343;
  border: 1px solid rgba(244, 67, 67, 0.5);
  background-color: rgba(244, 67, 67, 0.1);
  &:hover {
    background: rgba(244, 67, 67, 0.1) !important;
  }
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 6px !important;
  .ant-form-item-explain-error{
    font-size: 12px;
  }
`;
