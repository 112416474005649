import styled from 'styled-components';
import {Input as AntdInput, Col, Form, Select as AntdSelect, Switch, Radio, Button, Modal} from 'antd';
import {opaqueColor} from '@/utils/colors';

const {TextArea} = AntdInput;

export const SectionTitle = styled.div`
  margin-bottom: 5px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  text-align: left;
  color: #515151;
`;


export const Input = styled(AntdInput)`
  width: 100%;
  height: 32px;
  border-radius: 8px;
  border: 1px solid #D2D2D2;
   &.error-border {
    border: 1px solid #FF8536 !important;
  }
`;

export const Label = styled.div`
  margin-bottom: 6px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  color: #4E5156;
`;

export const StyledCol = styled(Col)`
  max-height: 700px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 102% !important;
  padding-right: 15px !important;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(78, 81, 86, 0.4);
    border-radius: 12px;
  }

`;

export const StyledFormItem = styled(Form.Item)`
  margin-bottom: 10px;
  .ant-form-item-explain-error {
    font-size: 12px !important;
  }
    .ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-select-selector,
.ant-form-item-has-error textarea {
  border-color: orange !important;
  border: 1px solid red !important;
}

`;

export const Textarea = styled(TextArea)`
  width: 100%;
  height: 80px;
  border-radius: 8px;
  border: 1px solid #D2D2D2;
   &.error-border {
    border: 1px solid #FF8536 !important;
  }

`;

export const SectionDivider = styled.div`
  width: 100%;
  height: 1px;
  margin: 0px 21px;
  transform: rotate(-180deg);
  background: linear-gradient(90deg, rgba(217, 217, 217, 0) 16.5%, #D9D9D9 100%);
`;

export const Select = styled(AntdSelect)`
  width: 100%;
  height: 32px;
  .ant-select-selector {
    border-radius: 8px !important;
    border: 1px solid #D2D2D2 !important;
  }
  &.error-border .ant-select-selector {
    border: 1px solid #FF8536 !important;
    border-radius: 8px !important;
  }
`;

export const InputCustomVoiceBtn = styled.div`
cursor: pointer;
font-size: 12px;
color: #2D6CCA;

&:hover {
  text-decoration: underline;
}
`;

export const ToneOfVoiceSingleOption = styled.div`
  cursor: pointer;
  font-size: 12px;

  &:hover {
    color: #2D6CCA;
    text-decoration: underline;
  }
`;

export const SelectContainer = styled.div`
  margin-bottom: 6px;
  font-size: 12px;
  justify-content: space-between;
`;

export const ToneOfVoice = styled.div`
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 8px;
  padding: 4px 11px;
  cursor: pointer;
  display: flex;
  border: 1px solid #D2D2D2;
`;

export const SwitchStyled = styled(Switch)`
  width: 30px;
  min-width: 30px;
  height: 12px;
  margin-right: 10px;
  background: #A7B2C3;

  .ant-switch-handle {
    height: 15px;
    width: 15px;
    margin-top: -4px;
  }

  &.ant-switch-checked {
    background: ${opaqueColor('#7F4EAD', 30)} !important;
    .ant-switch-handle {
      &::before {
        background: #7F4EAD;
      }
     
    }
  }
`;

export const Terms = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
  margin-top: 8px;
`;

export const Pill = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  width: fit-content;
  height: 22px;
  padding: 0px 10px;
  border-radius: 38px;
  background: var(--White, #FFFFFF);
  border: 1px solid #D2D2D2;
  svg {
    cursor: pointer;
  }
`;

export const ClearAll = styled.div`
  margin-left: 10px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #2D6CCA;
  cursor: pointer;
`;

export const BlueTextButton = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #2D6CCA;
  cursor: pointer;
  white-space: nowrap;
`;

export const StyledRadio = styled(Radio)`
.ant-radio-inner{
 border-color: #7F4EAD;
}
.ant-radio-inner::after{
  background-color: #7F4EAD;
}
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 31px;
  margin-top: 16px;
`;

export const CancelButton = styled.button`
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: left;
  color: #4E5156;
  background-color: transparent;
  border: 0px;
  cursor: pointer;
`;

export const UpdateButton = styled(Button)`
  width: 157px;
  height: 44px;
  border-radius: 8px;
  background-color: #7F4EAD;
  color: #FFFFFF;
  border-color: #7F4EAD;
  &:hover, &:focus {
    background-color: #7F4EAD;
    color: #FFFFFF;
    border-color: #7F4EAD;
  }
`;

export const FooterDivider = styled.div`
  width: 737px;
  height: 1px;
  background-color: #D9D9D9;
  position: absolute;
  left: -211px;
`;

export const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 17px;
  svg {
    width: 16.26px;
    height: 16.26px;
    cursor: pointer;
  }
`;

export const ModalHeading = styled.div`
  display: flex;
  align-items: center;
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: #121212;
`;

export const Container = styled.div`
  @keyframes highlightNotification {
    0% {
      background: rgba(127, 78, 173, 0.1);
    }
    100% {background: #fff;}
  }

  .selected-bg {
    animation: highlightNotification 1.5s forwards;
  } 
  #container {
    padding: 15px 21px;
    border-radius: 12px;
  }
  .install-shopify-banner {
      display: flex;
      gap: 15px;
      padding: 14px 18px;
      background: #174564;
      border-radius: 12px;
      .shopify-logo {
        svg {
          width: 21px;
          height: 24px;
        }
      }
      .banner-title {
        margin-bottom: 4px;
        font-family: Inter;
        font-size: 14px;
        font-weight: 600;
        line-height: 16.94px;
        text-align: left;
        color: #FFFFFF;
      }
      .banner-desc {
        margin-bottom: 11px;
        font-family: Inter;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.52px;
        text-align: left;
        color: #F9F9FB;
      }
      .view-instructions-button {
        width: 122px;
        height: 31px;
        border-radius: 8px;
        background: #5B8A3C;
        border: 0px;
        color: #FFFFFF;
        cursor: pointer;
        font-family: Inter;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.52px;
      }
    }
`;

export const StyledModal = styled(Modal)`
margin-bottom: 50px;
.inputs-dv{
  display: flex;
}
@media screen and (max-width: 790px) {
  .inputs-dv{
  display: block;
}
}
.right-input-dv{
  margin-left: 20px;
}
@media screen and (max-width: 790px) {
  .right-input-dv{
  margin-left: 0px;
  margin-top: 10px;
}
}
.ant-modal-content{
border-radius: 8px !important;
overflow: hidden;
overflow-y: auto;
}
.settings-div{
max-width: 660px;
min-height: 160px;
flex-shrink: 0;
border-radius: 8px;
border: 1px solid var(--text-gray-light, #E8E8E8);
background: var(--Table-row-stripe, #F9F9FB);
}
padding: 29px 25px;
.heading-modal-dv{
  margin-top: 12px;
  .heading-modal-txt{
    color: var(--Black, #121212);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 19px !important;
  }
}
.label{
  color: var(--text-black, var(--Black, #121212));
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; 
}
.update-project-modal-footer{
  display: flex;
  justify-content: space-between;
  .cancel-btn{
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #4E5156;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .update-btn{
    border: none;
    color: white;
    display: flex;
    width: 187px;
    height: 44px;
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 6px;
    background: var(--main-green, #1FAC47);
    cursor: pointer;
  }
}
`;

export const Option = styled.div<{isActive?: boolean}>`
display: flex;
align-items: center;
gap: 8px;
width: fit-content;
margin-top: 2px;
padding: 8px 12px;
border-radius: 8px;
cursor: pointer;
background-color: ${p => p?.isActive ? 'rgba(127, 78, 173, 0.15)' : ''};
svg {
  color: ${p => p?.isActive ? '#7F4EAD' : '#4E5156'};
  width: 17.95px;
  height: 18px;
}
a {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  text-align: left;
  color: ${p => p?.isActive ? '#7F4EAD' : '#4E5156'};
}
`;

export const FreezeBanner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8.88px;
  margin-bottom: 8px;
  width: 100%;
  height: 38px;
  border-radius: 12px;
  background: #F1AA3E26;
  box-shadow: 0px 3px 2px 0px #00000005;
  .txt {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: left;
    color: #FFFFFF;
    span {
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 16.94px;
      text-align: left;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export const AddShopifySiteModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-body {
    background-color: #174564;
    border-radius: 8px;
  }
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.15);
      svg {
        width: 21px;
        height: 24px;
      }
    }
    .title {
      margin-top: 21px;
      font-family: Inter;
      font-size: 24px;
      font-weight: 600;
      line-height: 28px;
      text-align: left;
      color: #FFFFFF;
    }
    .desc {
      margin-top: 8px;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      text-align: center;
      color: #FFFFFF;
    }
    .desc1 {
      margin-top: 25px;
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      text-align: center;
      color: #E8E8E8;
    }
    .install-button {
      margin-top: 15px;
      width: 100%;
      height: 50px;
      border-radius: 8px;
      background: #5B8A3C;
      border: 0px;
      color: #FFFFFF;
      cursor: pointer;
      svg {
        width: 14px;
        height: 14px;
      }
    }
    .contact-support-button {
      font-family: Inter;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      text-align: center;
      text-decoration-line: underline;
      text-decoration-style: solid;
      color: #34AEF3;
      cursor: pointer;
    }
  }
`;
