import React, {useEffect, useRef} from 'react';
import {
  aspectRatioOptions,
  colSpan,
  rowgutter,
} from './commonFolderSettingsUtils';
import {Col, Row} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import useIntersectionObserver from './useIntersectionObserver';
import {
  Container,
  Label,
  SectionTitle,
  Select,
  StyledFormItem,
  SwitchStyled,
  Textarea,
  // Input,
} from './styles';
import {LargeBadge} from '@/components/common-components/components/badge';

const ImagesSection = ({form, selectedSection, setActiveOption}) => {
  const blockref = useRef(null);
  const blockEntry = useIntersectionObserver(blockref, {
    threshold: [0.3, 0.5, 0.7, 0.9, 1.0],
  });

  const isVisible = !!blockEntry?.isIntersecting;
  useEffect(() => {
    if (isVisible) {
      setActiveOption('images');
    }
  }, [isVisible]);


  return (
    <Container id='images' ref={blockref}>
      <div className={selectedSection === 'images' ? 'selected-bg' : ''} id='container'>
        <SectionTitle style={{marginBottom: '0px'}}>IMAGES</SectionTitle>
        <StyledFormItem style={{marginBottom: '0px', marginTop: '0px'}} name='generate_hero_image' initialValue={true}>
          <SwitchStyled
            onChange={value => {
              const obj = {};
              obj['generate_hero_image'] = value;
              form.setFieldsValue(obj);
            }}
            defaultChecked={form.getFieldValue('generate_hero_image')}
          />
          Generate an article hero image
        </StyledFormItem>
        <StyledFormItem name='generate_images' initialValue={true}>
          <SwitchStyled
            onChange={value => {
              const obj = {};
              obj['generate_images'] = value;
              form.setFieldsValue(obj);
            }}
            defaultChecked={form.getFieldValue('generate_images')}
          />
          Generate one image per H2 section
        </StyledFormItem>
        <Row gutter={rowgutter}>
          {/* <Col span={colSpan}>
            <Label>Model</Label>
            <StyledFormItem
              name='images_engine'
              rules={[
                {required: true, message: 'This field is required'},
              ]}
            >
              <Input style={{color: 'black'}} placeholder='Model' disabled/>
            </StyledFormItem>
          </Col> */}
          <Col span={colSpan}>
            <Label>Aspect ratio</Label>
            <StyledFormItem
              name='images_aspect_ratio'
              rules={[
                {required: true, message: 'This field is required'},
              ]}>
              <Select
                onChange={value => {
                  const obj = {};
                  obj['images_aspect_ratio'] = value;
                  form.setFieldsValue(obj);
                }}
                placeholder='Aspect ratio'
                suffixIcon={<FontAwesomeIcon icon={faCaretDown} color='#4E5156' />}
              >
                {aspectRatioOptions.map((item, idx) => (
                  <Select.Option key={idx} value={item.value}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </StyledFormItem>
          </Col>
        </Row>
        <><div style={{display: 'flex', alignItems: 'center'}}><Label style={{width: 'fit-content'}}>AI Image Generation Prompt</Label><LargeBadge beta /></div><StyledFormItem
          name='images_additional_context'
        >
          <Textarea placeholder='Enter AI Image Generation Prompt' />
        </StyledFormItem></>
      </div>
    </Container>
  );
};

export default ImagesSection;
