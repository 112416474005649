import {observer} from 'mobx-react';
import {WildfireWrapper} from './styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowDown, faArrowUp, faAngleUp, faSearch, faAngleDown, faSpinnerThird, faCircleExclamation, faHourglassStart} from '@fortawesome/pro-regular-svg-icons';
import React, {useEffect, useState} from 'react';
import {Input, Table, Progress, Tooltip} from 'antd';
import {faCheckCircle, faCircleXmark} from '@fortawesome/pro-solid-svg-icons';
import {faCheckCircle as progressFull} from '@fortawesome/pro-duotone-svg-icons';
import {useStore} from '@/store/root-store';
import {OTTO_V2_API} from '@/api/otto-v2';
import {notification} from '@/utils/notification-v2';
import moment from 'moment';
import {apiError} from '@/utils/api';
import Image from 'next/image';
import styled from 'styled-components';
import {BannerNotification, notificationBannerProps, statusMapping} from '@/utils/notification-banner';

const notificationMapping = {
  INITIAL: {
    'message-inlinks': 'backlink you approved is reproved by the website owners. No backlink were added.',
    'message-outlinks': 'outlink you approved is reproved by the website owners. No outlink were added.',
    'status': 'error',
  },
  REQUESTED: {
    'message-inlinks': 'backlink you requested is approved by the website owners. No backlink were added',
    'message-outlinks': '',
    'status': 'info',
  },
  DENIED: {
    'message-inlinks': 'backlink you approved is reproved by the website owners. No backlink were added.',
    'message-outlinks': '',
    'status': 'error',
  },
  APPROVED: {
    'message-inlinks': 'backlink you approved is accepted by the website owners. Now your site has more backlink',
    'message-outlinks': 'outlink you requested is approved by the website owners. No outlink were added.',
    'status': 'success',
  },
  MONDAY_MESSAGE: {
    'message': 'backlinks suggestion and 10 outlink suggestion were added. Old suggestion and reproved links were removed.',
    'status': 'info',
  },
};

const initialNotificationBanner = {
  active: false,
  status: '',
  message: '',
  count: 0,
  showMore: false,
  type: '',
  index: -1,
};

const initialHighlightedIndex = {
  status: '',
  type: '',
  index: -1,
};

export const WILDFIREComponent = observer(() => {
  const [inlinksData, setInlinksData] = useState([]);
  const [outlinksData, setOutlinksData] = useState([]);
  const [pendingOutlinksData, setPendingOutlinksData] = useState([]);
  const {ottoV2Store: {getOttoV2Project}} = useStore('');
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState({
    pendingOutlinks: '',
    inlinks: '',
    outlinks: '',
  });
  const [showSearchInput, setShowSearchInput] = useState({
    inlinks: false,
    outlinks: false,
  });
  const [deployingIds, setDeployingIds] = useState([]);
  const [addBacklink, setAddBacklink] = useState(true);
  const [allowOutlinkRemove, setAllowOutlinkRemove] = useState(false);
  const [notificationBanner, setNotificationBanner] = useState<notificationBannerProps>(initialNotificationBanner);
  const [highlightedIndex, setHighlightedIndex] = useState(initialHighlightedIndex);
  const [mondayMessage, setMondayMessage] = useState({
    active: false,
    status: '',
    message: '',
    count: 0,
    showMore: false,
    type: '',
    index: -1,
  });
  let notificationIndex = -1;

  const filteredData = {
    pendingOutlinks: searchTerm?.pendingOutlinks ?
      pendingOutlinksData?.filter(item => item.isApproved ?
        (item?.renderData?.hostname?.includes(searchTerm?.pendingOutlinks) || item.linkOptions?.some(option => option.receiverUrl?.includes(searchTerm?.pendingOutlinks))) :
        item?.renderData?.hostname?.includes(searchTerm?.pendingOutlinks)) :
      pendingOutlinksData,
    inlinks: searchTerm?.inlinks ?
      inlinksData?.filter(item => item.isApproved ?
        (item?.renderData?.hostname?.includes(searchTerm?.inlinks) || item.linkOptions?.some(option => option.donorUrl?.includes(searchTerm?.inlinks))) :
        item?.renderData?.hostname?.includes(searchTerm?.inlinks)) :
      inlinksData,
    outlinks: searchTerm?.outlinks ?
      outlinksData?.filter(item => item.isApproved ?
        (item?.renderData?.hostname?.includes(searchTerm?.outlinks) || item.linkOptions?.some(option => option.receiverUrl?.includes(searchTerm?.outlinks))) :
        item?.renderData?.hostname?.includes(searchTerm?.outlinks)) :
      outlinksData,
  };


  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const today = new Date();
    if (today.getDay() === 1) {
      const hasUserHiddenMondayMessage = localStorage.getItem('hasUserHiddenMondayMessage');
      if (hasUserHiddenMondayMessage !== 'true') {
        setMondayMessage({
          active: true,
          status: notificationMapping.MONDAY_MESSAGE.status,
          message: notificationMapping.MONDAY_MESSAGE.message,
          count: 20,
          showMore: false,
          type: 'inlinks',
          index: 1,
        });
      } else {
        setMondayMessage({
          active: false,
          status: notificationMapping.MONDAY_MESSAGE.status,
          message: notificationMapping.MONDAY_MESSAGE.message,
          count: 20,
          showMore: false,
          type: '',
          index: -1,
        });
      }
    }
  }, []);

  useEffect(() => {
    if (notificationBanner?.showMore) {
      setHighlightedIndex({
        status: notificationBanner.status,
        type: notificationBanner.type,
        index: notificationBanner.index,
      });
      setTimeout(() => {
        setHighlightedIndex({
          status: '',
          type: '',
          index: -1,
        });
      }, (6000));
    }
  }, [notificationBanner?.showMore]);

  const getData = async () => {
    try {
      const response = await Promise.all(tables?.map(item => item?.type === 'pendingOutlinks' ? OTTO_V2_API.getWILDFIREData(getOttoV2Project?.uuid, {link_type: 'outlinks', link_status: 'REQUESTED'}) : (OTTO_V2_API.getWILDFIREData(getOttoV2Project?.uuid, {link_type: item.type}))));
      if (response?.length) {
        tables.forEach((item, index) => {
          if (item.type === 'inlinks') {
            if (!response[index]?.isCancel) {
              setInlinksData(response?.[index]?.results?.map(item => ({...item, renderData: item?.donor, opposite: item?.receiver})));
              setAddBacklink(response?.[index]?.quota?.canAddInlink);
              if (!response?.[index]?.results?.length) {
                setTables(prev => prev.map((table, i) => i === index ? {...table, isExpanded: false} : table));
              }
            }
          } else if (item.type === 'pendingOutlinks') {
            if (!response?.[index]?.isCancel) {
              setPendingOutlinksData(response?.[index]?.results?.map(item => ({...item, renderData: item?.receiver, opposite: item?.donor})));
              if (!response?.[index]?.results?.length) {
                setTables(prev => prev.map((table, i) => i === index ? {...table, isExpanded: false} : table));
              }
            }
          } else {
            if (!response?.[index]?.isCancel) {
              const withoutRequested = response?.[index]?.results?.filter(item => item?.status !== 'REQUESTED');
              setOutlinksData(withoutRequested?.map(item => ({...item, renderData: item?.receiver, opposite: item?.donor})));
              setAllowOutlinkRemove(response?.[index]?.quota?.canRemoveOutlink);
              if (!withoutRequested?.length) {
                setTables(prev => prev.map((table, i) => i === index ? {...table, isExpanded: false} : table));
              }
            }
          }
        });
      }
    } catch (e) {
      notification.error('Error', 'Failed to fetch data');
    } finally {
      setLoading(false);
    }
  };

  const [tables, setTables] = useState([
    {
      type: 'pendingOutlinks',
      isExpanded: true,
    },
    {
      type: 'outlinks',
      isExpanded: true,
    },
    {
      type: 'inlinks',
      isExpanded: true,
    },
  ]);

  const deployLink = async (obj, type, status, index) => {
    try {
      setDeployingIds(prev => [...prev, obj?.backlinkId]);
      setDeployingIds(prev => [...prev, obj?.backlinkId]);
      setTables(prev => prev?.map((item, index) => !index ? {...item, isLoading: true} : item));
      await OTTO_V2_API.communityBacklinksChangeStatus(getOttoV2Project?.uuid, {backlink_id: obj?.backlinkId, link_type: type, to_status: status});
      getData();
      setNotificationBanner({
        active: true,
        status: notificationMapping[status].status,
        message: notificationMapping[status][`message-${type}`],
        count: 1,
        showMore: false,
        type,
        index,
      });
    } catch (e) {
      const errorMessage = apiError(e);
      notification.error('Error', errorMessage);
    } finally {
      setTables(prev => prev?.map((item, index) => !index ? {...item, isLoading: false} : item));
      setDeployingIds(prev => prev?.filter(item => item != obj?.backlinkId));
      setDeployingIds(prev => prev?.filter(item => item != obj?.backlinkId));
    }
  };

  const spinner = <FontAwesomeIcon icon={faSpinnerThird} color={'#2AC155'} spin/>;

  const columns = type => ([
    {
      title: 'STATUS',
      dataIndex: 'isApproved',
      key: 'isApproved',
      className: 'border-right',
      align: 'left' as 'left',
      sorter: (a, b) => {
        const statusOrder = {
          'APPROVED': 1,
          'REQUESTED': 2,
          'INITIAL': 3,
          'DENIED': 4,
        };
        return statusOrder[a.status] - statusOrder[b.status];
      },
      width: type == 'inlinks' ? 120: 170,
      render: (_, obj, index) => {
        return (
          <div style={{cursor: deployingIds?.includes(obj?.backlinkId) || (getOttoV2Project?.pixelTagState === 'not_installed' || getOttoV2Project?.pixelTagState === 'wrong_uuid') ? 'not-allowed' : 'pointer'}}>
            {
              type == 'inlinks' ?
                <>
                  {
                    obj?.status === 'REQUESTED' ?
                      <>
                        <Tooltip title='Your backlink request is being reviewed by the site owner'>
                          <div style={{color: '#f0aa3e'}}><FontAwesomeIcon icon={faHourglassStart} color={'#f0aa3e'}/> Pending</div>
                        </Tooltip>
                      </>:
                      obj?.status === 'APPROVED' ?
                        <>
                          {deployingIds?.includes(obj?.backlinkId) ? <div style={{textAlign: 'center'}}>{spinner}</div> :
                            <div onClick={() => (!deployingIds?.includes(obj?.backlinkId) && !((getOttoV2Project?.pixelTagState === 'not_installed' || getOttoV2Project?.pixelTagState === 'wrong_uuid')) && deployLink(obj, type, 'INITIAL', index))}
                              style={{color: '#219843', pointerEvents: deployingIds?.includes(obj?.backlinkId) ? 'none' : 'auto'}}>
                              <Tooltip title={(getOttoV2Project?.pixelTagState === 'not_installed' || getOttoV2Project?.pixelTagState === 'wrong_uuid') ? 'OTTO Pixel not integrated properly' : (type == 'outlinks' && obj?.status === 'APPROVED' && !allowOutlinkRemove) ? 'Remove one backlink in order to remove this outlink' : ''}>
                                <FontAwesomeIcon icon={faCheckCircle} style={{marginRight: 5}} color={'#2AC155'}/>
                                Active
                              </Tooltip>
                            </div>
                          }
                        </>:
                        obj?.status === 'DENIED' ?
                          <>
                            {deployingIds?.includes(obj?.backlinkId) ? <div style={{textAlign: 'center'}}>{spinner}</div> :
                              <div onClick={() => (!deployingIds?.includes(obj?.backlinkId) && !((getOttoV2Project?.pixelTagState === 'not_installed' || getOttoV2Project?.pixelTagState === 'wrong_uuid')) && deployLink(obj, type, 'INITIAL', index))}
                                style={{color: '#F44343', pointerEvents: deployingIds?.includes(obj?.backlinkId) ? 'none' : 'auto'}}>
                                <Tooltip title={(getOttoV2Project?.pixelTagState === 'not_installed' || getOttoV2Project?.pixelTagState === 'wrong_uuid') ? 'OTTO Pixel not integrated properly' : 'The site owner has declined this backlink request. Please submit a request to a different site.'}>
                                  <FontAwesomeIcon icon={faCircleXmark} style={{marginRight: 5}} color={'#F44343'}/>
                                  Reproved
                                </Tooltip>
                              </div>
                            }
                          </>:
                          <div onClick={() => (!deployingIds?.includes(obj?.backlinkId) && !((getOttoV2Project?.pixelTagState === 'not_installed' || getOttoV2Project?.pixelTagState === 'wrong_uuid')) && deployLink(obj, type, 'REQUESTED', index))}
                            style={{color: '#A3A4A4', pointerEvents: deployingIds?.includes(obj?.backlinkId) ? 'none' : 'auto'}}>
                            {deployingIds?.includes(obj?.backlinkId) ? spinner :
                              <>
                                <Tooltip title={(getOttoV2Project?.pixelTagState === 'not_installed' || getOttoV2Project?.pixelTagState === 'wrong_uuid') ? 'OTTO Pixel not integrated properly' : ''}>
                                  <FontAwesomeIcon icon={faCheckCircle} style={{marginRight: 5}} color='#A3A4A4'/>
                                  Request
                                </Tooltip>
                              </>
                            }
                          </div>
                  }
                </>:
                <>
                  {
                    obj?.status === 'REQUESTED' ?
                      <>
                        {deployingIds?.includes(obj?.backlinkId) ? <div style={{textAlign: 'center'}}>{spinner}</div> :
                          <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div onClick={() => (!deployingIds?.includes(obj?.backlinkId) && !((getOttoV2Project?.pixelTagState === 'not_installed' || getOttoV2Project?.pixelTagState === 'wrong_uuid')) && deployLink(obj, type, 'APPROVED', index))}
                              style={{color: '#A3A4A4', pointerEvents: deployingIds?.includes(obj?.backlinkId) ? 'none' : 'auto'}}>
                              <Tooltip title={(getOttoV2Project?.pixelTagState === 'not_installed' || getOttoV2Project?.pixelTagState === 'wrong_uuid') ? 'OTTO Pixel not integrated properly' : ''}>
                                <FontAwesomeIcon icon={faCheckCircle} style={{marginRight: 5}} color={'#A3A4A4'}/>
                                  Approve
                              </Tooltip>
                            </div>
                            <div onClick={() => (!deployingIds?.includes(obj?.backlinkId) && !((getOttoV2Project?.pixelTagState === 'not_installed' || getOttoV2Project?.pixelTagState === 'wrong_uuid')) && deployLink(obj, type, 'DENIED', index))}
                              style={{color: '#A3A4A4', pointerEvents: deployingIds?.includes(obj?.backlinkId) ? 'none' : 'auto'}}>
                              <Tooltip title={(getOttoV2Project?.pixelTagState === 'not_installed' || getOttoV2Project?.pixelTagState === 'wrong_uuid') ? 'OTTO Pixel not integrated properly' : ''}>
                                <FontAwesomeIcon icon={faCircleXmark} style={{marginRight: 5}} color={'#A3A4A4'}/>
                                  Deny
                              </Tooltip>
                            </div>
                          </div>
                        }
                      </>:
                      obj?.status === 'APPROVED' ?
                        <>
                          {deployingIds?.includes(obj?.backlinkId) ? <div style={{textAlign: 'center'}}>{spinner}</div> :
                            <div onClick={() => (!deployingIds?.includes(obj?.backlinkId) && !((getOttoV2Project?.pixelTagState === 'not_installed' || getOttoV2Project?.pixelTagState === 'wrong_uuid')) && deployLink(obj, type, 'INITIAL', index))}
                              style={{color: '#219843', pointerEvents: deployingIds?.includes(obj?.backlinkId) ? 'none' : 'auto'}}>
                              <Tooltip title={(getOttoV2Project?.pixelTagState === 'not_installed' || getOttoV2Project?.pixelTagState === 'wrong_uuid') ? 'OTTO Pixel not integrated properly' : (type == 'outlinks' && obj?.status === 'APPROVED' && !allowOutlinkRemove) ? 'Remove one backlink in order to remove this outlink' : ''}>
                                <FontAwesomeIcon icon={faCheckCircle} style={{marginRight: 5}} color={'#2AC155'}/>
                                  Active
                              </Tooltip>
                            </div>
                          }
                        </>:
                        obj?.status === 'DENIED' ?
                          <>
                            {deployingIds?.includes(obj?.backlinkId) ? <div style={{textAlign: 'center'}}>{spinner}</div> :
                              <div onClick={() => (!deployingIds?.includes(obj?.backlinkId) && !((getOttoV2Project?.pixelTagState === 'not_installed' || getOttoV2Project?.pixelTagState === 'wrong_uuid')) && deployLink(obj, type, 'INITIAL', index))}
                                style={{color: '#F44343', pointerEvents: deployingIds?.includes(obj?.backlinkId) ? 'none' : 'auto'}}>
                                <Tooltip title={(getOttoV2Project?.pixelTagState === 'not_installed' || getOttoV2Project?.pixelTagState === 'wrong_uuid') ? 'OTTO Pixel not integrated properly' : 'The site owner has declined this backlink request. Please submit a request to a different site.'}>
                                  <FontAwesomeIcon icon={faCircleXmark} style={{marginRight: 5}} color={'#F44343'}/>
                                    Reproved
                                </Tooltip>
                              </div>
                            }
                          </>:
                          <div onClick={() => (!deployingIds?.includes(obj?.backlinkId) && !((getOttoV2Project?.pixelTagState === 'not_installed' || getOttoV2Project?.pixelTagState === 'wrong_uuid')) && deployLink(obj, type, 'APPROVED', index))}
                            style={{color: '#A3A4A4', pointerEvents: deployingIds?.includes(obj?.backlinkId) ? 'none' : 'auto'}}>
                            {deployingIds?.includes(obj?.backlinkId) ? spinner :
                              <>
                                <Tooltip title={(getOttoV2Project?.pixelTagState === 'not_installed' || getOttoV2Project?.pixelTagState === 'wrong_uuid') ? 'OTTO Pixel not integrated properly' : ''}>
                                  <FontAwesomeIcon icon={faCheckCircle} style={{marginRight: 5}} color={ '#A3A4A4'}/>
                                    Approve
                                </Tooltip>
                              </>
                            }
                          </div>
                  }
                </>
            }
          </div>
        );
      },
    },
    {
      title: type == 'inlinks' ? 'REFERRING PAGE' : 'TARGET PAGE',
      dataIndex: 'linkOptions',
      key: 'hostname',
      align: 'left' as 'left',
      sorter: (a, b) => a.renderData.hostname?.localeCompare(b.renderData.hostname),
      width: 300,
      render: (item, task) => {
        const url = item?.find(val => val.isApproved)?.[(type == 'inlinks' ? 'donorUrl' : 'receiverUrl')] ?? task?.renderData?.hostname;
        return (
          <div className='page-cell' onClick={() => window.open(url?.includes('http') ? url : 'https://' + url, '_blank')}> {url}</div>);
      },
    },
    {
      title: 'REFERRING PAGE',
      dataIndex: 'linkOptions',
      key: 'referringPage',
      align: 'left' as 'left',
      width: 300,
      hidden: type == 'inlinks',
      render: item => {
        const url = item?.find(val => val.isApproved)?.donorUrl ?? '-';
        return (
          <div className='page-cell' onClick={() => window.open(url?.includes('http') ? url : 'https://' + url, '_blank')}> {url}</div>);
      },
    },
    {
      title: 'DOMAIN POWER',
      key: 'domainPower',
      align: 'right' as 'right',
      width: 150,
      sorter: (a, b) => a.renderData.dp - b.renderData.dp,
      render: item => {
        const difference = item?.renderData?.dp - item?.opposite?.dp;
        return <div>{item?.renderData?.dp ?
          (difference == 0 ? item?.renderData?.dp + ' (same as you)': `${item?.renderData?.dp} (${(difference > 0 ? '+' : '') + difference} vs you)`) : '-'}</div>;
      },
    },
    {
      title: 'ORGANIC TRAFFIC',
      dataIndex: ['renderData', 'ot'],
      key: 'ot',
      align: 'center' as 'center',
      width: 150,
      sorter: (a, b) => a.renderData.ot - b.renderData.ot,
      render: item => <div>{item ?? '-'}</div>,
    },
    {
      title: 'TOPICALITY',
      dataIndex: 'projectScore',
      key: 'topicality',
      align: 'right' as 'right',
      width: 150,
      sorter: (a, b) => a.projectScore - b.projectScore,
      render: item => <div>{item + '%'}</div>,
    },
    {
      title: 'ADDED ON',
      dataIndex: 'isApprovedAt',
      key: 'isApprovedAt',
      align: 'right' as 'right',
      width: `${type == 'inlinks' ? 'max-content' : 140}`,
      sort: true,
      sorter: (a, b) => moment(b.isApprovedAt ?? 0).unix() - moment(a.isApprovedAt ?? 0).unix(),
      render: item => <div>{item ? moment(item).format('MMM DD, YYYY') : '-'}</div>,
    },
  ]?.filter(item => !item?.hidden));

  const getIcon = () => {
    const approvedCount = inlinksData?.filter(item => item.isApproved)?.length;
    if (approvedCount == inlinksData?.length && inlinksData?.length !== 0) {
      return <FontAwesomeIcon icon={progressFull} color='#2AC155' />;
    } else {
      return <Tooltip title={inlinksData?.length ? `Approved Backlinks: ${approvedCount}/${inlinksData?.length}` : ''}><Progress
        type='circle'
        trailColor='rgb(229 229 229)'
        percent={(approvedCount/inlinksData?.length)*100}
        strokeColor='#2D6CCA'
        width={16}
        strokeWidth={14}
        gapDegree={0}
        size='small'
        showInfo={false}
      /></Tooltip>;
    }
  };
  const [pagination, setPagination] = useState({
    inlinks: {pageSize: 10, current: 1},
    outlinks: {pageSize: 10, current: 1},
  });

  const handleTableChange = (paginationConfig, type) => {
    setPagination(prev => ({
      ...prev,
      [type]: {
        pageSize: paginationConfig.pageSize,
        current: paginationConfig.current,
      },
    }));
  };

  tables.map((table, index) => {
    if (table.type === notificationBanner.type) {
      notificationIndex = index;
    }
  });

  return (
    <WildfireWrapper loading={loading}>
      {!loading && mondayMessage && mondayMessage.index === 1 ?
        <BannerNotification state={mondayMessage} setState={setMondayMessage} hideShowMore={true} delay={0}/> : <></>}
      { loading ? <FontAwesomeIcon spin icon={faSpinnerThird} color={'#2D6CCA'} fontSize={100} className='loader'/> :
        tables?.map((item, tableIndex) => {
          const pendingOutlinksCount = item?.type === 'pendingOutlinks' ? filteredData[item.type]?.length : 0;
          return (
            <>
              { tableIndex === notificationIndex ?
                <BannerNotification state={notificationBanner} setState={setNotificationBanner}/> : <></>
              }
              <div key={item.type} className='section' style={{paddingBottom: !item?.isExpanded && 0}}>
                <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}} >
                  <div className='table-header-wrapper'>
                    <FontAwesomeIcon icon={item.type == 'outlinks' ? faArrowUp : faArrowDown} />
                    <div className='table-heading'>{item.type == 'inlinks' ? 'Backlinks' :
                      item.type === 'pendingOutlinks' ? `${pendingOutlinksCount} pending outlink requests` : 'Outlinks'} {item.type === 'pendingOutlinks' ? '' :
                      item.type == 'inlinks' ? 'to' : 'from'}
                    {item.type == 'pendingOutlinks' ? '' : <span onClick={() => window.open(getOttoV2Project?.hostname?.includes('http') ? getOttoV2Project?.hostname : 'https://' + getOttoV2Project?.hostname, '_blank')}>{` ${getOttoV2Project?.hostname}`}</span>}
                    </div>
                    <FontAwesomeIcon icon={faSearch} className='search' onClick={() => {
                      setShowSearchInput(prev => ({...prev, [item.type]: !prev[item.type]}));
                      searchTerm?.[item.type] && setSearchTerm(prev => ({...prev, [item.type]: ''}));
                    }}/>
                    {showSearchInput?.[item.type] && <Input className='search-input' value={searchTerm[item.type]} onChange={e => setSearchTerm(prevStat => ({...prevStat, [item.type]: e.target.value}))}
                      placeholder={`${item.type == 'inlinks' ? 'Referring page' : 'Target page'}`}/>}
                    {item.type == 'inlinks' && getIcon()}
                    <FontAwesomeIcon className='icon' icon={item.isExpanded ? faAngleUp : faAngleDown} onClick={() => setTables(prevItems => prevItems.map((item, index) => index === tableIndex ? {...item, isExpanded: !item.isExpanded} : item))}/>
                    {item.type == 'inlinks' && <div className='add-more'>
                      {addBacklink && <div><Image src={'/icons/check.png'} width={14} height={14} alt='Check Icon'/> You can add more backlinks</div>}
                      {!addBacklink && <div className='not-available'>
                        <FontAwesomeIcon icon={faCircleExclamation}/>
                        <div className='not-available-text'>To receive more backlinks, you need to add at least 2 outlinks first </div>
                        <div className='ratio'>2:1 ratio required</div>
                      </div>}
                    </div>}
                  </div>
                </div>
                {item.isExpanded && <TableStyled
                  dataSource={filteredData[item.type]}
                  notificationStatus={highlightedIndex.status}
                  rowClassName={(record, index) => highlightedIndex.type === item.type && highlightedIndex.index === index ? 'table-row-light' : ''}
                  columns={columns(item.type === 'pendingOutlinks' ? 'outlinks' : item.type)}
                  scroll={{x: 'max-content'}}
                  pagination={{
                    ...pagination[item.type === 'pendingOutlinks' ? 'outlinks' : item.type],
                    total: filteredData[item.type]?.length,
                    showSizeChanger: true,
                    showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`,
                  }}
                  onChange={paginationConfig => handleTableChange(paginationConfig, item.type === 'pendingOutlinks' ? 'outlinks' : item.type)}
                />}
              </div>
            </>
          );
        })
      }
    </WildfireWrapper>
  );
});

const TableStyled = styled(Table)<{notificationStatus: string}>`
 .ant-table-tbody {
    .ant-table-cell {
      &.border-right {
        border-right: 1px solid #f0f0f0 !important;
      }
    }
  }
  .table-row-light {
    background: ${p => (statusMapping[p.notificationStatus]?.backgroundColor || '')};
}
`;
