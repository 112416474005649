import {Button, Modal} from '@/components/common-components';
import {faCaretDown, faCircleCheck, faPen, faRotate, faSortDown, faSortUp, faTimes, faXmark} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ButtonComponent from '@/components/common-components/components/button-component';
import {observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {DatePicker, Dropdown, Empty, Input, InputNumber, Radio, Spin, Tooltip} from 'antd';
import {faCalendar, faCommentCheck, faCommentQuestion, faLayerGroup, faLocationDot} from '@fortawesome/pro-duotone-svg-icons';
import {CaretDownOutlined, LoadingOutlined} from '@ant-design/icons';
import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';
import {useStore} from '@/store/root-store';
import {getSingleUrlParam} from '@/utils/url';
import {faArrowRight, faMagnifyingGlass, faTrash} from '@fortawesome/pro-regular-svg-icons';
import {notification} from '@/utils/notification-v2';
import {getOttoTablePageSize} from '@/api/common-utils';
import {getStandAloneLocations} from '@/utils/functions';

interface GenerateModalProps {
  openGenerateQAModal: boolean;
  setOpenGenerateQAModal: (value: boolean) => void;
  standaloneTool?: boolean;
  selectedGbpData?: object;
  isV2?: boolean;
  currentBusiness?:any;
}
const step1Options = {
  questionOption: {
    option: 'write-with-ai',
  },
  answerOption: {
    option: 'write-with-ai',
  },
};
export const GenerateQAModal: React.FC<GenerateModalProps> = observer(({openGenerateQAModal, currentBusiness, setOpenGenerateQAModal, standaloneTool, selectedGbpData, isV2}) => {
  const {ottoV2Store: {loadIssueTableData, businessesList, questionBulkAiGenerate, generateQAContent, generatedBulkQA, onEditQA, editingQA, loadingGenerateBulkQA, addingQA, addQuestionAnswer, setSearchText, getSelectedLocation, gbpLocations},
    settings: {
      customer: {getCustomerQuota},
    }} = useStore('');
  const [step1OptionStates, setStep1OptionStates] = useState(step1Options);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [quantity, setQuantity] = useState<number>(1);
  const [step, setStep] = useState<1 | 2>(1);
  const [date, setDate] = useState<any>(moment());
  const [generatingIndex, setGeneratingIndex] = useState('');
  const uuid = getSingleUrlParam('uuid');
  const [questionInputIdx, setQuestionInputIdx] = useState('');
  const [editingValue, setEditingValue] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [selectedGbp, setSelectedGbp] = useState<any>({});
  const [showGbpDropdown, setShowGbpDropdown] = useState(false);
  const [errorStates, setErrorStates] = useState({
    question: {message: 'Provide question prompt or select write your own.', error: false},
  });
  const [focusedSubmitButton, setFocusedSubmitButton] = useState(false);
  const onChangeQuantity = value => {
    if (value > 0) {
      setQuantity(value);
    }
  };
  const handleQuestionOption = e => {
    setErrorStates({...errorStates, question: {...errorStates['question'], error: false}});
    setStep1OptionStates({...step1OptionStates, questionOption: {...step1OptionStates['questionOption'], option: e.target.value}});
    setQuestion('');
  };
  const handleAnswerOption = e => {
    setStep1OptionStates({...step1OptionStates, answerOption: {...step1OptionStates['answerOption'], option: e.target.value}});
    setAnswer('');
  };
  const onChangeQuestionPrompt = e => {
    if (e.target.value) {
      setErrorStates({...errorStates, question: {...errorStates['question'], error: false}});
    }
    setQuestion(e?.target?.value);
  };
  const onChangeAnswerPrompt = e => {
    setAnswer(e?.target?.value);
  };

  const onSubmit = async () => {
    try {
      if (step === 1) {
        if (step1OptionStates['questionOption']?.option === 'write-with-ai' && !question) {
          setErrorStates({...errorStates, question: {...errorStates['question'], error: true}});
          return;
        }
        const payload = {
          count: quantity,
        };
        if (question) {
          payload['question_prompt'] = question;
        }
        if (answer) {
          payload['answer_prompt'] = answer;
        }
        await questionBulkAiGenerate(payload, moment(date).format('YYYY-MM-DD'), standaloneTool ? (isV2 ? currentBusiness?.id : selectedGbp?.id) : null, standaloneTool);
        setStep(2);
      } else if (step === 2 && generatedBulkQA?.length) {
        setQuestionInputIdx('');
        setEditingValue('');
        const emptyItemIndex = generatedBulkQA.findIndex(obj => (!obj?.question || !obj?.answer));
        if (emptyItemIndex !== -1) {
          notification.error('', `All questions and answers must be filled out before proceeding.`);
          return;
        }
        let payload;
        for (let index = 0; index < generatedBulkQA.length; index++) {
          payload = {
            content: generatedBulkQA[index]?.question,
            schedule_publishing_at: moment(generatedBulkQA[index]?.date).format('YYYY-MM-DD'),
            owners_answer: {
              content: generatedBulkQA[index]?.answer,
            },
          };
          if (payload?.content && payload?.owners_answer?.content) {
            await addQuestionAnswer(payload, standaloneTool ? (isV2 ? currentBusiness?.id : selectedGbp?.id) : null, standaloneTool);
          }
        }
        setSearchText('');
        notification.success('Success', 'Questions are generated successfully.');
        setOpenGenerateQAModal(false);
        setStep(1);
        const params = {
          uuid,
          issue_type: 'qanda_suggestions',
          page_size: getOttoTablePageSize('qanda_suggestions'),
          page: 1,
          is_loading: false,
        };
        if (standaloneTool) {
          params['isStandAlone'] = true;
        }
        await loadIssueTableData(params, false, false, null, isV2);
      }
      if (standaloneTool) {
        getCustomerQuota();
      }
    } catch (error) {
      setOpenGenerateQAModal(false);
      setStep(1);
    }
  };

  const generateQA = async (index, question, type: 'question' | 'answer') => {
    setGeneratingIndex(`${index}-${type}`);
    try {
      const payload = {
        question: question,
      };
      await generateQAContent(payload, type, standaloneTool, isV2 ? currentBusiness?.id : null);
      setGeneratingIndex('');
    } catch (error) {
      setGeneratingIndex('');
    }
  };
  const descriptionContainer = (index, item, type) => {
    return (
      type === 'question' ?
        <QuestionWrapper>
          {item?.question ? <>
            <div>
              <span>Q:</span>{item?.question}
            </div>
            <div>
              <Tooltip
                overlayInnerStyle={{backgroundColor: '#000000', borderRadius: '8px', padding: '7px 10px', width: '380'}}
                title={!generatingIndex &&'Edit'}
              >
                <FontAwesomeIcon
                  style={{cursor: generatingIndex ? 'not-allowed' : 'pointer', display: addingQA && 'none'}}
                  icon={faPen}
                  color={generatingIndex ? 'rgba(78, 81, 86, 50%)' : '#4E5156'}
                  fontSize={14}
                  onClick={() => {
                    if (!generatingIndex) {
                      setEditingValue(item?.question);
                      setQuestionInputIdx(`${index}-${type}`);
                    }
                  }}
                />
              </Tooltip>
              <Tooltip
                overlayInnerStyle={{backgroundColor: '#000000', borderRadius: '8px', padding: '7px 10px', width: '380px'}}
                title={!generatingIndex && 'Generate a similar question, based on the current one. Question input field must not be empty.'}
              >
                <FontAwesomeIcon
                  style={{cursor: generatingIndex ? 'not-allowed' : 'pointer', display: addingQA && 'none'}}
                  icon={faRotate}
                  color={generatingIndex ? 'rgba(78, 81, 86, 50%)' : '#4E5156'}
                  fontSize={14}
                  className={generatingIndex === `${index}-${type}` ? 'fa-spin' : ''}
                  onClick={() => generatingIndex ? {} : generateQA(index, item?.question, type)}
                />
              </Tooltip>
            </div>
          </> : <>
            <div>
              <span>Q:</span>
            </div>
            <ButtonsWrapper>
              <Tooltip overlayInnerStyle={{background: '#121212'}} title={'Write'}>
                <div className='content-wrapper' onClick={() => {
                  setEditingValue('');
                  setQuestionInputIdx(`${index}-${type}`);
                }}>
                  <FontAwesomeIcon icon={faPen} fontSize={12} color='#4E5156' />
                  Write
                </div>
              </Tooltip>
            </ButtonsWrapper>
          </>}
        </QuestionWrapper> :
        <AnswerWrapper>
          {item?.answer ? <>
            <div>
              <span>A:</span>{item?.answer}
            </div>
            <div>
              <Tooltip
                overlayInnerStyle={{backgroundColor: '#000000', borderRadius: '8px', padding: '7px 10px', width: '380'}}
                title={!generatingIndex && 'Edit'}
              >
                <FontAwesomeIcon
                  style={{cursor: generatingIndex ? 'not-allowed' : 'pointer', display: addingQA && 'none'}}
                  icon={faPen}
                  color={generatingIndex ? 'rgba(78, 81, 86, 50%)' : '#4E5156'}
                  fontSize={14}
                  onClick={() => {
                    if (!generatingIndex) {
                      setEditingValue(item?.answer);
                      setQuestionInputIdx(`${index}-${type}`);
                    }
                  }}
                />
              </Tooltip>
              <Tooltip
                overlayInnerStyle={{backgroundColor: '#000000', borderRadius: '8px', padding: '7px 10px', width: '362px'}}
                title={!generatingIndex && 'Generate an answer based on the current question. Question input field must not be empty.'}
              >
                <FontAwesomeIcon
                  style={{cursor: generatingIndex ? 'not-allowed' : 'pointer', display: addingQA && 'none'}}
                  icon={faRotate}
                  color={generatingIndex ? 'rgba(78, 81, 86, 50%)' : '#4E5156'}
                  fontSize={14}
                  className={generatingIndex === `${index}-${type}` ? 'fa-spin' : ''}
                  onClick={() => generatingIndex ? {} : generateQA(index, item?.question, type)}
                />
              </Tooltip>
            </div>
          </> : <>
            <div>
              <span>A:</span>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <ButtonsWrapper>
                <Tooltip overlayInnerStyle={{background: '#121212'}} title={'Write'}>
                  <div className='content-wrapper' onClick={() => {
                    setEditingValue('');
                    setQuestionInputIdx(`${index}-${type}`);
                  }}>
                    <FontAwesomeIcon icon={faPen} fontSize={12} color='#4E5156' />
                  Write
                  </div>
                </Tooltip>
              </ButtonsWrapper>
              {item?.question && (<Tooltip
                overlayInnerStyle={{backgroundColor: '#000000', borderRadius: '8px', padding: '7px 10px', width: '362px'}}
                title={'Generate an answer based on the current question. Question input field must not be empty.'}
              >
                <FontAwesomeIcon
                  style={{cursor: generatingIndex ? 'not-allowed' : 'pointer'}}
                  icon={faRotate}
                  color='#4E5156'
                  fontSize={14}
                  className={generatingIndex === `${index}-${type}` ? 'fa-spin' : ''}
                  onClick={() => generatingIndex ? {} : generateQA(index, item?.question, type)}
                />
              </Tooltip>)}
            </div>
          </>}
        </AnswerWrapper>
    );
  };
  const inputContainer = (index, type: 'question' | 'answer') => {
    return (
      <QuestionWrapper>
        <span>{type === 'question' ? 'Q:' : 'A:'}</span>
        <div style={{width: '96%'}}>
          {type === 'question' ? (
            <StyledInput value={editingValue} onChange={e => {
              setEditingValue(e.target.value);
            }}/>) : (
            <StyledAnswerInput rows={3} value={editingValue}
              onChange={e => setEditingValue(e.target.value)}
            />)}
          <StyledAddButton
            buttonType='blue-button'
            loading={editingQA}
            disabled={!editingValue || editingQA}
            onClick={() => {
              onEditQA(index, '', editingValue, `${type}-edit`);
              setEditingValue('');
              setQuestionInputIdx('');
            }}
          >
            Add
          </StyledAddButton>
          <CloseButton onClick={() => setQuestionInputIdx('')}>
            <FontAwesomeIcon icon={faTimes} fontSize={18} color='white'/>
          </CloseButton>
        </div>
      </QuestionWrapper>
    );
  };

  const handleSelectedLocation = e => {
    setSelectedGbp(e);
  };

  const filteredLocations = standaloneTool ? getStandAloneLocations((isV2 ? businessesList?.data : gbpLocations), searchValue, isV2) : [];

  useEffect(() => {
    if (gbpLocations?.length && standaloneTool) {
      for (const item of gbpLocations) {
        if (item?.businessName) {
          setSelectedGbp(item);
          break;
        }
      }
    }
  }, []);

  useEffect(() => {
    setSelectedGbp(selectedGbpData);
  }, [selectedGbpData]);

  const locationList = (
    <DropdownContainer >
      <GbpSearchInput value={searchValue} onChange={e => setSearchValue(e.target.value)} placeholder='Search...'
        prefix={<FontAwesomeIcon icon={faMagnifyingGlass} fontSize={'12px'} color='#A3A4A4' />}
      />

      {/* <Radio.Group style={{width: '100%', margin: '10px 0'}} onChange={(e)=> handleSelectedLocation(e.target.value)}> */}
      {filteredLocations?.length ? filteredLocations?.map(business => (
        <Radio key={business?.locationId} style={{width: '100%'}} onChange={e=> handleSelectedLocation(e.target.value)} checked={selectedGbp ? String(selectedGbp?.id) === String(business?.id) : String(currentBusiness?.id) === String(business?.id)} value={business}>
          <div>
            <BusinessName style={{fontWeight: 'bold', fontSize: '12px', width: '405px'}}>{isV2? business?.attributes?.businessName : business?.businessName}</BusinessName>
            <BusinessAddress style={{width: '405px'}}>{isV2? business?.attributes?.businessAddress : business?.businessAddress}</BusinessAddress>
          </div>
        </Radio>
      )) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      {/* </Radio.Group> */}
    </DropdownContainer>
  );

  return (
    <StyledGenerateQAModal
      width={step === 1 ? '650px' : '1214px'}
      closable={true}
      onClose={() => setOpenGenerateQAModal(false)}
      closeIcon={<div><FontAwesomeIcon color={'#fff'} fontSize={22} icon={faXmark}/></div>}
      visible={openGenerateQAModal}
    >
      <StyledModalHeader>
        <div className='steps'>Step {step} of 2</div>
        <div className='modal-heading'>{step === 1 ? 'Generate' : 'Preview'} Questions and Answers</div>
        {!standaloneTool && <div className='steps' style={{marginTop: '-10px'}}>{getSelectedLocation()['businessName']}</div>}
      </StyledModalHeader>
      <ModalContent>
        {step === 1 ? (
          <>
            {standaloneTool && <FieldWrapper>
              <div className='label' style={{marginTop: '-10px', marginBottom: 10}}>
                <FontAwesomeIcon icon={faLocationDot} color='#2D6CCA' fontSize={22}/> Business
              </div>
              <div className='field-container'>
                <Dropdown getPopupContainer={trigger => trigger} trigger={['click']} overlay={locationList} disabled={loadingGenerateBulkQA || isV2} visible={showGbpDropdown} onVisibleChange={e => {
                  setShowGbpDropdown(e);
                  if (!e) {
                    setSearchValue('');
                  }
                }}>
                  <DropdownWrapper >
                    <div style={{width: '92%'}}>
                      <BusinessName>{selectedGbp?.businessName ?? currentBusiness?.businessName}</BusinessName>
                      <BusinessAddress>{selectedGbp?.businessAddress ?? currentBusiness?.businessAddress}</BusinessAddress>
                    </div>
                    <FontAwesomeIcon icon={faCaretDown} />
                  </DropdownWrapper>
                </Dropdown>
              </div>
            </FieldWrapper>}
            <FieldWrapper>
              <div className='label' style={{marginTop: 12, marginBottom: 10}}>
                <FontAwesomeIcon icon={faLayerGroup} color='#2D6CCA' fontSize={22}/> Quantity
              </div>
              <div className='field-container'>
                <div className='body' style={{padding: 0}}>
                  <div className='domain-input-wrapper'>
                    <StyledNumberInput
                      upHandler={<FontAwesomeIcon icon={faSortUp} color='#4E5156' fontSize={14} className='up-icon'/>}
                      downHandler={<FontAwesomeIcon icon={faSortDown} color='#4E5156' fontSize={14} className='down-icon'/>}
                      value={quantity}
                      max={10}
                      disabled={loadingGenerateBulkQA}
                      onChange={onChangeQuantity}
                      // formatter={formatter}
                      bordered={false}
                      showAfter={true}
                      onKeyDown={e => e.preventDefault()}
                      type='number'
                    />
                  </div>
                </div>
              </div>
            </FieldWrapper>
            <FieldWrapper style={{alignItems: 'flex-start'}}>
              <div className='label' style={{marginTop: 18}}>
                <FontAwesomeIcon icon={faCalendar} color='#2D6CCA' fontSize={22}/> Post on
              </div>
              <div className='field-container'>
                {/* <div className='body' style={{padding: 0}}>
                  <div className='domain-input-wrapper' style={{display: 'block'}}>
                    <StyledRadioGroup onChange={handlePostOption} value={step1OptionStates['postOption']?.option}>
                      <StyledRadio value='all-together'>All together</StyledRadio>
                      <StyledRadio value='separately'>Separately</StyledRadio>
                    </StyledRadioGroup>
                    {step1OptionStates['postOption']?.option === 'separately' ? (
                      <StyledPostContainer>
                        <div className='single-input'>
                    Publish
                          <StyledNumberInput
                            style={{padding: '0px 6px'}}
                            width='42px'
                            upHandler={<FontAwesomeIcon icon={faSortUp} color='#4E5156' fontSize={14} className='up-icon'/>}
                            downHandler={<FontAwesomeIcon icon={faSortDown} color='#4E5156' fontSize={14} className='down-icon'/>}
                            showAfter={false}
                          />
                        </div>
                        <div className='single-input'>
                    Q&As, every
                          <StyledNumberInput
                            style={{padding: '0px 6px'}}
                            width='42px'
                            upHandler={<FontAwesomeIcon icon={faSortUp} color='#4E5156' fontSize={14} className='up-icon'/>}
                            downHandler={<FontAwesomeIcon icon={faSortDown} color='#4E5156' fontSize={14} className='down-icon'/>}
                            showAfter={false}
                          />
                        </div>
                        <div className='single-input'>
                    days at <div style={{borderRadius: '8px', padding: '4px 10px', border: '1px solid #D8D8D8'}}>8:00 AM</div>
                        </div>
                        <div className='single-input'>
                  starting on
                          <DatePicker placeholder='Date' suffixIcon={<CaretDownOutlined />} size={'small'} format={'MMM D YYYY'}
                            className='date-input'
                            showToday={false}
                            disabledDate={currentDate => currentDate <= moment().subtract(1, 'days')}
                            value={date}
                            onChange={(e: any) => setDate(e)}
                            allowClear={false} />
                        </div>
                      </StyledPostContainer>
                    ) : (
                      <StyledPostContainer>
                        <div className='all-together-wrapper'>
                          <DatePicker placeholder='Date' suffixIcon={<CaretDownOutlined />} size={'small'} format={'MMM D YYYY'}
                            className='date-input'
                            showToday={false}
                            disabledDate={currentDate => currentDate <= moment().subtract(1, 'days')}
                            value={date ? date : moment()}
                            onChange={(e: any) => setDate(e)}
                            allowClear={false}
                          />
                        </div>
                      </StyledPostContainer>
                    )}
                  </div>
                </div> */}
                <StyledPostContainer style={{padding: '10px 0px'}}>
                  <div className='all-together-wrapper'>
                    <DatePicker getPopupContainer={trigger => trigger} placeholder='Date' suffixIcon={<CaretDownOutlined />} size={'small'} format={'MMM D, YYYY'}
                      className='date-input'
                      showToday={false}
                      disabled={loadingGenerateBulkQA}
                      disabledDate={currentDate => currentDate <= moment().subtract(1, 'days')}
                      value={date}
                      onChange={(e: any) => setDate(e)}
                      allowClear={false}
                    />
                  </div>
                </StyledPostContainer>
              </div>
            </FieldWrapper>
            <FieldWrapper style={{alignItems: 'flex-start'}}>
              <div className='label' style={{marginTop: 18}}>
                <FontAwesomeIcon icon={faCommentQuestion} color='#2D6CCA' fontSize={22}/> Question
              </div>
              <div className='field-container'>
                <div className='body' style={{padding: 0}}>
                  <div className='domain-input-wrapper' style={{display: 'block'}}>
                    <StyledRadioGroup onChange={handleQuestionOption} value={step1OptionStates['questionOption']?.option} disabled={loadingGenerateBulkQA}>
                      <StyledRadio value='write-with-ai'>Write with AI</StyledRadio>
                      <StyledRadio value='write-your-own'>Write your own</StyledRadio>
                    </StyledRadioGroup>
                    {step1OptionStates['questionOption']?.option === 'write-with-ai' ? (
                      <StyledPostContainer>
                        <TextArea
                          style={{resize: 'none', height: '110px', padding: '0px'}}
                          bordered={false}
                          value={question}
                          disabled={loadingGenerateBulkQA}
                          onChange={e => onChangeQuestionPrompt(e)}
                          placeholder={`Provide an overview of the topic, including key themes and relevant background information. The more specific the details, the more tailored and effective the AI-generated questions will be.`}
                        />
                      </StyledPostContainer>
                    ) : null}
                  </div>
                </div>
                {step1OptionStates['questionOption']?.option === 'write-your-own' && <div>You can write the Questions in the next step. AI Help will be available.</div>}
                {errorStates['question'].error ? <div style={{color: 'red', paddingLeft: '4px'}}>{errorStates['question'].message}</div> : null}
              </div>
            </FieldWrapper>
            <FieldWrapper style={{alignItems: 'flex-start'}}>
              <div className='label' style={{marginTop: 18}}>
                <FontAwesomeIcon icon={faCommentCheck} color='#2D6CCA' fontSize={22}/> Answer
              </div>
              <div className='field-container'>
                <div className='body' style={{padding: 0}}>
                  <div className='domain-input-wrapper' style={{display: 'block'}}>
                    <StyledRadioGroup onChange={handleAnswerOption} value={step1OptionStates['answerOption']?.option} disabled={loadingGenerateBulkQA}>
                      <StyledRadio value='write-with-ai'>Write with AI</StyledRadio>
                      <StyledRadio value='write-your-own'>Write your own</StyledRadio>
                    </StyledRadioGroup>
                    {step1OptionStates['answerOption']?.option === 'write-with-ai' ? (
                      <StyledPostContainer>
                        <TextArea
                          style={{resize: 'none', height: '110px', padding: '0px'}}
                          bordered={false}
                          value={answer}
                          disabled={loadingGenerateBulkQA}
                          onChange={e => onChangeAnswerPrompt(e)}
                          placeholder={`Provide an overview of the topic, including key themes and relevant background information. The more specific the details, the more tailored and effective the AI-generated questions will be.`}
                        />
                      </StyledPostContainer>
                    ) : null}
                  </div>
                </div>
                {step1OptionStates['answerOption']?.option === 'write-your-own' && <div>You can write the Questions in the next step. AI Help will be available.</div>}
              </div>
            </FieldWrapper>
          </>
        ) : (
          <StyledStepTwoWrapper>
            <Step2Header>
              <div className='text' style={{flexBasis: '86%'}}>QUESTIONS & ANSWERS</div>
              <div className='text' style={{flexBasis: '14%'}}>SCHEDULE DATE</div>
            </Step2Header>
            <AQWrapper>
              {!loadingGenerateBulkQA && generatedBulkQA?.length ? generatedBulkQA?.map((item, index) => (
                <Step2Body key={index} style={{marginBottom: (index !== generatedBulkQA?.length -1) && '10px'}}>
                  <LeftContainer>
                    {questionInputIdx === `${index}-question` ? inputContainer(index, 'question') : descriptionContainer(index, item, 'question')}
                    {questionInputIdx === `${index}-answer` ? inputContainer(index, 'answer') : descriptionContainer(index, item, 'answer')}
                  </LeftContainer>
                  <RightContainer>
                    <div>
                      <Tooltip overlayStyle={{maxWidth: '350px'}} title={addingQA ? 'Scheduled Date cannot be edited while Approving Q&As' : ''}>
                        <ScheduleDatePicker placeholder='Date' suffixIcon={<CaretDownOutlined />} size={'small'} format={'MMM D, YYYY'}
                          className='date-input'
                          showToday={false}
                          disabledDate={currentDate => currentDate <= moment().subtract(1, 'days')}
                          value={moment(item?.date)}
                          onChange={(e: any) => onEditQA(index, item?.date, moment(e).format('YYYY-MM-DD'), 'date-edit')}
                          disabled={generatingIndex !== '' || addingQA}
                          allowClear={false} />
                      </Tooltip>
                    </div>
                    <DeleteIcon disabled={generatingIndex !== ''} icon={faTrash} color='rgb(78, 81, 86)' fontSize={16} style={{display: addingQA && 'none'}} onClick={() => !generatingIndex && onEditQA(index, '', '', 'delete')}/>
                  </RightContainer>
                </Step2Body>
              )) :(
                <EmptyWrapper>
                  {questionInputIdx === 'add-new' ? (
                    <StyledNewQuestionWrapper>
                      <div className='input-wrapper'>
                        <span style={{paddingLeft: '2px'}}>Question:</span>
                        <StyledInput
                          value={editingValue}
                          onChange={e => {
                            setEditingValue(e.target.value);
                          }}/>
                      </div>
                      <div className='buttons-wrapper'>
                        <StyledAddButton
                          buttonType='blue-button'
                          loading={editingQA}
                          disabled={!editingValue || editingQA}
                          onClick={() => {
                            onEditQA(0, '', editingValue, 'add');
                            setEditingValue('');
                            setQuestionInputIdx('');
                          }}>
                        Add
                        </StyledAddButton>
                        <CloseButton onClick={() => setQuestionInputIdx('')}>
                          <FontAwesomeIcon icon={faTimes} fontSize={18} color='white'/>
                        </CloseButton>
                      </div>
                    </StyledNewQuestionWrapper>
                  ) : <>
                    <ButtonsWrapper style={{justifyContent: 'flex-end'}}>
                      <Tooltip
                        overlayInnerStyle={{backgroundColor: '#000000', borderRadius: '8px', padding: '7px 10px'}}
                        placement='topRight'
                        title={'Add question'}>
                        <div className='content-wrapper' onClick={() => {
                          setEditingValue('');
                          setQuestionInputIdx('add-new');
                        }}>
                          Add Question
                        </div>
                      </Tooltip>
                    </ButtonsWrapper>
                    <Empty/>
                  </>
                  }
                </EmptyWrapper>
              )}
            </AQWrapper>
          </StyledStepTwoWrapper>
        )}
        {/* {step === 1 && <StyledNote>
          <FontAwesomeIcon icon={faCircleInfo} color='#2D6CCA' fontSize={18} />
          Scheduled date, questions and answers can be modified in the next step.
        </StyledNote>} */}
        <StyledModalFooter>
          <div className='cancel-btn' onClick={() => setOpenGenerateQAModal(false)}>
            Cancel
          </div>
          <Tooltip
            placement='topRight'
            overlayStyle={generatingIndex ? {maxWidth: 350} : {}}
            overlayInnerStyle={{backgroundColor: '#000000', borderRadius: '8px', padding: '7px 10px', maxWidth: generatingIndex && 350}}
            title={focusedSubmitButton ? (loadingGenerateBulkQA ? 'Generating question and answers' : (step !== 1 && !generatedBulkQA?.length) ? 'Add at least one question to approve Q&A' : generatingIndex ? 'Please wait while the Question/Answer is being generated' : '') : ''}
          >
            <div onMouseOver={() => setFocusedSubmitButton(true)} onMouseOut={() => setFocusedSubmitButton(false)}>
              <ButtonComponent
                color='blue'
                style='solid'
                type='button'
                className='update-button'
                onClick={onSubmit}
                disabled={loadingGenerateBulkQA || addingQA || !!(generatingIndex)}
              >
                {step === 1 ? (<>
            Preview {quantity} Q&As {!loadingGenerateBulkQA && <FontAwesomeIcon icon={faArrowRight} color='#fff' fontSize={14}/>}
                </>) : (<>
                  <FontAwesomeIcon icon={faCircleCheck} fontSize={14} color='#fff'/> Approve {generatedBulkQA?.length > 0 && generatedBulkQA?.length} Q&As
                </>
                )} {loadingGenerateBulkQA || addingQA ? <Spin indicator={<LoadingOutlined style={{fontSize: '6', color: 'white', marginLeft: 5}} spin />} /> : <></>}
              </ButtonComponent>
            </div>
          </Tooltip>
        </StyledModalFooter>
      </ModalContent>
    </StyledGenerateQAModal>
  );
});

const StyledGenerateQAModal = styled(Modal)`
   .rc-dialog-content {
    .rc-dialog-body {
      background-color: #F9F9FB;
      padding: 25px !important;
      border-radius: 10px;
    }
    .rc-dialog-close {
    font-size: 21px;
    right: -34px;
    top: 0px;
    font-weight: 700;
    color: #fff !important;
    &:hover {
      opacity: 1 !important;
    }
    }
  }
`;
const StyledModalHeader = styled.div`
  padding: 0px !important;
  margin-bottom: 16px;
  .steps {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #4E5156;
  }
  .modal-heading {
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #121212;
    margin-bottom: 8px;
  }
  .modal-description {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #4E5156;
  }
`;
const ModalContent = styled.div``;
const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  .label {
    display: flex;
    width: 110px;
    align-items: center;
    gap: 8px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    color: #121212;
    margin-top: 8px;
  }
  .field-container {
    .header {
      display: flex;
      justify-content: space-between;
      .right-section {
        align-items: center;
        display: flex;
        gap: 10px;
        .button {
          align-items: center;
          display: flex;
          gap: 10px;
          font-family: Inter;
          font-size: 12px;
          font-weight: 400;
          color:#2D6CCA;
          cursor: pointer;
        }
      }
    }
    .body {
      overflow: hidden;
      border: 1px solid #D8D8D8;
      border-radius: 8px;
      padding: 10px;
      width: 100%;
      margin: 5px 0;
      background-color: #ffffff;

      .date {
        width: 105px;
      }
      .time {
        width: 85px;
      }
      .ant-picker-small {
        padding: 0px 0px 0px !important;
      }
      .replace-image {
        padding: 6px;
        background: #4E5156;
        border-radius: 6px;
        position: absolute;
        right: 33px;
        top: 131px;
      }

      .domain-input-wrapper {
        display: flex;
        align-items: center;
        .domain {
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 16.94px;
          text-align: left;
          color: #A3A4A4;
          padding-left: 10px;
        }
        .slug {
          padding: 5px 0px;
        }
      }
      .keywords-input {
        resize: none;
        height: 78px;
      }
    }
    .error-message {
      color: red;
    }

  }
`;
const StyledModalFooter = styled.div`
  margin-top: 18px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  .cancel-btn {
    width: 47px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #4E5156;
    cursor: pointer;
  }
`;
const StyledPostContainer = styled.div`
  padding: 10px 14px;
  width: 480px; 
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  .single-input {
    display: flex;
    align-items: center;
    gap: 6px;
    .date-input {
      border: 1px solid #D8D8D8;
      border-radius: 8px;
      width: 130px !important;
      .ant-picker-input {
        padding: 4px 10px !important;
      }
    }
  }
  .all-together-wrapper {
    display: flex;
    gap: 7px;
    .date-input {
      border: 1px solid #D8D8D8;
      border-radius: 8px;
      width: 145px !important;
      .ant-picker-input {
        padding: 4px 10px !important;
      }
    }
    .time-input {
      border: 1px solid #D8D8D8;
      border-radius: 8px;
      width: 130px !important;
    }
  }
`;
const StyledRadioGroup = styled(Radio.Group)`
  padding: 10px 14px;
  border-bottom: 1px solid #D8D8D8;
  display: block;
  width: 480px;
  .ant-radio-button-wrapper {
    padding: 0px 10px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #121212;
    &.ant-radio-button-wrapper-checked {
      background: #2D6CCA26;
      :focus {
        outline: none;
        box-shadow: none;
      }
      :focus-within {
        outline: none;
        box-shadow: none;
      }
      :hover {
        color: #121212;
      }
    }
    :focus {
        outline: none;
        box-shadow: none;
      }
      :focus-within {
        outline: none;
        box-shadow: none;
      }
      :hover {
        color: #121212;
      }
  }
  .ant-radio-button-wrapper:first-child {
    border-radius: 8px 0px 0px 8px;
  }
  .ant-radio-button-wrapper:last-child {
    border-radius: 0px 8px 8px 0px;
  }
`;
// const StyledNote = styled.div`
//   margin-top: 15px;
//   border-radius: 8px;
//   background-color: rgba(45, 108, 202, 12%);
//   padding: 8px 12px;
//   display: flex;
//   align-items: center;
//   gap: 9px;
// `;
const StyledNumberInput = styled(InputNumber)<{width?: string; showAfter?: boolean}>`
  width: ${p => p?.width ? p.width : '90px'} !important;
  border: 1px solid #D8D8D8 !important;
  border-radius: 8px;
  /* overflow: hidden; */
  .ant-input-number-handler-wrap {
    opacity: 1 !important;
  }
  &.ant-input-number-borderless {
    box-shadow: none !important;
    border: none !important;
  }
  .ant-input-number-input-wrap {
    .ant-input-number-input {
      padding: 0px 58px 0px 11px !important;
    }
  }
  .ant-input-number-handler-wrap {
    .ant-input-number-handler {
      border-left: none !important;
      border-top: none !important;
      &:active {
        background: transparent;
      }
      .up-icon {
        position: absolute;
        top: 7px;
        right: 8px;
      }
      .down-icon {
        position: absolute;
        bottom: 7px;
        right: 8px;
      }
    }
    &:hover .ant-input-number-handler {
      height: 50% !important;
    }
  }
  ${p => p?.showAfter ? `
  &::after {
    content: 'Q&As';
    position: absolute;
    top: 5px;
    right: 20px;
    color: #121212;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    z-index: 1;
  }` : ''};
`;
const StyledStepTwoWrapper = styled.div`
  border: 1px solid #D8D8D8;
  border-radius: 12px;
  padding: 8px 10px;
`;
const Step2Header = styled.div`
  background: #F2F2F5;
  border-radius: 8px;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .text {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 600;
    color: #121212;
  }
`;
const AQWrapper = styled.div`
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(138,138,138,0.32);
  }

  &::-webkit-scrollbar-track {
    background: rgba(138,138,138,0.12);
  }
`;
const Step2Body = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 13px;
  margin-bottom: 20px;
`;
const LeftContainer = styled.div`
  border: 1px solid #D8D8D8;
  border-radius: 8px;
  width: 958px;
`;
const QuestionWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 10px;
  justify-content: space-between;
  border-bottom: 1px solid #D8D8D8;
  div {
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #121212;
    span {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-weight: 600;
      color:#A3A4A4;
    }
  }
`;
const AnswerWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 8px 10px;
  justify-content: space-between;
  div {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #4E5156;
    span {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-weight: 600;
      color:#A3A4A4;
    }
  }
`;
const RightContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  div {
    display: flex;
    align-items: flex-start;
  }
`;
const ScheduleDatePicker = styled(DatePicker)`
  border: 1px solid #D8D8D8;
  border-radius: 8px 8px 8px 8px;
  .ant-picker-input {
    input {
      height: 32px;
      width: 94px;
    }
  }
`;
// const ScheduleTimePicker = styled(TimePicker)`
//   border: 1px solid #D8D8D8;
//   border-radius: 0px 8px 8px 0px;
//   .ant-picker-input {
//     input {
//       height: 25px;
//     }
//   }
// `;

const StyledRadio = styled(Radio)`
  .ant-radio {
    .ant-radio-inner{
      background-color: #fff;
    &::after {
      background-color: #FFFFFF;
    }
  }
  &.ant-radio-checked .ant-radio-inner {
    border-color: #2D6CCA;
    background-color: #2D6CCA;
  }
}
`;
const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  .content-wrapper {
    cursor: pointer;
    font-size: 13px;
    font-weight: 400;
    color: #4E5156;
    display: flex;
    align-items: center;
    gap: 4px;
    background-color: #F9F9FB;
    padding: 2px 6px;
    border-radius: 4px;
    border: 1px solid #E8E8E8;
  }
`;
const CloseButton = styled.div`
  width: 32px;
  height: 26px;
  border-radius: 4px;
  background-color: #4E5156;
  align-items: center;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
`;
const StyledInput = styled(Input)`
  height: 35px;
  border-radius: 6px !important;
  border: 1px solid #CDCDCD;
  &:hover {
    border-color: #CDCDCD !important;
  }
  &:focus {
    border-color: #CDCDCD !important;
    box-shadow: none;
  }
  &::placeholder {
    font-size: 13px;
  }
`;
const StyledAddButton = styled(Button)`
  display: flex;
  align-items: center;
  height: 26px;
  font-size: 14px;
  padding: 0px 10px;
  span {
    color: #fff !important;
  }
`;
const EmptyWrapper = styled.div``;
const StyledAnswerInput = styled(TextArea)`
  border-radius: 6px !important;
  border: 1px solid #CDCDCD;
  &:hover {
    border-color: #CDCDCD !important;
  }
  &:focus {
    border-color: #CDCDCD !important;
    box-shadow: none;
  }
  &::placeholder {
    font-size: 13px;
  }
`;
const StyledNewQuestionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  .input-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 2px;
  }
  .buttons-wrapper {
    display: flex;
    align-items: center;
    gap: 2px;
  }
`;
const DeleteIcon = styled(FontAwesomeIcon)<{disabled: boolean}>`
cursor: ${p => p?.disabled ? 'not-allowed' : 'pointer'};
color: ${p => p?.disabled ? 'rgba(78, 81, 86, 50%)' : 'rgb(78, 81, 86)'};;
  &:hover {
    color: ${p => p?.disabled ? 'rgba(78, 81, 86, 50%)' : '#F44343'};
  }
`;

const DropdownContainer = styled.div`
  width: 480px;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 12px;
  background: white;
  padding: 14px 12px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    border-radius: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background: rgba(138,138,138,0.32);
  }
          
  &::-webkit-scrollbar-track {
    border-radius: 12px;
    background: rgba(138,138,138,0.12);
  }
`;

const GbpSearchInput = styled(Input)`
  width: 460px;
  height: 32px;
  margin-top: 9px;
  margin-bottom: 10px;
  border-radius: 8px;
  border: 1px solid #CDCDCD;
`;

const DropdownWrapper = styled.div<{disabled?: boolean}>`
  width: 480px;
  padding: 7px 15px 8px 15px;
  border: 1px solid #D8D8D8;
  border-radius: 8px;
  background: ${p => p?.disabled ? '#f5f5f5' : '#FFFFFF'};
  display: flex;
  align-items: center;  
  justify-content: space-between;
  cursor: pointer;
`;

const BusinessName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #121212;
`;

const BusinessAddress = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  color: #4E5156;
`;
